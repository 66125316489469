import React, { useEffect } from 'react';
import "./MessageAttachments.scss";
import { ImageCore, Label, UnstyledButton } from 'src/ui';
import { formatInteractiveText } from 'src/utils';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import ComposerAttachmentFile from '../ComposerAttachmentFile/ComposerAttachmentFile';
import { SpintrTypes } from 'src/typings';

interface IProps {
    message: Spintr.IChatMessage;
}

const MessageAttachments = (props: IProps) => {
    if (!props.message.attachments || props.message.attachments.length === 0) {
        return null;
    }

    const imageFileTypes = [SpintrTypes.FileType.Png, SpintrTypes.FileType.Jpg, SpintrTypes.FileType.Gif];
    const audioFileTypes = [SpintrTypes.FileType.Aac, SpintrTypes.FileType.Mp3, SpintrTypes.FileType.M4a, SpintrTypes.FileType.Webm];

    return (
        <div className={"MessageAttachments"}>
            {props.message.attachments.map((attachment: Spintr.IChatMessageAttachment) => {
                if (imageFileTypes.indexOf(attachment.typeId) > -1) {
                    return (
                        <ImageCore
                            key={attachment.id}
                            openPhotoBoxOnClick
                            imageInChat={true}
                            imageId={attachment.id}
                            alt={attachment.name}
                            //@ts-ignore
                            src={attachment.thumbnailUrl || attachment.path}
                            title={attachment.name}
                        />
                    )
                }
                
                if (audioFileTypes.indexOf(attachment.typeId) > -1) {
                    return (
                        <AudioPlayer
                            url={attachment.path}
                            key={attachment.id} />
                    )
                }

                return (
                    <a className="file-attachment" key={attachment.id} href={`/api/v1/servefile/${attachment.id}`} target="_blank">
                        <ComposerAttachmentFile attachment={attachment} />
                    </a>
                )
            })}
        </div>
    )
}

export default MessageAttachments;
