import classNames from "classnames";
import React, { FunctionComponent, CSSProperties, ReactNode, useMemo } from "react";

interface IBackgroundImageProps {
    children?: ReactNode | ReactNode[] | undefined;
    imageUrl: string;
    center?: boolean;
    style?: CSSProperties;
    className?: string;
}

const BackgroundImage: FunctionComponent<IBackgroundImageProps> =
    ({ children, imageUrl, center, style, className }) => {
        const cssProps = useMemo<CSSProperties>(() => ({
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            ...(center ? {backgroundPosition: "center"} : {}),
            ...style
        }), [imageUrl, center, style]);
        
        return (
            <div
                className={classNames("background-image", className)}
                style={cssProps}
            >
                {children}
            </div>
        )
    }

export default BackgroundImage;