export enum MessageRole {
    User = "user",
    Assistant = "assistant"
};

export interface IAnswerCitation {
    content: string;
    id: string;
    title: string;
    url: string;
}

export interface IPostQuestionResponse {
    citations: IAnswerCitation[];
    date: string;
    role: MessageRole;
    text: string;
}

export interface IMessage {
    role: MessageRole;
    text: string;
    date: Date;
    citations?: IAnswerCitation[];
}
