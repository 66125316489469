import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";

interface Props {
    userOfficeId: number;
}

interface State {}

class MyOfficeRedirectView extends Component<Props, State> {
    state = {};

    render() {
        if (!this.props.userOfficeId) {
            return <Redirect to={"/"} />;
        }

        const url = `/avdelningar/v/${this.props.userOfficeId}`;
        return <Redirect to={url} />;
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        userOfficeId: state.profile.active?.department?.office?.id,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(MyOfficeRedirectView));
