import React from "react";
import { localize } from "src/l10n";
import CustomDialog from "../Dialogs/CustomDialog";

const ConfirmDialog = (props) => {
    return (
        <CustomDialog
            title={props.title}
            message={props.message ? props.message : localize("ArDuSakerAttDuVillUtforaDennaAtgard")}
            show={props.show}
            onDismiss={props.onDismiss}
            onConfirm={props.onConfirm}
        />
    );
};

export default ConfirmDialog;
