import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";
import "./HeaderImportantArticlesButton.scss";
import { ReactSVG } from "react-svg";
import { Label, UnstyledButton } from "src/ui/components";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { localize } from "src/l10n";
import { Callout } from "@fluentui/react";
import { fromNow } from "src/utils";
import { Link } from "react-router-dom";

interface IProps {
    importantArticles?: any[];
}

const HeaderImportantArticlesButton = (props: IProps) => {
    const [state, setState] = useState({
        title: "",
        displayCallout: false
    });

    useEffect(() => {
        setState(s => ({
            ...s,
            title: localize(props.importantArticles.length === 1 ?
                "NEED_TO_KNOW_ARTICLES_FILTER_BUTTON_SINGLE" :
                "NEED_TO_KNOW_ARTICLES_FILTER_BUTTON_MULTIPLE").replace("{0}", props.importantArticles.length.toString())
        }))
    }, [props.importantArticles]);

    return (
        <div>
            {state.displayCallout && (
                <Callout
                    role="alertdialog"
                    gapSpace={0}
                    setInitialFocus
                    onDismiss={() => {
                        setState(s => ({
                            ...s,
                            displayCallout: false,
                        }));
                    }}
                    target={"#HeaderImportantArticlesButton"}
                >
                    <div className="HeaderImportantArticlesButton-callout">
                        {props.importantArticles.map((item: any, index: number) => {
                            const slug = item.url.split("/").slice(1).join("/");

                            return (
                                <Link
                                    to={`/news/${slug}`}
                                    key={item.id}
                                    onClick={() => {
                                        setState(s => ({
                                            ...s,
                                            displayCallout: false,
                                        }));
                                    }}
                                    className="HeaderImportantArticlesButton-article">
                                    <div>
                                        <Label weight="medium" color="dark-grey">{item.title}</Label>
                                        <div className="entry-meta">
                                            <Label size="body-2" color="mid-grey">{fromNow(item.date, true)}</Label>
                                        </div>
                                    </div>
                                    <Visage2Icon icon="arrow-circle-right" color="neutralBlue" type="bold" />
                                </Link>
                            )
                        })}
                    </div>
                </Callout>
            )}
            <UnstyledButton
                id="HeaderImportantArticlesButton"
                title={state.title}
                onClick={() => {
                    setState(s => ({
                        ...s,
                        displayCallout: !s.displayCallout,
                    }))
                }}
                className="HeaderImportantArticlesButton visage2-box-shadow">
                <div className="icon-wrapper">
                    <ReactSVG src={"/images/fire.svg"} />
                </div>
                <Label size="body-2">
                    {state.title}
                </Label>
                <Visage2Icon icon="arrow-down-1" size="small" />
            </UnstyledButton>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        importantArticles: state.informationFeed.importantArticles.filter(x => x.priorityLevel === 5),
    };
};

export default connect(mapStateToProps)(HeaderImportantArticlesButton);
