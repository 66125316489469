import React from "react";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";

interface IPageRouteParams {
    path: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    callBack: any;
    post: Spintr.IBlogPost;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    dispatch: any;
}

interface IState {
    showInfoDialog: boolean;
    showShareModal: boolean;
}

class BlogsActionMenu extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            showInfoDialog: false,
            showShareModal: false
        };
    }

    deleteBlogpost = () => {
        this.setState({ showInfoDialog: false });
        api.delete(`/api/v1/blogposts/${this.props.post.id}`).then((_response) => {
            this.props.callBack("delete");
        });
    };

    public render = () => {
        const { post, isAdmin, isEditor, currentUserId } = this.props;
        const { showInfoDialog } = this.state;
        const isAuthor = post.author.id === currentUserId;
        // FIXME: blog doesn't have coauthors here
        const isCoauthor = post.blog.coauthors && post.blog.coauthors.some((ca) => ca.id === currentUserId);

  
        if (Object.keys(post).length === 0) return <Loader />;
        else {
            let blogSlug = post.url.split("/")[2];
            let postSlug = post.url.split("/")[3];

            return (
                <div>
                    {showInfoDialog && (
                        <CustomDialog
                            title={localize("RaderaInnehall")}
                            message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase())}
                            show={showInfoDialog}
                            onDismiss={() => {
                                this.setState({ showInfoDialog: false });
                            }}
                            onConfirm={() => {
                                this.deleteBlogpost();
                            }}
                        />
                    )}
                    <StandardActionMenu
                        canAddToFavourites={true}
                        canFollow={true}
                        canShare={true}
                        canDelete={isAdmin || isEditor || isAuthor || isCoauthor}
                        canEdit={isAdmin || isEditor || isAuthor || isCoauthor}
                        objectId={post.id}
                        onEditClick={() => {
                            this.props.history.push({
                                pathname: `/blogs/${blogSlug}/${postSlug}/edit`,
                            });
                        }}
                        onDeleteClick={() => {
                            this.setState({
                                showInfoDialog: true,
                            });
                        }}
                        isFollowing={post.isFollowing}
                        isFavourite={post.isFavourite} />
                </div>
            );
        }
    };
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
    };
};

export default withRouter(connect(mapStateToProps)(BlogsActionMenu));
