import React, { useState } from 'react';
import "./ComposerMemoTodoList.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import { TextField } from '@fluentui/react';
import ComposerMemoTodoItem from '../ComposerMemoTodoItem/ComposerMemoTodoItem';
import { generateUniqueNumberId } from 'src/utils/uniqueId';

interface IProps {
    todos: any[];
    onChange: (data: any) => void;
}

interface IState {
    activeIndex: number;
}

const ComposerMemoTodoList = (props: IProps) => {
    const [state, setState] = useState<IState>({
        activeIndex: 0
    });
    
    return (
        <div className="ComposerMemoTodoList">
            {props.todos.length > 0 && (
                <div className="items">
                    {props.todos.map((item: any, index: number) => {
                        return (
                            <ComposerMemoTodoItem
                                key={item.tmpId}
                                item={item}
                                isActive={index === state.activeIndex}
                                onActive={() => {
                                    setState((s: IState) => {
                                        return {
                                            ...s,
                                            activeIndex: index
                                        }
                                    });
                                }}
                                onChange={(updatedItem: any) => {
                                    props.onChange(props.todos.map((x: any, xIndex: number) => {
                                        if (item.tmpId === x.tmpId) {
                                            return updatedItem;
                                        }

                                        return x;
                                    }))
                                }}
                                onDelete={() => {
                                    props.onChange(props.todos.filter(x => x.id !== item.id));
                                }} />
                        )
                    })}
                </div>
            )}
            <UnstyledButton className="add-button" onClick={() => {
                props.onChange([...props.todos, {id: 0, tmpId: generateUniqueNumberId(), assignees: []}]);
            }}>
                <Visage2Icon icon="add-circle" size="small" color="light-blue" />
                <Label color="light-blue" weight="medium">{localize("LaggTillNyAttGoraPunkt")}</Label>
            </UnstyledButton>
        </div>
    )
}

export default ComposerMemoTodoList;
