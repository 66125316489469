import api from "src/spintr/SpintrApi";

export type AutosaveApiHandler =
    (model: Spintr.IContentVersion) => Promise<Spintr.IContentVersion>;

const autosave: AutosaveApiHandler = async (model) => {
    try {
        const response = await api.post<Spintr.IContentVersion>(
            "/api/v1/contentversions/autosave",
            model,
        );

        return response.data;
    } catch {
        return null;
    }
}

export default autosave;
