import { DatePicker, DayOfWeek, Stack, TextField } from '@fluentui/react';
import React, { ChangeEvent, Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { localize } from 'src/l10n';
import { SpintrTypes } from 'src/typings';
import { Loader } from 'src/ui';
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from 'src/ui/components/Forms';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/ui/components/Messagebars/SuccessMessagebar';
import TinyEditorComponent from 'src/ui/components/Tiny/TinyEditorComponent';
import { scrollToTop, validateRequiredTextField } from 'src/utils';
import formatDatePickerDate from 'src/utils/formatDatePickerDate';
import getDatePickerLanguageStrings from 'src/utils/getDatePickerLanguageStrings';
import './ToDoItemEditView.scss';
import api from 'src/spintr/SpintrApi';

interface IPageRouteParams {
    id: string;
    listId: string;
}

interface Props extends RouteChildrenProps<IPageRouteParams> {
    currentUser: any;
}

interface State {
    isLoading: boolean;
    todo: any;
    saveError: string[];
    saveSuccess: boolean;
    list: any;
    enableFieldValidation?: boolean;
}

class TodoItemEditView extends Component<Props, State> {
    state = {
        isLoading: true,
        todo: {
            name: "",
        } as any,
        saveError: [],
        saveSuccess: false,
        list: {} as any,
        enableFieldValidation: false
    };

    componentDidMount = () => {
        const { id, listId } = this.props.match.params;
        api.get(`/api/v1/todos/list?listId=${listId}`).then((response) => {
            this.setState({ list: response.data }, () => {
                if (id) {
                    this.setState({ isLoading: true }, () => {
                        api.get(`/api/v1/todos/${id}`).then((response) => {
                            let todo = response.data;
                            if (todo.dueDate) {
                                todo.dueDate = new Date(todo.dueDate);
                            }

                            if (todo.user) {
                                todo.user.type = SpintrTypes.UberType.User;
                            }

                            todo.list = this.state.list;

                            this.setState({ todo, isLoading: false });
                        });
                    });
                } else {
                    let todo = {} as any;
                    todo.user = {
                        id: this.props.currentUser.id,
                        type: 1,
                        name: this.props.currentUser.name,
                        typeName: "Person",
                    };
                    todo.list = this.state.list;
                    this.setState({ todo, isLoading: false });
                }
            });
        });
    };

    onCancel = () => {
        this.props.history.goBack();
    };

    onSubmit = () => {
        this.setState({
            enableFieldValidation: true
        });
        const { listId } = this.props.match.params;

        let payload = {} as any;
        if (this.refs.tiny) {
            payload = {
                ...this.state.todo,
                // @ts-ignore
                text: this.refs.tiny.getContent(),
            };
        } else {
            payload = this.state.todo;
        }

        payload.user = {
            id: payload.user?.id,
        };

        this.setState({ isLoading: true, todo: { ...this.state.todo, text: payload.text } }, () => {
            api
                .post("/api/v1/todos", payload)
                .then((_response) => {
                    scrollToTop();
                    this.setState({ saveSuccess: true, saveError: [], isLoading: false }, () => {
                        this.props.history.push({
                            pathname: `/todo/list/${listId}`,
                        });
                    });
                })
                .catch((saveError) => {
                    scrollToTop();
                    this.setState({ saveError: saveError.response.data.errorlist, isLoading: false });
                });
        });
    };

    render() {
        const { todo, isLoading, saveError, saveSuccess, list } = this.state;

        if (isLoading) return <Loader />;

        return (
            <div className="TodoItemEditView">
                <Helmet>
                    <title>{localize("AttGora")}</title>
                </Helmet>
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                <Stack tokens={{ childrenGap: 50 }} styles={{ root: { width: 650 } }} style={{ marginBottom: "3em" }}>
                    <form>
                        <FormSection>
                            <FormControl>
                                <TextField
                                    label={localize("Titel")}
                                    value={todo.name}
                                    onChange={(event: ChangeEvent<HTMLInputElement>, name) => {
                                        this.setState((state) => ({ todo: { ...state.todo, name} }));
                                    }}
                                    placeholder={localize("SkrivEnRubrik") + "..."}
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                                <TinyEditorComponent
                                    label={localize("Beskrivning")}
                                    ref={"tiny"}
                                    id={"23"}
                                    content={todo.text}
                                />
                                <TextField label={localize("Lista")} value={list.name} disabled={true} />

                                <FormTokenizedObjectInput
                                    types={[SpintrTypes.UberType.User]}
                                    itemLimit={1}
                                    items={
                                        todo.user
                                            ? [todo.user]
                                            : [
                                                {
                                                    id: this.props.currentUser.id,
                                                    type: 1,
                                                    name: this.props.currentUser.name,
                                                    typeName: "Person",
                                                },
                                            ]
                                    }
                                    label={localize("Ansvarig")}
                                    onChange={(user) => {
                                        this.setState((state) => ({
                                            todo: {
                                                ...state.todo,
                                                user: user.map((e) => {
                                                    return {
                                                        name: e.name,
                                                        info: e.subText,
                                                        type: SpintrTypes.UberType.User,
                                                        id: e.key,
                                                        imageUrl: e.imageUrl,
                                                    };
                                                })[0],
                                            },
                                        }));
                                    }}
                                />
                                <DatePicker
                                    label={localize("Datum")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={formatDatePickerDate}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={todo.dueDate}
                                    onSelectDate={(dueDate: Date) => {
                                        this.setState((state) => ({
                                            todo: {
                                                ...state.todo,
                                                dueDate,
                                            },
                                        }));
                                    }}
                                />

                                {/* <FormTokenizedObjectInput 
                                hideImage={true}
                                enableNewItems={true}
                                items={
                                    todo.tags
                                        ? todo.tags.map((tag) => {
                                              return {
                                                  name: tag,
                                              };
                                          })
                                        : []
                                }
                                label={localize("SokBlandTaggar")}
                                onChange={(tags) => {
                                    this.setState((state) => ({
                                        todo: {
                                            ...state.todo,
                                            tags: tags.map((t) => {
                                                return t.name;
                                            }),
                                        },
                                    }));
                                }}
                            /> */}


                            </FormControl>
                        </FormSection>
                    </form>
                </Stack>
                <FormFooterBar onCancelClick={this.onCancel} onSaveClick={this.onSubmit} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(TodoItemEditView));
