import { AxiosResponse } from "axios";
import { Action } from "redux";

export enum MenuActionTypes {
    FetchMainMenu               = "FETCH_MAIN_MENU",
    FetchMainMenuPending        = "FETCH_MAIN_MENU_PENDING",
    FetchMainMenuRejected       = "FETCH_MAIN_MENU_REJECTED",
    FetchMainMenuFulfilled      = "FETCH_MAIN_MENU_FULFILLED",

    RemoveMainMenuItem          = "REMOVE_MAIN_MENU_ITEM",
}

export interface IFetchMainMenuMeta {
    isSilentFetch: boolean;
}
export interface IFetchMainMenuAction extends Action<MenuActionTypes.FetchMainMenu> {
    payload: Promise<AxiosResponse>;
    meta: IFetchMainMenuMeta;
}

export interface IFetchMainMenuPendingAction extends Action<MenuActionTypes.FetchMainMenuPending> {
    meta: IFetchMainMenuMeta;
}
export interface IFetchMainMenuRejectedAction extends Action<MenuActionTypes.FetchMainMenuRejected> {
    meta: IFetchMainMenuMeta;
}
export interface IFetchMainMenuFulfilledAction extends Spintr.IAsyncAction<Spintr.IMainMenuItem[], IFetchMainMenuMeta> {
    type: MenuActionTypes.FetchMainMenuFulfilled;
}

export interface IRemoveMainMenuItemAction extends Action<MenuActionTypes.RemoveMainMenuItem> {
    byProperty: keyof Spintr.IMainMenuItem;
    propertyValue: any;
}


export type FetchMainMenuAction
    = IFetchMainMenuAction
    | IFetchMainMenuPendingAction
    | IFetchMainMenuRejectedAction
    | IFetchMainMenuFulfilledAction
    ;

export type MenuAction
    = FetchMainMenuAction
    | IRemoveMainMenuItemAction
    ;
