import React from 'react';
import "./CompactTable.scss";
import { Label } from 'src/ui';

interface IProps {
    columNames: string[];
    rows: string[][];
};

const CompactTable = (props: IProps) => {
    return (
        <div className="CompactTable">
            <div className="row title">
                {props.columNames.map((name: string, columnIndex: number) => {
                    return (
                        <div className="column" key={columnIndex}>
                            <Label size="body-2" color="mid-grey">{name}</Label>
                        </div>
                    )
                })}
            </div>
            <div>
                {props.rows.map((row: string[], rowIndex: number) => {
                    return (
                        <div className="row" key={rowIndex}>
                            {row.map((value: string, columnIndex: number) => {
                                return (
                                    <div className="column" key={columnIndex}>
                                        <Label size="body-1">{value}</Label>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CompactTable;
