import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Label, UnstyledButton } from "src/ui";
import PopoutFrame from "src/ui/components/PopoutFrame";
import "./SpintrStaticLinksPopout.scss";

interface IProps {
    dispatch: any;
    headerText?: string;
    onDismiss: any;
    footerButtonText?: string;
    onFooterButtonClick?: any;
    extraWide?: boolean;
    location?: any;
    menuItems: any;
    headerChildren?: React.ReactNode[];
    width?: number;
    alternativePosition?: boolean;
}

class SpintrStaticLinksPopout extends Component<IProps> {
    constructor(props) {
        super(props);

        this.escFunction = this.escFunction.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.location &&
            !!prevProps.location &&
            this.props.location.pathname !== prevProps.location.pathname) {
            this.dismiss();
        }
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.dismiss();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        this.hideScrollIfNeeded();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        this.displayScroll();
    }

    dismiss() {
        this.props.onDismiss();
    }

    hideScrollIfNeeded() {
        function getScrollbarWidth() {
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll';
            document.body.appendChild(outer);
            const inner = document.createElement('div');
            outer.appendChild(inner);
            const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
            outer.parentNode.removeChild(outer);

            return scrollbarWidth;
        }

        // document.body.style.overflowY = "hidden";
        // document.body.style.marginRight = getScrollbarWidth() + "px";
        //document.getElementById("SpintrHeader").style.marginRight = getScrollbarWidth() + "px";

        const chatTabContainer = document.getElementById("ChatTabContainer");

        if (!!chatTabContainer) {
            chatTabContainer.style.marginRight = getScrollbarWidth() + "px";
        }
    }

    displayScroll() {
        // document.body.style.overflowY = "scroll";
        // document.body.style.marginRight = "0px";

        // const spintrHeader = document.getElementById("SpintrHeader");

        // if (!!spintrHeader) {
        //     spintrHeader.style.marginRight = "0px";
        // }

        const chatTabContainer = document.getElementById("ChatTabContainer");

        if (!!chatTabContainer) {
            chatTabContainer.style.marginRight = "0px";
        }
    }

    public render() {
        let classList = ["SpintrStaticLinksPopout",];

        if (this.props.extraWide) {
            classList.push("SpintrStaticLinksPopout-extraWide")
        }

        if (this.props.alternativePosition) {
            classList.push("alternativePosition");
        }

        return (
            <div>
                <div className={classList.join(" ")} style={{width: this.props.width}}>
                    <PopoutFrame 
                        title={this.props.headerText} 
                        menuItems={this.props.menuItems}
                        headerChildren={this.props.headerChildren}
                    >
                        {this.props.children}
                        {
                            !!this.props.footerButtonText && (
                                <div className="SpintrStaticLinksCallout-footer">
                                    <UnstyledButton onClick={this.props.onFooterButtonClick}>
                                        <Label size="body-2" color="light-blue">{this.props.footerButtonText}</Label>
                                    </UnstyledButton>
                                </div>
                            )
                        }
                    </PopoutFrame>
                </div>
                <div className="SpintrOverlay SpintrStaticLinksPopoutMode" onClick={this.dismiss.bind(this)} />
            </div>
        )
    }
}

export default connect()(withRouter(SpintrStaticLinksPopout));
