export const FETCH_ATTACHED_FILE = "FETCH_ATTACHED_FILE";
export const FETCH_ATTACHED_FILE_PENDING = "FETCH_ATTACHED_FILE_PENDING";
export const FETCH_ATTACHED_FILE_REJECTED = "FETCH_ATTACHED_FILE_REJECTED";
export const FETCH_ATTACHED_FILE_FULFILLED = "FETCH_ATTACHED_FILE_FULFILLED";

export const FETCH_ATTACHED_FOLDER= "FETCH_ATTACHED_FOLDER";
export const FETCH_ATTACHED_FOLDER_PENDING = "FETCH_ATTACHED_FOLDER_PENDING";
export const FETCH_ATTACHED_FOLDER_REJECTED = "FETCH_ATTACHED_FOLDER_REJECTED";
export const FETCH_ATTACHED_FOLDER_FULFILLED = "FETCH_ATTACHED_FOLDER_FULFILLED";

export const CLEAR_ATTACHED_FILES_AND_FOLDERS= "CLEAR_ATTACHED_FILES_AND_FOLDERS";

export const REMOVE_ATTACHED_FILE = "REMOVE_ATTACHED_FILE";

export const REMOVE_ATTACHED_FOLDER = "REMOVE_ATTACHED_FOLDER";


