import React, { FunctionComponent, useMemo } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import zxcvbn from "zxcvbn-typescript";

interface IProps {
    password?: string;
}

const currentYear =  (new Date()).getFullYear();
const swedishSeasons = ["Sommar", "Vinter", "Höst", "Vår"];

const userInputs: Array<string | number | boolean> = [];
swedishSeasons.forEach((season) => {
    userInputs.push(season);

    userInputs.push(season + currentYear);
    userInputs.push(season + (currentYear + 1));
    userInputs.push(season + (currentYear - 1));
    userInputs.push(season + (currentYear - 2));
});

const PasswordStrengthMeter: FunctionComponent<IProps> = (props) => {
    const { password } = props;

    const result = !password ? null : zxcvbn(password, userInputs);

    const text = result ? localize("PASSWORD_STRENGTH_" + (result.score + 1)) : "";
    console.log(result);

    const feedback = result?.feedback;
    const warning = feedback?.warning;

    const warningText = useMemo(
        () => {
            if (!warning) {
                if (userInputs.some((pw) => pw.toString().toLocaleLowerCase() === (password || "").toLocaleLowerCase())) {
                    return localize("THISISAVERYCOMMONPASSWORD");
                }
                return null;
            }

            const key = warning.split(".")[0]
                .replace(/\s+/gmi, "")
                .replace(/"/gmi, "")
                .toUpperCase();
            const localized = localize(key);

            if (key === localized) {
                return null;
            }

            return localized;
        },
        [warning, password],
    );

    const suggestions = feedback?.suggestions || [];
    const suggestionText = useMemo(
        () => suggestions
            .map((suggestion: string) => {
                const key = suggestion.split(".")[0].replace(/\s+/gmi, "").toUpperCase();
                const localized = localize(key);
    
                if (key === localized) {
                    return null;
                }
    
                return localized;
            })
            .filter((str) => !!str)
            .join(". "),
        [suggestions],
    );

    return (
        <div className="PasswordStrengthMeter">
            {result !== null && (
                <>
                    <div
                        className={"meter strength" + (result.score+1)}
                        role="presentation"
                        title={text}
                    >
                        {[...Array(5)].map((_: never, i: number) => (
                            <div key={i} className="indicator" />
                        ))}
                    </div>
                    <Label size="body-3">
                        {text}. {result.score < 3 && suggestionText}
                    </Label>
                    {warningText && (
                        <Label size="body-3" color="red">
                            {warningText}
                        </Label>
                    )}
                </>
            )}
        </div>
    );
};

export default PasswordStrengthMeter;
