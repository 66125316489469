import React, { Component } from "react";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { produceTheme } from "src/style/ThemeContext";
import { Loader } from "src/ui";
import { fetchAuthData } from "./actions";
import ForgotPassword from "./forgotpassword/ForgotPassword";
import PasswordRecovery from "./passwordrecovery/PasswordRecovery";
import SignIn from "./signin/SignIn";
import { Helmet } from "react-helmet";
import { getQueryStringMap } from "src/utils";
import { consumeLocalization } from "src/l10n/localize";
import { IApplicationState } from "src/spintr/reducer";

interface IStateProps {
    appSettings?: { [name: string]: any };
    languageTags?: { [name: string]: string };
    isLoading?: boolean;
    isLoggedIn?: boolean;
}

interface IState {
    languageConsumed?: boolean;
}

type Props = RouteComponentProps & DispatchProp & IStateProps;

class Auth extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {};

        this.loadAuthData();
    }

    public componentDidUpdate(prevProps, prevState) {
        if (prevProps.appSettings.color !== this.props.appSettings.color) {
            produceTheme(this.props.appSettings.color);
        }

        if (prevProps.languageTags !== this.props.languageTags) {
            consumeLocalization(this.props.languageTags);

            this.setState({
                languageConsumed: true,
            });
        }

        if (prevProps.appSettings.faviconUrl !== this.props.appSettings.faviconUrl) {
            const faviconEl = document.getElementById("favicon");

            // @ts-ignore
            faviconEl.href = this.props.appSettings.faviconUrl;
        }

        if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn) {
            const qs = getQueryStringMap(this.props.location);

            const returnUrl = qs.return || qs.ReturnUrl || "/";

            this.props.history.replace(returnUrl);
        }
    }

    public render() {
        if (this.props.isLoading || !this.state.languageConsumed) {
            return <Loader />;
        }

        return (
            <div>
                <Helmet>
                    <title>{this.props.appSettings.name || "Spintr"}</title>
                </Helmet>
                <Switch>
                    <Route path="/sign-in" component={SignIn} />
                    <Route path="/password-recovery" component={PasswordRecovery} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                </Switch>
            </div>
        );
    }

    private loadAuthData = async () => {
        const qs = getQueryStringMap(this.props.location);

        this.props.dispatch(fetchAuthData(qs.code));
    };
}

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state) => ({
    appSettings: state.auth.appSettings,
    isLoading: state.auth.isLoading,
    isLoggedIn: state.auth.isLoggedIn,
    languageTags: state.auth.languageTags,
});

const ConnectedAuth = connect(mapStateToProps)(Auth);
const ConnectedAuthWithRouter = withRouter(ConnectedAuth);

export default ConnectedAuthWithRouter;
