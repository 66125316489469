import { ContentBlock } from "draft-js";
import { Strategy, StrategyCallback } from "../../types";

function findProductInContentBlock(
    pattern:        RegExp,
    text:           string,
    nonEntityStart: number,
    callback:       StrategyCallback
): void {
    let matchArr: RegExpExecArray | null;
    let start: number;
    let prevEntityEnd = pattern.lastIndex;

    while ((matchArr = pattern.exec(text)) !== null) {
        if (pattern.lastIndex === prevEntityEnd) {
            break;
        }

        prevEntityEnd = pattern.lastIndex;

        start = nonEntityStart + matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

function findProductWithPattern(
    pattern:       RegExp,
    contentBlock:   ContentBlock,
    callback:       StrategyCallback,
): void {
    const contentBlockText = contentBlock.getText();

    contentBlock.findEntityRanges(
        (character) => !character.getEntity(),
        (nonEntityStart, nonEntityEnd) => findProductInContentBlock(
            pattern,
            contentBlockText.slice(nonEntityStart, nonEntityEnd),
            nonEntityStart,
            callback
        ),
    );
}
    
export function createProductSuggestionStrategy(...patterns: RegExp[]): Strategy {
    return function productSuggestionStrategy(block, callback) {
        patterns.forEach((pattern) => {
            findProductWithPattern(pattern, block, callback);
        });
    };
}
