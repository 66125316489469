import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import OrganisationUnitEditView from "../OrganisationUnitEditView";

interface IRouteParams {
    firstId?: string;
    secondId?: string;
    thirdId?: string;
}

const OrganisationAdminFormView: FunctionComponent = () => {
    const enableCompanyLevel = useSelector<Spintr.AppState>(
        (state) => state.instance.get("enableCompanyLevel"),
    );
    const { params } = useRouteMatch<IRouteParams>();

    const departmentId = enableCompanyLevel
        ? params.thirdId
        : params.secondId;

    const officeId = enableCompanyLevel
        ? params.secondId
        : params.firstId;

    const companyId = enableCompanyLevel
        ? params.firstId
        : undefined;

    return (
        <OrganisationUnitEditView
            departmentId={departmentId}
            officeId={officeId}
            companyId={companyId}
        />
    );
};

export default OrganisationAdminFormView;
