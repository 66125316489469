import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { localize } from "src/l10n";
import { ActionMenu } from "src/ui";

interface IProps {
    item: Spintr.ICompanyQueryItem;

    onDelete(id: number): void;
    onRestore(id: number): void;
}

const CompanyRowActions: FunctionComponent<IProps> = ({ item, onDelete, onRestore }) => {
    const history = useHistory();

    const itemId = item?.id;
    const deleted = item?.deleted;
    const officeCount = item?.officeCount || 0;

    const onEditClick = useCallback(
        () => history.push(
            `/admin/organisation/${itemId}/edit`,
        ),
        [history, itemId]
    );

    const onRestoreClick = useCallback(
        () => onRestore(itemId),
        [onRestore, itemId],
    );

    const onDeleteClick = useCallback(
        () => {
            if (officeCount > 0) {
                alert(
                    localize(
                        "DetGarBaraAttTaBortForetagSomInteHarNagraKontor"
                    )
                );
                return;
            }

            onDelete(itemId);
        },
        [onDelete, itemId, officeCount],
    );

    const categories = useMemo(
        () => {
            const items = [{
                onClick: onEditClick,
                text: localize("Redigera"),
            }];

            items.push({
                onClick: deleted ? onRestoreClick : onDeleteClick,
                text: deleted
                    ? localize("Aterstall")
                    : localize("TaBort")
            })

            return [{
                items,
            }];
        },
        [onEditClick, deleted, onRestoreClick, onDeleteClick],
    );

    return (
        <ActionMenu
            categories={categories}
        />
    );
};

export default CompanyRowActions;