import { Checkbox, DefaultButton, Dropdown, IDropdownOption, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import api from 'src/spintr/SpintrApi';
import { IApplicationState } from 'src/spintr/reducer';
import { PageHeader } from 'src/ui';
import { FormControl, FormFooterBar } from 'src/ui/components/Forms';
import SpintrLoader from 'src/ui/components/Loader';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import { scrollToTop, validateRequiredTextField } from 'src/utils';
import { Breadcrumbs } from '../../ui/components';
import { MandatoryText } from '../../ui/components/Forms';
import { renderAdminTitle } from '../common/Title';

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
    isModal?: boolean;
    onCancel?: any;
    postSave?: any;
    enablePersonalRSS: boolean;
    enableCompanyLevel?: boolean | undefined;
}

interface IState {
    isNew: boolean;
    isLoading: boolean;
    rss: any;
    saveError: string[];
    listDepartments: any[];
    enableFieldValidation?: boolean;
}

class AdminRssEditView extends Component<IProps, IState> {
    private isNew = false;
    private isUser = this.props.isAdmin || this.props.isEditor ? false : true;

    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            isLoading: true,
            saveError: [],
            listDepartments: [],
            rss: {
                address: "",
                deleted: false,
                import: true,
                forceSubscription: false,
                id: 0,
                isBroken: false,
                isPersonal: false,
                isdraft: false,
                name: "",
                published: false,
                sortedBy: 0,
                targetAudience: 0,
                userName: "",
            },
        };
    }

    initPage(rss) {
        this.setState({
            isNew: rss.isNew,
            isLoading: rss.isLoading,
            rss: {
                address: rss.address,
                deleted: rss.deleted,
                import: rss.import,
                forceSubscription: rss.forceSubscription,
                id: rss.id,
                isBroken: rss.isBroken,
                isPersonal: rss.isPersonal,
                isdraft: rss.isdraft,
                name: rss.name,
                published: rss.published,
                sortedBy: rss.sortedBy,
                targetAudience: rss.targetAudience,
                userName: rss.userName,
            },
            saveError: [],
        });
    }

    async loadDepartments(): Promise<void> {
        const params: any = {
            take: 9999,
        };

        try {
            const pagesResponse = await api.get("/api/departments", { params, });

            this.setState({
                listDepartments: pagesResponse.data,
            });
        } catch (_) {
            return;
        }
    }

    componentDidMount() {
        let pageId;
        if (window.location.href.indexOf("admin/rss/create") > -1 || this.props.isModal) {
            this.isNew = true;
        } else {
            this.isNew = false;
            pageId = window.location.href.split("/").reverse()[1];
        }

        this.loadDepartments();

        if (this.isNew) {
            this.initPage({
                isNew: true,
                isLoading: false,
                address: "",
                deleted: false,
                import: true,
                forceSubscription: true,
                id: undefined,
                isBroken: false,
                isPersonal: false,
                isdraft: false,
                name: "",
                published: true,
                sortedBy: 0,
                targetAudience: 0,
                userName: "",
            });
        } else {
            let fetchPage = () => {
                api.get("/api/rss/" + pageId).then((response) => {
                    this.setState({ isNew: false });
                    this.initPage(response.data);
                });
            };

            fetchPage();
        }
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    async onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, async () => {
            let { rss } = this.state;
            let saveErrors = [];
            let result;

            if (!rss.name) {
                saveErrors.push("DuMasteFyllaIEttNamn");
            }

            if (rss.address) {
                await api.post("/api/rss/isvalid", { url: rss.address }).then((response) => {
                    result = response.data.Result;
                });
            }

            if (!result) {
                saveErrors.push("GickEjLasaRSS");
            }

            if (saveErrors.length > 0) {
                this.setState(
                    {
                        saveError: saveErrors,
                        isLoading: false
                    },
                    () => {
                        scrollToTop();
                    }
                );
                return;
            }

            if (rss.isPersonal) {
                rss.department = null;
                rss.published = true;
                rss.targetAudience = this.props.currentUserId;
            }

            if (rss.id) {
                api.put("/api/rss/" + rss.id, rss);
            } else {
                api
                    .post("/api/rss/", rss)
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

            if (this.props.isModal) {
                this.props.postSave();
            } else {
                this.props.history.goBack();
            }
        });
    }

    formatDepartment(value: any): IDropdownOption {
        return {
            key: value.id,
            text: /*this.getOfficeName(value) + " > " +*/ value.name,
        };
    }

    getOfficeName(department: any): string {
        return this.props.enableCompanyLevel
            ? `${department.companyName} > ${department.officeName}`
            : department.officeName;
    }

    getDepartmentDropdownOptions(): IDropdownOption[] {
        const officeNames = this.state.listDepartments
            .reduce<string[]>(
                (list, value) => list.includes(this.getOfficeName(value))
                    ? list
                    : [...list, this.getOfficeName(value)],
                [],
            )
            .sort((a, b) => a > b ? 1 : -1);

        return [{ key: 0, text: localize("Alla") }, ...officeNames.flatMap<IDropdownOption>((officeName) => {
            const departments = this.state.listDepartments
                .filter((department) => this.getOfficeName(department) === officeName)
                .map((department) => this.formatDepartment(department));

            const header: IDropdownOption = {
                key: officeName,
                text: officeName,
                itemType: 2,
            };

            const divider: IDropdownOption = {
                key: "divider" + officeName,
                text: "-",
                itemType: 1,
            };

            return [header, ...departments];
        })];
    }

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        let adminNewsEditView = false;
        if (window.location.href.indexOf("admin") > -1) {
            adminNewsEditView = true;
        }
        const infoText = localize("RSSFeedInfo");
        const rss = this.state.rss;

        const departments = this.getDepartmentDropdownOptions();

        return (
            <div className={classnames("AdminRssEditView page-margin-bottom", {
                "isModal": this.props.isModal
            })}>
                {renderAdminTitle("RssFloden")}
                {
                    !this.props.isModal && (
                        <Breadcrumbs useGlobalBreadcrumbs
                            displayInstance
                            items={[
                                ...(adminNewsEditView ? [{
                                    text: localize("Administration"),
                                    key: 2,
                                    link: "/admin",
                                }] : []),
                                {
                                    text: localize("appRSS"),
                                    key: 3,
                                    link: "/admin/rss",
                                },
                                {
                                    text: this.isNew ? localize("LaggTillNyFeed") : localize("RedigeraRSS"),
                                    key: 4,
                                    link: window.location.pathname,
                                },
                            ]}
                        />
                    )
                }
                {this.props.isModal ?
                    <div>
                        <MandatoryText />
                    </div>
                    :
                    <PageHeader
                        title={this.isNew ? localize("LaggTillNyFeed") : localize("RedigeraRSS")}
                        subText={infoText}
                        useShowMoreSubText
                        displayMandatoryText />
                }
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <form>
                    <FormControl>
                        <TextField
                            className="textField"
                            label={localize("Namn")}
                            value={rss.name}
                            onChange={(ev, val) => {
                                this.setState((prevState) => ({
                                    rss: {
                                        ...prevState.rss,
                                        name: val,
                                    },
                                }));
                            }}
                            maxLength={64}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    {!this.isUser && (
                        <>
                            {rss.isPersonal ? (
                                <FormControl>
                                    <TextField
                                        className="textField"
                                        label={localize("Anvandarnamn")}
                                        value={rss.userName}
                                        disabled
                                    />
                                </FormControl>
                            ) : (
                                <FormControl>
                                    <Dropdown
                                        label={localize("RiktatForAvdelning")}
                                        selectedKey={rss.targetAudience}
                                        onChange={(_e, v) => {
                                            this.setState((state) => ({
                                                rss: {
                                                    ...state.rss,
                                                    targetAudience: Number(v.key),
                                                },
                                            }));
                                        }}
                                        options={departments}
                                    />
                                </FormControl>
                            )}
                            <FormControl>
                                <Dropdown
                                    label={localize("Sortering")}
                                    selectedKey={rss.sortedBy}
                                    onChange={(_e, v) => {
                                        this.setState((state) => ({
                                            rss: {
                                                ...state.rss,
                                                sortedBy: Number(v.key),
                                            },
                                        }));
                                    }}
                                    options={[
                                        { key: 0, text: localize("NyasteForst") },
                                        { key: 1, text: localize("NarmasteForst") },
                                    ]}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                        </>
                    )}
                    <FormControl>
                        <TextField
                            className="textField"
                            label={"Url"}
                            value={rss.address}
                            onChange={(ev, val) => {
                                this.setState((prevState) => ({
                                    rss: {
                                        ...prevState.rss,
                                        address: val,
                                    },
                                }));
                            }}
                            maxLength={256}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    {this.props.enablePersonalRSS && !this.isUser && (
                        <FormControl>
                            <Checkbox
                                label={localize("Personlig")}
                                checked={rss.isPersonal}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                    this.setState({
                                        rss: {
                                            ...this.state.rss,
                                            isPersonal: checked,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    )}
                    {!rss.isPersonal && !this.isUser && (
                        <>
                            <FormControl>
                                <Checkbox
                                    label={localize("Publicerad")}
                                    checked={rss.published}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                        this.setState({
                                            rss: {
                                                ...this.state.rss,
                                                published: checked,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <Checkbox
                                    label={localize("TvingaPrenumeration")}
                                    checked={rss.forceSubscription}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                        this.setState({
                                            rss: {
                                                ...this.state.rss,
                                                forceSubscription: checked,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>

                            {this.isNew ? (
                                <FormControl>
                                    <Checkbox
                                        label={localize("LasInDomSenaste15Posterna")}
                                        checked={rss.import}
                                        onChange={(
                                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                            checked?: boolean
                                        ) => {
                                            this.setState({
                                                rss: {
                                                    ...this.state.rss,
                                                    import: checked,
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                            ) : null}

                        </>

                    )}
                </form>
                {this.props.isModal ? (
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>

                        <DefaultButton onClick={this.props.onCancel.bind(this)}>{localize("Avbryt")}</DefaultButton>
                        <PrimaryButton onClick={this.onSaveClick.bind(this)}>{localize("Spara")}</PrimaryButton>

                    </Stack>
                ) : (
                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize("Spara")}
                    />
                )}

            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        enablePersonalRSS: state.instance.get("enablePersonalRSS"),
        enableCompanyLevel: state.instance.get("enableCompanyLevel"),
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
    };
};

export default withRouter(connect(mapStateToProps)(AdminRssEditView));
