import React, { Component } from 'react'
import { Label } from 'src/ui'
import { capitalizeFirstLetter } from 'src/utils'
import "./CategoryButton.scss"

interface IProps {
    label: string;
    id?: number;
}

class CategoryButton extends Component<IProps> {
    render() {
        return (
            <div className="CategoryButton">
                <Label size="body-2" className="label">{capitalizeFirstLetter(this.props.label)}</Label>
            </div>
        )
    }
}

export default CategoryButton
