import React, { Component, ReactNode } from "react";
import { Label } from "src/ui";
import TranslateButton from "src/ui/components/TranslateButton";
import { formatInteractiveText } from "src/utils";
import Bookmark from "./Bookmark";
import "./SocialBookmark.scss";

interface IProps {
    post: Spintr.ISocialStatusBookmark;
}

interface IState {
    faviconFailed: boolean;
    postText: string;
}

class SocialBookmark extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            faviconFailed: false,
            postText: this.props.post.Text
        }
    }

    protected translate = (text: string): void => {
        this.setState({ postText: text })
    }
    
    public render() : ReactNode {
        let node: ReactNode = <div />;

        node = this.renderBookmark();

        return (
            <div className="SocialBookmark">
                {node}
            </div>
        );
    }

    protected renderBookmark(): ReactNode {
        const { post } = this.props;

        return (
            <Bookmark
                text={post.Text}
                bookmarkImage={post.BookmarkImage}
                bookmarkDescription={post.BookmarkDescription}
                bookmarkTitle={post.BookmarkTitle}
                bookmarkType={post.BookmarkType}
                bookmarkUrl={post.BookmarkUrl} />
        )
    }
}

export default SocialBookmark;