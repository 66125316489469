import { Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { localize } from "src/l10n";
import { ActionMenu, UnstyledButton } from "src/ui";
import { Label } from "src/ui/components/Label/Label";
import SpintrLoader from "src/ui/components/Loader";
import { fetchNotifications, markAllNotificationsAsRead, markNotificationsAsRead } from "../actions";
import NotificationItem from "./NotificationItem";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./NotificationList.scss";

interface IStateProps {
    hasMore: boolean;
    isLoading: boolean;
    notifications: Spintr.INotification[];
    sidebarExpanded?: boolean;
    hasFetched?: boolean;
    instanceName: string;
}

interface IOwnProps {
    isDisplayingO365Footer?: boolean;
    pageSize?: number;
    fullscreen?: boolean;
}

type Props = IOwnProps & IStateProps & DispatchProp;

class NotificationList extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
    }

    public componentDidMount(): void {
        this.props.dispatch(markNotificationsAsRead());

        if (!this.props.hasFetched) {
            this.dispatchFetch();
        }
    }

    public dispatchFetch() {
        const params = {
            imageSize: 50,
            latestOnly: false,
            skip: 0,
            take: this.props.pageSize || 20,
        };

        if (this.props.notifications.length) {
            params.skip = this.props.notifications.length
        }

        this.props.dispatch(fetchNotifications(params));
    }

    public loadMore() {
        if (this.props.isLoading || !this.props.hasMore) {
            return;
        }

        this.dispatchFetch();
    }

    public render(): ReactNode {
        return (
            <div>
                <InfiniteScroll
                    dataLength={this.props.notifications.length}
                    next={this.loadMore.bind(this)}
                    hasMore={this.props.hasMore}
                    loader={<SpintrLoader />}
                    height={this.props.fullscreen ? undefined : 400}>
                    {
                        (!this.props.notifications || this.props.notifications.length === 0) && this.props.isLoading && (
                            <SpintrLoader />
                        )
                    }
                    {
                        (!this.props.notifications || this.props.notifications.length === 0) && !this.props.isLoading && !this.props.hasMore && (
                            <div className="empty-sidebar-list">
                                <div className="icon">
                                    <Visage2Icon icon="coffee" />
                                </div>
                                <div className="content">
                                    <Label role="text" size="body-2">
                                        <span>
                                            {localize("NOTIFCATIONS_EMPTY_PLACEHOLDER_2").replace("{0}", this.props.instanceName)}
                                        </span>
                                    </Label>
                                </div>
                            </div>
                        )
                    }
                    {this.props.notifications.map(this.renderNotificationItem, this)}
                </InfiniteScroll>
            </div>
        )
    }

    protected renderNotificationItem(notification: Spintr.INotification): ReactNode {
        const key = `n:ns:ni:${notification.id}`;

        return (
            <div key={key}>
                <NotificationItem notification={notification} />
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state) => ({
        hasMore: state.notification.hasMore,
        isLoading: state.notification.isLoading,
        notifications: state.notification.items,
        sidebarExpanded: state.ui.sidebarExpanded,
        hasFetched: state.notification.hasFetched,
        instanceName: state.instance.get("name"),
    });

export default connect(mapStateToProps)(NotificationList);
