import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const GroupMemberAcceptedInviteNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IGroupMemberAcceptedInviteNotification;
    if (!notification) {
        return null;
    }

    return (
        <Fragment>
            {
                notification.userName 
                + " " 
                + localize("ArNuMedlemIGruppen").toLowerCase()
                + " "
            }
            <Link to={notification.url}>
                {notification.groupName}
            </Link>
        </Fragment>
    );
};

export default GroupMemberAcceptedInviteNotification;