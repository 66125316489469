import classNames from "classnames";
import React, { FunctionComponent, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { UnstyledButton } from "src/ui";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import { StartPageBuilderDrawer } from "../StartPageBuilderDrawer";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

export type DrawerToggleHandler = (isOpen: boolean) => void;

interface IProps {
    drawerIsOpen?: boolean;
    onDrawerToggle: DrawerToggleHandler;
    onModeChange(mode: SpintrTypes.StartPageBuilderMode): void;
    onSaveClick(): void;
}

type DrawerToggleButton = -1;
type HoverTarget = undefined | DrawerToggleButton | SpintrTypes.StartPageBuilderMode;

const DrawerToggleButtonValue: DrawerToggleButton = -1;

const StartPageBuilderMenu: FunctionComponent<IProps> = (props) => {
    const { drawerIsOpen, onDrawerToggle, onModeChange, onSaveClick } = props;

    const onDrawerClick = useCallback(
        () => {
            onDrawerToggle(!drawerIsOpen);

            if (drawerIsOpen) {
                return;
            }

            onModeChange(SpintrTypes.StartPageBuilderMode.Edit);
            mixpanelTrack(MixPanelEvents.Dynamo_OpenDrawer);
        },
        [drawerIsOpen, onDrawerToggle, onModeChange],
    );

    const activeBuilderMode = useSelector<Spintr.AppState, SpintrTypes.StartPageBuilderMode>(
        (appState) => appState.ui.startPageBuilderMode
    );

    const modeMenuItems = [
        {
            className: "edit-button",
            icon: "Edit",
            onClickData: SpintrTypes.StartPageBuilderMode.Edit,
        },
        {
            className: "desktop-preview-button",
            icon: "Desktop",
            iconStyle: "Custom",
            onClickData: SpintrTypes.StartPageBuilderMode.DesktopPreview,
        },
        {
            className: "responsive-preview-button",
            icon: "Phone",
            iconStyle: "Custom",
            onClickData: SpintrTypes.StartPageBuilderMode.ResponsivePreview,
        },
    ];

    const [hoveringOver, setIsHoveringOver] = useState<HoverTarget>();

    return (
        <div id="StartPageBuilderMenu">
            <div className="menu">
                <ul className="menu-section">
                    <li className="menu-item">
                        <UnstyledButton
                            className={classNames("drawer-button", {
                                active: drawerIsOpen || hoveringOver == DrawerToggleButtonValue
                            })}
                            onClick={onDrawerClick}
                            title={localize("DYNAMO_DRAWER_TITLE")}
                            ariaLabel={localize("DYNAMO_DRAWER_TITLE")}
                            onMouseEnter={() => setIsHoveringOver(DrawerToggleButtonValue)}
                            onMouseLeave={() => setIsHoveringOver(undefined)}
                        >
                            <Visage2Icon
                                icon="add"
                                color={drawerIsOpen || hoveringOver == DrawerToggleButtonValue ? "neutralBlue" : "dark-grey"}
                            />
                        </UnstyledButton>
                    </li>
                    {/*
                    <li className="menu-item">
                        <UnstyledButton onClick={onSaveClick}>
                            <Visage2Icon icon="save" />
                        </UnstyledButton>
                    </li>
                    */}
                </ul>
                <ul className="menu-section modes">
                    {modeMenuItems.map((menuItem) => {
                        const isActive = activeBuilderMode === menuItem.onClickData || hoveringOver === menuItem.onClickData;

                        return (
                            <li className="menu-item" key={menuItem.icon}>
                                <UnstyledButton
                                    className={classNames(menuItem.className, { active: isActive })}
                                    onClick={onModeChange}
                                    onClickData={menuItem.onClickData}
                                    onMouseEnter={() => setIsHoveringOver(menuItem.onClickData)}
                                    onMouseLeave={() => setIsHoveringOver(undefined)}
                                >
                                    <Visage2Icon
                                        icon={menuItem.icon}
                                        color={isActive ? "neutralBlue" : "dark-grey"}
                                    />
                                </UnstyledButton>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <StartPageBuilderDrawer hidden={!drawerIsOpen} />
        </div>
    );
};

export default StartPageBuilderMenu;
