import { Modal, Stack } from "@fluentui/react";
import axios from "axios";
import React, { FunctionComponent, useEffect, useState } from "react";

import { localize } from "src/l10n";
import TeaserBox from "src/teaser-box/components/TeaserBox";
import { SpintrTypes } from "src/typings";
import { Loader } from "src/ui";

import PopupHeader from "./PopupHeader";
import "./TeaserBoxSelectionPopup.scss";
import { icons } from "src/admin/views/AdminTeaserboxItems";

interface IProps {
    isShowing: boolean;
    onDismiss: () => void;
    onSelected: any;
    hideDataWidgets?: boolean;
}

const getIcon = (name) => {
    if (!name) {
        return "";
    }

    const icon = icons.find(
        (obj) => obj.key === name || obj.text === name || obj.key === "fa-" + name || obj.text === "fa-" + name
    );

    return icon.visage2Icon;
};

const TeaserBoxSelectionPopup: FunctionComponent<IProps> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get("/api/teaserbox", { params: { take: 1000, hideDeleted: true, hideTextPage: false, hideDataWidgets: props.hideDataWidgets } })
            .then((response) => {
                setData(response.data.teaserBoxes.filter((t) => t.type != 5));
                setIsLoading(false);
            });
    }, []);

    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            containerClassName="TeaserBoxSelectionPopup-container"
            isBlocking={false}
            isOpen={props.isShowing}
            onDismiss={props.onDismiss}
        >
            <PopupHeader
                text={localize("TEASER_BOX_SELECT_BOX")}
                onClose={props.onDismiss} />
            <div className="TeaserBoxSelectionPopup popup-inner">
                <div className="TeaserBoxLibrary">
                    {isLoading && <Loader />}

                    {!isLoading && data && data.length === 0 && (
                        <div className="empty-library">{localize("TEASERBOX_LIBRARY_EMPTY")}</div>
                    )}

                    {data && data.length > 0 && (
                        <Stack className="teaserbox-list" horizontal wrap tokens={{ childrenGap: 24 }}>
                            {data
                                .map((obj) => {
                                    if (obj.type === SpintrTypes.TeaserBoxType.Iframe) {
                                        obj.text = `${obj.url} (iframe)`;
                                    } else if (obj.type === SpintrTypes.TeaserBoxType.Blog) {
                                        obj.text = `${obj.text} (${localize("Blogg").toLowerCase()})`;
                                    }

                                    return (
                                        <TeaserBox
                                            onClick={() => { props.onSelected(obj) }}
                                            key={obj.id}
                                            item={obj}
                                            showBorder
                                            isSelectable
                                        />
                                    );
                                })}
                        </Stack>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default TeaserBoxSelectionPopup;
