import { AxiosRequestConfig, CancelToken } from "axios";
import SpintrAPI from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/PlannerItems";
const categoriesBaseUrl = "/api/v1/PlannerCategories";

export type QueryPlannerItemsResponse
    = Spintr.QueryEnvelope<Spintr.PlannerItem>;

export type PlannerQueryParams = {
    plannerOwnerId?:   number | undefined;

    skip?:             number | undefined;
    take?:             number | undefined;

    orderByColumn?:    string | undefined;
    orderAscending?:   boolean | undefined;

    search?:           string | undefined;
    status?:           Spintr.ContentStatus | undefined;
    before?:           Date | undefined;
    after?:            Date | undefined;
};

export const defaultPlannerQueryParams: PlannerQueryParams = {
    plannerOwnerId: 0,

    skip: 0,
    take: 100,

    orderByColumn: "startsAt",
    orderAscending: false,
};

export async function queryPlannerItemsAsync(
    params?:         PlannerQueryParams | undefined,
    cancelToken?:   CancelToken | undefined,
): Promise<QueryPlannerItemsResponse> {
    const config: AxiosRequestConfig = {
        cancelToken,
        params: params ?? defaultPlannerQueryParams,
    };

    const response = await SpintrAPI.get<QueryPlannerItemsResponse>(
        baseUrl,
        config,
    );

    return {
        totalCount: response.data.totalCount,
        items: response.data.items.map((item) => ({
            ...item,
            startsAt: new Date(item.startsAt),
            endsAt: new Date(item.endsAt),
        }))
    };
}

export async function getPlannerItemAsync(
    id:             number,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItem> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.get<Spintr.PlannerItem>(
        `${baseUrl}/${id}`,
        config,
    );

    return {
        ...response.data,
        startsAt: new Date(response.data.startsAt),
        endsAt: new Date(response.data.endsAt),
    };
}

export type CreatePlannerItemModel
    = Omit<
        Spintr.PlannerItem,
        "id" | "instanceId" | "files" | "assignees" | "targetedTo" |
        "approvedBy" | "plannerOwner" |
        "createdAt" | "editedAt"  | "createdBy" | "editedBy" |
        "participants" | "category"
    > & {
        approvedBy?:    number | undefined
        plannerOwner?:  number | undefined
        assignees:      number[];
        targetedTo:     number[];
        files?:         undefined; // just keeping it here cause I don't want to risk sending all the files
        participants?:  number[] | undefined;
    };

export async function createPlannerItemAsync(
    model:          CreatePlannerItemModel,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItem> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.post<Spintr.PlannerItem>(
        baseUrl,
        model,
        config,
    );

    return response.data;
}

export async function updatePlannerItemAsync(
    id:             number,
    model:          CreatePlannerItemModel,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItem> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.put<Spintr.PlannerItem>(
        `${baseUrl}/${id}`,
        model,
        config,
    );

    return response.data;
}

export async function deletePlannerItemAsync(
    id:             number,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItem> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.delete<Spintr.PlannerItem>(
        `${baseUrl}/${id}`,
        config,
    );

    return response.data;
}

type PlannerItemFileUpload = {
    file: File;
    fieldType: number;
    itemType: Spintr.PlannerItemType;
}

export async function uploadPlannerFileAsync(
    plannerItemId:  number,
    model:          PlannerItemFileUpload,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItemFile> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const body = new FormData();
    body.append("fieldType", model.fieldType.toString());
    body.append("itemType", model.itemType.toString());
    body.append("file", model.file);

    const response = await SpintrAPI.post<Spintr.PlannerItemFile>(
        `${baseUrl}/${plannerItemId}/files`,
        body,
        config,
    );

    return response.data;
}

export type QueryPlannerCategoriesResponse
    = Spintr.QueryEnvelope<Spintr.PlannerItemCategory>;

export type PlannerCategoriesQueryParams = {
    skip?:             number | undefined;
    take?:             number | undefined;

    orderByColumn?:    string | undefined;
    orderAscending?:   boolean | undefined;

    search?:           string | undefined;
    status?:           Spintr.ContentStatus | undefined;
};

export async function queryPlannerCategoriesAsync(
    params?:        PlannerCategoriesQueryParams | undefined,
    cancelToken?:   CancelToken | undefined,
): Promise<QueryPlannerCategoriesResponse> {
    const config: AxiosRequestConfig = {
        params,
        cancelToken,
    };

    const response = await SpintrAPI.get<QueryPlannerCategoriesResponse>(
        categoriesBaseUrl,
        config,
    );

    return response.data;
}

export async function getPlannerCategoryAsync(
    id:             number,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItemCategory> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.get<Spintr.PlannerItemCategory>(
        `${categoriesBaseUrl}/${id}`,
        config,
    );

    return response.data;
}

export type PlannerCategoryInputModel = {
    name: string;
    status: Spintr.ContentStatus;
};

export async function createPlannerCategoryAsync(
    model:          PlannerCategoryInputModel,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItemCategory> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.post<Spintr.PlannerItemCategory>(
        categoriesBaseUrl,
        model,
        config,
    );

    return response.data;
}

export async function updatePlannerCategoryAsync(
    id:             Spintr.PlannerItemCategory["id"],
    model:          PlannerCategoryInputModel,
    cancelToken?:   CancelToken | undefined,
): Promise<Spintr.PlannerItemCategory> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    const response = await SpintrAPI.put<Spintr.PlannerItemCategory>(
        `${categoriesBaseUrl}/${id}`,
        model,
        config,
    );

    return response.data;
}

export async function deletePlannerCategoryAsync(
    id:             number,
    cancelToken?:   CancelToken | undefined,
): Promise<void> {
    const config: AxiosRequestConfig = {
        cancelToken,
    };

    await SpintrAPI.delete<void>(
        `${categoriesBaseUrl}/${id}`,
        config,
    );
}
