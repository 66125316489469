import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { RouteChildrenProps, withRouter } from "react-router";
import { SpintrTypes } from "src/typings";
import SjWidgetModal from "../SjWidgetModal";
import TeaserBox from "./TeaserBox";
import "./TeaserBoxContainer.scss";

interface ITeaserBoxContainerProps extends RouteChildrenProps {
    items: Spintr.ITeaserBoxItem[];
    isLoading?: boolean;
}

interface IState {
    showSjWidgetModal: boolean;
    sjWidgetCode: string;
}

class TeaserBoxContainer extends Component<ITeaserBoxContainerProps, IState> {
    state = {
        showSjWidgetModal: false,
        sjWidgetCode: ""
    };

    getCustomElements1() {
        return (
            <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                    { type: ShimmerElementType.line, width: '50px', height: 10, verticalAlign: "center" },
                ]}
            />
        );
    }

    private renderShimmerLoader = () => {
        return [...Array(3)].map((index) => {
            return (
                <div key={index} className="TeaserBoxWrapper">
                    <div className="teaser-box" style={{ width: "100%", height: 125, flexGrow: 1, textAlign: "center", backgroundColor: "white", alignContent: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Shimmer
                            style={{ width: "30%" }}
                            className="ShimmerLoader"
                            customElementsGroup={this.getCustomElements1()}
                        />
                    </div>
                </div>
            );
        });
    }

    public render(): ReactNode {
        let blogTeaserboxes = this.props.items.filter((d) => {
            return d.blog !== undefined;
        });

        let normalTeaserboxes = this.props.items.filter((d) => {
            return d.blog === undefined;
        });

        return (
            <div className="all-teaserboxes" tabIndex={0}>
                {this.state.showSjWidgetModal && <SjWidgetModal micrositeId={this.state.sjWidgetCode} onDismiss={() => { this.setState({ showSjWidgetModal: false }) }} />}
                {
                    !!normalTeaserboxes && normalTeaserboxes.length > 0 && (
                        <div className={"teaser-box-container teaser-box-count-" + normalTeaserboxes.length}>
                            {this.props.isLoading && this.renderShimmerLoader()}
                            {!this.props.isLoading && normalTeaserboxes.map((teaserBox) => this.renderItem(teaserBox))}
                        </div>
                    )
                }
                {
                    blogTeaserboxes && blogTeaserboxes.length > 0 && (
                        <div className={"teaser-box-container blogs teaser-box-count-" + blogTeaserboxes.length}>
                            {blogTeaserboxes.map((teaserBox, idx) => {

                                return this.renderItem(teaserBox);
                            })}
                        </div>
                    )
                }
            </div>
        );
    }

    renderItem = (item: Spintr.ITeaserBoxItem) => {
        return (
            <div
                className={"teaser-box-wrapper"}
                key={item.id}>
                <TeaserBox
                    item={item}
                    onClick={
                        item.type === SpintrTypes.TeaserBoxType.SjWidget
                            ? () => {
                                  this.setState({ showSjWidgetModal: true, sjWidgetCode: item.sjWidgetCode });
                              }
                            : undefined
                    }
                />
            </div>
        );
    }
}

export default withRouter(TeaserBoxContainer);
