import React, { Component, ReactNode } from 'react';
import { localizeFormat } from '../../l10n';

interface ILocalizedStringProps {
    className?: string;
    text: string;
    args?: any[];
}

class LocalizedString extends Component<ILocalizedStringProps> {
    public render() : ReactNode {
        const html = {
            __html: localizeFormat(this.props.text, this.props.args)
        };

        return (
            <span 
                className={this.props.className}
                dangerouslySetInnerHTML={html}
            />
        )
    }
}

export default LocalizedString

