import { ActionButton } from "@fluentui/react";
import React, { FunctionComponent, useCallback } from "react";

import { localize } from "src/l10n";
import { Label } from "src/ui";
import {
    CreateConfigurationClickHandler,
    DeleteConfigurationClickHandler,
    EditConfigurationClickHandler,
    LibraryConfigurationClickHandler,
} from "./WidgetLibrary.types";
import WidgetLibraryConfiguration from "./WidgetLibraryConfiguration";

interface IProps {
    entry: Spintr.IMarketplaceLibraryWrapper;
    onConfigurationClicked: LibraryConfigurationClickHandler;
    onCreateConfigurationClicked: CreateConfigurationClickHandler;
    onEditConfigurationClicked?: EditConfigurationClickHandler;
    onDeleteClick?: DeleteConfigurationClickHandler;
    showWidgetOptions?: boolean;
}

const WidgetLibraryAppCategory: FunctionComponent<IProps> = ({
    entry, onConfigurationClicked, onCreateConfigurationClicked,
    onDeleteClick, onEditConfigurationClicked, showWidgetOptions
}) => {
        const onCreateClick = useCallback(
            () => onCreateConfigurationClicked(entry.app),
            [entry, onCreateConfigurationClicked]
        );

        return (
            <div className="WidgetLibraryAppCategory">
                <div className="heading">
                    <Label
                        className="text"
                        size="h3"
                    >
                        {entry.app.name}
                    </Label>
                    {onCreateConfigurationClicked && (
                        <ActionButton
                            className="create-button"
                            iconProps={{ iconName: "AddTo" }}
                            onClick={onCreateClick}
                            text={localize("SkapaNy")}
                        />
                    )}
                </div>
                <div className="widget-list">
                    {entry.widgets.length === 0 && (
                        <div className="empty-list">
                            <Label
                                as="p"
                                className="text"
                                color="dark-grey"
                                size="body-2"
                            >
                                {localize("MARKETPLACE_NO_CONFIGURATIONS")
                                    .split(/(\{\{.+\}\})/gmi)
                                    .map((str, i) => str.indexOf("{{") !== 0
                                        ? str
                                        : (
                                            <a
                                                key={i}
                                                onClick={onCreateClick}
                                            >
                                                {str.replace("{{", "").replace("}}", "")}
                                            </a>)
                                        )
                                }
                            </Label>
                        </div>
                    )}
                    {entry.widgets.map(widget => (
                        <WidgetLibraryConfiguration
                            key={widget.id}
                            configuration={widget}
                            onDeleteClick={onDeleteClick}
                            onEditConfigurationClicked={onEditConfigurationClicked}
                            onConfigurationClicked={onConfigurationClicked}
                            showOptions={showWidgetOptions}
                        />
                    ))}
                </div>
            </div>
        );
    };

export default WidgetLibraryAppCategory;
