import React, { Component } from "react";
import { localize } from "src/l10n";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./PopupHeader.scss";
import { Icon } from "@fluentui/react";

interface IProps {
    text: string;
    subText?: string;
    onClose?: any;
    onBackClick?: any;
    hideCloseButton?: boolean;
    displayBackButton?: boolean;
}

class PopupHeader extends Component<IProps> {
    render() {
        return (
            <div className="PopupHeader">
                <div className="top-row">
                    {this.props.displayBackButton && (
                        <UnstyledButton
                            className="back-button"
                            onClick={this.props.onBackClick}
                        >
                            <Visage2Icon icon="arrow-cricle-right" />
                        </UnstyledButton>
                    )}
                    <Label
                        size="h7"
                        color="visageGray"
                        weight="medium">
                        {this.props.text}
                    </Label>
                    {
                        !this.props.hideCloseButton && (
                            <UnstyledButton
                                className="close-button"
                                title={localize("Stang")}
                                onClick={() => {
                                    if (this.props.onClose) {
                                        this.props.onClose();
                                    }
                                }}
                            >
                                <Icon iconName="ChromeClose" />
                            </UnstyledButton>
                        )
                    }
                </div>
                {!!this.props.subText && (
                    <Label
                        className="sub-text"
                        size="body-2"
                        color="mid-grey">
                        {this.props.subText}
                    </Label>
                )}
            </div>
        )
    }
}

export default PopupHeader;
