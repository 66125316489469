import React, { useCallback, useEffect, useState } from "react";
import "./PayrollView.scss";
import { Dropdown } from "@fluentui/react";
import { localize } from "src/l10n";
import { FormControl, FormSection } from "src/ui/components/Forms";
import { connect } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import { AxiosError, AxiosResponse } from "axios";
import { Label, Loader } from "src/ui";
import PayrollHomeSection from "./PayrollHomeSection";
import moment from "moment";

interface IProps {
    currentUser?: IActiveUserProfile;
    categories: any[];
    offices: any[];
}

interface IState {
    selectedCategoryId: number,
    selectedOfficeId: number,
    selectedIntervalId: number,
    data: any,
    isLoading: boolean,
    translations: any,
    intervals: any[]
}

const PayrollHome = (props: IProps) => {
    const [state, setState] = useState<IState>({
        selectedCategoryId: 0,
        selectedOfficeId: 0,
        selectedIntervalId: 3,
        data: undefined,
        isLoading: false,
        translations: {
            "SEX": localize("SEX") + " (" + localize("SHARE_OF_WOMEN").toLowerCase() + ")",
            "AGE": localize("AGE") + " (" + localize("AVERAGE").toLowerCase() + ")",
            "INDUSTRY_EXPERIENCE": localize("INDUSTRY_EXPERIENCE") + " (" + localize("AVERAGE").toLowerCase() + ")",
            "SALARY": localize("SUM_ALL_SALARIES") + " (" + localize("AVERAGE").toLowerCase() + ")",
            "Kategori": localize("Kategori"),
            "Alla": localize("Alla"),
            "Kontor": localize("Kontor"),
            "Intervall": localize("Intervall"),
            "PAYROLL_ENTRY_COUNT_ERROR": localize("PAYROLL_ENTRY_COUNT_ERROR")
        },
        intervals: [{
            id: 1,
            name: localize("TIME_UNIT_MONTH")
        }, {
            id: 2,
            name: localize("TIME_UNIT_QUARTER")
        }, {
            id: 3,
            name: localize("TIME_UNIT_YEAR")
        }]
    });

    const fetchData = useCallback(() => {
        setState(s => ({
            ...s,
            displayEntryCountError: false,
            isLoading: true
        }));

        api.get("/api/v1/payroll/statistics", {
            params: {
                categoryId: state.selectedCategoryId > 0 ? state.selectedCategoryId : undefined,
                officeId: state.selectedOfficeId > 0 ? state.selectedOfficeId : undefined,
                intervalId: state.selectedIntervalId > 0 ? state.selectedIntervalId : undefined
            },
        }).then((response: AxiosResponse) => {
            setState(s => ({
                ...s,
                data: response.data,
                isLoading: false
            }));
        }).catch(() => {});
    }, [state.selectedCategoryId, state.selectedOfficeId, state.selectedIntervalId]);

    useEffect(() => {
        fetchData();
    }, [state.selectedCategoryId, state.selectedOfficeId, state.selectedIntervalId]);

    const formatData = (dataSet: any) => {
        return {
            labels: dataSet.map(x => x.key),
            datasets: [{
                fill: true,
                data: dataSet.map(x => x.value),
                borderColor: "#5542F6",
                pointBackgroundColor: "#5542F6",
                tension: 0.4,
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 225);
                    gradient.addColorStop(0, "rgba(85, 66, 246, 0.3)");
                    gradient.addColorStop(1, "rgba(85, 66, 246, 0)");
                    return gradient;
                }
            }]
        }
    }

    return (
        <div className="PayrollHome">
            <div className="filter-wrapper">
                <FormSection>
                    <FormControl>
                        <div className="filter">
                            <Dropdown
                                options={[
                                    {
                                        key: 0,
                                        text: state.selectedCategoryId === 0 ?
                                            state.translations["Kategori"] + ": " + state.translations["Alla"] :
                                            state.translations["Alla"]
                                    },
                                    ...props.categories.map((d) => ({
                                        key: d.id,
                                        text: d.nameShort,
                                    }))
                                ]}
                                styles={{
                                    dropdown: { width: 150 },
                                    callout: {width: 300, minWidth: 300}
                                }}
                                selectedKey={state.selectedCategoryId}
                                onChange={(event, option) => {
                                    setState(s => ({
                                        ...s,
                                        selectedCategoryId: Number(option.key)
                                    }));
                                }}
                            />
                            <Dropdown
                                options={[
                                    {
                                        key: 0,
                                        text: state.selectedOfficeId === 0 ?
                                            state.translations["Kontor"] + ": " + state.translations["Alla"] :
                                            state.translations["Alla"]
                                    },
                                    ...props.offices.map((d) => ({
                                        key: d.id,
                                        text: d.name,
                                    }))
                                ]}
                                styles={{
                                    dropdown: { width: 150 },
                                    callout: {width: 300, minWidth: 300}
                                }}
                                selectedKey={state.selectedOfficeId}
                                onChange={(event, option) => {
                                    setState(s => ({
                                        ...s,
                                        selectedOfficeId: Number(option.key)
                                    }));
                                }}
                            />
                            <Dropdown
                                options={state.intervals.map((interval) => {
                                    return {
                                        key: interval.id,
                                        text: interval.name
                                    }
                                })}
                                styles={{
                                    dropdown: { width: 150 },
                                    callout: {width: 300, minWidth: 300}
                                }}
                                selectedKey={state.selectedIntervalId}
                                onChange={(event, option) => {
                                    setState(s => ({
                                        ...s,
                                        selectedIntervalId: Number(option.key)
                                    }));
                                }}
                            />
                        </div>
                    </FormControl>
                </FormSection>
            </div>
            {state.isLoading && (
                <Loader />
            )}
            {!!state.data && state.data.hasBeenCensored && (
                <div>
                    <Label
                        centerText
                        color="red">
                        {state.translations["PAYROLL_ENTRY_COUNT_ERROR"]}
                    </Label>
                </div>
            )}
            {!!state.data && (
                <div className="data">
                    <PayrollHomeSection
                        title={state.translations["SEX"]}
                        data={formatData(state.data.equality)}
                        isPercentage />
                    <PayrollHomeSection
                        title={state.translations["AGE"]}
                        data={formatData(state.data.age)} />
                    <PayrollHomeSection
                        title={state.translations["INDUSTRY_EXPERIENCE"]}
                        data={formatData(state.data.yearsInIndustry)} />
                    <PayrollHomeSection
                        title={state.translations["SALARY"]}
                        data={formatData(state.data.salary)} />
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(PayrollHome);

