import { ProgressIndicator } from '@fluentui/react/lib/components/ProgressIndicator/ProgressIndicator'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { localize } from 'src/l10n'
import { IApplicationState } from 'src/spintr/reducer'
import { clearUploads } from 'src/ui/actions'
import "./UploadsInProgressIndicator.scss"

interface IProps {
    uploadsInProgress?: any[]
    dispatch?: any;
}

class UploadsInProgressIndicator extends Component<IProps> {
    checkIfDone(timer?: number) {
        if (!this.props.uploadsInProgress ||
            this.props.uploadsInProgress.length === 0) {
            return;
        }

        if (!timer) {
            timer = 10000;
        }

        let hasChanged = false;
        let previousUploads = [...this.props.uploadsInProgress];

        setTimeout(() => {
            let newUploads = [...this.props.uploadsInProgress];

            if (previousUploads.length !== newUploads.length) {
                hasChanged = true;
            } else {
                for (let i = 0; i < previousUploads.length; i++) {
                    if (previousUploads[i].uploadId !== newUploads[i].uploadId ||
                        previousUploads[i].total !== newUploads[i].total ||
                        previousUploads[i].loaded !== newUploads[i].loaded) {
                        hasChanged = true;
                    }
                }
            }

            if (!hasChanged) {
                this.props.dispatch(clearUploads());
            }
        }, 15000);
    }

    componentDidUpdate() {
        this.checkIfDone();

        if (this.props.uploadsInProgress &&
            this.props.uploadsInProgress.length > 0) {
            const avg = this.getAvg();

            if (avg === 1) {
                this.checkIfDone(5000);
            }
        }
    }

    componentDidMount() {
        this.checkIfDone();
    }

    getAvg() {
        let total = 0;
        let loaded = 0;

        for (let file of this.props.uploadsInProgress) {
            total += file.total;
            loaded += file.loaded;
        }

        const round = (num) => {
            return Math.round(num * 100) / 100;
        }

        const avg = round(loaded / total);

        return avg;
    }

    render() {
        if (!this.props.uploadsInProgress ||
            this.props.uploadsInProgress.length === 0) {
            return null;
        }

        const avg = this.getAvg();

        const text = avg === 1 ?
            localize("KlarMedUppladdning") :
            localize("LaddarUpp") +
                " " +
                (this.props.uploadsInProgress.length === 1 ?
                    localize("Ubertype7_0_0") :
                    this.props.uploadsInProgress.length + " " + localize("Ubertype7_1_0")) +
                " (" +
                (avg * 100).toFixed() +
                "%)";

        return (
            <div className="general-progress-bar-wrapper">
                <ProgressIndicator label={text} percentComplete={avg} />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        uploadsInProgress: state.ui.uploadsInProgress
    };
};

export default connect(mapStateToProps)(UploadsInProgressIndicator);
