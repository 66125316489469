import api from 'src/spintr/SpintrApi';
import {
    FETCH_PLANNING,
    SAVE_CATEGORY,
    SAVE_CATEGORY_FULFILLED,
    SAVE_ITEM,
    SAVE_ITEM_FULFILLED,
    SAVE_BOARD_POSITIONS,
    SAVE_BOARD_POSITIONS_FULFILLED,
    DELETE_ITEM_FULFILLED
} from './planning-action-types';

export const fetchPlanning = (axios, groupId) => ({
    meta: { groupId },
    payload: api.get(`/api/groupplanning/${groupId}`),
    type: FETCH_PLANNING
});

export const saveCategory = (axios, groupId, category) => ({
    meta: {
        groupId,
        category
    },
    payload: api.post(`/api/groupplanning/${groupId}/category/${category.id}`, category),
    type: SAVE_CATEGORY
});

export const saveCategoryFulfilled = (groupId, category) => ({
    meta: {
        groupId,
        category
    },
    type: SAVE_CATEGORY_FULFILLED
});

export const saveItem = (axios, groupId, item) => ({
    meta: {
        groupId,
        item
    },
    payload: api.post(`/api/groupplanning/${groupId}/item/${item.id}`, item),
    type: SAVE_ITEM
});

export const saveItemFulfilled = (groupId, item) => ({
    meta: {
        groupId,
        item
    },
    type: SAVE_ITEM_FULFILLED
});

export const saveBoardPositions = (axios, groupId, categories) => ({
    meta: {
        groupId,
        categories
    },
    payload: api.post(`/api/groupplanning/${groupId}/boardpositions`, categories),
    type: SAVE_BOARD_POSITIONS
});

export const updateBoardPositionsRealtime = (groupId, categories) => ({
    meta: {
        groupId,
        categories
    },
    type: SAVE_BOARD_POSITIONS_FULFILLED
});

export const deleteItemRealtime = (itemId) => ({
    meta: {
        itemId
    },
    type: DELETE_ITEM_FULFILLED
});
