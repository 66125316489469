import { localize } from "src/l10n";

let isRunning = false;

function startDraftWcagCheck() {
    if (isRunning) {
        return;
    }

    isRunning = true;

    setInterval(draftWcagCheck, 5000);
}

function draftWcagCheck() {
    const draftEditors = document.querySelectorAll('[data-editor]');

    if (draftEditors &&
        draftEditors.length > 0) {
        for (var i = 0; i < draftEditors.length; i += 1) {
            let el = draftEditors[i];

            //@ts-ignore
            if (!el.hasAttribute("role")) {
                //@ts-ignore
                el.setAttribute("role", "textbox")
            }

            //@ts-ignore
            if (!el.hasAttribute("aria-label")) {
                //@ts-ignore
                el.setAttribute("aria-label", localize("SkrivNagot"))
            }

            //@ts-ignore
            if (!el.hasAttribute("title")) {
                //@ts-ignore
                el.setAttribute("title", localize("SkrivNagot"))
            }
        }
    }
}

export default startDraftWcagCheck;