import { Text, TextField } from "@fluentui/react";
import React, { ChangeEvent, ReactNode } from "react";
import { localize } from "src/l10n";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

class BasicAuthInput extends AuthenticationDataComponent<any, any> {
    private instructions: string = localize("BASIC_AUTH_INSTRUCTIONS");
    private passwordLabel: string = localize("PASSWORD");
    private usernameLabel: string = localize("USERNAME");

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                password: "",
                username: ""
            },
            instructions: undefined,
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    public componentDidMount(): void {
        try {
            const data: any = JSON.parse(this.props.authenticationData);

            this.setState({
                instructions: data.instructions,
            });
        } catch (_) {}
    }

    public getAuthenticationData(): string {
        return JSON.stringify(this.state.data);
    }    
    
    public isValid(): boolean {
        return (
            this.state.data.username.length > 0 &&
            this.state.data.password.length > 0  
        );
    }

    public onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        const { name, value } = event.target;

        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            }
        }, () => {
            this.isValid();

            if (typeof this.props.onChange === "function") {
                this.props.onChange();
            }
        });
    }
    
    public render(): ReactNode {
        const instructions = this.state.instructions || this.instructions;

        return (
            <div className="BasicAuthInput">
                <p
                    className="instructions"
                    dangerouslySetInnerHTML={{
                        __html: instructions
                    }}
                />
                <TextField
                    label={this.usernameLabel}
                    name="username"
                    onChange={this.onValueChanged}
                    value={this.state.data.username}
                />
                <TextField
                    label={this.passwordLabel}
                    name="password"
                    onChange={this.onValueChanged}
                    value={this.state.data.password}
                />
            </div>
        );
    }
}

export default BasicAuthInput;