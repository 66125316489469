import { Strategy } from "../../types";

export function createProductMentionStrategy(): Strategy {
    return function productMentionStrategy(block, callback, contentState) {
        block.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                
                return (
                    entityKey !== null &&
                    contentState.getEntity(entityKey).getType() === "PRODUCT"
                );
            },
            callback,
        )
    };
}
