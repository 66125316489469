import React, { useState } from "react";
import "./PayrollView.scss";
import { Dropdown, PrimaryButton } from "@fluentui/react";
import { localize } from "src/l10n";
import { FormControl, FormSection } from "src/ui/components/Forms";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { AxiosResponse } from "axios";

interface IProps {
    categories: any[];
}

const PayrollDownloadReport = (props: IProps) => {
    return (
        <PrimaryButton
            text={localize("DOWNLOAD_REPORT")}
            onClick={() => {
                api.post("/api/v1/payroll/export").then((response: AxiosResponse) => {
                    const link = document.createElement("a");

                    link.setAttribute("href", response.data);
                    link.setAttribute("target", "_blank");
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch(() => {});
            }} />
    )
}

export default PayrollDownloadReport;
