import classnames from "classnames";
import { Icon, PrimaryButton } from "@fluentui/react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { Link } from "@fluentui/react/lib/Link";
import * as React from "react";
import { localize } from "src/l10n";
import ThemeContext from "src/style/ThemeContext";
import { Label } from "src/ui";
import PopupHeader from "src/ui/components/PopupHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IState {
    isDraggingOver: boolean;
    hideDialog: boolean;
}

interface IProps {
    className?: string | undefined;
    isMultiple: boolean;
    fileTypesString: string;
    fileTypes?: any;
    onFilesSelected: any;
    text?: string;
    renderSizeString?: any;
    disabled?: boolean;
}

class DropZone extends React.Component<IProps, IState> {
    public static contextType = ThemeContext;
    fileInput = React.createRef<HTMLInputElement>();

    constructor(props) {
        super(props);

        this.state = {
            isDraggingOver: false,
            hideDialog: true,
        };
    }

    stopEvent(e) {
        let event = e as Event;

        event.stopPropagation();
        event.preventDefault();
    }

    onFileSelected(files) {
        this.props.onFilesSelected(this.props.isMultiple ? files : [files[0]]);
    }

    removeDragEffect(data) {
        this.stopEvent(data.nativeEvent);

        this.setState({
            isDraggingOver: false,
        });
    }

    validateFiles(files) {
        let isOk = false;

        if (!this.props.fileTypes || this.props.fileTypes === "*") {
            isOk = true;

            return isOk;
        }

        for (let file of files) {
            for (let fileType of this.props.fileTypes) {
                if (file.name.toLowerCase().indexOf("." + fileType.toLowerCase()) > -1) {
                    isOk = true;
                }
            }
        }

        return isOk;
    }

    public render() {
        const text =
            this.props.text || (this.props.isMultiple
                ? localize("DRAG_OR_UPLOAD_MULTIPLE")
                : localize("DRAG_OR_UPLOAD_SINGLE"));

        return (
            <div className="drop-zone-container">
                <div
                    onClick={this.props.disabled ? undefined : (ev) => this.fileInput.current.click()}
                    className={classnames("drop-zone", this.props.className, {
                        ["is-dragging-file-over"]: this.state.isDraggingOver,
                    })}
                    onDrop={this.props.disabled ? undefined : (data) => {
                        this.stopEvent(data.nativeEvent);

                        let files = data.dataTransfer.files;

                        if (files.length > 0 && this.validateFiles(files)) {
                            this.onFileSelected(files);
                        } else {
                            this.setState({
                                hideDialog: false,
                            });
                            this.fileInput.current.value = "";
                        }

                        this.setState({
                            isDraggingOver: false,
                        });
                    }}
                    onDragOver={this.props.disabled ? undefined : (data) => {
                        this.stopEvent(data.nativeEvent);

                        this.setState({
                            isDraggingOver: true,
                        });
                    }}
                    onDragEndCapture={this.props.disabled ? undefined : this.removeDragEffect.bind(this)}
                    onDragExit={this.props.disabled ? undefined : this.removeDragEffect.bind(this)}
                    onDragLeave={this.props.disabled ? undefined : this.removeDragEffect.bind(this)}
                >
                    <input
                        aria-label={localize("DRAG_OR_UPLOAD_MULTIPLE")}
                        ref={this.fileInput}
                        style={{ height: 0, width: 0 }}
                        onChange={(e) => {
                            let files = e.target.files;
                            if (files.length > 0 && this.validateFiles(files)) {
                                this.onFileSelected(files);
                            } else {
                                this.setState({
                                    hideDialog: false,
                                });
                                this.fileInput.current.value = "";
                            }
                        }}
                        type="file"
                        multiple={this.props.isMultiple}
                        accept={this.props.fileTypesString}
                    />
                    {this.props.children}
                    {!this.props.children && (
                        <>
                            <Visage2Icon icon="document-upload" className="uploadIcon" />
                            <Link className={"text"}>{text}</Link>

                            {!!this.props.renderSizeString && (
                                <div className="image-size-recommendation">
                                    <Label
                                        as="p"
                                        size="body-3"
                                        dangerouslySetInnerHTML={{ __html: this.props.renderSizeString() }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <Dialog
                        hidden={this.state.hideDialog}
                        containerClassName="dialogWithPopupHeader"
                        onDismiss={() => {
                            this.setState({
                                hideDialog: true,
                            });
                        }}
                        modalProps={{
                            isBlocking: true,
                            allowTouchBodyScroll: true
                        }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: localize("WARNING"),
                            closeButtonAriaLabel: localize("Stang"),
                            subText: localize("FilTypInteTillaten"),
                        }}
                    >
                        <PopupHeader
                            text={localize("WARNING")}
                            subText={localize("FilTypInteTillaten")}
                            onClose={() => {
                                this.setState({
                                    hideDialog: false,
                                });
                            }} />
                        <DialogFooter>
                            <PrimaryButton
                                theme={this.context}
                                onClick={() => {
                                    this.setState({
                                        hideDialog: true,
                                    });
                                }}
                                text={localize("Ok")}
                            />
                        </DialogFooter>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default DropZone;
