import api from "src/spintr/SpintrApi";

export const respondToInvite = async (inviteId: number, accept: boolean): Promise<any> => {
    const url = `/api/v1/groupmembers/${inviteId}`;

    const responsePromise = accept
        ? api.put(url)
        : api.delete(url);

    const response = await responsePromise;

    return response.data;
};

export const respondToRequest = async (userId: number, groupId: number, accept: boolean): Promise<any> => {
    const url = `/api/v1/groupmembers/request/${groupId}/${accept ? "accept" : "reject"}/${userId}`;

    const response = await api.post(url);

    return response.data;
};

export const GroupsApi = {
    respondToInvite,
    respondToRequest
};

export default GroupsApi;