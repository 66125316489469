import { IconButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Label, Loader, UnstyledButton, setShouldReloadFavourites } from "src/ui";
import { decodeHtmlEntities } from "src/utils";
import "./Favourites.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    items?: any;
    hideTitle?: boolean;
    disableButtons?: boolean;
    history?: any;
    id?: number;
    fetchFavourites?: boolean;
    shouldReload: boolean;
    dispatch: any;
}

const removeUberTypeFromUrl = (url) => {
    url = url.split("/");
    url.splice(2, 1);
    return url.join("/");
};

const Favourites = (props: Props) => {
    const { hideTitle, disableButtons } = props;
    const icon = { iconName: "Delete" };
    const [favourites, setFavourites] = useState(props.items);
    const [isLoading, setIsLoading] = useState(true);

    const handleRemoveClick = (id) => {
        !disableButtons && setIsLoading(true);
        api.delete("/api/favorites/" + id).then(() =>
            api.get("/api/favorites").then((response) => {
                setFavourites(response.data);
                setIsLoading(false);
            })
        );
    };

    useEffect(() => {
        let mounted = true;

        if (props.fetchFavourites) {
            api.get("/api/v1/favorites").then((response) => {
                if (mounted) {
                    setFavourites(response.data);
                    setIsLoading(false);
                    props.dispatch(setShouldReloadFavourites(false));
                }
            });
        } else {
            setIsLoading(false);
            props.dispatch(setShouldReloadFavourites(false));
        }

        return () => (mounted = false);
    }, [props.shouldReload]);

    if (isLoading) return <Loader />;
    if (!favourites) return null;

    return (
        <div className="favouriteBox">
            {hideTitle ? null : (
                <div className="widget-header">
                    <Label style={{ marginLeft: 0 }} as="h4" size="small-2">
                        {localize("Favoriter")}
                    </Label>
                </div>
            )}

            <div>
                {favourites.length > 0 ? (
                    favourites.map((item) => {
                        return (
                            <div className="eachFavourite" key={item.id}>
                                {disableButtons ? (
                                    <Label as="span" size="body-2" className="textEachFavourite">
                                        {decodeHtmlEntities(item.title)}
                                    </Label>
                                ) : (
                                    <Link
                                        className="textEachFavourite"
                                        to={removeUberTypeFromUrl(item.url)}
                                    >
                                        <Label as="span" size="body-2">
                                            {decodeHtmlEntities(item.title)}
                                        </Label>
                                    </Link>
                                )}

                                <div className="favIcon" style={{ width: "10%" }}>
                                    <Visage2Icon icon="star" />
                                </div>
                                <div className="deleteButton">
                                    <UnstyledButton onClick={() => handleRemoveClick(item.id)}>
                                        <Visage2Icon icon="trash" />
                                    </UnstyledButton>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="textNoFavourite">
                        <Label as="p" size="body-2">
                            {localize("DuHarIngaFavoriter")}
                        </Label>
                        
                        </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => {
    return {
        shouldReload: state.ui.shouldReloadFavourites,
    };
};

export default connect(mapStateToProps)(Favourites);
