function getLightOrDarkColorBasedOnColor (bgColor : string, lightColor : string, darkColor : string) {
    let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;

    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);

    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? darkColor : lightColor;
}

export default getLightOrDarkColorBasedOnColor;