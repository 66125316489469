import classNames from "classnames";
import * as React from "react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

export interface IProps {
    color?: spintrColors;
    className?: string;
    icon: string;
    size?: number;
}

export const Icon: React.FunctionComponent<IProps> = props => {
    return (
        <Visage2Icon
            color={props.color}
            className={classNames("icon-component no-user-select", props.className)}
            icon={props.icon} />
    );
};

Icon.defaultProps = {
    color: "dark-grey",
    size: 1
};

const areEqual = (prevProps, nextProps) => {
    return prevProps.color === nextProps.color &&
        prevProps.icon === nextProps.icon;
};

export default React.memo(Icon, areEqual);

