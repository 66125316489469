import React, { FunctionComponent, useMemo } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { buildAppPageComponent } from "src/marketplace/utils";

interface IProps extends IAppPageComponent {
    horizontal?: boolean;
    verticalAlign?: string;
}

const AppPageComponentGroup: FunctionComponent<IProps> = (props) => {
    const { children, variables, verticalAlign } = props;

    const items = useMemo(
        () => (children || []).map((item, index) => {
            const el = buildAppPageComponent({
                ...item,
                variables,
            }, index);

            if (el === null) {
                return null;
            }

            const cssProps: React.CSSProperties = {};
            if (item.width) {
                cssProps.width = item.width;
            }

            if (item.grow === false) {
                cssProps.flexGrow = 0;
            }

            return (
                <div className="child" key={index} style={cssProps}>
                    {el}
                </div>
            );
        }),
        [children, variables],
    );

    const className = "AppPageComponentGroup"
        + (props.horizontal ? " horizontal" : "");

    const style: React.CSSProperties = {};
    if (verticalAlign === "center") {
        style.alignItems = "center";
    }

    return (
        <div className={className} style={style}>
            {items}
        </div>
    )
};

export default AppPageComponentGroup;
