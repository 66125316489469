import * as actionTypes from "./action-types";

const initialState = {
    items: [],
};

const emergencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALERTS_FULFILLED:
            return {
                ...state,
                items: [...action.payload.data],
            };

        case actionTypes.REMOVE_ALERT_FULFILLED:
            return {
                ...state,
                items: [
                    ...state.items.filter((i) => {
                        return i.id !== action.meta.alertId;
                    }),
                ],
            };
        default:
            return state;
    }
};

export default emergencyReducer;
