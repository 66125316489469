import React, { Component, CSSProperties, ReactNode } from 'react';
import { Label } from "src/ui";
import { getInitialsFromString } from "src/utils";
import ImageCore from './ImageCore';
import './SplitImage.scss';

interface IProps {
    borders: boolean,
    size: number,
    srcs: string[],
    circle: boolean,
    alt: string
}

class SplitImage extends Component<IProps> {
    public render(): ReactNode {
        if (!this.props.srcs.length) {
            const initials = getInitialsFromString(this.props.alt);

            const style: CSSProperties = {
                height: this.props.size + 'px',
                lineHeight: this.props.size + 'px',
                width: this.props.size + 'px',
            };

            return (
                <div
                    className={"SplitImage empty" + (this.props.circle
                            ? " circle"
                            : "")}
                    style={style}
                >
                    <Label weight="medium" as="span" size="small-1">
                        {initials}
                    </Label>
                </div>
            );
        }

        if (this.props.srcs.length === 1 || this.props.srcs.length === 2) {
            return (
                <div
                    className={"SplitImage single" + (this.props.circle
                            ? " circle"
                            : "")}
                >
                    <ImageCore
                        height={this.props.size}
                        src={this.props.srcs[0]}
                        width={this.props.size}
                        style={{
                            borderRadius: this.props.circle ? '100%' : '0px'
                        }} />
                </div>
            )
        }

        const bigWidth = Math.floor(this.props.size * 0.667);

        const bigStyle: CSSProperties = {
            height: this.props.size + "px",
            width: bigWidth + "px",
        };

        const smallHeight = (this.props.size / 2);
        const smallWidth = this.props.size - bigWidth;

        const smallStyle: CSSProperties = {
            height: smallHeight + "px",
            width: smallWidth  + "px",
        };

        return (
            <div
                className={"SplitImage three" + (this.props.circle
                        ? " circle"
                        : "")}
            >
                <div>
                    <div className="left" style={bigStyle}>
                        <ImageCore
                            alt=""
                            height={this.props.size}
                            src={this.props.srcs[0]}
                            width={bigWidth}
                        />
                    </div>
                    <div className="rightTop" style={smallStyle}>
                        <ImageCore
                            alt=""
                            height={smallHeight}
                            src={this.props.srcs[1]}
                            width={smallWidth} />
                    </div>
                    <div className="rightBottom" style={smallStyle}>
                        <ImageCore
                            alt=""
                            height={smallHeight}
                            src={this.props.srcs[2]}
                            width={smallWidth} />
                    </div>
                </div>
            </div>
        )
    }
}

export default SplitImage
