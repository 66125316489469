import classnames from "classnames";
import { Image } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Label, Loader } from "src/ui";
import { Style } from "src/ui/helpers";
import {
    IMarketplaceWidgetContext,
    MarketplaceWidgetContext
} from "../MarketplaceWidgetContext";
import { MarketplaceWidgetData } from "../MarketplaceWidgetData";
import { MarketplaceWidgetErrorBoundary } from "../MarketplaceWidgetErrorBoundary";
import { MarketplaceWidgetLoader } from "../MarketplaceWidgetLoader";
import "./MarketplaceWidget.scss";

interface IProps {
    data?: Spintr.IMarketplaceWidgetDataResponse;
    id?: string;
    autoWidth?: boolean;
    displayBorder?: boolean;
}

interface IState {
    data: Spintr.IMarketplaceWidgetDataResponse;
}

class MarketplaceWidget extends Component<IProps, IState> {
    private readonly widgetContext: IMarketplaceWidgetContext;

    constructor(props: IProps) {
        super(props);

        this.state = {
            data: props.data,
        };

        this.widgetContext = {
            onDataLoaded: this.onDataLoaded.bind(this),
        };
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    public render(): ReactNode {
        const { data } = this.state;

        return (
            <div className={classnames("MarketplaceWidget", { autoWidth: this.props.autoWidth, displayBorder: !!this.props.displayBorder })}>
                <MarketplaceWidgetContext.Provider value={this.widgetContext}>
                    <MarketplaceWidgetErrorBoundary>
                        <div className="information start-page-flexible-padding">
                            <div className="logo">
                                <Image
                                    height={24}
                                    src={!data ? null : data.iconUrl}
                                    width={24}
                                />
                            </div>
                            <div className="name">
                                <Label
                                    as="div"
                                    size="body-2"
                                    weight="medium"
                                    color="visageGray2"
                                >
                                    {!data ? localize("Laddar") : data.name}
                                </Label>
                            </div>
                        </div>
                        <div className="data start-page-flexible-padding">
                            {this.props.id && (
                                <MarketplaceWidgetLoader id={this.props.id} />
                            )}
                            {!data && (
                                <Loader />
                            )}
                            {data && data.failed && (
                                <Label
                                    className="widget-error"
                                    color="mid-grey"
                                    size="body-2">
                                    {localize((!!data.errorCode && data.errorCode === 404) ? 'NOT_FOUND' : 'TeknisktFel')}
                                </Label>
                            )}
                            {data && !data.failed && (
                                <MarketplaceWidgetData
                                    config={data.config}
                                    data={data.data}
                                    displayType={data.displayType}
                                    variables={data.variables}
                                />
                            )}
                        </div>
                    </MarketplaceWidgetErrorBoundary>
                </MarketplaceWidgetContext.Provider>
            </div>
        );
    }

    private onDataLoaded(data: Spintr.IMarketplaceWidgetDataResponse): void {
        this.setState({ data });
    }
}

export default MarketplaceWidget;
