import React, { CSSProperties } from "react";
import { localize } from "src/l10n";
import { ComposerStage, IComposerPost, defaultFrameFonts } from "./Visage2Composer";
import { Text } from "@fluentui/react";
import { EditorState } from "draft-js";

interface IProps {
    onPostUpdate: any;
    post: Spintr.ISocialPostBase;
    editorState: EditorState;
    setStep: any;
}

const Visage2ComposerFrameFontSelector = (props: IProps) => {
    const text = (
        props.editorState.getCurrentContent().getPlainText() ||
        localize("COMPOSER_PLACEHOLDER")
    );

    return (
        <div>
            <div className="fonts">
                <ul className="list">
                    {defaultFrameFonts.map((font, i) => {
                        const css: CSSProperties = {
                            fontFamily: font
                        };

                        return (
                            <li
                                className="item"
                                key={font}
                            >
                                <Text
                                    tabIndex={0}
                                    as="a"
                                    className="sample ignore-custom-font"
                                    style={css}
                                    variant="xLarge"
                                    data-font={i+1}
                                    onClick={() => {
                                        props.onPostUpdate({
                                            ...props.post,
                                            font: i + 1,
                                        });

                                        props.setStep(ComposerStage.EditingPost);
                                    }}
                                >
                                    {text}
                                </Text>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Visage2ComposerFrameFontSelector;