import { AxiosRequestConfig } from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, RouteChildrenProps, withRouter } from "react-router";
import SocialBlock from "src/interactions/components/SocialBlock";
import { localize } from "src/l10n";
import { canUserCreatePages } from "src/privileges/utils";
import { IVisageSidebarMenuItem, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import api from "src/spintr/SpintrApi";
import {
    Breadcrumbs,
    ContentImageViewerAndSelector, ContentWithInfoPanel, ContentWithSubmenu, Loader, PageInfoPanel, Submenu
} from "src/ui";
import { FillHeight } from "src/ui/components";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { Style } from "src/ui/helpers";

interface IPageRouteParams {
    path: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    id?: string;
    name?: string;
    username?: string;
    instance?: any;
    currentUser?: any;
    privileges?: any;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    page?: any;
    pageNotFound: boolean;
}

interface IPropsIframe {
    src: string;
    height: string;
    width: string;
    scrolling: string;
    frameBorder: string;
}

class Iframe extends React.Component<IPropsIframe> {
    render() {
        return (
            <iframe
                src={this.props.src}
                height={this.props.height}
                width={this.props.width}
                scrolling={this.props.scrolling}
                frameBorder={this.props.frameBorder}
            />
        );
    }
}

class IframePageView extends Component<IProps, IState> {
    private _isMounted: boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageNotFound: false,
        };
    }

    removeSlashFromPath(path) {
        if (!path) {
            return path;
        }

        if (path[0] === "/") {
            return path.substring(1, path.length);
        } else {
            return path;
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidMount(): void {
        this._isMounted = true;

        if (this.props.id) {
            this.fetchPageById(this.props.id);
            return;
        }

        this.fetchPage(this.removeSlashFromPath(this.props.location.pathname));
    }

    private fetchPageById = (pageId: string) => {
        api.get("/api/v1/iframes/" + pageId).then(this.processPage, this.errorPage);
    }

    public fetchPage(path: string): void {
        const config: AxiosRequestConfig = {
            params: {
                route: path,
            },
        };

        api.get("/api/v1/routes", config).then(this.processPage, this.errorPage);
    }

    private processPage = (response) => {
        let item = response.data;

        if (!item.uberContent) {
            item.uberContent = {
                uberContentRows: [],
            };

            if (!!item.preamble && item.preamble.length > 0) {
                item.uberContent.uberContentRows.push({
                    id: 0,
                    index: item.uberContent.uberContentRows.length,
                    uberContentColumns: [
                        {
                            id: 0,
                            state: 0,
                            type: 0,
                            index: 0,
                            content: '<div style="font-weight: 600;">' + item.preamble + "</div>",
                        },
                    ],
                });
            }

            item.uberContent.uberContentRows.push({
                id: 0,
                index: item.uberContent.uberContentRows.length,
                uberContentColumns: [
                    {
                        id: 0,
                        state: 0,
                        type: 0,
                        index: 0,
                        content: item.content,
                    },
                ],
            });
        }

        item.content = item.content
            .replace("{userName}", this.props.username)
            .replace("{userFullName}", this.props.name);

        this.setState({
            isLoading: false,
            page: response.data,
        });
    };

    private errorPage = (reason: any): void => {
        console.log(reason);

        this.setState({
            pageNotFound: true,
        });
    }

    updateSideBar() {
        const isGroupPage = this.props.location.pathname.indexOf("/groups/") === 0;

        const newParams = {
            id: this.state.page.menuId,
            searchQuery: "",
            groupMode: !!isGroupPage
        };

        api.get("/api/menu/submenu/tree", {
            params: newParams
        }).then((response: any) => {
            if (!this._isMounted) {
                return;
            }

            let result = [...response.data.filter(i => i.deleted !== true)];

            const setIsExpanded = (items) => {
                for (let item of items) {
                    item.isExpanded = !item.collapsed;

                    if (item.nodes) {
                        setIsExpanded(item.nodes);
                    }
                }
            };

            setIsExpanded(result);

            const getItems = (items: any[], isFirstLevel?: boolean) => {
                let visageSidebarMenuItems: IVisageSidebarMenuItem[] = [];

                for (let item of items) {
                    visageSidebarMenuItems.push({
                        key: item.id,
                        name: item.title,
                        moduleKey: item.moduleKey,
                        isExpanded: item.isExpanded,
                        children: !!item.nodes && item.nodes.length > 0 ?
                            getItems(item.nodes) :
                            undefined,
                        route: item.url,
                        routes: [item.url],
                        icon: isFirstLevel ?
                            "document" :
                            undefined,
                        activeIcon: isFirstLevel ?
                            "document" :
                            undefined,
                    });
                }

                return visageSidebarMenuItems;
            }

            let convertedItems = getItems(result, true);

            if (canUserCreatePages(this.props.privileges, this.props.currentUser, this.props.instance)) {
                const createItem = {
                    key: "create-text-page",
                    name: localize("SkapaTextsida"),
                    route: "/pages/create",
                    routes: ["/pages/create"],
                    icon: "add",
                    search: this.state.page.menuCategory && this.state.page.menuCategory.indexOf("group_") >= 0 ?
                        ("?groupId=" + this.state.page.menuCategory.substring(6) + "&parent=" + this.state.page.menuId) :
                        ("?parent=" + this.state.page.menuId)
                };

                if (convertedItems.length > 0) {
                    convertedItems[0] = {
                        ...convertedItems[0],
                        children: [
                            ...(!!convertedItems[0].children ? convertedItems[0].children : []),
                            createItem
                        ]
                    }
                } else {
                    convertedItems.push(createItem);
                }
            }

            this.props.dispatch(setSidebarItems(convertedItems, convertedItems[0].key));
            this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
            this.props.dispatch(expandToActiveItem());
        }).catch(() => { });
    }

    public componentDidUpdate(prevProps: IProps): void {
        const pathHasChanged = this.props.match.params.path !== prevProps.match.params.path;
        const locationHasChanged = this.props.location.pathname !== prevProps.location.pathname;

        if (!pathHasChanged && !locationHasChanged) {
            return;
        }

        let newPath;

        if (pathHasChanged) {
            newPath = this.props.match.params.path;
        } else if (locationHasChanged) {
            newPath = this.removeSlashFromPath(this.props.location.pathname);
        }

        this.setState(
            {
                isLoading: true,
            },
            (): void => this.fetchPage(newPath)
        );
    }

    public getScrolling() {
        if (this.state.page.hasScroll) {
            return "yes";
        } else {
            return "no";
        }
    }

    public getBorder() {
        if (this.state.page.hasBorder) {
            return "1";
        } else {
            return "0";
        }
    }
    render() {
        if (this.state.pageNotFound) {
            return <Redirect to="/" />;
        }

        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div>
                <div>
                    {this.state.page.isBigIframe ? (
                        <FillHeight>
                            <div style={{
                                height: "calc(100% - " + Style.getSpacingStr(10) + ")",
                                width: "100%",
                            }}>
                                <Iframe
                                    src={this.state.page.content}
                                    height="100%"
                                    width="100%"
                                    scrolling={this.getScrolling()}
                                    frameBorder={this.getBorder()}
                                />
                            </div>
                        </FillHeight>
                    ) : (
                        <div className="contentMiddle">
                            <div>
                                <Breadcrumbs
                                    displayInstance
                                    items={[
                                        {
                                            key: this.state.page.id,
                                            text: this.state.page.title,
                                            link: "",
                                        },
                                    ]}
                                />
                                {!!this.state.page.headerImageUrl ? (
                                    <div
                                        style={{
                                            marginBottom: Style.getSpacingStr(3),
                                        }}
                                    >
                                        <ContentImageViewerAndSelector
                                            imageUrl={this.state.page.headerImageUrl}
                                            editMode={false}
                                        ></ContentImageViewerAndSelector>
                                    </div>
                                ) : null}
                                <ContentWithInfoPanel
                                    template={this.state.page.template}
                                    renderInfoPanel={() => {
                                        return (
                                            <PageInfoPanel
                                                uberId={this.state.page.id}
                                                publishers={this.state.page.publishers}
                                                displayTags={true}
                                                displayLastEdited={true}
                                                displayReach={true}
                                                displayPublishers={this.state.page.showPublisher}
                                                isWide={this.state.page.template === 2}
                                                tags={this.state.page.tags}
                                            ></PageInfoPanel>
                                        );
                                    }}
                                >
                                    {this.state.page.uberContent.uberContentRows.map((ucr) => {
                                        return (
                                            <div
                                                key={ucr.index}
                                                className="contentSection"
                                                style={{
                                                    marginBottom: Style.getSpacingStr(5),
                                                }}
                                            >
                                                {ucr.uberContentColumns.map((ucc) => {
                                                    let isTwoColumns = ucr.uberContentColumns.length === 2;

                                                    let style = {
                                                        width: isTwoColumns
                                                            ? "calc((100% - " + Style.getSpacingStr(5) + ") / 2)"
                                                            : "100%",
                                                        marginRight: isTwoColumns
                                                            ? ucc.index === 0
                                                                ? Style.getSpacingStr(5)
                                                                : 0
                                                            : 0,
                                                        display: isTwoColumns ? "inline-block" : "block",
                                                        verticalAlign: "top",
                                                    };

                                                    return (
                                                        <div key={ucc.index} style={style}>
                                                            <TinyFormattedContent content={ucc.content} />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </ContentWithInfoPanel>
                                {this.state.page.allowComments ? (
                                    <SocialBlock uberId={this.state.page.id}></SocialBlock>
                                ) : null}
                            </div>
                            <Iframe
                                src={this.state.page.content}
                                height="600px"
                                width="100%"
                                scrolling={this.getScrolling()}
                                frameBorder={this.getBorder()}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: Spintr.AppState, props) {
    return {
        ...props,
        name: state.profile.active.name,
        username: state.profile.active.username,
        instance: state.instance,
        currentUser: state.profile.active,
        privileges: state.privileges.data,
    };
}

// @ts-ignore
export default withRouter(connect(mapStateToProps)(IframePageView));
