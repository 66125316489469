import { Dialog, PrimaryButton, DefaultButton } from "@fluentui/react";
import Axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PopupHeader from "../../PopupHeader";
import { localize, localizeFormat } from "src/l10n";
import SpintrApi from "src/spintr/SpintrApi";
import { Editor, NotificationSpec } from "tinymce";
import { SpintrTypes } from "src/typings";
import { UnstyledButton } from "src/ui";

import "./TinyArtificialIntelligenceFunction.scss";
import { ITinyEditorComponent } from "../Tiny.types";
import { AssistantTypingLoader } from "src/assistant/components/AssistantTypingLoader";

type PluginDataParameters = { [key: string]: any };

interface IPluginData {
    completionType: Spintr.CompletionType;
    parameters: PluginDataParameters;
}

interface IProps {
    language: Spintr.Language;
    pluginData: IPluginData;
    selectedText: string;

    onClose?: () => void;
    displayNotification?: (notification: NotificationSpec) => void;
    replaceText?: (text: string) => void;
}

interface IPluginState {
    hasErrors: boolean;
    isLoading: boolean;
    fullText: string;
    text: string;
}

const pluginId = "spnArtificialIntelligence";

const formatTextAsHtml = (text: string): string => {
    return text
        .replace(/(?:([^\n\n]+)\n{2})/gmi, "<p>$1</p><br />")
        .replace(/((?:\-\s+([^\n]+)\n?)+)/gmi, (potentialList) => {
            const listItems = potentialList.replace(/(?:\-\s+([^\n]+)\n?)/gmi, "<li>$1</li>\r\n");

            return `<ul>\r\n${listItems}</ul>`;
        });
}

export const getTinyArtificialIntelligencePlugin = (editorComponent: ITinyEditorComponent, enabled : boolean) => {
    let pluginData: IPluginData = undefined;

    const onConfigure = (editor: Editor): void => {
        const onAction = (completionType: Spintr.CompletionType, parameters: PluginDataParameters = {}): void => {
            const selection = editor?.selection?.getContent({
                format: "text",
            });
    
            if (!selection.trim()) {
                return;
            }
    
            pluginData = {
                completionType,
                parameters,
            };

            editorComponent.setPluginActive(pluginId, true);
        };

        editor.ui.registry.addMenuButton(pluginId, {
            text: "AI",
            fetch: (callback) => callback([{
                type: "menuitem",
                text: localize("AI_EXPAND"),
                onAction: () => onAction(SpintrTypes.CompletionType.Expansion, { length: 500 })
            }, {
                type: "menuitem",
                text: localize("AI_CORRECT"),
                onAction: () => onAction(SpintrTypes.CompletionType.Correction),
            }, {
                type: "menuitem",
                text: localize("AI_REFORMULATE"),
                onAction: () => onAction(SpintrTypes.CompletionType.Reformulation),
            }, {
                type: "nestedmenuitem",
                text: localize("AI_SUMMARIZE"),
                getSubmenuItems: () => [{
                    type: "menuitem",
                    text: localize("AI_SUMMARIZE_LIST"),
                    onAction: () => onAction(SpintrTypes.CompletionType.Summarization, { type: 1 }),
                }, {
                    type: "menuitem",
                    text: localize("AI_SUMMARIZE_PARAGRAPH"),
                    onAction: () => onAction(SpintrTypes.CompletionType.Summarization, { type: 3 }),
                }, {
                    type: "menuitem",
                    text: localize("AI_SUMMARIZE_SENTENCE"),
                    onAction: () => onAction(SpintrTypes.CompletionType.Summarization, { type: 2 }),
                }],
            }, {
                type: "nestedmenuitem",
                text: localize("AI_TONALITY"),
                getSubmenuItems: () => [{
                    type: "menuitem",
                    text: localize("AI_TONALITY_FRIENDLY"),
                    onAction: () => onAction(SpintrTypes.CompletionType.TonalityChange, { mode: 1 }),
                }, {
                    type: "menuitem",
                    text: localize("AI_TONALITY_PROFESSIONAL"),
                    onAction: () => onAction(SpintrTypes.CompletionType.TonalityChange, { mode: 2 }),
                }, {
                    type: "menuitem",
                    text: localize("AI_TONALITY_WITTY"),
                    onAction: () => onAction(SpintrTypes.CompletionType.TonalityChange, { mode: 3 }),
                }, {
                    type: "menuitem",
                    text: localize("AI_TONALITY_WARM"),
                    onAction: () => onAction(SpintrTypes.CompletionType.TonalityChange, { mode: 4 }),
                }, {
                    type: "menuitem",
                    text: localize("AI_TONALITY_EDUCATIONAL"),
                    onAction: () => onAction(SpintrTypes.CompletionType.TonalityChange, { mode: 5 }),
                }],
            }]),
        });
    };

    const onClose = () => {
        editorComponent.setPluginActive("spnArtificialIntelligence", false);
        pluginData = undefined;
    };

    const onRender = (editor: Editor): React.ReactNode => {
        const selection = editor?.selection?.getContent({
            format: "text",
        });
    
        if (!selection.trim() || !pluginData) {
            return null;
        }

        return (
            <TinyArtificialIntelligenceFunction
                language={editorComponent.getCurrentLanguage()}
                pluginData={pluginData}
                selectedText={selection}
                onClose={onClose}
                displayNotification={(notification) => editor.notificationManager.open(notification)}
                replaceText={(generatedText) => {
                    editor.undoManager.transact(() => {
                        editor?.selection?.setContent(generatedText);
                    });
                }}
            />
        );
    }

    return {
        id: pluginId,
        enabled,
        active: false,
        configFn: onConfigure,
        renderFn: onRender,
    }
};

const TinyArtificialIntelligenceFunction: React.FC<IProps> = (props) => {
    const { pluginData, selectedText, onClose, language } = props;
    const textareaRef = useRef<HTMLTextAreaElement>();

    const [state, setState] = useState<IPluginState>({
        hasErrors: false,
        isLoading: true,
        fullText: "",
        text: "",
    });

    const onCopyClick = useCallback(async () => {
        if (!textareaRef.current) {
            return;
        }

        textareaRef.current.select();
        textareaRef.current.setSelectionRange(0, textareaRef.current.textLength);
        
        try {
            await navigator.clipboard.writeText(state.fullText);

            if (props.displayNotification) {
                props.displayNotification({
                    closeButton: true,
                    icon: "copy",
                    text: localize("TEXT_WAS_COPIED"),
                    timeout: 3000,
                    type: "success",
                });
            }
    
            if (!props.onClose) {
                return;
            }
    
            props.onClose();
        } catch (_) {}
    }, [textareaRef.current, state.fullText, props.displayNotification, props.onClose]);

    const onInsertClick = useCallback(() => {
        if (!props.replaceText) {
            return;
        }

        props.replaceText(formatTextAsHtml(state.fullText));
        props.onClose();
    }, [state.fullText, props.replaceText, props.onClose]);

    useEffect(() => {
        let timeoutTokens: ReturnType<typeof setTimeout>[] = [];
        const cancelTokenSource = Axios.CancelToken.source();

        const promise = SpintrApi.post<{ completion: string; }>("/api/v1/assistant", {
            ...pluginData,
            text: selectedText,
            language: language,
        }, { cancelToken: cancelTokenSource.token });

        promise.then(
            (response) => {
                setState({
                    hasErrors: false,
                    isLoading: false,
                    fullText: response.data.completion,
                    text: "",
                });

                const messageText = response.data.completion;                
                const words = messageText.split(" ");
                const lastWordIndex = words.length - 1;

                timeoutTokens = words.map((word, index) => setTimeout(() => setState((state) => ({
                    ...state,
                    text: index === lastWordIndex
                        ? messageText
                        : index === 0 ? word : state.text + " " + word
                })), index * 100));
            },
            () => setState({
                hasErrors: true,
                isLoading: false,
                fullText: "",
                text: localize("TeknisktFel"),
            }),
        );

        return () => {
            cancelTokenSource.cancel();
            timeoutTokens.forEach((token) => clearTimeout(token));
        };
    }, [language]);

    return (
        <Dialog
            hidden={false}
            onDismiss={onClose}
            modalProps={{
                allowTouchBodyScroll: true,
                containerClassName: "dialogWithPopupHeader tiny-ai-popup",
                isBlocking: true,
            }}
        >
            <div className="TinyArtificialIntelligenceFunction">
                <PopupHeader
                    text={localize("AI_ASSISTANT")}
                    onClose={onClose}
                />
                <div className="popup-body">
                    <textarea
                        readOnly={true}
                        ref={textareaRef}
                        value={state.text}
                    />
                    {state.isLoading && (
                        <div className="loader-wrapper">
                            <AssistantTypingLoader />
                        </div>
                    )}
                </div>
                <div className="popup-footer">
                    {state.isLoading && (
                        <UnstyledButton onClick={onClose}>
                            {localize("STOP")}
                        </UnstyledButton>
                    )}
                    <DefaultButton onClick={onCopyClick}>
                        {localize("COPY_TEXT")}
                    </DefaultButton>
                    <PrimaryButton onClick={onInsertClick}>
                         {localize("INSERT_TEXT")}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
};

export const TinyArtificialIntelligencePluginId = pluginId;

export default TinyArtificialIntelligenceFunction;
