import {
    Dropdown,
    IDropdownStyles,
    PrimaryButton,
    Stack,
    TextField,
    Toggle,
    Label as FluentLabel,
    Separator,
    getWindow,
    isIE11,
} from "@fluentui/react";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { RouteComponentProps, RouterProps } from "react-router";
import { connect } from "react-redux";
import { Label, Loader, UnstyledButton } from "src/ui";
import "./SignIn.scss";
import { setAppSettings } from "../actions";
import SplitAuth from "../splitauth/SplitAuth";
import ThemeContext, { produceTheme } from "src/style/ThemeContext";
import { getQueryStringMap } from "src/utils";
import api from "src/spintr/SpintrApi";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { SpintrTypes } from "src/typings";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { Style } from "src/ui/helpers";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps extends RouteComponentProps {
    dispatch?: (Action) => void;
    appSettings?: { [name: string]: any };
}

interface IState {
    username: string;
    password: string;
    rememberMe: boolean;
    isLoading: boolean;
    error?: string;
    forceForm?: boolean;
    firstTimeData?: any;
    isRevealingPassword: boolean;

    mfaNonce?: string;
    availableMfaMechanisms?: SpintrTypes.AuthenticationMechanisms[];
    selectedMfaMechanism?: SpintrTypes.AuthenticationMechanisms;
    totpValue: string,
}

class SignIn extends Component<IProps, IState> {
    public static contextType = ThemeContext;
    protected revealPasswordAnimationRef?;
    private months = moment.months().map((m, i) => ({
        key: i,
        text: m,
    }));
    private dropdownDateStyles: Partial<IDropdownStyles> = {
        callout: { minWidth: 150, maxWidth: 250 },
    };
    private browserNeedsRevealButton: boolean;

    constructor(props: IProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            rememberMe: true,
            isLoading: false,
            isRevealingPassword: false,
            totpValue: "",
        };

        const win = getWindow();

        if (win?.navigator) {
            // Edge, Chromium Edge
            const isEdge = /Edg/.test(win.navigator.userAgent || "");

            this.browserNeedsRevealButton = !(isIE11() || isEdge);
        } else {
            this.browserNeedsRevealButton = true;
        }
    }

    private onBlurUsername = async (ev) => {
        const username = ev.target.value;

        const pattern =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!pattern.test(username)) {
            return;
        }

        api.get("/api/v1/authentication/domain", {
            params: {
                userName: username,
            },
        }).then((response) => {
            if (!response.data.instanceFound) {
                return;
            }

            const newAppSettings = {
                ...this.props.appSettings,
                color: response.data.primaryColor,
                colorLogoUrl: response.data.logoUrl,
                loginBackgroundImageUrl: response.data.loginBackgroundImageUrl,
            };
            this.props.dispatch(setAppSettings(newAppSettings));
        });
    };

    private onChangeUsername = (ev, val) => {
        this.setState({ username: val });
    };

    private onChangePassword = (ev, val) => {
        this.setState({ password: val });
    };

    private toggleRememberMe = (ev, val) => {
        this.setState((prevState) => ({
            rememberMe: !prevState.rememberMe,
        }));
    };

    private setForceForm = () => {
        this.setState({
            forceForm: true,
        });
    };

    private onSubmit = async (ev) => {
        this.setState({
            isLoading: true,
        });

        api.post("/api/v1/authentication/login", {
            username: this.state.username,
            password: this.state.password,
            RememberMe: this.state.rememberMe,
        })
            .then(this.loginSuccess)
            .catch((error: AxiosError) => {
                const response = error.response;
                console.log(error.response);

                if (response?.status === 401 && response.data?.code === SpintrTypes.LoginResponse.AuthenticatorRequired) {
                    const authenticatorResponse = response.data as Spintr.AuthenticatorRequiredResponse;
                    this.setState({
                        isLoading: false,
                        availableMfaMechanisms: authenticatorResponse.availableMechanisms,
                        mfaNonce: authenticatorResponse.nonce,
                        selectedMfaMechanism: authenticatorResponse.availableMechanisms[0],
                    });
                    
                    return;
                }

                if (typeof (error?.response?.data === "object")) {
                    if (
                        error.response.data.type === SpintrTypes.LoginResponse.FirstTime ||
                        error.response.data.type === SpintrTypes.LoginResponse.FirstTimeAD
                    ) {
                        this.setState({
                            firstTimeData: error.response.data,
                            isLoading: false,
                        });

                        return;
                    } else if (error.response.data.url) {
                        window.location = error.response.data.url;
                        return;
                    }
                }

                this.setState({
                    isLoading: false,
                    error: localize(
                        error?.response?.data === SpintrTypes.LoginResponse.LockedOut
                            ? "DittKontoArLastVarGodKontaktaAdministrator"
                            : "InloggningenMisslyckades"
                    ),
                });
            });
    };

    private onClickPasswordInputButton = () => {
        const segments = this.state.isRevealingPassword ? [14, 24] : [0, 14];
        this.revealPasswordAnimationRef.playSegments(segments, true);

        this.setState((prevState) => ({
            isRevealingPassword: !prevState.isRevealingPassword,
        }));
    };

    private onRenderPasswordInput = (props, defaultRenderer) => {
        props.type = this.state.isRevealingPassword ? "text" : "password";

        return (
            <>
                {defaultRenderer(props)}
                {this.browserNeedsRevealButton && (
                    <button
                        type="button"
                        className="reveal-button ms-Button"
                        aria-pressed={this.state.isRevealingPassword}
                        onClick={this.onClickPasswordInputButton}
                    >
                        <span className="reveal-span">
                            <Visage2Icon icon="eye" />
                        </span>
                    </button>
                )}
            </>
        );
    };

    private async submitTotp(event?: React.FormEvent<HTMLFormElement> | undefined) {
        if (event?.preventDefault) {
            event.preventDefault();
        }

        this.setState({ isLoading: true });

        try {
            const response = await api.post("/api/v1/authentication/totp", {
                otp: this.state.totpValue,
                nonce: this.state.mfaNonce,
                RememberMe: this.state.rememberMe,
            });

            if (response.status < 400) {
                this.loginSuccess(response);
                return;
            }
            
            this.handleTotpError(response);
        } catch (err) {
            const axiosError = err as AxiosError;
            if (!axiosError.isAxiosError) {
                this.setState({ isLoading: false });
                return;
            }

            this.handleTotpError(axiosError.response);
        }
    }

    private handleTotpError(response: AxiosResponse) {
        let errorTag: string | null;
        switch (response.status) {
            case 400:
                errorTag = "RFC6238_INVALID";
                break;

            case 401:
                errorTag = "RFC6238_EXPIRES";
                break;

            case 403: // Too many attempts
                if (response.data?.code === 4031) {
                    this.setState({
                        isLoading: false,
                        selectedMfaMechanism: undefined,
                        availableMfaMechanisms: undefined,
                        error: localize("RFC6238_INVALID"),
                    });
                    return;
                }

                errorTag = "RFC6238_INVALID";
                break;

            case 500:
                errorTag = "TeknisktFel";
                break;

            default:
                errorTag = "TeknisktFel";
                break;
        }

        console.log(errorTag);
        this.setState({
            isLoading: false,
            error: localize(errorTag),
        });

    }

    private onSubmitTotpClicked = () => {
        this.submitTotp();
    }

    private onTotpValueChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!event?.target) {
            return;
        }

        const target = event.target as HTMLInputElement;
        const value = target.value || "";

        this.setState({
            totpValue: value,
        });
    };

    private renderTotpForm = () => {
        return (
            <form onSubmit={this.submitTotp} method="POST" action="/sign-in">
                <TextField
                    label={localize("ONE_TIME_PASSWORD")}
                    value={this.state.totpValue}
                    className="one-time-password"
                    onChange={this.onTotpValueChanged}
                    id="otp"
                />

                <PrimaryButton
                    text={localize("LoggaIn")}
                    onClick={this.onSubmitTotpClicked}
                    className="sign-in-button"
                    type="submit"
                />
            </form>
        );
    };

    private renderForm = () => {
        if (this.state.selectedMfaMechanism) {
            return this.renderTotpForm();
        }

        return (
            <>
                <form>
                    <TextField
                        label={`${localize("Anvandarnamn")}/${localize("Epost")}`}
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        onBlur={this.onBlurUsername}
                        className="email-input"
                        id="username"
                    />

                    <TextField
                        label={localize("Losenord")}
                        onChange={this.onChangePassword}
                        className="password-input"
                        id="password"
                        type="password"
                        onRenderInput={this.onRenderPasswordInput}
                    />

                    <div className="remember-forgot">
                        <Toggle
                            label={localize("HallMigInloggad")}
                            checked={this.state.rememberMe}
                            onChange={this.toggleRememberMe}
                            inlineLabel
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                        />

                        <Link to="/forgot-password">
                            <Label className="forgot-link" size="body-2" weight="medium">
                                {localize("GlomtLosenord") + "?"}
                            </Label>
                        </Link>
                    </div>

                    <PrimaryButton
                        text={localize("LoggaIn")}
                        onClick={this.onSubmit}
                        className="sign-in-button"
                        type="submit"
                    />
                </form>
            </>
        );
    };

    private loginSuccess = (response) => {
        const qs = getQueryStringMap(this.props.location);

        console.log(qs);
        if (qs.return) {
            this.props.history.replace(qs.return);
        } else if (qs.ReturnUrl) {
            this.props.history.replace(qs.ReturnUrl);
        } else {
            this.props.history.replace("/");
        }
    };

    private submitFirstTime = () => {
        this.setState({
            isLoading: true,
        });

        api.post("/api/v1/authentication/firsttime", {
            username: this.state.username,
            password: this.state.password,
            Name: this.state.firstTimeData.name,
            BirthdateMonth: this.state.firstTimeData.month,
            BirthdateDay: this.state.firstTimeData.day,
            Gender: 0,
        })
            .then(this.loginSuccess)
            .catch((error: AxiosError) => {
                this.setState({
                    isLoading: false,
                    error: error?.response?.data?.errorlist
                        ? error.response.data.errorlist.map((langTag) => localize(langTag)).join(" - ")
                        : localize("InloggningenMisslyckades"),
                });
            });
    };

    private renderFirstTime = (firstTimeData) => (
        <>
            <Label>{localize("ForstaGangenDinAnvandareLoggasInVarGodFyllIDinaUppgifterNedan")}</Label>
            <TextField
                label={localize("Namn")}
                value={firstTimeData.name}
                onChange={(ev, value) => {
                    this.setState((prevState) => ({
                        firstTimeData: {
                            ...prevState.firstTimeData,
                            name: value,
                        },
                    }));
                }}
            />
            {firstTimeData.birthdaysEnabled && (
                <div className="birthday-pickers">
                    <FluentLabel>{localize("Fodelsedatum")}</FluentLabel>
                    <Stack horizontal tokens={{ childrenGap: Style.getSpacing(5) }}>
                        <Dropdown
                            selectedKey={firstTimeData.day || 1}
                            options={Array.from({
                                length: moment(`2000-${firstTimeData.month + 1}`).daysInMonth(),
                            }).map((_, idx) => ({
                                key: idx + 1,
                                text: (idx + 1).toString(),
                            }))}
                            styles={this.dropdownDateStyles}
                            onChange={(evt, option) => {
                                this.setState((prevState) => ({
                                    firstTimeData: {
                                        ...firstTimeData,
                                        day: option.key,
                                    },
                                }));
                            }}
                        />
                        <Dropdown
                            selectedKey={firstTimeData.month}
                            options={this.months}
                            styles={this.dropdownDateStyles}
                            onChange={(evt, option) => {
                                this.setState((prevState) => ({
                                    firstTimeData: {
                                        ...firstTimeData,
                                        month: option.key,
                                    },
                                }));
                            }}
                        />
                    </Stack>
                </div>
            )}
            <PrimaryButton className="sign-in-button" text={localize("LoggaIn")} onClick={this.submitFirstTime} />
        </>
    );

    private renderOr = () => (
        <Separator
            styles={{
                root: {
                    padding: "30px",
                    selectors: {
                        ":before": {
                            backgroundColor: Style.getHexFromSpintrColor("borderColor"),
                        },
                    },
                },
            }}
        >
            <Label style={{ color: this.props.appSettings.color }}>{localize("Eller")}</Label>
        </Separator>
    );

    private renderExternalLogin = (externalLogin) => (
        <>
            <PrimaryButton
                className="external-login-button"
                text={localize("LoggaIn")}
                onClick={() => {
                    window.location = externalLogin;
                }}
            />

            {this.renderOr()}

            <UnstyledButton onClick={this.setForceForm} className="external-sign-in-button">
                <Label color="light-blue" underline centerText>
                    {localize("SIGN_IN_AS_EXTRNAL")}
                </Label>
            </UnstyledButton>
        </>
    );

    private renderExternalLogins = (externalLogins) => (
        <>
            <Label className="choose-login-text">{localize("ValjHurDuVillLoggaIn")}</Label>

            {externalLogins.map((externalLogin, idx) => {
                const backgroundColor = externalLogin.backgroundColor || this.props.appSettings.color;
                const borderColor = externalLogin.borderColor || "transparent";
                const textColor =
                    externalLogin.textColor || getLightOrDarkColorBasedOnColor(backgroundColor, "#fff", "#000");
                return (
                    <div key={idx}>
                        <UnstyledButton
                            className="external-login-button"
                            style={{ backgroundColor, borderColor }}
                            onClick={() => {
                                window.location = externalLogin.url;
                            }}
                        >
                            {externalLogin.image ? <img src={externalLogin.image} height={24} /> : null}
                            <Label size="body-1" weight="medium" style={{ color: textColor }}>
                                {externalLogin.text}
                            </Label>
                        </UnstyledButton>
                        {this.renderOr()}
                    </div>
                );
            })}

            <UnstyledButton onClick={this.setForceForm} className="external-sign-in-button">
                <Label color="light-blue" underline centerText>
                    {localize("SIGN_IN_AS_EXTRNAL")}
                </Label>
            </UnstyledButton>
        </>
    );

    public render() {
        const { isLoading, error, forceForm, firstTimeData } = this.state;
        const { appSettings } = this.props;

        const qs = getQueryStringMap(this.props.location);
        if (appSettings.forceExternalLogin && appSettings.externalLogin && qs.ignoreForce !== "1") {
            window.location = appSettings.externalLogin;

            return null;
        }

        let externalLogins;
        if (appSettings.externalLogins) {
            externalLogins = JSON.parse(appSettings.externalLogins);
        }

        return (
            <div className="login-view">
                <SplitAuth error={error} backgroundUrl={appSettings.loginBackgroundImageUrl}>
                    <Label as="h2" size="h2" weight="medium" className="sign-in-label">
                        {localize("SIGN_IN_HEADER_TEXT").replace("{X}", this.props.appSettings.name || "Spintr")}
                    </Label>

                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {firstTimeData
                                ? this.renderFirstTime(firstTimeData)
                                : externalLogins && !forceForm
                                ? this.renderExternalLogins(externalLogins)
                                : appSettings.externalLogin && !forceForm
                                ? this.renderExternalLogin(appSettings.externalLogin)
                                : this.renderForm()}
                        </>
                    )}
                </SplitAuth>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: any) => {
    return {
        ...props,
        appSettings: state.auth.appSettings,
    };
};

const ConnectedSignIn = connect(mapStateToProps)(SignIn);
const ConnectedSignInWithRouter = withRouter(ConnectedSignIn);

export default ConnectedSignInWithRouter;
