export const FETCH_AUTH_DATA = "FETCH_AUTH_DATA";

export const FETCH_AUTH_DATA_PENDING = "FETCH_AUTH_DATA_PENDING"
export interface IFetchAuthDataPendingAction {
    type: typeof FETCH_AUTH_DATA_PENDING;
}

export const FETCH_AUTH_DATA_REJECTED = "FETCH_AUTH_DATA_REJECTED";
export interface IFetchAuthDataRejectedAction extends Spintr.IAsyncActionError<any> {
    type: typeof FETCH_AUTH_DATA_REJECTED;
}

export const FETCH_AUTH_DATA_FULFILLED = "FETCH_AUTH_DATA_FULFILLED"
export interface IFetchAuthDataFulfilledAction extends Spintr.IAsyncAction<any> {
    type: typeof FETCH_AUTH_DATA_FULFILLED;
}

export type FetchAuthDataAction = 
      IFetchAuthDataPendingAction
    | IFetchAuthDataRejectedAction
    | IFetchAuthDataFulfilledAction;

export const SET_APP_SETTINGS = "SET_APP_SETTINGS";

export interface ISetAppSettingsAction {
    type: typeof SET_APP_SETTINGS;
    data: any;
}

export type SetAppSettingsAction = ISetAppSettingsAction;

export type AuthAction = 
      FetchAuthDataAction
    | SetAppSettingsAction;