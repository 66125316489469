import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { BasePickerListBelow, ISuggestionItemProps } from "@fluentui/react/lib/Pickers";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import * as React from "react";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { NoOp } from "src/utils";
import api from "src/spintr/SpintrApi";

export interface IPickerState {
    contextualMenuVisible?: boolean;
    contextualMenuTarget?: HTMLElement;
    targets: any[];
}

const rootClass = mergeStyles({
    maxWidth: 500,
});

const SuggestedBigItem: (target, itemProps) => JSX.Element = (target: any, itemProps: ISuggestionItemProps<any>) => {
    return <Persona imageUrl={target && target.imageUrl} text={target.name} size={PersonaSize.size40} />;
};

export class TargetPicker extends React.Component<
    { itemSelected: any; existingIds: number[]; types: Spintr.UberType[], excludeEmptyRoles?: boolean, ownDepartmentsOnly?: boolean },
    IPickerState
> {
    constructor(props) {
        super(props);
        this._onFilterChanged = this._onFilterChanged.bind(this);
        this.state = {
            targets: [],
        };
    }

    private updateSearch = s => {
        const { types, excludeEmptyRoles, ownDepartmentsOnly } = this.props;
        const params = {
            excludeDepartments: types.includes(SpintrTypes.UberType.Department) ? false : true,
            excludeGroups: types.includes(SpintrTypes.UberType.Group) ? false : true,
            excludeOffices: types.includes(SpintrTypes.UberType.Office) ? false : true,
            excludeEmptyRoles: excludeEmptyRoles,
            excludeRoles: types.includes(SpintrTypes.UberType.Role) ? false : true,
            excludeUsers: types.includes(SpintrTypes.UberType.User) ? false : true,
            ownDepartmentsOnly,
            searchAllGroups: true,
            searchPhrase: s,
            take: 10,
        };
        const targets = api.get("/api/v1/targets", { params });

        targets.then(response => {
            this.setState({ targets: response.data });
        });
    };

    public render(): JSX.Element {
        let { types } = this.props;
        let name = "";
        if (types.includes(SpintrTypes.UberType.Group)) {
            name = "Grupp";
        } else if (types.includes(SpintrTypes.UberType.Office)) {
            name = "Kontor";
        } else if (types.includes(SpintrTypes.UberType.Role)) {
            name = "Roll";
        } else if (types.includes(SpintrTypes.UberType.User)) {
            name = "Anvandare";
        }

        return (
            <div className={rootClass}>
                <BasePickerListBelow
                    removeButtonAriaLabel="Remove"
                    onRenderSuggestionsItem={SuggestedBigItem as any}
                    onResolveSuggestions={this._onFilterChanged}
                    getTextFromItem={this._getTextFromItem}
                    onItemSelected={t => this.props.itemSelected(t)}
                    pickerSuggestionsProps={
                        {
                            //  suggestionsHeaderText: "Suggested Groups",
                            //    noResultsFoundText: "No groups Found",
                        }
                    }
                    
                    inputProps={
                        {
                            "onBlur": NoOp,
                            "placeholder": localize("SokOchLaggTill")
                             ,"aria-label": "Group Picker"
                        }
                    }
                />
            </div>
        );
    }

    private _getTextFromItem(props: any): any {
        return props.name;
    }

    private _onFilterChanged(filterText: string) {
        this.updateSearch(filterText);

        return filterText ? this.state.targets.filter(item => !this.props.existingIds.includes(item.id)) : [];
    }
}
