import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PayrollView.scss";
import { Checkbox, DefaultButton, Dropdown, Modal, PrimaryButton, SpinButton, Stack, TextField } from "@fluentui/react";
import { localize } from "src/l10n";
import { FormControl, FormSection } from "src/ui/components/Forms";
import { connect, useDispatch } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import { ActionMenu, Loader } from "src/ui";
import { AxiosError, AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { validateRequiredTextField } from "src/utils";
import PopupHeader from "src/ui/components/PopupHeader";
import { setConfirmPopup } from "src/popups/actions";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";

interface IProps {
    currentUser?: IActiveUserProfile;
    categories: any[];
    offices: any[];
}

interface IState {
    includeDeleted: boolean;
    showModal: boolean;
    item: any;
    isLoading: boolean;
    categoryId: number;
    officeId: number;
    saveError: string[];
}

const getEmptyItem = (categories: any[], currentUser: IActiveUserProfile) => {
    return {
        id: 0,
        categoryId: categories.filter(x => x.id !== 0)[0].id,
        sex: 1,
        officeId: currentUser.department.office.id,
        externalid: 1,
        birthYear: 1970,
        generateExternalId: true
    }
}

const PayrollEntries = (props: IProps) => {
    const listRef = useRef<SpintrList>();
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        includeDeleted: false,
        showModal: false,
        item: getEmptyItem(props.categories, props.currentUser),
        isLoading: false,
        categoryId: 0,
        officeId: 0,
        saveError: []
    });

    const closeModal = useCallback(() => {
        setState(s => ({
            ...s,
            showModal: false
        }));
    }, []);
    
    const save = useCallback(() => {
        setState(s => ({
            ...s,
            isLoading: true,
            saveError: []
        }));

        api.post("/api/v1/payroll/entries", state.item).then(() => {
            listRef.current.reFetch();

            setState(s => ({
                ...s,
                isLoading: false,
                showModal: false,
                item: getEmptyItem(props.categories, props.currentUser)
            }));
        }).catch((saveError: AxiosError) => {
            const hasValidationErrorList = !!saveError &&
                !!saveError.response &&
                !!saveError.response.data &&
                !!saveError.response.data.errorlist;

            setState(s => ({
                ...s,
                isLoading: false,
                saveError: hasValidationErrorList ?
                    saveError.response.data.errorlist :
                    ["TeknisktFel"]
            }));
        });
    }, [state.item]);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        listRef.current.reFetch();
    }, [state.includeDeleted, state.categoryId, state.officeId]);

    return (
        <div>
            <SpintrList
                ref={listRef}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        api.get(`/api/v1/payroll/entries`, {
                            params: {
                                includeDeleted: false,
                                isAscending,
                                orderByColumn: columnId,
                                searchQuery: searchQuery,
                                skip,
                                take,
                                categoryId: state.categoryId === 0 ? undefined : state.categoryId,
                                officeId: state.officeId === 0 ? undefined : state.officeId
                            },
                        })
                        .then((response: AxiosResponse) => {
                            resolve({
                                data: response.data.items,
                                totalCount: response.data.totalCount
                            });
                        }).catch(() => {});
                    });
                }}
                take={10}
                orderByColumn={"externalId"}
                columns={[{
                    name: localize("EXTERNAL_ID"),
                    fieldName: "externalId",
                    minWidth: 50,
                    maxWidth: 50
                }, {
                    name: localize("SEX"),
                    fieldName: "sex",
                    onRender: (item) => {
                        const names = ["Okand", "Man", "Kvinna"];

                        return (
                            <span>{localize(names[item.sex])}</span>
                        )
                    },
                    minWidth: 50,
                    maxWidth: 50
                }, {
                    name: localize("BIRTH_YEAR"),
                    fieldName: "birthYear",
                    minWidth: 75,
                    maxWidth: 75
                }, {
                    name: localize("INDUSTRY_EXPERIENCE"),
                    fieldName: "experienceYearCount",
                    minWidth: 125,
                    maxWidth: 125
                }, {
                    name: localize("REGULAR_MONTHLY_SALARY"),
                    fieldName: "salary",
                    minWidth: 125,
                    maxWidth: 125
                }, {
                    name: localize("PERFORMANCE_BASED_SALARY"),
                    fieldName: "performanceBasedSalary",
                    minWidth: 125,
                    maxWidth: 125
                }, {
                    name: localize("BENEFITS"),
                    fieldName: "benefits",
                    minWidth: 125,
                    maxWidth: 125
                }, {
                    name: localize("SUM_ALL_SALARIES"),
                    fieldName: "salarySum",
                    minWidth: 160,
                    maxWidth: 160
                }, {
                    name: localize("Kategori"),
                    fieldName: "categoryName",
                    onRender: (item) => {
                        return (
                            <span>{item.category.nameShort}</span>
                        )
                    },
                    minWidth: 150,
                    maxWidth: 150
                }, {
                    name: "",
                    key: "actionmenu",
                    minWidth: 40,
                    onRender: (item: any) => {
                        return (
                            <ActionMenu
                                categories={[
                                    {
                                        items: [
                                            {
                                                text: localize("Redigera"),
                                                onClick: () => {
                                                    setState(s => ({
                                                        ...s,
                                                        showModal: true,
                                                        item,
                                                        saveError: []
                                                    }));
                                                },
                                            },
                                            {
                                                text: localize("TaBort"),
                                                onClick: () => {
                                                    dispatch(setConfirmPopup({
                                                        isOpen: true,
                                                        title: localize("ArDuSakerAttDuVillRaderaDennaPost"),
                                                        onConfirm: () => {
                                                            api.post("/api/v1/payroll/entries", {
                                                                ...item,
                                                                isDeleted: !item.isDeleted
                                                            }).then(() => {
                                                                listRef.current.reFetch();
                                                            }).catch(() => {});
                                                        }
                                                    }));
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        );
                    },
                }]}
                optionItemsTitle={localize("Filtrera")}
                optionItems={props.currentUser.settings.isPayrollAdministrator && {
                    items: [{
                        key: "0",
                        text: localize("Kategori"),
                        subMenuProps: {
                            items: [
                                {
                                    key: "0",
                                    text: localize("Alla"),
                                    className: state.categoryId === 0 ? "active-option-items-item" : "",
                                    onClick: () => {
                                        setState(s => ({
                                            ...s,
                                            categoryId: 0
                                        }));
                                    }
                                },
                                ...props.categories.map((d) => ({
                                    key: d.id,
                                    text: d.name,
                                    className: state.categoryId === d.id ? "active-option-items-item" : "",
                                    onClick: () => {
                                        setState(s => ({
                                            ...s,
                                            categoryId: d.id
                                        }));
                                    }
                                }))
                            ]
                        }
                    }, {
                        key: "1",
                        text: localize("Kontor"),
                        subMenuProps: {
                            items: [
                                {
                                    key: "0",
                                    text: localize("Alla"),
                                    className: state.officeId === 0 ? "active-option-items-item" : "",
                                    onClick: () => {
                                        setState(s => ({
                                            ...s,
                                            officeId: 0
                                        }));
                                    }
                                },
                                ...props.offices.map((d) => ({
                                    key: d.id,
                                    text: d.name,
                                    className: state.officeId === d.id ? "active-option-items-item" : "",
                                    onClick: () => {
                                        setState(s => ({
                                            ...s,
                                            officeId: d.id
                                        }));
                                    }
                                }))
                            ]
                        }
                    }]
                }}
                buttons={[
                    {
                        key: "add",
                        text: localize("SkapaNy"),
                        onClick: () => {
                            setState(s => ({
                                ...s,
                                showModal: true,
                                item: getEmptyItem(props.categories, props.currentUser),
                                saveError: []
                            }));
                        },
                        iconProps: { iconName: "Add" },
                        className: "commandBarAddButton",
                    },
                ]}
            />
            <Modal
                allowTouchBodyScroll
                containerClassName="calendar-event-popup"
                className="spintr-modal modalWithPopupHeader"
                isOpen={state.showModal}
                onDismiss={closeModal}
            >
                <PopupHeader
                    text={state.item.id === 0 ? localize("SkapaNy") : localize("Redigera")}
                    onClose={closeModal} />
                <div>
                    {state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={state.saveError}
                            onDismiss={() => {
                                setState(s => ({
                                    ...s,
                                    saveError: []
                                }));
                            }}
                        />
                    )}
                    {state.isLoading && <Loader />}
                    {!state.isLoading && (
                        <div className="popup-inner">
                            <FormSection>
                                <FormControl>
                                    <Dropdown
                                        placeholder={localize("SELECT_CATEGORY")}
                                        options={props.categories.filter(x => x.id !== 0).map((d) => ({
                                            key: d.id,
                                            text: d.name,
                                        }))}
                                        styles={{ dropdown: { width: 250 } }}
                                        label={localize("SELECT_CATEGORY")}
                                        selectedKey={state.item.categoryId}
                                        onChange={(event, option) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    categoryId: Number(option.key)
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                {props.currentUser.settings.isPayrollAdministrator && (
                                    <FormControl>
                                        <Dropdown
                                            disabled={state.item.id !== 0}
                                            placeholder={localize("Kontor")}
                                            options={props.offices.map((d) => ({
                                                key: d.id,
                                                text: d.name,
                                            }))}
                                            styles={{ dropdown: { width: 250 } }}
                                            label={localize("Kontor")}
                                            selectedKey={state.item.officeId}
                                            onChange={(event, option) => {
                                                setState(s => ({
                                                    ...s,
                                                    item: {
                                                        ...s.item,
                                                        officeId: Number(option.key)
                                                    }
                                                }))
                                            }}
                                        />
                                    </FormControl>
                                )}
                                <FormControl label={localize("EXTERNAL_ID")}>
                                    {state.item.id === 0 && (
                                        <Checkbox
                                            label={localize("GENERATE_ID")}
                                            checked={state.item.generateExternalId}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                setState(s => ({
                                                    ...s,
                                                    item: {
                                                        ...s.item,
                                                        generateExternalId: checked
                                                    }
                                                }));
                                            }}
                                        />
                                    )}
                                    {!state.item.generateExternalId && (
                                        <SpinButton
                                            disabled={state.item.id !== 0}
                                            className="spinButton"
                                            defaultValue={state.item.externalId}
                                            min={1}
                                            step={1}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                                setState(s => ({
                                                    ...s,
                                                    item: {
                                                        ...s.item,
                                                        externalId: newValue
                                                    }
                                                }))
                                            }}
                                        />
                                    )}
                                </FormControl>
                                <FormControl>
                                    <Dropdown
                                        placeholder={localize("SEX")}
                                        options={[
                                            {
                                                key: 1,
                                                text: localize("Man")
                                            },
                                            {
                                                key: 2,
                                                text: localize("Kvinna")
                                            },
                                        ]}
                                        required
                                        styles={{ dropdown: { width: 250 } }}
                                        label={localize("SEX")}
                                        selectedKey={state.item.sex}
                                        onChange={(event, option) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    sex: Number(option.key)
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <FormControl label={localize("BIRTH_YEAR")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={state.item.birthYear}
                                        min={0}
                                        step={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    birthYear: newValue
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <FormControl label={localize("INDUSTRY_EXPERIENCE")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={state.item.experienceYearCount}
                                        min={0}
                                        step={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    experienceYearCount: newValue
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <FormControl label={localize("REGULAR_MONTHLY_SALARY")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={state.item.salary}
                                        min={0}
                                        step={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    salary: newValue
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <FormControl label={localize("PERFORMANCE_BASED_SALARY")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={state.item.performanceBasedSalary}
                                        min={0}
                                        step={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    performanceBasedSalary: newValue
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <FormControl label={localize("BENEFITS")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={state.item.benefits}
                                        min={0}
                                        step={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    benefits: newValue
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                    <DefaultButton text={localize("Avbryt")} onClick={closeModal} />
                                    <PrimaryButton
                                        text={localize(state.item.id === 0 ? "Skapa" : "Spara")}
                                        onClick={save}
                                    />
                                </Stack>
                            </FormSection>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(PayrollEntries);
