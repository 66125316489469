import { IconButton, Modal, Pivot, PivotItem } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { Label, Loader, UnstyledButton } from "src/ui";
import PopupHeader from "src/ui/components/PopupHeader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { listActionMenuWidth } from "src/ui/helpers/style";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { FormControl } from "../ui/components/Forms";
import DeviationsForm from "./DeviationsForm";

interface Props {
    pageId: number;
    userDepartmentId: number;
    isAdmin: boolean;
}

interface State {
    isLoading: boolean;
    categories: any[];
    editMode: boolean;
    item: any;
    deviations: any[];
    showDetails: boolean;
    onlyDeleted: boolean;
    searchText: string;
    showAll: boolean;
}

class DeviationsInPageView extends Component<Props, State> {
    private listRef = React.createRef<SpintrList>();

    state = {
        showDetails: false,
        isLoading: false,
        editMode: false,
        categories: [],
        item: {} as any,
        deviations: [],
        onlyDeleted: false,
        searchText: "",
        showAll: false,
    };

    componentDidMount = () => {
        api.get("/api/v1/msystem/deviationcategories").then((response) => {
            for (let c of response.data) {
                c.key = c.id;
                c.text = c.name;
            }
            this.setState({ categories: response.data });
        });
    };

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    detailsModal = () => {
        const { item } = this.state;
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showDetails}
                onDismiss={() => {
                    this.setState({ showDetails: false });
                }}
            >
                <PopupHeader
                    text={localize("Avvikelse")}
                    onClose={() => {
                        this.setState({ showDetails: false });
                    }} />
                <div>
                    <Pivot overflowBehavior={"menu"}>
                        <PivotItem headerText={localize("Avvikelse")}>
                            <FormControl label={localize("SkapadAv")}>
                                <Label as="p" size="body-4">
                                    {item.creator.name}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("Avdelning")}>
                                <Label as="p" size="body-4">
                                    {item.departmentName}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("BeskrivningAvAvvikelsen")}>
                                <Label as="p" size="body-4">
                                    {item.step1}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("VadHarAtgardats")}>
                                <Label as="p" size="body-4">
                                    {item.step2}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("HurForhindrarVi")}>
                                <Label as="p" size="body-4">
                                    {item.step3}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("Uppfoljning")}>
                                <Label as="p" size="body-4">
                                    {item.step4}
                                </Label>
                            </FormControl>
                        </PivotItem>
                        <PivotItem headerText={localize("Handelseanalys")}>
                            <FormControl label={localize("InitieraHandelseanalys")}>
                                <Label as="p" size="body-4">
                                    {item.action1}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("BakomliggandeOrsaker")}>
                                <Label as="p" size="body-4">
                                    {item.action2}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("Handelseforlopp")}>
                                <Label as="p" size="body-4">
                                    {item.action3}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("IdentifieraBakomliggande")}>
                                <Label as="p" size="body-4">
                                    {item.action4}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("ForeslaAtgarder")}>
                                <Label as="p" size="body-4">
                                    {item.action5}
                                </Label>
                            </FormControl>
                            <FormControl label={localize("Riskbedomning")}>
                                <Label as="p" size="body-4">
                                    {item.action6}
                                </Label>
                            </FormControl>
                        </PivotItem>
                    </Pivot>
                </div>
            </Modal>
        );
    };

    render() {
        const { isLoading, editMode, showDetails, onlyDeleted, searchText, showAll } = this.state;
        const { pageId, isAdmin } = this.props;

        if (isLoading) return <Loader />;

        const myDepartmentFarItem = {
            key: "myDepartment",
            text: showAll ? localize("MinAvdelning") : localize("VisaAlla"),
            onClick: () => {
                this.setState({ showAll: !showAll }, () => {
                    this.listRef.current.fetch();
                });
            },
        };

        const showCurrentFarItem = {
            key: "showCurrent",
            text: onlyDeleted ? localize("Aktuella") : localize("Arkiverade"),
            onClick: () => {
                this.setState({ onlyDeleted: !onlyDeleted }, () => {
                    this.listRef.current.fetch();
                });
            },
        };

        return (
            <div className="DeviationsInPageView">
                <Label as="h4" size="h4">
                    {localize("Avvikelser")}
                </Label>

                {showDetails && this.detailsModal()}

                {!editMode ? (
                    <>
                        <UnstyledButton
                            className="primaryFGColor primaryBorderColorHover"
                            onClick={() => {
                                this.setState({ editMode: true });
                            }}
                        >
                            {localize("LaggTill")}
                        </UnstyledButton>

                        <SpintrList
                            disableSearch
                            ref={this.listRef}
                            fetch={(skip, take, columnId, isAscending, searchText) => {
                                return new Promise((resolve, reject) => {
                                    let departmentId = 0
                                    if (!showAll) {
                                        departmentId = this.props.userDepartmentId
                                    }

                                    api
                                        .get(
                                            `/api/v1/msystem/deviations`,
                                            { params: { departmentId, isAscending, onlyDeleted, columnId, searchText, skip, take, pageId } }
                                        )
                                        .then((response) => {
                                            let { data } = response;
                                            for (var i = 0; i < data.length; i++) {
                                                data[i].date = new Date(data[i].date);

                                                if (data[i].status == 2) data[i].statusName = localize("Paborjad");
                                                else if (data[i].status == 3) data[i].statusName = localize("Beslutad");
                                                else if (data[i].status == 4)
                                                    data[i].statusName = localize("Genomford");
                                                else if (data[i].status == 5)
                                                    data[i].statusName = localize("Arkiverad");
                                                else data[i].statusName = localize("EjPaborjad");
                                            }
                                            this.setState({
                                                deviations: data,
                                                isLoading: false,
                                                searchText,
                                            });
                                            resolve({ data, totalCount: response.headers["x-total-count"] });
                                        });
                                });
                            }}
                            orderByColumn="name"
                            farItems={[
                                {
                                    key: "actionMenu",
                                    title: localize("Alternativ"),
                                    iconProps: { iconName: "More" },
                                    subMenuProps: {
                                        className: "displayOptions",
                                        items: isAdmin ?
                                            [myDepartmentFarItem, showCurrentFarItem] :
                                            [showCurrentFarItem]
                                    },
                                },
                            ]}
                            columns={[
                                {
                                    key: "column00",
                                    name: "",
                                    fieldName: "",
                                    minWidth: 15,
                                    maxWidth: 15,
                                    isResizable: true,
                                    isCollapsible: true,
                                    data: "string",
                                    onRender: (item: any) => {
                                        return (
                                            <span>
                                                <UnstyledButton onClick={() => {
                                                    this.setState({ showDetails: true, item });
                                                }}>
                                                    <Visage2Icon icon="add-circle" />
                                                </UnstyledButton>
                                            </span>
                                        );
                                    },
                                },
                                {
                                    key: "column0",
                                    name: localize("Datum"),
                                    fieldName: "date",
                                    minWidth: 90,
                                    maxWidth: 120,
                                    isRowHeader: true,
                                    isResizable: true,
                                    isSorted: true,
                                    isSortedDescending: false,
                                    data: "number",
                                    isPadded: true,
                                    onRender: (item) => {
                                        return <span>{item.date && this.formatDate(item.date)}</span>;
                                    },
                                },
                                {
                                    key: "column1",
                                    name: localize("Kategori"),
                                    fieldName: "category.name",
                                    minWidth: 90,
                                    maxWidth: 120,
                                    isResizable: true,
                                    data: "string",
                                    isPadded: true,
                                    onRender: (item) => {
                                        return <span>{item.category && item.category.name}</span>;
                                    },
                                },
                                {
                                    key: "column3",
                                    name: localize("Avvikelse"),
                                    fieldName: "title",
                                    minWidth: 90,
                                    maxWidth: 220,
                                    isResizable: true,
                                    data: "string",
                                    isPadded: true,
                                },
                                {
                                    key: "column4",
                                    name: localize("Status"),
                                    fieldName: "statusName",
                                    minWidth: 90,
                                    maxWidth: 120,
                                    isResizable: true,
                                    data: "string",
                                    isPadded: true,
                                },
                                {
                                    key: "column6",
                                    name: "",
                                    fieldName: "",
                                    minWidth: listActionMenuWidth,
                                    maxWidth: listActionMenuWidth,
                                    isResizable: true,
                                    isCollapsible: true,
                                    data: "string",
                                    onRender: (item: any) => {
                                        return (
                                            <IconButton
                                                iconProps={{ iconName: "More" }}
                                                text=""
                                                menuProps={{
                                                    shouldFocusOnMount: true,
                                                    items: [
                                                        {
                                                            key: "edit",
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.setState({ item, editMode: true });
                                                            },
                                                        },
                                                        {
                                                            key: "archive",
                                                            text: item.deleted
                                                                ? localize("Aterstall")
                                                                : localize("Arkivera"),
                                                            onClick: () => {
                                                                this.setState({ isLoading: true }, () => {
                                                                    api
                                                                        .post(
                                                                            `/api/v1/msystem/deviation/${item.id}/toggleDeleted`
                                                                        )
                                                                        .then((_response) => {
                                                                            this.setState({ isLoading: false }, () => {
                                                                                // this.fetch();
                                                                            });
                                                                        });
                                                                });
                                                            },
                                                        },
                                                    ],
                                                }}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                ) : (
                    <DeviationsForm
                        showFormFooterBar
                        item={this.state.item}
                        categories={this.state.categories}
                        onCancel={() => {
                            this.setState({ item: {} as any, editMode: false });
                        }}
                        onSaved={() => {
                            this.setState({ isLoading: false, editMode: false, item: {} as any });
                        }}
                        pageId={pageId}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        userDepartmentId: state.profile.active.department.id,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default connect(mapStateToProps)(DeviationsInPageView);
