import { getFileTypeIconProps } from "@fluentui/react-file-type-icons/lib/getFileTypeIconProps";
import {
    Dropdown,
    Icon,
    Modal,
    Pivot,
    PivotItem,
    PrimaryButton,
    SearchBox,
    Separator,
    Stack
} from "@fluentui/react";
import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { ContentWithSubmenu, Label, Loader, PageHeader, setPublic360UnitLevel1, setPublic360UnitLevel2, Submenu } from "src/ui";
import { FormControl, FormSection } from "src/ui/components/Forms";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import { debounce, getQueryStringMap } from "src/utils";
import "./Public360MainView.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";

interface IProps extends RouteComponentProps {
    selectedUnitLevel1?: any;
    selectedUnitLevel2?: any;
    title?: string;
    currentUser: IActiveUserProfile;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    isLoadingDocuments?: boolean;
    showUnitModal: boolean;
    units?: any[];
    menu?: any[];
    selectedUnitLevel1?: any;
    selectedUnitLevel2?: any;
    selectedSubmenuItem?: any;
    documents?: any;
    folderTitle?: string;
    searchText: string;
    activeFilter: string;
}

class Public360MainView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showUnitModal: false,
            searchText: getQueryStringMap(props.location)?.search || "",
            activeFilter: "1",
        };
    }

    public async componentDidMount() {
        const menu = await api.get("/api/v1/public360/menu");

        const self = this;
        const menuData = menu.data.map(function f(menuItem) {
            menuItem.name = menuItem.code;
            menuItem.id = menuItem.recno;
            menuItem.key = menuItem.recno;
            menuItem.action = () => {
                self.props.history.push({
                    search: qs.stringify({
                        search: undefined,
                    }),
                });

                self.setState({ selectedSubmenuItem: menuItem, searchText: "" }, () => {
                    self.fetchData();
                });
            };

            menuItem.nodes = (menuItem.children || []).map(f, this);

            return menuItem;
        });

        var foundUnitLevel1 = this.props.selectedUnitLevel1;
        var foundUnitLevel2 = this.props.selectedUnitLevel2;

        menuData.unshift({
            name: localize("Startsidan"),
            id: 0,
            action: () => {
                this.setState({ selectedSubmenuItem: { id: 0 }, searchText: "" }, () => {
                    self.fetchData();
                });
            },
            nodes: [],
        });

        const units = await api.get("/api/v1/public360/units");

        const unitData = units.data.map((unit) => {
            unit.text = unit.name;
            unit.key = unit.key || unit.recno;

            unit.children.unshift({
                children: [],
                level: 2,
                name: localize("AllaAvdelningar"),
                parent: unit.recno,
                recno: null,
                key: "0",
            });

            if (
                !this.props.selectedUnitLevel1 &&
                this.props.currentUser.department.office.name.toLowerCase().indexOf(unit.name.toLowerCase()) >= 0
            ) {
                foundUnitLevel1 = unit;
            }

            unit.children = unit.children.map((childUnit) => {
                if (childUnit.name.indexOf(unit.name) === 0) {
                    childUnit.name = childUnit.name.substring(unit.name.length);
                }

                childUnit.text = childUnit.name;
                childUnit.key = childUnit.key || childUnit.recno;

                if (foundUnitLevel1 && !foundUnitLevel2) {
                    if (
                        childUnit.name.length > 0 &&
                        this.props.currentUser.department.name.toLowerCase().indexOf(childUnit.name.toLowerCase()) >= 0
                    ) {
                        foundUnitLevel2 = childUnit;
                    }
                }
                return childUnit;
            });

            return unit;
        });

        if (foundUnitLevel1) {
            this.props.dispatch(setPublic360UnitLevel1(foundUnitLevel1));
        }

        if (foundUnitLevel2) {
            this.props.dispatch(setPublic360UnitLevel2(foundUnitLevel2));
        }

        this.setState(
            {
                units: unitData,
                menu: menuData,
                selectedUnitLevel1: foundUnitLevel1,
                selectedUnitLevel2: foundUnitLevel2,
                isLoading: false,
            },
            () => {
                if (!foundUnitLevel1) {
                    this.setState({
                        showUnitModal: true,
                    });
                } else {
                    this.fetchData();
                }
            }
        );
    }

    private closeUnitModal = () => {
        this.setState({
            showUnitModal: false,
        });
    };

    private fetchData = async () => {
        this.setState({
            isLoadingDocuments: true,
            activeFilter: "1",
        });

        var req;
        var folderTitle;

        if (
            (!this.state.selectedSubmenuItem || this.state.selectedSubmenuItem.id === 0) &&
            this.state.searchText.length === 0
        ) {
            req = api.get("/api/v1/public360/documents/lastchanged", {
                params: {
                    unitLevel1: this.state.selectedUnitLevel1.recno,
                    unitLevel2: this.state.selectedUnitLevel2?.recno,
                },
            });
            folderTitle = localize("NyaOchUppdateradeDokumentSenasteManaden");
        } else if (this.state.searchText.length > 0) {
            req = api.get("/api/v1/public360/documents", {
                params: {
                    title: this.state.searchText,
                    recnoHeadingLevel1: "",
                    recnoHeadingLevel2: "",
                    unitLevel1: this.state.selectedUnitLevel1.recno,
                    unitLevel2: this.state.selectedUnitLevel2?.recno,
                },
            });

            folderTitle = localize("DinSokningGav");
        } else {
            req = api.get("/api/v1/public360/documents", {
                params: {
                    title: "",
                    recnoHeadingLevel1: this.state.selectedSubmenuItem.ToHeadinglevel1
                        ? ""
                        : this.state.selectedSubmenuItem.recno,
                    recnoHeadingLevel2: this.state.selectedSubmenuItem.ToHeadinglevel1
                        ? this.state.selectedSubmenuItem.recno
                        : "",
                    unitLevel1: this.state.selectedUnitLevel1.recno,
                    unitLevel2: this.state.selectedUnitLevel2?.recno,
                },
            });

            folderTitle = this.state.selectedSubmenuItem.name;
        }

        const documents = await req;

        const documentsData = documents.data;

        documents.filter = documentsData.filter.map((filter) => {
            if (filter.type === 4) {
                filter.name = this.state.selectedUnitLevel1.name;
            }
            if (filter.type === 5) {
                filter.name = this.state.selectedUnitLevel2.name;
            }
            return filter;
        });

        this.setState({
            documents: documentsData,
            isLoadingDocuments: false,
            folderTitle,
        });
    };

    private debouncedFetch = debounce(this.fetchData, 500);

    private getDownloadUrl = (document) => {
        if (document.documentLink) {
            return document.documentLink;
        }

        if (document.documentReferenceRecno) {
            return `/api/public360/documents/download?documentId=${document.documentReferenceRecno}`;
        }

        return `/api/public360/documents/download?documentId=${document.recno}`;
    };

    public render() {
        const {
            isLoading,
            showUnitModal,
            units,
            selectedUnitLevel1,
            selectedUnitLevel2,
            folderTitle,
            activeFilter,
        } = this.state;
        const { title } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        if (showUnitModal) {
            return (
                <Modal className="spintr-modal modalWithPopupHeader" isOpen={true} onDismiss={this.closeUnitModal} isBlocking>
                    <PopupHeader
                        text={title}
                        hideCloseButton />
                    <FormSection>
                        <FormControl>
                            <Dropdown
                                label={localize("Kontor")}
                                options={units}
                                defaultSelectedKey={(this.state.selectedUnitLevel1 || units[0]).key}
                                onChange={(e, v) => {
                                    this.setState({
                                        selectedUnitLevel1: v,
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <Dropdown
                                label={localize("Avdelning")}
                                defaultSelectedKey={selectedUnitLevel2?.key || "0"}
                                options={(selectedUnitLevel1 || this.state.units[0]).children}
                                onChange={(e, v) => {
                                    this.setState({
                                        selectedUnitLevel2: v,
                                    });
                                }}
                            />
                        </FormControl>
                    </FormSection>

                    <Separator />

                    <Stack horizontal horizontalAlign="end">
                        <PrimaryButton
                            text={localize("Ok")}
                            type="submit"
                            onClick={async () => {
                                var unitLevel1 = this.state.selectedUnitLevel1;
                                var unitLevel2 = this.state.selectedUnitLevel2;

                                if (!this.state.selectedUnitLevel1) {
                                    await this.setState({
                                        selectedUnitLevel1: this.state.units[0],
                                        selectedUnitLevel2: this.state.units[0].children[0],
                                    });

                                    unitLevel1 = this.state.units[0];
                                    unitLevel2 = this.state.units[0].children[0];
                                } else if (!this.state.selectedUnitLevel2) {
                                    await this.setState({
                                        selectedUnitLevel2: this.state.selectedUnitLevel1.children[0],
                                    });

                                    unitLevel2 = this.state.selectedUnitLevel1.children[0];
                                }

                                this.props.dispatch(setPublic360UnitLevel1(unitLevel1));
                                this.props.dispatch(setPublic360UnitLevel2(unitLevel2));

                                this.setState({
                                    showUnitModal: false,
                                });
                                this.fetchData();
                            }}
                        />
                    </Stack>
                </Modal>
            );
        }

        let documents = [...(this.state.documents?.documents || [])];

        if (documents) {
            if (activeFilter === "1") {
            } else if (activeFilter === "2") {
                documents = documents.filter((d) => d.isCommonFilter);
            } else if (activeFilter === "3") {
                documents = documents.filter((d) => d.isCompanyCommonFilter);
            } else if (activeFilter === "4") {
                documents = documents.filter((d) => d.isUnitFilter && !d.orgLevel3);
            } else if (activeFilter === "5") {
                documents = documents.filter((d) => d.isUnitFilter && d.orgLevel3 == this.state.selectedUnitLevel2?.id);
            }
        }

        return (
            <div className="public360-main-view">
                <ContentWithSubmenu
                    renderSubmenu={() => (
                        <>
                            <PrimaryButton
                                className="switch-button"
                                text={localize("VaxlaAvdelning")}
                                onClick={() => {
                                    this.setState({ showUnitModal: true });
                                }}
                            />

                            <Submenu
                                fetch={() => {
                                    return new Promise(async (resolve, reject) => {
                                        resolve(this.state.menu);
                                    });
                                }}
                                autoExpand={false}
                                take={9999}
                            />
                        </>
                    )}
                >
                    <PageHeader title={title} />
                    <SearchBox
                        role="search"
                        ariaLabel={localize("Sok")}
                        placeholder={localize("Sok") + "..."}
                        value={this.state.searchText}
                        onChange={(event, value) => {
                            this.props.history.push({
                                search: qs.stringify({
                                    search: value.length > 0 ? value : undefined,
                                }),
                            });
                            this.setState(
                                {
                                    searchText: value,
                                },
                                this.debouncedFetch
                            );
                        }}
                        styles={{ root: { paddingLeft: 0 } }}
                    />

                    {this.state.isLoadingDocuments ? (
                        <Loader />
                    ) : (
                        <>
                            {this.state.documents?.filter?.length > 0 && (
                                <Pivot
                                    selectedKey={activeFilter}
                                    onLinkClick={(item, ev) => {
                                        this.setState({
                                            activeFilter: item.props.itemKey.toString(),
                                        });
                                    }}
                                >
                                    {this.state.documents.filter.map((filter) => (
                                        <PivotItem
                                            key={filter.type}
                                            itemKey={filter.type.toString()}
                                            headerText={`${filter.name} (${filter.hits})`}
                                        />
                                    ))}
                                </Pivot>
                            )}
                            <Label size="h4" className="folder-title">
                                {folderTitle}
                            </Label>
                            {documents.length === 0 ? (
                                <Label>{localize("IngaTraffar")}</Label>
                            ) : (
                                documents.map((document) => (
                                    // duplicate ids from server, setting key breaks everything
                                    <div className="file">
                                        <Icon {...getFileTypeIconProps({ extension: document.fileFormat })} />
                                        <a href={this.getDownloadUrl(document)} target="_self">
                                            <Label>{document.title}</Label>
                                        </a>

                                        {document.relatedDocuments?.length > 0 && (
                                            <>
                                                <Separator styles={{ root: { lineHeight: 0 } }} />
                                                {document.relatedDocuments.map((relatedDocument) => (
                                                    <div className="related-file">
                                                        <Icon
                                                            {...getFileTypeIconProps({
                                                                extension: relatedDocument.fileFormat,
                                                            })}
                                                        />
                                                        <a href={this.getDownloadUrl(relatedDocument)} target="_self">
                                                            <Label>{relatedDocument.documentReferenceTitle}</Label>
                                                        </a>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                ))
                            )}
                        </>
                    )}
                </ContentWithSubmenu>
            </div>
        );
    }
}

function mapStateToProps(state: Spintr.AppState, props) {
    return {
        ...props,
        currentUser: state.profile.active,
        selectedUnitLevel1: state.ui.public360.selectedUnitLevel1,
        selectedUnitLevel2: state.ui.public360.selectedUnitLevel2,
        title: state.instance.get("public360Title"),
    };
}

export default connect(mapStateToProps)(Public360MainView);
