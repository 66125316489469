import React, { Fragment, ReactElement, useCallback, useMemo } from "react";
import { DropZone } from "src/spintr/components";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { PlannerFilePickerProps } from "./PlannerFilePicker.types";
import { CaptionBody, CaptionBolder } from "src/components/Text";
import { localize } from "src/l10n";
import { fileSizeToHumanReadable, uniqueId } from "src/utils";
import classNames from "classnames";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { Icon, TooltipHost } from "@fluentui/react";
import { UnstyledButton } from "src/ui";

function PlannerFilePicker({
    fileTypes,
    files,
    multiple,
    onModelUpdate,
    fieldType,
    simple,
    itemType,
}: PlannerFilePickerProps): ReactElement {

    const fileTypeText = useMemo(() => fileTypes.map((fileType) => `.${fileType}`).join(", "), [fileTypes]);

    const onFilesSelected = useCallback((files: FileList) => onModelUpdate((prevModel) => ({
        ...prevModel,
        files: (prevModel.files || [])
            .concat(Array.from(files).map((file) => ({
                localId: uniqueId("fileUpload"),
                fileName: file.name,
                fileType: file.name.split(".").pop() || "",
                file,
                fieldType: fieldType,
                itemType: prevModel.itemType,
                fileSize: file.size,
            }))
        )
    })), [onModelUpdate, fieldType, itemType]);

    const filteredFiles = useMemo(
        () => files
            .filter((file) => file.fieldType === fieldType && file.itemType === itemType),
        [files, fieldType, itemType],
    );

    const inner = !simple ? null : (
        <Fragment>
            <Visage2Icon
                color="visageMidBlue"
                icon="paperclip-2"
                size="small" />
            <CaptionBody color="blue">
                {localize("BifogaFil")}
            </CaptionBody>
            <TooltipHost
                className="PlannerFilePicker-tooltip"
                content={localize("VALID_FILE_TYPES") + " " + fileTypes.join(", ")}
                style={{
                    maxWidth: 300
                }}
            >
            <   Visage2Icon icon="info-circle" size="small" />
            </TooltipHost>
        </Fragment>
    );

    const onRemoveFile = useCallback(
        (localId: string) => onModelUpdate((prevModel) => ({
            ...prevModel,
            files: prevModel.files.filter((file) => file.localId !== localId),
        })),
        [onModelUpdate],
    );

    return (
        <div className="PlannerFilePicker">
            <DropZone
                className={classNames("PlannerFilePicker-dropzone", {
                    "PlannerFilePicker-simple": simple,
                })}
                fileTypes={fileTypes}
                fileTypesString={fileTypeText}
                isMultiple={multiple}
                onFilesSelected={onFilesSelected}
            >
                {inner}
            </DropZone>

            <div className="PlannerFilePicker-files">
                {filteredFiles.map((file) => (
                    <div key={file.localId} className="PlannerFilePicker-file">
                        <div className="image">
                            <Icon {...getFileTypeIconProps({ extension: file.fileType, size: 32 })} />
                        </div>
                        <div className="information">
                            <CaptionBolder color="contentDark">
                                {file.fileName}
                            </CaptionBolder>
                            <CaptionBody color="contentDark">
                                {fileSizeToHumanReadable(file.fileSize)}
                            </CaptionBody>
                        </div>
                        <div className="actions">
                            <UnstyledButton
                                onClick={onRemoveFile}
                                onClickData={file.localId}
                            >
                                <Visage2Icon
                                    color="visageGray"
                                    icon="close-circle"
                                    size="small" />
                            </UnstyledButton>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PlannerFilePicker;
