import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/index";
import { ActionMenu, Loader } from "src/ui";
import { Label } from "../../ui";
import { fetchOperatingInfo, fetchOperatingInfoSupport, IFetchOperatingInfoParams } from "../actions";
import OperatingInfoSidebarPost from "./OperatingInfoSidebarPost";
import "./OperatingInfoSidebarWidget.scss";
import OperatingInfoSupportPost from "./OperatingInfoSupportPost";

interface IProps {
    categories?: Spintr.IOperatingInfoCategory[];
    fetchOperatingInfo?: (params: IFetchOperatingInfoParams) => void;
    fetchOperatingInfoSupport?: () => void;
    isLoading?: boolean;
    disableButtons?: boolean;
    hideTitle?: boolean;
    support?: Spintr.IOperatingInfoSupport[];
}

interface IState {
    showContacts: boolean;
}

class OperatingInfoSidebarWidget extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showContacts: false,
        };
    }

    public componentDidMount(): void {
        if (this.props.isLoading || this.props.categories.length > 0) {
            return;
        }

        this.props.fetchOperatingInfo({
            showDeleted: false,
            typesConfig: "operatingInfoSidebar",
        });

        this.props.fetchOperatingInfoSupport();
    }

    public render(): ReactNode {
        const { isLoading, categories, support } = this.props;

        return (
            <div className="OperatingInfoSidebarWidget">
                <div className="top">
                    <div className="widget-header">
                        {!this.props.hideTitle && (
                            <Label weight="semi-bold" size="h6">
                                {localize("Driftinformation")}
                            </Label>
                        )}
                    </div>
                    {support.length > 0 &&
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            text: localize("Kontakta"),
                                            onClick: () => {
                                                this.setState((prevState) => ({ showContacts: !prevState.showContacts }));
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />}
                </div>
                <div>
                    {isLoading && <Loader />}
                    {!isLoading && categories.filter((c) => (c.items || []).length > 0).length === 0 && (
                        <div className="textNoOperatingInfo">
                            <Label className="spintr-list-empty-list-label" as="p" size="body-2">
                                {localize("IngaDriftstorningar")}
                            </Label>
                        </div>
                    )}
                    {!isLoading &&
                        categories
                            .filter((c) => (c.items || []).length > 0)
                            .map((category) => (
                                <div className="category" key={"ois-cat" + category.id}>
                                    {category.items.map((item) => this.renderPost(item, category))}
                                </div>
                            ))}
                </div>
                {this.state.showContacts && <OperatingInfoSupportPost items={this.props.support} />}
            </div>
        );
    }

    protected renderPost(post: Spintr.IOperatingInfoPost, category: Spintr.IOperatingInfoCategory) {
        return (
            <OperatingInfoSidebarPost
                disableButtons={this.props.disableButtons}
                key={"ois-item" + post.id}
                post={post}
                type={category.id}
            />
        );
    }
}

const stateMapper = (state: IApplicationState, props: IProps): IProps => ({
    ...props,

    categories: state.operatingInfo.currentCategories,
    isLoading: state.operatingInfo.isLoadingSidebar,
    support: state.operatingInfo.support,
});

const dispatchActions = {
    fetchOperatingInfo,
    fetchOperatingInfoSupport,
};

export default connect(stateMapper, dispatchActions)(OperatingInfoSidebarWidget);
