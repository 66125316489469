import { Icon, Link } from "@fluentui/react";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminShortcutsEditView from "src/admin/views/AdminShortcutsEditView";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Label, SpintrUser } from "src/ui";
import { Loader, Scrollable } from "src/ui/components";
import { fetchShortcuts } from "../../actions";
import "./Shortcuts.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    items?: any[];
    userId: number;
    hideTitle?: boolean;
    disableButtons?: boolean;
    history?: any;
    id?: number;
    fetchShortcuts?: boolean;
    disableEdit?: boolean;
    isInTeamsTools?: boolean;
}

const UseFetching = (someFetchActionCreator) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(someFetchActionCreator());
    }, []);
}

export default memo(function Shortcuts(props: Props) {
    const { hideTitle, disableButtons } = props;

    const [isShown, setIsShown] = useState(0);
    const [showForm, setShowForm] = useState(undefined);

    const currentUserId = useSelector((state: IApplicationState) => state.profile.active.id);
    const isAdmin = useSelector((state: IApplicationState) => state.profile.active.isAdmin);
    const isEditor = useSelector((state: IApplicationState) => state.profile.active.isEditor);

    let data = useSelector((state: IApplicationState) => state.shortcuts.items);
    let items = data ? [...data] : [];

    const isLoading = useSelector((state: IApplicationState) => state.shortcuts.isLoading);
    const dispatch = useDispatch();

    UseFetching(fetchShortcuts);

    const onShortcutEdited = () => {
        dispatch(fetchShortcuts());

        setShowForm(undefined);
    }

    const isUrlSameSite = (url) => {
        var matches = url.match(/^(https?:)?\/\/([^\/]+)/);

        if (!matches) {
            return true;
        }

        return window.location.host === matches[2];
    };

    const getTargetFromUrl = (url) => {
        return isUrlSameSite(url) ? "_self" : "_blank";
    };

    if (isLoading) return <Loader />;

    return (
        <div className="shortcuts">
            {hideTitle ? null : (
                <div className="widget-header">
                    <Label as="h4" size="small-2">
                        {localize("Genvagar")}
                    </Label>
                </div>
            )}

            {/* <ActionMenu TODO: Reintroduce this with style that works for tabs in zoltor
                categories={[
                    {
                        items: [
                            {
                                text: localize("SkapaGenvag"),
                                onClick: () => {
                                    setShowForm(0);
                                },
                            },
                        ],
                    },
                ]}
            /> */}
            <div>
                {items.length > 0 ? (
                    items
                        .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map((item) => {
                            let disableEdit = props.disableEdit;

                            if (!isAdmin &&
                                !isEditor &&
                                !(item.targets && item.targets.length === 1 && item.targets[0].id === currentUserId)) {
                                disableEdit = true;
                            }

                            return (
                                <div key={item.id}>
                                    <div
                                        className="eachShortcutContainer"
                                        onMouseLeave={() => setIsShown(0)}
                                        onMouseEnter={() => setIsShown(item.id)}
                                        key={item.id}
                                    >
                                        {showForm === item.id ? (
                                            <Scrollable>
                                                <div className="add-shortcut">
                                                    <AdminShortcutsEditView
                                                        imageUrl={item.imageUrl}
                                                        dropdownEditing={true}
                                                        pageId={isShown}
                                                        closeEditingCallback={onShortcutEdited}
                                                    />
                                                </div>
                                            </Scrollable>
                                        ) : (
                                            <Link href={item.url} target={getTargetFromUrl(item.url)}>
                                                <div className="eachShortcut">
                                                    {
                                                        props.isInTeamsTools && (
                                                            <Link href={item.url} target={getTargetFromUrl(item.url)} className="imageWrapper">
                                                                <SpintrUser
                                                                    size={25}
                                                                    name={item.title}
                                                                    squareImage={true}
                                                                    hideText={true}
                                                                    iconToDisplayInsteadOfInitials={"export-3"}
                                                                    imageUrl={item.imageUrl}
                                                                    personalName={false} />
                                                            </Link>
                                                        )
                                                    }
                                                    <div className="textEachShortcut">
                                                        {disableButtons ? (
                                                            <span> {item.title} </span>
                                                        ) : (
                                                            <Link href={item.url} target={getTargetFromUrl(item.url)}>
                                                                {item.title}
                                                            </Link>
                                                        )}
                                                    </div>
                                                    <div className="button-right">
                                                        {disableButtons ? (
                                                            <div
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginBottom: 5,
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {isShown === item.id ? (
                                                                    <div>
                                                                        <Visage2Icon icon={"edit"} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="">
                                                                        <Visage2Icon icon={"arrow-right-3"} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                onClick={(e) => {
                                                                    if (disableEdit) {
                                                                        return;
                                                                    }

                                                                    e.preventDefault();
                                                                    e.stopPropagation();

                                                                    setShowForm(item.id)
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginBottom: 5,
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {isShown === item.id && !disableEdit ? (
                                                                    <div className="">
                                                                        <Visage2Icon icon={"edit"} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="">
                                                                        <Visage2Icon icon={"arrow-right-3"} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                ) : (
                    <div className="textNoShortcut">
                        <Label as="p" size="body-2">
                            {localize("DetFinnsIngaGenvagar")}
                        </Label>
                    </div>
                )}
                {showForm === 0 && (
                    <div className="add-shortcut-form">
                        <AdminShortcutsEditView
                            imageUrl={""}
                            dropdownEditing={true}
                            pageId={0}
                            closeEditingCallback={onShortcutEdited}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});
