import { DefaultButton, IStackTokens, Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import classnames from "classnames";
import moment from "moment";
import { IconButton, Modal, Stack } from "@fluentui/react";
import React, { Component, useEffect } from "react";
import { Calendar, momentLocalizer, ToolbarProps, View } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Label, UnstyledButton } from "src/ui";
import { isAnythingDirty } from "src/utils";
import { renderUnsavedChangesDialog } from "src/utils/renderUnsavedChangesDialog";
import { fetchCalendar, setActiveCalendar, setCalendarColor, setCalendarPopup } from "../actions";
import CalendarAPI from "../calendar-api";
import CalendarColorModal from "./CalendarColorModal";
import CalendarEventView from "./CalendarEventView";
import "./CalendarView.scss";
import SpintrAgenda from "./SpintrAgenda";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { withRouter } from "react-router-dom";

interface IProps {
    dispatch: (action: Action) => void;
    events: any[];
    match: any;
    activeCalendar: number;
    userCalendar: number;
    isAdmin: boolean;
    isEditor: boolean;
    history: any;
    small: boolean;
    calendarId: number;
    groupId: number;
    unitId: number;
    externalId: string;
    isSmallViewMode: boolean;
}

interface IState {
    calendars: Spintr.ICurrentUserCalendar[];
    isColorModalOpen: boolean;
    showEvent: any;
    displayUnsavedChangesPopup: boolean;
    view?: string;
}

class CalendarView extends Component<IProps, IState> {
    private localizer = momentLocalizer(moment);
    private calendarRef = React.createRef<Calendar>();
    private isGroup = !!this.props.groupId;
    private isUnit = !!this.props.unitId;

    constructor(props) {
        super(props);

        this.state = {
            calendars: [],
            isColorModalOpen: false,
            showEvent: undefined,
            displayUnsavedChangesPopup: false,
            view: "month"
        };
    }

    public componentDidMount() {

        this.setState({
            view: this.props.match.params.view
        })

        this.fetchCalendars();
        this.fetchCalendarEvents();

        const calendarId = parseInt(this.props.calendarId || this.props.match.params.calendarId)

        this.props.dispatch(setActiveCalendar(calendarId || 0));
    }

    protected fetchCalendars = async () => {
        const calendars = await CalendarAPI.fetchUserCalendars();

        this.setState({
            // @ts-ignore
            calendars: [
                {
                    id: 0,
                    name: localize("Alla"),
                    eventNumber: 0,
                    isDeleted: false,
                    isDraft: false,
                    isPublished: true,
                    type: SpintrTypes.CalendarType.Public,
                    lastChanged: new Date(),
                    status: 0,
                },
                ...calendars,
            ],
        });
    };

    public componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.calendarId !== this.props.match.params.calendarId) {
            this.fetchCalendarEvents();
        }
    }

    protected handleSelectSlot = ({ start, end }) => {
        this.props.dispatch(
            setCalendarPopup({
                isOpen: true,
                startDate: start,
                endDate: end,
                preSelectedCalendarId: this.props.activeCalendar,
                hideCalendarSelector: !!this.props.calendarId, // hide calendar selector if group
                externalId: this.props.externalId
            })
        );
    };

    protected handleSelectEvent = (event: Spintr.ICalendarEvent) => {
        if (event.exchangeId || event.googleId) {
            this.props.dispatch(setCalendarPopup({
                isOpen: true,
                event: {
                    ...event,
                    externalId: this.props.externalId
                },
                externalId: this.props.externalId
            }));
        } else {
            this.setState({ showEvent: event });
            // this.props.history.push({
            //     pathname:
            //         (this.isGroup
            //             ? `/groups/${this.props.groupId}`
            //             : this.isUnit
            //             ? `/organisation/v/${this.props.unitId}`
            //             : ``) + `/calendar/${event.id}`,
            // });
        }
    };

    protected fetchCalendarEvents = () => {
        setTimeout(() => {
            const date = this.calendarRef?.current?.props?.date as Date;
            const view = this.calendarRef?.current?.props?.view as "month" | "week" | "day" | "agenda";

            let start;
            let end;

            if (view === "week" || view === "day") {
                start = moment(date).startOf(view);
                end = moment(date).endOf(view);
            }

            if (view === "month") {
                start = moment(date).startOf("month").startOf("week");
                end = moment(date).endOf("month").endOf("week");
            }

            if (view === "agenda") {
                start = moment(date);
                end = moment(date).add(30, "days");
            }

            if (start && end) {
                this.props.dispatch(
                    fetchCalendar({
                        id: this.props.activeCalendar,
                        start: start.unix(),
                        end: end.unix(),
                        externalId: this.props.externalId
                    })
                );
            }
        }, 0);
    };

    protected eventPropGetter = (event) => ({
        style: {
            backgroundColor: event.color,
        },
    });

    protected toggleCalendarColorModal = () => {
        this.setState((prevState) => ({
            isColorModalOpen: !prevState.isColorModalOpen,
        }));
    };

    protected saveCalendarColorModal = (color: string) => {
        const activeCalendar = this.state.calendars.find((c) => c.id === this.props.activeCalendar);

        this.props.dispatch(setCalendarColor(activeCalendar.id, activeCalendar.name, color.substr(1)));
    };

    protected getToolbarActionMenu = (toolbarProps: ToolbarProps, activeCalendar: Spintr.ICurrentUserCalendar) => {
        if (!activeCalendar) {
            return null;
        }

        const owner = activeCalendar.id === this.props.userCalendar;
        const canEdit =
            this.props.isAdmin || this.props.isEditor || activeCalendar.type === SpintrTypes.CalendarType.Project;

        const editorItems: Spintr.IActionMenuItem[] = [
            ...(owner && canEdit
                ? [
                    {
                        text: localize("NyKalenderpost"),
                        onClick: () => {
                            this.props.dispatch(
                                setCalendarPopup({
                                    isOpen: true,
                                    preSelectedCalendarId: this.props.activeCalendar,
                                    hideCalendarSelector: !!this.props.calendarId, // hide calendar selector if group
                                    externalId: this.props.externalId
                                })
                            );
                        },
                    },
                    {
                        text: localize("AndraFarg"),
                        onClick: this.toggleCalendarColorModal,
                    },
                ]
                : []),
            ...(owner && !canEdit
                ? [
                    {
                        text: localize("AndraFarg"),
                        onClick: this.toggleCalendarColorModal,
                    },
                ]
                : []),
            ...((!owner && canEdit) || activeCalendar.id === 0
                ? [
                    {
                        text: localize("NyKalenderpost"),
                        onClick: () => {
                            this.props.dispatch(
                                setCalendarPopup({
                                    isOpen: true,
                                    preSelectedCalendarId: this.props.activeCalendar,
                                    hideCalendarSelector: !!this.props.calendarId, // hide calendar selector if group
                                    externalId: this.props.externalId
                                })
                            );
                        },
                    },
                ]
                : []),
        ];

        return (
            <ActionMenu
                categories={[
                    {
                        title: localize("Visa"),
                        items: (toolbarProps.views as View[]).map((name) => ({
                            text: toolbarProps.localizer.messages[name],
                            onClick: () => {
                                toolbarProps.onView(name as View);
                            },
                        })),
                    },
                    ...(editorItems.length > 0
                        ? [
                            {
                                title: localize("Redaktor"),
                                items: editorItems,
                            },
                        ]
                        : []),
                ]}
            />
        );
    };

    protected Event = React.memo((props: any) => {
        return (
            <a
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        this.handleSelectEvent(props.event);
                    }
                }}
            >
                <Label color="white" size="body-2">
                    {props.event.title}
                </Label>
            </a>
        );
    });

    protected Toolbar = React.memo((props: ToolbarProps) => {
        const navigatePrevious = () => props.onNavigate(navigate.PREVIOUS);

        const navigateNext = () => props.onNavigate(navigate.NEXT);

        useEffect(() => {
            //@ts-ignore
            props.onView(this.state.view);
        }, [this.state.view]);

        const linkClick = (item) => {
            if (item && item.props && item.props.itemKey === "CreateEvent") {
                this.props.dispatch(
                    setCalendarPopup({
                        isOpen: true,
                        preSelectedCalendarId: this.props.activeCalendar,
                        hideCalendarSelector: !!this.props.calendarId, // hide calendar selector if group
                        externalId: this.props.externalId
                    })
                );
            };
                if (!this.isGroup) {
                    this.props.history.push(`/calendar/${this.props.activeCalendar}/${item.props.itemKey}`);
                }

                return props.onView(item.props.itemKey);

        }
        const activeCalendar = this.state.calendars.find((c) => c.id === this.props.activeCalendar);

        const horizontalGapStackTokens: IStackTokens = {
            childrenGap: 10,
            padding: 5
        };

        return (
            <div>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="calendarHeader">
                    <Stack horizontal >
                        <Stack className={!this.isGroup ? "calendarActionMenu" : ""} horizontal tokens={horizontalGapStackTokens} verticalAlign="center" >
                            {!this.isGroup && <Label>{activeCalendar && activeCalendar.name}</Label>}
                            {
                                /* Don't show calendar selector if in group/ */
                                !this.props.calendarId && (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [
                                                    ...this.state.calendars.map((calendar) => ({
                                                        key: calendar.id.toString(),
                                                        text: calendar.name,
                                                        onClick: () => {
                                                            this.props.dispatch(setActiveCalendar(calendar.id));
                                                            this.props.history.push(`/calendar/${calendar.id}/${this.state.view}`);
                                                        },
                                                    })),
                                                ],
                                            },
                                        ]}
                                        renderButton={() => {
                                            return (
                                                <Visage2Icon icon="arrow-down-1" size="medium" />
                                            )
                                        }}
                                    />
                                )
                            }
                        </Stack>

                        <Stack horizontal verticalAlign="center" className="calendarNavigation">
                            <UnstyledButton onClick={navigatePrevious}>
                                <Visage2Icon icon="arrow-left-2" />
                            </UnstyledButton>
                            <Label weight="medium">{props.label}</Label>
                            <UnstyledButton onClick={navigateNext}>
                                <Visage2Icon icon="arrow-right-3" />
                            </UnstyledButton>
                        </Stack>
                    </Stack>
                    {this.props.small || this.props.isSmallViewMode ? (
                        this.getToolbarActionMenu(props, activeCalendar)
                    ) : (
                        <div className="calendarToolbar">
                            <Pivot onLinkClick={linkClick} selectedKey={props.view} linkFormat="tabs">
                                {(props.views as View[]).map((name) => (
                                    <PivotItem headerText={props.localizer.messages[name]} key={name} itemKey={name} />
                                ))}
                            </Pivot>
                            <PrimaryButton
                                id="CreateEvent"
                                text={localize("SkapaKalenderpost")}
                                key={"CreateEvent"}
                                onClick={() => {
                                    this.props.dispatch(
                                        setCalendarPopup({
                                            isOpen: true,
                                            preSelectedCalendarId: this.props.activeCalendar,
                                            hideCalendarSelector: !!this.props.calendarId, // hide calendar selector if group
                                            externalId: this.props.externalId
                                        })
                                    );
                                }}
                            />
                        </div>
                    )}
                </Stack>
            </div>
        );
    });

    //protected views = {"month": true, "week": true, "day": true, "agenda": SpintrAgenda} as View[];

    public render() {
        const { events } = this.props;
        const { showEvent } = this.state;
        const activeCalendar = this.state.calendars.find((c) => c.id === this.props.activeCalendar);
        setTimeout(() => {
            // TODO: this is UGLY
            const eventElements = document.getElementsByClassName("rbc-event");

            if (eventElements && eventElements.length > 0) {
                for (let i = 0; i < eventElements.length; i += 1) {
                    eventElements[i].removeAttribute("tabindex");
                }
            }
        }, 100);

        return (
            <div
                className={classnames("calendar-view", {
                    isSmall: !!this.props.small,
                    isAgendaView: this.state.view === "agenda"
                })}
            >
                <Helmet>
                    <title>{localize("Kalender")}</title>
                </Helmet>

                {showEvent && (
                    <Modal
                        className="spintr-modal CalendarEventViewModal"
                        isOpen={showEvent !== undefined}
                        onDismiss={() => {
                            if (isAnythingDirty()) {
                                return this.setState({
                                    displayUnsavedChangesPopup: true,
                                });
                            }

                            this.setState({ showEvent: undefined });
                        }}
                    >
                        <CalendarEventView
                            id={showEvent.id}
                            onDismiss={() => {
                                this.setState({ showEvent: undefined });
                            }}
                        />
                    </Modal>
                )}
                <Calendar
                    events={events}
                    ref={this.calendarRef}
                    localizer={this.localizer}
                    onNavigate={this.fetchCalendarEvents}
                    // onRangeChange={this.rangeChange}
                    onView={(view?: string) => {
                        if (!!view) {
                            this.setState({
                                view
                            }, () => {
                                this.fetchCalendarEvents();
                            })
                        } else {
                            this.fetchCalendarEvents();
                        }
                    }}
                    onSelectSlot={this.handleSelectSlot}
                    onSelectEvent={this.handleSelectEvent}
                    //@ts-ignore
                    components={{ toolbar: this.Toolbar, event: this.Event }}
                    eventPropGetter={this.eventPropGetter}
                    //@ts-ignore
                    views={{ month: true, week: true, day: true, agenda: SpintrAgenda as any }}
                    formats={{
                        monthHeaderFormat: this.props.isSmallViewMode ? "MMM" : "MMMM YYYY",
                        agendaHeaderFormat: ({ start, end }, culture, localizer) =>
                            localizer.format(start, 'DD MMM YYYY', culture) +
                            ' - ' +
                            localizer.format(end, 'DD MMM YYYY', culture)
                    }}
                    messages={{
                        // https://github.com/jquense/react-big-calendar/blob/master/src/utils/messages.js
                        date: localize("Datum"),
                        time: localize("Tid"),
                        event: localize("Event").substr(0, 1).toUpperCase() + localize("Event").substr(1),
                        allDay: localize("Heldag"),
                        today: localize("Idag"),
                        previous: localize("Foregaende"),
                        next: localize("Nasta"),
                        month: localize("Manad"),
                        week: localize("Vecka"),
                        day: localize("Dag"),
                        agenda: localize("Agenda"), // TODO: Add lang tag?
                        showMore: (total) => `+${total} ${localize("till_more")}`,
                        noEventsInRange: localize("IngaPoster"),
                    }}
                    selectable
                />
                {this.state.isColorModalOpen && (
                    <CalendarColorModal
                        calendar={activeCalendar}
                        save={this.saveCalendarColorModal}
                        dismiss={this.toggleCalendarColorModal}
                    />
                )}

                {renderUnsavedChangesDialog(
                    this.state.displayUnsavedChangesPopup,
                    () => {
                        this.setState({
                            displayUnsavedChangesPopup: false,
                        });
                    },
                    () => {
                        this.setState(
                            {
                                displayUnsavedChangesPopup: false,
                            },
                            () => {
                                this.setState({ showEvent: undefined });
                            }
                        );
                    }
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        events: state.calendar.events?.reduce(
            (acc, x) =>
                acc.concat(
                    acc.find(
                        (y) =>
                            (y.id && y.id === x.id) ||
                            (y.exchangeId && y.exchangeId === x.exchangeId) ||
                            (y.googleId && y.googleId === x.googleId)
                    )
                        ? []
                        : [x]
                ),
            []
        ),
        activeCalendar: state.calendar.activeCalendar,
        userCalendar: state.profile.active.calendarId,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default withRouter(connect(mapStateToProps)(CalendarView));
