import { Checkbox } from "@fluentui/react";
import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Label } from "src/ui";
interface IProps {
    componentKey: string;
    index: number;
    title: string;
    text: string;
    icon: string;
    disableDrag?: boolean;
    renderCheckbox?: boolean;
    selected?: boolean;
}

const StartPageBuilderDrawerItem: FunctionComponent<IProps> = ({ componentKey, index, title, text, icon, disableDrag, renderCheckbox, selected  }) => {
    return (
        <div className="StartPageBuilderDrawerItem">
            <Draggable draggableId={componentKey} index={index} isDragDisabled={disableDrag}>
                {(provided, snapshot) => (
                    <div
                        key={componentKey}
                        ref={provided.innerRef}
                        className={classNames("component-wrapper", {
                            dragging: snapshot.isDragging,
                            selected: selected,
                        })}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className="icon">
                            {renderCheckbox && <Checkbox checked={selected} />}
                            <img
                                alt={title}
                                src={"/images/drawer/" + icon}
                            />
                        </div>
                        <div className="info">
                            <Label size="body-2" weight="medium" centerText>
                                {title}
                            </Label>
                            <Label size="body-3" centerText>
                                {text}
                            </Label>
                        </div>

                        <div className="no-click-overlay" />
                    </div>
                )}
            </Draggable>
        </div>
    );
};

export default StartPageBuilderDrawerItem;
