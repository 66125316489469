export const QUERY_FILES_BY_FOLDER = "QUERY_FILES_BY_FOLDER";
export const QUERY_FILES_BY_FOLDER_PENDING = "QUERY_FILES_BY_FOLDER_PENDING";
export const QUERY_FILES_BY_FOLDER_REJECTED = "QUERY_FILES_BY_FOLDER_REJECTED";
export const QUERY_FILES_BY_FOLDER_FULFILLED = "QUERY_FILES_BY_FOLDER_FULFILLED";

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

export const NEW_FOLDER = "NEW_FOLDER";

export const ABORT_NEW_FOLDER = "ABORT_NEW_FOLDER";

export const SAVE_NEW_FOLDER = "SAVE_NEW_FOLDER";
export const SAVE_NEW_FOLDER_PENDING = "SAVE_NEW_FOLDER_PENDING";
export const SAVE_NEW_FOLDER_REJECTED = "SAVE_NEW_FOLDER_REJECTED";
export const SAVE_NEW_FOLDER_FULFILLED = "SAVE_NEW_FOLDER_FULFILLED";

export const SAVE_NEW_FILE = "SAVE_NEW_FILE";
export const SAVE_NEW_FILE_PENDING = "SAVE_NEW_FILE_PENDING";
export const SAVE_NEW_FILE_REJECTED = "SAVE_NEW_FILE_REJECTED";
export const SAVE_NEW_FILE_FULFILLED = "SAVE_NEW_FILE_FULFILLED";

export const SELECT_ITEM = "SELECT_ITEM";

export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";

export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_FOLDER_PENDING = "DELETE_FOLDER_PENDING";
export const DELETE_FOLDER_REJECTED = "DELETE_FOLDER_REJECTED";
export const DELETE_FOLDER_FULFILLED = "DELETE_FOLDER_FULFILLED";

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_PENDING = "DELETE_FILE_PENDING";
export const DELETE_FILE_REJECTED = "DELETE_FILE_REJECTED";
export const DELETE_FILE_FULFILLED = "DELETE_FILE_FULFILLED";

export const UPLOADED_FILE = "UPLOADED_FILE";

export const SET_RENAMING_ITEM = "SET_RENAMING_ITEM";

export const UPDATE_FILE = "UPDATE_FILE";
export const UPDATE_FILE_PENDING = "UPDATE_FILE_PENDING";
export const UPDATE_FILE_REJECTED = "UPDATE_FILE_REJECTED";
export const UPDATE_FILE_FULFILLED = "UPDATE_FILE_FULFILLED";

export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_FOLDER_PENDING = "UPDATE_FOLDER_PENDING";
export const UPDATE_FOLDER_REJECTED = "UPDATE_FOLDER_REJECTED";
export const UPDATE_FOLDER_FULFILLED = "UPDATE_FOLDER_FULFILLED";

export const FAVOURITE_ITEM = "FAVOURITE_ITEM";
export const FAVOURITE_ITEM_PENDING = "FAVOURITE_ITEM_PENDING";
export const FAVOURITE_ITEM_REJECTED = "FAVOURITE_ITEM_REJECTED";
export const FAVOURITE_ITEM_FULFILLED = "FAVOURITE_ITEM_FULFILLED";

export const UNFAVOURITE_ITEM = "UNFAVOURITE_ITEM";
export const UNFAVOURITE_ITEM_PENDING = "UNFAVOURITE_ITEM_PENDING";
export const UNFAVOURITE_ITEM_REJECTED = "UNFAVOURITE_ITEM_REJECTED";
export const UNFAVOURITE_ITEM_FULFILLED = "UNFAVOURITE_ITEM_FULFILLED";

export const FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE = "FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE";
export const FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_PENDING = "FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_PENDING";
export const FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_REJECTED = "FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_REJECTED";
export const FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_FULFILLED = "FETCH_SHAREPOINT_ATTACHED_FILES_FOR_PAGE_FULFILLED";