import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Label } from "src/ui";

import "./SplitAuth.scss";

interface IProps {
    backgroundUrl: string;
    error?: string;
    children?: any;
    appSettings?: any;
}

class SplitAuth extends Component<IProps> {
    private getBackgroundUrl = (): string => {
        const url = this.props.backgroundUrl || "/images/loginbg.jpg";

        return url;
    };

    private getLogoUrl = (): string => {
        const url = this.props.appSettings.colorLogoUrl || "/logo-start.png";

        return url;
    };

    public render() {
        const { error } = this.props;

        return (
            <div className="split-auth-view">
                {error && (
                    <div className="error-bar" role="alert">
                        <Label color="white" size="body-1" weight="medium" centerText>
                            {error}
                        </Label>
                    </div>
                )}
                <div className={classNames("content", { ["has-error"]: error })}>
                    <div className="left">
                        <div className="form-container">
                            <div className="login-logo-wrapper">
                                <img src={this.getLogoUrl()} alt="Spintr logotyp" />
                            </div>

                            {this.props.children}
                        </div>

                        <Label
                        className="footer-text"
                            dangerouslySetInnerHTML={{
                                __html: localize("SIGNIN_FOOTER_TEXT").replace(
                                    /{{(.*)}}/,
                                    '<a href="https://spintr.com">$1</a>'
                                ),
                            }}
                            italic
                            centerText
                            color="mid-grey"
                        />
                    </div>
                    <div className="right">
                        <div
                            className="background-image"
                            style={{ backgroundImage: `url("${this.getBackgroundUrl()}")` }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => {
    return {
        ...props,
        appSettings: state.auth.appSettings,
    };
};

export default connect(mapStateToProps)(SplitAuth);
