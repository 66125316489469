import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { SpintrTypes } from "src/typings";
import { localize } from "src/l10n";
import { Label, UnstyledButton } from "src/ui";
import { SocialFeedType, SocialPost } from "src/social-feed";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Icon, Stack, StackItem } from "@fluentui/react";
import "./SystemStatusesPrioView.scss";
import { fetchPrioritySystemStatuses, postSystemStatusViewed } from "./actions";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface IProps {
    activeStatuses: Spintr.ISocialPostBase[];
}

const SystemStatusesPrioView: FunctionComponent<IProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [viewAll, setViewAll] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {dispatch(fetchPrioritySystemStatuses());}, []);

    const toggleExpanded = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const toggleViewAll = useCallback(() => {
        setViewAll(!viewAll);
    }, [viewAll]);

    if (props.activeStatuses.length == 0) {
        return null;
    }

    return (
        <div className="SystemStatusesPrioView">
            <div
                className={classNames("content", {
                    expanded,
                })}
            >
                <UnstyledButton onClick={toggleExpanded}>
                    <Stack className="header" horizontal horizontalAlign="space-between">
                        <div>
                            <Label className="current-status-label" weight="medium" size="h5" as="span">
                                {localize("SYSTEM_STATUS_CURRENT_STATUS")}
                            </Label>
                            <Label size="h5" as="span">
                                {localize(
                                    props.activeStatuses.length === 1
                                        ? "SYSTEM_STATUS_1_UPDATE_HIGH_PRIORITY"
                                        : "SYSTEM_STATUS_X_UPDATES_HIGH_PRIORITY"
                                ).replace("{{X}}", props.activeStatuses.length.toString())}
                            </Label>
                        </div>
                        <UnstyledButton onClick={toggleExpanded}>
                            <Visage2Icon icon={expanded ? "arrow-up-2" : "arrow-down-1"} />
                        </UnstyledButton>
                    </Stack>
                </UnstyledButton>

                {expanded && (
                    <div className="expanded-items">
                        {props.activeStatuses.slice(0, !viewAll ? 5 : undefined).map((status) => (
                            <Stack className="item" key={status.Id} horizontal horizontalAlign="space-between">
                                <Link
                                    to={
                                        "/operations/" +
                                        (
                                            status.content.find(
                                                (c) => c.Type === SpintrTypes.UberType.SystemStatus
                                            ) as Spintr.ISocialSystemStatus
                                        ).resourceId
                                    }
                                    onClick={toggleExpanded}
                                >
                                    <SocialPost
                                        feedType={SocialFeedType.SystemStatus}
                                        hideActionMenu={true}
                                        hidePinned
                                        post={status}
                                    />
                                </Link>
                                <StackItem align="center">
                                    <UnstyledButton
                                        className="close-button"
                                        onClick={() => {
                                            const systemStatusId = status.content.find(
                                                (c) => c.Type === SpintrTypes.UberType.SystemStatus
                                            ).Id;

                                            dispatch(postSystemStatusViewed(systemStatusId));
                                        }}
                                    >
                                        <Icon iconName="ChromeClose" />
                                    </UnstyledButton>
                                </StackItem>
                            </Stack>
                        ))}
                        {props.activeStatuses.length > 5 && !viewAll && (
                            <UnstyledButton className="view-all" onClick={toggleViewAll}>
                                <Label>{localize("VisaAlla")}</Label>
                            </UnstyledButton>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    activeStatuses: state.systemStatus.prioritySystemStatuses,
});

export default connect(mapStateToProps)(SystemStatusesPrioView);
