import React, { ReactElement, useMemo } from "react";
import "./SystemStatusResourceInfo.scss";
import { Label, SpintrUser } from "src/ui";
import SystemStatusTypePill from "./SystemStatusTypePill";
import getOpenTime from "./utils";
import { localize } from "src/l10n";

type Props = {
    resource: Spintr.ISystemStatusResource;
    showBallOnly?: boolean;
}

function SystemStatusResourceInfo({ resource, showBallOnly }: Props): ReactElement{
    const subText = useMemo(() => {
        if (resource.isSuggestion) {
            return localize("Utkast");
        }

        return getOpenTime(resource.openTimes);
    }, [resource.openTimes]);

    return (
        <div className="SystemStatusResourceInfo">
            <SpintrUser
                key={resource.id}
                personalName={false}
                name={resource.title}
                subText={subText}
                imageUrl={resource.imageUrl}
                onRenderPrimaryText={() => {
                    return (
                        <div className="title-row">
                            <Label title={resource.title}>{resource.title}</Label>
                            {!resource.isSuggestion && (
                                <SystemStatusTypePill
                                    statusType={resource.status}
                                    ballOnly={showBallOnly} />
                            )}
                        </div>
                    )
                }}
            />
        </div>
    );
}

export default SystemStatusResourceInfo;
