import { AxiosResponse } from "axios";
import { Checkbox } from "@fluentui/react/lib/components/Checkbox";
import { Dropdown } from "@fluentui/react/lib/components/Dropdown/Dropdown";
import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { localize } from "src/l10n";
import { ActionMenu, Label, Loader } from "src/ui";
import FormControl from "../Forms/FormControl";
import SpintrList from "../SpintrList/SpintrList";
import "./Todos.scss";
import api from "src/spintr/SpintrApi";

interface Props extends RouteComponentProps {
    hideTitle?: boolean;
}

interface State {
    todos: any[];
    showAddTodo: boolean;
}

const AddTodo = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [lists, setLists] = useState([]);
    const history = useHistory();

    //@ts-ignore
    const isAdmin = useSelector((state) => state.profile.active.isAdmin);
    //@ts-ignore
    const isEditor = useSelector((state) => state.profile.active.isEditor);
    //@ts-ignore
    const enableDepartmentLists = useSelector((state) => state.instance.get("enableTodoDepartmentLists"));

    useEffect(() => {
        let mounted = true;
        api.get(`/api/v1/todos/lists`).then((response) => {
            if (mounted) {
                setLists([
                    ...response.data.mylists,
                    ...response.data.other,
                    ...response.data.projects,
                    ...[response.data.departmentlists].filter((item) => {
                        return (item.userCanEdit || isAdmin || isEditor) && enableDepartmentLists;
                    }),
                    ...[{ creator: null, id: 0, name: localize("SkapaNyLista"), targets: null, type: 0 }],
                ]);
                setIsLoading(false);
            }
            return () => (mounted = false);
        });
    });

    if (isLoading) return <Loader />;

    return (
        <FormControl>
            <Dropdown
                label={localize("ValjLista")}
                onChange={(_e, v) => {
                    if (v.key === 0) history.push(`/todo/create`);
                    else history.push(`/todo/list/${v.key}/create`);
                }}
                options={[
                    ...lists.map((c) => {
                        return {
                            key: c.id,
                            text: c.name,
                        };
                    }),
                ]}
            />
        </FormControl>
    );
};

class Todos extends Component<Props, State> {
    private listRef = React.createRef<SpintrList>();

    state = {
        todos: [],
        showAddTodo: false,
    };

    render = () => {
        const { todos, showAddTodo } = this.state;
        const { hideTitle } = this.props;

        return (
            <div className="Sidebar-Todos">
                <div className="Todos-Header">
                    {hideTitle ? null : (
                        <div className="widget-header">
                            <Label weight="semi-bold" size="h6">
                                {localize("AttGora")}
                            </Label>
                        </div>
                    )}
                    <ActionMenu categories={
                        [{
                            items: [
                                {
                                    key: "add",
                                    text: localize("LaggTill"),
                                    onClick: () => {
                                        this.setState({ showAddTodo: true });
                                    },
                                    icon: "CircleAdditionSolid",
                                },
                                {
                                    key: "all",
                                    text: localize("AllaAttGora"),
                                    onClick: () => {
                                        this.props.history.push("/todo");
                                    },
                                },
                            ],
                        }]
                    } />
                </div>

                {!showAddTodo ? (
                    <SpintrList
                        hideHeader
                        disablePagination
                        disableSearch
                        disableCommandBar
                        ref={this.listRef}
                        fetch={(skip, take, columnId, isAscending) => {
                            return new Promise((resolve, reject) => {
                                api
                                    .get("/api/v1/todos/today", {
                                        params: {
                                            orderByColumn: columnId,
                                            includeDeleted: true,
                                            isAscending: isAscending,
                                            take: 5,
                                            skip: skip,
                                        },
                                    })
                                    .then((response: AxiosResponse) => {
                                        this.setState({ todos: response.data.items });
                                        resolve({
                                            data: response.data.items,
                                            totalCount: response.data.totalCount,
                                        });
                                    });
                            });
                        }}
                        columns={[
                            {
                                key: "checkbox",
                                name: "",
                                minWidth: 20,
                                maxWidth: 20,
                                onRender: (item) => {
                                    let listItem = todos.findIndex((f) => f.id === item.id);

                                    return (
                                        <Checkbox
                                            ariaLabel={(todos[listItem] ? todos[listItem].isComplete : false) ?
                                                localize("KlickaForAttAvmarkeraSomKlar") :
                                                localize("KlickaForAttMarkeraSomKlar")}
                                            checked={todos[listItem] ? todos[listItem].isComplete : false}
                                            onChange={() => {
                                                api.post(`/api/v1/todos/togglecompleted/${item.id}`, {
                                                    id: item.id,
                                                    isComplete: !item.isComplete,
                                                });
                                                todos[listItem].isComplete = !todos[listItem].isComplete;
                                                this.setState({ todos });
                                            }}
                                        />
                                    );
                                },
                            },
                            {
                                name: localize("Namn"),
                                fieldName: "name",
                                maxWidth: 300,
                            },
                        ]}
                        orderByColumn="name"
                    // actionMenu={[
                    //     {
                    //         items: [
                    //             {
                    //                 key: "add",
                    //                 text: localize("LaggTill"),
                    //                 onClick: () => {
                    //                     this.setState({ showAddTodo: true });
                    //                 },
                    //                 icon: "CircleAdditionSolid",
                    //             },
                    //             {
                    //                 key: "all",
                    //                 text: localize("AllaAttGora"),
                    //                 onClick: () => {
                    //                     this.props.history.push("/todo");
                    //                 },
                    //             },
                    //         ],
                    //     },
                    // ]}
                    />
                ) : (
                    <AddTodo />
                )}
            </div>
        );
    };
}

export default withRouter(Todos);
