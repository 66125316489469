import { TextField } from "@fluentui/react";
import React, { ChangeEvent, ReactNode } from "react";
import { localize } from "src/l10n";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

const queryStringNamePattern: RegExp = /^([\w-]+)$/i;

/**
 * Used for configuring the name of a custom query string
 */
class CustomQueryStringSetup extends AuthenticationDataComponent {
    private labelText: string = localize("CUSTOMER_QUERY_STRING_NAME");

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                customQueryStringName: ""
            },
            errorMessage: null
        };

        this.onNameChanged = this.onNameChanged.bind(this);
    }

    public getAuthenticationData(): string {
        return JSON.stringify(this.state.data);
    }    
    
    public isValid(): boolean {
        const name = ((this.state.data.customQueryStringName as string) || "").trim();

        const isValid: boolean =  queryStringNamePattern.test(name);
        
        this.setState({ 
            errorMessage: isValid
                ? null
                : localize("INVALID_QUERY_STRING_FORMAT")
        });

        return isValid;
    }

    public onNameChanged(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({
            data: {
                customQueryStringName: event.target.value.trim()
            }
        }, () => this.isValid());
    }

    public render(): ReactNode {
        return (
            <TextField
                errorMessage={this.state.errorMessage}
                label={this.labelText}
                name="customerQueryStringName"
                onChange={this.onNameChanged}
            />
        )
    }
}

export default CustomQueryStringSetup;