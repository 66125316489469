import React, { Component } from 'react';
import "./ContentWithInfoPanel.scss";

interface IProps {
    onEndReached: any;
    className?: string;
    hasMore: boolean;
    isLoading: boolean;
}

class SpintrBasicInfiniteScroll extends Component<IProps> {
    componentDidMount(): void {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (!this.props.hasMore ||
            this.props.isLoading) {
            return;
        }

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const diff = height - winScroll;

        if (diff < 100) {
            this.props.onEndReached();
        }
    }

    render() {
        return (
            <div className={"SpintrBasicInfiniteScroll " + (this.props.className || "")}>
                {this.props.children}
            </div>
        )
    }
}


export default SpintrBasicInfiniteScroll;
