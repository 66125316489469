import React, { ReactElement } from "react";
import { TagItemProps } from "./TagItem.types";
import { CaptionBody } from "../Text";

function TagItem({ tag }: TagItemProps): ReactElement {
    return (
        <div className="TagItem">
            <CaptionBody letterSpacing="dense" weight="medium" title={tag.text}>
                {tag.text}
            </CaptionBody>
        </div>
    );
}

export default TagItem;
