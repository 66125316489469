import { Icon } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ConversationsPanel } from "src/chat/components/ConversationsPanel";
import { localize } from "src/l10n";
import { VisageSidebarDrawerId, VisageSidebarMode } from "src/sidebar";
import { setSidebarDrawerId, setSidebarMode } from "src/sidebar/actions";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, PageHeader } from "src/ui";
import "src/groups/views/GroupsStartView.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    history: any;
    location: any;
    match: any;
    smallViewMode?: boolean;
    appMode?: boolean;
    dispatch?: any;
}

class GroupsStartView extends Component<IProps> {
    componentDidMount() {
        if (this.props.smallViewMode || this.props.appMode) {
            this.props.history.push({
                pathname: "/my-groups"
            });
        } else {
            this.props.dispatch(setSidebarMode(VisageSidebarMode.drawer));
            this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.groups));
        }
    }

    render() {
        return (
            <div className="GroupsStartView">
                <div className="GroupsStartView-align-helper" />
                <div>
                    <div className="empty-sidebar-list">
                        <div className="icon">
                            <Visage2Icon icon="people" size="big" />
                        </div>
                        <div className="content">
                            <Label role="text" size="body-2">
                                <span>
                                    {localize("SkapaGruppText") + " "}
                                    {localize("GROUP_START_PAGE_INFO")}
                                </span>
                            </Label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    smallViewMode: state.ui.isSmallViewMode,
    appMode: state.ui.appMode,
});

export default withRouter(connect(mapStateToProps)(GroupsStartView));