import React, { useCallback, useEffect, useState } from 'react';
import { DefaultButton } from '@fluentui/react';
import { localize } from 'src/l10n';
import { Label, UnstyledButton } from 'src/ui';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { StateProps } from './CalypsoSupportBanner.types';
import { TicketFormModal } from 'src/groups/views/GroupSupportView/TicketFormModal';
import { set } from 'date-fns';

const localStorageId = "spintr_hasSeenPromoBanner2";

function CalypsoSupportBanner() {
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [zendeskOpen, setZendeskOpen] = useState<boolean>(false);

    const { isAdmin, appMode } = useSelector<Spintr.AppState, StateProps>(
        (appState) => ({
            isAdmin: appState.profile.active.isAdmin,
            appMode: appState.ui.appMode,
        })
    );

    useEffect(() =>  {
        const hasSeenPromoBanner = localStorage.getItem(localStorageId) === "true";

        if (isAdmin && !appMode && !hasSeenPromoBanner) {
            setShowBanner(true);
        }
    }, [isAdmin]);

    const onCloseClicked = useCallback(() => {
        setShowBanner(false);
        localStorage.setItem(localStorageId, "true");
    }, [setShowBanner]);

    const onOpenWebinarClicked = useCallback(() => {
        window.open("https://www.spintr.com/calypso", "_blank");
    }, []);

    const onReportIssueClicked = useCallback(() => {
        setZendeskOpen(true);
    }, [setZendeskOpen]);

    const onZendeskClosed = useCallback(() => {
        setZendeskOpen(false);
    }, [setZendeskOpen]);

    if (!isAdmin || !showBanner) {
        return null;
    }

    return (
        <div className="CalypsoSupportBanner">
            <div className="CalypsoSupportBanner-inner">
                <div className="main">
                    <div className="icon-wrapper">
                        <ReactSVG src={"/images/SomethingFun_party.svg"} />
                    </div>
                    <div className="content">
                        <Label size="body-1" color="white" weight="medium">{localize("CALYPSO_SUPPORT_TITLE")}</Label>
                        <Label size="body-2" color="white">{localize("CALYPSO_SUPPORT_MESSAGE")}</Label>
                    </div>
                </div>
                <div className="buttons">
                    <UnstyledButton onClick={onCloseClicked}>
                        <Label color="white">{localize("Stang")}</Label>
                    </UnstyledButton>
                    <DefaultButton onClick={onOpenWebinarClicked}>
                        {localize("OPEN_WEBINAR")}
                    </DefaultButton>
                    <DefaultButton onClick={onReportIssueClicked}>
                        {localize("REPORT_BUG")}
                    </DefaultButton>
                </div>
            </div>
            <TicketFormModal
                formTitle={localize("REPORT_BUG")}
                hideSupportLink={true}
                isOpen={zendeskOpen}
                onClosing={onZendeskClosed} />
        </div>
    )
}

export default CalypsoSupportBanner;
