import { mergeStyleSets, Separator } from "@fluentui/react";
import React from "react";
import { Style } from "src/ui/helpers";
import { Label } from "../Label/Label";
import ModalCloseButton from "./ModalCloseButton";

interface IProps {
    children: React.ReactNode;
    onClose: any;
    size?: spintrLabelSizes;
    hideCloseButton?: boolean;
    as?: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "h7" | "label" | "span" | "p" | "em";
}

const contentStyles = mergeStyleSets({
    header: [
        {
            display: "flex",
            alignItems: "center",
        },
    ],
    label: [
        {
            flexGrow: 1,
            marginTop: 0,
        },
    ],
});

const ModalHeader = (props: IProps) => {
    return (
        <div className="modal-header-wrapper">
            <div className={`${contentStyles.header} modal-header`}>
                <Label
                    className={contentStyles.label}
                    as={props.as ?? "h2"}
                    size={props.size ?? "h2"}
                    style={{
                        marginRight: Style.getSpacingStr(3),
                        marginBottom: 0
                    }}
                >
                    {props.children}
                </Label>
                {!props.hideCloseButton && <ModalCloseButton onClose={props.onClose} />}
            </div>
            <Separator style={{marginBottom: 16}} />
        </div>
    );
};

export default ModalHeader;
