import { Label, TooltipHost } from "@fluentui/react";
import React, { PropsWithChildren, ReactElement } from "react";
import { localize } from "src/l10n";
import "./FormControl.scss";
import classNames from "classnames";
import { Conditional } from "src/components/Conditional";
import { ConditionalRender } from "src/components/ConditionalRender";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

export type IFormControlProps = PropsWithChildren<{
    className?: string;
    htmlFor?: string;
    label?: string;
    hasTopSpacing?: boolean;
    required?: boolean;
    validationFailed?: boolean;
    tooltipText?: string | undefined;
}>;

function FormControl({
    children,
    className: propClassName,
    hasTopSpacing,
    htmlFor,
    label,
    required,
    tooltipText,
    validationFailed,
}: IFormControlProps): ReactElement {
    const className = classNames("FormControl", propClassName, {
        "spacingTop": hasTopSpacing,
        "validationFailed": validationFailed,
    });

    return (
        <div className={className}>
            <ConditionalRender condition={label !== undefined}>
                {() => (
                    <div className="label">
                        <Label
                            required={required}
                            htmlFor={htmlFor}
                        >
                            {label}
                        </Label>
                        <ConditionalRender condition={tooltipText !== undefined}>
                            {() => (
                                <TooltipHost
                                    className="marketplace-tooltip"
                                    content={tooltipText}
                                    style={{
                                        maxWidth: 300
                                    }}
                                >
                                <   Visage2Icon icon="info-circle" size="small" />
                                </TooltipHost>
                            )}             
                        </ConditionalRender>
                    </div>
                )}
            </ConditionalRender>
            <div className="control">{children}</div>
            <Conditional condition={validationFailed}>
                <Label>
                    <span className="required-field-text-replica">
                        {
                            localize("VALIDATION_FIELDNAME_IS_REQUIRED")
                        }
                    </span>
                </Label>
            </Conditional>
        </div>
    );
}


export default FormControl;