import React from "react";
import {
    AppPageBackground,
    AppPageBox,
    AppPageBreadcrumbs,
    AppPageComponentGroup,
    AppPageDataGrid,
    AppPageDataRegion,
    AppPageForm,
    AppPageHeader,
    AppPageList,
    AppPagePoweredBy,
    AppPageSubmitButton,
    AppPageText,
    AppPageTextInput,
} from "../components";
import { IAppPageComponent } from "../types";

export function buildAppPageComponent(item: IAppPageComponent, index: number = 0): JSX.Element {
    if (item.displayCondition) {
        const variables = item.variables || {};
        
        if (!variables[item.displayCondition]) {
            return null;
        }
    }

    switch (item.component) {
        case "Background":
            return (
                <AppPageBackground
                    { ...item }
                    key={index}
                />
            );

        case "Box":
            return (
                <AppPageBox
                    { ...item }
                    key={index}
                />
            );

        case "Breadcrumbs":
            return (
                <AppPageBreadcrumbs
                    { ...item }
                    key={index}
                />
            );

        case "ComponentGroup":
            return (
                <AppPageComponentGroup
                    { ...item }
                    children={item.children}
                    horizontal={
                        typeof item["horizontal"] === "boolean"
                            ? item["horizontal"] as boolean
                            : false
                    }
                    key={index}
                />
            );

        case "DataGrid":
            return (
                <AppPageDataGrid
                    { ...item }
                />
            );

        case "DataRegion":
            return (
                <AppPageDataRegion
                    {...item}
                    children={item.children}
                    key={index}
                />
            );

        case "Form":
            return (
                <AppPageForm
                    { ...item }
                    key={index}
                />
            );

        case "Header":
            return (
                <AppPageHeader
                    { ...item }
                    key={index}
                />
            );

        case "List":
            return (
                <AppPageList
                    { ...item }
                    key={index}
                />
            );

        case "PoweredBy":
            return (
                <AppPagePoweredBy
                    key={index}
                />
            );

        case "Submit":
            return (
                <AppPageSubmitButton
                    { ...item }
                    key={index}
                />
            );

        case "Text":
            return (
                <AppPageText
                    { ...item }
                    key={index}
                />
            );

        case "TextInput":
            return (
                <AppPageTextInput
                    { ...item }
                    name={item.name as string}
                    key={index}
                />
            );

        default: return null;
    }
}
