import moment from "moment";

// let days = [
//     { key: "monday", value: 1 },
//     { key: "tuesday", value: 2 },
//     { key: "wednesday", value: 4 },
//     { key: "thursday", value: 8 },
//     { key: "friday", value: 16 },
//     { key: "saturday", value: 32 },
//     { key: "sunday", value: 64 },
// ];

let lastId = 0;
const getInterval = (value, selectedMonth = undefined) => {
    let items = [];
    let values = [1, 2, 4, 8, 16, 32, 64];

    // 2%31
    // 2 = värdet på dagen, så kommer vara 1,2,4,8,16
    // 31 = är värdet man får in, vill testa
    let numbers = values.map((obj) => obj & value.availability);
    let today;
    if (!selectedMonth) {
        today = moment();
    } else {
        today = moment(selectedMonth).toDate();
    }

    let thisMonth = moment(today).startOf("month");

    let monday = thisMonth.startOf("isoWeek").add(2, "hours"); // FIXME: Why 2 hours?
    let tuesday = moment(monday).add(1, "days");
    let wednesday = moment(monday).add(2, "days");
    let thursday = moment(monday).add(3, "days");
    let friday = moment(monday).add(4, "days");
    let saturday = moment(monday).add(5, "days");
    let sunday = moment(monday).add(6, "days");

    function addDay(day) {
        let id = 1 + lastId;
        items.push(
            {
                bgColor: value.bgColor,
                group: value.group,
                id: id,
                start_time: moment.utc(day).startOf("day").valueOf(),
                end_time: moment.utc(day).endOf("day").valueOf(),
                title: value.title,
            },
            {
                bgColor: value.bgColor,
                group: value.group,
                id: id + 1,
                start_time: moment.utc(day).add(7, "days").startOf("day").valueOf(),
                end_time: moment.utc(day).add(7, "days").endOf("day").valueOf(),
                title: value.title,
            },
            {
                bgColor: value.bgColor,
                group: value.group,
                id: id + 2,
                start_time: moment.utc(day).add(14, "days").startOf("day").valueOf(),
                end_time: moment.utc(day).add(14, "days").endOf("day").valueOf(),
                title: value.title,
            },
            {
                bgColor: value.bgColor,
                group: value.group,
                id: id + 3,
                start_time: moment.utc(day).add(21, "days").startOf("day").valueOf(),
                end_time: moment.utc(day).add(21, "days").endOf("day").valueOf(),
                title: value.title,
            },
            {
                bgColor: value.bgColor,
                group: value.group,
                id: id + 4,
                start_time: moment.utc(day).add(28, "days").startOf("day").valueOf(),
                end_time: moment.utc(day).add(28, "days").endOf("day").valueOf(),
                title: value.title,
            },
            {
                bgColor: value.bgColor,
                group: value.group,
                id: id + 5,
                start_time: moment.utc(day).add(35, "days").startOf("day").valueOf(),
                end_time: moment.utc(day).add(35, "days").endOf("day").valueOf(),
                title: value.title,
            }
        );
        lastId = Math.max.apply(
            null,
            items.map((item) => item.id)
        );
    }

    numbers.forEach((obj) => {
        if (obj === 1) {
            addDay(monday);
        }
        if (obj === 2) {
            addDay(tuesday);
        }
        if (obj === 4) {
            addDay(wednesday);
        }
        if (obj === 8) {
            addDay(thursday);
        }
        if (obj === 16) {
            addDay(friday);
        }
        if (obj === 32) {
            addDay(saturday);
        }
        if (obj === 64) {
            addDay(sunday);
        }
    });

    return items;
};

export default getInterval;
