import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { UsersListPopup } from "src/users/views";

interface IProps {
    post: any;
}

interface IState {
    users: Spintr.IUser[];
    isLoading: boolean;
    displayPopup: boolean;
}

class SocialPostSeenBy extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isLoading: false,
            displayPopup: false
        };
    }

    click() {
        let fetchUrl = `/api/users/list?projectId=${this.props.post.feedOwnerId}&skip=0&take=999&userIds=${this.props.post.uberViews.join("&userIds=")}`;

        if (this.state.users &&
            this.state.users.length > 0) {
            this.setState({
                displayPopup: true
            });

            return;
        }

        this.setState({
            isLoading: true
        }, () => {
            api.get(fetchUrl).then((response) => {
                this.setState({
                    users: response.data.users,
                    isLoading: false,
                    displayPopup: true
                });
            });
        });
    }

    public renderPopup() {
        const users : Spintr.IUser[] = this.state.users || [];

        return (
            <UsersListPopup
                users={users}
                title={localize("SettAv")}
                popupIsHidden={!this.state.displayPopup}
                closePopup={() => {
                    this.setState({
                        displayPopup: false
                    });
                }} />
        )
    }

    public render() : ReactNode {
        if (!this.props.post.feedOwnerId ||
            !this.props.post.uberViews ||
            this.props.post.uberViews.length === 0) {
            return null;
        }

        return (
            <a onClick={this.click.bind(this)} style={{ color: "inherit" }}>
                {
                    !this.state.isLoading && (
                        localize("SettAv") + " " + this.props.post.uberViews.length
                    )
                }
                {
                    this.state.isLoading && (
                        <span style={{
                            position: "absolute",
                            top: "-3px",
                            marginLeft: 5
                        }}>
                            <Loader loaderSize="small" />
                        </span>
                    )
                }
                {
                    this.renderPopup()
                }
            </a>
        );
    }
}

export default SocialPostSeenBy;