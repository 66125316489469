import moment from "moment";
import React, { Component } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, Loader, UnstyledButton, UserHovercard } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import TinyFormattedContent from "./TinyFormattedContent";
import "./TinyQA.scss";

interface IProps {
    id: number;
}

interface IState {
    data?: any;
    isLoading: boolean;
}

class TinyQA extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        const url =
            "/api/qa?isAscending=true&orderbyColumn=question&searchText=&skip=0&take=999&tagId=" + this.props.id;

        api.get(url).then((response) => {
            this.setState({
                isLoading: false,
                data: response.data,
            });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="TinyQA-wrapper">
                <div className="TinyQA-header">
                    <Label weight="medium">{localize("FragorTaggadeMed") + ' "' + this.state.data.tagName + '"'}</Label>
                </div>
                <div className="TinyQA-content">
                    {this.state.data.qas.length === 0 && (
                        <div className="spintr-list-empty-list">
                            <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                                {localize("IngaPoster")}
                            </Label>
                        </div>
                    )}
                    {this.state.data.qas.map((item) => {
                        return (
                            <div className="TinyQA-question-wrapper" key={item.id}>
                                <UnstyledButton
                                    className="TinyQA-question-button"
                                    onClick={() => {
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                qas: this.state.data.qas.map((qa) => {
                                                    if (qa.id === item.id) {
                                                        return {
                                                            ...qa,
                                                            isExpanded: !qa.isExpanded,
                                                        };
                                                    } else {
                                                        return qa;
                                                    }
                                                }),
                                            },
                                        });
                                    }}
                                >
                                    <div className="TinyQA-question">
                                        <Visage2Icon icon={item.isExpanded ? "minus" : "add"} />
                                        <Label className="TinyQA-question-label" size="body-2" weight="medium">{item.question}</Label>
                                    </div>
                                </UnstyledButton>
                                {item.isExpanded && (
                                    <div className="TinyQA-answer">
                                        {item.answer && item.answer.length > 0 ? (
                                            <TinyFormattedContent content={item.answer} />
                                        ) : (
                                            <Label role="heading" as="div" color="grey" size="body-2">
                                                {localize("IngetSvarTillgangligt")}
                                            </Label>
                                        )}
                                        <div className="TinyQA-answered-by">
                                            <Label className="TinyQA-answered-by-inline" size="body-2" color="mid-grey">
                                                {localize("SenastAndrad") + " " + localize("Av_By").toLowerCase() + " "}
                                            </Label>
                                            <UserHovercard userId={item.answererId}>
                                                <UnstyledButton className="TinyQA-question-button" onClick={() => {}}>
                                                    <Label
                                                        className="TinyQA-answered-by-inline"
                                                        color="light-blue"
                                                        size="body-2"
                                                    >
                                                        {localize(item.answererName)}
                                                    </Label>
                                                </UnstyledButton>
                                            </UserHovercard>
                                            <Label className="TinyQA-answered-by-inline" size="body-2" color="mid-grey">
                                                {" " + moment(item.lastAnswered).format("L")}
                                            </Label>
                                            <UnstyledButton
                                                className="TinyQA-question-button"
                                                onClick={() => {
                                                    this.setState({
                                                        data: {
                                                            ...this.state.data,
                                                            qas: this.state.data.qas.map((qa) => {
                                                                if (qa.id === item.id) {
                                                                    return {
                                                                        ...qa,
                                                                        isExpanded: !qa.isExpanded,
                                                                    };
                                                                } else {
                                                                    return qa;
                                                                }
                                                            }),
                                                        },
                                                    });
                                                }}
                                            >
                                                <Label color="light-blue" size="body-2">
                                                    {localize("Stang")}
                                                </Label>
                                            </UnstyledButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default TinyQA;
