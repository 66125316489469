import { Icon } from "@fluentui/react";
import classNames from "classnames";
import React, { Fragment, FunctionComponent, useCallback, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { Label, UnstyledButton } from "src/ui";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import { StartPageBuilderColumn } from "../StartPageBuilderColumn";
import { StartPageBuilderContext } from "../StartPageBuilderContext";
import { StartPageBuilderDivider } from "../StartPageBuilderDivider";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    row: Spintr.IStartPageRow;
}

const roundToNearest = (x: number, m: number = 5) => ((x % m) >= (m / 2))
    ? m * Math.round((x + (m / 2)) / m)
    : m * Math.round(x / m);

const getMinSize = (componentKeys: string[]): number => componentKeys.reduce(
    (minSize, componentKey) => {
        if ((componentKey === "SocialFeed" || componentKey === "NewsFeed") && minSize < 20) {
            return 20;
        }

        return minSize;
    },
    15,
);

const defaultScrollOffset: string = "calc(50% - 15px)";

const StartPageBuilderRow: FunctionComponent<IProps> = (props) => {
    const { row } = props;
    const context = useContext(StartPageBuilderContext);
    const { setSelected, addCell, removeComponent } = context;
    const { id: rowId  } = row;

    const selfRef = useRef<HTMLDivElement>();
    const ownWidth = selfRef?.current?.clientWidth || 100;

    const currentSelf = selfRef?.current;

    const windowHeight = window?.document?.firstElementChild?.clientHeight || 0;


    const columns = row.columns || [];

    const onSizeChanged = useCallback(
        (delta: number, prevId: string, nextId: string, snapToGrid: boolean) => {
            const previous = columns.find((col) => col.id === prevId);
            const next = columns.find((col) => col.id === nextId);
            
            if (!previous) {
                return;
            }

            const totalWidth = columns.reduce((acc, col) => acc + col.width, 0);
            
            const compBase = totalWidth - columns
                .filter((col) => col !== previous && col !== next)
                .reduce((total, col) => total + (col.width || 0), 0);

            const prevMinSize = getMinSize(previous.items.map((item) => item.componentKey));
            const nextMinSize = getMinSize(next.items.map((item) => item.componentKey));
            
            const previousSize = ((previous.width / 100) * ownWidth) + delta;

            let prevPercentage = Math.max(prevMinSize, Math.min(100, (previousSize / ownWidth) * 100));
            let nextPercentage = compBase - prevPercentage;
            if (nextPercentage < nextMinSize) {
                const diff = nextMinSize - nextPercentage;
                nextPercentage = nextMinSize;
                prevPercentage -= diff;
            }
            
            if (snapToGrid) {
                prevPercentage = roundToNearest(prevPercentage, 5);
                nextPercentage = compBase - prevPercentage;
            }

            context.resizeComponent(previous.id, prevPercentage);
            if (!next) {
                return;
            }

            context.resizeComponent(next.id, nextPercentage);
        },
        [context, columns, ownWidth],
    );
    
    const [scrollOffset, setScrollOffset] = useState(defaultScrollOffset);
    useEffect(
        () => {
            const onScroll = (_: Event) => {
                if (!currentSelf || !windowHeight) {
                    setScrollOffset(defaultScrollOffset);
                    return;
                }

                const ownRect = currentSelf.getBoundingClientRect();
                if (ownRect.bottom <= 0) {
                    // not visible so don't calc
                    setScrollOffset(defaultScrollOffset);
                    return;
                }

                if (ownRect.bottom < windowHeight) {
                    setScrollOffset(defaultScrollOffset);
                    return;
                }

                if (ownRect.top > 0 && ownRect.top < windowHeight) {
                    const relOffset = ((windowHeight - ownRect.top) / 2) - 15;
                    setScrollOffset(relOffset + "px");
                    return;
                }

                const offset = (((windowHeight / 2) - ownRect.top) - 15);
                setScrollOffset(offset + "px");
            };

            window.addEventListener("scroll", onScroll);

            return () => window.removeEventListener("scroll", onScroll);
        },
        [setScrollOffset, currentSelf, windowHeight],
    );

    const onAddClick = useCallback(
        ([componentId, before]) => {
            if (typeof componentId !== "string") {
                return;
            }

            addCell(componentId, before);
            mixpanelTrack(componentId === rowId
                ? MixPanelEvents.Dynamo_AddRow
                : MixPanelEvents.Dynamo_AddColumn);
        },
        [addCell, rowId],
    );
    const dispatch = useDispatch();

    const columnCount = props.row.columns.length;
    const onRemoveClick = useCallback((componentId: any) => {
        if (typeof componentId !== "string") {
            return;
        }

        const remove = () => {
            removeComponent(componentId)
            mixpanelTrack(MixPanelEvents.Dynamo_RemoveRow);
        };

        if (columnCount === 0) {
            remove();
            return;
        }

        dispatch(setConfirmPopup({
            isOpen: true,
            message: localize("CONFIRM_OBJECT_DELETION"),
            onConfirm: () => remove(),
        }));
    }, [dispatch, removeComponent, columnCount]);

    const onClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();

        setSelected(rowId);
    }, [setSelected, rowId]);

    return (
        <div
            className={classNames("StartPageBuilderRow", {
                "selected": context.selectedId === rowId,
                "empty": columns.length === 0,
            })}
            onClick={onClick}
            ref={selfRef}>
            <div className="button-container">
                <UnstyledButton
                    className="add-before-row"
                    onClick={onAddClick}
                    title={localize("ADD_ROW")}
                    onClickData={[rowId, true]}
                >
                    <Visage2Icon
                        color="dark-grey"
                        icon="Add"
                        size="medium"
                    />
                    <Label
                        color="dark-grey"
                        size="body-3"
                        weight="medium"
                    >
                        {localize("ADD_NEW_ROW")}
                    </Label>
                </UnstyledButton>
            </div>
            {false && context.selectedId === rowId && (
                <div className="controls-container">
                    <div className="controls">
                        <div className="control edit">
                            <Visage2Icon icon="Edit" color="neutralBlue" />
                            <Label size="body-2" weight="medium">{localize("Andra")}</Label>
                        </div>
                        <UnstyledButton
                            className="control move"
                        >
                            <Visage2Icon icon="arrow-2" />
                        </UnstyledButton>
                        <UnstyledButton
                            className="control delete"
                            onClick={onRemoveClick}
                            onClickData={rowId}
                        >
                            <Visage2Icon icon="Delete" color="red" />
                        </UnstyledButton>
                    </div>
                </div>
            )}
            <div className="row-content">
                {columns.map((column, index) => (
                    <Fragment key={column.id}>
                        <StartPageBuilderColumn
                            column={column}
                            key={column.id + "-column"}
                        />
                        {index < (columns.length - 1) && (
                            <StartPageBuilderDivider
                                nextId={(index + 1) !== columns.length ? columns[index + 1].id : ""}
                                onSizeChange={onSizeChanged}
                                previousId={column.id}
                                key={column.id + "-divider"}
                                snapOffset={
                                    !column.width || column.width % 5 === 0
                                        ? 0
                                        : (column.width % 5 >= 2.5
                                            ? (
                                                ((roundToNearest(column.width, 5) - column.width) / 100) * ownWidth
                                            )
                                            : 0 - (((column.width % 5) / 100) * ownWidth)
                                        )
                                }
                            />
                        )}
                        {row.columns.length < 3 && (
                            <div className="add-column-button">
                                <UnstyledButton
                                    className="button"
                                    onClick={onAddClick}
                                    onClickData={[column.id, false]}
                                    title={localize("ADD_COLUMN")}
                                    ariaLabel={localize("ADD_COLUMN")}
                                    style={{
                                        top: scrollOffset,
                                    }}
                                >
                                    <Visage2Icon icon="add-circle" />
                                </UnstyledButton>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default StartPageBuilderRow;
