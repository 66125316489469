import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { getQueryStringMap } from "src/utils";
import { UserCatalogueView } from ".";
import "./UserCatalogueView.scss";

interface IProps {
    currentUser: any;
    instance: any;
    isAdmin: boolean;
    isEditor: boolean;
    location?: any;
    enableCompany: boolean;
}

interface IState {
    quickFilter: any;
    roleFilter: any;
    unitFilter: any;
    data?: any;
}

type Props = IProps & RouteComponentProps;

class UserCatalogueViewWithSubmenu extends Component<Props, IState> {
    protected submenuRef: any;

    constructor(props) {
        super(props);

        const queryString = getQueryStringMap(this.props.location);
        const defaultUserCatalogueQuickFilter = props.instance.get('defaultUserCatalogueQuickFilter');

        let quickFilter = 0;
        let roleFilter = parseInt(queryString.r, 10) || 0;
        let unitFilter = parseInt(queryString.d, 10) || 0;

        if (roleFilter === 0 &&
            unitFilter === 0 &&
            defaultUserCatalogueQuickFilter > 0) {
            if (defaultUserCatalogueQuickFilter === 1) {
                quickFilter = 1;
                roleFilter = 0;
                unitFilter = props.currentUser.department.office.id;
            } else if (defaultUserCatalogueQuickFilter === 2) {
                quickFilter = 2;
                roleFilter = 0;
                unitFilter = props.currentUser.department.id;
            } else if (defaultUserCatalogueQuickFilter === 3) {
                quickFilter = 3;
                roleFilter = -5;
                unitFilter = 0;
            } else if (defaultUserCatalogueQuickFilter === 4) {
                quickFilter = 4;
                roleFilter = -10;
                unitFilter = 0;
            }  else if (defaultUserCatalogueQuickFilter === 5) {
                quickFilter = 5;
                roleFilter = 0;
                unitFilter = props.currentUser.department.office.company.id;
            }
        }

        this.state = {
            quickFilter,
            roleFilter,
            unitFilter,
        };

        this.submenuRef = React.createRef();
    }

    private updateQueryString = () => {
        const query = {
            ...getQueryStringMap(this.props.location),
            r: this.state.roleFilter || undefined,
            d: this.state.unitFilter|| undefined,
        };

        this.props.history.push({ search: qs.stringify(query) });
    };

    updateSubmenu() {
        if (this.submenuRef &&
            this.submenuRef.current) {
            this.submenuRef.current.fetch();
        }
    }

    postFilterFn = () => {
        this.updateSubmenu();
        this.updateQueryString();
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.location.search !== prevProps.location.search) {
            const s = this.props.location.search;

            if (!!s && s.indexOf("=") > -1) {
                const id = parseInt(s.split("=")[1]);
                const isRole = s.indexOf("?r=") > -1;
                const isUnit = s.indexOf("?d=") > -1;
                const isAdministrators = isRole && id === -5;
                const isEditors = isRole && id === -10;

                this.setState({
                    quickFilter: isAdministrators ? 3 : isEditors ? 4 : 0,
                    roleFilter: isRole ? id : 0,
                    unitFilter: !isAdministrators && !isEditors && isUnit ? id : 0
                });
            } else if (!s && !!prevProps.location.search) {
                this.setState({
                    quickFilter: 0,
                    roleFilter: 0,
                    unitFilter: 0
                });
            }
        }
    }

    public render() {
        return (
            <div>
                <UserCatalogueView
                    filterOnRole={this.state.roleFilter}
                    filterOnDepartment={this.state.unitFilter}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    instance: state.instance,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    enableCompany: state.instance.get("enableCompanyLevel"),
});

export default connect(mapStateToProps)(UserCatalogueViewWithSubmenu);
