import React, { CSSProperties, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TimelineRowCreationBoxState, TimelineRowProps, TimelineRowState } from "./TimelineRow.types";
import { CaptionBody, SmallBody } from "src/components/Text";
import { TimelineBarGroup } from "../TimelineBarGroup";
import { calculateBarPosition, formatDates, snapCreationBoxToGrid } from "../utils";
import { ConditionalRender } from "src/components/ConditionalRender";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Conditional } from "src/components/Conditional";
import { UnstyledButton } from "src/ui";
import { ITextField, TextField } from "@fluentui/react";
import { SpintrTypes } from "src/typings";

function TimelineRow({
    axisWidth,
    category,
    hasWriteAccess,
    onBarClick,
    onBarGroupClick,
    onCategoryUpdate,
    onItemDurationChange,
    onRailClick,
    rowIndex,
    style,
    timelineDuration,
    timelineMode,
    timelineWidth,
    todayTime,
}: TimelineRowProps): ReactElement {
    const railRef = useRef<HTMLDivElement>(null);
    const textFieldRef = useRef<ITextField>(null);
    const [creationBox, setCreationBox] = useState<TimelineRowCreationBoxState | null>(null);
    const [state, setState] = useState<TimelineRowState>({
        isEditing: false,
        name: category.name,
    });

    const railStyle = useMemo<CSSProperties>(
        () => ({ width: `${timelineWidth}px`, }),
        [timelineWidth],
    );

    const { name, items } = category;

    const onMouseMove = useCallback(function _TimelineRowMouseMove(event: MouseEvent<HTMLDivElement>) {
        if (!railRef.current) {
            setCreationBox(null);
            return;
        }

        const draggedBars = railRef.current.querySelectorAll(".TimelineBar.dragging");
        if (draggedBars.length > 0) {
            setCreationBox(null);
            return;
        }

        let scrollParent: HTMLElement = railRef.current;
        while (!scrollParent.classList.contains("timeline") && scrollParent.parentElement) {
            scrollParent = scrollParent.parentElement;
        }

        if (!scrollParent) {
            setCreationBox(null);
            return;
        }

        const mouseX = event.clientX;
        const axisOffset = axisWidth || 257;

        const scrollParentRect = scrollParent.getBoundingClientRect();
        const scrollParentLeft = scrollParentRect.left
        const scrollLeft = scrollParent.scrollLeft - axisOffset; // remove axis width

        const manualElementOffset = 25;
        const positionInTimeline = Math.max(0, mouseX - manualElementOffset - scrollParentLeft + scrollLeft);
        
        const percentage = positionInTimeline / timelineWidth;
        const timeAtPointer = timelineDuration.startMilliseconds + timelineDuration.totalMilliseconds * percentage;

        const startDate = snapCreationBoxToGrid(new Date(timeAtPointer), timelineMode);
        let timeToEnd: number;
        switch (timelineMode) {
            case "DAYS":
                timeToEnd = 24 * 60 * 60 * 1000 - 1;
                break;

            case "WEEKS":
                timeToEnd = 3 * 24 * 60 * 60 * 1000 - 1;
                break;
            
            default:
            case "MONTHS":
            case "QUARTERS":
                timeToEnd = 7 * 24 * 60 * 60 * 1000 - 1;
                break;
        }

        const endDate = new Date(startDate.getTime() + timeToEnd)

        const flatItems = items
            .flatMap((barGroup) => barGroup.items)
            .filter(
                // Make sure that the bar (startDate, endDate) doesn't overlap or clip the existing items
                (item) => item.start.getTime() < endDate.getTime() && item.end.getTime() > startDate.getTime()
            );

        if (flatItems.length > 0) {
            setCreationBox(null);
            return;
        }

        setCreationBox({ startDate, endDate, });
    }, [railRef.current, axisWidth, timelineMode, timelineWidth, items, setCreationBox]);

    const onMouseLeave = useCallback(function _TimelineRowMouseLeave(_: MouseEvent<HTMLDivElement>) {
        setCreationBox(null);
    }, [setCreationBox]);

    const onRailClicked = useCallback((event: MouseEvent<HTMLDivElement>) => {
        if (!creationBox) {
            return;
        }

        const element = event.target as HTMLElement;
        if (element?.classList) {
            const disallowedClasses = ["TimelineBar-barHandle", "TimelineBar"];
            if (disallowedClasses.some((className) => element.classList.contains(className))) {
                return;
            }
        }

        const { startDate, endDate } = creationBox;

        onRailClick?.(category, startDate, endDate);
    }, [onRailClick, creationBox, category]);

    const creationBoxStyle = useMemo<CSSProperties>(() => {
        if (!creationBox) {
            return {};
        }
        
        const { left, right } = calculateBarPosition(
            timelineWidth,
            timelineDuration,
            creationBox.startDate.getTime(),
            creationBox.endDate.getTime()
        );

        return {
            height: "42px",
            left: `${left}px`,
            right: `${right}px`,
            top: "4px",
        };
    }, [creationBox, timelineDuration, timelineWidth]);

    const onEditButtonClicked = useCallback(() => {
        setState((prevState) => ({
            ...prevState, isEditing: true,
        }));

        setTimeout(() => textFieldRef.current?.focus?.(), 1);
    }, [setState, textFieldRef]);

    const onSaveNameClicked = useCallback(async () => {
        if (!state.isEditing || state.name.length === 0) {
            return;
        }

        if (state.name === category.name) {
            setState((prevState) => ({ ...prevState, isEditing: false }));
            return;
        }

        onCategoryUpdate?.({
            id: category.key,
            contentStatus: SpintrTypes.ContentStatus.Published,
            name: state.name,
        });

        setState((prevState) => ({ ...prevState, isEditing: false }));
    }, [state, category]);

    const onCancelEditClicked = useCallback(() => setState((prevState) => ({
        ...prevState, isEditing: false, name: category.name,
    })), [setState, category.name]);

    const onNameKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case "Enter":
                onSaveNameClicked();
                break;

            case "Escape":
                onCancelEditClicked();
                break;
        }
    }, [onSaveNameClicked, onCancelEditClicked]);

    const onNameInputChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setState((prevState) => ({ ...prevState, name: value }));
    }, [setState]);

    useEffect(() => setState((prevState) => ({
        ...prevState,
        name: prevState.isEditing ? prevState.name : category.name,
    })), [category.name]);

    return (
        <section
            aria-rowindex={rowIndex}
            className="TimelineRow"
            role="row"
            style={style}
        >
            <div className="TimelineRow-label" role="gridcell" style={{
                overflow: axisWidth > 0 ? undefined : "hidden",
                padding: axisWidth > 0 ? undefined : 0,
                width: `${axisWidth}px`,
            }}>
                <Conditional condition={state.isEditing}>
                    <TextField
                        componentRef={textFieldRef}
                        className="TimelineRow-nameInput"
                        onChange={onNameInputChanged}
                        onKeyDown={onNameKeyDown}
                        value={state.name} />

                    <UnstyledButton
                        className="TimelineRow-saveButton"
                        onClick={onSaveNameClicked}
                    >
                        <Visage2Icon
                            className="TimelineRow-saveIcon"
                            color="visageGray"
                            icon="tick-square"
                            size="small" />
                    </UnstyledButton>

                    <UnstyledButton
                        className="TimelineRow-cancelButton"
                        onClick={onCancelEditClicked}
                    >
                        <Visage2Icon
                            className="TimelineRow-cancelIcon"
                            color="visageGray"
                            icon="add"
                            size="small" />
                    </UnstyledButton>
                </Conditional>

                <Conditional condition={!state.isEditing}>
                    <SmallBody
                        className="TimelineRow-labelText"
                        color="contentNormal"
                        title={category.name}
                        weight="regular"
                    >
                        {name}
                    </SmallBody>
                    <Conditional condition={hasWriteAccess && onCategoryUpdate}>
                        <UnstyledButton
                            className="TimelineRow-editButton"
                            onClick={onEditButtonClicked}
                        >
                            <Visage2Icon
                                className="TimelineRow-editIcon"
                                color="visageGray"
                                icon="edit"
                                size="small" />
                        </UnstyledButton>
                    </Conditional>    
                </Conditional>
            </div>

            <div
                className="TimelineRow-rail"
                onClick={hasWriteAccess ? onRailClicked : undefined}
                onMouseLeave={hasWriteAccess ? onMouseLeave : undefined}
                onMouseMove={hasWriteAccess ? onMouseMove : undefined}
                role="gridcell"
                ref={railRef}
                style={railStyle}
            >
                {items.map((barGroup) => (
                    <TimelineBarGroup
                        axisWidth={axisWidth}
                        group={barGroup}
                        key={barGroup.key}
                        onBarClick={onBarClick}
                        onBarGroupClick={onBarGroupClick}
                        onItemDurationChange={onItemDurationChange}
                        timelineDuration={timelineDuration}
                        timelineMode={timelineMode}
                        timelineWidth={timelineWidth}
                        todayTime={todayTime} />
                ))}
                <ConditionalRender condition={creationBox !== null && onRailClick}>
                    {() => (
                        <div className="TimelineRow-creationBox" style={creationBoxStyle}>
                            <div className="creationBox-inner">
                                <CaptionBody
                                    className="creationBox-label-top"
                                    color="contentDark"
                                    weight="medium"
                                >
                                    {localize("SkapaNytt")}
                                </CaptionBody>
                                <div className="creationBox-label-bottom">
                                    <CaptionBody color="contentNormal" weight="regular">
                                        {formatDates(
                                            creationBox.startDate.getTime(),
                                            creationBox.endDate.getTime(),
                                        )}
                                    </CaptionBody>
                                </div>
                            </div>
                        </div>
                    )}
                </ConditionalRender>
            </div>
        </section>
    );
}

export default TimelineRow;
