import { AxiosError, AxiosResponse } from "axios";
import { IconButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Breadcrumbs, ContentWithSubmenu, Label, PageHeader, Submenu, UnstyledButton } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, FormFooterBar } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { scrollToTop } from "src/utils";
import "./OrganisationInviteView.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IRouteParams {
    unitId?: string;
    inviteId?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isAdmin: boolean;
    currentUserDepartment: number;
    currentUserOffice: number;
    enableCompany: boolean;
}

interface IState {
    isLoading: boolean;
    departments: any[];
    duplicates: any[];
    showDuplicateDialog: boolean;
    saveError: string[];
}

class OrganisationInviteView extends Component<IProps, IState> {
    private unitId = this.props.match.params.unitId;
    private inviteId = this.props.match.params.inviteId;
    private organisationSpecific = this.unitId ? this.inviteId || this.unitId : false;
    private hideSubmenu = !!this.organisationSpecific;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            departments: [],
            duplicates: [],
            showDuplicateDialog: false,
            saveError: [],
        };
    }

    componentDidMount() {
        if (!this.props.isAdmin) {
            this.organisationSpecific = this.props.currentUserOffice.toString();
            this.inviteId = this.props.currentUserOffice.toString();
            this.unitId = this.props.currentUserDepartment.toString();
        }

        if (this.organisationSpecific) {
            if (this.unitId && this.inviteId && this.unitId !== this.inviteId) {
                api.get(`/api/v1/departments/${this.unitId}`).then((response: AxiosResponse) => {
                    let department = response.data;

                    this.setState({
                        isLoading: false,
                        departments: [
                            {
                                id: department.id,
                                name: department.name,
                                expanded: true,
                                invites: [{ email: "", isNew: true }],
                            },
                        ],
                    });
                });
            } else {
                var params = {
                    officeId: this.inviteId,
                    take: 9999,
                };
                api.get("/api/v1/departments", { params: params }).then((response: AxiosResponse) => {
                    let departments = response.data.map((department) => ({
                        id: department.id,
                        name: department.name,
                        expanded: false,
                        invites: [{ email: "", isNew: true }],
                    }));

                    this.setState({
                        isLoading: false,
                        departments: departments,
                    });
                });
            }
            return;
        }

        api
            .get(`/api/v1/units/simple`, {
                params: { includeDeleted: false, includeCompanies: this.props.enableCompany },
            })
            .then((response: AxiosResponse) => {
                let departments = [];

                if (!this.props.enableCompany) {
                    departments = response.data.flatMap((office) =>
                        office.departments.map((department) => ({
                            id: department.id,
                            name: `${office.name} > ${department.name}`,
                            expanded: this.props.currentUserDepartment === department.id,
                            invites: [{ email: "", isNew: true }],
                        }))
                    );
                } else {
                    departments = response.data.flatMap((company) =>
                        company.offices.flatMap((office) =>
                            office.departments.map((department) => ({
                                id: department.id,
                                name: `${company.name} > ${office.name} > ${department.name}`,
                                expanded: this.props.currentUserDepartment === department.id,
                                invites: [{ email: "", isNew: true }],
                            }))
                        )
                    );
                }

                this.setState({
                    isLoading: false,
                    departments: departments,
                });
            });
    }

    fetchSubmenu = (params: submenuQueryParams) => {
        return new Promise((resolve, reject) => {
            api
                .get("/api/v1/units", {
                    params: { ignoreEmptyOffices: true, searchText: params.searchText, takeAll: true },
                })
                .then((response: AxiosResponse) => {
                    var offices = [
                        {
                            name: localize("Alla"),
                            id: 0,
                            url: "/organisation/0",
                        },
                        ...response.data.offices.map((office) => {
                            return {
                                name: office.name,
                                id: office.id,
                                url: `/organisation/${office.id}`,
                            };
                        }),
                    ];

                    resolve(offices);
                });
        });
    };

    submenuSearchProps = { enabled: true };

    onCancelClick = () => {
        this.props.history.push({
            pathname: this.organisationSpecific ? `/organisation/v/${this.unitId}/members` : `/organisation`,
        });
    };

    onSaveError = (error: AxiosError) => {
        this.setState(
            {
                saveError: error.response.data.errorlist || ["TeknisktFel"],
                isLoading: false,
                showDuplicateDialog: false,
            },
            () => {
                scrollToTop();
            }
        );
    };

    onSaveClick = () => {
        this.setState({ isLoading: true });

        var departmentsWithInvites = this.state.departments.filter((d) => d.invites.filter((i) => !i.isNew).length > 0);

        api
            .post("/api/v1/units/invitewizard", departmentsWithInvites)
            .then((response: AxiosResponse) => {
                if (response.data.length > 0) {
                    this.setState({
                        duplicates: response.data,
                        showDuplicateDialog: true,
                        isLoading: false,
                    });
                    return;
                }

                this.props.history.push({
                    pathname: this.organisationSpecific ? `/organisation/v/${this.unitId}/members` : `/organisation`,
                });
            })
            .catch(this.onSaveError);
    };

    breadcrumbItems = [
        {
            key: "",
            text: localize("Organisation"),
            link: "/organisation",
        },

        {
            key: "invite",
            text: localize("LaggTillDinaKollegor"),
        },
    ];

    toggleExpanded = (department) => {
        var departments = [...this.state.departments];
        var departmentIdx = departments.findIndex((d) => department.id == d.id);
        var newDepartment = { ...departments[departmentIdx] };
        newDepartment.expanded = !newDepartment.expanded;
        departments[departmentIdx] = newDepartment;

        this.setState({
            departments: departments,
        });
    };

    emailChange = (invite, department, val) => {
        var departments = [...this.state.departments];
        var departmentIdx = departments.findIndex((d) => department.id == d.id);
        var department2 = { ...departments[departmentIdx] };
        var invites = [...department2.invites];
        var inviteIdx = invites.findIndex((i) => invite == i);
        var invite2 = { ...invites[inviteIdx] };

        if (invite2.isNew) {
            var newInvite = { email: "", isNew: true };
            invites.push(newInvite);
            invite2.isNew = false;
        }

        invite2.email = val;

        invites[inviteIdx] = invite2;
        department2.invites = invites;
        departments[departmentIdx] = department2;

        this.setState({ departments });
    };

    removeIconProps = { iconName: "RemoveFilter" };

    removeInvite = (invite, department) => {
        var departments = [...this.state.departments];
        var departmentIdx = departments.findIndex((d) => d.id == department.id);
        var newDepartment = { ...departments[departmentIdx] };
        newDepartment.invites = newDepartment.invites.filter((i) => i != invite);

        departments[departmentIdx] = newDepartment;

        this.setState({
            departments,
        });
    };

    DepartmentListItem = React.memo((props: any) => {
        return (
            <div>
                <DepartmentButton department={props.department} onClick={this.toggleExpanded} />
                {props.department.expanded &&
                    props.department.invites.map((invite, idx) => (
                        <InviteListItem
                            key={idx}
                            department={props.department}
                            invite={invite}
                            emailChange={this.emailChange}
                            removeInvite={this.removeInvite}
                            removeIconProps={this.removeIconProps}
                        />
                    ))}
            </div>
        );
    });

    dismissErrorBar = () => {
        this.setState({
            saveError: [],
        });
    };

    DuplicateDialog = React.memo((props: any) => {
        const duplicates = this.state.duplicates;

        return (
            <CustomDialog
                show={props.show}
                confirmMessage={localize("Ja")}
                dismissMessage={localize("Nej")}
                children={
                    <>
                        {duplicates.map((duplicate) => (
                            <div key={duplicate.email}>
                                <Label>
                                    {`${duplicate.email} (${duplicate.name}) ${localize(
                                        "TillhorRedan"
                                    ).toLowerCase()} ${duplicate.officeName} - ${duplicate.departmentName}`}
                                </Label>
                            </div>
                        ))}
                        <div>
                            <Label>
                                {localize(duplicates.length == 1 ? "VillDuFlyttaHonomHenne" : "VillDuFlyttaDem")}
                            </Label>
                        </div>
                    </>
                }
                onConfirm={() => {
                    this.setState({ isLoading: true, showDuplicateDialog: false, duplicates: [] });
                    var departmentsWithInvites = this.state.departments.filter(
                        (d) => d.invites.filter((i) => !i.isNew).length > 0
                    );

                    api
                        .post("/api/v1/units/invitewizard?confirm=true", departmentsWithInvites)
                        .then((response: AxiosResponse) => {
                            this.props.history.push({
                                pathname: "/organisation",
                            });
                        })
                        .catch(this.onSaveError);
                }}
                onDismiss={() => {
                    this.setState({
                        showDuplicateDialog: false,
                        duplicates: [],
                    });
                }}
            />
        );
    });

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const departments = this.state.departments;

        return (
            <div>
                <Breadcrumbs displayInstance items={this.breadcrumbItems} />
                <PageHeader title={localize("LaggTillDinaKollegor")} />
                <div className="page-margin-bottom organisation-invite-view">
                    {this.state.saveError.length > 0 && (
                        <ErrorMessagebar errorList={this.state.saveError} onDismiss={this.dismissErrorBar} />
                    )}
                    <this.DuplicateDialog show={this.state.showDuplicateDialog} />

                    <Label color="dark-grey" size="body-2" as="h3">
                        {localize("LaggTillDinaKollegorBeskrivning")}
                    </Label>

                    {departments.map((department) => (
                        <this.DepartmentListItem key={department.id} department={department} />
                    ))}
                    <FormFooterBar
                        onCancelClick={this.onCancelClick}
                        onSaveClick={this.onSaveClick}
                        saveText={localize("BjudIn")}
                    />
                </div>
            </div>
        );
    }
}

const DepartmentButton = React.memo(
    (props: any) => {
        const _onClick = () => {
            props.onClick(props.department);
        };

        return (
            <UnstyledButton className="department-button" onClick={_onClick}>
                <Label as="span" className="expander">
                    {props.department.expanded ? "-" : "+"}&nbsp;
                </Label>
                <Label as="span">{props.department.name}</Label>
            </UnstyledButton>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.department.expanded === nextProps.department.expanded;
    }
);

const InviteListItem = React.memo(
    (props: any) => {
        return (
            <div className="input-container">
                <FormControl>
                    <EmailTextField department={props.department} invite={props.invite} onChange={props.emailChange} />
                </FormControl>

                {!props.invite.isNew && (
                    <RemoveButton
                        item={props.invite}
                        department={props.department}
                        onClick={props.removeInvite}
                        removeIconProps={props.removeIconProps}
                    />
                )}
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.invite === nextProps.invite;
    }
);

const EmailTextField = React.memo(
    (props: any) => {
        const _onChange = (ev, val) => {
            props.onChange(props.invite, props.department, val);
        };

        return <TextField placeholder={localize("Epost")} value={props.invite.email} onChange={_onChange} />;
    },
    (prevProps, nextProps) => {
        return prevProps.invite.email == nextProps.invite.email;
    }
);

const RemoveButton = React.memo((props: any) => {
    const _onClick = () => {
        props.onClick(props.item, props.department);
    };

    return (
        <UnstyledButton onClick={_onClick}>
            <Visage2Icon icon="trash" />
        </UnstyledButton>
    )
});

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        currentUserDepartment: state.profile.active.department.id,
        currentUserOffice: state.profile.active.department.office.id,
        enableCompany: state.instance.get("enableCompanyLevel"),
    };
};

export default connect(mapStateToProps)(OrganisationInviteView);
