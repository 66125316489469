export default function getInitialsFromString(str) {
    if (!str) {
        return ''
    }

    let parts = str.split(' ')
    if (parts.length === 1) {
        return str[0];
    }

    return parts[0][0] + parts[parts.length - 1][0]
}