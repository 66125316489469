import { PrimaryButton, Text } from "@fluentui/react";
import React, { ReactNode } from "react";
import { localize } from "src/l10n";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

class FortnoxInstaller extends AuthenticationDataComponent<any, any> {
    private readonly instructions: string;
    private readonly activateAppText: string;

    private popup: Window | null = null;

    constructor(props: any) {
        super(props);
        console.log(props);

        this.instructions = localize("");
        this.activateAppText = localize("FORTNOX_ACTIVATE_APP");

        this.state = {
            accessCodeReceived: false,
            authData: {},
        };

        this.onActivateClick = this.onActivateClick.bind(this);
    }

    public componentDidMount() {
        try {
            const authData = JSON.parse(this.props.authenticationData);

            this.setState({ authData });
        } catch (_) {
            /* ignored */
        }
    }

    public getAuthenticationData(): string {
        return "";
    }

    public isValid(): boolean {
        return false;
    }

    public render(): ReactNode {
        return (
            <div className="FortnoxInstaller">
                <Text
                    className="instructions"
                    as="p"
                >
                    {this.instructions}
                </Text>
                {!this.state.accessCodeReceived ? (
                    <PrimaryButton
                        className="consent-button"
                        as="button"
                        onClick={this.onActivateClick}
                    >
                        {this.activateAppText}
                    </PrimaryButton>
                ) : (
                    <Text as="p">
                        {localize("OAUTH2_CONSENT_GRANTED")}
                    </Text>
                )}
            </div>
        );
    }

    private onActivateClick(): void {
        this.popup = window.open(
            this.state.authData.authorizeUri,
            "SpintrAppFortnox",
            [
                "height=800",
                "location=no",
                "menubar=no",
                "resizable=yes",
                "toolbar=no",
                "width=1300"
            ].join(",")
        );
    }
}

export default FortnoxInstaller;
