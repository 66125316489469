import classNames from 'classnames';
import React, { Component } from 'react';
import { Icon, Label } from "src/ui";
import { UnstyledButton } from '../Buttons';
import "./FormSection.scss";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

declare type formSectionType = 
    | "wrapper"
    | "inner";

interface IProps {
    title?: string
    isExpandable?: boolean
    className?: string;
}

interface IState {
    isExpanded: boolean
}

class FormSection extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: false
        }
    }

    getLabel() {
        if (this.props.isExpandable) {
            return (
                <UnstyledButton className="no-user-select" onClick={() => {
                    this.setState({
                        isExpanded: !this.state.isExpanded
                    });
                }}>
                    <div style={{
                        verticalAlign: "2px",
                        display: "inline-block"
                    }}>
                        <Visage2Icon icon={this.state.isExpanded ? "add" : "minus"} />
                    </div>
                    <div style={{
                        verticalAlign: "middle",
                        display: "inline-block"
                    }}>
                        <Label size="body-2" weight="medium">
                            {
                                this.props.title
                            }
                        </Label>
                    </div>
                </UnstyledButton>
            )
        }

        return (
            <Label size="body-2" weight="medium">
                {
                    this.props.title
                }
            </Label>
        )
    }

    render() {
        return (
            <div className={classNames("FormSection form-item-width", this.props.className)}>
                <div>
                    {
                        !!this.props.title &&
                        this.props.title.length > 0 ?
                            this.getLabel() :
                            null
                    }
                </div>
                {
                    this.props.isExpandable && !this.state.isExpanded ?
                        null :
                        this.props.children
                }
            </div>
        )
    }
}

export default FormSection
