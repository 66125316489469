import { Stack } from "@fluentui/react";
import classNames from "classnames";
import React from "react";
import { FunctionComponent } from "react";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SystemStatusTypePill.scss";

interface IProps {
    statusType: SpintrTypes.SystemStatusType;
    isSidebar?: boolean;
    big?: boolean;
    ballOnly?: boolean;
    isInitialStatus?: boolean;
}

const SystemStatusTypePill: FunctionComponent<IProps> = ({ statusType, isSidebar, big, ballOnly, isInitialStatus }) => {
    const color = statusType === SpintrTypes.SystemStatusType.Information ?
        "dark-grey" :
        statusType === SpintrTypes.SystemStatusType.Planned ?
            "orange" :
            statusType === SpintrTypes.SystemStatusType.Ongoing ?
                "red" :
                "green";

    const textColor = statusType === SpintrTypes.SystemStatusType.Planned ? "darkOrange" : color;

    if (ballOnly) {
        return (
            <div className={"SystemStatusTypePill-dot " + color} />
        )
    }

    if (big) { 
        const text = isInitialStatus ?
            "Skapad" :
            statusType === SpintrTypes.SystemStatusType.Information ?
                "Information" :
                statusType === SpintrTypes.SystemStatusType.AllClear ?
                    "ALL_CLEAR" :
                    statusType === SpintrTypes.SystemStatusType.Ongoing ?
                        "Pagaende" :
                        statusType === SpintrTypes.SystemStatusType.Planned ?
                            "Planerad" :
                            "Avklarad";

        return (
            <div className={"SystemStatusTypePill big " + color}>
                {statusType !== SpintrTypes.SystemStatusType.Information && (
                    <div className={"SystemStatusTypePill-dot " + color} />
                )}
                {statusType === SpintrTypes.SystemStatusType.Information && (
                    <Visage2Icon icon="info-circle" size="small" />
                )}
                <Label color={textColor} size="body-2">{localize(text)}</Label>
            </div>
        )
    }

    switch (statusType) {
        case SpintrTypes.SystemStatusType.Ongoing:
            return (
                <div className="SystemStatusTypePill red">
                    <div className="SystemStatusTypePill-dot red" />
                    <Label color="red" size="body-2">
                        {localize("Pagaende")}
                    </Label>
                </div>
            );

        case SpintrTypes.SystemStatusType.Planned:
            return (
                <div className="SystemStatusTypePill orange">
                    <div className="SystemStatusTypePill-dot orange" />
                    <Label color="darkOrange" size="body-2">{localize("Planerad")}</Label>
                </div>
            );

        case SpintrTypes.SystemStatusType.Information:
            return (
                <div className="SystemStatusTypePill grey">
                    <div className="SystemStatusTypePill-dot grey" />
                    <Label color="grey" size="body-2">{localize("Information")}</Label>
                </div>
            )

        case SpintrTypes.SystemStatusType.Done:
        case SpintrTypes.SystemStatusType.AllClear:
        default:
            return (
                <div className="SystemStatusTypePill-dot green" />
            )
    }

    return (
        <Stack
            className={classNames("system-status-type-pill", "color-" + color)}
            horizontal
            tokens={{ childrenGap: Style.getSpacing(1) }}
            verticalAlign="center"
        >
            <Visage2Icon
                size="small"
                icon={
                    statusType === SpintrTypes.SystemStatusType.Done ||
                    statusType === SpintrTypes.SystemStatusType.AllClear
                        ? "tick-circle"
                        : statusType === SpintrTypes.SystemStatusType.Ongoing
                        ? "danger"
                        : "clock"
                }
                color={color}
            />
            <Label size="body-2" color={color}>
                {localize(
                    statusType === SpintrTypes.SystemStatusType.AllClear
                        ? "ALL_CLEAR"
                        : statusType === SpintrTypes.SystemStatusType.Ongoing
                        ? "Pagaende"
                        : statusType === SpintrTypes.SystemStatusType.Planned
                        ? "Planerad"
                        : isSidebar
                        ? "IngaDriftstorningar"
                        : "Avklarad"
                )}
            </Label>
        </Stack>
    );
};

export default SystemStatusTypePill;
