import React, { FunctionComponent, useContext, useMemo } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { AppPageContext } from "../AppPageContext";

interface IProps extends IAppPageComponent { }

const AppPageText: FunctionComponent<IProps> = (props) => {
    const context = useContext(AppPageContext);
    const { text, variables } = props;

    const html = useMemo(
        () => {
            let retVal = text || "";

            retVal = Object.keys(variables || {}).reduce(
                (str, key) => {
                    return str.replace(`{{${key}}}`, variables[key] || "")
                },
                retVal,
            );

            return retVal.replace(
                /(?:\[\[(?<name>[^\]]+)\]\])/gmi,
                (_, key) => context.text["sv"][key],
            );
        },
        [context, text, variables],
    );

    return (
        <div
            className="AppPageText"
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    );
};

export default AppPageText;
