import { useCallback, useEffect } from "react";

interface IParams {
    key: string;
    holdCtrlKey?: boolean;
    holdShiftKey?: boolean;
    holdMetaKey?: boolean;
    callback: () => void;
}

const useKeyboardShortcut = (params: IParams) => {
    const handleKeyDown = useCallback((event) => {
        if (event.key.toLowerCase() === params.key &&
            (!params.holdCtrlKey || event.ctrlKey) &&
            (!params.holdShiftKey || event.shiftKey) &&
            (!params.holdMetaKey || event.metaKey)) {
            event.preventDefault();
            params.callback();
        }
    }, [params]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return null;
};

export default useKeyboardShortcut;
