import { Image } from "@fluentui/react";
import React, { FunctionComponent, useContext } from "react";
import { Label } from "src/ui";
import { AppPageContext } from "../AppPageContext";

const AppPagePoweredBy: FunctionComponent = (props) => {
    const context = useContext(AppPageContext);

    return(
        <div className="AppPagePoweredBy">
            <Label
                as="div"
                className="powered-by"
                color="neutralBlue"
                size="body-3"
                weight="medium"
            >
                Powered by
            </Label>
            <Image
                alt={context.appName}
                src={context.appLogoUrl}
            />
        </div>
    );
};

export default AppPagePoweredBy;
