export enum ActionTypes {
    FetchInformationFeed = "FETCH_INFORMATION_FEED",
    FetchInformationFeedPending = "FETCH_INFORMATION_FEED_PENDING",
    FetchInformationFeedRejected = "FETCH_INFORMATION_FEED_REJECTED",
    FetchInformationFeedFulfilled = "FETCH_INFORMATION_FEED_FULFILLED",
    SetInformationFeedLoading = "SET_INFORMATION_FEED_LOADING",
    FetchContentCategories = "FETCH_CONTENT_CATEGORIES",
    FetchContentCategoriesPending = "FETCH_CONTENT_CATEGORIES_PENDING",
    FetchContentCategoriesRejected = "FETCH_CONTENT_CATEGORIES_REJECTED",
    FetchContentCategoriesFulfilled = "FETCH_CONTENT_CATEGORIES_FULFILLED",
    FetchImportantArticles = "FETCH_IMPORTANT_ARTICLES",
    FetchImportantArticlesPending = "FETCH_IMPORTANT_ARTICLES_PENDING",
    FetchImportantArticlesRejected = "FETCH_IMPORTANT_ARTICLES_REJECTED",
    FetchImportantArticlesFulfilled = "FETCH_IMPORTANT_ARTICLES_FULFILLED",
    MarkAsRead = "MARK_AS_READ",
    MarkAsReadPending  = "MARK_AS_READ_PENDING",
    MarkAsReadRejected = "MARK_AS_READ_REJECTED",
    MarkAsReadFulfilled = "MARK_AS_READ_FULFILLED",
    ClearInformationFeed = "CLEAR_IFNROMATION_FEED",
    AddItem = "ADD_INFORMATION_FEED_ITEM",
    UpdateItem = "UPDATE_INFORMATION_FEED_ITEM",
    AddImportantArticle = "ADD_IMPORTANT_ARTICLE",
    RemoveImportantArticle = "REMOVE_IMPORTANT_ARTICLE"
}
