import { Steps } from "intro.js-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { OnboardingPopupView } from "src/onboarding";
import { getActiveUser } from "src/profile/actions";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { scrollToTop } from "src/utils";

interface IProps {
    profile?: any;
    instance?: any;
    introductionsEnabled?: boolean;
    isSmallViewMode?: boolean;
    location: any;
    history: any;
    dispatch: any;
    onRef: any;
    appMode: boolean;
}

interface IState {
    isReady: boolean;
    hasBeenInitialized: boolean;
    displayOnboarding: boolean;
    displayIntroduction: boolean;
    unseenOnboarding: any[];
    introductionQueue: any[];
    introductionSteps?: any;
    currentIntroductionSequence?: number;
}

class StartupGuides extends Component<IProps, IState> {
    unlisten: any = null;
    private _isMounted: boolean = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isReady: this.getIsReady(props),
            hasBeenInitialized: false,
            displayOnboarding: false,
            displayIntroduction: false,
            unseenOnboarding: [],
            introductionQueue: []
        }
    }

    getIsReady(props: IProps) {
        return !!props.instance.get("fetchHasBeenInitialized") &&
            !props.instance.get("isLoading") &&
            !!props.profile.fetchHasBeenInitialized &&
            !props.profile.isLoading;
    }

    componentDidUpdate() {
        if (!this.state.hasBeenInitialized &&
            !this.state.isReady &&
            this.getIsReady(this.props)) {
            this.setState({
                isReady: true
            }, () => {
                if (!this._isMounted) {
                    return;
                }

                this.initialize();
            });
        }
    }

    onNavigation(location: any) {
        if (this.state.hasBeenInitialized) {
            this.runIntroJs(location);
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.state.isReady) {
            this.initialize();
        }

        this.unlisten = this.props.history.listen(this.onNavigation.bind(this));

        this.props.onRef(this);
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (!!this.unlisten) {
            this.unlisten();
        }
    }

    initialize() {
        this.setState({
            hasBeenInitialized: true
        }, () => {
            if (!this._isMounted) {
                return;
            }

            let unseenOnboarding: any = [];

            if (!!this.props.instance &&
                !!this.props.instance.get("onboarding")) {
                let allOnboarding = this.props.instance.get("onboarding");

                if (allOnboarding.length == undefined) {
                    allOnboarding = allOnboarding.toJS();
                }

                unseenOnboarding = allOnboarding.filter((v) => !this.props.profile.active.onboarding.includes(v.id));
            }

            const hasUnseenOnboarding = !!unseenOnboarding && unseenOnboarding.length > 0;

            if (hasUnseenOnboarding) {
                this.setState({
                    displayOnboarding: true,
                    unseenOnboarding
                });
            }
            if (!!this.props.appMode) {
                this.setState({
                    displayOnboarding: false,
                });
            }
            else {
                this.runIntroJs(this.props.location);
            }
        });
    }

    filterIntroductionsByPath = (path: string, introductionSequences: any[]): any[] => 
        (introductionSequences || []).filter((i) => i && i.path && typeof i.path === "string" && i.path.endsWith("*") 
            ? path.startsWith(i.path.substring(0, i.path.length - 1))
            : path === i?.path
        );

    getUnreadIntroductionsForPath = (path, currentUser) =>
        this.filterIntroductionsByPath(path, currentUser.introductionSequences)
            .filter((i) => i.read === false);

    getAllIntroductionsForPath = (path) =>
        this.filterIntroductionsByPath(
            path,
            this.props.profile.active.introductionSequences
        );
    

    playIntroductions = (delayMs) => {
        setTimeout(() => {
            if (!this._isMounted) {
                return;
            }

            let introductionSteps = JSON.parse(this.state.introductionQueue[0]?.data)

            introductionSteps = introductionSteps.filter((obj) => {
                if (!obj.element) return true

                return window.document.querySelector(obj.element) !== null
            })

            this.setState({
                introductionSteps,
                currentIntroductionSequence: this.state.introductionQueue[0].id,
                displayIntroduction: true
            }, () => {
                if (!this._isMounted) {
                    return;
                }

                scrollToTop();
            })
        }, delayMs);
    }

    runIntroJs(location = this.props.location, delayMs = 3000, showUnseen = false) {
        if (!this.props.introductionsEnabled ||
            this.props.isSmallViewMode) {
            return;
        }

        let introductionsForPath;

        if (showUnseen) {
            introductionsForPath = this.getAllIntroductionsForPath(location.pathname);
        } else {
            introductionsForPath = this.getUnreadIntroductionsForPath(location.pathname, this.props.profile.active);
        }

        if (introductionsForPath && introductionsForPath.length > 0) {
            this.setState({
                introductionQueue: introductionsForPath
            }, () => {
                if (!this._isMounted) {
                    return;
                }

                this.playIntroductions(delayMs);
            });
        }
    }

    completeIntroductionSequence = () => {
        api.put(`/api/v1/introduction/${this.state.currentIntroductionSequence}`).then(response => {
            this.props.dispatch(getActiveUser());

            if (this.state.introductionQueue?.length > 1) {
                this.setState({ introductionQueue: this.state.introductionQueue.slice(1) }, () => {
                    this.playIntroductions(0);
                });
            }
        });
    }

    render() {
        if (this.state.displayOnboarding) {
            return (
                <OnboardingPopupView
                    callback={() => {
                        this.setState({
                            displayOnboarding: false
                        });

                        this.runIntroJs(this.props.location);
                    }}
                    unseen={this.state.unseenOnboarding} />
            )
        } else if (!this.props.isSmallViewMode && this.state.displayIntroduction) {
            return (
                <Steps
                    enabled={true}
                    initialStep={0}
                    steps={this.state.introductionSteps}
                    onExit={() => {
                        this.completeIntroductionSequence();
                        this.setState({ displayIntroduction: false })
                    }}
                    onComplete={() => this.completeIntroductionSequence()}
                    options={{
                        hideNext: false,
                        nextLabel: localize("Nasta"),
                        prevLabel: localize("Foregaende"),
                        doneLabel: localize("Klar"),
                        scrollToElement: false
                    }}
                />
            )
        }

        return null;
    };
}

const mapStateToProps = (state: IApplicationState, props: any) => ({
    ...props,
    profile: state.profile,
    instance: state.instance,
    introductionsEnabled: state.instance.get("enableIntroductions"),
    isSmallViewMode: state.ui.viewMode < SpintrTypes.ViewMode.SmallestLaptop,
    appMode: state.ui.appMode,
});

export default withRouter(connect(mapStateToProps)(StartupGuides));