import React from "react";
import "./ConversationItemImage.scss";
import { SpintrTypes } from "src/typings";
import { SpintrUser } from "src/ui";
import { circleMedium } from "src/ui/helpers/style";

interface IProps {
    conversation: Spintr.IConversation;
    currentUserId: number;
    hideText: boolean;
}

const ConversationItemImage = (props: IProps) => {
    if (props.conversation.type === SpintrTypes.ConversationType.MultiplePeople) {
        const participants = [];

        for (let i = 0; i < props.conversation.participants.length; i++) {
            if (props.conversation.participants[i].id !== props.currentUserId &&
                participants.length < 3 &&
                !participants.find(x => x.id === props.conversation.participants[i].id)) {
                participants.push(props.conversation.participants[i]);
            }
        }

        if (participants.length > 1) {
            return (
                <div className="ConversationItemImage">
                    <div className={[
                        "images",
                        "item-count-" + participants.length
                    ].join(" ")}>
                        {participants.map((p) => (
                            <SpintrUser
                                key={p.id}
                                name={p.name}
                                imageUrl={p.imageUrl}
                                personalName
                                size={22}
                                hideText
                            />
                        ))}
                    </div>
                    {!props.hideText && (
                        <SpintrUser
                            name={props.conversation.title}
                            personalName
                            size={22}
                            hideImage
                        />
                    )}
                </div>
            )
        }
    }

    let imageUrl = "";

    if (props.conversation.imageUrl && props.conversation.imageUrl.length > 0) {
        imageUrl = props.conversation.imageUrl[0];
    }

    return (
        <SpintrUser
            name={props.conversation.title}
            personalName={!props.conversation.group}
            imageUrl={imageUrl}
            hideText={props.hideText}
            size={circleMedium}
        />
    )
};

export default ConversationItemImage;