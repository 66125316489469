import api from "src/spintr/SpintrApi";
import {
    FETCH_PEOPLE,
    FOLLOW_USER,
    UNFOLLOW_USER,
    LOCK_USER,
    UNLOCK_USER,
    DISABLE_USER,
    ENABLE_USER,
} from "./action-types";

const baseUrl = "/api";

const delay = (ms): Promise<any> => 
    new Promise(resolve => setTimeout(resolve, ms))

const fetchPeople = (
    sortingColumn,
    isAscending = true,
    skip = 0,
    take = 10,
    searchWord = "",
    hideActiveUsers = false,
    hideDeactivatedUsers = true,
    hideLockedUsers = false,
    filterOnRoleId,
    filterOnDepartmentId,
    cancelToken,
    fetchMore
) => {
    const params = {
        includeActive: !hideActiveUsers,
        includeDeactivated: !hideDeactivatedUsers,
        includeLocked: !hideLockedUsers,
        orderByColumn: sortingColumn,
        isAscending,
        skip,
        take,
        ...(searchWord ? { word: searchWord } : {}),
        ...(filterOnRoleId > "0" ? { roleId: filterOnRoleId } : {}),
        ...(filterOnRoleId === "-5" ? { onlyAdministrators: true } : {}),
        ...(filterOnRoleId === "-10" ? { onlyEditors: true } : {}),
        ...(filterOnDepartmentId > "0" ? { unitId: filterOnDepartmentId } : {}),
        ...(filterOnDepartmentId === "-5" ? { onlyExternal: true } : {}),
    };

    console.log(params);
    console.log(filterOnRoleId);
    console.log(filterOnDepartmentId);

    return {
        payload: api.get(`${baseUrl}/users`, { params, cancelToken } ),
        meta: { fetchMore },
        type: FETCH_PEOPLE,
    };
};

const followUser = (userId) => {
    return {
        payload: api.post(`${baseUrl}/follow`, { id: userId }),
        type: FOLLOW_USER,
    };
};

const unfollowUser = (userId) => {
    return {
        payload: api.post(`${baseUrl}/follow`, { id: userId }),
        type: UNFOLLOW_USER,
    };
};

const lockUser = (userId) => {
    return {
        payload: api.put(`${baseUrl}/users/${userId}/togglelocked`),
        meta: { userId },
        type: LOCK_USER,
    };
};

const unlockUser = (userId) => {
    return {
        payload: api.put(`${baseUrl}/users/${userId}/togglelocked`),
        meta: { userId },
        type: UNLOCK_USER,
    };
};

const disableUser = (userId) => {
    return {
        payload: api.put(`${baseUrl}/users/${userId}/toggledeleted`),
        meta: { userId },
        type: DISABLE_USER,
    };
};

const enableUser = (userId) => {
    return {
        payload: api.put(`${baseUrl}/users/${userId}/toggledeleted`),
        meta: { userId },
        type: ENABLE_USER,
    };
};

export { fetchPeople, followUser, unfollowUser, lockUser, unlockUser, enableUser, disableUser };
