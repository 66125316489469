import classnames from "classnames";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import React, { Component } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Icon, Label, Loader } from "src/ui";
import { uniqueId } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    page?: any;
    wikiArticle?: any;
    everythingIsDraggable?: boolean;
    disableDropOnItem?: boolean;
    onChange?: any;
    onLoad?: any;
    hideUnplacedItems?: boolean;
}

interface IState {
    isLoading: boolean;
    isDragging: boolean;
    autoExpandParent: boolean;
    gData: any;
    expandedKeys: string[];
    activeItemKey: string;
    unplacedItemsCategoryKey: string;
    placedItemsCategoryKey: string;
    reservedModuleKeys: string[];
}

class WebStructure extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        let unplacedItemsCategoryKey = uniqueId();
        let placedItemsCategoryKey = uniqueId();

        this.state = {
            isLoading: true,
            isDragging: false,
            gData: [],
            autoExpandParent: true,
            expandedKeys: [unplacedItemsCategoryKey, placedItemsCategoryKey],
            activeItemKey: uniqueId(),
            unplacedItemsCategoryKey,
            placedItemsCategoryKey,
            reservedModuleKeys: [
                "start",
                "kontordir",
                "avdelningdir",
                "gallerytop",
                "wikitop",
                "files",
                "news",
                "blogtop",
                "bookings"
            ]
        };

        this.fetchMenu();
    }

    formatData(items) {
        let formatData = (items) => {
            if (!items) {
                return [];
            }

            return items.map((item) => {
                return {
                    ...item,
                    key: uniqueId(),
                    children: formatData(item.nodes),
                };
            });
        };

        let formattedData = formatData(items);

        let unplacedItemsCategory = {
            key: this.state.unplacedItemsCategoryKey,
            id: -1,
            title: localize("UNPUBLISHED_CONTENT"),
            children: [],
        };

        let placedItemsCategory = {
            key: this.state.placedItemsCategoryKey,
            id: 0,
            title: localize("MENU_STRUCTURE"),
            children: formattedData,
        };

        let gData = [unplacedItemsCategory, placedItemsCategory];

        if (this.props.wikiArticle) {
            if (this.props.wikiArticle.newSection) {
                gData[0].children.push({
                    key: this.state.activeItemKey,
                    title: localize("NySektion"),
                });
            }
        }

        if (!!this.props.page) {
            if (!!this.props.page.menuId && this.props.page.menuId > 0) {
                let removeItemWithId = (collection, id) => {
                    collection = collection.filter((i) => i.id !== id);

                    for (let i of collection) {
                        if (i.children) {
                            i.children = removeItemWithId(i.children, id);
                        }
                    }

                    return collection;
                };

                gData = removeItemWithId(gData, this.props.page.menuId);
            }

            let activeItem = {
                key: this.state.activeItemKey,
                title: this.props.page.title ? this.props.page.title : localize("NyTextsida"),
            };

            if (this.props.page.menuParent === undefined || this.props.page.menuParent === -1) {
                gData[0].children.push(activeItem);
            } else if (this.props.page.menuParent === 0) {
                gData[1].children.splice(this.props.page.menuPosition, 0, activeItem);
            } else {
                let addItemToCollecntion = (collection, item) => {
                    for (let i of collection) {
                        if (i.id === this.props.page.menuParent) {
                            i.children.splice(this.props.page.menuPosition, 0, item);
                        }

                        if (i.children) {
                            addItemToCollecntion(i.children, item);
                        }
                    }
                };

                addItemToCollecntion(gData, activeItem);
            }

            let hasBeenAdded = false;

            let checkIfAdded = (items) => {
                if (items.find(i => i.key === this.state.activeItemKey)) {
                    hasBeenAdded = true;
                } else {
                    for (let item of items) {
                        if (item.children) {
                            checkIfAdded(item.children);
                        }
                    }
                }
            }

            checkIfAdded(gData);

            if (!hasBeenAdded) {
                if (this.props.page.groupId) {
                    gData[1].children.splice(this.props.page.menuPosition, 0, activeItem);
                } else {
                    gData[0].children.push(activeItem);
                }
            }
        }

        if (!this.props.page &&
            !this.props.wikiArticle) {
            const allItems = [...gData[1].children];

            gData[0].children = allItems.filter(c =>
                !c.active ||
                !c.visible).sort((a, b) => {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
                    if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
                    return 0;
                });

            gData[1].children = allItems.filter(c =>
                !c.parentId &&
                c.active &&
                c.visible);

            const setChildren = (item) => {
                item.children = allItems.filter(c =>
                    c.parentId === item.id);

                for (let c of item.children) {
                    setChildren(c);
                }
            }

            for (let c of gData[0].children) {
                setChildren(c);
            }

            for (let c of gData[1].children) {
                setChildren(c);
            }

            const sortItems = (items) => {
                items.sort(function (a, b) {
                    return a.position - b.position
                });

                for (let item of items) {
                    if (item.children) {
                        sortItems(item.children);
                    }
                }
            }

            sortItems(gData[1].children);
        }

        if (this.props.onLoad) {
            this.props.onLoad(gData);
        }

        return gData;
    }

    expandCorrectItems(gData) {
        let keysToExpand = [];

        let loopCollection = (collection) => {
            for (let item of collection) {
                if (item.key === this.state.activeItemKey) {
                    return true;
                }

                if (item.children && item.children.length > 0) {
                    let isParent = loopCollection(item.children);

                    if (isParent) {
                        keysToExpand.push(item.key);

                        return true;
                    }
                }
            }
        };

        loopCollection(gData);

        return keysToExpand;
    }

    fetchMenu() {
        if (!!this.props.page) {
            let req;

            if (!!this.props.page.groupId) {
                req = api.get("/api/v1/menu/group?id=" + this.props.page.groupId);
            } else {
                req = api.get("/api/v1/menu/textpage?id=" + this.props.page.id);
            }

            req.then((response) => {
                let gData = this.formatData(response.data);
                let expandedkeys = this.expandCorrectItems(gData);

                this.setState({
                    isLoading: false,
                    gData,
                    expandedKeys: [...this.state.expandedKeys, ...expandedkeys],
                });
            });
        } else if (this.props.wikiArticle) {
            api.get("/api/v1/wikis/article/menu", { params: { id: this.props.wikiArticle.id } }).then((response) => {
                const wikiMenu = response.data.map((item) => ({
                    key: uniqueId(),
                    id: item.id,
                    title: item.title,
                }));

                this.setState({
                    isLoading: false,
                    gData: this.formatData(wikiMenu),
                });
            });
        } else {
            api.get("/api/menuitems").then((response) => {
                let gData = this.formatData(response.data);
                let expandedkeys = this.expandCorrectItems(gData);

                this.setState({
                    isLoading: false,
                    gData,
                    expandedKeys: [...this.state.expandedKeys, ...expandedkeys],
                });
            });
        }
    }

    onDragStart = (info) => {
        if (!this.isItemWithKeyDraggable(info.node.props.eventKey)) {
            info.event.preventDefault();
            info.event.stopPropagation();

            this.setState({
                expandedKeys: [
                    ...this.state.expandedKeys,
                    this.state.unplacedItemsCategoryKey,
                    this.state.placedItemsCategoryKey,
                ],
            });
        }
    };

    onDragEnter = (info) => {
        this.setState({
            expandedKeys: [
                ...info.expandedKeys,
                this.state.unplacedItemsCategoryKey,
                this.state.placedItemsCategoryKey,
            ],
        });
    };

    isItemWithKeyDraggable(key) {
        if (key == this.state.unplacedItemsCategoryKey ||
            key == this.state.placedItemsCategoryKey) {
            return false;
        }

        if (this.props.everythingIsDraggable) {
            return true;
        }

        return key == this.state.activeItemKey;
    }

    onDrop = (info) => {
        if (!this.isItemWithKeyDraggable(info.dragNode.props.eventKey)) {
            return;
        }

        if (this.props.disableDropOnItem && !info.dropToGap) {
            return;
        }

        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split("-");
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        let dropItem;

        const setDropItem = (items) => {
            for (let item of items) {
                if (item.key === dropKey) {
                    dropItem = item;
                }

                if (item.children) {
                    setDropItem(item.children);
                }
            }
        }

        setDropItem(this.state.gData);
        
        if (dropItem &&
            !info.dropToGap &&
            this.state.reservedModuleKeys.indexOf(dropItem.moduleKey) > -1) {
            info.event.preventDefault();
            info.event.stopPropagation();

            return;
        }

        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key === key) {
                    callback(item, index, arr);

                    return;
                }

                if (item.children) {
                    loop(item.children, key, callback);
                }
            });
        };

        const data = [...this.state.gData];

        let dragObj;

        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);

            dragObj = item;
        });

        if (!info.dropToGap) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];

                item.children.push(dragObj);
            });
        } else if ((info.node.props.children || []).length > 0 && info.node.props.expanded && dropPosition === 1) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];

                item.children.unshift(dragObj);
            });
        } else {
            let ar, i;

            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });

            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        this.setState(
            {
                gData: data,
            },
            () => {
                this.pushUpdatedItemToParent(dragObj.key);
            }
        );
    };

    pushUpdatedItemToParent(objectKey) {
        if (!this.props.onChange) {
            return;
        }

        let menuParent, menuPosition, currentData, movedItem;

        currentData = [...this.state.gData];

        let setMenuParentAndPosition = (parent) => {
            if (!parent.children) {
                return;
            }

            const foundChild = parent.children.find(i => i.key === objectKey);

            if (!!foundChild) {
                movedItem = foundChild;
                menuParent = parent.id;
                menuPosition = parent.children.indexOf(foundChild);
            } else {
                for (let child of parent.children) {
                    setMenuParentAndPosition(child);
                }
            }
        }

        if (typeof menuParent === undefined) {
            menuParent = -1;
        }

        setMenuParentAndPosition({
            children: currentData
        });

        this.props.onChange(
            {
                menuParent,
                menuPosition,
            },
            currentData,
            movedItem
        );
    }

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys: [...expandedKeys, this.state.unplacedItemsCategoryKey, this.state.placedItemsCategoryKey],
            autoExpandParent: false,
        });
    };

    getTitle(item) {
        if (item.key === this.state.unplacedItemsCategoryKey || item.key === this.state.placedItemsCategoryKey) {
            return (
                //@ts-ignore
                <Label
                    className="categoryTitle"
                    size="small-2"
                    color="mid-grey"
                    style={{
                        textTransform: "uppercase",
                        cursor: "default"
                    }}
                >
                    {item.title}
                </Label>
            );
        }

        return (
            // @ts-ignore
            <Label style={{ userSelect: "none" }} color={"mid-grey"} size="body-2">
                {item.title}
            </Label>
        );
    }

    getClassName(item) {
        let className = "";

        if (item.key === this.state.unplacedItemsCategoryKey || item.key === this.state.placedItemsCategoryKey) {
            className += " web-structure-title";
        }

        if (item.key === this.state.activeItemKey) {
            className += "activeItem";
        }

        return className;
    }

    render() {
        const switcherIcon = (obj) => {
            if (obj.isLeaf) {
                return <div></div>;
            }

            return (
                <Visage2Icon icon={obj.expanded ? "arrow-down-1" : "arrow-right-3"} color="grey" size="extra-small" />
            )
        };

        const loop = (data) =>
            data.map((item) => {
                if (item.children && item.children.length) {
                    return (
                        // @ts-ignore
                        <TreeNode key={item.key} className={this.getClassName(item)} title={this.getTitle(item)}>
                            {loop(item.children)}
                        </TreeNode>
                    );
                }

                return (
                    // @ts-ignore
                    <TreeNode key={item.key} className={this.getClassName(item)} title={this.getTitle(item)} />
                );
            });

        if (this.state.isLoading) {
            return (
                <div>
                    <Loader />
                </div>
            );
        }

        return (
            <div className={classnames("draggable-demo", {
                "disable-drop-on-item": this.props.disableDropOnItem,
                "hide-unplaced-items": this.props.hideUnplacedItems
            })}>
                <div className="draggable-container">
                    {
                        // @ts-ignore
                        <Tree
                            expandedKeys={this.state.expandedKeys}
                            onExpand={this.onExpand}
                            selectable={false}
                            autoExpandParent={this.state.autoExpandParent}
                            draggable
                            showIcon={false}
                            switcherIcon={switcherIcon}
                            onDragStart={this.onDragStart}
                            onDragEnter={this.onDragEnter}
                            onDrop={this.onDrop}
                        >
                            {loop(this.state.gData)}
                        </Tree>
                    }
                </div>
            </div>
        );
    }
}

export default WebStructure;
