import { Location } from "history";

export interface IQueryStringMap {
    [key: string]: string;
}

export const getQueryStringMap = 
    (location: Location<{}>): IQueryStringMap => location.search
        .slice(1)
        .split("&")
        .reduce(
            (acc, val) => {
                const parts = val.split("=", 2);
                if (!parts[0]) {
                    return acc;
                }
                
                acc[parts[0]] = decodeURIComponent(parts[1]);

                return acc;
            },
            {}
        );
