import { MarketplaceAppFormView } from "./views/MarketplaceAppFormView";
import { MarketplaceAppView } from "./views/MarketplaceAppView";
import { MarketplaceCatalogView } from "./views/MarketplaceCatalogView";
import { MarketplaceLibraryView } from "./views/MarketplaceLibraryView";
import { MarketplaceWidgetFormView } from "./views/MarketplaceWidgetFormView";

const routes : Spintr.ISpintrUiRoute[] = [
    { path: "/marketplace/library", exact: true, component: MarketplaceLibraryView },
    { path: "/marketplace/app/:appId/create-widget", exact: true, component: MarketplaceWidgetFormView },
    { path: "/marketplace/create-app", exact: true, component: MarketplaceAppFormView },
    { path: "/marketplace/app/:id", exact: true, component: MarketplaceAppView },
    { path: "/marketplace/:categoryId", exact: true, component: MarketplaceCatalogView },
    { path: "/marketplace", exact: false, component: MarketplaceCatalogView }
];

export default routes;
