import { AxiosResponse } from "axios";
import { Action } from "redux";

/**
 * Base action for initiating thunk-promise chain
 */
export enum AppsMessageTypes {
    FetchApps               = "FETCH_APPS",
    FetchAppsPending        = "FETCH_APPS_PENDING",
    FetchAppsRejected       = "FETCH_APPS_REJECTED",
    FetchAppsFulfilled      = "FETCH_APPS_FULFILLED",

    UpdateApp               = "UPDATE_SPINTR_APP",
}

export const FETCH_APPS = "FETCH_APPS";

export interface IFetchAppsAction extends Action<AppsMessageTypes.FetchApps> {
    payload: Promise<AxiosResponse<Spintr.ISystemApp>>;
}
export interface IFetchAppsPendingAction extends Action<AppsMessageTypes.FetchAppsPending> {}
export interface IFetchAppsRejectedAction extends Action<AppsMessageTypes.FetchAppsRejected> {}
export interface IFetchAppsFulfilledAction extends Spintr.IAsyncAction<Spintr.ISystemApp[]> {
    type: AppsMessageTypes.FetchAppsFulfilled;
}

export interface IUpdateAppAction extends Action<AppsMessageTypes.UpdateApp> {
    appId: number;
    enabled: boolean;
}

export type FetchAppsAction
    = IFetchAppsPendingAction
    | IFetchAppsRejectedAction
    | IFetchAppsFulfilledAction;

export type AppAction
    = IUpdateAppAction
    | FetchAppsAction;