import React, { Fragment, FunctionComponent } from 'react';
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const MessageReplyNotification: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.INotification;
    if (!notification) {
        return;
    }

    return (
        <Fragment>
            {localize("MESSAGE_REPLY_NOTIFICATION_MSG").replace("{0}", notification.userName) + "."}
        </Fragment>
    );
};

export default MessageReplyNotification;
