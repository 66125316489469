import React, { FunctionComponent } from "react";
import { LocalizedString } from "src/ui";
import "./ZippedFolderReadyNotification.scss";

interface IProps {
    notification: Spintr.INotification;
}

const ZippedFolderReadyNotification: FunctionComponent<IProps> = (props) => {
    return (
        <div className="ZippedFolderReadyNotification">
            <LocalizedString 
                text={"EnMappArTillgangligForNedladdningViaLanken"} 
            /> <span>
                {props.notification.url}
            </span>
        </div>
    );
};

export default ZippedFolderReadyNotification;
