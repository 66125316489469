import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import RightColumnDashboard from "src/admin/views/AdminDashboard/RightColumnDashboard";
import { localize } from "src/l10n";
import NewsSpotlight from "src/news/components/Spotlight/NewsSpotlight";
import { setConfirmPopup } from "src/popups/actions";
import { SocialFeedType } from "src/social-feed/types";
import RightColumnTags from "src/tags/RightColumnTags";
import { TeaserBoxLoader } from "src/teaser-box";
import { SpintrTypes } from "src/typings";
import { Label, StandardErrorBoundary, UnstyledButton } from "src/ui";
import Birthdays from "src/ui/components/Birthdays/Birthdays";
import OperatingInfo from "src/ui/components/OperatingInfo/OperatingInfo";
import Todos from "src/ui/components/Todos/Todos";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import VisageInformationFeed from "../InformationFeed/VisageInformationFeed";
import SpintrSocialFeed from "../SocialFeed/SpintrSocialFeed";
import { StartPageBuilderComponentSettings } from "../StartPageBuilderComponentSettings";
import { StartPageBuilderContext } from "../StartPageBuilderContext";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    component: Spintr.IStartPageComponent;
    index: number;
}

const settingsEnabled = ["NewsFeed"];

const getComponentNameKey = (componentKey: string) => {
    switch (componentKey) {
        case "SpotlightNews":
            return "ATTACHED_NEWS_ARTICLES";

        case "NewsFeed":
            return "NEWS_FEED";

        case "SocialFeed":
            return "SocialtFlode";

        case "Birthdays":
            return "appBirthdays";

        case "Tags":
            return "appTags";

        case "DataBoxes":
            return "DATA_WIDGETS";

        case "TeaserBoxes":
            return "Textpuffar";

        case "BlogBoxes":
            return "Bloggar";

        case "Todo":
            return "AttGora";

        case "Dashboard":
            return "appDashboard";

        case "OperatingInfo":
            return "Driftinformation";

        default:
            return "COMPONENT";
    }
}

const StartPageBuilderComponent: FunctionComponent<IProps> = (props) => {
    const { component } = props;
    const { id, componentKey, props: componentProps } = component;

    const [settingsOpen, openSettings] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { setSelected, selectedId, removeComponent, updateComponent } = useContext(StartPageBuilderContext);

    const onClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();

        setSelected(id);
    }, [setSelected, id]);

    const onRemoveClick = useCallback(
        () => {
            dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("CONFIRM_OBJECT_DELETION"),
                onConfirm: () => {
                    removeComponent(id);
                    mixpanelTrack(MixPanelEvents.Dynamo_RemoveComponent, {key: componentKey});
                },
            }));
        },
        [dispatch, removeComponent, id],
    );

    const onSettingsButtonClick = useCallback(() => {
        openSettings((isOpen) => !isOpen);
        if (componentKey !== "NewsFeed") {
            return;
        }
        mixpanelTrack(MixPanelEvents.Dynamo_ClickComponentsSettings);
    }, [openSettings, componentKey])

    const currentUserId = useSelector<Spintr.AppState, number>(
        (state) => state.profile.active.id,
    );

    const element = useMemo(
        () => {
            switch (componentKey) {
                case "SpotlightNews":
                    return <NewsSpotlight inEditMode={true} />

                case "NewsFeed":
                    return (
                        <StandardErrorBoundary>
                            <VisageInformationFeed
                                template={
                                    (componentProps || {})["template"] as SpintrTypes.NewsFeedType || SpintrTypes.NewsFeedType.SmallNewsArticles
                                }
                            />
                        </StandardErrorBoundary>
                    );

                case "SocialFeed":
                    return (
                        <StandardErrorBoundary>
                            <SpintrSocialFeed
                                feedType={SocialFeedType.Combined}
                                feedId={6}
                                galleryId={0}
                                userId={12}
                                isStartPage={true}
                            />
                        </StandardErrorBoundary>
                    );

                case "Birthdays":
                    return (
                        <Birthdays
                            disableButtons={false}
                            hideTitle={false}
                            userId={currentUserId}
                        />
                    );

                case "Tags":
                    return (
                        <RightColumnTags />
                    );

                case "DataBoxes":
                    return (
                        <TeaserBoxLoader
                            blogsOnly={false}
                            dataWidgetsOnlyIfMoreThanOneOfEach={true}
                        />
                    );

                case "TeaserBoxes":
                    return (
                        <TeaserBoxLoader
                            blogsOnly={false}
                            nonDataWidgetsOnlyIfMoreThanOneOfEach={true}
                        />
                    );

                case "BlogBoxes":
                    return (
                        <TeaserBoxLoader
                            blogsOnly={true}
                        />
                    );

                case "Todo":
                    return (
                        <Todos hideTitle={false} />
                    );

                case "Dashboard":
                    return (
                        <RightColumnDashboard />
                    );

                case "OperatingInfo":
                    return (
                        <OperatingInfo
                            hideTitle={false}
                            disableButtons={false}
                        />
                    );

                default:
                    return (
                        <div className="warning">
                            {localize("COMPONENT_RENDER_ERROR") + ": " + componentKey}
                        </div>
                    );
            }
        },
        [componentKey, currentUserId, componentProps],
    );

    const supportsSettings = useMemo(
        () => settingsEnabled.indexOf(componentKey) > -1,
        [componentKey],
    );

    return (
        <div
            className={
                "StartPageBuilderComponent" + 
                (selectedId === id ? " selected" : "")
            }
            onClick={onClick}
        >
            <Draggable draggableId={props.component.id} index={props.index}>
                {(provided, snapshot) => (
                    <div
                        className="component-wrapper"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {element}
                        <div
                            className="overlay"
                        />
                        {selectedId === id && (
                            <div className="controls-container">
                                <div className="controls">
                                    <Label
                                        size="body-3"
                                        weight="medium"
                                    >
                                        {localize(getComponentNameKey(componentKey))}
                                    </Label>
                                    {supportsSettings && (
                                        <UnstyledButton
                                            className="control settings"
                                            onClick={onSettingsButtonClick}
                                        >
                                            <Visage2Icon
                                                color="neutralBlue"
                                                icon="setting-2"
                                                size="small"
                                            />
                                        </UnstyledButton>
                                    )}
                                    <UnstyledButton
                                        className="control delete"
                                        onClick={onRemoveClick}
                                        onClickData={id}
                                    >
                                        <Visage2Icon
                                            color="red"
                                            icon="trash"
                                            size="small"
                                        />
                                    </UnstyledButton>
                                </div>
                            </div>
                        )}
                        {selectedId === id && supportsSettings && settingsOpen && (
                            <StartPageBuilderComponentSettings
                                componentKey={componentKey}
                                props={{
                                    ...(componentKey !== "NewsFeed" ? {} : {
                                        template: SpintrTypes.NewsFeedType.SmallNewsArticles
                                    }),
                                    ...(componentProps || {})
                                }}
                                onSave={(newProps, close) => {
                                    updateComponent(id, {
                                        ...component,
                                        props: newProps,
                                    });

                                    if (!close) {
                                        return;
                                    }

                                    openSettings(false);
                                }}
                                onClose={() => {
                                    openSettings(false);
                                    mixpanelTrack(MixPanelEvents.Dynamo_CancelComponentSettings);
                                }}
                            />
                        )}
                    </div>
                )}
            </Draggable>
        </div>
    )
};

export default StartPageBuilderComponent;
