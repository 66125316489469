import { createContext } from "react";
import { NoOp } from "src/utils";

export type StartPageBuilderWidthUpdateHandler = (width: number) => void;
export type StartPageBuilderComponentResizeHandler = (id: string, stepDelta: number, snapToGrid?: boolean) => void;
export type StartPageBuilderGridSelectionHandler = (id: string | undefined | null) => void;
export type StartPageBuilderGridRemovalHandler = (id: string) => void;
export type StartPageBuilderGridAdditionHandler = (id: string, before: boolean) => void;
export type StartPageBuilderComponentUpdateHandler = (id: string, component: Spintr.IStartPageComponent) => void;
export type StartPageBuilderColumnNameUpdateHandler = (id: string, name: string) => void;
export type StartPageBuilderDrawerStateHandler = (isOpen: boolean) => void;

export interface IStartPageBuilderContext {
    selectedId?: string;
    stepSize: number;
    widthStep: number;

    addCell: StartPageBuilderGridAdditionHandler;
    removeComponent: StartPageBuilderGridRemovalHandler;
    resizeComponent: StartPageBuilderComponentResizeHandler;
    setColumnName: StartPageBuilderColumnNameUpdateHandler;
    setSelected: StartPageBuilderGridSelectionHandler;
    updateWidth: StartPageBuilderWidthUpdateHandler;
    updateComponent: StartPageBuilderComponentUpdateHandler;
    setDrawerOpen: StartPageBuilderDrawerStateHandler;
}

const emptyContext: IStartPageBuilderContext = {
    stepSize: 5,
    widthStep: 0,

    addCell: NoOp as StartPageBuilderGridAdditionHandler,
    removeComponent: NoOp as StartPageBuilderGridRemovalHandler,
    resizeComponent: NoOp as StartPageBuilderComponentResizeHandler,
    setColumnName: NoOp as StartPageBuilderColumnNameUpdateHandler,
    setDrawerOpen: NoOp as StartPageBuilderDrawerStateHandler,
    setSelected: NoOp as StartPageBuilderGridSelectionHandler,
    updateComponent: NoOp as StartPageBuilderComponentUpdateHandler,
    updateWidth: NoOp as StartPageBuilderWidthUpdateHandler,
};

const StartPageBuilderContext = createContext(emptyContext);

export const StartPageBuilderContextProvider = StartPageBuilderContext.Provider;
export const StartPageBuilderContextConsumer = StartPageBuilderContext.Consumer;

export default StartPageBuilderContext;
