import { Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { Swipeable } from "react-swipeable";
import { localize } from "src/l10n";
import { uniqueId } from "src/utils";
import { UnstyledButton } from "../Buttons";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Label } from "src/ui";

interface ISliderProps {
    children: ReactNode[];
    relativePositioning?: boolean;
    hideIndicator?: boolean;
    onUpdate?: any;
}

interface ISliderState {
    activeIndex: number;
    componentId : string;
    paused: boolean;
}

const slideIntervalMs = 10000;

class Slider extends Component<ISliderProps, ISliderState> {
    private intervalRef : number;

    constructor(props : ISliderProps) {
        super(props);

        this.state = {
            activeIndex: 0,
            componentId: uniqueId("Slider"),
            paused: false
        };

        this.intervalRef = window.setInterval(
            this.onIntervalTick.bind(this),
            slideIntervalMs
        );
    }

    public componentWillUnmount() : void {
        window.clearInterval(this.intervalRef);
    }

    public onIntervalTick() : void {
        if (this.props.children.length < 2) {
            return;
        }

        const nextIndex = this.state.activeIndex + 1;

        this.setState({
            activeIndex: nextIndex >= this.props.children.length
                ? 0
                : nextIndex
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state.activeIndex, !this.state.paused);
            }
        });
    }

    public onIndicatorDotClicked(index : number) : void {
        window.clearInterval(this.intervalRef);
        this.intervalRef = window.setInterval(
            this.onIntervalTick.bind(this),
            slideIntervalMs
        );

        this.setState({
            activeIndex: index
        });
    }

    public onPausePressed = (): void => {
        window.clearInterval(this.intervalRef);
        this.setState({paused: true}, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state.activeIndex, !this.state.paused);
            }
        })
    }

    public onPlayPressed = (): void => {
        this.intervalRef = window.setInterval(
            this.onIntervalTick.bind(this),
            slideIntervalMs
        );
        this.setState({paused: false}, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state.activeIndex, !this.state.paused);
            }
        })
    }

    public onSwipedRight = (): void => {
        const nextIndex = this.state.activeIndex + 1;
        window.clearInterval(this.intervalRef);
        this.intervalRef = window.setInterval(this.onIntervalTick.bind(this), slideIntervalMs);

        this.setState({
            activeIndex: nextIndex >= this.props.children.length ? 0 : nextIndex,
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state.activeIndex, !this.state.paused);
            }
        });
    };

    public onSwipedLeft = (): void => {
        const previousIndex = this.state.activeIndex - 1;
        window.clearInterval(this.intervalRef);
        this.intervalRef = window.setInterval(this.onIntervalTick.bind(this), slideIntervalMs);

        this.setState({
            activeIndex: previousIndex < 0 ? this.props.children.length - 1 : previousIndex,
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state.activeIndex, !this.state.paused);
            }
        });
    };

    public render() : ReactNode {
        return (
            <Swipeable
                className={"slider" + (this.props.relativePositioning ? " slider-relative" : "")}
                onSwipedLeft={this.onSwipedLeft}
                onSwipedRight={this.onSwipedRight}
            >
                {this.props.children.map(this.renderSlide.bind(this))}
                {this.renderIndicator()}
            </Swipeable>
        );
    }

    public renderIndicator() : ReactNode {
        if (this.props.children.length < 2 || this.props.hideIndicator) {
            return null;
        }
        
        return (
            <div className="slider-indicator">
                {this.props.children.map((_, index) => {
                    const key = `${this.state.componentId}.indicator${index}`;
                    const classNames : string[] = ["indicator-dot"];

                    if (index === this.state.activeIndex) {
                        classNames.push("active");
                    }

                    const accessibleLabel : string = 
                        localize("SLIDER_SLIDE") + " " + (index + 1) 
                        + (index === this.state.activeIndex 
                            ? " (" + localize("Aktiv") + ")"
                            : "");

                    return (
                        <div
                            aria-label={accessibleLabel}
                            className={classNames.join(" ")}
                            key={key}
                            onClick={this.onIndicatorDotClicked.bind(this, index)}
                            role="button" />
                    );
                })}
                {this.state.paused ?
                    <UnstyledButton onClick={this.onPlayPressed}>
                        <Visage2Icon className="pause-resume pause" title={localize("Starta")} icon="play" size="extra-small" type="bold" color="white" />
                    </UnstyledButton>   
                : 
                    <UnstyledButton onClick={this.onPausePressed}>
                        <Visage2Icon className="pause-resume pause" title={localize("Pausa")} icon="pause" size="extra-small" type="bold" color="white" />
                    </UnstyledButton>
                }
                <div className="back-next">
                    <div className="back">
                        <UnstyledButton onClick={this.onBackClicked}>
                            <Label color="white" size="body-2">
                                {localize("Tillbaka")}
                            </Label>
                        </UnstyledButton>
                    </div>
                    <div className="next">
                        <UnstyledButton onClick={this.onNextClicked}>
                            <Label color="white" size="body-2">
                                {localize("Nasta")}
                            </Label>
                        </UnstyledButton>
                    </div>
                </div>
            </div>
        );
    }

    public onBackClicked = (): void => {
        const previousIndex = this.state.activeIndex - 1;
        this.updateActiveIndex(previousIndex);
    };


    public onNextClicked = (): void => {
        const nextIndex = this.state.activeIndex + 1;
        const newIndex = nextIndex >= this.props.children.length ? 0 : nextIndex;
        this.updateActiveIndex(newIndex);
    };
    private updateActiveIndex(index: number): void {
        window.clearInterval(this.intervalRef);
        this.intervalRef = window.setInterval(this.onIntervalTick.bind(this), slideIntervalMs);

        this.setState(
            {
                activeIndex: index < 0 ? this.props.children.length - 1 : index,
            },
            () => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(this.state.activeIndex, !this.state.paused);
                }
            }
        );
    }

    public renderSlide(node : ReactNode, index : number) : ReactNode {
        const classNames : string[] = ["slider-item"];

        if (index === this.state.activeIndex) {
            classNames.push("active");
        }

        const key = `${this.state.componentId}.indicator${index}`;

        return (
            <div className={classNames.join(' ')} key={key}>
                {node}
            </div>
        );
    }
}

export default Slider;