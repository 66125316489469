import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { capitalizeFirstLetter, decodeHtmlEntities, truncate } from "src/utils";
import "./SocialPage.scss";

interface IProps {
    isShare?: boolean;
    post: Spintr.ISocialTextPage;
}

const SocialPage: FunctionComponent<IProps> = (props) => (
    <div className="SocialPage">
        {!props.isShare && (
            <Label
                as="p"
                color="mid-grey"
                size="body-2"
            >
                {capitalizeFirstLetter(
                    localize("skapadeTextsidan_small")
                ) + " "}
                <Link to={props.post.url}>
                    {props.post.pageTitle}
                </Link>
            </Label>
        )}
        {props.isShare && (
            <>
                <Label
                    as="p"
                    className="title"
                    size="body-2"
                >
                    <Link to={props.post.url}>
                        {props.post.pageTitle}
                    </Link>
                </Label>
                <Label
                    as="p"
                    className="preamble"
                    size="body-3"
                >
                    {truncate(
                        decodeHtmlEntities(
                            props.post.pagePreamble.length === 0
                                ? props.post.pageBody
                                : props.post.pagePreamble
                        ),
                        200,
                    )}
                </Label>
            </>
        )}
    </div>
);

export default SocialPage;
