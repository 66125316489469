import classnames from "classnames";
import { Icon } from "@fluentui/react";
import React, { PureComponent } from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { localize } from "src/l10n";
import { setResponsiveSubmenuVisible } from "src/ui";
import { Style } from "src/ui/helpers";
import { Label, UnstyledButton } from ".";
import "./ContentWithSubmenu.scss";
import { StandardErrorBoundary } from "./StandardErrorBoundary";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";

interface IDispatchProps {
    setResponsiveSubmenuVisible: (visible: boolean) => void;
}

interface IProps extends IDispatchProps {
    hideSubmenu: boolean;
    isSmallViewMode: boolean;
    renderSubmenu?: any;
    className?: string;
    responsiveSubmenuVisible: boolean;
    responsiveSubmenuButtonText?: string;
    customSmallViewModeTrigger?: number;
    appMode: boolean;
}

class ContentWithSubmenu extends PureComponent<IProps> {
    subMenuWidth = 260;

    componentDidMount() {
        if (this.props.responsiveSubmenuVisible) {
            this.props.setResponsiveSubmenuVisible(false);
        }
    }

    toggleResponsiveSubmenu = () => {
        this.props.setResponsiveSubmenuVisible(!this.props.responsiveSubmenuVisible);
    };

    render() {
        let classNames = ["ContentWithSubmenu"];

        if (this.props.className) {
            classNames.push(this.props.className);
        }

        if (this.props.hideSubmenu) {
            return this.props.children;
        }
        if (this.props.appMode || this.props.isSmallViewMode) {
            return (
                <div className="ContentWithSubmenu">
                    {this.props.renderSubmenu && (
                        <>
                            <UnstyledButton
                                className="responsive-submenu-button"
                                onClick={this.toggleResponsiveSubmenu}
                            >
                                <Visage2Icon icon="menu-1" />
                                <Label as="span">
                                    {this.props.responsiveSubmenuButtonText || localize("VisaUndermeny")}
                                </Label>
                            </UnstyledButton>

                            <div
                                className={classnames("responsive-submenu", {
                                    hidden: !this.props.responsiveSubmenuVisible,
                                })}
                            >
                                <div className="menu">
                                    <div className="header">
                                        <UnstyledButton className="close-button" onClick={this.toggleResponsiveSubmenu}>
                                            <Visage2Icon icon="close-circle" />
                                        </UnstyledButton>
                                    </div>
                                    <div className="body">{this.props.renderSubmenu()}</div>
                                </div>
                                <div className="overlay" onClick={this.toggleResponsiveSubmenu} />
                            </div>
                        </>
                    )}
                    <StandardErrorBoundary>{this.props.children}</StandardErrorBoundary>
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        className={classNames.join(" ")}
                        style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            width: this.subMenuWidth + "px",
                            marginRight: Style.getSpacingStr(10),
                        }}
                    >
                        {this.props.renderSubmenu && this.props.renderSubmenu()}
                    </div>
                    <div
                        role="main"
                        className="ContentWithSubmenuRight"
                        style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            width: "calc(100% - " + (this.subMenuWidth + Style.getSpacing(10) + "px") + ")",
                        }}
                    >
                        <StandardErrorBoundary>
                            {this.props.children}
                        </StandardErrorBoundary>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        isSmallViewMode: props.customSmallViewModeTrigger
            ? state.ui.viewMode <= props.customSmallViewModeTrigger
            : state.ui.isSmallViewMode,
        responsiveSubmenuVisible: state.ui.responsive?.submenuVisible,
        appMode: state.ui.appMode
    };
};

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IProps> = {
    setResponsiveSubmenuVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentWithSubmenu);
