import { Action} from "redux";
import api from "src/spintr/SpintrApi";
import { CLEAR_TEASERBOXES, FETCH_TEASERBOXES } from './action-types'

export const fetchTeaserBoxes = () : Action => ({
    // @ts-ignore
    payload: api.get('/api/teaserbox/active'),
    type: FETCH_TEASERBOXES
});

export const clearTeaserBoxes = () : Action => ({
    type: CLEAR_TEASERBOXES
});
