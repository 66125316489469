import { produce } from "immer";
import { Reducer } from "redux";
import { ActionTypes } from "./action-types";
import { FavoriteAction } from "./actions";

export interface IFavoriteState {
    isLoading: boolean;
    items: Spintr.IUserFavorite[];
}

const initialState: IFavoriteState = {
    isLoading: false,
    items: [],
};

export const favoriteReducer: Reducer<IFavoriteState, FavoriteAction> =
    (state = initialState, action) => produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.FetchUserFavoritesPending:
                draft.isLoading = true;
                break;

            case ActionTypes.FetchUserFavoritesRejected:
                draft.isLoading = false;
                break;

            case ActionTypes.FetchUserFavoritesFulfilled:
                draft.isLoading = false;
                draft.items = action.payload.data;
                break;
        }
    });
