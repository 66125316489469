import React, { Component } from 'react';
import { connect } from "react-redux";
import { localize } from 'src/l10n';
import { IApplicationState } from "src/spintr/reducer";
import { Icon, Label } from "src/ui";
import "./VisageGradient.scss";

interface IProps {
    backgroundUrl?: string;
    isInTeamsApp?: boolean;
}

interface IState {
    hasLoaded: boolean;
}

class VisageGradient extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            hasLoaded: false
        }
    }

    componentDidMount() {
        if (!this.props.backgroundUrl ||
            this.props.isInTeamsApp) {
            return null;
        }

        const imageLoader = new Image();

        imageLoader.src = this.props.backgroundUrl;

        imageLoader.onload = () => {
            this.setState({
                hasLoaded: true
            });
        };
    }

    render() {
        return null;

        if (!this.props.backgroundUrl ||
            !this.props.isInTeamsApp ||
            !this.state.hasLoaded) {
            return null;
        }

        return (
            <div className="VisageGradient" style={{
                backgroundImage: "url(" + this.props.backgroundUrl + ")"
            }} />
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        backgroundUrl: state.instance.get("backgroundUrl"),
        isInTeamsApp: state.ui.isInTeamsApp,
    };
};

export default connect(mapStateToProps)(VisageGradient);
