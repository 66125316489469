import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/users";

export const fetchUser = async (userId: number): Promise<any> => {
    const response = await api.get(`${baseUrl}/${userId}`);

    return response.data;
};

export const UsersAPI = {
    fetchUser,
};
export default UsersAPI;
