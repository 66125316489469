import React, { FunctionComponent, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Label, Scrollable, UnstyledButton } from "src/ui";
import { IMessage, MessageRole } from "../../types";
import { AssistantMessage } from "../AssistantMessage";
import { Icon } from "@fluentui/react";
import { AssistantInstructions } from "../AssistantInstructions";
import { AssistantTypingLoader } from "../AssistantTypingLoader";

interface IProps {
    assistantName: string;
    assistantImage?: string;
    companyName: string;
    userImage: string;
    userName: string;
    activeSubjectDate: Date;
}

interface IStateProps {
    isLoadingHistory: boolean;
    isLoadingResponse: boolean;
    messages: IMessage[];
}

const AssistantMessages: FunctionComponent<IProps> =
    ({ assistantName, assistantImage, companyName, userImage, userName, activeSubjectDate }) => {
        const state = useSelector<Spintr.AppState, IStateProps>(
            (appState) => ({
                isLoadingHistory: appState.assistant.isLoadingHistory,
                isLoadingResponse: appState.assistant.isLoadingResponse,
                messages: appState.assistant.messages,
            })
        );

        const scrollableRef = useRef<Scrollable>();

        useEffect(
            () => {
                if (!scrollableRef.current) {
                    return;
                }

                scrollableRef.current.scrollToBottom(true);
            },
            [scrollableRef.current, state.isLoadingResponse],
        );

        return (
            <div className="AssistantMessages">
                <div className="messages-wrapper">
                    <Scrollable ref={scrollableRef}>
                        <div className="messages">
                            {state.messages.length === 0 && (
                                <AssistantInstructions
                                    assistantName={assistantName}
                                    companyName={companyName}
                                />
                            )}
                            {state.messages.map((message) => (
                                <AssistantMessage
                                    key={`message${message.date.getTime()}`}
                                    message={message}
                                    senderImage={message.role === MessageRole.User
                                        ? userImage
                                        : assistantImage || "/Style/Images/ai-assistant.png"}
                                    senderName={message.role === MessageRole.User
                                        ? userName
                                        : assistantName}
                                    activeSubjectDate={activeSubjectDate}
                                />
                            ))}
                        </div>
                    </Scrollable>
                </div>
                {state.isLoadingResponse && (
                    <div className="response-loader">
                        <AssistantTypingLoader
                            assistantName={assistantName}
                        />
                    </div>
                )}
            </div>
        );
    };

export default AssistantMessages;
