import React, {
    FunctionComponent,
    useCallback,
    useMemo,
    useRef,
    useState
} from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { renderAdminTitle } from "src/admin/common/Title";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, PageHeader } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { listActionMenuWidth } from "src/ui/helpers/style";
import CompanyRowActions from "./CompanyRowActions";

interface IState {
    data: Spintr.IOfficeQueryItem[];
    totalCount: number;
    showDeleted: boolean;
}

const initialState: IState = {
    data: [],
    showDeleted: false,
    totalCount: 0
};

const CompaniesListAdminView: FunctionComponent = () => {
    const history = useHistory();
    const listRef = useRef<SpintrList>();
    const [state, setState] = useState<IState>(initialState);

    const currentListRef = listRef?.current

    const onRestoreClick = useCallback(
        (id: number) => {
            api
                .put(`/api/v1/companies/${id}/restore`)
                .then(() => {
                    currentListRef?.reFetch();
                });
        },
        [currentListRef],
    );

    const onDeleteClick = useCallback(
        (id: number) => {
            api
                .delete(`/api/v1/companies/${id}`)
                .then(() => {
                    currentListRef?.reFetch();
                });
        },
        [currentListRef],
    );

    const onCreateClick = useCallback(
        () => history.push("/admin/organisation/create"),
        [history],
    );

    const listButtons = useMemo(
        () => [{
            className: "commandBarAddButton",
            iconProps: { iconName: "Add" },
            key: "add",
            onClick: onCreateClick,
            text: localize("ORG_NEW_COMPANY"),
        }],
        [onCreateClick],
    );

    const onToggleDeleted = useCallback(
        () => {
            setState((s) => ({
                ...s,
                showDeleted: !s.showDeleted,
            }));

            currentListRef?.reFetch();
        },
        [currentListRef, setState]
    );

    const optionItems = useMemo(
        () => ({
            items: [{
                key: "showHidden",
                onClick: onToggleDeleted,
                text: localize(state.showDeleted ? "DoljBorttagna" : "VisaBorttagna"),
            }]
        }),
        [onToggleDeleted, state.showDeleted, history]
    )

    const columns = useMemo(
        () => [{
            fieldName: "name",
            maxWidth: 400,
            name: localize("Namn"),
            onRender: (item: Spintr.ICompanyQueryItem) => {
                return (
                    <Link
                        className={"linkFGColor"}
                        style={{
                            ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                        }}
                        to={`/admin/organisation/${item.id}`}
                    >
                        {item.name}
                    </Link>
                );
            }
        },
        {
            fieldName: "officeCount",
            minWidth: 10,
            name: localize("Kontor_plural"),
        },
        {
            fieldName: "status",
            minWidth: 100,
            name: localize("Status"),
            onRender: (item: Spintr.ICompanyQueryItem) => {
                return <span>{item.deleted ? localize("Borttagen") : localize("Tillganglig")}</span>;
            },
        }, {
            minWidth: listActionMenuWidth,
            maxWidth: listActionMenuWidth,
            name: "",
            onRender: (item: Spintr.ICompanyQueryItem) => {
                return (
                    <CompanyRowActions
                        item={item}
                        onDelete={onDeleteClick}
                        onRestore={onRestoreClick}
                    />
                );
            }
        }],
        [onDeleteClick, onRestoreClick]
    );

    const fetchList = useCallback(
        async (offset, limit, columnName, ascending, query) => {
            const response = await api.get<Spintr.ICompanyQueryEnvelope>(
                "/api/v1/companies", {
                params: {
                    ascending,
                    filter: query,
                    includeDeleted: state.showDeleted,
                    orderBy: columnName,
                    offset,
                    limit,
                }
            }
            );

            setState((s) => ({
                ...s,
                totalCount: response.data.totalCount,
            }));

            return {
                data: response.data.items,
                totalCount: response.data.totalCount,
            };
        },
        [state.showDeleted],
    );

    return (
        <div className="AdminOrganisationStructureView">
            {renderAdminTitle("Organisationsstruktur")}
            <PageHeader
                title={localize("Organisationsstruktur")}
                subText={localize("PAGE_INFO_ORGANISATIONSTRUCTURE")}
                useShowMoreSubText
                hits={state.totalCount} />
            <SpintrList
                ref={listRef}
                buttons={listButtons}
                columns={columns}
                fetch={fetchList}
                optionItems={optionItems}
                orderByColumn="name"
            />
        </div>
    );
};

export default CompaniesListAdminView;
