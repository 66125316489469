import React, { FunctionComponent } from "react";
import { Label } from "src/ui";

export interface IListWidgetProps {
    items: any[];
}

const ListWidget: FunctionComponent<IListWidgetProps> = ({ items }) => {
    return (
        <div className="ListWidget">
            <div className="list">
                {(items || []).map((item, index) => (
                    <div
                        className="list-item"
                        key={item.key || index.toString()}
                    >
                        <Label
                            as="div"
                            className="text"
                            color="dark-grey"
                            size="body-2"
                            weight="medium"
                        >
                            {item.text}
                        </Label>
                        {item.value && (
                            <Label
                                as="div"
                                className="value"
                                color="mid-grey"
                                size="body-2"
                                weight="normal"
                            >
                                {item.value}
                            </Label>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ListWidget;
