import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { ProductPostsProps, ProductPostsState } from "./ProductPosts.types";
import { SocialPostContainer } from "src/social-feed";
import InfiniteScroll from "react-infinite-scroll-component";
import { queryPostsAsync } from "src/products/productsApi";
import Axios, { CancelToken } from "axios";
import { SocialPostShimmer } from "src/social-feed/components/SocialPostShimmer";

const initialState: ProductPostsState = {
    isLoading: false,
    productId: 0,
    postCount: 0,
    posts: [],
};

const pageSize = 8;
const shimmerArray = [...Array(pageSize)]
    .map((_, index) => index)
    .map((index) => (
        <SocialPostShimmer key={index} />
    ));

function getPostKey(item: Spintr.ISocialPostBase): string {
    return (
        "SFC.Post." + item.Id + "."
        + (item.isFavourited ? "1" : "0")
        + "."
        + (item.isFollowing ? "1" : "0")
    );
}

function ProductPosts({ productId }: ProductPostsProps): ReactElement {
    const [state, setState] = useState<ProductPostsState>({
        ...initialState,
        productId,
    });
    const postsLoaded = state.posts.length;

    const loadMorePosts = useCallback(async (cancelToken?: CancelToken | undefined) => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        try {
            const envelope = await queryPostsAsync(
                state.productId,
                postsLoaded,
                pageSize,
                cancelToken,
            );

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                posts: [...prevState.posts, ...envelope.items],
                postCount: envelope.totalCount,
            }));
        } catch (error) {
            console.error(error);
        }
    }, [state.productId, postsLoaded, setState]);

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        setState((prevState) => ({
            ...prevState,
            posts: prevState.productId !== productId ? [] : prevState.posts,
            postCount: prevState.productId !== productId ? 0 : prevState.postCount,
            productId,
            isLoading: true,
        }));

        queryPostsAsync(productId, 0, pageSize, cancelTokenSource.token)
            .then((envelope) => setState((prevState) => ({
                ...prevState,
                isLoading: false,
                posts: [...prevState.posts, ...envelope.items],
                postCount: envelope.totalCount,
            })))
            .catch((error) => {
                console.error(error);
            });

        return () => cancelTokenSource.cancel();
    }, [productId]);

    const loader = state.isLoading
        ? shimmerArray
        : null;

    return (
        <div className="ProductPosts">
            <div className="posts-list">
                <InfiniteScroll
                    next={loadMorePosts}
                    dataLength={postsLoaded}
                    hasMore={postsLoaded < state.postCount}
                    loader={null}
                >
                    {state.posts.map((post) => (
                        <SocialPostContainer
                            key={getPostKey(post)}
                            post={post}
                            interactiveTextOptions={{
                                products: {
                                    enabled: true,
                                }
                            }}
                        />
                    ))}
                </InfiniteScroll>
                {loader}
            </div>
        </div>
    );
}

export default ProductPosts;
