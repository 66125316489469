import React, { ReactNode } from "react";

import { ComposerContext } from "../ComposerContext";
import SocialComposerHeader from "../SocialComposerHeader";
import SocialComposerType, {
    IComposerSaveOptions,
    ISocialComposerTypeProps
} from "../SocialComposerType";
import SocialComposerTypes from "../SocialComposerTypes";
import ComposerTypeButton from "./ComposerTypeButton";

import "./ExpandedComposerTypeSelector.scss";

class ExpandedComposerTypeSelector extends SocialComposerType<ISocialComposerTypeProps, never> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;

    constructor(props: ISocialComposerTypeProps) {
        super(props);

        this.onTypeClick = this.onTypeClick.bind(this);
    }

    public componentDidMount(): void {
        this.context.setSize("small");
    }

    public render(): ReactNode {
        const types: SocialComposerTypes[] = [
            "image", "external-file", "question",
            "poll"
        ];

        return (
            <div className="ExpandedComposerTypeSelector selector">
                <SocialComposerHeader
                    textKey={"LaggTillInnehall"}
                    displayBackButton={false}
                    hideCloseButton={true}
                />
                <div className="type-body">
                    <div className="type-list">
                        {types.map((type) => (
                            <ComposerTypeButton
                                key={type}
                                onClick={this.onTypeClick}
                                type={type}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    public saveContent(options: IComposerSaveOptions): Promise<Spintr.ISocialPostBase> {
        return new Promise((_, reject) => reject("Unused method"));
    }

    protected onTypeClick(type: SocialComposerTypes): void {
        this.context.setSize("default");
        this.context.setType(type);

        if (type === "question") {
            this.context.setContentState({
                isQuestion: true,
            });
        }
    }

    protected renderTypeContent(): ReactNode {
        return null;
    }
}

export default ExpandedComposerTypeSelector;
