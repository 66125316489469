import React, { FunctionComponent, useContext, useMemo } from "react";
import {  useSelector} from "react-redux";
import { IAppPageComponent } from "src/marketplace/types";
import { Label } from "src/ui";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { AppPageContext } from "../AppPageContext";

interface IProps extends IAppPageComponent {
    color?: string;
    link?: string;
}

const AppPageBox: FunctionComponent<IProps> = (props) => {
    const context = useContext(AppPageContext);
    const backgroundColor = useSelector<Spintr.AppState, string>(
        (state) => state.instance.get("color") as string,
    );

    const url = useMemo(
        () => {
            if (!props.link) {
                return;
            }

            if (!props.variables) {
                return props.link;
            }

            return Object.keys(props.variables || {}).reduce(
                (str, key) => {
                    return str.replace(`{{${key}}}`, props.variables[key])
                },
                props.link,
            );
        },
        [props.link, props.variables],
    );

    const text = useMemo(
        () => context.text?.sv ? context.text.sv[props.text] || props.text : props.text,
        [context.text, props.text],
    );

    const style = useMemo<React.CSSProperties>(
        () => {
            const cssProps: React.CSSProperties = {};

            if (props.color) {
                cssProps.backgroundColor = props.color;
            }
            
            return cssProps;
        },
        [props.color],
    );

    const className = useMemo(
        () => {
            const classNames = ["box", "primaryBGColor"];

            classNames.push(getLightOrDarkColorBasedOnColor(
                props.color ? props.color : backgroundColor,
                "fc-white",
                "fc-visageGray"
            ));

            return classNames.join(" ");
        },
        [backgroundColor, props.color],
    );

    return (
        <div className="AppPageBox">
            {url ? (
                <a
                    className={className}
                    href={url}
                    target={url.substring(0, 1) === '/'
                        ? undefined
                        : "blank"
                    }
                    style={style}
                >
                    <Label
                        as="span"
                        size="h5"
                        weight="medium"
                    >
                        {text}
                    </Label>
                </a>
            ) : (
                <div
                    className={className}
                    style={style}
                >
                    <Label
                        as="span"
                        size="h5"
                        weight="medium"
                    >
                        {text}
                    </Label>
                </div>
            )}
        </div>
    );
};

export default AppPageBox;
