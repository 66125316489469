import { fetchData } from "../api";

type DataResponse = Spintr.IMarketplaceWidgetDataResponse;

interface ICompletedFetch {
    expires: Date;
    response: DataResponse;
}

interface IFetchHistory {
    [id: string]: ICompletedFetch;
}

interface IPendingFetches {
    [id: string]: Promise<DataResponse>;
}

interface IQueuedFetch {
    dataId: string;
    token: string;
    url: string;
    promise: Promise<DataResponse>;
    rejector: (reason: any) => void;
    resolver: (value: DataResponse) => void;
}

class MarketplaceDataLoader {
    public static async remove(dataId) {
        if (MarketplaceDataLoader.history[dataId]) {
            MarketplaceDataLoader.history[dataId] = undefined;
        }
    }

    public static async load(dataId: string, url: string = null, token: string = null): Promise<DataResponse> {
        const now = new Date();

        if (MarketplaceDataLoader.history[dataId]) {
            const stored = MarketplaceDataLoader.history[dataId];
            
            if (stored.expires < now) {
                // Invalidate store if data has expired
                delete MarketplaceDataLoader[dataId];
            } else {
                // Return stored response
                return stored.response;
            }
        }

        if (MarketplaceDataLoader.pending[dataId]) {
            // If we have an active fetch, return it when it's done
            return await MarketplaceDataLoader.pending[dataId];
        }

        if (MarketplaceDataLoader.queue.some(q => q.dataId === dataId)) {
            const item = MarketplaceDataLoader.queue.find(q => q.dataId === dataId);

            return await item.promise;
        }

        const pendingItems = Object
            .keys(MarketplaceDataLoader.pending)
            .filter((key) => isNaN(+key));

        if (pendingItems.length >= MarketplaceDataLoader.maxConcurrentFetches) {
            // Add to queue if we're exceeding fetch limit
            return await MarketplaceDataLoader.queueFetch(dataId, url, token);
        }

        return await MarketplaceDataLoader.fetch(dataId, url, token);
    }

    private static readonly defaultTtl = 5 * 60 * 1000;
    private static readonly maxConcurrentFetches = 4;

    private static readonly history: IFetchHistory = {};
    private static readonly pending: IPendingFetches = {};
    private static readonly queue: IQueuedFetch[] = [];

    private static async fetch(dataId: string, url: string = null, token: string = null): Promise<DataResponse> {
        const promise = fetchData(dataId, url, token);
        MarketplaceDataLoader.pending[dataId] = promise;

        const response = await promise;
        delete MarketplaceDataLoader.pending[dataId];

        const now = new Date();
        MarketplaceDataLoader.history[dataId] = {
            expires: new Date(now.getTime() + MarketplaceDataLoader.defaultTtl),
            response,
        };

        MarketplaceDataLoader.unqueueNextFetch();

        return response;
    }

    private static unqueueNextFetch(): void {
        // TODO: Check if we have too many pending? 
        // Also, add to pending
        if (MarketplaceDataLoader.queue.length === 0) {
            return;            
        }

        const next = MarketplaceDataLoader.queue.pop();
        
        fetchData(next.dataId, next.url, next.token)
            .then(next.resolver, next.rejector)
            .finally(() => {
                MarketplaceDataLoader.unqueueNextFetch();
            });
    }

    private static queueFetch(dataId: string, url: string = null, token: string = null) {
        const promise = new Promise<DataResponse>((resolve, reject) => {
            MarketplaceDataLoader.queue.unshift({
                dataId,
                promise,
                rejector: reject,
                resolver: resolve,
                token,
                url,
            });
        });

        return promise;
    }

    private constructor() { /* Unused, just to prevent instantiation */ }
}

export default MarketplaceDataLoader;