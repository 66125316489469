import React, { ReactElement, useMemo } from "react";
import { ListContentCardProps } from "./ListContentCard.types";
import { Shimmer, ShimmerElementType, ShimmerElementsGroup, Stack, StackItem } from "@fluentui/react";
import { CaptionBody, MediumBodyBolder } from "../Text";
import { TagList } from "../TagList";
import { Link } from "react-router-dom";
import { ActionMenu, SpintrUser } from "src/ui";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import classNames from "classnames";

function renderShimmer(): ReactElement {
    return (
        <div className="ListContentCard shimmer">
            <Stack className="ListContentCard-wrapper" horizontal={true}>
                <StackItem className="ListContentCard-image">
                    <Shimmer customElementsGroup={
                        <ShimmerElementsGroup shimmerElements={[{
                            type: ShimmerElementType.circle,
                            height: 48,
                            width: 48,
                        }]} />
                    } />
                </StackItem>
                <StackItem className="ListContentCard-text" grow={1}>
                    <Stack className="ListContentCard-textStack">
                        <StackItem>
                            <Shimmer customElementsGroup={
                                <ShimmerElementsGroup shimmerElements={[{
                                    type: ShimmerElementType.line,
                                    width: "80%",
                                }]} />
                            } />
                        </StackItem>
                        <StackItem grow={1}>
                            <Shimmer customElementsGroup={
                                <ShimmerElementsGroup shimmerElements={[{
                                    type: ShimmerElementType.line,
                                    width: "80%",
                                }]} />
                            } />
                        </StackItem>
                        <StackItem>
                            <Shimmer customElementsGroup={
                                <ShimmerElementsGroup shimmerElements={[{
                                    type: ShimmerElementType.line,
                                }, {
                                    type: ShimmerElementType.gap,
                                }, {
                                    type: ShimmerElementType.line,
                                }, {
                                    type: ShimmerElementType.gap,
                                }, {
                                    type: ShimmerElementType.line,
                                }]} />
                            } />
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
        </div>
    );
}

function ListContentCard(props: ListContentCardProps): ReactElement {
    const {
        title,
        url,
        description,
        imageUrl,
        shimmer,
        tags,
        menuItems,
        menuHasSpintrIcons,
        standardActionMenuProps
    } = props;

    const descriptionSubstring = useMemo(() => {
        if (!description || description.length < 80) {
            return description;
        }

        const parts = description.split(" ")
        let result = "";
        for (const part of parts) {
            if (result.length + part.length > 77) {
                result += part.substring(0, 77 - result.length);

                result = result.trim();
                if (result.endsWith(",")) {
                    result = result.substring(0, result.length - 1);
                }
                
                result += "...";
                break;
            }

            result += part + " ";
        }

        return result.trim();
    }, [description]);

    if (shimmer) {
        return renderShimmer();
    }

    const actionMenu = !menuItems ? null : (
        <div className="ListContentCard-actionMenu">
            <ActionMenu
                menuClassName={menuHasSpintrIcons ? "hasSpintrIcons" : undefined}
                categories={menuItems}
            />
        </div>
    );

    const standardActionMenu = !standardActionMenuProps ? null : (
        <div className="ListContentCard-actionMenu">
            <StandardActionMenu {...standardActionMenuProps} />
        </div>
    )

    return (
        <div className={classNames("ListContentCard", {
            "tags": tags
        })}>
            <Link className="ListContentCard-link" to={url}>
                <Stack className="ListContentCard-wrapper" horizontal={true} grow={1} verticalFill>
                    <StackItem className="ListContentCard-image">
                        <SpintrUser
                            name={title}
                            imageUrl={imageUrl}
                            size={48}
                            hideText={true}
                            personalName={false} />
                    </StackItem>
                    <StackItem className="ListContentCard-text" grow={1} shrink={1}>
                        <Stack className="ListContentCard-textStack" grow verticalFill>
                            <StackItem className="ListContentCard-title">
                                <MediumBodyBolder
                                    color="contentDark"
                                    lineHeight="normal"
                                >
                                    {title}
                                </MediumBodyBolder>
                            </StackItem>
                            <Stack verticalAlign="space-between" grow>
                                <StackItem grow={1} >
                                    <CaptionBody color="grayDark">
                                        {descriptionSubstring}
                                    </CaptionBody>
                                </StackItem>
                                <StackItem>
                                    <TagList tags={tags || []} />
                                </StackItem>
                            </Stack>
                        </Stack>
                    </StackItem>
                </Stack>
            </Link>
            {actionMenu}
            {standardActionMenu}
        </div>
    );
}

export default ListContentCard;
