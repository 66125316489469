function debounce(fn: Function, time: number): (...args: any[]) => void {
    let timeout: number;

    return function() {
        const args = arguments;
        const functionCall: TimerHandler = () => fn.apply(this, args);

        clearTimeout(timeout);

        timeout = setTimeout(functionCall, time);
    };
}

export default debounce;