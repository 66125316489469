import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Label, LocalizedString } from "src/ui";

interface IProps {
    post: Spintr.ISocialPageUpdate;
}

const SocialPageUpdate: FunctionComponent<IProps> = (props) => (
    <Label
        as="p"
        className="SocialPageUpdate"
        color="mid-grey"
        size="body-2"
    >
        <LocalizedString 
            text="UppdateradeTextsidan"
        />&nbsp;<Link to={props.post.url}>{props.post.pageTitle}</Link>
    </Label>
);

export default SocialPageUpdate;
