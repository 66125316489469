import { Modal, PrimaryButton } from "@fluentui/react";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useCallback } from "react";
import { localize } from "src/l10n";
import { Label, UnstyledButton } from "src/ui";
import PopupHeader from "src/ui/components/PopupHeader";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import NewsLayoutSettings from "./NewsLayoutSettings";

type ComponentProps = { [key: string]: any };
interface IComponentSettingsProps {
    props: ComponentProps

    onPropsChange(newProps: { [key: string]: any }): void;
};

interface IProps {
    componentKey: string;
    props: ComponentProps;
    
    onSave(newProps: { [key: string]: any }, close?: boolean): void;
    onClose(): void;
}

interface ISettingsDeclaration {
    titleLocalizationKey: string;
    component: FunctionComponent<IComponentSettingsProps> | React.ComponentClass<IComponentSettingsProps>;
}

const typeMap: { [componentKey: string]: ISettingsDeclaration } = {
    "NewsFeed": {
        titleLocalizationKey: "CHANGE_NEWS_LAYOUT",
        component: NewsLayoutSettings,
    }
}

const StartPageBuilderComponentSettings: FunctionComponent<IProps> = (props) => {
    const { componentKey, props: componentProps, onClose, onSave } = props;

    const [state, setState] = useState<{ [key: string]: any }>({
        ...(componentProps || {}),
    });

    const onSaveClick = useCallback(
        (event: any) => {
            if (event.stopPropagation) {
                event.stopPropagation();
            }

            onSave(state, true);
            mixpanelTrack(MixPanelEvents.Dynamo_SaveComponentSettings);
        },
        [state, onSave],
    );

    const settingsType = useMemo(
        () => typeMap[componentKey],
        [componentKey],
    );

    if (!settingsType) {
        return null;
    }

    const SettingsComponent = settingsType.component;

    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            containerClassName="StartPageBuilderComponentSettings"
            allowTouchBodyScroll
            isOpen
        >
            <PopupHeader text={localize(settingsType.titleLocalizationKey)} onClose={onClose} />
            <div className="settings-body">
                <SettingsComponent
                    props={state}
                    onPropsChange={setState}
                />
            </div>
            <div className="buttons">
                <UnstyledButton className="cancel" onClick={onClose}>
                    <Label color="light-blue" weight="medium">
                        {localize("Avbryt")}
                    </Label>
                </UnstyledButton>
                <PrimaryButton
                    className="save"
                    onClick={onSaveClick}
                >
                    {localize("Spara")}
                </PrimaryButton>
            </div>
        </Modal>
    );
};

export default StartPageBuilderComponentSettings;
