import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Action } from "redux";
import { FileBrowser, ZohoPopup } from "src/files/components";
import FileBrowserHeader from "src/files/components/FileBrowserHeader";
import { setCurrentFolderId } from "src/files/folder-actions";
import { FileDetailsEditView, FileDetailsView } from "src/files/views";
import FileDetailsOffice365View from "src/files/views/FileDetailsOffice365View";
import { IApplicationState } from "src/spintr/reducer";
import { FillHeight, Scrollable } from "src/ui/components";

interface IRouteParams {
    id: string;
    filesId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    currentFolderId: string;
    group: any;
    dispatch: (action: Action) => void;
    isSmallViewMode?: boolean;
    hasFormFooterBar?: boolean;
}

class GroupFilesView extends Component<IProps> {
    componentDidMount() {
        this.props.dispatch(setCurrentFolderId(this.props.group.folderId));
    }

    render() {
        var isFile = this.props.match.params.filesId && this.props.match.params.filesId.substr(0, 1) == "f";
        var isOffice365File = this.props.match.params.filesId && this.props.match.params.filesId.substr(0, 4) == "365f";
        var isVersion = this.props.location.pathname.includes("version");
        var isEdit = this.props.location.pathname.includes("edit");
        var source = this.props.group.syncFiles && this.props.group.externalId ? 6 : 3;

        var id =
            isFile || isOffice365File
                ? this.props.currentFolderId.toString()
                : this.props.match.params.filesId
                    ? this.props.match.params.filesId.substr(1)
                    : this.props.group.folderId;

        return (
            <div className="GroupFilesView FilesRootView GroupContentView">
                <div>
                    <div className="inner">
                        <div className={classnames("content noSourceList", { isFile: isFile || isOffice365File, isSmallViewMode: this.props.isSmallViewMode })}>
                            <div className="header">
                                <FileBrowserHeader
                                    id={id}
                                    location={this.props.location}
                                    source={source}
                                    group={this.props.group}
                                />
                            </div>
                            <FileBrowser
                                id={id}
                                location={this.props.location}
                                history={this.props.history}
                                source={source}
                                group={this.props.group}
                            />
                            {isFile && !isOffice365File && !isVersion && !isEdit && (
                                <FileDetailsView
                                    id={this.props.match.params.filesId.substr(1)}
                                    source={source}
                                    group={this.props.group}
                                />
                            )}
                            {isFile && isEdit && (
                                <FileDetailsEditView
                                    id={this.props.match.params.filesId.substr(1)}
                                    source={source}
                                    group={this.props.group}
                                />
                            )}
                            {isFile && isVersion && (
                                <FileDetailsEditView
                                    id={this.props.match.params.filesId.substr(1)}
                                    source={source}
                                    group={this.props.group}
                                    isVersion
                                />
                            )}
                            {isOffice365File && (
                                <FileDetailsOffice365View
                                    id={this.props.match.params.filesId.substr(4)}
                                    source={source}
                                    group={this.props.group}
                                />
                            )}
                            <ZohoPopup />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,

    currentFolderId: state.files.folders.get("currentFolderId"),
    searchText: state.files.files.searchText,
    isSmallViewMode: state.ui.isSmallViewMode,
    hasFormFooterBar: state.ui.formFooterBarVisible
});

// @ts-ignore
export default connect(mapStateToProps)(GroupFilesView);
