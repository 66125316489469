export interface ISearchHeaders {
    debugTime: string;
    debugTypes: string[];
    departments: { [id: number]: number },
    fileTypes: { [type: number]: number },
    objectTypes: { [type: number]: number },
    timePeriods: { [period: string]: number },
    totalCount: number;
}

export function parseSearchHeaders(headers: { [name: string]: string }): ISearchHeaders {
    return {
        debugTime: headers["x-time"] || "",
        debugTypes: !!headers["x-dbg-types"]
            ? headers["x-dbg-types"].split(",").map(s => s.trim())
            : [],
        departments: !!headers["x-department-hits"]
            ? headers["x-department-hits"].split(";").reduce(
                (acc, str) => {
                    const parts = str.split(":", 2);

                    try {
                        acc[parseInt(parts[0], 10)] = parseInt(parts[1], 10);
                    } catch (_) {
                        /* ignored */
                    }

                    return acc;
                },
                {}
            )
            : {},
        fileTypes: !!headers["x-file-types"]
            ? headers["x-file-types"].split(";").reduce(
                (acc, str) => {
                    const parts = str.split(":", 2);

                    try {
                        acc[parseInt(parts[0], 10)] = parseInt(parts[1], 10);
                    } catch (_) {
                        /* ignored */
                    }

                    return acc;
                },
                {}
            )
            : {},
        objectTypes: !!headers["x-types-count"]
            ? headers["x-types-count"].split(";").reduce(
                (acc, str) => {
                    const parts = str.split(":", 2);

                    try {
                        acc[parseInt(parts[0], 10)] = parseInt(parts[1], 10);
                    } catch (_) {
                        /* ignored */
                    }

                    return acc;
                },
                {}
            )
            : {},
        timePeriods: !!headers["x-time-periods"]
            ? headers["x-time-periods"].split(";").reduce(
                (acc, str) => {
                    const parts = str.split(":", 2);

                    try {
                        acc[parts[0]] = parseInt(parts[1], 10);
                    } catch (_) {
                        /* ignored */
                    }

                    return acc;
                },
                {}
            )
            : {},
        totalCount: !!headers["x-total-count"]
            ? parseInt(headers["x-total-count"], 10)
            : 0,
    }
}
