import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const TodoExpiredNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.ITodoExpiredNotification;
    if (!notification) {
        return null;
    }

    return (
        <Fragment>
            {localize("AttGoraPunkten") + " "}
            <Link to={notification.url}>
                {notification.name}
            </Link>
            {" " + localize("HarLoptUt").toLowerCase()}
        </Fragment>
    );
};

export default TodoExpiredNotification;
