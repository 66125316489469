import { combineReducers } from 'redux'

import groupReducer from './group-reducer'
import planningReducer from './planning-reducer'

const groupsReducer = combineReducers({
    group: groupReducer,
    planning: planningReducer
})

export default groupsReducer
