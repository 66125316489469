import { Component } from 'react';
import { isAnythingDirty } from 'src/utils';
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { localize } from 'src/l10n';
import CustomDialog from 'src/ui/components/Dialogs/CustomDialog';
import React from 'react';

interface IProps extends RouteChildrenProps {
    history: any;
}

interface IState {
    openBlockNavigationModal?: boolean;
    nextLocation?: string;
}

class SpintrNavigationBlocker extends Component<IProps, IState> {
    private unblock: any;

    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.initNavigationBlock();
    }

    initNavigationBlock() {
        this.unblock = this.props.history.block((nextLocation) => {
            const isSameLocation = nextLocation &&
                nextLocation.pathname &&
                this &&
                this.props &&
                this.props.history &&
                this.props.history.location &&
                this.props.history.location.pathname &&
                nextLocation.pathname === this.props.history.location.pathname;

            const isDirty = !isSameLocation && isAnythingDirty();

            if (isDirty) {
                this.setState({
                    openBlockNavigationModal: true,
                    nextLocation: nextLocation
                });
            }

            return !isDirty;
        });
    }

    render() {
        return (
            <CustomDialog
                message={localize("UnsavedChangesWarning")}
                show={!!this.state.openBlockNavigationModal}
                confirmMessage={localize("Ok")}
                onDismiss={() => {
                    this.setState({
                        openBlockNavigationModal: false
                    }, () => {
                        if (this.unblock) {
                            this.unblock();
                        }

                        this.initNavigationBlock();
                    });
                }}
                onConfirm={() => {
                    if (this.unblock) {
                        this.unblock();
                    }

                    this.setState({
                        openBlockNavigationModal: false
                    }, () => {
                        this.props.history.push(this.state.nextLocation);

                        this.initNavigationBlock();
                    });
                }} />
        );
    }
}

export default withRouter(SpintrNavigationBlocker);
