import { DefaultButton, Pivot, PivotItem, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React, {
    ChangeEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from "react";
import { from } from "rxjs";
import { localize } from "src/l10n";

import { fetchWidgetConfiguration, queryWidgets, saveConfiguration } from "src/marketplace/api";
import { getSampleWidgetData } from "src/marketplace/utils";
import { SpintrTypes } from "src/typings";
import { Label, Loader, UnstyledButton } from "src/ui";
import { FormControl, FormSection } from "src/ui/components/Forms";
import { Style } from "src/ui/helpers";
import { validateRequiredTextField } from "src/utils";
import { KeyPerformanceIndicatorSelector } from "../KeyPerformanceIndicatorSelector";
import { MarketplaceWidget } from "../MarketplaceWidget";
import { VariableInput } from "../VariableInput";

import "./WidgetConfiguration.scss";

interface IProps {
    appId: string;
    widgetConfigurationId: string;
    onCancelClick: () => void;
    onSaved?: (data: Spintr.IMarketplaceLibraryWidgetResponse, isNew?: boolean) => void;
}

interface IState {
    app: Spintr.IMarketplaceAppResponse;
    configuration: Spintr.IMarketplaceWidgetConfiguration;
    isLoading: boolean;
    isSaving: boolean;
    selectedPivotKey: string;
    totalCount: number;
    widgets: Spintr.IMarketplaceWidget[];
}

const defaultState: IState = {
    app: null,
    configuration: {
        id: null,
        data: {},
        name: "",
        widgetId: null,
    },
    isLoading: true,
    isSaving: false,
    selectedPivotKey: "0",
    totalCount: 0,
    widgets: [],
}

const getVariableValue = (state: IState, variableName: string): string => {
    const variables = state.configuration.data.variables || {};
    const value = variables[variableName] || "";

    if (!!value && !!value.type && value.type === "relative") {
        value.type = "variable";
    }

    return value;
}

const WidgetConfiguration: FunctionComponent<IProps> =
    ({ appId, widgetConfigurationId, onCancelClick, onSaved }) => {
        const [state, setState] = useState(defaultState);

        useEffect(() => {
            const subscription = from(queryWidgets(appId)).subscribe({
                next: (data) => setState((s) => ({
                    ...s,
                    ...data,
                    isLoading: false,
                }))
            });

            return () => subscription.unsubscribe();
        }, [appId]);

        useEffect(() => {
            if (widgetConfigurationId) {
                const subscription = from(fetchWidgetConfiguration(widgetConfigurationId)).subscribe({
                    next: (data) => setState((s) => ({
                        ...s,
                        configuration: {
                            ...s.configuration,
                            widgetId: data.widgetId,
                            name: data.name,
                            id: data.id,
                            data: {
                                variables: JSON.parse(data.variables)
                            }
                        },
                        isLoading: false,
                        selectedPivotKey: "1"
                    }))
                });

                return () => subscription.unsubscribe();
            }
        }, [widgetConfigurationId]);

        const templateClickHandler = useCallback(
            (w: Spintr.IMarketplaceWidget) => {
                setState((s) => ({
                    ...s,
                    configuration: {
                        ...s.configuration,
                        widgetId: w.id,
                        name: w.name,
                        data: {
                            ...(s.configuration.data || {}),
                            variables: {
                                ...(s.configuration.data.variables || {}),
                                text: !!w.config && !!w.config.dummy && !!w.config.dummy["text"] ?
                                    w.config.dummy["text"] :
                                    ""
                            }
                        }
                    },
                    selectedPivotKey: "1",
                }));
            },
            [setState],
        );

        const onPivotClick = useCallback((item: PivotItem) => {
            setState((s) => ({
                ...s,
                selectedPivotKey: item.props.itemKey!,
            }));
        }, [setState]);

        const onNameChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
            const { value } = ev.target;

            setState((s) => ({
                ...s,
                configuration: {
                    ...s.configuration,
                    name: value,
                },
            }));
        }, [setState]);

        const onTextVariableChanged = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
            const { name, value } = ev.target;

            setState((s) => ({
                ...s,
                configuration: {
                    ...s.configuration,
                    data: {
                        ...s.configuration.data,
                        variables: {
                            ...s.configuration.data.variables,
                            [name]: value,
                        },
                    },
                },
            }));
        }, [setState]);

        const onVariableChanged = useCallback(
            (input: Spintr.IMarketplaceVariableInput) => setState((s) => ({
                ...s,
                configuration: {
                    ...s.configuration,
                    data: {
                        ...s.configuration.data,
                        variables: {
                            ...s.configuration.data.variables,
                            [input.variableName]: input.value,
                        },
                    }
                }
            })),
            [setState],
        );

        const onSaveClick = useCallback(async () => {
            if (state.isSaving) {
                return;
            }

            if (!state.configuration.name) {
                return;
            }

            setState((s) => ({
                ...s,
                isSaving: true,
            }));

            try {
                const data = await saveConfiguration(
                    state.configuration.widgetId,
                    state.configuration.name,
                    state.configuration.data.variables,
                    state.configuration.id
                );

                if (onSaved) {
                    onSaved(data, !widgetConfigurationId);
                }
            } catch (err) {
                // TODO: Display error message

                setState((s) => ({
                    ...s,
                    isSaving: false,
                }));

                return;
            }
        }, [state, setState, onSaved, widgetConfigurationId]);

        const onKpiChanged = useCallback(
            (id: string) => setState((s) => ({
                ...s,
                configuration: {
                    ...s.configuration,
                    data: {
                        ...s.configuration.data,
                        variables: {
                            ...s.configuration.data.variables,
                            kpiId: id,
                        },
                    },
                },
            })),
            [setState],
        );

        const widget = !state.configuration.widgetId
            ? null
            : state.widgets.find(w => w.id === state.configuration.widgetId);

        const variables = (!widget
            ? []
            : Array.isArray(widget.variables)
                ? widget.variables
                : Object.keys(widget.variables || {})
                    .filter((k) => isNaN(+k))) as Spintr.IMarketplaceVariableSetup[];

        const hasSettings = (
            variables.length > 0 ||
            widget?.displayType === SpintrTypes.WidgetType.KeyPointIndicator ||
            state.app?.authenticationMethod === SpintrTypes.DataAppAuthenticationMethod.SpintrKPI
        );

        return (
            <div className="WidgetConfiguration">
                {state.isSaving && (
                    <Loader />
                )}
                {!state.isSaving && (
                    <Pivot
                        className="configuration-body"
                        onLinkClick={onPivotClick}
                        selectedKey={state.selectedPivotKey}
                    >
                        <PivotItem
                            headerText={localize("Mall")}
                            itemKey="0"
                        >
                            <div className="widget-list">
                                {state.widgets.map(w => (
                                    <div
                                        className="widget"
                                        key={w.id}
                                    >
                                        <UnstyledButton
                                            onClick={templateClickHandler}
                                            onClickData={w}
                                        >
                                            <MarketplaceWidget
                                                data={{
                                                    data: w.config?.dummy || getSampleWidgetData(w.displayType),
                                                    displayType: w.displayType,
                                                    iconUrl: state.app.iconUrl,
                                                    name: w.name,
                                                    variables: w.config?.dummy || {},
                                                }}
                                                displayBorder
                                            />
                                        </UnstyledButton>
                                        <Label
                                            as="div"
                                            className="heading"
                                            size="h3"
                                        >
                                            {w.name}
                                        </Label>
                                        <Label
                                            as="div"
                                            size="body-2"
                                        >
                                            {w.description}
                                        </Label>
                                    </div>
                                ))}
                                {state.isLoading && (
                                    <Loader />
                                )}
                            </div>
                        </PivotItem>
                        {widget && (
                            <PivotItem
                                headerText={localize("Installningar")}
                                itemKey="1"
                            >
                                <Stack
                                    className="settings"
                                    horizontal={true}
                                    styles={{
                                        root: {
                                            paddingTop: Style.getSpacing(2)
                                        }
                                    }}
                                >
                                    <div className="left">
                                        <FormControl>
                                            <TextField
                                                label={localize("Namn")}
                                                onChange={onNameChange}
                                                value={state.configuration.name}
                                                required
                                                aria-required
                                                validateOnFocusIn
                                                validateOnFocusOut
                                                onGetErrorMessage={validateRequiredTextField}
                                            />
                                        </FormControl>
                                        {hasSettings && (
                                            <>
                                                {widget.displayType === SpintrTypes.WidgetType.KeyPointIndicator && (
                                                    <FormControl>
                                                        <TextField
                                                            label={localize("Undertext")}
                                                            name="text"
                                                            onChange={onTextVariableChanged}
                                                            value={getVariableValue(state, "text")}
                                                        />
                                                    </FormControl>
                                                )}
                                                {state.app?.authenticationMethod === SpintrTypes.DataAppAuthenticationMethod.SpintrKPI && (
                                                    <KeyPerformanceIndicatorSelector
                                                        onChange={onKpiChanged}
                                                        selectedId={getVariableValue(state, "kpiId")}
                                                    />
                                                )}
                                                {variables.map((variable) => (
                                                    <VariableInput
                                                        key={variable.variableName}
                                                        onChange={onVariableChanged}
                                                        variable={{
                                                            ...variable,
                                                            value: getVariableValue(state, variable.variableName),
                                                        }}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </div>
                                    <div className="right">
                                        <FormSection title={localize("Forhandsvisning")}>
                                            <div className="data-widget">
                                                <MarketplaceWidget
                                                    data={{
                                                        data: getSampleWidgetData(widget.displayType),
                                                        displayType: widget.displayType,
                                                        iconUrl: state.app.iconUrl,
                                                        name: state.configuration.name || widget.name,
                                                        variables: state.configuration.data.variables || {},
                                                    }}
                                                    displayBorder
                                                />
                                            </div>
                                        </FormSection>
                                    </div>
                                </Stack>
                            </PivotItem>
                        )}
                    </Pivot>
                )}
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                    <DefaultButton onClick={onCancelClick} text={localize("Avbryt")} />
                    <PrimaryButton onClick={onSaveClick} text={localize("Spara")} />
                </Stack>
            </div>
        );
    };

export default WidgetConfiguration;