import React, { FunctionComponent, useCallback, useContext, useMemo } from "react";

import { UnstyledButton } from "src/ui";
import { ComposerContext } from "../ComposerContext";
import SocialComposerTypes from "../SocialComposerTypes";
import ComposerTypeButton from "./ComposerTypeButton";

import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./ComposerTypeSelectorBar.scss";

export type TypeSelectionCallback = (type: SocialComposerTypes) => void;

interface ISocialComposerTypeSelector {
    onChange: TypeSelectionCallback;
    selected?: SocialComposerTypes;
    types: SocialComposerTypes[];
    hideMoreButton?: boolean;
}

const ComposerTypeSelectorBar: FunctionComponent<ISocialComposerTypeSelector> =
    ({ hideMoreButton, onChange, types }) => {
        const context = useContext(ComposerContext);

        const setType = context.setType;
        const onMoreClick = useCallback(
            () => setType("type-selector"),
            [setType],
        );

        const displayedTypes = useMemo<SocialComposerTypes[]>(
            () => {
                const retVal: SocialComposerTypes[] = [];

                if (types.indexOf("image") > -1 || types.indexOf("video") > -1) {
                    retVal.push("image");
                }

                if (types.indexOf("external-file") > -1) {
                    retVal.push("external-file");
                }

                // We don't have this type
                // retVal.push("file");

                //retVal.push("question");
                retVal.push("poll");

                return retVal;
            },
            [types],
        );

        const classNames = useMemo(
            () => {
                const retVal: string[] = ["ComposerTypeSelectorBar"];

                if (hideMoreButton || displayedTypes.length < 4) {
                    retVal.push("single-view");
                }

                return retVal.join(" ");
            },
            [displayedTypes, hideMoreButton],
        );

        return (
            <div className={classNames}>
                <div className="type-list">
                    {displayedTypes.slice(0, 3).map((type) => (
                        <ComposerTypeButton
                            key={type}
                            onClick={onChange}
                            type={type}
                        />
                    ))}
                </div>
                {!hideMoreButton && displayedTypes.length > 3 && (
                    <UnstyledButton
                        className="more-button"
                        onClick={onMoreClick}
                    >
                        <Visage2Icon icon="more-square" className="hide-circle" />
                    </UnstyledButton>
                )}
            </div>
        );
    };

export default ComposerTypeSelectorBar;
