import React, { FunctionComponent } from "react";
import { TeaserBoxLoader } from "src/teaser-box";

const TeaserBoxesWrapper: FunctionComponent = () => (
    <div className="TeaserBoxesWrapper">
        <TeaserBoxLoader
            nonDataWidgetsOnlyIfMoreThanOneOfEach={true}
            blogsOnly={false}
        />
    </div>
);

export default TeaserBoxesWrapper;
