import { SpintrTypes } from 'src/typings';
import {
    FETCH_GROUP_PENDING,
    FETCH_GROUP_REJECTED,
    FETCH_GROUP_FULFULLED,
    SET_IS_FOLLOWING,
    JOIN_GROUP,
    LEAVE_GROUP,
    CLEAR_UNREAD,
    INCREMENT_UNREAD
} from './group-action-types';

const initialState = {
    failed: false,
    isLoaded: false,
    isLoading: false,
    unread: 0
}

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GROUP_PENDING:
            return {
                ...state,
                failed: false,
                isLoaded: false,
                isLoading: true
            };
            
        case FETCH_GROUP_REJECTED:
            return {
                ...state,
                failed: true,
                isLoaded: false,
                isLoading: false
            };

        case FETCH_GROUP_FULFULLED:
            return {
                ...action.payload.data,
                failed: false,
                isLoaded: true,
                isLoading: false
            };

        case SET_IS_FOLLOWING:
            // @ts-ignore
            if (action.meta.id === state.id) {
                return {
                    ...state,
                    isFollowing: action.meta.isFollowing
                }
            } else {
                return state;
            }
        
        case JOIN_GROUP:
            // @ts-ignore
            if (action.meta.id === state.id) {
                return {
                    ...state,
                    isMember: true
                }
            } else {
                return state
            }

        case LEAVE_GROUP:
            // @ts-ignore
            if (action.meta.id === state.id) {
                return {
                    ...state,
                    isMember: false
                }
            } else {
                return state
            }            

        case INCREMENT_UNREAD:
            return {
                ...state,
                unread: (state.unread ?? 0) + 1
            }
        case CLEAR_UNREAD:
            return {
                ...state,
                unread: 0
            };
        default:
            return state;
    }
}

export default groupReducer;
