import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Label, Loader, UnstyledButton } from "src/ui";
import { Style } from "src/ui/helpers";
import api from "../SpintrApi";

interface IProps {

}

interface IState {
    isLoading: boolean;
}

class TeamsLogOutButton extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    logOut() {
        this.setState({
            isLoading: true
        }, () => {
            const callback = () => {
                localStorage.clear();
                window.location.href = (window.location.origin + "/teamsapp/loggedout");
            }

            api.get("/api/authentication/signout").then(callback).catch(callback);
        });
    }

    public render() : ReactNode {
        return (
            <div style={{textAlign: "right", marginBottom: Style.getSpacing(1), marginRight: Style.getSpacing(1)}}>
                {
                    this.state.isLoading && (
                        <div style={{
                            display: "inline-block",
                            margin: -24,
                            marginLeft: 0,
                            marginRight: 0
                        }}>
                            <Loader loaderSize="small" />
                        </div>
                    )
                }
                {
                    !this.state.isLoading && (
                        <UnstyledButton onClick={this.logOut.bind(this)}>
                            <Label size="small-1" color="light-blue">{localize("LoggaUt")}</Label>
                        </UnstyledButton>
                    )
                }
            </div>
        );
    }
}

export default TeamsLogOutButton;