import React, { Component } from 'react';
import { FillHeight, Scrollable } from "src/ui/components";

class GroupClassicNotesView extends Component {
    render() {
        return (
            <div className="GroupClassicNotesView GroupContentView">
                GroupClassicNotesView
            </div>
        )
    }
}

export default GroupClassicNotesView;
