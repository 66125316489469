import React, { ReactElement, useEffect, useRef, useState } from "react";
import { TagListProps } from "./TagList.types";
import { TagItem } from "../TagItem";
import { localize } from "src/l10n";
import { CaptionBody } from "../Text";
import { debounce } from "src/utils";
import classNames from "classnames";

function TagList({ tags, initialDisplayAll }: TagListProps): ReactElement {
    const [displayAll, setDisplayAll] = useState(initialDisplayAll);
    const [visibleTagCount, setVisibleTagCount] = useState(999);
    const [lastItemWidth, setLastItemWidth] = useState(0);
    const containerRef = useRef(null);
    const displayAllButtonRef = useRef(null);

    const handleResize = () => {
        if (!containerRef.current) {
            return;
        }

        if (containerRef.current.offsetWidth == 0) {
            return;
        }

        let width = 0;
        let count = 0;
        let lastItemWidth = 0;
        const containerWidth = containerRef.current.offsetWidth;
        const buttonWidth = displayAllButtonRef?.current?.offsetWidth || 0;

        for (const tag of containerRef.current.children) {
            if (tag === displayAllButtonRef) {
                continue;
            }

            width += tag.offsetWidth;

            if (width + buttonWidth <= containerWidth) {
                count++;
                width += 8; // Include spacing between tags
            } else {
                const remainingSpace = containerWidth - buttonWidth - (width - tag.offsetWidth);

                if (remainingSpace > 40) {
                    count++;
                    lastItemWidth = remainingSpace;
                }

                break;
            }
        }

        setVisibleTagCount(count);
        setLastItemWidth(lastItemWidth);
    }

    const debouncedResize = debounce(() => handleResize(), 10);

    useEffect(() => {
        // @ts-ignore
        const observer = new ResizeObserver(debouncedResize);
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect();
    }, [tags, visibleTagCount])

    if (!tags || tags.length === 0) {
        return null;
    }

    const visibleTags = displayAll ? tags : tags.slice(0, visibleTagCount);
    const hiddenTagCount = tags.length - visibleTagCount;

    return (
        <div
            className={classNames("TagList", {
                displayAll,
            })}
        >
            <ol className="TagList-items" ref={containerRef}>
                {visibleTags.map((tag, idx) => (
                    <li
                        className="item"
                        key={tag.id}
                        style={{
                            maxWidth:
                                !displayAll && lastItemWidth > 0 && idx === visibleTags.length - 1
                                    ? lastItemWidth
                                    : undefined,
                        }}
                    >
                        <TagItem tag={tag} />
                    </li>
                ))}
                {!displayAll && hiddenTagCount > 0 && (
                    <li className="TagItem" ref={displayAllButtonRef} onClick={(e) => {
                        e.preventDefault(); // Prevent parent Link from being triggered
                        setDisplayAll(true);
                    }}>
                        <CaptionBody letterSpacing="dense" weight="medium">
                            {`+${hiddenTagCount} ${localize("Mer2").toLowerCase()}`}
                        </CaptionBody>
                    </li>
                )}
            </ol>
        </div>
    )
}

export default TagList;
