import React, { FunctionComponent } from "react";

interface IProps {
    notification: Spintr.INotification;
}

const InAppTipNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IInAppTipNotification;
    if (!notification) {
        return;
    }

    return (
        <span>{notification.message}</span>
    );
};

export default InAppTipNotification;
