import React from "react";
import "./SocialPostTypeContent.scss";
import { visage2SocialPostTypes } from "src/social-feed/types";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";
import { Label } from "src/ui";
import { localize } from "src/l10n";
import SocialPostTypeContentInput from "./SocialPostTypeContentInput";
import SocialPostTypeContentConfetti from "./SocialPostTypeContentConfetti";

interface IProps {
    post: any;
    editMode?: boolean;
    onUpdate?: any;
}

const SocialPostTypeContentBusinessDeal = (props: IProps) => {
    const foundType = visage2SocialPostTypes.find(x => x.type === SpintrTypes.StatusType.BusinessDeal);

    return (
        <div>
            {
                props.post.displayDecorations && (
                    <SocialPostTypeContentConfetti />
                )
            }
            <div className="icon-wrapper">
                <Visage2Icon
                    icon={foundType.icon}
                    type="custom"
                    size="extra-big"
                    hexColor={foundType.iconColor} />
            </div>
            {!props.editMode && (
                <div className="content-wrapper">
                    <Label color={"white"} size="h3">{props.post.customer}</Label>
                    <Label color={"white"} size="body-1">{props.post.value}</Label>
                </div>
            )}
            {props.editMode && (
                <div className="content-wrapper">
                    <SocialPostTypeContentInput
                        big
                        value={props.post.customer}
                        placeholder={localize("ENTER_CUSTOMER_NAME")}
                        onChange={(text) => {
                            props.onUpdate({
                                ...props.post,
                                customer: text
                            })
                        }} />
                    <SocialPostTypeContentInput
                        value={props.post.value}
                        placeholder={localize("ENTER_VALUE_HERE")}
                        onChange={(text) => {
                            props.onUpdate({
                                ...props.post,
                                value: text
                            })
                        }} />
                </div>
            )}
        </div>
    )
}

export default SocialPostTypeContentBusinessDeal;