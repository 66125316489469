import { AxiosError, AxiosResponse } from "axios";
import {
    Checkbox,
    DefaultButton,
    Dropdown,
    Image,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import React, {
    FormEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import FileSelector from "src/spintr/components/FileSelector";
import { Breadcrumbs, ContentWithSubmenu, Label, Submenu } from "src/ui";
import { FormControl, FormFooterBar } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { Style } from "src/ui/helpers";
import { scrollToTop } from "src/utils";
import { validateRequiredTextField } from "src/utils";
import { MandatoryText } from "../../ui/components/Forms";
import api from "src/spintr/SpintrApi";

interface IProps {
    companyId?: string;
    departmentId?: string;
    officeId?: string;
}

interface IAppStateProps {
    displayCustomLinkSSO?: boolean;
    displayIosoftKeyInput?: boolean;
    displaySkyltdirektSSOInputs?: boolean;
    displayDirektenIdInput?: boolean;
    isAdmin: boolean;
    currentUserCompanyId: number;
    currentUserOfficeId: number;
    currentUserDepartmentId: number;
}

interface IState {
    isLoading: boolean;
    saveError: string[];
    unitModel: any;
    showCoverImageSelector: boolean;
    showProfileImageSelector: boolean;
    profileImage: string;
    coverImage: string;
    enableFieldValidation?: boolean;
    themes: any[];
    offices: any[];
    originalThemeId?: number;
}

const initialState: IState = {
    coverImage: undefined,
    unitModel: {
        themeId: -1,
    },
    isLoading: true,
    profileImage: undefined,
    saveError: [],
    showCoverImageSelector: false,
    showProfileImageSelector: false,
    themes: [],
    offices: [],
};

type MfaSettings = {
    enabled: boolean;
    enforced: boolean;
};

enum FormType {
    Company = 1,
    Office,
    Department
}

const OrganisationUnitEditView: FunctionComponent<IProps> = (props) => {
    const { companyId, departmentId, officeId } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const enableCompanyLevel = useSelector<Spintr.AppState>(
        (s) => s.instance.get("enableCompanyLevel"),
    );
    const forceCompanyFeed = useSelector<Spintr.AppState>(
        (s) => s.instance.get("forceCompanyFeed"),
    );

    const mfaSettings = useSelector<Spintr.AppState, MfaSettings>(
        (s) => ({
            enabled: s.instance.get("enableMfa") || false,
            enforced: s.instance.get("enforceMfa") || false,
        }),
    );
    console.log(mfaSettings);

    const isNew = !location.pathname.includes("edit") && !location.pathname.includes("settings");
    const formType = useMemo(
        () => {
            if (isNew) {
                if (!companyId && enableCompanyLevel) {
                    return FormType.Company;
                }

                return officeId ? FormType.Department : FormType.Office;
            }

            if (departmentId) {
                return FormType.Department;
            }

            return officeId ? FormType.Office : FormType.Company;
        },
        [companyId, departmentId, isNew, officeId],
    );

    const isFromAdminView = location.pathname.startsWith("/admin");
    const isFromOrganisationView = location.pathname.startsWith("/organisation/v/");

    const isNewOffice = !officeId;

    const appState = useSelector<Spintr.AppState, IAppStateProps>((s) => ({
        currentUserCompanyId: s.profile.active.department.office.company.id,
        currentUserDepartmentId: s.profile.active.department.id,
        currentUserOfficeId: s.profile.active.department.office.id,
        displayCustomLinkSSO: s.instance.get("displayCustomLinkSSO") as boolean,
        displayIosoftKeyInput: s.instance.get("displayIosoftKeyInput") as boolean,
        displaySkyltdirektSSOInputs: s.instance.get("displaySkyltdirektSSOInputs") as boolean,
        displayDirektenIdInput: s.instance.get("enableDirekten") as boolean,
        isAdmin: s.profile.active.isAdmin,
    }));

    const [state, setState] = useState(initialState);

    const languages = [
        {
            key: "1",
            text: localize("Swedish"),
        },
        {
            key: "2",
            text: localize("Norwegian"),
        },
        {
            key: "3",
            text: localize("English"),
        },
        {
            key: "4",
            text: localize("German"),
        },
        {
            key: "5",
            text: localize("Finnish"),
        },
        {
            key: "6",
            text: localize("Danish"),
        },
        {
            key: "7",
            text: localize("Italian"),
        },
        {
            key: "8",
            text: localize("Spanish"),
        },
        {
            key: "9",
            text: localize("French"),
        }
    ];

    useEffect(
        () => {
            api.get("/api/v1/themes", { params: { take: 1000 } }).then((response: AxiosResponse) => {
                const themes = [
                    {
                        key: -1,
                        text: localize("Standardtema"),
                    },
                    ...response.data.map((theme) => ({
                        key: theme.id,
                        text: theme.name,
                    })),
                ];

                setState((s) => ({
                    ...s,
                    unitModel: {
                        ...s.unitModel,
                        themeId: -1,
                    },
                    themes,
                }));

                if (isNew) {
                    if (
                        (formType === FormType.Company && !companyId) ||
                        (formType === FormType.Office && !enableCompanyLevel)
                    ) {
                        setState((s) => ({ ...s, isLoading: false }));
                        return;
                    }

                    const companyPromise = api
                        .get<Spintr.ICompanyQueryItem>(`/api/v1/companies/${companyId}`);

                    if (formType === FormType.Office && enableCompanyLevel) {
                        companyPromise
                            .then((response) => {
                                setState((s) => ({
                                    ...s,
                                    isLoading: false,
                                    unitModel: {
                                        ...s.unitModel,
                                        companyId,
                                        companyName: response.data.name,
                                    }
                                }));
                            });
                        return;
                    }

                    const officePromise = api.get(`/api/v1/offices/${officeId}`);

                    const promises: Array<Promise<any>> = [
                        ...(enableCompanyLevel
                            ? [
                                companyPromise.then((response) => {
                                    setState((s) => ({
                                        ...s,
                                        unitModel: {
                                            ...s.unitModel,
                                            companyId,
                                            companyName: response.data.name,
                                        },
                                    }));

                                    return true;
                                }),
                            ]
                            : []),
                        officePromise.then((response: AxiosResponse) => {
                            setState((s) => ({
                                ...s,
                                unitModel: {
                                    ...s.unitModel,
                                    officeId: response.data.id,
                                    officeName: response.data.name,
                                },
                            }));

                            return true;
                        }),
                    ];

                    Promise.all(promises).then(() => setState((s) => ({
                        ...s,
                        isLoading: false,
                    })));

                    return;
                }

                api
                    .get(`/api/v1/units/${departmentId || officeId || companyId}`)
                    .then((response: AxiosResponse) => {
                        const unit = response.data;

                        unit.address1 = unit.address[0] || "";
                        unit.address2 = unit.address[1] || "";
                        unit.coverArtUrl = unit.coverArt;
                        unit.themeId = unit.themeId || -1;
                        delete unit.coverArt;

                        setState((s) => ({
                            ...s,
                            isLoading: false,
                            unitModel: unit,
                            originalThemeId: unit.themeId > 0 ? unit.themeId : undefined,
                        }));
                    });
            });
        },
        [departmentId, isNew, officeId, formType, companyId],
    );

    useEffect(
        () => {
            api
                .get("/api/v1/offices", {
                    params: {
                        take: 1000,
                    },
                })
                .then((response: AxiosResponse) => {
                    setState((s) => ({
                        ...s,
                        offices: response.data.map((office) => ({
                            key: office.id,
                            text: office.name,
                        })),
                    }));                    
                });
        },
        [departmentId, isNew, officeId, formType, companyId],
    );

    const fetchSubmenu = useCallback(
        async (params: submenuQueryParams) => {
            const response = await api.get(
                "/api/v1/units", {
                params: {
                    ignoreEmptyOffices: true,
                    searchText: params.searchText,
                    takeAll: true
                },
            }
            );

            const offices = [
                {
                    active: officeId === "0",
                    id: 0,
                    name: localize("Alla"),
                    url: "/organisation/0",
                },
                ...response.data.offices.map((office: any) => {
                    return {
                        active: office.id === officeId,
                        id: office.id,
                        name: office.name,
                        url: `/organisation/${office.id}`,
                    };
                }),
            ];

            return offices;
        },
        [officeId, appState.isAdmin],
    );

    const submenuSearchProps = useMemo(() => ({ enabled: true }), []);

    const breadcrumbs = useMemo(
        () => {
            const baseUrl = "/admin/organisation";

            const crumbs = [{
                key: "org",
                link: baseUrl,
                text: localize("KontorOchAvdelningar"),
            }];

            let address = enableCompanyLevel && companyId
                ? baseUrl + "/" + companyId
                : baseUrl;

            if (state.unitModel?.companyName) {
                crumbs.push({
                    key: "company",
                    link: address,
                    text: state.unitModel.companyName,
                });
            }

            if (formType !== FormType.Company && officeId) {
                address += "/" + officeId;
            }

            if (state.unitModel?.officeName) {
                crumbs.push({
                    key: "office",
                    link: address,
                    text: state.unitModel.officeName,
                });
            }

            if (formType !== FormType.Office && departmentId) {
                address += "/" + departmentId;
            }

            if (!isNew && state.unitModel?.name) {
                crumbs.push({
                    key: "department",
                    link: address + "/edit",
                    text: state.unitModel.name,
                });
            }

            if (isNew) {
                crumbs.push({
                    key: "create",
                    link: location.pathname,
                    text: formType === FormType.Company
                        ? localize("ORG_CREATE_COMPANY")
                        : formType === FormType.Office
                            ? localize("SkapaKontor")
                            : localize("SkapaAvdelning"),
                });
            }


            return crumbs;
        },
        [state.unitModel, isNew, formType, location, enableCompanyLevel, officeId, companyId, departmentId],
    );

    const onChangeForm = useCallback(
        (ev: FormEvent, value: any) => {
            // No idea why fluent/react/typescript doesn't get this, but
            // the cast below shouldn't be an issue
            const target = ev.target as HTMLInputElement | HTMLTextAreaElement;
            const name = target.name;

            setState((s) => ({
                ...s,
                unitModel: {
                    ...s.unitModel,
                    [name]: value,
                },
            }));
        },
        [],
    );

    const onChangeDropdown = (ev, option) => {
        setState((s) => ({
            ...s,
            unitModel: {
                ...s.unitModel,
                themeId: option.key,
            },
        }));
    };

    const onChangeOfficeDropdown = (ev, option) => {
        setState((s) => ({
            ...s,
            unitModel: {
                ...s.unitModel,
                officeId: option.key,
            },
        }));
    };

    const onChangeLanguageDropdown = (ev, option) => {
        setState((s) => ({
            ...s,
            unitModel: {
                ...s.unitModel,
                defaultLanguage: option.key,
            },
        }));
    };

    const dismisErrorBar = useCallback(
        () => setState((s) => ({ ...s, saveError: [] })),
        [setState],
    );

    const onCancelClick = useCallback(
        () => {
            history.push({
                pathname: isFromOrganisationView
                    ? `/organisation/v/${departmentId || officeId || companyId}`
                    : isFromAdminView
                        ? "/admin/organisation"
                        : departmentId ? `/organisation/${officeId}` : companyId && officeId && enableCompanyLevel ? `/organisation/c/${companyId}` : "/organisation",
            });
        },
        [departmentId, history, isFromAdminView, isFromOrganisationView, officeId, enableCompanyLevel],
    );

    const onSaveClick = useCallback(
        async () => {
            setState((s) => ({
                ...s,
                enableFieldValidation: true,
                isLoading: true,
                saveError: []
            }));

            const unitModel = state.unitModel;

            const saveData = {
                ...unitModel,
                companyId,
                officeId: !!unitModel.officeId ? unitModel.officeId : officeId,
                themeId: unitModel.themeId > -1 ? unitModel.themeId : undefined,                
            };

            if (!unitModel.name || unitModel.name.length === 0) {
                setState((s) => ({
                    ...s,
                    isLoading: false,
                    saveError: ["DuMasteFyllaIEttNamn"]
                }));

                return;
            }

            try {
                if (formType === FormType.Company) {
                    const promise = isNew
                        ? api.post("/api/v1/companies", saveData)
                        : api.put(`/api/v1/units/${companyId}`, saveData);

                    const response = await promise;

                    history.push({
                        pathname: isFromAdminView
                            ? "/admin/organisation"
                            : isFromOrganisationView
                                ? `/organisation/v/${response.data.id}`
                                : `/organisation`,
                    });

                    return;
                }

                if (isNew) {
                    saveData.telephone = saveData.phoneNumber;
                    saveData.email = saveData.emailAddress;
                    saveData.profileImageTicket = saveData.profileImage;
                    saveData.coverArtTicket = saveData.coverArt;

                    if (isNewOffice) {
                        if (!saveData.companyId) {
                            saveData.companyId = appState.currentUserCompanyId;
                        }

                        const response = await api.post("/api/v1/offices", saveData);

                        history.push({
                            pathname: `/admin/organisation/${companyId ? companyId + "/" : ""}${response.data.id}`,
                        });
                    } else {
                        await api.post("/api/v1/departments", saveData);

                        history.push({
                            pathname: `/admin/organisation/${companyId ? companyId + "/" : ""}${officeId}`,
                        });
                    }
                } else {
                    if (officeId && !departmentId) {
                        await api.put(`/api/v1/units/${officeId}`, saveData);

                        if (
                            state.originalThemeId !== saveData.themeId &&
                            unitModel.id === appState.currentUserOfficeId
                        ) {
                            dispatch(
                                setConfirmPopup({
                                    isOpen: true,
                                    message: localize("LaddarOmForAttSeAndringar"),
                                    onConfirm: () => {
                                        window.location.reload();
                                    },
                                    onDismiss: () => {
                                        history.push({
                                            pathname: isFromOrganisationView
                                                ? `/organisation/v/${officeId}`
                                                : isFromAdminView
                                                    ? enableCompanyLevel
                                                        ? `/admin/organisation/${companyId}`
                                                        : "/admin/organisation"
                                                    : enableCompanyLevel
                                                        ? `/organisation/c/${companyId}`
                                                        : "/organisation",
                                        });
                                    },
                                })
                            );
                            return;
                        }

                        history.push({
                            pathname: isFromOrganisationView
                                ? `/organisation/v/${officeId}`
                                : isFromAdminView
                                    ? enableCompanyLevel
                                        ? `/admin/organisation/${companyId}`
                                        : "/admin/organisation"
                                    : enableCompanyLevel
                                        ? `/organisation/c/${companyId}`
                                        : "/organisation",
                        });

                        return;
                    }

                    await api.put(`/api/v1/units/${departmentId}`, saveData);

                    if (
                        state.originalThemeId !== saveData.themeId &&
                        unitModel.id === appState.currentUserDepartmentId
                    ) {
                        dispatch(
                            setConfirmPopup({
                                isOpen: true,
                                message: localize("LaddarOmForAttSeAndringar"),
                                onConfirm: () => {
                                    window.location.reload();
                                },
                                onDismiss: () => {
                                    history.push({
                                        pathname: isFromOrganisationView
                                            ? `/organisation/v/${departmentId}`
                                            : isFromAdminView
                                                ? enableCompanyLevel
                                                    ? `/admin/organisation/${companyId}/${officeId}`
                                                    : `/admin/organisation/${officeId}`
                                                : `/organisation/${officeId}`,
                                    });
                                },
                            })
                        );
                        return;
                    }

                    history.push({
                        pathname: isFromOrganisationView
                            ? `/organisation/v/${departmentId}`
                            : isFromAdminView
                                ? enableCompanyLevel
                                    ? `/admin/organisation/${companyId}/${officeId}`
                                    : `/admin/organisation/${officeId}`
                                : `/organisation/${officeId}`,
                    });
                }
            } catch (err) {
                const error = err as AxiosError;

                setState((s) => ({
                    ...s,
                    isLoading: false,
                    saveError: error.response.data.errors?.map((e) => e.message) || ["TeknisktFel"],
                }));
                scrollToTop();
            }
        },
        [departmentId, history, isFromAdminView, isFromOrganisationView, isNew, isNewOffice, officeId, state.unitModel, formType, companyId],
    );

    const renderSubmenu = useCallback(
        () => {
            return (
                <>
                    <Breadcrumbs
                        displayInstance={true}
                        isSubmenu={true}
                        items={[
                            {
                                key: "",
                                link: "/organisation",
                                text: localize("Organisation"),
                            },
                            {
                                key: state.unitModel.officeId,
                                link: `/organisation/${state.unitModel.officeId}`,
                                text: state.unitModel.officeName,
                            },
                            ...(isNew
                                ? [
                                    {
                                        key: "create",
                                        text: localize("Skapa"),
                                    },
                                ]
                                : []),
                            ...(!isNew
                                ? [
                                    ...(departmentId
                                        ? [
                                            {
                                                key: state.unitModel.id,
                                                link: `/organisation/v/${state.unitModel.id}`,
                                                text: state.unitModel.name,
                                            },
                                        ]
                                        : []),
                                    {
                                        key: "edit",
                                        text: localize("Redigera"),
                                    },
                                ]
                                : []),
                        ]}
                    />
                    <Submenu search={submenuSearchProps} fetch={fetchSubmenu} take={999} />
                </>
            );
        },
        [departmentId, fetchSubmenu, isNew, state.unitModel, submenuSearchProps]
    );

    if (state.isLoading) {
        return <SpintrLoader />;
    }

    const {
        showProfileImageSelector,
        showCoverImageSelector,
    } = state;
    const iframe = `<iframe className="map" aria-label="${localize('Karta')}" width="350" height="250" frameborder="0" style="border:0;padding-top: 12px;" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAVB0aZK9eoz3LbX7GrXZ7aKanoxG6ImFo&q=${state.unitModel.mapQuery}" allowfullscreen></iframe>`;

    return (
        <ContentWithSubmenu
            renderSubmenu={renderSubmenu}
            hideSubmenu={isFromOrganisationView || isFromAdminView}

        >
            <div className="page-margin-bottom">
                {state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={state.saveError}
                        onDismiss={dismisErrorBar}
                    />
                )}
                {isFromAdminView &&
                    <Breadcrumbs
                        isSubmenu={true}
                        items={breadcrumbs}
                    />}
                {!isNew && <Label size="h1">{localize("Installningar")}</Label>}
                {isNew && isFromAdminView && formType === FormType.Company && (
                    <div>
                        <Label size="h1">{localize("ORG_CREATE_COMPANY")}</Label>
                        <MandatoryText />
                    </div>
                )}
                {isNew && isFromAdminView && formType === FormType.Office && (
                    <div>
                        <Label size="h1">{localize("SkapaKontor")}</Label>
                        <ShowMoreText
                            lines={1}
                            more={localize("VisaMer")}
                            less={localize("VisaMindre")}
                            expanded={false}
                            width={480}
                        >
                            {localize("SkapaKontorText")}
                        </ShowMoreText>
                        <MandatoryText />
                    </div>
                )}
                {isNew && isFromAdminView && formType === FormType.Department && (
                    <>
                        <Label size="h1">{localize("SkapaAvdelning")}</Label>
                        <ShowMoreText
                            lines={1}
                            more={localize("VisaMer")}
                            less={localize("VisaMindre")}
                            expanded={false}
                            width={480}
                        >
                            {localize("SkapaAvdelningText")}
                        </ShowMoreText>
                    </>
                )}
                <form>
                    <FormControl>
                        <TextField
                            label={localize("Namn")}
                            value={state.unitModel.name}
                            name="name"
                            onChange={onChangeForm}
                            required={true}
                            aria-required={true}
                            validateOnFocusIn={true}
                            validateOnFocusOut={true}
                            validateOnLoad={!!state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={localize("Telefonnummer")}
                            value={state.unitModel.phoneNumber}
                            name="phoneNumber"
                            onChange={onChangeForm}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={localize("Epost")}
                            value={state.unitModel.emailAddress}
                            name="emailAddress"
                            onChange={onChangeForm}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={`${localize("Adress")} 1`}
                            value={state.unitModel.address1}
                            name="address1"
                            onChange={onChangeForm}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={`${localize("Adress")} 2`}
                            value={state.unitModel.address2}
                            name="address2"
                            onChange={onChangeForm}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={localize("Hemsida")}
                            value={state.unitModel.websiteUrl}
                            name="websiteUrl"
                            onChange={onChangeForm}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={localize("AndraProfilMeddelande")}
                            value={state.unitModel.message}
                            name="message"
                            onChange={onChangeForm}
                            maxLength={160}
                            multiline={true}
                            autoAdjustHeight={true}
                        />
                    </FormControl>

                    <FormControl>
                        <Dropdown
                            placeholder={localize("Tema")}
                            options={state.themes}
                            selectedKey={state.unitModel.themeId}
                            onChange={onChangeDropdown}
                            label={localize("Tema")}
                        />
                    </FormControl>
                    
                    {formType === FormType.Department && (
                    <FormControl>
                        <Dropdown
                            placeholder={localize("Kontor")}
                            options={state.offices}
                            selectedKey={state.unitModel.officeId}
                            onChange={onChangeOfficeDropdown}
                            label={localize("Kontor")}
                        />
                    </FormControl>
                    )}                    

                    <FormControl>
                        <TextField
                            label={localize("Karta")}
                            value={state.unitModel.mapQuery}
                            placeholder={localize("Sok") + "..."}
                            name="mapQuery"
                            onChange={onChangeForm}
                        />
                        {state.unitModel.mapQuery && <div dangerouslySetInnerHTML={{ __html: iframe }} />}
                    </FormControl>

                    <FormControl>
                        <Dropdown
                            label={localize("Sprak")}
                            style={
                                {
                                    //maxWidth: 500
                                }
                            }
                            placeholder={localize("Valj")}
                            selectedKey={state.unitModel.defaultLanguage}
                            onChange={onChangeLanguageDropdown}
                            options={languages}
                        />
                    </FormControl>

                    <FormControl>
                        <Checkbox
                            label={localize("HarAvdelningssida")}
                            checked={state.unitModel.hasPage}
                            name="hasPage"
                            onChange={onChangeForm}
                        />
                    </FormControl>

                    {forceCompanyFeed && formType === FormType.Company && (
                        <FormControl>
                            <Checkbox
                                label={localize("IS_PARENT_COMPANY")}
                                checked={state.unitModel.isParentCompany}
                                name="isParentCompany"
                                onChange={onChangeForm}
                            />
                        </FormControl>
                    )}

                    {mfaSettings.enabled && (
                        <FormControl>
                            <Checkbox
                                label={localize("REQUIRE_MFA")}
                                checked={mfaSettings.enforced || state.unitModel.requireMfa}
                                disabled={mfaSettings.enforced}
                                name="requireMfa"
                                onChange={onChangeForm}
                            />
                        </FormControl>
                    )}
                </form>
                <Stack style={{ marginTop: "20px" }}>
                    <form>
                        <FormControl label={localize("Bild")}>
                            {!state.unitModel.imageUrl && (
                                <PrimaryButton
                                    onClick={() => {
                                        setState((s) => ({
                                            ...s,
                                            showProfileImageSelector: true
                                        }));
                                    }}
                                >
                                    {localize("ValjBild")}
                                </PrimaryButton>
                            )}
                        </FormControl>
                    </form>
                    {state.unitModel.imageUrl && (
                        <>
                            <Image style={{ maxWidth: "100%" }} src={state.unitModel.imageUrl} className="profile-image" />
                            <form>
                                <FormControl>
                                    <DefaultButton
                                        style={{ marginTop: Style.getSpacingStr(3) }}
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                unitModel: {
                                                    ...prevState.unitModel,
                                                    imageUrl: "",
                                                    profileImage: "",
                                                    removeImage: true,
                                                },
                                            }));

                                        }}
                                    >
                                        {localize("TaBortBild")}
                                    </DefaultButton>
                                </FormControl>
                            </form>
                        </>
                    )}
                    {showProfileImageSelector && (
                        <FileSelector
                            renderSizeString={() => {
                                return (
                                    `${localize(
                                        "OptimalBildStorlek"
                                    )}: 80x80px <br />`
                                )
                            }}
                            cropImage
                            cropShape="round"
                            cropAspect={1}
                            onClose={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    showProfileImageSelector: false,
                                }));
                            }}
                            onSelect={(data) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    showProfileImageSelector: false,
                                    unitModel: {
                                        ...prevState.unitModel,
                                        imageUrl: data[0].thumbnailUrl,
                                        profileImage: data[0].ticket,
                                        removeImage: false,
                                    },
                                }));
                            }}
                            allowMultipleFiles={false}
                            fileTypesString={"image/png, image/jpeg"}
                            fileTypes={["png", "jpeg", "jpg"]}
                            sourcesToDisplay={[0]}
                            fileUploadType={1}
                        />
                    )}
                </Stack>

                <Stack style={{ marginTop: "20px" }}>
                    <form>
                        <FormControl label={`${localize("Omslagsbild")}`}>
                            {!state.unitModel.coverArtUrl && (
                                <PrimaryButton
                                    onClick={() => {
                                        setState((s) => ({ ...s, showCoverImageSelector: true }));
                                    }}
                                >
                                    {localize("ValjBild")}
                                </PrimaryButton>
                            )}
                        </FormControl>
                    </form>
                    {state.unitModel.coverArtUrl && (
                        <>
                            <Image style={{ maxWidth: "100%" }} src={state.unitModel.coverArtUrl} className="cover-image" />
                            <form>
                                <FormControl>
                                    <DefaultButton
                                        style={{ marginTop: Style.getSpacingStr(3) }}
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                unitModel: {
                                                    ...prevState.unitModel,
                                                    coverArtUrl: "",
                                                    coverArt: "",
                                                    removeCover: true,
                                                },
                                            }));

                                        }}
                                    >
                                        {localize("TaBortBild")}
                                    </DefaultButton>
                                </FormControl>
                            </form>
                        </>
                    )}
                    {showCoverImageSelector && (
                        <FileSelector
                            renderSizeString={() => {
                                return (
                                    `${localize(
                                        "OptimalBildStorlek"
                                    )}: 1300x215px <br />`
                                )
                            }}
                            cropImage
                            cropAspect={1300 / 215}
                            onClose={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    showCoverImageSelector: false,
                                }));
                            }}
                            onSelect={(data) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    showCoverImageSelector: false,
                                    unitModel: {
                                        ...prevState.unitModel,
                                        coverArtUrl: data[0].thumbnailUrl,
                                        coverArt: data[0].ticket,
                                        removeCover: false,
                                    },
                                }));
                            }}
                            allowMultipleFiles={false}
                            fileTypesString={"image/png, image/jpeg"}
                            fileTypes={["png", "jpeg", "jpg"]}
                            sourcesToDisplay={[0]}
                            fileUploadType={1}
                        />
                    )}
                </Stack>
                <form>
                    {appState.displayCustomLinkSSO && (
                        <FormControl>
                            <TextField
                                label={localize("DepartmentCustomLink")}
                                value={state.unitModel.customLinkSSO}
                                name="customLinkSSO"
                                onChange={onChangeForm}
                            />
                        </FormControl>
                    )}
                    {appState.displayIosoftKeyInput && (
                        <FormControl>
                            <TextField
                                label={"IOSoft Key"}
                                value={state.unitModel.iosoftKey}
                                name="iosoftKey"
                                onChange={onChangeForm}
                            />
                        </FormControl>
                    )}
                    {appState.displaySkyltdirektSSOInputs && (
                        <div>
                            <FormControl>
                                <TextField
                                    label={"Skyltdirekt " + localize("Anvandarnamn").toLowerCase()}
                                    value={state.unitModel.skyltdirektSSOUsername}
                                    name="skyltdirektSSOUsername"
                                    onChange={onChangeForm}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    label={"Skyltdirekt " + localize("Losenord").toLowerCase()}
                                    value={state.unitModel.skyltdirektSSOPassword}
                                    name="skyltdirektSSOPassword"
                                    onChange={onChangeForm}
                                />
                            </FormControl>
                        </div>
                    )}
                    {appState.displayDirektenIdInput && (
                        <FormControl>
                            <TextField
                                label={localize("ExterntId")}
                                value={state.unitModel.direktenId}
                                name="direktenId"
                                onChange={onChangeForm}
                            />
                        </FormControl>
                    )}
                </form>
                <FormFooterBar
                    onCancelClick={onCancelClick}
                    onSaveClick={onSaveClick}
                    saveText={isNew ? localize("Skapa") : localize("Spara")}
                />
            </div>
        </ContentWithSubmenu>
    );
};

export default OrganisationUnitEditView;
