import api from "src/spintr/SpintrApi";

export interface INewsQueryParams {
    targets?: number;
    columnId?: string;
    currentColumn?: boolean;
    getUserDeletedOnly?: boolean;
    getUserDraftsOnly?: boolean;
    getUserOldOnly?: boolean;
    getUserPublishedOnly?: boolean;
    getAdminDeletedOnly?: boolean;
    getAdminDraftsOnly?: boolean;
    getAdminOldOnly?: boolean;
    getAdminPublishedOnly?: boolean;
    isAscending?: boolean;
    searchLetter?: string;
    searchQuery?: string;
    skip?: number;
    take?: number;
    tags?: number;
    newsCategory?: number;
    getTopNews?: boolean;
}

export const loadNewsFeed = (params: INewsQueryParams = {}) =>
    // `news?columnId=publishDate&currentColumn=false&getUserDeletedOnly=false&getUserDraftsOnly=false&get`
    // FIXME: <Spintr.INewsEnvelope> type this request?
    api.get("/api/v1/news", { params });
