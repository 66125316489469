import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { IMessage, MessageRole } from "src/assistant/types";
import { localize } from "src/l10n";
import { Label, SpintrUser } from "src/ui";
import { capitalizeFirstLetter } from "src/utils";
import AssistantMessageText from "./AssistantMessageText";
import AssistantMessageCitations from "./AssistantMessageCitations";

interface IProps {
    message: IMessage;
    senderName: string;
    senderImage: string;
    activeSubjectDate: Date;
}

const AssistantMessage: FunctionComponent<IProps> = ({ message, senderName, senderImage, activeSubjectDate }) => {
    console.log(JSON.stringify({
        ...message,
        citations: [],
        activeSubjectDate,
        inactive: message.date < activeSubjectDate
    }));

    return (
        <div className={classNames("AssistantMessage", {
            user: message.role === MessageRole.User,
            assistant: message.role === MessageRole.Assistant,
            inactive: message.date < activeSubjectDate
        })}>
            <div className="image">
                <SpintrUser
                    hideText={true}
                    imageUrl={senderImage}
                    name={senderName}
                    size={40}
                    personalName={true}
                />
            </div>
            <div
                className="text"
            >
                <div className="text-wrapper">
                    <AssistantMessageText message={message} />
                    <AssistantMessageCitations message={message} />
                </div>
            </div>
        </div>
    );
}

export default AssistantMessage;
