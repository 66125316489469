export const FETCH_BLOGS = 'FETCH_BLOGS'
export const FETCH_BLOGS_PENDING = 'FETCH_BLOGS_PENDING'
export const FETCH_BLOGS_REJECTED = 'FETCH_BLOGS_REJECTED'
export const FETCH_BLOGS_FULFILLED = 'FETCH_BLOGS_FULFILLED'

export const FIND_BLOG = 'FIND_BLOG'
export const FIND_BLOG_PENDING = 'FIND_BLOG_PENDING'
export const FIND_BLOG_REJECTED = 'FIND_BLOG_REJECTED'
export const FIND_BLOG_FULFILLED = 'FIND_BLOG_FULFILLED'

export const FETCH_BLOG_POSTS = 'FETCH_BLOG_POSTS'
export const FETCH_BLOG_POSTS_PENDING = 'FETCH_BLOG_POSTS_PENDING'
export const FETCH_BLOG_POSTS_REJECTED = 'FETCH_BLOG_POSTS_REJECTED'
export const FETCH_BLOG_POSTS_FULFILLED = 'FETCH_BLOG_POSTS_FULFILLED'

export const FIND_BLOG_POST = 'FIND_BLOG_POST'
export const FIND_BLOG_POST_PENDING = 'FIND_BLOG_POST_PENDING'
export const FIND_BLOG_POST_REJECTED = 'FIND_BLOG_POST_REJECTED'
export const FIND_BLOG_POST_FULFILLED = 'FIND_BLOG_POST_FULFILLED'
