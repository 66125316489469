import classNames from "classnames";
import React, { FunctionComponent, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Color from "src/style/colors/Color";

import { AssistantInput } from "../AssistantInput";
import { AssistantMessages } from "../AssistantMessages";
import { Label, UnstyledButton } from "src/ui";
import { Icon } from "@fluentui/react";
import { localize, localizeFormat } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { setActiveSubjectDate, setActiveSubjects } from "src/assistant/actions";

interface IProps {
    assistantName: string;
    assistantImage?: string;

    onClose?: () => void;
    activeSubjects?: string[];
    activeSubjectDate?: Date;
}

interface IStateProps {
    companyName: string;
    isLoading: boolean;
    profileColor: string;
    showIntroduction: boolean;
    userName: string;
    userImage: string;
}

const formatColor = (color: string) => {
    if (!color) {
        return "EAEAEA";
    }

    if (color.length === 4) {
        color += color.substring(1);
    }

    return color.toUpperCase();
};

const AssistantWindow: FunctionComponent<IProps> = ({ assistantImage, assistantName, onClose, activeSubjects, activeSubjectDate }) => {
    const dispatch = useDispatch();

    const stateProps = useSelector<Spintr.AppState, IStateProps>(
        (appState) => ({
            showIntroduction: appState.assistant.messages.length === 0,
            isLoading: appState.assistant.isLoadingResponse,
            companyName: appState.instance.get("instance").name,
            profileColor: appState.instance.get("theme").useColorHeader
                ? formatColor(appState.instance.get("theme").accentColor)
                : formatColor(appState.instance.get("theme").primaryColor),
            userName: appState.profile.active.name.split(' ').slice(0, 1).join(""),
            userImage: appState.profile.active.images.Message,
        }),
    );

    return (
        <div className={classNames("AssistantWindow", {
            "with-introduction": stateProps.showIntroduction,
        })}>
            <div
                className="body"
            >
                <AssistantMessages
                    assistantName={assistantName}
                    assistantImage={assistantImage} // TODO: Add later if needed
                    companyName={stateProps.companyName}
                    userImage={stateProps.userImage}
                    userName={stateProps.userName}
                    activeSubjectDate={activeSubjectDate}
                />
            </div>
            <AssistantInput
                assistantName={assistantName}
                disabled={stateProps.isLoading}
            />
        </div>
    )
};

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        activeSubjects: state.assistant.activeSubjects,
        activeSubjectDate: state.assistant.activeSubjectDate
    };
};

export default connect(mapStateToProps)(AssistantWindow);
