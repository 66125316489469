import React, { Component, ReactNode, useState } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { Style } from "src/ui/helpers";
import { decodeHtmlEntities } from "src/utils";
import { UnstyledButton } from "../Buttons";
import "./SimpleFile.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    file: any;
    onRemove?: any;
}

const SimpleFile = (props: IProps) => {
    return (
        <div className="SimpleFile">
            <div>
                <Label size="small-2" color="grey" uppercase>{localize("Fil")}</Label>
                <Label as="p" size="body-2">
                    {decodeHtmlEntities(props.file.name)}
                </Label>
            </div>
            {
                props.onRemove && (
                    <UnstyledButton onClick={props.onRemove}>
                        <Visage2Icon icon="trash" />
                    </UnstyledButton>
                )
            }
        </div>
    )
}

export default SimpleFile;