import React from "react";
import { IAcademyUserProgress } from "src/academy/types";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import "./AcademyCourseProgress.scss";

interface IProps {
    userProgress: IAcademyUserProgress;
}

const AcademyCourseProgress = (props: IProps) => {
    return (
        <div className={[
            "AcademyCourseProgress",
            props.userProgress.isCompleted ? "completed" : "in-progress"
        ].join(" ")}>
            <div className="AcademyCourseProgress-meter">
                <div className="AcademyCourseProgress-meter-progress" style={{
                    width: props.userProgress.completionPercent + "%"
                }} />
            </div>
            <Label color="dark-grey" size="body-2">{localize("COMPLETION_PERCENT").replace("{0}", props.userProgress.completionPercent.toString())}</Label>
        </div>
    )
}

export default AcademyCourseProgress;
