import { Pivot, PivotItem } from "@fluentui/react";
import classNames from "classnames";
import React, { ComponentType, FunctionComponent, ReactElement, useMemo } from "react";
import RightColumnDashboard from "src/admin/views/AdminDashboard/RightColumnDashboard";
import {
    BirthdaysWrapper,
    BlogBoxesWrapper,
    DataWidgetsWrapper,
    InformationFeedWrapper,
    OperatingInfoWrapper,
    SocialFeedWrapper,
    SpotlightNewsWrapper,
    TasksWrapper,
    TeaserBoxesWrapper,
} from "./Wrappers";

interface IProps {
    rows: Spintr.IStartPageRow[];
    responsive?: boolean;
}

const componentMap: { [key: string]: ComponentType<any> } = {
    Birthdays: BirthdaysWrapper,
    BlogBoxes: BlogBoxesWrapper,
    Dashboard: RightColumnDashboard,
    DataBoxes: DataWidgetsWrapper,
    NewsFeed: InformationFeedWrapper,
    OperatingInfo: OperatingInfoWrapper,
    SocialFeed: SocialFeedWrapper,
    SpotlightNews: SpotlightNewsWrapper,
    Todo: TasksWrapper,
    TeaserBoxes: TeaserBoxesWrapper,
};

const StartPageView: FunctionComponent<IProps> = ({ rows, responsive }) => {
    const renderCell = useMemo(
        () => (item: Spintr.IStartPageComponent): ReactElement | null => {
            const Component = componentMap[item.componentKey];
            if (!Component) {
                return null;
            }

            return (
                <div className="StartPageCell" key={item.id}>
                    <Component props={item.props} />
                </div>
            );
        },
        [],
    );

    const renderColumn = useMemo(
        () => (column: Spintr.IStartPageColumn) => (
            <div
                className="StartPageColumn"
                key={column.id}
                style={responsive ? undefined : {
                    flexBasis: !column.width
                        ? undefined
                        : `${column.width}%`,
                    width: !column.width
                        ? undefined
                        : `${column.width}%`,
                }}
            >
                {column.items.map(renderCell)}
            </div>
        ),
        [renderCell, responsive],
    );
    
    if (responsive) {
        // Maybe we should only use pivot if any of the components 
        // contained in it use infinite scroll
        return (
            <div className={classNames("StartPageView", {
                "responsive": responsive,
            })}>
                {rows.map((row) => row.columns.length < 2
                    ? (
                        <div className="StartPageRow" key={row.id}>
                            {row.columns.map(renderColumn)}
                        </div>
                    )
                    : (
                        <div className="StartPageRow" key={row.id}>
                            <Pivot
                                className="SpintrStartViewResponsivePivot"
                                defaultSelectedKey={
                                    row.columns.map((c) => c.id)[0]
                                }
                            >
                                {row.columns.map((column) => (
                                    <PivotItem
                                        headerText={column.name}
                                        key={column.id}
                                    >
                                        {renderColumn(column)}
                                    </PivotItem>
                                ))}
                            </Pivot>
                        </div>
                    )
                )}
            </div>
        )
    }

    return (
        <div className="StartPageView">
            {rows.map((row) => (
                <div className="StartPageRow" key={row.id}>
                    {row.columns.map(renderColumn)}
                </div>
            ))}
        </div>
    );
}

export default StartPageView;
