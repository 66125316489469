import {
    Checkbox,
    ChoiceGroup, Image, Modal,
    PrimaryButton
} from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Label, Loader } from "src/ui";
import { FormControl } from "src/ui/components/Forms";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { Style } from "src/ui/helpers";
import "./OnboardingPopupView.scss";
import api from "src/spintr/SpintrApi";
import { getActiveUser } from "src/profile/actions";
import { connect } from "react-redux";

interface Props {
    unseen: any[];
    dispatch: any;
    callback: any;
}

interface State {
    isLoading: boolean;
    isOpen: boolean;
    index: number;
    currentPage: any;
    pages: any[];
}

class OnboardingPopupView extends Component<Props, State> {
    state = {
        isLoading: false,
        isOpen: true,
        index: 0,
        currentPage: undefined,
        pages: [],
    };

    componentDidMount = () => {
        let currentPage = this.props.unseen[this.state.index];

        this.setState({
            currentPage,
            pages: this.props.unseen
        });
    };

    accept = () => {
        let pages = this.state.pages;

        pages[this.state.index] = this.state.currentPage;

        this.setState({
            pages
        }, () => {
            api.put(`/api/onboarding/pages/setasread`, this.state.pages).then(_response => {
                this.props.dispatch(getActiveUser());
            });

            this.setState({
                isOpen: false
            }, () => {
                this.props.callback();
            });
        });
    };

    page = (page) => {
        const { index } = this.state;

        return (
            <>
                <Label as="p" size="body-2" className="page-number">
                    {localize("Steg")} {index + 1} / {this.props.unseen.length}
                </Label>
                <Label as="h1" size="h2">
                    {page.title}
                </Label>

                {page.imageUrl && <Image src={page.imageUrl} style={{ width: "800px" }} />}
                <Label as="p" size="body-4">
                    <TinyFormattedContent content={page.text} />
                </Label>
                {page.userMustAccept && !page.isPolarQuestion && (
                    <FormControl>
                        <Checkbox
                            key={page.id}
                            label={
                                page.acceptText && page.acceptText.length > 0 ? page.acceptText : localize("I_ACCEPT")
                            }
                            checked={page.accepted}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    currentPage: {
                                        ...this.state.currentPage,
                                        accepted: checked,
                                    },
                                });
                            }}
                        />
                    </FormControl>
                )}

                {page.userMustAccept && page.isPolarQuestion && (
                    <FormControl>
                        <ChoiceGroup
                            key={page.id}
                            options={[
                                { key: "yes", value: 2, text: localize("Ja") },
                                { key: "no", value: 3, text: localize("Nej") },
                            ]}
                            required={true}
                            aria-required={true}
                            onChange={(ev?, option?) => {
                                this.setState({
                                    currentPage: {
                                        ...this.state.currentPage,
                                        answer: option.value,
                                    },
                                });
                            }}
                        />
                    </FormControl>
                )}
            </>
        );
    };

    renderButton(page, lastPage) {
        return !lastPage ? (
            <PrimaryButton
                disabled={
                    (page.userMustAccept && page.isPolarQuestion && !page.answer) ||
                    (page.userMustAccept && !page.isPolarQuestion && !page.accepted)
                }
                onClick={() => {
                    let pages = this.state.pages;
                    pages[this.state.index] = this.state.currentPage;
                    this.setState((state) => ({
                        index: state.index + 1,
                        currentPage: this.props.unseen[this.state.index + 1],
                    }));
                }}
            >
                {localize("Nasta")}
            </PrimaryButton>
        ) : (
            <PrimaryButton
                disabled={
                    (page.userMustAccept && page.isPolarQuestion && !page.answer) ||
                    (page.userMustAccept && !page.isPolarQuestion && !page.accepted)
                }
                onClick={this.accept}
            >
                {localize("Klar")}
            </PrimaryButton>
        );
    }

    render() {
        const { isOpen, index } = this.state;
        const lastPage = index === this.props.unseen.length - 1;
        const { currentPage } = this.state;
        if (!currentPage) return <Loader />;

        return (
            <Modal isOpen={isOpen}>
                <div
                    className="onboarding-popup-component"
                    style={{
                        backgroundColor: "#FFF",
                        width: "636px",
                        padding: Style.getSpacingStr(6),
                    }}
                >
                    {this.page(currentPage)}
                    {this.renderButton(currentPage, lastPage)}
                </div>
            </Modal>
        );
    }
}

export default connect()(OnboardingPopupView);
