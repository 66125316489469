import { produce } from "immer";

import { SET_ZOHO_POPUP } from "./zoho-action-types";

export interface IZohoPopupState {
    isOpen: boolean;
    id: number;
    version: number;
    sizeErrorOpen: boolean;
    size: 0;
}

const initialState: IZohoPopupState = {
    isOpen: false,
    sizeErrorOpen: false,
    id: 0,
    version: 0,
    size: 0,
};

const ZohoPopupReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_ZOHO_POPUP:
                if (action.size > 10 * 1024) {
                    draft.sizeErrorOpen = true;
                    return;
                } else {
                    draft.sizeErrorOpen = false;
                }

                draft.isOpen = action.isOpen;
                draft.id = action.id;
                draft.version = action.version;

                break;
            default:
                return state;
        }
    });

export default ZohoPopupReducer;
