import { DirectionalHint, TeachingBubble } from '@fluentui/react';
import React, { Component, RefObject } from 'react';
import { localize } from 'src/l10n';
import { Label } from "src/ui";
import "./SpintrCoachmark.scss";
import { IApplicationState } from 'src/spintr/reducer';
import { connect } from 'react-redux';

interface IProps {
    refDiv: RefObject<HTMLDivElement>,
    text: string,
    coachmarkId?: string
    refIsInHeader?: boolean;
    formFooterBarTop?: boolean;
}

interface IState {
    isCoachmarkVisible: boolean;
}

class SpintrCoachmark extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isCoachmarkVisible: false
        }
    }

    markAsSeen() {
        if (!this.props.coachmarkId) {
            return;
        }

        const localStorageId = "spintr_seenCoachmarks";

        let oldData = localStorage.getItem(localStorageId);
        let newData = [this.props.coachmarkId];

        if (oldData) {
            let formattedOldData = JSON.parse(oldData);

            if (formattedOldData.indexOf(this.props.coachmarkId) > -1) {
                return;
            }

            newData = [
                ...formattedOldData,
                ...newData
            ];
        }

        localStorage.setItem(localStorageId, JSON.stringify(newData));
    }

    componentDidMount() {
        if (this.props.coachmarkId) {
            const localStorageId = "spintr_seenCoachmarks";

            let data = localStorage.getItem(localStorageId);

            if (data) {
                let formattedData = JSON.parse(data);

                if (formattedData.indexOf(this.props.coachmarkId) > -1) {
                    return;
                }
            }
        }

        this.showCoachmark();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.refIsInHeader &&
            this.state.isCoachmarkVisible &&
            this.props.formFooterBarTop &&
            !prevProps.formFooterBarTop) {
            this.hideCoachmark();
        }
    }

    showCoachmark() {
        if (!this.props.refIsInHeader ||
            !this.props.formFooterBarTop) {
            setTimeout(() => {
                this.setState({
                    isCoachmarkVisible: true
                });
            }, 1000);
        }
    }

    hideCoachmark() {
        this.setState({
            isCoachmarkVisible: false
        });
    }

    render() {
        if (!this.state.isCoachmarkVisible ||
            !this.props.refDiv ||
            !this.props.refDiv.current) {
            return null;
        }

        return (
            <TeachingBubble
                target={this.props.refDiv.current}
                headline={localize("COACHMARK_HEADLINE")}
                focusTrapZoneProps={{
                    isClickableOutsideFocusTrap: true,
                    disableFirstFocus: true,
                    forceFocusInsideTrap: false
                }}
                calloutProps={{
                    shouldDismissOnWindowFocus: false,
                    className: "SpintrCoachmark",
                    calloutMaxWidth: 300,
                    calloutWidth: 300,
                    calloutMinWidth: 300,
                    directionalHint: DirectionalHint.bottomCenter,
                    gapSpace: 20,
                    beakWidth: 10,
                    preventDismissOnEvent: (ev: Event | React.FocusEvent<Element> | React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
                        if (ev.type === "click" || ev.type === "focus") {
                            return true;
                        }

                        return false;
                    }
                }}
                primaryButtonProps={{
                    text: localize("Ok"),
                    onClick: () => {
                        this.markAsSeen();
                        this.hideCoachmark();
                    }
                }}
                onDismiss={() => {
                    this.hideCoachmark();
                }}
            >
                <Label size="body-2">
                    {
                        this.props.text
                    }
                </Label>
            </TeachingBubble>
        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    formFooterBarTop: state.ui.formFooterBarTop
});

export default connect(mapStateToProps)(SpintrCoachmark);
