import { RightColumnActionTypes as ActionTypes} from "./action-types";

export interface IRightColumnState {
    items: any[];
    hasFetchedItems: boolean;
}

const initialState: IRightColumnState = {
    items: [],
    hasFetchedItems: false
};


const rightColumnmReducer = (state: IRightColumnState = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.SetRightColumns:
            return {
                ...state,
                items: [...action.items],
                hasFetchedItems: true
            };
        case ActionTypes.ClearRightColumns:
            return {
                ...state,
                items: [],
                hasFetchedItems: false
            }
        default:
            return state;
    }
}

export default rightColumnmReducer;
