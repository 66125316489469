import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { getTypeName, localize } from "src/l10n";
import { capitalizeFirstLetter } from "src/utils";

interface IProps {
    notification: Spintr.INotification;
}

const ReachNotificationText: FunctionComponent<IProps> = (props) =>{
    const notification = props.notification as Spintr.IReachNotification;
    if (!notification) {
        return null;
    }
    


    return (
        <Fragment>
            {capitalizeFirstLetter(getTypeName(notification.objectType, {
                case: "definite"
            })) + " "}
            <Link to={notification.url}>
                {notification.objectName}
            </Link>
            {" " + localize("HarNattXLasningar")
                .replace("{{count}}", notification.count.toString())}
        </Fragment>
    );
};

export default ReachNotificationText;
