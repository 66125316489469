import {
    DefaultButton,
    PrimaryButton, TextField
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import "src/groups/views/planning/GroupPlanningView.scss";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { validateRequiredTextField } from "src/utils";

interface IProps {
    text?: any;
    onSave?: any;
    onCancel?: any;
    onDelete?: any
    groupId: number;
    dispatch?: any;
}

class PlanningCardEditor extends Component<IProps> {
    state = {
        text: this.props.text || ""
    };

    handleChangeText = event => this.setState({
        text: event.target.value
    });

    onEnter = e => {
        const { text } = this.state;

        if (e.keyCode === 13) {
            e.preventDefault();
            this.props.onSave(text);
        }
    };

    cancel() {
        let fn = () => {
            this.props.onCancel();
        }

        if (this.state.text &&
            this.state.text.length > 0) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: fn
            }));
        } else {
            fn();
        }
    }

    render() {
        return (
            <div className="Planning-EditCard">
                <form>
                    <TextField
                        className="textField"
                        value={this.state.text}
                        onChange={this.handleChangeText}
                        placeholder={localize("Titel")}
                        onKeyDown={this.onEnter}
                        maxLength={1024}
                        autoFocus={true}
                        required
                        aria-required
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateRequiredTextField}
                    />
                    <div className="Planning-EditCardFooter">
                        <DefaultButton onClick={this.cancel.bind(this)}>
                            {
                                localize("Avbryt")
                            }
                        </DefaultButton>
                        <PrimaryButton className="Planning-EditCardSaveButton" onClick={() => this.props.onSave(this.state.text)}>
                            {
                                localize("SkapaKort")
                            }
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        );
    }
}

export default connect()(PlanningCardEditor);
