import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { getTypeName, localize } from 'src/l10n';
import { getUserPart } from "../utils";

interface IProps {
    notification: any;
}

const LikeNotificationText: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.ILikeNotification;
    if (!notification) {
        return;
    }

    const typeText = getTypeName(notification.objectType, {
        alternative: (
            [16,17].indexOf(notification.objectType) > -1 && 
            notification.feedTypeId === 3
        ),
        case: "possessive"
    });

    return (
        <Fragment>
            {getUserPart(notification) + " "}
            {localize("HarGillat")+" "}
            {notification.objectName && notification.objectName.length > 0 && (
                <Fragment>
                    {typeText + " "}
                    <Link to={notification.url}>
                        {notification.objectName}
                    </Link>
                </Fragment>
            )}
            {(!notification.objectName || notification.objectName.length === 0) && (
                typeText
            )}.
        </Fragment>
    );
}

export default LikeNotificationText;
