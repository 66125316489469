import { TooltipHost } from "@fluentui/react";
import React from "react";
import { localize } from "src/l10n";
import { UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    onClose: any;
}

const ModalCloseButton = (props: IProps) => {
    return (
        <div className="modal-close-button" style={{ display: "flex", flexDirection: "row-reverse" }}>
            <UnstyledButton
                ariaLabel={localize("Stang")}
                onClick={props.onClose}
            >
                <TooltipHost content={localize("Stang")}>
                    <Visage2Icon style={{verticalAlign: "bottom"}} icon="close-circle" />
                </TooltipHost>
            </UnstyledButton>
        </div>
    );
};

export default ModalCloseButton;
