import { Pivot, PivotItem } from "@fluentui/react";
import { DayOfWeek, Dropdown, IDropdownOption, IStackProps, Stack, TextField } from "@fluentui/react";
import { DatePicker } from "@fluentui/react/lib/components/DatePicker/DatePicker";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Loader } from "src/ui";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { validateRequiredTextField } from "src/utils";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import api from "src/spintr/SpintrApi";

interface IProps {
    item: any;
    categories: any;
    onSaved: any;
    onCancel: any;
    showFormFooterBar?: boolean;
    pageId?: any;
}

interface IState {
    item: any;
    isLoading: boolean;
    enableFieldValidation?: boolean;
    saveError?: any;
}

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 500 } },
};

class DeviationsForm extends Component<IProps, IState> {
    state = {
        item: {} as any,
        isLoading: true,
        enableFieldValidation: false,
        saveError: null
    };

    componentDidMount = () => {
        this.setState({
            item: {
                ...this.props.item,
                date: this.props.item.date ?
                    this.props.item.date :
                    new Date(),
                category: this.props.item.category ?
                    this.props.item.category :
                    (this.props.categories && this.props.categories.length > 0) ?
                        this.props.categories[0] :
                        null
            }
        }, () => {
            this.setState({
                isLoading: false
            });
        });
    };

    render() {
        const { item, isLoading } = this.state;
        const { showFormFooterBar } = this.props;

        if (isLoading) return <Loader />;

        return (
            <div className="DeviationsForm">
                <Stack horizontal tokens={{ childrenGap: 50 }} styles={{ root: { width: 650 } }}>
                    <Stack {...columnProps}>
                        {this.state.saveError && this.state.saveError.length > 0 && (
                            <ErrorMessagebar
                                errorList={this.state.saveError}
                                onDismiss={() => {
                                    this.setState({
                                        saveError: [],
                                    });
                                }}
                            />
                        )}

                        <form>
                            <FormSection>
                                <FormControl>
                                    <DatePicker
                                        label={localize("Fran")}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        strings={getDatePickerLanguageStrings()}
                                        formatDate={formatDatePickerDate}
                                        placeholder={localize("ValjDatum")}
                                        ariaLabel={localize("ValjDatum")}
                                        value={item.date}
                                        isRequired={true}
                                        aria-required={true}
                                        onSelectDate={(date: Date) => {
                                            this.setState((state) => ({
                                                item: {
                                                    ...state.item,
                                                    date,
                                                },
                                            }));
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Dropdown
                                        label={localize("Kategori")}
                                        ariaLabel={localize("Kategori")}
                                        title={localize("Kategori")}
                                        selectedKey={item.category && item.category.id && item.category.id}
                                        onChange={(event: React.FormEvent<HTMLDivElement>, i: IDropdownOption) => {
                                            this.setState((state) => ({
                                                item: { ...state.item, category: { id: i.key } },
                                            }));
                                        }}
                                        options={this.props.categories}
                                    />
                                </FormControl>

                            </FormSection>
                            <FormControl>
                                <TextField
                                    label={localize("Titel")}
                                    name="title"
                                    value={item.title}
                                    onChange={(_e, title) => {
                                        this.setState((state) => ({ item: { ...state.item, title } }));
                                    }}
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>

                            <Pivot overflowBehavior={"menu"}>
                                <PivotItem headerText={localize("Avvikelse")}>
                                    <FormControl>
                                        <TextField
                                            label={localize("BeskrivningAvAvvikelsen") + ` (${localize("EjPaborjad")})`}
                                            value={item.step1}
                                            onChange={(_e, step1) => {
                                                this.setState((state) => ({ item: { ...state.item, step1 } }));
                                            }}
                                            placeholder={localize("MSystemStep1")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("VadHarAtgardats") + ` (${localize("Paborjad")})`}
                                            value={item.step2}
                                            onChange={(_e, step2) => {
                                                this.setState((state) => ({ item: { ...state.item, step2 } }));
                                            }}
                                            placeholder={localize("MSystemStep2")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("HurForhindrarVi") + ` (${localize("Beslutad")})`}
                                            value={item.step3}
                                            onChange={(_e, step3) => {
                                                this.setState((state) => ({ item: { ...state.item, step3 } }));
                                            }}
                                            placeholder={localize("MSystemStep3")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("Uppfoljning") + ` (${localize("Genomford")})`}
                                            value={item.step4}
                                            onChange={(_e, step4) => {
                                                this.setState((state) => ({ item: { ...state.item, step4 } }));
                                            }}
                                            placeholder={localize("MSystemStep4")}
                                            multiline={true}
                                        />
                                    </FormControl>

                                </PivotItem>
                                <PivotItem headerText={localize("Handelseanalys")}>
                                    <FormControl>
                                        <TextField
                                            label={localize("InitieraHandelseanalys")}
                                            value={item.action1}
                                            onChange={(_e, action1) => {
                                                this.setState((state) => ({ item: { ...state.item, action1 } }));
                                            }}
                                            placeholder={localize("MsystemAction1")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("BakomliggandeOrsaker")}
                                            value={item.action2}
                                            onChange={(_e, action2) => {
                                                this.setState((state) => ({ item: { ...state.item, action2 } }));
                                            }}
                                            placeholder={localize("MsystemAction2")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("Handelseforlopp")}
                                            value={item.action3}
                                            onChange={(_e, action3) => {
                                                this.setState((state) => ({ item: { ...state.item, action3 } }));
                                            }}
                                            placeholder={localize("MsystemAction3")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("IdentifieraBakomliggande")}
                                            value={item.action4}
                                            onChange={(_e, action4) => {
                                                this.setState((state) => ({ item: { ...state.item, action4 } }));
                                            }}
                                            placeholder={localize("MsystemAction4")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("ForeslaAtgarder")}
                                            value={item.action5}
                                            onChange={(_e, action5) => {
                                                this.setState((state) => ({ item: { ...state.item, action5 } }));
                                            }}
                                            placeholder={localize("MsystemAction5")}
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            label={localize("Riskbedomning")}
                                            value={item.action6}
                                            onChange={(_e, action6) => {
                                                this.setState((state) => ({ item: { ...state.item, action6 } }));
                                            }}
                                            placeholder={localize("MsystemAction6")}
                                            multiline={true}
                                        />
                                    </FormControl>

                                </PivotItem>
                            </Pivot>
                        </form>
                    </Stack>
                </Stack>
                {showFormFooterBar && (
                    <FormFooterBar
                        onCancelClick={() => {
                            this.props.onCancel();
                        }}
                        onSaveClick={() => {
                            let payload = {
                                ...item,
                                pageId: this.props.pageId
                            }

                            this.setState({
                                enableFieldValidation: true,
                                isLoading: true
                            }, () => {
                                api.post("/api/v1/msystem/deviation", payload).then(this.props.onSaved).catch(this.handleCatch.bind(this));
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };
}

export default DeviationsForm;
