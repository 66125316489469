export enum SpintrActionTypes {
    AddToFavorite = "SPINTR_ADD_FAVORITE",
    AddToFavoriteFulfilled = "SPINTR_ADD_FAVORITE_FULFILLED",
    AddToFavoritePending = "SPINTR_ADD_FAVORITE_PENDING",
    AddToFavoriteRejected = "SPINTR_ADD_FAVORITE_REJECTED",

    RemoveFromFavorites = "SPINTR_REMOVE_FAVORITE",
    RemoveFromFavoritesFulfilled = "SPINTR_REMOVE_FAVORITE_FULFILLED",
    RemoveFromFavoritesPending = "SPINTR_REMOVE_FAVORITE_PENDING",
    RemoveFromFavoritesRejected = "SPINTR_REMOVE_FAVORITE_REJRECTED",

    StartFollowingObject = "SPINTR_ADD_OBJECT_FOLLOW",
    StartFollowingObjectFulfilled = "SPINTR_ADD_OBJECT_FOLLOW_FULFILLED",
    StartFollowingObjectPending = "SPINTR_ADD_OBJECT_FOLLOW_PENDING",
    StartFollowingObjectRejected = "SPINTR_ADD_OBJECT_FOLLOW_REJECTED",

    StopFollowingObject = "SPINTR_REMOVE_OBJECT_FOLLOW",
    StopFollowingObjectFulfilled = "SPINTR_REMOVE_OBJECT_FOLLOW_FULFILLED",
    StopFollowingObjectPending = "SPINTR_REMOVE_OBJECT_FOLLOW_PENDING",
    StopFollowingObjectRejected = "SPINTR_REMOVE_OBJECT_FOLLOW_REJECTED",

    DeleteObject = "SPINTR_DELETE_OBJECT",
    DeleteObjectFulfilled = "SPINTR_DELETE_OBJECT_FULFILLED",
    DeleteObjectPending = "SPINTR_DELETE_OBJECT_PENDING",
    DeleteObjectRejected = "SPINTR_DELETE_OBJECT_REJECTED",

    PinObject = "SPINTR_PIN_OBJECT",
    PinObjectFulfilled = "SPINTR_PIN_OBJECT_FULFILLED",
    PinObjectPending = "SPINTR_PIN_OBJECT_PENDING",
    PinObjectRejected = "SPINTR_PIN_OBJECT_REJECTED",

    HideObject = "SPINTR_HIDE_OBJECT",
    HideObjectFulfilled = "SPINTR_HIDE_OBJECT_FULFILLED",
    HideObjectPending = "SPINTR_HIDE_OBJECT_PENDING",
    HideObjectRejected = "SPINTR_HIDE_OBJECT_REJECTED",
}
