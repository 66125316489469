import api from 'src/spintr/SpintrApi'
import {
    FETCH_INSTANCE_BASE,
    SET_INSTANCE_PROPERTY
} from './action-types'

export const getInstance = () => ({
    type: FETCH_INSTANCE_BASE,
    payload: api.get('/api/v1/instances/this')
})

export const updateInstanceProperty = (key: string, value: any) => ({
    type: SET_INSTANCE_PROPERTY,
    key,
    value,
});

const InstanceActions = {
    getInstance: getInstance
}

export default InstanceActions