import { Component } from "react";

/**
 * An abstract base class for gathering authentication data.
 */
abstract class AuthenticationDataComponent<TState = any, TProps = any> 
    extends Component<TState, TProps> {
    /**
     * Get's any authentication data gathered by the component.
     * 
     * @returns A JSON-string containing the authentication data gathered.
     */
    public abstract getAuthenticationData() : string;

    /**
     * Checks whether the authentication data gathered validates successfully.
     * 
     * @returns true if data is valid; otherwise false.
     */
    public abstract isValid() : boolean;
}

export default AuthenticationDataComponent;