import api from "src/spintr/SpintrApi";

import { FETCH_TEAMS, INCREMENT_TEAM_UNREAD, CLEAR_TEAM_UNREAD } from "./action-types";

export const fetchTeams = () => {
    return {
        payload: api.get("/api/groups/office365/teams"),
        type: FETCH_TEAMS,
    };
};

export const incrementTeamUnread = (teamId) => {
    return {
        meta: {
            teamId
        },
        type: INCREMENT_TEAM_UNREAD
    }
}

export const clearTeamUnread = (teamId) => {
    return {
        meta: {
            teamId
        },
        type: CLEAR_TEAM_UNREAD
    }
}