import React, { Component, ReactNode } from "react";
import { uniqueId } from "src/utils";
import FileUpload, { IFileUploadProps } from "./FileUpload";
import FormControl from "./FormControl";

export interface IFormFileUploadProps extends IFileUploadProps {
    label: string;
}

interface IFormFileUploadState {
    id: string;
}

class FormFileUpload extends Component<IFormFileUploadProps, IFormFileUploadState> {
    constructor(props : IFormFileUploadProps) {
        super(props);

        this.state = {
            id: uniqueId("FormSelect")
        };
    }

    public render() : ReactNode {
        const id : string = this.props.id || this.state.id;

        const { label, ...attrs } = this.props;

        return (
            <div className="FormSelect">
                <FormControl 
                    htmlFor={id}
                    label={this.props.label}
                >
                    <FileUpload 
                        id={id}
                        {...attrs}
                    />
                </FormControl>
            </div>
        )
    }
}

export default FormFileUpload;