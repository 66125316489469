import { useCallback, useEffect, useState } from 'react';
import { throttle } from 'src/ui/helpers';

interface IProps {
    onActivityAfterIdle: () => void;
    idleLimitMs: number;
}

const throttleMs = 10000;
const events = ["mousemove", "touchmove", "visibilitychange"];

const IdleChecker = (props: IProps) => {
    const [lastActivity, setLastActivity] = useState(new Date().getTime());

    const ev = useCallback(() => {
        const now = new Date().getTime();
        const diff = now - lastActivity;

        if (diff < throttleMs * 2) {
            return;
        }

        setLastActivity(now);

        if (diff > props.idleLimitMs) {
            props.onActivityAfterIdle();
        }
    }, [lastActivity, props.onActivityAfterIdle, setLastActivity]);

    const throttledEv = useCallback(throttle(ev, throttleMs, undefined), [ev]);

    useEffect(() => {
        for (const eventName of events) {
            window.addEventListener(eventName, throttledEv);
        }

        return () => {
            for (const eventName of events) {
                window.removeEventListener(eventName, throttledEv);
            }
        }
    }, [throttledEv]);

    return null;
}

export default IdleChecker;
