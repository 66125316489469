import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { replaceTinyContent } from "src/ui/components/Tiny/utils";
import { fixImageMaps } from "src/utils";
import PhotoBox from "../../PhotoBox/PhotoBox";
import UnsplashAttribution from "../../UnsplashAttribution";
import "./TinyFormattedContent.scss";

interface IProps extends RouteComponentProps {
    content: string;
    attachedFiles?: any;
    attachedFolders?: any;
    attachedSharePointListSearches?: any;
    language?: string;
    className?: string;
}

interface IState {
    photoBoxImageUrl?: string;
}

function CSSstring(string) {
    const css_json = `{"${string
        .replace(/; /g, '", "')
        .replace(/: /g, '": "')
        .replace(";", "")}"}`;

    let obj;
    try {
        obj = JSON.parse(css_json);
    } catch {
        return undefined
    }

    const keyValues = Object.keys(obj).map((key) => {
        var camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
        return { [camelCased]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}

class TinyFormattedContent extends PureComponent<IProps, IState> {
    private ran = false;
    private ref = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (!this.ran) {
            setTimeout(() => {
                this.ran = true;

                if (!this.ref.current) {
                    return;
                }

                const thisDOM = ReactDOM.findDOMNode(this);

                // @ts-ignore
                var imgTags = thisDOM.querySelectorAll("img");

                if (imgTags &&
                    imgTags.length > 0) {
                    for (const imgTag of imgTags) {
                        const isReact = !!Object.keys(imgTag).find((key) => key.startsWith("__reactInternalInstance$"));

                        if (isReact) {
                            continue;
                        }

                        // create parent that gets replaced later, to avoid the new element
                        // getting appended to the old <a>
                        const parent = document.createElement("div");

                        ReactDOM.render(
                            <>
                                <img
                                    src={imgTag.src}
                                    alt={imgTag.alt}
                                    height={imgTag.height}
                                    width={imgTag.width}
                                    onLoad={() => {
                                        if (imgTag.useMap) {
                                            fixImageMaps();
                                        }
                                    }}
                                    onClick={(e) => {
                                        if (
                                            imgTag.useMap ||
                                            e.currentTarget.parentElement.getAttribute("target") === "_blank"
                                        ) {
                                            return;
                                        }

                                        this.setState({
                                            photoBoxImageUrl: imgTag.src,
                                        });
                                    }}
                                    style={{
                                        float: imgTag.style.float,
                                        display: imgTag.style.display,
                                        marginLeft: imgTag.style["margin-left"],
                                        marginRight: imgTag.style["margin-right"],
                                        cursor: imgTag.useMap ? "default" : "pointer",
                                    }}
                                    //@ts-ignore
                                    useMap={imgTag.useMap}
                                />
                                {imgTag.dataset.externalAuthorName && (
                                    <UnsplashAttribution
                                        authorName={imgTag.dataset.externalAuthorName}
                                        authorUrl={imgTag.dataset.externalAuthorUrl}
                                    />
                                )}
                            </>,
                            parent,
                            () => {
                                imgTag.replaceWith(...Array.from(parent.childNodes));
                            }
                        );
                    }
                }

                // @ts-ignore
                var tables = thisDOM.querySelectorAll("table");

                for (const table of tables) {
                    const parent = document.createElement("div");

                    ReactDOM.render(
                        <div className="table-wrapper" dangerouslySetInnerHTML={{ __html: table.outerHTML }} />,
                        parent,
                        () => {
                            table.replaceWith(...Array.from(parent.childNodes));
                        }
                    );
                }

                // @ts-ignore
                var aLinks = thisDOM.querySelectorAll("a:not(.fixed)");

                if (aLinks.length === 0) {
                    return;
                }

                for (const aLink of aLinks) {
                    // ignore react components
                    const isReact = !!Object.keys(aLink).find((key) => key.startsWith("__reactInternalInstance$"));
                    if (isReact) {
                        continue;
                    }

                    // create parent that gets replaced later, to avoid the new element
                    // getting appended to the old <a>
                    const parent = document.createElement("div");
                    const newStyle = aLink.style?.cssText ? CSSstring(aLink.style.cssText) : undefined

                    ReactDOM.render(
                        <a
                            className="fixed underline"
                            href={aLink.href}
                            target={aLink.target}
                            title={aLink.title}
                            rel={aLink.rel}
                            style={newStyle}

                            onClick={(e) => {
                                if (
                                    !(aLink.target && aLink.target === "_blank") &&
                                    aLink.hostname == window.location.hostname &&
                                    !aLink.pathname.startsWith("/api/") &&
                                    !aLink.pathname.startsWith("/media/usercontent/")
                                ) {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (!!aLink.hash &&
                                        aLink.pathname === window.location.pathname) {
                                        const id = aLink.hash.replace('#', '');
                                        const element = document.getElementById(id);

                                        if (element) {
                                            element.scrollIntoView();

                                            return;
                                        }
                                    }

                                    this.props.history.push({
                                        pathname: aLink.pathname,
                                        hash: aLink.hash,
                                        search: aLink.search,
                                    });
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: aLink.innerHTML }}
                        >
                            {/* <span dangerouslySetInnerHTML={{ __html: aLink.innerHTML }}></span> */}
                        </a>,
                        parent,
                        () => {
                            aLink.replaceWith(...Array.from(parent.childNodes));
                        }
                    );
                }
            }, 1000);
        }

        return (
            <div
                className={["TinyFormattedContent", this.props?.className && this.props.className].filter(Boolean).join(" ")}
                lang={this.props.language ? this.props.language : undefined}
                ref={this.ref}
            >
                {
                    replaceTinyContent(
                        this.props.content,
                        this.props.attachedFiles,
                        this.props.attachedFolders,
                        this.props.attachedSharePointListSearches
                    ).content
                }
                {
                    this.state.photoBoxImageUrl && (
                        <PhotoBox
                            imageUrl={this.state.photoBoxImageUrl}
                            onClose={() => {
                                this.setState({
                                    photoBoxImageUrl: null,
                                });
                            }}
                        />
                    )
                }
            </div>
        );
    }
}

export default withRouter(TinyFormattedContent);
