import Immutable, { Map } from 'immutable'

import {
    FETCH_INSTANCE_PENDING,
    FETCH_INSTANCE_REJECTED,
    FETCH_INSTANCE_FULFILLED,
    SET_INSTANCE_PROPERTY
} from './action-types'

const initialState: Map<string, any> = Immutable.fromJS({
    color: '#e6007e',
    failed: false,
    id: 0,
    loading: false,
    logoUrl: 'https://intra.spintr.me/Style/Images/specific/logo.png',
    name: 'Spintr',
    fetchHasBeenInitialized: false
})

const instanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INSTANCE_PENDING:
            return state.merge({
                loading: true,
                fetchHasBeenInitialized: true
            })

        case FETCH_INSTANCE_REJECTED:
            return state.merge({
                failed: true,
                loading: false
            })

        case FETCH_INSTANCE_FULFILLED:
            const data = action.payload.data;

            return state.merge({
                ...data,
                loading: false,
                failed: false
            })

        case SET_INSTANCE_PROPERTY:
            return state.merge({
                [action.key]: action.value,
            });

        default:
            return state
    }
}

export default instanceReducer
