import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './ResponsiveBlock.css'

const mutationObserverSettings = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true
}

class ResponsiveBlock extends Component<{ className?: string, style?: React.CSSProperties }> {
    static propTypes = {
        onResize: PropTypes.func
    }

    bindResizeEvent() {
        // @ts-ignore
        this.sensorTarget = ReactDOM.findDOMNode(this.sensorNode).contentWindow

        // @ts-ignore
        if (!this.sensorTarget) {
            // TODO: handle som shit
            return
        }

        // @ts-ignore
        this.sensorTarget.onresize = this.didResize.bind(this)
        this.didResize()
    }

    componentDidMount() {
        // @ts-ignore
        this.width = null;
        // @ts-ignore
        this.height = null;

        this.bindResizeEvent()

        // @ts-ignore
        this.observer = new MutationObserver(this.didResize.bind(this))
        // @ts-ignore
        this.observer.observe(ReactDOM.findDOMNode(this), mutationObserverSettings)
    }

    didResize() {
        const self = ReactDOM.findDOMNode(this)
        // @ts-ignore
        const height = self.offsetHeight
        // @ts-ignore
        const width = self.offsetWidth

        // @ts-ignore
        if (width !== this.width || height !== this.height) {
            // @ts-ignore
            this.height = height
            // @ts-ignore
            this.width = width

            // @ts-ignore
            this.props.onResize && this.props.onResize(width, height)
        }
    }

    render() {
        let className: string = "ResponsiveBlock"
        // @ts-ignore
        if (this.props.className) {
            // @ts-ignore
            className += ` ${this.props.className}`
        }

        /* eslint-disable jsx-a11y/iframe-has-title */
        return (
            <div className={className} style={
                // @ts-ignore
                this.props.style}>
                {this.props.children}
                <div className="sensor" key="sensor">
                    <iframe
                        aria-hidden="true"
                        className="sensorNode"
                        ref={
                            // @ts-ignore
                            (n) => this.sensorNode = n}
                        tabIndex={-1}></iframe>
                </div>
            </div>
        )
        /* eslint-enable jsx-a11y/iframe-has-title */
    }
}

export default ResponsiveBlock
