import React, { Component } from 'react';
import { Label } from "src/ui";
import { Style } from "src/ui/helpers";

interface IProps {
    text: string,
    textColor: spintrColors,
    onClick?: any,
    backgroundColor?: spintrColors
}

class FormButton extends Component<IProps> {
    render() {
        return (
            <div>
                <a onClick={this.props.onClick}>
                    <div className="border-radius" style={{
                        backgroundColor: !!this.props.backgroundColor ?
                            Style.getHexFromSpintrColor(this.props.backgroundColor) :
                            "transparent",
                        display: 'inline-block',
                        padding: !!this.props.backgroundColor ?
                            Style.getSpacingStr(3) :
                            Style.getSpacingStr(2),
                        paddingTop: Style.getSpacingStr(2),
                        paddingBottom: Style.getSpacingStr(2),
                        cursor: "pointer",
                        userSelect: "none"
                    }}>
                        <Label color={this.props.textColor} size="body-2">
                            {
                                this.props.text
                            }
                        </Label>
                    </div>
                </a>
            </div>
        )
    }
}

export default FormButton;
