import Axios, { AxiosResponse } from "axios";
import React, {
    FunctionComponent,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { Link, useHistory } from "react-router-dom";
import { renderAdminTitle } from "src/admin/common/Title";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Breadcrumbs, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { listActionMenuWidth } from "src/ui/helpers/style";
import DeactivateDepartmentUsersModal from "../DeactivateDepartmentUsersModal";

interface IState {
    company: any;
    data: any[];
    totalCount: number;
    showDeleted: boolean;
    isLoading: boolean;
    office: any;
    showDeleteModal?: boolean;
    itemIdToDelete?: number;
}

interface IProps {
    companyId?: number;
    officeId: number;
}

const initialState: IState = {
    company: null,
    data: [],
    isLoading: true,
    office: undefined,
    showDeleted: false,
    totalCount: 0,
}

const AdminDepartmentList: FunctionComponent<IProps> = ({ companyId, officeId }) => {
    const listRef = useRef<SpintrList>();
    const history = useHistory();
    const [state, setState] = useState(initialState);

    useEffect(
        () => {
            setState((s) => ({ ...s, isLoading: true }));

            const promises: Array<Promise<any>> = [];

            if (companyId) {
                promises.push(Axios
                    .get<Spintr.ICompanyQueryItem>(`/api/v1/companies/${companyId}`)
                    .then((response) => {
                        setState((s) => ({
                            ...s,
                            company: response.data,
                        }));
                    }),
                );
            }

            promises.push(Axios
                .get(`/api/v1/offices/${officeId}`)
                .then((response) => {
                    setState((s) => ({
                        ...s,
                        office: response.data,
                    }));
                }),
            );

            Promise.all(promises).then(() => {
                setState((s) => ({ ...s, isLoading: false }));
            });
        },
        [companyId, officeId],
    );

    const breadcrumbItems = useMemo(
        () => {
            const path = [{
                key: "Organisation",
                link: "/admin/organisation",
                text: localize("KontorOchAvdelningar"),
            }];

            if (state.company) {
                path.push({
                    key: "companyId",
                    link: `/admin/organisation/${state.company?.id || ""}`,
                    text: state.company?.name || "",
                });
            }

            path.push({
                key: "officeId",
                link: `/admin/organisation/${state.office?.id || ""}`,
                text: state.office?.name || "",
            });

            return path;
        },
        [state.company, state.office],
    )

    const renderDeactivateUsersModal = () => {
        if (!state.showDeleteModal) {
            return null;
        }

        return (
            <DeactivateDepartmentUsersModal
                itemIdToDelete={state.itemIdToDelete}
                onSuccess={() => {
                    setState((s) => ({
                        ...s,
                        showDeleteModal: false
                    }));

                    listRef.current.reFetch();
                }}
                onDismiss={() => {
                    setState((s) => ({
                        ...s,
                        showDeleteModal: false
                    }));
                }}
            />
        )
    }

    if (state.isLoading) {
        return <SpintrLoader />;
    } return (
        <div className="AdminOrganisationUnitView">
            <Breadcrumbs
                isSubmenu={true}
                items={breadcrumbItems}
            />
            {renderAdminTitle("KontorOchAvdelningar")}
            <PageHeader
                title={state.office?.name || ""}
                subText={localize("PAGE_INFO_ORGANISATIONSTRUCTURE")}
                useShowMoreSubText
                hits={state.totalCount} />
            <SpintrList
                ref={listRef}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        Axios
                            .get("/api/v1/departments", {
                                params: {
                                    officeId: state.office.id,
                                    orderByColumn: columnId,
                                    includeDeleted: state.showDeleted,
                                    isAscending: isAscending,
                                    searchText: searchQuery,
                                    take: take,
                                    skip: skip,
                                },
                            })
                            .then((response: AxiosResponse) => {
                                resolve({
                                    data: response.data,
                                    totalCount: response.headers["x-total-count"],
                                });

                                setState((s) => ({
                                    ...s,
                                    data: response.data,
                                    totalCount: response.headers["x-total-count"],
                                }));
                            });
                    });
                }}
                data={{ data: state.data, totalCount: state.totalCount }}
                columns={[
                    {
                        name: localize("Avdelning"),
                        fieldName: "name",
                        maxWidth: 400,
                        onRender: (item) => {
                            return (
                                <Link
                                    className={"linkFGColor"}
                                    style={{
                                        ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                    }}
                                    to={
                                        companyId
                                            ? `/admin/organisation/${companyId}/${state.office.id}/${item.id}/edit`
                                            : `/admin/organisation/${state.office.id}/${item.id}/edit`
                                    }
                                >
                                    {item.name}
                                </Link>
                            );
                        },
                    },
                    {
                        name: localize("AntalAnvandare"),
                        fieldName: "userCount",
                        minWidth: 10,
                    },
                    {
                        name: localize("HarAvdelningssida"),
                        fieldName: "hasPage",
                        minWidth: 150,
                        onRender: (item) => {
                            return <span>{item.hasPage ? localize("Ja") : localize("Nej")}</span>;
                        },
                    },
                    {
                        name: localize("Status"),
                        fieldName: "deleted",
                        minWidth: 100,
                        onRender: (item) => {
                            return <span>{item.deleted ? localize("Borttagen") : localize("Tillganglig")}</span>;
                        },
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () =>
                                                        history.push(
                                                            companyId
                                                                ? `/admin/organisation/${companyId}/${state.office.id}/${item.id}/edit`
                                                                : `/admin/organisation/${state.office.id}/${item.id}/edit`
                                                        ),
                                                },

                                                item.deleted
                                                    ? {
                                                        text: localize("Aterstall"),
                                                        onClick: () => {
                                                            Axios
                                                                .put(`/api/v1/departments/${item.id}/restore`)
                                                                .then(() => {
                                                                    listRef.current.reFetch();
                                                                });
                                                        },
                                                    }
                                                    : {
                                                        text: localize("TaBort"),
                                                        onClick: () => {
                                                            if (item.userCount > 0) {
                                                                setState((s) => ({
                                                                    ...s,
                                                                    showDeleteModal: true,
                                                                    itemIdToDelete: item.id
                                                                }));
                                                            } else {
                                                                api.delete(`/api/v1/departments/${item.id}`).then(() => {
                                                                    listRef.current.reFetch();
                                                                });
                                                            }
                                                        },
                                                    },
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn="name"
                buttons={[
                    {
                        key: "add",
                        text: localize("NyAvdelning"),
                        onClick: () =>
                            history.push(
                                companyId
                                    ? `/admin/organisation/${companyId}/${state.office.id}/create`
                                    : `/admin/organisation/${state.office.id}/create`
                            ),
                        iconProps: { iconName: "Add" },
                        className: "commandBarAddButton",
                    },
                ]}
                actionMenu={[
                    {
                        items: [
                            {
                                text: localize(state.showDeleted ? "DoljBorttagna" : "VisaBorttagna"),
                                onClick: () => {
                                    setState(
                                        (prevState) => ({
                                            ...prevState,
                                            showDeleted: !prevState.showDeleted,
                                        }),
                                    );

                                    listRef.current.reFetch()
                                },
                            },
                        ],
                    },
                ]}
            />
            {renderDeactivateUsersModal()}
        </div>
    );
};

export default AdminDepartmentList;