import { AxiosRequestConfig, CancelToken } from "axios";
import api from "src/spintr/SpintrApi";

type TargetProjection = {
    id: number;
    name?: string | undefined;
    type?: number | undefined;
    info?: string | undefined;
    image?: string | undefined;
}

export type GroupInviteModel
    = { id: number; }
    | { email: string; };

export type GroupFunctionProjection = {
    id: Spintr.GroupFunction;
    enabled: boolean;
};

export type GroupBaseModel = {
    name:               string;
    categoryId? :       number | undefined;
    description:        string;
    availability:       Spintr.GroupAvailability;
    membersCanInvite:   boolean;
    memberCount?:       number | undefined;
};

export type GroupRequestModel = GroupBaseModel & {
    imageTicket?:               string | undefined;
    ticket?: ({
        inProgress: boolean;
        ticket: string;
        image: string;
        remove: boolean;
        data: string;
        id: number;
        mediaflowId: number;
        hotlink: boolean;
    }) | undefined;
    newCategoryName?:           string | undefined;
    creatorId?:                 number | undefined;
    initialMembers?:            GroupInviteModel | undefined;
    isArchived?:                boolean | undefined;
    version:                    number;
    template?:                  number | undefined;
    referenceId?:               number | undefined;
    targets?:                   TargetProjection[] | undefined;
    adminTargets?:              TargetProjection[] | undefined;
    disableManualMembership?:   boolean | undefined;
    externalId?:                string | undefined; 
    syncMetadata?:              boolean | undefined;
    syncFiles?:                 boolean | undefined;
    syncCalendar?:              boolean | undefined;
    syncMembers?:               boolean | undefined;
    tabOrder?:                  GroupFunctionProjection[] | undefined;

};

export async function createGroupAsync(
    model: GroupRequestModel,
    cancelToken?: CancelToken | undefined,
): Promise<Spintr.Group> {
    const response = await api.post<Spintr.Group>(
        "/api/v1/groups",
        model,
        { cancelToken }
    );

    return response.data;
}


export type GetGroupByIdParams = {
    forceMembership?: boolean | undefined;
    useElevatedRights?: boolean | undefined;
};

export async function getGroupByIdAsync(
    groupId: number,
    params: GetGroupByIdParams,
    cancelToken?: CancelToken | undefined,
): Promise<Spintr.Group> {
    const config: AxiosRequestConfig = {
        cancelToken,
        params,
    };

    const response = await api.get<Spintr.Group>(
        `/api/v1/groups/${groupId}`,
        config
    );

    return response.data;
}


export async function getGroupTemplatesAsync(
    includeDeleted?: boolean | undefined,
    cancelToken?: CancelToken | undefined,
): Promise<Spintr.GroupTemplate[]> {
    const config: AxiosRequestConfig = {
        params: {
            includeDeleted,
        },
        cancelToken,
    };

    const response = await api.get<Spintr.GroupTemplate[]>("/api/v1/grouptemplate", config);

    return response.data;
}

export type GroupTemplateModel = {
    groupId: number;
    name: string;
    saveGroupChat: boolean;
    savePlanning: boolean;
    saveNotes: boolean;
    saveFiles: boolean;
    savePages: boolean;
};

export async function createGroupTemplateAsync(
    model: GroupTemplateModel,
): Promise<void> {
    await api.post("/api/v1/grouptemplate", model);
}

export async function deleteGroupTemplateAsync(
    id: number,
): Promise<void> {
    await api.delete(`/api/v1/grouptemplate/${id}`);
}

