import React, { CSSProperties, Component, } from "react";
import { localize } from "src/l10n";
import { DraftHandleValue, EditorState, Modifier } from "draft-js";
import { Label, UnstyledButton } from "src/ui";
import createMentionPlugin, { DraftMentionObject, MentionSearchChangedEvent, MentionsPlugin } from "draft-js-mention-plugin";
import createEmojiPlugin, { EmojiPlugin } from "draft-js-emoji-plugin";
import Editor from "draft-js-plugins-editor";
import { Subject, Subscription } from "rxjs";
import { searchUsers } from "src/search";
import { debounceTime } from "rxjs/operators";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ComposerStage, defaultFrameColors, defaultFrameFonts } from "./Visage2Composer";
import { SpintrTypes } from "src/typings";
import { ProductPlugin, createProductPlugin, ProductSuggestionsProps } from "./plugins";

interface IProps {
    disableEffects?: boolean | undefined;
    editorState: EditorState;
    post: Spintr.ISocialPostBase;
    setStep: any;
    onPostUpdate: any;
    onEditorStateUpdate: (editorState: EditorState) => void;
    placeholder?: string;
    onProductAdded?:    ProductSuggestionsProps["onAddProduct"] | undefined;
    onProductRemoved?:  ProductSuggestionsProps["onRemoveProduct"] | undefined;
    productsOptions?: {
        enabled: boolean;
        patterns: RegExp[];
    };
}

interface IState {
    suggestions: DraftMentionObject[];
}

class Visage2ComposerTextInput extends Component<IProps, IState> {
    protected mentionPlugin: MentionsPlugin;
    protected emojiPlugin: EmojiPlugin;
    protected productPlugin: ProductPlugin;
    protected mentionSubject: Subject<string>;
    protected mentionSubscription: Subscription;

    constructor(props: IProps) {
        super(props);
        
        this.mentionPlugin = createMentionPlugin({
            supportWhitespace: true 
        });
        this.emojiPlugin = createEmojiPlugin();
        this.productPlugin = createProductPlugin({
            enabled: props.productsOptions?.enabled || false,
            patterns: props.productsOptions?.patterns || [],
        });
        console.log(props.productsOptions);

        this.mentionSubject = new Subject();
        this.mentionSubscription = this.mentionSubject
            .pipe(
                debounceTime(100)
            )
            .subscribe(this.onMentionValue.bind(this));

        this.state = {
            suggestions: [],
        }
    }

    public componentWillUnmount(): void {
        this.mentionSubscription.unsubscribe();
    }

    protected onAddMention(_: DraftMentionObject) {
        /* ignore */
    }

    protected async onMentionValue(value: string): Promise<void> {
        try {
            const results = await searchUsers({ query: value });

            this.setState({
                suggestions: results.data.map(hit => ({
                    avatar: hit.imageUrl,
                    key: hit.id,
                    link: `/goto/1/${hit.id}`,
                    name: hit.name
                }))
            });
        } catch (err) {
            console.log(err);
        }
    }

    protected onSearchChange(event: MentionSearchChangedEvent): void {
        const { value } = event;

        if (value) {
            this.mentionSubject.next(value);
            return;
        }

        this.setState(
            { suggestions: [] },
            () => this.mentionSubject.next(value)
        );
    }

    protected handlePastedText = (text: string): DraftHandleValue => {
        if (this.props.post.StatusType === SpintrTypes.StatusType.Frame) {
            const currentContent = this.props.editorState.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;

            if (currentContentLength > 128) {
                return 'handled';
            }
        }

        this.props.onEditorStateUpdate(EditorState.push(
          this.props.editorState,
          Modifier.replaceText(
            this.props.editorState.getCurrentContent(),
            this.props.editorState.getSelection(),
            text.replace(/\n/g, ' ')
          ), 'insert-characters'
        ));

        return 'handled';
    }

    protected renderEditor() {
        const { MentionSuggestions } = this.mentionPlugin;
        const { EmojiSuggestions } = this.emojiPlugin;
        const { ProductSuggestions } = this.productPlugin;

        return (
            <div>
                <Editor
                    editorState={this.props.editorState}
                    placeholder={this.props.placeholder}
                    textAlignment={this.props.post.StatusType === SpintrTypes.StatusType.Frame ? "center" : "left"}
                    onChange={(editorState: EditorState) => {
                        this.props.onEditorStateUpdate(editorState);
                    }}
                    handleBeforeInput={() => {
                        if (this.props.post.StatusType === SpintrTypes.StatusType.Frame) {
                            const currentContent = this.props.editorState.getCurrentContent();
                            const currentContentLength = currentContent.getPlainText('').length;

                            if (currentContentLength > 128) {
                                return 'handled';
                            }
                        }
                    }}
                    handlePastedText={this.handlePastedText}
                    plugins={[
                        this.mentionPlugin,
                        this.emojiPlugin,
                        this.productPlugin,
                    ]}
                />
                    <MentionSuggestions
                        onAddMention={this.onAddMention.bind(this)}
                        suggestions={this.state.suggestions}
                        onSearchChange={this.onSearchChange.bind(this)}
                    />
                    <ProductSuggestions
                        onAddProduct={this.props.onProductAdded}
                        onRemoveProduct={this.props.onProductRemoved} />
                <EmojiSuggestions />
            </div>
        )
    }

    public render() {
        if (this.props.post.StatusType === SpintrTypes.StatusType.Frame) {
            const style: CSSProperties = {
                background: `linear-gradient(${this.props.post.color.from}, ${this.props.post.color.to})`,
                fontFamily: defaultFrameFonts[this.props.post.font - 1],
                fontWeight: 600
            };

            return (
                <div className="frame-form" style={style}>
                    <div className="frame-form-inner">
                    {this.renderEditor()}
                    </div>
                    <UnstyledButton
                        className="absolute-button text"
                        onClick={() => {
                            this.props.setStep(ComposerStage.SelectFrameFont);
                        }}
                    >
                        <Visage2Icon icon="text" />
                    </UnstyledButton>
                    <UnstyledButton
                        className="absolute-button remove-button close-button-circle"
                        onClick={() => {
                            this.props.onPostUpdate({
                                ...this.props.post,
                                StatusType: SpintrTypes.StatusType.Text,
                                background: "",
                                emoji: undefined,
                                font: undefined,
                                color: undefined
                            });
                        }}
                    >
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                </div>
            )
        }

        return (
            <div className="composer-form">
                {this.renderEditor()}
                {this.getStatusType() === SpintrTypes.StatusType.Text && !this.props.disableEffects && (
                    <div className="effect-button">
                        <UnstyledButton
                            ariaLabel={localize("ADD_EFFECT")}
                            title={localize("ADD_EFFECT")}
                            onClick={() => {
                                const c = defaultFrameColors[0];

                                this.props.onPostUpdate({
                                    ...this.props.post,
                                    StatusType: SpintrTypes.StatusType.Frame,
                                    emoji: "",
                                    font: 1,
                                    color: c,
                                    background: {
                                        start: {
                                            color: c.from
                                        },
                                        stop: {
                                            color: c.to
                                        }
                                    }
                                });
                            }}
                        >
                            <Label as="div" size="body-2" weight="medium">
                                Aa
                            </Label>
                        </UnstyledButton>
                    </div>
                )}
            </div>
        )
    }

    getStatusType() {
        if (this.props.post.content.find(x => x.StatusType === SpintrTypes.StatusType.BusinessDeal)) {
            return SpintrTypes.StatusType.BusinessDeal;
        } else if (this.props.post.content.find(x => x.StatusType === SpintrTypes.StatusType.SomethingFun)) {
            return SpintrTypes.StatusType.SomethingFun;
        } else if (this.props.post.content.find(x => x.StatusType === SpintrTypes.StatusType.Kudos)) {
            return SpintrTypes.StatusType.Kudos;
        } else if (this.props.post.content.find(x => x.StatusType === SpintrTypes.StatusType.SystemStatus)) {
            return SpintrTypes.StatusType.SystemStatus
        } else {
            return this.props.post.StatusType;
        }
    }
}

export default Visage2ComposerTextInput;