import moment from "moment";
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Breadcrumbs, PageHeader } from "src/ui";
import { Label } from "src/ui/components/Label/Label";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { listActionMenuWidth } from "src/ui/helpers/style";

type Props = RouteComponentProps<any>;

const KeyPointIndicatorListView: FunctionComponent<Props> = ({ history }) => {
    /**
     * State
     */
    const [state, setState] = useState<Spintr.IKeyPointIndicatorEnvelope>({
        items: [],
        totalCount: 0,
    });

    /**
     * Reference
     */
    const listReference = useRef<SpintrList>();


    /**
     * Localized strings
     */
    const headerText = useMemo(() => localize("KEY_POINT_INDICATORS"), []);
    const descriptionText = useMemo(() => localize("KEY_POINT_INDICATORS_DESCRIPTION"), []);
    const showMoreText = useMemo(() => localize("VisaMer"), []);
    const showLessText = useMemo(() => localize("VisaMindre"), []);
    const hitsText = useMemo(() => localize("Traffar"), []);

    /**
     * List properties
     */
    const onCreateClicked = useCallback(
        () => history.push("/admin/key-point-indicators/create"),
        [history]
    );

    const onDeleteClicked = useCallback(
        async (item: Spintr.IKeyPointIndicatorResponseModel) => {
            try {
                const response = await api.delete<Spintr.IKeyPointIndicatorResponseModel>(
                    "/api/v1/KeyPointIndicators/" + item.id,
                );

                if (response.status > 399) {
                    return;
                }

                listReference.current.reFetch();
            } catch (err) {
                console.log(err);
            }
        },
        [listReference]
    );

    const buttons = useMemo(() => [{
        className: "commandBarAddButton",
        iconProps: { iconName: "Add" },
        key: "add",
        onClick: onCreateClicked,
        text: localize("SkapaNy"),
    }], [onCreateClicked]);

    const columns = useMemo(() => [{
        fieldName: "displayName",
        name: localize("Namn"),
        onRender: (item: Spintr.IKeyPointIndicatorResponseModel) => (
            <Label as="span">
                {item.displayName}
            </Label>
        ),
    }, {
        fieldName: "value",
        minWidth: 200,
        name: localize("KEY_POINT_INDICATOR_VALUE"),
        onRender: (item: Spintr.IKeyPointIndicatorResponseModel) => (
            <Label as="span">
                {item.value}
            </Label>
        ),
    }, {
        fieldName: "updated",
        minWidth: 140,
        name: localize("UPDATED_AT"),
        onRender: (item: Spintr.IKeyPointIndicatorResponseModel) => (
            <Label as="span">
                {moment(item.updated).format("yyyy-MM-DD HH:mm")}
            </Label>
        ),
    }, {
        minWidth: listActionMenuWidth,
        maxWidth: listActionMenuWidth,
        name: "",
        onRender: (item: Spintr.IKeyPointIndicatorResponseModel) => {
            return (
                <ActionMenu
                    categories={[{
                        items: [{
                            onClick: () => history.push(
                                "/admin/key-point-indicators/" + item.id + "/edit",
                            ),
                            text: localize("Redigera"),
                        }, {
                            onClick: () => onDeleteClicked(item),
                            text: localize("TaBort"),
                        }],
                    }]}
                />
            );
        },
    }], [onDeleteClicked, history]);

    const fetchList = useCallback(
        async (skip: number, take: number, orderBy: number, ascending: boolean, query: string) => {
            const retVal = { data: [], totalCount: 0 };

            try {
                const response = await api.get<Spintr.IKeyPointIndicatorEnvelope>(
                    "/api/v1/KeyPointIndicators",
                    {
                        params: {
                            ascending,
                            orderBy,
                            query,
                            skip,
                            take,
                        },
                    },
                );

                retVal.data = response?.data?.items || [];
                retVal.totalCount = response?.data?.totalCount || 0;

                setState(() => ({
                    items: retVal.data,
                    totalCount: retVal.totalCount,
                }));


            } catch (_) { /* ignored */ }

            return retVal;
        },
        [setState],
    );


    /**
     * Render
     */
    return (
        <div id="KeyPointIndicatorListView">
            <Breadcrumbs
                displayInstance
                items={[
                    {
                        text: localize("Administration"),
                        key: 2,
                        link: "/admin",
                    },
                    {
                        text: headerText,
                        key: 3,
                        link: window.location.pathname
                    },
                ]}
            />
            <PageHeader
                title={headerText}
                subText={descriptionText}
                useShowMoreSubText
                hits={state.totalCount} />
            <SpintrList
                buttons={buttons}
                columns={columns}
                data={{
                    data: state.items,
                    totalCount: state.totalCount,
                }}
                fetch={fetchList}
                history={history}
                isDescending={false}
                ref={listReference}
                orderByColumn={"displayName"}
            />
        </div>
    );
};

const KeyPointIndicatorListViewWithRouter = withRouter(
    KeyPointIndicatorListView
);

export default KeyPointIndicatorListViewWithRouter;
