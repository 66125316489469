import { Dropdown, Image, PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { FileSelector } from "src/spintr/components";
import { SpintrTypes } from "src/typings";
import { ContentImageViewerAndSelector, Loader, PageHeader } from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { Style } from "src/ui/helpers";
import { validateRequiredTextField } from "src/utils";
import api from "src/spintr/SpintrApi";

interface IPageRouteParams {
    path: string;
    id: any;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    instance?: any;
    currentUser?: any;
    isMediaEnabled?: boolean;
}

interface IState {
    isLoading: boolean;
    item?: any;
    enableFieldValidation?: boolean;
    saveError?: any;
    saveSuccess?: boolean;
    categories?: any;
    showImageSelector?: boolean;
    extraInformation?: any[];
}

class SuppliersEditView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const { id } = this.props.match.params;

        if (id) {
            this.setState({ isLoading: true }, async () => {
                try {
                    var supplierResponse = await api.get(`/api/suppliers/${id}`);
                    var extraInformationResponse = await api.get("/api/suppliers/extrainformation");

                    supplierResponse.data.targets = supplierResponse.data.targets.map((target) => ({
                        ...target,
                        key: target.id,
                    }));

                    this.setState({
                        item: supplierResponse.data,
                        extraInformation: extraInformationResponse.data,
                        isLoading: false,
                    });
                } catch (ex) {
                    this.handleCatch(ex);
                }
            });
        } else {
            var extraInformationResponse = await api.get("/api/suppliers/extrainformation");

            this.setState({
                isLoading: false,
                extraInformation: extraInformationResponse.data,
                item: {
                    id: 0,
                    name: "",
                    users: [],
                    articleManagers: [],
                    extraInformation: [],
                    targets: [],
                },
            });
        }

        api.get("/api/suppliers/categories?skip=0&take=999")
            .then((response) => {
                this.setState({
                    categories: response.data.items,
                });
            })
            .catch(this.handleCatch.bind(this));
    }

    goBack() {
        this.props.history.push({
            pathname: `/suppliers`,
        });
    }

    private onCancelClick = () => {
        this.goBack();
    };

    private onSaveClick = () => {
        this.setState(
            {
                isLoading: true,
                enableFieldValidation: true,
            },
            () => {
                api.post("/api/suppliers/" + this.state.item.id, this.state.item)
                    .then(() => {
                        this.goBack();
                    })
                    .catch(this.handleCatch.bind(this));
            }
        );
    };

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    private onImageChange = (result) => {
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                imageUrl: result.thumbnailUrl,
                imageTicket: {
                    ticket: result.ticket,
                    remove: !!!result.ticket,
                },
            },
        }));
    };

    private onChangeText = (ev, val) => {
        const name = ev.target.name;
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [name]: val,
            },
        }));
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="SuppliersView page-margin-bottom fullWidthForm">
                {this.state.saveSuccess && (
                    <div
                        style={{
                            marginBottom: Style.getSpacing(3),
                        }}
                    >
                        <SuccessMessagebar
                            message={localize("SparningenLyckades")}
                            onDismiss={() => {
                                this.setState({
                                    saveSuccess: false,
                                });
                            }}
                        />
                    </div>
                )}
                {!!this.state.saveError && this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PageHeader
                    title={!this.state.item.id ? localize("Skapa") : localize("Redigera")}
                    displayMandatoryText
                />
                <form>
                    <FormSection>
                        <FormControl label={localize("Bild")}>
                            <ContentImageViewerAndSelector
                                editMode
                                cropImage
                                height={170}
                                cropAspect={1170 / 170}
                                placeholderString={localize("ValjBild")}
                                renderSizeString={() => `${localize("OptimalBildStorlek")}: 1170x170px`}
                                onChange={this.onImageChange}
                                imageUrl={this.state.item.imageUrl}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.name}
                                className="textField"
                                label={localize("Namn")}
                                name="name"
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        {this.state.categories && (
                            <FormControl>
                                <Dropdown
                                    required
                                    aria-required
                                    label={localize("Kategori")}
                                    selectedKey={this.state.item.categoryId}
                                    onChange={(_e, v) => {
                                        this.setState((state) => ({
                                            item: {
                                                ...state.item,
                                                categoryId: Number(v.key),
                                            },
                                        }));
                                    }}
                                    options={this.state.categories.map((c) => {
                                        return {
                                            key: c.id,
                                            text: c.name,
                                        };
                                    })}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <TextField
                                value={this.state.item.tagString}
                                name="tagString"
                                className="textField"
                                label={localize("Produktkoder") + " (" + localize("SepareraMedKomma") + ")"}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        {this.props.instance.get("enableCompanyLevel") && (
                            <FormControl>
                                <FormTokenizedObjectInput
                                    showAllWhenEmpty
                                    items={this.state.item.targets}
                                    types={[SpintrTypes.UberType.Company]}
                                    onChange={(targets) => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                targets: targets.map((t) => ({
                                                    ...t,
                                                    id: t.key,
                                                })),
                                            },
                                        });
                                    }}
                                    label={localize("SynligFor")}
                                />
                            </FormControl>
                        )}
                    </FormSection>
                    <FormControl>
                        <FormTokenizedObjectInput
                            aria-label={localize("SokOchLaggTill")}
                            items={(this.state.item.groups || []).map((tag) => {
                                return {
                                    ...tag,
                                    text: tag.name || tag.text,
                                    key: tag.id || tag.key,
                                };
                            })}
                            label={localize("SUPPLIER_GROUPS")}
                            types={[
                                SpintrTypes.UberType.SupplierGroup,
                            ]}
                            onChange={(targets) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        groups: targets.map((t) => {
                                            return {
                                                ...t,
                                                id: t.key,
                                                text: t.name,
                                                image: t.imageUrl,
                                                info: t.subText,
                                            };
                                        }),
                                    },
                                });
                            }}
                        />
                    </FormControl>
                    <FormSection title={localize("Overgripande")}>
                        <FormControl>
                            <TextField
                                value={this.state.item.organisationNumber}
                                name="organisationNumber"
                                className="textField"
                                label={localize("Organisationsnummer")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={this.state.item.users.map((p) => {
                                    return {
                                        ...p,
                                        text: p.text ? p.text : p.name,
                                        name: p.name ? p.name : p.text,
                                        imageUrl: !p.image ? p.imageUrl : p.image,
                                        info: p.info ? p.info : p.subText,
                                        subText: p.info ? p.info : p.subText,
                                        id: p.id ? p.id : p.key,
                                        key: p.key ? p.key : p.id,
                                    };
                                })}
                                types={[SpintrTypes.UberType.User]}
                                required
                                validateOnLoad={this.state.enableFieldValidation}
                                label={localize("SUPPLIERS_RESPONSIBLE")}
                                onChange={(publishers) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            users: publishers.map((p) => {
                                                return {
                                                    id: p.key,
                                                    text: p.name,
                                                    image: p.imageUrl,
                                                    info: p.subText,
                                                    type: p.type,
                                                };
                                            }),
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={this.state.item.articleManagers.map((p) => {
                                    return {
                                        ...p,
                                        text: p.text ? p.text : p.name,
                                        name: p.name ? p.name : p.text,
                                        imageUrl: !p.image ? p.imageUrl : p.image,
                                        info: p.info ? p.info : p.subText,
                                        subText: p.info ? p.info : p.subText,
                                        id: p.id ? p.id : p.key,
                                        key: p.key ? p.key : p.id,
                                    };
                                })}
                                types={[SpintrTypes.UberType.User]}
                                validateOnLoad={this.state.enableFieldValidation}
                                label={localize("Artikelansvarig")}
                                onChange={(articleManagers) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            articleManagers: articleManagers.map((p) => {
                                                return {
                                                    id: p.key,
                                                    text: p.name,
                                                    image: p.imageUrl,
                                                    info: p.subText,
                                                    type: p.type,
                                                };
                                            }),
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.externalId}
                                name="externalId"
                                className="textField"
                                label={localize("Leverantorsnummer_small")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.addressTitle}
                                name="addressTitle"
                                className="textField"
                                label={localize("AdressForstaRaden")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.address}
                                name="address"
                                className="textField"
                                label={localize("Adress")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.zipCode}
                                name="zipCode"
                                className="textField"
                                label={localize("Postnummer")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.city}
                                name="city"
                                className="textField"
                                label={localize("Stad")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.text}
                                name="text"
                                className="textField"
                                multiline
                                label={localize("Fritext")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                    </FormSection>
                    <FormSection title={localize("Kontaktuppgifter")}>
                        <FormControl>
                            <TextField
                                value={this.state.item.phone}
                                name="phone"
                                className="textField"
                                label={localize("Telefonnummer")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.email}
                                name="email"
                                className="textField"
                                label={localize("Epost")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.fax}
                                name="fax"
                                className="textField"
                                label={localize("Fax")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.website}
                                name="website"
                                className="textField"
                                label={localize("Hemsida")}
                                onChange={this.onChangeText}
                            />
                        </FormControl>
                    </FormSection>

                    {this.state.extraInformation?.length > 0 && (
                        <FormSection title={localize("OvrigInformation")}>
                            {this.state.extraInformation.map((ei) => (
                                <FormControl>
                                    <TextField
                                        label={ei.name}
                                        defaultValue={
                                            this.state.item.extraInformation.find(
                                                (itemExtraInformation) => itemExtraInformation.id === ei.id
                                            )?.text
                                        }
                                        onChange={(ev, val) => {
                                            this.setState((prevState) => ({
                                                item: {
                                                    ...prevState.item,
                                                    extraInformationInput: {
                                                        ...prevState.item.extraInformationInput,
                                                        [ei.id]: val,
                                                    },
                                                },
                                            }));
                                        }}
                                    />
                                </FormControl>
                            ))}
                        </FormSection>
                    )}
                    <FormFooterBar
                        onCancelClick={this.onCancelClick}
                        onSaveClick={this.onSaveClick}
                        saveText={localize("Spara")}
                    />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isMediaEnabled: state.app.items.some((a) => a.id === SpintrTypes.SpintrApp.Media && a.enabled),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersEditView));
