const possessiveName = (name: string, locale?: string): string => {
    let firstTwo = "";

    if (!!locale &&
        locale.length > 1) {
        firstTwo = locale.substr(0, 2);
    }

    firstTwo = firstTwo.toLowerCase();

    switch (firstTwo) {
        default:
            return (/(s|x|z)$/i.test(name) ? name : name + "s");
        case "en":
            return name + (/(s|x|z)$/i.test(name) ? "'" : "'s");
    }
};

export default possessiveName;