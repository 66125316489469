import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Loader } from "src/ui";
import {
    PrimaryButton
} from '@fluentui/react';
import ThemeContext from "src/style/ThemeContext";
import api from "src/spintr/SpintrApi";
import PopupHeader from "../../PopupHeader";

interface IProps {
    editor: any,
    onClose: any
}

interface IState {
    isLoading: any,
    tags?: any,
    selectedTag?: any
}

class TinyQaFunction extends Component<IProps, IState> {
    public static contextType = ThemeContext;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        this.setState({
            isLoading: true
        }, () => {
            api.get("/api/qa/tags").then((response) => {
                for (let form of response.data) {
                    form.key = form.id;
                    form.text = form.title;
                }

                this.setState({
                    tags: response.data,
                    isLoading: false
                });
            });
        });
    }

    tagSelected() {
        const id = this.state.selectedTag.id;
        const name = this.state.selectedTag.text;
        const html = '<div><span class="redactorQa qa-' + id + '">' + name + '</span></div> <br />';

        this.props.editor.insertContent(html);

        this.props.onClose();
    }

    public render(): ReactNode {
        return (
            <Dialog
                hidden={false}
                containerClassName="dialogWithPopupHeader"
                onDismiss={this.props.onClose}
                modalProps={{
                    isBlocking: true,
                    allowTouchBodyScroll: true
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: localize("FragorOchSvar"),
                    showCloseButton: true,
                    closeButtonAriaLabel: localize("Stang"),
                }}>
                <PopupHeader
                    text={localize("FragorOchSvar")}
                    onClose={this.props.onClose} />
                {
                    this.state.isLoading ?
                        <Loader /> :
                        <div>
                            <Dropdown
                                selectedKey={!!this.state.selectedTag ? this.state.selectedTag.key : null}
                                onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                                    this.setState({
                                        selectedTag: item
                                    });
                                }}
                                options={this.state.tags}
                                placeholder={localize("SELECT_TAG")}
                            />
                            <DialogFooter>
                                <PrimaryButton
                                    theme={this.context}
                                    onClick={this.tagSelected.bind(this)}
                                    text={localize("Valj")} />
                            </DialogFooter>
                        </div>
                }
            </Dialog>
        );
    }

}

export default TinyQaFunction;