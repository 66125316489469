import Axios, { CancelTokenSource } from "axios";
import ClassNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MapStateToProps, connect } from "react-redux";
import { IFieldCompletionProps as OwnProps } from "./FieldCompletion.types";
import { getCompletion } from "../../api";
import stripHtml from "src/utils/stripHtml";
import { TooltipDelay, TooltipHost } from "@fluentui/react";
import { localize } from "src/l10n";
import RainbowButton from "../RainbowButton/RainbowButton";

interface IStateProps {
    completionsEnabled: boolean;
}

type Props = OwnProps & IStateProps;

const FieldCompletion: React.FC<Props> = (props) => {
    const {
        anchorPosition,
        children,
        completionsEnabled,
        completionType,
        language,
        offsetX,
        offsetY,
        referenceText,
        staggerInterval,
        staggeredOutput,
        onTextGenerated,
        buttonText
    } = props;

    const [isLoading, setLoading] = useState(false);
    const [timeoutTokens, setTimeoutTokens] = useState<ReturnType<typeof setTimeout>[]>([]);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

    useEffect(() => () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel();
        }

        if (timeoutTokens) {
            timeoutTokens.forEach((token) => clearTimeout(token));
        }
    }, [cancelTokenSource, timeoutTokens])

    const textInput = (referenceText || "").trim();
    const onCompletionClick = useCallback(
        async (data) => {
            const requestText = stripHtml(data as string || "");
            if (requestText.length === 0) {
                return;
            }

            const cts = Axios.CancelToken.source();
            setCancelTokenSource(cts);
            setLoading(true);

            let completion: string;
            try {
                const response = await getCompletion({
                    text: requestText,
                    completionType,
                    language,
                }, cts.token);

                completion = response.data.completion || "";
            } catch (_) {
                setLoading(false);
                return;
            }

            console.log(completion);
            if (completion.length === 0) {
                return;
            }

            if (!staggeredOutput) {
                onTextGenerated(completion);
                setLoading(false);
                return;
            }

            const stagger = staggerInterval || 100;

            setTimeoutTokens(completion
                .split(" ")
                .reduce<ReturnType<typeof setTimeout>[]>((tokens, _, i, fullText) => tokens.concat([
                    setTimeout(() => onTextGenerated(fullText.slice(0, i + 1).join(" ")), i * stagger)
                ]), [])
            );
            setLoading(false);
        },
        [completionType, language, staggerInterval, staggeredOutput, onTextGenerated, setLoading, setTimeoutTokens, setCancelTokenSource],
    );

    const buttonStyle = useMemo<React.CSSProperties>(() => {
        switch (anchorPosition) {
            case "TopLeft":
                return {
                    top: offsetY || 0,
                    left: offsetX || 0,
                };

            case "TopRight":
                return {
                    top: offsetY || 0,
                    right: offsetX || 0,
                };

            case "BottomLeft":
                return {
                    bottom: offsetY || 0,
                    left: offsetX || 0,
                };

            default:
            case "BottomRight":
                return {
                    bottom: offsetY || 0,
                    right: offsetX || 0,
                };
        }
    }, [anchorPosition, offsetY, offsetX])

    const tooltipContent = useMemo(
        () => textInput.length === 0
            ? props.noContentTooltipHint || localize("AI_COMPLETION_PROVIDE_TEXT")
            : props.tooltipHint || localize("AI_COMPLETION_GENERATE_TEXT"),
        [textInput],
    );

    if (!completionsEnabled) {
        return children;
    }

    return (
        <div className={ClassNames("FieldCompletion", "anchor-" + anchorPosition, props.wrapperClassName)}>
            {children}
            <div className="button-wrapper" style={buttonStyle}>
                <TooltipHost
                    calloutProps={{ beakWidth: 10 }}
                    className="marketplace-tooltip"
                    delay={TooltipDelay.zero}
                    content={tooltipContent}
                >
                    <RainbowButton
                        isEnabled={textInput.length > 0}
                        isLoading={isLoading}
                        onClick={onCompletionClick}
                        onClickData={textInput}
                        text={buttonText}
                    />
                </TooltipHost>
            </div>
        </div>
    );
};

const mapStateToProps: MapStateToProps<IStateProps, OwnProps, Spintr.AppState> = (state) => ({
    completionsEnabled: state.instance.get("aiCompletionsEnabled"),
});

export default connect(mapStateToProps)(FieldCompletion);
