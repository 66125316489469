import { format } from "d3-format";
import { interpolateNumber } from "d3-interpolate";
import { select } from "d3-selection";
import { arc } from "d3-shape";
import { transition } from "d3-transition";
import React, { Component } from "react";
import CircularBar from "./components/bar/circularBar";

const formatNumber = format(",d");

interface IStyle {
    position?: any;
    left?: any;
    top?: any;
    bottom?: any;
    right?: any;
    fontSize?: any;
    display?: any;
    justifyContent?: any;
    alignItems?: any;
    fontFamily?: any;
    fontWeight?: any;
    width?: any;
}

type showValue = "left" | "center" | "none";

interface IProps {
    max?: number;
    value: number;
    fgColor?: string;
    showValue?: string;
    width?: number;
    unit?: string;
}

class CircularChartWidget extends Component<IProps> {
    private ref = React.createRef<HTMLSpanElement>();
    constructor(props: IProps) {
        super(props);
        this.ref = React.createRef();
    }
    public componentDidMount() {
        this.renderText(this.props.value);
    }
    public renderText(value) {
        const node = this.ref.current;
        const t = transition().duration(800);

        select(node)
            .transition(t)
            .attr("style", "font-family: 'Eloquia', sans-serif;")
            .tween("text", function () {
                const el = select(this);
                const i = interpolateNumber(
                    //@ts-ignore
                    el.text().replace(/,/g, ""),
                    value
                );
                return (t) => el.text(formatNumber(i(t)));
            });
    }
    public render() {
        const tau = 2 * Math.PI; // http://tauday.com/tau-manifesto
        const svgWidth = 150;
        const svgHeight = svgWidth;
        const arcWidth = 20;
        const arcOuterRadius = svgWidth / 2;
        const arcInnerRadius = svgWidth / 2 - arcWidth;

        const { value, showValue, fgColor, max, unit } = this.props;

        const arcGenerator = arc()
            .innerRadius(arcInnerRadius)
            .outerRadius(arcOuterRadius)
            .startAngle(0)
            .cornerRadius(5);


        const fontSize = value < 100 ? "92px" : "32px";
        const fontWeight = value < 100 ? "700" : "400";

        let containerStyle: IStyle = {
            position: "relative",
        };

        let textStyle: IStyle = {
            display: "none",
        };

        let unitStyle: IStyle = {
            fontSize: "32px",
        };

        if (showValue === "left") {
            containerStyle = {
                position: "relative",
                width: svgWidth * 2.1,
            };
            textStyle = {
                position: "absolute",
                top: 0,
                width: svgWidth,
                bottom: 0,
                right: 0,
                fontSize: fontSize,
                fontWeight: fontWeight,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "'Eloquia', sans-serif;",
            };
            unitStyle = {
                fontSize: "24px"
            }
        }

        if (showValue === "center") {
            textStyle = {
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                fontSize: "32px",
                fontWeight: fontWeight,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "'Eloquia', sans-serif;",
            };
            unitStyle = {
                fontSize: "32px"
            }
        }

        return (
            <div>
                <div style={containerStyle}>
                    <svg height={svgHeight} width={svgWidth}>
                        <g
                            className="background-bar-group"
                            transform={`translate(${svgWidth / 2}, ${svgHeight /
                                2})`}
                        >
                            <path
                                d={
                                    //@ts-ignore
                                    arcGenerator({ endAngle: tau })
                                }
                                opacity=".05"
                            />
                        </g>
                        <CircularBar
                            arcGenerator={arcGenerator}
                            value={value}
                            max={max}
                            fgColor={fgColor}
                            tau={tau}
                            svgWidth={svgWidth}
                            svgHeight={svgHeight}
                        />
                    </svg>
                    <div style={textStyle}>
                        <div>
                            <span ref={this.ref}>0</span>
                            <span style={unitStyle}>{unit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CircularChartWidget;
