import { Modal } from '@fluentui/react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { setConfirmPopup } from 'src/popups/actions';
import api from 'src/spintr/SpintrApi';
import { IApplicationState } from "src/spintr/reducer";
import { ActionMenu, Breadcrumbs, PageHeader, SpintrUser } from 'src/ui';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import PopupHeader from 'src/ui/components/PopupHeader';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import { scrollToTop } from 'src/utils';

interface IProps {
    history: any;
    disableGroupCreation: boolean;
    smallViewMode: boolean;
    dispatch?: any;
    isInTeamsApp?: boolean;
}

interface IState {
    myGroupsOnly: boolean;
    activeCategoryId: number;
    displayMembersForGroup: any;
    saveError: any[];
}

class GroupList extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();
    private membersListRef = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        this.state = {
            myGroupsOnly: props.isInTeamsApp,
            activeCategoryId: 0,
            displayMembersForGroup: null,
            saveError: []
        }
    }

    renderList() {
        let buttons = [];

        if (!this.props.disableGroupCreation) {
            buttons.push({
                key: "add",
                text: localize("SkapaNyGrupp"),
                onClick: () => {
                    this.props.history.push({
                        pathname: `/groups/create`,
                    });
                },
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
            });
        }

        return (
            <SpintrList
                ref={this.listRef}
                buttons={buttons}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        api.get(`/api/v1/groups`, {
                            params: {
                                isAscending: isAscending,
                                orderByColumn: columnId,
                                phrase: searchQuery,
                                take: take,
                                skip: skip,
                                categoryId: this.state.activeCategoryId,
                                myGroupsOnly: this.state.myGroupsOnly
                            },
                        }).then((response: AxiosResponse) => {
                            resolve({
                                data: response.data,
                                totalCount: response.headers["x-total-count"]
                            });
                        }).catch(() => { });
                    });
                }}
                columns={[
                    {
                        key: 1,
                        minWidth: 36,
                        maxWidth: 36,
                        onRender: (item) => {
                            return <SpintrUser
                                imageUrl={item.imageUrl}
                                hideText
                                personalName={false}
                                name={item.name} />;
                        },
                    },
                    {
                        key: 2,
                        name: localize("Namn"),
                        fieldName: "name",
                        minWidth: 100,
                        onRender: (item) => {
                            const allowNavigation = item.isMember;

                            if (allowNavigation) {
                                return (
                                    <Link to={`/groups/${item.id}`}>
                                        {
                                            item.name
                                        }
                                    </Link>
                                )
                            }
                            return (
                                <span>
                                    {
                                        item.name
                                    }
                                </span>
                            );
                        },
                    },
                    {
                        key: 3,
                        name: localize("Medlemmar"),
                        fieldName: "memberCount",
                        minWidth: 100,
                        onRender: (item) => {
                            return <span>{item.memberCount}</span>;
                        },
                    },
                    {
                        key: 4,
                        name: localize("Andrad"),
                        fieldName: "lastActivity",
                        minWidth: 100,
                        onRender: (item) => {
                            return (
                                <span>
                                    {
                                        moment(item.lastActivity).format("L")
                                    }
                                </span>
                            );
                        },
                    },
                    {
                        key: 5,
                        name: localize("Tillganglighet"),
                        fieldName: "status",
                        onRender: (item) => {
                            const text =
                                item.status === 1
                                    ? localize("Borttagen")
                                    : item.status === 2
                                        ? localize("Hemlig")
                                        : item.status === 3
                                            ? localize("Oppen")
                                            : localize("Stangd");

                            return <span>{text}</span>;
                        },
                    },
                    {
                        key: 6,
                        minWidth: 40,
                        onRender: (item: any) => {
                            let items = [{
                                text: localize("ListaMedlemmar"),
                                onClick: () => {
                                    this.setState({
                                        displayMembersForGroup: item
                                    });
                                }
                            }];

                            if (item.isMember) {
                                items.push({
                                    text: localize("LamnaGruppen"),
                                    onClick: () => {
                                        const fn = () => {
                                            api.delete("/api/groupmembers/" + item.id).then(() => {
                                                this.listRef.current.reFetch();
                                            }).catch((saveError) => {
                                                scrollToTop();

                                                this.setState({
                                                    saveError: saveError.response.data.errorlist
                                                });
                                            });
                                        }

                                        this.props.dispatch(setConfirmPopup({
                                            isOpen: true,
                                            title: localize("LamnaGruppen"),
                                            message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                                            onConfirm: fn
                                        }));
                                    }
                                });
                            }

                            if (!item.isMember &&
                                item.isOpen) {
                                items.push({
                                    text: localize("GaMed"),
                                    onClick: () => {
                                        api.post("/api/groups/" + item.id + "/join").then(() => {
                                            this.listRef.current.reFetch();
                                        });
                                    }
                                });
                            }

                            if (!item.isMember &&
                                item.status === 4) {
                                if (item.isRequestingMembership) {
                                    items.push({
                                        text: localize("AnsokanHarSkickats"),
                                        onClick: () => { }
                                    });
                                } else {
                                    items.push({
                                        text: localize("AnsokOmMedlemskap"),
                                        onClick: () => {
                                            api.post("/api/groupmembers/request/" + item.id).then(() => {
                                                this.listRef.current.reFetch();
                                            });
                                        }
                                    });
                                }
                            }

                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: items
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn={"name"}
            />
        )
    }

    closeMembersModal = () => {
        this.setState({
            displayMembersForGroup: null
        });
    }

    renderMembersModal() {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={!!this.state.displayMembersForGroup}
                onDismiss={this.closeMembersModal}
                containerClassName={"GroupList-MembersModal"}
            >
                <PopupHeader
                    text={localize("MedlemmarI") + " " + this.state.displayMembersForGroup.name}
                    onClose={this.closeMembersModal} />
                {/* <div>
                    <Stack horizontal verticalAlign="center">
                        <StackItem grow={1}>
                            <Label as="h2" size="h2">
                            {
                        localize("MedlemmarI") + " " + this.state.displayMembersForGroup.name
                    }
                            </Label>
                        </StackItem>
                        <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.closeMembersModal}>
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </Stack>
                    <Separator />
                </div> */}
                <SpintrList
                    ref={this.membersListRef}
                    disableSearch={true}
                    disableCommandBar={true}
                    hideHeader={true}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api.get(`/api/v1/groupmembers`, {
                                params: {
                                    groupId: this.state.displayMembersForGroup.id,
                                    isAscending: isAscending,
                                    orderByColumn: "instanceId",
                                    phrase: searchQuery,
                                    take: take,
                                    skip: skip,
                                },
                            }).then((response: AxiosResponse) => {
                                resolve({
                                    data: response.data.members,
                                    totalCount: response.data.total
                                });
                            });
                        });
                    }}
                    columns={[
                        {
                            key: 5,
                            onRender: (item) => {
                                let subText = item.instanceId === 1 ?
                                    localize("Administrator") :
                                    item.instanceId === 2 ?
                                        localize("Medlem") :
                                        localize("Extern");

                                return <SpintrUser
                                    imageUrl={item.imageUrl}
                                    subText={subText}
                                    personalName={true}
                                    name={item.name} />;
                            },
                        }
                    ]}
                    orderByColumn={"name"}
                />
            </Modal>
        )
    }

    render() {
        return (
            <div>
                <div>
                    {this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    <Breadcrumbs
                        displayInstance
                        items={[
                            {
                                text: localize("Grupper"),
                                key: 0,
                                link: "/groups",
                            },
                        ]}
                    />
                    <PageHeader
                        title={localize("Grupper")}
                    />
                    {
                        this.renderList()
                    }
                </div>
                {
                    !!this.state.displayMembersForGroup ?
                        this.renderMembersModal() :
                        null
                }
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    disableGroupCreation: state.instance.get("disableGroupCreation") || (state.instance.get("restrictGroups") && !state.profile.active.isAdmin && !state.profile.active.isEditor && !state.profile.active.settings.canCreateGroups),
    smallViewMode: state.ui.isSmallViewMode,
    isInTeamsApp: state.ui.isInTeamsApp,
});

export default withRouter(connect(mapStateToProps)(GroupList));
