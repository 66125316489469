import React, { Fragment, FunctionComponent } from "react";
import { getTypeName, localizeFormat } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const FollowNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IUpdatedContentNotification;
    if (!notification) {
        return null;
    }

    const typeText = getTypeName(notification.objectType, { case: 'definite' })
        .toLowerCase();

    const updateText = localizeFormat(
        notification.count > 1
            ? "DetHarSkettXUppdateringarI"
            : "DetHarSkettEnUppdateringI",
        notification.count
    )

    return (
        <Fragment>
            <span>{updateText} {typeText} {notification.objectName}</span>
        </Fragment>
    );
};

export default FollowNotification;