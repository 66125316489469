export const FETCH_PEOPLE = 'FETCH_PEOPLE'
export const FETCH_PEOPLE_PENDING = 'FETCH_PEOPLE_PENDING'
export const FETCH_PEOPLE_REJECTED = 'FETCH_PEOPLE_REJECTED'
export const FETCH_PEOPLE_FULFILLED = 'FETCH_PEOPLE_FULFILLED'

export const FOLLOW_USER = 'FOLLOW_USER'
export const FOLLOW_USER_PENDING = 'FOLLOW_USER_PENDING'
export const FOLLOW_USER_REJECTED = 'FOLLOW_USER_REJECTED'
export const FOLLOW_USER_FULFILLED = 'FOLLOW_USER_FULFILLED'

export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const UNFOLLOW_USER_PENDING = 'UNFOLLOW_USER_PENDING'
export const UNFOLLOW_USER_REJECTED = 'UNFOLLOW_USER_REJECTED'
export const UNFOLLOW_USER_FULFILLED = 'UNFOLLOW_USER_FULFILLED'

export const LOCK_USER = 'LOCK_USER'
export const LOCK_USER_PENDING = 'LOCK_USER_PENDING'
export const LOCK_USER_REJECTED = 'LOCK_USER_REJECTED'
export const LOCK_USER_FULFILLED = 'LOCK_USER_FULFILLED'

export const UNLOCK_USER = 'UNLOCK_USER'
export const UNLOCK_USER_PENDING = 'UNLOCK_USER_PENDING'
export const UNLOCK_USER_REJECTED = 'UNLOCK_USER_REJECTED'
export const UNLOCK_USER_FULFILLED = 'UNLOCK_USER_FULFILLED'

export const DISABLE_USER = 'DISABLE_USER'
export const DISABLE_USER_PENDING = 'DISABLE_USER_PENDING'
export const DISABLE_USER_REJECTED = 'DISABLE_USER_REJECTED'
export const DISABLE_USER_FULFILLED = 'DISABLE_USER_FULFILLED'

export const ENABLE_USER = 'ENABLE_USER'
export const ENABLE_USER_PENDING = 'ENABLE_USER_PENDING'
export const ENABLE_USER_REJECTED = 'ENABLE_USER_REJECTED'
export const ENABLE_USER_FULFILLED = 'ENABLE_USER_FULFILLED'