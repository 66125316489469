import classnames from "classnames";
import * as React from "react";
import "./ContentButton.scss";

export interface IProps<T> {
    onClick?: (data?: T) => void;
    onClickData?: T;
    className?: string;
    title?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
}

class ContentButton<T = any> extends React.Component<IProps<T>> {
    public render() {
        const { className, title, children, style } = this.props;

        return (
            this.props.disabled ?
                <div
                    tabIndex={0}
                    title={title}
                    style={style}
                >
                    {children}
                </div> :
                <div
                    className={classnames("content-button-component", className)}
                    onClick={this.onClick}
                    onKeyDown={this.onKeyDown}
                    role="button"
                    tabIndex={0}
                    title={title}
                    style={style}
                >
                    {children}
                </div>
        );
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if ([13, 32].some(k => e.keyCode === k)) {
            this.onClick(e);
        }
    };

    private onClick = (
        e:
            | React.MouseEvent<HTMLDivElement>
            | React.KeyboardEvent<HTMLDivElement>
    ) => {
        const { props } = this;

        if (props.disabled) {
            return;
        }

        if (props.onClick) {
            this.props.onClick(props.onClickData);

            if (e) {
                e.stopPropagation();
            }
        }
    };
}

export default ContentButton;
