import { TextField } from "@fluentui/react";
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { Breadcrumbs, Loader, PageHeader } from "src/ui";
import { FormControl, FormFooterBar, FormSection, MandatoryText } from "src/ui/components/Forms";
import { Label } from "src/ui/components/Label/Label";
import api from "src/spintr/SpintrApi";

interface IRouteParams {
    kpiId: string;
}

type Props = RouteComponentProps<IRouteParams>;

const KeyPointIndicatorFormView: FunctionComponent<Props> = ({ history, match }) => {
    const kpiId = match?.params?.kpiId || "";

    const headerText = useMemo(
        () => localize(
            kpiId.length === 0
                ? "CREATE_KEY_POINT_INDICATOR"
                : "EDIT_KEY_POINT_INDICATOR"
        ),
        [kpiId],
    );
    const nameText = useMemo(() => localize("Namn"), []);
    const ariaNameText = useMemo(() => localize("Namn"), []);
    const valueText = useMemo(() => localize("KEY_POINT_INDICATOR_VALUE"), []);
    const ariaValueText = useMemo(() => localize("KEY_POINT_INDICATOR_VALUE_ARIA"), []);

    const [loading, setLoading] = useState(true);

    const [original, setOriginal] = useState<Spintr.IKeyPointIndicatorResponseModel>({
        created: new Date(),
        displayName: "",
        id: "",
        name: "",
        updated: new Date(),
        value: "",
    });

    const [model, setModel] = useState<Spintr.IKeyPointIndicatorRequestModel>({
        displayName: "",
        value: ""
    });

    const onNameChanged = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            setModel((m) => ({
                ...m,
                displayName: value,
            }));
        },
        [setModel]
    );

    const onValueChanged = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            setModel((m) => ({
                ...m,
                value,
            }));
        },
        [setModel]
    );

    const onCancelClick = useCallback(
        () => history.push("/admin/key-point-indicators"),
        [history],
    );

    const onSaveClick = useCallback(
        async () => {
            setLoading(true);

            try {
                const promise = kpiId.length === 0
                    ? api.post(
                        "/api/v1/KeyPointIndicators",
                        model,
                    )
                    : api.put(
                        "/api/v1/KeyPointIndicators/" + kpiId,
                        model,
                    );

                const response = await promise;

                if (response.status < 300) {
                    history.push("/admin/key-point-indicators");
                    return;
                }

                console.log(response);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        },
        [setLoading, history, model, kpiId],
    );

    useEffect(() => {
        if (kpiId.length === 0) {
            setLoading(false);
            return;
        }

        const promise = api.get<Spintr.IKeyPointIndicatorResponseModel>(
            "/api/v1/KeyPointIndicators/" + kpiId,
        );

        promise.then(
            (response) => {
                setOriginal(response.data);
                setModel(() => ({
                    displayName: response.data.displayName,
                    value: response.data.value,
                }));
                setLoading(false);
            },
            (err) => {
                console.log(err);
                history.push("/admin/key-point-indicators")
            },
        )
    }, [kpiId, history]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div
            className="page-margin-bottom"
            id="KeyPointIndicatorFormView"
        >
            <Breadcrumbs
                displayInstance
                items={[
                    {
                        text: localize("Administration"),
                        key: 2,
                        link: "/admin",
                    },
                    {
                        text: localize("KEY_POINT_INDICATORS"),
                        key: 2,
                        link: "/admin/key-point-indicators"
                    },
                    {
                        text: headerText,
                        key: 3,
                        link: window.location.pathname
                    },
                ]}
            />
            <div className="view-header">
                <PageHeader title={headerText} displayMandatoryText />
            </div>
            <form>
                <FormSection>
                    <FormControl>
                        <TextField
                            ariaLabel={ariaNameText}
                            aria-required={true}
                            defaultValue={original.displayName}
                            label={nameText}
                            name="displayName"
                            onChange={onNameChanged}
                            required={true}
                            value={model.displayName}
                            validateOnFocusIn={true}
                            validateOnFocusOut={true}
                            validateOnLoad={true}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            ariaLabel={ariaValueText}
                            aria-required={true}
                            defaultValue={original.displayName}
                            label={valueText}
                            name="displayName"
                            onChange={onValueChanged}
                            required={true}
                            value={model.value}
                            validateOnFocusIn={true}
                            validateOnFocusOut={true}
                            validateOnLoad={true}
                        />
                    </FormControl>
                </FormSection>
                <FormFooterBar
                    onCancelClick={onCancelClick}
                    onSaveClick={onSaveClick}
                />
            </form>
        </div>
    );
};

const KeyPointIndicatorFormViewWithRouter = withRouter(
    KeyPointIndicatorFormView,
);

export default KeyPointIndicatorFormViewWithRouter;
