import { Icon, Image } from "@fluentui/react";
import React, { ReactElement, useCallback } from "react";
import { CaptionBody, CaptionBolder } from "src/components";
import { localize } from "src/l10n";
import {
    ProductSuggestionsEntryProps as Props
} from "./ProductSuggestionsEntry.types";
import { UnstyledButton } from "src/ui";

function ProductSuggestionsEntry(props: Props): ReactElement {
    const { onDismiss, product } = props;

    const onDismissClicked = useCallback(() => onDismiss(product), [onDismiss, product]);

    const image = !product.imageUrl ? null : (
        <div className="logo">
            <Image src={product.imageUrl} />
        </div>
    );

    return (
        <div className="ProductSuggestionsEntry">
            {image}
            <div className="text-content">
                <CaptionBolder className="name" color="contentDark">
                    {product.name}
                </CaptionBolder>
                <CaptionBody className="sku" color="contentLight">
                    {localize("ARTICLE_ID")}: {product.articleId}
                </CaptionBody>
            </div>
            <div className="actions">
                <UnstyledButton className="dismiss-button" onClick={onDismissClicked}>
                    <Icon iconName="ChromeClose" />
                </UnstyledButton>
            </div>
        </div>
    );
}

export default ProductSuggestionsEntry;
