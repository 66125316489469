import classnames from "classnames";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/components/TextField/TextField";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { FormControl, FormSection } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import "./FeedBackInPageView.scss";
import { registerIcons, Separator } from "@fluentui/react";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    pageId: number;
}

interface State {
    isLoading: boolean;
    hasPosted: boolean;
    hasVoted: boolean;
    isPositive: boolean;
    isNegative: boolean;
    text: string;
    displayError: boolean;
    isAllowed: boolean;
}

class FeedBackInPageView extends Component<Props, State> {
    state = {
        isLoading: false,
        hasPosted: false,
        hasVoted: false,
        isPositive: false,
        isNegative: false,
        text: "",
        displayError: false,
        isAllowed: false,
    };

    componentDidMount = () => {
        api.get(`/api/v1/feedback/privilege/${this.props.pageId}`).then((response) => {
            this.setState({ isAllowed: response.data });
        });
    };

    postFeedback = () => {
        console.log(this.props.pageId, this.state.hasVoted);
        this.setState({ isLoading: true }, () => {
            api
                .post(`/api/v1/feedback`, {
                    hasVoted: this.state.hasVoted,
                    isPositive: this.state.isPositive,
                    isNegative: this.state.isNegative,
                    text: this.state.text,
                    uberId: this.props.pageId,
                })
                .then((response) => {
                    this.setState({
                        hasPosted: true,
                        isLoading: false,
                    });
                })
                .catch((e) => {
                    this.setState({ displayError: true, isLoading: false });
                });
        });
    };

    render() {
        if (this.state.isLoading) return <SpintrLoader />;

        if (!this.state.isAllowed) return null;

        return (
            <div className="FeedBackInPageView">
                <Separator className="content-separator" />
                {this.state.displayError && (
                    <div className="feedbackBox">
                        <Label>{localize("TeknisktFel")}</Label>
                    </div>
                )}
                {this.state.hasPosted && !this.state.displayError && <Label>{localize("FEEDBACK_SUCCESS")}</Label>}
                {!this.state.hasPosted && !this.state.displayError && (
                    <>
                        <Label as="p" size="body-2">{localize("FEEDBACK_HEADLINE")}</Label>
                        <div className="voteOptions">
                            <Label className="VoteBtn">
                                <DefaultButton
                                    aria-label={localize("FEEDBACK_POSITIVE")}
                                    className={classnames("positive", {
                                        active: this.state.hasVoted && this.state.isPositive,
                                    })}
                                    onClick={() =>
                                        this.setState({
                                            hasVoted: true,
                                            isPositive: true,
                                            isNegative: false,
                                        })
                                    }
                                >
                                    <Visage2Icon icon="like-1" />
                                </DefaultButton>

                                <DefaultButton
                                    aria-label={localize("FEEDBACK_NEGATIVE")}
                                    className={classnames("negative", {
                                        active: this.state.hasVoted && this.state.isNegative,
                                    })}
                                    onClick={() =>
                                        this.setState({
                                            hasVoted: true,
                                            isPositive: false,
                                            isNegative: true,
                                        })
                                    }
                                >
                                    <Visage2Icon icon="dislike" />
                                </DefaultButton>
                            </Label>

                            {this.state.hasVoted && (
                                <div className="feedbackBox">
                                    <FormSection>
                                        <FormControl>
                                            <TextField
                                                label={localize("FEEDBACK_HEADLINE2")}
                                                value={this.state.text}
                                                onChange={(_e, text) => {
                                                    this.setState((state) => ({ text }));
                                                }}
                                                placeholder={localize("Svara")}
                                                multiline={true}
                                                size={1024}
                                            />
                                        </FormControl>
                                    </FormSection>
                                    <FormControl>
                                        <PrimaryButton onClick={this.postFeedback}>{localize("Skicka")}</PrimaryButton>
                                    </FormControl>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default FeedBackInPageView;
