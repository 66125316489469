import React, { Component, ReactNode } from "react";
import { SpintrUser } from "src/ui";
import Icon from "src/ui/components/Icon/Icon";
import ITokenizedInputItem from "./ITokenizedInputItem";
import "./TokenizedInputElement.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";

export type TokenizedInputElementHandler = (item: ITokenizedInputItem) => void;

export interface ITokenizedInputElementProps {
    item: ITokenizedInputItem;
    onRemove: TokenizedInputElementHandler;
    onSelect: TokenizedInputElementHandler;
    selected: boolean;
}

class TokenizedInputElement extends Component<ITokenizedInputElementProps> {
    constructor(props: ITokenizedInputElementProps) {
        super(props);

        this.onElementClick = this.onElementClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
    }

    public onElementClick() {
        this.props.onSelect(this.props.item);
    }

    public onRemoveClick() {
        this.props.onRemove(this.props.item);
    }

    public render(): ReactNode {
        const classNames: string[] = ["TokenizedInputElement"];
        if (this.props.selected) {
            classNames.push("selected");
        }

        return (
            <div className={classNames.join(" ")} onClick={this.onElementClick}>
                <div className="item">
                    {
                        <SpintrUser
                            name={this.props.item.name}
                            subText={this.props.item.subText}
                            imageUrl={this.props.item.imageUrl}
                            size={32}
                            hideImage
                            personalName={this.props.item.type === SpintrTypes.UberType.User}
                        />
                    }
                </div>
                <div className="remove-button" onClick={this.onRemoveClick}>
                    <Visage2Icon icon="close-circle" />
                </div>
            </div>
        );
    }
}

export default TokenizedInputElement;
