import { Dropdown, IDropdownOption, Modal, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Label, Loader } from "src/ui";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import PopupHeader from "src/ui/components/PopupHeader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { listActionMenuWidth } from "src/ui/helpers/style";
import { isAnythingDirty, validateRequiredTextField } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Breadcrumbs, PageHeader, UnstyledButton } from "../../ui/components";
import { renderAdminTitle } from "../common/Title";
import "./CompetenceView.scss";

interface IPageRouteParams {
    path: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    userId?: number;
    dispatch?: any;
    isInProfileView?: boolean;
}

interface IState {
    columns: any[];
    editMode: boolean;
    item: any;
    showDetails: boolean;
    onlyDeleted: boolean;
    statuses: any[];
    enableFieldValidation?: boolean;
    saveError?: any;
    isLoading?: boolean;
}

class CompetenceView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    constructor(props: IProps) {
        super(props);

        const statuses = [
            {
                key: 6,
                text: localize("Behov"),
            },
            {
                key: 1,
                text: localize("Anmald"),
            },
            {
                key: 2,
                text: localize("Pagaende"),
            },
            {
                key: 3,
                text: localize("Genomford"),
            },
            {
                key: 4,
                text: localize("EjAktuell"),
            },
            {
                key: 5,
                text: localize("Framskjuten"),
            },
        ];

        const columns = [
            {
                key: "column00",
                name: "",
                fieldName: "",
                minWidth: 15,
                maxWidth: 15,
                data: "string",
                onRender: (item: any) => {
                    return (
                        <span>
                            <UnstyledButton onClick={() => {
                                this.setState({ showDetails: true, item });
                            }}>
                                <Visage2Icon icon={"add-circle"} />
                            </UnstyledButton>
                        </span>
                    );
                },
            },
            {
                key: "column0",
                name: localize("Datum"),
                fieldName: "date",
                maxWidth: 80,
                data: "number",
                isPadded: true,
            },
            ...(!this.props.userId
                ? [
                    {
                        key: "column1",
                        name: localize("Avdelning"),
                        fieldName: "department",
                        minWidth: 90,
                        maxWidth: 120,
                        data: "string",
                    },
                    {
                        key: "column2",
                        name: localize("Anvandare"),
                        fieldName: "user",
                        minWidth: 90,
                        maxWidth: 120,
                        data: "string",
                    },
                ]
                : []),
            {
                key: "column3",
                name: localize("Titel"),
                fieldName: "title",
                data: "string"
            },
            {
                key: "column4",
                name: localize("Status"),
                fieldName: "statusName",
                data: "string",
            },
            {
                key: "column6",
                name: "",
                fieldName: "",
                minWidth: listActionMenuWidth,
                maxWidth: listActionMenuWidth,
                data: "string",
                onRender: (item: any) => {
                    return (
                        <ActionMenu
                            categories={[
                                {
                                    items: [
                                        {
                                            key: "edit",
                                            text: localize("Redigera"),
                                            onClick: () => {
                                                this.setState({ item, editMode: true });
                                            },
                                        },
                                        {
                                            key: "archive",
                                            text: item.deleted ? localize("Aterstall") : localize("Arkivera"),
                                            onClick: () => {
                                                api
                                                    .post(`/api/v1/competence/${item.id}/toggleDeleted`)
                                                    .then((_response) => {
                                                        this.listRef.current.reFetch();
                                                    });
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />
                    );
                },
            },
        ];

        this.state = {
            editMode: false,
            item: undefined,
            showDetails: false,
            onlyDeleted: false,
            columns,
            statuses,
        };
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    private fetch = (skip, take, columnId, isAscending, searchQuery) => {
        return new Promise(async (resolve, reject) => {
            const response = await api.get(`/api/v1/competence`, {
                params: {
                    userId: this.props.userId,
                    orderByColumn: columnId,
                    onlyDeleted: this.state.onlyDeleted,
                    searchText: searchQuery,
                    isAscending,
                    take,
                    skip,
                },
            });

            const competences = response.data.map((competence) => {
                let status = this.state.statuses.find((s) => s.key === competence.status);

                return {
                    ...competence,
                    date: this.formatDate(competence.date),
                    statusName: status ?
                        status.text :
                        "",
                }
            });

            resolve({
                data: competences,
                totalCount: response.headers["x-total-count"],
            });
        });
    };

    private renderCompetences = () => {
        const { onlyDeleted } = this.state;
        return (
            <>
                <SpintrList renderCommandBarAsHeader={!this.props.isInProfileView}
                    ref={this.listRef}
                    onRowClick={(item) => {
                        this.setState({ showDetails: true, item });
                    }}
                    fetch={this.fetch}
                    columns={this.state.columns}
                    orderByColumn="date"
                    buttons={[
                        {
                            key: "add",
                            text: localize("LaggTillNy"),
                            onClick: () => {
                                this.setState({
                                    editMode: true,
                                    item: {
                                        title: "",
                                        status: 0,
                                        text1: "",
                                        text2: "",
                                        text3: "",
                                        text4: "",
                                        text5: "",
                                        isNew: true,
                                        userId: this.props.userId,
                                        date: new Date(),
                                    },
                                });
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                        },
                    ]}
                    optionItems={{
                        items: [
                            {
                                key: "exportList",
                                text: localize("ExporteraLista"),
                                onClick: () => {
                                    //@ts-ignore
                                    window.location =
                                        "/api/v1/servefile/competence?s=" +
                                        this.listRef.current.getSearchText() +
                                        "&onlyDeleted=" +
                                        onlyDeleted;
                                },
                            },
                            {
                                key: "showCurrent",
                                text: onlyDeleted ? localize("Aktuella") : localize("Arkiverade"),
                                onClick: () => {
                                    this.setState({ onlyDeleted: !onlyDeleted }, () => {
                                        this.listRef.current.reFetch();
                                    });
                                },
                            },
                        ],
                    }}
                />
                {/* <div className="buttons" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                        <PrimaryButton
                            onClick={() => {
                                this.setState({ onlyDeleted: !onlyDeleted }, () => {
                                    this.fetch();
                                });
                            }}
                        >
                            {onlyDeleted ? localize("Aktuella") : localize("Arkiverade")}
                        </PrimaryButton>
                    </div>
                </div> */}
            </>
        );
    };

    private formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    private onChangeText = (ev, val) => {
        const name = ev.target.name;
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                [name]: val,
            },
        }));
    };

    private renderEditForm = () => {
        const { item } = this.state;

        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        return (
            <>
                {this.state.saveError && this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <form>
                    <FormSection>
                        <FormControl>
                            <TextField
                                label={localize("Titel")}
                                name="title"
                                value={item.title}
                                onChange={this.onChangeText}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <Dropdown
                                label={localize("Status")}
                                selectedKey={item.status}
                                onChange={(event: React.FormEvent<HTMLDivElement>, i: IDropdownOption) => {
                                    this.setState((state) => ({
                                        item: { ...state.item, status: i.key },
                                    }));
                                }}
                                options={this.state.statuses}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Kompetensbehov")}
                                value={item.text1}
                                name="text1"
                                onChange={this.onChangeText}
                                placeholder={localize("CompetenceText1")}
                                multiline={true}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Utbildningsinnehall")}
                                value={item.text2}
                                name="text2"
                                onChange={this.onChangeText}
                                placeholder={localize("CompetenceText2")}
                                multiline={true}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("TypAvUtbildning")}
                                value={item.text3}
                                name="text3"
                                onChange={this.onChangeText}
                                placeholder={localize("CompetenceText3")}
                                multiline={true}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Datum")}
                                value={item.text4}
                                name="text4"
                                onChange={this.onChangeText}
                                placeholder={localize("CompetenceText4")}
                                multiline={true}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Tidsatgang")}
                                value={item.text5}
                                name="text5"
                                onChange={this.onChangeText}
                                placeholder={localize("CompetenceText5")}
                                multiline={true}
                            />
                        </FormControl>
                    </FormSection>
                </form>
                <FormFooterBar
                    onCancelClick={() => {
                        let fn = () => {
                            this.setState({ item: undefined, editMode: false });
                        }

                        if (isAnythingDirty()) {
                            this.props.dispatch(setConfirmPopup({
                                isOpen: true,
                                message: localize("UnsavedChangesWarning"),
                                onConfirm: fn
                            }));
                        } else {
                            fn();
                        }
                    }}
                    onSaveClick={() => {
                        this.setState({
                            enableFieldValidation: true,
                            isLoading: true
                        }, () => {
                            api.post("/api/v1/competence", this.state.item).then((_response) => {
                                this.setState({
                                    editMode: false,
                                    item: undefined,
                                    isLoading: false
                                }, () => {
                                    this.listRef.current.reFetch();
                                });
                            }).catch(this.handleCatch.bind(this));
                        });
                    }}
                />
            </>
        );
    };

    private detailsModal = () => {
        const { item } = this.state;
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showDetails}
                onDismiss={() => {
                    this.setState({ showDetails: false });
                }}
            >
                <PopupHeader
                    text={localize("Kompetensutveckling")}
                    onClose={() => {
                        this.setState({ showDetails: false });
                    }} />
                <div className="competenceModal">
                    <FormControl label={localize("Kompetensbehov")}>
                        <Label as="p" size="body-4">
                            {item.text1}
                        </Label>
                    </FormControl>
                    <FormControl label={localize("Utbildningsinnehall")}>
                        <Label as="p">{item.text2}</Label>
                    </FormControl>
                    <FormControl label={localize("TypAvUtbildning")}>
                        <Label as="p">{item.text3}</Label>
                    </FormControl>
                    <FormControl label={localize("Datum")}>
                        <Label as="p">{item.text4}</Label>
                    </FormControl>
                    <FormControl label={localize("Tidsatgang")}>
                        <Label as="p">{item.text5}</Label>
                    </FormControl>
                    <FormControl label={localize("Status")}>
                        <Label as="p">{item.statusName}</Label>
                    </FormControl>

                </div>
            </Modal>
        );
    };

    public render() {
        const { editMode, showDetails } = this.state;
        return (
            <div className="CompetenceView">
                {renderAdminTitle("Kompetensutveckling")}
                <Breadcrumbs useGlobalBreadcrumbs
                    displayInstance
                    items={[
                        {
                            text: localize("Administration"),
                            key: 2,
                            link: "/admin",
                        },
                        {
                            text: localize("Kompetensutveckling"),
                            key: 3,
                            link: window.location.pathname
                        },
                    ]}
                />
                {showDetails && this.detailsModal()}
                <PageHeader
                    title={localize("Kompetensutveckling")}
                    displayMandatoryText
                    subText={localize("PAGE_INFO_COMPETENCEDEVELOPMENT")}
                    useShowMoreSubText />
                {/* <div className="mandatoryText">
                    <Label as="h1" size="h2">
                        {localize("Kompetensutveckling")}
                    </Label>
                    <Label as="p">
                        <ShowMoreText
                            lines={1}
                            more={localize("VisaMer")}
                            less={localize("VisaMindre")}
                            expanded={false}
                            width={480}
                        >
                            {localize("PAGE_INFO_COMPETENCEDEVELOPMENT")}
                        </ShowMoreText>
                    </Label>
                    <MandatoryText />
                </div> */}
                {/* {this.props.userId && (
                    <PrimaryButton
                        onClick={() => {
                            this.setState({
                                editMode: true,
                                item: {
                                    title: "",
                                    status: 0,
                                    text1: "",
                                    text2: "",
                                    text3: "",
                                    text4: "",
                                    text5: "",
                                    isNew: true,
                                    userId: this.props.userId,
                                    date: new Date(),
                                },
                            });
                        }}
                    >
                        {localize("LaggTillNy")}
                    </PrimaryButton>
                )} */}
                {editMode ? this.renderEditForm() : this.renderCompetences()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props
    };
};

export default withRouter(connect(mapStateToProps)(CompetenceView));
