import { ITheme, loadTheme } from "@fluentui/react";
import { createContext } from "react";

import Color from "./colors/Color";
import { Style } from "src/ui/helpers";

export const produceTheme = (primaryColor: string): ITheme => {
    const eloquiaFont: string = '"Eloquia", sans-serif';
    const color = Color.fromHex(primaryColor);

    return loadTheme({
        defaultFontStyle: {
            fontFamily: eloquiaFont
        },

        fonts: {
            large: { fontFamily: eloquiaFont },
            medium: { fontFamily: eloquiaFont },
            mediumPlus: { fontFamily: eloquiaFont },
            mega: { fontFamily: eloquiaFont },
            small: { fontFamily: eloquiaFont },
            smallPlus: { fontFamily: eloquiaFont },
            superLarge: { fontFamily: eloquiaFont },
            tiny: { fontFamily: eloquiaFont },
            xLarge: { fontFamily: eloquiaFont },
            xLargePlus: { fontFamily: eloquiaFont },
            xSmall: { fontFamily: eloquiaFont },
            xxLarge: { fontFamily: eloquiaFont },
            xxLargePlus: { fontFamily: eloquiaFont },
        },

        palette: {
            themeDark: color.darken(30).toString("hex"),
            themeDarkAlt: color.darken(15).toString("hex"),
            themeDarker: color.darken(50).toString("hex"),
            themeLight: Style.getHexFromSpintrColor("dusk-grey"),
            themeLighter: color.lighten(10).toString("hex"),
            themeLighterAlt: color.lighten(12.5).toString("hex"),
            themePrimary: color.toString("hex"),
            themeSecondary: color.lighten(2.5).toString("hex"),
            themeTertiary: color.lighten(5).toString("hex"),
        }
    });
};

export const defaultTheme: ITheme = produceTheme("#e6007e");

const ThemeContext = createContext(defaultTheme);

export default ThemeContext;