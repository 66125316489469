import { TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Label, Loader, PageHeader } from "src/ui";
import { localize } from "src/l10n";
import { FormSection, FormControl, FormTokenizedObjectInput, FormFooterBar } from "src/ui/components/Forms";
import { RouteChildrenProps, withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import api from "src/spintr/SpintrApi";
import { connect } from "react-redux";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";

interface IPageRouteParams {
    path: string;
    id: any;
}

interface Props extends RouteChildrenProps<IPageRouteParams> {
    isAdmin: boolean;
    isEditor: boolean;
}
interface State {
    isLoading: boolean;
    question: any;
    saveSuccess: boolean;
    saveError: string[];
    enableFieldValidation?: boolean;
    canCreate: boolean;
    canAnswer: boolean;
}

class QAEditView extends Component<Props, State> {
    private tinyRef = React.createRef<any>();

    state = {
        isLoading: true,
        question: {
            id: 0,
            tags: [],
        } as any,
        saveSuccess: false,
        saveError: [],
        enableFieldValidation: false,
        canCreate: false,
        canAnswer: false
    };

    componentDidMount = () => {
        const { id } = this.props.match.params;

        api.get("/api/v1/qa/settings").then((response) => {
            const { data } = response;
            const { isAdmin, isEditor } = this.props;

            let canCreate =
                data.createPrivilegesLevel === 0 ||
                (data.createPrivilegesLevel === 1 && (isEditor || isAdmin)) ||
                (data.createPrivilegesLevel === 2 && isAdmin);

            let canAnswer =
                data.answerPrivilegesLevel === 0 ||
                (data.answerPrivilegesLevel === 1 && (isEditor || isAdmin)) ||
                (data.answerPrivilegesLevel === 2 && isAdmin);

            this.setState({ canCreate, canAnswer });
        });

        if (id) {
            this.setState({ isLoading: true }, () => {
                api.get(`/api/v1/qa/${id}`).then((response) => {
                    this.setState({ question: response.data, isLoading: false });
                });
            });
        } else {
            this.setState({ isLoading: false });
        }


    };

    onCancel = () => {
        this.props.history.push({
            pathname: `/qa`,
        });
    };

    onSubmit = () => {
        this.setState({
            enableFieldValidation: true
        });
        let payload = {
            ...this.state.question,
            answer: this.tinyRef.current.getContent(),
        };

        payload.tagString = payload.tags.join(",");
        // set tagString
        // Redirect to list after creating new question.

        this.setState({ isLoading: true, question: payload }, () => {
            api
                .post(`/api/v1/qa/${payload.id}`, payload)
                .then((response) => {
                    this.setState({ isLoading: false }, () => {
                        this.props.history.push({
                            pathname: `/qa`,
                        });
                    });
                })
                .catch((saveError) => {
                    scrollToTop();
                    this.setState({ saveError: saveError.response.data.errorlist, isLoading: false });
                });
        });
    };

    render() {
        const { question, isLoading, saveError, saveSuccess } = this.state;
        if (isLoading) return <Loader />;

        return (
            <div className="QAEditView">
                <Helmet>
                    <title>{localize("appQA")}</title>
                </Helmet>
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                <PageHeader title={question.id === 0 ? localize("LaggTillFraga") : localize("RedigeraFraga")} />
                <form>
                    <FormSection>
                        <FormControl>
                            <TextField
                                maxLength={1024}
                                label={localize("Fraga")}
                                value={question.question}
                                onChange={(_e, question) => {
                                    this.setState((state) => ({ question: { ...state.question, question } }));
                                }}
                                required={true}
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>

                        <FormControl>
                            {this.state.canAnswer && <TinyEditorComponent
                                    label={localize("Svar")}
                                    ref={this.tinyRef}
                                    id={"23"}
                                    content={question.answer}
                                 />
                            }
                        </FormControl>

                        <FormControl>
                            <FormTokenizedObjectInput
                                hideImage={true}
                                enableNewItems={true}
                                //@ts-ignore
                                items={question.tags.map((tag) => {
                                    return {
                                        name: tag,
                                    };
                                })}
                                label={localize("SokBlandTaggar")}
                                onChange={(tags) => {
                                    this.setState((state) => ({
                                        question: {
                                            ...state.question,
                                            tags: tags.map((t) => {
                                                return t.name;
                                            }),
                                        },
                                    }));
                                }}
                                noAutocomplete
                            />
                        </FormControl>
                    </FormSection>
                </form>
                <FormFooterBar onCancelClick={this.onCancel} onSaveClick={this.onSubmit} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
    };
};

export default withRouter(connect(mapStateToProps)(QAEditView));
