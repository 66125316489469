import React, { Component, ReactNode } from "react";
import { Swipeable } from "react-swipeable";
import { Icon, Label } from "src/ui";
import { Style } from "src/ui/helpers";
import { UnstyledButton } from "../Buttons";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./CenteredAddButton.scss";

interface IProps {
    onClick: any;
    text: string;
}

const CenteredAddButton = (props: IProps) => {
    return (
        <div
            className="CenteredAddButton"
            style={{
                textAlign: "center",
                position: "relative",
                height: "40px",
                marginBottom: "6px",
                display: "inline-block",
                width: "100%"
            }}
        >
            <div
                style={{
                    borderBottomWidth: 1,
                    borderBottomColor: Style.getHexFromSpintrColor("visageMidBlue"),
                    borderBottomStyle: "solid",
                    top: "calc(50% - 1px)",
                    position: "absolute",
                    width: "100%",
                    boxSizing: "border-box",
                    cursor: "pointer",
                }}
            ></div>
            <div
                style={{
                    display: "inline-block",
                    position: "relative",
                    transform: "translateY(-50%)",
                    msTransform: "translateY(-50%)",
                    top: "50%",
                }}
            >
                <div
                    className="CenteredAddButton-button"
                    onClick={props.onClick}
                >
                    <Label as="span" className="addPreambleText" size="body-2" weight="medium" color="visageMidBlue">
                        {props.text}
                    </Label>
                    <Visage2Icon icon="add" color="light-blue" />
                </div>
            </div>
        </div>
    )
}

export default CenteredAddButton;