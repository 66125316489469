import React, { Component } from 'react';
import { localize } from 'src/l10n';
import { Icon, Label } from "src/ui";
import "./ErrorPage.scss";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { PrimaryButton } from '@fluentui/react';

interface IProps {
    history: any;
}

class ErrorPage extends Component<IProps> {
    render() {
        return (
            <div className="ErrorPage">
                <div className="ErrorPage-icon-wrapper">
                    <Visage2Icon icon="triangle" color="grey" size="extra-big" />
                </div>
                <div className="ErrorPage-headline-wrapper" tabIndex={0}>
                    <Label
                        as="div"
                        className="ErrorPage-headline"
                        size="h1"
                        role="heading"
                        color="dark-grey">
                        {
                            localize("SidanHittadesInte")
                        }
                    </Label>
                </div>
                <div className="ErrorPage-text-wrapper" tabIndex={0}>
                    <Label
                        as="p"
                        className="ErrorPage-text"
                        color="grey">
                        {
                            localize("InnehalletKundeInteVisasDettaKanBero")
                        }
                    </Label>
                    <Label
                        as="p"
                        className="ErrorPage-text"
                        size="body-2"
                        color="grey">
                        {
                            localize("EjPubliceratInnehall")
                        }
                    </Label>
                    <Label
                        as="p"
                        className="ErrorPage-text"
                        size="body-2"
                        color="grey">
                        {
                            localize("BorttagetInnehall")
                        }
                    </Label>
                    <Label
                        as="p"
                        className="ErrorPage-text"
                        size="body-2"
                        color="grey">
                        {
                            localize("SaknarRattigheter")
                        }
                    </Label>
                    <PrimaryButton
                        className='homepage-button'
                        text={localize("GA_TILL_STARTSIDAN")}
                        onClick={() => {
                            this.props.history.push('/')
                        }} />
                </div>
            </div>
        )
    }
}

export default ErrorPage;
