import moment from "moment";
import { PrimaryButton } from '@fluentui/react';
import { DefaultButton } from "@fluentui/react/lib/Button";
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import * as React from 'react';
import { localize } from "src/l10n";
import ThemeContext from "src/style/ThemeContext";
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import { uniqueId } from "src/utils";
import api from "../SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";

interface IState {
    items: any;
    isLoading: boolean;
    isHidingDialog: boolean;
    columns: IColumn[];
}

interface IProps {
    uberId: number;
    onContentVersionSelected: any;
    isNews?: boolean;
}

export default class ContentVersionsList extends React.Component<IProps, IState> {
    public static contextType = ThemeContext;
    private listRef = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoading: true,
            isHidingDialog: true,
            columns: this.getColumns()
        };
    }

    getColumns() {
        return [{
            key: uniqueId(),
            name: "",
            minWidth: 60,
            maxWidth: 60,
            isCollapsible: false,
            onRender: (item) => {
                return (
                    <DefaultButton
                        styles={{root: {
                            minWidth: 40,
                        }}}
                        menuProps={{
                            items: [{
                                key: uniqueId(),
                                text: localize("Aktivera"),
                                onClick: () => {
                                    this.props.onContentVersionSelected(item);
                                }
                            }]
                        }}
                    />
                )
            }
        }, {
            key: uniqueId(),
            name: localize("Titel"),
            fieldName: 'Headline',
            minWidth: 100
        }, {
            key: uniqueId(),
            name: localize("Typ"),
            fieldName: 'Type',
            minWidth: 70,
            maxWidth: 90,
            onRender: (item) => {
                return (
                    <span>
                        {
                            item.Type === 1 ?
                                localize("Version") :
                                localize("Utkast")
                        }
                    </span>
                )
            }
        }, {
            key: uniqueId(),
            name: localize("Skapad"),
            fieldName: 'CreatedDate',
            minWidth: 120,
            onRender: (item) => {
                return (
                    <span>
                        {
                            moment(item.CreatedDate).format("L") +
                            " " +
                            moment(item.CreatedDate).format("LT")
                        }
                    </span>
                )
            }
        }];
    }

    showDialog() {
        this.setState({
            isHidingDialog: false
        });
    };

    closeDialog() {
        this.setState({
            isHidingDialog: true
        });
    };

    public render() {
        return (
            <div>
                <SpintrList
                    ref={this.listRef}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            let url = "";

                            if (this.props.isNews) {
                                url = `/api/v1/news/${this.props.uberId}/versions`
                            } else {
                                url = "/api/v1/pages/" + this.props.uberId + "/versions";
                            }

                            api.get(url, {
                                params: {
                                    take: take,
                                    skip: skip,
                                    type: 0
                                },
                            }).then((response) => {
                                resolve({
                                    data: response.data,
                                    totalCount: response.headers["x-total-count"],
                                });
                            });
                        });
                    }}
                    columns={this.state.columns}
                    orderByColumn="name"
                ></SpintrList>
                <Dialog
                    hidden={this.state.isHidingDialog}
                    containerClassName="dialogWithPopupHeader"
                    onDismiss={this.closeDialog.bind(this)}
                    modalProps={{
                        isBlocking: true,
                        allowTouchBodyScroll: true
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: localize("WARNING"),
                        closeButtonAriaLabel: localize("Stang"),
                        subText: localize("TeknisktFel")
                    }}>
                    <PopupHeader
                        text={localize("WARNING")}
                        subText={localize("TeknisktFel")}
                        onClose={this.closeDialog.bind(this)} />
                    <DialogFooter>
                        <PrimaryButton
                            theme={this.context}
                            onClick={this.closeDialog.bind(this)}
                            text={localize("Ok")} />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}
