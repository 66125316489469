import api from "src/spintr/SpintrApi";

export const loadPrioritizedBlogs = (params = {}) => 
    api.get<Spintr.IBlogPostsEnvelope>("/api/v1/blogposts/priority", { params });
    
export interface IBlogPostQueryParams {
    blogId?: number;
    includeDeleted?: boolean;
    isAscending?: boolean;
    skip?: number;
    take?: number;
    orderByColumn?: string;
    tagId?: number;
}

export const loadBlogPostFeed = (params : IBlogPostQueryParams = {}) => 
    api.get<Spintr.IBlogPostsEnvelope>("/api/v1/blogposts", { params });