import { DirectionalHint, Icon as FabricIcon } from "@fluentui/react";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React, { Component } from 'react';
import { Icon, Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    actions: any,
    disableHoverEffects?: boolean,
    displayBorder?: boolean
    displayActionsAtBottom?: boolean;
    displayActionsVertically?: boolean;
}

interface IState {
    isHoveringActionIndex: string;
    displayChildrenForIndex: string;
    isHoveringTooltip: boolean;
}

class DivWithHoverActions extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isHoveringActionIndex: "-1",
            displayChildrenForIndex: "-1",
            isHoveringTooltip: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            isHoveringActionIndex: nextProps.disableHoverEffects ?
                -1 :
                prevState.isHoveringActionIndex
        }
    }

    onClick (item, index?) {
        if (!!item.children) {
            if (this.state.displayChildrenForIndex === index) {
                this.setState({
                    displayChildrenForIndex: "-1"
                });
            } else {
                this.setState({
                    displayChildrenForIndex: index
                });
            }

            return;
        }

        this.setState({
            displayChildrenForIndex: "-1"
        });

        if (item.onClick) {
            return item.onClick();
        }
    }

    hexToUnicode(input) {
        const code = input - 0x10000;
        const high = (code >> 10) + 0xD800;
        const low = (code & 0x3FF) + 0xDC00;
        const output = String.fromCharCode(high, low);

        return output;
    };

    renderIcon(item, index) {
        if (!!item.fabricIcon) {
            return <FabricIcon iconName={item.fabricIcon} />

        } else if (!!item.icon) {
            return (
                <Visage2Icon
                    icon={item.icon}
                    color={!!item.isActive ? 'light-blue' : (this.state.isHoveringActionIndex === index ? "dark-grey" : "grey")} />
            )
        }

        if (!!item.reactionId) {
            const text = this.hexToUnicode("0x" + item.reactionId.toString(16));

            return (
                <div className="divWithHoverActionsActionReaction">
                    {
                        text
                    }
                </div>
            )
        }

        return null;
    }

    render() {
        let className = "divWithHoverActions";

        if (this.props.displayBorder) {
            className += " displayBorder";
        }

        if (!this.props.disableHoverEffects) {
            className += " displayActions";
        }

        if (this.props.displayActionsAtBottom) {
            className += " displayActionsAtBottom"
        }

        if (this.props.displayActionsVertically) {
            className += " displayActionsVertically";
        }

        if (this.state.isHoveringTooltip) {
            className += " isHoveringTooltip";
        }

        const actionGroups = Array.isArray(this.props.actions[0]) ? this.props.actions : [this.props.actions];

        return (
            <div className={className}>
                {
                    this.props.children
                }
                <div className="divWithHoverActionsContainer">
                    <div className="divWithHoverActionsActions">
                        {actionGroups.map((groupItem, groupIndex) => {
                            return (
                                <div className="divWithHoverActionsActionsGroup" key={groupIndex}>
                                    {groupItem.map((item, idx) => {
                                        const index = `${groupIndex}-${idx}`;
                                        const Element = item.parent || UnstyledButton;
                                        return (
                                            <div
                                                className="divWithHoverActionsAction no-user-select"
                                                onMouseOver={() => {
                                                    this.setState({
                                                        isHoveringActionIndex: index,
                                                    });
                                                }}
                                                onMouseOut={() => {
                                                    this.setState({
                                                        isHoveringActionIndex: "-1",
                                                    });
                                                }}
                                                key={index}
                                            >
                                                <TooltipHost
                                                    content={item.title}
                                                    directionalHint={
                                                        this.props.displayActionsVertically
                                                            ? DirectionalHint.leftCenter
                                                            : DirectionalHint.topCenter
                                                    }
                                                    onMouseOver={() => {
                                                        this.setState({ isHoveringTooltip: true });
                                                    }}
                                                    onMouseOut={() => {
                                                        this.setState({ isHoveringTooltip: false });
                                                    }}
                                                >
                                                    <Element
                                                        className="divWithHoverActionsActionButton"
                                                        onClick={this.onClick.bind(this, item, index)}
                                                    >
                                                        <Label size="small-1">{this.renderIcon(item, index)}</Label>
                                                    </Element>
                                                </TooltipHost>
                                                {this.state.displayChildrenForIndex === index ? (
                                                    <div className="divWithHoverActionsActionChildren box-shadow-and-border">
                                                        {item.children.map((child, childIndex) => {
                                                            return (
                                                                <div
                                                                    key={childIndex}
                                                                    className="divWithHoverActionsActionChild"
                                                                >
                                                                    <UnstyledButton
                                                                        className="divWithHoverActionsActionButton"
                                                                        onClick={this.onClick.bind(this, child)}
                                                                    >
                                                                        <div className="divWithHoverActionsActionChildInner">
                                                                            <div className="divWithHoverActionsActionChildIcon">
                                                                                <Label size="small-1">
                                                                                    {this.renderIcon(
                                                                                        child,
                                                                                        "child" + childIndex
                                                                                    )}
                                                                                </Label>
                                                                            </div>
                                                                            <div className="divWithHoverActionsActionChildText">
                                                                                <Label size="small-1">
                                                                                    {child.title}
                                                                                </Label>
                                                                            </div>
                                                                        </div>
                                                                    </UnstyledButton>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default DivWithHoverActions
