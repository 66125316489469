import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { getTypeName, localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const UserLeftFeedbackNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IUserLeftFeedbackNotification;
    if (!notification) {
        return null;
    }

    const typeText = getTypeName(notification.objectType, {
        case: "possessive"
    });
    
    return (
        <Fragment>
            {
                localize("EnAnvandareHarLamnatFeedbackPa")
                + " " 
                + typeText
                + " "
            }
            <Link to={notification.url}>
                {notification.objectName}
            </Link>
        </Fragment>
    );
};

export default UserLeftFeedbackNotification;