import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { capitalizeFirstLetter } from "src/utils";
import "./SocialGroup.scss";

interface IProps {
    post: Spintr.ISocialGroup;
}

const SocialGroup: FunctionComponent<IProps> = (props) => (
    <div className="SocialGroup">
        <Label
            as="p"
            color="mid-grey"
            size="body-2">
            <span>
                {capitalizeFirstLetter(localize("skapadeGruppen_small"))}
            </span> <Link to={props.post.groupUrl}>
                {props.post.groupName}
            </Link>.
        </Label>
    </div>
);

export default SocialGroup;
