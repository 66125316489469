import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { setOverlayId } from "src/ui";
import { IApplicationState } from "../reducer";

interface IProps {
    overlayId?: string;
    dispatch?: any;
}

class SpintrOverlay extends Component<IProps> {
    constructor(props) {
        super(props);
    }

    onClick() {
        this.props.dispatch(setOverlayId(""));
    }

    public render() : ReactNode {
        if (!this.props.overlayId) {
            return null;
        }

        return (
            <div className="SpintrOverlay" onClick={this.onClick.bind(this)} />
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        overlayId: state.ui.overlayId
    };
};

export default connect(mapStateToProps)(SpintrOverlay);
