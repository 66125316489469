import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";
import api from "src/spintr/SpintrApi";
import ActionTypes from "./action-types";
import { IPostQuestionResponse } from "./types";

export interface IPostQuestionMetadata {
    question: string;
}

export interface IPostQuestionAction extends Action {
    meta: IPostQuestionMetadata;
    payload: Promise<AxiosResponse<IPostQuestionResponse>>;
    type: ActionTypes.PostQuestion,
}

export interface IPostQuestionPendingAction extends Action {
    meta: IPostQuestionMetadata;
    payload: Promise<AxiosResponse<IPostQuestionResponse>>;
    type: ActionTypes.PostQuestionPending,
}

export interface IPostQuestionRejectedAction extends Action {
    meta: IPostQuestionMetadata;
    payload: AxiosError;
    type: ActionTypes.PostQuestionRejected,
}

export interface IPostQuestionFulFilledAction extends Action {
    meta: IPostQuestionMetadata;
    payload: AxiosResponse<IPostQuestionResponse>;
    type: ActionTypes.PostQuestionFulFilled,
}

export interface ISetActiveSubjectDate extends Action {
    activeSubjectDate: Date;
    type: ActionTypes.SetActiveSubjectDate;
}

export interface ISetActiveSubjects extends Action {
    activeSubjects: string[];
    type: ActionTypes.SetActiveSubjects;
}

export type SetActiveSubjectDateHandler
    = (activeSubjectDate: Date) => ISetActiveSubjectDate;

export type SetActiveSubjectsHandler
    = (activeSubjects: string[]) => ISetActiveSubjects;

export type PostQuestionActionHandler
    = (question: string, history?: any[]) => IPostQuestionAction;

export const setActiveSubjectDate: SetActiveSubjectDateHandler
    = (activeSubjectDate: Date) => ({
        activeSubjectDate,
        type: ActionTypes.SetActiveSubjectDate
    });

export const setActiveSubjects: SetActiveSubjectsHandler
    = (activeSubjects: string[]) => ({
        activeSubjects,
        type: ActionTypes.SetActiveSubjects
    });

export const postQuestionAction: PostQuestionActionHandler
    = (question, history?: any[]) => ({
        meta: { question, },
        payload: api.post(
            "/api/v1/assistant/messages", 
            { message: question, history: (history || []) },
        ),
        type: ActionTypes.PostQuestion,
    });

export type AssistantAction
    = IPostQuestionAction
    | IPostQuestionPendingAction
    | IPostQuestionRejectedAction
    | IPostQuestionFulFilledAction
    | ISetActiveSubjectDate
    | ISetActiveSubjects;

export default AssistantAction;
