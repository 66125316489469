import { TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { Loader, PageHeader } from 'src/ui';
import { FormFooterBar } from 'src/ui/components/Forms';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/ui/components/Messagebars/SuccessMessagebar';
import { Style } from 'src/ui/helpers';
import { validateRequiredTextField } from 'src/utils';
import api from 'src/spintr/SpintrApi';

interface IPageRouteParams {
    path: string;
    id: any;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    instance?: any;
    currentUser?: any;
}

interface IState {
    isLoading: boolean;
    item?: any;
    enableFieldValidation?: boolean;
    saveError?: any;
    saveSuccess?: boolean;
}

class SuppliersRolesEditView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        if (id) {
            this.setState({ isLoading: true }, () => {
                api.get(`/api/suppliers/roles`).then((response) => {
                    const role = response.data.find((item: any) => item.id === parseInt(id));

                    if (!role) {
                        this.setState({
                            isLoading: false,
                            item: {
                                id: 0,
                                name: ""
                            }
                        });

                        return;
                    }

                    this.setState({
                        item: role,
                        isLoading: false
                    });
                });
            });
        } else {
            this.setState({
                isLoading: false,
                item: {
                    id: 0,
                    name: ""
                }
            });
        }
    }

    goBack() {
        this.props.history.push({
            pathname: `/suppliers/roles`,
        });
    }

    onCancelClick() {
        this.goBack();
    }

    onSaveClick() {
        this.setState({
            isLoading: true
        }, () => {
            api.post("/api/suppliers/roles/" + this.state.item.id, this.state.item).then(() => {
                this.goBack();
            }).catch(this.handleCatch.bind(this));
        });
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    render() {
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        return (
            <div className="SuppliersView">
                {this.state.saveSuccess && (
                    <div style={{
                        marginBottom: Style.getSpacing(3)
                    }}>
                        <SuccessMessagebar
                            message={localize("SparningenLyckades")}
                            onDismiss={() => {
                                this.setState({
                                    saveSuccess: false
                                });
                            }}
                        />
                    </div>
                )}
                {!!this.state.saveError && this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PageHeader
                    title={!this.state.item.id ? localize("SkapaRoll") : localize("RedigeraRoll")}
                    displayMandatoryText />
                <form>
                    <TextField
                        value={this.state.item.name}
                        className="textField"
                        label={localize("Namn")}
                        required
                        aria-required
                        validateOnFocusIn
                        validateOnFocusOut
                        validateOnLoad={!!this.state.enableFieldValidation}
                        onGetErrorMessage={validateRequiredTextField}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                            this.setState({
                                item: {
                                    ...this.state.item,
                                    name: newValue,
                                },
                            });
                        }}
                    />
                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize("Spara")}
                    />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersRolesEditView));
