import { Icon } from "@fluentui/react";
import classNames from "classnames";
import React, {
    FunctionComponent,
    useEffect,
    useCallback,
    useContext,
    useState,
    useMemo
} from "react";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { Label, UnstyledButton } from "src/ui";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import { StartPageBuilderComponent } from "../StartPageBuilderComponent";
import { StartPageBuilderContext } from "../StartPageBuilderContext";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    column: Spintr.IStartPageColumn;
}

const localizationStrings = [
    "Andra",
    "CONFIRM_OBJECT_DELETION",
    "Titel",
    "DYNAMO_EMPTY_ROW_TEXT"
];

type LocalizationDictionary = { [key: string]: string };

const StartPageBuilderColumn: FunctionComponent<IProps> = (props) => {
    const [nameInputOpen, setNameInputOpen] = useState(false);
    const context = useContext(StartPageBuilderContext);
    const { removeComponent, setColumnName, setDrawerOpen } = context;
    const { id } = props.column;
    const dispatch = useDispatch();

    const l10n = useMemo<LocalizationDictionary>(
        () => localizationStrings.reduce(
            (dict, str) => {
                dict[str] = localize(str);
                return dict;
            },
            {},
        ),
        []
    );

    const itemsLength = props.column.items.length;
    const onRemoveClick = useCallback((componentId: any) => {
        if (typeof componentId !== "string") {
            return;
        }

        const remove = () => {
            removeComponent(componentId);
            mixpanelTrack(MixPanelEvents.Dynamo_RemoveColumn);
        };

        if (itemsLength === 0) {
            remove();
            return;
        }

        dispatch(setConfirmPopup({
            isOpen: true,
            message: l10n["CONFIRM_OBJECT_DELETION"],
            onConfirm: () => remove(),
        }));
    }, [dispatch, l10n, removeComponent, itemsLength]);

    const onClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();

        context.setSelected(id);
    }, [context, id]);

    const isSelected = context.selectedId === id;
    const selfOrChildIsSelected = isSelected || props.column.items.some(
        (item) => item.id === context.selectedId,
    );

    const onNameInputClick = useCallback(
        () => setNameInputOpen(true),
        [setNameInputOpen],
    );

    const onNameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;

            setColumnName(id, value);
        },
        [id, setColumnName],
    );

    const onNameKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>): void => {
            if (event.keyCode !== 13) {
                return;
            }

            setNameInputOpen(false);
            mixpanelTrack(MixPanelEvents.Dynamo_RenameColumn);
        },
        [setNameInputOpen]
    );

    const onPlaceholderClick = useCallback(
        () => {
            setDrawerOpen(true);
            mixpanelTrack(MixPanelEvents.Dynamo_ClickEmptyColumn);
        },
        [setDrawerOpen],
    );

    useEffect(
        () => {
            if (isSelected) {
                return;
            }

            setNameInputOpen(false);
        },
        [isSelected, setNameInputOpen]
    )
    
    return (
        <div
            className={classNames("StartPageBuilderColumn", {
                "selected": selfOrChildIsSelected,
            })}
            onClick={onClick}
            style={{
                flexBasis: props.column.width + "%",
            }}
        >
            {isSelected && (
                <div className="controls-container">
                    <div className="controls">
                        <UnstyledButton
                            className="control edit"
                            onClick={onNameInputClick}
                        >
                            <Visage2Icon
                                color="neutralBlue"
                                icon="Edit"
                                size="small"
                            />
                            <Label size="body-3" weight="medium">{l10n["Andra"]}</Label>
                        </UnstyledButton>
                        <UnstyledButton
                            className="control move"
                        >
                            <Visage2Icon icon="arrow-2" />
                        </UnstyledButton>
                        <UnstyledButton
                            className="control delete"
                            onClick={onRemoveClick}
                            onClickData={id}
                        >
                            <Visage2Icon
                                color="red"
                                icon="Delete"
                                size="small"
                            />
                        </UnstyledButton>
                    </div>
                    {nameInputOpen && (
                        <div className="edit-name">
                            <Label
                                className="heading"
                                color="neutralBlue"
                                size="body-3"
                            >
                                {l10n["Titel"]}
                            </Label>
                            <div className="input">
                                <input
                                    className="title-input fs-body-3"
                                    onChange={onNameChange}
                                    onKeyDown={onNameKeyDown}
                                    type="text"
                                    value={props.column.name || ""}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Droppable
                droppableId={id}
            >
                {({ droppableProps, placeholder, innerRef }, snapshot) => (
                    <div
                        className={
                            "builder-drop-area" +
                            (snapshot.isDraggingOver ? " drop-available" : "")
                        }
                        ref={innerRef}
                        style={{ backgroundColor: snapshot.isDraggingOver ? 'rgba(0, 0, 0, 0.1)' : 'transparent' }}
                        {...droppableProps}
                    >
                        {(props.column.items || []).length === 0 && (
                            <UnstyledButton
                                className="empty-placeholder"
                                onClick={onPlaceholderClick}
                            >
                                <Label
                                    className="text-wrapper"
                                    size="body-2"
                                >
                                    {l10n["DYNAMO_EMPTY_ROW_TEXT"]}
                                </Label>
                            </UnstyledButton>
                        )}
                        {(props.column.items || []).map((item, index) => (
                            <div
                                className="StartPageBuilderColumn-cell"
                                key={item.id}
                            >
                                <StartPageBuilderComponent
                                    component={item}
                                    index={index}
                                />
                            </div>
                        ))}
                        {placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default StartPageBuilderColumn;
