import moment from "moment";
import { Callout, PrimaryButton } from "@fluentui/react";
import React, { Component } from "react";
import Timeline, {
    CustomHeader, DateHeader, TimelineHeaders, TodayMarker
} from "react-calendar-timeline/lib";
import "react-calendar-timeline/lib/Timeline.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import getInterval from "src/bookings/ResourceInterval";
import "src/bookings/ResourceView.scss";
import { hitEnterOrSpace } from "src/files/utils";
import { getLocalizationTag, localize } from "src/l10n";
import Color from "src/style/colors/Color";
import { SpintrUser } from "src/ui";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import SpintrLoader from "../Loader";
import "./CustomTimeline.scss";
import { dateToYMD } from "./Utils";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps extends RouteComponentProps {
    items: any;
    showInfo?: any;
    onClickGroup?: any;
    whichMonth?: any;
    match: any;
    resource: any;
    currentUserId?: number;
    isAdmin: boolean;
    isEditor: boolean;
    year?: string;
    week?: string;
    instance?: any;
}

interface IState {
    hours: any[];
    items: any;
    isLoading: boolean;
    visibleTimeStart: any;
    visibleTimeEnd: any;
    showCallout: any;
    intervals: any;
}
class CustomWeeklyTimeline extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        let visibleTimeStart;
        let visibleTimeEnd;

        if (props.year && props.week) {
            visibleTimeStart = moment(props.year).add(props.week, 'weeks').startOf('isoWeek').valueOf();
            visibleTimeEnd = moment(visibleTimeStart).endOf("isoWeek").valueOf();
        } else {
            visibleTimeStart = moment().startOf("isoWeek").valueOf();
            visibleTimeEnd = moment().endOf("isoWeek").valueOf();
        }

        this.state = {
            isLoading: true,
            hours: [],
            items: [],
            visibleTimeStart,
            visibleTimeEnd,
            showCallout: undefined,
            intervals: [],
        };
    }

    componentDidMount() {
        let tag = getLocalizationTag();
        var moment = require("moment");

        switch (tag) {
            case "sv":
                require("moment/locale/sv");
                break;
            case "no":
                require("moment/locale/nb");
                break;
            case "en":
                require("moment/locale/en-gb");
                break;
            case "de":
                require("moment/locale/de");
                break;
            case "fi":
                require("moment/locale/fi");
                break;
        }

        this.fetch(this.state.visibleTimeStart, this.state.visibleTimeEnd);
    }

    getIntervalFromResources(intervals) {
        return (
            intervals
                .map((item) => {
                    return {
                        title: `${item.start} - ${item.end}`,
                        group: item.id,
                        id: item.id,
                        startHour: item.start,
                        endHour: item.end,
                        availability: item.availability,
                    };
                })
                // .filter((v, i, a) => a.findIndex((t) => t.availability === v.availability && t.group === v.group) === i)
                .map((obj) => getInterval(obj, this.state.visibleTimeStart))
                .flat()
        );
    }

    fetch = (after, before) => {
        this.setState({ isLoading: true }, () => {
            api
                .get(`/api/v1/bookingintervals`, {
                    params: {
                        resourceId: this.props.resource.id,
                        after: new Date(after),
                        before: new Date(before),
                        take: 9999
                    },
                })
                .then((response) => {
                    this.setState({
                        intervals: response.data.sort((a, b) => (a.start > b.start ? 1 : -1)),
                        hours: response.data.map((interval) => {
                            return { id: interval.id, name: "" };
                        }),
                        isLoading: false,
                        items: this.getIntervalFromResources(response.data),
                    });
                });
        });
    };

    getBookingByDateBookedOnInterval = (date: Date, intervalId: number) => {
        return this.state.intervals
            .find((i) => {
                return i.id === intervalId;
            })
            .bookings.find((b) => {
                return b.date === dateToYMD(date);
            });
    };

    itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        item.booking = this.getBookingByDateBookedOnInterval(moment(item.start_time).toDate(), item.group);

        const itemProps = getItemProps({
            onTouchEnd: (e) => {
                this.showReserveItem(item);
            },
            onMouseUp: (e) => {
                this.showReserveItem(item);
            },
            onKeyUp: (e) => {
                hitEnterOrSpace(e, this.showReserveItem(item))
            },

        });

        const activeColor = this.props.instance.get('color');
        const defaultColor = Color.fromHex(activeColor).customLighten().toString("hex");
        const backgroundColor= item.booking ?
            activeColor :
            defaultColor;

        return (
            <div
                {...itemProps}
                style={{
                    ...itemProps.style,
                    width: itemContext.dimensions.width - 8,
                    background: backgroundColor,
                    border: "1px solid transparent",
                    color: getLightOrDarkColorBasedOnColor(backgroundColor, "#FFF", "#000"),
                    height: itemContext.dimensions.height,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                    textAlign: "center",
                }}
                id={`item-${item.id}`}
            >
                {itemContext.title}
            </div>
        );
    };
    groupRenderer = ({ group }) => {
        return (
            <div className="custom-group">
                <span style={{ cursor: "pointer" }} onClick={() => this.onClickGroup(group.id)} className="title">
                    {group.title}{" "}
                </span>
            </div>
        );
    };
    onPrevClick = () => {
        const { resource } = this.props;

        let newVisibleTimeStart = moment(this.state.visibleTimeStart).subtract(1, "weeks").startOf("isoWeek").valueOf();
        let newVisibleTimeEnd = moment(this.state.visibleTimeStart).subtract(1, "weeks").endOf("isoWeek").valueOf();
        this.setState({
            visibleTimeStart: newVisibleTimeStart,
            visibleTimeEnd: newVisibleTimeEnd,
        });
        const year = moment(newVisibleTimeStart).get("year");
        const week = moment(newVisibleTimeStart).get("week");
        this.props.history.push(`/booking/${resource.categoryId}/${resource.id}/${year}/${week}`);
    };

    onNextClick = () => {
        const { resource } = this.props;

        let newVisibleTimeStart = moment(this.state.visibleTimeStart).add(1, "week").startOf("isoWeek").valueOf();
        let newVisibleTimeEnd = moment(this.state.visibleTimeStart).add(1, "week").endOf("isoWeek").valueOf();
        this.setState({
            visibleTimeStart: newVisibleTimeStart,
            visibleTimeEnd: newVisibleTimeEnd,
        });
        const year = moment(newVisibleTimeStart).get("year");
        const week = moment(newVisibleTimeStart).get("week");
        this.props.history.push(`/booking/${resource.categoryId}/${resource.id}/${year}/${week}`);
    };

    showReserveItem = (item) => {
        this.setState({
            showCallout: undefined
        }, () => {
            this.setState({
                showCallout: item
            });
        });
    };

    onClickGroup = (id) => {
        this.props.onClickGroup(id);
    };

    changeMonth = (startdate, enddate) => {
        this.props.whichMonth(startdate, enddate);
    };

    book = (item) => {
        let model = {
            id: 0, // if isNew
            userId: this.props.currentUserId,
            intervalId: item.group,
            date: dateToYMD(moment(item.start_time).toDate()),
        };
        this.setState({ isLoading: true }, () => {
            api.post("/api/v1/bookings", model).then((response) => {
                this.fetch(this.state.visibleTimeStart, this.state.visibleTimeEnd);
            });
        });
    };

    removeReservation = (booking) => {
        this.setState({ isLoading: true }, () => {
            api.delete(`/api/v1/bookings/${booking.id}`).then((response) => {
                this.fetch(this.state.visibleTimeStart, this.state.visibleTimeEnd);
            });
        });
    };

    renderBook = () => {
        const { showCallout } = this.state;
        if (showCallout.booking) {
            const { user } = showCallout.booking;
            const canRemoveBooking = this.props.isAdmin || this.props.isEditor || user.id === this.props.currentUserId;

            return (
                <div className="ResourceWeeklyView-reservation">
                    <SpintrUser size={24} id={user.id} name={user.name} personalName={true} />
                    {canRemoveBooking && (
                        <PrimaryButton onClick={() => this.removeReservation(showCallout.booking)}>
                            {localize("TaBortBokning")}
                        </PrimaryButton>
                    )}
                </div>
            );
        } else {
            return (
                <div className="ResourceWeeklyView-reserve">
                    <PrimaryButton
                        onClick={() => {
                            this.book(showCallout);
                        }}
                    >
                        <b>{localize("Boka")}&nbsp;</b> {showCallout.title}
                    </PrimaryButton>
                </div>
            );
        }
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const { visibleTimeStart, visibleTimeEnd, items, showCallout } = this.state;

        return (
            <>
                {showCallout && (
                    <Callout
                        onDismiss={() => {
                            this.setState({ showCallout: undefined });
                        }}
                        target={`#item-${this.state.showCallout.id}`}
                        setInitialFocus={true}
                    >
                        {this.renderBook()}
                    </Callout>
                )}
                <Timeline
                    onBoundsChange={(canvasTimeStart, canvasTimeEnd) => {
                        this.fetch(canvasTimeStart, canvasTimeEnd);
                    }}
                    groups={this.state.hours}
                    items={items}
                    itemTouchSendsClick={false}
                    stackItems
                    itemHeightRatio={0.75}
                    showCursorLine
                    canMove={false}
                    canResize={false}
                    visibleTimeStart={visibleTimeStart}
                    visibleTimeEnd={visibleTimeEnd}
                    itemRenderer={this.itemRenderer}
                    groupRenderer={this.groupRenderer}
                    sidebarWidth={0}
                    className="weekly"
                >
                    <TodayMarker>{({ styles }) => <div style={{ ...styles, backgroundColor: "green" }} />}</TodayMarker>
                    <TimelineHeaders className="sticky">
                        <CustomHeader height={31} unit="week">
                            {({ headerContext: { intervals }, getRootProps, getIntervalProps, showPeriod }) => {
                                return (
                                    <div {...getRootProps()}>
                                        {intervals.map((interval) => {
                                            const intervalStyle = {
                                                lineHeight: "30px",
                                                textAlign: "center",
                                                borderBottom: "1px solid #cecece",
                                                display: "flex",
                                                justifyContent: "center",
                                            };
                                            return (
                                                <div
                                                    onClick={() => {
                                                        showPeriod(interval.startTime, interval.endTime);
                                                    }}
                                                    {...getIntervalProps({
                                                        interval,
                                                        style: intervalStyle,
                                                    })}
                                                >
                                                    <div
                                                        className="sticky"
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <div
                                                            style={{ height: 25, cursor: "pointer" }}
                                                            onClick={() => this.onPrevClick()}
                                                        >
                                                            <Visage2Icon icon="arrow-left-2" color={"grey"} />
                                                        </div>
                                                        {localize("Vecka")} {interval.startTime.format("w")}
                                                        <div
                                                            style={{ height: 25, cursor: "pointer" }}
                                                            onClick={() => this.onNextClick()}
                                                        >
                                                            <Visage2Icon icon="arrow-right-3" color={"grey"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }}
                        </CustomHeader>
                        <DateHeader unit="day" labelFormat="ddd DD/MM" />
                    </TimelineHeaders>
                </Timeline>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        instance: state.instance
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(CustomWeeklyTimeline));
