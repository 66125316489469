import api from "src/spintr/SpintrApi";

import { FETCH_AUTH_DATA, SET_APP_SETTINGS } from "./action-types";

export const fetchAuthData = (code?: string) => ({
    payload: api.get<any>("/api/v1/authentication/authdata", { params: { code } }),
    type: FETCH_AUTH_DATA,
});

export const setAppSettings = (data) => ({
    type: SET_APP_SETTINGS,
    data,
});

const AuthActions = {
    fetchAuthData,
    setAppSettings,
};

export default AuthActions;
