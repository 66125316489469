import React, { Component, ReactNode } from "react";
import { Label } from "src/ui";
import { uniqueId } from "src/utils";
import "./ActionMenuCategory.scss";
import ActionMenuItem from "./ActionMenuItem";

interface IActionMenuCategoryProps {
    category: Spintr.IActionMenuCategory;
}

interface IActionMenuCategoryState {
    componentId: string;
}

class ActionMenuCategory extends Component<IActionMenuCategoryProps, IActionMenuCategoryState> {
    constructor(props : IActionMenuCategoryProps) {
        super(props);

        this.state = {
            componentId: uniqueId("ActionMenuCategory")
        };
    }

    public render() : ReactNode {
        const category = this.props.category;
        const componentId = this.state.componentId;

        return (
            <div className="ActionMenuCategory">
                {category.title && (
                    <div className="title">
                        <Label
                            as="span"
                            color="mid-grey"
                            size="small-2"
                            weight="regular">
                            {category.title}
                        </Label>
                    </div>
                )}
                <div className="items">
                    {category.items.map((item : Spintr.IActionMenuItem, index : number) : ReactNode => {
                        const key = `${componentId}.${index}`;
                        
                        return (
                            <ActionMenuItem
                                item={item}
                                key={key} />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default ActionMenuCategory;