import { fromEvent, Subject, Subscription } from "rxjs";
import { throttleTime } from "rxjs/operators";

import CSS from "../helpers/CSS";
import Scroll from "../helpers/Scroll";

const minimumGripSize = 12;
const nativeClassName = "native";

interface IScrollAreaOptions {
    fade?: boolean;
    persistent?: boolean;
    shadow?: boolean;
    tabIndex?: number;
}

class ScrollArea {
    public static fromNative(element: HTMLElement, options?: IScrollAreaOptions): ScrollArea {
        if (!CSS.hasClass(element, "ScrollableArea") ||
            !CSS.hasClass(element, nativeClassName)
        ) {
            return;
        }

        options = options || {};

        CSS.removeClass(element, nativeClassName);

        const track = window.document.createElement("div");
        CSS.addClass(track, "ScrollableAreaTrack");

        const gripper = window.document.createElement("div");
        CSS.addClass(gripper, "ScrollableAreaGripper");
        track.append(gripper);

        if (options.fade !== false) {
            CSS.addClass(element, "fade");
            CSS.addClass(track, "hiddenElement");
        } else {
            CSS.addClass(element, "nofade");
        }

        if (options.tabIndex !== undefined && options.tabIndex !== null) {
            track.tabIndex = options.tabIndex;
            element.prepend(track);
        } else {
            element.append(track);
        }

        const area = new ScrollArea(element, options);
        area.resize();

        return area;
    }

    protected containerHeight: number;
    protected contentHeight: number;
    protected gripperHeight: number;
    protected isFocused: boolean;
    protected options: IScrollAreaOptions;
    protected trackHeight: number;
    protected trackIsHovered: boolean;

    protected body: HTMLElement;
    protected content: HTMLElement;
    protected gripper: HTMLElement;
    protected root: HTMLElement;
    protected track: HTMLElement;
    protected wrap: HTMLElement;

    protected subject: Subject<Event>;
    protected subscription: Subscription;

    constructor(root: HTMLElement, options: IScrollAreaOptions) {
        if (!root) {
            return;
        }

        options = options || {};

        this.root = root;
        this.wrap = root.querySelector("div.ScrollableAreaWrap");
        this.body = this.wrap.querySelector("div.ScrollableAreaBody");
        this.content = this.body.querySelector("div.ScrollableAreaContent");
        this.track = root.querySelector("div.ScrollableAreaTrack");
        this.trackIsHovered = false;
        this.isFocused = false;
        this.gripper = this.track.querySelector("div.ScrollableAreaGripper");
        this.options = options;

        this.subject = new Subject<Event>();
        this.subscribe = this.subject.subscribe;
        
        this.subscription = fromEvent(this.wrap, "scroll")
            .pipe(throttleTime(20))
            .subscribe(event => {
                this.subject.next(event);
            });
    }

    public destroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        // @ts-ignore
        if (this.drag) {
            // @ts-ignore
            this.drag.destroy();
        }
    }

    public distanceToBottom(): number {
        this.computeHeights();

        return this.contentHeight - (Scroll.getTop(this.wrap) + this.containerHeight);
    }

    public getClientHeight(): number {
        return this.wrap.clientHeight;
    }

    public getScrollHeight(): number {
        return this.wrap.scrollHeight;
    }

    public getScrollTop(): number {
        return Scroll.getTop(this.wrap);
    }

    public isScrolledToBottom(): boolean {
        return this.distanceToBottom() <= 0;
    }

    public isScrolledToTop(): boolean {
        return Scroll.getTop(this.wrap) === 0;
    }

    public scrollElementToTop(child: HTMLElement, useAnimation: boolean, callback: () => void): void {
        this.setScrollTop(child.offsetTop, useAnimation, { callback });
    }

    public scrollToBottom(useAnimation: boolean, options: any) {
        this.setScrollTop(this.wrap.scrollHeight, useAnimation, options)
    }

    public scrollToTop(useAnimation: boolean, options: any) {
        this.setScrollTop(0, useAnimation, options);
    }

    public setScrollTop(value: number, useAnimation: boolean, options: any = {}): void {
        if (useAnimation !== false) {
            // TODO: animation stuff
            this.simpleSetScrollTop(value, options)
        } else {
            this.simpleSetScrollTop(value, options)
        }
    }

    public simpleSetScrollTop(value: number, options: any): void {
        Scroll.setTop(this.wrap, value);

        if (options.callback) {
            options.callback();
        }
    }

    public subscribe(callback: (event: Event) => void): void { 
        /* This gets overriden rn */
        console.log("Subscribe called");
     }

    protected computeHeights() {
        this.containerHeight = this.root.clientHeight
        this.contentHeight = this.content.offsetHeight
        this.trackHeight = this.track.offsetHeight
        this.gripperHeight = Math.max(this.containerHeight / this.contentHeight * this.trackHeight, minimumGripSize);
    }

    protected resize(): ScrollArea {
        if (this.body.style.width) {
            this.body.style.width = "";
        }

        const widthDelta = this.wrap.offsetWidth - this.wrap.clientWidth;
        if (widthDelta > 0) {
            this.body.style.marginRight = (-widthDelta) + "px";
        }

        this.computeHeights();

        return this;
    }
}

export default ScrollArea
