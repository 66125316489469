import { Reducer } from "redux";

import {
    AppsMessageTypes,
    AppAction,
} from "./action-types";

export interface IAppsState {
    failed: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    items: Spintr.ISystemApp[];
    fetchHasBeenInitialized: boolean;
}

const initialState: IAppsState = {
    failed: false,
    isLoaded: false,
    isLoading: false,
    items: [],
    fetchHasBeenInitialized: false
};

type AppsReducer = Reducer<IAppsState, AppAction>;

const appReducer: AppsReducer = (state = initialState, action: AppAction) => {
    switch (action.type) {
        case AppsMessageTypes.FetchAppsPending:
            return {
                ...state,
                failed: false,
                isLoading: true,
                fetchHasBeenInitialized: true,
            };

        case AppsMessageTypes.FetchAppsRejected:
            return {
                ...state,
                failed: true,
                isLoaded: true,
                isLoading: false,
            };

        case AppsMessageTypes.FetchAppsFulfilled:
            return {
                ...state,
                failed: false,
                isLoaded: true,
                isLoading: false,
                items: [...action.payload.data]
            };

        case AppsMessageTypes.UpdateApp:
            return {
                ...state,
                items: state.items.map(
                    (app) => app.id === action.appId
                        ? {
                            ...app,
                            enabled: action.enabled,
                        }
                        : app,
                ),
            }

        default:
            return state;
    }
};

export default appReducer;
