import api from "src/spintr/SpintrApi";

import {
    FETCH_PRIVILEGES_BASE
} from './action-types';

const getPrivileges = () => ({
    type: FETCH_PRIVILEGES_BASE,
    payload: api.get('/api/v1/privileges/all')
});

const PrivilegesActions = {
    getPrivileges: getPrivileges
}

export default PrivilegesActions;

export {
    getPrivileges
};
