import {
    Modal
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { Label, UnstyledButton } from "src/ui";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import SpintrLoader from "src/ui/components/Loader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { setZohoPopup } from "../zoho-actions";
import "./ZohoPopup.scss";

interface ZohoFile {
    document_delete_url: string;
    document_id: string;
    document_url: string;
    session_delete_url: string;
    session_id: string;
}

interface IProps {
    isOpen?: boolean;
    sizeErrorOpen: boolean;
    id?: number;
    version?: number;
    dispatch: (action: Action) => void;
}

interface IState {
    isLoading: boolean;
    zoho?: ZohoFile;
    error?: boolean;
}

class ZohoPopup extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (!this.props.id || this.props.id == 0 || prevProps.id === this.props.id) {
            return;
        }

        api
            .post(`/api/zohofiles/edit/${this.props.id}?version=${this.props.version}`)
            .then((response: AxiosResponse) => {
                let data = response.data as ZohoFile;

                this.setState({
                    isLoading: false,
                    error: false,
                    zoho: data,
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    error: true,
                });
            });
    }

    dismissPopup = () => {
        this.props.dispatch(setZohoPopup(false));
    };

    render() {
        const state = this.state;

        return (
            <>
                {
                    !!this.props.sizeErrorOpen && (
                        <InfoDialog
                            message={localize("OnlineredigeringenStodjerEndastFilerSomArMindreAn10MB")}
                            show={this.props.sizeErrorOpen}
                            onDismiss={this.dismissPopup}
                        />
                    )
                }
                <Modal isOpen={this.props.isOpen} containerClassName="zoho-popup">
                    <div className="zoho-popup">
                        {state.isLoading ? (
                            <SpintrLoader />
                        ) : state.error ? (
                            <Label>{localize("TeknisktFel")}</Label>
                        ) : (
                            <iframe src={state.zoho.document_url} />
                        )}
                        <UnstyledButton
                            className="close-button primaryBGColor"
                            title={localize("Stang")}
                            onClick={this.dismissPopup}
                        >
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        ...state.files.zohoPopup,
    };
};

export default connect(mapStateToProps)(ZohoPopup);
