import { SET_CONFIRM_POPUP, SET_INFO_POPUP } from "./action-types";
import { IConfirmPopupState, IInfoPopupState } from "./reducer";

const setConfirmPopup = (props: IConfirmPopupState) => ({
    props,
    type: SET_CONFIRM_POPUP,
});

const setInfoPopup = (props: IInfoPopupState) => ({
    props,
    type: SET_INFO_POPUP,
});

export { setConfirmPopup, setInfoPopup };
