import { Dropdown, IDropdownOption, Stack, TextField } from "@fluentui/react";
import React, { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { localize } from "src/l10n";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { FormControl, FormSection } from "src/ui/components/Forms";

interface IProps {
    variable: Spintr.IMarketplaceVariableInput;
    onChange(variable: Spintr.IMarketplaceVariableInput): void;
}

const constructAgo = (num: string, unit: string, direction: string) =>
    `ago(${direction || ""}${num}${unit})`;

const constructStartOf = (unit: string) =>
    `dateStartOf(${unit}, false)`;

const DateVariableInput: FunctionComponent<IProps> = ({ onChange, variable }) => {

    const typeOptions = useMemo<IDropdownOption[]>(() => [
        { key: "fixed", id: "fixed", text: localize("FIXED_DATE") },
        { key: "variable", id: "variable", text: localize("RELATIVE_DATE") },
        { key: "startOf", id: "startOf", text: localize("CURRENT_DATE") },
    ], []);

    const timeUnitOptions = useMemo<IDropdownOption[]>(() => [
        { key: "SECOND", id: "SECOND", text: localize("TIME_UNIT_SECONDS") },
        { key: "MINUTES", id: "MINUTES", text: localize("TIME_UNIT_MINUTES") },
        { key: "HOURS", id: "HOURS", text: localize("TIME_UNIT_HOURS") },
        { key: "DAYS", id: "DAYS", text: localize("TIME_UNIT_DAYS") },
        { key: "WEEKS", id: "WEEKS", text: localize("TIME_UNIT_WEEKS") },
        { key: "YEARS", id: "YEARS", text: localize("TIME_UNIT_YEARS") },
    ], []);

    const startOfTimeUnitOptions = useMemo<IDropdownOption[]>(() => [
        { key: "DAY", id: "DAY", text: localize("TIME_UNIT_DAY") },
        { key: "WEEK", id: "WEEK", text: localize("TIME_UNIT_WEEK") },
        { key: "MONTH", id: "MONTH", text: localize("TIME_UNIT_MONTH") },
        { key: "YEAR", id: "YEAR", text: localize("TIME_UNIT_YEAR") },
    ], []);

    const tempusOption = useMemo<IDropdownOption[]>(() => [
        { key: "past", id: "past", text: localize("TIME_PAST") },
        { key: "future", id: "future", text: localize("TIME_FUTURE") },
    ], []);

    const agoVariables = variable.value?.type !== "variable"
        ? []
        : /^ago\((\+|-)?([0-9]+)(s|m|h|d|w|y){1}\)$/gmi.exec(variable.value?.value?.slice() || "") || [];

    const startOfVariables = variable.value?.type !== "startOf" ?
        [] :
        [
            variable.value?.value,
            undefined,
            variable.value?.value?.split("(")[1].split(",")[0]
        ];

    const onDateChange = useCallback(
        (selectedDate: Date) => onChange({
            ...variable,
            value: {
                type: "fixed",
                value: selectedDate,
            },
        }),
        [onChange, variable],
    );

    const onTypeChange = useCallback(
        (_: any, option: IDropdownOption) => onChange({
            ...variable,
            value: {
                type: option.key,
                value: option.key === "variable"
                    ? "ago(1d)"
                    : option.key === "startOf" ?
                        "dateStartOf(m, false)"
                        : new Date(),
            },
        }),
        [variable, onChange],
    );

    const onAgoNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            onChange({
                ...variable,
                value: {
                    type: variable.value.type,
                    value: constructAgo(
                        value,
                        agoVariables[3],
                        agoVariables[1] || ""
                    ),
                },
            })
        },
        [onChange, variable, agoVariables]
    );

    const onAgoUnitChange = useCallback(
        (_: any, option: IDropdownOption) => {
            onChange({
                ...variable,
                value: {
                    type: variable.value.type,
                    value: constructAgo(
                        agoVariables[2],
                        option.key.toString().toLowerCase()[0],
                        agoVariables[1] || ""
                    ),
                },
            });
        },
        [onChange, variable, agoVariables]
    );

    const onStartOfUnitChange = useCallback(
        (_: any, option: IDropdownOption) => {
            onChange({
                ...variable,
                value: {
                    type: variable.value.type,
                    value: constructStartOf(
                        option.key.toString().toLowerCase()[0]
                    ),
                },
            });
        },
        [onChange, variable, startOfVariables]
    );

    const onAgoDirectionChange = useCallback(
        (_: any, option: IDropdownOption) => {
            onChange({
                ...variable,
                value: {
                    type: variable.value.type,
                    value: constructAgo(
                        agoVariables[2],
                        agoVariables[3],
                        option.key.toString().toLowerCase() === "future"
                            ? "-"
                            : "+"
                    ),
                },
            });
        },
        [onChange, variable, agoVariables]
    );

    const dateValue = (variable.value as Spintr.IDateVariableValue) || {
        type: "fixed",
        value: new Date(),
    };

    const inputComponent = () => {
        if (dateValue?.type === "startOf") {
            return (
                <Dropdown
                    label={localize("TIME_UNIT")}
                    onChange={onStartOfUnitChange}
                    options={startOfTimeUnitOptions}
                    selectedKey={startOfTimeUnitOptions
                        .filter(
                            (opt) => opt.key[0].toLowerCase() === (startOfVariables[2] || "").toLowerCase()
                        )
                        .map(
                            (opt) => opt.key,
                        )[0]}
                />
            );
        }

        if (dateValue?.type !== "variable") {
            return (
                <SpintrDatePicker
                    label={localize("Datum")}
                    onChangeHandler={onDateChange}
                    value={dateValue.value}
                />
            );
        }

        return (
            <div className="relative-date">
                <FormSection>
                    <Stack horizontal={true}>
                        <TextField
                            label={localize("AMOUNT")}
                            onChange={onAgoNumberChange}
                            type="number"
                            value={agoVariables[2]}
                        />
                        <Dropdown
                            label={localize("TIME_UNIT")}
                            onChange={onAgoUnitChange}
                            options={timeUnitOptions}
                            selectedKey={
                                timeUnitOptions
                                    .filter(
                                        (opt) => opt.key[0].toLowerCase() === (agoVariables[3] || "").toLowerCase()
                                    )
                                    .map(
                                        (opt) => opt.key,
                                    )[0]
                            }
                        />
                        <Dropdown
                            label={localize("TIME_DIRECTION")}
                            onChange={onAgoDirectionChange}
                            options={tempusOption}
                            selectedKey={
                                agoVariables[1] === "-"
                                    ? "future"
                                    : "past"
                            }
                        />
                    </Stack>
                </FormSection>
            </div>
        );
    };

    return (
        <div className="DateVariableInput">
            <FormControl>
                <Dropdown
                    selectedKey={dateValue.type}
                    label={localize("DATE_TYPE")}
                    onChange={onTypeChange}
                    options={typeOptions}
                />
            </FormControl>
            <FormControl>
                {inputComponent()}
            </FormControl>
        </div>
    );
}

export default DateVariableInput;