import React, { FunctionComponent } from "react";
import { localize, localizeFormat } from "src/l10n";
import { Label } from "src/ui";

interface IProps {
    assistantName: string;
    companyName: string;
}

const AssistantInstructions: FunctionComponent<IProps> =
    ({ assistantName, companyName }) => (
        <Label
            className="AssistantInstructions"
            size="body-2"
            weight="regular"
        >
            {localizeFormat("AI_ASSISTANT_INSTRUCTIONS_F", assistantName, companyName)}
            <br /><br />
            {localize("AI_ASSISTANT_INTRO_CTA")}
        </Label>
    );

export default AssistantInstructions;
