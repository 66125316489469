import React, { FunctionComponent } from "react";
import { TeaserBoxLoader } from "src/teaser-box";

const BlogBoxesWrapper: FunctionComponent = () => (
    <div className="BlogBoxesWrapper">
        <TeaserBoxLoader
            blogsOnly={true}
        />
    </div>
);

export default BlogBoxesWrapper;
