import React, { ReactElement } from "react";
import { DrawerHeaderProps } from "./DrawerHeader.types";

function DrawerHeader(props: DrawerHeaderProps): ReactElement {
    const Tag = props.as || "header";

    return (
        <Tag className="DrawerHeader">
            {props.children}
        </Tag>
    )
}

export default DrawerHeader;
