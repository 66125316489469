import React, { useState } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { ReactSVG } from "react-svg";

interface IProps {
    priorityLevel?: number;
    isInNewsFeed?: boolean;
}

const MiniTitlePriorityLevel = (props: IProps) => {
    const [state] = useState({
        title: localize(props.priorityLevel === 4 ? "NICE_TO_KNOW" : "NEED_TO_KNOW"),
        iconSrc: `/images/${props.priorityLevel === 4 ? "light_bulb" : "fire"}.svg`
    });

    return (
        <div
            className={[
                "mini-title-item",
                "priority-level",
                (props.isInNewsFeed ? "compact" : "")
            ].join(" ")}
            title={state.title}
        >
            <ReactSVG src={state.iconSrc} />
            {!props.isInNewsFeed && (
                <Label size="small-2" weight="normal" color="dark-grey">
                    {state.title}
                </Label>
            )}
        </div>
    )
}

export default MiniTitlePriorityLevel;
