import React, { Component } from "react";
import { connect } from "react-redux";
import { Label } from "src/ui";
import "./UnreadIndicator.scss";

interface IProps {
    count?: number;
}

class UnreadIndicator extends Component<IProps> {
    render() {
        if (!this.props.count) {
            return null;
        }

        const count = this.props.count > 9 ?
            "9+" :
            this.props.count;

        return (
            <div className="UnreadIndicator">
                <Label size="small-2" color="white">
                    {
                        count
                    }
                </Label>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
    };
};

export default connect(mapStateToProps)(UnreadIndicator);
