import React, { FunctionComponent, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { localizeFormat } from "src/l10n";
import { IAppPageComponent } from "src/marketplace/types";
import { BackgroundImage, Label } from "src/ui";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { AppPageContext } from "../AppPageContext";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps extends IAppPageComponent {
    headerUrl?: string;
    linkUrl?: string;
}

const AppPageHeader: FunctionComponent<IProps> = ({ headerUrl, linkUrl, variables }) => {
    const context = useContext(AppPageContext);
    const backgroundColor = useSelector<Spintr.AppState, string>(
        (state) => state.instance.get("color") as string,
    );

    const linkClassName = useMemo(
        () => {
            const classNames = ["primaryBGColor icon-animation animation-pulse"];

            classNames.push(getLightOrDarkColorBasedOnColor(
                backgroundColor,
                "fc-white light",
                "fc-visageGray dark"
            ));

            return classNames.join(" ");
        },
        [backgroundColor],
    );

    const headerImageUrl = headerUrl || context.appHeaderUrl;

    const treatedHeaderUrl = useMemo(
        () => [...Object.keys(variables || {})].reduce(
            (acc, key) => acc.replace(`{{${key}}}`, variables[key] || ""),
            headerImageUrl || "",
        ),
        [headerImageUrl, variables],
    );

    const treatedLink = useMemo(
        () => !linkUrl ? undefined : [...Object.keys(variables || {})].reduce(
            (acc, key) => acc.replace(`{{${key}}}`, variables[key] || ""),
            linkUrl || "",
        ),
        [linkUrl, variables],
    );

    return (
        <div className="AppPageHeader">
            <BackgroundImage
                imageUrl={treatedHeaderUrl}
                style={{
                    backgroundPosition: "center",
                }}
            >
                {treatedLink && (
                    <a
                        className={linkClassName}
                        href={treatedLink}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <Label
                            as="span"
                            size="body-2"
                        >
                            {localizeFormat("GO_TO_SERVICE_F", context?.appName)}
                        </Label>
                        <span>
                            <Visage2Icon
                                icon="export-3"
                                color={getLightOrDarkColorBasedOnColor(
                                    backgroundColor,
                                    "white",
                                    "visageGray"
                                ) as spintrColors}
                                size="small"
                            />
                        </span>
                    </a>
                )}
            </BackgroundImage>
        </div>
    );
};

export default AppPageHeader;
