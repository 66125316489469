import axios from "axios";
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { buildAppPageComponent } from "src/marketplace/utils";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { AppPageContext } from "../AppPageContext";

interface IProps extends IAppPageComponent { }

interface IState {
    data?: any;
    isLoading: boolean;
}

const AppPageDataRegion: FunctionComponent<IProps> = (props) => {
    const context = useContext(AppPageContext);
    const [state, setState] = useState<IState>({ isLoading: false });

    const { requestName, requestVariables, variables } = props;

    useEffect(
        () => {
            setState((s) => ({
                ...s,
                isLoading: true,
            }));

            const cancelTokenSource = axios.CancelToken.source();

            const promise = api.post(
                `/api/v1/MarketplaceApplicationPage/${context.appId}/request`, {
                    requestName,
                    variables: requestVariables
                    ? requestVariables.reduce(
                        (acc, key) => {
                            acc[key] = (variables || {})[key];

                            return acc;
                        },
                        {},
                    )
                    : {}
                }, {
                    cancelToken: cancelTokenSource.token,
                }
            );

            promise.then(
                (response) => setState({
                    data: response.data,
                    isLoading: false,
                }),
                (reason) => setState((s) => ({
                    ...s,
                    isLoading: false,
                }))
            );

            return () => cancelTokenSource.cancel();
        },
        [setState, context.appId, requestName, requestVariables, variables],
    );

    const items = useMemo(
        () => (props.children || []).map((item, index) => {
            const el = buildAppPageComponent({
                ...item,
                variables: {
                    ...variables,
                    ...state.data,
                },
            }, index);

            return (
                <div className="child" key={index}>{el}</div>
            );
        }),
        [props.children, state.data, variables],
    );


    return (
        <div className="AppPageDataRegion">
            {state.isLoading ? <Loader /> : items}
        </div>
    )
};

export default AppPageDataRegion;
