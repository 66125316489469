import React, { Component, CSSProperties, ReactNode } from "react";
import "./FloatingLayer.scss";

export interface IFloatingLayerProps {
    horizontalAnchor?: "left" | "right";
    open: boolean;
}

class FloatingLayer extends Component<IFloatingLayerProps> {
    public static defaultProps : IFloatingLayerProps = {
        horizontalAnchor: "left",
        open: false
    };

    public render() : ReactNode {
        const classNames : string[] = ["FloatingLayer"];
        
        if (this.props.open) {
            classNames.push("open");
        }

        const style : CSSProperties = {};
        style[this.props.horizontalAnchor || "left"] = 0;

        return (
            <div className={classNames.join(" ")} style={style}>
                {this.props.children}
            </div>
        );
    }
}

export default FloatingLayer;