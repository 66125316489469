import { CancelToken } from "axios";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/MarketplaceApplications";

export async function findMarketplaceApp(
    id : string,
    cancelToken?: CancelToken
) : Promise<Spintr.IMarketplaceAppResponse> {
    const response = await api.get<Spintr.IMarketplaceAppResponse>(
        `${baseUrl}/${id}`,
        { cancelToken }
    );

    return response.data;
}

export async function installMarketplaceApp(
    appId : string,
    authenticationData : string | null = null
) : Promise<boolean> {
    const response = await api.post(
        `${baseUrl}/${appId}/install`,
        { authenticationData }
    );
    
    return response.status < 300;
}

export async function uninstallMarketplaceApp(
    appId: string
) : Promise<boolean> {
    const response = await api.delete(
        `${baseUrl}/${appId}/install`
    );
    
    return response.status < 300;
}

export async function saveMarketplaceApp(
    app : Spintr.IMarketplaceAppRequest
) : Promise<Spintr.IMarketplaceAppResponse> {    
    console.log(app);
    const response = await api.post<Spintr.IMarketplaceAppResponse>(
        baseUrl,
        { ...app, tags: app.tags.map(t => t.text) }
    );

    return response.data;
}

export interface IQueryMarketplaceAppsParams {
    query?: string;
    categoryId?: number;
    tagId?: number;
    skip?: number;
    take?: number;
}

export async function queryMarketplaceApps(
    params?: IQueryMarketplaceAppsParams
) : Promise<Spintr.IMarketplaceAppEnvelope> {
    const response = await api.get<Spintr.IMarketplaceAppEnvelope>(
        baseUrl, {
            params,
        },
    );

    return response.data;
}
