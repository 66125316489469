import {
    ChoiceGroup, DefaultButton, IChoiceGroupOption, Modal, PrimaryButton, Separator, Stack
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import PopupHeader from "src/ui/components/PopupHeader";
import "./FileDownloadPopup.scss";

interface IProps {
    close: () => void;
    isOpen: boolean;
    file: any;
    currentUserId: number;
}

interface IState {
    lockTime: number;
}

class FileDownloadPopup extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            lockTime: 0,
        };
    }

    downloadFile = () => {
        window.location.href = `/api/servefile/${this.props.file.id}`;
        this.props.close();
    };

    lockAndDownloadFile = () => {
        api
            .post("/api/files/lock", {
                id: this.props.file.id,
                lockTime: this.state.lockTime,
                userId: this.props.currentUserId,
            })
            .then(() => {
                this.downloadFile();
            });
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} containerClassName="file-download-popup modalWithPopupHeader">
                <PopupHeader
                    text={localize("VillDuLasaFilen")}
                    onClose={this.props.close} />
                <Label size="body-3">{localize("LasFilInfoText")}</Label>
                <Separator />
                <ChoiceGroup
                    defaultSelectedKey={"0"}
                    options={[
                        {
                            text: localize("LasInteFilen"),
                            value: "0",
                            key: "0",
                        },
                        {
                            text: `15 ${localize("Minuter").toLowerCase()}`,
                            value: "15",
                            key: "15",
                        },
                        {
                            text: `30 ${localize("Minuter").toLowerCase()}`,
                            value: "30",
                            key: "30",
                        },
                        {
                            text: `60 ${localize("Minuter").toLowerCase()}`,
                            value: "60",
                            key: "60",
                        },
                    ]}
                    onChange={(event, option: IChoiceGroupOption) => {
                        this.setState({
                            lockTime: option.value as number,
                        });
                    }}
                />
                <Separator />
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                    <DefaultButton text={localize("Avbryt")} onClick={this.props.close} />
                    {this.state.lockTime == 0 ? (
                        <PrimaryButton text={localize("LaddaNed")} onClick={this.downloadFile} />
                    ) : (
                        <PrimaryButton text={localize("LasFilenOchLaddaNer")} onClick={this.lockAndDownloadFile} />
                    )}
                </Stack>
            </Modal>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        currentUserId: state.profile.active.id,
    };
};

export default connect(mapStateToProps)(FileDownloadPopup);
