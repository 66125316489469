import { PrimaryButton } from "@fluentui/react";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { versionString } from "src/spintr/SpintrApi";
import { Label } from "src/ui";

import "./UpdateReloadBanner.scss";

type Props = {} & RouteComponentProps;

interface IState {
    versionString: string;
    showBanner: boolean;
};

class UpdateReloadBanner extends Component<Props, IState> {
    private intervalRef: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            versionString,
            showBanner: false,
        }
    }

    public componentDidMount() {
        this.intervalRef = setInterval(() => {
            if (this.state.versionString !== versionString) {
                this.setState({
                    versionString,
                    showBanner: !!this.state.versionString && !!versionString
                });
            }
        }, 10000);
    }

    public componentWillUnmount() {
        clearInterval(this.intervalRef);
    }

    render() {
        if (!this.state.showBanner) {
            return null;
        }

        if (this.props.location.pathname.startsWith("/edit-startpage")) {
            return null;
        }

        return (
            <div className="UpdateReloadBanner-Wrapper">
                <div>
                    <Label size="body-1" color="dark-grey" weight="medium">{localize("RELOAD_TITLE")}</Label>
                    <Label size="body-2" color="mid-grey">{localize("SYSTEM_UPDATED_MSG")}</Label>
                </div>
                <div>
                    <PrimaryButton onClick={() => {
                        window.location.reload();
                    }}>{localize("RELOAD")}</PrimaryButton>
                </div>
            </div>
        )
    }
}

export default withRouter(UpdateReloadBanner);
