export enum MfaWizardStage {
    Information = 1,
    Scanning,
    Verifying,
    Completed,
}

export type MfaWizardProps = {
    enforced?:          boolean | undefined;
    show?:              boolean | undefined;
    onClose?:           (completedSuccessfully: boolean) => void;
};

export type MfaWizardState = {
    isLoading:          boolean;
    currentStage:       MfaWizardStage;
    otpUri?:            string | undefined;
    otpQrCode?:         string | undefined;
    totp:               string;
    errorMessage?:      string | undefined;
};
