const CSS = {
    addClass: (element: Element, className: string): void => 
        element.classList.add(className),

    hasClass: (element: Element, className: string): boolean => 
        element.classList.contains(className),

    removeClass: (element: Element, className: string): void => 
        element.classList.remove(className)
};

export default CSS;
