import { Checkbox, DatePicker, DayOfWeek, DefaultButton, Modal, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { fetchPlanning, updateBoardPositionsRealtime } from 'src/groups/planning-actions';
import "src/groups/views/planning/GroupPlanningView.scss";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { FileSelector } from "src/spintr/components";
import { SpintrTypes } from "src/typings";
import { Loader, UnstyledButton } from "src/ui";
import ColorPicker from "src/ui/components/ColorPicker/ColorPicker";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import {
    FormControl, FormSection,
    FormTokenizedObjectInput
} from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import PopupHeader from "src/ui/components/PopupHeader";
import { isAnythingDirty, validateRequiredTextField } from "src/utils";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { MandatoryText } from "../../../ui/components/Forms";

interface IProps {
    cardId: number;
    groupId: number;
    onClose: any;
    dispatch?: any;
}

interface IState {
    isLoading: boolean,
    displayNewTodoForm: boolean,
    isCreatingNewTodo: boolean,
    newTodoTitle: string,
    item?: any,
    todoToDelete?: number,
    displayDeleteTodoDialog: boolean,
    showFileSelector: boolean,
    displayDeleteFileDialog: boolean,
    fileToDelete?: number,
    displayDeleteCardDialog: boolean,
    enableFieldValidation?: boolean,
    saveError?: any,
    displayUnsavedChangesPopup: boolean;
}

class PlanningCardPopup extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            displayNewTodoForm: false,
            isCreatingNewTodo: false,
            newTodoTitle: "",
            displayDeleteTodoDialog: false,
            showFileSelector: false,
            displayDeleteFileDialog: false,
            displayDeleteCardDialog: false,
            displayUnsavedChangesPopup: false
        };
    }

    fetch() {
        api.get(`/api/groupplanning/${this.props.groupId}/item/${this.props.cardId}`).then((response) => {
            this.setState({
                isLoading: false,
                item: response.data
            });
        });
    }

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        api.get(`/api/groupplanning/${this.props.groupId}`).then(response => {
            this.props.dispatch(updateBoardPositionsRealtime(this.props.groupId, response.data));
        });
    }

    close() {
        if (isAnythingDirty()) {
            return this.setState({
                displayUnsavedChangesPopup: true
            });
        }

        this.props.onClose();
    }

    save() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true,
            saveError: []
        }, () => {
            if (!this.state.item.title ||
                this.state.item.length === 0) {
                this.setState({
                    isLoading: false,
                    saveError: ["DuMasteFyllaIEnTitel"]
                });

                return;
            }

            api.post(`/api/groupplanning/${this.props.groupId}/item/${this.props.cardId}`, this.state.item).then(() => {
                this.props.onClose();
            }).catch(this.handleCatch.bind(this));
        });
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    onEditTodoEnter = e => {
        if (e.keyCode === 13) {
            e.preventDefault();

            for (let t of this.state.item.todos) {
                if (t.isEditing &&
                    !!t.newTitle) {
                    api.post(`/api/groupplanning/${this.props.groupId}/todo/${t.id}`, {
                        ...t,
                        title: t.newTitle
                    });
                }
            }

            this.setState({
                item: {
                    ...this.state.item,
                    todos: this.state.item.todos.map(t => {
                        return {
                            ...t,
                            title: t.newTitle ? t.newTitle : t.title,
                            isEditing: false
                        }
                    })
                }
            });
        }
    };

    onCreateTodoEnter = e => {
        if (e.keyCode === 13) {
            e.preventDefault();

            this.setState({
                isCreatingNewTodo: true
            }, () => {
                const newTodo = {
                    done: false,
                    id: 0,
                    itemId: this.props.cardId,
                    projectId: this.props.groupId,
                    title: this.state.newTodoTitle
                };

                api.post(`/api/groupplanning/${this.props.groupId}/todo/0`, newTodo).then((response) => {
                    //this.props.dispatch(fetchPlanning(axios, this.props.groupId));

                    this.setState({
                        isCreatingNewTodo: false,
                        displayNewTodoForm: false,
                        item: {
                            ...this.state.item,
                            todos: [
                                ...this.state.item.todos,
                                {
                                    ...newTodo,
                                    id: response.data
                                }
                            ]
                        }
                    });
                });
            });
        }
    };

    renderDeleteCardDialog() {
        return (
            <CustomDialog
                show={this.state.displayDeleteCardDialog}
                message={localize("REMOVE_ITEM_MSG")}
                onConfirm={() => {
                    this.setState({
                        isLoading: true,
                        displayDeleteCardDialog: false
                    }, () => {
                        api.delete(`/api/groupplanning/${this.props.groupId}/item/${this.state.item.id}`).then(() => {
                            this.props.dispatch(fetchPlanning(api, this.props.groupId));
                        });
                    });
                }}
                onDismiss={() => this.setState({ displayDeleteCardDialog: false })}
            />
        );
    }

    renderDeleteTodoDialog() {
        return (
            <CustomDialog
                show={this.state.displayDeleteTodoDialog}
                message={localize("REMOVE_TODO_MSG")}
                onConfirm={() => {
                    this.setState({
                        displayDeleteTodoDialog: false,
                        item: {
                            ...this.state.item,
                            todos: [...this.state.item.todos].filter(t => t.id !== this.state.todoToDelete)
                        }
                    }, () => {
                        api.delete(`/api/groupplanning/${this.props.groupId}/todo/${this.state.todoToDelete}`).then(() => {
                            this.setState({
                                todoToDelete: null
                            });
                        });
                    });
                }}
                onDismiss={() => this.setState({ displayDeleteTodoDialog: false, todoToDelete: null })}
            />
        );
    }

    renderDeleteFileDialog() {
        return (
            <CustomDialog
                show={this.state.displayDeleteFileDialog}
                message={localize("VillDuVerkligenTaBortX").replace("{{X}}", localize("Ubertype7_0_1"))}
                onConfirm={() => {
                    this.setState({
                        displayDeleteFileDialog: false,
                        item: {
                            ...this.state.item,
                            files: [...this.state.item.files].filter(t => t.id !== this.state.fileToDelete)
                        }
                    }, () => {
                        api.delete(`/api/v1/objects/${this.state.fileToDelete}`).then(() => {
                            this.setState({
                                fileToDelete: null
                            });
                        });
                    });
                }}
                onDismiss={() => this.setState({ displayDeleteFileDialog: false, fileToDelete: null })}
            />
        );
    }

    renderUnsavedChangesDialog() {
        return (
            <CustomDialog
                message={localize("UnsavedChangesWarning")}
                show={!!this.state.displayUnsavedChangesPopup}
                confirmMessage={localize("Ok")}
                onDismiss={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    });
                }}
                onConfirm={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    }, () => {
                        this.props.onClose();
                    });
                }} />
        )
    }

    private onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        let updatedItemTodos = [...this.state.item.todos];

        const fromIndex = source.index;
        const toIndex = destination.index;

        const c = updatedItemTodos[fromIndex];

        updatedItemTodos.splice(fromIndex, 1);
        updatedItemTodos.splice(toIndex, 0, c);

        updatedItemTodos = updatedItemTodos.map((todo, index) => ({
            ...todo,
            position: index,
        }));

        api.post(`/api/v1/groupplanning/${this.props.groupId}/todopositions/${this.state.item.id}`, updatedItemTodos);

        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                todos: updatedItemTodos,
            }
        }));
    }

    renderTasks() {
        return (
            <div className="PlanningCardPopup-tasks">
                {
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="task">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {this.state.item.todos.map((t, index) => {
                                        return (
                                            <Draggable draggableId={t.id.toString()} index={index} key={t.id}>
                                                {(provided, _snapshot) => (
                                                    <div
                                                        className="PlanningCardPopup-task"
                                                        key={t.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {t.isEditing ? (
                                                            <div>
                                                                <form>
                                                                    <TextField
                                                                        required
                                                                        aria-required
                                                                        validateOnFocusIn
                                                                        validateOnFocusOut
                                                                        validateOnLoad={
                                                                            !!this.state.enableFieldValidation
                                                                        }
                                                                        onGetErrorMessage={validateRequiredTextField}
                                                                        className="textField"
                                                                        value={t.newTitle ? t.newTitle : t.title}
                                                                        onChange={(
                                                                            ev?: React.FormEvent<
                                                                                HTMLElement | HTMLInputElement
                                                                            >,
                                                                            newValue?: string
                                                                        ) => {
                                                                            this.setState({
                                                                                item: {
                                                                                    ...this.state.item,
                                                                                    todos: this.state.item.todos.map(
                                                                                        (todo) => {
                                                                                            if (todo.id === t.id) {
                                                                                                return {
                                                                                                    ...todo,
                                                                                                    newTitle: newValue,
                                                                                                };
                                                                                            } else {
                                                                                                return todo;
                                                                                            }
                                                                                        }
                                                                                    ),
                                                                                },
                                                                            });
                                                                        }}
                                                                        placeholder={localize("Titel")}
                                                                        onKeyDown={this.onEditTodoEnter}
                                                                        onBlur={() => {
                                                                            this.setState({
                                                                                item: {
                                                                                    ...this.state.item,
                                                                                    todos: this.state.item.todos.map(
                                                                                        (todo) => {
                                                                                            if (todo.id === t.id) {
                                                                                                return {
                                                                                                    ...todo,
                                                                                                    newTitle: null,
                                                                                                    isEditing: false,
                                                                                                };
                                                                                            } else {
                                                                                                return todo;
                                                                                            }
                                                                                        }
                                                                                    ),
                                                                                },
                                                                            });
                                                                        }}
                                                                        maxLength={1024}
                                                                        autoFocus={true}
                                                                    />
                                                                </form>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <form className="taskForm">
                                                                    <div className="PlanningCardPopup-taskCheckBox">
                                                                        <Checkbox
                                                                            checked={t.done}
                                                                            onChange={(
                                                                                ev?: React.FormEvent<
                                                                                    HTMLElement | HTMLInputElement
                                                                                >,
                                                                                checked?: boolean
                                                                            ) => {
                                                                                this.setState(
                                                                                    {
                                                                                        item: {
                                                                                            ...this.state.item,
                                                                                            todos: this.state.item.todos.map(
                                                                                                (todo) => {
                                                                                                    if (
                                                                                                        todo.id === t.id
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...todo,
                                                                                                            done: checked,
                                                                                                        };
                                                                                                    } else {
                                                                                                        return todo;
                                                                                                    }
                                                                                                }
                                                                                            ),
                                                                                        },
                                                                                    },
                                                                                    () => {
                                                                                        api.post(
                                                                                            `/api/groupplanning/${this.props.groupId}/todo/${t.id}`,
                                                                                            {
                                                                                                ...t,
                                                                                                done: checked,
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="PlanningCardPopup-taskCheckBoxLabel">
                                                                        <UnstyledButton
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    item: {
                                                                                        ...this.state.item,
                                                                                        todos: this.state.item.todos.map(
                                                                                            (todo) => {
                                                                                                if (todo.id === t.id) {
                                                                                                    return {
                                                                                                        ...todo,
                                                                                                        isEditing: true,
                                                                                                    };
                                                                                                } else {
                                                                                                    return todo;
                                                                                                }
                                                                                            }
                                                                                        ),
                                                                                    },
                                                                                });
                                                                            }}
                                                                        >
                                                                            {t.title}
                                                                        </UnstyledButton>
                                                                    </div>
                                                                    <div className="PlanningCardPopup-taskRemove">
                                                                        <TooltipHost content={localize("TaBort")}>
                                                                            <UnstyledButton
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        displayDeleteTodoDialog: true,
                                                                                        todoToDelete: t.id,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <div className="remove-button">
                                                                                    <Visage2Icon icon="close-circle" />
                                                                                </div>
                                                                            </UnstyledButton>
                                                                        </TooltipHost>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                }<form>
                    {
                        this.state.displayNewTodoForm ?
                            <div>
                                {
                                    this.state.isCreatingNewTodo ?
                                        <Loader /> :
                                        <TextField
                                            className="textField"
                                            value={this.state.newTodoTitle}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                                this.setState({
                                                    newTodoTitle: newValue
                                                });
                                            }}
                                            placeholder={localize("Titel")}
                                            onKeyDown={this.onCreateTodoEnter}
                                            maxLength={1024}
                                            autoFocus={true}
                                        />
                                }
                            </div> :
                            <div className="PlanningCardPopup-tasksFooter">
                                <PrimaryButton onClick={() => {
                                    this.setState({
                                        enableFieldValidation: true
                                    });
                                    this.setState({
                                        newTodoTitle: "",
                                        displayNewTodoForm: true
                                    });
                                }} type="button">
                                    {localize("ADD_NEW_TASK")}
                                </PrimaryButton>
                            </div>
                    }
                </form>
            </div>
        )
    }

    render() {
        return (
            <Modal
                allowTouchBodyScroll
                className="spintr-modal modalWithPopupHeader"
                isOpen={true}
                isClickableOutsideFocusTrap
                onDismiss={this.close.bind(this)}
                containerClassName={"PlanningCardPopup"}
            >
                <PopupHeader
                    text={localize("Planeringskort")}
                    onClose={this.close.bind(this)} />
                {this.state.saveError && this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <div className="PlanningCardPopup-Inner">
                    {
                        this.state.isLoading ?
                            <div className="PlanningCardPopup-LoaderWrapper">
                                <Loader />
                            </div> :
                            <div>
                                <MandatoryText />
                                <div className="PlanningCardPopup-Main">
                                    <div className="PlanningCardPopup-MainLeft">
                                        <div className="PlanningCardPopup-Header">
                                            <form>
                                                <FormControl>
                                                    <TextField
                                                        ariaLabel={localize("SkrivEnRubrik")}
                                                        value={this.state.item.title}
                                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, title?: string) => {
                                                            this.setState({
                                                                item: {
                                                                    ...this.state.item,
                                                                    title,
                                                                }
                                                            });
                                                        }}
                                                        label={localize("Rubrik")}
                                                        required
                                                        aria-required
                                                        validateOnFocusIn
                                                        validateOnFocusOut
                                                        validateOnLoad={!!this.state.enableFieldValidation}
                                                        onGetErrorMessage={validateRequiredTextField}
                                                    />
                                                </FormControl>

                                            </form>
                                        </div>
                                        <form>
                                            <FormSection>
                                                <FormControl>
                                                    <TextField
                                                        autoAdjustHeight={true}
                                                        resizable={false}
                                                        label={localize("Beskrivning")}
                                                        multiline={true}
                                                        onChange={(_e, text) => {
                                                            this.setState({
                                                                item: {
                                                                    ...this.state.item,
                                                                    description: text
                                                                }
                                                            });
                                                        }}
                                                        value={this.state.item.description}
                                                        maxLength={1024}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <DatePicker
                                                        label={localize("Forfallodatum")}
                                                        firstDayOfWeek={DayOfWeek.Monday}
                                                        strings={getDatePickerLanguageStrings()}
                                                        formatDate={formatDatePickerDate}
                                                        placeholder={localize("ValjDatum")}
                                                        ariaLabel={localize("ValjDatum")}
                                                        value={this.state.item.date ? new Date(this.state.item.date) : null}
                                                        onSelectDate={(date: Date) => {
                                                            this.setState({
                                                                item: {
                                                                    ...this.state.item,
                                                                    date
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl label={localize("ValjFarg")}>

                                                    <ColorPicker
                                                        small={true}
                                                        color={this.state.item.color ? this.state.item.color : "#FFFFFF"}
                                                        onChangeColor={(ev, val) => {
                                                            this.setState({
                                                                item: {
                                                                    ...this.state.item,
                                                                    color: val.str
                                                                }
                                                            });
                                                        }}
                                                    ></ColorPicker>
                                                </FormControl>
                                                <FormControl label={localize("TASKS")}>
                                                    {
                                                        this.renderTasks()
                                                    }
                                                </FormControl>
                                                <FormControl label={localize("Delegera")}>
                                                    <FormTokenizedObjectInput
                                                        groupId={this.props.groupId}
                                                        types={[SpintrTypes.UberType.User]}
                                                        items={this.state.item.targets.map(t => {
                                                            return {
                                                                ...t,
                                                                id: t.id ? t.id : t.key,
                                                                key: t.key ? t.key : t.id
                                                            }
                                                        })}
                                                        onChange={(users) => {
                                                            this.setState({
                                                                item: {
                                                                    ...this.state.item,
                                                                    targets: users.map(u => {
                                                                        return {
                                                                            ...u,
                                                                            id: u.key
                                                                        }
                                                                    })
                                                                }
                                                            });
                                                        }} />
                                                </FormControl>
                                                <FormControl label={localize("Filer")}>
                                                    {
                                                        this.state.item.files &&
                                                            this.state.item.files.length > 0 ?
                                                            <div className="PlanningCardPopup-Files">
                                                                {
                                                                    this.state.item.files.map(f => {
                                                                        return (
                                                                            <div className="PlanningCardPopup-File" key={f.id}>
                                                                                <div className="PlanningCardPopup-fileLabel">
                                                                                    <UnstyledButton onClick={() => {
                                                                                        window.location.href = `/api/servefile/${f.id}`;
                                                                                    }}>
                                                                                        {
                                                                                            f.name
                                                                                        }
                                                                                    </UnstyledButton>
                                                                                </div>
                                                                                <div className="PlanningCardPopup-fileRemove">
                                                                                    <TooltipHost content={localize("TaBort")}>
                                                                                        <UnstyledButton onClick={() => {
                                                                                            this.setState({
                                                                                                displayDeleteFileDialog: true,
                                                                                                fileToDelete: f.id
                                                                                            });
                                                                                        }}>
                                                                                            <div className="remove-button">
                                                                                                <Visage2Icon icon="close-circle" />
                                                                                            </div>
                                                                                        </UnstyledButton>
                                                                                    </TooltipHost>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div> :
                                                            null
                                                    }
                                                    <PrimaryButton onClick={() => {
                                                        this.setState({
                                                            showFileSelector: true
                                                        });
                                                    }} type="button">
                                                        {
                                                            localize("LaggTillEnFil")
                                                        }
                                                    </PrimaryButton>
                                                    {
                                                        this.state.showFileSelector ?
                                                            <FileSelector
                                                                onClose={() => {
                                                                    this.setState({
                                                                        showFileSelector: false,
                                                                    });
                                                                }}
                                                                onSelect={(files) => {
                                                                    this.setState({
                                                                        showFileSelector: false,
                                                                        item: {
                                                                            ...this.state.item,
                                                                            files: [
                                                                                ...this.state.item.files,
                                                                                ...files
                                                                            ]
                                                                        }
                                                                    });
                                                                }}
                                                                allowMultipleFiles={true}
                                                                sourcesToDisplay={[0]}
                                                                fileUploadType={5}
                                                                projectId={this.props.groupId}
                                                                planningItemId={this.state.item.id}
                                                                hideMedia
                                                            /> :
                                                            null
                                                    }
                                                </FormControl>
                                            </FormSection>
                                        </form>
                                    </div>
                                    <div className="PlanningCardPopup-MainRight">
                                        <SocialBlock
                                            uberId={this.state.item.id}
                                            onlyDisplayCommentsContainer={true}
                                        />
                                    </div>
                                </div>
                                <div className="PlanningCardPopup-Footer">
                                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                        <DefaultButton text={localize("TaBort")} onClick={() => {
                                            this.setState({
                                                displayDeleteCardDialog: true
                                            });
                                        }} />
                                        <DefaultButton text={localize("Avbryt")} onClick={this.close.bind(this)} />
                                        <PrimaryButton text={localize("Spara")} onClick={this.save.bind(this)} />
                                    </Stack>
                                </div>

                            </div>
                    }
                    {
                        this.renderDeleteTodoDialog()
                    }
                    {
                        this.renderDeleteFileDialog()
                    }
                    {
                        this.renderDeleteCardDialog()
                    }
                    {
                        this.renderUnsavedChangesDialog()
                    }
                </div>
            </Modal>
        );
    }
}

export default connect()(PlanningCardPopup);
