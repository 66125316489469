import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PayrollView.scss";
import { DefaultButton, Dropdown, Modal, PrimaryButton, SpinButton, Stack, TextField } from "@fluentui/react";
import { localize } from "src/l10n";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { connect, useDispatch } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import { ActionMenu, Loader } from "src/ui";
import axios, { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import PopupHeader from "src/ui/components/PopupHeader";
import { validateRequiredTextField } from "src/utils";
import { SpintrTypes } from "src/typings";
import moment from "moment";
import { setConfirmPopup } from "src/popups/actions";

interface IProps {
}

interface IState {
    includeDeleted: boolean;
    showModal: boolean;
    item: any;
    isLoading: boolean;
}

const emptyItem = {id: 0};

const PayrollEditors = (props: IProps) => {
    const listRef = useRef<SpintrList>();
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        includeDeleted: false,
        showModal: false,
        item: emptyItem,
        isLoading: false
    });
    
    const closeModal = useCallback(() => {
        setState(s => ({
            ...s,
            showModal: false
        }));
    }, []);
    
    const save = useCallback(() => {
        setState(s => ({
            ...s,
            isLoading: true
        }));

        const requests = [];

        for (let editor of state.item.editors) {
            requests.push(api.post("/api/v1/payroll/editors/toggle-editor", {
                ...editor,
                id: editor.key,
                isEditor: true
            }).then(() => {
                listRef.current.reFetch();
            }).catch(() => {}))
        }

        axios.all(requests).then(() => {
            listRef.current.reFetch();

            setState(s => ({
                ...s,
                isLoading: false,
                showModal: false,
                item: emptyItem
            }));
        }).catch(() => {});
    }, [state.item]);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        listRef.current.reFetch();
    }, [state.includeDeleted]);

    return (
        <div>
            <SpintrList
                ref={listRef}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        api.get("/api/v1/payroll/editors", {
                            params: {
                                includeDeleted: state.includeDeleted,
                                isAscending,
                                orderByColumn: columnId,
                                searchQuery: searchQuery,
                            },
                        })
                        .then((response: AxiosResponse) => {
                            resolve({
                                data: response.data.items,
                                totalCount: response.data.totalCount
                            });
                        }).catch(() => {});
                    });
                }}
                take={1000}
                orderByColumn={"externalId"}
                columns={[{
                    name: localize("Namn"),
                    fieldName: "name",
                    onRender: (item) => {
                        return (
                            <span>
                                {item.user.name}
                            </span>
                        );
                    },
                }, {
                    name: localize("Kontor"),
                    fieldName: "officeName",
                    minWidth: 250,
                    onRender: (item) => {
                        return (
                            <span>
                                {item.user.officeName}
                            </span>
                        );
                    },
                }, {
                    name: localize("SALARIES_UPDATED"),
                    fieldName: "lastActivity",
                    minWidth: 200,
                    onRender: (item) => {
                        if (!item.lastActivity) {
                            return null;
                        }

                        return (
                            <span>{moment(item.lastActivity).format("LLL")}</span>
                        )
                    }
                }, {
                    name: "",
                    key: "actionmenu",
                    minWidth: 40,
                    onRender: (item: any) => {
                        return (
                            <ActionMenu
                                categories={[
                                    {
                                        items: [{
                                                text: localize("SEND_REMINDER"),
                                                onClick: () => {
                                                    api.post("/api/v1/payroll/editors/send-reminder/" + item.id).then(() => {
                                                        dispatch(setConfirmPopup({
                                                            isOpen: true,
                                                            title: localize("REMINDER_SENT_SUBJECT"),
                                                            message: localize("REMINDER_SENT_BODY").replace("{0}", item.editor.name),
                                                            onConfirm: () => {}
                                                        }));
                                                    }).catch(() => {});
                                                }
                                            }, {
                                                text: localize("TaBort"),
                                                onClick: () => {
                                                    dispatch(setConfirmPopup({
                                                        isOpen: true,
                                                        title: localize("ArDuSakerAttDuVillRaderaDennaPost"),
                                                        onConfirm: () => {
                                                            api.post("/api/v1/payroll/editors/toggle-editor", {
                                                                ...item,
                                                                isEditor: false
                                                            }).then(() => {
                                                                listRef.current.reFetch();
                                                            }).catch(() => {});
                                                        }
                                                    }));
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        );
                    },
                }]}
                buttons={[
                    {
                        key: "add",
                        text: localize("LaggTill") + " " + localize("REPORTERS").toLowerCase(),
                        onClick: () => {
                            setState(s => ({
                                ...s,
                                showModal: true,
                                item: emptyItem
                            }));
                        },
                        iconProps: { iconName: "Add" },
                        className: "commandBarAddButton",
                    },
                ]}
            />
            <Modal
                allowTouchBodyScroll
                containerClassName="calendar-event-popup"
                className="spintr-modal modalWithPopupHeader"
                isOpen={state.showModal}
                onDismiss={closeModal}
            >
                <PopupHeader
                    text={localize("LaggTill") + " " + localize("REPORTERS").toLowerCase()}
                    onClose={closeModal} />
                <div>
                    {state.isLoading && <Loader />}
                    {!state.isLoading && (
                        <div className="popup-inner">
                            <FormSection>
                                <FormControl>
                                    <FormTokenizedObjectInput
                                        items={state.item.editors}
                                        types={[SpintrTypes.UberType.User]}
                                        onChange={(targets) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    editors: targets
                                                }
                                            }));
                                        }}
                                    />
                                </FormControl>
                                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                    <DefaultButton text={localize("Avbryt")} onClick={closeModal} />
                                    <PrimaryButton
                                        text={localize("LaggTill")}
                                        onClick={save}
                                    />
                                </Stack>
                            </FormSection>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default PayrollEditors;

