// @ts-ignore
const Event = window.Event

Object.assign(Event, {
    listen: (target, type, listener, priority, config) => {
        config = {
            passive: (!config || typeof config === 'boolean')
                ? false
                : config.passive || false
        }

        if (typeof target === 'string') {
            target = window.document.querySelector(target)
        }

        // TODO: priority

        if (typeof type === 'object') {
            let handles = {}
            Object.keys(type).forEach(k => {
                // @ts-ignore
                handles[k] = Event.listen(target, k, handles[k], priority, config)
            })
            return handles
        }

        if (!target) {
            throw new Error()
        }
    }
})

export default Event
