import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { IVisageSidebarMenuItem, VisageSidebarDrawerId, VisageSidebarMode } from "src/sidebar";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import VisageSidebarItems from "./VisageSidebarItems";

interface IProps {
    sidebarMode?: VisageSidebarMode;
    dispatch?: any;
    history?: any;
    sidebarItems?: IVisageSidebarMenuItem[];
    drawerId?: VisageSidebarDrawerId;
    isSmallViewMode: boolean;
    appMode: boolean;
    currentUser: any;
}

interface IState {
    showSubmenu: boolean;
}

class VisageResponsiveSubmenu extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showSubmenu: false
        }
    }

    public render(): ReactNode {
        const showSubmenu = (this.props.isSmallViewMode || this.props.appMode) &&
            this.props.sidebarMode === VisageSidebarMode.submenu &&
            this.props.currentUser.roles.indexOf("supplier") === -1;

        if (!showSubmenu) {
            return null;
        }

        return (
            <div className="VisageResponsiveSubmenu">
                <UnstyledButton className="VisageResponsiveSubmenu-button" onClick={() => {
                    this.setState({
                        showSubmenu: !this.state.showSubmenu
                    });
                }}>
                    <Visage2Icon icon="menu-1" />
                    <Label size="body-2" color="mid-grey">
                        {localize(this.state.showSubmenu ? "DoljUndermeny" : "VisaUndermeny")}
                    </Label>
                </UnstyledButton>
                {
                    this.state.showSubmenu && (
                        <div className="VisageResponsiveSubmenu-menu">
                            <VisageSidebarItems />
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        sidebarMode: state.sidebar.mode,
        sidebarItems: state.sidebar.items,
        drawerId: state.sidebar.drawerId,
        isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
        appMode: state.ui.appMode,
        currentUser: state.profile.active
    };
};

export default withRouter(connect(mapStateToProps)(VisageResponsiveSubmenu));
