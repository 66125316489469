import React, { Component } from "react";
import "./PagePreamble.scss";
import TinyFormattedContent from "./Tiny/displayment/TinyFormattedContent";

interface IProps {
    content: string;
    language: string;
}

class PagePreamble extends Component<IProps> {
    public render() {
        return (
            <TinyFormattedContent content={this.props.content} className={"PagePreamble"} language={this.props.language} />
        );
    }
}

export default PagePreamble;
