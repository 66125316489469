import { localize } from "src/l10n";
import { IVisageSidebarMenuItem, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";

export const updateSidebar = (isAdmin: boolean, isEditor: boolean, appName: string, dispatch): void => {
    const children = [
        ...(isAdmin || isEditor
            ? [
                {
                    key: "contact-persons",
                    name: localize("Kontaktpersoner"),
                    route: "/suppliers/contactpersons",
                    routes: ["/suppliers/contactpersons"],
                },
            ]
            : []),
        ...(isAdmin || isEditor
            ? [
                {
                    key: "supplier-files",
                    name: localize("SUPPLIER_FILES"),
                    route: "/suppliers/files",
                    routes: ["/suppliers/files"],
                },
            ]
            : []),
        ...(isAdmin || isEditor
            ? [
                {
                    key: "messages",
                    name: localize("Meddelanden"),
                    route: "/suppliers/messages",
                    routes: ["/suppliers/messages"],
                },
            ]
            : []),
        {
            key: "suppliers-admin",
            name: localize("Administration"),
            children: [
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "suppliers-groups",
                            name: localize("SUPPLIER_GROUPS"),
                            route: "/suppliers/groups",
                            routes: ["/suppliers/groups"],
                        },
                    ]
                    : []),
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "suppliers-categories",
                            name: localize("Kategorier"),
                            route: "/suppliers/categories",
                            routes: ["/suppliers/categories"],
                        },
                    ]
                    : []),
                {
                    key: "suppliers-product-codes",
                    name: localize("Produktkoder"),
                    route: "/suppliers/product-codes",
                    routes: ["/suppliers/product-codes"],
                },
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "suppliers-filecategories",
                            name: localize("Filkategorier"),
                            route: "/suppliers/filecategories",
                            routes: ["/suppliers/filecategories"],
                        },
                    ]
                    : []),
                ...(isAdmin || isEditor
                    ? [
                        {
                            key: "suppliers-roles",
                            name: localize("Roller"),
                            route: "/suppliers/roles",
                            routes: ["/suppliers/roles"],
                        },
                    ]
                    : []),
            ],
        },
    ];

    const items: IVisageSidebarMenuItem[] = [
        {
            key: "suppliers",
            name: !!appName ? localize(appName) : localize("appSuppliers"),
            icon: "hierarchy-square",
            iconStyle: "Custom",
            activeIcon: "hierarchy-square",
            route: "/suppliers",
            routes: ["/suppliers"],
            // search: "",
            children,
            isExpanded: true,
            isLoading: false,
            moduleKey: "suppliers",
            //activeMode: VisageSidebarMenuItemActiveMode.relative
        },
    ];

    dispatch(setSidebarItems(items, "suppliers"));
    dispatch(setSidebarMode(VisageSidebarMode.submenu));
    dispatch(expandToActiveItem());
};
