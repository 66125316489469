import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/nb';
import 'moment/locale/sv';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getLocalizationTag, localize } from 'src/l10n';
import OperatingInfoSidebarPost from 'src/operating-info/components/OperatingInfoSidebarPost';
import { Label } from 'src/ui';
import './OperatingInfoNotificationPopup.scss';

interface Props extends RouteComponentProps {
    operatingInfo: any;
    history: any;
    onClickCallback: any;
}

interface State { }

class OperatingInfoNotificationPopup extends Component<Props, State> {
    state = {};

    formatDate(dateString) {
        const d = new Date(dateString);
        //return d.toLocaleString(); //Time¨
        return moment(d).locale(getLocalizationTag()).format("Do MMM HH:mm");
    }

    gotoOperatingInfo = () => {
        this.props.history.push("/operatinginfo");
    };

    renderOperatingInfoOngoing = (oi) => {
        return <OperatingInfoSidebarPost onClickCallback={this.props.onClickCallback} post={oi} type={1} key={oi.id} />
    }

    renderOperatingInfoPlanned = (oi) => {
        return <OperatingInfoSidebarPost onClickCallback={this.props.onClickCallback} post={oi} type={3} key={oi.id} />
    }

    renderOngoingAndOrPlanned(operatingInfo) {
        let items = [];
        operatingInfo[0].items.length > 0 &&
            operatingInfo[0].items.map((oi) => {
                items.push(this.renderOperatingInfoOngoing(oi));
            });
        operatingInfo[1].items.length > 0 &&
            operatingInfo[1].items.map((oi) => {
                items.push(this.renderOperatingInfoPlanned(oi));
            });
        return items;
    }

    render() {
        const { operatingInfo } = this.props;
        const anyItems = operatingInfo[0].items.length > 0 || operatingInfo[1].items.length > 0;

        return (
            <div className="OperatingInfoNotificationPopup">
                {anyItems ? (
                    this.renderOngoingAndOrPlanned(operatingInfo)
                ) : (
                    <Label as="p" className="NoActiveOrPlanned" size="body-2">
                        {localize("DetFinnsIngaAktivaEllerPlaneradeDriftstorningar")}
                    </Label>
                )}
            </div>
        );
    }
}

export default withRouter(OperatingInfoNotificationPopup);
