import React, { createContext, FunctionComponent } from "react";


interface IAppPageFormContext {
    model: { [key: string]: any };
    updateModelProperty: (key: string, value: any) => void;
    submitForm: () => void;
    requestName?: string   
  }

  const defaultContext: IAppPageFormContext = {
      model: {},
      updateModelProperty: function (key: string, value: any): void {
          throw new Error("Function not implemented.");
      },
      submitForm: function (): void {
          throw new Error("Function not implemented.");
      },
 
  };

 const AppPageFormContext = createContext(defaultContext);


export default AppPageFormContext;