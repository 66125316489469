import { produce } from "immer";
import { Action } from "redux"

import {
    FETCH_NOTIFICATIONS_FULFILLED,
    FETCH_NOTIFICATIONS_PENDING,
    FETCH_NOTIFICATIONS_REJECTED,

    MARK_NOTIFICATION_AS_READ_PENDING,
    MARK_NOTIFICATION_AS_READ_REJECTED,

    MARK_NOTIFICATION_AS_UNREAD_PENDING,
    MARK_NOTIFICATION_AS_UNREAD_REJECTED,

    MARK_NOTIFICATIONS_AS_READ_PENDING,

    MARK_ALL_NOTIFICATIONS_AS_READ_PENDING,

    ADD_NOTIFICATION,

    REMOVE_NOTIFICATION,

    NotificationAction,
    SET_UNREAD_COUNT
} from './action-types'

export interface INotificationsError {
    exists: boolean;
    message?: string;
}

export interface INotificationsState {
    error: INotificationsError;
    hasMore: boolean;
    isLoading: boolean;
    items: Spintr.INotification[];
    unread: number;
    hasFetched: boolean;
}

const initialState: INotificationsState = {
    error: { exists: false },
    hasMore: false,
    isLoading: true,
    items: [],
    unread: 0,
    hasFetched: false
};

const notificationReducer = (state: INotificationsState = initialState, action: NotificationAction) => produce(state, draft => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_PENDING:
            draft.error = initialState.error;
            draft.isLoading = true;
            break;

        case FETCH_NOTIFICATIONS_REJECTED:
            draft.error = {
                exists: true
            };
            draft.isLoading = false;
            break;

        case SET_UNREAD_COUNT:
            draft.unread = action.meta;
            break;

        case FETCH_NOTIFICATIONS_FULFILLED:
            // Convert strings to dates
            const items: Spintr.INotification[] = action.payload.data.map(n => ({
                ...n,
                date: new Date(n.date)
            }));

            const current = state.items.slice();
            items.forEach(notification => {
                if (current.some(n => n.id === notification.id)) {
                    return;
                }

                current.push(notification);
            });
            
            if (action.meta.take) {
                draft.hasMore = items.length > 0;
            }

            draft.error = initialState.error;
            draft.isLoading = false;
            draft.items = current.sort((a, b) => a.date < b.date ? 1 : -1);
            draft.unread = 0;
            draft.hasFetched = true;

            break;

        case MARK_NOTIFICATIONS_AS_READ_PENDING:
            return {
                ...state,
                unread: 0
            }

        case MARK_ALL_NOTIFICATIONS_AS_READ_PENDING:
            return {
                ...state,
                unread: 0,
                items: state.items.map((i) => {
                    return {
                        ...i,
                        isRead: true
                    }
                })
            }

        case MARK_NOTIFICATION_AS_READ_PENDING:
            return {
                ...state,
                items: state.items.map((i) => {
                    if (i.id === action.meta.id) {
                        return {
                            ...i,
                            isRead: true
                        }
                    } else {
                        return i;
                    }
                })
            }

        case MARK_NOTIFICATION_AS_UNREAD_PENDING:
            return {
                ...state,
                items: state.items.map((i) => {
                    if (i.id === action.meta.id) {
                        return {
                            ...i,
                            isRead: false,
                        }
                    } else {
                        return i;
                    }
                })
            };

        case MARK_NOTIFICATION_AS_UNREAD_REJECTED:
            return {
                ...state,
                items: state.items.map((i) => {
                    if (i.id === action.meta.id) {
                        return {
                            ...i,
                            isRead: true,
                        }
                    } else {
                        return i;
                    }
                })
            };

        case ADD_NOTIFICATION:
            return {
                ...state,
                unread: state.unread ? (state.unread + 1) : 1,
                items: [
                    action.meta.notification,
                    ...state.items
                ]
            };

        case REMOVE_NOTIFICATION:
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.meta)
            }
    }
});

export default notificationReducer
