import { DefaultButton, PrimaryButton, Rating, RatingSize, Stack, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { IAcademyCourse } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";
import "./AcademyCourseDoneView.scss";
import SocialPostTypeContentConfetti from "src/social-feed/components/SocialPostTypeContent/SocialPostTypeContentConfetti";
import { FormControl, FormSection } from "src/ui/components/Forms";
import { ReactSVG } from "react-svg";
import SpintrLoader from "src/ui/components/Loader";

interface IProps {
    course: IAcademyCourse;
    onUpdate: (course: IAcademyCourse) => void;
    displayCongratulations: boolean;
    onClose: () => void;
}

interface IState {
    text: string;
    rating: number;
    isLoading: boolean;
}

const AcademyCourseDoneView = (props: IProps) => {
    const [state, setState] = useState<IState>({
        text: props.course.userProgress?.reviewComment || "",
        rating: props.course.userProgress?.reviewRating || 0,
        isLoading: false
    });

    const post = useCallback(() => {
        setState((s: IState) => ({
            ...s,
            isLoading: true
        }));

        api.post("/api/v1/academy/courses/" + props.course.id + "/rate", {
            rating: state.rating,
            comment: state.text
        }).then(() => {
            props.onUpdate({
                ...props.course,
                userProgress: {
                    ...props.course.userProgress,
                    reviewRating: state.rating
                }
            });

            props.onClose();
        }).catch(() => {
            setState((s: IState) => ({
                ...s,
                isLoading: false
            }));
        });
    }, [state.rating, state.text]);

    return (
        <div className="AcademyCourseDoneView">
            <div className="background">
                <img src={"/images/background_boxes_pattern.svg"} />
                <SocialPostTypeContentConfetti />
            </div>
            <div className="content">
                <div className="image-wrapper single">
                    <ReactSVG className="emoji" src={"/images/partying_face.svg"} />
                </div>
                <Label className="congratulations-title" size="h1" weight="semi-bold">{localize("ACADEMY_CONGRATULATIONS_1")}</Label>
                <Label className="congratulations-sub" size="h4">{localize("ACADEMY_CONGRATULATIONS_2").replace("{0}", props.course.name)}</Label>
                <Label className="review-info" color="grey">{localize("ACADEMY_CONGRATULATIONS_3")}</Label>
                <FormSection>
                    <FormControl>
                        <Rating
                            max={5}
                            size={RatingSize.Large}
                            rating={state.rating}
                            disabled={state.isLoading}
                            allowZeroStars
                            onChange={(event: React.FormEvent<HTMLElement>, rating?: number) => {
                                if (event.type === "focus") {
                                    return;
                                }

                                setState((s: IState) => ({
                                    ...s,
                                    rating
                                }));
                            }}
                        />
                    </FormControl>
                </FormSection>
            </div>
            {state.isLoading && (
                <div className="footer">
                    <SpintrLoader />
                </div>
            )}
            {!state.isLoading && (
                <div className="footer">
                    <DefaultButton
                        text={localize("Avbryt")}
                        onClick={props.onClose} />
                    <PrimaryButton
                        text={localize("RATE_THE_COURSE")}
                        onClick={post} />
                </div>
            )}
        </div>
    )
}

export default AcademyCourseDoneView;
