import { DefaultButton, Dropdown, IContextualMenuProps, IDropdownOption } from "@fluentui/react";
import Axios, { AxiosError } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateInstanceProperty } from "src/instance/actions";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { FloatingFooterBar, UnstyledButton } from "src/ui";
import { MixPanelEvents, mixpanelTrack } from "src/utils";

export interface IStartPageBuilderFooter {
    onSaveClick(status: Spintr.ContentStatus): void;
};

const StartPageBuilderFooter: FunctionComponent<IStartPageBuilderFooter> = (props) => {
    const [themes, setThemes] = useState<Spintr.ISpintrTheme[]>([]);
    const { onSaveClick } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useSelector<Spintr.AppState, Spintr.ISpintrTheme>(
        (state) => state.instance.get("theme") as Spintr.ISpintrTheme,
    );

    const [originalTheme] = useState<Spintr.ISpintrTheme>(theme);

    const options = useMemo<IDropdownOption[]>(
        () => themes.map<IDropdownOption>((t) => ({
            key: t.id,
            text: t.name,
        })),
        [themes],
    );

    const onThemeChange = useCallback(
        (_, option: IDropdownOption) => {

            dispatch(updateInstanceProperty(
                "theme",
                themes.find(theme => theme.id === option.key),
            ));

            mixpanelTrack(MixPanelEvents.Dynamo_ChangeTheme);
        },
        [dispatch, themes],
    );

    useEffect(
        () => {
            const cancelTokenSource = Axios.CancelToken.source();

            api
                .get<Spintr.ISpintrTheme[]>(
                    "/api/v1/themes",
                    { cancelToken: cancelTokenSource.token },
                )
                .then(
                    (response) => {
                        setThemes(response.data);
                    },
                    (error: AxiosError) => {
                        console.error(error);
                    }
                );

            return () => cancelTokenSource.cancel();
        },
        [setThemes],
    );

    // Restore original theme on unmount
    useEffect(
        () => () => dispatch(updateInstanceProperty(
            "theme",
            {...originalTheme},
        )),
        [originalTheme, dispatch],
    );

    const onCancelClick = useCallback(
        () => history.push("/edit-startpage"),
        [history],
    );

    const splitButtonOptions = useMemo<IContextualMenuProps>(
        () => ({
            items: [{
                key: "save",
                onClick: () => onSaveClick(SpintrTypes.ContentStatus.Published),
                text: localize("Spara"),
            }, {
                key: "saveAsDraft",
                onClick: () => onSaveClick(SpintrTypes.ContentStatus.Draft),
                text: localize("SparaSomUtkast"),
            }]
        }),
        [onSaveClick],
    );

    return (
        <FloatingFooterBar className="StartPageBuilderFooter">
            <div className="left">
                <div className="theme-selector">
                    <Dropdown
                        label={localize("VIEW_WITH_THEME") + ":"}
                        onChange={onThemeChange}
                        options={options}
                        selectedKey={theme.id}
                    />
                </div>
            </div>
            <div className="right buttons">
                <UnstyledButton
                    className="cancel-button fw-medium"
                    onClick={onCancelClick}
                >
                    {localize("Avbryt")}
                </UnstyledButton>
                <DefaultButton
                    onClick={() => onSaveClick(SpintrTypes.ContentStatus.Published)}
                    primary={true}
                    text={localize("Spara")}
                />
            </div>
        </FloatingFooterBar>
    );
};

export default StartPageBuilderFooter;
