import moment from "moment";
import { Text } from "@fluentui/react";
import React, { Component, createRef, MouseEvent, ReactNode, RefObject } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/index";
import { ActionMenu, Loader, UnstyledButton } from "src/ui";
import { FillHeight, Label } from "src/ui/components";
import { IFetchCalendarSidebarParams, ISetCalendarPopupParams } from "../action-types";
import { fetchCalendarSidebar, setCalendarPopup } from "../actions";
import { isNow, isSameDay, isToday } from "../util";
import "./CalendarSidebarWidget.scss";
import api from "src/spintr/SpintrApi";

interface IProps {
    fetchCalendarSidebar?: (params: IFetchCalendarSidebarParams) => void;
    events?: Spintr.ICalendarEvent[];
    relativeExternalSkip?: number;
    relativeSpintrSkip?: number;
    hasMore?: boolean;
    isLoading?: boolean;
    isLoadingMore?: boolean;
    history: any;
    googlePostAuth?: boolean;
    setCalendarPopup?: (params: ISetCalendarPopupParams) => void;
}

class CalendarSidebarWidget extends Component<IProps> {
    protected heightRef: RefObject<FillHeight>;

    protected static getDateString(event: Spintr.ICalendarEvent): string {
        const start = (event.start instanceof Date)
            ? event.start
            : new Date(event.start);
        const end = (event.end instanceof Date)
            ? event.end
            : new Date(event.end);

        if (isToday(start) && isToday(end)) {
            if (event.allDay) {
                return localize("Idag");
            }

            return [
                moment(start).format("HH:mm"),
                "-",
                moment(end).format("HH:mm")
            ].join(" ");
        }

        if (!isSameDay(start, end)) {
            if (event.allDay) {
                return [
                    moment(start).format("D MMMM"),
                    "-",
                    moment(end).format("D MMMM")
                ].join(" ");
            }

            return [
                moment(start).format("D MMMM HH:mm"),
                "-",
                moment(end).format("D MMMM HH:mm")
            ].join(" ");
        }

        if (event.allDay) {
            return moment(start).format("D MMMM");
        }

        return [
            moment(start).format("D MMMM HH:mm"),
            "-",
            moment(end).format("HH:mm")
        ].join(" ");
    }

    protected static getTimeRemaining(event: Spintr.ICalendarEvent): string {
        if (isNow(event)) {
            return localize("Pagaende");
        }

        const now = new Date();
        const end = new Date(event.end);
        if (end < now) {
            return null;
        }

        const start = new Date(event.start);
        if (event.allDay) {
            start.setHours(0);
            start.setMinutes(0);
        }

        const minutesLeft = Math.round(
            (start.getTime() - now.getTime()) / 60000
        );
        if (minutesLeft > 60) {
            return null;
        }

        if (minutesLeft <= 0) {
            return localize("Pagaende");
        }

        return localize("MINUTES_LEFT")
            .replace("{{x}}", minutesLeft.toString());
    }

    protected intervalRef: number;
    protected menuItems: Spintr.IActionMenuCategory[];

    constructor(events: IProps) {
        super(events);

        this.onCreateClicked = this.onCreateClicked.bind(this);
        this.onLoadMoreClicked = this.onLoadMoreClicked.bind(this);
        this.onConnectGoogleClicked = this.onConnectGoogleClicked.bind(this);
        this.renderEvent = this.renderEvent.bind(this);
        this.heightRef = createRef();

        this.menuItems = [{
            items: [{
                onClick: () => {
                    this.props.history.push({ pathname: "/calendar" });
                },
                text: localize("GaTillKalendern")
            }]
        }]
    }

    public componentDidMount(): void {
        // Force update every minute to refresh time remaining
        this.intervalRef = setInterval(
            (this.forceUpdate.bind(this)) as TimerHandler,
            60000
        );

        if (this.props.isLoading || this.props.events.length > 0) {
            return;
        }

        this.props.fetchCalendarSidebar({
            eventCategory: 2,
            take: 3,
        });
    }

    public componentWillUnmount() {
        if (this.intervalRef) {
            clearInterval(this.intervalRef);
        }
    }

    public render(): ReactNode {
        const { hasMore, isLoading, isLoadingMore } = this.props;

        const now = new Date();
        const events = this.props.events
            .filter(event => (new Date(event.end) >= now));

        return (
            <div className="CalendarSidebarWidget">
                <div className="widget-header">
                    <Text as="span" className="header" variant="smallPlus">
                        <Label as="h4"
                            size="body-3"
                            weight="medium"
                            uppercase>
                            {localize("KommandeHandelser")}
                        </Label>

                    </Text>
                    <div className="calendar-menu">
                        <ActionMenu
                            categories={this.menuItems}
                        />
                    </div>
                </div>
                <div className="inner">
                    {isLoading && (
                        <Loader />
                    )}
                    {!isLoading && events.length === 0 && (
                        <div className="spintr-list-empty-list">
                            <Label className="spintr-list-empty-list-label" as="p" size="body-2">
                                {localize("CALENDAR_EMPTY_PLACEHOLDER")}
                            </Label>
                        </div>
                    )}
                    {!isLoading && events.length > 0 && (
                        events.map(this.renderEvent)
                    )}
                    {!isLoading && !isLoadingMore && hasMore && (
                        <UnstyledButton className="load-more" onClick={this.onLoadMoreClicked}>
                            <Text
                                as="a"
                                variant="smallPlus"
                            >
                                {localize("LaddaInFlera")}
                            </Text>
                        </UnstyledButton>
                    )}
                    {isLoadingMore && (
                        <Loader />
                    )}
                    <UnstyledButton className="create-button" onClick={this.onCreateClicked}>
                        <Text
                            className="primaryFGColorHover"
                            as="a"
                            variant="smallPlus"
                        >
                            {localize("SkapaNyHandelse")}
                        </Text>
                    </UnstyledButton>
                    {
                        this.props.googlePostAuth && (
                            <UnstyledButton className="create-button" onClick={this.onConnectGoogleClicked}>
                                <Text
                                    className="primaryFGColorHover"
                                    as="a"
                                    variant="smallPlus"
                                >
                                    {localize("LoggaInMedGoogle")}
                                </Text>
                            </UnstyledButton>
                        )
                    }
                </div>
            </div>
        )
    }

    protected openCalendarEvent = (event: Spintr.ICalendarEvent) => {
        this.props.setCalendarPopup({ isOpen: true, event });
    }

    protected renderEvent(event: Spintr.ICalendarEvent): ReactNode {
        const key = `calsidebar.event${event.id || event.exchangeId || event.googleId}`;
        const dateString = CalendarSidebarWidget.getDateString(event);
        const timeRemaining = CalendarSidebarWidget.getTimeRemaining(event);

        const elem = document.createElement("div");
        elem.innerHTML = event.description
            .replace(/\[spintr:[0-9]+\]/gi, "");
        const description = elem.innerText
            .replace(/<!--[\s\S]*?-->/g, "");
        elem.remove();

        return (
            <UnstyledButton onClick={() => this.openCalendarEvent(event)} className="calendar-event" key={key}>
                <div className="inner">
                    <Text as="p" className="event-date" variant="small">
                        {dateString}
                    </Text>
                    {timeRemaining !== null && (
                        <div className="text-wrapper">
                            <Text as="p" className="status-text">
                                {timeRemaining}
                            </Text>
                            <div className="event-icon" />
                        </div>
                    )}
                    <Text as="p" className="event-title" variant="mediumPlus">
                        {event.title}
                    </Text>
                </div>
            </UnstyledButton>
        )
    }

    protected onCreateClicked(event: MouseEvent<HTMLAnchorElement>): void {
        this.props.setCalendarPopup({ isOpen: true });
    }

    protected onConnectGoogleClicked(): void {
        api.get("/api/google/authurl").then((response) => {
            if (response.data.url?.startsWith("http")) {
                window.location = response.data.url;
            }
        });
    }

    protected onLoadMoreClicked(event: MouseEvent<HTMLAnchorElement>): void {
        this.props.fetchCalendarSidebar({
            eventCategory: 2,
            take: 3,
            relativeExternalSkip: this.props.relativeExternalSkip,
            relativeSpintrSkip: this.props.relativeSpintrSkip
        });
    }
}

const stateMapper = (state: IApplicationState, props: IProps): IProps => ({
    ...props,

    events: state.calendar.sidebarEvents,
    hasMore: state.calendar.hasMoreSidebarEvents,
    isLoading: state.calendar.isLoadingSidebar,
    isLoadingMore: state.calendar.isLoadingMoreSidebar,
    relativeExternalSkip: state.calendar.relativeExternalSkip,
    relativeSpintrSkip: state.calendar.relativeSpintrSkip,
    googlePostAuth:
        state.instance.get("googleEnabled") &&
        state.instance.get("googlePostAuth") &&
        !state.profile.active.googleConnected &&
        state.instance
            .get("googleDomains")
            .split(",")
            .some(
                (domain: string) => domain === state.profile.active.email.substring(state.profile.active.email.indexOf("@") + 1)
            ),

})

const dispatchActions = {
    fetchCalendarSidebar,
    setCalendarPopup
};

export default connect(stateMapper, dispatchActions)(CalendarSidebarWidget);