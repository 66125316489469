import { ColorModel, IColor, IRgbaColor } from "./IColor";


class Color implements IColor {
    public static fromHex(hex: string): IColor {
        if (hex.substr(0, 1) === "#") {
            hex = hex.substr(1);
        }

        const isShortForm = hex.length === 3;

        const red = isShortForm
            ? hex.substr(0, 1) + hex.substr(0, 1)
            : hex.substr(0, 2);

        const green = isShortForm
            ? hex.substr(1, 1) + hex.substr(1, 1)
            : hex.substr(2, 2);

        const blue = isShortForm
            ? hex.substr(2, 1) + hex.substr(2, 1)
            : hex.substr(4, 2);

        return Color.fromRgba(
            parseInt(red, 16),
            parseInt(green, 16),
            parseInt(blue, 16),
            1
        );
    }

    public static fromRgba(
        red: number,
        green: number,
        blue: number,
        alpha: number
    ): IColor {
        const r = red / 255;
        const g = green / 255;
        const b = blue / 255;

        const min = Math.min(r, g, b);
        const max = Math.max(r, g, b);

        const lightness = (max + min) / 2;
        const delta = max - min;

        let hue = 0;
        switch (max) {
            case min: hue = 0; break;
            case r: hue = 60 * (g - b) / delta; break;
            case g: hue = 60 * (b - r) / delta + 120; break;
            case b: hue = 60 * (r - g) / delta + 240; break;
        }

        let saturation: number;
        if (max === min) {
            saturation = 0;
        } else if (lightness < 0.5) {
            saturation = delta / (2 * lightness);
        } else {
            saturation = delta / (2 - 2 * lightness);
        }

        return new Color(
            hue % 360,
            saturation * 100,
            lightness * 100,
            alpha
        );
    }

    private static clampAlpha(alpha: number): number {
        return Math.max(0, Math.min(alpha, 1));
    }

    private static clampDegrees(degrees: number): number {
        const leftovers = degrees % 360;

        return leftovers >= 0 ? leftovers : 360 + leftovers;
    }

    private static clampPercentage(percentage: number): number {
        return Math.max(0, Math.min(percentage, 100));
    }

    private hue: number;
    private saturation: number;
    private lightness: number;
    private alpha: number;

    constructor(
        hue: number,
        saturation: number,
        lightness: number,
        alpha: number = 0
    ) {
        this.hue = Math.round(Color.clampDegrees(hue) * 10) / 10;
        this.saturation = Math.round(Color.clampPercentage(saturation) * 10) / 10;
        this.lightness = Math.round(Color.clampPercentage(lightness) * 10) / 10;
        this.alpha = Color.clampAlpha(alpha);
    }

    public adjustHue(degrees: number) {
        const hue = Color.clampDegrees(this.hue + degrees);

        return new Color(
            hue,
            this.saturation,
            this.lightness,
            this.alpha
        );
    }

    public darken(percent: number): IColor {
        const lightness = Color.clampPercentage(
            this.lightness - this.lightness * (percent / 100)
        );

        return new Color(
            this.hue,
            this.saturation,
            lightness,
            this.alpha
        );
    }

    public lighten(percent: number): IColor {
        const lightness = Color.clampPercentage(
            this.lightness + this.lightness * (percent / 100)
        );

        return new Color(
            this.hue,
            this.saturation,
            lightness,
            this.alpha
        );
    }

    public desaturate(percent: number) {
        const saturation = Color.clampPercentage(
            this.saturation - this.saturation * (percent / 100)
        );

        return new Color(
            this.hue,
            saturation,
            this.lightness,
            this.alpha
        );
    }

    customLighten(brightness?: number, saturation?: number) {
        return new Color(
            this.hue,
            !!saturation ? saturation : this.saturation,
            !!brightness ? brightness : 95,
            this.alpha
        );
    }

    public toString(model: ColorModel): string {
        if (model === "hsla") {
            return `hsla(${this.hue},${this.saturation}%,${this.lightness}%,${this.alpha})`;
        }

        if (model === "hsl") {
            return `hsl(${this.hue},${this.saturation}%,${this.lightness}%)`;
        }

        const rgba = this.toRgba();

        if (model === "rgba") {
            return `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`;
        }

        if (model === "rgb") {
            return `rgb(${rgba.red}, ${rgba.green}, ${rgba.blue})`;
        }

        const red = rgba.red.toString(16);
        const green = rgba.green.toString(16);
        const blue = rgba.blue.toString(16);

        return "#" + [
            red.length === 1 ? `0${red}` : red,
            green.length === 1 ? `0${green}` : green,
            blue.length === 1 ? `0${blue}` : blue
        ].join("");
    }

    public toRgba(): IRgbaColor {
        const h = this.hue / 360;
        const s = this.saturation / 100;
        const l = this.lightness / 100;

        const m2 = l <= 0.5
            ? l * (s + 1)
            : l + s - l * s;

        const m1 = l * 2 - m2;

        const calculateHue = (n: number): number => {
            if (n < 0) {
                ++n;
            }

            if (n > 1) {
                --n;
            }

            if (n * 6 < 1) {
                return m1 + (m2 - m1) * n * 6;
            }

            if (n * 2 < 1) {
                return m2;
            }

            if (n * 3 < 2) {
                return m1 + (m2 - m1) * (2 / 3 - n) * 6;
            }

            return m1;
        };

        const clamp = (num: number): number =>
            Math.max(0, Math.min(Math.round(num), 255));

        return {
            alpha: this.alpha,
            blue: clamp(calculateHue(h - 1 / 3) * 0xFF),
            green: clamp(calculateHue(h) * 0xFF),
            red: clamp(calculateHue(h + 1 / 3) * 0xFF)
        };
    }
}

export default Color;