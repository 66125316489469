import { AxiosError, AxiosResponse } from 'axios';
import { constants } from 'perf_hooks';
import { Action } from 'redux';
import { fetchInformationFeed } from 'src/spintr/components/InformationFeed/actions';
import api from 'src/spintr/SpintrApi';
import { ActionTypes } from './action-types'

interface IFetchSpotlightAction extends Action {
    payload: Promise<AxiosResponse<Spintr.SpotlightNewsArticle[]>>;
    type: ActionTypes.FetchSpotlight;
}

interface IFetchSpotlightPendingAction extends Action {
    payload: Promise<AxiosResponse<Spintr.SpotlightNewsArticle[]>>;
    type: ActionTypes.FetchSpotlightPending;
}

interface IFetchSpotlightRejectedAction extends Action {
    payload: AxiosError<any>;
    type: ActionTypes.FetchSpotlightRejected;
}

interface IFetchSpotlightFulfilledAction extends Action {
    payload: AxiosResponse<Spintr.SpotlightNewsArticle[]>;
    type: ActionTypes.FetchSpotlightFulfilled;
}


export type FetchSpotlightAction =
      IFetchSpotlightAction
    | IFetchSpotlightPendingAction
    | IFetchSpotlightRejectedAction
    | IFetchSpotlightFulfilledAction;

export type FetchSpotlightActionHandler = () => (dispatch, getState) => void;

interface IRemoveSpotlightItemAction extends Action {
    payload: number;
    type: ActionTypes.RemoveSpotlightItem;
}

export type RemoveSpotlightItemActionHandler = (id: number) => IRemoveSpotlightItemAction;

const startTimer = (dispatch, getState, data) => {
    if (window.spotlightInterval) {
        clearInterval(window.spotlightInterval);
    }

    if (data.length > 0) {
        const nextExpiry = data.sort((a, b) => a.attachedEndDate.getTime() - b.attachedEndDate.getTime())[0];
        const nextExpiryTime = nextExpiry.attachedEndDate.getTime();

        window.spotlightInterval = setInterval(() => {
            if (new Date().getTime() > nextExpiryTime) {
                dispatch(removeSpotlightItem(nextExpiry.id));
                const informationFeed = (getState() as Spintr.AppState).informationFeed;

                if (informationFeed.params.includeNews) {
                    const params = { ...informationFeed.params };
                    params.take = informationFeed.items.length;
                    params.skip = 0;

                    dispatch(fetchInformationFeed(false, { params }, true));
                }

                data = data.filter(d => d.id !== nextExpiry.id);
                startTimer(dispatch, getState, data);
            }
        }, 10000);
    }
}

export const fetchSpotlight: FetchSpotlightActionHandler = () => (dispatch, getState) => {
    const payload = api.get("/api/v1/news/attached");
    payload.then((response) => {
        const data = response.data.map((news) => ({
            ...news,
            attachedEndDate: new Date(news.attachedEndDate),
        }));

        startTimer(dispatch, getState, data);
    });

    dispatch({
        payload,
        type: ActionTypes.FetchSpotlight,
    });
}

export const removeSpotlightItem: RemoveSpotlightItemActionHandler = (id: number) => ({
    type: ActionTypes.RemoveSpotlightItem,
    payload: id
});

interface IClearSpotlightAction extends Action {
    type: ActionTypes.ClearSpotlight;
}

export type ClearSpotlightHandler = () => IClearSpotlightAction;

export const clearSpotlight: ClearSpotlightHandler = () => ({
    type: ActionTypes.ClearSpotlight,
});

export type SpotlightAction = FetchSpotlightAction | IRemoveSpotlightItemAction | IClearSpotlightAction;