import React, { ReactElement, useCallback, useMemo } from "react";
import { Image } from "@fluentui/react";
import { ProductImageUploadProps } from "./ProductImageUpload.types";
import { ActionMenu } from "src/ui/components";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Text } from "src/components";

function ProductImageUpload(props: ProductImageUploadProps): ReactElement {
    const { image, onDelete, onMarkAsPrimary } = props;

    const onItemClicked = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        // Stop event from bubbling up
        if (event.preventDefault) {
            event.preventDefault();
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        }
        
        // No clue why
        const mouseEvent = (event as any) as MouseEvent;
        if (mouseEvent.stopImmediatePropagation) {
            mouseEvent.stopImmediatePropagation();
        }
    }, []);

    const onMakePrimaryClicked = useCallback(() => {
        if (onMarkAsPrimary) {
            onMarkAsPrimary(image);
        }
    }, [image, onMarkAsPrimary]);

    const onRemoveClicked = useCallback(() => {
        if (onDelete) {
            onDelete(image);
        }
    }, [image, onDelete]);

    const menuCategories = useMemo<Spintr.IActionMenuCategory[]>(() => [{
        items: [{
            text: localize("MAKE_PRIMARY"),
            onClick: onMakePrimaryClicked,
        }, {
            text: localize("TaBort"),
            onClick: onRemoveClicked,
        }],
    }], [onMakePrimaryClicked, onRemoveClicked]);

    const onActionButtonRendering = useCallback(() => (
        <Visage2Icon
            icon="more"
            size="small" />
    ), []);

    const primaryBanner = props.isPrimary ? (
        <div className="primary-banner">
            <Text color="white" size="bodySmall">{localize("IMAGE_PRIMARY")}</Text>
        </div>
    ) : null;

    return (
        <div className="ProductImageUpload" onClick={onItemClicked}>
            <div className="image-wrapper">
                <Image className="product-image"
                    alt={image.fileName}
                    src={image.thumbnailUrl}
                />
                {primaryBanner}
            </div>
            <ActionMenu
                categories={menuCategories}
                className="options-button"
                menuClassName="ProductImageUpload-options"
                renderButton={onActionButtonRendering}
            />
        </div>
    )
}

export default ProductImageUpload;
