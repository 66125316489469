import { Callout, Icon, Modal } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { fetchOperatingInfo, fetchOperatingInfoSupport, IFetchOperatingInfoParams } from "src/operating-info/actions";
import Color from "src/style/colors/Color";
import { Label, Scrollable, UnstyledButton } from "src/ui";
import { Style } from "src/ui/helpers";
import { mixpanelTrack } from "src/utils";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { IApplicationState } from "../reducer";
import "./OperatingInfoNotification.scss";
import OperatingInfoNotificationPopup from "./OperatingInfoNotificationPopup";
import { SpintrTypes } from "src/typings";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import classnames from "classnames";
import api from "../SpintrApi";
import { updateActiveUser } from "src/profile/actions";
import SpintrStaticLinksPopout from "./SpintrStaticLinksPopout";
import ContentWithUnreadIndicator from "src/ui/components/UnreadIndicator/ContentWithUnreadIndicator";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    dispatch?: (action: Action) => void;
    categories?: Spintr.IOperatingInfoCategory[];
    fetchOperatingInfo?: (params: IFetchOperatingInfoParams) => void;
    fetchOperatingInfoSupport?: () => void;
    isLoading?: boolean;
    instance?: any;
    isInHeader?: boolean;
    highlight: boolean;
    lastSeenOperatingInfo: Date;
    currentUser: any;
}

interface IState {
    isLoading: boolean;
    showPopup: boolean;
}

class OperatingInfoNotification extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            showPopup: false,
        }
    }

    protected iconAnimationRef?: any;

    getStatus = (props: IProps) => {
        let status = "";

        if (props.categories && props.categories.length > 0 && props.categories[0].items.length > 0) {
            status = "ongoing";
        } else if (props.categories && props.categories.length > 0 && props.categories[1].items.length > 0) {
            status = "planned";
        } else {
            status = "nonePlanned";
        }

        return status;
    }

    componentDidMount = () => {
        this.props.dispatch(
            fetchOperatingInfo({
                showDeleted: false,
                typesConfig: "operatingInfoSidebar",
            })
        );

        this.props.dispatch(fetchOperatingInfoSupport());
    };

    numberOfUnreadOperatingInfos(): number {
        const { categories } = this.props
        const plannedAndOngoing = categories[0]?.items.concat(categories[1]?.items)
        const numberOfUnseenOperatingInfos = plannedAndOngoing?.filter((o) => new Date(o.dateCreated) > new Date(this.props.lastSeenOperatingInfo))?.length

        return numberOfUnseenOperatingInfos
    }

    sendHasSeenOperatingInfo = async () => {
        await api.put("/api/v1/operatinginfo/updatelastseen");

        this.props.dispatch(updateActiveUser({
            ...this.props.currentUser,
            lastSeenOperatingInfo: new Date().toISOString()
        }));
    }

    dismiss = () => {
        this.setState({
            showPopup: false,
        });
    }

    render() {
        const id = "operating-info-" + (!!this.props.isInHeader ? 1 : 2);
        let { showPopup } = this.state;
        const highlight = this.props.highlight && this.props.isInHeader;
        const numUnread = this.numberOfUnreadOperatingInfos();
        const showIcon = true;

        if (this.props.isLoading) {
            return null;
        }

        const status = this.getStatus(this.props);

        const text = status === "ongoing" ?
            "stAktivaDriftstorningar" :
            status === "planned" ?
                "stKommandeDriftstorningar" :
                "IngaDriftstorningar";

        const shadowClass = status === "nonePlanned" ? "visage2-box-shadow" : "";

        return (
            <div className={"VisageSidebar-menuItem-wrapper " + status + " " + shadowClass}>
                {showPopup && (
                    <Callout
                        role="alertdialog"
                        gapSpace={0}
                        setInitialFocus
                        onDismiss={() => {
                            this.setState({
                                showPopup: false,
                            });
                        }}
                        target={"#" + id}
                    >
                        <OperatingInfoNotificationPopup onClickCallback={this.dismiss} operatingInfo={this.props.categories} />
                    </Callout>
                    
                    // <Modal
                    //     isOpen={true}
                    //     onDismiss={this.dismiss}
                    // >
                    //     <PopoutFrame title={localize("Driftinformation")}>
                    //         <OperatingInfoNotificationPopup onClickCallback={this.dismiss} operatingInfo={this.props.categories}  />
                    //     </PopoutFrame>
                    // </Modal>
                    
                )}
                <ContentWithUnreadIndicator
                    active={true}
                    count={numUnread}
                    secondaryPlacement={true}
                >
                    <UnstyledButton
                        id={id}
                        className={"VisageSidebar-menuItem VisageSidebar-menuItem-small" + (this.props.isInHeader ? " is-in-header" : "")}
                        title={localize(text)}
                        onClick={async () => {
                            this.setState({ showPopup: !showPopup });

                            if (!showPopup && highlight && numUnread > 0) await this.sendHasSeenOperatingInfo();

                            if (window.location.pathname === "/" && !showPopup) {
                                mixpanelTrack("Opened operating info");
                                startDeliverTrack(SpintrTypes.InsightsType.OpenedOperatingInfo);
                            }
                        }}>
                        <Visage2Icon
                            icon={status === "ongoing" ? "danger" : status === "planned" ? "clock" : "tick-circle"}
                            type={status === "nonePlanned" ? "bold" : "outline"}
                            color={status === "ongoing" ? "red" : status === "planned" ? "yellow" : "neutralBlue"} />
                        <Label
                            as="span"
                            key={status}
                            color={"dark-grey"}
                            size={this.props.isInHeader ? "body-2" : "body-3"}
                            className={classnames("operating-info-label", {"highlight": highlight})}>
                            <span>{localize(text)}</span>
                        </Label>
                        <Visage2Icon icon="arrow-down-1" size="small" />
                    </UnstyledButton>
                </ContentWithUnreadIndicator>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        categories: state.operatingInfo.currentCategories,
        isLoading: state.operatingInfo.isLoadingSidebar,
        highlight: state.instance.get('operatingInfoHighlight'),
        instance: state.instance,
        lastSeenOperatingInfo: state.profile.active.lastSeenOperatingInfo,
        currentUser: state.profile.active
    };
};

export default connect(mapStateToProps)(OperatingInfoNotification);
