import React, { FunctionComponent, PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../ErrorFallback";

type Props = PropsWithChildren<{}>;

const StandardErrorBoundary: FunctionComponent<Props> = (props) => (
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
    >
        {props.children}
    </ErrorBoundary>
);

export default StandardErrorBoundary;