import { DayOfWeek, TextField } from "@fluentui/react";
import React, { FormEventHandler, Fragment, ReactElement, useCallback } from "react";
import { localize } from "src/l10n";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { PlannerModel } from "../types";
import { SpintrTypes } from "src/typings";
import { PlannerFilePicker } from "../PlannerFilePicker";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import ITokenizedInputItem from "src/ui/components/Forms/ITokenizedInputItem";
import { getAvailableFileTypes, PlannerFileTypes } from "../utils";

function PlannerEventForm(props: PlannerItemFormProps<Spintr.PlannerEvent>): ReactElement {
    const { errors, model, onModelUpdate } = props

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);
            const isNumber = name === "numberOfParticipants" || name === "numberOfSeats";

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: !isNumber
                    ? value
                    : parseInt(value, 10) || undefined,
                },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    const onRegistrationDeadlineChanged = useCallback((date: Date) => onModelUpdate((prevModel) => ({
        ...prevModel,
        registrationDeadline: date,
    })), [onModelUpdate]);
    
    const onParticipantsChanged = useCallback(
        (items : ITokenizedInputItem[]) => onModelUpdate((prevModel) => {
            const participants = (prevModel.participants || [])
                .filter((participant) => items.some((item) => item.key !== participant.id));
                
            return {
                ...prevModel,
                participants: participants.concat(items
                    .filter((item) => !participants.some((participant) => participant.id === item.key))
                    .map((item) => ({
                        id: item.key,
                        name: item.name,
                        subText: item.subText,
                        imageUrl: item.imageUrl,
                    })))
            };
        }),
        [onModelUpdate],
    );

    if (model.itemType !== SpintrTypes.PlannerItemType.Event) {
        return null;
    }

    return (
        <Fragment>
            <FormControl>

                {/*
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_EVENT_PARTICIPANT_COUNT")}
                    name="numberOfParticipants"
                    onChange={onTextFieldChanged}
                    required={false}
                    type="text"
                    pattern="^[0-9]*$"
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.numberOfParticipants}
                    value={model.numberOfParticipants?.toString() || ""} />
                */}
                    
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_EVENT_HOSTS_SPEAKERS")}
                    multiline={true}
                    name="hostsAndSpeakers"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.hostsAndSpeakers}
                    value={model.hostsAndSpeakers || ""}
                />
            </FormControl>

            <FormControl label={localize("PLANNER_EVENT_AGENDA")} tooltipText={getAvailableFileTypes()}>

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerEventFileType.Agenda}
                    onModelUpdate={onModelUpdate}
                    simple={false} />

            </FormControl>

            <FormControl label={localize("PLANNER_EVENT_MATERIAL")} tooltipText={getAvailableFileTypes()}>

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerEventFileType.EventMaterial}
                    onModelUpdate={onModelUpdate}
                    simple={false} />

            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_EVENT_LOGISTICS")}
                    multiline={true}
                    name="logisticsInformation"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.logisticsInformation}
                    value={model.logisticsInformation || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={PlannerFileTypes}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerEventFileType.LogisticsInfo}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>

            <FormControl>

                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_EVENT_SEAT_COUNT")}
                    name="numberOfSeats"
                    onChange={onTextFieldChanged}
                    pattern="^[0-9]*$"
                    required={false}
                    type="text"
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.numberOfSeats}
                    value={model.numberOfSeats?.toString() || ""} />
                    
            </FormControl>

            <FormControl>
                
                <SpintrDatePicker
                    label={localize("PLANNER_EVENT_DEADLINE")}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={getDatePickerLanguageStrings()}
                    formatDate={undefined}
                    placeholder={localize("ValjDatum")}
                    ariaLabel={localize("ValjDatum")}
                    value={model.registrationDeadline}
                    isClearable={true}
                    maxDate={undefined}
                    isRequired={false}
                    aria-required={false}
                    onChangeHandler={onRegistrationDeadlineChanged} />

            </FormControl>
            
            {/*
            <FormControl>
                <FormTokenizedObjectInput
                    items={(model.participants || [])
                        .map((participant) => ({
                            key: participant.id,
                            name: participant.name,
                            subText: participant.subText,
                            imageUrl: participant.imageUrl,
                        }))
                    }
                    label={localize("PLANNER_EVENT_PARTICIPANTS")}
                    onChange={onParticipantsChanged}
                    types={[1]} />
            </FormControl>
            */}
        </Fragment>
    );
}

export default PlannerEventForm;
