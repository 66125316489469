import classNames from "classnames";
import { Icon } from "@fluentui/react";
import React, { Component } from "react";
import { fileTypeToExtensionMap } from "src/files/utils";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import "./TinyFile.scss";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import api from "src/spintr/SpintrApi";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchAttachedFile } from "src/pages/page-actions";

interface IProps {
    item: any;
    external?: boolean;
    single?: boolean;
    dispatch: any;
    attachedFile: any;
}

interface IState {
    isLoading?: boolean;
    item?: any;
    isError?: boolean;
}

class TinyFile extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: !!props.item.externalId
        };

        if (props.item.externalId) {
            this.fetchExternalFile();
        } else if (props.item.id && !props.item.name && !props.attachedfile) {
            this.props.dispatch(fetchAttachedFile(props.item.id))
        }
    }

    private fetchExternalFile = async () => {
        const source = parseInt(this.props.item.source, 10);

        if (source === SpintrTypes.FolderRootSource.GoogleDrive) {
            try {
                const response = await api.get<Spintr.GoogleDriveItem>(
                    `/api/v1/files/google/${this.props.item.externalId}`
                );

                const { data: file } = response;
                
                this.setState({
                    isLoading: false,
                    item: {
                        id: file.id,
                        name: file.name,
                        webLink: file.webViewLink,
                        downloadUrl: file.webContentLink,
                        type: file.fileType,
                        size: !file.size ? undefined : Math.round((file.size / 1024) * 10) / 10,
                    },
                });

            } catch (_) {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            }

            return;
        }

        try {
            const response = await api.get(`/api/v1/files/office365/${this.props.item.externalId}`, {
                params: { source: this.props.item.source },
            });

            this.setState({
                isLoading: false,
                item: response.data,
            });
        } catch {
            this.setState({
                isLoading: false,
                isError: true,
            });
        }
    };

    public render() {
        // const attachedFile = this.props.attachedFiles.find((f) => f.id == this.props.item.id) 
        const attachedFile = this.props.attachedFile;

        if (this.state.isLoading || attachedFile?.isLoading) {
            return <Loader />;
        }

        if (this.state.isError || attachedFile?.isError) {
            return <Label className="TinyFile-MissingRights">{localize("YOU_LACK_FILE_RIGHTS")}</Label>
        }

        const item = attachedFile || this.state.item || this.props.item;
        
        const showFileTypes = [
            SpintrTypes.FileType.Pdf,
            SpintrTypes.FileType.Jpg,
            SpintrTypes.FileType.Png,
            SpintrTypes.FileType.Gif,
        ];

        return (
            <div className={classNames("TinyFile-wrapper", { single: this.props.single })}>
                <div className="TinyFile">
                    <div className="icon-wrapper">
                        <Icon {...getFileTypeIconProps({ extension: fileTypeToExtensionMap[item.type], size: 16 })} />
                    </div>
                    <div className="file-name-wrapper">
                        <a
                            href={
                                this.props.external ? item.webUrl || item.downloadUrl || `/api/v1/servefile/${item.id}` : `/api/v1/servefile/${item.id}`
                            }
                            title={item.name}
                            target="_blank"
                        >
                            <Label size="body-2">{item.name}</Label>
                        </a>
                        {!this.props.external && showFileTypes.includes(item.type) && (
                            <a href={`/api/v1/servefile/${item.id}?show=true`} target="_blank">
                                <Label size="body-2">&nbsp;({localize("Visa")})</Label>
                            </a>
                        )}
                    </div>
                    <div className="file-size-wrapper">
                        <Label color="grey" size="body-2">
                            {item.size + " KB"}
                        </Label>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        attachedFile: state.pages.attachedFiles.find((f) => f.id == props.item.id)
    };
};

export default withRouter(connect(mapStateToProps)(TinyFile));
