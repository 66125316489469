import React, { MouseEvent, ReactNode } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import FilePicker from "../FilePicker";
import { ComposerContext } from "./ComposerContext";
import { IComposerExternalFileContent } from "./IComposerViewState";
import "./SocialComposerImages.scss";
import SocialComposerType, {
    IComposerSaveOptions,
    ISocialComposerTypeProps as BaseProps
} from "./SocialComposerType";

import "./SocialComposerExternalFile.scss";
import { Icon } from "@fluentui/react";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IStateProps {
    enableSharepoint: boolean;
    enableSharePointListSearch: boolean;
}

interface IState {
    isFilePickerOpen: boolean;
}

type Props = BaseProps & IStateProps;

class SocialComposerExternalFile extends SocialComposerType<Props, IState> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;

    private sources: Spintr.FolderRootSource[] = [];

    constructor(props: Props) {
        super(props);

        this.onFilesSelected = this.onFilesSelected.bind(this);
        this.toggleFilePicker = this.toggleFilePicker.bind(this);

        this.state = {
            isFilePickerOpen: false,
        };

        this.setFileSources();
    }

    public async saveContent(options: IComposerSaveOptions): Promise<Spintr.ISocialPostBase | null> {
        const contentState = this.getContentState();

        const selectedFiles = contentState?.selectedFiles || [];
        if (selectedFiles.length === 0) {
            return null;
        }

        const response = await api.post<any>("/api/v1/status", {
            externalFiles: selectedFiles.map((ef) => ({
                externalId: ef.file.id,
                source: ef.source
            })),
            feedId: options.feedId,
            flag: options.flag,
            text: options.text,
        }, {
            headers: {
                ...(options.identity?.id 
                    ? {"x-impersonate-user" : options.identity?.id} 
                    : {}
                )
            }
        });

        return response.data;
    }

    protected getContentState(): IComposerExternalFileContent {
        return this.context?.contentState as IComposerExternalFileContent || {
            selectedFiles: [],
        };        
    }

    protected renderTypeContent(): ReactNode {
        const contentState = this.getContentState();

        return (
            <div className="SocialComposerExternalFile">
                {!contentState?.selectedFiles?.length && (
                    <UnstyledButton
                        className="file-stage"
                        onClick={this.toggleFilePicker}
                    >
                        <Visage2Icon icon="arrow-circle-up" />
                        <Label
                            className="heading"
                            size="body-1"
                            weight="medium"
                        >
                            {localize("ValjFil")}
                        </Label>
                    </UnstyledButton>
                )}
                {contentState?.selectedFiles?.map((file) => (
                    <div className="external-file" key={
                        file.source + "_" + file.file.id
                    }>
                        <div className="file-info">
                            <span
                            >
                                {file.file.name}
                            </span>
                        </div>
                    </div>
                ))}
                
                {this.state.isFilePickerOpen && (
                    <FilePicker
                        onClose={this.toggleFilePicker}
                        onSelect={this.onFilesSelected}
                        sourcesToDisplay={this.sources}
                        source={this.sources[0]}
                        showFiles={true}
                        selectFolders={false}
                        allowMultipleFiles={true}
                    />
                )}
            </div>
        );
    }

    protected onFilesSelected(files: Spintr.IFileBrowserFile[], source: SpintrTypes.FolderRootSource) {
        const contentState = this.getContentState();

        const items = (contentState?.selectedFiles || []).slice();
        files.forEach(file => {
            const added = items.some(
                (item) => item.source === source && item.file.id === file.id,
            );

            if (added) {
                return;
            }

            items.push({ file, source });
        });
        
        this.context.setContentState({
            selectedFiles: items,
        });
    }

    protected onRemoveClicked(event: MouseEvent<HTMLDivElement>): void {
        // 
    }

    protected setFileSources(): void {
        if (!this.props.enableSharepoint) {
            return;
        }

        this.sources.push(SpintrTypes.FolderRootSource.Office365Public);
    };

    protected toggleFilePicker(): void {
        /*
        // TODO: Remove this 
        const contentState = this.getContentState();
        contentState.selectedFiles = [{
            file: {
                deleted: false,
                enableOnlineEdit: false,
                id: "1",
                isDirectory: false,
                isLoading: false,
                isLocked: false,
                itemCount: 0,
                lockedBy: 0,
                name: "Test.docx",
                newFolder: false,
                typeName: "Dokument",
            },
            source: 1
        }]
        this.context.setContentState(contentState);
        return;
        */

        this.setState((prevState) => ({
            isFilePickerOpen: !prevState.isFilePickerOpen,
        }));
    };
}

const ConnectedSocialComposerExternalFile = connect<IStateProps, {}, BaseProps, IApplicationState>(
    (state) => ({
        enableSharePointListSearch: false,
        enableSharepoint: state.instance.get("enableSharepoint") as boolean,
        // user: state.profile.active,
    }),
    null,
    null,
    { forwardRef: true }
)(SocialComposerExternalFile)

export default ConnectedSocialComposerExternalFile;
