import api from "src/spintr/SpintrApi";

import { SET_ALERTS, REMOVE_ALERT } from "./action-types";

export const fetchAlerts = () => {
    return {
        payload: api.get("/api/v1/emergencymessages/active"),
        type: SET_ALERTS,
    };
};

export const removeAlert = (alertId, userId) => {
    return {
        payload: api.post(`/api/emergencymessages/${alertId}/viewed/${userId}`),
        meta: { alertId },
        type: REMOVE_ALERT,
    };
};
