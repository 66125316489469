
import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./ContentWithInfoPanel.scss";
import classnames from 'classnames';

interface IProps {
    renderInfoPanel: any,
    template: number
    isSmallViewMode: boolean;
    visagePageStyle?: boolean;
    customDesign?: boolean;
    noMaxWidth?: boolean;
    customSmallViewModeTrigger?: number;
}

class ContentWithInfoPanel extends Component<IProps> {
    render() {
        let classNames = ["contentWithInfoPanel"];

        if (this.props.visagePageStyle) {
            classNames.push("visagePageStyle");
        }

        if (this.props.isSmallViewMode) {
            classNames.push("isSmallViewMode");
        }

        if (this.props.customDesign) {
            classNames.push("customDesign");
        }

        const combinedClassNames = classNames.join(" ");

        if (this.props.visagePageStyle) {
            if ((this.props.template === 1 || this.props.template === 4) && !this.props.isSmallViewMode) {
                return (
                    <div className={combinedClassNames}>
                        <div role="main" className={"contentWIthInfoPanelLeft" + (this.props.customDesign ? "" : " visage-box-shadow")} style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: 'calc(100% - 300px - 12px)'
                        }}>
                            {this.props.children}
                        </div>
                        <div className={"contentWIthInfoPanelRight print-ignore" + (this.props.customDesign ? "" : " visage-box-shadow")} style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: '300px',
                            marginLeft: '12px'
                        }}>
                            {this.props.renderInfoPanel()}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={combinedClassNames}>
                        <div
                            className={classnames("contentWIthInfoPanelLeft", "noInfoPanel", "visage-box-shadow", {
                                noMaxWidth: this.props.noMaxWidth,
                            })}
                            style={{ verticalAlign: "top" }}
                        >
                            {this.props.children}
                        </div>
                    </div>
                )
            }
        }

        if (this.props.isSmallViewMode) {
            return (
                <div className={combinedClassNames}>
                    <div role="main" className="contentWIthInfoPanelLeft" style={{ verticalAlign: "top" }}>
                        {this.props.children}
                    </div>
                    {this.props.template !== 3 && (
                        <div className="contentWIthInfoPanelRight print-ignore" style={{ verticalAlign: "top" }}>
                            {this.props.renderInfoPanel()}
                        </div>
                    )}
                </div>
            );
        }

        if (this.props.template === 1 || this.props.template === 4) {
            return (
                <div className={combinedClassNames}>
                    <div role="main" className="contentWIthInfoPanelLeft" style={{ display: 'inline-block', verticalAlign: 'top', width: '70%' }}>
                        {this.props.children}
                    </div>
                    <div className="contentWIthInfoPanelRight print-ignore" style={{ display: 'inline-block', verticalAlign: 'top', width: 'calc(30% - 20px)', marginLeft: '18px' }}>
                        {this.props.renderInfoPanel()}
                    </div>
                </div>
            )
        }

        if (this.props.template === 2) {
            return (
                <div className={combinedClassNames}>
                    <div className="contentWIthInfoPanelLeft" style={{ verticalAlign: 'top' }}>
                        {this.props.children}
                    </div>
                    <div className="contentWIthInfoPanelRight print-ignore" style={{ verticalAlign: 'top' }}>
                        {this.props.renderInfoPanel()}
                    </div>
                </div>
            )
        }

        if (this.props.template === 3) {
            return (
                <div className={combinedClassNames}>
                    <div role="main" className="contentWIthInfoPanelLeft" style={{ verticalAlign: 'top' }}>
                        {this.props.children}
                    </div>
                </div>
            )
        }

        return null;
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isSmallViewMode: props.customSmallViewModeTrigger
            ? state.ui.viewMode <= props.customSmallViewModeTrigger
            : state.ui.isSmallViewMode,
    };
};

export default (connect(mapStateToProps)(ContentWithInfoPanel));
