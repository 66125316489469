import { ContextualMenu, ContextualMenuItemType } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { uniqueId } from "src/utils";
import ActionMenuCategory from "./ActionMenuCategory";
import "./ActionMenuLayer.scss";

interface IActionMenuLayerProps {
    categories: Spintr.IActionMenuCategory[];
    visible: boolean;
    linkref?: any;
    onClick?: any;
    onDismiss?: any;
}

interface IActionMenuLayerState {
    componentId: string;
}
const convertCategoriesToMenuItems = (categories) => {
    let menuItems = categories.map((c) => {
        return {
            key: c.title ? c.title : undefined,
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                title: c.title ? c.title : undefined,
                items: c.items.map((ci) => {
                    return { key: ci.text, onClick: ci.onClick, text: ci.text };
                }),
            },
        };
    });
    return menuItems;
};

class ActionMenuLayer extends Component<IActionMenuLayerProps, IActionMenuLayerState> {
    constructor(props: IActionMenuLayerProps) {
        super(props);

        this.state = {
            componentId: uniqueId("ActionMenuLayer"),
        };

        this.renderCategories = this.renderCategories.bind(this);
    }

    public render(): ReactNode {
        return (
            <ContextualMenu
                items={convertCategoriesToMenuItems(this.props.categories)}
                hidden={!this.props.visible}
                target={this.props.linkref}
                onItemClick={this.props.onClick}
                onDismiss={this.props.onDismiss}
            />
        );
    }

    public renderCategories(category: Spintr.IActionMenuCategory, index: number): ReactNode {
        const key = `${this.state.componentId}.Category${index}`;

        return (
            <div className="category" key={key}>
                <ActionMenuCategory category={category} />
            </div>
        );
    }
}

export default ActionMenuLayer;
