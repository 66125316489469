import React, { useState } from "react";
import "./SocialPostTypeContentConfetti.scss";
import { ReactSVG } from "react-svg";

interface IProps {
    displayEmojis?: boolean;
}

const colors = ["#22234a", "#ffd300", "#fbb5d4"];

const getRandomColor = () => {
    return colors[Math.floor(Math.random()*colors.length)];
}

const getRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}

const SocialPostTypeContentConfetti = (props: IProps) => {
    const [pieces] = useState("1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22".split(",").map((num: any) => {
        return {
            backgroundColor: getRandomColor(),
            animationDelay: getRandomNumber(100, 5000) + "ms",
            animationDuration: getRandomNumber(1000, 3000) + "ms",
            transform: "rotate(" + getRandomNumber(-60, 60) + "deg)",
            left: getRandomNumber(5, 95) + "%"
        }
    }));

    return (
        <div className="type-content-confetti">
            <div className="confetti">
                {pieces.map((piece: any, key: number) => {
                    return (
                        <div className="confetti-piece" key={key} style={{
                            backgroundColor: piece.backgroundColor,
                            animationDelay: piece.animationDelay,
                            animationDuration: piece.animationDuration,
                            transform: piece.transform,
                            left: piece.left
                        }} />
                    )
                })}
            </div>
            {
                props.displayEmojis && (
                    <ReactSVG src={"/images/SomethingFun_party.svg"} className="emoji-left" />
                )
            }
            {
                props.displayEmojis && (
                    <ReactSVG src={"/images/SomethingFun_birthday.svg"} className="emoji-right" />
                )
            }
        </div>
    )
}

export default SocialPostTypeContentConfetti;