import React, { FunctionComponent } from "react";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.IUserBlockedNotification;
}

const UserBlockedNotification: FunctionComponent<IProps> = (props) => {
    return (
        <div className="UserBlockedNotification">
            {localize("X_HAS_BLOCKED_Y")
                .replace("{{X}}", props.notification.userName)
                .replace("{{Y}}", props.notification.blockedUserName)}
        </div>
    );
};

export default UserBlockedNotification;
