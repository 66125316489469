import { Icon } from "@fluentui/react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Label } from "src/ui";
import "./PageActionButton.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    label: string;
    iconName?: string;
    onClick?: any;
    link?: string;
    labelClassname?: string;
    fontSize?: number;
}

class PageActionButton extends Component<IProps> {
    render() {
        return (
            <Link to={this.props.link} onClick={this.props.onClick}>
                <div className="PageActionButton">
                    <Visage2Icon icon="calendar" color="mid-grey" />
                    <Label className={this.props.labelClassname} color="mid-grey" size="body-2">
                        {this.props.label}
                    </Label>
                </div>
            </Link>
        );
    }
}

export default PageActionButton;
