import { SearchBox, TextField } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useContext, useMemo } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { AppPageContext } from "../AppPageContext";
import { AppPageFormContext } from "../AppPageFormContext";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps extends IAppPageComponent {
    multiline?: boolean;
    name: string;
    placeholderText?: string;
    type?: string;
    pattern?: string;
}

const AppPageTextInput: FunctionComponent<IProps> = (props) => {
    const { multiline, name, pattern, placeholderText, type, variables } = props;
    const { model, updateModelProperty } = useContext(AppPageFormContext);
    const { localize } = useContext(AppPageContext);

    const inputRegex = useMemo(
        () => pattern ? new RegExp(pattern, "g") : undefined,
        [pattern],
    );

    const onChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!ev?.target) {
                return;
            }

            const { value } = ev.target;

            const filteredValue = inputRegex
                // @ts-ignore
                ? value.replaceAll(inputRegex, "")
                : value;

            updateModelProperty(name, filteredValue);
        },
        [name, updateModelProperty, inputRegex],
    );

    const onClear = useCallback(
        () => updateModelProperty(name, ""),
        [name, updateModelProperty],
    );

    const placeholder = useMemo(
        () => {
            let retVal = placeholderText || "";

            retVal = Object.keys(variables || {}).reduce(
                (str, key) => {
                    return str.replace(`{{${key}}}`, variables[key] || "")
                },
                retVal,
            );

            return localize(retVal);
        },
        [localize, placeholderText, variables],
    );

    const inputValue = model[name] || "";

    return type === "search"
        ? (
            <div className="AppPageTextInput SpintrSearch">
                <Visage2Icon icon="search-normal-1" color="visageGray3" />
                <SearchBox
                    name={name}
                    onChange={onChange}
                    onClear={onClear}
                    placeholder={placeholder}
                    value={inputValue}
                />
            </div>
        )
        : (
            <TextField
                multiline={multiline}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                value={inputValue}
            />
        );
}

export default AppPageTextInput;
