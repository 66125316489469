import React, { CSSProperties, FormEventHandler, ReactElement, useCallback } from "react";
import { UberContentColumn as ContentColumn } from "../UberContent.types"
import { Style } from "src/ui/helpers";
import { SpintrTypes } from "src/typings";
import { FormControl, FormTokenizedObjectInput } from "../../Forms";
import { PrimaryButton, Stack, TextField } from "@fluentui/react";
import { localize } from "src/l10n";
import { validateRequiredTextField } from "src/utils";
import ITokenizedInputItem from "../../Forms/ITokenizedInputItem";
import { ProductListColumn } from "src/components";

export type ProductsColumnProps = {
    column:                 ContentColumn;
    enabeFieldValidation?:  boolean | undefined;
    onColumnChanged:        (tmpId: string, callback: (column: ContentColumn) => ContentColumn) => void;
}

type ProductsColumnContent = {
    title: string;
    items: string[];
}

const emptyContent: ProductsColumnContent = {
    title: "",
    items: [],
};

const formStyle: CSSProperties = {
    backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
    borderRadius: "12px",
    padding: Style.getSpacingStr(3),
};

const tokenizedTypes: SpintrTypes.UberType[] = [SpintrTypes.UberType.Product];

const deserializeContent = (json: string): ProductsColumnContent => {
    try {
        const content = JSON.parse(json);

        return content;
    } catch (err) {
        console.error("Failed to parse content", err);
        return { ...emptyContent };
    }
};

function ProductsColumnForm(props: ProductsColumnProps): ReactElement {
    const { column, onColumnChanged } = props;

    const { tmpId } = column;
    let content: ProductsColumnContent = { ...emptyContent };
    
    if (column.content) {
        content = deserializeContent(column.content);
    }

    const onTitleChanged = useCallback(
        (_: unknown, newValue?: string) => onColumnChanged(
            tmpId,
            (column) => ({
                ...column,
                content: JSON.stringify({
                    ...deserializeContent(column.content),
                    title: newValue ?? "",
                })
            }),
        ),
        [tmpId, onColumnChanged],
    );

    const onProductsChanged = useCallback(
        (items: ITokenizedInputItem[]) => onColumnChanged(
            tmpId,
            (column) => ({
                ...column,
                content: JSON.stringify({
                    ...deserializeContent(column.content),
                    items,
                })
            }),
        ),
        [tmpId, onColumnChanged],
    );

    const onSaveClicked = useCallback(
        () => onColumnChanged(tmpId, (column) => ({
            ...column,
            state: SpintrTypes.UberContentSectionState.IsDisplayed,
        })),
        [tmpId, onColumnChanged],
    );

    return (
        <div
            className="EditableContentSectionsRow-IframeForm ProductsColumn"
            style={formStyle}
        >
            <FormControl>
                <TextField
                    aria-required={true}
                    autoFocus={true}
                    className="textField"
                    label={localize("Titel")}
                    name={column.tmpId}
                    required={true}
                    value={content.title ?? ""}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    validateOnLoad={!!props.enabeFieldValidation}
                    onGetErrorMessage={validateRequiredTextField}
                    onChange={onTitleChanged}
                />
            </FormControl>
            <FormControl>
                <FormTokenizedObjectInput
                    items={content.items}
                    label={localize("Sok")}
                    onChange={onProductsChanged}
                    required={true}
                    types={tokenizedTypes}
                    useSearchEngine={true}
                />
            </FormControl>
            <Stack horizontal={true} horizontalAlign="end">
                <PrimaryButton onClick={onSaveClicked}>
                    {localize("Spara")}
                </PrimaryButton>
            </Stack>
        </div>
    );
}

function ProductsColumnDisplay(props: ProductsColumnProps): ReactElement {
    return (
        <ProductListColumn
            columnContent={props.column.content} />
    )
}

export function ProductsColumn(props: ProductsColumnProps): ReactElement {
    const { column } = props;

    switch (column?.state) {
        case SpintrTypes.UberContentSectionState.IsDisplayed:
            return <ProductsColumnDisplay {...props} />;

        case SpintrTypes.UberContentSectionState.IsEditing:
            return <ProductsColumnForm {...props} />;

        default:
            return null;
    }
}
