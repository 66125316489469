import { DefaultButton, Dropdown, PrimaryButton, Separator, Stack, StackItem, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { isAnythingDirty, validateRequiredTextField } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { UnstyledButton } from "../../ui/components";
import { MandatoryText } from "../../ui/components/Forms";

interface IProps extends RouteChildrenProps {
    onCancel: any;
    note: any;
    targetId?: number;
    isInModal?: boolean;
    dispatch?: any;
}
interface IState {
    note: any;
    isLoading: boolean;
    saveError: string[];
    categories: any[];
    enableFieldValidation?: boolean;
}

class NotesEditView extends Component<IProps, IState> {
    state = {
        note: {} as any,
        isLoading: true,
        saveError: [],
        categories: [],
        enableFieldValidation: false
    };

    submit = () => {
        let payload = {} as any;

        if (this.refs.tiny) {
            payload = {
                ...this.state.note,
                // @ts-ignore
                content: this.refs.tiny.getContent(),
            };
        } else {
            payload = this.state.note;
        }

        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            if (!!this.props.targetId) {
                payload.targetId = this.props.targetId;
            }

            let saveError = [];

            if (!payload.title) {
                saveError.push("DuMasteFyllaIEttNamn");
            }

            if (!payload.content) {
                saveError.push("BlogError2");
            }

            if (saveError.length > 0) {
                this.setState({
                    saveError: saveError,
                    isLoading: false
                });

                return;
            }

            payload.tags = payload.tags?.map((tag) => tag.name);

            if (payload.id) {
                api
                    .put(`/api/v1/notes/${payload.id}`, payload)
                    .then((response) => {
                        this.setState({ isLoading: false }, () => {
                            this.props.onCancel
                                ? this.props.onCancel()
                                : this.props.history.push({
                                    pathname: this.props.targetId ? `/groups/${this.props.targetId}/notes` : `/notes`,
                                });
                        });
                    })
                    .catch((saveError) => {
                        this.setState({ isLoading: false, saveError: saveError.response.data.errorlist });
                    });
            } else {
                api.post("/api/v1/notes", payload).then((response) => {
                    this.setState({ isLoading: false }, () => {
                        this.props.onCancel
                            ? this.props.onCancel()
                            : this.props.history.push({
                                pathname: this.props.targetId ? `/groups/${this.props.targetId}/notes` : `/notes`,
                            });
                    });
                });
            }
        });
    };

    componentDidMount = () => {
        if (this.props.targetId) {
            api.get(`/api/v1/notes/categories?targetId=${this.props.targetId}`).then((response) => {
                const categories = response.data.map((category) => ({
                    key: category.id,
                    text: category.name,
                }));

                this.setState({ categories, });
            });
        }

        if (this.props.note) {
            api.get(`/api/v1/notes/${this.props.note.id}`).then((response) => {
                this.setState({
                    note: {
                        ...response.data, targets: response.data.targets.map((target) => {
                            return {
                                ...target,
                                key: target.id
                            }
                        })
                    },
                    isLoading: false,
                });
            });
        } else {
            this.setState({ isLoading: false });
        }
    };

    close() {
        let f = () => {
            this.props.onCancel ?
                this.props.onCancel() :
                this.props.history.push({
                    pathname: this.props.targetId ? `/groups/${this.props.targetId}/notes` : `/notes`,
                });
        }

        if (isAnythingDirty() && this.props.isInModal) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: f,
            }));
        } else {
            f();
        }
    }

    render() {
        const { note, isLoading, saveError } = this.state;

        if (isLoading) return <Loader />;
        return (
            <div>
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                {
                    !this.props.isInModal && (
                        <div>
                            <Stack horizontal verticalAlign="center">
                                <StackItem grow={1}>
                                    <Label as="h2" size="h2">
                                        {note.id ? localize("Anteckning") : localize("NyAnteckning")}
                                    </Label>
                                </StackItem>
                                <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.close.bind(this)}>
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            </Stack>
                            <Separator />
                        </div>
                    )
                }
                <MandatoryText />
                <form>
                    <FormControl>
                        <TextField
                            label={localize("Namn")}
                            name="name"
                            value={note.title}
                            onChange={(_event: React.FormEvent, title: string) => {
                                this.setState((state) => ({ note: { ...state.note, title } }));
                            }}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>

                    <FormControl>
                        <TinyEditorComponent
                            required
                            aria-required={true}
                            validateOnLoad={!!this.state.enableFieldValidation}
                            label={localize("Innehall")}
                            ref={"tiny"}
                            id={"23"}
                            content={note.content}
                            isInModal={this.props.isInModal}
                        />
                    </FormControl>

                    {this.props.targetId ? (
                        <>
                            <FormSection>
                                <FormControl>
                                    <Dropdown
                                        label={localize("Kategori")}
                                        options={[
                                            {
                                                key: 0,
                                                text: localize("Ingen"),
                                            },
                                            ...this.state.categories,
                                            {
                                                key: -1,
                                                text: localize("NyKategori"),
                                            },
                                        ]}
                                        defaultSelectedKey={this.state.note.categoryId || 0}
                                        onChange={(event, value) => {
                                            console.log(value);
                                            this.setState((prevState) => ({
                                                note: {
                                                    ...prevState.note,
                                                    categoryId: Number(value.key),
                                                    categoryName: "",
                                                },
                                            }));
                                        }}
                                    />
                                </FormControl>

                                {this.state.note.categoryId === -1 && (
                                    <FormControl>
                                        <TextField
                                            label={localize("Namn")}
                                            value={this.state.note.categoryName}
                                            onChange={(event, value) => {
                                                this.setState((prevState) => ({
                                                    note: { ...prevState.note, categoryName: value },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                )}
                                <FormControl>
                                    <FormTokenizedObjectInput
                                        hideImage={true}
                                        enableNewItems={true}
                                        items={this.state.note.tags?.map((tag) => {
                                            if (typeof tag === "string") {
                                                return {
                                                    name: tag,
                                                };
                                            } else {
                                                return {
                                                    name: !tag.text ? tag.name : tag.text,
                                                };
                                            }
                                        })}
                                        label={localize("Taggar")}
                                        onChange={(tags) => {
                                            this.setState({
                                                note: {
                                                    ...this.state.note,
                                                    tags,
                                                },
                                            });
                                        }}
                                        noAutocomplete
                                    />
                                </FormControl>
                            </FormSection>
                            <FormSection>
                                {this.state.note.id && (
                                    <SocialBlock uberId={this.state.note.id} onlyDisplayCommentsContainer />
                                )}
                            </FormSection>
                        </>
                    ) : (
                        <FormSection>
                            <FormTokenizedObjectInput
                                label={localize("Dela")}
                                types={[SpintrTypes.UberType.User]}
                                items={note.targets}
                                onChange={(targets) => {
                                    this.setState((prevState) => ({
                                        note: {
                                            ...prevState.note,
                                            targetId: 0,
                                            targets: targets.map((e) => {
                                                return {
                                                    ...e,
                                                    name: e.name,
                                                    info: e.subText,
                                                    type: SpintrTypes.UberType.User,
                                                    id: e.key,
                                                    key: e.key,
                                                    imageUrl: e.imageUrl,
                                                };
                                            }),
                                        },
                                    }));
                                }}
                            />
                        </FormSection>

                    )}

                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                        <DefaultButton
                            text={localize("Avbryt")}
                            onClick={this.close.bind(this)}
                        />
                        <PrimaryButton
                            disabled={isLoading}
                            text={localize("Spara") + " " + localize("Ubertype56_0_4")}
                            onClick={() => {
                                this.submit();
                            }}
                        />
                    </Stack>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(NotesEditView));