import React from "react";
import { localize } from "../../../l10n";
import Label from "../Label/Label";

const MandatoryText: React.FunctionComponent = () => (
    <Label className="obligatoryInfoText" as="p" size="body-2">
        {localize("ObligatoriskInfoText") + "."}
    </Label>
);

export default MandatoryText;
