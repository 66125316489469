import axios from "axios";
import moment from "moment";
import { Link } from "@fluentui/react";
import { FocusZone, FocusZoneDirection } from "@fluentui/react/lib/FocusZone";
import { Icon } from "@fluentui/react/lib/Icon";
import { List } from "@fluentui/react/lib/List";
import { getFocusStyle, getTheme, ITheme, mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";
import { Component } from "react";
import { localize } from "src/l10n";
import { ActionMenu, Label as SpintrLabel } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import "./Operatinginfo.scss";
import api from "src/spintr/SpintrApi";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: "border-box",
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: "flex",
            selectors: {
                "&:hover": { background: palette.neutralLight },
            },
        },
    ],
    itemImage: {
        flexShrink: 0,
    },
    itemContent: {
        marginLeft: 10,
        overflow: "hidden",
        flexGrow: 1,
    },
    itemName: [
        fonts.xLarge,
        {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
    warning: {
        alignSelf: "center",
        marginLeft: 10,
        marginRight: 10,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
        color: "red",
    },
    clock: {
        alignSelf: "center",
        marginLeft: 10,
        marginRight: 10,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
        color: palette.neutralTertiary,
    },
    completed: {
        alignSelf: "center",
        marginLeft: 10,
        marginRight: 10,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
        color: "green",
    },
});

interface IProps {
    isAdmin: boolean;
    items: any;
    history?: any;
    fetchList: () => void;
}

interface IState {
    isLoading: boolean;
}

class OperatinginfoList extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    async changeDelete(item) {
        item.isDeleted = !item.isDeleted;

        await api.post("/api/operatinginfo", item);

        this.props.fetchList();
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    renderIcon(type: number) {
        return (
            <Visage2Icon
                icon={type === 1 ? "danger" : type === 3 ? "clock" : "tick-circle"}
                size={"medium"}
                color={type === 1 ? "red" : type === 3 ? "yellow" : "green"} />
        )
    }

    onRenderCell = (item, index: number | undefined): JSX.Element => {
        const dateStart = moment(item.dateStart).format("LLL");
        let dateEnd = "";
        item.dateEnd ? (dateEnd = moment(item.dateEnd).format("LLL")) : (dateEnd = "");
        const icon = this.renderIcon(item.type);
        return (
            <div className={classNames.itemCell} data-is-focusable={true}>
                {icon}

                <div className={classNames.itemContent}>
                    <SpintrLabel as="h1" size="h3">
                        {item.title}
                    </SpintrLabel>
                    <SpintrLabel as="p" size="body-2">

                        {dateStart.toString()}{" "}
                        {item.dateEnd ? localize("Till").toLowerCase() + " " + dateEnd.toString() : ""}
                    </SpintrLabel>
                    <div className={classNames.itemIndex}>
                        {item.creator && (
                            <>
                                <SpintrLabel size="body-2">
                                    {localize("SkapadAv") + " "}
                                    <Link onClick={() => this.props.history.push(`/profile/${item.creator.id}`)}>
                                        {item.creator.name}
                                    </Link>
                                </SpintrLabel>
                                <p> </p>

                            </>
                        )}
                    </div>
                    {this.props.isAdmin ? (
                        <div id="top-right-actionmenu">
                            <ActionMenu
                                categories={[
                                    {
                                        items: [
                                            {
                                                text: localize("Redigera"),
                                                onClick: () => {
                                                    this.props.history.push(`/operatinginfo/${item.id}/edit`);
                                                },
                                            },
                                            {
                                                text: localize("TaBort"),
                                                onClick: () => {
                                                    this.changeDelete(item);
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <SpintrLabel as="p" size="body-4">
                        <span dangerouslySetInnerHTML={{
                            __html: item.text.replace(/(\r\n|\n)/g, "<br />"),
                        }} />
                    </SpintrLabel>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        return (
            <FocusZone direction={FocusZoneDirection.vertical} className="OperatinginfoList">
                <List items={this.props.items.items} onRenderCell={this.onRenderCell} />
            </FocusZone>
        );
    }
}

export default OperatinginfoList;
