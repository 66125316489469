export const distinctObjectArray = (arr: any[], key: string) => {
    let result: any[] = [];

    for (let item of arr) {
        if (!result.find(i => i[key] === item[key])) {
            result.push(item);
        }
    }

    return result;
};