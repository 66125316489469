import { Reducer } from "redux";

import AssistantAction, {
    IPostQuestionFulFilledAction,
    IPostQuestionPendingAction,
    IPostQuestionRejectedAction,
    ISetActiveSubjectDate,
    ISetActiveSubjects
} from "./actions";
import AssistantActionTypes from "./action-types";
import { IAnswerCitation, IMessage, MessageRole } from "./types";
import { localize } from "src/l10n";

export interface IAssistantState {
    isLoadingHistory: boolean;
    isLoadingResponse: boolean;
    messages: IMessage[];
    activeSubjectDate: Date;
    activeSubjects: string[];
}

type Handler<TType> = (state: IAssistantState, action: TType) => IAssistantState;

const initialState: IAssistantState = {
    isLoadingHistory: false,
    isLoadingResponse: false,
    messages: [],
    activeSubjectDate: new Date(),
    activeSubjects: []
};

const handlePostQuestion: Handler<IPostQuestionPendingAction> = (state, action) => ({
    ...state,
    isLoadingResponse: true,
    messages: [
        ...state.messages,
        {
            date: new Date(),
            role: MessageRole.User,
            text: action.meta.question,
        }
    ]
});

const handlePostQuestionRejected: Handler<IPostQuestionRejectedAction> = (state, action) => ({
    ...state,
    isLoadingResponse: false,
    messages: [
        ...state.messages,
        {
            date: new Date(),
            role: MessageRole.Assistant,
            text: localize("AI_ASSISTANT_ERROR"),
        }
    ]
});

const handlePostQuestionFulfilled: Handler<IPostQuestionFulFilledAction> = (state, action) => ({
    ...state,
    isLoadingResponse: false,
    messages: [
        ...state.messages,
        {
            citations: action.payload.data.citations.reduce<IAnswerCitation[]>(
                (acc, val) => acc.some(citation => citation.id === val.id)
                    ? acc
                    : acc.concat(val),
                [],
            ),
            date: new Date(),
            role: action.payload.data.role,
            text: action.payload.data.text,
        }
    ]
});

const handleSetActiveSubjectDate: Handler<ISetActiveSubjectDate> = (state, action) => ({
    ...state,
    activeSubjectDate: action.activeSubjectDate
})

const handleSetActiveSubjects: Handler<ISetActiveSubjects> = (state, action) => ({
    ...state,
    activeSubjects: action.activeSubjects
})

const handlerMapping = {
    [AssistantActionTypes.PostQuestionPending]: handlePostQuestion,
    [AssistantActionTypes.PostQuestionRejected]: handlePostQuestionRejected,
    [AssistantActionTypes.PostQuestionFulFilled]: handlePostQuestionFulfilled,
    [AssistantActionTypes.SetActiveSubjectDate]: handleSetActiveSubjectDate,
    [AssistantActionTypes.SetActiveSubjects]: handleSetActiveSubjects,
};

export const reducer: Reducer<IAssistantState, AssistantAction> =
    (state = initialState, action) => handlerMapping[action.type]
        ? handlerMapping[action.type].call(null, state, action) 
        : state;

export default reducer;
