import { ActionButton, PrimaryButton } from "@fluentui/react";
import React, { Fragment, FunctionComponent, MouseEvent, useState } from 'react';
import { Link } from "react-router-dom";
import { Action } from "redux";
import { respondToInvitation } from "src/calendar/calendar-api";
import { localize } from "src/l10n";
import { Loader } from "src/ui";
import { removeNotification } from "../actions";

interface IProps {
    notification: Spintr.INotification;
    dispatch: (action: Action) => void;
}

const EventInviteNotification: FunctionComponent<IProps> = (props)  => {
    const [ isLoading, setIsLoading ] = useState(false);

    const notification = props.notification as Spintr.IEventInviteNotification;
    if (!notification) {
        return;
    }

    const respond = async (accepted: boolean): Promise<void> => {
        setIsLoading(true);

        try {
            await respondToInvitation(
                notification.eventId,
                accepted ? 1 : 2
            );

            // TODO: get rid of this antipattern
            notification.hasResponded = true;
            props.dispatch(removeNotification(notification.id));

            setIsLoading(false);
        } catch (err) {
            console.log(err);

            setIsLoading(false);
        }
    };

    const accept = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        respond(true);
    };

    const reject = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        respond(false);
    };

    return (
        <Fragment>
            <div className="eventInfo">
                {localize("DuArInbjudenTillEvenemanget") + " "}
                <Link to={notification.url}>
                    {notification.eventName}
                </Link>
            </div>
            {!isLoading && notification.hasResponded !== true && (
                <div className="actions">
                    <ActionButton onClick={reject}>
                        {localize("Avsla")}
                    </ActionButton>
                    <PrimaryButton onClick={accept}>
                        {localize("Acceptera")}
                    </PrimaryButton>
                </div>
            )}
            {isLoading && <Loader />}
        </Fragment>
    );
};

export default EventInviteNotification;
