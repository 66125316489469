import { Image } from "@fluentui/react";
import React, { FunctionComponent, ReactElement, useMemo } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Link } from "react-router-dom";
import { BackgroundImage, Label } from "src/ui";
import { truncate } from "src/utils";
import { 
    IMarketplaceListItemComponentProps as OwnProps,
    IMarketplaceListItemStateProps as StateProps,
    MarketplaceListItemProps as Props
} from "./MarketplaceListItem.types";
import { localize } from "src/l10n";

const isRelative = (url: string): boolean => url.length > 0 && url.substring(0, 1) === "/";

const renderLink = (path : string, children : ReactElement): ReactElement => isRelative(path)
    ? <Link className="link" to={path}>{children}</Link>
    : <a className="link" href={path} target="blank">{children}</a>;

const MarketplaceListItem: FunctionComponent<Props> = (props) => {
    const { app, companyName, userEmail } = props;
    const { id, byRequest, name } = app;

    const path = useMemo(() => {
        if (id) {
            return `/marketplace/app/${id}`;
        }
        
        const url = `https://share.hsforms.com/1R5N3v4c3SQKAJ68TzG1e-w1k8u4?email=${userEmail}&company=${companyName}`;

        return url + ((byRequest && name) ? "&kalla_app_=" + name : "")
    }, [id, byRequest, name, companyName, userEmail]);

    return (
        <div className="MarketplaceListItem">
            <div className="body">
                <div className="heading">
                    <div className="logo">
                        <Image
                            src={app.iconUrl}
                            height={48}
                            width={48}
                        />
                    </div>
                    {renderLink(path, (
                        <Label
                            as="div"
                            className="name"
                            color="dark-grey"
                            size="body-5"
                            weight="medium"
                        >{app.name}</Label>
                    ))}
                </div>
                <Label
                    className="description"
                    color="dark-grey"
                >{truncate(app.description || "", 80)}</Label>
            </div>
            {renderLink(path, (
                <Label as="div" size="body-1" weight="medium" color="dark-grey">
                    {localize(!app.id
                        ? "SUGGEST_NEW_APP"
                        : "VIEW_INTEGRATION")}
                </Label>
            ))}
        </div>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, Spintr.AppState> =
    (state) => ({
        companyName: state.instance.get("instance").get ? state.instance.get("instance").get("name") : state.instance.get("instance").name,
        userEmail: state.profile.active.email,
        userName: state.profile.active.name,
    });

const ConnectedMarketplaceListItem = connect(
    mapStateToProps
)(MarketplaceListItem);

export default ConnectedMarketplaceListItem;
