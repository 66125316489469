import React, { ReactElement, useEffect } from "react";
import { ProductMentionProps as Props } from "./ProductMention.types";
import { Product } from "src/products/types";

import "./ProductMention.scss";

function ProductMention(props: Props): ReactElement {
    const { children, contentState, entityKey, onRemoved } = props;
    
    const product = contentState
        .getEntity(entityKey)
        ?.getData()
        ?.product as Product | undefined;

    /*
    useEffect(() => () => {
        onRemoved?.(product);
    }, [product, onRemoved]);
    */
    return (
        <span className="ProductMention" title={product?.name}>
            {children}
        </span>
    );
}

export default ProductMention;
