import { PrimaryButton } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useContext, useMemo } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { AppPageContext } from "../AppPageContext";
import { AppPageFormContext } from "../AppPageFormContext";

interface IProps extends IAppPageComponent {}

const AppPageSubmitButton: FunctionComponent<IProps> = ({ text, variables }) => {
    const { submitForm } = useContext(AppPageFormContext);
    const { localize } = useContext(AppPageContext);

    const onClick = useCallback(
        () => submitForm(),
        [submitForm],
    );

    const value = useMemo(
        () => {
            let retVal = text || "";

            retVal = Object.keys(variables || {}).reduce(
                (str, key) => {
                    return str.replace(`{{${key}}}`, variables[key] || "")
                },
                retVal,
            );

            return localize(retVal);
        },
        [localize, text, variables],
    );
    
    return (
        <PrimaryButton
            onClick={onClick}
            type="submit"
        >
            {value}
        </PrimaryButton>
    );
};

export default AppPageSubmitButton;
