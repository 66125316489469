import React, { Component } from 'react';
import { connect } from "react-redux";
import NotesView from "src/notes/components/NotesView";
import { IApplicationState } from "src/spintr/reducer";
import { FillHeight, Scrollable } from "src/ui/components";

interface IProps {
    dispatch?: any,
    group: any,
}

class GroupNotesView extends Component<IProps> {
    render() {
        return (
            <div className="GroupNotesView GroupContentView">
                <NotesView targetId={this.props.group.id} />
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    group: state.groups.group
});

export default connect(mapStateToProps)(GroupNotesView);
