import moment from "moment";

export default function getTimeOrDateFromDate(d) {
    if (typeof d === "string") {
        d = new Date(d);
    }

    const now = new Date();

    const isToday = d.getDate() === now.getDate() &&
        d.getMonth() === now.getMonth() &&
        d.getFullYear() === now.getFullYear();

    if (isToday) {
        return moment(d).format("LT");
    } else {
        return moment(d).format("D MMM");
    }
}