export const FETCH_MEDIA_CONTENT = "FETCH_MEDIA_CONTENT";
export const FETCH_MEDIA_CONTENT_PENDING = "FETCH_MEDIA_CONTENT_PENDING";
export const FETCH_MEDIA_CONTENT_REJECTED = "FETCH_MEDIA_CONTENT_REJECTED";
export const FETCH_MEDIA_CONTENT_FULFILLED = "FETCH_MEDIA_CONTENT_FULFILLED";

export const FETCH_MEDIA_FOLDER = "FETCH_MEDIA_FOLDER";
export const FETCH_MEDIA_FOLDER_FULFILLED = "FETCH_MEDIA_FOLDER_FULFILLED"

export const UPDATE_MEDIA_FOLDER = "UPDATE_MEDIA_FOLDER";

export const RESET_MEDIA_FOLDER_PATH = "RESET_MEDIA_FOLDER_PATH";

export const SET_MEDIA_TAG = "SET_MEDIA_TAG";

export const SET_MEDIA_SEARCH = "SET_MEDIA_SEARCH";

export const RESET_MEDIA_SEARCH_TAG = "RESET_MEDIA_SEARCH_TAG";