import React from "react";
import SocialPostContentItem from "../SocialPostContentItem/SocialPostContentItem";

interface IProps {
    post: Spintr.ISocialPostBase;
    isShare?: boolean;
}

const SocialPostContent = (props: IProps) => {
    if (!props.post.content ||
        props.post.content.length === 0) {
        return null;
    }

    return (
        <div className="SocialPostContent">
            {props.post.content.map((item: Spintr.ISocialPostBase, index: number) => {
                return (
                    <SocialPostContentItem post={item} parentPost={props.post} isShare key={index} />
                )
            })}
        </div>
    )
}

export default SocialPostContent;