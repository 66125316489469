import React, { Component, ReactNode, RefObject } from 'react';
import { findDOMNode } from 'react-dom';
import ScrollArea from '../helpers/ScrollArea';
import './ScrollableArea.scss';

interface IProps {
    className?: string;
    fade?: boolean;
    height?: number | string;
    maxHeight?: number | string;
    onEndReached?: () => void;
    onScroll?: () => void;
    persistent?: boolean;
    shadow?: boolean;
    tabIndex?: number;
    width?: number | string;
}

class ScrollableArea extends Component<IProps> {
    protected disposed: boolean = false;
    protected area: ScrollArea;
    protected body: RefObject<HTMLDivElement>;
    protected root: RefObject<HTMLDivElement>;
    protected wrap: RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.body = React.createRef();
        this.root = React.createRef();
        this.wrap = React.createRef();

        this.area = null;
        // @ts-ignore
        this.subscriptions = null
    }

    public componentDidMount(): void {
        // @ts-ignore
        this.applyScrollArea()

        const wrap = findDOMNode(this.wrap.current);
        if (!wrap) {
            return;
        }

        // TODO: Apply scroll boundry contain
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (prevProps.width !== this.props.width) {
            this.updateBodyWidth()
        }

        const area = this.getArea()
        if (!area) {
            return;
        }

        // area.throttledAdjustGripper()
    }

    public componentWillUnmount(): void {
        // @ts-ignore
        if (this.area) {
            // @ts-ignore
            this.area.destroy();
        }
        
        this.disposed = true;
        // this.subscriptions.release()
    }

    public getArea() {
        // @ts-ignore
        return this.area;
    }

    public render(): ReactNode {
        const style = {
            height: this.props.height
        };

        const props = { ...this.props }
        delete props.maxHeight;
        delete props.fade;
        delete props.persistent;
        delete props.shadow;

        const classNames = ["ScrollableArea", "native"];
        
        if (this.props.className) {
            classNames.push(this.props.className);
        }

        const maxHeight = this.props.maxHeight

        return (
            <div
                {...props}
                className={classNames.join(" ")}
                ref={this.root}
                style={style}
            >
                <div
                    className="ScrollableAreaWrap"
                    ref={this.wrap}
                    style={{ maxHeight }}
                >
                    <div className="ScrollableAreaBody" ref={this.body}>
                        <div className="ScrollableAreaContent">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected applyScrollArea(): void {
        if (this.disposed) {
            return;
        }

        const root = this.root.current;
        // @ts-ignore
        this.area = ScrollArea.fromNative(root, {
            fade: this.props.fade,
            persistent: this.props.persistent,
            shadow: this.props.shadow === undefined ? true : this.props.shadow,
            tabIndex: this.props.tabIndex
        });

        this.updateBodyWidth()

        if ((this.props.onScroll || this.props.onEndReached) && this.area) {
            this.area.subscribe(this.onScroll);
        }
    }

    protected onScroll(): void {
        console.log('Scrolling!');
        
        if (this.props.onScroll) {
            this.props.onScroll();
        }

        // @ts-ignore
        if (this.area && this.area.isScrolledToBottom()) {
            if (this.props.onEndReached) {
                this.props.onEndReached();
            }
        }
    }

    protected updateBodyWidth() {
        const body = this.body.current;
        
        body.style.width = this.props.width + 'px';
    }
}

export default ScrollableArea;
