import React, { memo } from "react";
import { OperatingInfoSidebarWidget } from "src/operating-info";
import "./OperatingInfo.scss";

interface IProps {
    hideTitle?: boolean;
    disableButtons?: boolean;
    history?: any;
}

export default memo(function OperatingInfo(props: IProps) {
    const { disableButtons } = props;

    return (
        <div className="operatingInfoBox">
            <OperatingInfoSidebarWidget disableButtons={disableButtons} hideTitle={props.hideTitle} />
        </div>
    );
});
