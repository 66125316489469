import { Modal } from "@fluentui/react";
import React, { CSSProperties, useState } from "react";
import PopupHeader from "src/ui/components/PopupHeader";
import SocialComposerTypes from "../Composer/SocialComposerTypes";
import { localize } from "src/l10n";
import SocialComposerHeader from "../Composer/SocialComposerUserHeader";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import SocialComposerTarget from "src/social-feed/components/SocialComposerTarget/SocialComposerTarget";
import { circleLarge } from "src/ui/helpers/style";
import { defaultFrameColors } from "./Visage2Composer";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";

interface IProps {
    post: Spintr.ISocialPostBase;
    onPostUpdate: any;
}

const Visage2ComposerColorSelector = (props: IProps) => {
    return (
        <div className="Visage2ComposerColorSelector-wrapper">
            <Label weight="medium" size="body-2">{localize("SELECT_THEME")}</Label>
            <div className="Visage2ComposerColorSelector">
                {defaultFrameColors.map((c, i) => {
                    const css: CSSProperties = {
                        background: `linear-gradient(${c.from}, ${c.to})`
                    };

                    const isSelected = props.post.color?.from === c.from && props.post.color?.to === c.to;

                    return (
                        <UnstyledButton
                            key={i}
                            className="item"
                            onClick={(e) => {
                                props.onPostUpdate({
                                    ...props.post,
                                    color: c,
                                    background: {
                                        start: {
                                            color: c.from
                                        },
                                        stop: {
                                            color: c.to
                                        }
                                    }
                                });
                            }}
                            style={css}
                        >
                            {isSelected && (
                                <div className="item-selected">
                                    <Visage2Icon icon="tick-circle" color="white" size="small" />
                                </div>
                            )}
                            {!isSelected && (
                                <div className="item-not-selected" />
                            )}
                        </UnstyledButton>
                    );
                })}
            </div>
        </div>
    )
}

export default Visage2ComposerColorSelector;