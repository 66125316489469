import { produce } from "immer";

import {
    FETCH_OPERATING_INFO_FULFILLED,
    FETCH_OPERATING_INFO_PENDING,
    FETCH_OPERATING_INFO_REJECTED,
    
    FETCH_OPERATING_INFO_SUPPORT_FULFILLED,

    OperatingInfoAction,
} from "./action-types";

export interface IOperatingInformationState {
    currentCategories: Spintr.IOperatingInfoCategory[];
    isLoadingSidebar: boolean;
    support: Spintr.IOperatingInfoSupport[];
}

const initialState: IOperatingInformationState = {
    currentCategories: [],
    isLoadingSidebar: false,
    support: [],
};

export const operatingInfoReducer = (
    state: IOperatingInformationState = initialState, 
    action: OperatingInfoAction
) : IOperatingInformationState => produce(state, draft => {
    switch (action.type) {
        case FETCH_OPERATING_INFO_PENDING:
            draft.isLoadingSidebar = true;
            break;

        case FETCH_OPERATING_INFO_REJECTED:
            draft.isLoadingSidebar = false;
            break;

        case FETCH_OPERATING_INFO_FULFILLED:
            draft.isLoadingSidebar = false;
            draft.currentCategories = action.payload.data.map(category => {
                category.items = category.items || [];
                category.items.forEach(item => {
                    item.dateStart = new Date(item.dateStart);

                    if (item.dateEnd) {
                        item.dateEnd = new Date(item.dateEnd);
                    }
                });
                return category;
            });
            break;

        case FETCH_OPERATING_INFO_SUPPORT_FULFILLED:
            draft.support = action.payload.data.support;
            break;
    }
});

export default operatingInfoReducer;