import { Icon, Separator, TooltipHost } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AdminShortcutsEditView from "src/admin/views/AdminShortcutsEditView";
import { fetchUserFavorites } from "src/favorites";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { fetchTeams } from "src/teams/actions";
import { SpintrTypes } from "src/typings";
import { SpintrUser, UnstyledButton, setShouldReloadFavourites } from "src/ui";
import LoadMoreButton from "src/ui/components/Buttons/LoadMoreButton/LoadMoreButton";
import { Label } from "src/ui/components/Label/Label";
import SpintrLoader from "src/ui/components/Loader";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import { circleSmall } from "src/ui/helpers/style";
import { uniqueId } from "src/utils";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./ShortcutsPopupView.scss";
import { fetchShortcuts } from "./actions";
import TeaserBoxesWrapper from "src/spintr/components/StartPageView/Wrappers/TeaserBoxesWrapper";
import RightColumn from "src/spintr/components/RightColumn/RightColumn";
import { TeaserBoxLoader } from "src/teaser-box";
import { setRightColumnItems } from "src/right-column/actions";

interface Props {
    currentUser?: IActiveUserProfile;
    instance: any;
    isEditor: boolean;
    isAdmin: boolean;
    dispatch?: any;
    isLoadingShortcuts?: boolean;
    shortcuts?: any[];
    favorites?: any[];
    isLoadingFavorites?: boolean;
    teams?: any[];
    isLoadingTeams?: boolean;
    extraWide?: boolean;
    setTitle?: any;
    setEditMode?: any;
    shouldReloadFavorites: boolean;
    isSmallViewMode?: boolean;
    rightColumnItems?: any[];
    hasFetchedRightColumnItems?: boolean;
    hasFetchedShortcuts?: boolean;
    office365Connected?: boolean;
    office365Enabled?: boolean;
}

interface State {
    isEditing: boolean;
    editId: number;
    limitTeams: boolean;
    searchValue: string;
    collapsableOpen: boolean;
}

type ShortcutsRow = "teaserBoxes" | "shortcuts" | "rightColumn" | "separator";

interface IShortcutsColumn {
    rows: ShortcutsRow[];
}

interface IShortcutsConfiguration {
    id: number;
    columns: IShortcutsColumn[];
}
const configurations: IShortcutsConfiguration[] = [{
    id: 0,
    columns: [{
        rows: ["teaserBoxes", "shortcuts", "rightColumn"]
    }]
}, {
    id: 1,
    columns: [{
        rows: ["teaserBoxes", "rightColumn"]
    }, {
        rows: ["separator"]
    }, {
        rows: ["shortcuts"]
    }]
}, {
    id: 2,
    columns: [{
        rows: ["shortcuts"]
    }, {
        rows: ["separator"]
    }, {
        rows: ["teaserBoxes", "rightColumn"]
    }]
}]

class ShortcutsPopupView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditing: false,
            editId: 0,
            limitTeams: true,
            searchValue: "",
            collapsableOpen: false,
        };
    }

    shortcutsRef = React.createRef<HTMLDivElement>();

    displayOfficeShortcuts = this.props.currentUser.office365Connected &&
        this.props.instance.get("office365Enabled") &&
        !this.props.instance.get("hideOffice365Shortcuts");

    displayTeams = this.props.currentUser.office365Connected &&
        this.props.instance.get('office365Enabled') &&
        this.props.instance.get('teamsTabEnabled') &&
        !this.props.currentUser.isGroupUser;

    formrefs = [];

    fetchShortcuts = () => {
        this.props.dispatch(fetchShortcuts());
    };

    fetchFavorites = () => {
        this.props.dispatch(fetchUserFavorites());
    }

    fetchTeams = () => {
        this.props.dispatch(fetchTeams());
    }

    fetchRightColumn = () => {
        api.get("/api/v1/sidebars?take=0").then((response) => {

            const items = response.data.map((d) => {
                return {
                    ...d,
                    formattedData: JSON.parse(d.data),
                };
            });

            this.props.dispatch(setRightColumnItems(items));
        });
    }

    componentDidMount = () => {

        if (!this.props.hasFetchedShortcuts) {
            this.fetchShortcuts();
        }

        if (!this.props.hasFetchedRightColumnItems) {
            this.fetchRightColumn();
        }

        if (!this.props.favorites || this.props.favorites.length === 0) {
            this.fetchFavorites();
        }

        if (this.displayTeams && (!this.props.teams || this.props.teams.length === 0)) {
            this.fetchTeams();
        }
    };

    renderOfficeShortcuts = () => {
        let officeShortcuts = [
            {
                name: "OneNote",
                imageUrl: "/images/onenote.svg",
                url: "https://www.office.com/launch/onenote",
            },
            {
                name: "Word",
                imageUrl: "/images/word.svg",
                url: "https://www.office.com/launch/word",
            },
            {
                name: "Excel",
                imageUrl: "/images/excel.svg",
                url: "https://www.office.com/launch/excel",
            },
            {
                name: "PowerPoint",
                imageUrl: "/images/powerpoint.svg",
                url: "https://www.office.com/launch/powerpoint",
            },
        ];

        if (!this.displayTeams) {
            officeShortcuts.push({
                name: "Teams",
                imageUrl: "/images/teams.svg",
                url: "https://teams.microsoft.com/",
            });
        }

        if (!!this.state.searchValue) {
            officeShortcuts = officeShortcuts.filter(s => s.name && s.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        return (
            <div className="collapse-menu">
                <UnstyledButton className="collapse-menu-header" onClick={() => {
                    this.setState({
                        collapsableOpen: !this.state.collapsableOpen
                    })
                }}>
                    <div className="div-left">
                        <Icon iconName="OfficeLogo" />
                        <Label style={{ marginLeft: 6 }} size="h6" weight="medium">Office 365</Label>
                    </div>
                    <div className="div-right">
                        <Visage2Icon icon={!this.state.collapsableOpen ? "arrow-down-1" : "arrow-up-2"} />
                        {/* <Icon className="lastIcon icon-animation animation-pulse" iconName="VisageWindowNew20Regular" title={localize("Oppna")} onClick={() => {
                        window.open("https://www.office.com/", "_blank");
                    }} /> */}
                    </div>
                </UnstyledButton>
                {!!this.state.collapsableOpen && <div className="officeShortcuts">
                    {officeShortcuts
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((s) => {
                            return (
                                <a className="officeShortcut" key={uniqueId()} href={s.url} target="_blank" title={s.name} tabIndex={0}>
                                    <div className="nameWrapper">
                                        <div className="officeShortcutImage">
                                            <img src={s.imageUrl} alt={s.name} />
                                        </div>
                                        <Label size="body-2">{s.name}</Label>
                                    </div>
                                    {/* <div className="addButton">
                                        <Visage2Icon icon={"add-circle"} />
                                    </div> */}
                                </a>
                            );
                        })}
                </div>}
            </div>
        );
    };

    edit = (id) => {
        // this.closeEditing();
        this.props.setEditMode(true);
        this.setState({ editId: id, isEditing: true }, () => {
            if (id == 0) {
                this.props.setTitle(`${localize("Skapa")} ${localize("Genvag").toLowerCase()}`)
            } else {
                this.props.setTitle(`${localize("Redigera")} ${localize("Genvag").toLowerCase()}`)
            }
        });
    };

    canEdit = (s) => {
        const canEdit = this.props.isEditor || this.props.isAdmin;
        return canEdit || (s.targets && s.targets.length === 1 && s.targets[0].id === this.props.currentUser.id);
    };

    isUrlSameSite(url) {
        var matches = url.match(/^(https?:)?\/\/([^\/]+)/);

        if (!matches) {
            return true;
        }

        return window.location.host === matches[2];
    }

    getTargetFromUrl(url) {
        return this.isUrlSameSite(url) ? "_self" : "_blank";
    }

    renderHighlights = () => {
        let highlightedShortcuts = this.props.shortcuts.filter((sc) => {
            return !!sc.highlight;
        });

        highlightedShortcuts = highlightedShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            highlightedShortcuts = highlightedShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!highlightedShortcuts || highlightedShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="highlightedShortcuts">
                <div>
                    {highlightedShortcuts
                        .map((s, idx) => {
                            return (
                                <div key={uniqueId()} className="highlightedShortcut">
                                    {s.formAction !== undefined ? (
                                        <div
                                            className="linkItem"
                                            onClick={() => {
                                                this.formrefs[s.id].submit();
                                            }}
                                        >
                                            <form
                                                ref={(ref) => {
                                                    this.formrefs[s.id] = ref;
                                                }}
                                                method="POST"
                                                id={`shortcutform${s.id}`}
                                                action={s.formAction}
                                                target="_blank"
                                            >
                                                <input
                                                    name="formAction"
                                                    value={String(s.formAction)}
                                                    type="hidden"
                                                />
                                                <input name="foo" value="bar" type="hidden" />
                                                <input
                                                    name={s.usernameName}
                                                    value={s.usernameValue}
                                                    type="hidden"
                                                />
                                                <input
                                                    name={s.passwordName}
                                                    value={s.passwordValue}
                                                    type="hidden"
                                                />
                                                <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                                <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                            </form>
                                            <SpintrUser
                                                size={50}
                                                name={s.title}
                                                squareImage={true}
                                                hideText={true}
                                                iconToDisplayInsteadOfInitials={"export-3"}
                                                imageUrl={s.imageUrl}
                                                personalName={false}
                                            />
                                            <Label size="body-2">{s.title}</Label>
                                        </div>
                                    ) : (
                                        <a
                                            className="linkItem popupShortcut"
                                            href={s.url}
                                            target={this.getTargetFromUrl(s.url)}
                                            title={s.title}
                                            tabIndex={0}
                                            onClick={() => {
                                                startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut);
                                            }}
                                        >
                                            <SpintrUser
                                                size={50}
                                                name={s.title}
                                                squareImage={true}
                                                hideText={true}
                                                iconToDisplayInsteadOfInitials={"export-3"}
                                                imageUrl={s.imageUrl}
                                                personalName={false}
                                            />
                                            <Label size="body-2">{s.title}</Label>
                                        </a>
                                    )}
                                    {!this.props.isSmallViewMode && this.editButton(s)}
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    }

    renderShortcuts = () => {
        let nonHighlightShortcuts = this.props.shortcuts.filter((sc) => {
            return !sc.highlight;
        });

        nonHighlightShortcuts = nonHighlightShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            nonHighlightShortcuts = nonHighlightShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (this.props.shortcuts.length === 0) {
            return (
                <Label centerText size="body-2" color="mid-grey">{localize("EMPTY_SHORTCUTS")}</Label>
            )
        }

        if (!nonHighlightShortcuts || nonHighlightShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="normalShortcuts">
                <Label size="h6" weight="semi-bold" className="subHeaderLabel">{localize("Genvagar")}</Label>
                <div className="normalShortcuts-inner">
                    {nonHighlightShortcuts
                        .map((s, idx) => {
                            return (
                                <div key={`shortcut-${idx}`} className="nonHighlightedShortcut">
                                    {s.formAction !== undefined && !!s.usernameName ? (
                                        <div
                                            className="shortcutLink"
                                            onClick={() => {
                                                //this.form.dispatchEvent(new Event("submit", { cancelable: true }));
                                                this.formrefs[s.id].submit();
                                            }}
                                        >
                                            <form
                                                ref={(ref) => {
                                                    this.formrefs[s.id] = ref;
                                                }}
                                                method="POST"
                                                id={`shortcutform${s.id}`}
                                                action={s.formAction}
                                                target="_blank"
                                            >
                                                <input name="formAction" value={String(s.formAction)} type="hidden" />
                                                <input name="foo" value="bar" type="hidden" />
                                                <input name={s.usernameName} value={s.usernameValue} type="hidden" />
                                                <input name={s.passwordName} value={s.passwordValue} type="hidden" />
                                                <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                                <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                                <Label size="body-2">{s.title}</Label>
                                            </form>
                                        </div>
                                    ) : (
                                        <a
                                            className="shortcutLink"
                                            href={s.url}
                                            target={this.getTargetFromUrl(s.url)}
                                            title={s.title}
                                            tabIndex={0}
                                        >
                                            <Label size="body-2">{s.title}</Label>
                                        </a>
                                    )}
                                    {!this.props.isSmallViewMode && this.editButton(s)}
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    }
    renderNonHighlightShortcuts = () => {
        let nonHighlightShortcuts = this.props.shortcuts.filter((sc) => {
            return !sc.highlight;
        });

        nonHighlightShortcuts = nonHighlightShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            nonHighlightShortcuts = nonHighlightShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!nonHighlightShortcuts || nonHighlightShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="nonHighlightedShortcuts">
                <div>
                    {nonHighlightShortcuts
                        .map((s) => {
                            return (
                                <div key={uniqueId()} className="nonHighlightedShortcut">
                                    {s.formAction !== undefined && !!s.usernameName ? (
                                        <div
                                            className="shortcutLink"
                                            onClick={() => {
                                                //this.form.dispatchEvent(new Event("submit", { cancelable: true }));
                                                this.formrefs[s.id].submit();
                                            }}
                                        >
                                            <form
                                                ref={(ref) => {
                                                    this.formrefs[s.id] = ref;
                                                }}
                                                method="POST"
                                                id={`shortcutform${s.id}`}
                                                action={s.formAction}
                                                target="_blank"
                                            >
                                                <input name="formAction" value={String(s.formAction)} type="hidden" />
                                                <input name="foo" value="bar" type="hidden" />
                                                <input name={s.usernameName} value={s.usernameValue} type="hidden" />
                                                <input name={s.passwordName} value={s.passwordValue} type="hidden" />
                                                <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                                <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                                <Label size="body-2">{s.title}</Label>
                                            </form>
                                        </div>
                                    ) : (
                                        <a
                                            className="shortcutLink"
                                            href={s.url}
                                            target={this.getTargetFromUrl(s.url)}
                                            title={s.title}
                                            tabIndex={0}
                                        >
                                            <Label size="body-2">{s.title}</Label>
                                        </a>
                                    )}
                                    <Visage2Icon icon="arrow-right-3" />
                                    {!this.props.isSmallViewMode && this.editButton(s)}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    closeEditing = () => {
        this.setState({ isEditing: false }, () => {
            this.fetchShortcuts();
            this.props.setTitle(localize("Genvagar"))
            this.props.setEditMode(false);
        });
    };

    form: HTMLFormElement;

    renderLinks() {
        let links = [{
            name: localize("Intranatskarta"),
            url: "/sitemap",
            icon: "SplitObject"
        }, {
            name: localize("appTags"),
            url: "/tags",
            icon: "Tag"
        }];

        if (!!this.state.searchValue) {
            links = links.filter(s => s.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!links || links.length === 0) {
            return null;
        }

        return (
            <div className="links module">
                {
                    links.map((link: any, index: number) => {
                        return (
                            <Link key={index} className="shortcuts-app-link" to={link.url}
                                onClick={() => { startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut); }}
                            >
                                <Icon iconName={link.icon} />
                                <Label size="body-2" className="link">{link.name}</Label>
                            </Link>
                        )
                    })
                }
            </div>
        )
    }

    deleteFavorite = async (favoriteId) => {
        await api.delete(`/api/v1/favorites/${favoriteId}`);
        // this.setState((prevState) => ({
        //     event: {
        //         ...prevState.event,
        //         isFavourite: false,
        //     },
        // }));

        this.props.dispatch(setShouldReloadFavourites(true));
        this.fetchFavorites();
    }

    deleteFavoriteButton = (favorite) => {
        return (
            <TooltipHost content={localize("TaBortFranFavoriter")}>
                <UnstyledButton
                    className="deleteFavorite"
                    title={localize("TaBortFranFavoriter")}
                    onClick={() => {
                        this.props.dispatch(setConfirmPopup({
                            isOpen: true,
                            title: localize("TaBortFranFavoriter"),
                            message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                            onConfirm: () => {
                                this.deleteFavorite(favorite.id);
                            }
                        }));
                    }}
                >
                    <Visage2Icon icon="trash" size="small" />
                </UnstyledButton>
            </TooltipHost>
        )
    }

    renderFavorites() {
        let favorites = [...this.props.favorites];

        if (!!this.state.searchValue) {
            favorites = favorites.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!favorites || favorites.length === 0) {
            return null;
        }

        return (
            <>
                <div className="favorites">
                    <Label weight="semi-bold" size="h6" className="subHeaderLabel">{localize("Favoriter")}</Label>
                    {favorites.map((item: any, index: number) => {
                        return (
                            <div key={`favorite-${index}`} className="favorite">
                                <Link
                                    onClick={() => { startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut); }}
                                    key={index}
                                    className={"link"}
                                    to={item.url}
                                >
                                    <Label size="body-2">{item.title}</Label>
                                </Link>

                                {this.deleteFavoriteButton(item)}
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    editMode = () => {
        let imageUrl = "";

        if (this.state.editId && this.props.shortcuts.includes(this.state.editId)) {
            imageUrl = this.props.shortcuts.find((s) => {
                return s.id === this.state.editId;
            }).imageUrl;
        }

        return (
            <div>
                <div className="shortcutsEditMode">
                    <AdminShortcutsEditView
                        imageUrl={imageUrl}
                        dropdownEditing={true}
                        pageId={this.state.editId}
                        closeEditingCallback={() => {
                            this.closeEditing();
                        }}
                    />
                </div>
            </div>
        )

    }

    collapsable365 = () => {
        const o365Enabled = this.props.office365Connected && this.props.office365Enabled;

        if (!o365Enabled) {
            return null;
        }

        return (
            <div>
                <div className="collapsable365">
                    {this.renderOfficeShortcuts()}
                </div>
            </div>
        )
    }

    teaserBoxes = () => {
        return (
            <div>
                <TeaserBoxLoader position={SpintrTypes.TeaserBoxPosition.Shortcuts} />
            </div>
        )
    }

    editButton = (shortcut) => {
        if (!this.canEdit(shortcut)) {
            return null
        }

        return (
            <TooltipHost content={localize("RedigeraGenvagen")}>
                <UnstyledButton
                    className="edit"
                    title={localize("Redigera")}
                    onClick={() => {
                        this.edit(shortcut.id);
                    }}
                >
                    <Visage2Icon icon="edit" size="small" />
                </UnstyledButton>
            </TooltipHost>
        )
    }

    renderPrioritizedShortcuts = () => {
        let highlightedShortcuts = this.props.shortcuts.filter((sc) => {
            return !!sc.highlight;
        });

        highlightedShortcuts = highlightedShortcuts.sort((a, b) => (a.title > b.title ? 1 : -1));

        if (!!this.state.searchValue) {
            highlightedShortcuts = highlightedShortcuts.filter(s => s.title.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);
        }

        if (!highlightedShortcuts || highlightedShortcuts.length === 0) {
            return null;
        }

        return (
            <div className="prioritized ">
                <Label weight="semi-bold" size="h6" className="subHeaderLabel">{localize("PrioriteradeGenvagar")}</Label>
                <div className="prioritizedInner">
                    {highlightedShortcuts
                        .map((s, idx) => {
                            return (
                                s.formAction !== undefined ? (
                                    <div
                                        key={`linkItem-${idx}`}
                                        className="linkItem"
                                        onClick={() => {
                                            this.formrefs[s.id].submit();
                                        }}
                                    >
                                        <form
                                            ref={(ref) => {
                                                this.formrefs[s.id] = ref;
                                            }}
                                            method="POST"
                                            id={`shortcutform${s.id}`}
                                            action={s.formAction}
                                            target="_blank"
                                        >
                                            <input
                                                name="formAction"
                                                value={String(s.formAction)}
                                                type="hidden"
                                            />
                                            <input name="foo" value="bar" type="hidden" />
                                            <input
                                                name={s.usernameName}
                                                value={s.usernameValue}
                                                type="hidden"
                                            />
                                            <input
                                                name={s.passwordName}
                                                value={s.passwordValue}
                                                type="hidden"
                                            />
                                            <input name={s.custom1Name} value={s.custom1Value} type="hidden" />
                                            <input name={s.custom2Name} value={s.custom2Value} type="hidden" />
                                        </form>

                                        <SpintrUser size={32} name={s.title} imageUrl={s.imageUrl} subText={s.description} personalName={false} />

                                        {!this.props.isSmallViewMode && this.editButton(s)}
                                    </div>
                                ) : (
                                    <a
                                        key={`linkItem-${idx}`}
                                        className="prioritizedShortcut"
                                        href={s.url}
                                        target={this.getTargetFromUrl(s.url)}
                                        title={s.title}
                                        tabIndex={0}
                                        onClick={() => {
                                            startDeliverTrack(SpintrTypes.InsightsType.ClickedShortcut);
                                        }}
                                    >
                                        <SpintrUser size={32} name={s.title} imageUrl={s.imageUrl} subText={s.description} personalName={false} />

                                            {!this.props.isSmallViewMode && this.editButton(s)}
                                    </a>
                                )
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    renderAllShortcuts() {
        return (
            <div className="allShortcuts module">
                {this.renderPrioritizedShortcuts()}
                {this.renderShortcuts()}
            </div>
        )
    }

    renderRightColumnItems() {
        const rightColumn = this.props.rightColumnItems.find((s) => s.contexts.indexOf("start") > -1);

        if (!rightColumn) {
            return null
        }

        return (
            <div className="rightColumn">
                <RightColumn />
            </div>
        )
    }



    render() {
        let imageUrl = "";

        if (this.state.editId && this.props.shortcuts.includes(this.state.editId)) {
            imageUrl = this.props.shortcuts.find((s) => {
                return s.id === this.state.editId;
            }).imageUrl;
        }

        if (this.props.isLoadingShortcuts || this.props.isLoadingFavorites) {
            return (
                <div className="SpintrLoader-wrapper">
                    <SpintrLoader />
                </div>
            );
        }
        const shortcutLayoutMode = this.props.instance.get("shortcutsLayoutMode");
        const activeLayout = parseInt(shortcutLayoutMode)
        const configuration = configurations.find(x => x.id === activeLayout && !this.props.isSmallViewMode) || configurations[0];

        if (this.state.isEditing) {
            return this.editMode()
        }

        else {
            return (
                <div className={"shortcutsLayer" + (this.props.extraWide ? " extra-wide" : "")}>
                    <div className={"shortcuts-content" + (activeLayout != 0 && !this.props.isSmallViewMode ? " row-layout" : " column-layout")}>
                        {configuration.columns.map((c: IShortcutsColumn, index: number) => {
                            return (
                                <div>
                                    {c.rows.map((c: ShortcutsRow, index: number) => {
                                        if (c === "teaserBoxes") {
                                            return (
                                                <>
                                                    {this.teaserBoxes()}
                                                </>
                                            )
                                        } else if (c === "shortcuts") {
                                            return (
                                                <div className="shortcuts-column">
                                                    {this.collapsable365()}
                                                    <div className="topContent">
                                                        <div className="search">
                                                            <SpintrSearch
                                                                placeholder={`${localize("Sok")} ${localize("Genvag").toLowerCase()}`}
                                                                value={this.state.searchValue}
                                                                onChange={(_ev, query) => {
                                                                    this.setState({
                                                                        searchValue: !!query ? query : "",
                                                                    });
                                                                }} />
                                                            <UnstyledButton
                                                                className="addButton"
                                                                onClick={() => {
                                                                    this.edit(0)
                                                                }}>
                                                                <Visage2Icon className="icon-animation animation-rotate" icon="add-circle" />
                                                            </UnstyledButton>
                                                        </div>
                                                    </div>
                                                    {this.renderAllShortcuts()}
                                                    {this.renderFavorites()}
                                                    {this.renderLinks()}
                                                </div>
                                            )
                                        } else if (c === "rightColumn") {
                                            return (
                                                <>
                                                    {this.renderRightColumnItems()}
                                                </>
                                            )
                                        }
                                        else if (c === "separator") {
                                            return (
                                                <div className="separator"></div>
                                            )
                                        }
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    shortcuts: state.shortcuts.items,
    hasFetchedShortcuts: state.shortcuts.hasFetched,
    isLoadingShortcuts: state.shortcuts.isLoading,
    favorites: state.favorites.items,
    isLoadingFavorites: state.favorites.isLoading,
    teams: state.teams.items,
    isLoadingTeams: state.teams.isLoading,
    shouldReloadFavorites: state.ui.shouldReloadFavourites,
    isSmallViewMode: state.ui.isSmallViewMode,
    rightColumnItems: state.rightColumn.items,
    hasFetchedRightColumnItems: state.rightColumn.hasFetchedItems,
    office365Connected: state.profile.active.office365Connected,
    office365Enabled: state.instance.get("office365Enabled"),
});

export default connect(mapStateToProps)(ShortcutsPopupView);
