import { produce } from "immer";

import {
    AuthAction,
    FETCH_AUTH_DATA_FULFILLED,
    FETCH_AUTH_DATA_PENDING,
    FETCH_AUTH_DATA_REJECTED,
    SET_APP_SETTINGS,
} from "./action-types";

export interface IAuthState {
    isLoading: boolean;
    isLoggedIn: boolean;
    languageTags: { [name: string]: string };
    appSettings: { [name: string]: any };
}

const initialState: IAuthState = {
    isLoading: true,
    isLoggedIn: false,
    languageTags: {},
    appSettings: {},
};

const authReducer = (state: IAuthState = initialState, action: AuthAction): IAuthState =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_AUTH_DATA_FULFILLED:
                draft.isLoading = false;
                draft.languageTags = action.payload.data.languageTags;
                draft.appSettings = action.payload.data.appSettings;
                break;

            case FETCH_AUTH_DATA_REJECTED:
                if (action.payload.response.data === "AlreadyLoggedIn") {
                    draft.isLoggedIn = true;
                }

                break;

            case SET_APP_SETTINGS:
                draft.appSettings = action.data;
                break;
        }
    });

export default authReducer;
