import moment from "moment";
import React from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import languages from "src/utils/languages";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./AcademyCourseMetaDataRow.scss";

interface IProps {
    lessonCount: number;
    userCount: number;
    language: number;
    date?: Date;
}

interface IColumnProps {
    icon?: string;
    text: string;
}

const AcademyCourseMetaDataRowColumn = (props: IColumnProps) => {
    return (
        <div>
            {!!props.icon && (
                <Visage2Icon icon={props.icon} size="small" color="grey" />
            )}
            <Label size="body-2" color="grey">{props.text}</Label>
        </div>
    )
}

const AcademyCourseMetaDataRow = (props: IProps) => {
    return (
        <div className="AcademyCourseMetaDataRow">
            {!!props.date && (
                <AcademyCourseMetaDataRowColumn text={localize("SenastUppdaterad") + " " + moment(props.date).format("LL")} />
            )}
            {props.lessonCount > 0 && (
                <AcademyCourseMetaDataRowColumn icon="book-1" text={props.lessonCount.toString()} />
            )}
            {props.userCount > 0 && (
                <AcademyCourseMetaDataRowColumn icon="profile-2user" text={props.userCount.toString()} />
            )}
            {props.language > 0 && (
                <AcademyCourseMetaDataRowColumn icon="global" text={languages.find(x => x.key === props.language.toString()).text} />
            )}
        </div>
    )
}

export default AcademyCourseMetaDataRow;
