import { Pivot, PivotItem } from "@fluentui/react";
import { AxiosResponse } from "axios";
import moment from "moment";
import { Stack } from "@fluentui/react";
import React, { Component, CSSProperties } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { SocialFeedType } from "src/social-feed";
import { SpintrSocialFeed } from "src/spintr/components";
import { InformationFeedLoader } from "src/spintr/components/InformationFeed";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Label, UnstyledButton } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import { Style } from "src/ui/helpers";
import "./OrganisationUnitView.scss";
import api from "src/spintr/SpintrApi";

interface IProps {
    match: any;
    history: any;
    isAdmin: boolean;
    isEditor: boolean;
    userOfficeId: number;
    userDepartmentId: number;
    userCompanyId: number;
    userDepartments: any[];
    userName: string;
    isSmallViewMode: boolean;
    unit: IUnitUnitView;
    blogsEnabled: boolean;
    newsEnabled: boolean;
    rssEnabled: boolean;
    primaryColor: string;
    userGalleryId: number;
    forceCompanyFeed: boolean;
    publicUnitNewsFeeds: boolean;
}

interface IState {
    isLoading: boolean;
    unit: IUnitUnitView;
    events: ICalendarEventUnitView[];
    isAdmin: boolean;
    isImpersonating: boolean;
    displayNewsActionMenu: boolean;
    displayRSSActionMenu: boolean;
    rssMenu: any[];
    showRSSModal: boolean;
    isLoadingRSS: boolean;
    feedLoaderKey: number;
    impersonatingGalleryId?: number;
    canSeeFeed: boolean;
}

interface IUnitUnitView extends Spintr.IUnit {
    location?: string;
}

interface ICalendarEventUnitView extends Spintr.ICalendarEvent {
    isMultipleDays: boolean;
}

class OrganisationUnitStartView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            unit: null,
            events: null,
            isAdmin: false,
            isImpersonating: false,
            displayNewsActionMenu: false,
            displayRSSActionMenu: false,
            rssMenu: [],
            showRSSModal: false,
            isLoadingRSS: false,
            feedLoaderKey: 0,
            canSeeFeed: true,
        };
    }

    componentDidMount() {
        if (this.props.unit.calendarId > 0) {
            api
                .get(`/api/v1/calendarevent`, {
                    params: {
                        calendarId: this.props.unit.calendarId,
                        eventCategory: SpintrTypes.EventCategory.Upcoming,
                        skip: 0,
                        take: 3,
                    },
                })
                .then((response: AxiosResponse) => {
                    var events = response.data as ICalendarEventUnitView[];

                    events = events.map((event) => {
                        event.start = new Date(event.start);
                        event.end = new Date(event.end);
                        event.isMultipleDays = !moment(event.start).isSame(event.end, "day");

                        return event;
                    });

                    this.setState({
                        events: response.data,
                    });
                });
        }

        const isAdmin =
            (this.props.isAdmin || this.props.isEditor) &&
            (this.props.unit.id == this.props.userDepartmentId || this.props.unit.id == this.props.userOfficeId || this.props.unit.id == this.props.userCompanyId);

        const canSeeFeed = !this.props.forceCompanyFeed || this.props.unit.id == this.props.userDepartmentId || this.props.unit.id == this.props.userOfficeId || this.props.unit.id == this.props.userCompanyId;

        this.setState({
            isLoading: false,
            isAdmin,
            canSeeFeed,
        });
    }

    renderFeed = (unit: IUnitUnitView) => {
        const canImpersonate =
            (this.props.isEditor || this.props.isAdmin) &&
            (this.props.unit.id == this.props.userDepartmentId || this.props.unit.id == this.props.userOfficeId || this.props.unit.id == this.props.userCompanyId);

        var identity = this.state.isImpersonating
            ? {
                  id: unit.socialUserId,
                  name: unit.name,
                  images: {
                      feedComposer: unit.imageUrl,
                  },
              }
            : null;

        return (
            <div className="feed">
                {canImpersonate && (
                    <div className="impersonationBanner mediumLineHeight">
                        <Label size="body-3" as="div">
                            {localize("DuPostarSomPage") +
                                " " +
                                (this.state.isImpersonating ? unit.name : this.props.userName) +
                                "."}
                        </Label>
                        <UnstyledButton
                            onClick={() => {
                                this.setState((prevState) => ({
                                    isImpersonating: !prevState.isImpersonating,
                                    impersonatingGalleryId: unit.galleryId
                                }));
                            }}
                        >
                            <Label size="body-3" as="div" color="light-blue">
                                {localize("AndraTill")}{" "}
                                {localize(this.state.isImpersonating ? this.props.userName : unit.name)}
                            </Label>
                        </UnstyledButton>
                    </div>
                )}
                <SpintrSocialFeed
                    identity={identity}
                    galleryId={this.state.isImpersonating ? this.state.impersonatingGalleryId : this.props.userGalleryId}
                    feedId={unit.feedId}
                    feedType={SocialFeedType.Profile}
                    userId={unit.id}
                    isUnit
                />
            </div>
        );
    };

    getDateStringTop = (event: ICalendarEventUnitView) => {
        if (event.isMultipleDays) {
            if (event.allDay) {
                return `${moment(event.start).format("L")} - ${moment(event.end).format("L")}`;
            } else {
                return `${moment(event.start).format("L")} ${moment(event.start).format("LT")} - ${moment(
                    event.end
                ).format("L")} ${moment(event.end).format("LT")}`;
            }
        } else {
            return moment(event.start).format("LL");
        }
    };

    getDateStringBottom = (event: ICalendarEventUnitView) => {
        if (event.allDay) {
            return localize("Heldag");
        }

        return `${moment(event.start).format("LT")} - ${moment(event.end).format("LT")}`;
    };

    renderEvents = (events: ICalendarEventUnitView[], renderEventHighlight: boolean) => {
        const eventHeaderStyle: CSSProperties = {
            backgroundImage: `url(${events && events[0] && events[0].imageUrl})`,
        };
        return (
            <Stack className="events" horizontal tokens={{ childrenGap: Style.getSpacing(4) }}>
                {renderEventHighlight && (
                    <div className="event-highlight">
                        <Link to={`/organisation/v/${this.props.unit.id}/calendar/${events[0].id}`}>
                            <div className="event-header" style={eventHeaderStyle}>
                                {this.renderEventDateBox(events[0])}
                            </div>
                            <Label className="event-title" size="h6" weight="medium">
                                {localize("Evenemang").toUpperCase()}
                            </Label>
                            {this.renderEventInfo(events[0])}
                        </Link>
                        <SocialBlock
                            onlyDisplayInteractionsBar
                            uberId={events[0].id}
                            onCommentIconClick={() => {
                                this.props.history.push({
                                    pathname: `/organisation/v/${this.props.unit.id}/calendar/${events[0].id}`,
                                });
                            }}
                        />
                    </div>
                )}

                <div className="event-list">
                    <Label className="header-title" size="h6" weight="medium">
                        {localize("Evenemang").toUpperCase()}
                    </Label>
                    {events.map((event) => (
                        <div className="event" key={event.id}>
                            <Link to={`/organisation/v/${this.props.unit.id}/calendar/${event.id}`}>
                                {this.renderEventDateBox(event)}
                                {this.renderEventInfo(event)}
                            </Link>
                        </div>
                    ))}
                    {events.length > 2 && (
                        <Link className="load-more" to={`/organisation/v/${this.props.unit.id}/calendar`}>
                            <Label size="h6">{localize("VisaFler")}</Label>
                        </Link>
                    )}
                </div>
            </Stack>
        );
    };

    renderEventDateBox = (event: ICalendarEventUnitView) => {
        return (
            <Stack className="date-box" verticalAlign="center" horizontalAlign="center">
                <Label role="heading" as="div" size="small-1" weight="normal">
                    {moment(event.start).format("DD")}
                </Label>
                <Label role="heading" as="div" size="small-1" weight="normal">
                    {moment(event.start).format("MMM").toUpperCase()}
                </Label>
            </Stack>
        );
    };

    renderEventInfo = (event: ICalendarEventUnitView) => {
        return (
            <div className="event-info">
                <Label className="title" weight="medium" size="body-2" title={event.title}>
                    {event.title}
                </Label>
                <Label color="mid-grey" size="body-2">
                    {this.getDateStringTop(event)}
                </Label>
                {!event.isMultipleDays && (
                    <Label color="mid-grey" size="body-2">
                        {this.getDateStringBottom(event)}
                    </Label>
                )}
            </div>
        );
    };

    renderNewsActionMenu() {
        return (
            <ActionMenu
                categories={[
                    {
                        items: [
                            {
                                text: localize("GaTillNyhetsarkivet"),
                                onClick: () => this.props.history.push(`/news`),
                            },
                        ],
                    },
                ]}
            />
        );
    }

    renderRSSActionMenu() {
        return (
            <ActionMenu
                categories={[
                    {
                        items: [
                            ...this.state.rssMenu.map((m) => {
                                return {
                                    text: m.name,
                                    id: m.id,
                                    isChecked: m.forceSubscription || m.isUserSubscribed,
                                    canCheck: true,
                                    onClick: () => {
                                        // this.toggleRSSSubscription(m);
                                    },
                                };
                            }),
                            {
                                text: localize("LaggTillRssFlode"),
                                onClick: () => {
                                    this.setState({ showRSSModal: true });
                                },
                            },
                        ],
                    },
                ]}
            />
        );
    }

    renderFeedLoader = () => {
        // <InformationFeedLoader take={6} unitId={this.props.unit.id} />
        // const socialText = localize("Handelser");
        const everythingText = localize("Allt");
        const newsText = localize("Nyheter");
        const blogsText = localize("Bloggar");
        const rssText = localize("appRSS");

        var identity = this.state.isImpersonating
        ? {
              id: this.props.unit.socialUserId,
              name: this.props.unit.name,
              images: {
                  feedComposer: this.props.unit.imageUrl,
              },
          }
        : null;

        return (
            <Pivot
                overflowBehavior={"menu"}
                onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
                    this.setState({
                        displayNewsActionMenu: item.props.headerText === newsText,
                        displayRSSActionMenu: item.props.headerText === rssText,
                    });
                    if (item.props.headerText === rssText) {
                        // this.fetchRSSFeeds();
                    }
                }}
                styles={{
                    linkIsSelected: {
                        color: this.props.primaryColor,
                    },
                }}
            >
                {/* {this.props.socialFeedEnabled && (
                            <PivotItem headerText={socialText}>
                                <SpintrSocialFeed
                                    feedId={this.props.userFeedId}
                                    feedType={SocialFeedType.Combined}
                                    galleryId={this.props.userGalleryId}
                                />
                            </PivotItem>
                        )} */}
                {this.props.newsEnabled && this.props.blogsEnabled && (
                    <PivotItem headerText={everythingText}>
                        <InformationFeedLoader identity={identity} take={6} unitId={this.props.unit.id} />
                    </PivotItem>
                )}
                {this.props.newsEnabled && (
                    <PivotItem
                        headerText={newsText}
                        onRenderItemLink={(props: any) => {
                            return (
                                <span className="PivotItemWithActionMenu">
                                    {props.headerText}
                                    {this.state.displayNewsActionMenu && this.renderNewsActionMenu()}
                                </span>
                            );
                        }}
                    >
                        <InformationFeedLoader
                            identity={identity}
                            take={6}
                            unitId={this.props.unit.id}
                            includeBlogPosts={false}
                        />
                    </PivotItem>
                )}
                {this.props.blogsEnabled && (
                    <PivotItem headerText={blogsText}>
                        <InformationFeedLoader
                            identity={identity}
                            take={6}
                            unitId={this.props.unit.id}
                            includeNews={false}
                        />
                    </PivotItem>
                )}
                {this.props.rssEnabled && (
                    <PivotItem
                        headerText={rssText}
                        onRenderItemLink={(props) => {
                            return (
                                <span className="PivotItemWithActionMenu">
                                    {props.headerText}
                                    {this.state.displayRSSActionMenu && this.renderRSSActionMenu()}
                                </span>
                            );
                        }}
                    >
                        <InformationFeedLoader
                            identity={identity}
                            key={this.state.feedLoaderKey}
                            unitId={this.props.unit.id}
                            isRss={true}
                        />
                    </PivotItem>
                )}
            </Pivot>
        );
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        const everythingText = localize("Allt");
        const newsText = localize("Nyheter");
        const blogsText = localize("Bloggar");
        const rssText = localize("appRSS");

        const { events, canSeeFeed } = this.state;
        const { publicUnitNewsFeeds }  = this.props
        const unit = this.props.unit;

        const isMember =
            this.props.unit.id == this.props.userDepartmentId ||
            this.props.unit.id == this.props.userOfficeId ||
            this.props.unit.id == this.props.userCompanyId;

        return (
            <div className="organisation-unit-view">
                {!this.props.isSmallViewMode ? (
                    <Stack tokens={{ childrenGap: Style.getSpacing(4) }} horizontal verticalAlign="start">
                        {canSeeFeed && this.renderFeed(unit)}
                        <div className="right">
                            {events && events.length > 0 && this.renderEvents(events, true)}
                            {publicUnitNewsFeeds || isMember ? (
                                <div className="InformationFeedModule">
                                    <div className="tabs">{this.renderFeedLoader()}</div>
                                </div>
                            ) : (
                                <div className="unauthorized">
                                    <Label className="text" size="body-2" color="mid-grey">
                                        {localize("ORG_PAGE_UNAUTHORIZED")}
                                    </Label>
                                </div>
                            )}
                        </div>
                    </Stack>
                ) : (
                    <>
                        <Pivot overflowBehavior={"menu"}>
                            {canSeeFeed && <PivotItem headerText={localize("HandelserFeed")}>{this.renderFeed(unit)}</PivotItem>}

                            {events && events.length > 0 && (
                                <PivotItem headerText={localize("Kalender")}>
                                    <div className="right">{this.renderEvents(events, false)}</div>
                                </PivotItem>
                            )}

                            {this.props.newsEnabled && this.props.blogsEnabled && (
                                <PivotItem headerText={everythingText}>
                                    <div className="right">
                                        {publicUnitNewsFeeds || isMember ? (
                                            <InformationFeedLoader
                                                take={6}
                                                unitId={this.props.unit.id}
                                            />
                                        ) : (
                                            <Label size="body-1">{localize("EjRattighetAttSeDettaInnehall")}</Label>
                                        )}
                                    </div>
                                </PivotItem>
                            )}

                            {this.props.newsEnabled && (
                                <PivotItem
                                    headerText={newsText}
                                    onRenderItemLink={(props) => {
                                        return (
                                            <span className="PivotItemWithActionMenu">
                                                {props.headerText}
                                                {this.state.displayNewsActionMenu && this.renderNewsActionMenu()}
                                            </span>
                                        );
                                    }}
                                >
                                    <InformationFeedLoader
                                        take={6}
                                        unitId={this.props.unit.id}
                                        includeBlogPosts={false}
                                    />
                                </PivotItem>
                            )}

                            {this.props.blogsEnabled && (
                                <PivotItem headerText={blogsText}>
                                    <InformationFeedLoader
                                        take={6}
                                        unitId={this.props.unit.id}
                                        includeNews={false}
                                    />
                                </PivotItem>
                            )}

                            {this.props.rssEnabled && (
                                <PivotItem
                                    headerText={rssText}
                                    onRenderItemLink={(props) => {
                                        return (
                                            <span className="PivotItemWithActionMenu">
                                                {props.headerText}
                                                {this.state.displayRSSActionMenu && this.renderRSSActionMenu()}
                                            </span>
                                        );
                                    }}
                                >
                                    <InformationFeedLoader
                                        key={this.state.feedLoaderKey}
                                        unitId={this.props.unit.id}
                                        isRss={true}
                                    />
                                </PivotItem>
                            )}
                        </Pivot>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        userDepartmentId: state.profile.active.department.id,
        userDepartments: state.profile.active.departments,
        userOfficeId: state.profile.active.department.office.id,
        userCompanyId: state.profile.active.department.office.company.id,
        userName: state.profile.active.name,
        isSmallViewMode: state.ui.isSmallViewMode,
        blogsEnabled: state.app.items.some((app) => app.enabled && app.id === 2),
        newsEnabled: state.app.items.some((app) => app.enabled && app.id === 5),
        rssEnabled: state.app.items.some((app) => app.enabled && app.id === 11),
        primaryColor: state.instance.get("color") as string,
        userGalleryId: state.profile.active.galleryId,
        forceCompanyFeed: state.instance.get("forceCompanyFeed"),
        publicUnitNewsFeeds: state.instance.get("publicUnitNewsFeeds"),
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(OrganisationUnitStartView));
