import React, { FunctionComponent } from "react";
import { Label } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./KeyPointIndicatorWidget.scss";
import ScaleTextFontSize from "src/ui/components/ScaleTextFontSize/ScaleTextFontSize";

interface IProps {
    primaryValue: string | string[];
    secondaryValue: string;
    difference?: number | string;
    title?: string;
    config?: { [key: string]: any };
    variables: { [key: string]: string };
    isMultiRow?: boolean;
}

type IconNames = "FlickUp" | "FlickDown";

const getDiff = (props: IProps): any => {
    if (typeof props.difference === "undefined") {
        return;
    }

    const primaryValue = props.primaryValue as string;

    const getExtraDiffText = () => {
        if (props.difference === "string" && props.difference.indexOf("%") > -1) {
            return "";
        }

        const isNegative = props.difference.toString().indexOf("-") === 0;
        const val = parseInt(primaryValue);
        const dif = parseInt(props.difference.toString().replace("-", ""));
        const percent = Math.round(((dif / val) * 100));

        if (!Number.isFinite(percent)) {
            return "";
        }

        const diffPercentString = (isNegative ? "-" : "") + percent + "%";

        return " (" + diffPercentString + ")";
    }

    // I think this should be fine
    if (props.difference === "string") {
        if (props.difference.indexOf("%")) {
            return {
                diff: props.difference,
                positive: props.difference.substr(0, 1) !== "-",
            }
        } else if (props.difference.substr(0, 1) === "-") {
            return {
                diff: Math.abs(parseFloat(props.difference)) + getExtraDiffText(),
                positive: false,
            };
        }
    }

    const value = typeof props.primaryValue === "number"
        ? props.primaryValue
        : parseFloat(
            (primaryValue || "").replace(/\D+/gmi, "")
        ) || 0;

    const refValue = typeof props.difference === "number"
        ? props.difference
        : parseFloat(
            (props.difference || "").replace(/[^\d-]+/gmi, "")
        ) || 0;

    const isPercentage = (
        props.config?.isPercentage || (
            props.difference === "string" &&
            props.difference.indexOf("%") > -1
        ));

    return {
        diff: Math.abs(refValue) + getExtraDiffText(),
        positive: refValue > -1,
    };
};

const KeyPointIndicatorWidgetPrimaryValue = (props) => {
    return (
        <Label
            className="number"
            size="h1"
            color="visageGray"
        >
            {props.scaleText && <ScaleTextFontSize text={props.value} />}
            {!props.scaleText && props.value}
        </Label>
    )
}

const KeyPointIndicatorWidgetPrimaryValueTitle = (props) => {
    return (
        <Label className="title" color="mid-grey" weight="medium">{props.title}</Label>
    )
}

const KeyPointIndicatorWidget: FunctionComponent<IProps> = (props) => {
    const usingDiff = typeof props.difference !== "undefined";
    const diffData = usingDiff ? getDiff(props) : undefined;

    const isImprovement = diffData && (diffData.positive
        ? !props.config?.lessIsBetter
        : !!props.config?.lessIsBetter);

    const multiColumn = Array.isArray(props.primaryValue);

    return (
        <div className={["KeyPointIndicatorWidget", ...(multiColumn ? ["multiColumn"] : [])].join(" ")}>
            <div>
                {(!!props.title || props.secondaryValue) && (
                    <KeyPointIndicatorWidgetPrimaryValueTitle title={props.title || props.secondaryValue} />
                )}
                {!multiColumn && (
                    <KeyPointIndicatorWidgetPrimaryValue value={props.primaryValue} scaleText={!props.isMultiRow} />
                )}
                {!!multiColumn && (
                    <div className="KeyPointIndicatorWidget-columns">
                        {
                            (props.primaryValue as string[]).map((value, index) => {
                                return (
                                    <div key={index}>
                                        <KeyPointIndicatorWidgetPrimaryValueTitle
                                            title={!!props.variables.columns && index < props.variables.columns.length ?
                                                props.variables.columns[index] :
                                                undefined} />
                                        <KeyPointIndicatorWidgetPrimaryValue value={value} />
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            </div>
            {usingDiff && (
                <Label
                    className={
                        "text difference " + (
                            isImprovement ? "green" : "red"
                        )
                    }
                    size="body-2"
                >
                    <div className="iconWrapper">
                        <Visage2Icon icon="arrow-up-3" size="small" color="red" />
                    </div>
                    <Label
                        as="span"
                        size="body-1"
                    >
                        {diffData.diff}
                        &nbsp;
                        {(diffData.positive
                            ? props.config?.posDiffText
                            : props.config?.negDiffText) || ""}
                    </Label>
                </Label>
            )}
        </div>
    );
}

export default KeyPointIndicatorWidget;
