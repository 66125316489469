import classNames from "classnames";
import { Icon } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import "./ErrorFallback.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    code?: string;
    horizontal?: boolean;
}

type Props = IProps & FallbackProps;

const ErrorFallback: FunctionComponent<Props> = (props) => {
    if (props.error) {
        // @ts-ignore'
        console.log(props.error);
    }

    return (
        <div role="alert" className={classNames(
            "ErrorFallback",
            props.horizontal ? "horizontal" : "",
        )}>
            <div className="icon">
                <Visage2Icon icon="triangle" />
            </div>
            <Label
                className="ErrorBoundaryContent"
                size="body-2"
            >
                <div
                    className="ErrorBoundaryText"
                    dangerouslySetInnerHTML={{
                        __html: !!props.code
                            ? localize("COMPONENT_CRASH_TEXT_WITH_CODE") + " " + props.code
                            : localize("COMPONENT_CRASH_TEXT")
                    }}
                />
            </Label>
        </div>
    );
};

export default ErrorFallback;