import { ComboBox, IComboBoxOption } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { FormControl } from "src/ui/components/Forms";

interface IProps {
    selectedId?: string;
    onChange(id: string): void;
}

const KeyPerformanceIndicatorSelector: FunctionComponent<IProps> = ({ selectedId, onChange }) => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<Spintr.IKeyPointIndicatorResponseModel[]>([]);

    const onComboBoxChange = useCallback(
        (_: any, option: IComboBoxOption) => {
            onChange(option.key.toString());
        },
        [onChange]
    );

    useEffect(() => {
        api
            .get<Spintr.IKeyPointIndicatorEnvelope>(
                "/api/v1/KeyPointIndicators", {
                    params: {
                        take: 100,
                    },
                },
            )
            .then(
                (response) => {
                    setLoading(false);
                    setItems(() => response.data.items);
                },
                (reason) => {
                    console.log(reason);
                    setLoading(false);
                }
            )
    }, [setItems, setLoading]);

    if (loading) {
        return <Loader />;
    }

    return (
        <FormControl>
            <ComboBox
                allowFreeform={false}
                autoComplete={"on"}
                label={localize("KEY_POINT_INDICATORS")}
                onChange={onComboBoxChange}
                options={
                    items.map((kpi) => ({
                        key: kpi.id,
                        text: kpi.displayName,
                    }))
                }
                selectedKey={selectedId}
            />
        </FormControl>
    )
};

export default KeyPerformanceIndicatorSelector;
