import api from 'src/spintr/SpintrApi';
import {
    FETCH_GROUP,
    SET_IS_FOLLOWING,
    JOIN_GROUP,
    LEAVE_GROUP,
    INCREMENT_UNREAD,
    CLEAR_UNREAD
} from './group-action-types';

export const findGroupById = (id) => ({
    meta: { id },
    payload: api.get(`/api/groups/${id}`),
    type: FETCH_GROUP
})

export const setIsFollowing = (id, isFollowing) => ({
    meta: {
        id,
        isFollowing
    },
    type: SET_IS_FOLLOWING
})

export const joinGroup = (id) => ({
    meta: {
        id
    },
    type: JOIN_GROUP
})

export const leaveGroup = (id) => ({
    meta: {
        id
    },
    type: LEAVE_GROUP
})

export const incrementUnread = () => ({
    type: INCREMENT_UNREAD
})

export const clearUnread = () => ({
    type: CLEAR_UNREAD
})
