import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import Axios from "axios";
import React, { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Label, Loader, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { setCurrentFolderId } from "../folder-actions";

type Props = {
    id: string;
};

type State = {
    isLoading: boolean;
    file: Spintr.GoogleDriveItem | null;
};

function renderGoogleDriveImage(file: Spintr.GoogleDriveItem): ReactElement | null {
    if (!file.thumbnailLink) {
        return null;
    }

    return (
        <div className="preview">
            <img src={file.thumbnailLink} alt={file.name} />
        </div>
    );
}

function renderGoogleCommandBar(items: ICommandBarItemProps[]): ReactElement | null {
    if (items.length === 0) {
        return null;
    }

    return (
        <CommandBar
            items={items}
        />
    );
}

function renderGoogleInfoPart(
    title: string,
    content?: string | number | undefined,
    renderer?: () => ReactElement | null
): ReactElement | null {
    if (!content && !renderer) {
        return null;
    }

    const contentElement = content
        ? <Label color="dark-grey" size="body-2">{content}</Label>
        : (
            <div className="FileDetailsView-InfoPartContent-inner">
                {renderer()}
            </div>
        );

    return (
        <div className="FileDetailsView-InfoPart">
            <Label size="body-2" color="mid-grey">{title}</Label>
            <div className="FileDetailsView-InfoPartContent">
                {contentElement}
            </div>
        </div>
    )
}

function renderGoogleDataBox(file: Spintr.GoogleDriveItem): ReactElement | null {
    if (!file) {
        return null;
    }

    const sizeInKilobytes = file.size ? Math.round((file.size / 1024) * 10) / 10 : undefined;

    return (
        <div className="data-box">
            {renderGoogleInfoPart(localize("Titel"), file.name)}
            {/* File Type */}
            {sizeInKilobytes ? renderGoogleInfoPart(localize("Storlek"), sizeInKilobytes + " KB") : null}
            {/* Owner */}
            {/* Last Modified By */}
        </div>
    );
}

function renderGoogleDriveItem(file: Spintr.GoogleDriveItem, options: ICommandBarItemProps[]): ReactElement | null {
    if (!file) {
        return null;
    }

    return (
        <Fragment>
            {renderGoogleDriveImage(file)}
            {renderGoogleCommandBar(options)}
            {renderGoogleDataBox(file)}
        </Fragment>
    )
}

function FileDetailsGoogleView(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [state, setState] = useState<State>({
        isLoading: true,
        file: null,
    });

    const { id } = props;
    const { file, isLoading } = state;

    useEffect(() => {
        const cancelTokenSource = Axios.CancelToken.source();

        const promise = api.get<Spintr.GoogleDriveItem>(`/api/v1/files/google/${id}`, {
            cancelToken: cancelTokenSource.token,
            params: {
                source: SpintrTypes.FolderRootSource.GoogleDrive,
            },
        });

        promise
            .then((response) => {
                const file = response.data;

                setState((prevState) => ({
                    ...prevState,
                    file,
                    isLoading: false,
                }));

                if (file.parentIds.length === 0) {
                    return;
                }

                var currentFolderId = `${file.driveId}|${file.parentIds[0]}`;

                dispatch(setCurrentFolderId(currentFolderId));
            })
            .catch(() => setState((prevState) => ({
                ...prevState,
                isLoading: false,
            })));

        return () => cancelTokenSource.cancel();
    }, [id, dispatch]);

    const onDownloadClick = useCallback(() => {
        if (!file || !file.webContentLink) {
            return;
        }

        window.open(file.webContentLink, "_blank");
    }, [file]);

    const onOpenClick = useCallback(() => {
        if (!file || !file.webViewLink) {
            return;
        }

        window.open(file.webViewLink, "_blank");
    }, [file]);

    const commandBarOptions = useMemo(() => {
        if (!file) {
            return [];
        }

        const items: ICommandBarItemProps[] = [];
        if (file.webContentLink) {
            items.push({
                key: "download",
                text: localize("LaddaNed"),
                onClick: onDownloadClick,
                iconProps: { iconName: "Download" },
                onRenderIcon: () => (
                    <Visage2Icon icon="document-upload" />
                ),
            });
        }

        if (file.webViewLink) {
            items.push({
                key: "open",
                text: localize("Oppna"),
                onClick: onOpenClick,
                iconProps: { iconName: "OpenInNewTab" },
                onRenderIcon: () => (
                    <Visage2Icon icon="arrow-right-1" />
                ),
            });
        }

        return items;
    }, [file, onDownloadClick, onOpenClick]);

    const inner = file ? renderGoogleDriveItem(file, commandBarOptions) : (
        <Fragment>
            <Label>{localize("TeknisktFel")}</Label>
            <UnstyledButton>
                <Label className="primaryFGColor">{localize("RELOAD")}</Label>
            </UnstyledButton>
        </Fragment>
    );

    return (
        <div className="file-details">
            {isLoading ? <Loader /> : inner}
        </div>
    );
}

export default FileDetailsGoogleView;
