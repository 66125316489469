import { TextField } from "@fluentui/react";
import React, { ChangeEvent, ReactNode } from "react";
import { localize } from "src/l10n";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

const headerNamePattern: RegExp = /^([a-z0-9\-]+)$/i;

/**
 * Used for configuring the name of a custom header
 */
class CustomHeaderSetup extends AuthenticationDataComponent {
    private labelText: string = localize("CUSTOMER_HEADER_NAME");

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                customHeaderName: ""
            },
            errorMessage: null
        };

        this.onNameChanged = this.onNameChanged.bind(this);
    }

    public getAuthenticationData(): string {
        return JSON.stringify(this.state.data);
    }    
    
    public isValid(): boolean {
        const name = ((this.state.data.customHeaderName as string) || "").trim();

        const isValid: boolean =  headerNamePattern.test(name);
        
        this.setState({ 
            errorMessage: isValid
                ? null
                : localize("INVALID_HEADER_FORMAT")
        });

        return isValid;
    }

    public onNameChanged(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({
            data: {
                customerHeaderName: event.target.value.trim()
            }
        }, () => this.isValid());
    }

    public render(): ReactNode {
        return (
            <TextField
                errorMessage={this.state.errorMessage}
                label={this.labelText}
                name="customHeaderName"
                onChange={this.onNameChanged}
            />
        )
    }
}

export default CustomHeaderSetup;