import { Image } from "@fluentui/react";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { RouteComponentProps, withRouter } from 'react-router';
import { localize } from 'src/l10n';
import { installMarketplaceApp } from "src/marketplace/api";
import { SpintrTypes } from "src/typings";
import { Label, Loader, UnstyledButton } from "src/ui";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";
import { BasicAuthInput } from "../BasicAuth";
import { CustomHeaderInput } from "../CustomHeader";
import { CustomQueryStringInput } from "../CustomQueryString";
import { FortnoxInstaller } from '../FortnoxInstaller';
import OAuth1ClientCredentialsInput from '../OAuth1ClientCredentials/OAuth1ClientCredentialsInput';
import { OAuth2ClientCredentialsInput } from "../OAuth2ClientCredentials";
import "./InstallPopup.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps extends RouteComponentProps {
    app: Spintr.IMarketplaceAppResponse;

    onDismiss(): void;
    onInstalled(appId: string): void;
}

interface IState {
    hasInstalled: boolean;
    isInstalling: boolean;
}

class InstallPopup extends Component<IProps, IState> {
    private componentRef: RefObject<AuthenticationDataComponent>;

    constructor(props: IProps) {
        super(props);

        this.componentRef = createRef();

        this.onNextClick = this.onNextClick.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            hasInstalled: false,
            isInstalling: false,
        }
    }

    public render(): ReactNode {
        const isValid = 
            [1, 101].indexOf(this.props.app.authenticationMethod) !== -1
            || (
                !!this.componentRef.current &&
                this.componentRef.current.isValid()
            );

        return (
            <div className="InstallPopup MarketplaceApp">
                <div className="header">
                    <div className="logo">
                        <Image
                            alt={this.props.app.name}
                            src={this.props.app.iconUrl}
                        />
                    </div>
                    <div className="name">
                        <Label
                            size="h1"
                        >
                            {this.props.app.name}
                        </Label>
                    </div>
                </div>
                <div className="body">
                    <div
                        className="body-wrapper"
                        style={{
                            display: this.state.isInstalling ? "none" : "block",
                        }}
                    >
                        {!this.state.hasInstalled && this.getInputComponent()}
                        {this.state.hasInstalled && (
                            <p>
                                Appen har installerats.
                            </p>
                        )}
                    </div>
                    {this.state.isInstalling && (<Loader />)}
                </div>
                <div className="footer">
                    <UnstyledButton
                        className="button"
                        disabled={!isValid}
                        onClick={this.onNextClick}
                    >
                        <Label
                            className="button-content"
                            size="body-1"
                            weight="medium"
                        >
                            <span className="text">
                                {this.state.hasInstalled
                                    ? localize("MARKETPLACE_GOTO_LIBRARY")
                                    : localize("MARKETPLACE_NEXT_STEP")
                                }
                            </span>
                            <Visage2Icon icon="arrow-right" />
                        </Label>
                    </UnstyledButton>
                </div>
            </div>
        );
    }

    private getInputComponent(): ReactNode {
        switch (this.props.app.authenticationMethod) {
            case SpintrTypes.DataAppAuthenticationMethod.AnonymousAccess:
            default:
                return null;

            case SpintrTypes.DataAppAuthenticationMethod.BasicAuthentication:
                return <BasicAuthInput
                    authenticationData={this.props.app.authenticationData}
                    onChange={this.onChange}
                    ref={this.componentRef as RefObject<BasicAuthInput>}                    
                />;
    
            case SpintrTypes.DataAppAuthenticationMethod.Learnster:
            case SpintrTypes.DataAppAuthenticationMethod.Teamtailor:
            case SpintrTypes.DataAppAuthenticationMethod.CustomHeader:
                return <CustomHeaderInput
                    authenticationData={this.props.app.authenticationData}
                    onChange={this.onChange}
                    ref={this.componentRef as RefObject<CustomHeaderInput>}                    
                />;

            case SpintrTypes.DataAppAuthenticationMethod.CustomQueryString:
                return <CustomQueryStringInput
                    authenticationData={this.props.app.authenticationData}
                    onChange={this.onChange}
                    ref={this.componentRef as RefObject<CustomQueryStringInput>}                    
                />;

            case SpintrTypes.DataAppAuthenticationMethod.OAuth2ClientCredentials:
                return <OAuth2ClientCredentialsInput 
                    authenticationData={this.props.app.authenticationData}
                    onChange={this.onChange}
                    ref={this.componentRef as RefObject<OAuth2ClientCredentialsInput>}                    
                />;

            case SpintrTypes.DataAppAuthenticationMethod.OAuth1ClientCredentials:
                return <OAuth1ClientCredentialsInput 
                    authenticationData={this.props.app.authenticationData}
                    applicationId={this.props.app.id}
                    onChange={this.onChange}
                    ref={this.componentRef as RefObject<OAuth1ClientCredentialsInput>}                    
                />;

            case SpintrTypes.DataAppAuthenticationMethod.Fortnox:
                return <FortnoxInstaller
                    authenticationData={this.props.app.authenticationData}
                    onChange={this.onChange}
                    ref={this.componentRef as RefObject<FortnoxInstaller>}
                />;
        }
    }

    private onChange(): void {
        this.forceUpdate();
    }

    private onNextClick(): void {
        if (this.state.isInstalling) {
            return;
        }

        if (this.state.hasInstalled) {
            this.props.history.push("/marketplace/library");
            return;
        }

        if ([1, 101].indexOf(this.props.app.authenticationMethod) === -1) {
            if (!this.componentRef.current) {
                return;
            }

            if (!this.componentRef.current.isValid()) {
                return;
            }
        }

        const authData: string = (
            !this.componentRef.current
                ? "{}"
                : this.componentRef.current.getAuthenticationData()
        );

        this.setState({ isInstalling: true }, async () => {
            let hasInstalled = false;
            try {
                hasInstalled = await installMarketplaceApp(
                    this.props.app.id, authData
                );
            } catch (err) {
                console.error(err);
            } finally {
                this.setState({
                    hasInstalled,
                    isInstalling: false,
                });
            }
        })
    }
}

const InstallPopupWithRouter = withRouter(InstallPopup);

export default InstallPopupWithRouter;
