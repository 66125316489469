import React, { ReactElement, useEffect, useLayoutEffect } from "react";
import { ProductSuggestionProps as Props } from "./ProductSuggestion.types";

const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

function ProductSuggestion(props: Props): ReactElement {
    const { children, state } = props;

    useIsomorphicLayoutEffect(() => {
        state.register(props.offsetKey);

        if (state.setEditorState && state.getEditorState) {
            // force re-render
            state.setEditorState(state.getEditorState());
        }

        return () => state.unregister(props.offsetKey);
    }, []);

    return (
        <span className="ProductSuggestion">
            {children}
        </span>
    );
}

export default ProductSuggestion;
