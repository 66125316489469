import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React, { memo } from "react";
import { localize } from "src/l10n";
import PopupHeader from "../PopupHeader";

interface Props {
    message: string;
    show: boolean;
    onDismiss: any;
}

export default memo(function InfoDialog(props: Props) {
    const { message, show, onDismiss } = props;

    return (
        <Dialog
            hidden={!show}
            containerClassName="dialogWithPopupHeader"
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: localize("Information"),
                closeButtonAriaLabel: localize("Stang"),
                subText: message,
            }}
            modalProps={{
                isBlocking: true,
                allowTouchBodyScroll: true
            }}
        >
            <PopupHeader
                text={localize("Information")}
                subText={message}
                onClose={onDismiss} />
            <DialogFooter>
                <PrimaryButton
                    // theme={useContext(ThemeContext)}
                    onClick={() => {
                        onDismiss();
                    }}
                    text={localize("Ok")}
                />
            </DialogFooter>
        </Dialog>
    );
});
