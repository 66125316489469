import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { getUserPart } from "../utils";

interface IProps {
    notification: Spintr.INotification;
}

const SystemStatusNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.ISystemStatusNotification;

    if (!notification) {
        return;
    }

    const userPart = getUserPart(notification);
    const typeText = localize("Ubertype56_0_1");
    const isNew = props.notification.type == 57 || props.notification.type == 58 || props.notification.type == 59 || props.notification.type == 64;

    if (isNew) {
        const text =
            props.notification.type == 57
                ? localize("SYSTEM_STATUS_NOTIFICATION_CREATED_ONGOING")
                : props.notification.type == 58
                ? localize("SYSTEM_STATUS_NOTIFICATION_CREATED_DONE")
                : props.notification.type == 59
                ? localize("SYSTEM_STATUS_NOTIFICATION_CREATED_PLANNED")
                : props.notification.type == 64
                ? localize("SYSTEM_STATUS_NOTIFICATION_CREATED_INFORMATION")
                : "";

        return (
            <Fragment>
                <Link to={notification.url}>{notification.resourceName}</Link> {text}
            </Fragment>
        );
    }

    let text =
        props.notification.type == 60
            ? localize("SYSTEM_STATUS_NOTIFICATION_UPDATED_ONGOING")
            : props.notification.type == 61
            ? localize("SYSTEM_STATUS_NOTIFICATION_UPDATED_DONE")
            : props.notification.type == 62
            ? localize("SYSTEM_STATUS_NOTIFICATION_UPDATED_PLANNED")
            : "";

    text = text.replace("{{X}}", notification.resourceName);

    return <Fragment>{text}</Fragment>;
};

export default SystemStatusNotification;
