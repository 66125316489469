import * as actionTypes from "./action-types";

const initialState = {
    items: [],
    isLoading: true
};

const teamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TEAMS_FULFILLED:
            return {
                ...state,
                items: [...action.payload.data],
                isLoading: false
            };
        case actionTypes.FETCH_TEAMS_REJECTED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.INCREMENT_TEAM_UNREAD:
            return {
                ...state,
                items: state.items.map(t => {
                    return {
                        ...t,
                        unreadCount: t.id === action.meta.teamId ?
                            (t.unreadCount + 1) :
                            t.unreadCOunt
                    }
                })
            }
        case actionTypes.CLEAR_TEAM_UNREAD:
            return {
                ...state,
                items: state.items.map(t => {
                    return {
                        ...t,
                        unreadCount: t.id === action.meta.teamId ?
                            0 :
                            t.unreadCOunt
                    }
                })
            }
        default:
            return state;
    }
};

export default teamsReducer;
