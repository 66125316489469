import { Checkbox, DefaultButton, Modal, PrimaryButton, Stack } from "@fluentui/react";
import React, {
    useState
} from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, Loader } from "src/ui";
import PopupHeader from "src/ui/components/PopupHeader";

interface IState {
    deactivateUsers: boolean;
    isLoading: boolean;
}

interface IProps {
    itemIdToDelete: number;
    onDismiss: any;
    onSuccess: any;
}

const initialState: IState = {
    deactivateUsers: false,
    isLoading: false
}

const DeactivateDepartmentUsersModal = (props: IProps) => {
    const [state, setState] = useState(initialState);

    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            isOpen={true}
            onDismiss={() => {
                props.onDismiss();
            }}
        >
            <PopupHeader
                text={localize("DEPARTMENT_CONTAINS_USERS")}
                onClose={() => {
                    props.onDismiss();
                }} />
            <div>
                <Label className="spintr-modal-margin-bottom">{localize("DEPARTMENT_CONTAINS_USERS_INFO")}</Label>
                <Checkbox
                    className="spintr-modal-margin-bottom"
                    disabled={state.isLoading}
                    label={localize("REMOVE_USERS_IN_DEPARTMENT")}
                    checked={state.deactivateUsers}
                    onChange={(
                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                        checked?: boolean
                    ) => {
                        setState((s) => ({ ...s, deactivateUsers: checked }));
                    }}
                />
            </div>
            {state.isLoading && (
                <Loader />
            )}
            {!state.isLoading && (
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                    <DefaultButton
                        text={localize("Avbryt")}
                        onClick={() => {
                            props.onDismiss();
                        }}
                    />
                    <PrimaryButton
                        data-cy="dialog-confirm"
                        onClick={() => {
                            setState((s) => ({
                                ...s,
                                isLoading: true
                            }));

                            api.delete(`/api/v1/departments/${props.itemIdToDelete}?deactivateUsers=true`).then(() => {
                                setState((s) => ({
                                    ...s,
                                    deactivateUsers: false,
                                    isLoading: false
                                }));

                                props.onSuccess();
                            }).catch(() => {
                                setState((s) => ({
                                    ...s,
                                    isLoading: false
                                }));
                            });
                        }}
                        disabled={!state.deactivateUsers}
                        text={localize("TaBortAvdelning")}
                    />
                </Stack>
            )}
        </Modal>
    )
};

export default DeactivateDepartmentUsersModal;
