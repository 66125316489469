import React, { Component, ReactNode } from "react";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import { localize } from "src/l10n";
import { Label, Slider, UnstyledButton } from "src/ui/";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import NewsSpotlightItem from "./NewsSpotlightItem";
import { fetchSpotlight } from "./actions";

interface IStateProps {
    isLoading: boolean;
    items: Spintr.SpotlightNewsArticle[];
    importantArticles: Spintr.IInformationFeedItem[];
}

interface IOwnProps {
    inEditMode?: boolean;
}

type Props = DispatchProp & IStateProps & IOwnProps;

interface IState {
    index: number;
    isPlaying: boolean;
}

class NewsSpotlight extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            index: 1,
            isPlaying: true
        }
    }

    private sliderRef = React.createRef<Slider>();

    public componentDidMount(): void {
        // @ts-ignore
        this.props.dispatch(fetchSpotlight());
    }

    public left = () => {
        this.sliderRef?.current.onSwipedLeft();
    };

    public right = () => {
        this.sliderRef?.current.onSwipedRight();
    };

    public togglePlay = () => {
        if (this.state.isPlaying) {
            this.sliderRef?.current.onPausePressed();
        } else {
            this.sliderRef?.current.onPlayPressed();
        }
    }

    public getItems = () => {
        return [
            ...this.props.importantArticles.map((x: Spintr.IInformationFeedItem) => {
                return {
                    ...x,
                    attachedBackground: x.imageUrl ? 2 : 1
                }
            }),
            ...this.props.items,
        ]
    }

    public render(): ReactNode {
        const items = this.getItems();

        if (items.length === 0) {
            if (!this.props.inEditMode) {
                return null;
            }

            return this.renderPlaceholder();
        }

        return (
            <div id="news-spotlight" className="visage-box-shadow">
                <Slider
                    onUpdate={(index, isPlaying) => {
                        this.setState({
                            index,
                            isPlaying
                        });
                    }}
                    ref={this.sliderRef}
                    hideIndicator>
                    {items.map(this.renderNewsArticle.bind(this))}
                </Slider>
                {items.length > 1 && (
                    <>
                        <Label className="index" color="white" size="body-2">{(this.state.index + 1)}/{items.length}</Label>
                        <UnstyledButton onClick={this.togglePlay} className="arrow-circle play">
                            <Visage2Icon icon={this.state.isPlaying ? "pause" : "play"} color="white" type="bold" size="extra-small" />
                        </UnstyledButton>
                        <UnstyledButton onClick={this.left} className="arrow-circle left">
                            <Visage2Icon icon="arrow-left" color="white" size="small" />
                        </UnstyledButton>
                        <UnstyledButton onClick={this.right} className="arrow-circle right">
                            <Visage2Icon icon="arrow-right-1" color="white" size="small" />
                        </UnstyledButton>
                    </>
                )}
            </div>
        );
    }

    public renderNewsArticle(item: Spintr.SpotlightNewsArticle): ReactNode {
        const key = `NewsSpotlight${item.id}`;

        return <NewsSpotlightItem article={item} key={key} />;
    }

    protected renderPlaceholder(): ReactNode {
        return (
            <div className="news-spotlight-placeholder">
                <Label
                    className="placeholder-name italic"
                    size="body-2"
                    weight="medium"
                >
                    {localize("FEATURES_NEWS_PLACEHOLDER")}
                </Label>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state: Spintr.AppState) => ({
        isLoading: state.spotlight.isLoading,
        items: state.spotlight.items,
        importantArticles: state.informationFeed.importantArticles.filter(x => x.priorityLevel === 5),
    });

export default connect(mapStateToProps)(NewsSpotlight);
