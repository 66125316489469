import { Stack } from "@fluentui/react";
import moment from "moment";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Label, SpintrUser, UserHovercard } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SocialSystemStatus.scss";
import { Link } from "react-router-dom";
import { cirlceTiny } from "src/ui/helpers/style";
import { fromNow } from "src/utils";

interface IProps {
    post: Spintr.ISocialSystemStatus;
}

interface IState {}

class SocialSystemStatus extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <div className="SocialSystemStatus">
                <div>
                    <Visage2Icon size="small" color="primaryContent" icon="clock" />
                    <Label size="body-2" color="primaryContent">
                        {!!this.props.post.endDate ? moment(this.props.post.startDate).format("lll") : fromNow(this.props.post.startDate)}
                        {this.props.post.endDate && this.props.post.endDate !== this.props.post.startDate && (
                            <>
                                &nbsp;{localize("Till_small")} {moment(this.props.post.endDate).format("lll")}
                            </>
                        )}
                    </Label>
                </div>
                <div>
                    <SpintrUser
                        imageUrl={this.props.post.ImageUrl}
                        size={cirlceTiny}
                        name={this.props.post.UserName}
                        personalName
                        hideText
                    />
                    <Label size="body-2" color="primaryContent">
                        {localize("SkapadAv") + ": " + this.props.post.UserName}
                    </Label>
                </div>
                {this.props.post.responsibleUser && (
                    <div>
                        <SpintrUser
                            imageUrl={this.props.post.responsibleUser.image}
                            size={cirlceTiny}
                            name={this.props.post.responsibleUser.name}
                            personalName
                            hideText
                        />
                        <Label size="body-2" color="primaryContent">
                            {localize("RESPONSIBLE_PERSON") + ": " + this.props.post.responsibleUser.name}
                        </Label>
                    </div>
                )}
            </div>
        );
    }
}

export default SocialSystemStatus;
