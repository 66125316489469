import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hitEnterOrSpace } from "src/files/utils";
import { localize } from "src/l10n";
import { FileSelector } from "src/spintr/components";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Icon, Label } from "src/ui";
import { Style } from "src/ui/helpers";
import CoverImageButton from "./Buttons/CoverImageButton/CoverImageButton";
import UnsplashAttribution from "./UnsplashAttribution";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    imageUrl?: string;
    editMode: boolean;
    onChange?: any;
    height?: number;
    placeholderString?: string;
    autoHeight?: boolean;
    imageMaxWidth?: number;
    displayFileSelector?: boolean;
    cropImage?: boolean;
    cropAspect?: number;
    isMediaEnabled: boolean;
    customRequestUrl?: string;
    onFileSelectorClose?: any;
    renderSizeString?: any;
    appMode: boolean;
    square?: boolean;
    blur?: boolean;
    cropHeaderImagesAfterUpload?: boolean;
    cropShape?: "rect" | "round";
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
}

interface IState {
    imageUrl: string;
    height: string;
    displayFileSelector: boolean;
    displayImageCropper: boolean;
    imageDescription: string;
    base64?: string;
    externalAuthorName?: string;
    externalAuthorUrl?: string;
    externalPhotoUrl?: string;
}

class ContentImageViewerAndSelector extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            imageUrl: props.imageUrl,
            height: (props.height ? props.height : 170) + "px",
            displayFileSelector: props.displayFileSelector,
            displayImageCropper: false,
            imageDescription: "",
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.height !== this.props.height) {
            this.setState({ height: this.props.height + "px" });
        }
    };

    componentDidMount = () => {
        if (this.props.editMode || !this.props.imageUrl?.startsWith("https://images.unsplash.com")) {
            return;
        }

        const imagePath = new URL(this.props.imageUrl).pathname;

        api.get("/api/v1/images/metadata", {
            params: {
                imagePath,
            },
        }).then((response) => {
            this.setState({
                externalAuthorName: response.data.externalAuthorName,
                externalAuthorUrl: response.data.externalAuthorUrl,
                externalPhotoUrl: response.data.externalPhotoUrl,
            });
        });
    }

    inputRef = React.createRef<HTMLInputElement>();

    onImageSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];

        let formData = new FormData();

        formData.set("type", "1");
        formData.set("ticket", new Date().getTime().toString());
        formData.set("thumbnailSize", "natural");

        formData.append("file", file);

        const config = {
            headers: {
                "content-type": "multipart/form-data; boundary=----WebKitFormBoundary5ieermsRmERasb8V",
            },
        };

        let dat = this;

        api.post("/api/v1/uploads", formData, config).then(function (response) {
            dat.setState(
                {
                    //@ts-ignore
                    imageUrl: response.data.Result.thumbnailUrl,
                },
                () => {
                    if (dat.props.onChange) {
                        dat.props.onChange({
                            //@ts-ignore
                            thumbnailUrl: response.data.Result.thumbnailUrl,
                            //@ts-ignore
                            ticket: response.data.Result.ticket,

                            file,
                        });
                    }
                }
            );
        });
    };

    deleteImage = () => {
        this.setState(
            {
                imageUrl: "",
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange({
                        //@ts-ignore
                        thumbnailUrl: "",
                        //@ts-ignore
                        ticket: "",
                    });
                }
            }
        );
    };

    cropImage = () => {
        this.setState({
            displayFileSelector: true,
            displayImageCropper: true
        });
    }

    renderAddButton() {
        return (
            <CoverImageButton
                icon={"camera"}
                placeholder={this.props.placeholderString}
                onClick={this.displayFileSelector}
            />
        )
    }

    renderImageAction(text: string, renderIcon: any, onClick: any) {
        return (
            <div
                className="image-action"
                style={{
                    display: "inline-block",
                    position: "relative",
                    transform: "translateY(-50%)",
                    msTransform: "translateY(-50%)",
                    top: "50%",
                    padding: Style.getSpacingStr(5),
                    cursor: "pointer",
                }}
                onClick={onClick}
            >
                <div
                    style={{
                        display: "inline-block",
                    }}
                >
                    {renderIcon()}
                </div>
                <div
                    style={{
                        textAlign: "center",
                        userSelect: "none",
                    }}
                >
                    <Label color="white" size="h6">
                        {text}
                    </Label>
                </div>
            </div>
        )
    }

    renderImageActions() {
        return (
            <div
                className="pageHeaderImageDeleteOverlay"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                    display: "none",
                }}
            >
                {this.renderImageAction(localize("TaBortBild"), () => {
                    return (
                        <Visage2Icon color="white" icon={"trash"} />
                    )
                }, this.deleteImage)}
                {this.props.cropHeaderImagesAfterUpload &&
                    !!this.props.blur &&
                    !!this.state.base64 &&
                    this.renderImageAction(localize("BeskarBild"), () => {
                        return (
                            <Visage2Icon color="white" icon="box-1" />
                        )
                    }, this.cropImage)}
            </div>
        );
    }

    displayFileSelector = () => {
        this.setState({
            displayFileSelector: true,
        });
    };

    aspectRatioToPadding(aspectRatio: number) {
        const height = 100;
        const width = height * aspectRatio;
        const paddingNumber = ((height / Math.round(width)) * 100).toFixed(2);

        return paddingNumber + "% 0px 0px 0px";
    }

    renderFileSelector() {
        return (
            <FileSelector
                renderSizeString={this.props.renderSizeString}
                cropImage={this.props.cropImage && !this.props.cropHeaderImagesAfterUpload}
                cropAspect={this.props.cropAspect}
                cropShape={this.props.cropShape}
                cropHeight={this.props.height}
                enableMediaflow={this.props.enableMediaflow}
                enableUnsplash={this.props.enableUnsplash}
                onClose={() => {
                    this.setState({
                        displayFileSelector: false,
                        displayImageCropper: false
                    }, () => {
                        if (this.props.onFileSelectorClose) {
                            this.props.onFileSelectorClose();
                        }
                    });
                }}
                showImageCropper={this.state.displayImageCropper}
                imageBase64={this.state.base64}
                syncBase64={(data) => {
                    this.setState({
                        base64: data
                    });
                }}
                onSelect={(data) => {
                    let thumbnailUrl = data[0].thumbnailUrl ? data[0].thumbnailUrl : data[0].url;

                    this.setState(
                        {
                            displayFileSelector: false,
                            displayImageCropper: false,
                            imageUrl: thumbnailUrl,
                            imageDescription: data[0].description,
                        },
                        () => {
                            if (this.props.onChange) {
                                this.props.onChange({
                                    base64: data[0]?.base64?.split(",")[1],
                                    thumbnailUrl: thumbnailUrl,
                                    ticket: data[0].ticket,
                                    imageDescription: data[0].description,
                                    externalAuthorName: data[0].externalAuthorName,
                                    externalAuthorUrl: data[0].externalAuthorUrl,
                                    externalPhotoUrl: data[0].externalPhotoUrl,
                                    externalMediaflowId: data[0].externalMediaflowId,
                                    isUnsplash: data[0].isUnsplash,
                                });
                            }
                        }
                    );
                }}
                allowMultipleFiles={false}
                fileTypesString={"image/png, image/jpeg"}
                fileTypes={["png", "jpeg", "jpg"]}
                sourcesToDisplay={[0, ...(this.props.isMediaEnabled ? [7] : [])]}
                fileUploadType={1}
                customRequestUrl={this.props.customRequestUrl}
            />
        )
    }

    render() {
        const hasImage = !(!this.state.imageUrl || this.state.imageUrl.length === 0);

        if (!this.props.editMode && !hasImage) {
            return null;
        }

        if (this.props.blur && !this.props.editMode) {
            return (
                <div className="pageHeaderImageWrapperWrapper">
                    <div
                        className="pageHeaderImageWrapper with-blur"
                        style={{
                            padding: this.aspectRatioToPadding(this.props.cropAspect),
                        }}
                    >
                        <div>
                            <div
                                className="image-blur"
                                style={{
                                    backgroundImage: `url(${this.state.imageUrl})`,
                                }}
                            />
                            <div
                                className="image-blur-front"
                                style={{
                                    backgroundImage: `url(${this.state.imageUrl})`,
                                    backgroundSize: "contain",
                                }}
                            />
                        </div>
                    </div>
                    {this.state.externalAuthorName && (
                        <UnsplashAttribution
                            authorName={this.state.externalAuthorName}
                            authorUrl={this.state.externalAuthorUrl}
                        />
                    )}
                </div>
            );
        }

        if (this.props.cropHeaderImagesAfterUpload && this.props.blur && this.props.editMode) {
            return (
                <div className="pageHeaderImageWrapper with-blur edit-mode" style={{
                    padding: hasImage ? this.aspectRatioToPadding(this.props.cropAspect) : undefined,
                    backgroundColor: hasImage ? "transparent" : Style.getHexFromSpintrColor("visageMidBlueBackground")
                }}>
                    {hasImage && (
                        <div className="image-blur" style={{
                            backgroundImage: `url(${this.state.imageUrl})`
                        }} />
                    )}
                    {hasImage && (
                        <div className="image-blur-front" style={{
                            backgroundImage: `url(${this.state.imageUrl})`,
                            backgroundSize: "contain"
                        }} />
                    )}
                    {!hasImage && this.renderAddButton()}
                    {!!hasImage && this.renderImageActions()}
                    {this.state.displayFileSelector && this.renderFileSelector()}
                </div>
            )
        }

        return (
            <div
                className={classNames("pageHeaderImageWrapper", {
                    ["appMode"]: this.props.appMode
                })}
                tabIndex={0}
                onKeyUp={(e) => {
                    hitEnterOrSpace(e, (hasImage ? this.deleteImage : this.displayFileSelector));
                }}
                style={{
                    maxHeight: hasImage && !this.props.autoHeight ? this.state.height : "none",
                    position: "relative",
                    display: this.props.autoHeight && hasImage ? "inline-block" : "flex",
                    alignItems: "center",
                    backgroundColor: hasImage ? Style.getHexFromSpintrColor("white") : Style.getHexFromSpintrColor("visageMidBlueBackground"),
                    ...(!this.props.square ? { borderRadius: 12 } : {}),
                    overflow: "hidden"
                }}
            >
                {hasImage && this.props.autoHeight && (
                    <div
                        className="pageHeaderImage"
                        style={{
                            maxWidth: this.props.imageMaxWidth ? this.props.imageMaxWidth + "px" : "none",
                            width: "100%",
                            ...(!this.props.square ? { borderRadius: 12 } : {}),
                        }}
                    >
                        <img
                            role="presentation"
                            alt={this.state.imageDescription}
                            style={{
                                width: "100%",
                                ...(!this.props.square ? { borderRadius: 12 } : {}),
                            }}
                            src={this.state.imageUrl}
                        />
                    </div>
                )}
                {hasImage && !this.props.autoHeight && (
                    <div
                        className="pageHeaderImage"
                        style={{
                            ...(hasImage ? { maxHeight: this.state.height } : { height: this.state.height }),
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            ...(!this.props.square ? { borderRadius: 12 } : {}),
                        }}
                    >
                        <img
                            role="presentation"
                            alt={this.state.imageDescription}
                            style={{
                                width: "100%",
                                ...(!this.props.square ? { borderRadius: 12 } : {}),
                            }}
                            src={this.state.imageUrl}
                        />
                    </div>
                )}
                {this.props.editMode && !hasImage && this.renderAddButton()}
                {this.props.editMode && hasImage && this.renderImageActions()}
                {this.state.displayFileSelector && this.renderFileSelector()}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    isMediaEnabled: state.app.items.some((a) => a.id === SpintrTypes.SpintrApp.Media && a.enabled),
    appMode: state.ui.appMode,
    cropHeaderImagesAfterUpload: state.instance.get("cropHeaderImagesAfterUpload")
});

export default connect(mapStateToProps)(ContentImageViewerAndSelector);
