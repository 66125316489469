import { Label as FabricLabel, TooltipHost } from "@fluentui/react";
import classnames from "classnames";
import no from "date-fns/locale/da";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import fi from "date-fns/locale/fi";
import sv from "date-fns/locale/sv";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect, MapStateToProps } from "react-redux";
import localize, { getLocalizationTag } from "src/l10n/localize";
import { Style } from "src/ui/helpers";
import { uniqueId } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

export type PopperPlacement
    = 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';

type OwnProps = {
    onChangeHandler: any;
    value: any;
    hideTimeSelect?: boolean;
    label?: string;
    minDate?: Date;
    minTime?: Date;
    maxTime?: Date;
    isClearable?: boolean;
    showTimeSelectOnly?: boolean;
    ariaLabel?: string;
    ariaRequired?: boolean;
    disabled?: boolean;
    customInput?: any;
    withPortal?: boolean;
    infoTooltip?: string;
    popperPlacement?: PopperPlacement | undefined;

    
    /**
     * @deprecated
     */
    firstDayOfWeek?: any;
    /**
     * @deprecated
     */
    strings?: any;
    /**
     * @deprecated
     */
    formatDate?: any;
    /**
     * @deprecated
     */
    placeholder?: any;
    /**
     * @deprecated
     */
    maxDate?: any;
    /**
     * @deprecated
     */
    isRequired?: any;
};

type StateProps = {
    isSmallViewMode: boolean;
}

type Props = OwnProps & StateProps;

interface State { }

class CustomInput extends Component<{ onClick: any; value: any; fieldId: string; ariaLabel?: string; ariaRequired?: boolean; disabled?: boolean; }> {
    render = () => {
        const { props } = this;

        return (
            <div className="CustomInputWrapper SpintrDatePicker" style={{ color: props.disabled && Style.getHexFromSpintrColor("disabled") }}>
                <i onClick={props.onClick} aria-hidden="true" className="fa fa-calendar">
                    <Visage2Icon color={props.disabled ? "disabled" : "dark-grey"} icon="calendar-1" />
                </i>
                <input
                    style={{ borderColor: props.disabled && Style.getHexFromSpintrColor("disabled"), color: props.disabled && Style.getHexFromSpintrColor("disabled") }}
                    aria-label={props.ariaLabel || localize("ValjDatum")}
                    aria-required={props.ariaRequired}
                    id={props.fieldId}
                    onClick={props.onClick}
                    onChange={props.onClick}
                    className="dateInput"
                    value={props.value}
                    type="text"
                />
            </div>
        );
    };
}

class SpintrDatePicker extends Component<Props, State> {
    private fieldId: string;

    constructor(props) {
        super(props);
        this.fieldId = uniqueId();
        this.state = {};
    }

    setLocale = () => {
        let tag = getLocalizationTag();

        switch (tag) {
            case "sv":
                return sv;
            case "en":
                return en;
            case "no":
                return no;
            case "fi":
                return fi;
            case "de":
                return de;
        }
    };

    render = () => {
        const { showTimeSelectOnly } = this.props;

        return (
            <div
                className={classnames("SpintrDatePicker", {
                    ["time-only"]: showTimeSelectOnly,
                })}
            >
                {this.props.label && (
                    <div className="label">
                        <FabricLabel htmlFor={this.fieldId}>{this.props.label}</FabricLabel>
                        {this.props.infoTooltip && (
                            <TooltipHost
                                className="marketplace-tooltip"
                                content={this.props.infoTooltip}
                                style={{
                                    maxWidth: 300
                                }}
                            >
                                <Visage2Icon icon="info-circle" size="small" />
                            </TooltipHost>
                        )}
                    </div>
                )}

                <DatePicker
                    disabled={this.props.disabled}
                    isClearable={this.props.isClearable && this.props.value}
                    customInput={this.props.customInput ?
                        this.props.customInput : 
                        <CustomInput
                            ariaLabel={this.props.ariaLabel}
                            ariaRequired={this.props.ariaRequired}
                            value={this.props.value}
                            onClick={this.props.onChangeHandler}
                            fieldId={this.fieldId}
                        />
                    }
                    selected={this.props.value}
                    onChange={(date) => {
                        this.props.onChangeHandler(date);
                    }}
                    showTimeSelect={this.props.isSmallViewMode ? false : this.props.hideTimeSelect ? false : true}
                    showTimeSelectOnly={this.props.showTimeSelectOnly}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeInputLabel={localize("Tid")}
                    showTimeInput={showTimeSelectOnly ? false : this.props.hideTimeSelect ? false : true}
                    dateFormat={
                        showTimeSelectOnly ? "HH:mm" : this.props.hideTimeSelect ? "MMMM d, yyyy" : "MMMM d, yyyy HH:mm"
                    }
                    locale={this.setLocale()}
                    showWeekNumbers
                    timeCaption={localize("Tid")}
                    minDate={this.props.minDate}
                    minTime={this.props.minTime}
                    maxTime={this.props.maxTime}
                    withPortal={this.props.withPortal}
                    popperPlacement={this.props.popperPlacement}
                />
            </div>
        );
    };
}


const mapStateToProps: MapStateToProps<StateProps, OwnProps, Spintr.AppState> = (state) => ({
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(SpintrDatePicker);

