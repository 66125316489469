import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, MapStateToProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IQueryMarketplaceAppsParams, queryMarketplaceApps } from "src/marketplace/api";
import { MarketplaceList } from "src/marketplace/components";
import { Label, LocalizedString } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import {
    CatalogViewProps as Props,
    ICatalogViewComponentProps as OwnProps,
    ICatalogViewStateProps as StateProps,
    ICatalogViewState as State,
} from "./MarketplaceCatalogView.types";

import "./MarketplaceCatalogView.scss";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";

const MarketplaceCatalogView: React.FC<Props> = (props) => {
    const [state, setState] = useState<State>({
        apps: [],
        isLoadingApps: false,
        searchPhrase: "",
    });

    const onSearchChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            setState((s) => ({ ...s, searchPhrase: value || "" }));
        },
        [setState],
    );

    useEffect(() => {
        setState((s) => ({ ...s, isLoadingApps: true }));
        
        const queryParams: IQueryMarketplaceAppsParams = {
            take: 100,
        };

        let canceled = false;
        queryMarketplaceApps(queryParams).then(
            (envelope) => {
                if (canceled) {
                    return;
                }

                setState((s) => ({ ...s, apps: envelope.items, isLoadingApps: false }));
            },
            (_) => {
                if (canceled) {
                    return;
                }
                
                setState((s) => ({ ...s, isLoadingApps: false }));
            }
        );

        return () => { canceled = true; };
    }, [setState]);

    const apps = useMemo(() => state.apps.filter(
        (app) => app.name.toLowerCase().includes(state.searchPhrase.toLowerCase())
              || app.description.toLowerCase().includes(state.searchPhrase.toLowerCase())
              || app.instructions.toLowerCase().includes(state.searchPhrase.toLowerCase())
    ), [state.apps, state.searchPhrase]);

    return (
        <div id="MarketplaceCatalogView">
            <div className="view-header">
                <div className="header-text">
                    <Label
                        as="h2"
                        className="app-title"
                        color="black"
                        size="h2"
                        weight="semi-bold"
                    >
                        <LocalizedString text="MARKETPLACE_HEADING" />
                    </Label>
                </div>
                <div className="search-bar">
                    <SpintrSearch
                        classes="searchBox"
                        value={state.searchPhrase}
                        onChange={onSearchChanged}
                    />
                </div>
            </div>
            <div className="view-body">
                {
                    state.isLoadingApps ? <SpintrLoader /> : (
                        <div className="app-grid-view">
                            <MarketplaceList
                                apps={apps}
                                displayMissingAppEntry={!state.searchPhrase}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, Spintr.AppState> =
    (appState) => {
        const developerAccounts = appState.instance
            .get("developerAccounts") as Spintr.IMarketplaceDeveloperAccount[];

        const adminSidebarIsActive = !!appState.sidebar.items &&
            appState.sidebar.items.length > 0 &&
            appState.sidebar.items[0].key === "admin";

        return { developerAccounts, adminSidebarIsActive };
    };

const ConnectedMarketplaceCatalogView = connect(mapStateToProps)(MarketplaceCatalogView);

export default withRouter(ConnectedMarketplaceCatalogView);
