import React from "react";
import { Link } from "react-router-dom";
import { localize, getTypeName } from "src/l10n";

const activityLanguageStrings = [
    "ACTIVITY_CREATE",
    "ACTIVITY_COMMENT",
    "ACTIVITY_DELETE",
    "ACTIVITY_EDIT",
    "ACTIVITY_LIKE",
    "ACTIVITY_SEARCH",
    "ACTIVITY_VISIT",
    "ACTIVITY_UPLOAD",
    "ACTIVITY_RESTORE",
    "ACTIVITY_DOWNLOAD",
];

const noTitleUberTypes = [3, 4, 5, 6, 13, 33, 34, 35, 49];

export const getActivityName = (type, actionType) => {
    if (type === 4 && actionType !== 0) {
        return localize("ACTIVITY_UNLIKE");
    } else if (type === 1 && actionType === 2) {
        return localize("ACTIVITY_DELETE");
    }

    return localize(activityLanguageStrings[type]);
};

export const formatString = (
    currentLanguage,
    actionText,
    typeText,
    linkTarget,
    linkText,
    addTillhorande
): JSX.Element => {
    return (
        <span>
            {currentLanguage === "4" && "hat "}
            {actionText} {typeText} {addTillhorande && localize("Tillhorande")}{" "}
            <Link to={`/goto/${linkTarget}`}>{linkText}</Link>
        </span>
    );
};

export const getActivityText = (language, item) => {
    let result = () => {};
    let actionText = getActivityName(item.Type, item.ActionType);

    const uberType =
        item.ExtraInformation.LikeType > 0
            ? item.ExtraInformation.LikeType
            : item.ExtraInformation.Type > 0
            ? item.ExtraInformation.Type
            : item.UberType;

    if (item.ContentIsSecret || uberType === 73) {
        var typeText = getTypeName(uberType, {
            case: "indefinite",
            alternative: item.IsInGroup,
        }).toLowerCase();

        result = () => formatString(language, actionText, null, null, typeText, item.UberType === 71);
    } else {
        var useTypeTextLink = noTitleUberTypes.includes(uberType);

        if (uberType <= 0) {
            return () => (
                <span>
                    {actionText} <Link to={item.ExtraInformation.TargetUrl}>{item.ExtraInformation.Title}</Link>
                </span>
            );
        } else if (useTypeTextLink) {
            var typeText = getTypeName(uberType, {
                case: "indefinite",
                alternative: item.IsInGroup,
            }).toLowerCase();

            result = () =>
                formatString(language, actionText, null, item.ExtraInformation.Id, typeText, item.UberType === 71);
        } else if (item.ExtraInformation?.Title?.length > 0) {
            var typeText = getTypeName(uberType, {
                case: "definite",
                alternative: item.IsInGroup,
            }).toLowerCase();

            result = () =>
                formatString(
                    language,
                    actionText,
                    typeText,
                    item.ExtraInformation.Id,
                    item.ExtraInformation.Title,
                    item.UberType === 71
                );
        } else {
            var typeText = getTypeName(uberType, {
                case: "indefinite",
                alternative: item.IsInGroup,
            }).toLowerCase();

            result = () => formatString(language, actionText, typeText, null, null, item.UberType === 71);
        }
    }

    return result;
};
