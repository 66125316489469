import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React, { memo } from 'react';
import { localize } from 'src/l10n';
import PopupHeader from '../PopupHeader';
import "./CustomDialog.scss";

interface Props {
    className?: string;
    message?: string;
    title?: string;
    show: boolean;
    onDismiss: any;
    confirmMessage?: string;
    dismissMessage?: string;
    onConfirm: any;
    children?: JSX.Element;
    hideTitle?: boolean;
    hidePrimaryBtn?: boolean;
    hideSecondaryBtn?: boolean;
    fullscreen?: boolean;
    footerChildren?: JSX.Element;
    primaryButtonDisabled?: boolean;
}

export default memo(function InfoDialog(props: Props) {
    const {
        message,
        title,
        show,
        onDismiss,
        confirmMessage,
        dismissMessage,
        onConfirm,
        children,
        hideTitle,
        hidePrimaryBtn,
        hideSecondaryBtn,
        fullscreen,
        footerChildren,
    } = props;

    let fullscreenView;
    if (fullscreen) {
        fullscreenView = "100%";
    }

    return (
        <Dialog
            maxWidth={fullscreenView}
            minWidth={fullscreenView}
            hidden={!show}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: hideTitle ? "" : title || localize("Information"),
                closeButtonAriaLabel: localize("Stang"),
                //@ts-ignore
                subText: <div className="spintrCustomDialogMessageWrap">{message}</div>,
                className: "spintrCustomDialogInner"
            }}
            modalProps={{
                isBlocking: true,
                className: "spintrCustomDialog",
                containerClassName: "dialogWithPopupHeader",
                allowTouchBodyScroll: true
            }}
            styles={{ main: { minHeight: 161 } }}
        >
            <PopupHeader
                text={hideTitle ? "" : title || localize("Information")}
                subText={message}
                onClose={onDismiss} />
            {children}

            <DialogFooter>
                {footerChildren}
                {
                    !hideSecondaryBtn && (
                        <DefaultButton
                            data-cy="dialog-cancel"
                            text={dismissMessage || localize("Avbryt")}
                            onClick={() => {
                                onDismiss();
                            }}
                        />
                    )
                }

                {hidePrimaryBtn ? null : (
                    <PrimaryButton
                        // theme={useContext(ThemeContext)}
                        data-cy="dialog-confirm"
                        onClick={() => {
                            onConfirm();
                        }}
                        disabled={props.primaryButtonDisabled}
                        text={confirmMessage || localize("Ok")}
                    />
                )}
            </DialogFooter>
        </Dialog>
    );
});
