import React, {
    ChangeEvent,
    Component,
    createRef,
    KeyboardEvent,
    MouseEvent,
    ReactNode,
    RefObject
} from "react";
import { Subject, Subscription, timer } from "rxjs";
import { debounce } from "rxjs/operators";
import { StandardFloatingLayer } from "../FloatingLayer";
import ITokenizedInputItem from "./ITokenizedInputItem";
import "./TokenizedInput.scss";
import TokenizedInputElement from "./TokenizedInputElement";
import TokenizedInputSuggestion from "./TokenizedInputSuggestion";

export type TokenizedInputChangeHandler = 
    (items : ITokenizedInputItem[]) => void;

export type TokenizedInputAutocompleteCallback = 
    (text : string) => Promise<ITokenizedInputItem[]>;

export interface ITokenizedInputProps {
    id?: string;
    autocomplete?: TokenizedInputAutocompleteCallback;
    items: ITokenizedInputItem[];
    onChange: TokenizedInputChangeHandler;
    tabIndex?: number;
}

export interface ITokenizedInputState {
    focused: boolean;
    selected: ITokenizedInputItem | null;
    suggestionIndex: number;
    suggestions: ITokenizedInputItem[];
    text: string;
    textWidth: number;
}

const DEFAULT_TEXT_WIDTH : number = 30;

class TokenizedInput extends Component<ITokenizedInputProps, ITokenizedInputState> {
    private inputStream : Subject<string>;
    private inputSubscriber : Subscription;
    private shadow : RefObject<HTMLDivElement>;
    private input : RefObject<HTMLInputElement>;

    constructor(props : ITokenizedInputProps) {
        super(props);

        this.state = {
            focused: false,
            selected: null,
            suggestionIndex: -1,
            suggestions: [],
            text: "",
            textWidth: DEFAULT_TEXT_WIDTH
        };

        this.fetchAutocompleteOptions = this.fetchAutocompleteOptions.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSuggestionClicked = this.onSuggestionClicked.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onTokenSelected = this.onTokenSelected.bind(this);
        this.onTokenRemove = this.onTokenRemove.bind(this);

        this.shadow = createRef();
        this.input = createRef();
        this.inputStream = new Subject<string>();
        this.inputSubscriber = this.inputStream
            .pipe(debounce(s => timer(250)))
            .subscribe(this.fetchAutocompleteOptions);
    }

    public componentWillUnmount() {
        this.inputSubscriber.unsubscribe();
        this.inputStream.unsubscribe();
    }

    public tagsChanged(tags : ITokenizedInputItem[]) : void {
        this.props.onChange(tags);
    }

    public onBlur() : void {
        this.setState({ focused: false });
    }

    public onClick(event : MouseEvent<HTMLElement>) : void {
        this.input.current.focus();
        
        const target = event.target as HTMLElement;
        if (target.nodeName !== "UL" && target.nodeName !== "INPUT") {
            return;
        }

        this.setState({ selected: null });
    }

    public onFocus() : void {
        this.setState({ focused: true });
    }

    public fetchAutocompleteOptions(text : string) : void {
        const trimmedText = (text || "").trim();
        if (!this.props.autocomplete || !trimmedText) {
            this.setState({
                suggestionIndex: -1,
                suggestions: []
            });

            return;
        }

        this.props.autocomplete(trimmedText).then(
            (items) => {
                this.setState({ 
                    suggestionIndex: -1,
                    suggestions: items
                });
            },
            (reason : any) => {
                console.log(reason);
            }
        );
    }

    public onTextChange(event : ChangeEvent<HTMLInputElement>) : void {
        const { value } = event.target;

        this.shadow.current.innerText = value
        this.setState({ 
            selected: null,
            text: value,
            textWidth: Math.max(
                DEFAULT_TEXT_WIDTH,
                this.shadow.current.clientWidth + 10
            )
        }, () => this.inputStream.next(this.state.text));
    }

    public onKeyDown(event : KeyboardEvent<HTMLInputElement>) : void {
        if (event.which === 8) {
            // Backspace

            if (this.state.text) {
                return;
            }

            if (this.props.items.length === 0) {
                return;
            }

            if (this.state.selected === null) {
                this.setState({
                    selected: this.props.items[this.props.items.length - 1]
                })
                return;
            }
            
            const selected = this.state.selected;
            this.setState(
                { selected: null},
                () => this.onTokenRemove(selected)
            );
        } else if (event.which === 27) {
            // Escape

            if (this.state.selected !== null) {
                this.setState(
                    { selected: null}
                );
                return;
            }

            if (this.state.suggestions.length > 0) {
                this.setState({ 
                    suggestionIndex: -1,
                    suggestions: []
                });
            }
        } else if (event.which === 13 || event.which === 188) {
            // Enter or comma

            event.preventDefault();

            if (this.state.suggestionIndex > -1 && 
                this.state.suggestions[this.state.suggestionIndex]
            ) {
                const suggestion = this.state.suggestions[
                    this.state.suggestionIndex
                ];

                this.setState({ 
                        suggestionIndex: -1,
                        suggestions: [],
                        text: "", 
                        textWidth: DEFAULT_TEXT_WIDTH
                    },
                    () => this.tagsChanged(
                        this.props.items.concat([suggestion])
                    )
                );
                return;
            }

            if (!this.state.text) {
                return;
            }

            this.tagsChanged(
                this.props.items.concat([{
                    name: this.state.text
                }])
            );

            this.setState({ text: "", textWidth: DEFAULT_TEXT_WIDTH });
        } else if (event.which === 38 || event.which === 40) {
            // Up or Down

            event.preventDefault();

            const step  = event.which === 38 ? -1 : 1;
            const nextIndex = this.state.suggestionIndex + step;
            if (nextIndex < -1 || nextIndex >= this.state.suggestions.length) {
                return;
            }

            this.setState({ suggestionIndex: nextIndex });
        }
    }

    public onSuggestionClicked(item : ITokenizedInputItem) : void {
        const nextState = {
            suggestionIndex: -1,
            suggestions: [],
            text: "",
            textWidth: DEFAULT_TEXT_WIDTH
        };

        this.setState(
            nextState,
            () => this.tagsChanged(
                this.props.items.concat([item])
            )
        );
    }

    public onTokenSelected(item : ITokenizedInputItem) : void {
        this.setState({ selected: item });
    }

    public onTokenRemove(item : ITokenizedInputItem) : void {
        const items = this.props.items.slice();

        const indexToRemove = items.indexOf(item);
        if (indexToRemove === -1) {
            return;
        }

        items.splice(indexToRemove, 1);
        this.tagsChanged(items);
    }

    public render() : ReactNode {
        const inputAttrs : {[key : string]: string } = {};

        if (this.props.tabIndex !== null && this.props.tabIndex !== undefined) {
            inputAttrs["tabIndex"] = this.props.tabIndex.toString();
        }

        const classNames : string[] = ["TagInput"];
        if (this.state.focused) {
            classNames.push("focus");
        }

        return (
            <div className={classNames.join(" ")}>
                <div className="input-area" onClick={this.onClick}>
                    <ul className="tags">
                        {
                            this.props.items.map(
                                (item, index) => this.renderTag(item, index))
                        }
                        <li className="input-element"> 
                            <input
                                className="tag-input"
                                id={this.props.id}
                                onBlur={this.onBlur}
                                onChange={this.onTextChange}
                                onFocus={this.onFocus}
                                onKeyDown={this.onKeyDown}
                                ref={this.input}
                                style={{width: `${this.state.textWidth}px`}}
                                value={this.state.text}
                                {...inputAttrs}
                            />
                            <div 
                                className="shadow"
                                ref={this.shadow}
                            />
                            {this.renderSuggestions()}
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    public renderSuggestions() : ReactNode {
        if (this.state.suggestions.length === 0) {
            return null;
        }

        return (
            <StandardFloatingLayer open={true}>
                <div className="autocomplete-layer">
                    <ul className="suggestions">
                        {this.state.suggestions.map(
                            (item, index) => 
                                this.renderSuggestedItem(item, index)
                        )}
                    </ul>
                </div>
            </StandardFloatingLayer>
        );
    }

    public renderSuggestedItem(item : ITokenizedInputItem, index : number) : ReactNode {
        return (
            <li 
                className="suggestion"
                key={`tiac${index}`}
            >
                <TokenizedInputSuggestion 
                    item={item}
                    selected={this.state.suggestionIndex===index}
                    onClick={this.onSuggestionClicked}
                />
            </li>
        );
    }

    public renderTag(item : ITokenizedInputItem, index : number) : ReactNode {
        return (
            <li key={`TagInpug.Tag${index}`}>
                <TokenizedInputElement 
                    item={item} 
                    onSelect={this.onTokenSelected}
                    onRemove={this.onTokenRemove}
                    selected={this.state.selected === item}
                />
            </li>
        );
    }
}

export default TokenizedInput;