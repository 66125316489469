import React, { FunctionComponent } from "react";
import { localize } from "src/l10n";
import { NotificationList } from "src/notifications/components";
import { PageHeader } from "src/ui";
import "./NotificationsView.scss";

const NotificationsView: FunctionComponent = () => {
    return (
        <div id="NotificationsView">
            <PageHeader title={localize("Notiser")} />
                <NotificationList
                    fullscreen
                    isDisplayingO365Footer={false}
                />
        </div>
    )
};

export default NotificationsView;
