import { ActionButton, Icon, TextField } from "@fluentui/react";
import React, { ChangeEvent, MouseEvent, ReactNode } from "react";

import { hitEnterOrSpace } from "src/files/utils";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, UnstyledButton } from "src/ui";
import { ComposerContext } from "./ComposerContext";
import { IComposerPollContent } from "./IComposerViewState";
import SocialComposerType, {
    IComposerSaveOptions,
    ISocialComposerTypeProps,
} from "./SocialComposerType";

import "./SocialComposerPoll.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const defaultContentState: IComposerPollContent = {
    options: ["", ""],
};

class SocialComposerPoll extends SocialComposerType<ISocialComposerTypeProps, never> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;
    
    constructor(props: ISocialComposerTypeProps) {
        super(props);

        this.onAddClick = this.onAddClick.bind(this);
        this.onOptionChange = this.onOptionChange.bind(this);
        this.onOptionRemoveClick = this.onOptionRemoveClick.bind(this);
        this.onDismissTypeClick = this.onDismissTypeClick.bind(this);
    }

    public validate() {
        const contentState = this.getContentState();
        const result = [];
        const validOptions = [];

        for (const o of contentState.options) {
            if (o && o.length > 0) {
                validOptions.push(o);
            }
        }

        if (!validOptions ||
            validOptions.length < 2) {
            result.push("DetMasteFinnasMinstTvaSvarsalternativIfyllda");
        }

        return result;
    }

    public async saveContent(options: IComposerSaveOptions)
        : Promise<Spintr.ISocialPostBase | null> 
    {
        const contentState = this.getContentState();
        // Todo: Check validity

        const model = {
            // todo: only content
            text: options.text,
            feedId: options.feedId,
            flag: options.flag,
            content: [{
                flag: options.flag,
                options: contentState.options,
                question: options.text,
                feedId: options.feedId,
                typeDiscriminator: 14
            }],
        };

        const formData = new FormData();
        formData.append("jsonString", JSON.stringify(model));

        try {
            const response = await api.post<Spintr.ISocialPoll>(
                "/api/v1/status",
                formData,
                {
                    headers: {
                        ...(options.identity?.id ? {"x-impersonate-user": options.identity?.id} : {})
                    }
                }
            );

            this.context.setContentState({ options: ["", ""] });

            return response.data;
        } catch (err) {
            console.log(err);

            return null;
        }
    }

    protected getContentState(): IComposerPollContent {
        const contentState = this.context?.contentState as IComposerPollContent;
        if (!contentState?.options) {
            return {
                options: defaultContentState.options.slice(),
            };
        }

        return contentState;
    }

    protected renderTypeContent(): ReactNode {
        const options = this.getContentState()?.options || ["", ""];

        return (
            <div className="SocialComposerPoll">
                <div className="options">
                    <Label size="body-2" weight="medium">
                        {localize("ADD_POLL")}
                    </Label>
                    {options.map((option, i) => (
                        <div className="option" key={i}>
                            <TextField 
                                borderless={true}
                                className="option-input" 
                                data-index={i}
                                placeholder={localize("Svarsalternativ")}
                                onChange={this.onOptionChange}
                                value={option}
                                maxLength={128}
                            />
                            {options.length > 2 &&
                                <UnstyledButton
                                    className="remove-icon"
                                    onClick={this.onOptionRemoveClick}>
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            }
                        </div>
                    ))}
                    <UnstyledButton
                        className="remove-button"
                        onClick={this.onDismissTypeClick}
                    >
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                </div>
                <div className="add">
                    <div className="text">
                        <ActionButton 
                            className="fs-body-1"
                            iconProps={{
                                iconName: "Add"
                            }}
                            onClick={this.onAddClick}
                        >
                            {localize("LaggTillEttSvarsalternativ")}
                        </ActionButton>
                    </div>
                </div>
            </div>
        );
    }

    protected onDismissTypeClick(): void {
        this.context.setSize("default");
        this.context.setType("text");
    }

    protected onAddClick(event: MouseEvent<HTMLButtonElement>): void {
        const contentState = this.getContentState();

        this.context.setContentState({
            ...contentState,
            options: contentState.options.slice().concat([""]),
        });
    }

    protected onOptionChange(event: ChangeEvent<HTMLInputElement>): void {
        const { dataset, value } = event.target;
        const contentState = this.getContentState();

        const index = parseInt(dataset.index, 10);
        if (isNaN(index) || index >= contentState.options.length) {
            return;
        }

        const options = contentState.options.slice();
        if (options[index] === undefined) {
            return;
        }

        options[index] = value;
        this.context.setContentState({
            ...contentState,
            options
        });
    }

    protected onOptionRemoveClick(event: MouseEvent<HTMLElement>): void {
        const { dataset } = event.target as HTMLElement;
        const contentState = this.getContentState();

        const index = parseInt(dataset.index, 10);
        if (isNaN(index) || index >= contentState.options.length) {
            console.log(dataset.index, "fail");
            return;
        }

        const options = contentState.options.slice();
        if (options[index] === undefined) {
            console.log(index, "doesnt exist");
            return;
        }

        options.splice(index, 1);

        this.context.setContentState({
            ...contentState,
            options,
        });
    }
}

export default SocialComposerPoll;