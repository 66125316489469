import { Action } from "redux";

export enum RightColumnActionTypes {
    SetRightColumns = "SET_RIGHT_COLUMNS",
    ClearRightColumns = "CLEAR_RIGHT_COLUMNS"
}

export interface ISetRightColumns extends Action {
    items: any[];
    type: RightColumnActionTypes.SetRightColumns;
}

export interface IClearRightColumns extends Action {
    type: RightColumnActionTypes.ClearRightColumns;
}

export type RightColumnAction =
    | ISetRightColumns
    | IClearRightColumns;