import { PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { Label, Loader } from "src/ui";
import "./ForgotPassword.scss";
import SplitAuth from "../splitauth/SplitAuth";
import ThemeContext from "src/style/ThemeContext";
import api from "src/spintr/SpintrApi";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";

interface IProps extends RouteComponentProps {
    dispatch?: (Action) => void;
}

interface IState {
    username: string;
    emailSent?: boolean;
    isLoading: boolean;
    error?: string;
}

class ForgotPassword extends Component<IProps, IState> {
    public static contextType = ThemeContext;

    constructor(props: IProps) {
        super(props);

        this.state = {
            username: "",
            isLoading: false,
        };
    }

    private onChangeUsername = (ev, val) => {
        this.setState({ username: val });
    };

    private onSubmit = async (ev) => {
        this.setState({
            isLoading: true,
        });

        this.setState({
            emailSent: true,
        });

        api.post("/api/v1/authentication/forgot", {
            username: this.state.username,
        }).then((response) => {
            this.setState({
                emailSent: true,
                isLoading: false,
            });
        });
    };

    public render() {
        const { isLoading, error, emailSent } = this.state;

        return (
            <div>
                <div className="forgot-password-view">
                    <SplitAuth error={error} backgroundUrl="/images/forgotpassword.jpg">
                        <>
                            <Label as="h2" size="h2" weight="medium" className="forgot-password-label">
                                {localize("GlomtLosenord")}
                            </Label>

                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    <Label size="body-1" className="forgot-password-subtext-label">
                                        {localize(
                                            emailSent
                                                ? "EttNyttLosenordHarSkickatsTillDinEpostAdress"
                                                : "PASSWORD_RESET_ENTER_USERNAME"
                                        )}
                                    </Label>

                                    {emailSent ? (
                                        <>
                                            <PrimaryButton
                                                text={localize("Ok")}
                                                onClick={() => this.props.history.push("/sign-in")}
                                                className="next-button"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <TextField
                                                label={localize("Anvandarnamn")}
                                                name="username"
                                                id="username"
                                                value={this.state.username}
                                                onChange={this.onChangeUsername}
                                            />

                                            <PrimaryButton
                                                text={localize("Skicka") + "!"}
                                                onClick={this.onSubmit}
                                                className="next-button"
                                                type="submit"
                                            />
                                            <Link to="/sign-in" className="back-button">
                                                {localize("Tillbaka")}
                                            </Link>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </SplitAuth>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: any) => {
    return {
        ...props,
    };
}

const ConnectedForgotPassword = connect(mapStateToProps)(ForgotPassword);
const ConnectedForgotPasswordWithRouter = withRouter(ConnectedForgotPassword);

export default ConnectedForgotPasswordWithRouter;
