
import React, { Component, RefObject } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import Label from "./Label/Label";
import "./VideoViewCount.scss"
interface IProps {
    url?: string;
    id?: number;
}

interface IState {
    viewCount?: number;
}

class VideoViewCount extends Component<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            viewCount: undefined
        }
    }
    public componentDidMount = () => {
        this.fetchViewCount();
    }

    public fetchViewCount = () => {
        api.get(`/api/v1/blogposts/viewcount/${this.props.id ?? 0}`, { params: { url: this.props.url ?? ""}}).then((response) => {
            this.setState({viewCount: response.data})
        })
    }

    public render() {
        if (!this.state.viewCount) {
            return null
        }

        return (
            <Label className="VideoViewCount" color="visageGray3" size="body-3">{this.state.viewCount} {localize("Visningar")} </Label>
        )
    }
}

export default VideoViewCount;