import React, { Fragment, FunctionComponent } from 'react';
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const EventDeletedNotification: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.IEventDeletedNotification;
    if (!notification) {
        return;
    }

    return (
        <Fragment>
            {localize("Kalenderposten") + " " + notification.eventName + " " + localize("HarTagitsBort").toLowerCase()}
        </Fragment>
    );
};

export default EventDeletedNotification;
