import { interpolate } from "d3-interpolate";
import { select } from "d3-selection";
import { transition } from "d3-transition";
import React from "react";

const arcTween = (newAngle, arcGenerator) => d => {
    const customInterpolate = interpolate(d.endAngle, newAngle);
    return t => {
        d.endAngle = customInterpolate(t);
        return arcGenerator(d);
    };
};
interface IProps {
    arcGenerator : any
    value : any
    max : any
    fgColor : any
    tau : any
    svgWidth : any
    svgHeight : any
}


export default class CircularBar extends React.Component <IProps> {
    private ref = React.createRef<SVGGElement>();
    private g:any;
    constructor(props: IProps) {
        super(props);
        this.ref = React.createRef();
    }
    public componentDidMount() {
        this.init();
    }
    public componentDidUpdate() {
        this.transitionBar();
    }
    public init() {
        const node = this.ref.current;
        const { tau, max, fgColor } = this.props;
        const initialValue = 0;

        const g = select(node)
            .append("path")
            .style("fill", fgColor)
            .datum({ endAngle: (initialValue / max) * tau });
        this.g = g;
        this.transitionBar();
    }
    public transitionBar() {
        const { g } = this;
        const t = transition().duration(800);
        const { max, value, tau, arcGenerator } = this.props;

        g.transition(t)
            .duration(750)
            .attrTween("d", arcTween((value * tau) / max, arcGenerator));
    }
    public render() {
        const { svgWidth, svgHeight } = this.props;
        return (
            <g
                className="front-bar-group"
                transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}
                ref={this.ref}
            />
        );
    }
}