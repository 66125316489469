import React, { memo } from "react";
import { localize } from "src/l10n";
import { Icon, Label } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    placeholder?: string;
    subPlaceholder?: string;
    icon?: string;
    onClick?: () => void;
}

export default memo(function CoverImageButton(props: Props) {
    const { placeholder, subPlaceholder, onClick } = props;

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    display: "inline-block",
                    position: "relative",
                    padding: Style.getSpacingStr(5),
                    cursor: onClick ? "pointer" : "default",
                }}
                onClick={onClick ? onClick : null}
            >
                <div
                    style={{
                        display: "inline-block",
                        marginBottom: Style.getSpacingStr(2)
                    }}
                >
                    <Visage2Icon color="visageMidBlue" icon={props.icon} />
                </div>
                <div
                    style={{
                        textAlign: "center",
                        userSelect: "none",
                    }}
                >
                    <Label color="visageMidBlue" size="body-2">
                        {placeholder ? placeholder : localize("ValjOmslagsbild")}
                    </Label>
                </div>
                {
                    subPlaceholder ?
                        <div
                            style={{
                                textAlign: "center",
                                userSelect: "none",
                                wordBreak: "break-word",
                            }}
                        >
                            <Label color="grey" size="h6">
                                {subPlaceholder}
                            </Label>
                        </div> :
                        null
                }
            </div>
        </div>
    );
});
