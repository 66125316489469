import { DefaultButton, Stack, StackItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { IActiveUserProfile } from "src/profile/reducer";
import { SpintrHeaderSearch } from "src/search";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import SpintrStaticLinks from "./SpintrStaticLinks";
import Headroom from "react-headroom";
import { UnstyledButton, setDisplayHeaderSearch } from "src/ui";
import { debounce } from "src/utils";
import OperatingInfoNotification from "./OperatingInfoNotification";
import { SpintrTypes } from "src/typings";
import Color from "src/style/colors/Color";
import { localize } from "src/l10n";
import RightColumnDashboard from "src/admin/views/AdminDashboard/RightColumnDashboard";
import classnames from "classnames";
import { getSpintrTheme } from "src/style/utilities";
import { ReactSVG } from "react-svg";
import HeaderImportantArticlesButton from "./HeaderImportantArticlesButton/HeaderImportantArticlesButton";
import SpintrHeaderMenu from "./SpintrHeaderMenu";
import SystemStatusHeaderButton from "src/system-status/SystemStatusHeaderButton";

interface IStateProps {
    currentUser?: IActiveUserProfile;
    instance?: any;
    apps?: any;
    displayHeaderSearch?: boolean;
    operatingInfoEnabled?: boolean;
    systemStatusEnabled?: boolean;
    dashboardEnabled?: boolean;
    displayDashboardObjectsInHeader?: boolean;
    importantArticles?: any[];
}

interface IOwnProps {
    runIntroduction?: any;
    logoTitle?: string;
    logoUrl?: string;
}

interface IState {
    headerWidth: number;
}

type Props = IStateProps & IOwnProps & DispatchProp & RouteComponentProps;

class SpintrHeader extends Component<Props, IState> {
    private _isMounted: boolean = false;
    unlisten: any = null;

    protected intervalRef: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            headerWidth: this.getHeaderWidth()
        }
    }

    getHeaderWidth() {
        const elements = document.getElementsByClassName("headroom-wrapper");

        if (!!elements && elements.length > 0) {
            const element = elements[0];

            if (!!element) {
                return element.clientWidth;
            }
        }
    }

    debouncedOnResize = debounce(() => this.onResize(), 50);

    onResize() {
        const headerWidth = this.getHeaderWidth();

        if (headerWidth !== this.state.headerWidth) {
            if (!this._isMounted) {
                return;
            }

            this.setState({
                headerWidth
            });
        }
    }

    enableOperatingInfo = this.props.apps.items.find((a) => a.name === "operatinginfo" && a.enabled);

    componentDidMount() {
        this._isMounted = true;
        //this.unlisten = this.props.history.listen(this.onNavigation.bind(this));
        window.addEventListener("resize", this.debouncedOnResize.bind(this), false);

        this.intervalRef = setInterval(
            (this.debouncedOnResize.bind(this)) as TimerHandler,
            1000
        );
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (!!this.unlisten) {
            this.unlisten();

            this.props.dispatch(setDisplayHeaderSearch(false));
        }

        window.removeEventListener("resize", this.debouncedOnResize.bind(this), false);

        if (this.intervalRef) {
            clearInterval(this.intervalRef);
        }
    }

    // onNavigation() {
    //     if (this.props.displayHeaderSearch) {
    //         this.props.dispatch(setDisplayHeaderSearch(false));
    //     }
    // }

    getHeaderColor() {
        let color = "#FFFFFF";

        const spintrTheme = getSpintrTheme(this.props.instance);

        if (spintrTheme.useColorHeader) {
            color = spintrTheme.headerColor;

            if (!color) {
                color = "#FFFFFF";
            }
        }

        return color;
    }

    public renderLogo(): ReactNode {
        let logoUrl;
        const color = this.getHeaderColor();

        let preferliblyUseLightLogo = getLightOrDarkColorBasedOnColor(
            color,
            "a",
            "b"
        ) === "a";

        if (preferliblyUseLightLogo) {
            logoUrl = !!this.props.instance.get("logoUrl") ?
                this.props.instance.get("logoUrl") :
                this.props.instance.get("colorLogoUrl");
        } else {
            logoUrl = !!this.props.instance.get("colorLogoUrl") ?
                this.props.instance.get("colorLogoUrl") :
                this.props.instance.get("logoUrl");
        }

        if (color === "#FFFFFF" &&
            logoUrl === "/Style/Images/specific/logomodern.png") {
            logoUrl = this.props.instance.get("logoUrl");
        }

        return (
            <div className={"logo-wrapper" + (color === "#FFFFFF" ? " logo-wrapper-white" : "")}>
                <Link to="/">
                    <img src={logoUrl} />
                </Link>
            </div>
        );
    }

    public render(): ReactNode {
        let classNames = ["SpintrHeader"];

        if (this.props.instance.get("useColorHeader")) {
            classNames.push("headerBGColor");
        }

        return (
            <div className="SpintrHeaderWrapper">
                {this.renderTop()}
            </div>
        )
    }

    private logout = () => {
        if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.userAgent.indexOf("Trident") !== -1) {
            document.execCommand("ClearAuthenticationCache");
        }

        localStorage.clear();

        window.location.assign("/api/authentication/signout");
    }

    public renderTop(): ReactNode {
        if (this.props.currentUser.isGroupUser || this.props.currentUser.roles.indexOf("supplier") > -1) {
            return (
                <div className="SpintrHeader">
                    <div className="header-background" />
                    {this.renderLogo()}
                    <div className="externalUser">
                        <DefaultButton onClick={this.logout}>{localize("LoggaUt")}</DefaultButton>
                    </div>
                </div>
            );
        }

        return (
            <div className="SpintrHeader">
                <div className="header-background" />
                {this.renderLogo()}
                <SpintrHeaderMenu />
                {this.props.dashboardEnabled && this.props.displayDashboardObjectsInHeader && (
                    <RightColumnDashboard isInHeader />
                )}
                {this.props.importantArticles.length > 0 && (
                    <HeaderImportantArticlesButton />
                )}
                <div className="spacer" />
                <SpintrStaticLinks runIntroduction={this.props.runIntroduction} disableCoachmarks={!this.state.headerWidth} />
            </div>
        )
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> = (state, props) => ({
    ...props,
    currentUser: state.profile.active,
    instance: state.instance,
    apps: state.app,
    displayHeaderSearch: state.ui.displayHeaderSearch,
    operatingInfoEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.OperatingInfo,
    ),
    systemStatusEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.SystemStatus,
    ),
    dashboardEnabled: state.app.items.some(
        (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Dashboard,
    ),
    displayDashboardObjectsInHeader: state.instance.get("displayDashboardObjectsInHeader"),
    importantArticles: state.informationFeed.importantArticles.filter(x => x.priorityLevel === 5),
});

export default withRouter(connect(mapStateToProps)(SpintrHeader));
