import { localize, localizeFormat } from "src/l10n";

export const getUserPart = (notification: Spintr.INotification): string => {
    if (!notification.others || !notification.others.length) {
        return notification.userName
    }

    if (notification.others.length === 1) {
        return [
            notification.userName,
            localize("Och_small"),
            notification.others[0].name
        ].join(" ");
    }

    return [
        notification.userName,
        localizeFormat("OchXAndra", notification.others.length).toLowerCase()
    ].join(" ");
};