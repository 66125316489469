import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { Breadcrumbs, Loader, PageHeader } from "src/ui";

const splitName = (name = "") => {
    const [firstname, ...lastname] = name.split(" ").filter(Boolean);
    return {
        firstname: firstname,
        lastname: lastname.join(" "),
    };
};

interface IProps {
    list: any;
    currentUser?: IActiveUserProfile;
}

interface IState {
    list: any;
    isLoading: boolean;
}

class ImprovementsView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            isLoading: false,
        };
    }

    public componentDidMount = () => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        script.async = true;
        document.body.appendChild(script);
        const user = this.props.currentUser;
        const { firstname, lastname } = splitName(user.name);
        const prefilledValues = { firstname, lastname, email: user.email, company: user?.department?.office?.company?.name ?? ""};

        window.addEventListener("message", (event) => {
            if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormReady") {
                for (const key in prefilledValues) {
                    const el = document
                        ?.getElementsByClassName("hs-form-iframe")[0]
                        //@ts-ignore
                        ?.contentDocument?.querySelector(`input[name="${key}"]`);
                    if (el) {
                        el.setAttribute("value", prefilledValues[key]);
                        el.dispatchEvent(new Event("input", { bubbles: true }));
                    }
                }
            }
        });

        script.addEventListener("load", () => {
            //@ts-ignore
            if (window.hbspt) {
                //@ts-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "2624188",
                    formId: "182a26fb-ed52-4cc3-8648-e1ab6be2e0be",
                    target: "#hubspotForm",
                });
            }
        });
    };

    public render(): ReactNode {
        const improvementsText = localize("ForbattringsforslagForm");

        return (
            <div className="ImprovementsView">
                <Helmet>
                    <title>{improvementsText}</title>
                </Helmet>
                <Breadcrumbs
                    items={[
                        {
                            text: improvementsText,
                            key: 1,
                        },
                    ]}
                />
                <div className="improvementsInner contentMiddle">
                    {this.state.isLoading && <Loader />}
                    {!this.state.isLoading && (
                        <div className="improvementsBody">
                            <div id="hubspotForm"></div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(ImprovementsView);
