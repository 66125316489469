import React from "react";
import { useState } from "react";
import { Label } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SearchShortcutLabel.scss";

const SearchShortcutLabel = () => {
    const [isMac] = useState(window.navigator.platform.indexOf("Mac") > -1);

    if (isMac) {
        return (
            <Label
                className="SearchShortcutLabel"
                size="body-1">
                <Visage2Icon icon="command" />
                <span className="K">K</span>
            </Label>
        )
    }

    return (
        <Label
            className="SearchShortcutLabel"
            size="body-2">
            CTRL K
        </Label>
    )
}

export default SearchShortcutLabel;
