import { combineReducers } from "redux";

import blogReducer from "./blog-reducer";
import blogPostReducer from "./post-reducer";

const blogsReducer = combineReducers({
    // @ts-ignore
    blogs: blogReducer,
    posts: blogPostReducer,
});

export default blogsReducer;
