import { Action } from "redux";
import { VisageSidebarDrawerId, VisageSidebarMode } from ".";

import * as ActionTypes from "./action-types";
import { IVisageSidebarMenuItem } from "./reducer";

export const setSidebarItemExpanded = (key: string, value: boolean, isLoading?: boolean): ActionTypes.ISetItemExpanded => ({
    key,
    value,
    isLoading,
    type: ActionTypes.SidebarActionTypes.SetItemExpanded
})

export const setSidebarItemChildren = (key: string, items: IVisageSidebarMenuItem[]): ActionTypes.ISetItemChildren => ({
    key,
    items,
    type: ActionTypes.SidebarActionTypes.SetItemChildren
})

export const setSidebarItems = (items: IVisageSidebarMenuItem[], key?: string): ActionTypes.ISetSidebarItems => ({
    items,
    key,
    type: ActionTypes.SidebarActionTypes.SetSidebarItems
})

export const expandToActiveItem = (): ActionTypes.IExpandToActiveItem => ({
    type: ActionTypes.SidebarActionTypes.ExpandToActiveItem
})

export const setSidebarMode = (mode: VisageSidebarMode): ActionTypes.ISetSidebarMode => ({
    mode,
    type: ActionTypes.SidebarActionTypes.SetSidebarMode
})

export const setSidebarDrawerId = (value: VisageSidebarDrawerId): ActionTypes.ISetSidebarDrawerId => ({
    value,
    type: ActionTypes.SidebarActionTypes.SetSidebarDrawerId
})

export const setMenu = (items: any[]): ActionTypes.ISetMenu => ({
    items,
    type: ActionTypes.SidebarActionTypes.SetMenu
})

export const setDisplaySearch = (value: boolean): ActionTypes.ISetDisplaySearch => ({
    value,
    type: ActionTypes.SidebarActionTypes.SetDisplaySearch
})

export const setDisplayBackButton = (value: boolean): ActionTypes.ISetDisplayBackButton => ({
    value,
    type: ActionTypes.SidebarActionTypes.SetDisplayBackButton
})