export enum InteractionActionTypes {
    SetComments = 'SET_COMMENTS',
    FetchComments = 'FETCH_COMMENTS',
    FetchCommentsPending = 'FETCH_COMMENTS_PENDING',
    FetchCommentsRejected = 'FETCH_COMMENTS_REJECTED',
    FetchCommentsFulfilled = 'FETCH_COMMENTS_FULFILLED',

    SetLikers = 'SET_LIKERS',
    FetchLikers = 'FETCH_LIKERS',
    FetchLikersPending = 'FETCH_LIKERS_PENDING',
    FetchLikersRejected = 'FETCH_LIKERS_REJECTED',
    FetchLikersFulfilled = 'FETCH_LIKERS_FULFILLED',

    AddLike = 'ADD_LIKE',
    AddLikePending = 'ADD_LIKE_PENDING',
    AddLikeFulfilled = 'ADD_LIKE_FULFILLED',

    RemoveLike = 'REMOVE_LIKE',
    RemoveLikePending = 'REMOVE_LIKE_PENDING',
    RemoveLikeFulfilled = 'REMOVE_LIKE_FULFILLED',
    
    AddComment = 'ADD_COMMENT',
    AddCommentPending = 'ADD_COMMENT_PENDING',
    AddCommentFulfilled = 'ADD_COMMENT_FULFILLED',

    UpdateComment = 'UPDATE_COMMENT',
    UpdateCommentPending = 'UPDATE_COMMENT_PENDING',
    UpdateCommentFulfilled = 'UPDATE_COMMENT_FULFILLED',

    RemoveComment = 'REMOVE_COMMENT',
    RemoveCommentPending = 'REMOVE_COMMENT_PENDING',
    RemoveCommentFulfilled = 'REMOVE_COMMENT_FULFILLED',

    MarkCommentAsAnswer = 'MARK_COMMENT_AS_ANSWER',
    Clear = 'CLEAR'
}