import { ActionButton, PrimaryButton } from "@fluentui/react";
import React, { Fragment, FunctionComponent, MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { respondToRequest } from "src/groups/groups-api";
import { localize } from "src/l10n";
import { Loader } from "src/ui";
import { removeNotification } from "../actions";

interface IProps {
    notification: Spintr.INotification;
    history: any;
    dispatch: (action: Action) => void;
}

const GroupJoinRequestNotification: FunctionComponent<IProps> = (props) => {
    const [ isLoading, setIsLoading ] = useState(false);

    const notification = props.notification as Spintr.IGroupJoinRequestNotification;
    if (!notification) {
        return null;
    }

    const respond = async (accepted: boolean): Promise<void> => {
        setIsLoading(true);

        try {
            await respondToRequest(notification.userId, notification.groupId, accepted);

            // TODO: Remove this anti-pattern
            notification.hasResponded = true;
            props.dispatch(removeNotification(notification.id));
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reject = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        respond(false);
    };

    const accept = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        respond(true);
    };

    return (
        <div className="GroupInvitationNotification">
            {!isLoading && (
                <Fragment>
                    <div className="info">
                        <Link to={`/profile/${notification.userId}`}>
                            {notification.userName}
                        </Link>
                        {notification.crossInstance && (
                            "("+notification.companyName+")"
                        )} {
                            localize("VillBliMedlemIDinGrupp")
                        } {!(notification.url && notification.hasResponded) && (
                            "\""+notification.groupName+"\""
                        )}
                        {notification.url && notification.hasResponded && (
                            <Link to={notification.url}>
                                "{notification.groupName}"
                            </Link>
                        )}.
                    </div>
                    {(!notification.hasResponded) && (
                        <div className="actions">
                            <ActionButton onClick={reject}>
                                {localize("Avsla")}
                            </ActionButton>
                            <PrimaryButton onClick={accept}>
                                {localize("Acceptera")}
                            </PrimaryButton>
                        </div>
                    )}
                </Fragment>
            )}
            {isLoading && (
                <Loader />
            )}
        </div>
    );
};

export default GroupJoinRequestNotification;