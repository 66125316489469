import React from "react";
import "./RunningLabel.scss";
import { Label } from "src/ui";
import { localize } from "src/l10n";

interface IProps {
    langTag: string;
}

const RunningLabel = (props: IProps) => {
    return (
        <div className="RunningLabel">
            <Label size="body-2" weight="medium">{localize(props.langTag)}</Label>
        </div>
    )
}

export default RunningLabel;
