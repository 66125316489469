import { Checkbox, ChoiceGroup, IChoiceGroupOption, Stack, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { RouteChildrenProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { Loader, PageHeader } from "src/ui";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import api from "src/spintr/SpintrApi";

interface Props extends RouteChildrenProps { }
interface State {
    isLoading: boolean;
    settings: any;
    saveSuccess: boolean;
    saveError: string[];
    enableFieldValidation?: boolean;
}

class QASettingsView extends Component<Props, State> {
    state = {
        isLoading: true,
        settings: {} as any,
        saveSuccess: false,
        saveError: [],
        enableFieldValidation: false
    };

    createOptions = {};

    componentDidMount = () => {
        this.setState({ isLoading: true }, () => {
            api.get(`/api/v1/qa/settings`).then((response) => {
                this.setState({ settings: response.data }, () => {
                    this.setState({ isLoading: false });
                });
            });
        });
    };

    onCancel = () => {
        this.props.history.push({
            pathname: `/qa`,
        });
    };

    onSubmit = () => {
        this.setState({
            enableFieldValidation: true
        });
        this.setState({ isLoading: true }, () => {
            api
                .post("/api/v1/qa/settings", this.state.settings)
                .then((response) => {
                    scrollToTop();
                    this.setState({ saveSuccess: true, saveError: [], isLoading: false });
                })
                .catch((saveError) => {
                    scrollToTop();
                    this.setState({ saveError: saveError.response.data.errorlist });
                });
        });
    };

    render() {
        const { settings, isLoading, saveSuccess, saveError } = this.state;
        if (isLoading) return <Loader />;

        return (
            <div className="QASettingsView">
                <Helmet>
                    <title>{localize("appQA")}</title>
                </Helmet>
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                <Stack styles={{ root: { width: 650, marginBottom: "6em" } }}>
                    <PageHeader title={localize("Installningar")} />
                    <form>
                        <FormControl>
                            <TextField
                                label={localize("MenyTitel")}
                                name="name"
                                defaultValue={localize(settings.name)}
                                value={settings.name}
                                onChange={(_e, name) => {
                                    this.setState((state) => ({ settings: { ...state.settings, name } }));
                                }}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>

                        <FormControl>
                            <ChoiceGroup
                                label={localize("KanSkapaFragor")}
                                options={[
                                    { key: "0", text: localize("Alla") },
                                    { key: "1", text: localize("RedaktorerOchAdministratorer") },
                                    { key: "2", text: localize("Administratorer") },
                                ]}
                                selectedKey={settings.createPrivilegesLevel.toString()}
                                onChange={(event, option: IChoiceGroupOption) => {
                                    this.setState((state) => ({
                                        settings: {
                                            ...state.settings,
                                            createPrivilegesLevel: option.key,
                                        },
                                    }));
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <ChoiceGroup
                                label={localize("KanSvaraPaFragorOchRedigeraSvar")}
                                options={[
                                    { key: "0", text: localize("Alla") },
                                    { key: "1", text: localize("RedaktorerOchAdministratorer") },
                                    { key: "2", text: localize("Administratorer") },
                                ]}
                                selectedKey={settings.answerPrivilegesLevel.toString()}
                                onChange={(event, option: IChoiceGroupOption) => {
                                    this.setState((state) => ({
                                        settings: {
                                            ...state.settings,
                                            answerPrivilegesLevel: option.key,
                                        },
                                    }));
                                }}
                            />
                        </FormControl>

                        <FormSection title={localize("Notiser")}>
                            <FormControl>
                                <Checkbox
                                    label={localize("SkickaNotifikationerTillAdministratorerNarFragorSkapas")}
                                    checked={settings.notificationCreate}
                                    onChange={(
                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                        notificationCreate?: boolean
                                    ) => {
                                        this.setState((state) => ({
                                            settings: {
                                                ...state.settings,
                                                notificationCreate,
                                            },
                                        }));
                                    }}
                                />
                            </FormControl>

                            <FormControl>
                                <Checkbox
                                    label={localize("SkickaNotifikationerTillSkapareNarFragorBesvaras")}
                                    checked={settings.notificationAnswer}
                                    onChange={(
                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                        notificationAnswer?: boolean
                                    ) => {
                                        this.setState((state) => ({
                                            settings: {
                                                ...state.settings,
                                                notificationAnswer,
                                            },
                                        }));
                                    }}
                                />
                            </FormControl>
                        </FormSection>
                    </form>
                    <FormFooterBar onCancelClick={this.onCancel} onSaveClick={this.onSubmit} />
                </Stack>
            </div>
        );
    }
}

export default withRouter(QASettingsView);
