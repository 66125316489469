import { 
    getFileIconUrl as getSpintrFileIconUrl 
} from "src/files/utils";

export const getFileIconUrl = (item: any): string => {
    if (!item) {
        return getSpintrFileIconUrl(0);
    }

    if (item.isDirectory || (!!item.spintrDriveItem && item.spintrDriveItem.type === 1)) {
        return "/Style/Images/icon-folder.png";
    }

    let fileType = 0;

    if (!!item.spintrDriveItem) {
        fileType = item.spintrDriveItem.fileType && item.spintrDriveItem.fileType > 0 ?
            item.spintrDriveItem.fileType :
            item.spintrDriveItem.type;
    } else {
        fileType = item.fileType && item.fileType > 0 ?
            item.fileType :
            item.type;
    }

    return getSpintrFileIconUrl(fileType);
}