import api from "src/spintr/SpintrApi";

import {
    FETCH_ACTIVE_USER_BASE,
    UPDATE_ACTIVE_USER,
    IFetchActiveUserBaseAction,
    IUpdateActiveUserAction,
    IFetchUnreadEmailsAction,
    FETCH_UNREAD_EMAILS,
    IClearUnreadEmailsAction,
    CLEAR_UNREAD_EMAILS
} from "./action-types"
import { IActiveUserProfile } from "./reducer";

export const getActiveUser = (): IFetchActiveUserBaseAction => ({
    payload: api.get("/api/v1/profile/me"),
    type: FETCH_ACTIVE_USER_BASE,
});

export const updateActiveUser = (user: IActiveUserProfile): IUpdateActiveUserAction => ({
    meta: {
        user
    },
    type: UPDATE_ACTIVE_USER
});

export const getUnreadEmails = (): IFetchUnreadEmailsAction => ({
    payload: api.get("/api/v1/users/emails"),
    type: FETCH_UNREAD_EMAILS
})

export const clearUnreadEmails = (): IClearUnreadEmailsAction => ({
    payload: api.post("/api/v1/users/emails/clearcache"),
    type: CLEAR_UNREAD_EMAILS
})

export const ProfileActions = {
    getActiveuser: getActiveUser,
    getUnreadEmails,
    clearUnreadEmails,
};

export default ProfileActions;