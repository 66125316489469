import mixpanel from "mixpanel-browser";

interface mpEvent {
    s: string;
    properties?: any;
}

let hasBeenInitialized : boolean = false;
let queuedEvents : mpEvent[] = [];

export enum MixPanelEvents {
    Dynamo_AddRow = "Dynamo: Add row",
    Dynamo_AddColumn = "Dynamo: Add column",
    Dynamo_RemoveRow = "Dynamo: Remove row",
    Dynamo_RemoveColumn = "Dynamo: Remove column",
    Dynamo_RenameColumn = "Dynamo: Rename column",
    Dynamo_ClickEmptyColumn = "Dynamo: Click empty column",
    Dynamo_ClickDrawerCategory = "Dynamo: Click drawer category",
    Dynamo_RemoveComponent = "Dynamo: Remove component",
    Dynamo_DropNewComponent = "Dynamo: Drop new component",
    Dynamo_MoveComponent = "Dynamo: Move component",
    Dynamo_OpenDrawer = "Dynamo: Open drawer",
    Dynamo_PreviewDesktop = "Dynamo: Preview desktop",
    Dynamo_PreviewMobile = "Dynamo: Preview mobile",
    Dynamo_EnterEditMode = "Dynamo: Enter edit mode",
    Dynamo_ChangeTheme = "Dynamo: Change theme",
    Dynamo_ClickComponentsSettings = "Dynamo: Click component settings",
    Dynamo_ClickNewsTemplate = "Dynamo: Click news template",
    Dynamo_SaveComponentSettings = "Dynamo: Save component settings",
    Dynamo_CancelComponentSettings = "Dynamo: Cancel component settings",
    Dynamo_ResizeColumn = "Dynamo: Resize column",
}

const isDev = () => {
    const hostname = window.location.hostname;

    return hostname === "localhost"
        || hostname.startsWith("192.168")
        || hostname.startsWith("127.0");
};

export const initializeMixpanel = (email: string, instanceId: any) => {
    if (hasBeenInitialized) {
        return;
    }

    mixpanel.init(isDev() ? "fd291131403a88e11d230b8917bd30fa" : "d5f8017b0489b8a2aa02ac664455133d", {
        loaded: () => {
            mixpanel.identify(email);

            mixpanel.register({
                instanceId: instanceId
            });

            mixpanel.add_group("Instance", instanceId.toString());

            hasBeenInitialized = true;
        }
    });
}

export const mixpanelTrack = (s: string, properties?: any) => {
    if (!s) {
        return;
    }

    if (isDev()) {
        console.log("MixPanel Track: " + s);
    }

    if (!hasBeenInitialized) {
        queuedEvents.push({s, properties});

        return;
    }

    mixpanel.track(s, properties);
}

const sendQueuedEvents = () => {
    if (hasBeenInitialized &&
        !!queuedEvents &&
        queuedEvents.length > 0) {
        for (let queuedEvent of queuedEvents) {
            mixpanelTrack(queuedEvent.s, queuedEvent.properties);
        }

        queuedEvents = [];
    }
}

setInterval(() => {
    sendQueuedEvents();
}, 1000);