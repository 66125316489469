import React, { CSSProperties, useEffect, useState } from "react";
import { localize } from "src/l10n";
import { ComposerStage, IComposerPost, defaultFrameFonts } from "./Visage2Composer";
import { DefaultButton, PrimaryButton, SearchBox, Text } from "@fluentui/react";
import { EditorState } from "draft-js";
import api from "src/spintr/SpintrApi";
import { AxiosResponse } from "axios";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { SpintrTypes } from "src/typings";
import SpintrLoader from "src/ui/components/Loader";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";
import { capitalizeFirstLetter } from "src/utils";

interface IProps {
    onPostUpdate: any;
    post: Spintr.ISocialPostBase;
    setStep: any;
    currentUser?: any;
}

const Visage2ComposerPeoplePicker = (props: IProps) => {
    const [state, setState] = useState({
        prefilledUsers: [],
        users: [],
        selectedUsers: props.post.targetUsers || [],
        isInitialLoading: true,
        isLoading: false,
        searchText: "",
    });

    useEffect(() => {
        api.get("/api/v1/users", {
            params: {
                word: state.searchText,
                unitId: props.currentUser.department.id
            }
        }).then((response) => {
            setState({
                ...state,
                isInitialLoading: false,
                prefilledUsers: response.data.map((item: any) => {
                    return {
                        id: item.id,
                        name: item.name,
                        subText: item.departmentName,
                        image: item.image
                    }
                })
            });
        }).catch(() => {});
    }, [])

    useEffect(() => {
        const isEmptySearch = !state.searchText;
        
        if (isEmptySearch) {
            setState({
                ...state,
                users: state.prefilledUsers
            });

            return;
        }
        
        if (!state.isLoading) {
            setState({
                ...state,
                isLoading: true
            });
        } else {
            return;
        }

        api.get("/api/v1/targets", {
            params: {
                excludeAll: true,
                excludeDepartments: false,
                excludeGroups: true,
                excludeOffices: false,
                excludeEmptyRoles: true,
                excludeRoles: true,
                excludeTargetGroups: true,
                excludeUsers: false,
                excludeCompanies: false,
                excludeSupplierGroups: true,
                searchPhrase: state.searchText,
                shareableGroupsOnly: false,
                skip: 0,
                take: 10,
                includeDeleted: false
            }
        }).then((response) => {
            setState({
                ...state,
                isLoading: false,
                users: response.data.map((item: any) => {
                    return {
                        id: item.id,
                        name: (item.name || "").split(" > ").pop() || "",
                        subText: item.info ?? capitalizeFirstLetter(localize("Ubertype" + item.type + "_0_4")),
                        image: item.imageUrl
                    }
                })
            });
        }).catch(() => {});
    }, [state.searchText, state.prefilledUsers]);

    if (state.isInitialLoading) {
        return (
            <SpintrLoader />
        )
    }

    return (
        <div className="Visage2ComposerPeoplePicker">
            <SpintrSearch
                classes="searchBox"
                value={state.searchText}
                placeholder={localize("Sok") + "..."}
                onClear={() => {
                    setState({
                        ...state,
                        searchText: ""
                    });
                }}
                onChange={(event: React.ChangeEvent, value: string) => {
                    setState({
                        ...state,
                        searchText: value
                    });
                }} />
            <div className="user-lists">
                {state.selectedUsers.length > 0 && (
                    <div className="user-list">
                        <Label>{localize("SELECTED_USERS")}</Label>
                        {state.selectedUsers.map((item: any, index: number) => {
                            return (
                                <UnstyledButton
                                    key={item.id}
                                    title={localize("TaBort")}
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            selectedUsers: state.selectedUsers.filter(x => x.id !== item.id)
                                        });
                                    }}>
                                    <SpintrUser
                                        name={item.name}
                                        subText={item.subText}
                                        imageUrl={item.image}
                                        personalName={true}
                                    />
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            )
                        })}
                    </div>
                )}
                <div className="user-list">
                    <Label>{!!state.searchText ? localize("Anvandare") : localize("SUGGESTIONS")}</Label>
                    {state.users.map((item: any, index: number) => {
                        const isSelected = !!state.selectedUsers.find(x => x.id === item.id);

                        return (
                            <UnstyledButton
                                key={item.id}
                                title={isSelected ? "" : localize("chat_add")}
                                disabled={isSelected}
                                className={isSelected ? "selected" : "not-selected"}
                                onClick={() => {
                                    if (isSelected) {
                                        return;
                                    }

                                    setState({
                                        ...state,
                                        selectedUsers: [
                                            ...state.selectedUsers,
                                            item
                                        ]
                                    });
                                }}>
                                <SpintrUser
                                    name={item.name}
                                    subText={item.subText}
                                    imageUrl={item.image}
                                    personalName={true}
                                />
                                <Visage2Icon icon="tick-circle" />
                            </UnstyledButton>
                        )
                    })}
                </div>
                {state.isLoading && (
                    <SpintrLoader />
                )}
            </div>
            <div className="Visage2ComposerPeoplePicker-footer">
                <DefaultButton style={{marginRight: "12px"}} text={localize("Avbryt")} onClick={() => {
                    props.setStep(ComposerStage.EditingPost);
                }} />
                <PrimaryButton text={localize("CONTINUE")} onClick={() => {
                        props.onPostUpdate({
                            ...props.post,
                            targetUsers: state.selectedUsers
                        });
                    props.setStep(ComposerStage.EditingPost);
                }} />
            </div>
        </div>
    )
    // return (
    //     <div className="Visage2ComposerPeoplePicker">
    //         <FormSection>
    //             <FormControl>
    //                 <FormTokenizedObjectInput
    //                     items={props.post.users}
    //                     types={[SpintrTypes.UberType.User]}
    //                     placeholder={localize("Sok") + "..."}
    //                     label={localize("Anvandare")}
    //                     onChange={(targets) => {
    //                         props.onPostUpdate({
    //                             ...props.post,
    //                             users: targets.map((t) => {
    //                                 return {
    //                                     ...t,
    //                                     id: t.key,
    //                                 };
    //                             })
    //                         });
    //                     }}
    //                 />
    //             </FormControl>
    //         </FormSection>
    //         <div className="Visage2ComposerPeoplePicker-footer">
    //             <PrimaryButton text={localize("CONTINUE")} onClick={() => {
    //                 props.setStep(ComposerStage.EditingPost);
    //             }} />
    //         </div>
    //     </div>
    // )
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(Visage2ComposerPeoplePicker);