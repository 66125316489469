import React, { HTMLAttributes, ReactElement, useMemo } from "react";
import { TextProps as Props } from "./Text.types";

function Text(props: Props): ReactElement {
    const Tag = props.as || "div";
    
    const {
        className,
        color,
        letterSpacing,
        lineHeight,
        size,
        weight,
    } = props;

    const classNames = useMemo(
        () => {
            let cn = "Text";

            if (className) {
                cn += " " + className;
            }

            cn += " fs-" + (size || "bodyMedium");
            if (letterSpacing) {
                cn += ` ls-${letterSpacing}`;
            }

            if (lineHeight) {
                cn += ` lh-${lineHeight}`;
            }

            if (weight) {
                cn += ` fw-${weight}`;
            }

            if (color) {
                cn += ` fc-${color}`;
            }

            return cn;
        },
        [className, color, letterSpacing, lineHeight, size, weight],
    );

    const adjustedProps = useMemo<HTMLAttributes<HTMLElement>>(
        () => {
            const adjustedProps = { ...props };

            delete adjustedProps.as;
            delete adjustedProps.color;
            delete adjustedProps.letterSpacing;
            delete adjustedProps.lineHeight;
            delete adjustedProps.size;
            delete adjustedProps.weight;

            return adjustedProps;
        },
        [props]
    );

    return (
        <Tag {...adjustedProps} className={classNames}>
            {props.children}
        </Tag>
    );
};

export default Text;
