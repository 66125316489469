import api from "src/spintr/SpintrApi";

import { FETCH_OPERATING_INFO, FETCH_OPERATING_INFO_SUPPORT } from "./action-types";

const baseUrl = "/api/v1/operatinginfo";

export interface IFetchOperatingInfoParams {
    showDeleted?: boolean;
    typesConfig?: string;
}

export const fetchOperatingInfo = (params: IFetchOperatingInfoParams) => ({
    payload: api.get(baseUrl, { params }),
    type: FETCH_OPERATING_INFO,
});

export const fetchOperatingInfoSupport = () => ({
    payload: api.get(`${baseUrl}/support`),
    type: FETCH_OPERATING_INFO_SUPPORT,
});
