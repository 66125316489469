import { Stack, StackItem } from "@fluentui/react";
import React from "react";
import { PageHeader } from "src/ui";
import ExpandableFormField from "../ExpandableFormField/ExpandableFormField";
import { FormSection } from "../Forms";
import { localize } from "src/l10n";
import "./SplitForm.scss";

interface IAdditionalSettings {
    title: string;
    content: JSX.Element;
    enabled?: boolean;
    startExpanded?: boolean;
}

interface ILowerAdditionalSettings {
    title: string;
    content: JSX.Element;
    enabled?: boolean;
}

interface IProps {
    children?: any;
    headerTitle: string;
    displayMandatoryText?: boolean;
    expandAllSettings?: boolean;
    additionalSettings?: IAdditionalSettings[];
    lowerAdditionalSettings?: ILowerAdditionalSettings[];
}

const SplitForm = (props: IProps) => {
    return (
        <div className="SplitForm">
            <form>
                <Stack horizontal wrap>
                    <StackItem
                        // grow
                        // disableShrink
                        style={{
                            minWidth: "60%",
                            flexBasis: "60%",
                        }}
                    >
                        <div className="main-form">
                            <PageHeader title={props.headerTitle} displayMandatoryText={props.displayMandatoryText} />

                            {props.children}
                        </div>
                    </StackItem>
                    <StackItem
                        // grow
                        // disableShrink
                        style={{
                            minWidth: "40%",
                            flexBasis: "40%",
                        }}
                    >
                        <div className="additional-settings">
                            <PageHeader title={localize("Installningar")} />
                            {props.additionalSettings
                                ?.filter(
                                    (additionalSetting) =>
                                        additionalSetting.enabled == undefined || additionalSetting.enabled
                                )
                                ?.map((additionalSetting) => (
                                    <ExpandableFormField
                                        title={additionalSetting.title}
                                        startExpanded={props.expandAllSettings || additionalSetting.startExpanded}
                                    >
                                        {additionalSetting.content}
                                    </ExpandableFormField>
                                ))}
                            <div className="lower-additional-settings">
                                {props.lowerAdditionalSettings
                                    ?.filter(
                                        (lowerAdditionalSettings) =>
                                            lowerAdditionalSettings.enabled == undefined || lowerAdditionalSettings.enabled
                                    )
                                    ?.map((lowerAdditionalSettings) => (
                                        <FormSection
                                            title={lowerAdditionalSettings.title}
                                        >
                                            {lowerAdditionalSettings.content}
                                        </FormSection>
                                    ))}
                            </div>
                        </div>
                    </StackItem>
                </Stack>
            </form>
        </div>
    );
};

export default SplitForm;
