import React from "react";
import { localize } from "src/l10n";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog"

export const renderUnsavedChangesDialog = (show, onDismiss, onConfirm) => {
    return (
        <CustomDialog
            message={localize("UnsavedChangesWarning")}
            show={!!show}
            confirmMessage={localize("Ok")}
            onDismiss={onDismiss}
            onConfirm={onConfirm} />
    )
}