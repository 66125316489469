import React from "react";
import { Label, UnstyledButton } from "src/ui";
import "./AcademyCourseSidebarTest.scss";
import { IAcademyChapter, IAcademyCourse, IAcademyLesson, IAcademyTest } from "src/academy/types";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import AcademyCourseSidebarProgressCircleSmall from "../AcademyCourseSidebarProgressCircleSmall/AcademyCourseSidebarProgressCircleSmall";
import RunningLabel from "../RunningLabel/RunningLabel";

interface IProps {
    test: IAcademyTest;
    activeStepId?: number;
    goToTest: (test: IAcademyTest) => void;
}

const AcademyCourseSidebarTest = (props: IProps) => {
    const isActive = props.activeStepId === props.test.id;

    return (
        <UnstyledButton
            className="AcademyCourseSidebarLesson"
            disabled={!props.test.userProgress.isEligable}
            onClick={() => props.goToTest(props.test)}>
            <AcademyCourseSidebarProgressCircleSmall
                isDone={props.test.userProgress.isCompleted}
                isEligable={props.test.userProgress.isEligable}
                isActive={isActive}
                isTest
            />
            <Label
                size="body-2"
                lineThrough={!isActive && props.test.userProgress.isCompleted}
                weight={isActive || props.test.userProgress.isCompleted ? "medium" : undefined}
                color={isActive ? "dark-grey" : "grey"}>
                {props.test.name}
            </Label>
            {isActive && <RunningLabel langTag={"Aktiv"} />}
        </UnstyledButton>
    )
}

export default AcademyCourseSidebarTest;
