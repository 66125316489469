import { PrimaryButton } from "@fluentui/react";
import React, { Component } from "react";
import localize from "src/l10n/localize";
import { Label } from "./Label/Label";
import "./TimeoutInformation.scss";

interface Props {
    reloadFn?: any;
}

interface State {}

class TimeoutInformation extends Component<Props, State> {
    state = {};

    render() {
        return (
            <div className="TimeoutInformation">
                <img style={{ width: 300 }} alt="SJ" src={"/Style/Images/disconnected-plugs.png"} />
                <Label className="ErrorBoundaryContent" size="h3">
                    {localize("LOAD_TIMED_OUT_HEADLINE")}
                </Label>
                <Label className="ErrorBoundaryContent" size="body-2">
                    {localize("LOAD_TIMED_OUT_MESSAGE")}
                </Label>

                {this.props.reloadFn && <PrimaryButton className="ReloadButton" onClick={this.props.reloadFn}>{localize("ForsokIgen")}</PrimaryButton>}
            </div>
        );
    }
}

export default TimeoutInformation;
