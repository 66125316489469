import { Pivot, PivotItem } from "@fluentui/react";
import moment from "moment";
import {
    Checkbox, Dropdown,
    IDropdownOption,
    IDropdownStyles, Image, IStackItemStyles, ITextFieldStyles, PrimaryButton, Separator, Stack, StackItem, TextField, Toggle
} from "@fluentui/react";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { FileSelector } from "src/spintr/components";
import { Loader, PageHeader, PasswordStrengthMeter } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import ConfirmDialog from "src/ui/components/Forms/ConfirmDialog";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { Style } from "src/ui/helpers";
import { capitalizeFirstLetter, scrollToTop } from "src/utils";
import { MandatoryText } from "../ui/components/Forms";
import { getActiveUser } from "./actions";
import "./ProfileSettingsView.scss";
import api from "src/spintr/SpintrApi";
import { MfaWizard } from "./components";

interface IProps {
    instance: any;
    isSmallViewMode?: boolean;
    isUserManager?: boolean;
    currentUser?: IActiveUserProfile;
    sidebarIsExpanded?: boolean;
    history: any;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    profile: any;
    saveError: string[];
    saveSuccess: boolean;
    showCoverImageSelector: boolean;
    showProfileImageSelector: boolean;
    profileImage: string;
    coverImage: string;
    initialLanguage?: any;
    displayReloadDialog?: boolean;
    showConfirmSubmitDialog: boolean;
    uncoverOldPassword?: boolean;
    uncoverNewPassword1?: boolean;
    uncoverNewPassword2?: boolean;
    displayMfaWizard?:          boolean | undefined;
}

const dropdownDateStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 149 }, // 149*2 + 2 margin = width of textfield ⬇
};

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 300 } };

const stackItemStyles: IStackItemStyles = {
    root: {
        marginRight: 16
    },
};

class ProfileSettingsView extends PureComponent<IProps, IState> {
    private dates: IDropdownOption[];
    private months: IDropdownOption[];
    private languages: any[];

    constructor(props) {
        super(props);

        this.dates = [];
        for (var i = 1; i < 32; i++) this.dates.push({ key: i, text: i.toString() });

        this.months = moment.months().map((m, i) => {
            return { key: i, text: m };
        });

        this.languages = [
            {
                key: "1",
                text: "Svenska",
            },
            {
                key: "2",
                text: "Norsk",
            },
            {
                key: "3",
                text: "English",
            },
            {
                key: "4",
                text: "Deutsch",
            },
            {
                key: "5",
                text: "Suomi",
            },
            {
                key: "6",
                text: "Dansk",
            },
            {
                key: "7",
                text: "Italiano"
            },
            {
                key: "8",
                text: "Español"
            },
            {
                key: "9",
                text: "Française"
            }
        ];

        this.state = {
            isLoading: true,
            profile: undefined,
            saveError: [],
            saveSuccess: false,
            showProfileImageSelector: false,
            showCoverImageSelector: false,
            profileImage: this.props.currentUser.images["250x999"],
            coverImage: undefined,
            showConfirmSubmitDialog: false
        };

        this.onMfaWizardClose = this.onMfaWizardClose.bind(this);
    }

    componentDidMount = () => {
        this.setState({ isLoading: true }, () => {
            api.get("/api/v1/profile/settings").then((response) => {
                let profile = response.data;
                if (!profile.dateOfBirth) {
                    profile.showDate = false;
                } else {
                    profile.showDate = true;
                    let date = new Date(profile.dateOfBirth);
                    profile.month = this.months[date.getMonth()].key;
                    profile.date = date.getDate();
                }
                this.setState({ profile, initialLanguage: profile.language, isLoading: false });
            });
        });

        api.get(`/api/v1/profiles/${this.props.currentUser.id}`).then((response) => {
            this.setState({ coverImage: response.data.CoverArt });
        });
    };

    private handleProfileSelectChange = (name, evt, option) => {
        this.setState((state) => ({
            profile: {
                ...state.profile,
                [name]: option.key,
            },
        }));
    };

    public onCancel = () => {
        this.props.history.push(`/profile/${this.props.currentUser.id}`);
    };

    private confirmSubmit = () => {
        this.setState({ showConfirmSubmitDialog: true })
    }

    public onSubmit = () => {
        let { profile } = this.state;

        this.setState({
            isLoading: true,
        });

        if (profile.showDate) {
            let month = profile.month ? profile.month : 0;
            let date = new Date(Date.UTC(1970, month, profile.date));
            profile.dateOfBirth = date;
        } else {
            profile.dateOfBirth = null;
        }

        if (profile.imageTicket) {
            api.put("/api/v1/profiles/image", { ticket: profile.imageTicket }).then(() => {
                this.props.dispatch(getActiveUser());
            });
        }

        if (profile.coverImageTicket) {
            api.put("/api/v1/profiles/coverart", { ticket: profile.coverImageTicket });
        }

        api
            .put("/api/v1/profile", profile)
            .then((_response) => {
                scrollToTop();

                this.props.dispatch(getActiveUser()).then(() => {
                    this.setState({
                        saveSuccess: true,
                        saveError: [],
                        displayReloadDialog: profile.language !== this.state.initialLanguage,
                        isLoading: false,
                    }, () => {
                        if (!this.state.displayReloadDialog) {
                            this.props.history.push(`/profile/${this.props.currentUser.id}`)
                        }
                        
                    });
                })
            })
            .catch((saveError) => {
                scrollToTop();
                this.setState({ saveError: saveError.response.data.errorlist, isLoading: false });
            });
    };

    onDismissDialog = () => {
        this.setState({
            displayReloadDialog: false,
        });
    };

    onConfirmDialog = () => {
        window.location.reload();
    };

    private onChange = (ev, val) => {
        const name = ev.target.name;
        this.setState((prevState) => ({
            profile: {
                ...prevState.profile,
                [name]: val,
            },
        }));
    };

    renderInformation = () => {
        const { profile } = this.state;
        const { handleProfileSelectChange } = this;
        const showEmailField = this.props.instance.get("enableUserEmailEdit");
        return (
            <>
                <FormControl>
                    <Toggle
                        label={localize("VisaFodelsedatum")}
                        checked={profile.showDate}
                        onChange={() => {
                            this.setState((state) => ({
                                profile: { ...state.profile, showDate: !state.profile.showDate },
                            }));
                        }}
                        onText={localize("Ja")}
                        offText={localize("Nej")}
                    />
                </FormControl>


                {profile.showDate && (
                    <Stack tokens={{ childrenGap: 2, padding: 0 }} horizontal verticalAlign="start">
                        <StackItem styles={stackItemStyles}>
                            <FormControl>
                                <Dropdown
                                    label={localize("Fodelsedatum")}
                                    defaultSelectedKey={profile.date}
                                    options={this.dates}
                                    styles={dropdownDateStyles}
                                    onChange={(evt, option) => {
                                        handleProfileSelectChange("date", evt, option);
                                    }}
                                />
                            </FormControl>
                        </StackItem>
                        <StackItem styles={stackItemStyles}>
                            <FormControl>
                                <Dropdown
                                    label="&nbsp;"
                                    selectedKey={profile.month}
                                    options={this.months}
                                    styles={dropdownDateStyles}
                                    onChange={(evt, option) => {
                                        handleProfileSelectChange("month", evt, option);
                                    }}
                                />
                            </FormControl>
                        </StackItem>

                    </Stack>
                )}
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Namn")}
                        value={profile.name}
                        size={100}
                        name="name"
                        onChange={this.onChange}
                    />
                </FormControl>

                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Adress")}
                        value={profile.address}
                        size={100}
                        name="address"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Postnummer")}
                        value={profile.zipCode}
                        size={100}
                        name="zipCode"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Stad")}
                        value={profile.city}
                        size={100}
                        name="city"
                        onChange={this.onChange}
                    />
                </FormControl>

                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Telefonnummer")}
                        value={profile.phoneNumber}
                        name="phoneNumber"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    <Checkbox
                        label={localize("NumretArHemligt")}
                        checked={profile.hideTelephoneNumber}
                        name="hideTelephoneNumber"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Mobilnummer")}
                        value={profile.cellphoneNumber}
                        name="cellphoneNumber"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    <Checkbox
                        label={localize("NumretArHemligt")}
                        checked={profile.hideMobileNumber}
                        name="hideMobileNumber"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    {showEmailField && (



                        <TextField
                            styles={textFieldStyles}
                            label={localize("EpostAdress")}
                            type={"email"}
                            value={profile.emailAddress}
                            name="emailAddress"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("PersonligEpost")}
                        type={"email"}
                        value={profile.personalEmailAddress}
                        name="personalEmailAddress"
                        onChange={this.onChange}
                    />
                </FormControl>

                <FormControl>
                    <Checkbox
                        className="testartestar"
                        label={localize("EpostenArHemlig")}
                        checked={profile.hidePersonalEmailAddress}
                        name="hidePersonalEmailAddress"
                        onChange={this.onChange}
                    />
                </FormControl>

                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("PersonligTelefon")}
                        value={profile.personalPhoneNumber}
                        name="personalPhoneNumber"
                        onChange={this.onChange}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Arbetsuppgifter")}
                        value={profile.duties}
                        name="duties"
                        onChange={this.onChange}
                        multiline={true}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        styles={textFieldStyles}
                        label={localize("Familj")}
                        value={profile.family}
                        name="family"
                        onChange={this.onChange}
                        multiline={true}
                    />
                </FormControl>

            </>
        );
    };

    renderLanguage = () => {
        const { profile } = this.state;

        return (
            <FormControl>
                <Dropdown
                    label={localize("Sprak")}
                    styles={{ dropdown: { width: 300 } }}
                    selectedKey={profile.language}
                    onChange={(event: React.FormEvent<HTMLDivElement>, i: IDropdownOption) => {
                        this.setState((state) => ({
                            profile: { ...state.profile, language: i.key },
                        }));
                    }}
                    options={this.languages}
                />
            </FormControl>

        );
    };

    renderTeamsNotificationSettings = () => {
        return (
            <div>
                <FormSection>
                    <FormControl>
                        <Checkbox
                            label={localize("Nyheter")}
                            checked={this.state.profile.teamsNotificationSettings.enableNewsNotification}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                checked?: boolean
                            ) => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        teamsNotificationSettings: {
                                            ...this.state.profile.teamsNotificationSettings,
                                            enableNewsNotification: checked
                                        }
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Checkbox
                            label={localize("Bloggar")}
                            checked={this.state.profile.teamsNotificationSettings.enableBlogPostNotification}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                checked?: boolean
                            ) => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        teamsNotificationSettings: {
                                            ...this.state.profile.teamsNotificationSettings,
                                            enableBlogPostNotification: checked
                                        }
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Checkbox
                            label={localize("Flode")}
                            checked={this.state.profile.teamsNotificationSettings.enableFeedItemNotification}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                checked?: boolean
                            ) => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        teamsNotificationSettings: {
                                            ...this.state.profile.teamsNotificationSettings,
                                            enableFeedItemNotification: checked
                                        }
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Checkbox
                            label={localize("appEmergencyAlerts")}
                            checked={this.state.profile.teamsNotificationSettings.enableEmergencyAlertNotification}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                checked?: boolean
                            ) => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        teamsNotificationSettings: {
                                            ...this.state.profile.teamsNotificationSettings,
                                            enableEmergencyAlertNotification: checked
                                        }
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Checkbox
                            label={localize("Driftinformation")}
                            checked={this.state.profile.teamsNotificationSettings.enableOperatingInfoNotification}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                checked?: boolean
                            ) => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        teamsNotificationSettings: {
                                            ...this.state.profile.teamsNotificationSettings,
                                            enableOperatingInfoNotification: checked
                                        }
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    {/* <FormControl>
                        <Checkbox
                            label={localize("appBirthdays")}
                            checked={this.state.profile.teamsNotificationSettings.enableBirthdayNotification}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                checked?: boolean
                            ) => {
                                this.setState({
                                    profile: {
                                        ...this.state.profile,
                                        teamsNotificationSettings: {
                                            ...this.state.profile.teamsNotificationSettings,
                                            enableBirthdayNotification: checked
                                        }
                                    }
                                });
                            }}
                        />
                    </FormControl> */}
                </FormSection>
            </div>
        )
    }

    renderNotifications = () => {
        const { profile } = this.state;
        const enableSMS = this.props.instance.get("enableSms");

        return (
            <>
                <FormControl label={localize("NarDuBlirTaggadIEttFoto")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyTagByEmail}
                        name="notifyTagByEmail"
                        onChange={this.onChange}
                    />
                </FormControl>

                {enableSMS && (
                    <FormControl>
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyTagBySms}
                            name="notifyTagBySms"
                            onChange={this.onChange}
                        />
                    </FormControl>

                )}
                <FormControl label={localize("NarNagonPostarIDinProfil")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyProfilePostByEmail}
                        name="notifyProfilePostByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyProfilePostBySms}
                            name="notifyProfilePostBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>


                <FormControl label={localize("NärNagonSkickarEttMeddelandeTillDig")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyMessageByEmail}
                        name="notifyMessageByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyMessageBySms}
                            name="notifyMessageBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>

                <FormControl label={localize("NarNagonKommenterarNagotSomDuHarPostat")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyCommentByEmail}
                        name="notifyCommentByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyCommentBySms}
                            name="notifyCommentBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>

                <FormControl label={localize("NarNagonGillarNagotSomDuHarPostat")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyLikeByEmail}
                        name="notifyLikeByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyLikeBySms}
                            name="notifyLikeBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>

                <FormControl label={localize("NarNagonTaggarDigIEnUppdatering")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyMentionByEmail}
                        name="notifyMentionByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyMentionBySms}
                            name="notifyMentionBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>

                <FormControl label={localize("NarNagotHanderPaInnehallDuFoljer")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyUpdatesByEmail}
                        name="notifyUpdatesByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyUpdatesBySms}
                            name="notifyUpdatesBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>

                <FormControl label={localize("NarDuBlirInbjudenTillEnKalenderhandelse")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyEventInvitesByEmail}
                        name="notifyEventInvitesByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyEventInvitesBySms}
                            name="notifyEventInvitesBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>
                <FormControl label={localize("NarEnFragaStallsSomDuTrosKunnaSvaretPa")}>
                    <Checkbox
                        label={localize("Epost")}
                        checked={profile.notifyQuestionsByEmail}
                        name="notifyQuestionsByEmail"
                        onChange={this.onChange}
                    />
                    {enableSMS && (
                        <Checkbox
                            label={localize("SMS")}
                            checked={profile.notifyQuestionsBySms}
                            name="notifyQuestionsBySms"
                            onChange={this.onChange}
                        />
                    )}
                </FormControl>
                <Separator />
                <FormControl>
                    <Toggle
                        label={localize("VisaNotisKort")}
                        checked={profile.notificationCardsEnabled}
                        onChange={() => {
                            this.setState((state) => ({
                                profile: {
                                    ...state.profile,
                                    notificationCardsEnabled: !state.profile.notificationCardsEnabled,
                                },
                            }));
                        }}
                        onText={localize("Ja")}
                        offText={localize("Nej")}
                    />
                </FormControl>

            </>
        );
    };

    renderChangePassword = () => {
        const { profile } = this.state;

        const hasEnabledMfa = (profile?.enabledAuthMechanisms || []).indexOf(1) !== -1;
        console.log("renderChangePassword", hasEnabledMfa);

        return (
            <>
                <FormSection title={localize("AndraLosenord")}>
                    <FormControl>
                        <TextField
                            type={"password"}
                            styles={textFieldStyles}
                            label={localize("DittNuvarandeLosenord")}
                            value={profile.oldpassword}
                            name="oldpassword"
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            type={"password"}
                            styles={textFieldStyles}
                            label={localize("DittNyaLosenord")}
                            value={profile.newpassword}
                            name="newpassword"
                            onChange={this.onChange}
                        />
                        <div className="strength-meter">
                            <PasswordStrengthMeter
                                password={profile.newpassword}
                            />
                        </div>
                    </FormControl>
                    <FormControl>
                        <TextField
                            type={"password"}
                            styles={textFieldStyles}
                            label={localize("RepeteraDittNyaLosenord")}
                            value={profile.newpassword2}
                            name="newpassword2"
                            onChange={this.onChange}
                        />
                    </FormControl>
                </FormSection>
                {this.props.instance.get("enableMfa") && (
                    <FormSection title={localize("MULTI_FACTOR_AUTHENTICATION")}>
                        <FormControl>
                            {!hasEnabledMfa && (
                                <PrimaryButton onClick={() => this.setState({ displayMfaWizard: true })}>
                                    {localize("LaggTill")}
                                </PrimaryButton>
                            )}
                            {hasEnabledMfa && (
                                <div className="totp-mfa-enabled">
                                    {localize("TOTP_MFA_ACTIVE")}
                                </div>
                            )}
                        </FormControl>
                    </FormSection>
                )}
            </>
        );
    };

    renderSound = () => {
        const { profile } = this.state;
        return (
            <>
                <FormControl>
                    <Toggle
                        label={localize("Notisljud")}
                        checked={profile.ntfnSoundEnabled}
                        onChange={() => {
                            this.setState((state) => ({
                                profile: { ...state.profile, ntfnSoundEnabled: !state.profile.ntfnSoundEnabled },
                            }));
                        }}
                        onText={capitalizeFirstLetter(localize("Pa"))}
                        offText={localize("Av_off")}
                    />
                </FormControl>

            </>
        );
    };

    renderSelectImage = () => {
        const { showProfileImageSelector, showCoverImageSelector, profileImage, coverImage } = this.state;

        return (
            <>
                <FormControl label={localize("Profilbild")}>
                    <PrimaryButton
                        onClick={() => {
                            this.setState({ showProfileImageSelector: true });
                        }}
                    >
                        {localize("ValjBild")}
                    </PrimaryButton>
                </FormControl>

                <Stack>
                    {profileImage && (
                        <>
                            <Image src={profileImage} className="profile-image" />
                            <FormControl>
                                <PrimaryButton
                                    style={{ marginTop: Style.getSpacingStr(3) }}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                profileImage: null,
                                                profile: {
                                                    ...this.state.profile,
                                                    imageTicket: null,
                                                },
                                            },
                                            () => {
                                                api.delete("/api/v1/profiles/image").then(() => {
                                                    this.props.dispatch(getActiveUser());
                                                });
                                            }
                                        );
                                    }}
                                >
                                    {localize("TaBortNuvarande")}
                                </PrimaryButton>
                            </FormControl>
                        </>
                    )}
                    {showProfileImageSelector && (
                        <FileSelector
                            renderSizeString={() => {
                                return (
                                    `${localize(
                                        "OptimalBildStorlek"
                                    )}: 100x100px <br />`
                                )
                            }}
                            cropImage //FIXME: Image proportions
                            cropShape="round"
                            cropAspect={1}
                            onClose={() => {
                                this.setState({
                                    showProfileImageSelector: false,
                                });
                            }}
                            onSelect={(data) => {
                                this.setState((state) => ({
                                    showProfileImageSelector: false,
                                    profileImage: data[0].thumbnailUrl,
                                    profile: {
                                        ...state.profile,
                                        imageTicket: data[0].ticket,
                                    },
                                }));
                            }}
                            allowMultipleFiles={false}
                            fileTypesString={"image/png, image/jpeg"}
                            fileTypes={["png", "jpeg", "jpg"]}
                            sourcesToDisplay={[0]}
                            fileUploadType={1}
                        />
                    )}
                </Stack>

                <Stack>
                    <FormControl label={localize("Omslagsbild")}>
                        <PrimaryButton
                            onClick={() => {
                                this.setState({ showCoverImageSelector: true });
                            }}
                        >
                            {localize("ValjBild")}
                        </PrimaryButton>
                    </FormControl>
                    {coverImage && (
                        <>
                            <Image className="coverImagePreview" src={coverImage} />
                            <FormControl>
                                <PrimaryButton
                                    style={{ marginTop: Style.getSpacingStr(3) }}
                                    onClick={() => {
                                        this.setState({ coverImage: null }, () => {
                                            api.delete("/api/v1/profiles/coverart");
                                        });
                                    }}
                                >
                                    {localize("TaBortNuvarande")}
                                </PrimaryButton>
                            </FormControl>
                        </>
                    )}
                    {showCoverImageSelector && (
                        <FileSelector
                            renderSizeString={() => {
                                return (
                                    `${localize(
                                        "OptimalBildStorlek"
                                    )}: 1300x175px <br />`
                                )
                            }}
                            cropImage
                            cropAspect={980 / 175}
                            onClose={() => {
                                this.setState({
                                    showCoverImageSelector: false,
                                });
                            }}
                            onSelect={(data) => {
                                this.setState((state) => ({
                                    showCoverImageSelector: false,
                                    coverImage: data[0].thumbnailUrl,
                                    profile: {
                                        ...state.profile,
                                        coverImageTicket: data[0].ticket,
                                    },
                                }));
                            }}
                            allowMultipleFiles={false}
                            fileTypesString={"image/png, image/jpeg"}
                            fileTypes={["png", "jpeg", "jpg"]}
                            sourcesToDisplay={[0]}
                            fileUploadType={1}
                        />
                    )}
                </Stack>
            </>
        );
    };

    onMfaWizardClose(success: boolean): void {
        console.log("onMfaWizardClose1", success, this.state.profile);

        this.setState((state) => ({
            ...state,
            displayMfaWizard: false,
            profile: success ? {
                ...state.profile,
                enabledAuthMechanisms: (state.profile.enabledAuthMechanisms || []).length === 0
                    ? [1]
                    : [...state.profile.enabledAuthMechanisms, 1],
            } : state.profile,
        }), () => {
            console.log("onMfaWizardClose2", this.state.profile);
        });
    }

    render() {
        const { isLoading, saveSuccess, saveError } = this.state;
        if (isLoading) return <Loader />;

        return (
            <div>
                <Helmet>
                    <title>{localize("Profilinstallningar")}</title>
                </Helmet>

                <MfaWizard
                    onClose={this.onMfaWizardClose}
                    show={this.state.displayMfaWizard}
                />

                <ConfirmDialog
                    show={this.state.showConfirmSubmitDialog}
                    title={localize("AndraInnehall")}
                    message={localize("ArDuSakerPaAttDuVillX").replace(
                        "{{X}}",
                        localize("Spara").toLowerCase()
                    )
                    }
                    onDismiss={() => {
                        this.setState({ showConfirmSubmitDialog: false });
                    }}
                    onConfirm={() => {
                        this.setState({ showConfirmSubmitDialog: false }, () => {
                            this.onSubmit();
                        });
                    }}
                />

                <div className="profile-settings page-margin-bottom">
                    <PageHeader title={localize("Profilinstallningar")} />
                    {saveSuccess && (
                        <SuccessMessagebar
                            message={localize("SparningenLyckades")}
                            onDismiss={() => {
                                this.setState({ saveSuccess: false });
                            }}
                        />
                    )}
                    {saveError && saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({ saveError: [] });
                            }}
                        />
                    )}
                    <MandatoryText />
                    <form>
                        <Pivot overflowBehavior={"menu"}>
                            <PivotItem headerText={localize("Information")}>{this.renderInformation()}</PivotItem>
                            <PivotItem headerText={localize("Profilbild")}>{this.renderSelectImage()}</PivotItem>
                            <PivotItem headerText={localize("ValjSprak")}>{this.renderLanguage()}</PivotItem>
                            <PivotItem headerText={localize("Aviseringar")}>{this.renderNotifications()}</PivotItem>
                            <PivotItem headerText={localize("SECURITY")}>{this.renderChangePassword()}</PivotItem>
                            <PivotItem headerText={localize("Ljud")}>{this.renderSound()}</PivotItem>
                            {
                                !!this.state.profile.teamsNotificationSettings && (
                                    <PivotItem headerText={(localize("Notisinstallningar") + " Teams")}>{this.renderTeamsNotificationSettings()}</PivotItem>
                                )
                            }
                        </Pivot>
                    </form>
                    <FormFooterBar onCancelClick={this.onCancel} onSaveClick={this.confirmSubmit} />
                    <CustomDialog
                        message={localize("LaddarOmForAttSeAndringar")}
                        show={this.state.displayReloadDialog}
                        confirmMessage={localize("RELOAD")}
                        onDismiss={this.onDismissDialog}
                        onConfirm={this.onConfirmDialog}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const roles = state.profile.active.roles;

    return {
        ...props,
        currentUser: state.profile.active,
        instance: state.instance,
        isSmallViewMode: state.ui.isSmallViewMode,
        isUserManager: roles.includes("usermanager") && !state.profile.active.isAdmin,
        sidebarIsExpanded: state.ui.sidebarExpanded,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(ProfileSettingsView));
