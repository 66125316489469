import { Action } from "redux";
import { IVisageSidebarMenuItem, VisageSidebarMode, VisageSidebarDrawerId } from "./reducer";

export enum SidebarActionTypes {
    SetSidebarItems = "SET_SIDEBAR_ITEMS",
    SetItemExpanded = "SET_SIDEBAR_ITEM_EXPANDED",
    SetItemChildren = "SET_SIDEBAR_ITEM_CHILDREN",
    ExpandToActiveItem = "EXPAND_TO_ACTIVE_ITEM",
    SetSidebarMode = "SET_SIDEBAR_MODE",
    SetSidebarDrawerId = "SET_SIDEBAR_DRAWER_ID",
    SetMenu = "SET_MENU",
    SetDisplaySearch = "SET_DISPLAY_SEARCH",
    SetDisplayBackButton = "SET_DISPLAY_BACK_BUTTON"
}

export interface ISetSidebarItems extends Action {
    items: IVisageSidebarMenuItem[];
    key?: string;
    type: SidebarActionTypes.SetSidebarItems;
}

export interface ISetItemExpanded extends Action {
    key: string;
    value: boolean;
    isLoading?: boolean;
    type: SidebarActionTypes.SetItemExpanded;
}

export interface ISetItemChildren extends Action {
    key: string;
    items: IVisageSidebarMenuItem[];
    type: SidebarActionTypes.SetItemChildren;
}

export interface IExpandToActiveItem extends Action {
    type: SidebarActionTypes.ExpandToActiveItem
}

export interface ISetSidebarMode extends Action {
    mode: VisageSidebarMode,
    type: SidebarActionTypes.SetSidebarMode
}

export interface ISetSidebarDrawerId extends Action {
    value: VisageSidebarDrawerId,
    type: SidebarActionTypes.SetSidebarDrawerId
}

export interface ISetMenu extends Action {
    items: any[];
    type: SidebarActionTypes.SetMenu
}

export interface ISetDisplaySearch extends Action {
    value: boolean;
    type: SidebarActionTypes.SetDisplaySearch
}

export interface ISetDisplayBackButton extends Action {
    value: boolean;
    type: SidebarActionTypes.SetDisplayBackButton
}

export type SideBarAction =
    | ISetSidebarItems
    | ISetItemExpanded
    | ISetItemChildren
    | IExpandToActiveItem
    | ISetSidebarMode
    | ISetSidebarDrawerId
    | ISetMenu
    | ISetDisplaySearch
    | ISetDisplayBackButton;