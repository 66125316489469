const getTargetForLink = (s: string) => {
    const isUrlSameSite = (url) => {
        const matches = url.match(/^(https?:)?\/\/([^\/]+)/);

        if (!matches) {
            return true;
        }

        return window.location.host === matches[2];
    }

    const getTargetFromUrl = (url) => {
        return isUrlSameSite(url) ? "_self" : "_blank";
    }

    return getTargetFromUrl(s);
};

export default getTargetForLink;