import {
    AddToFavoruteAction,
    RemoveFavoriteAction,
    StartFollowingObjectAction,
    StopFollowingObjectAction,
} from "src/spintr/actions";

export const FETCH_INPAGE_CALENDAR =  "FETCH_INPAGE_CALENDAR";

export const FETCH_INPAGE_CALENDAR_PENDING = "FETCH_INPAGE_CALENDAR_PENDING";
interface IFetchInPageCalendarPendingAction {
    type: typeof FETCH_INPAGE_CALENDAR_PENDING;
    meta: { params: IFetchInPageCalendarParams };
}

export const FETCH_INPAGE_CALENDAR_REJECTED = "FETCH_INPAGE_CALENDAR_REJECTED";
interface IFetchInPageCalendarRejectedAction {
    type: typeof  FETCH_INPAGE_CALENDAR_REJECTED;
    meta: { params: IFetchInPageCalendarParams };
}

export const FETCH_INPAGE_CALENDAR_FULFILLED = "FETCH_INPAGE_CALENDAR_FULFILLED";
export interface IFetchInPageCalendarFulfilledAction extends Spintr.IAsyncAction<Spintr.ICalendarEvent[]> {
    type: typeof FETCH_INPAGE_CALENDAR_FULFILLED;
    meta: { params: IFetchInPageCalendarParams };
}

export interface IFetchInPageCalendarParams {
    eventCategory?: number;
    relativeExternalSkip?: number;
    relativeSpintrSkip?: number;
    take?: number;
    calendarId?: number;
    after?: Date;
}

export type FetchInPageCalendarAction =
    | IFetchInPageCalendarPendingAction
    | IFetchInPageCalendarRejectedAction
    | IFetchInPageCalendarFulfilledAction;
    
export const FETCH_CALENDAR_SIDEBAR = "FETCH_CALENDAR_SIDEBAR";

export const FETCH_CALENDAR_SIDEBAR_PENDING = "FETCH_CALENDAR_SIDEBAR_PENDING";
interface IFetchCalendarSidebarPendingAction {
    type: typeof FETCH_CALENDAR_SIDEBAR_PENDING;
    meta: { params: IFetchCalendarSidebarParams };
}

export const FETCH_CALENDAR_SIDEBAR_REJECTED = "FETCH_CALENDAR_SIDEBAR_REJECTED";
interface IFetchCalendarSidebarRejectedAction {
    type: typeof FETCH_CALENDAR_SIDEBAR_REJECTED;
    meta: { params: IFetchCalendarSidebarParams };
}

export interface IFetchCalendarSidebarParams {
    eventCategory?: number;
    relativeExternalSkip?: number;
    relativeSpintrSkip?: number;
    take?: number;
}

export const FETCH_CALENDAR_SIDEBAR_FULFILLED = "FETCH_CALENDAR_SIDEBAR_FULFILLED";
export interface IFetchCalendarSidebarFulfilledAction extends Spintr.IAsyncAction<Spintr.ICalendarEvent[]> {
    type: typeof FETCH_CALENDAR_SIDEBAR_FULFILLED;
    meta: { params: IFetchCalendarSidebarParams };
}

export type FetchCalendarSidebarAction =
    | IFetchCalendarSidebarPendingAction
    | IFetchCalendarSidebarRejectedAction
    | IFetchCalendarSidebarFulfilledAction;

export const SET_CALENDAR_POPUP = "SET_CALENDAR_POPUP";

export interface ISetCalendarPopupParams {
    isOpen: boolean;
    eventId?: number;
    startDate?: Date;
    endDate?: Date;
    editMode?: boolean;
    event?: any;
    preSelectedCalendarId?: number;
    hideCalendarSelector?: boolean;
    onSave?: (event: any) => void;
    externalId?: string;
}
export interface ISetCalendarPopupAction {
    eventId: number;
    isOpen: boolean;
    startDate: Date;
    endDate: Date;
    editMode: boolean;
    event: any;
    preSelectedCalendarId: number;
    hideCalendarSelection: boolean;
    onSave?: (event: any) => void;
    externalId?: string;
    type: typeof SET_CALENDAR_POPUP;
}

export default CalendarAction;

export const SET_ACTIVE_CALENDAR = "SET_ACTIVE_CALENDAR";

export interface ISetActiveCalendarAction {
    id: number;
    type: typeof SET_ACTIVE_CALENDAR;
}

export const SET_CALENDAR_COLOR = "SET_CALENDAR_COLOR";
export const SET_CALENDAR_COLOR_PENDING = "SET_CALENDAR_COLOR_PENDING";

interface ISetCalendarColorPendingAction {
    meta: any;
    type: typeof SET_CALENDAR_COLOR_PENDING;
}

export type SetCalendarColorAction = ISetCalendarColorPendingAction;

interface IJoinEventAction {
    meta: any;
    type: typeof JOIN_CALENDAR_EVENT;
}

interface ILeaveEventAction {
    meta: any;
    type: typeof LEAVE_CALENDAR_EVENT;
}

export type JoinEventAction = IJoinEventAction;
export type LeaveEventAction = ILeaveEventAction;

export const CLEAR_ERROR_LIST = "CLEAR_ERROR_LIST";

export interface IClearErrorListAction {
    type: typeof CLEAR_ERROR_LIST;
}

export const FETCH_CALENDAR = "FETCH_CALENDAR";
export const FETCH_CALENDAR_PENDING = "FETCH_CALENDAR_PENDING";
export const FETCH_CALENDAR_REJECTED = "FETCH_CALENDAR_REJECTED";
export const FETCH_CALENDAR_FULFILLED = "FETCH_CALENDAR_FULFILLED";

interface IFetchCalendarPendingAction {
    type: typeof FETCH_CALENDAR_PENDING;
}

interface IFetchCalendarRejectedAction {
    type: typeof FETCH_CALENDAR_REJECTED;
}

interface IFetchCalendarFulfilledAction extends Spintr.IAsyncAction<Spintr.ICalendarEvent[]> {
    type: typeof FETCH_CALENDAR_FULFILLED;
    //  meta: { params: IFetchCalendarSidebarParams };
}

// interface IFetchCalendarFulfilledAction {
//     type: typeof FETCH_CALENDAR_FULFILLED;
// }

export type FetchCalendarAction =
    | IFetchCalendarPendingAction
    | IFetchCalendarRejectedAction
    | IFetchCalendarFulfilledAction;

export interface IFetchCalendarParams {
    id: number;
    start: number;
    end: number;
    externalId?: string;
}

export const CREATE_CALENDAR_EVENT = "CREATE_CALENDAR_EVENT";
export const CREATE_CALENDAR_EVENT_PENDING = "CREATE_CALENDAR_EVENT_PENDING";
export const CREATE_CALENDAR_EVENT_REJECTED = "CREATE_CALENDAR_EVENT_REJECTED";
export const CREATE_CALENDAR_EVENT_FULFILLED = "CREATE_CALENDAR_EVENT_FULFILLED";

interface ICreateCalendarEventPendingAction {
    type: typeof CREATE_CALENDAR_EVENT_PENDING;
}

interface ICreateCalendarEventRejectedAction {
    type: typeof CREATE_CALENDAR_EVENT_REJECTED;
}

interface ICreateCalendarEventFulfilledAction extends Spintr.IAsyncAction<any> {
    type: typeof CREATE_CALENDAR_EVENT_FULFILLED;
    //  meta: { params: IFetchCalendarSidebarParams };
}

export type CreateCalendarEventAction =
    | ICreateCalendarEventPendingAction
    | ICreateCalendarEventRejectedAction
    | ICreateCalendarEventFulfilledAction;

export const UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT";
export const UPDATE_CALENDAR_EVENT_PENDING = "UPDATE_CALENDAR_EVENT_PENDING";
export const UPDATE_CALENDAR_EVENT_REJECTED = "UPDATE_CALENDAR_EVENT_REJECTED";
export const UPDATE_CALENDAR_EVENT_FULFILLED = "UPDATE_CALENDAR_EVENT_FULFILLED";

interface IUpdateCalendarEventPendingAction {
    type: typeof UPDATE_CALENDAR_EVENT_PENDING;
}

interface IUpdateCalendarEventRejectedAction {
    type: typeof UPDATE_CALENDAR_EVENT_REJECTED;
}

interface IUpdateCalendarEventFulfilledAction extends Spintr.IAsyncAction<any> {
    type: typeof UPDATE_CALENDAR_EVENT_FULFILLED;
    //  meta: { params: IFetchCalendarSidebarParams };
}

export type UpdateCalendarEventAction =
    | IUpdateCalendarEventPendingAction
    | IUpdateCalendarEventRejectedAction
    | IUpdateCalendarEventFulfilledAction;

export const UPDATE_CALENDAR_EVENT_REALTIME = "UPDATE_CALENDAR_EVENT_REALTIME";

interface IUpdateCalendarEventRealtimeAction {
    meta: { event: Spintr.ICalendarEventUpdatedMessage };
    type: typeof UPDATE_CALENDAR_EVENT_REALTIME;
}

export type UpdateCalendarEventRealtimeAction = IUpdateCalendarEventRealtimeAction;

export const TOGGLE_CALENDAR_EVENT_DELETED = "TOGGLE_CALENDAR_EVENT_DELETED";
export const TOGGLE_CALENDAR_EVENT_DELETED_PENDING = "TOGGLE_CALENDAR_EVENT_DELETED_PENDING";
export const TOGGLE_CALENDAR_EVENT_DELETED_REJECTED = "TOGGLE_CALENDAR_EVENT_DELETED_REJECTED";
export const TOGGLE_CALENDAR_EVENT_DELETED_FULFILLED = "TOGGLE_CALENDAR_EVENT_DELETED_FULFILLED";

interface IToggleCalendarEventDeletedPendingAction {
    type: typeof TOGGLE_CALENDAR_EVENT_DELETED_PENDING;
}

interface IToggleCalendarEventDeletedRejectedAction {
    type: typeof TOGGLE_CALENDAR_EVENT_DELETED_REJECTED;
}

interface IToggleCalendarEventDeletedFulfilledAction extends Spintr.IAsyncAction<any> {
    type: typeof TOGGLE_CALENDAR_EVENT_DELETED_FULFILLED;
    //  meta: { params: IFetchCalendarSidebarParams };
}

export type ToggleCalendarEventDeletedAction =
    | IToggleCalendarEventDeletedPendingAction
    | IToggleCalendarEventDeletedRejectedAction
    | IToggleCalendarEventDeletedFulfilledAction;

export const FETCH_CALENDAR_EVENT = "FETCH_CALENDAR_EVENT";
export const FETCH_CALENDAR_EVENT_PENDING = "FETCH_CALENDAR_EVENT_PENDING";
export const FETCH_CALENDAR_EVENT_REJECTED = "FETCH_CALENDAR_EVENT_REJECTED";
export const FETCH_CALENDAR_EVENT_FULFILLED = "FETCH_CALENDAR_EVENT_FULFILLED";

interface IFetchCalendarEventPendingAction {
    type: typeof FETCH_CALENDAR_EVENT_PENDING;
}

interface IFetchCalendarEventRejectedAction {
    type: typeof FETCH_CALENDAR_EVENT_REJECTED;
}

interface IFetchCalendarEventFulfilledAction extends Spintr.IAsyncAction<Spintr.ICalendarEvent> {
    type: typeof FETCH_CALENDAR_EVENT_FULFILLED;
}

export const JOIN_CALENDAR_EVENT = "JOIN_CALENDAR_EVENT"
export const LEAVE_CALENDAR_EVENT = "LEAVE_CALENDAR_EVENT"

export type FetchCalendarEventAction =
    | IFetchCalendarEventPendingAction
    | IFetchCalendarEventRejectedAction
    | IFetchCalendarEventFulfilledAction;

export type CalendarAction =
    | FetchCalendarSidebarAction
    | FetchCalendarAction
    | ISetCalendarPopupAction
    | CreateCalendarEventAction
    | UpdateCalendarEventAction
    | ISetActiveCalendarAction
    | IClearErrorListAction
    | SetCalendarColorAction
    | ToggleCalendarEventDeletedAction
    | FetchCalendarEventAction
    | UpdateCalendarEventRealtimeAction
    | AddToFavoruteAction
    | RemoveFavoriteAction
    | StartFollowingObjectAction
    | StopFollowingObjectAction
    | JoinEventAction
    | LeaveEventAction
    | FetchInPageCalendarAction;