import { Icon, IconButton } from "@fluentui/react";
import React, { Component } from "react";
import "./ExpandableBox.scss";
import classNames from "classnames";
import { IApplicationState } from "src/spintr/reducer";
import { connect } from 'react-redux';
import { Label } from "../..";
import { Style } from "../../helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    isExpandable: boolean;
    isExpandedByDefault: boolean;
    title: string;
    children: any;
}

interface IState {
    showContent: boolean;
}

class ExpandableBox extends Component<IProps, IState> {
    state = {
        showContent: this.props.isExpandedByDefault,
    };

    public render() {
        return (
            <div
                className={classNames("ExpandableFieldWrapper", {
                    ["isExpanded"]: this.state.showContent,
                })}
            >
                <div className="ExpandableBox">
                    <div
                        className={classNames("header", {
                            ["isExpanded"]: this.state.showContent,
                        })}
                        onClick={() => {
                            this.setState({ showContent: !this.state.showContent });
                        }}
                    >
                        <Label size="h3" title={this.props.title}>{this.props.title}</Label>
                        <Visage2Icon icon={this.state.showContent ? "arrow-up-3" : "arrow-down-3"} color="white" />
                    </div>

                    {this.state.showContent &&
                        <div
                            className="TinyFormattedContent isExpanded"
                            //dangerouslySetInnerHTML={{ __html: this.props.children }}

                        >{this.props.children}</div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        instanceColor: state.instance.get("color"),
    };
};

export default connect(mapStateToProps)(ExpandableBox);