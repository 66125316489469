import { PrimaryButton, Text, TextField } from "@fluentui/react";
import React, { ChangeEvent, MouseEvent, ReactNode } from "react";

import { localize } from "src/l10n";
import { extractVariableNames, insertVariables } from "src/marketplace/utils";
import { 
    fieldValidation,
    FieldValidator,
    required,
    validateModel,
} from "src/utils/validation";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

//import "./OAuth2ClientCredentialsInput.scss";

class OAuth1ClientCredentialsInput extends AuthenticationDataComponent<any, any> {
    private instructions: string = localize("OAUTH1_CLIENT_CREDENTIALS_INSTRUCTIONS");
    private grantConsentText: string = localize("OAUTH1_GRANT_CONSENT");
    private popup: Window | null = null;
    private popupInterval: number | null = null;
    private formValidation: FieldValidator[] = [];

    constructor(props: any) {
        super(props);

        this.popup = null;

        this.state = {
            consentGranted: false,
            data: {
                variables: {}
            },
            errorMessages: {},
            oauth2Data: {},
            instructions: undefined,
        };

        this.onGrantConsentClicked = this.onGrantConsentClicked.bind(this);
        this.onVariableChanged = this.onVariableChanged.bind(this);
    }

    public componentDidMount(): void {
        const data: any = JSON.parse(this.props.authenticationData);
        
        this.setState({
            instructions: data.instructions,
            oauth2Data: data
        }, () => {
            const variables = this.getVariables();

            this.formValidation = variables.map(variable => fieldValidation(
                variable.name,
                variable.label,
                required()
            ))
        });
    }

    public componentWillUnmount() {
        if (this.popupInterval !== null) {
            clearInterval(this.popupInterval);
            this.popupInterval = null;
        }

        if (this.popup !== null) {
            if (!this.popup.closed) {
                this.popup.close();
            }

            this.popup = null;
        }
    }

    public getAuthenticationData(): string {
        return JSON.stringify(this.state.data);
    }
    
    public isValid(): boolean {
        return this.state.data.oauthToken && this.state.data.oauthTokenSecret;
    }

    public onGrantConsentClicked(event: MouseEvent<HTMLButtonElement>): void {
        if (!this.isConsentGrantable()) {
            this.isValid();
            return;
        }

        const url = this.state.oauth2Data.consentEndpoint + "?appId=" + this.props.applicationId;

        this.popup = window.open(
            url,
            "SpintrAppOAuth2",
            [
                "height=600",
                "location=no",
                "menubar=no",
                "resizable=no",
                "toolbar=no",
                "width=1200"
            ].join(",")
        );

        if (!this.popup) {
            // TODO: Display popup blocked warning
            return;
        }
        
        if (this.popupInterval !== null) {
            clearInterval(this.popupInterval);
            this.popupInterval = null;
        }

        const locationChecker: TimerHandler = () => {
            if (!this.popup) {
                if (this.popupInterval) {
                    clearInterval(this.popupInterval);
                    this.popupInterval = null;
                }
                return;
            }

            if (this.popup.closed) {
                clearInterval(this.popupInterval);
                this.popupInterval = null;
                return;
            }

            try {
                if (
                    window.location.protocol !== this.popup.location.protocol &&
                    window.location.host !== this.popup.location.host
                ) {
                    return;
                }

                var html = this.popup.document.querySelector("pre").innerHTML;
                var json = JSON.parse(html);

                if (!json) {
                    return;
                }

                clearInterval(this.popupInterval);
                this.popupInterval = null;
                this.popup.close();
                this.popup = null;

                this.setState({
                    data: {
                        oauthToken: json.oauthToken,
                        oauthTokenSecret: json.oauthTokenSecret,
                    },
                    consentGranted: json.oauthToken && json.oauthTokenSecret,
                }, () => {
                    this.isValid();

                    if (typeof this.props.onChange === "function") {
                        this.props.onChange();
                    }
                });

            } catch (err) {
                /* Most likely security error due to X-origin object */
                console.log(err);
            }
        };

        this.popupInterval = setInterval(locationChecker, 100);
    }

    public onVariableChanged(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;

        this.setState({
            data: {
                ...this.state.data,
                variables: {
                    ...this.state.data.variables,
                    [name]: value
                }
            }
        }, () => {
            this.isValid();

            if (typeof this.props.onChange === "function") {
                this.props.onChange();
            }
        });
    }
    
    public render(): ReactNode {
        const variables = this.getVariables();
        const instructions = this.state.instructions || this.instructions;

        return (
            <div className="OAuth2ClientCredentialsInput">
                <p
                    className="instructions"
                    dangerouslySetInnerHTML={{
                        __html: instructions
                    }}
                />
                {!this.state.consentGranted ? (
                    <PrimaryButton
                        className="consent-button"
                        as="button"
                        disabled={!this.isConsentGrantable()}
                        onClick={this.onGrantConsentClicked}
                    >
                        {this.grantConsentText}
                    </PrimaryButton>
                ) : (
                    <Text as="p">
                        {localize("OAUTH2_CONSENT_GRANTED")}
                    </Text>
                )}
            </div>
        );
    }

    private isConsentGrantable(): boolean {
        const endpoint = this.state.oauth2Data.consentEndpoint || "";

        const variableNames = extractVariableNames(endpoint);

        if (variableNames.length === 0) {
            return true;
        }
    
        const errors = validateModel(
            this.state.data.variables, 
            this.formValidation
        );

        return Object.keys(errors).length === 0;
    }

    private getVariables(): Array<{label: string, name: string}> {
        return Object.keys(this.state.oauth2Data.variables || {})
            .filter(key =>
                Object.prototype.hasOwnProperty.call(
                    this.state.oauth2Data.variables || {},
                    key
                )
            )
            .map(key => ({
                label: (this.state.oauth2Data.variables || {})[key] as string,
                name: key
            }));
    }
}

export default OAuth1ClientCredentialsInput;