import { Modal } from "@fluentui/react";
import React from "react";
import ModalCloseButton from "src/ui/components/Modal/ModalCloseButton";
import "./SjWidgetModal.scss";

interface IProps {
    onDismiss: any;
    micrositeId: string;
}

interface IState {
    sj: boolean;
}
class SjWidgetModal extends React.Component<IProps, IState> {
    private div = React.createRef<HTMLDivElement>();

    state = {
        sj: false,
    };

    componentDidMount = () => {
        var configuration = {
            micrositeId: this.props.micrositeId, //"ca1e8d76-fc56-4984-8a04-b70230d649a8",
            language: "sv",
        };

        this.setState({ sj: true }, () => {
            //@ts-ignore;
            window.SJ.widget.init(configuration);
        });
    };

    render() {
        return (
            <Modal className="SJWidgetpopup spintr-modal" isOpen={true} onDismiss={this.props.onDismiss}>
                <ModalCloseButton onClose={this.props.onDismiss} />
                <div id="sj-widget"></div>
                <div ref={this.div} />
            </Modal>
        );
    }
}

export default SjWidgetModal;
