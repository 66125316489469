import { Pivot, PivotItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import RightColumnDashboard from "src/admin/views/AdminDashboard/RightColumnDashboard";
import { changeHeaderText } from "src/admin/views/AdminSidebar";
import { contexts } from "src/admin/views/AdminSidebarContexts";
import { localize } from "src/l10n";
import { setRightColumnItems } from "src/right-column/actions";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import RightColumnTags from "src/tags/RightColumnTags";
import { TeaserBox } from "src/teaser-box";
import { SpintrTypes } from "src/typings";
import { setCurrentlyShowingTeaserboxes, StandardErrorBoundary } from "src/ui";
import Birthdays from "src/ui/components/Birthdays/Birthdays";
import OperatingInfo from "src/ui/components/OperatingInfo/OperatingInfo";
import Todos from "src/ui/components/Todos/Todos";
import { uniqueId } from "src/utils";
import { TeamsTools } from "../TeamsTools";
import "./RightColumn.scss";

interface IProps {
    currentUserId?: number;
    history?: any;
    location?: any;
    match?: any;
    dispatch?: any;
    instance?: any;
    smallViewMode?: boolean;
    isInTeamsApp?: boolean;
    sidebars?: any[];
    hasFetchedSidebars?: boolean;
    apps?: any;
}


class RightColumn extends Component<IProps> {

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    getSidebarFromContext() {
        return this.props.sidebars.find((s) => s.contexts.indexOf("start") > -1);
    }

    widgetTypes = {
        birthdays: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Birthdays)) {
                    return null;
                }

                return (
                    <Birthdays
                        disableButtons={false}
                        hideTitle={isGroup}
                        userId={this.props.currentUserId}
                    />
                );
            },
        },

        operatinginfo: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.OperatingInfo)) {
                    return null;
                }

                return (
                    <OperatingInfo
                        hideTitle={isGroup}
                        disableButtons={false}
                    />
                );
            },
        },

        calendar: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Calendars)) {
                    return null;
                }

                return <div>Calendar</div>;
            },
        },

        dashboard: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Dashboard)) {
                    return null;
                }

                return <RightColumnDashboard />
            },
        },

        todo: {
            render: (item, isGroup) => {
                if (!this.isAppEnabled(SpintrTypes.SpintrApp.Todos)) {
                    return null;
                }

                return <Todos hideTitle={isGroup} />;
            },
        },
        groups: {
            render: () => {
                // TODO: Fix this

                return null;
            },
        },
        grouponline: {
            render: () => {
                // TODO: Fix this

                return null;
            },
        },
        widget: {
            render: (item, index) => {
                if (!item || !item.widgets || !item.widgets.length) {
                    return null;
                }

                if (item.widgets.length < 2) {
                    return (
                        item.widgets &&
                        item.widgets.map((w, idx) => {
                            if (!w || !w.name) {
                                return null;
                            }

                            const widget = this.widgetTypes[w.name];
                            if (!widget || !widget.render) {
                                return null;
                            }

                            return <div key={`widget-${idx}`}>{widget.render(w)}</div>;
                        })
                    );
                } else {
                    return (
                        <Pivot
                            overflowBehavior={"menu"}
                            key={`pivot-${index + "-" + item.id}`}
                        >
                            {item.widgets &&
                                item.widgets.map((w) => {
                                    if (!w || !w.name) {
                                        return null;
                                    }

                                    const widget = this.widgetTypes[w.name];
                                    if (!widget || !widget.render) {
                                        return null;
                                    }

                                    return (
                                        <PivotItem key={uniqueId()} headerText={localize(changeHeaderText(w.name))}>
                                            <div key={uniqueId()}>{widget.render(w, true)}</div>
                                        </PivotItem>
                                    );
                                })}
                        </Pivot>
                    );
                }
            },
        },
    };

    renderSidebar(sidebar) {
        let widgets = sidebar.formattedData;

        return (
            <div className="RightColumn-widgets">
                {widgets.map((w, i) => {
                    let widgetId = w.name;
                    if (!widgetId) widgetId = "widget";

                    const foundWidgetType = this.widgetTypes[widgetId];

                    if (!foundWidgetType) {
                        console.log("couldn't find widget type: " + widgetId); //TODO: Remove this

                        return null;
                    }

                    return foundWidgetType.render(w, i);
                })}
                {
                    this.isAppEnabled(SpintrTypes.SpintrApp.Tags) && (
                        <RightColumnTags />
                    )
                }
            </div>
        );
    }

    public render(): ReactNode {

        const activeSidebar = this.getSidebarFromContext();

        if (
            !this.props.sidebars ||
            this.props.sidebars.length === 0 ||
            !activeSidebar
        ) {
            return null
        };

        return (
            <div className="right-column-wrapper">
                <div className="RightColumn">
                    <StandardErrorBoundary>
                        {this.renderSidebar(activeSidebar)}
                    </StandardErrorBoundary>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUserId: state.profile.active.id,
    instance: state.instance,
    smallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.TabletPortrait,
    isInTeamsApp: state.ui.isInTeamsApp,
    sidebars: state.rightColumn.items,
    apps: state.app
});

const ConnectedRightColumn = connect(mapStateToProps)(RightColumn);

const ConnectedRightColumnWithRouter = withRouter(ConnectedRightColumn)

export default ConnectedRightColumnWithRouter;
