import { Context, createContext } from "react";

export interface IMarketplaceWidgetContext {
    onDataLoaded(data: Spintr.IMarketplaceWidgetDataResponse): void;
}

const MarketplaceWidgetContext: Context<IMarketplaceWidgetContext> =
    createContext({
        onDataLoaded: (_) => { /* ignored */ },
    });

export default MarketplaceWidgetContext;