import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { localize } from 'src/l10n';
import "./AppFeedbackBanner.scss";
import { Label } from 'src/ui';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import api from 'src/spintr/SpintrApi';

interface IProps extends RouteChildrenProps { 
    isAdminOrEditor: boolean;
    enableAppFeedbackBanner: boolean;
    hasSeenAppFeedback: boolean;
};

interface IState {
    showBanner: boolean;
};

class AppFeedbackBanner extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showBanner: this.props.hasSeenAppFeedback === false
        }
    }

    public componentDidUpdate = (prevProps) => {
        if (prevProps.hasSeenAppFeedback !== this.props.hasSeenAppFeedback &&
            this.props.hasSeenAppFeedback === false) {
            this.setState({showBanner: true})
        }
    }

    private postHasSeen = () => {
        api.post("/api/v1/users/appfeedback")
    }

    private closeBanner = () => {
        this.postHasSeen()
        this.setState({showBanner: false})
    }

    render() {
        if (!this.props.enableAppFeedbackBanner || !this.props.isAdminOrEditor || !this.state.showBanner) {
            return null;
        }

        return (
            <div className="AppFeedbackBanner-Wrapper">
                <div>
                    <Label size="body-1" color="dark-grey" weight="medium">{localize("AppFeedbackTitle")}</Label>
                    <Label size="body-2" color="mid-grey">{localize("AppFeedbackMessage")}</Label>
                </div>
                <div>
                    <a href="#" onClick={this.closeBanner}> {localize("Stang")}</a>
                    &nbsp; &nbsp;
                    <PrimaryButton onClick={() => {
                        this.closeBanner();
                        this.props.history.push("/appfeedback")
                    }}>{localize("AppFeedbackVisitForm")}</PrimaryButton>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdminOrEditor: state.profile.active.isAdmin || state.profile.active.isEditor,
        hasSeenAppFeedback: state.profile.active.hasSeenAppFeedback,
        enableAppFeedbackBanner: state.instance.get("enableAppFeedbackBanner")
    };
};

export default withRouter(connect(mapStateToProps)(AppFeedbackBanner));
