import React, { Component, createRef, ReactNode, RefObject } from 'react';
import { findDOMNode } from 'react-dom';
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface IProps {
    heightCheck?: boolean;
    ignoreSiblings?: boolean;
    offset?: number;
    renderFooter?: () => ReactNode;
    renderHeader?: () => ReactNode;
    continuouslyCalculateHeightUntilNotZero?: boolean;
    checkParentHeight?: boolean;
    bottomOffset?: number;
}

interface IState {
    height?: number,
    innerHeight?: string
}

class FillHeight extends Component<IProps, IState> {
    protected fillHeightRef: RefObject<HTMLDivElement>;
    protected footerRef: RefObject<HTMLDivElement>;
    protected headerRef: RefObject<HTMLDivElement>;
    protected resizeSubscription: Subscription | null;
    private mounted = true;


    constructor(props: IProps) {
        super(props);

        this.fillHeightRef = createRef();
        this.footerRef = createRef();
        this.headerRef = createRef();

        this.state = {};

        this.calculateHeight = this.calculateHeight.bind(this);
        // TODO: Add watchers to parent height and footer height (if needed)
    }

    public componentDidMount(): void {
        this.calculateHeight();

        this.resizeSubscription =
            fromEvent(window, "resize")
                .pipe(debounceTime(100))
                .subscribe(this.calculateHeight);
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (prevProps === this.props) {
            return;
        }

        this.calculateHeight();
    }

    public componentWillUnmount(): void {
        this.mounted = false;
        if (!this.resizeSubscription) {
            return;
        }

        this.resizeSubscription.unsubscribe();
    }

    public getCurrentHeight(): number | undefined {
        return this.state.height;
    }

    public render(): ReactNode {
        return (
            <div
                ref={this.fillHeightRef}
                className="FillHeight"
                style={
                    !!this.state.height ?
                        {
                            height: this.state.height,
                            width: "100%"
                        } :
                        {
                            width: "100%"
                        }
                }
            >
                {this.props.renderHeader
                    ? (
                        <div className="FillHeight-header" ref={this.headerRef}>
                            {this.props.renderHeader()}
                        </div>
                    )
                    : null
                }
                <div style={{
                    display: "flex",
                    height: this.state.innerHeight ? this.state.innerHeight : "auto"
                }}>
                    {this.props.children}
                </div>
                {this.props.renderFooter
                    ? (
                        <div className="FillHeight-footer" ref={this.footerRef}>
                            {this.props.renderFooter()}
                        </div>
                    )
                    : null
                }
            </div>
        )
    }

    protected calculateHeight() {
        const el = findDOMNode(this) as HTMLElement;
        if (!el) {
            return;
        }

        const parent = el.parentElement;


        // @ts-ignore
        const windowHeight = !!window.visualViewport ?
            // @ts-ignore
            window.visualViewport.height :
            document.documentElement ?
                document.documentElement.clientHeight :
                window.innerHeight;

        const parentRect = parent.getBoundingClientRect();
        const parentHeight = parent.clientHeight;

        if (this.props.checkParentHeight && parentHeight === 0) {
            // In GroupNotesView parentHeight is sometimes 0 on initial load which causes offset to not calculate properly
            setTimeout(() => {
                this.calculateHeight();
            }, 10);
            return;
        }

        const offset = Math.ceil(parentRect.top) >= 0
            ? Math.ceil(parentRect.top)
            : Math.ceil(
                Math.abs(
                    window.document.body.getBoundingClientRect().top
                    - parentRect.top
                )
            );

        const siblingHeight = this.props.ignoreSiblings
            ? 0
            : Array.from(parent.children).reduce(
                (acc, node) => {
                    if (node === el) {
                        return acc;
                    }

                    const styles = window.getComputedStyle(node);
                    if (styles.position === "absolute") {
                        return acc;
                    }

                    return acc + el.clientHeight;
                },
                0
            );

        let fillHeight = Math.max(0, windowHeight - offset - siblingHeight);

        if (this.props.heightCheck && parentHeight > 0 && fillHeight > parentHeight) {
            fillHeight = parentHeight;
        }

        if (this.props.offset !== undefined) {
            fillHeight += this.props.offset;
        }

        fillHeight = Math.floor(fillHeight);

        const changed = fillHeight !== this.state.height;

        if (!!this.props.bottomOffset) {
            fillHeight = fillHeight - this.props.bottomOffset;
        }

        let minusHeight = 0;

        if (this.props.renderFooter && this.footerRef.current) {
            minusHeight = minusHeight + this.footerRef.current.clientHeight;
        }

        if (this.props.renderHeader && this.headerRef.current) {
            minusHeight = minusHeight + this.headerRef.current.clientHeight;
        }

        const innerHeight = minusHeight > 0
            ? `calc(100% - ${minusHeight}px)`
            : "100%";

        const doAnotherCheck = changed ||
            (this.props.renderFooter && (!this.footerRef.current || this.footerRef.current.clientHeight === 0) ||
                this.props.renderHeader && (!this.headerRef.current || this.headerRef.current.clientHeight === 0));

        this.setState(
            { height: fillHeight, innerHeight },
            () => {
                if (!doAnotherCheck) {
                    return;
                }

                if (!this.props.continuouslyCalculateHeightUntilNotZero) {
                    return;
                }

                setTimeout(() => {
                    if (this.mounted) {
                        this.calculateHeight();
                    }
                }, 10);
            }
        );
    }
}

export default FillHeight;
