import { DraftHandleValue, EditorState, Modifier } from "draft-js";
import Editor, { EditorPlugin } from "draft-js-plugins-editor";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { localize } from "src/l10n";

export interface ISocialComposerTextInputProps {
    editorState: EditorState;
    onFocus: Spintr.CallbackAction;
    plugins: EditorPlugin[];
    isFrame?: boolean;
    ariaLabel?: string;
    placeholderKey?: string;
    onBlur?: any;
    onChange(editorState: EditorState): void;
}

class SocialComposerTextInput extends Component<ISocialComposerTextInputProps> {
    protected editorRef: RefObject<Editor>;

    constructor(props: ISocialComposerTextInputProps) {
        super(props);

        this.editorRef = createRef();
    }

    public focus(): void {
        if (!this.editorRef.current) {
            return;
        }

        this.editorRef.current.focus();
    }

    handlePaste = (text): DraftHandleValue =>  {
        this.props.onChange(EditorState.push(
          this.props.editorState,
          Modifier.replaceText(
            this.props.editorState.getCurrentContent(),
            this.props.editorState.getSelection(),
            text.replace(/\n/g, ' ')
          ), 'insert-characters'
        ));
        return 'handled';
      }

    public render() : ReactNode {
        return (
            <div aria-label={this.props.ariaLabel} className="SocialComposerTextInput">
                <Editor
                    {...this.props}
                    handlePastedText={this.handlePaste}
                    placeholder={this.props.placeholderKey
                        ? localize(this.props.placeholderKey)
                        : localize("COMPOSER_PLACEHOLDER")
                    } 
                    ref={this.editorRef}
                    textAlignment={this.props.isFrame === true ? "center" : "left"}
                />
            </div>
        );
    }
}

export default SocialComposerTextInput;