import React, { FunctionComponent } from "react";
import { StandardErrorBoundary } from "src/ui";
import VisageInformationFeed from "../../InformationFeed/VisageInformationFeed";

interface IProps {
    props?: { [key: string]: any };
}

const InformationFeedWrapper: FunctionComponent<IProps> = ({ props }) => {
    return (
        <div className="InformationFeedWrapper">
            <StandardErrorBoundary>
                <VisageInformationFeed
                    template={props?.template || 2}
                />
            </StandardErrorBoundary>
        </div>
    );
};

export default InformationFeedWrapper;
