import React, { Fragment, FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const ImageTagNotification: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.ImageTagNotification;
    if (!notification) {
        return;
    }

    return (
        <Fragment>
            {
                notification.userName
                + " " 
                + localize("HarTaggatEttFotoAvDigI").toLowerCase()
                + " "
            }
            <Link to={notification.url}>
                {notification.galleryName}
            </Link>.
        </Fragment>
    );
};

export default ImageTagNotification;
