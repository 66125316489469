import React, { FunctionComponent, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";

const MarketplaceWidgetErrorBoundary: FunctionComponent = (props) => {
    const fallbackRender = useCallback(() => (
        <div className="MarketplaceWidgetErrorBoundary">
            ERROR!
        </div>
    ), []);

    return (
        <ErrorBoundary fallbackRender={fallbackRender}>
            {props.children}
        </ErrorBoundary>
    );
}

export default MarketplaceWidgetErrorBoundary;