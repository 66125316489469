import React, { FunctionComponent } from "react";
import { localize } from "src/l10n";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SocialComposerHeader.scss";

interface IProps {
    /**
     * Localization key for text to override the text displayed
     * in the header
     */
    textKey?: string;

    displayBackButton?: boolean;
    hideCloseButton?: boolean;

    onBackClick?: () => void;
    onCloseClick?: () => void;
}

const SocialComposerHeader: FunctionComponent<IProps> = (props) => {
    return (
        <div id="SocialComposerHeader">
            <div className="header-bar">
                <div className="text">
                    {props.displayBackButton && (
                        <UnstyledButton
                            className="back-button"
                            onClick={props.onBackClick}
                        >
                            <Visage2Icon icon="arrow-circle-left" />
                        </UnstyledButton>
                    )}
                    <Label
                        className="header-text"
                        color="visageGray"
                        size="body-2"
                        weight="semi-bold"
                    >
                        {localize(props.textKey || "START_COMPOSER_HEADER_TEXT")}
                    </Label>
                </div>
                {!props.hideCloseButton && (
                    <UnstyledButton
                        className="close-button"
                        onClick={props.onCloseClick}
                    >
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                )}
            </div>
        </div>
    );
};

export default SocialComposerHeader;
