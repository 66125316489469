import { AxiosResponse } from "axios";
import { Action } from "redux";

import { SpintrActionTypes } from "./action-types";
import api from "./SpintrApi";

export interface IAddToFavoriteAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<Spintr.IUserFavorite>>;
    type: SpintrActionTypes.AddToFavorite;
}

export interface IAddToFavoriteFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    type: SpintrActionTypes.AddToFavoriteFulfilled;
}

export interface IAddToFavoritePendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.AddToFavoritePending;
}

export interface IAddToFavoriteRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.AddToFavoriteRejected;
}

export type AddToFavoruteAction =
    IAddToFavoriteAction
    | IAddToFavoriteFulfilledAction
    | IAddToFavoritePendingAction
    | IAddToFavoriteRejectedAction;

export type AddToFavoriteHandler = (id: number) => IAddToFavoriteAction;
export const addToFavorite: AddToFavoriteHandler =
    (id) => ({
        meta: id,
        payload: api.post<Spintr.IUserFavorite>(
            "/api/v1/favorites",
            { id },
        ),
        type: SpintrActionTypes.AddToFavorite,
    });


export interface IRemoveFavoriteAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<string>>;
    type: SpintrActionTypes.RemoveFromFavorites;
}

export interface IRemoveFavoriteFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    meta: number;
    type: SpintrActionTypes.RemoveFromFavoritesFulfilled;
}

export interface IRemoveFavoritePendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.RemoveFromFavoritesPending;
}

export interface IRemoveFavoriteRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.RemoveFromFavoritesRejected;
}

export type RemoveFavoriteAction =
    IRemoveFavoriteAction
    | IRemoveFavoriteFulfilledAction
    | IRemoveFavoritePendingAction
    | IRemoveFavoriteRejectedAction;

export type RemoveFavoriteHandler = (id: number) => IRemoveFavoriteAction;
export const removeFavorite: RemoveFavoriteHandler =
    (id) => ({
        meta: id,
        payload: api.delete<string>(
            "/api/v1/favorites/" + id,
        ),
        type: SpintrActionTypes.RemoveFromFavorites,
    });


export interface IStartFollowingObjectAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<never>>;
    type: SpintrActionTypes.StartFollowingObject;
}

export interface IStartFollowingObjectFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    meta: number;
    type: SpintrActionTypes.StartFollowingObjectFulfilled;
}

export interface IStartFollowingObjectPendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.StartFollowingObjectPending;
}

export interface IStartFollowingObjectRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.StartFollowingObjectRejected;
}

export type StartFollowingObjectAction =
    IStartFollowingObjectAction
    | IStartFollowingObjectFulfilledAction
    | IStartFollowingObjectPendingAction
    | IStartFollowingObjectRejectedAction;

export type StartFollowingObjectHandler = (id: number) => IStartFollowingObjectAction;
export const startFollowingObject: StartFollowingObjectHandler =
    (id) => ({
        meta: id,
        payload: api.post<never>(
            "/api/v1/follow",
            { id },
        ),
        type: SpintrActionTypes.StartFollowingObject,
    });


export interface IStopFollowingObjectAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<string>>;
    type: SpintrActionTypes.StopFollowingObject;
}

export interface IStopFollowingObjectFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    meta: number;
    type: SpintrActionTypes.StopFollowingObjectFulfilled;
}

export interface IStopFollowingObjectPendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.StopFollowingObjectPending;
}

export interface IStopFollowingObjectRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.StopFollowingObjectRejected;
}

export type StopFollowingObjectAction =
    IStopFollowingObjectAction
    | IStopFollowingObjectFulfilledAction
    | IStopFollowingObjectPendingAction
    | IStopFollowingObjectRejectedAction;

export type StopFollowingObjectHandler = (id: number) => IStopFollowingObjectAction;
export const stopFollowingObject: StopFollowingObjectHandler =
    (id) => ({
        meta: id,
        payload: api.delete<string>(
            "/api/v1/follow/" + id,
        ),
        type: SpintrActionTypes.StopFollowingObject,
    });


export interface IDeleteObjectAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<string>>;
    type: SpintrActionTypes.DeleteObject;
}

export interface IDeleteObjectFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    meta: number;
    type: SpintrActionTypes.DeleteObjectFulfilled;
}

export interface IDeleteObjectPendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.DeleteObjectPending;
}

export interface IDeleteObjectRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.DeleteObjectRejected;
}

export type DeleteObjectAction =
    IDeleteObjectAction
    | IDeleteObjectFulfilledAction
    | IDeleteObjectPendingAction
    | IDeleteObjectRejectedAction;

export type DeleteObjectHandler = (id: number) => IDeleteObjectAction;
export const deleteObject: DeleteObjectHandler =
    (id) => ({
        meta: id,
        payload: api.delete<never>(
            "/api/v1/objects/" + id,
        ),
        type: SpintrActionTypes.DeleteObject,
    });

export interface IPinObjectAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<string>>;
    type: SpintrActionTypes.PinObject;
}

export interface IPinObjectFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    meta: number;
    type: SpintrActionTypes.PinObjectFulfilled;
}

export interface IPinObjectPendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.PinObjectPending;
}

export interface IPinObjectRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.PinObjectRejected;
}

export type PinObjectAction =
    IPinObjectAction
    | IPinObjectFulfilledAction
    | IPinObjectPendingAction
    | IPinObjectRejectedAction;

export type PinObjectHandler = (id: number) => IPinObjectAction;
export const pinObject: PinObjectHandler =
    (id) => ({
        meta: id,
        payload: api.post<never>(
            "/api/v1/objects/toggle-pin/" + id,
        ),
        type: SpintrActionTypes.PinObject,
    });

export type HideObjectAction =
    | IHideObjectAction
    | IHideObjectFulfilledAction
    | IHideObjectPendingAction
    | IHideObjectRejectedAction;

export type HideObjectHandler = (id: number) => IHideObjectAction;
export const hideObject: HideObjectHandler = (id) => ({
    meta: id,
    payload: api.post<never>(`/api/v1/objects/${id}/hidetoggle`),
    type: SpintrActionTypes.HideObject,
});

export interface IHideObjectAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<string>>;
    type: SpintrActionTypes.HideObject;
}
export interface IHideObjectFulfilledAction extends Spintr.IAsyncAction<Spintr.IUserFavorite> {
    meta: number;
    type: SpintrActionTypes.HideObjectFulfilled;
}
export interface IHideObjectPendingAction extends Action {
    meta: number;
    type: SpintrActionTypes.HideObjectPending;
}
export interface IHideObjectRejectedAction extends Spintr.IAsyncActionError {
    meta: number;
    type: SpintrActionTypes.HideObjectRejected;
}

export type SpintrAction =
    AddToFavoruteAction
    | RemoveFavoriteAction

    | StartFollowingObjectAction
    | StopFollowingObjectAction

    | DeleteObjectAction

    | HideObjectAction;