import { Image } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { capitalizeFirstLetter, decodeHtmlEntities, truncate } from "src/utils";
import "./SocialNews.scss";

interface IProps {
    isShare?: boolean;
    post: Spintr.ISocialNewsArticle;
}

const SocialNews: FunctionComponent<IProps> = (props) => (
    <div className="SocialNews">
        {props.isShare && props.post.pageImage && (
            <div className="image">
                <Link to={props.post.url}>
                    <Image
                        alt={props.post.pageTitle}
                        src={props.post.pageImage}
                    />
                </Link>
            </div>
        )}
        <div className="content">
            {!props.isShare && (
                <Label
                    className="text"
                    color="mid-grey"
                    size="body-2"
                >
                    {capitalizeFirstLetter(
                        localize("skapadeNyhetsartikeln_small")) + " "}
                    <Link to={props.post.url}>
                        {props.post.pageTitle}
                    </Link>
                </Label>
            )}
            {props.isShare && (
                <>
                    <Label className="title" size="body-2">
                        <Link to={props.post.url}>
                            {props.post.pageTitle}
                        </Link>
                    </Label>
                    <Label className="preamble" size="body-3">
                        {truncate(
                            decodeHtmlEntities(
                                props.post.pagePreamble.length === 0
                                    ? props.post.pageBody
                                    : props.post.pagePreamble
                            ),
                            200,
                        )}
                    </Label>
                </>
            )}
        </div>
    </div>
);

export default SocialNews;
