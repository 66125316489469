import moment from "moment";
import { DefaultButton, Icon, Modal, PrimaryButton } from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/l10n";
import LoadMoreButton from "./Buttons/LoadMoreButton/LoadMoreButton";
import Label from "./Label/Label";
import SpintrLoader from "./Loader";
import "./PageCalendar.scss";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { setConfirmPopup } from "src/popups/actions";
import {
    fetchCalendarEvent,
    fetchInPageCalendar,
    joinEvent,
    leaveEvent,
    setCalendarPopup,
    toggleCalendarEventDeleted,
} from "src/calendar/actions";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/index";
import CalendarAPI from "src/calendar/calendar-api";
import { isAnythingDirty } from "src/utils";
import { renderUnsavedChangesDialog } from "src/utils/renderUnsavedChangesDialog";
import CalendarEventView from "src/calendar/components/CalendarEventView";
import { IFetchInPageCalendarParams } from "src/calendar/action-types";
import { SpintrTypes } from "src/typings";
import PopupHeader from "./PopupHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    id: number;
    columnCount: number;
    joinEvent: any;
    leaveEvent: any;
    currentUserId: number;
    dispatch: any;
    events?: any[];
    fetchInPageCalendar?: (params: IFetchInPageCalendarParams) => void;
    isLoading: boolean;
    hasMore: boolean;
    relativeExternalSkip?: number;
    relativeSpintrSkip?: number;
    isSmallViewMode: boolean;
}

interface IState {
    nextUrl: string;
    showEvent?: Spintr.ICalendarEvent;
    displayUnsavedChangesPopup: boolean;
    showNum: number;
}

class PageCalendar extends Component<IProps, IState> {
    state = {
        nextUrl: "",
        showEvent: undefined,
        displayUnsavedChangesPopup: false,
        showNum: 5,
    };

    private openEvent = (event) => {
        if (event.exchangeId || event.googleId) {
            this.props.dispatch(setCalendarPopup({ isOpen: true, event }));
        } else {
            this.setShowEvent(event);
        }
    };

    private setShowEvent = (showEvent) => {
        this.setState({ showEvent });
    };

    public componentDidMount = () => {
        // reset calendar events

        this.props.fetchInPageCalendar({
            eventCategory: SpintrTypes.EventCategory.Upcoming,
            calendarId: this.props.id,
            take: 5,
            after: moment.utc().toDate(),
        });
    };

    private join = async (eventId) => {
        const response = await CalendarAPI.joinEvent(this.props.currentUserId, eventId, true);
        this.props.joinEvent(eventId, this.props.currentUserId, response);
    };

    private leave = async (eventId) => {
        const response = await CalendarAPI.leaveEvent(this.props.currentUserId, eventId);
        this.props.leaveEvent(eventId, this.props.currentUserId, response);
    };

    private loadMore = () => {
        this.setState({ showNum: this.state.showNum + 5 });
        this.props.fetchInPageCalendar({
            calendarId: this.props.id,
            eventCategory: SpintrTypes.EventCategory.Upcoming,
            take: 5,
            relativeExternalSkip: this.props.relativeExternalSkip,
            relativeSpintrSkip: this.props.relativeSpintrSkip,
        });
    };

    public render() {
        if (this.props.isLoading) {
            return <SpintrLoader />;
        }
        const events = this.props.events.filter((e) => e.presentingCalendarId == this.props?.id);

        if (events?.length < 1) {
            return (
                <div className="PageCalendar">
                    <div className={classNames("error event", {
                        ["oneColumn"]: this.props.columnCount === 1 && !this.props.isSmallViewMode,
                        ["twoColumns"]: this.props.columnCount === 2 && !this.props.isSmallViewMode,
                        ["threeColumns"]: this.props.columnCount === 3 || this.props.isSmallViewMode,
                    })}
                    >
                        <Visage2Icon className="errorIcon" icon="calendar" />
                        <Label size="body-1" className="eventError">
                            {localize("IngaEvent")}
                        </Label>
                    </div>
                </div>
            );
        }

        const { showEvent } = this.state;

        return (
            <div className="PageCalendar">
                {showEvent && (
                    <Modal
                        className="spintr-modal CalendarEventViewModal modalWithPopupHeader"
                        isOpen={showEvent !== undefined}
                        onDismiss={() => {
                            if (isAnythingDirty()) {
                                return this.setState({
                                    displayUnsavedChangesPopup: true,
                                });
                            }

                            this.setState({ showEvent: undefined });
                        }}
                    >
                        <PopupHeader
                            text={localize("Kalenderhandelse")}
                            onClose={() => {
                                this.setState({ showEvent: undefined });
                            }} />
                        <CalendarEventView
                            id={showEvent.id}
                            isModal
                            onDismiss={() => {
                                this.setState({ showEvent: undefined });
                            }}
                        />
                    </Modal>
                )}

                {renderUnsavedChangesDialog(
                    this.state.displayUnsavedChangesPopup,
                    () => {
                        this.setState({
                            displayUnsavedChangesPopup: false,
                        });
                    },
                    () => {
                        this.setState(
                            {
                                displayUnsavedChangesPopup: false,
                            },
                            () => {
                                this.setState({ showEvent: undefined });
                            }
                        );
                    }
                )}

                {events?.slice(0, this.state.showNum).map((event: Spintr.ICalendarEvent, i) => {
                    const startDateNumber = moment(event.start).format("DD");
                    const startDateMonth = moment(event.start).format("MMM");
                    const startTime = moment(event.start).format("HH:mm");
                    const endTime = moment(event.end).format("HH:mm");
                    const registrationClosed =
                        moment().isAfter(event.registrationClose) || event.attendees?.length >= event.seats;
                    const hasJoined = event.attendees.some(
                        (attendee) => attendee.id === this.props.currentUserId && attendee.status === 1
                    );
                    const attendingUsers = event.attendees.filter((e) => e.status === 1);

                    return (
                        <div
                            className={classNames("event", {
                                ["oneColumn"]: this.props.columnCount === 1 && !this.props.isSmallViewMode,
                                ["twoColumns"]: this.props.columnCount === 2 && !this.props.isSmallViewMode,
                                ["threeColumns"]: this.props.columnCount === 3 || this.props.isSmallViewMode,
                            })}
                            key={i}
                            onClick={() => {
                                this.openEvent(event);
                            }}
                        >
                            <div className="date">
                                <Label weight="medium" as="div">
                                    {startDateNumber}
                                </Label>
                                <Label weight="medium" as="div">
                                    {startDateMonth.toLowerCase()}
                                </Label>
                            </div>

                            <div className="eventContent">
                                <div className="timeAndTitle">
                                    <Label size="body-3" className="time">
                                        {event.allDay ? localize("Heldag") : `${startTime} - ${endTime}`}
                                    </Label>
                                    <Label weight="medium" size="body-1" className="title">
                                        {event.title}
                                    </Label>
                                </div>

                                {/* <div className="seatsAndAttendingButton">
                                    <Label size="body-2" className="seats">
                                        {event.seats
                                            ? `${localize("AntalPlatser")}: ${attendingUsers?.length} / ${event.seats}`
                                            : `${attendingUsers.length} ${localize("Deltagare").toLowerCase()}`}
                                    </Label>
                                    {hasJoined ? (
                                        <DefaultButton
                                            className="attendingButton"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                this.leave(event.id);
                                            }}
                                        >
                                            {localize("Lamna")}
                                        </DefaultButton>
                                    ) : (
                                        <PrimaryButton
                                            className="attendingButton"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                this.join(event.id);
                                            }}
                                            disabled={registrationClosed}
                                        >
                                            {localize("Kommer")}
                                        </PrimaryButton>
                                    )}
                                </div> */}
                            </div>
                        </div>
                    );
                })}

                {!this.props.isLoading && this.props.hasMore && <LoadMoreButton onClick={this.loadMore} />}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        currentUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isSmallViewMode: state.ui.isSmallViewMode,
        isLoading: props.id ? state.calendar.isLoadingInPage[props.id] : true,
        event: state.calendar.event.event,
        instanceName: state.instance.get("name"),
        //@ts-ignore
        events: state.calendar.events,
        hasMore: props.id ? state.calendar.hasMoreInPageEvents[props.id] : false,
        relativeExternalSkip: props.id ? Number(state.calendar.relativeExternalSkipInPage[props.id]) : 0,
        relativeSpintrSkip: props.id ? Number(state.calendar.relativeSpintrSkipInPage[props.id]) : 0,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...dispatch,
        ...bindActionCreators(
            {
                setConfirmPopup,
                toggleCalendarEventDeleted,
                setCalendarPopup,
                fetchCalendarEvent,
                joinEvent,
                leaveEvent,
                fetchInPageCalendar,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCalendar);
