import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { localize } from 'src/l10n';
import { getUserPart } from '../utils';

interface IProps {
    notification: Spintr.INotification;
}

const NoteUpdatedNotificationText: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.INoteUpdatedNotification;

    if (!notification) {
        return;
    }

    const userPart = getUserPart(notification);
    const typeText = localize("Ubertype56_0_1");

    return (
        <Fragment>
            {
                userPart +
                " " +
                localize("HAS_UPDATED") +
                " " +
                typeText +
                " "
            }
            <Link to={notification.url}>
                {notification.title}
            </Link>
        </Fragment>
    )
};

export default NoteUpdatedNotificationText
