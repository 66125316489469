import { localize } from "src/l10n";

export default function getShortFromNow(date: Date) {
    const diffInSeconds = Math.floor((+new Date() - +date) / 1000);
    const intervalValues = [31536000, 2592000, 86400, 3600, 60];
    const intervalStrings = ["TIME_UNIT_YEARS_SHORT", "TIME_UNIT_MONTHS_SHORT", "TIME_UNIT_DAYS_SHORT", "TIME_UNIT_HOURS_SHORT", "TIME_UNIT_MINUTES_SHORT"];

    let intervalValue: number = 0;
    let intervalString: string = "";

    for (let i = 0; i < intervalValues.length; i++) {
        if (!!intervalString) {
            continue;
        }

        intervalValue = Math.floor(diffInSeconds / intervalValues[i]);

        if (intervalValue >= 1) {
            intervalString = localize(intervalStrings[i]);
        }
    }

    if (!intervalString) {
        return localize("NOW");
    }

    return intervalValue + " " + intervalString;
}
