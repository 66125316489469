import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

import { renderAdminTitle } from "src/admin/common/Title";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Breadcrumbs, Label, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { listActionMenuWidth } from "src/ui/helpers/style";

interface IState {
    company: any;
    data: Spintr.IOfficeQueryItem[];
    isLoading: boolean;
    totalCount: number;
    showDeleted: boolean;
}

interface IProps {
    companyId?: string;
}

const initialState: IState = {
    company: null,
    data: [],
    isLoading: false,
    showDeleted: false,
    totalCount: 0
}

const OfficesListAdminView: FunctionComponent<IProps> = ({ companyId }) => {
    const history = useHistory();
    const listRef = useRef<SpintrList>();
    const [state, setState] = useState<IState>(initialState);

    const enableGroupTemplate = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.instance.get("enableGroupTemplates") as boolean,
    );

    const breadcrumbItems = useMemo(
        () => !state.company ? [] : [
            {
                key: "Organisation",
                link: "/admin/organisation",
                text: localize("Organisationsstruktur"),
            },
            {
                key: "companyId",
                link: `/admin/organisation/${state.company?.id || ""}`,
                text: state.company?.name || "",
            }
        ],
        [state.company],
    );

    const fetchOffices = useCallback(
        async (skip, take, columnId, isAscending, searchQuery) => {
            const response = await api.get<Spintr.IOfficeQueryItem[]>(
                "/api/v1/offices",
                {
                    params: {
                        companyId,
                        includeDeleted: state.showDeleted,
                        isAscending,
                        orderByColumn: columnId,
                        searchText: searchQuery,
                        skip,
                        take,
                    }
                }
            );

            setState((s) => ({
                ...s,
                data: response.data,
                totalCount: parseInt(response.headers["x-total-count"], 10),
            }));

            return {
                data: response.data,
                totalCount: parseInt(response.headers["x-total-count"], 10),
            };
        },
        [setState, state.showDeleted, companyId],
    );

    useEffect(
        () => {
            if (!companyId) {
                return;
            }

            setState((s) => ({ ...s, isLoading: true }));

            api
                .get<Spintr.ICompanyQueryItem>(`/api/v1/companies/${companyId}`)
                .then((response) => {
                    setState((s) => ({
                        ...s,
                        company: response.data,
                        isLoading: false,
                    }))
                });
        },
        [companyId],
    );

    if (state.isLoading) {
        return <SpintrLoader />;
    }

    return (
        <div className="AdminOrganisationStructureView">
            {companyId && (
                <Breadcrumbs
                    isSubmenu={true}
                    items={breadcrumbItems}
                />
            )}
            {renderAdminTitle("Kontor_plural")}
            <PageHeader
                title={localize("KontorOchAvdelningar")}
                subText={localize("PAGE_INFO_ORGANISATIONSTRUCTURE")}
                useShowMoreSubText
                hits={state.totalCount} />
            <SpintrList
                ref={listRef}
                fetch={fetchOffices}
                data={{
                    data: state.data,
                    totalCount: state.totalCount
                }}
                columns={[
                    {
                        fieldName: "name",
                        maxWidth: 400,
                        name: localize("Namn"),
                        onRender: (item: Spintr.IOfficeQueryItem) => {
                            return (
                                <Link
                                    className={"linkFGColor"}
                                    style={{
                                        ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                    }}
                                    to={
                                        companyId
                                            ? `/admin/organisation/${companyId}/${item.id}`
                                            : `/admin/organisation/${item.id}`
                                    }
                                >
                                    {item.name}
                                </Link>
                            );
                        },
                    },
                    {
                        fieldName: "departmentCount",
                        minWidth: 10,
                        name: localize("Avdelningar"),
                    },
                    {
                        fieldName: "status",
                        minWidth: 100,
                        name: localize("Status"),
                        onRender: (item) => {
                            return <span>{item.deleted ? localize("Borttagen") : localize("Tillganglig")}</span>;
                        },
                    },
                    {
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        name: "",
                        onRender: (item: any) => {
                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () =>
                                                        history.push(
                                                            companyId
                                                                ? `/admin/organisation/${companyId}/${item.id}/edit`
                                                                : `/admin/organisation/${item.id}/edit`
                                                        ),
                                                },

                                                item.deleted
                                                    ? {
                                                        text: localize("Aterstall"),
                                                        onClick: () => {
                                                            api
                                                                .put(`/api/v1/offices/${item.id}/restore`)
                                                                .then(() => {
                                                                    listRef.current.reFetch();
                                                                });
                                                        },
                                                    }
                                                    : {
                                                        text: localize("TaBort"),
                                                        onClick: () => {
                                                            if (item.departmentCount > 0) {
                                                                alert(
                                                                    localize(
                                                                        "DetGarBaraAttTaBortKontorSomInteHarNagraAvdelningar"
                                                                    )
                                                                );
                                                            } else {
                                                                api
                                                                    .delete(`/api/v1/offices/${item.id}`)
                                                                    .then(() => {
                                                                        listRef.current.reFetch();
                                                                    });
                                                            }
                                                        },
                                                    },
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn="name"
                buttons={[
                    {
                        key: "add",
                        text: localize("NyttKontor"),
                        onClick: () => history.push(
                            companyId
                                ? `/admin/organisation/${companyId}/create`
                                : `/admin/organisation/create`
                        ),
                        iconProps: { iconName: "Add" },
                        className: "commandBarAddButton",
                    },
                ]}
                optionItems={{
                    items: [
                        {
                            key: "showHidden",
                            text: localize(state.showDeleted ? "DoljBorttagna" : "VisaBorttagna"),
                            onClick: () => {
                                setState(
                                    (prevState) => ({
                                        ...prevState,
                                        showDeleted: !prevState.showDeleted,
                                    })
                                );
                                listRef.current.reFetch();
                            },
                        }, 
                        ...(!enableGroupTemplate ? [] : [{
                            key: "groups",
                            text: localize("Grupper"),
                            onClick: () => history.push("/admin/organisation/groups"),
                        }]),
                    ],
                }}
            />
        </div>
    );
};

export default OfficesListAdminView;
