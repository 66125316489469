import React, { Component, ReactNode } from "react";
import { uniqueId } from "src/utils";
import FormControl from "./FormControl";
import TokenizedObjectInput, { ITokenizedObjectInputProps } from "./TokenizedObjectInput";
import { connect } from "react-redux";

export interface IFormTokenizedObjectInputProps extends ITokenizedObjectInputProps {
    label?: string;
    hideImage?: boolean;
    enableNewItems?: boolean;
    noAutocomplete?: boolean;
    required?: boolean;
    validateOnLoad?: boolean;
    groupId?: number;
    addEmails?: boolean;
    showTags?: boolean;
    dispatch?: any;
}

interface IFormTokenizedObjectInputState {
    id?: string;
    validationFailed: boolean;
}

class FormTokenizedObjectInput extends Component<IFormTokenizedObjectInputProps, IFormTokenizedObjectInputState> {
    constructor(props: IFormTokenizedObjectInputProps) {
        super(props);

        this.state = {
            id: uniqueId("FormTokenizedObjectInput"),
            validationFailed: false
        };
    }

    validate() {
        if (this.props?.required) {
            this.setState({
                validationFailed: !this.props.items ||
                    this.props.items.length === 0
            });
        }
    }

    componentDidMount() {
        if (this.props.validateOnLoad) {
            this.validate();
        }
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.items !== !!prevProps.items ||
            (!!this.props.items && !!prevProps.items && this.props.items.length !== prevProps.items.length)) {
            this.validate();
        }
    }

    public render(): ReactNode {
        const id: string = this.props.id || this.state.id;

        return (
            <div className="FormSelect">
                <FormControl
                    htmlFor={id}
                    label={this.props.label}
                    required={this.props.required}
                    validationFailed={this.state.validationFailed}>
                    <TokenizedObjectInput
                        id={id}
                        dispatch={this.props.dispatch}
                        items={this.props.items}
                        onChange={(items) => {
                            this.props.onChange(items);
                        }}
                        types={this.props.types}
                        hideImage={this.props.hideImage}
                        enableNewItems={this.props.enableNewItems}
                        addEmails={this.props.addEmails}
                        itemLimit={this.props.itemLimit}
                        disabled={this.props.disabled}
                        useSearchEngine={this.props.useSearchEngine}
                        allowEmailAddresses={this.props.allowEmailAddresses}
                        noAutocomplete={this.props.noAutocomplete}
                        onQueryChange={this.props.onQueryChange}
                        initialInputText={this.props.initialInputText}
                        placeholder={this.props.placeholder}
                        showAllWhenEmpty={this.props.showAllWhenEmpty}
                        excludeEmptyRoles={this.props.excludeEmptyRoles}
                        shareableGroupsOnly={this.props.shareableGroupsOnly}
                        groupId={this.props.groupId}
                        includeDeleted={this.props.includeDeleted}
                        showTags={this.props.showTags}
                        searchSupplierRoles={this.props.searchSupplierRoles}
                    />
                </FormControl>
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props
    }
}
export default connect(mapStateToProps)(FormTokenizedObjectInput);
