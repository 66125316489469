import classNames from "classnames";
import moment from "moment";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { localize } from "src/l10n";
import { ActionMenu, Label, SpintrUser, UnstyledButton } from "src/ui";
import { circleLarge, circleMedium, circleSmall, circleXLarge } from "src/ui/helpers/style";
import { decapitalizeFirstLetter, decodeHtmlEntities, fromNow, getTimeOrDateFromDate, stripInteractiveText } from "src/utils";
import "./ConversationItem.scss";
import api from "src/spintr/SpintrApi";
import { queryConversations, updateConversation } from "src/chat/redux";
import { SpintrTypes } from "src/typings";
import { connect, useDispatch } from "react-redux";
import UnreadIndicator from "src/ui/components/UnreadIndicator/UnreadIndicator";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { RouteComponentProps, withRouter } from "react-router";
import { IApplicationState } from "src/spintr/reducer";
import { ConversationItemImage } from "../ConversationItemImage";

interface IProps extends RouteComponentProps {
    conversation: Spintr.IConversation;
    currentUserId: number;
    onClick?: (conversation: Spintr.IConversation) => void;
    isGroupItem?: boolean;
    isPinned?: boolean;
    allowAdministratorsAccessToAllGroups?: boolean;
}

const ConversationItem: FunctionComponent<IProps> = (props) => {
    const dispatch = useDispatch();

    const text = useMemo(() => {
        if (!props.conversation.id && props.conversation.group) {
            return localize(props.conversation.group.isOpen ? "OppenGrupp" : props.conversation.group.isSecret ? "HemligGrupp" : "StangdGrupp");
        }

        if (!props.conversation.lastMessage) {
            return null;
        }

        let message = props.conversation.lastMessage.text;
        let userName = props.conversation.lastMessage.user.name.split(" ")[0];

        if (props.conversation.lastMessage.isSystem) {
            let prefix = props.conversation.lastMessage.user.id === props.currentUserId ?
                localize("Du") :
                userName;

            message = decapitalizeFirstLetter(localize(message));

            return prefix + " " + message;
        }

        if (!message) {
            let prefix = props.conversation.lastMessage.user.id === props.currentUserId ?
                localize("Du") :
                userName;

            message = decapitalizeFirstLetter(localize("HarSkickatEttMeddelande"));

            return prefix + " " + message;
        }

        if (props.conversation.lastMessage.user.id === props.currentUserId) {
            message = localize("Du") + ": " + message;
        } else if (props.conversation.participants.length > 2) {
            message = userName + ": " + message;
        }

        return decodeHtmlEntities(
            stripInteractiveText(
                message
            )
        );
    }, [props.conversation]);

    const getActionMenuItems = () => {
        let items = [];

        if (props.conversation.id) {
            items.push({
                text: props.conversation.isPinned ? localize("UNPIN_OBJECT") : localize("PIN_OBJECT"),
                onClick: () => {
                    api.post("/api/conversations/" + props.conversation.id + "/pin")
                        .then((response) => {
                            dispatch(queryConversations({
                                conversationsSkip: 0,
                                groupsSkip: 0,
                                usersSkip: 0,
                                take: 40
                            }))
                        }).catch(() => {})
                }
            });
        }

        if (!props.conversation.id &&
            props.conversation.group &&
            props.allowAdministratorsAccessToAllGroups) {
            items.push({
                text: localize("JOIN_AS_ADMIN"),
                onClick: () => {
                    api.post("/api/groups/" + props.conversation.group.id + "/join?joinAsAdmin=true").then(() => {
                        dispatch(queryConversations({
                            conversationsSkip: 0,
                            groupsSkip: 0,
                            usersSkip: 0,
                            take: 40
                        }));

                        if (props.history) {
                            props.history.push({
                                pathname: "/groups/" + props.conversation.group.id,
                            });
                        }
                    }).catch(() => { });
                }
            });
        }

        if (!props.conversation.id &&
            props.conversation.group &&
            props.conversation.group.isOpen) {
            items.push({
                text: localize("GaMed"),
                onClick: () => {
                    api.post("/api/groups/" + props.conversation.group.id + "/join").then(() => {
                        dispatch(queryConversations({
                            conversationsSkip: 0,
                            groupsSkip: 0,
                            usersSkip: 0,
                            take: 40
                        }));

                        if (props.history) {
                            props.history.push({
                                pathname: "/groups/" + props.conversation.group.id,
                            });
                        }
                    }).catch(() => { });
                }
            });
        }

        if (!props.conversation.id &&
            props.conversation.group &&
            !props.conversation.group.isOpen &&
            !props.conversation.group.isSecret) {
            if (props.conversation.group.isRequestingMembership) {
                items.push({
                    text: localize("AnsokanHarSkickats"),
                    onClick: () => { }
                });
            } else {
                items.push({
                    text: localize("AnsokOmMedlemskap"),
                    onClick: () => {
                        api.post("/api/groupmembers/request/" + props.conversation.group.id).then(() => {
                            dispatch(updateConversation({
                                ...props.conversation,
                                group: {
                                    ...props.conversation.group,
                                    isRequestingMembership: true
                                }
                            }))
                        }).catch(() => { });
                    }
                });
            }
        }

        return items
    }

    const onClick = useCallback(
        () => {
            if (props.onClick) {
                props.onClick(props.conversation);
            }
        },
        [props.onClick, props.conversation],
    );

    const { lastMessage } = props.conversation;
    const message = (lastMessage && lastMessage.text)
        ? lastMessage.text
        : "";

    let dateText = "";
    if (lastMessage) {
        const date = typeof (lastMessage.date) === "string"
            ? new Date(lastMessage.date)
            : lastMessage.date;

        dateText = moment(date).format("HH:mm");
    }

    let imageUrl = "";

    if (props.conversation.imageUrl && props.conversation.imageUrl.length > 0) {
        imageUrl = props.conversation.imageUrl[0];
    }

    const isActive = props.location.pathname.indexOf("/" + (!!props.conversation.group ? props.conversation.group.id : props.conversation.id)) > -1;

    return (
        <UnstyledButton
            className={classNames(
                "ConversationItem",
                props.conversation.unread > 0 ? "unread" : "",
                isActive ? "active lightPrimaryBGColor" : "",
                props.isGroupItem ? "group" : ""
            )}
            onClick={onClick}
        >
            <div className="image">
                <ConversationItemImage
                    conversation={props.conversation}
                    currentUserId={props.currentUserId}
                    hideText />
            </div>
            <div className="text">
                <div>
                    <Label className="top" size="body-2" weight="medium" color="dark-grey" title={props.conversation.title}>
                        {props.conversation.title}
                    </Label>
                    {text && (
                        <Label className="bottom" size="body-2" color="grey">
                            {text}
                        </Label>
                    )}
                </div>
                <div>
                    {!!dateText && (
                        <div className="top">
                            <Label className="date" size="body-2" weight="medium" color="grey">
                                {getTimeOrDateFromDate(
                                    props.conversation.lastMessage.date
                                )}
                            </Label>
                        </div>
                    )}
                    <div className="bottom">
                        {props.conversation.unread > 0 && (
                            <UnreadIndicator count={props.conversation.unread} />
                        )}
                        <div className="action-menu">
                            <ActionMenu
                                categories={[
                                    {
                                        items: getActionMenuItems()
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </UnstyledButton>
    );
};

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        allowAdministratorsAccessToAllGroups: state.profile.active.isSpintrAdminAccount ||
            (state.instance.get("allowAdministratorsAccessToAllGroups") && state.profile.active.isAdmin)
    };
};

export default withRouter(connect(mapStateToProps)(ConversationItem));