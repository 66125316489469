import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Planner } from "src/components";

function PlannerItemsView(): ReactElement {
    const isSmallViewMode = useSelector<Spintr.AppState, boolean>(
        (state) => state.ui.isSmallViewMode,
    );

    return (
        <section id="PlannerItemsView">
            {/* <header className="PlannerItemsView-heading">
                <Heading color="contentDark" lineHeight="normal" weight="medium">
                    {localize("appPlanner")}
                </Heading>
            </header> */}
            
            <Planner initialTimelineMode={isSmallViewMode ? "MONTHS" : "WEEKS"} />
        </section>
    );
}

export default PlannerItemsView;
