import { Icon } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { capitalizeFirstLetter } from "src/utils";
import "./SocialFile.scss";

interface IProps {
    post: Spintr.ISocialFile;
}

const getFileIconName = (type: string): string => {
    if (!type) {
        return "Document";
    }

    switch (type.toLowerCase()) {
        case "doc":
        case "docx":
            return "WordDocument";

        case "xls":
        case "xlsx":
            return "ExcelDocument";

        case "ppt":
        case "pptx":
            return "PowerPointDocument";

        case "pdf":
            return "PDF";

        case "odt":
        case "sxw":
        case "txt":
        case "csv":
            return "TextDocument";

        case "jpg":
        case "png":
        case "gif":
            return "FileImage";

        default:
            return "Document";
    }
}

const SocialFile: FunctionComponent<IProps> = (props) => {
    const folderLink = "/files/1/d" + props.post.folderId;

    if (props.post.files.length === 1) {
        const theFile = props.post.files[0];
        const theFileLink = "/files/1/f" + theFile.id;

        return (
            <div className="SocialFile">
                <Label
                    className="text"
                    color="mid-grey"
                    size="body-2"
                >
                    <span>
                        {capitalizeFirstLetter(
                            localize("laddadeUppenFiliMappen_small")) + " "
                        }
                    </span>
                    <Link to={folderLink}>
                        {props.post.folderName}
                    </Link>
                </Label>
                <div className="single-file">
                    <div className="icon">
                        <Icon
                            iconName={getFileIconName(theFile.fileType)}
                        />
                    </div>
                    <div className="text">
                        <Label as="p" className="name" size="body-2">
                            <Link to={theFileLink}>
                                {theFile.fileName}
                            </Link>
                        </Label>
                        <Label role="heading" as="div" className="size" size="small-2">
                            {theFile.fileSize}kb
                        </Label>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="SocialFile">
            <Label
                className="text"
                color="mid-grey"
                size="body-2"
            >
                <span>
                    {capitalizeFirstLetter(
                        localize("HarLaddatUppFilerIMappen")
                    ) + " "}
                </span>
                <Link to={folderLink}>
                    {props.post.folderName}
                </Link>
            </Label>
            <div className="file-list">
                {props.post.files
                    .slice(0, 5)
                    .map((file) => (
                        <div className="file" key={"file" + file.id}>
                            <div className="icon">
                                <Icon
                                    iconName={getFileIconName(
                                        file.fileType,
                                    )}
                                />
                            </div>
                            <div className="text">
                                <Label role="text" as="div" className="name" size="body-2">
                                    <Link to={"/files/1/f" + file.id}>
                                        {file.fileName}
                                    </Link>
                                </Label>
                                <Label role="text" as="div" className="size" size="small-2">
                                    {file.fileSize}kb
                                </Label>
                            </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SocialFile;