import { ContentState, convertFromRaw, genKey, RawDraftContentState } from "draft-js";
import decodeHtmlEntities from "./decodeHtmlEntities";
import { stripInteractiveText } from "./formatInteractiveText";

export function getContentStateFromString(s : string) : ContentState {
    let text = decodeHtmlEntities(s);

    text = text.replace(/<br ?\/?>/g, "\n");

    let strippedText = stripInteractiveText(text);

    let state : RawDraftContentState = {
        blocks: [
            {
                key: genKey(),
                text: strippedText,
                type: 'unstyled',
                inlineStyleRanges: [],
                data: {},
                depth: 0,
                entityRanges: []
              }
        ],
        entityMap: {}
    };

    const splitPattern = /(\[link@(?:.+?)@link\](?:.+?)\[\/link])/ig;
    const matchPattern = /\[link@(.+?)@link\](.+?)\[\/link]/ig;

    let i = 0;
    let foundMentionTexts : string[] = [];

    function indexes(source, find) {
        if (!source) {
          return [];
        }

        if (!find) {
            return source.split('').map(function(_, i) { return i; });
        }

        var result = [];

        for (i = 0; i < source.length; ++i) {
            if (source.substring(i, i + find.length) == find) {
                result.push(i);
            }
        }

        return result;
    }

    text.split(splitPattern).map((part, i) => {
        const match = matchPattern.exec(part);

        if (match) {
            state.blocks[0].entityRanges.push({
                offset: indexes(strippedText, match[2])[foundMentionTexts.filter(t => t === match[2]).length],
                length: match[2].length,
                key: i,
            });

            state.entityMap[i] = {
                "type": "mention",
                "mutability": "SEGMENTED",
                "data": {
                    "mention": {
                        "name": match[2],
                        "link": match[1],
                        "avatar": ""
                    }
                }
            }

            foundMentionTexts.push(match[2]);

            i++;
        }
    });

    return convertFromRaw(state);
}