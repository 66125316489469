import {
    FETCH_PRIVILEGES_PENDING,
    FETCH_PRIVILEGES_REJECTED,
    FETCH_PRIVILEGES_FULFILLED
} from './action-types';

const initialState = {
    data: [],
    isLoaded: false,
    fetchHasBeenInitialized: false
};

const privilegesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIVILEGES_PENDING:
            return {
                ...state,
                fetchHasBeenInitialized: true
            }
        case FETCH_PRIVILEGES_REJECTED:
            return {
                ...state,
                isLoaded: true,
            }
        case FETCH_PRIVILEGES_FULFILLED:
            return {
                ...state,
                isLoaded: true,
                data: !!action.payload && !!action.payload.data ?
                    action.payload.data :
                    []
            }
        default:
            return state;
    }
}

export default privilegesReducer;
