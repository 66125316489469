import moment from "moment";
import React, { useEffect, useState } from "react";
import { getShortFromNow } from "src/utils";

interface IProps {
    date: Date;
    short?: boolean;
}

const formatDate = (date: Date, short?: boolean) => {
    if (short) {
        return getShortFromNow(date);
    } else {
        return moment(date).fromNow()
    }
}

const UpdatingDate = (props: IProps) => {
    const [formattedDate, setFormattedDate] = useState(formatDate(props.date, props.short));
    const [titleDate, setTitleDate] = useState(moment(props.date).format("LLL"));

    useEffect(() => {
        const interval = setInterval(() => {
            const newFormattedDate = formatDate(props.date, props.short);

            if (newFormattedDate !== formattedDate) {
                setFormattedDate(newFormattedDate);
            }
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <span title={titleDate}>{formattedDate}</span>
    )
}

export default UpdatingDate;