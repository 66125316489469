import classNames from "classnames";
import { Icon } from "@fluentui/react";
import React, { Component, Fragment, MouseEvent, ReactNode } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";
import { Label } from "src/ui";
import { decodeHtmlEntities, formatInteractiveText } from "src/utils";
import "./SocialPoll.scss";
import api from "src/spintr/SpintrApi";
import { localize } from "src/l10n";
import TranslateButton from "src/ui/components/TranslateButton";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { remotePollVote } from "src/social-feed/actions";
import { SpintrTypes } from "src/typings";

interface ISocialPollProps {
    ownId?: number;
    post: Spintr.ISocialPoll;
    dispatch?: any;
}

interface ISocialPollState {
    postText: string;
}

class SocialPoll extends Component<ISocialPollProps, ISocialPollState> {
    constructor(props: ISocialPollProps) {
        super(props);

        this.state = {
            postText: this.props.post.question
        }
    }

    protected translate = (text: string): void => {
        this.setState({ postText: text })
    }

    public render() : ReactNode {
        return (
            <div className="Visage2SocialPoll">
                <div className="poll-alternatives">
                    {this.props.post.options.map((option, index) => {
                        return (
                            <div className="poll-alternative general-row-break" key={index}>
                                <a 
                                    data-option-id={option.id} 
                                    onClick={() => {
                                        this.onOptionClick(option.id);
                                    }}
                                    role="button"
                                >
                                    {this.renderOption(option)}
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    protected getVotedOptionId(): number | undefined {
        const { ownId, post } = this.props;

        if (!ownId) {
            return undefined;
        }
        
        for (const option of post.options) {
            if (option.answers.indexOf(ownId) === -1) {
                continue;
            }

            return option.id;
        }

        return undefined;
    }

    protected hasVoted(): boolean {
        return !!this.getVotedOptionId();
    }

    protected async onOptionClick(optionId: number): Promise<void> {
        const option = this.props.post.options.find(o => o.id === optionId);
        if (!option) {
            return;
        }
        
        const votedOptionId = this.getVotedOptionId();
        const votedOnThis = votedOptionId === optionId;

        const success = (
            !!votedOptionId
                ? (
                    votedOnThis
                        ? await this.removeVote(optionId)
                        : (
                            (await this.removeVote(votedOptionId)) 
                            &&
                            (await this.registerVote(optionId))
                        )
                )
                : await this.registerVote(optionId)
        );

        this.props.dispatch(remotePollVote({
            eventName: SpintrTypes.RealtimeEventName.PollVote,
            pollId: this.props.post.Id,
            optionId,
            userId: this.props.ownId,
            value: !votedOnThis
        }));

        if (!success) {
            // TODO: Handle error
            return;
        }

        this.forceUpdate();
    }

    protected async registerVote(optionId: number): Promise<boolean> {
        try {
            await api.post("/api/v1/polls/vote/" + optionId);

            return true;
        } catch(err) {
            return false;
        }
    }

    protected async removeVote(optionId: number): Promise<boolean> {
        try {
            await api.delete("/api/v1/polls/vote/" + optionId);

            return true;
        } catch(err) {
            return false;
        }
    }

    protected renderOption(option: Spintr.ISocialPollOption) {
        const isSelected = option.answers.indexOf(this.props.ownId) > -1;
        const totalVotes = this.props.post.options.reduce(
            (acc, opt) => acc + opt.answers.length,
            0
        );

        let percentage = Math.round((option.answers.length / totalVotes) * 100);

        if (isNaN(percentage)) {
            percentage = 0;
        }
        return (
            <div className={"Visage2SocialPoll-option" + (isSelected ? " is-selected" : "")}>
                <div className="Visage2SocialPoll-option-bg" style={{
                    width: percentage + "%"
                }} />
                <div className="left">
                    <Visage2Icon
                        className="alternative-checkbox"
                        icon={"tick-circle"}
                        color={isSelected ? "neutralBlue" : "mid-grey"}
                        type={isSelected ? "bold" : "outline"} />
                    <Label 
                        as="span"
                        color="dark-grey"
                        size="body-2"
                    >
                        {decodeHtmlEntities(option.option)}
                    </Label>
                </div>
                {!!option.answers.length && <div className="right">
                    <Label as="span" color="dark-grey" size="body-2">
                        {option.answers.length} {option.answers.length === 1 ? localize("POLL_VOTE") : localize("POLL_VOTES")}, {percentage}%
                    </Label>
                </div>}
            </div>
        )
    }
}

export default connect(
    (state: IApplicationState, props: ISocialPollProps): ISocialPollProps => ({
        ...props,

        ownId: state.profile.active.id
    })
)(SocialPoll);