import { Reducer } from "redux";
import * as Actions from "./actions";
import { produce } from "immer";
import { ActionTypes } from "./action-types";
import generateUniqueId, { generateUniqueNumberId } from "src/utils/uniqueId";

export interface IInformationFeedState {
    isLoading: boolean;
    isLoadingMore: boolean;
    hasMore: boolean;
    items: Spintr.IInformationFeedItem[];
    params: any;
    categories: any[];
    hasFetchedCategories: boolean;
    importantArticles: Spintr.IInformationFeedItem[];
    hasFetchedImportantArticles: boolean;
    isLoadingImportantArticles: boolean;
}

const initialState: IInformationFeedState = {
    isLoading: true,
    isLoadingMore: false,
    hasMore: false,
    items: [],
    params: {},
    categories: [],
    hasFetchedCategories: false,
    importantArticles: [],
    hasFetchedImportantArticles: false,
    isLoadingImportantArticles: false
};

const informationFeedReducer: Reducer<IInformationFeedState, Actions.InformationFeedAction> =
    (state = initialState, action) => produce(state, draft => {
        switch (action.type) {
            case ActionTypes.FetchInformationFeedPending:
                if (action.meta.silent) {
                    return;
                }

                if (action.meta.params.skip > 0) {
                    draft.isLoadingMore = true;
                } else {
                    draft.isLoading = true;
                }
                break;

            case ActionTypes.FetchInformationFeedFulfilled:
                if (action.meta.isRSS) {
                    action.payload.data = (action.payload.data as Spintr.IRssFeedEntry[]).map((item) => ({
                        allowComments: true,
                        commentCount: 0,
                        content: item.text,
                        date: item.date.toString(),
                        id: generateUniqueNumberId(),
                        imageUrl: item.imageUrl,
                        isBlogPost: false,
                        likers: [],
                        priorityLevel: 1,
                        title: item.title,
                        url: item.url,
                        isFavourite: false,
                        isFollowing: false,
                        isRss: true,
                        rssName: item.feed.name
                    })) as Spintr.IInformationFeedItem[];
                }

                action.payload.data = action.payload.data as Spintr.IInformationFeedItem[];

                if (action.meta.params.skip > 0) {
                    draft.items = [...state.items, ...action.payload.data];
                } else {
                    draft.items = action.payload.data;
                }

                draft.params = action.meta.params;
                draft.hasMore = action.payload.data.length === action.meta.params.take;
                draft.isLoading = false;
                draft.isLoadingMore = false;

                break;

            case ActionTypes.SetInformationFeedLoading:
                draft.isLoading = true;
                break;
            case ActionTypes.FetchContentCategoriesFulfilled:
                draft.hasFetchedCategories = true;
                draft.categories = action.payload.data.items;
                break;
            case ActionTypes.FetchImportantArticlesPending:
                draft.isLoadingImportantArticles = true;
                break;
            case ActionTypes.FetchImportantArticlesRejected:
                draft.isLoadingImportantArticles = false;
                break;
            case ActionTypes.FetchImportantArticlesFulfilled:
                draft.hasFetchedImportantArticles = true;
                draft.isLoadingImportantArticles = false;
                draft.importantArticles = action.payload.data;
                break;
            case ActionTypes.MarkAsReadPending:
                draft.items = draft.items.map((item: Spintr.IInformationFeedItem) => {
                    if (item.id === action.meta.uberId) {
                        item.isUnread = false;
                    }

                    return item;
                });

                draft.importantArticles = [...draft.importantArticles].filter(x => x.id !== action.meta.uberId);
                break;
            case ActionTypes.ClearInformationFeed:
                for (const [key, value] of Object.entries(initialState)) {
                    draft[key] = value;
                }

                break;
            case ActionTypes.RemoveImportantArticle:
                draft.importantArticles = [...draft.importantArticles].filter(x => x.id !== action.meta.id);
                break;
            case ActionTypes.AddImportantArticle:
                draft.importantArticles = [action.meta.item, ...state.importantArticles];
                break;
            case ActionTypes.AddItem:
                draft.items = [action.meta.item, ...state.items];
                break;
            case ActionTypes.UpdateItem:
                draft.items = draft.items.map((item: Spintr.IInformationFeedItem) => {
                    if (item.id === action.meta.item.id) {
                        return action.meta.item;
                    }

                    return item;
                });

                if (action.meta.item.priorityLevel > 3) {
                    draft.importantArticles = draft.importantArticles.map((item: Spintr.IInformationFeedItem) => {
                        if (item.id === action.meta.item.id) {
                            return action.meta.item;
                        }

                        return item;
                    });
                }

                break;
        }
    });

export default informationFeedReducer;