import { AxiosResponse, CancelToken } from "axios";
import SpintrApi from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/assistant";

interface ICompletionResponse {
    completion: string;
}

interface ICompletionRequestParams {
    text: string;
    completionType: Spintr.CompletionType;
    language?: Spintr.Language;
}

interface ITonalityCompletionRequestParams extends ICompletionRequestParams {
    parameters: { mode: number; }
}

interface ISummarizationCompletionRequestParams extends ICompletionRequestParams {
    parameters: { type: number; }
}

interface IExpansionCompletionRequestParams extends ICompletionRequestParams {
    parameters: { length: number; }
}

type CompletionRequestParams
    = ICompletionRequestParams
    | ITonalityCompletionRequestParams
    | ISummarizationCompletionRequestParams
    | IExpansionCompletionRequestParams;

export const getCompletion =
    (requestBody: CompletionRequestParams, cancelToken: CancelToken): Promise<AxiosResponse<ICompletionResponse>> =>
        SpintrApi.post<ICompletionResponse>(
            baseUrl,
            requestBody,
            { cancelToken, },
        );
