export interface ILikeType {
    id: number,
    name: string,
    icon: string,
    json: string;
    color: string
}

export const likeTypes: ILikeType[] = [{
    id: 0,
    name: "Gilla",
    icon: "/images/reactions/sparkling_heart.svg",
    json: "/images/reactions/sparkling_heart.json",
    color: "#db0916"
}, {
    id: 1,
    name: "fa-thumbs-o-up",
    icon: "/images/reactions/thumbs_up.svg",
    json: "/images/reactions/thumbs_up.json",
    color: "#f7b125"
}, {
    id: 2,
    name: "Fira",
    icon: "/images/reactions/clapping_hands.svg",
    json: "/images/reactions/clapping_hands.json",
    color: "#f7b125"
}, {
    id: 5,
    name: "Omtanke",
    icon: "/images/reactions/smiling_face_with_hearts.svg",
    json: "/images/reactions/smiling_face_with_hearts.json",
    color: "#db0916"
}, {
    id: 3,
    name: "Haha",
    icon: "/images/reactions/face_with_tears_of_joy.svg",
    json: "/images/reactions/face_with_tears_of_joy.json",
    color: "#f7b125"
}, {
    id: 4,
    name: "Ledsen",
    icon: "/images/reactions/frowning_face.svg",
    json: "/images/reactions/frowning_face.json",
    color: "#f7b125"
}];

likeTypes.forEach((image) => {
    new Image().src = image.icon
});