import React, { useMemo } from 'react';
import "./ComposerAttachmentImage.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { SpintrTypes } from 'src/typings';
import { localize } from 'src/l10n';

interface IProps {
    attachment: Spintr.IChatMessageAttachment;
    onRemove: () => void;
}

const ComposerAttachmentImage = (props: IProps) => {
    const url = useMemo(() => {
        if (props.attachment.thumbnailUrl) {
            return props.attachment.thumbnailUrl;
        }

        if (props.attachment.path) {
            return props.attachment.path;
        }

        if (props.attachment.data) {
            return URL.createObjectURL(props.attachment.data);
        }

        return "";
    }, [props.attachment]);

    if (!url) {
        return null;
    }

    return (
        <div className="ComposerAttachmentImage">
            <img src={url} />
            <UnstyledButton className="close-button visage-box-shadow" onClick={props.onRemove} title={localize("TaBort")}>
                <Visage2Icon icon="close-circle" />
            </UnstyledButton>
        </div>
    )
}

export default ComposerAttachmentImage;
