import moment from "moment";
import { localize } from "src/l10n";

const expandDetails = (window: Window) => {
    const details = window.document.querySelectorAll('details')
    Array.from(details)
        .filter(detail => detail.classList.contains('print-open'))
        .map(d => {
            d.setAttribute('open', '');
        })
}

export const print = (instanceName: string, title?: string, versionNumber?: number) => {
    const printWindow = window.open("#", "_blank");

    printWindow.document.write('<html><head><title></title>');
    printWindow.document.write('<link href="/style/print.css" rel="stylesheet"/>');
    printWindow.document.write("</head>");
    printWindow.document.write('<table style="width: 100%;"><thead><tr><td class="printhead" colspan="3"><span style="font-size: 20px; margin-top: 10px;">' + instanceName + '</span></td></tr></thead><tbody><tr><td class="printbody" colspan="3">' +
        '<h2 class="label-component fs-h2">' + title + '</h1>' +
        '</td></tr></tbody><tfoot><tr>' +
        '<td class="printfooter printfooterleft">' + window.location.host + '</td>' +
        '<td class="printfooter printfootercenter">' + moment().format("YYYY-MM-DD") + '</td>' +
        '<td class="printfooter printfooterright">' + (versionNumber ? localize('Version') + ' #' + versionNumber : '') + '</td>' +
        '</tr></tfoot>')

    const head = printWindow.document.querySelector("head");
    const body = printWindow.document.querySelector("body");



    const styleElements = document.querySelectorAll("style");

    for (let i = 0; styleElements.length > i; i++) {
        //@ts-ignore
        head.insertAdjacentHTML("beforeend", styleElements[i].outerHTML);

        const style = head.querySelector("style:last-child");
        // @ts-ignore
        for (let y = 0; styleElements[i].sheet.rules.length > y; y++) {
            // @ts-ignore
            style.sheet.insertRule(styleElements[i].sheet.rules[y].cssText, y);
        }
    }

    const printContainer = document.querySelector(".print-container");
    const printBody = body.querySelector(".printbody");
    printBody.insertAdjacentHTML("beforeend", printContainer.innerHTML);

    printWindow.opener.focus();
    printWindow.document.close();
    expandDetails(printWindow);
    setTimeout(() => printWindow.print(), 0);
};

export const pdf = (PDFHeader: string, versionNumber?: number) => {
    const printContainer = document.querySelector(".print-container");
    const html = printContainer.innerHTML;
    const styleElements = document.querySelectorAll("style");
    const pageHeaderImage = document.querySelector(".pageHeaderImage")?.querySelector("img")?.outerHTML ?? "";
    
    let style = "";
    for (let i = 0; styleElements.length > i; i++) {
        let newStyle = '<style>'
        // @ts-ignore
        for (let y = 0; styleElements[i].sheet.rules.length > y; y++) {
            // @ts-ignore
            newStyle += styleElements[i].sheet.rules[y].cssText;
        }
        newStyle += '</style>';
        style += newStyle;
    }

    style += "<style>img { max-width: 100%; }</style>"

    const formContainer = document.createElement("div");
    formContainer.innerHTML = '<form action="/api/servefile/pdf" method="post" style="display: none;">' +
        '<textarea name="header" style="display: none;">' + pageHeaderImage + (typeof (PDFHeader) === 'undefined' ? localize('Utskrift') : PDFHeader) + '</textarea>' +
        '<textarea name="content" style="display: none;">' + html.replace(/</g, "&lt;").replace(/>/g, '&gt;') + '</textarea>' +
        '<textarea name="style" style="display: none;">' + style + '</textarea>' +
        '<input type="hidden" name="versionNumber" style="display: none;" value="' + versionNumber || 0 + '" />' +
        '</form>';
    const form = formContainer.querySelector("form");

    // @ts-ignore
    document.body.insertAdjacentElement("beforeend", form).submit();
}