const throttler = (handler, time, thisArg, timeout, blocking) => {
    var args, context, lastCalled = 0, ref = null
    const ms = time == null ? 100 : time

    const guardedCallback = () => {
        lastCalled = Date.now()

        if (!args) {
            return (ref = null)
        }

        let callback = ((args) => handler.apply(context, args)).bind(null, args)

        callback()
        args = null
        ref = timeout(guardedCallback, ms)
    }
    
    function callback() {
        args = arguments
        context = this
    
        if (thisArg !== undefined) {
            context = thisArg
        }
    
        if (ref === null || Date.now() - lastCalled > ms) {
            if (blocking) {
                guardedCallback()
            } else {
                ref = timeout(guardedCallback, 0)
            }
        }  
    }

    return callback
}

const throttle = (handler, time, thisArg) => {
    return throttler(handler, time, thisArg, setTimeout, false)
}

Object.assign(throttle, {
    withBlocking: (handler, time, thisArg) => {
        return throttler(handler, time, thisArg, setTimeout, true)
    }
})

export default throttle
