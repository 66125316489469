import { Text } from "@fluentui/react";
import React, { Component, CSSProperties, ReactNode } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ExternalFile } from "src/chat/components/ExternalFile";
import localize from "src/l10n/localize";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import TranslateButton from "src/ui/components/TranslateButton";
import { Style } from "src/ui/helpers";
import { capitalizeFirstLetter, decodeHtmlEntities, formatInteractiveText, shortenString } from "src/utils";
import { SocialPostContainer } from "../SocialPostContainer";
import "./SocialStatus.scss";

const defaultFonts: string[] = [
    "\"Roboto\", sans-serif",
    "\"Merriweather\", serif",
    "\"Indie Flower\", cursive",
    "\"Pacifico\", cursive",
];

interface ISocialStatusProps {
    isShare?: boolean;
    post: Spintr.ISocialStatus;
}

interface ISocialStatusState {
    postText: string;
}
class SocialStatus extends Component<ISocialStatusProps, ISocialStatusState> {
    constructor(props: ISocialStatusProps) {
        super(props);
        
        this.state = {
            postText: this.props.post.Text,
        }

    }

    public render() : ReactNode {
        const content : ReactNode = this.getStatusNode();

        return (
            <div className="SocialStatus">
                {content}
            </div>
        );
    }

    protected getStatusNode() :ReactNode {
        const { StatusType } = this.props.post;

        switch (StatusType) {
            case 2:
                return this.renderSharedPost();

            default: 
                return null;

            case 4:
                return this.renderStatusFrame();

            case 5:
                return this.renderExternalFile();
        }
    }

    protected renderExternalFile(): ReactNode {
        return (
            <div>
                {!!this.props.post.externalFiles && this.props.post.externalFiles.length > 0 && (
                    <div className="external-file-wrapper">
                        {
                            this.props.post.externalFiles.map((ef, i) => {
                                return (
                                    <div key={i}>
                                        <ExternalFile externalId={ef.externalId} source={ef.source} />
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            </div>
        )
    }

    protected renderShareText(share: Spintr.ISocialStatusShare): ReactNode {
        return (
            <div
                className="share-text"
                style={{
                    marginTop: share.Text && share.Text.length > 0 ? Style.getSpacing(3) : 0,
                }}
            >
                {share.UberIsDeleted ? (
                    <Label as="p" color="mid-grey" size="body-2">{localize("DetDeladeInnehalletHarTagitsBort")}</Label>
                ) : (
                    <Label as="p" color="mid-grey" size="body-2">
                        <span>{capitalizeFirstLetter(localize("Delade")) + " "}</span>
                        <Link className={"linkFGColor"} to={`/goto/${share.Share.Id}`}>
                            {localize("Ubertype" + share.Share.Type + "_0_0")}
                        </Link>
                        {/* {share.Share.UserName && (
                            <span>
                                <span>{" " + localize("Av_By").toLowerCase() + " "}</span>
                                <Link className={"linkFGColor"} to={`/goto/${share.Share.UserId}`}>
                                    {share.Share.UserName}
                                </Link>
                            </span>
                        )} */}
                    </Label>
                )}
            </div>
        )
    }

    protected renderShareObject(share: Spintr.ISocialStatusShare): ReactNode {
        if (!share.Share) {
            return null;
        }

        if (!!share.Share.pageTitle &&
            share.Share.pageTitle.length > 0) {
            const bodyText = !!share.Share.pagePreamble && share.Share.pagePreamble.length > 0 ?
                share.Share.pagePreamble :
                share.Share.pageBody;

            return (
                <div className="shared-post shared-content-post">
                    {
                        share.Share.pageImage && (
                            <div>
                                <img src={share.Share.pageImage} alt="" />
                            </div>
                        )
                    }
                    <Label className="shared-content-post-title">
                        <Link
                            className={"linkFGColor general-row-break"}
                            to={`/goto/${share.Share.Id}`}
                        >
                            {
                                share.Share.pageTitle
                            }
                        </Link>
                    </Label>
                    {
                        bodyText && bodyText.length > 0 && (
                            <Label size="body-2" className="general-row-break">
                                {
                                    shortenString(decodeHtmlEntities(bodyText), 150, "...")
                                }
                            </Label>
                        )
                    }
                </div>
            )
        }

        return (
            <div className="shared-post">
                <SocialPostContainer
                    isShare={true}
                    post={share.Share}
                />
            </div>
        )
    }

    protected renderSharedPost(): ReactNode {
        const share = this.props.post as Spintr.ISocialStatusShare;

        return (
            <div className="share-wrapper">
                {
                    this.renderShareText(share)
                }
                {
                    !this.props.isShare && !share.UberIsDeleted && this.renderShareObject(share)
                }
            </div>
        )
    }

    protected renderStatusFrame(): ReactNode {
        const post = null;
        if (!post) {
            return null;
        }

        const { background, font } = post;
        const style: CSSProperties = {};

        // if (background.type === SpintrTypes.StatusFrameBackground.Gradient) {
        //     const gradientBg = background as Spintr.ISocialStatusFrameGradientBackground;

        //     style.backgroundImage = `linear-gradient(${gradientBg.start.color} 0%, ${gradientBg.stop.color} 100%)`;
        // }

        const fontStyle: CSSProperties = {};
        fontStyle.fontFamily = defaultFonts[font - 1];
        fontStyle.lineHeight = "35px";

        return (
            <div className="status-frame ignore-custom-font">
                <div className="stage-wrapper">
                    <div className="stage" style={style}>
                        <Text
                            as="p"
                            className="text general-row-break interactive-text"
                            style={fontStyle}
                            variant="xLarge"
                        >
                            {formatInteractiveText(this.state.postText)}
                        </Text>
                    </div>
                </div>
                <TranslateButton authorLanguage={this.props.post.UserLanguage} uberId={this.props.post.Id} text={this.props.post.Text} onTranslateFn={this.onTranslate} />
            </div>
        );
    }

    protected onTranslate = (text: string): void => {
        this.setState({ postText: text })
    }
}

export default SocialStatus;