import classnames from "classnames";
import addClass from "dom-helpers/addClass";
import removeClass from "dom-helpers/removeClass";
import scrollbarSize from "dom-helpers/scrollbarSize";
import getWidth from "dom-helpers/width";
import dompurify from "dompurify";
import moment from "moment";
import { DefaultButton, Icon, Modal, Separator } from "@fluentui/react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import localize from "src/l10n/localize";
import { IApplicationState } from "src/spintr/reducer";
import { Label } from "src/ui";
import { FormControl } from "src/ui/components/Forms";
import { capitalizeFirstLetter, isAnythingDirty, truncate } from "src/utils";
import { renderUnsavedChangesDialog } from "src/utils/renderUnsavedChangesDialog";
import { joinEvent, leaveEvent, setCalendarPopup } from "../actions";
import CalendarAPI from "../calendar-api";
import { getAvailableSeatsString, getDateString, isRegistrationAvailable } from "../util";
import CalendarEventView from "./CalendarEventView";
import "./SpintrAgenda.scss";
import { navigate } from "./utils/constants";
import * as dates from "./utils/dates";
import { inRange } from "./utils/eventLevels";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const sanitizer = dompurify.sanitize;

const renderDateSpan = (event) => {
    return getDateString(event);
};

const renderDateBox = (event) => {
    return (
        <div className="date-box">
            <Label className="date" as="p" size="body-2" color="grey">
                {moment(event.start).format("DD MMM YYYY")}
            </Label>
            {/* {event.start !== event.end &&
                <Label className="date">{moment(event.end).format("DD MMM YYYY")}</Label>
            } */}
            {/* <Label className="month">{capitalizeFirstLetter(moment(event.start).format("MMM"))}</Label> */}
        </div>
    );
};

const renderAttendingButtons = (dispatch, userId, event) => {
    const hasJoined = event.attendees.some((attendee) => attendee.id === userId && attendee.status === 1);

    return (
        <div className="attending-buttons">
            <FormControl>
                <DefaultButton
                    styles={{ icon: { color: "green" }, root: { marginRight: "6px" } }}
                    disabled={!hasJoined}
                    iconProps={!hasJoined && { iconName: "Accept" }}
                    onClick={async () => {
                        const response = await CalendarAPI.leaveEvent(userId, event.id);
                        dispatch(leaveEvent(event.id, userId, response));
                    }}
                >
                    {localize("KommerInte")}
                </DefaultButton>
            </FormControl>
            <FormControl>
                <DefaultButton
                    styles={{ icon: { color: "green" } }}
                    iconProps={hasJoined && { iconName: "Accept" }}
                    disabled={hasJoined || !isRegistrationAvailable(event)}
                    onClick={async () => {
                        const response = await CalendarAPI.joinEvent(userId, event.id, true);
                        dispatch(joinEvent(event.id, userId, response));
                    }}
                >
                    {localize("Kommer")}
                </DefaultButton>
            </FormControl>
        </div>
    );
};

const renderParticipants = (event) => {
    return (
        // FIXME: LINK
        <>
            <Label size="body-3" className="participants">
                {
                    event.attendees.filter((a) => {
                        return a.status === 1;
                    })?.length
                }{" "}
                {localize("Deltagare")}
            </Label>
            <Label size="body-3" className="seats-left">
                {getAvailableSeatsString(event)}
            </Label>
        </>
    );
};

function SpintrAgenda({ selected, getters, accessors, localizer, components, length, date, events }) {
    const headerRef = useRef(null);
    const dateColRef = useRef(null);
    const timeColRef = useRef(null);
    const contentRef = useRef(null);
    const tbodyRef = useRef(null);
    const selectUserId = (state: IApplicationState) => state.profile.active.id;
    const currentUserId = useSelector(selectUserId);
    const dispatch = useDispatch();
    const [showEvent, setShowEvent] = useState(undefined);
    const [displayUnsavedChangesPopup, setDisplayUnsavedChangesPopup] = useState(false);
    const location = useLocation();
    const isGroupView = location.pathname.indexOf("/groups/") > -1;

    useEffect(() => {
        _adjustHeader();
    });

    const openEvent = (event) => {
        if (event.exchangeId || event.googleId) {
            dispatch(setCalendarPopup({ isOpen: true, event }));
        } else {
            setShowEvent(event);
        }
    };

    const renderAgendaItems = () => {
        const eventsByDate = groupEventsByDate(events);

        return Object.keys(eventsByDate).map((date, idx) => {
            const isToday = moment(date).isSame(moment(), 'day');
            const isTomorrow = moment(date).isSame(moment().add(1, 'day'), 'day');
            return (
                <div key={`agenda-row-container-${idx}`}>
                    <div className="date-label">
                        {isToday && (
                            <Label weight="medium">
                                {localize("Idag")}
                            </Label>
                        )}
                        {isTomorrow && (
                            <Label weight="medium">
                                {localize("Imorgon")}
                            </Label>
                        )}
                        <Label color="grey" style={{ marginLeft: 8 }}>
                            {moment(date).format("LL")}
                        </Label>
                    </div>
                    <div className="agenda-row">
                        {eventsByDate[date].map((event, eventIdx) => (
                            <div className="agenda-item" key={`agenda-item-${date}-${eventIdx}`}>
                                <div
                                    onClick={() => {
                                        openEvent(event);
                                    }}
                                    className={classnames("top", {
                                        ["with-image"]: event.imageUrl,
                                    })}
                                    style={{
                                        backgroundImage: event.imageUrl && `url(${event.imageUrl})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center"
                                    }}
                                >
                                    <div className="gradient-overlay"></div>
                                </div>

                                <div className="middle" onClick={() => {
                                    openEvent(event);
                                }}>
                                    {!!event.tags && event.tags.length > 0 &&
                                        <div className="calendar-event-tags">
                                            {event.tags.map((tag) => {
                                                return (
                                                    <div className="calendar-event-tag">
                                                        <Label weight="medium" size="body-2">
                                                            {tag}
                                                        </Label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    {renderDateBox(event)}
                                    <Label className="title" size="h4" weight="medium">
                                        {event.title}
                                    </Label>
                                    <div className="date-span">
                                        <Label as="p" size="body-2" color="grey">
                                            {renderDateSpan(event)}
                                        </Label>
                                        {event.place && event.place !== "" && event.place !== "-" && (
                                            <Label as="p" size="body-2" color="grey">
                                                {event.place}
                                            </Label>
                                        )}
                                    </div>
                                    {event.text && (
                                        <Label size="body-1" className="description general-row-break">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: truncate(sanitizer(event.text, { ALLOWED_TAGS: [] }), 250),
                                                }}
                                            ></p>
                                        </Label>
                                    )}
                                </div>
                                <div className="bottom">
                                    {!!event.enableParticipation && !!event.seats && (
                                        <>
                                            <Separator className="agenda-separator" />
                                            <>
                                                {renderAttendingButtons(dispatch, currentUserId, event)}
                                                {renderParticipants(event)}
                                                {event.registrationClose && (
                                                    <>
                                                        <Label
                                                            className="calendarEventHeader"
                                                            as="div"
                                                            role="heading"
                                                            size="small-2"
                                                            color="dark-grey"
                                                            weight="medium"
                                                            style={{
                                                                textTransform: "uppercase",
                                                                marginTop: 12
                                                            }}
                                                        >
                                                            {localize("CALENDAR_ACTIVITY_LAST_REGISTRATION")}
                                                        </Label>
                                                        <Label as="p" size="body-3">
                                                            {moment(event.registrationClose).format("L")}{" "}
                                                            {moment(event.registrationClose).format("LT")}
                                                        </Label>
                                                    </>
                                                )}
                                            </>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        });
    };

    const groupEventsByDate = (events) => {
        const groupedEvents = {};

        events.forEach((event) => {
            const dateKey = moment(event.start).format("YYYY-MM-DD");
            if (!groupedEvents[dateKey]) {
                groupedEvents[dateKey] = [];
            }

            groupedEvents[dateKey].push(event);
        });

        return groupedEvents;
    };

    const _adjustHeader = () => {
        if (!tbodyRef.current) return;

        let header = headerRef.current;
        let firstRow = tbodyRef.current.firstChild;

        if (!firstRow) return;

        let isOverflowing = contentRef.current.scrollHeight > contentRef.current.clientHeight;

        let _widths = [];
        let widths = _widths;

        _widths = [getWidth(firstRow.children[0]), getWidth(firstRow.children[1])];

        if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
            dateColRef.current.style.width = _widths[0] + "px";
            timeColRef.current.style.width = _widths[1] + "px";
        }

        if (isOverflowing) {
            addClass(header, "rbc-header-overflowing");
            header.style.marginRight = scrollbarSize() + "px";
        } else {
            removeClass(header, "rbc-header-overflowing");
        }
    };

    let { messages } = localizer;
    let end = dates.add(date, length, "day");

    let range = dates.range(date, end, "day");

    events = events
        .filter((event) => inRange(event, date, end, accessors))
        .filter((e) => {
            if (!e.delted) return e;
        });

    events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

    return (
        <>
            <Separator />
            <div
                className={classnames("SpintrAgenda", {
                    ["group-view"]: isGroupView,
                })}
            >
                {showEvent && (
                    <Modal
                        className="spintr-modal CalendarEventViewModal"
                        isOpen={showEvent !== undefined}
                        onDismiss={() => {
                            if (isAnythingDirty()) {
                                return setDisplayUnsavedChangesPopup(true);
                            }

                            setShowEvent(undefined);
                        }}
                    >
                        <CalendarEventView
                            id={showEvent.id}
                            onDismiss={() => {
                                setShowEvent(undefined);
                            }}
                        />
                    </Modal>
                )}
                {events.length !== 0 ? (
                    <>{renderAgendaItems()}</>
                ) : (
                    <span className="rbc-agenda-empty">
                        <Visage2Icon icon={"calendar"} /> {messages.noEventsInRange}
                    </span>
                )}

                {renderUnsavedChangesDialog(
                    displayUnsavedChangesPopup,
                    () => {
                        setDisplayUnsavedChangesPopup(false);
                    },
                    () => {
                        setDisplayUnsavedChangesPopup(false);
                        setShowEvent(undefined);
                    }
                )}
            </div>
        </>
    );
}

SpintrAgenda.propTypes = {
    events: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number.isRequired,

    selected: PropTypes.object,

    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
};

SpintrAgenda.defaultProps = {
    length: 30,
};

SpintrAgenda.range = (start, { length = SpintrAgenda.defaultProps.length }) => {
    let end = dates.add(start, length, "day");
    return { start, end };
};

SpintrAgenda.navigate = (date, action, { length = SpintrAgenda.defaultProps.length }) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -length, "day");

        case navigate.NEXT:
            return dates.add(date, length, "day");

        default:
            return date;
    }
};

SpintrAgenda.title = (start, { length = SpintrAgenda.defaultProps.length, localizer }) => {
    let end = dates.add(start, length, "day");
    return localizer.format({ start, end }, "agendaHeaderFormat");
};

export default SpintrAgenda;
