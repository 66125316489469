export const fileTypeToExtensionMap = {
    1: "default",
    2: "pdf",
    3: "mp3",
    4: "jpg",
    5: "xls",
    6: "xlsx",
    7: "doc",
    8: "docx",
    9: "png",
    10: "ppt",
    11: "pptx",
    12: "zip",
    13: "gif",
    14: "wav",
    15: "rtf",
    16: "html",
    17: "txt",
    18: "csv",
    19: "pps",
    20: "ods",
    21: "odt",
    22: "odp",
    23: "sxw",
    24: "sxc",
    25: "sxi",
};

// TODO: Move this inside a constructor or something.
// this will run before any localization has been loaded
export const fileTypeToName = {
    1: "Fil",
    2: "Pdf",
    3: "Ljud",
    4: "Bild",
    5: "Excel",
    6: "Excel",
    7: "Word",
    8: "Word",
    9: "Bild",
    10: "PPT",
    11: "PPT",
    12: "ZIP",
    13: "Gif",
    14: "Ljud",
    15: "rtf",
    16: "Html",
    17: "Text",
    18: "CSV",
    19: "PPS",
    20: "ODS",
    21: "ODT",
    22: "ODP",
    23: "SXW",
    24: "SXC",
    25: "SXI",
};

export const getFileIconUrl = (type: number): string => {
    const extension = Object.prototype.hasOwnProperty.call(fileTypeToExtensionMap, type)
        ? fileTypeToExtensionMap[type]
        : fileTypeToExtensionMap[1];

    return `/Style/Icons/small/${extension}.png`;
};

export const checkFileExtension = (file: File, restrictedExtensions: string): boolean => {
    if (!restrictedExtensions || restrictedExtensions.length === 0) {
        return false;
    }

    const extensions = restrictedExtensions.split(",");

    if (extensions.some((extension) => file.name.endsWith(extension))) {
        return true;
    }

    return false;
};

export const hitEnterOrSpace = (e, fn) => {
    if ([13, 32].some((k) => e.keyCode === k)) {
        fn(e);
    }
};