import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { postQuestionAction, setActiveSubjects } from "../../actions";
import { UnstyledButton } from "src/ui";
import { localizeFormat } from "src/l10n";
import { Icon } from "@fluentui/react";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";

interface IProps {
    assistantName: string;
    disabled?: boolean;
    history?: any;
    activeSubjectDate: Date;
}

const AssistantInput: FunctionComponent<IProps> = ({ assistantName, disabled, history, activeSubjectDate }) => {
    const inputRef = useRef<HTMLInputElement>();

    const [question, setQuestion] = useState("");
    const dispatch = useDispatch();

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setQuestion(event.target.value),
        [setQuestion],
    );

    const onLabelClick = useCallback(
        () => inputRef?.current?.focus && inputRef.current.focus(),
        [inputRef],
    );

    const submitQuestion = useCallback(
        () => {
            if (disabled || question.length === 0) {
                return;
            }

            dispatch(postQuestionAction(question, history.filter(x => x.date > activeSubjectDate).map((m: any, index: number) => {
                return {
                    ...m,
                    content: m.text
                }
            })));
            setQuestion("");
        },
        [disabled, question, setQuestion],
    )

    const onSubmit = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault && event.preventDefault();

            submitQuestion();
        },
        [submitQuestion],
    );

    useEffect(
        () => inputRef.current.focus(),
        [inputRef.current],
    );

    useEffect(() => {
        const recentMessages = history.filter(x => x.date > activeSubjectDate).map((m: any, index: number) => {
            return {
                ...m,
                content: m.text
            }
        });

        api.post("/api/v1/assistant/subject", {message: "", history: (recentMessages || [])}).then((response) => {
            dispatch(setActiveSubjects(response.data));
        }).catch(() => {});
    }, [history]);

    return (
        <div className="AssistantInput">
            <form
                action="#"
                method="POST"
                className="form-skip-dirty-check"
                onSubmit={onSubmit}
            >
                <label
                    className="input-wrapper"
                    onClick={onLabelClick}
                >
                    <input
                        aria-aria-label={localizeFormat("AI_ASSISTANT_PLACEHOLDER_F", assistantName)}
                        autoComplete="off"
                        name="question"
                        onChange={onChange}
                        placeholder={localizeFormat("AI_ASSISTANT_PLACEHOLDER_F", assistantName)}
                        ref={inputRef}
                        type="text"
                        value={question}
                    />
                </label>
                <UnstyledButton
                    className="send-button primaryBGColor desaturatePrimaryBGColorHover primaryTextContrast"
                    onClick={submitQuestion}
                >
                    <Icon
                        iconName="Send"
                    />
                </UnstyledButton>
            </form>
        </div>
    );
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        history: state.assistant.messages,
        activeSubjectDate: state.assistant.activeSubjectDate
    };
};

export default connect(mapStateToProps)(AssistantInput);
