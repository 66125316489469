import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFormFooterBarVisible } from "src/ui/actions";

export interface IFloatingFooterBarProps {
    className?: string;
}

const FloatingFooterBar: FunctionComponent<IFloatingFooterBarProps> = (props) => {
    const dispatch = useDispatch();

    const className = "FloatingFooterBar" + (props.className ? (" " + props.className) : "");

    useEffect(() => {
        dispatch(setFormFooterBarVisible(true));
        
        return () => dispatch(setFormFooterBarVisible(false));
    }, [dispatch]);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

export default FloatingFooterBar;