import {
    DefaultButton, Dropdown,
    Modal, PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import { Loader } from "src/ui";
import { FormControl, FormSection } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import PopupHeader from "src/ui/components/PopupHeader";
import { isAnythingDirty, validateRequiredTextField } from "src/utils";
import { MandatoryText } from "../../ui/components/Forms";
import { saveNewFile } from "../file-actions";
import "./CreateFilePopup.scss";

interface IProps {
    close: () => void;
    isOpen: boolean;
    currentUserId: number;
    folderId: number;
    source: number;
    isLoading: boolean;
    group?: any;
    dispatch: (action: Action) => void;
}

interface IState {
    selectedItem: string;
    name: string;
    enableFieldValidation?: boolean;
    isLoading: boolean;
    saveError?: any;
}

class CreateFilePopup extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: "1",
            name: "",
            isLoading: false
        };
    }

    componentDidUpdate(prevProps: IProps, prevState) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.props.close();
        }
    }

    save = () => {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            if (!this.state.name ||
                this.state.name.length === 0) {
                this.setState({
                    isLoading: false,
                    saveError: ["DuMasteFyllaIEnRubrik"]
                });

                return;
            }

            const file = {
                name: this.state.name,
                type: this.state.selectedItem,
                folderId: this.props.folderId,
                source: this.props.source,
                externalId: this.props.group?.externalId,
            };

            this.props.dispatch(saveNewFile(file));
            this.setState({ isLoading: false })
        });
    };

    onCloseModal() {
        const close = () => {
            this.props.close();
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    render() {
        return (
            <Modal
                onDismiss={this.onCloseModal.bind(this)}
                isOpen={this.props.isOpen}
                className="spintr-modal modalWithPopupHeader">
                <PopupHeader
                    text={localize("SkapaNyFil")}
                    onClose={this.onCloseModal.bind(this)} />
                <div>
                    <MandatoryText />
                    {!!this.state.saveError && this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    {this.props.isLoading || this.state.isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <form>
                                <FormSection>
                                    <FormControl>
                                        <TextField
                                            label={localize("Namn")}
                                            required
                                            aria-required
                                            validateOnFocusIn
                                            validateOnFocusOut
                                            validateOnLoad={!!this.state.enableFieldValidation}
                                            onGetErrorMessage={validateRequiredTextField}
                                            onChange={(event, value) => {
                                                this.setState({
                                                    name: value,
                                                });
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Dropdown
                                            label={localize("Typ")}
                                            selectedKey={this.state.selectedItem}
                                            onChange={(event, item) => {
                                                this.setState({
                                                    selectedItem: item.id,
                                                });
                                            }}
                                            options={[
                                                {
                                                    text: "Word",
                                                    key: "1",
                                                    id: "1",
                                                },
                                                {
                                                    text: "Excel",
                                                    key: "2",
                                                    id: "2",
                                                },
                                                {
                                                    text: "Powerpoint",
                                                    key: "3",
                                                    id: "3",
                                                },
                                            ]}
                                        />
                                    </FormControl>
                                </FormSection>
                                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                    <DefaultButton text={localize("Avbryt")} onClick={this.onCloseModal.bind(this)} />
                                    <PrimaryButton text={localize("Spara")} onClick={this.save} />
                                </Stack>
                            </form>
                        </>
                    )}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        currentUserId: state.profile.active.id,
        isLoading: state.files.files.isLoadingNewFile,
    };
};

export default connect(mapStateToProps)(CreateFilePopup);
