import Immutable, { List, Map } from "immutable";
import {
    FETCH_MEDIA_CONTENT_PENDING,
    FETCH_MEDIA_CONTENT_REJECTED,
    FETCH_MEDIA_CONTENT_FULFILLED,
    FETCH_MEDIA_FOLDER,
    FETCH_MEDIA_FOLDER_FULFILLED,
    SET_MEDIA_TAG,
    SET_MEDIA_SEARCH,
    RESET_MEDIA_SEARCH_TAG,
    RESET_MEDIA_FOLDER_PATH,
    UPDATE_MEDIA_FOLDER,
} from "./media-action-types";
import { Action } from "redux";

class MediaReducerState extends Immutable.Record({
    error: Immutable.Map({ exists: false }),
    isLoadingMedia: false,
    isLoadingMoreMedia: false,
    folders: Immutable.List<Spintr.IMediaFolder>(),
    images: Immutable.List<Spintr.IMediaImage>(),
    totalCount: 0,
    tagId: 0,
    searchText: "",
    folderPath: [] as Spintr.IBreadcrumbItem[],
    userCanEditAndRemove: false,
    userCanUpload: false,
    editableType: false,
}) {}

const initialState = new MediaReducerState({});

const mediaReducer = (state: MediaReducerState = initialState, action) => {
    switch (action.type) {
        case FETCH_MEDIA_CONTENT_PENDING:
            return state.merge({
                error: initialState.get("error"),
                isLoadingMedia: action.meta.skip == 0,
                isLoadingMoreMedia: action.meta.skip > 0,
            });

        case FETCH_MEDIA_CONTENT_REJECTED:
            return state.merge({
                error: Immutable.Map({ exists: true }),
                isLoadingMedia: false,
            });

        case FETCH_MEDIA_CONTENT_FULFILLED:
            // const folders = action.payload.data.folders.map(i =>
            //     Map({
            //         ...i,
            //     })
            // );

            const folders = action.payload.data.folders.map(folder => {
                folder.date = new Date(folder.date);

                return folder;
            });
            //Immutable.fromJS(action.payload.data.folders);

            const images = action.payload.data.images.map(image => {
                image.date = new Date(image.date);

                return image;
            });

            return state.merge({
                error: initialState.get("error"),
                isLoadingMedia: false,
                isLoadingMoreMedia: false,
                folders: action.meta.skip > 0 ? state.get("folders").merge(List(folders)) : List(folders),
                images: action.meta.skip > 0 ? state.get("images").merge(List(images)) : List(images),
                totalCount: parseInt(action.payload.headers["x-total-count"]),
                tagId:
                    folders.length + images.length === 0 && state.get("searchText") == initialState.get("searchText")
                        ? initialState.get("tagId")
                        : state.get("tagId"), // reset tagId if no results and no searchText
            });
        //.mergeIn(["folders"], List(folders))
        //.mergeIn(["images"], List(images));

        case FETCH_MEDIA_FOLDER_FULFILLED:
            var data = action.payload.data as Spintr.IMediaFolder2;
            var path: Spintr.IBreadcrumbItem[] = [];

            var getParent = (folder: Spintr.IMediaFolder2Parent) => {
                if (folder == null) {
                    return;
                }

                if (folder.parent != null) {
                    getParent(folder.parent);
                }
                path.push({
                    text: folder.name,
                    key: folder.id.toString(),
                    link: `/media/folder/${folder.id}`,
                });
            };
            getParent(data.parent);

            path.push({
                text: data.name,
                key: data.id.toString(),
                link: `/media/folder/${data.id}`,
            });

            if (action.meta.image) {
                const image = action.meta.image as Spintr.IMediaImageImage;

                path.push({
                    text:
                        image.displayName ||
                        image.imageUrl
                            .split("/")
                            .slice(-1)[0]
                            .split("?")[0],
                    key: image.id.toString(),
                    link: `/media/image/${image.id}`,
                });
            }

            return state.merge({
                folderPath: path,
                userCanEditAndRemove: data.userCanEditAndRemove,
                userCanUpload: data.userCanUpload,
                editableType: data.editableType,
            });

        case UPDATE_MEDIA_FOLDER:
            const { id, name } = action.payload;

            var folderPath: Spintr.IBreadcrumbItem[] = [...state.get("folderPath")];
            var folderPathItem = folderPath.find(fp => fp.key == id.toString());

            if (!folderPathItem) {
                return state;
            }

            folderPathItem.text = name;

            return state.merge({
                folderPath,
            });

        case RESET_MEDIA_FOLDER_PATH:
            return state.merge({
                folderPath: initialState.get("folderPath"),
                userCanEditAndRemove: initialState.get("userCanEditAndRemove"),
                userCanUpload: initialState.get("userCanUpload"),
            });

        case SET_MEDIA_TAG:
            return state.merge({
                tagId: action.payload,
            });

        case SET_MEDIA_SEARCH:
            return state.merge({
                searchText: action.payload,
            });

        case RESET_MEDIA_SEARCH_TAG:
            return state.merge({
                tagId: initialState.get("tagId"),
                searchText: initialState.get("searchText"),
            });
        default:
            return state;
    }
};

export default mediaReducer;
