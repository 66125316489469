import api from "src/spintr/SpintrApi";

interface IOffice365FileSearchParams {
    ignoreEmptyQuery?: boolean;
    orderByLatest?: boolean;
    query: string;
    serviceType: number;
    skip?: number;
    take?: number;
}

export const searchFiles = 
    async (reqParams: Partial<IOffice365FileSearchParams>): 
        Promise<Spintr.IOffice365FileSearchEnvelope> => 
    {
        const params: IOffice365FileSearchParams = {
            orderByLatest: false,
            query: "",
            serviceType: 2,
            skip: 0,
            take: 0,

            ...reqParams
        };

        const response = await api.get<Spintr.IOffice365FileSearchEnvelope>(
            "/api/v1/search/office365/files", { params }
        );

        return {
            ...response.data,
            items: response.data.items.map(item => ({
                ...item,
                created: new Date(item.created),
                lastEdited: new Date(item.lastEdited),
            }))
        };
    };