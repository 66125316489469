import api from "src/spintr/SpintrApi";

import { AppsMessageTypes, IFetchAppsAction, IUpdateAppAction } from "./action-types";

const baseUrl = "/api/v1/apps";

export type FetchAppsActionCreator = () => IFetchAppsAction;
export const fetchApps: FetchAppsActionCreator = () => ({
    payload: api.get<Spintr.ISystemApp>(baseUrl, { params: { includeMSystem: true } }),
    type: AppsMessageTypes.FetchApps
});

export type UpdateAppActionCreator = (id: number, enabled: boolean) => IUpdateAppAction
export const updateApp: UpdateAppActionCreator = (id, enabled) =>({
    appId: id,
    enabled,
    type: AppsMessageTypes.UpdateApp,
});

const AppActions = {
    fetchApps,
    updateApp,
};

export default AppActions;
