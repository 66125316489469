import React, { MouseEvent, ReactNode } from 'react';
import { hitEnterOrSpace } from 'src/files/utils';
import { localize } from 'src/l10n';
import api from 'src/spintr/SpintrApi';
import { DropZone } from 'src/spintr/components';
import { Label, UnstyledButton } from 'src/ui';
import { uniqueId } from 'src/utils';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { ComposerContext } from './ComposerContext';
import { IComposerImageContent } from "./IComposerViewState";
import './SocialComposerImages.scss';
import SocialComposerType, {
    IComposerSaveOptions,
    ISocialComposerTypeProps,
} from './SocialComposerType';

const defaultContentState: IComposerImageContent = {
    images: [],
};

class SocialComposerImages extends SocialComposerType<ISocialComposerTypeProps, never> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;

    protected imageRef = React.createRef<HTMLInputElement>();

    constructor(props: ISocialComposerTypeProps) {
        super(props);

        this.onRemoveClicked = this.onRemoveClicked.bind(this);
        this.onFilesSelected = this.onFilesSelected.bind(this);
        this.onDismissTypeClick = this.onDismissTypeClick.bind(this);
    }

    public validate() {
        const contentState = this.getContentState();
        const images = (contentState?.images || []).slice();

        const result = [];
        if (!images || images.length === 0) {
            result.push("DuMasteValjaMinstEnBild");
        }

        return result;
    }

    public async saveContent(options: IComposerSaveOptions): Promise<Spintr.ISocialPostBase | null> {
        const tickets = [];
        
        const contentState = this.getContentState();
        const images = (contentState?.images || []).slice();

        images.forEach((img) => {
            tickets.push(img.imageTicket.ticket);
        });

        try {
            const model = {
                text: options.text,
                feedId: options.feedId,
                flag: options.flag,
                content: [{
                    description: options.text,
                    feedId: options.feedId,
                    galleryId: options.galleryId,
                    tickets,
                    typeDiscriminator: 2,
                }],
            };

            const formData = new FormData();
            formData.append("jsonString", JSON.stringify(model));

            const response = await api.post<any>("/api/v1/status", formData,  { headers: {
                ...(options.identity?.id ? {"x-impersonate-user": options.identity?.id} : {})
            }});

            if (!response || !response.data) {
                throw localize("TeknisktFel");
            }

            return response.data;
        } catch (err) {
            console.log(err);

            throw err;
        }

        return null;
    }

    /*
    triggerUpload = (e) => {
        var code = e.which;
        if (code === 13 || code === 32) {
            // 13 = Return, 32 = Space
            // this.imageRef.current.click()
            this.showFileSelector();
        }
    };

    showFileSelector = () => {
        this.setState({ showFileSelector: true });
    };
    */

    protected getContentState(): IComposerImageContent {
        const contentState = this.context?.contentState as IComposerImageContent;

        return contentState || defaultContentState;
    }

    protected async onFilesSelected(files: File[]): Promise<void> {
        // Ensure array, not FileList
        files = [...files];

        if (files.length === 0) {
            return;
        }

        const isVideo = files[0].type.indexOf("video/") === 0;
        if (isVideo) {
            this.context.setContentState({ file: files[0] });
            this.context.setType("video");
            return;
        }

        // Remove any non-images
        files = files.filter((file) => file.type.indexOf("image/") === 0);

        const promises = files.map((file) => {
            const body = new FormData();
            const ticket = new Date().getTime().toString() + uniqueId();

            body.append("type", "1");
            body.append("file", file, file.name);
            body.append("thumbnailSize", "natural");
            body.append("ticket", ticket);

            const config = {
                onUploadProgress: (progressEvent) => {
                    console.log(progressEvent);
                },
            };

            return {
                fileName: file.name,
                promise: api.post("/api/uploads", body, config),
            };
        });

        const uploads = await Promise.all(promises.map(async (file) => ({
            fileName: file.fileName,
            result: await file.promise,
        })));

        const contentState = this.getContentState();
        const images = (contentState?.images || []).slice();
        this.context.setContentState({
            images: [
                ...images,
                ...uploads.map((upload) => ({
                        imageTicket: {
                            remove: false,
                            ticket: upload.result.data.Result.ticket,
                        },
                        thumbnailUrl: upload.result.data.Result.thumbnailUrl,
                }))
            ]
        });
    }

    protected renderTypeContent(): ReactNode {
        const contentState = this.getContentState();
        const images = (contentState?.images || []);

        return (
            <div className="SocialComposerImages">
                <div className="images">
                    <DropZone
                        isMultiple={true}
                        fileTypesString="image/*,video/*"
                        onFilesSelected={this.onFilesSelected}
                    >
                        {images.length === 0 && (
                            <div className="upload-placeholder">
                                <div className="content">
                                    <Visage2Icon icon="add-circle" />
                                    <Label
                                        color="visageGray"
                                        size="body-1"
                                        weight="medium"
                                    >
                                        {localize("ADD_PHOTO_VIDEO")}
                                    </Label>
                                    <Label
                                        color="mid-grey"
                                        size="body-2"
                                    >
                                        {localize("OR_DRAG_DROP")}
                                    </Label>
                                </div>
                                <UnstyledButton
                                    className="remove-button"
                                    onClick={this.onDismissTypeClick}
                                >
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            </div>
                        )}
                        {images.length > 0 && (
                            <div className={"image-stage display" + Math.min(5, images.length)}>
                                {images.slice(0, 5).map((img, i) => (
                                    <div key={i} className="image">
                                        <div
                                            className="image-wrapper"
                                            tabIndex={0}
                                            onKeyUp={(e) => {
                                                hitEnterOrSpace(e, this.onRemoveClicked);
                                            }}
                                            data-index={i}
                                        >
                                            <img alt="" src={typeof img.thumbnailUrl === "string" ? img.thumbnailUrl : ""} />
                                            <div className="remove">
                                                <div
                                                    className="icon-wrapper"
                                                    data-index={i}
                                                    onClick={this.onRemoveClicked}
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    <Visage2Icon icon="close-circle" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </DropZone>
                </div>
            </div>
        );
    }

    protected onDismissTypeClick(): void {
        this.context.setSize("default");
        this.context.setType("text");
    }

    protected onRemoveClicked(event: MouseEvent<HTMLDivElement>): void {
        if (event.preventDefault) {
            event.preventDefault();
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        }

        const { dataset } = event.target as HTMLElement;

        const index = parseInt(dataset.index, 10);
        if (typeof index !== "number" || index === undefined || isNaN(index)) {
            return;
        }
        
        const contentState = this.getContentState();
        const images = (contentState?.images || []).slice();
        images.splice(index, 1);

        this.context.setContentState({
            images,
        });
    }
}

export default SocialComposerImages;
