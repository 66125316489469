import React from "react";
import { Label, UnstyledButton } from "src/ui";
import "./AcademyCourseSidebarFinalExam.scss";
import { IAcademyChapter, IAcademyCourse, IAcademyTest } from "src/academy/types";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import AcademyCourseSidebarProgressCircle from "../AcademyCourseSidebarProgressCircle/AcademyCourseSidebarProgressCircle";
import RunningLabel from "../RunningLabel/RunningLabel";

interface IProps {
    test: IAcademyTest;
    activeStepId?: number;
    goToFinalExam: () => void;
}

const AcademyCourseSidebarFinalExam = (props: IProps) => {
    const isActive = props.activeStepId === props.test.id;

    return (
        <div className="AcademyCourseSidebarFinalExam">
            <UnstyledButton
                disabled={!props.test.userProgress.isEligable}
                className="top-row"
                onClick={props.goToFinalExam}>
                <AcademyCourseSidebarProgressCircle
                    isDone={props.test.userProgress.isCompleted}
                    isEligable={props.test.userProgress.isEligable}
                    progressPercent={props.test.userProgress.completionPercent}
                    isTest
                />
                <div className="info">
                    <Label weight="medium">{props.test.name}</Label>
                </div>
                {isActive && <RunningLabel langTag={"Aktiv"} />}
            </UnstyledButton>
        </div>
    )
}

export default AcademyCourseSidebarFinalExam;
