import { Pivot, PivotItem } from "@fluentui/react";
import { Dropdown } from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { localize } from 'src/l10n';
import { Shortcuts } from 'src/shortcuts';
import { TeaserBox } from 'src/teaser-box/components';
import { Label, Loader } from 'src/ui';
import Birthdays from 'src/ui/components/Birthdays/Birthdays';
import Favourites from 'src/ui/components/Favourites/Favourites';
import OperatingInfo from 'src/ui/components/OperatingInfo/OperatingInfo';
import Todos from 'src/ui/components/Todos/Todos';
import { uniqueId } from 'src/utils';
import { v4 as uuid } from 'uuid';
import { renderAdminTitle } from '../common/Title';
import './AdminSidebar.scss';
import { icons } from './AdminTeaserboxItems';

function Copyable(props) {
    return (
        <Droppable droppableId={props.droppableId} isDropDisabled={true}>
            {(provided, snapshot) => (
                <ul ref={provided.innerRef} className={props.className} key={uniqueId()}>
                    {props.items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id + "," + item.name} index={index}>
                            {(provided, snapshot) => (
                                <React.Fragment>
                                    {item.name === "teaserbox" && (
                                        <div
                                            className="itemContainer"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <TeaserBox
                                                sideBarTeaserboxStyle={{
                                                    width: 100,
                                                    height: 100,
                                                    backgroundColor: "#ffffff",
                                                }}
                                                text={localize("Textpuff")}
                                                backgroundColor1={"#ffffff"}
                                                backgroundColor2={"#ffffff"}
                                                textColor={"#131413"}
                                            />
                                        </div>
                                    )}
                                    {item.name === "favourites" && (
                                        <div
                                            className="itemContainer"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Favourites
                                                history={props.history}
                                                disableButtons={props.disableButtons}
                                                items={props.favourites}
                                            ></Favourites>
                                        </div>
                                    )}

                                    {item.name === "shortcuts" && (
                                        <div
                                            className="itemContainer"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Shortcuts
                                                history={props.history}
                                                disableButtons={props.disableButtons}
                                                items={props.shortcuts}
                                                userId={props.userId}
                                            ></Shortcuts>
                                        </div>
                                    )}

                                    {item.name === "birthdays" && (
                                        <div
                                            className="itemContainer"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Birthdays
                                                disableButtons={props.disableButtons}
                                                items={props.birthdays}
                                                userId={props.userId}
                                            ></Birthdays>
                                        </div>
                                    )}
                                    {item.name === "operatinginfo" && (
                                        <div
                                            className="itemContainer"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <OperatingInfo disableButtons={props.disableButtons} />
                                        </div>
                                    )}
                                    {item.name === "todo" && (
                                        <div
                                            className="itemContainer"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Todos />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </ul>
            )}
        </Droppable>
    );
}

function PickableItems(props) {
    return (
        <Copyable
            birthdays={props.birthdays}
            shortcuts={props.shortcuts}
            favourites={props.favourites}
            droppableId="pickabeleItems"
            className="pickabeleItemsClass"
            items={props.items}
            disableButtons={props.disableButtons}
            history={props.history}
            userId={props.userId}
        />
    );
}

function getIcon(name) {
    if (!name) {
        return "";
    }

    let icon = icons.find(
        (obj) => obj.key === name || obj.text === name || obj.key === "fa-" + name || obj.text === "fa-" + name
    );

    return icon.visage2Icon;
}

export function Sidebar(props) {

    return (
        <Droppable droppableId="sidebar" isCombineEnabled={true}>
            {(provided, snapshot) => (
                <ul ref={provided.innerRef} className="sidebarBox">
                    <Label style={{ margin: 0, marginBottom: 5 }} as="h4" size="h4">
                        {localize("SIDEBAR_DROP_HERE")}
                    </Label>
                    {props.items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id + "," + item.name} index={index}>
                            {(provided, snapshot) => (
                                <React.Fragment key={uniqueId()}>
                                    {item.name === "teaserbox" && (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {item.teaserbox ? (
                                                <TeaserBox
                                                    sideBarTeaserboxStyle={{
                                                        width: 100,
                                                        height: 100,
                                                        backgroundColor: item.teaserbox.backgroundColor,
                                                    }}
                                                    text={item.teaserbox.text}
                                                    icon={getIcon(item.teaserbox.icon)}
                                                    backgroundColor1={item.teaserbox.backgroundColor}
                                                    backgroundColor2={item.teaserbox.backgroundColor}
                                                    textColor={item.teaserbox.textColor}
                                                    iconColor={item.teaserbox.iconColor}
                                                />
                                            ) : (
                                                <TeaserBox
                                                    sideBarTeaserboxStyle={{
                                                        width: 100,
                                                        height: 100,
                                                        backgroundColor: "#ffffff",
                                                    }}
                                                    text={localize("Textpuff")}
                                                    backgroundColor1={"#ffffff"}
                                                    backgroundColor2={"#ffffff"}
                                                    textColor={"#131413"}
                                                />
                                            )}

                                            <Link className={"linkFGColor"} onClick={() => props.settings(item)}>
                                                {localize("Inställningar")}
                                            </Link>
                                        </div>
                                    )}
                                    {item.widgets ? (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Pivot
                                                overflowBehavior={"menu"}
                                            >
                                                {item.widgets.map((obj) => (
                                                    <PivotItem
                                                        key={obj.id}
                                                        headerText={localize(changeHeaderText(obj.name))}
                                                    >
                                                        {obj.name === "favourites" && (
                                                            <Favourites
                                                                hideTitle={true}
                                                                items={props.favourites}
                                                                history={props.history}
                                                                disableButtons={props.disableButtons}
                                                            ></Favourites>
                                                        )}
                                                        {obj.name === "shortcuts" && (
                                                            <Shortcuts
                                                                hideTitle={true}
                                                                items={props.shortcuts}
                                                                userId={props.userId}
                                                                history={props.history}
                                                                disableButtons={props.disableButtons}
                                                            ></Shortcuts>
                                                        )}
                                                        {obj.name === "birthdays" && (
                                                            <Birthdays
                                                                hideTitle={true}
                                                                disableButtons={props.disableButtons}
                                                                items={props.birthdays}
                                                                userId={props.userId}
                                                            ></Birthdays>
                                                        )}
                                                        {obj.name === "operatinginfo" && (
                                                            <OperatingInfo
                                                                hideTitle={true}
                                                                disableButtons={props.disableButtons}
                                                            />
                                                        )}
                                                        {obj.name === "todo" && <Todos />}
                                                    </PivotItem>
                                                ))}
                                            </Pivot>
                                        </div>
                                    ) : (
                                        item.name === "favourites" && (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Favourites items={props.favourites}></Favourites>
                                            </div>
                                        )
                                    )}
                                    {item.name === "shortcuts" && (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Shortcuts
                                                history={props.history}
                                                disableButtons={props.disableButtons}
                                                items={props.shortcuts}
                                                userId={props.userId}
                                            ></Shortcuts>
                                        </div>
                                    )}

                                    {item.name === "birthdays" && (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Birthdays
                                                disableButtons={props.disableButtons}
                                                items={props.birthdays}
                                                userId={props.userId}
                                            ></Birthdays>
                                        </div>
                                    )}
                                    {item.name === "operatinginfo" && (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <OperatingInfo disableButtons={props.disableButtons} />
                                        </div>
                                    )}
                                    {item.name === "todo" && (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Todos />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </ul>
            )}
        </Droppable>
    );
}

export const changeHeaderText = (label) => {
    switch (label) {
        case "favourites":
            return "Favoriter";

        case "shortcuts":
            return "Genvagar";

        case "birthdays":
            return "appBirthdays";

        case "operatinginfo":
            return "Driftinformation";

        case "todo":
            return "AttGora";

        case "dashboard":
            return "appDashboard";

        case "teaserbox":
            return "Textpuff";
    }

    return label;
};

let COLLECTION = [
    { id: uuid(), name: "teaserbox" },
    { id: "999", name: "favourites" },
    { id: "998", name: "shortcuts" },
    { id: "997", name: "birthdays" },
    { id: "996", name: "operatinginfo" },
    { id: "995", name: "todo" },
];

let COLLECTIONCOPY = [
    { id: uuid(), name: "teaserbox" },
    { id: "999", name: "favourites" },
    { id: "998", name: "shortcuts" },
    { id: "997", name: "birthdays" },
    { id: "996", name: "operatinginfo" },
    { id: "995", name: "todo" },
];

const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
};

const copy = (source, destination, droppableSource, droppableDestination) => {
    const item = source[droppableSource.index];

    let list = [...destination];
    let newId = 0;
    list.forEach((obj) => {
        if (obj.name === "teaserbox") {
            if (parseInt(obj.id) > newId) {
                newId = obj.id;
            }
        }
    });
    newId++;

    destination.splice(droppableDestination.index, 0, { ...item, id: newId.toString() });
    return destination;
};

const move = (source, destination, droppableSource, droppableDestination, id) => {
    const item = source[droppableSource.index];
    destination.splice(droppableDestination.index, 0, { ...item, id: id });
    COLLECTION.splice(droppableSource.index, 1);
    return destination;
};

const addTogether = (source, destination, droppableSource, combineDraggableId, newItem) => {
    let item;

    let index = destination.findIndex((item) => item.id === combineDraggableId);
    let item2 = { ...destination[index] };

    if (newItem[1] === "widget" || item2.name === "widget") {
        if (newItem[1] === "widget") {
            item = destination[droppableSource.index];
            item.widgets.push(item2);
            destination.splice(index, 1);
        } else {
            if (droppableSource.droppableId === "pickabeleItems") {
                COLLECTION.splice(droppableSource.index, 1);
                item = { id: newItem[0], name: newItem[1] };

                item2.widgets.push(item);
            } else {
                item = { id: newItem[0], name: newItem[1] };

                item2.widgets.push(item);
                destination.splice(droppableSource.index, 1);
            }
        }
    } else {
        if (droppableSource.droppableId === "pickabeleItems") {
            item = source[droppableSource.index];
            COLLECTION.splice(droppableSource.index, 1);
            destination.splice(index, 1);
            destination.splice(index, 0, {
                id: uniqueId(),
                name: "widget",
                widgets: [{ ...item2 }, { ...item }],
            });
        } else {
            item = { id: newItem[0], name: newItem[1] };
            destination.splice(index, 1);
            destination.splice(index, 0, {
                id: uniqueId(),
                name: "widget",
                widgets: [{ ...item2 }, { ...item }],
            });
            destination.splice(droppableSource.index, 1);
        }
    }
    return destination;
};

const remove = (list, index) => {
    list.splice(index, 1);
    return list;
};

interface IProps {
    history?: any;
    items: any;
    changeSettingsTeaserbox?: any;
    favourites?: any;
    shortcuts?: any;
    userId?: number;
    activeTeaserboxes: any;
    getItems?: any;
    disableButtons?: any;
    birthdays?: any;
}

interface IState {
    saveError: any;
    isNew: boolean;
    isLoading: boolean;
    items: any;
    showDialog: boolean;
    teaserboxId: number;
    favourites: any;
    shortcuts?: any;
    userId?: number;
    dropDownCollection: any;
    dropdownKey: string;
    itemsWidget: any;
    activeTeaserboxes: any;
}

class AdminSidebar extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            saveError: [],
            isNew: false,
            isLoading: true,
            items: [],
            teaserboxId: 0,
            favourites: [],
            shortcuts: [],
            userId: 0,
            dropDownCollection: [],
            dropdownKey: "99",
            itemsWidget: {},
            activeTeaserboxes: [],
        };
    }
    componentDidMount() {
        this.setState(
            {
                userId: this.props.userId,
                shortcuts: this.props.shortcuts,
                items: this.props.items,
                isLoading: false,
                favourites: this.props.favourites,
                activeTeaserboxes: this.props.activeTeaserboxes,
                dropDownCollection: [
                    { id: uuid(), name: "Textpuff" },
                    { id: "999", name: "Favoriter" },
                    { id: "998", name: "Genvagar" },
                    { id: "997", name: "appBirthdays" },
                    { id: "996", name: "Driftinformation" },
                    { id: "995", name: "AttGora" },

                ],
            },
            () => this.setSidebar()
        );
    }
    setSidebar() {
        let list = [...this.state.items];
        let listTeaser = [...this.state.activeTeaserboxes];
        COLLECTION = [
            { id: uuid(), name: "teaserbox" },
            { id: "999", name: "favourites" },
            { id: "998", name: "shortcuts" },
            { id: "997", name: "birthdays" },
            { id: "996", name: "operatinginfo" },
            { id: "995", name: "todo" },
        ];
        list.forEach((obj) => {
            if (obj.boxId) {
                obj.teaserbox = listTeaser.find((object) => object.id === obj.boxId);
                obj.id = obj.id;
                obj.boxId = obj.boxId;
                obj.name = obj.name;
            }

            switch (obj.name) {
                case "shortcuts":
                    obj.id = "998";
                    break;
                case "favourites":
                    obj.id = "999";
                    break;
                case "birthdays":
                    obj.id = "997";
                    break;
                case "operatinginfo":
                    obj.id = "996";
                    break;
                case "todo":
                    obj.id = "995";
                    break;
            }
        });

        this.setState({ items: list });

        let i;
        list.forEach((obj) => {
            if (obj.widgets) {
                obj.widgets.forEach((object) => {
                    i = COLLECTION.findIndex((item) => item.name === object.name);
                    COLLECTION.splice(i, 1);
                });
            }
            i = COLLECTION.findIndex((item) => item.name === obj.name);
            COLLECTION.splice(i, 1);
        });

        !COLLECTION.some((obj) => obj.name === "teaserbox") && COLLECTION.push({ id: uniqueId(), name: "teaserbox" });
        if (list.length === 0) {
            COLLECTION = [
                { id: uuid(), name: "teaserbox" },
                { id: "999", name: "favourites" },
                { id: "998", name: "shortcuts" },
                { id: "997", name: "birthdays" },
                { id: "996", name: "operatinginfo" },
                { id: "995", name: "todo" },
            ];
        }
        this.saveWidget();
    }

    onClickHandler = (item) => {
        this.props.changeSettingsTeaserbox(item);
    };

    changeDropdown = (value) => {
        let list = [];
        let found = false;
        this.state.items.forEach((obj) => {
            if (obj.id === value.key) {
                found = true;
                list.push({ ...obj });
            }
            if (obj.widgets) {
                obj.widgets.forEach((widgetItem) => {
                    if (widgetItem.id === value.key) {
                        found = true;
                        list.push({ ...widgetItem });
                    }
                });
            }
        });

        if (found) {
            COLLECTION = [];
        } else {
            let lista = [];
            let listDrop = [...COLLECTIONCOPY];
            listDrop.forEach((obj) => {
                if (obj.id === value.key) {
                    lista.push({ ...obj });
                }
            });

            COLLECTION = lista;
        }

        if (value.text === localize("Textpuff")) {
            COLLECTION = [{ id: uniqueId(), name: "teaserbox" }];
        }
        if (value.key === "99") {
            let list = [];

            this.state.items.forEach((obj) => {
                COLLECTIONCOPY.forEach((item) => {
                    if (obj.id === item.id) {
                        list.push({ ...item });
                    }
                    if (obj.widgets) {
                        obj.widgets.forEach((widgetItem) => {
                            if (widgetItem.id === item.id) {
                                list.push({ ...widgetItem });
                            }
                        });
                    }
                });
            });

            let listDrop = [...COLLECTIONCOPY];
            list.forEach((obj) => {
                listDrop.splice(
                    listDrop.findIndex((item) => item.name === obj.name),
                    1
                );
            });

            COLLECTION = listDrop;
        }

        this.setState({ dropdownKey: value.key });
    };
    saveWidget() {
        let list = [];
        this.state.items.forEach((obj) => {
            if (obj.name === "widget") {
                list.push(obj);
            }
        });

        this.setState({ itemsWidget: list });
    }
    onDragEnd = (result) => {
        const { source, destination, combine } = result;

        let draggableLabel = result.draggableId.split(",");
        let id = draggableLabel[0];
        let dragLabel = draggableLabel[1];

        if (combine) {
            let label = combine.draggableId.split(",");
            let combineId = label[0];
            let combineLabel = label[1];
            if (dragLabel === "widget" && combineLabel === "widget") {
                return;
            }
            if (
                dragLabel === "favourites" ||
                dragLabel === "shortcuts" ||
                dragLabel === "birthdays" ||
                dragLabel === "widget" ||
                dragLabel === "operatinginfo" ||
                dragLabel === "todo"
            ) {
                if (
                    combineLabel === "favourites" ||
                    combineLabel === "shortcuts" ||
                    combineLabel === "birthdays" ||
                    combineLabel === "widget" ||
                    combineLabel === "operatinginfo" ||
                    combineLabel === "todo"
                ) {
                    this.setState(
                        {
                            items: addTogether(COLLECTION, this.state.items, source, combineId, draggableLabel),
                        },
                        () => this.saveWidget()
                    );
                }
            }
        }
        if (source.droppableId === "sidebar" && !destination && !combine) {
            this.setState({ items: remove(this.state.items, source.index) });
            if (this.state.dropdownKey === id || this.state.dropdownKey === "99") {
                if (dragLabel !== "teaserbox") {
                    if (dragLabel === "widget") {
                        this.state.itemsWidget.forEach((obj) => {
                            if (obj.id === id) {
                                obj.widgets.forEach((obj) => {
                                    COLLECTION.push(obj);
                                });
                            }
                        });
                    } else {
                        COLLECTION.push({ id: id, name: dragLabel });
                    }
                }
            }
        }
        this.props.getItems(this.state.items);
        if (!destination) {
            return;
        }

        if (dragLabel === "favourites" && source.droppableId === "pickabeleItems") {
            this.setState({ items: move(COLLECTION, this.state.items, source, destination, id) });
        } else if (dragLabel === "shortcuts" && source.droppableId === "pickabeleItems") {
            this.setState({ items: move(COLLECTION, this.state.items, source, destination, id) });
        } else if (dragLabel === "birthdays" && source.droppableId === "pickabeleItems") {
            this.setState({ items: move(COLLECTION, this.state.items, source, destination, id) });
        } else if (dragLabel === "operatinginfo" && source.droppableId === "pickabeleItems") {
            this.setState({ items: move(COLLECTION, this.state.items, source, destination, id) });
        } else if (dragLabel === "todo" && source.droppableId === "pickabeleItems") {
            this.setState({ items: move(COLLECTION, this.state.items, source, destination, id) });
        } else {
            switch (source.droppableId) {
                case destination.droppableId:
                    this.setState({ items: reorder(this.state.items, source.index, destination.index) });
                    break;
                case "pickabeleItems":
                    this.setState({ items: copy(COLLECTION, this.state.items, source, destination) });
                    break;
                default:
                    break;
            }
        }
        this.props.getItems(this.state.items);
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        
        let modules = [{ key: "99", text: localize("Alla") }];

        this.state.dropDownCollection.map((obj) => {
            modules.push({ key: obj.id, text: localize(obj.name) });
        });

        return (
            <div className="AdminSidebar dialogItemsContainer">
                {renderAdminTitle("Hogerspalter")}
                <Dropdown
                    selectedKey={this.state.dropdownKey}
                    onChange={(_e, v) => {
                        this.changeDropdown(v);
                    }}
                    style={{ maxWidth: 250, marginBottom: 10, marginTop: 10, marginRight: 0 }}
                    options={modules}
                    styles={{ dropdown: { width: 250 } }}
                ></Dropdown>

                <div className="sidebarPopup">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="pickableItemsContainer">
                            <PickableItems
                                birthdays={this.props.birthdays}
                                userId={this.state.userId}
                                shortcuts={this.state.shortcuts}
                                favourites={this.state.favourites}
                                items={COLLECTION}
                                disableButtons={this.props.disableButtons}
                                history={this.props.history}
                            />
                        </div>

                        <div className="sidebarItemsContainer">
                            <Sidebar
                                birthdays={this.props.birthdays}
                                userId={this.state.userId}
                                shortcuts={this.state.shortcuts}
                                favourites={this.state.favourites}
                                settings={this.onClickHandler}
                                items={this.state.items}
                                disableButtons={this.props.disableButtons}
                                history={this.props.history}
                            />
                        </div>
                    </DragDropContext>
                </div>
            </div>
        );
    }
}

export default connect()(AdminSidebar);
