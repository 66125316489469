import { AxiosResponse } from "axios";

export const FETCH_ACTIVE_USER_BASE = "FETCH_ACTIVE_USER";
export interface IFetchActiveUserBaseAction {
    payload: Promise<AxiosResponse<Spintr.IActiveUser>>;
    type: typeof FETCH_ACTIVE_USER_BASE;
}

export const FETCH_ACTIVE_USER_PENDING = "FETCH_ACTIVE_USER_PENDING";
export interface IFetchActiveUserPendingAction {
    type: typeof FETCH_ACTIVE_USER_PENDING;
}

export const FETCH_ACTIVE_USER_REJECTED = "FETCH_ACTIVE_USER_REJECTED";
export interface IFetchActiveUserRejectedAction {
    type: typeof FETCH_ACTIVE_USER_REJECTED;
}

export const FETCH_ACTIVE_USER_FULFILLED = "FETCH_ACTIVE_USER_FULFILLED";
export interface IFetchActiveUserFulfilledAction 
    extends Spintr.IAsyncAction<Spintr.IActiveUser> 
{
    type: typeof FETCH_ACTIVE_USER_FULFILLED;
}

export const UPDATE_ACTIVE_USER = "UPDATE_ACTIVE_USER";
export interface IUpdateActiveUserAction {
    meta: any;
    type: typeof UPDATE_ACTIVE_USER;
}

export const FETCH_UNREAD_EMAILS = "FETCH_UNREAD_EMAILS";

export interface IFetchUnreadEmailsAction {
    payload: Promise<AxiosResponse<number>>;
    type: typeof FETCH_UNREAD_EMAILS;
}

export const FETCH_UNREAD_EMAILS_PENDING = "FETCH_UNREAD_EMAILS_PENDING";

export interface IFetchUnreadEmailsPendingAction {
    type: typeof FETCH_UNREAD_EMAILS_PENDING;
}

export const FETCH_UNREAD_EMAILS_REJECTED = "FETCH_UNREAD_EMAILS_REJECTED";

export interface IFetchUnreadEmailsRejectedAction {
    type: typeof FETCH_UNREAD_EMAILS_REJECTED;
}

export const FETCH_UNREAD_EMAILS_FULFILLED = "FETCH_UNREAD_EMAILS_FULFILLED";

export interface IFetchUnreadEmailsFulfilledAction extends Spintr.IAsyncAction<number> {
    type: typeof FETCH_UNREAD_EMAILS_FULFILLED;
}

export const CLEAR_UNREAD_EMAILS = "CLEAR_UNREAD_EMAILS";

export interface IClearUnreadEmailsAction {
    payload: Promise<AxiosResponse>;
    type: typeof CLEAR_UNREAD_EMAILS;
}

export const CLEAR_UNREAD_EMAILS_PENDING = "CLEAR_UNREAD_EMAILS_PENDING";

export interface IClearUnreadEmailsPendingAction {
    type: typeof CLEAR_UNREAD_EMAILS_PENDING;
}

export const CLEAR_UNREAD_EMAILS_REJECTED = "CLEAR_UNREAD_EMAILS_REJECTED";

export interface IClearUnreadEmailsRejectedAction {
    type: typeof CLEAR_UNREAD_EMAILS_REJECTED;
}

export const CLEAR_UNREAD_EMAILS_FULFILLED = "CLEAR_UNREAD_EMAILS_FULFILLED";

export interface IClearUnreadEmailsFulfilledAction {
    type: typeof CLEAR_UNREAD_EMAILS_FULFILLED;
}

export type FetchActiveUserAction =
      IFetchActiveUserBaseAction
    | IFetchActiveUserPendingAction
    | IFetchActiveUserRejectedAction
    | IFetchActiveUserFulfilledAction;

export type FetchUnreadEmailsAction =
      IFetchUnreadEmailsAction
    | IFetchUnreadEmailsPendingAction
    | IFetchUnreadEmailsRejectedAction
    | IFetchUnreadEmailsFulfilledAction;

export type ClearUnreadEmailsAction =
      IClearUnreadEmailsAction
    | IClearUnreadEmailsPendingAction
    | IClearUnreadEmailsRejectedAction
    | IClearUnreadEmailsFulfilledAction;

export type ProfileAction =
      FetchActiveUserAction
    | IUpdateActiveUserAction
    | FetchUnreadEmailsAction
    | ClearUnreadEmailsAction;

export default ProfileAction;