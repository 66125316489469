import { ActionButton } from '@fluentui/react';
import React, { Component } from 'react';
import { localize } from 'src/l10n';
import "./LoadMoreButton.scss";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    onClick: any;
    text?: string;
    visageStyle?: boolean;
}

class LoadMoreButton extends Component<IProps> {
    render() {
        let classNames = ["LoadMoreButtonWrapper"];

        if (this.props.visageStyle) {
            classNames.push("visageStyle");
        }

        return (
            <div className={classNames.join(" ")}>
                <ActionButton
                    className={"LoadMoreButton"}
                    onClick={this.props.onClick}
                >
                    {this.props.text ? this.props.text : localize("LaddaInFlera")}
                    {this.props.visageStyle && (
                        <Visage2Icon icon="arrow-down-1" color="light-blue" />
                    )}
                </ActionButton>
            </div>
        )
    }
}

export default LoadMoreButton;
