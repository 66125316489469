import React, { FunctionComponent, useCallback } from "react";
import { useDispatch } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { ActionMenu } from "src/ui";

import { MarketplaceWidget } from "../MarketplaceWidget";
import { DeleteConfigurationClickHandler, EditConfigurationClickHandler, LibraryConfigurationClickHandler } from "./WidgetLibrary.types";

interface IProps {
    configuration: Spintr.IMarketplaceLibraryWidget;
    onConfigurationClicked: LibraryConfigurationClickHandler;
    onDeleteClick?: DeleteConfigurationClickHandler;
    onEditConfigurationClicked?: EditConfigurationClickHandler;
    showOptions?: boolean;
}

const WidgetLibraryConfiguration: FunctionComponent<IProps> = (props) => {
    const { configuration, onConfigurationClicked, onDeleteClick, onEditConfigurationClicked } = props;

    const dispatch = useDispatch()

    const onClick = useCallback(() => {
        if (!onConfigurationClicked) {
            return;
        }

        onConfigurationClicked(configuration);
    }, [configuration, onConfigurationClicked]);

    const onEditClicked = useCallback(() => {
        onEditConfigurationClicked(configuration);
    }, [configuration, onEditConfigurationClicked])

    const onDeleteClicked = useCallback(() => {
        if (!onDeleteClick) {
            return;
        }

        dispatch(setConfirmPopup({
            isOpen: true,
            title: localize("RaderaInnehall"),
            message: localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase()),
            onConfirm: () => {
                onDeleteClick(configuration);
            }
        }));
    }, [configuration, onDeleteClick]);

    return (
        <div
            className={
                "WidgetLibraryConfiguration"
                + (!onClick ? "" : " clickable")
            }
            onClick={onClick}
        >
            <MarketplaceWidget
                id={configuration.id}
                displayBorder
            />
            {props.showOptions && (
                <div className="options">
                    <ActionMenu
                        categories={[{
                            items: [{
                                onClick: onEditClicked,
                                text: localize("Redigera"),
                            }, {
                                onClick: onDeleteClicked,
                                text: localize("TaBort"),
                            }]
                        }]}
                    />
                </div>
            )}
        </div>
    )
}

export default WidgetLibraryConfiguration;
