import React, { FunctionComponent } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { Breadcrumbs } from "src/ui";

interface IBreadcrumbItem {
    key: string;
    link: string;
    text: string;
}

interface IProps extends IAppPageComponent {
    crumbs?: IBreadcrumbItem[];
}

const AppPageBreadcrumbs: FunctionComponent<IProps> = ({ crumbs, variables }) => {
    return (
        <div className="AppPageBreadcrumbs">
            <Breadcrumbs
                displayInstance={true}
                items={(crumbs || []).map((crumb): IBreadcrumbItem => ({
                    key: crumb.key,
                    link: [...Object.keys(variables || {})].reduce(
                        (acc, key) => acc.replace(`{{${key}}}`, variables[key] || ""),
                        crumb.link,
                    ),
                    text: [...Object.keys(variables || {})].reduce(
                        (acc, key) => acc.replace(`{{${key}}}`, variables[key] || ""),
                        crumb.text,
                    ),
                }))}
            />
        </div>
    );
};

export default AppPageBreadcrumbs;
