import { AxiosResponse } from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { IAcademyCourse } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";
import CompactTable from "src/ui/components/CompactTable/CompactTable";
import SpintrLoader from "src/ui/components/Loader";
import "./AcademyUserCourseList.scss";

interface IProps {
    userId: number;
}

interface IState {
    isLoading: boolean;
    courses?: IAcademyCourse[];
}

const AcademyUserCourseList = (props: IProps) => {
    const [state, setState] = useState<IState>({
        isLoading: true
    });

    const fetch = useCallback(() => {
        api.get("/api/v1/academy/users/" + props.userId + "/completed-courses").then((response: AxiosResponse) => {
            setState((s: IState) => {
                return {
                    ...s,
                    courses: response.data,
                    isLoading: false
                }
            });
        }).catch(() => { });
    }, []);

    useEffect(() => {
        fetch();
    }, []);

    if (state.isLoading) {
        return <SpintrLoader />
    }

    if (state.courses.length === 0) {
        return (
            <Label>{localize("NO_COURSES_COMPLETED")}</Label>
        )
    }

    return (
        <div className="AcademyUserCourseList">
            <CompactTable
                columNames={[localize("Namn"), localize("Kategori"), localize("COMPLETION_DATE")]}
                rows={state.courses.map((course: IAcademyCourse) => {
                    return [course.name, course.category?.name, moment(course.userProgress.completionDate).format("LLL")]
                })} />
        </div>
    )
}

export default AcademyUserCourseList;
