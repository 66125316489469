import Immutable from 'immutable'

import {
    FETCH_BLOGS_PENDING,
    FETCH_BLOGS_REJECTED,
    FETCH_BLOGS_FULFILLED
} from './action-types'

const initialState = Immutable.Map({
    error: Immutable.Map({ exists: false }),
    isLoading: false,
    items: Immutable.List()
})

// @ts-ignore
const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BLOGS_PENDING:
            return state.merge({
                error: initialState.get('error'),
                isLoading: true
            })

        case FETCH_BLOGS_REJECTED:
            return state.merge({
                error: Immutable.Map({ exists: true }),
                isLoading: false
            })

        case FETCH_BLOGS_FULFILLED:
            return state
                .merge({
                    error: Immutable.Map({ exists: true }),
                    isLoading: false
                })
                .mergeIn(['items'], Immutable.fromJS(action.payload.data.blogs))

        default:
            return state
    }
}

export default blogReducer
