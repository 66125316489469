import { Component } from 'react';
import { isAnythingDirty, mixpanelTrack, scrollToTop } from 'src/utils';
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { localize } from 'src/l10n';
import CustomDialog from 'src/ui/components/Dialogs/CustomDialog';
import React from 'react';
import { IApplicationState } from '../reducer';
import api from '../SpintrApi';
import { IActiveUserProfile } from 'src/profile/reducer';
import { connect } from 'react-redux';

interface IProps extends RouteChildrenProps {
    history: any;
    currentUser: IActiveUserProfile;
}

interface IState {
}

class SpintrLocationChecker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    private async postVisit(pathname): Promise<void> {
        try {
            await api.post("/api/statistics/visits", [{
                Url: pathname,
                UserId: this.props.currentUser.id
            }]);
        } catch (_) {
            // ignored
        }
    }

    componentDidMount(): void {
        this.groupUserLocationCheck();
        this.postVisit(this.props.history.location.pathname);

        this.props.history.listen((location) => {
            const hasChanged = !!location &&
                !!this.props.location &&
                (location.pathname !== this.props.location.pathname || location.search !== this.props.location.search);

            if (hasChanged) {
                scrollToTop();
            }

            if (this.props.location.pathname === "/") {
                if (
                    this.props.location.pathname.startsWith("/bloggar/") ||
                    this.props.location.pathname.startsWith("/blogs/")
                ) {
                    mixpanelTrack("Visited blog");
                } else if (
                    this.props.location.pathname.startsWith("/news/") ||
                    this.props.location.pathname.startsWith("/nyheter/")
                ) {
                    mixpanelTrack("Visited news");
                }
            }

            this.postVisit(location.pathname);
        });
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.location.pathname !== this.props.location.pathname ||
            (this.props.currentUser &&
                this.props.currentUser.id &&
                (!prevProps.currentUser || !prevProps.currentUser.id))) {
            this.groupUserLocationCheck();
        }
    }

    groupUserLocationCheck() {
        if (!!this.props.currentUser &&
            this.props.currentUser.isGroupUser &&
            window.location.pathname.indexOf("/groups/") !== 0 &&
            window.location.pathname.indexOf("/profile-settings") !== 0 &&
            window.location.pathname.indexOf("/404") !== 0 &&
            window.location.pathname.indexOf("/loader") !== 0 &&
            window.location.pathname.indexOf("/goto") !== 0 &&
            window.location.pathname.indexOf("/messages") !== 0 &&
            window.location.pathname.indexOf("/pages/") !== 0) {
            this.props.history.push({
                pathname: `/loader`,
            });

            api.get("/api/v1/conversations?fetchType=3&skip=0&take=1").then((response) => {
                if (response &&
                    response.data &&
                    response.data.length > 0 &&
                    !!response.data[0].group) {
                    this.props.history.push({
                        pathname: `/groups/${response.data[0].group.id}`,
                    });
                }
            });
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    currentUser: state.profile.active,
});

export default withRouter(connect(mapStateToProps)(SpintrLocationChecker));