import Immutable from 'immutable'

import {
    FETCH_USER_NOTES_PENDING,
    FETCH_USER_NOTES_REJECTED,
    FETCH_USER_NOTES_FULFILLED
} from './action-types'

const initialState = Immutable.Map({
    error: Immutable.Map({ exists: false }),
    isLoading: false,
    items: Immutable.List()
})

const notesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_NOTES_PENDING:
            return state.merge({
                error: initialState.get('error'),
                isLoading: true,
            })

        case FETCH_USER_NOTES_REJECTED:
            return state.merge({
                error: Immutable.Map({ exists: true }),
                isLoading: false,
            })

        case FETCH_USER_NOTES_FULFILLED:
            return state
                .merge({
                    error: initialState.get('error'),
                    isLoading: false,
                })
                .mergeIn(['items'], Immutable.fromJS(action.payload.data))

        default:
            return state
    }
}

export default notesReducer
