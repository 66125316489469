import moment from "moment";
import { Icon as FluentIcon } from "@fluentui/react/lib/Icon";
import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label as SpintrLabel } from "src/ui";
import { truncate } from "src/utils";
import "./OperatingInfoSidebarPost.scss";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    post: Spintr.IOperatingInfoPost;
    type: number;
    disableButtons?: boolean;
    onClickCallback?: any;
}
class OperatingInfoSidebarPost extends Component<IProps> {
    public render(): ReactNode {
        const { post, type } = this.props;

        const renderIcon = (): ReactNode => {
            return (
                <Visage2Icon
                    icon={type === 1 ? "danger" : type === 3 ? "clock" : "tick-circle"}
                    size={"medium"}
                    color={type === 1 ? "red" : type === 3 ? "yellow" : "green"} />
            )
        };

        const now = new Date();

        return (
            <div className={`OperatingInfoSidebarPost type${type}`}>
                <Link aria-disabled={!this.props.disableButtons} className="wrapper" onClick={this.props.onClickCallback} to={`/operatinginfo`}>
                    <div className="icon">{renderIcon()}</div>
                    <div className="text">
                        <SpintrLabel as="h5" className="name" size="body-2" weight="medium" color="dark-grey">
                            {post.title}
                        </SpintrLabel>
                        {
                            !!post.text && (
                                <SpintrLabel as="p" className="description general-row-break" size="body-2">
                                    <span dangerouslySetInnerHTML={{
                                        __html: truncate(post.text.replace(/(\r\n|\n)/g, "<br />"), 50),
                                    }} />
                                </SpintrLabel>
                            )
                        }
                        <SpintrLabel as="p" className="dates" size="body-3">
                            <>
                                {moment(post.dateStart).format("D MMM HH:mm")}{" "}
                                {post.dateEnd && (
                                    <>
                                        {localize("Till").toLowerCase()} {moment(post.dateEnd).format("D MMM HH:mm")}
                                    </>
                                )}
                            </>
                        </SpintrLabel>
                    </div>
                </Link>
            </div>
        );
    }
}

export default OperatingInfoSidebarPost;
