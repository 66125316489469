import api from "src/spintr/SpintrApi";

import { SpintrTypes } from "src/typings";

const baseUrl = "/api/v1/MarketplaceWidgets";

export async function queryWidgets(
    applicationId: string,
    skip: number = 0,
    take: number = 10,
    filter: string = null,
    orderBy: string = "name",
    ascending: boolean = true,
    displayType: SpintrTypes.WidgetType = SpintrTypes.WidgetType.Any
): Promise<Spintr.IMarketplaceWidgetEnvelope> {
    try {
        const response = await api.get<Spintr.IMarketplaceWidgetEnvelope>(
            baseUrl, {
                params: {
                    applicationId,
                    ascending,
                    displayType,
                    filter,
                    orderBy,
                    skip, 
                    take,
                }
            }
        );

        return response.data;
    } catch (_) {
        return null;
    }
}

export async function fetchWidgetConfiguration(
    widgetConfigurationId: string,
): Promise<any> {
    try {
        const response = await api.get<any>(
            baseUrl + "/configurations/" + widgetConfigurationId
        );

        console.log(response);

        return response.data;
    } catch (_) {
        return null;
    }
}

export async function fetchLibrary(): Promise<Spintr.IMarketplaceLibraryWrapperEnvelope> {
    try {
        const response = await api.get<Spintr.IMarketplaceLibraryWrapperEnvelope>(
            baseUrl + "/library"
        );

        return response.data;
    } catch (_) {
        return null;
    }
}

export async function fetchData(id: string, url: string = null, token: string = null): Promise<any> {
    try {
        const response = await api.get<any>(
            (url || "") + baseUrl + "/" + id + "/data",
            {
                headers: !token
                    ? {}
                    : {
                        "Authorization": "Bearer " + token,
                    },
            },
        );

        return response.data;
    } catch (error) {
        if (error &&
            error.response &&
            error.response.status) {
            return {
                id,
                failed: true,
                errorCode: error.response.status
            };
        } else {
            return {
                id,
                failed: true
            };
        }
    }
}

export async function fetchRawWidgetData(
    applicationId: string,
    resourceUrl: string,
    method: "GET" | "POST",
    content?: string,
    data?: any,
): Promise<any> {
    data = !!data ? JSON.stringify(data) : "";
    const response = await api.post(
        baseUrl + "/data/raw", 
        { applicationId, resourceUrl, method, content, data },
        {
            headers: {
                'Content-Type': 'application/json'
            },
        },
    );

    return response.data;
}

export async function validateDsl(
    dslExpression : string,
    sampleData : string = null,
): Promise<Spintr.IMarketplaceWidgetDslValidationResponse> {
    const response = await api.post<Spintr.IMarketplaceWidgetDslValidationResponse>(
        baseUrl + "/dsl/validate",
        { dslExpression, sampleData },
    );

    return response.data;
}

export async function saveConfiguration(
    widgetId: string,
    name: string,
    variables: object = null,
    id?: string,
): Promise<Spintr.IMarketplaceLibraryWidgetResponse> {
    const response = await api.post<Spintr.IMarketplaceLibraryWidgetResponse>(
        baseUrl + "/configurations",
        { widgetId, name, variables, id }
    );

    return response.data;
}

export async function deleteConfiguration(
    configurationId: string,
): Promise<boolean> {
    const response = await api.delete(
        baseUrl + "/configurations/" + configurationId,
    );

    return response.status < 400;
}

export async function saveWidget(
    model: Spintr.IMarketplaceWidgetRequest
): Promise<Spintr.IMarketplaceWidget> {
    const response = await api.post<Spintr.IMarketplaceWidget>(
        baseUrl,
        model
    );

    return response.data;
};
