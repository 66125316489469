import { Icon } from "@fluentui/react";
import { EditorState } from "draft-js";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";

import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { ActionMenu, Label, SpintrUser, UnstyledButton } from "src/ui";
import { circleLarge, circleMedium } from "src/ui/helpers/style";
import {
    ComposerContextProvider,
    IComposerContext
} from "../ComposerContext";
import { ComposerTypeButton, ComposerTypeSelectorBar } from "../ComposerTypeSelector";
import SocialComposer from "../SocialComposer";
import SocialComposerTypes from "../SocialComposerTypes";
import { draftToHtml } from "../utils";
import Visage2Composer, { defaultFrameColors } from "../../Visage2Composer/Visage2Composer";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { IVisage2SocialPostType, visage2SocialPostTypes } from "src/social-feed";
import { SpintrTypes } from "src/typings";
import api from "src/spintr/SpintrApi";
import classNames from "classnames";

interface IStateProps {
    enableSharepoint: boolean;
    disableNewDealPostType?: boolean;
    user: IActiveUserProfile;
}

interface IProps {
    disableEffects?: boolean | undefined;
    feedId?: number;
    flag?: number;
    galleryId?: number;
    identity?: any;
    isUnit?: boolean;
    isStartPage?: boolean;
    placeholder?: string;
    placeholderColor?: spintrColors;
    hidePostTypes?: boolean;
    hideUser?: boolean;
    type?: IVisage2SocialPostType;
    types?: SocialComposerTypes[] | undefined;
    currentUser?: Spintr.IActiveUser;
    systemStatusEnabled: boolean;
    displayResourceSelector: boolean;
    spintrUserSize?: number;
}

const InlineComposer: FunctionComponent<IProps> = (props) => {
    const { disableNewDealPostType, enableSharepoint, user } = useSelector<Spintr.AppState, IStateProps>(
        (state) => ({
            enableSharepoint: state.instance.get("enableSharepoint") as boolean,
            disableNewDealPostType: state.instance.get("disableNewDealPostType"),
            user: state.profile.active,
        }),
    );

    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState(props.type);
    const [placeholder, setPlaceholder] = useState(props.placeholder);
    const [feedId, setFeedId] = useState(props.feedId);
    const [editorText, setEditorText] = useState<string | null>(null);
    const [identity, setIdentity] = useState<any>(props.identity);
    const [hidePostTypes, setHidePostTypes] = useState<any>(props.hidePostTypes);
    const [viewState, setViewState] = useState<IComposerContext>({
        contentState: null,
        editorState: EditorState.createEmpty(),
        size: "default",
        type: "text",

        reset: () => setViewState((s: IComposerContext) => ({
            ...s,
            contentState: null,
            editorState: EditorState.createEmpty(),
            size: "default",
            type: "text",
        })),
        resetEditorState: () => setViewState((s: IComposerContext) => ({
            ...s,
            editorState: EditorState.createEmpty(),
        })),
        setContentState: (contentState, callback) => {
            setViewState((s: IComposerContext) => ({
                ...s,
                contentState,
            }));

            if (!callback) {
                return;
            }

            callback();
        },
        setEditorState: (es) => setViewState((s: IComposerContext) => ({
            ...s,
            editorState: es,
        })),
        setSize: (size) => setViewState((s: IComposerContext) => ({ ...s, size })),
        setType: (type) => setViewState((s: IComposerContext) => ({ ...s, type: s.type === type ? "text" : type })),
    });

    const onClick = useCallback(
        () => setIsOpen(true),
        [setIsOpen],
    );

    const onTypeClick = useCallback(
        (type: SocialComposerTypes) => {
            setViewState((s: IComposerContext) => ({ ...s, type }));
            setIsOpen(true);
        },
        [setIsOpen, setViewState],
    );

    const editorState = viewState.editorState;
    const onClosing = useCallback(
        () => {
            setViewState((s: IComposerContext) => ({
                ...s,
                size: "default",
            }));

            setIsOpen(false);

            const html = draftToHtml(
                editorState.getCurrentContent()
            );

            // TODO: Convert html to text

            setEditorText(html);
        },
        [setIsOpen, editorState],
    );

    const handleKeys = e => {
        if (e.key === "Enter") {
            onClick();
        } else if (e.key === "Escape") {
            setIsOpen(false);
        }
    }

    const types = useMemo<SocialComposerTypes[]>(
        () => {
            let retVal: SocialComposerTypes[] = [
                "text",
                "image",
                "frame",
                "poll",
                "video",

                // for testing only:
                "file",
            ];

            if (user.office365Connected && enableSharepoint) {
                retVal.push("external-file");
            }

            if (props.types && props.types.length > 0) {
                retVal = retVal.filter((x) => props.types.includes(x));
            }

            return retVal;
        },
        [enableSharepoint, user, props.types],
    );

    const userName = identity ?
        identity.name :
        user.name;

    const userImage = identity
        ? identity.images.feedComposer
        : user.images.feedComposer;

    const postTypes = visage2SocialPostTypes.filter(
        (x) =>
            !x.hidden &&
            (!disableNewDealPostType || x.type !== SpintrTypes.StatusType.BusinessDeal)
    );

    return (
        <div id="InlineComposer">
            <ComposerContextProvider value={viewState}>
                <div
                    id="InlineComposerButton"
                    aria-label={placeholder || localize("COMPOSER_PLACEHOLDER")}
                    className={classNames("opener visage-box-shadow", {
                        hideUser: props.hideUser,
                    })}
                    onClick={onClick}
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeys}
                >
                    <div className="top start-page-flexible-padding">
                        {!props.hideUser && (
                            <div className="user">
                                <SpintrUser
                                    hideText={true}
                                    imageUrl={userImage}
                                    name={userName}
                                    size={props.spintrUserSize || circleMedium}
                                    personalName={!!!props.identity}
                                />
                            </div>
                        )}
                        <div className="text-placeholder">
                            <Label
                                size="body-2"
                                color={props.placeholderColor || "mid-grey"}
                            >
                                {placeholder || localize("COMPOSER_PLACEHOLDER")}
                            </Label>
                        </div>
                    </div>
                    {!hidePostTypes && (
                        <div className="type-selector">
                            {postTypes.map((type: any, index: number) => {
                                return (
                                    <UnstyledButton
                                        key={index}
                                        className="type-selector-type"
                                        onClick={() => {
                                            setType(type);
                                            setIsOpen(true);

                                            setTimeout(() => {
                                                setType(undefined);
                                            }, 1000);
                                        }}
                                        style={{
                                            backgroundColor: type.bgColor
                                        }}>
                                        <div className="icon-wrapper">
                                            <Visage2Icon icon={type.icon} hexColor={type.iconColor} type="custom" size="small" />
                                        </div>
                                        <Label size="body-2">
                                            {localize(type.titleTag)}
                                        </Label>
                                    </UnstyledButton>
                                )
                            })}
                        </div>
                    )}
                </div>
                {isOpen && (
                    <Visage2Composer
                        {...props}
                        disableEffects={props.disableEffects}
                        types={types}
                        isUnit={props.isUnit}
                        type={type}
                        feedId={feedId}
                        onClosing={onClosing}
                        identity={identity}
                        systemStatusResources={props.currentUser.systemStatusResources}
                        systemStatusEnabled={props.systemStatusEnabled}
                        isStartPage={props.isStartPage}
                        displayResourceSelector={props.displayResourceSelector}
                    />
                )}
            </ComposerContextProvider>
        </div>
    );
};

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
    systemStatusEnabled: state.app.items.some((app) => app.enabled && app.id === SpintrTypes.SpintrApp.SystemStatus),
});

export default connect(mapStateToProps)(InlineComposer);
