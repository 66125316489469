import {
    NewsSpotlight
} from "./components";

import NewsEditView from "./views/NewsEditView"
import NewsCatalogView from "./views/NewsCatalogView"

export {
    NewsSpotlight,
    NewsEditView,
    NewsCatalogView
};
