
import { Pivot, PivotItem } from "@fluentui/react";
import { AxiosResponse } from 'axios';
import { List, Map } from 'immutable';
import moment from 'moment';
import { PrimaryButton } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { IApplicationState } from 'src/spintr/index';
import { ActionMenu, Loader, PageHeader } from "src/ui";
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import { Style } from 'src/ui/helpers';
import "./MyPagesView.scss";
import api from "src/spintr/SpintrApi";
import { listActionMenuWidth } from "src/ui/helpers/style";

interface IProps {
    instance?: any;
    menuItems?: Spintr.IMainMenuItem[];
    dispatch?: any;
    history?: any;
}

interface IState {
    displayExpired: boolean;
    isLoading: boolean;
}

class MyPagesView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();
    private listRef2 = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        this.state = {
            displayExpired: false,
            isLoading: false
        };
    }

    renderList1() {
        return (
            <SpintrList
                ref={this.listRef}
                history={this.props.history}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    if (this.state.displayExpired) {
                        searchQuery = localize("Forfallet");
                    }

                    return new Promise((resolve, reject) => {
                        api.get("/api/pages/mypages", {
                            params: {
                                directType: 5,
                                orderByColumn: columnId,
                                isAscending: isAscending,
                                searchText: searchQuery,
                                take: take,
                                skip: skip
                            },
                        })
                            .then((response: AxiosResponse) => {
                                resolve({
                                    data: response.data,
                                    totalCount: response.headers["x-total-count"],
                                });
                            });
                    });
                }}
                columns={[
                    {
                        name: localize("Titel"),
                        fieldName: "title",
                        maxWidth: 400,
                        onRender: (item) => {
                            return (
                                <span>
                                    {item.title}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("SenastAndrad"),
                        fieldName: "lastChanged",
                        minWidth: 200,
                        onRender: (item) => {
                            return (
                                <span>
                                    {moment(item.lastChanged).format("LLL")}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("Forfaller"),
                        fieldName: "expires",
                        minWidth: 200,
                        onRender: (item) => {
                            if (!item.expires) {
                                return null;
                            }

                            const interval = item.expires.toString().substring(0, 1);

                            let expires;

                            if (interval === '1')
                                expires = moment(item.lastChanged).add(1, "month");
                            else if (interval === '2')
                                expires = moment(item.lastChanged).add(2, "month");
                            else if (interval === '3')
                                expires = moment(item.lastChanged).add(6, "month");
                            else if (interval === '4')
                                expires = moment(item.lastChanged).add(12, "month");

                            if (!expires) {
                                return null;
                            }

                            return (
                                <span>
                                    {expires.format("LLL")}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("Status"),
                        fieldName: "status",
                        minWidth: 100,
                        onRender: (item) => {
                            let statuses = ["", "Utkast", "Publicerad", "Avpublicerad", "Borttagen", "Utganget", "Utganget"];

                            return (
                                <span>
                                    {localize(statuses[item.status])}
                                </span>
                            );
                        },
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            if (item.readonly) {
                                return null;
                            }

                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () =>
                                                        this.props.history.push(
                                                            `/pages/${item.id}/edit`
                                                        ),
                                                },
                                                ...(!item.isDeleted && item.isPublished && item.menuUrl && item.menuUrl.length > 0 ?
                                                    [{
                                                        text: localize("GaTillTextsida"),
                                                        onClick: () =>
                                                            this.props.history.push(
                                                                `/goto/${item.id}`
                                                            ),
                                                    }] :
                                                    []),
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn="title"
            ></SpintrList>
        )
    }

    renderList2() {
        return (
            <SpintrList
                ref={this.listRef2}
                history={this.props.history}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    if (this.state.displayExpired) {
                        searchQuery = localize("Forfallet");
                    }

                    return new Promise((resolve, reject) => {
                        api.get("/api/pages/mypages", {
                            params: {
                                directType: 3,
                                orderByColumn: columnId,
                                isAscending: isAscending,
                                searchText: searchQuery,
                                take: take,
                                skip: skip
                            },
                        })
                            .then((response: AxiosResponse) => {
                                resolve({
                                    data: response.data,
                                    totalCount: response.headers["x-total-count"],
                                });
                            });
                    });
                }}
                columns={[
                    {
                        name: localize("Titel"),
                        fieldName: "title",
                        maxWidth: 400,
                        onRender: (item) => {
                            return (
                                <span>
                                    {item.title}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("SenastAndrad"),
                        fieldName: "lastChanged",
                        minWidth: 200,
                        onRender: (item) => {
                            return (
                                <span>
                                    {moment(item.lastChanged).format("LLL")}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("Forfaller"),
                        fieldName: "expires",
                        minWidth: 200,
                        onRender: (item) => {
                            if (!item.expires) {
                                return null;
                            }

                            const interval = item.expires.toString().substring(0, 1);

                            let expires;

                            if (interval == '1')
                                expires = moment(item.lastChanged).add(1, "month");
                            else if (interval == '2')
                                expires = moment(item.lastChanged).add(2, "month");
                            else if (interval == '3')
                                expires = moment(item.lastChanged).add(6, "month");
                            else if (interval == '4')
                                expires = moment(item.lastChanged).add(12, "month");

                            if (!expires) {
                                return null;
                            }

                            return (
                                <span>
                                    {expires.format("LLL")}
                                </span>
                            );
                        },
                    },
                    {
                        name: localize("Status"),
                        fieldName: "status",
                        minWidth: 100,
                        onRender: (item) => {
                            let statuses = ["", "Utkast", "Publicerad", "Avpublicerad", "Borttagen", "Utganget", "Utganget"];

                            return (
                                <span>
                                    {localize(statuses[item.status])}
                                </span>
                            );
                        },
                    },
                    {
                        name: "",
                        minWidth: listActionMenuWidth,
                        maxWidth: listActionMenuWidth,
                        onRender: (item: any) => {
                            if (item.readonly) {
                                return null;
                            }

                            return (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                {
                                                    text: localize("Redigera"),
                                                    onClick: () =>
                                                        this.props.history.push(
                                                            `/pages/${item.id}/edit`
                                                        ),
                                                },
                                                ...(!item.isDeleted && item.isPublished && item.menuUrl && item.menuUrl.length > 0 ?
                                                    [{
                                                        text: localize("GaTillTextsida"),
                                                        onClick: () =>
                                                            this.props.history.push(
                                                                `/goto/${item.id}`
                                                            ),
                                                    }] :
                                                    []),
                                            ],
                                        },
                                    ]}
                                />
                            );
                        },
                    },
                ]}
                orderByColumn="title"
            ></SpintrList>
        )
    }

    render() {
        return (
            <div className="MyPagesView page-margin-bottom">
                <div>
                    <div style={{
                        marginBottom: Style.getSpacing(3)
                    }}>
                        <PageHeader title={localize("MinaSidor")} />
                        <PrimaryButton onClick={() => {
                            this.setState({
                                isLoading: true
                            }, () => {
                                this.setState({
                                    displayExpired: !this.state.displayExpired,
                                    isLoading: false
                                });
                            });
                        }}>
                            {
                                !this.state.displayExpired && (localize("FiltreraVyn") + ": " + localize("Forfallet"))
                            }
                            {
                                this.state.displayExpired && (localize("FiltreraVyn") + ": " + localize("VisaAlla"))
                            }
                        </PrimaryButton>
                    </div>
                    <Pivot overflowBehavior={"menu"}>
                        <PivotItem headerText={localize("Ansvarig_Utgivare")}>
                            {
                                this.state.isLoading && (
                                    <Loader />
                                )
                            }
                            {
                                !this.state.isLoading && this.renderList1()
                            }
                        </PivotItem>
                        <PivotItem headerText={localize("GodkannaraAvInnehall")}>
                            {
                                this.state.isLoading && (
                                    <Loader />
                                )
                            }
                            {
                                !this.state.isLoading && this.renderList2()
                            }
                        </PivotItem>
                    </Pivot>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    instance: state.instance,
    menuItems: state.menu.main.items,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(MyPagesView));
