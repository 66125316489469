import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router";
import { getLocalizationTag } from "src/l10n";
import { AppPageContextProvider } from "src/marketplace/components";
import { IAppPageResponse } from "src/marketplace/types";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { AppPageView } from "../AppPageView";

interface IRouteParams {
    idOrSlug: string;
}

interface IState {
    isLoading: boolean;
    page?: IAppPageResponse;
}

interface IPageVariables { [propertyKey: string]: any };

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/gmi;

const AppRootView: FunctionComponent = () => {
    const { idOrSlug } = useParams<IRouteParams>();
    const [state, setState] = useState<IState>({ isLoading: false });
    const language = (getLocalizationTag() || "sv").toLowerCase();

    const localizationDictionaries = state?.page?.text || {};
    const applyLocalization = useCallback(
        (text: string) => (text || "").replace(
            /(?:\[\[(?<name>[^\]]+)\]\])/gmi,
            (_, key) => localizationDictionaries[language][key]
        ),
        [language, localizationDictionaries],
    );

    useEffect(
        () => {
            // TODO: Add request here

            const isId = uuidRegex.test(idOrSlug);

            console.log("Part of the path has changed", idOrSlug);
            setState((currentState) => ({...currentState, isLoading: true }));

            const cancelTokenSource = axios.CancelToken.source();
            
            const promise = api.get<IAppPageResponse>(
                isId
                    ? `/api/v1/MarketplaceApplicationPage/${idOrSlug}`
                    : `/api/v1/routes`,
                {
                    cancelToken: cancelTokenSource.token,
                    params: isId
                        ? undefined 
                        : {
                            route: `app/${idOrSlug}`,
                        },
                }
            );

            promise.then(
                (response) => setState((currentState) => ({
                    ...currentState,
                    isLoading: false,
                    page: {
                        ...response.data
                    },
                })),
                (reason) => {
                    console.log(reason);
                }
            );

            return () => {
                cancelTokenSource.cancel("The ID or slug has changed");
            };
        },
        [setState, idOrSlug],
    );

    const variables: IPageVariables = {
        appTitle: state?.page?.appTitle,
        appUrl: state?.page?.appUrl,
        ...(state?.page?.pageVariables || {}),
    };

    return (
        <div id="AppRootView">
            {state.isLoading && <Loader />}
            {!state.isLoading && state.page && (
                <AppPageContextProvider value={{
                    appHeaderUrl: state.page.headerUrl,
                    appId: state.page.id,
                    appLogoUrl: state.page.logoUrl,
                    appName: state.page.name,
                    appTitle: state.page.appTitle,
                    appUrl: state.page.appUrl,
                    text: state.page.text,

                    localize: applyLocalization,
                }}>
                    <div className="page-wrapper">
                        <Switch>
                            {state.page.views.map((view) => (
                                <Route
                                    exact={view.path.length === 0}
                                    key={view.path}
                                    path={`/app/${idOrSlug}${view.path}`}
                                >
                                    <AppPageView
                                        view={view}
                                        pageVariables={variables}
                                    />
                                </Route>
                            ))}
                            <Redirect to={`/app/${idOrSlug}`} />
                        </Switch>
                    </div>
                </AppPageContextProvider>
            )}
        </div>
    );
};

export default AppRootView;
