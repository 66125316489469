import { AxiosError } from "axios";
import {
    Dropdown,
    IDropdownOption,
    Pivot,
    PivotItem,
    TextField
} from "@fluentui/react";
import React, {
    ChangeEvent,
    Component,
    ReactNode
} from "react";
import {
    Redirect,
    RouteComponentProps
} from "react-router";
import { localize } from "src/l10n";
import { findMarketplaceApp, saveMarketplaceApp } from "src/marketplace/api";
import {
    AuthenticationDataComponent,
    CustomHeaderSetup,
    CustomQueryStringSetup,
    OAuth2ClientCredentialsSetup
} from "src/marketplace/components";
import { ContentImageViewerAndSelector, Label, Loader } from "src/ui";
import {
    FormControl,
    FormFooterBar,
    FormSection,
    FormTokenizedInput
} from "src/ui/components/Forms";
import FormElement from "src/ui/components/Forms/FormElement";
import ITokenizedInputItem from "src/ui/components/Forms/ITokenizedInputItem";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import {
    fieldValidation,
    FieldValidator,
    IValidationResult,
    minLength,
    regex,
    required,
    validateModel
} from "src/utils/validation";
import "./MarketplaceAppFormView.scss";

interface IAppCategory {
    id: string;
    name: string;
}

const categories: IAppCategory[] = [{
    id: "1",
    name: "PRODUCTIVITY",
}, {
    id: "2",
    name: "COLLABORATION",
}]

interface IAuthenticationMethod {
    text: string;
    value: number;
}

interface IMarketplaceAppFormViewState {
    errorMessages: string[];
    isLoading: boolean;
    model: Spintr.IMarketplaceAppRequest;
    original: Spintr.IMarketplaceAppResponse;
    redirectTo: string | null;
    validationErrors: IValidationResult;
}

interface IMarketplaceAppFormViewParams {
    id?: string;
}

class MarketplaceAppFormView extends Component<
    RouteComponentProps<IMarketplaceAppFormViewParams>,
    IMarketplaceAppFormViewState
> {
    private readonly formValidation: FieldValidator[];
    private authDataComponent: AuthenticationDataComponent | null = null;
    private authenticationMethods: IAuthenticationMethod[] = [{
        text: localize("ANONYMOUS_ACCESS"),
        value: 1
    }, {
        text: localize("BASIC_ACCESS_AUTH"),
        value: 2
    }, {
        text: localize("OAUTH2_CLIENT_CREDENTIALS"),
        value: 3
    }, {
        text: localize("CUSTOM_HEADER"),
        value: 4
    }, {
        text: localize("CUSTOM_QUERY_STRING"),
        value: 5
    }]

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessages: [],
            isLoading: true,
            model: {
                authenticationMethod: 4,
                banner: null,
                categoryId: "2",
                coverArt: null,
                description: "",
                icon: null,
                instructions: "",
                name: "",
                tags: [{ id: "0", text: "Productivity" }]
            },
            original: null,
            redirectTo: null,
            validationErrors: {}
        };

        this.formValidation = [
            fieldValidation("name", localize("MARKETPLACE_APP_NAME"),
                required(),
                minLength(3),
                regex(/^[\u00C0-\u017Fa-z0-9'][\u00C0-\u017Fa-zA-Z0-9-' ]+[\u00C0-\u017Fa-zA-Z0-9']?$/i)),
            fieldValidation("description", localize("MARKETPLACE_APP_DESCRIPTION"),
                required(),
                minLength(3)),
            fieldValidation("instructions", localize("PRESENTATION"),
                required(),
                minLength(3)),
        ];

        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAuthenticatinMethodChanged = this.onAuthenticatinMethodChanged.bind(this);
        this.onBannerSelected = this.onBannerSelected.bind(this);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
        this.onCoverArtelected = this.onCoverArtelected.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onInstructionsChanged = this.onInstructionsChanged.bind(this);
        this.onIconSelected = this.onIconSelected.bind(this);
        this.onTagsChanged = this.onTagsChanged.bind(this);
        this.setAuthenticationDataRef = this.setAuthenticationDataRef.bind(this);
        this.onDismissErrors = this.onDismissErrors.bind(this);
    }

    public componentDidMount() {
        if (!this.props.match.params.id) {
            this.setState({ isLoading: false });
            return;
        }

        try {
            findMarketplaceApp(this.props.match.params.id).then(
                app => this.setState({
                    isLoading: false,
                    model: {
                        ...(app as Spintr.IMarketplaceAppBase),
                        banner: null,
                        coverArt: null,
                        icon: null,
                        tags: app.tags,
                    },
                    original: app,
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    public onChange(event: ChangeEvent<FormElement>): void {
        const { name, value } = event.target;

        const model = { ...this.state.model };
        model[name] = value;

        this.setState({
            model,
            validationErrors: validateModel(model, this.formValidation)
        });
    }

    public onInstructionsChanged(content: string): void {
        this.setState((state) => ({
            ...state,
            model: {
                ...state.model,
                instructions: content,
            },
        }));
    }

    public onAuthenticatinMethodChanged(_: any, method: IDropdownOption): void {
        this.setState({
            model: {
                ...this.state.model,
                authenticationMethod: parseInt(method.id, 10)
            }
        });
    }

    public onCategoryChanged(_: any, option: IDropdownOption): void {
        this.setState({
            model: {
                ...this.state.model,
                categoryId: option.id,
            }
        });
    }

    public onTagsChanged(items: ITokenizedInputItem[]): void {
        const tags: Spintr.IMarketplaceTag[] = items.map(
            (item) => ({
                id: "0",
                text: item.name
            }),
        );

        this.setState({
            model: {
                ...this.state.model,
                tags,
            }
        })
    }

    public onIconSelected({ base64 }): void {
        // TODO: Change this to accept base64
        this.setState({
            model: {
                ...this.state.model,
                icon: base64 as string,
            }
        });
    }

    public onBannerSelected({ base64 }): void {
        // TODO: Change this to accept base64
        this.setState({
            model: {
                ...this.state.model,
                banner: base64 as string
            }
        });
    }

    public onCoverArtelected({ base64 }): void {
        this.setState({
            model: {
                ...this.state.model,
                coverArt: base64 as string,
            }
        });
    }

    public isValid() {
        const errors = validateModel(this.state.model, this.formValidation);
        const authDataValid =
            this.authDataComponent === null ||
            this.authDataComponent.isValid();

        if (Object.keys(errors).length === 0 && authDataValid) {
            return true;
        }

        this.setState({ validationErrors: errors });

        return false;
    }

    public onSubmit(): void {
        if (!this.isValid()) {
            return;
        }

        this.setState({
            isLoading: true,
            model: {
                ...this.state.model,
                authenticationData: (!!this.authDataComponent
                    ? this.authDataComponent.getAuthenticationData()
                    : "{}")
            }
        },
            async () => await this.saveApp()
        );
    }

    public async saveApp(): Promise<void> {
        let response: Spintr.IMarketplaceAppResponse = null;

        try {
            response = await saveMarketplaceApp(this.state.model);
        } catch (e) {
            const err = e as AxiosError<
                Spintr.IErrorResponse | Spintr.IValidationError
            >;

            if (!err || !err.response) {
                response = null;
            } else {
                // We have an error message we can display
                const model = err.response.data;
                const errorMessages: string[] = [];

                if ((model as Spintr.IErrorResponse).message) {
                    const errorResponse = model as Spintr.IErrorResponse;
                    errorMessages.push(localize(errorResponse.message));

                    if (model.errors && model.errors.length) {
                        errorMessages.push(
                            ...errorResponse.errors.map(
                                error => localize(error.message)
                            )
                        );
                    }
                } else if ((model as Spintr.IValidationError).title) {
                    const validationError = model as Spintr.IValidationError;
                    errorMessages.push(localize(validationError.title));

                    Object.keys(validationError.errors).forEach(x => {
                        if (Object.prototype.hasOwnProperty.call(validationError.errors, x)) {
                            errorMessages.push(
                                ...validationError.errors[x].map(
                                    m => x + ": " + localize(m)
                                )
                            );
                        }
                    });
                } else {
                    errorMessages.push(localize("TECHNICAL_ERROR"));
                }

                this.setState({
                    errorMessages,
                    isLoading: false
                });

                return;
            }
        }

        if (response == null) {
            this.setState({
                errorMessages: [
                    localize("TECHNICAL_ERROR")
                ],
                isLoading: false
            });
            return;
        }

        this.setState({ redirectTo: "/marketplace" });
    }

    public onCancel(): void {
        this.setState({ redirectTo: "/marketplace" });
    }

    public onDismissErrors(): void {
        this.setState({ errorMessages: [] });
    }

    public render(): ReactNode {
        if (this.state.redirectTo !== null) {
            return <Redirect to={this.state.redirectTo} />;
        }

        const hasErrors = this.state.errorMessages.length > 0;

        return (
            <div>
                {this.state.isLoading && <Loader />}
                <div
                    className={this.state.isLoading ? "loading" : undefined}
                    id="MarketplaceAppFormView"
                >
                    <div className="view-header">
                        <ContentImageViewerAndSelector
                            cropImage={true}
                            height={290}
                            cropAspect={1650 / 290}
                            imageUrl={this.state.original?.bannerUrl}
                            editMode={true}
                            onChange={this.onBannerSelected}
                        />
                        <div className="wrapper">
                            <div className="header-text">
                                <div className="image">
                                    <ContentImageViewerAndSelector
                                        cropImage={true}
                                        height={100}
                                        cropAspect={1}
                                        imageUrl={this.state.original?.iconUrl}
                                        editMode={true}
                                        onChange={this.onIconSelected}
                                        placeholderString={localize("ValjBild")}
                                    />
                                </div>
                                <div className="text">
                                    <Label
                                        as="span"
                                        className="app-title"
                                        color="white"
                                        size="h1"
                                        weight="medium"
                                    >
                                        {this.state.model.name}
                                    </Label>
                                    <Label
                                        as="span"
                                        className="category-name"
                                        color="white"
                                        size="h4"
                                        weight="regular"
                                    >
                                        CategoryName
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="view-body">
                        <div className="main-content">
                            {hasErrors && (
                                <ErrorMessagebar
                                    errorList={this.state.errorMessages}
                                    onDismiss={this.onDismissErrors}
                                />
                            )}
                            <Pivot>
                                <PivotItem headerText={localize("Information")}>
                                    <FormSection>
                                        <FormControl>
                                            <TextField
                                                errorMessage={this.state.validationErrors["name"]}
                                                label={localize("MARKETPLACE_APP_NAME")}
                                                name="name"
                                                onChange={this.onChange}
                                                value={this.state.model.name}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                errorMessage={this.state.validationErrors.description}
                                                label={localize("Beskrivning")}
                                                multiline={true}
                                                name="description"
                                                onChange={this.onChange}
                                                value={this.state.model.description}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <TinyEditorComponent
                                                id="marketplace-app-instructions"
                                                content={this.state.model.instructions}
                                                label={localize("Information")}
                                                onChange={this.onInstructionsChanged}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Dropdown
                                                label={localize("Katagori")}
                                                onChange={this.onCategoryChanged}
                                                options={categories.map((category): IDropdownOption => ({
                                                    id: category.id,
                                                    isSelected: category.id === this.state
                                                        .model
                                                        .categoryId
                                                        .toString(),
                                                    key: "",
                                                    text: localize(category.name)
                                                }))}
                                            />
                                        </FormControl>
                                        <FormTokenizedInput
                                            items={this.state.model.tags.map(tag => ({
                                                key: tag.id,
                                                name: tag.text,
                                            }))}
                                            label={localize("Taggar")}
                                            onChange={this.onTagsChanged}
                                        />
                                    </FormSection>
                                </PivotItem>
                                <PivotItem
                                    headerText={localize("Utseende")}
                                >
                                    <FormControl label={localize("MARKETPLACE_CATALOGUE_IMAGE")}>
                                        <div className="cover-art-image">
                                            <ContentImageViewerAndSelector
                                                cropImage={true}
                                                height={180}
                                                cropAspect={1.9445}
                                                imageUrl={this.state.original?.coverArtUrl}
                                                editMode={true}
                                                onChange={this.onCoverArtelected}
                                                placeholderString={localize("ValjBild")}
                                            />
                                        </div>
                                    </FormControl>
                                </PivotItem>
                                <PivotItem headerText={localize("Autentisering")}>
                                    <Dropdown
                                        label={localize("AUTHENTICATION_METHOD")}
                                        options={this.authenticationMethods.map((method): IDropdownOption => ({
                                            id: method.value.toString(10),
                                            isSelected: method.value === this.state.model.authenticationMethod,
                                            key: `authMethod_${method.value.toString(10)}`,
                                            text: method.text
                                        }))}
                                        onChange={this.onAuthenticatinMethodChanged}
                                    />
                                    {this.renderAuthenticationSection()}
                                </PivotItem>
                            </Pivot>
                            <FormFooterBar
                                onCancelClick={this.onCancel}
                                onSaveClick={this.onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public renderAuthenticationSection(): ReactNode {
        const authMethod = this.state.model.authenticationMethod;

        switch (authMethod) {
            default:
            case 1: // Anonymous Access
            case 2: // HTTP Basic
                return null;
            case 3:
                return <OAuth2ClientCredentialsSetup
                    ref={this.setAuthenticationDataRef} />;
            case 4:
                return <CustomHeaderSetup
                    ref={this.setAuthenticationDataRef} />
            case 5:
                return <CustomQueryStringSetup
                    ref={this.setAuthenticationDataRef} />
        }
    }

    public setAuthenticationDataRef(node: any): void {
        const authDataNode = node as AuthenticationDataComponent;

        this.authDataComponent = authDataNode;
    }
}

export default MarketplaceAppFormView;