import React from "react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./AcademyCourseSidebarProgressCircleSmall.scss";

interface IProps {
    isDone: boolean;
    isEligable: boolean;
    isActive: boolean;
    isTest?: boolean;
}


const AcademyCourseSidebarProgressCircleSmall = (props: IProps) => {
    if (props.isDone) {
        return (
            <div className="AcademyCourseSidebarProgressCircleSmall done">
                <Visage2Icon icon="tick-circle" color="green" type="bold" size="small" />
            </div>
        )
    }

    if (!props.isEligable) {
        return (
            <div className="AcademyCourseSidebarProgressCircleSmall locked">
                <Visage2Icon icon="lock" size="small" color="grey" />
            </div>
        )
    }

    if (props.isTest) {
        return (
            <div className="AcademyCourseSidebarProgressCircleSmall star">
                <Visage2Icon icon="star" size="small" color="dark-grey" />
            </div>
        )
    }

    if (props.isActive) {
        return (
            <div className="AcademyCourseSidebarProgressCircleSmall idle">
                <Visage2Icon icon="tick-circle" size="small" color="dark-grey" />
            </div>
        )
    }

    return (
        <div className="AcademyCourseSidebarProgressCircleSmall idle">
            <Visage2Icon icon="tick-circle" size="small" color="grey" />
        </div>
    )
}

export default AcademyCourseSidebarProgressCircleSmall;
