import { TextField } from "@fluentui/react";
import React, { ChangeEvent, ReactNode } from "react";

import { localize, localizeFormat } from "src/l10n";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

const standardFields: string[] = [
    "clientId",
    "clientSecret",
    "consentEndpoint",
    "tokenEndpoint"
];

/**
 * Used for configuring OAuth2 Client Credentials
 */
class OAuth2ClientCredentialsSetup extends AuthenticationDataComponent {
    private clientIdLabel: string = localize("OAUTH2_CLIENT_ID");
    private clientSecretLabel: string = localize("OAUTH2_CLIENT_SECRET");
    private consentEndpointLabel: string = localize("OAUTH2_CONSENT_ENDPOINT");
    private tokenEndpointLabel: string = localize("OAUTH2_TOKEN_ENDPOINT");

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                clientId: "",
                clientSecret: "",
                consentEndpoint: "",
                tokenEndpoint: "",
                variables: {}
            },
            errorMessages: {}
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    public getAuthenticationData(): string {
        return JSON.stringify(this.state.data);
    }    
    
    public isValid(): boolean {
        const errors: {[key: string]: string} = {};

        standardFields.forEach(name => {
            if (this.state.data[name].length > 0) {
                return;
            }

            errors[name] = localize("INVALID_INPUT");
        });

        Object.keys(this.state.data.variables)
            .filter(key => Object.prototype.hasOwnProperty.call(
                this.state.data.variables,
                key
            ))
            .forEach(name => {
                if (this.state.data.variables[name].length > 0) {
                    return;
                }
    
                errors[name] = localize("INVALID_INPUT");
            });
            
        this.setState({ errorMessages: errors });
        
        return Object.keys(errors).length === 0;
    }

    public onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        const { name, value } = event.target;
        
        const data = { 
            variables: { ...this.state.data.variables },
            ...this.state.data 
        };
        if (standardFields.indexOf(name) > -1) {
            data[name] = value.trim();
        } else {
            data.variables[name] = value;
        }

        if (name === "tokenEndpoint" || name === "consentEndpoint") {
            const variablePattern = /(?:\{([a-z]+)\})/mig;
            const variables: {[key: string]: string} = {};

            let match: RegExpExecArray;
            do {
                match = variablePattern.exec(value);
                if (match) {
                    variables[match[1]] = data.variables[match[1]] || "";
                }
            } while (match);

            data.variables = variables;
        }

        this.setState({data}, () => this.isValid());
    }

    public render(): ReactNode {
        return (
            <div id="OAuth2ClientCredentialsSetup">
                <TextField
                    errorMessage={
                        this.state.errorMessages["clientId"]
                    }
                    label={this.clientIdLabel}
                    name="clientId"
                    onChange={this.onValueChanged}
                    value={this.state.data.clientId}
                />
                <TextField
                    errorMessage={
                        this.state.errorMessages["clientSecret"]
                    }
                    label={this.clientSecretLabel}
                    name="clientSecret"
                    onChange={this.onValueChanged}
                    value={this.state.data.clientSecret}
                />
                <TextField
                    errorMessage={
                        this.state.errorMessages["consentEndpoint"]
                    }
                    label={this.consentEndpointLabel}
                    name="consentEndpoint"
                    onChange={this.onValueChanged}
                    value={this.state.data.consentEndpoint}
                />
                <TextField
                    errorMessage={
                        this.state.errorMessages["tokenEndpoint"]
                    }
                    label={this.tokenEndpointLabel}
                    name="tokenEndpoint"
                    onChange={this.onValueChanged}
                    value={this.state.data.tokenEndpoint}
                />
                {
                    Object.keys(this.state.data.variables)
                        .filter(key => Object.prototype.hasOwnProperty.call(
                            this.state.data.variables,
                            key
                        ))
                        .map(variableName => (
                            <TextField
                                errorMessage={
                                    this.state.errorMessages[variableName]
                                }
                                key={`variableField_${variableName}`}
                                label={
                                    localizeFormat(
                                        "NAME_OF_VARIABLE", 
                                        variableName
                                    )
                                }
                                name={variableName}
                                onChange={this.onValueChanged}
                                value={this.state.data.variables[variableName]}
                            />
                        ))
                }
            </div>
        )
    }
}

export default OAuth2ClientCredentialsSetup;