import { Icon } from "@fluentui/react";
import { EditorState } from "draft-js";
import React, { MouseEvent, ReactNode } from "react";
import { DispatchProp } from "react-redux";
import { localize } from "src/l10n";
import Bookmark from "src/social-feed/components/SocialBookmark/Bookmark";
import api from "src/spintr/SpintrApi";
import { Label, Loader, UnstyledButton } from "src/ui";
import { debounce } from "src/utils";
import { ComposerContext } from "./ComposerContext";
import { IComposerTextContent } from "./IComposerViewState";
import SocialComposerType, {
    IComposerSaveOptions,
    ISocialComposerTypeProps as BaseProps
} from "./SocialComposerType";
import { extractBookmarkUrl } from "./utils";

import "./SocialComposerText.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IState {}

const initialState: IComposerTextContent = {
    bookmark: null,
    bookmarkImageIndex: 0,
    bookmarkType: null,
    bookmarkUrl: null,
    embedUrl: null,
    isFetchingBookmark: false,
};

type Props = BaseProps & DispatchProp;

class SocialComposerText extends SocialComposerType<Props, IState> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};

        this.onEffectButtonClick = this.onEffectButtonClick.bind(this);
        this.onRemoveBookmarkClick = this.onRemoveBookmarkClick.bind(this);
    }

    public onTextChanged(editorState: EditorState): void {
        const contentState = this.getContentState();

        const result = extractBookmarkUrl(
            editorState.getCurrentContent()
        );

        if (result === false || !result?.url) {
            const text = editorState
                .getCurrentContent()
                .getBlocksAsArray()
                .map((block) => block.getText())
                .join("\n");

            const prevText = this.context.editorState
                .getCurrentContent()
                .getBlocksAsArray()
                .map((block) => block.getText())
                .join("\n");

            if (prevText === text) {
                return;
            }

            return;
        }

        if (result.url === contentState.bookmarkUrl) {
            return;
        }

        const nextState = {
            bookmarkType: result.type,
            bookmarkUrl: result.url,
            embedUrl: result.url,
            isFetchingBookmark: true,
        };

        switch (result.type) {
            case 2:
                const ytArr = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/.exec(result.url);
                if (!ytArr || ytArr.length === 0) {
                    break;
                }

                const ytId = ytArr[1];
                nextState.embedUrl = `https://www.youtube.com/embed/${ytId}`;
                break;

            case 3:
                const vimeoId = /[^/]*$/.exec(result.url)[0];
                nextState.embedUrl = `https://player.vimeo.com/video/${vimeoId}`;
                break;

            case 6:
                const svtPlayParts = result.url.split("video/");
                if (svtPlayParts && svtPlayParts.length > 1) {
                    const videoId = svtPlayParts[1].split("/")[0];

                    nextState.embedUrl = `http://www.svtplay.se/video/${videoId}?type=embed&external=true`;
                    break;
                }

                const clipId = result.url
                    .split("klipp/")[1]
                    .split("/")[0];

                nextState.embedUrl = `http://www.svtplay.se/klipp/${clipId}?type=embed&external=true`;
                break;

            case 7:
                const trackId = /[^/]*$/.exec(result.url)[0];
                nextState.embedUrl = `https://embed.spotify.com/?uri=spotify:track:${trackId}`;
                break;
        }

        const state: IComposerTextContent = {
            ...contentState,
            ...nextState,
        };

        this.context?.setContentState(
            state,
            () => this.debouncedFetch(),
        );
    }

    public async saveContent(options: IComposerSaveOptions):
        Promise<Spintr.ISocialPostBase | null> {
        const contentState = this.getContentState();
        const { bookmark } = contentState;

        if (!bookmark || !bookmark.title) {
            const doSaveText = async options => {
                const statusModel = {
                    feedId: options.feedId,
                    text: options.text,
                    flag: options.flag
                };

                const formData = new FormData();
                formData.append("jsonString", JSON.stringify(statusModel));

                try {
                    this.context.setContentState(initialState);

                    const response = await api.post<Spintr.ISocialStatus>(
                        "/api/v1/status",
                        formData,
                        {
                            headers: {
                                ...(options.identity?.id ? { "x-impersonate-user": options.identity?.id } : {})
                            }
                        }
                    );

                    return response.data;
                } catch (err) {
                    console.log(err);

                    return null;
                }
            }

            return await doSaveText(options)
        } else {
            const bookmarkModel = {
                ...bookmark,
                feedId: options.feedId,
                images: [bookmark.images[contentState.bookmarkImageIndex]],
                text: options.text,
                type: contentState.bookmarkType,
                flag: options.flag,
            };

            try {
                this.context.setContentState(initialState);

                const response = await api.post<Spintr.ISocialPostBase>(
                    "/api/v1/bookmarks/save",
                    bookmarkModel
                );

                return response.data;
            } catch (err) {
                return null;
            }
        }
    }

    protected getContentState(): IComposerTextContent {
        const contentState = this.context.contentState as IComposerTextContent;

        return contentState || initialState;
    }

    protected renderTypeContent(): React.ReactNode {
        const contentState = this.getContentState();

        if (contentState.isFetchingBookmark) {
            return <Loader />;
        }

        const {
            bookmark,
            bookmarkImageIndex,
            bookmarkType,
        } = contentState;

        if (!bookmark) {
            return null;
        }

        return (
            <div className="bookmark-stage">
                <div className="SocialComposerText bookmark">
                    <Bookmark
                        text={bookmark.text}
                        bookmarkImage={bookmark.images[bookmarkImageIndex]}
                        bookmarkDescription={bookmark.description}
                        bookmarkTitle={bookmark.title}
                        bookmarkType={bookmarkType}
                        bookmarkUrl={bookmark.url}
                    />
                </div>
                <div className="bookmark-actions">
                    {bookmarkType === 1 && bookmark.images.length > 0 && (
                        <div className="group box-shadow">
                            <a
                                title={localize("Foregaende") + " " + localize("bild_inline")}
                                className="action"
                                onClick={this.onImageLeftButtonClick.bind(this)}
                            >
                                <Visage2Icon icon="arrow-left-3" />
                            </a>
                            <a
                                title={localize("Nasta") + " " + localize("bild_inline")}
                                className="action"
                                onClick={this.onImageRightButtonClick.bind(this)}
                            >
                                <Visage2Icon icon="arrow-right-3" />
                            </a>
                        </div>
                    )}
                    <div className="group right box-shadow">
                        <a
                            title={localize("TaBortBild")}
                            className="action remove"
                            onClick={this.onRemoveBookmarkClick}
                        >
                            <Visage2Icon icon="close-circle" />
                    </a>
                    </div>
                </div>
            </div>
        );
    }

    protected debouncedFetch = debounce(() => this.fetch(), 500);

    protected fetch = async () => {
        const contentState = this.getContentState();

        try {
            const response = await api.get<Spintr.IBookmarkProjection>(
                "/api/bookmarks/fetchimages",
                {
                    params: {
                        currentHost: window.document.location.hostname,
                        url: contentState.embedUrl,
                    }
                }
            );

            const bookmark = response.data;
            if (bookmark.description) {
                const div = document.createElement("div");
                div.innerHTML = bookmark.description;
                bookmark.description = div.innerText;
            }

            this.context.setContentState({
                ...contentState,
                bookmark,
                isFetchingBookmark: false,
            });
        } catch (err) {
            console.log(err);
        }
    }

    protected onImageLeftButtonClick(event: MouseEvent<HTMLAnchorElement>): void {
        const contentState = this.getContentState();

        this.context.setContentState({
            ...contentState,
            bookmarkImageIndex: contentState.bookmarkImageIndex === 0 ?
                (contentState.bookmark.images.length - 1) :
                (contentState.bookmarkImageIndex - 1)
        });
    }

    protected onImageRightButtonClick(event: MouseEvent<HTMLAnchorElement>): void {
        const contentState = this.getContentState();

        this.context.setContentState({
            ...contentState,
            bookmarkImageIndex: contentState.bookmarkImageIndex === (contentState.bookmark.images.length - 1) ?
                0 :
                (contentState.bookmarkImageIndex + 1)
        });
    }

    protected onRemoveBookmarkClick(): void {
        const contentState = this.getContentState();

        this.context.setContentState({
            ...contentState,
            bookmark: undefined,
            bookmarkImageIndex: 0,
            bookmarkType: undefined,
            // Don't reset bookmarkUrl. This will prevent fetch from triggering
            // again once another key is pressed.
            embedUrl: undefined
        });
    }

    protected renderTextInput(): ReactNode {
        const contentState = this.getContentState();
        const effectText = localize("ADD_EFFECT");

        return (
            <div className="text-input">
                <div className="input-wrapper">
                    {this.props.textInput}
                </div>
                {!contentState.bookmark && (
                    <div className="effect-button">
                        <UnstyledButton
                            ariaLabel={effectText}
                            title={effectText}
                            onClick={this.onEffectButtonClick}
                        >
                            <Label as="div" size="body-3" weight="medium">
                                Aa
                            </Label>
                        </UnstyledButton>
                    </div>
                )}
            </div>
        )
    }

    protected onEffectButtonClick(): void {
        if (!this.props.onTypeChange) {
            return;
        }

        this.props.onTypeChange("frame");
    }
}

export default SocialComposerText;