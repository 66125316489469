import React, { FunctionComponent } from "react";
import OperatingInfo from "src/ui/components/OperatingInfo/OperatingInfo";

const OperatingInfoWrapper: FunctionComponent = () => {
    return (
        <div className="OperatingInfoWrapper">
            <OperatingInfo
                hideTitle={false}
                disableButtons={false}
            />
        </div>
    );
};

export default OperatingInfoWrapper;
