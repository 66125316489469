import { combineReducers } from "redux";

import fileReducer, { IFileFileState } from "./file-reducer";
import folderReducer from "./folder-reducer";
import zohoPopupReducer, { IZohoPopupState } from "./zoho-popup-reducer";

export interface IFileState {
    files: IFileFileState;
    folders: any;
    zohoPopup: IZohoPopupState;
}

const filesReducer = combineReducers({
    files: fileReducer,
    folders: folderReducer,
    zohoPopup: zohoPopupReducer,
});

export default filesReducer;
