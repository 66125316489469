import React, { ReactElement, useEffect, useState } from "react";
import {
    ProductImagesProps as Props,
} from "./ProductImages.types";
import {
    Image,
    Stack,
    StackItem,
} from "@fluentui/react";
import classNames from "classnames";

function ProductImages({ images, initialSelectedImageUrl }: Props): ReactElement {
    const [highlightedId, setHighlightedId] = useState<number>(0);

    useEffect(() => setHighlightedId((_) => {
        const imageList = images || [];
        
        if (initialSelectedImageUrl) {
            const match = imageList.find(
                (image) => image.imageUrl === initialSelectedImageUrl,
            );

            if (match) {
                return match.id;
            }
        }

        return imageList.map((image) => image.id).find((id) => !!id);
    }), [images]);

    const highlightedImage = images
        .find((image) => image.id === highlightedId);

    return (
        <div className="ProductImages">
            <Stack className="image-stack">
                <StackItem className="primary-image">
                    <Image
                        src={highlightedImage?.imageUrl} />
                </StackItem>
                <StackItem className="thumbnails">
                    <Stack className="thumbnail-stack" horizontal={true}>
                        {images.map((image) => (
                            <StackItem
                                className={classNames("thumbnail", {
                                    highlighted: image.id === highlightedId,
                                })}
                                key={image.id}
                            >
                                <Image
                                    src={image.imageUrl}
                                    onClick={() => setHighlightedId(image.id)} />
                            </StackItem>
                        ))}
                    </Stack>
                </StackItem>
            </Stack>
        </div>
    )
}

export default ProductImages;
