import * as microsoftTeams from "@microsoft/teams-js";
import { string } from "prop-types";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import MiniTitle from "src/news/components/Spotlight/MiniTitle";
import { canUserCreateAndEditNewsArticles } from "src/privileges/utils";
import { SpintrTypes } from "src/typings";
import { ActionMenu, BackgroundImage, Label } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import TranslateButton from "src/ui/components/TranslateButton";
import { decodeHtmlEntities, fromNow, shortenString } from "src/utils";
import "./InformationFeedEntry.scss";
import { markAsRead } from "./actions";

interface IInformationFeedEntryProps extends RouteComponentProps {
    entry: Spintr.IInformationFeedItem;
    onDelete?: any;
    isAdmin: boolean;
    isEditor: boolean;
    isPreview?: boolean;
    canUserCreateAndEditNewsArticles?: boolean;
    identity?: any;
    isInTeamsApp: boolean;
    instance?: any;
    enableTranslator: boolean;
    spotlight?: boolean;
    cropHeaderImagesAfterUpload: boolean;
    template?: SpintrTypes.NewsFeedType;
    dispatch?: any;
    currentUserId: number;
    layout: { name: string, jsonData: string };
}

interface State {
    showInfoDialog: boolean;
    deleteItem: number;
    translatedTexts: object;
}

class InformationFeedEntry extends Component<IInformationFeedEntryProps, State> {
    constructor(props: IInformationFeedEntryProps) {
        super(props);

        this.state = {
            showInfoDialog: false,
            deleteItem: undefined,
            translatedTexts: {
                [SpintrTypes.TranslateType.Title]: this.props.entry.title,
                [SpintrTypes.TranslateType.Preamble]: this.getPreambleText(),
            },
        };
    }

    componentDidUpdate(prevProps: Readonly<IInformationFeedEntryProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (!!prevProps.entry && this.props.entry && prevProps.entry.title !== this.props.entry.title) {
            this.setState({
                translatedTexts: {
                    ...prevState.translatedTexts,
                    [SpintrTypes.TranslateType.Title]: this.props.entry.title,
                },
            })
        }

        if (prevState.translatedTexts?.[SpintrTypes.TranslateType.Preamble] != this.getPreambleText()) {
            this.setState({
                translatedTexts: {
                    ...prevState.translatedTexts,
                    [SpintrTypes.TranslateType.Preamble]: this.getPreambleText(),
                },
            })
        }
    }

    getPreambleText() {
        let entryText = this.props.entry.content;

        if (!this.props.entry.isBlogPost) {
            const newsEntry: Spintr.IInformationFeedNewsArticle = this.props.entry as Spintr.IInformationFeedNewsArticle;

            if (newsEntry.introPreamble) {
                entryText = newsEntry.introPreamble;
            } else if (newsEntry.preamble) {
                entryText = newsEntry.preamble;
            }
        }

        const preambleText = shortenString(decodeHtmlEntities(entryText), 147, "...")

        return preambleText;
    }

    onEditClick() {
        const isBlogPost: boolean = this.props.entry.isBlogPost;

        if (!isBlogPost) {
            this.props.history.push({
                pathname: `/news/edit/${this.props.entry.id}`,
            });
        } else {
            this.props.history.push({
                pathname: `${this.props.entry.url.replace(
                    "bloggar/",
                    "/blogs/"
                )}/edit`,
            });
        }
    }

    protected translate = (text: string, key: string) => {
        //@ts-ignore
        this.setState((prevState) => ({
            translatedTexts: {
                ...prevState.translatedTexts,
                [key]: text,
            },
        }));
    }

    public render(): ReactNode {
        const { entry } = this.props;
        const { showInfoDialog } = this.state;

        const isBlogPost: boolean = entry.isBlogPost;

        let templateId = SpintrTypes.InformationFeedTemplate.Small
        let slug: string = "";
        let blogSlug: string = "";
        let blogUrl = "";

        if (isBlogPost) {
            templateId = entry.imageUrl ? SpintrTypes.InformationFeedTemplate.Medium : SpintrTypes.InformationFeedTemplate.OnlyText;
            slug = entry.url.split("/")[2]; // FIXME
            blogSlug = entry.url.split("/")[1]
            // FIXME: Fix on backend.
            blogUrl = entry.url.replace("bloggar/", "/blogs/")
        } else {
            const newsEntry: Spintr.IInformationFeedNewsArticle = entry as Spintr.IInformationFeedNewsArticle;
            slug = entry.url.split("/").slice(1).join("/");
            templateId = newsEntry.template;
        }

        const ConditionalLink = ({ children }) => {
            if (isBlogPost) {
                return (
                    <Link to={`/blogs/${blogSlug}/${slug}`}>
                        {children}
                    </Link>
                )
            } else if (this.props.entry.isRss) {
                return (
                    <a href={this.props.entry.url} target="_blank">
                        {children}
                    </a>
                )
            } else if (this.props.isInTeamsApp) {
                return (
                    <a onClick={() => {
                        const url = `/news/${slug}`;
                        const teamsAppId = this.props.instance.get("teamsAppId");

                        if (!teamsAppId) {
                            this.props.history.push({
                                pathname: url,
                            });

                            return;
                        }

                        const context = {
                            subEntityId: url
                        }

                        const encodedContext = encodeURIComponent(JSON.stringify(context));

                        const fullUrl = window.location.origin +
                            (url.indexOf("/") === 0 ? "" : "/") +
                            url;

                        const encodedUrl = encodeURIComponent(fullUrl);

                        const deeplinkUrl = "https://teams.microsoft.com/l/entity/" +
                            teamsAppId +
                            "/news" +
                            "?webUrl=" +
                            encodedUrl +
                            "&context=" +
                            encodedContext;

                        microsoftTeams.initialize(() => {
                            microsoftTeams.executeDeepLink(deeplinkUrl, (status, reason) => {
                                console.log(status);
                                console.log(reason);
                                if (!status) {
                                    window.open(fullUrl);
                                }
                            });
                        })
                    }}>
                        {children}
                    </a>
                )
            } else {
                return (
                    <Link to={`/news/${slug}`}>
                        {children}
                    </Link>
                )
            }
        }

        let wrapperClassNames = ["InformationFeedEntry", "visage-box-border"];

        const classNames: string[] = ["entry-wrapper", "template" + templateId];
        if (this.props.spotlight) {
            classNames.push("spotlight");
        }

        let preamble = this.state.translatedTexts[SpintrTypes.TranslateType.Preamble] ?
            this.state.translatedTexts[SpintrTypes.TranslateType.Preamble] :
            this.props.entry.content;
        
        if (!preamble) {
            preamble = "";
        }

        preamble = preamble.replace("<br/>", "").replace("<br>", "").replace("<br />", "");

        return (
            <div className={wrapperClassNames.join(" ")}>
                <CustomDialog
                    message={localize("ArDuSakerAttDuVillUtforaDennaAtgard")}
                    show={showInfoDialog}
                    onDismiss={() => {
                        this.setState({ showInfoDialog: false });
                    }}
                    onConfirm={() => {
                        this.setState({ showInfoDialog: false }, () => { });
                    }}
                />
                {!!this.props.entry.imageUrl && templateId !== SpintrTypes.InformationFeedTemplate.OnlyText && (
                    <div className="entry-content-image">
                        <ConditionalLink>
                            {this.renderImage(entry)}
                            {this.props.spotlight && <div className="gradient-overlay" />}
                        </ConditionalLink>
                    </div>
                )}
                <div className={classNames.join(" ")}>
                    <div className="entry-content">
                        <div className="entry-content-inner">
                            <div className="entry-content-text">
                                <div className="entry-metadata">
                                    <MiniTitle
                                        icon={this.props.entry.isBlogPost ? "card-edit" : "firstline"}
                                        text={!!this.props.entry.uberCategories && this.props.entry.uberCategories.length > 0 ?
                                            this.props.entry.uberCategories[0].name :
                                            this.props.entry.isRss ?
                                                this.props.entry.rssName :
                                                this.props.entry.isBlogPost ?
                                                    this.props.entry.blog :
                                                    localize("Nyhet")
                                        }
                                        readTimeMinutes={entry.readTimeMinutes}
                                        priorityLevel={entry.priorityLevel}
                                        isInNewsFeed
                                    />
                                </div>
                                <div className="entry-lead">
                                    <div className="entry-meta">
                                        <Label size="body-2" color="mid-grey">{fromNow(entry.date, true)}</Label>
                                    </div>
                                    <div className="entry-title general-row-break centered-flex-row">
                                        <ConditionalLink>
                                            <Label as="h4" weight="medium" size="body-5" color="dark-grey">{this.state.translatedTexts[SpintrTypes.TranslateType.Title]}</Label>
                                        </ConditionalLink>
                                        <ActionMenu categories={[{
                                            items: [
                                                ...(!this.props.canUserCreateAndEditNewsArticles ? [] : [{
                                                    text: localize("Redigera"),
                                                    onClick: this.onEditClick.bind(this)
                                                }]),
                                                ...(!entry.isUnread ? [] : [{
                                                    text: localize("chat_mark_read"),
                                                    onClick: () => {
                                                        this.props.dispatch(markAsRead(entry.id, this.props.currentUserId, entry.url));
                                                    }
                                                }]),
                                            ],
                                        }]} />
                                    </div>
                                    {
                                        !!preamble && preamble.length > 0 && (
                                            <div className="entry-preamble">
                                                <ConditionalLink>
                                                    <Label size="body-2" color="dark-grey">
                                                        {
                                                            !this.props.entry.isRss && !this.props.entry.isBlogPost && (
                                                                <span dangerouslySetInnerHTML={{ __html: preamble }}></span>
                                                            )
                                                        }
                                                        {
                                                            (this.props.entry.isRss || this.props.entry.isBlogPost) && (
                                                                preamble
                                                            )
                                                        }
                                                    </Label>
                                                </ConditionalLink>
                                            </div>
                                        )
                                    }
                                    {!this.props.entry.isRss && (
                                        <TranslateButton
                                            authorLanguage={this.props.entry.authorLanguage}
                                            uberId={this.props.entry.id}
                                            texts={{
                                                [SpintrTypes.TranslateType.Title]: this.state.translatedTexts[SpintrTypes.TranslateType.Title],
                                                [SpintrTypes.TranslateType.Preamble]: this.state.translatedTexts[SpintrTypes.TranslateType.Preamble],
                                            }}
                                            onTranslateFn={this.translate}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="entry-interactions" style={this.props.isPreview ? {
                            pointerEvents: "none",
                            cursor: "default"
                        } : {}}>
                            <SocialBlock
                                identity={this.props.identity}
                                uberId={entry.id}
                                uberIsComment={!entry.allowComments}
                                likers={entry.likers}
                                comments={[]}
                                staticCommentCount={entry.commentCount}
                                isUnread={entry.isUnread}
                                onlyDisplayInteractionsBar={true}
                                onCommentIconClick={() => {
                                    this.props.history.push({
                                        pathname: entry.isBlogPost ? `/blogs/${blogSlug}/${slug}` : `/news/${slug}`,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public renderCategoryName(): ReactNode {
        if (this.props.entry.isRss) {
            return (
                <Label className="entry-category-news" as="span">
                    <Label as="span" className="entry-category" color="dark-grey" size="small-2" uppercase>
                        {this.props.entry.rssName}
                    </Label>
                </Label>
            );
        }

        if (!this.props.entry.isBlogPost) {
            return (
                <Label className="entry-category-news" as="span">
                    <Label as="span" className="entry-category" color="dark-grey" size="small-2" uppercase>
                        {localize("Nyhet")}
                    </Label>
                </Label>
            );
        }

        const blogPost = this.props.entry as Spintr.IInformationFeedBlogPost;
        const blogUrl: string = `/goto/${blogPost.blogId}`;

        return (
            <Link className="InformationFeedEntryLink" to={blogUrl} title={blogPost.blog}>
                <Label as="span" className="entry-category" color="dark-grey" size="small-2" uppercase>
                    {blogPost.blog}
                </Label>
            </Link>
        );
    }

    public renderImage(entry: Spintr.IInformationFeedItem): ReactNode {
        if (this.props.cropHeaderImagesAfterUpload) {
            return (
                <div className="entry-image fixed-height">
                    <div className="image-blur" style={{
                        backgroundImage: `url(${entry.imageUrl})`
                    }} />
                    <div className="image-blur-front" style={{
                        backgroundImage: `url(${this.props.entry.imageUrl})`,
                        backgroundSize: "contain"
                    }} />
                </div>
            )
        }

        if (!!this.props.layout?.jsonData) {
            return (
                <BackgroundImage
                    className="entry-image"
                    imageUrl={entry.imageUrl}
                />
            );
        }

        return (
            <img className="entry-image" src={entry.imageUrl} />
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        instance: state.instance,
        canUserCreateAndEditNewsArticles: canUserCreateAndEditNewsArticles(state.privileges.data,
            state.profile.active,
            state.instance),
        isInTeamsApp: state.ui.isInTeamsApp,
        cropHeaderImagesAfterUpload: state.instance.get("cropHeaderImagesAfterUpload"),
        currentUserId: state.profile.active.id,
        layout: state.instance.get("defaultStartPageLayout"),
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(InformationFeedEntry));
