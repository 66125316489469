import { AxiosError, AxiosResponse } from 'axios';
import { Action } from 'redux';
import api from 'src/spintr/SpintrApi';
import { ActionTypes } from './action-types'

interface IFetchInformationFeedAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IInformationFeedItem[]>>;
    type: ActionTypes.FetchInformationFeed;
    meta: IFetchInformationFeedMeta;
}

interface IFetchInformationFeedPendingAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IInformationFeedItem[]>>;
    type: ActionTypes.FetchInformationFeedPending;
    meta: IFetchInformationFeedMeta;
}

interface IFetchInformationFeedRejectedAction extends Action {
    payload: AxiosError<any>;
    type: ActionTypes.FetchInformationFeedRejected;
    meta: IFetchInformationFeedMeta;
}

interface IFetchInformationFeedFulfilledAction extends Action {
    payload: AxiosResponse<Spintr.IInformationFeedItem[] | Spintr.IRssFeedEntry[]>;
    type: ActionTypes.FetchInformationFeedFulfilled;
    meta: IFetchInformationFeedMeta;
}

interface IFetchInformationFeedMeta {
    isRSS: boolean;
    params: any;
    silent?: boolean;
}

export type FetchInformationFeedAction =
    IFetchInformationFeedAction
    | IFetchInformationFeedPendingAction
    | IFetchInformationFeedRejectedAction
    | IFetchInformationFeedFulfilledAction;

export type FetchInformationFeedActionHandler = (isRSS: boolean, config: any, silent?: boolean) => IFetchInformationFeedAction;

interface ISetInformationFeedLoadingAction extends Action {
    type: ActionTypes.SetInformationFeedLoading;
}

export type SetInformationFeedLoadingActionHandler = () => ISetInformationFeedLoadingAction;


export const fetchInformationFeed: FetchInformationFeedActionHandler = (isRSS, config, silent?) => {
    let payload;
    if (isRSS) {
        payload = api.get<Spintr.IRssFeedEntry[]>("/api/v1/rss/feed", config);
    } else {
        payload = api.get<Spintr.IInformationFeedItem[]>("/api/v1/news/feed", config);
    }

    return {
        type: ActionTypes.FetchInformationFeed,
        meta: { isRSS, params: config.params, silent },
        payload,
    }
}

interface IFetchContentCategoriesAction extends Action {
    payload: any;
    type: ActionTypes.FetchContentCategories;
}

interface IFetchContentCategoriesPendingAction extends Action {
    payload: any;
    type: ActionTypes.FetchContentCategoriesPending;
}

interface IFetchContentCategoriesRejectedAction extends Action {
    payload: any;
    type: ActionTypes.FetchContentCategoriesRejected;
}

interface IFetchContentCategoriesFulfilledAction extends Action {
    payload: any;
    type: ActionTypes.FetchContentCategoriesFulfilled;
}

export type FetchContentCategoriesAction =
    IFetchContentCategoriesAction |
    IFetchContentCategoriesPendingAction |
    IFetchContentCategoriesRejectedAction |
    IFetchContentCategoriesFulfilledAction;

export type IFetchContentCategoriesHandler = () => FetchContentCategoriesAction;

export const fetchContentCategories: IFetchContentCategoriesHandler = () => {
    return {
        type: ActionTypes.FetchContentCategories,
        payload: api.get("/api/ubercategories?take=999&includeDeleted=false")
    }
}

interface IFetchImportantArticlesAction extends Action {
    payload: any;
    type: ActionTypes.FetchImportantArticles;
}

interface IFetchImportantArticlesPendingAction extends Action {
    payload: any;
    type: ActionTypes.FetchImportantArticlesPending;
}

interface IFetchImportantArticlesRejectedAction extends Action {
    payload: any;
    type: ActionTypes.FetchImportantArticlesRejected;
}

interface IFetchImportantArticlesFulfilledAction extends Action {
    payload: any;
    type: ActionTypes.FetchImportantArticlesFulfilled;
}

export type FetchImportantArticlesAction =
    IFetchImportantArticlesAction |
    IFetchImportantArticlesPendingAction |
    IFetchImportantArticlesRejectedAction |
    IFetchImportantArticlesFulfilledAction;

export type IFetchImportantArticlesHandler = () => FetchImportantArticlesAction;

export const fetchImportantArticles: IFetchImportantArticlesHandler = () => {
    return {
        type: ActionTypes.FetchImportantArticles,
        payload: api.get("/api/news/unread?onlyPrio=true")
    }
}

interface IMarkAsReadAction extends Action {
    payload: any;
    type: ActionTypes.MarkAsRead;
    meta: { uberId: number, userId: number, url: string };
}

interface IMarkAsReadPendingAction extends Action {
    payload: any;
    type: ActionTypes.MarkAsReadPending;
    meta: { uberId: number, userId: number, url: string };
}

interface IMarkAsReadRejectedAction extends Action {
    payload: any;
    type: ActionTypes.MarkAsReadRejected;
    meta: { uberId: number, userId: number, url: string };
}

interface IMarkAsReadFulfilledAction extends Action {
    payload: any;
    type: ActionTypes.MarkAsReadFulfilled;
    meta: { uberId: number, userId: number, url: string };
}

export type MarkAsReadAction =
    IMarkAsReadAction |
    IMarkAsReadPendingAction |
    IMarkAsReadRejectedAction |
    IMarkAsReadFulfilledAction;

export type IMarkAsReadHandler = (uberId: number, userId: number, url: string) => MarkAsReadAction;

export const markAsRead: IMarkAsReadHandler = (uberId: number, userId: number, url: string) => {
    return {
        type: ActionTypes.MarkAsRead,
        payload: api.post("/api/statistics/visits", [{
            UberId: uberId,
            UserId: userId,
            Url: url
        }]),
        meta: {uberId, userId, url }
    }
}

export const setInformationFeedLoading: SetInformationFeedLoadingActionHandler = () => ({
    type: ActionTypes.SetInformationFeedLoading,
});

interface IClearInformationFeedAction extends Action {
    type: ActionTypes.ClearInformationFeed;
}

export type ClearInformationFeedHandler = () => IClearInformationFeedAction;

export const clearInformationFeed: ClearInformationFeedHandler = () => ({
    type: ActionTypes.ClearInformationFeed,
});

interface IAddImportantArticleAction extends Action {
    type: ActionTypes.AddImportantArticle;
    meta: { item: Spintr.IInformationFeedItem };
}

export type AddImportantArticleHandler = (item: Spintr.IInformationFeedItem) => IAddImportantArticleAction;

export const addImportantArticle: AddImportantArticleHandler = (item: Spintr.IInformationFeedItem) => ({
    type: ActionTypes.AddImportantArticle,
    meta: { item }
});

interface IRemoveImportantArticleAction extends Action {
    type: ActionTypes.RemoveImportantArticle;
    meta: { id: number };
}

export type RemoveImportantArticleHandler = (id: number) => IRemoveImportantArticleAction;

export const removeImportantArticle: RemoveImportantArticleHandler = (id: number) => ({
    type: ActionTypes.RemoveImportantArticle,
    meta: { id }
});

interface IAddItemAction extends Action {
    type: ActionTypes.AddItem;
    meta: { item: Spintr.IInformationFeedItem };
}

export type AddItemHandler = (item: Spintr.IInformationFeedItem) => IAddItemAction;

export const addInformationFeedItem: AddItemHandler = (item: Spintr.IInformationFeedItem) => ({
    type: ActionTypes.AddItem,
    meta: { item }
});

interface IUpdateItemAction extends Action {
    type: ActionTypes.UpdateItem;
    meta: { item: Spintr.IInformationFeedItem };
}

export type UpdateItemHandler = (item: Spintr.IInformationFeedItem) => IUpdateItemAction;

export const updateInformationFeedItem: UpdateItemHandler = (item: Spintr.IInformationFeedItem) => ({
    type: ActionTypes.UpdateItem,
    meta: { item }
});

export type InformationFeedAction =
    FetchInformationFeedAction |
    ISetInformationFeedLoadingAction |
    FetchContentCategoriesAction |
    FetchImportantArticlesAction |
    MarkAsReadAction |
    IClearInformationFeedAction |
    IAddItemAction |
    IUpdateItemAction |
    IAddImportantArticleAction |
    IRemoveImportantArticleAction;
