import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { getTypeName, localize } from "src/l10n";
import capitalizeFirstLetter from "src/utils/capitalizeFirstLetter";

interface IProps {
    notification: Spintr.INotification;
}

const NewContentReview: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.INewContentReview;
    if (!notification) {
        return null;
    }

    const typeText = getTypeName(notification.uberType, { case: 'indefinite' })
    .toLowerCase();

    return (
        <Fragment>
            <Link to={"/admin/publishing"}>
                {capitalizeFirstLetter(typeText)}
            </Link>
            {" " + localize("HarKommitInForGranskning").toLowerCase()}.
        </Fragment>
    );
};

export default NewContentReview;
