import {
    getPlannerItemAsync,
    PlannerQueryParams,
    queryPlannerItemsAsync,
    queryPlannerCategoriesAsync,
    PlannerCategoriesQueryParams,
} from "src/api/plannerApi";

import {
    PlannerActionTypes,
    PlannerFileAddedAction,
    QueryPlannerCategoriesAction,
    QueryPlannerItemsAction
} from "../types";
import { CancelToken } from "axios";

export function plannerItemCreated(item: Spintr.PlannerItem) {
    return {
        type: PlannerActionTypes.PlannerItemCreated,
        item,
    };
}

export function plannerItemUpdated(item: Spintr.PlannerItem) {
    return {
        type: PlannerActionTypes.PlannerItemUpdated,
        item,
    };
}

export function plannerFileAdded(file: Spintr.PlannerItemFile): PlannerFileAddedAction {
    return {
        type: PlannerActionTypes.PlannerFileAdded,
        file,
    };
}

export function queryPlannerCategories(
    params?: PlannerCategoriesQueryParams | undefined,
    cancelToken?: CancelToken | undefined,
): QueryPlannerCategoriesAction {
    return {
        payload: queryPlannerCategoriesAsync(params, cancelToken),
        type: PlannerActionTypes.QueryPlannerCategories,
    };
}

export function queryPlannerItems(
    params?: PlannerQueryParams | undefined,
): QueryPlannerItemsAction {
    return {
        payload: queryPlannerItemsAsync(params),
        type: PlannerActionTypes.QueryPlannerItems,
    };
}

export function findPlannerItem(id: number) {
    return {
        payload: getPlannerItemAsync(id),
        type: PlannerActionTypes.FindPlannerItem,
    };
}
