import moment from "moment";
import capitalizeFirstLetter from "./capitalizeFirstLetter";

export default function formatDatePickerDate(date?: Date) {
    if (!date) {
        return "";
    }

    return capitalizeFirstLetter(moment(date).format("ddd LL"));
}
