import React, { Component } from "react";
import "./GaugeWidget.scss";
import * as d3 from 'd3';
import { uniqueId } from "src/utils";

interface GaugeWidgetData {
    value: number;
    thresholds: number[];
    timestamp?: string;
    isPercentage?: boolean;
    hasThresholdsBelowMinValue?: boolean;
    greyInsteadOfRed?: boolean;
}

interface IGaugeChartProps {
    data: GaugeWidgetData;
    variables: { [key: string]: string };
}

interface IState {
    id: string;
}

class D3GaugeChart extends Component<IGaugeChartProps, IState> {
    private gaugeWrapperRef = React.createRef<HTMLDivElement>();

    constructor(props: IGaugeChartProps) {
        super(props);

        this.state = {
            id: "gauge" + uniqueId()
        }
    }

    runD3() {
        if (this.gaugeWrapperRef == null ||
            this.gaugeWrapperRef.current == null ||
            this.gaugeWrapperRef.current.clientWidth === 0 ||
            this.gaugeWrapperRef.current.clientHeight === 0) {
            setTimeout(() => {
                this.runD3();
            }, 100);

            return;
        }

        const el = d3.select("#" + this.state.id);
        const barWidth = 20;
        const chartInset = 5;

        let arces: any[] = [];

        const thresholdMinValue = this.props.data.thresholds.length > 0 ? this.props.data.thresholds[0] : 0;

        for (let i = 0; i < this.props.data.thresholds.length; i++) {
            if (i === (this.props.data.thresholds.length - 1)) {
                continue;
            }

            if (this.props.variables.hasThresholdsBelowMinValue) {
                if (this.props.data.thresholds[i] < this.props.data.thresholds[0]) {
                    arces.push({
                        from: this.props.data.thresholds[i - 1],
                        to: this.props.data.thresholds[i + 1]
                    });
                }
                else if (this.props.data.thresholds[i + 1] < this.props.data.thresholds[0]) {
                    arces.push({
                        from: this.props.data.thresholds[i],
                        to: this.props.data.thresholds[i]
                    });
                }
                else
                {
                    arces.push({
                        from: this.props.data.thresholds[i],
                        to: this.props.data.thresholds[i + 1]
                    });
                }
            }
            else
            {
                arces.push({
                    from: this.props.data.thresholds[i],
                    to: this.props.data.thresholds[i + 1]
                });
            }
        }

        const percent = valueToPercent(arces, this.props.data.value);

        const margin = 20;
        const width = this.gaugeWrapperRef.current.clientWidth;
        const height = this.gaugeWrapperRef.current.clientHeight;
        const radius = (Math.min(width, height) / 2) - (margin / 2);

        const percToDeg = function (perc) {
            return perc * 360;
        };

        const percToRad = function (perc) {
            return degToRad(percToDeg(perc));
        };

        const degToRad = function (deg) {
            return (deg * Math.PI / 180);
        };

        const svg = el.append('svg').attr('width', width).attr('height', height);
        const chart = svg.append('g').attr('transform', 'translate(' + (width) / 2 + ', ' + (height) / 2 + ')');

        // dark red, red, yellow, green, bright green
        let colors = [["#FF5B29", "red"], ["#FC8763", "#FC744A"], ["#FFDA99", "#F5B544"], ["#9CF598", "#85E480"], ["#85E480", "#77cd73"]];
        
        if (this.props.variables.greyInsteadOfRed) {
            colors[0] = ["#E0E0E1", "#CCCCCD"];     // dark grey
            colors[1] = ["#F4F4F5", "#E0E0E1"];     // grey (="visage2LightGray")
        }

        const colorAssignments = [
            colors,
            [colors[1]],
            [colors[1], colors[3]],
            [colors[1], colors[2], colors[3]],
            [colors[1], colors[2], colors[3], colors[4]],
            colors
        ];

        const colorsToUse = colorAssignments[arces.length];

        for (let a of arces) {
            const index = arces.indexOf(a);
            const startAngle = valueToAngle(arces, a.from);
            const endAngle = valueToAngle(arces, a.to);

            const arc = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth).startAngle(degToRad(startAngle)).endAngle(degToRad(endAngle));
            const arcShadow = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - 5).startAngle(degToRad(startAngle)).endAngle(degToRad(endAngle));
            //const arcDash = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth).startAngle(degToRad(startAngle)).endAngle(degToRad(endAngle));

            chart.append('path').attr('class', 'arc chart-color' + (index + 1)).attr('d', arc).style("fill", colorsToUse[index][0]);
            chart.append('path').attr('class', 'arc chart-color' + (index + 1)).attr('d', arcShadow).style("fill", colorsToUse[index][1]);
            //chart.append('path').attr('class', 'line chart-color' + (index + 1)).style("stroke-dasharray", ("3, 3")).attr('d', arcDash).style("stroke", "red").style("fill", "transparent");

            const startValueText = chart.append('text').attr('text-anchor', 'middle');
            const startAx = startAngle / 180 * Math.PI + (startAngle < 0 ? Math.PI * 2 : 0);
            const startX = radius * Math.sin(startAx);
            const startY = radius * -Math.cos(startAx);
            let xSpacing = 1.05;
            const ySpacing = 1;
            const isLastNumber = index === (arces.length - 1);

            if (index === 0) {
                xSpacing = 1.35;
            }

            const bottomYSpacing = 1;

            if (!this.props.variables.hasThresholdsBelowMinValue || (this.props.variables.hasThresholdsBelowMinValue && a.from >= thresholdMinValue && a.to > thresholdMinValue)) {
                if (this.props.variables.isPercentage) {
                    startValueText.text(a.from + "%").style("font-size", "10px").style("fill", "#091B3D").attr('x', startX * (xSpacing + 0.1)).attr('y', startY * ySpacing);
                }
                else {
                    startValueText.text(a.from).style("font-size", "10px").style("fill", "#091B3D").attr('x', startX * xSpacing).attr('y', startY * ySpacing);
                }
            }

            if (isLastNumber) {
                const endValueText = chart.append('text').attr('text-anchor', 'middle');
                const endAx = endAngle / 180 * Math.PI + (endAngle < 0 ? Math.PI * 2 : 0);
                const endX = radius * Math.sin(endAx);
                const endY = radius * -Math.cos(endAx);

                if (this.props.variables.isPercentage) {
                    endValueText.text(a.to + "%").style("font-size", "10px").style("fill", "#091B3D").attr('x', ((endX * 1.35) + 0.1)).attr('y', endY * ySpacing);
                }
                else {
                    endValueText.text(a.to).style("font-size", "10px").style("fill", "#091B3D").attr('x', endX * 1.35).attr('y', endY * ySpacing);
                }
            }
        }

        // const ticks = (END_ANGLE - START_ANGLE);

        // for (let i = START_ANGLE; i < END_ANGLE; i++) {
        //     const arc = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth).startAngle(degToRad(startAngle)).endAngle(degToRad(endAngle));
        // }

        const valueText = chart.append('text').attr('text-anchor', 'middle');
        if (this.props.variables.isPercentage) {
            valueText.text(this.props.data.value + "%").style("font-size", "12px").style("fill", "#475267").style("font-weight", "600").attr('y', radius / 1.5);
        }
        else {
            valueText.text(this.props.data.value).style("font-size", "12px").style("fill", "#475267").style("font-weight", "600").attr('y', radius / 1.5);
        }

        const timestampText = chart.append('text').attr('text-anchor', 'middle');
        timestampText.text(this.props.data.timestamp).style("font-size", "10px").style("fill", "#6D7588").attr('y', radius / 0.95);

        const Needle = function () {
            function Needle(len, radius1) {
                this.len = len;
                this.radius = radius1;
            }
            Needle.prototype.drawOn = function (el, perc) {
                const arc = d3.arc().outerRadius(radius - barWidth - chartInset - 1.5).innerRadius(radius - barWidth - chartInset - 1.5).startAngle(degToRad(START_ANGLE)).endAngle(degToRad(END_ANGLE));
                el.append('path').attr('class', 'line').style("stroke-dasharray", ("1, 2")).style("stroke-width", "3px").attr('d', arc).style("stroke", "#a5adbe").style("fill", "transparent");

                el.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 0).attr('r', this.radius * 2);
                //el.append('circle').startAngle(degToRad(START_ANGLE)).endAngle(degToRad(END_ANGLE)).attr('cx', 0).style("stroke-dasharray", ("1, 1")).style("stroke-width", 3).style("stroke", "red").style("fill", "transparent").attr('cy', 0).attr('r', radius - barWidth - chartInset - 1.5);
                el.append('circle').attr('class', 'needle-center-inner').attr('cx', 0).attr('cy', 0).attr('r', this.radius);
                return el.append('path').attr('class', 'needle').attr('d', this.mkCmd(perc));
            };
            Needle.prototype.animateOn = function (el, perc) {
                var self;
                self = this;
                return el.transition().delay(500).duration(1000).selectAll('.needle').tween('progress', function () {
                    return function (percentOfPercent) {
                        var progress;
                        progress = percentOfPercent * perc;
                        return d3.select(this).attr('d', self.mkCmd(progress));
                    };
                });
            };
            Needle.prototype.mkCmd = function (perc) {
                var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
                thetaRad = percToRad(perc * 0.75) - (percToRad(1) / 8);
                centerX = 0;
                centerY = 0;
                const offset = 10;
                topX = centerX - this.len * Math.cos(thetaRad);
                topY = centerY - this.len * Math.sin(thetaRad);
                leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
                leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
                rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
                rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
                return 'M ' + leftX + ' ' + leftY + ' L ' + topX + ' ' + topY + ' L ' + rightX + ' ' + rightY;
            };
            return Needle;
        }();

        const needle = new Needle(radius * 0.55, 5);

        needle.drawOn(chart, 0);
        needle.animateOn(chart, percent);
    }

    componentDidMount(): void {
        this.runD3();
    }

    public render() {
        return (
            <div id={this.state.id} className="chart-gauge" ref={this.gaugeWrapperRef}></div>
        );
    }
}

const START_ANGLE = -135;
const END_ANGLE = 135;

const valueToAngle = (arces: any[], value: number) => {
    const startAngle = START_ANGLE;
    const endAngle = END_ANGLE;
    const minValue = arces[0].from;
    const maxValue = arces[arces.length - 1].to;
    const angleRange = endAngle - startAngle;
    const valueRange = maxValue - minValue;
    const angle = startAngle + ((value - minValue) / valueRange) * angleRange;

    return Math.round(angle);
};

const valueToPercent = (arces: any[], value: number) => {
    const minValue = arces[0].from;
    const maxValue = arces[arces.length - 1].to;
    const range = maxValue - minValue;
    const displacement = value - minValue;

    let result = displacement / range;

    return result > 1 ? 1 : result;
}

export default D3GaugeChart;