import { SpintrTypes } from "src/typings";
import CircularChartWidget from "./CircularChartWidget";
import LineChartWidget from "./LineChartWidget/LineChartWidget";

const typeMapping : { [type: number] : any } = {
    [SpintrTypes.WidgetType.LineChart]: LineChartWidget
};

export * from "./BarChartWidget";
export * from "./KeyPointIndicatorWidget";
export * from "./ListWidget";
export * from "./PieChartWidget";
export * from "./GaugeWidget";
export {
    LineChartWidget,
    CircularChartWidget,
    typeMapping,
};
