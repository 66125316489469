import { Dropdown, TextField } from "@fluentui/react";
import no from "date-fns/locale/da";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import fi from "date-fns/locale/fi";
import sv from "date-fns/locale/sv";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLocalizationTag, localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, PageHeader } from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./Operatinginfo.scss";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
    enableCompany?: boolean;
}

interface IState {
    isNew: boolean;
    isLoading: boolean;
    operatinginfo: any;
    hasExtendedRights: boolean;
    saveError: string[];
    enableFieldValidation?: boolean;
}

interface IPropsCustomInput {
    history: any;
    onClick?: () => void;
    value: any;
}

class CustomInput extends React.Component<IPropsCustomInput> {
    render() {
        return (
            <div className="wrapper">
                <i onClick={this.props.onClick} aria-hidden="true" className="fa fa-calendar">
                    <Visage2Icon icon="calendar-1" />
                </i>
                <input
                    onClick={this.props.onClick}
                    onChange={this.props.onClick}
                    className="dateInput"
                    value={this.props.value}
                    type="text"
                />
            </div>
        );
    }
}

class OperatinginfoEditView extends Component<IProps, IState> {
    // private isNew = !!!this.props.match.params.wikiId;
    private isNew = false;

    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            isLoading: true,
            hasExtendedRights: this.props.isAdmin || this.props.isEditor,
            saveError: [],
            operatinginfo: {
                dateCreated: new Date(),
                dateEnd: new Date(),
                dateStart: new Date(),
                id: 0,
                instanceId: 0,
                isDeleted: false,
                targets: [],
                text: "",
                title: "",
                type: 1,
            },
        };
    }

    initPage(operatinginfo) {
        let dateStart = new Date(operatinginfo.dateStart);

        let dateCreated = new Date(operatinginfo.dateCreated);
        let publishStartDate, dateEnd;

        if (operatinginfo.dateEnd) {
            dateEnd = new Date(operatinginfo.dateEnd);
        }

        if (operatinginfo.publishStartDate) {
            publishStartDate = new Date(operatinginfo.publishStartDate);
        }

        this.setState({
            isNew: operatinginfo.isNew,
            isLoading: operatinginfo.isLoading,
            operatinginfo: {
                dateCreated: dateCreated,
                dateEnd: dateEnd,
                dateStart: dateStart,
                id: operatinginfo.id,
                instanceId: operatinginfo.instanceId,
                isDeleted: operatinginfo.isDeleted,
                publishStartDate: publishStartDate,
                targets: operatinginfo.targets,
                text: operatinginfo.text,
                title: operatinginfo.title,
                type: operatinginfo.type,
            },
            saveError: [],
        });
    }
    componentDidMount() {
        let pageId;
        if (
            window.location.href.indexOf("admin/operatinginfo/create") > -1 ||
            window.location.href.indexOf("operatinginfo/create") > -1
        ) {
            this.isNew = true;
        } else {
            this.isNew = false;
            pageId = window.location.href.split("/").reverse()[1];
        }

        if (this.isNew) {
            const newDate = new Date();
            newDate.setHours(newDate.getHours() + 2);

            this.initPage({
                isLoading: false,

                dateCreated: new Date(),
                dateEnd: newDate,
                dateStart: new Date(),
                id: 0,
                instanceId: 0,
                isDeleted: false,
                publishStartDate: "",
                targets: [],
                text: "",
                title: "",
                type: 1,
            });
        } else {
            let fetchPage = () => {
                api.get("/api/v1/operatinginfo?id=" + pageId).then((response) => {
                    this.initPage(response.data);
                });
            };

            fetchPage();
        }
    }

    componentDidUpdate() { }

    onCancelClick() {
        this.props.history.goBack();
    }

    onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            var saveErrors = [];

            if (!this.state.operatinginfo.title) {
                saveErrors.push("DuMasteFyllaIEnRubrik");
            }

            if (saveErrors.length > 0) {
                this.setState(
                    {
                        saveError: saveErrors,
                        isLoading: false
                    },
                    () => {
                        scrollToTop();
                    }
                );

                return;
            }

            api
                .post("/api/operatinginfo", this.state.operatinginfo)
                .then(() => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.history.goBack();
                    });
                })
                .catch(function (error) {
                    if (!this) {
                        return;
                    }

                    this.setState({
                        isLoading: false
                    });

                    console.log(error);
                });
        });
    }

    setLocale() {
        let tag = getLocalizationTag();

        switch (tag) {
            case "sv":
                return sv;
            case "en":
                return en;
            case "no":
                return no;
            case "fi":
                return fi;
            case "de":
                return de;
        }
    }
    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        let adminNewsEditView = false;
        if (window.location.href.indexOf("admin") > -1) {
            adminNewsEditView = true;
        }

        const operatinginfo = this.state.operatinginfo;

        return (
            <div>
                <Breadcrumbs
                    displayInstance
                    items={[
                        ...(adminNewsEditView ? [{
                            text: localize("Administration"),
                            key: 2,
                            link: "/admin",
                        }] : []),
                        {
                            text: localize("Driftinformation"),
                            key: 3,
                            link: adminNewsEditView ? "/admin/operatinginfo" : "/operatinginfo",
                        },
                        {
                            text: this.isNew ? localize("SkapaNyDriftuppdatering") : localize("RedigeraDriftuppdatering"),
                            key: 4,
                            link: window.location.pathname,
                        },
                    ]}
                />
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <div className="page-margin-bottom">
                    <PageHeader
                        title={this.isNew ? localize("SkapaNyDriftuppdatering") : localize("RedigeraDriftuppdatering")}
                        displayMandatoryText />
                    <form>
                        <FormControl>
                            <TextField
                                className="textField"
                                label={localize("Rubrik")}
                                value={operatinginfo.title}
                                onChange={(ev, val) => {
                                    this.setState((prevState) => ({
                                        operatinginfo: {
                                            ...prevState.operatinginfo,
                                            title: val,
                                        },
                                    }));
                                }}
                                maxLength={64}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Beskrivning")}
                                className="textField"
                                value={operatinginfo.text}
                                onChange={(ev, val) => {
                                    this.setState((prevState) => ({
                                        operatinginfo: {
                                            ...prevState.operatinginfo,
                                            text: val,
                                        },
                                    }));
                                }}
                                multiline
                                autoAdjustHeight
                            />
                        </FormControl>
                        <FormControl>
                            <Dropdown
                                label={localize("Status")}
                                selectedKey={this.state.operatinginfo.type}
                                onChange={(_e, v) => {
                                    this.setState((state) => ({
                                        operatinginfo: {
                                            ...state.operatinginfo,
                                            type: Number(v.key),
                                        },
                                    }));
                                }}
                                options={[
                                    { key: 1, text: localize("Pagaende") },
                                    { key: 2, text: localize("Avklarade") },
                                    { key: 3, text: localize("Planerade") },
                                ]}

                            />
                        </FormControl>
                        <FormControl>
                            <SpintrDatePicker
                                label={localize("Startar")}
                                value={operatinginfo.dateStart}
                                onChangeHandler={(date) =>
                                    this.setState((prevState) => ({
                                        operatinginfo: {
                                            ...prevState.operatinginfo,
                                            dateStart: date,
                                        },
                                    }))
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <SpintrDatePicker
                                isClearable={true}
                                label={localize("Slutar")}
                                value={operatinginfo.dateEnd}
                                onChangeHandler={(date) =>
                                    this.setState((prevState) => ({
                                        operatinginfo: {
                                            ...prevState.operatinginfo,
                                            dateEnd: date,
                                        },
                                    }))
                                }
                            />
                        </FormControl>
                        <FormSection title={localize("SynligFor")}>
                            <FormControl>
                                <FormTokenizedObjectInput
                                    showAllWhenEmpty
                                    label={localize("RiktadTillBeskrivning")}
                                    types={[
                                        SpintrTypes.UberType.Department,
                                        SpintrTypes.UberType.Office,
                                        SpintrTypes.UberType.Role,
                                        SpintrTypes.UberType.TargetGroup,
                                        ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                                    ]}
                                    items={(operatinginfo.targets || []).map((target) => {
                                        return {
                                            ...target,
                                            key: target.key ? target.key : target.id,
                                            id: target.id ? target.id : target.key
                                        }
                                    })}
                                    onChange={(targets) => {
                                        this.setState((prevState) => ({
                                            operatinginfo: {
                                                ...prevState.operatinginfo,
                                                targets: targets.map((e) => {
                                                    return {
                                                        ...e,
                                                        name: e.name,
                                                        info: e.subText,
                                                        id: e.key,
                                                        imageUrl: e.imageUrl,
                                                    };
                                                }),
                                            },
                                        }));
                                    }}
                                />
                            </FormControl>
                        </FormSection>
                        <FormControl>
                            <SpintrDatePicker
                                value={operatinginfo.publishStartDate}
                                label={localize("Publicera")}
                                onChangeHandler={(date) =>
                                    this.setState((prevState) => ({
                                        operatinginfo: {
                                            ...prevState.operatinginfo,
                                            publishStartDate: date,
                                        },
                                    }))
                                }

                            />
                        </FormControl>
                    </form>
                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize("Spara")}
                        confirmSave={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("PubliceraDetta").toLowerCase())}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        enableCompany: state.instance.get("enableCompanyLevel"),
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(OperatinginfoEditView));
