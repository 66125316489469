import moment from "moment";
import { localize } from "src/l10n";

const getStrFromOpenTime = (openTime) => {
    if (!openTime.isOpen) {
        return localize("Stangd");
    }

    return localize("OPEN") +
        " " +
        moment(openTime.startTime).format("HH:mm") +
        " - " +
        moment(openTime.endTime).format("HH:mm"); 
}

export const getOpenTimes = (openTimes: Spintr.IOpenTimeDay[]) => {
    let result = [];

    for (let openTime of openTimes) {
        for (let day of openTime.days) {
            result.push({
                ...openTime,
                dayOfWeek: day
            });
        }
    }

    result = result.sort(function (a, b) {
        if (a.dayOfWeek < b.dayOfWeek) {
            return -1;
        }
        if (a.dayOfWeek > b.dayOfWeek) {
            return 1;
        }
        return 0;
    });

    return result;
}

const getOpenTime = (openTimes) => {
    if (!openTimes || openTimes.length === 0) {
        return "";
    }

    const now = new Date();

    const dayOfWeek = now.getDay();

    for (let openTime of openTimes) {
        if (openTime.days.indexOf(dayOfWeek) > -1) {
            return getStrFromOpenTime(openTime);
        }
    }

    return localize("Stangd");
}

export default getOpenTime;