import {
    ColorPicker, getColorFromString, IColorPickerStyles
} from "@fluentui/react/lib/index";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as React from "react";
import { memo } from "react";
import { localize } from "src/l10n";
import { UnstyledButton } from "src/ui";
import { Style } from "src/ui/helpers";
import "./ColorPicker.scss";

interface Props {
    color: any;
    onChangeColor: any;
    small?: boolean;
}

export default memo(function ColorPicker1(props: Props) {
    const white = getColorFromString("#ffffff")!;
    const { color, onChangeColor } = props;

    const classNames = mergeStyleSets({
        wrapper: { display: "flex" },
        column2: { marginLeft: 10 },
    });

    const colorPickerStyles: Partial<IColorPickerStyles> = {
        panel: { padding: Style.getSpacing(2) },
        root: {
            maxWidth: 280,
            minWidth: 280,
            maxHeight: 500,
        },
        colorRectangle: {
            width: 200,
            height: 200,
            minWidth: "none",
            minHeight: "none"
        },
        flexContainer: {
            width: 200,
        },
        table: {
            width: 200
        },
        colorSquare: {
            width: 20,
            height: 20,
            borderRadius: 100,
            border: 0
        }
    };

    if (props.small) {
        let colors = [
            "#eaeaea",
            "#ff4444",
            "#ffbb33",
            "#00C851",
            "#33b5e5"
        ];

        if (!!props.color &&
            !colors.find(c => c.toLowerCase() === props.color.toLowerCase())) {
            colors.push(props.color);
        }

        return (
            <div className="ColorPicker-small">
                {
                    colors.map((color, index) => {
                        const isActive = props.color.toLowerCase() === color.toLowerCase();

                        let classNames = ["ColorPicker-small-color"];

                        if (isActive) {
                            classNames.push("ColorPicker-small-color-active");
                        }

                        return (
                            <UnstyledButton
                            ariaLabel={localize("Fargval")}
                                key={index}
                                className={classNames.join(" ")}
                                onClick={() => {
                                    props.onChangeColor(null, {
                                        str: color
                                    });
                                }}>
                                <div style={{
                                    backgroundColor: color
                                }}></div>
                            </UnstyledButton>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className={classNames.wrapper}>
            <ColorPicker
                color={color}
                onChange={onChangeColor}
                alphaSliderHidden={true}
                showPreview={true}
                styles={colorPickerStyles}
                // The ColorPicker provides default English strings for visible text.
                // If your app is localized, you MUST provide the `strings` prop with localized strings.
                strings={{
                    // By default, the sliders will use the text field labels as their aria labels.
                    // If you'd like to provide more detailed instructions, you can use these props.
                    alphaAriaLabel:
                        "Alpha slider: Use left and right arrow keys to change value, hold shift for a larger jump",
                    hueAriaLabel:
                        "Hue slider: Use left and right arrow keys to change value, hold shift for a larger jump",
                }}
            />
        </div>
    );
});
