import { Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";
import "./RightColumnTags.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    hideHeader?: boolean;
}

interface IState {
    isLoading: boolean;
    tags?: any[];
}

class RightColumnTags extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    public componentDidMount() {
        api.get("/api/tags/zoltor").then((response) => {
            this.setState({
                isLoading: false,
                tags: response.data,
            });
        }).catch(() => {/* ignored */});
    }

    public render(): ReactNode {
        if (this.state.isLoading || !this.state.tags || this.state.tags.length === 0) {
            return null;
        }

        return (
            <div className="RightColumnTags">
                {!this.props.hideHeader && (
                    <div className="widget-header">
                        <Label weight="semi-bold" size="h6">
                            {localize("Taggar")}
                        </Label>
                    </div>
                )}
                <div className="RightColumnTags-content">
                    {this.state.tags.map((item, index) => {
                        return (
                            <div className="eachTagContainer" key={index}>
                                <div className="eachTag">
                                    <div className="text">
                                        <Link className="RightColumnTags-tag" to={`/search?q=%23${item.text}`}>
                                            {item.text}
                                        </Link>
                                    </div>
                                    <div className="icon">
                                        <Link className="RightColumnTags-tag" to={`/search?q=%23${item.text}`}>
                                            <Visage2Icon title={item.text} icon="arrow-right-3" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="RightColumnTags-footer">
                        <Label size="body-2">
                            <Link className="RightColumnTags-footer-text" to={`/tags`}>
                                {localize("VisaAlla") + " " + localize("Taggar").toLowerCase()}
                            </Link>
                        </Label>
                    </div>
                </div>
            </div>
        );
    }
}

export default RightColumnTags;
