import React, { FunctionComponent } from "react";
import { TeaserBoxLoader } from "src/teaser-box";
import { StandardErrorBoundary } from "src/ui";

const DataWidgetsWrapper: FunctionComponent = () => (
    <div className="DataWidgetsWrapper">
        <StandardErrorBoundary>
            <TeaserBoxLoader
                dataWidgetsOnlyIfMoreThanOneOfEach={true}
                blogsOnly={false}
            />
        </StandardErrorBoundary>
    </div>
);

export default DataWidgetsWrapper;
