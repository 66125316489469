import React, { memo } from "react";
import { Icon, UnstyledButton } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props {
    onClick: () => void;
}

export default memo(function PlusSignButton(props: Props) {
    const { onClick } = props;

    return (
        <UnstyledButton onClick={onClick} style={{ display: "flex" }}>
            <div
                style={{
                    backgroundColor: Style.getHexFromSpintrColor("light-grey"),
                    height: "150px",
                    textAlign: "center",
                    width: "100%",
                    display: "inline-block",
                    cursor: "pointer",
                    borderRadius:"12px"
                }}
            >
                <div
                    style={{
                        display: "inline-block",
                        position: "relative",
                        transform: "translateY(-50%)",
                        msTransform: "translateY(-50%)",
                        top: "50%",
                    }}
                >
                    <Visage2Icon icon="gallery-add" color="light-blue" />
                </div>
            </div>
        </UnstyledButton>
    );
});
