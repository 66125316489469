import Immutable, { List, Map } from "immutable";

import { localize } from "../l10n";
import {
    FETCH_ROOT_FOLDERS_FULFILLED,
    FETCH_ROOT_FOLDERS_PENDING,
    FETCH_ROOT_FOLDERS_REJECTED,
    FIND_FOLDER_FULFILLED,
    FIND_FOLDER_PENDING,
    FIND_FOLDER_REJECTED,
    SET_CURRENT_FOLDER_ID,
    SET_HIDE_SHARE,
    SET_ENABLE_ONLINE_EDIT,
    SET_COPIED_FOLDER,
} from "./folder-action-types";

class FolderReducerState extends Immutable.Record({
    error: Immutable.Map({ exists: false }),
    isLoadingRoots: false,
    items: Immutable.List(),
    roots: Immutable.List(),
    currentFolderId: 0,
    hideShare: true,
    copiedFolder: null,
}) {}

const initialState = new FolderReducerState({});

const findFolderPending = (state: FolderReducerState, action) => {
    const { id, source } = action.meta;
    const index = state.get("items").findIndex((i) => i.get("id") === id && i.get("source") === source);

    return index === -1
        ? state.mergeIn(["items"], List([Map({ id, source, isLoading: true })]))
        : state.mergeIn(["items", index], Map({ isLoading: true }));
};

const findFolderFulfilled = (state: FolderReducerState, action) => {
    const { id, source } = action.meta;
    const index = state.get("items").findIndex((i) => i.get("id") === id && i.get("source") === source);

    return state.mergeIn(
        ["items", index],
        Map({
            ...action.payload.data,

            id: action.payload.data.id.toString(),
            isLoading: false,
        })
    );
};

const folderReducer = (state: FolderReducerState = initialState, action) => {
    switch (action.type) {
        case FETCH_ROOT_FOLDERS_PENDING:
            return state.merge({
                error: initialState.get("error"),
                isLoadingRoots: true,
            });

        case FETCH_ROOT_FOLDERS_REJECTED:
            return state.merge({
                error: Immutable.Map({ exists: true }),
                isLoadingRoots: false,
            });

        case FETCH_ROOT_FOLDERS_FULFILLED:
            const items = action.payload.data.map((i) =>
                Map({
                    ...i,

                    name: i.name || localize("Filer"),
                })
            );

            return state
                .merge({
                    error: initialState.get("error"),
                    isLoadingRoots: false,
                })
                .mergeIn(["roots"], List(items));

        case FIND_FOLDER_PENDING:
            return findFolderPending(state, action);

        case FIND_FOLDER_REJECTED:
            return state.merge({
                error: Map({ exists: true }),
            });

        case FIND_FOLDER_FULFILLED:
            return findFolderFulfilled(state, action);

        case SET_CURRENT_FOLDER_ID:
            return state.merge({
                currentFolderId: action.payload,
            });

        case SET_HIDE_SHARE:
            return state.merge({
                hideShare: action.payload,
            });

        case SET_ENABLE_ONLINE_EDIT:
            const { id, enable, source } = action.meta;
            const index = state.get("items").findIndex((i) => i.get("id") === id && i.get("source") === source);

            return state.mergeIn(
                ["items", index],
                Map({
                    ...state.get("items")[index],

                    enableOnlineEdit: enable,
                })
            );

        case SET_COPIED_FOLDER:
            return state.merge({
                copiedFolder: action.payload,
            });

        default:
            return state;
    }
};

export default folderReducer;
