import React, { useEffect, useRef, useState } from "react";
import { Label } from "src/ui";

interface IProps {
    value?: string;
    onChange: any;
    placeholder: string;
    big?: boolean;
}

const SocialPostTypeContentInput = (props: IProps) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    
    useEffect(() => {
        if (ref &&
            ref.current) {
            setWidth(ref.current.clientWidth);
        }
    }, [props.value]);

    return (
        <div className={"SocialPostTypeContentInput" + (props.big ? " SocialPostTypeContentInput-big" : "")}>
            <span className="hidden-value" ref={ref}>
                <Label>
                    {props.value || props.placeholder}
                </Label>
            </span>
            <input
                style={{
                    width
                }}
                type="text"
                maxLength={30}
                onChange={(e) => {
                    props.onChange(e.target.value);
                }}
                defaultValue={props.value}
                //value={title}
                placeholder={props.placeholder} />
        </div>
    )
}

export default SocialPostTypeContentInput;