import React, { Component } from "react";
import { Label, SpintrUser } from "src/ui/components";
import SpintrHelpText from "src/ui/components/SpintrHelpText";
import "./CenteredPageHeader.scss";

interface IProps {
    title: string;
    description?: string;
    helpTextId?: number;
    imageUrl?: string;
    icon?: string;
    displayIcon?: boolean;
    isArticleView?: boolean;
}

class CenteredPageHeader extends Component<IProps> {
    render() {
        return (
            <div className="CenteredPageHeader">
                {this.props.displayIcon && (
                    <div className="CenteredPageHeader-Icon">
                        <SpintrUser
                            name={this.props.title}
                            imageUrl={this.props.imageUrl}
                            size={80}
                            hideText={true}
                            personalName={false}
                        />
                    </div>
                )}
                <Label
                    as="h1"
                    size={this.props.isArticleView ? "h3" : "h1"}
                    weight="medium"
                >
                    {this.props.title}
                </Label>
                <Label as="div" size={this.props.isArticleView ? "body-2" : "body-1"}>
                    {this.props.helpTextId && <SpintrHelpText id={1} />}
                    {this.props.description && <span>{this.props.description}</span>}
                </Label>
            </div>
        );
    }
}

export default CenteredPageHeader;
