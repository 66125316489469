import React, { useMemo } from 'react';
import "./PinnedMessage.scss";
import { ActionMenu, Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import api from 'src/spintr/SpintrApi';
import { unpinMessageRealtime } from 'src/chat/redux';
import { useDispatch } from 'react-redux';
import { stripInteractiveText } from 'src/utils';

interface IProps {
    message: Spintr.IChatMessage;
    onClick: () => void;
}

const PinnedMessage = (props: IProps) => {
    const dispatch = useDispatch();

    const content = useMemo(() => {
        if (props.message.isMemo) {
            if (props.message.memoTitle) {
                return {
                    text: `${localize("MEMO")}: ${props.message.memoTitle}`,
                    isStatusText: false
                };
            }

            if (props.message.text) {
                return {
                    text: `${localize("MEMO")}: ${stripInteractiveText(props.message.text)}`,
                    isStatusText: false
                };
            }

            return {
                text: localize("MEMO_FROM_X").replace("{0}", props.message.user.name),
                isStatusText: true
            };
        }

        if (props.message.text) {
            return {
                text: stripInteractiveText(props.message.text),
                isStatusText: false
            }
        }

        return {
            text: localize("MESSAGE_FROM_X").replace("{0}", props.message.user.name),
            isStatusText: true
        }
    }, []);

    return (
        <UnstyledButton className="PinnedMessage" onClick={props.onClick}>
            <Visage2Icon icon="pin" color="visageGray2" type="custom" size="small" />
            <Label className="left-text" weight="medium">{localize("PINNED_ITEM") + ":"}</Label>
            <div className="line" />
            <Label className="preview-text" italic={content.isStatusText}>
                {content.text}
            </Label>
            <div className="spacer" />
            <ActionMenu
                categories={[{
                    items: [{
                        text: localize("TaBort"),
                        onClick: () => {
                            api.delete("/api/v1/messages/" + props.message.id + "/pin").then(() => {}).catch(() => {});
                            dispatch(unpinMessageRealtime(props.message));
                        },
                    }],
                }]}
            />
        </UnstyledButton>
    )
}

export default PinnedMessage;