import { INotificationsQueryParams } from "./types";

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'

export const FETCH_NOTIFICATIONS_PENDING = 'FETCH_NOTIFICATIONS_PENDING';
export interface IFetchNotificationsPendingAction {
    type: typeof FETCH_NOTIFICATIONS_PENDING;
}

export const FETCH_NOTIFICATIONS_REJECTED = 'FETCH_NOTIFICATIONS_REJECTED';
export interface IFetchNotificationsRejectedAction {
    type: typeof FETCH_NOTIFICATIONS_REJECTED;
}

export const FETCH_NOTIFICATIONS_FULFILLED = 'FETCH_NOTIFICATIONS_FULFILLED';
export interface IFetchNotificationsFulfilledAction extends Spintr.IAsyncAction<Spintr.INotification[]> {
    meta: INotificationsQueryParams;
    type: typeof FETCH_NOTIFICATIONS_FULFILLED;
}

export type FetchNotificationAction = 
    IFetchNotificationsPendingAction |
    IFetchNotificationsRejectedAction |
    IFetchNotificationsFulfilledAction;


export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ'

export const MARK_NOTIFICATIONS_AS_READ_PENDING = 'MARK_NOTIFICATIONS_AS_READ_PENDING'
export interface IMarkNotificationsAsReadPendingAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_NOTIFICATIONS_AS_READ_PENDING;
}

export const MARK_NOTIFICATIONS_AS_READ_REJECTED = 'MARK_NOTIFICATIONS_AS_READ_REJECTED'
export interface IMarkNotificationsAsReadRejectedAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_NOTIFICATION_AS_READ_REJECTED;
}

export const MARK_NOTIFICATIONS_AS_READ_FULFILLED = 'MARK_NOTIFICATIONS_AS_READ_FULFILLED'
export interface IMarkNotificationsAsReadFulfilledAction {
    type: typeof MARK_NOTIFICATIONS_AS_READ_FULFILLED;
}


export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ'

export const MARK_NOTIFICATION_AS_READ_PENDING = 'MARK_NOTIFICATION_AS_READ_PENDING'
export interface IMarkNotificationAsReadPendingAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_NOTIFICATION_AS_READ_PENDING;
}

export const MARK_NOTIFICATION_AS_READ_REJECTED = 'MARK_NOTIFICATION_AS_READ_REJECTED'
export interface IMarkNotificationAsReadRejectedAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_NOTIFICATION_AS_READ_REJECTED;
}

export const MARK_NOTIFICATION_AS_READ_FULFILLED = 'MARK_NOTIFICATION_AS_READ_FULFILLED'
export interface IMarkNotificationAsReadFulfilledAction {
    type: typeof MARK_NOTIFICATION_AS_READ_FULFILLED;
}


export const MARK_NOTIFICATION_AS_UNREAD = 'MARK_NOTIFICATION_AS_UNREAD'

export const MARK_NOTIFICATION_AS_UNREAD_PENDING = 'MARK_NOTIFICATION_AS_UNREAD_PENDING'
export interface IMarkNotificationAsUnreadPendingAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_NOTIFICATION_AS_UNREAD_PENDING;
}

export const MARK_NOTIFICATION_AS_UNREAD_REJECTED = 'MARK_NOTIFICATION_AS_UNREAD_REJECTED'
export interface IMarkNotificationAsUnreadRejectedAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_NOTIFICATION_AS_UNREAD_REJECTED;
}

export const MARK_NOTIFICATION_AS_UNREAD_FULFILLED = 'MARK_NOTIFICATION_AS_UNREAD_FULFILLED'
export interface IMarkNotificationAsUnreadFulfilledAction {
    type: typeof MARK_NOTIFICATION_AS_UNREAD_FULFILLED;
}


export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ'

export const MARK_ALL_NOTIFICATIONS_AS_READ_PENDING = 'MARK_ALL_NOTIFICATIONS_AS_READ_PENDING'
export interface IMarkAllNotificationsAsReadPendingAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_ALL_NOTIFICATIONS_AS_READ_PENDING;
}

export const MARK_ALL_NOTIFICATIONS_AS_READ_REJECTED = 'MARK_ALL_NOTIFICATIONS_AS_READ_REJECTED'
export interface IMarkAllNotificationsAsReadRejectedAction extends Spintr.IAsyncAction<any> {
    type: typeof MARK_ALL_NOTIFICATIONS_AS_READ_REJECTED;
}

export const MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED = 'MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED'
export interface IMarkAllNotificationsAsReadFulfilledAction {
    type: typeof MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED;
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export interface IAddNotificationAction {
    meta: any;
    type: typeof ADD_NOTIFICATION;
}

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export interface  IRemoveNotificationAction {
    meta: number,
    type: typeof REMOVE_NOTIFICATION,
}

export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';

export interface ISetUnreadCountAction {
    meta: number,
    type: typeof SET_UNREAD_COUNT
}

export type MarkNotificationAsReadAction = 
    IMarkNotificationAsReadPendingAction |
    IMarkNotificationAsReadRejectedAction | 
    IMarkNotificationAsReadFulfilledAction;

export type MarkNotificationsAsReadAction =
    IMarkNotificationsAsReadPendingAction |
    IMarkNotificationsAsReadRejectedAction |
    IMarkNotificationsAsReadFulfilledAction;

export type MarkAllNotificationsAsReadAction =
    IMarkAllNotificationsAsReadPendingAction |
    IMarkAllNotificationsAsReadRejectedAction |
    IMarkAllNotificationsAsReadFulfilledAction;

export type MarkNotificationAsUnreadAction = 
    IMarkNotificationAsUnreadPendingAction |
    IMarkNotificationAsUnreadRejectedAction |
    IMarkNotificationAsUnreadFulfilledAction;

export type NotificationAction =
    MarkNotificationAsUnreadAction |
    FetchNotificationAction | 
    MarkNotificationAsReadAction |
    MarkNotificationsAsReadAction |
    MarkAllNotificationsAsReadAction |
    IAddNotificationAction |
    IRemoveNotificationAction |
    ISetUnreadCountAction;
