import { TooltipDelay } from "@fluentui/react";
import { TooltipHost } from "@fluentui/react/lib/components/Tooltip/TooltipHost";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "src/ui";
import "./BarChartWidget.scss";

interface IProps {
    data: any;
    config?: any;
    isZoomed?: boolean;
    variables: { [key: string]: string };
}

const BarChartWidget = (props: IProps) => {
    const ref = useRef(null);
    const [displayLabels, setDisplayLabels] = useState(true);
    const [displayAltTexts, setDisplayAltTexts] = useState(false);
    const [isAltTextLabelPlacementMiddle, setIsAltTextLabelPlacementMiddle] = useState(false);
    const [altLabel, setAltLabel] = useState<string>();

    const getText = (item) => {
        return item.name;
    }

    const getTitle = (item) => {
        return item.title || item.name;
    }

    const getAltText = (item) => {      
        if (item.altText) {
            return item.altText;
        } 
    }

    const checkIsAltTextLabelPlacementMiddle = () => {
        let altTextCount = 0;
            for (let item of props.data) {
                if (item.altText){
                    let altTextWithoutSpaces = item.altText.trim();

                     if (altTextWithoutSpaces.length > 0){
                        setAltLabel(item.altText);
                        altTextCount++;
                     }
                }
            }

            if (altTextCount === 1){
                setIsAltTextLabelPlacementMiddle(true);
            } else {
                setIsAltTextLabelPlacementMiddle(false);
            }
    }

    useEffect(() => {
        if (ref.current) {
            const totalWidth = ref.current.offsetWidth;
            const barWidth = totalWidth / props.data.length;

            let setDisplayLabelsToFalse = false;
            let setDisplayAltTextsToTrue = false;

            for (let item of props.data) {
                const text = getText(item);

                if (text.length * 5 > barWidth) {
                    setDisplayLabelsToFalse = true;
                }

                const altText = getAltText(item);

                if(altText && altText.length > 0){
                    setDisplayAltTextsToTrue = true;
                }
            }

            if (setDisplayLabelsToFalse) {
                setDisplayLabels(false);
            }

            if (setDisplayAltTextsToTrue) {
                setDisplayAltTexts(true);
            }

            checkIsAltTextLabelPlacementMiddle();
        }
    }, []);

    const colors = [
        "#98c3ff",
        "#ffdea5",
        "#ffd7d9",
        "#4b9f47",
    ];

    let maxValue = 0;

    for (let item of props.data) {
        if (item.value > maxValue) {
            maxValue = item.value;
        }
    }

    let minValue = maxValue;

    for (let item of props.data) {
        if (item.value < minValue) {
            minValue = item.value;
        }
    }   

    let zoomedStartValue = minValue * 0.9;

    const barMaxWidthPercentString = Math.floor(100 / props.data.length) + "%";

    return (
        <>
        <div className="BarChartWidget" ref={ref}>
            {props.data.map((item: any, index: number) => {
                
                let percent = 0;
                
                if(props.variables.isZoomed) {
                    percent = Math.round((((item.value - zoomedStartValue) || 0) / (maxValue - zoomedStartValue)) * 100) || 0;
                }
                else {
                    percent = Math.round(((item.value || 0) / maxValue) * 100) || 0;
                }

                const percentString = percent + "%";

                return (
                    <div key={index} className="BarChartWidget-group" style={{
                        maxWidth: barMaxWidthPercentString
                    }}>
                        {!!props.config && props.config.showLastBarLabel && (
                            <Label className="BarChartWidget-label">
                                {index === (props.data.length - 1) ? item.value : " "}
                            </Label>
                        )}
                        <div className="BarChartWidget-wrapper" style={{
                            height: percentString
                        }}>
                            <TooltipHost
                                delay={TooltipDelay.zero}
                                content={getTitle(item) + ": " + (item.value || 0)}
                                className="marketplace-tooltip"
                                calloutProps={{ beakWidth: 8 }}>
                                <div className="BarChartWidget-bar" style={{
                                    backgroundColor: colors[index % colors.length]
                                }} />
                            </TooltipHost>
                        </div>
                        {displayLabels && (
                            <>
                                <Label className="BarChartWidget-label">
                                    {getText(item)}
                                </Label>

                                {!isAltTextLabelPlacementMiddle && displayAltTexts && (
                                    <Label className="BarChartWidget-label altText">
                                        {getAltText(item) ?? ""}
                                    </Label>
                                )}
                            </>
                        )}
                    </div>
                )
            })}
        </div>
        {isAltTextLabelPlacementMiddle && (
            <Label className="BarChartWidget-label altText altTextMiddle">
                {altLabel ?? ""}
            </Label>
        )}
        </>
    );
}

export default BarChartWidget;