import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";

export function contentStatusToText(status: Spintr.ContentStatus): string {
    switch (status) {
        case SpintrTypes.ContentStatus.Deleted:
            return localize("Borttagen");
            
        case SpintrTypes.ContentStatus.Draft:
            return localize("Utkast");

        case SpintrTypes.ContentStatus.Expired:
            return localize("Utganget");
            
        case SpintrTypes.ContentStatus.Published:
            return localize("Publicerad");
    }
}