import React, { Component, ReactNode } from "react";
import { Label } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./ActionMenuItem.scss";

interface IActionMenuItemProps {
    item: Spintr.IActionMenuItem;
}

class ActionMenuItem extends Component<IActionMenuItemProps> {
    constructor(props : IActionMenuItemProps) {
        super(props);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    public onItemClicked() : void {
        if (typeof this.props.item.onClick !== "function") {
            return;
        }

        this.props.item.onClick();
    }

    public render() : ReactNode {
        const item = this.props.item;

        return (
            <div 
                className="ActionMenuItem"
                onClick={this.onItemClicked}
                role="button">
                {item.icon && (
                    <div className="icon">
                        <Visage2Icon icon={item.icon} />
                    </div>
                )}
                <div 
                    className="text">
                    <Label
                        as="span"
                        color="black"
                        size="body-2"
                        weight="regular">
                        {item.text}
                    </Label>
                </div>
            </div>
        );
    }
}

export default ActionMenuItem;