import { Modal } from "@fluentui/react";
import React, { FunctionComponent } from "react";

import { localize } from "src/l10n";
import PopupHeader from "src/ui/components/PopupHeader";

import { LibraryConfigurationClickHandler, WidgetLibrary } from "../WidgetLibrary";

import "./WidgetSelectionPopup.scss";

interface IProps {
    isShowing: boolean;
    onDismiss: () => void;
    onSelected: LibraryConfigurationClickHandler;
}

const WidgetSelectionPopup: FunctionComponent<IProps> = (props) => {
    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            containerClassName="calendar-event-popup WidgetSelectionPopup-container"
            isBlocking={false}
            isOpen={props.isShowing}
            onDismiss={props.onDismiss}
        >
            <PopupHeader
                text={localize("MARKETPLACE_SELECT_WIDGET")}
                onClose={props.onDismiss} />
            <div className="WidgetSelectionPopup popup-inner">
                <WidgetLibrary
                    onConfigurationClicked={props.onSelected}
                />
            </div>
        </Modal>
    );
};

export default WidgetSelectionPopup;
