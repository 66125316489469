import React, { ChangeEvent, Component, ReactNode } from "react";
import ContentButton from "src/ui/components/Buttons/ContentButton/ContentButton";
import ImageCore from "src/ui/components/ImageCore";
import Label from "src/ui/components/Label/Label";
import "./FileUpload.scss";

export type FilesSelectedHandler = (files : File[]) => void;

/**
 * Properties for a FileUpload component.
 */
export interface IFileUploadProps {
    /**
     * Sets which file typs are allowed. E.g.: "image/*"
     */
    accept: string;

    /**
     * Id for the component.
     */
    id?: string;

    /**
     * Allow multiple files to be selected.
     */
    multiple?: boolean;

    /**
     * Function called when files have been selected.
     */
    onChange?: FilesSelectedHandler;

    /**
     * Sets the button text.
     */
    text: string;

    /**
     * Sets the size (in pixels) of the thumbnails.
     */
    thumbnailSize?: number;
}

interface IFileUploadItem {
    file: File;
    dataUrl?: string | ArrayBuffer;
}

export interface IFileUploadState {
    files: IFileUploadItem[];
    isLoading: boolean;
}

/**
 * A component for selecting files from the users' disks.
 */
class FileUpload extends Component<IFileUploadProps, IFileUploadState> {
    /**
     * Default properties for 
     */
    public static defaultProps : IFileUploadProps = {
        accept: "*/*",
        id: "",
        multiple: false,
        text: "",
        thumbnailSize: 32,
    }

    constructor(props : IFileUploadProps) {
        super(props);

        this.state = {
            files: [],
            isLoading: false,
        };

        this.onFileSelected = this.onFileSelected.bind(this);
        this.renderFile = this.renderFile.bind(this);
    }

    public getFiles() : File[] {
        return this.state.files.map(item => item.file);
    }

    public onFileSelected(event : ChangeEvent<HTMLInputElement>) : void {
        const { files } = event.target;
        if (!files || files.length === 0) {
            this.setState({ files: []});
            return;
        }

        const items = Array.prototype.slice.call(files, 0, files.length)
            .map((file : File) : Promise<IFileUploadItem> => {
                return new Promise<IFileUploadItem>((resolve) => {
                    if (file.type.indexOf("image/") !== 0) {
                        resolve({ file });
                        return;
                    }

                    const reader = new FileReader();

                    reader.addEventListener("load", () => {
                        const result = reader.result;

                        resolve({ 
                            dataUrl: result,
                            file,
                        });
                    });

                    reader.readAsDataURL(file);
                });
            });
        
        Promise.all<IFileUploadItem>(items).then(
            i => this.setState(
                { files: i }, 
                () => this.props.onChange && this.props.onChange(
                    i.map(x => x.file)
                )
            )
        );
    }

    public render() : ReactNode {
        return (
            <div className="FileUpload">
                <ContentButton className="button">
                    <Label className="button-text">{this.props.text}</Label>
                    <input 
                        accept={this.props.accept}
                        className="file-selector"
                        id={this.props.id}
                        multiple={this.props.multiple}
                        onChange={this.onFileSelected}
                        type="file" 
                    />
                </ContentButton>
                {this.state.files&&(
                    <ul className="file-list">
                        {this.state.files.map(this.renderFile)}
                    </ul>
                )}
            </div>
        );
    }

    public renderFile(item : IFileUploadItem, index : number) : ReactNode {
        const key = `${this.props.id}.Item${index}`;

        const src = item.dataUrl as string;

        return (
            <li className="file-entry" key={key}>
                {src && (typeof(src) === "string") && (
                    <ImageCore  
                        alt={item.file.name}
                        height={this.props.thumbnailSize}
                        src={src}
                        width={this.props.thumbnailSize}
                    />
                )}
                <div className="text">
                    <Label>
                        {item.file.name}
                    </Label>
                </div>
            </li>
        );
    }
}

export default FileUpload;