import React, { CSSProperties, ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { localize } from "src/l10n";
import { TimelineProps, TimelineState, TimelineStateProps } from "./Timeline.types";
import { TimelineHeader } from "../TimelineHeader";
import { TimelineGroups } from "../TimelineGroups";
import { ColumnDuration, DisplayedItemsChangeHandler, PlannerBarGroup, PlannerTimelineCategory, PlannerTimelineGroup, PlannerTimelineItem, RailClickHandler, UpdateCategoryHandler } from "../types";
import { assignLayersToItems, generateColumnDurations, getTimelineDuration } from "../utils";
import { TimelineOverlay } from "../TimelineOverlay";
import { useDispatch, useSelector } from "react-redux";
import { plannerItemUpdated, queryPlannerItems } from "src/redux";
import { SpintrTypes } from "src/typings";
import { updatePlannerCategoryAsync, updatePlannerItemAsync } from "src/api/plannerApi";
import { Conditional } from "src/components/Conditional";
import { UnstyledButton } from "src/ui";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const defaultAxisWidth = 257;
const responsiveAxisWidth = 140;
const dayCellWidth = 96;
const regularCellWidth = 255;

function Timeline({
    hasWriteAccess,
    onBarClick,
    onCreateClick,
    timelineMode,
    searchText,
}: TimelineProps): ReactElement {
    const dispatch = useDispatch();
    const stateProps = useSelector<Spintr.AppState, TimelineStateProps>(
        (appState) => ({
            isSmallViewMode: appState.ui.isSmallViewMode,
            itemLookup: appState.planner.itemsById,
            queryItemIds: appState.planner.queryItemIds,
            user: appState.profile.active,
            appMode: appState.ui.appMode,
        }),
        (left, right) => {
            if (left.user.id !== right.user.id) {
                return false;
            }

            if (left.queryItemIds !== right.queryItemIds) {
                return false;
            }

            if (left.itemLookup !== right.itemLookup) {
                return false;
            }

            return true;
        }
    );

    const [state, setState] = useState<TimelineState>(() => {
        const now = new Date();
        now.setHours(12, 0, 0, 0);

        return {
            axisWidth: stateProps.appMode ? 0 : stateProps.isSmallViewMode ? responsiveAxisWidth : defaultAxisWidth,
            todayMs: now.getTime(),
        };
    });

    const [data, setData] = useState<PlannerTimelineGroup[]>([]);

    useEffect(() => {
        const { itemLookup } = stateProps;

        const text = (searchText || "").toLowerCase();

        var items = Object.values(itemLookup)
            .filter((item) => item.contentStatus === SpintrTypes.ContentStatus.Published)
            .filter((item) =>
                text.length === 0 ||
                item.name.toLowerCase().includes(text) ||
                (item?.category?.name && item.category.name.toLowerCase().includes(text))
            );

        setData((previousCategories) => [{
            categories: items
                .map((item) => item.category!)
                .filter((category) => !!category)
                .reduce<Spintr.PlannerItemCategory[]>((acc, category) => {
                    if (acc.find((c) => c.id === category.id)) {
                        return acc;
                    }

                    return [...acc, category];
                }, [])
                .map<PlannerTimelineCategory>((category) => {
                    const categoryItems = items.filter((item) => item.category?.id === category.id);

                    return {
                        key: category.id,
                        name: category.name,
                        contentStatus: category.contentStatus,
                        start: categoryItems.reduce(
                            (min, item) => min < item.startsAt ? min : item.startsAt,
                            new Date(Number.MAX_SAFE_INTEGER)
                        ),
                        end: categoryItems.reduce(
                            (max, item) => max > item.endsAt ? max : item.endsAt,
                            new Date(Number.MIN_SAFE_INTEGER)
                        ),
                        items: assignLayersToItems(categoryItems.map((item) => ({
                            key: item.id,
                            name: item.name,
                            start: item.allDayEvent
                                ? new Date(
                                    item.startsAt.getFullYear(), 
                                    item.startsAt.getMonth(),
                                    item.startsAt.getDate(), 0, 0, 0, 0)
                                : item.startsAt,
                            end: item.allDayEvent
                                ? new Date(
                                    item.endsAt.getFullYear(),
                                    item.endsAt.getMonth(),
                                    item.endsAt.getDate(), 23, 59, 59, 999)
                                : item.endsAt,
                            color: item.color,
                            canEdit: hasWriteAccess,
                        }))).map((barGroup) => ({
                            ...barGroup,
                            expanded: barGroup.layers > 2 && previousCategories.some(
                                (group) => group.categories.some(
                                    (category) => category.items.some((prevBarGroup) => prevBarGroup.key === barGroup.key && prevBarGroup.expanded)
                                ),
                            ),
                        })),
                    };
                })
                .filter((category) => category.items.length > 0)
                .sort((a, b) => a.start.getTime() - b.start.getTime()),
            expanded: true,
            itemsDisplayed: items.length,
            key: "all",
            name: localize("Allt"),
        }]);
    }, [hasWriteAccess, searchText, stateProps.itemLookup, stateProps.user, setData]);

    const onGroupClicked = useCallback(
        (group: PlannerTimelineGroup) => setData(
            (previousData) => previousData.map((g) => g.key === group.key
                ? { ...g, expanded: !g.expanded }
                : g,
            ),
        ),
        [setData],
    );

    const onBarGroupClicked = useCallback((barGroup: PlannerBarGroup) => {
        setData((previousData) => previousData.map((group) => ({
            ...group,
            categories: group.categories.map((category) => ({
                ...category,
                items: category.items.map((item) => ({
                    ...item,
                    expanded: item.key === barGroup.key && !item.expanded,
                })),
            })),
        })));
    }, []);

    const onItemDurationChange = useCallback(
        async (item: PlannerTimelineItem, startTime: number, endTime: number) => {
            const id = typeof item.key === "number" ? item.key : parseInt(item.key, 10);
            const plannerItem = stateProps.itemLookup[id];
            if (!plannerItem) {
                return;
            }

            const prevStart = plannerItem.startsAt;
            const prevEnd = plannerItem.endsAt;

            dispatch(plannerItemUpdated({
                ...plannerItem,
                startsAt: new Date(startTime),
                endsAt: new Date(endTime),
            }));

            try {
                const updatedItem = await updatePlannerItemAsync(plannerItem.id, {
                    ...plannerItem,
                    startsAt: new Date(startTime),
                    endsAt: new Date(endTime),
                    approvedBy: plannerItem.approvedBy?.id,
                    assignees: (plannerItem.assignees || []).map(
                        (item) => item.id,
                    ),
                    targetedTo: (plannerItem.targetedTo || []).map(
                        (item) => item.id,
                    ),
                    files: undefined,
                    plannerOwner: plannerItem.plannerOwner?.id,
                    participants: plannerItem.itemType === SpintrTypes.PlannerItemType.Event
                        ? (plannerItem.participants || []).map((p) => p.user?.id)
                        : undefined,
                });

                dispatch(plannerItemUpdated({
                    ...updatedItem,
                    startsAt: new Date(updatedItem.startsAt),
                    endsAt: new Date(updatedItem.endsAt),
                }));
            } catch (reason) {
                console.error("Failed to update planner item", reason);

                dispatch(plannerItemUpdated({
                    ...plannerItem,
                    startsAt: prevStart,
                    endsAt: prevEnd,
                }));
            }
        },
        [dispatch, stateProps.itemLookup],
    );

    const { axisWidth, todayMs } = state;

    const timelineRef = useRef<HTMLDivElement>(null);

    const columnDurations = useMemo<ColumnDuration[]>(
        () => generateColumnDurations(new Date(), timelineMode),
        [timelineMode]
    );

    const timelineDuration: ColumnDuration = useMemo(
        () => getTimelineDuration(columnDurations),
        [columnDurations]
    );

    const columnWidth = useMemo(
        () => {
            switch (timelineMode) {
                case "DAYS":
                    return stateProps.isSmallViewMode ? 50 : dayCellWidth;
                case "WEEKS":
                    return regularCellWidth;
                default:
                    return stateProps.isSmallViewMode ? dayCellWidth : regularCellWidth;
            }
        },
        [stateProps.isSmallViewMode, timelineMode],
    );

    const timelineWidth = useMemo<number>(
        () => columnWidth * columnDurations.length,
        [columnDurations.length, columnWidth]
    );

    const scrollToDate = useCallback((date: Date) => {
        if (!timelineRef.current) {
            return;
        }

        const time = date.getTime();
        const { startMilliseconds, totalMilliseconds } = timelineDuration;

        if (time < startMilliseconds || time > startMilliseconds + totalMilliseconds) {
            return;
        }

        const position = ((time - startMilliseconds) / totalMilliseconds) * timelineRef.current!.scrollWidth;

        timelineRef.current.scrollTo({
            left: position - Math.max(50, axisWidth) - (stateProps.isSmallViewMode ? 0 : 295),
            behavior: 'smooth'
        });
    }, [timelineDuration, timelineRef, axisWidth, stateProps.isSmallViewMode]);

    useLayoutEffect(() => {
        const midPoints = data
            .flatMap((group) => group.categories)
            .flatMap((category) => category.items)
            .flatMap((barGroup) => barGroup.items)
            .reduce((acc, item) => {
                acc.push(item.start.getTime() + (item.end.getTime() - item.start.getTime()) / 2);
                return acc;
             } , [])

        const averageDate = new Date(Math.floor(midPoints.reduce((acc, time) => acc + time, 0) / midPoints.length));

        scrollToDate(averageDate);
    }, [searchText])

    const onDisplayedItemsChanged = useCallback<DisplayedItemsChangeHandler>(
        (category, count) => setData(
            (categories) => categories.map(
                (c) => c.key === category.key
                    ? { ...c, itemsDisplayed: count }
                    : c
            ),
        ),
        [setData],
    );

    const onAxisToggleClicked = useCallback(() => setState((prevState) => ({
        ...prevState,
        axisWidth: prevState.axisWidth === 0
            ? (stateProps.isSmallViewMode ? responsiveAxisWidth : defaultAxisWidth)
            : 0,
    })), [setState, stateProps.isSmallViewMode]);

    const scrollAreaStyles = useMemo<CSSProperties>(() => ({
        height: data.reduce(
            (height, group) => height + (data.length > 1 ? 52 : 0) + (!group.expanded ? 0 :           
                (group.categories.length > 3 && data.length > 1 ? 40 : 0) + group.categories.slice(0, group.itemsDisplayed).reduce(
                    (acc, category) => acc + (category.items.some((barGroup) => barGroup.expanded)
                        ? 50 + 50 * Math.max(...category.items.filter((barGroup) => barGroup.expanded).map(barGroup => barGroup.layers))
                        : 50)
                    , 0)),
                68,
        ),
    }), [data]);

    const onRailClicked = useCallback<RailClickHandler>((category, startTime, endTime) => {
        onCreateClick?.(startTime, endTime, category.key);
    }, [onCreateClick]);

    const onCategoryUpdated = useCallback<UpdateCategoryHandler>(
        async (model) => {
            let previousName: string = "";

            setData((prevData) => prevData.map(
                (group) => group.categories.every((category) => category.key !== model.id)
                    ? group
                    : ({
                        ...group,
                        categories: group.categories.map((category) => {
                            if (category.key !== model.id) {
                                return category;
                            }

                            previousName = category.name;
            
                            return {
                                ...category,
                                name: model.name,
                            };
                        }),
                    }),
            ));

            try {
                await updatePlannerCategoryAsync(model.id, {
                    name: model.name,
                    status: model.contentStatus,
                });
                return;
            } catch (err) { }

            if (previousName.length === 0) {
                return;
            }

            setData((prevData) => prevData.map(
                (group) => group.categories.every((category) => category.key !== model.id)
                    ? group
                    : ({
                        ...group,
                        categories: group.categories.map((category) => {
                            if (category.key !== model.id) {
                                return category;
                            }
            
                            return {
                                ...category,
                                name: previousName
                            };
                        }),
                    }),
            ));
        },
        [],
    );

    useLayoutEffect(() => { // Scroll to current date when the view has loaded
        const timeoutRef = setTimeout(() => scrollToDate(new Date()), 250);

        return () => clearTimeout(timeoutRef);
    }, [timelineRef.current, timelineMode]);

    useEffect(() => {
        dispatch(queryPlannerItems({
            after: new Date(timelineDuration.startMilliseconds),
            before: new Date(timelineDuration.endMilliseconds),
            status: SpintrTypes.ContentStatus.Published,
        }));
    }, [dispatch]);

    return (
        <section className="Timeline">
            <div className="Timeline-roadmap-wrapper">
                <div
                    aria-label={localize("PLANNER_TIMELINE")}
                    className="Timeline-roadmap"
                    role="grid"
                >
                    <div className="timeline-wrapper">
                        <div className="timeline" ref={timelineRef}>
                            <div className="timeline-rows-wrapper" style={scrollAreaStyles}>
                                <TimelineHeader
                                    axisWidth={axisWidth}
                                    columnDurations={columnDurations}
                                    columnWidth={columnWidth}
                                    isSmallViewMode={stateProps.isSmallViewMode}
                                    timelineDuration={timelineDuration}
                                    timelineMode={timelineMode}
                                    timelineWidth={timelineWidth}
                                    todayMs={todayMs} />

                                <TimelineGroups
                                    axisWidth={axisWidth}
                                    groups={data}
                                    hasWriteAccess={hasWriteAccess}
                                    columnDurations={columnDurations}
                                    onBarClick={onBarClick}
                                    onBarGroupClick={onBarGroupClicked}
                                    onCategoryUpdate={onCategoryUpdated}
                                    onDisplayedItemsChange={onDisplayedItemsChanged}
                                    onRailClick={onRailClicked}
                                    onGroupClick={onGroupClicked}
                                    onItemDurationChange={onItemDurationChange}
                                    timelineDuration={timelineDuration}
                                    timelineMode={timelineMode}
                                    timelineSize={timelineWidth}
                                    todayTime={todayMs} />

                                <TimelineOverlay
                                    axisWidth={axisWidth}
                                    columnDurations={columnDurations}
                                    columnWidth={columnWidth}
                                    timelineDuration={timelineDuration}
                                    timelineMode={timelineMode}
                                    timelineWidth={timelineWidth}
                                    todayMs={todayMs} />

                                <div className="Timeline-axisBorder-wrapper">
                                    <div
                                        className="Timeline-axisBorder"
                                        style={{ width: `${axisWidth}px` }}/>
                                </div>

                                <Conditional condition={stateProps.isSmallViewMode}>
                                    <div className="Timeline-axisToggle-wrapper">
                                        <UnstyledButton
                                            className={classNames("Timeline-axisToggle", { minimized: axisWidth === 0 })}
                                            onClick={onAxisToggleClicked}
                                            style={{ left: `${axisWidth === 0 ? 0 : (axisWidth - 20)}px` }}
                                        >
                                            <Visage2Icon
                                                icon="arrow-left" 
                                                size="small" />
                                        </UnstyledButton>
                                    </div>
                                </Conditional>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Timeline;
