export const FETCH_OPERATING_INFO = "FETCH_OPERATING_INFO";

export const FETCH_OPERATING_INFO_PENDING = "FETCH_OPERATING_INFO_PENDING";
export interface IFetchOperatingInfoPendingAction {
    type: typeof FETCH_OPERATING_INFO_PENDING;
}

export const FETCH_OPERATING_INFO_REJECTED = "FETCH_OPERATING_INFO_REJECTED";
export interface IFetchOperatingInfoRejectedAction {
    type: typeof FETCH_OPERATING_INFO_REJECTED;
}

export const FETCH_OPERATING_INFO_FULFILLED = "FETCH_OPERATING_INFO_FULFILLED";
export interface IFetchOperatingInfoFulfilledAction
    extends Spintr.IAsyncAction<Spintr.IOperatingInfoCategory[]> {
    type: typeof FETCH_OPERATING_INFO_FULFILLED;
}

export type FetchOperatingInfoAction =
      IFetchOperatingInfoPendingAction
    | IFetchOperatingInfoRejectedAction
    | IFetchOperatingInfoFulfilledAction;

export const FETCH_OPERATING_INFO_SUPPORT = "FETCH_OPERATING_INFO_SUPPORT";

export const FETCH_OPERATING_INFO_SUPPORT_PENDING = "FETCH_OPERATING_INFO_SUPPORT_PENDING";
export interface IFetchOperatingInfoSupportPendingAction {
    type: typeof FETCH_OPERATING_INFO_SUPPORT_PENDING;
}

export const FETCH_OPERATING_INFO_SUPPORT_REJECTED = "FETCH_OPERATING_INFO_SUPPORT_REJECTED";
export interface IFetchOperatingInfoSupportRejectedAction {
    type: typeof FETCH_OPERATING_INFO_SUPPORT_REJECTED;
}

export const FETCH_OPERATING_INFO_SUPPORT_FULFILLED = "FETCH_OPERATING_INFO_SUPPORT_FULFILLED";
export interface IFetchOperatingInfoSupportFulfilledAction
    extends Spintr.IAsyncAction<Spintr.IOperatingInfoSupportRequest> {
    type: typeof FETCH_OPERATING_INFO_SUPPORT_FULFILLED;
}

export type FetchOperatingInfoSupportAction =
    IFetchOperatingInfoSupportPendingAction
  | IFetchOperatingInfoSupportRejectedAction
  | IFetchOperatingInfoSupportFulfilledAction;


export type OperatingInfoAction =
    FetchOperatingInfoAction |
    FetchOperatingInfoSupportAction;