import React, { Component } from "react";
import { connect } from "react-redux";
import api from "src/spintr/SpintrApi";
import { Label, Loader } from ".";

interface IState {
    data: Spintr.DirektenCategory[];
    isLoading: boolean;
}

interface IProps {
    category: string;
}

class Direkten extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
        };
    }

    public async componentDidMount() {
        let data: Spintr.DirektenCategory[];

        try {
            data = (await api.get("/api/integrations/direkten", { params: { category: this.props.category } })).data;
        } catch (error) {
            data = [];
        }

        this.setState({ data, isLoading: false });
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        const categories = this.state.data;

        return (
            <div>
                {categories.map((category) => (
                    <div key={category.name}>
                        {categories.length > 1 && (
                            <Label as="h2" weight="medium">
                                {category.name}
                            </Label>
                        )}
                        {category.items.map((item) => (
                            <div key={item.name}>
                                <Label as="span" weight="medium">
                                    {item.name}
                                </Label>
                                <Label as="span">{item.value}</Label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

function mapStateToProps(state: Spintr.AppState) {
    return {};
}

export default connect(mapStateToProps)(Direkten);
