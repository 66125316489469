import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { SocialFeedType } from "src/social-feed/types";
import { StandardErrorBoundary } from "src/ui";
import { SpintrSocialFeed } from "../../SocialFeed";

interface IStateProps {
    userFeedId: number;
    userGalleryId: number;
    userId: number;
}

const SocialFeedWrapper: FunctionComponent = () => {
    const feedProps = useSelector<Spintr.AppState, IStateProps>(
        (state) => ({
            userFeedId: state.profile.active.feedId,
            userGalleryId: state.profile.active.galleryId,
            userId: state.profile.active.id,
        }),
    );

    return (
        <div className="socialFeed">
            <StandardErrorBoundary>
                <SpintrSocialFeed
                    feedType={SocialFeedType.Combined}
                    feedId={feedProps.userFeedId}
                    galleryId={feedProps.userGalleryId}
                    userId={feedProps.userId}
                    isStartPage={true}
                />
            </StandardErrorBoundary>
        </div>
    )
}

export default SocialFeedWrapper;