import api from "src/spintr/SpintrApi";

import {
    FETCH_NOTIFICATIONS,
    MARK_NOTIFICATION_AS_READ,
    MARK_NOTIFICATIONS_AS_READ,
    MARK_NOTIFICATION_AS_UNREAD,
    MARK_ALL_NOTIFICATIONS_AS_READ,
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    SET_UNREAD_COUNT
} from "./action-types";
import { INotificationsQueryParams } from "./types";

const apiBaseUrl = "/api/v1/notifications";

const fetchNotifications = (params: INotificationsQueryParams) => ({
    meta: params,
    payload: api.get<Spintr.INotification[]>(apiBaseUrl, { params }),
    type: FETCH_NOTIFICATIONS
});

const addNotification = (notification) => ({
    meta: {
        notification
    },
    type: ADD_NOTIFICATION
})

const removeNotification = (id: number) => ({
    meta: id,
    type: REMOVE_NOTIFICATION,
});

const markNotificationsAsRead = () => ({
    payload: api.post(`${apiBaseUrl}/checked`),
    type: MARK_NOTIFICATIONS_AS_READ
});

const markAllNotificationsAsRead = () => ({
    payload: api.post(`${apiBaseUrl}/allchecked`),
    type: MARK_ALL_NOTIFICATIONS_AS_READ
})

const markNotificationAsRead = (id: number) => ({
    meta: { id },
    payload: api.request({
        data: JSON.stringify({ read: true }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        method: "PUT",
        url: `${apiBaseUrl}/${id}`,
    }),
    type: MARK_NOTIFICATION_AS_READ
});

const markNotificationAsUnread = (id: number) => ({
    meta: { id },
    payload: api.request({
        data: JSON.stringify({ read: false }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        method: "PUT",
        url: `${apiBaseUrl}/${id}`,
    }),
    type: MARK_NOTIFICATION_AS_UNREAD
});

const setUnreadCount = (value: number) => ({
    meta: value,
    type: SET_UNREAD_COUNT,
});

export {
    fetchNotifications,
    markNotificationsAsRead,
    markNotificationAsRead,
    markNotificationAsUnread,
    markAllNotificationsAsRead,
    addNotification,
    removeNotification,
    setUnreadCount
};
