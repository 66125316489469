import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import CalendarRootView from "src/calendar/components/CalendarRootView";
import "src/groups/views/GroupCalendarView.scss";
import { FillHeight, Scrollable } from "src/ui/components";

interface IProps extends RouteComponentProps {
    group: any;
}

class GroupCalendarView extends Component<IProps> {
    public render() {
        return (
            <div className="GroupCalendarView GroupContentView">
                <CalendarRootView
                    groupId={this.props.group.id}
                    calendarId={this.props.group.calendarId}
                    externalId={this.props.group.externalId} />
            </div>
        );
    }
}

export default GroupCalendarView;
