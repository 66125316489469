import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";
import { fetchFavorites } from "../api";
import { ActionTypes } from "./action-types";

export interface IFetchUserFavoritesAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IUserFavorite[]>>;
    type: ActionTypes.FetchUserFavorites;
}

export interface IFetchUserFavoritesPendingAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IUserFavorite[]>>;
    type: ActionTypes.FetchUserFavoritesPending;
}

export interface IFetchUserFavoritesRejectedAction extends Action {
    payload: AxiosError;
    type: ActionTypes.FetchUserFavoritesRejected;
}

export interface IFetchUserFavoritesFulfilledAction extends Action {
    payload: AxiosResponse<Spintr.IUserFavorite[]>;
    type: ActionTypes.FetchUserFavoritesFulfilled;
}

export type FetchUserFavoritesHandler =
    (userId?: number) => IFetchUserFavoritesAction;

export const fetchUserFavorites: FetchUserFavoritesHandler =
    (userId) => ({
        payload: fetchFavorites(userId),
        type: ActionTypes.FetchUserFavorites,
    })

export type FetchUserFavoritesAction =
      IFetchUserFavoritesAction
    | IFetchUserFavoritesPendingAction
    | IFetchUserFavoritesRejectedAction
    | IFetchUserFavoritesFulfilledAction;

export type FavoriteAction =
    FetchUserFavoritesAction;
