import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from "react";
import { PlannerListProps } from "./PlannerList.types";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { IColumn } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { DataListFetchHandler } from "src/components/DataList";
import { queryPlannerItems } from "src/redux";
import { SpintrTypes } from "src/typings";
import moment from "moment";
import { localize } from "src/l10n";
import { SmallBody } from "src/components/Text";
import { getPlannerItemTypeText } from "../utils";

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = "YYYY-MM-DD HH:mm";

function getColumns(onClickAction: (item: Spintr.PlannerItem) => void): IColumn[] {
    return [{
        key: "name",
        name: localize("Namn"),
        fieldName: "name",
        minWidth: 100,
        isResizable: true,
        onRender(item: Spintr.PlannerItem) {
            return (
                <SmallBody
                    className="PlannerList-name"
                    color="contentDark"
                    onClick={() => onClickAction?.(item)}
                    title={item.name}
                >
                    {item.name}
                </SmallBody>
            );
        }
    }, {
        key: "type",
        name: localize("Typ"),
        fieldName: "itemType",
        minWidth: 100,
        isResizable: true,
        onRender(item: Spintr.PlannerItem) {
            return (
                <SmallBody color="contentDark">
                    {getPlannerItemTypeText(item.itemType)}
                </SmallBody>
            )
        }
    }, {
        key: "createdAt",
        name: localize("Skapad"),
        fieldName: "createdAt",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (
            <SmallBody color="contentDark">
                {moment(item.createdAt).format(dateTimeFormat)}
            </SmallBody>
        ),
    }, {
        key: "startsAt",
        name: localize("Startar"),
        fieldName: "startsAt",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (
            <SmallBody color="contentDark">
                {moment(item.startsAt).format(item.allDayEvent ? dateFormat : dateTimeFormat)}
            </SmallBody>
        ),
    }, {
        key: "endsAt",
        name: localize("Slutar"),
        fieldName: "endsAt",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => (
            <SmallBody color="contentDark">
                {moment(item.endsAt).format(item.allDayEvent ? dateFormat : dateTimeFormat)}
            </SmallBody>
        ),
    }, {
        key: "contentStatus",
        name: localize("Status"),
        fieldName: "contentStatus",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: function onRenderStatus(item: Spintr.PlannerItem) {
            let text: string;
            switch (item.contentStatus) {
                case SpintrTypes.ContentStatus.Draft:
                    text = localize("Utkast");
                    break;
                case SpintrTypes.ContentStatus.Published:
                    text = localize("Publicerad");
                    break;
                case SpintrTypes.ContentStatus.Deleted:
                    text = localize("Borttagen");
                    break;
                default:
                    text = "";
                    break;
            }

            return (
                <SmallBody color="contentDark">
                    {text}
                </SmallBody>
            );
        }
    }];
}

function PlannerList(props: PlannerListProps): ReactElement {
    const { refetchRef, searchText } = props;
    const dispatch = useDispatch();
    const data = useSelector<
        Spintr.AppState,
        { data: Spintr.PlannerItem[], totalCount: number, }
    >((state) => ({
        data: state.planner.queryItemIds.map((id) => state.planner.itemsById[id]),
        totalCount: state.planner.queryResultCount,
    }));

    const hasElevatedPermissions = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.profile.active.roles.some(
            (role) => role === "administrators" || role === "editor",
        ),
    );

    const searchRef = useRef<SpintrList>(null);
    const columns = useMemo(() => getColumns(props.onItemClick), [props.onItemClick]);
    const refetch = useCallback(() => {
        if (!searchRef?.current) {
            return;
        }

        searchRef.current.reFetch();
    }, [searchRef]);

    const fetch = useCallback<DataListFetchHandler>(async (skip, take, orderBy, ascending, searchText) => {
        let status = SpintrTypes.ContentStatus.Published;

        if (hasElevatedPermissions) {
            status |= SpintrTypes.ContentStatus.Draft;
        }

        if (hasElevatedPermissions && props.includeDeleted) {
            status |= SpintrTypes.ContentStatus.Deleted;
        }

        const dispatchResult = dispatch(queryPlannerItems({
            orderAscending: ascending,
            orderByColumn: orderBy,
            search: searchText,
            skip,
            take,
            status,
        }));

        // @ts-ignore Typings here are wrong as redux-think changes it
        const promise = dispatchResult as Promise<{ value: Spintr.QueryEnvelope<Spintr.PlannerItem> }>;
        const result = await promise;

        return { total: result.value.totalCount, };
    }, [hasElevatedPermissions, props.includeDeleted]);

    const onRowClicked = useCallback((item: Spintr.PlannerItem) => {
        props.onItemClick?.(item);
    }, [props.onItemClick]);

    useEffect(() => {
        if (!refetchRef) {
            return;
        }

        refetchRef.current = refetch;

        return () => {
            if (refetchRef.current !== refetch) {
                return;
            }

            refetchRef.current = undefined;
        };
    }, [refetch, refetchRef]);

    return (
        <section className="PlannerList">
            <SpintrList
                columns={columns}
                data={data}
                disableCommandBar={true}
                disablePagination={false}
                disableSearch={true}
                disableSort={true}
                fetch={fetch}
                isDescending={false}
                orderByColumn="startsAt"
                searchText={searchText}
                onRowClick={onRowClicked}
                ref={searchRef}
            />
        </section>
    );
}

export default PlannerList;
