import {
    ChoiceGroup, DefaultButton,
    IChoiceGroupOption,
    PrimaryButton, Separator,
    Stack, StackItem, TextField
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Action } from "redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui/components";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { validateRequiredTextField } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { UnstyledButton } from "../../ui/components";
import { MandatoryText } from "../../ui/components/Forms";
import { updateMediaFolder } from "../media-actions";
import "./MediaFolderEditView.scss";

interface IProps {
    close: () => void;
    dispatch: (action: Action) => void;
    activeUserName: string;
    activeUserId: number;
    isAdmin: boolean;
    isEditor: boolean;
    limitEditorMediaRights: boolean;
    hideUserGalleries: boolean;
    parentId: number;
    folderId: number;
    history: any;
    isModal?: boolean;
}

interface IState {
    rightsOptions: any[];
    gallery: fs;
    isLoading: boolean;
    headerText: string;
    saveError?: any;
    enableFieldValidation?: boolean;
}

interface fs {
    name: string;
    description: string;
    parentId: number;
    tags: string[];
    type: SpintrTypes.GalleryType;
    photographerName: string;
    photographerId: number;
    photographer: string;
    editors: Spintr.ITarget[];
}

class MediaFolderEditView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            rightsOptions: [],
            isLoading: this.props.folderId > 0,
            headerText: this.props.folderId > 0 ? localize("RedigeraMapp") : localize("SkapaMapp"),
            gallery: {
                name: "",
                description: "",
                parentId: this.props.parentId || 0,
                tags: [],
                type: SpintrTypes.GalleryType.Personal,
                photographer: props.activeUserName,
                photographerName: props.activeUserName,
                photographerId: props.activeUserId,
                editors: [],
            },
        };
    }

    componentDidMount() {
        this.setGalleryTypes();

        if (this.props.folderId) {
            api.get(`/api/galleries/${this.props.folderId}`).then((response: AxiosResponse) => {
                this.setState({ gallery: response.data as fs, isLoading: false });
            }).catch(this.handleCatch.bind(this));
        }
    }

    setGalleryTypes = (): void => {
        var type2Text = "";

        if (this.state.gallery.photographerId == this.props.activeUserId) {
            // TODO:  ^ ^ ^ folder ADD ID HERE ?? ^ ^ ^
            type2Text = localize("BaraJag");
        } else {
            type2Text = `${localize("Bara")} ${this.state.gallery.photographerName || this.state.gallery.photographer}`;
        }

        var type1 = {
            text: localize("Alla"),
            value: SpintrTypes.GalleryType.Public,
            key: SpintrTypes.GalleryType.Public.toString(),
        } as IChoiceGroupOption;
        var type0 = {
            text: localize("Redaktorer"),
            value: SpintrTypes.GalleryType.Central,
            key: SpintrTypes.GalleryType.Central.toString(),
        } as IChoiceGroupOption;
        var type2 = {
            text: type2Text,
            value: SpintrTypes.GalleryType.Personal,
            key: SpintrTypes.GalleryType.Personal.toString(),
        } as IChoiceGroupOption;
        var type10 = {
            text: localize("AnpassaRedaktorer"),
            value: SpintrTypes.GalleryType.CustomAudience,
            key: SpintrTypes.GalleryType.CustomAudience.toString(),
        } as IChoiceGroupOption;

        if (this.props.isAdmin || (this.props.isEditor && !this.props.limitEditorMediaRights)) {
            this.setState({ rightsOptions: [type1, type0, type2, type10] });
        } else if (this.props.isEditor && this.props.limitEditorMediaRights) {
            this.setState({ rightsOptions: [type1, type0, type2] });
        } else if (!this.props.hideUserGalleries) {
            this.setState({ rightsOptions: [type1, type2] });
        }
    };

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError &&
            saveError.response &&
            saveError.response.data &&
            saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false
        });
    }

    save = (): void => {
        console.log(this.state.gallery);

        this.setState({
            enableFieldValidation: true,
            isLoading: true,
            saveError: []
        }, () => {
            if (!this.state.gallery.name ||
                this.state.gallery.name.length === 0) {
                this.setState({
                    saveError: ["DuMasteFyllaIEttNamn"],
                    isLoading: false
                });

                return;
            }

            if (this.props.folderId) {
                api
                    .put(`/api/galleries/${this.props.folderId}`, this.state.gallery)
                    .then((response: AxiosResponse) => {
                        this.props.history.push({
                            pathname: `/media/folder/${this.props.folderId}`,
                        });

                        this.props.dispatch(updateMediaFolder(response.data));
                        this.props.close();
                    }).catch(this.handleCatch.bind(this));
                return;
            }

            api
                .post("/api/galleries", this.state.gallery)
                .then((response: AxiosResponse) => {
                    this.props.history.push({
                        pathname: `/media/folder/${response.data.id}`,
                    });
                    this.props.close();
                }).catch(this.handleCatch.bind(this));
        });
    };

    render(): JSX.Element {
        return (
            <div className="media-folder-edit-view">
                {this.state.saveError && this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                {!this.props.isModal && (
                    <div>
                        <Stack horizontal verticalAlign="center">
                            <StackItem grow={1}>
                                <Label as="h2" size="h2" className="title">
                                    {this.state.headerText}
                                </Label>
                            </StackItem>
                            <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.props.close}>
                                <Visage2Icon icon="close-circle" />
                            </UnstyledButton>
                        </Stack>
                        <Label size="body-3">{localize("SkapaMappOchDelaBildOchFilmMedDinaKollegor")}</Label>
                        <Separator />
                    </div>
                )}
                <MandatoryText />
                {this.state.isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <form>
                            <FormControl>
                                <TextField
                                    label={localize("NamnPaMappen")}
                                    value={this.state.gallery.name}
                                    onChange={(event: React.FormEvent, val: string) => {
                                        this.setState({ gallery: { ...this.state.gallery, name: val } });
                                    }}
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>
                            <FormControl>
                                <Label size="body-2" weight="medium">
                                    {localize("Rattigheter")}
                                </Label>
                                <Label size="body-3">{localize("BestamVemSomFarLaddaUppMediaTillDennaMapp")}</Label>
                                <ChoiceGroup
                                    options={this.state.rightsOptions}
                                    selectedKey={this.state.gallery.type.toString()}
                                    onChange={(event, option: IChoiceGroupOption) => {
                                        this.setState((prevState) => ({
                                            gallery: {
                                                ...prevState.gallery,
                                                type: option.value as SpintrTypes.GalleryType,
                                            },
                                        }));
                                    }}
                                />
                                {this.state.gallery.type == SpintrTypes.GalleryType.CustomAudience ? (
                                    <FormTokenizedObjectInput
                                        items={this.state.gallery.editors}
                                        label=""
                                        types={[SpintrTypes.UberType.User]}
                                        onChange={(editors) => {
                                            this.setState((prevState) => ({
                                                gallery: {
                                                    ...prevState.gallery,
                                                    editors: editors.map((e) => {
                                                        return {
                                                            name: e.name,
                                                            info: e.subText,
                                                            type: SpintrTypes.UberType.User,
                                                            id: e.key,
                                                            imageUrl: e.imageUrl,
                                                        };
                                                    }),
                                                },
                                            }));
                                        }}
                                    />
                                ) : null}
                            </FormControl>
                            <FormControl>
                                <TextField
                                    styles={{ subComponentStyles: { label: { root: { paddingTop: 0 } } } }}
                                    label={localize("Taggar")}
                                    value={this.state.gallery.tags.toString()}
                                    onChange={(event: React.FormEvent, val: string) => {
                                        this.setState((prevState) => ({
                                            gallery: {
                                                ...prevState.gallery,
                                                tags: val.split(","),
                                            },
                                        }));
                                    }}
                                />
                            </FormControl>
                        </form>
                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                            <DefaultButton text={localize("Avbryt")} onClick={this.props.close} />
                            <PrimaryButton text={localize("Spara")} onClick={this.save} />
                        </Stack>

                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        activeUserName: state.profile.active.name,
        activeUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        limitEditorMediaRights: state.instance.get("limitEditorMediaRights"),
        hideUserGalleries: state.instance.get("hideUserGalleries"),
    };
};

export default withRouter(connect(mapStateToProps)(MediaFolderEditView));
