import { Action } from "redux";
import * as ActionTypes from "./action-types";

export const setRightColumnItems = (items: any[]) : ActionTypes.ISetRightColumns => ({
    items,
    type: ActionTypes.RightColumnActionTypes.SetRightColumns
})

export const clearRightColumns = () : ActionTypes.IClearRightColumns => ({
    type: ActionTypes.RightColumnActionTypes.ClearRightColumns
})
