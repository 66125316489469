import React, { MutableRefObject, ReactElement } from "react";
import { DraftDecorator } from "draft-js";
import { DecoratorProps } from "../types";
import {
    ProductPlugin,
    ProductPluginConfig,
    ProductPluginState,
    ProductSuggestionsProps as ExternalSuggestionsProps,
    ProductRemovedHandler
} from "./types";
import { ProductMention } from "./ProductMention";
import {
    createProductMentionStrategy,
    createProductSuggestionStrategy,
} from "./strategies";
import {
    EditorStateChangeHandler,
    ProductSuggestions,
    ProductSuggestionsProps as InternalSuggestionsProps,
} from "./ProductSuggestions";
import { ProductSuggestion } from "./ProductSuggestion";
import { Product } from "src/products/types";

export function createProductPlugin(config: ProductPluginConfig): ProductPlugin {
    const mentions: string[] = [];

    const state: ProductPluginState = {
        getMentions: function getSearches() {
            return mentions;
        },

        register: function registerProductSearch(offsetKey) {
            if (mentions.includes(offsetKey)) {
                return;
            }

            mentions.push(offsetKey);
        },

        unregister: function unregisterProductSearch(offsetKey) {
            const index = mentions.indexOf(offsetKey);

            if (index === -1) {
                return;
            }

            mentions.splice(index, 1);
        }
    };

    const onChangeCallback: MutableRefObject<EditorStateChangeHandler> = {
        current: null
    };

    const productRemovedCallback: MutableRefObject<ProductRemovedHandler> = {
        current: null,
    };

    const suggestionsProps: InternalSuggestionsProps = {
        editorStateChangeRef: onChangeCallback,
        productRemovedRef: productRemovedCallback,
        state,
    };

    const onProductRemoved = (product: Product): void => {
        if (!product || !productRemovedCallback.current) {
            return;
        }

        productRemovedCallback.current(product);
    };

    const DecoratedProductSuggestion = (props: DecoratorProps): ReactElement => (
        <ProductSuggestion state={state} {...props} />
    );

    const DecoratedProductSuggestions = (props: ExternalSuggestionsProps): ReactElement => (
        <ProductSuggestions {...suggestionsProps} {...props} />
    );

    const DecoratedProductMention = (props: DecoratorProps): ReactElement => (
        <ProductMention {...props} onRemoved={onProductRemoved} />
    );
    
    const decorators: DraftDecorator[] = [{
        component: DecoratedProductMention,
        strategy: createProductMentionStrategy(),
    }, {
        strategy: createProductSuggestionStrategy(
            ...(config.patterns || [/\d{5}\-\d{1}/gmi]),
        ),
        component: DecoratedProductSuggestion,
    }];    

    return {
        ProductSuggestions: DecoratedProductSuggestions,

        decorators: config.enabled ? decorators : [],

        initialize: ({ getEditorState, setEditorState}) => {
            state.getEditorState = getEditorState;
            state.setEditorState = setEditorState;
        },

        onChange: (editorState, pluginFunctions) => {
            if (!config.enabled) {
                return editorState;
            }

            if (!onChangeCallback.current) {
                return editorState;
            }

            return onChangeCallback.current(editorState);
        }
    };
}