import api from "src/spintr/SpintrApi";
import {
    FETCH_MEDIA_CONTENT,
    FETCH_MEDIA_FOLDER,
    UPDATE_MEDIA_FOLDER,
    SET_MEDIA_TAG,
    SET_MEDIA_SEARCH,
    RESET_MEDIA_SEARCH_TAG,
    RESET_MEDIA_FOLDER_PATH,
} from "./media-action-types";

const baseUrl = "/api/media";

const fetchMediaContent = (folderId, skip = 0, sort = 0, tagId = 0, searchText = "") => ({
    payload: api.get(
        `${baseUrl}?parentId=${folderId}&skip=${skip}&sort=${sort}&tagId=${tagId}&searchText=${searchText}`
    ),
    type: FETCH_MEDIA_CONTENT,
    meta: { skip: skip },
});

const fetchMediaFolder = (folderId: number, image?: Spintr.IMediaImageImage) => ({
    payload: api.get(`${baseUrl}/folders/${folderId}`),
    type: FETCH_MEDIA_FOLDER,
    meta: { image: image },
});

const updateMediaFolder = folder => ({
    payload: folder,
    type: UPDATE_MEDIA_FOLDER
})

const resetMediaFolderPath = () => ({
    type: RESET_MEDIA_FOLDER_PATH,
});

const setMediaTag = (tagId = 0) => ({
    payload: tagId,
    type: SET_MEDIA_TAG,
});

const setMediaSearch = (searchText = "") => ({
    payload: searchText,
    type: SET_MEDIA_SEARCH,
});

const resetMediaSearchTag = () => ({
    type: RESET_MEDIA_SEARCH_TAG,
});

export { fetchMediaContent, fetchMediaFolder, updateMediaFolder, resetMediaFolderPath, setMediaTag, setMediaSearch, resetMediaSearchTag };
