import React, { useCallback, useMemo } from "react";
import "./SystemStatusResource.scss";
import SystemStatusResourceInfo from "./SystemStatusResourceInfo";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Link } from "react-router-dom";
import SystemStatusResourceCTA from "./SystemStatusResourceCTA";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { localize } from "src/l10n";
import api from "src/api";

interface IProps {
    resource: Spintr.ISystemStatusResource;
    children?: any;
    onDeclineSuggestion?: (id: number) => void;
    onAcceptSuggestion?: (id: number) => void;
}

const SystemStatusResourceWrapper = (props: IProps) => {
    const isLink = useMemo(() => {
        return !props.resource.isSuggestion;
    }, [props.resource]);

    if (isLink) {
        return (
            <Link className="SystemStatusResource" to={"/operations/" + props.resource.id}>
                {props.children}
            </Link>
        )
    }

    return (
        <div className="SystemStatusResource">
            {props.children}
        </div>
    )
}

const SystemStatusResource = (props: IProps) => {
    return (
        <SystemStatusResourceWrapper {...props}>
            <SystemStatusResourceInfo resource={props.resource} />
            {!props.resource.isSuggestion && (
                <div className="footer">
                    <div className="cta">
                        <SystemStatusResourceCTA
                            resource={props.resource}
                            systemStatusPostId={props.resource.lastSystemStatusPostId} />
                    </div>
                    <div className="footer-button">
                        <Visage2Icon icon="arrow-right-3" size="small" />
                    </div>
                </div>
            )}
            {props.resource.isSuggestion && (
                <div className="footer buttons">
                    <DefaultButton onClick={() => {
                        api.post("/api/v1/systemstatuses/resources/" + props.resource.id + "/decline-suggestion");
                        props.onDeclineSuggestion(props.resource.id);
                    }}>
                        {localize("TaBort")}
                    </DefaultButton>
                    <PrimaryButton onClick={() => {
                        api.post("/api/v1/systemstatuses/resources/" + props.resource.id + "/accept-suggestion");
                        props.onAcceptSuggestion(props.resource.id);
                    }}>
                        {localize("LaggTill")}
                    </PrimaryButton>
                </div>
            )}
        </SystemStatusResourceWrapper>
    )
}

export default SystemStatusResource;
