import React from "react";
import { IAcademyCategory } from "src/academy/types";
import { Label } from "src/ui";
import "./AcademyCourseCategories.scss";

interface IProps {
    categories: IAcademyCategory[];
}

const AcademyCourseCategories = (props: IProps) => {
    return (
        <div className="AcademyCourseCategories">
            {props.categories.map((x: IAcademyCategory) => {
                return (
                    <span className="category-name" key={x.id}>
                        <Label
                            as="span"
                            color="grey"
                            size="body-2">
                            {x.name}
                        </Label>
                    </span>
                )
            })}
        </div>
    )
}

export default AcademyCourseCategories;
