import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";

import {
    AdminDepartmentList,
    CompaniesListAdminView,
    OfficesListAdminView
} from "src/organisation/components";

interface IRouteParams {
    firstId?: string;
    secondId?: string;
}

const OrganisationStructureAdminView: FunctionComponent = () => {
    const enableCompanyLevel = useSelector<Spintr.AppState>(
        (state) => state.instance.get("enableCompanyLevel"),
    );

    const routeParams = useRouteMatch<IRouteParams>();

    const officeId = enableCompanyLevel
        ? routeParams.params.secondId
        : routeParams.params.firstId;

    if (officeId) {
        const companyId = enableCompanyLevel
            ? parseInt(routeParams.params.firstId, 10)
            : undefined;

        return (
            <AdminDepartmentList
                companyId={companyId}
                officeId={parseInt(officeId, 10)}
            />
        );
    }

    if (!enableCompanyLevel || routeParams.params.firstId) {
        return (
            <OfficesListAdminView
                companyId={routeParams.params.firstId}
            />
        );
    }

    return (
        <CompaniesListAdminView />
    );
};

export default OrganisationStructureAdminView;
