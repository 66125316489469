import api from "src/spintr/SpintrApi";
import {
    FETCH_ROOT_FOLDERS,
    FIND_FOLDER,
    SET_CURRENT_FOLDER_ID,
    SET_HIDE_SHARE,
    SET_ENABLE_ONLINE_EDIT,
    SET_COPIED_FOLDER,
} from "./folder-action-types";

const baseUrl = "/api/folders";

const fetchRootFolders = () => ({
    payload: api.get(`${baseUrl}/rootfolders`),
    type: FETCH_ROOT_FOLDERS,
});

const findFolderById = (id, source, externalId) => ({
    meta: { id, source },
    payload: api.get(`${baseUrl}/${id}?source=${source}${externalId ? `&externalId=${externalId}` : ""}`),
    type: FIND_FOLDER,
});

const setCurrentFolderId = (id) => ({
    payload: id,
    type: SET_CURRENT_FOLDER_ID,
});

const setHideShare = (hide: boolean) => ({
    payload: hide,
    type: SET_HIDE_SHARE,
});

const setEnableOnlineEdit = (id, enable, source) => ({
    meta: { id, enable, source },
    payload: {},
    type: SET_ENABLE_ONLINE_EDIT,
});

const setCopiedFolder = (folder: Spintr.IFileBrowserFile) => ({
    payload: folder,
    type: SET_COPIED_FOLDER,
});

export { fetchRootFolders, findFolderById, setCurrentFolderId, setHideShare, setEnableOnlineEdit, setCopiedFolder };
