import { MessageBar, MessageBarType } from "@fluentui/react";
import classNames from "classnames";
import React, { memo } from "react";
import { localize } from "src/l10n";
import "./ErrorMessagebar.scss";

interface Props {
    errorList: string[];
    onDismiss?: () => void;
    style?: React.CSSProperties;
    actions?: any;
    title?: string;
    messageBarType?: MessageBarType;
    aboveSplitForm?: boolean;
}

export default memo(function ErrorMessagebar(props: Props) {
    const { errorList, onDismiss, actions, title, messageBarType } = props;

    return (
        <div
            className={classNames("ErrorMessagebar", {
                splitForm: props.aboveSplitForm
            })}
            role="alert"
            style={props.style}
        >
            <MessageBar
                dismissButtonAriaLabel="Close"
                onDismiss={onDismiss}
                messageBarType={!!messageBarType ? messageBarType : MessageBarType.error}
                isMultiline={true}
                actions={actions}
            >
                {
                    !!title && title
                }
                {
                    !title && (localize("Fel") + ":")
                }
                {errorList.map((err, i) => {
                    return <div key={i}>{localize(err)}</div>;
                })}
            </MessageBar>
        </div>
    );
});
