import { AxiosResponse } from "axios";
import { IContextualMenuItem, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Action } from "redux";
import { localize } from "src/l10n";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { IApplicationState } from "src/spintr/reducer";
import { ActionMenu, Label, Loader } from "src/ui";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import { validateRequiredTextField } from "src/utils";
import { setCurrentFolderId } from "../folder-actions";
import { checkFileExtension } from "../utils";
import api from "src/spintr/SpintrApi";

interface IProps {
    id: number;
    dispatch: (action: Action) => void;
    location: Location;
    isFileUser: boolean;
    isEditor: boolean;
    isAdmin: boolean;
    disableFileLocking: boolean;
    isVersion: boolean;
    history: any;
    source: Spintr.FolderRootSource;
    enableTextpageExpires: boolean;
    restrictedExtensions: string;
    group?: any;
}

interface IState {
    isLoading: boolean;
    file: IFile;
    isFileDownloadOpen: boolean;
    tags: any[];
    selectedFile?: File;
    dialogMessage: string;
    showDialog: boolean;
    enableFieldValidation?: boolean;
}

interface IFile {
    date: string;
    dateStart: Date;
    dateEnd?: string;
    description: string;
    edit: string;
    folder: IFileFolder;
    id: number;
    isFavourite: boolean;
    isFollowing: boolean;
    isGroupFile: boolean;
    isImage: boolean;
    isLocked: boolean;
    lockedBy?: IFileUser;
    lockedFrom: string;
    lockedTo: string;
    name: string;
    owner: IFileUser;
    path: string;
    size: string;
    tags: string[];
    type: string;
    typeId: number;

    tagString: string;
}

interface IFileFolder {
    enableOnlineEdit: boolean;
    id: number;
    name: string;
}

interface IFileUser {
    id: number;
    name: string;
}

class FileDetailsEditView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            file: {} as IFile,
            isFileDownloadOpen: false,
            tags: [],
            dialogMessage: "",
            showDialog: false,
        };
    }

    componentDidMount() {
        this.fetchFile();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.fetchFile();
        }
    }

    fetchImportantTags(fileTags: string[]) {
        api.get("/api/tags/important").then((tagResponse: AxiosResponse) => {
            var tags = tagResponse.data.map((tag) => ({
                ...tag,
                isChecked: fileTags.includes(tag.text),
            }));

            this.setState({
                tags: tags,
            });
        });
    }

    fetchFile() {
        api.get(`/api/files/${this.props.id}`).then((response: AxiosResponse) => {
            var file = response.data;
            file.dateStart = new Date(file.dateStart);
            if (file.dateEnd) {
                file.dateEnd = new Date(file.dateEnd);
            }

            file.tagString = file.tags.join(", ");

            if (this.props.enableTextpageExpires) {
                this.fetchImportantTags(file.tags);
            }

            this.setState({
                isLoading: false,
                file: file,
            });

            this.props.dispatch(setCurrentFolderId(response.data.folder.id));
        });
    }

    goToFile() {
        this.props.history.push({
            pathname: this.props.group
                ? `/groups/${this.props.group.id}/files/f${this.props.id}`
                : `/files/${this.props.source}/f${this.props.id}`,
        });
    }

    onCancelClick = () => {
        this.goToFile();
    };

    save = () => {
        this.setState({
            enableFieldValidation: true
        });
        const { file } = this.state;
        // fix file extension?
        this.setState({
            isLoading: true,
        });

        var tags = [];
        if (this.state.tags.length > 0) {
            tags = this.state.tags.filter((tag) => tag.isChecked).map((tag) => tag.text);
        } else if (file.tagString) {
            tags = file.tagString.split(/\s*,\s*/);
        }

        var model = {
            id: file.id,
            folderId: file.folder.id.toString(),
            name: file.name,
            description: file.description,
            tags: tags,
            dateStart: file.dateStart,
            dateEnd: file.dateEnd,
        };

        api
            .put(`/api/files/${file.id}`, model)
            .then(() => {
                if (Date.parse(file.dateEnd) < Date.now()) {
                    this.props.history.push({
                        pathname: this.props.group
                            ? `/groups/${this.props.group.id}/files/d${file.folder.id}`
                            : `/files/${this.props.source}/d${file.folder.id}`,
                    });
                    return;
                }
                this.goToFile();
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    addVersion = () => {
        if (!this.state.selectedFile) {
            this.setState({
                dialogMessage: localize("DuHarInteLagtTillFil"),
                showDialog: true,
            });
            return;
        }

        if (checkFileExtension(this.state.selectedFile, this.props.restrictedExtensions)) {
            this.setState({
                dialogMessage: localize("FilTypInteTillaten"),
                showDialog: true,
            });
            return;
        }

        this.setState({ isLoading: true });

        let body = new FormData();
        body.append("id", this.props.id.toString());
        body.append("type", "3");
        body.append("description", this.state.file.description);
        body.append("file", this.state.selectedFile);

        api.post("/api/uploads", body).then(this.save);
    };

    onToggleSelect = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        var tags = [...this.state.tags];
        var tagIndex = tags.findIndex((tag) => tag.text === item.text);
        var tag = { ...tags[tagIndex] };
        tag.isChecked = !tag.isChecked;
        tags[tagIndex] = tag;

        this.setState({ tags: tags });
    };

    onTextFieldChange = (event, value) => {
        if (!event ||
            !event.target) {
            return;
        }

        const name = event.target.name;

        this.setState((prevState) => ({
            file: {
                ...prevState.file,
                [name]: value,
            },
        }));
    };

    onFileSelected = (file: File) => {
        this.setState((prevState) => ({
            selectedFile: file,
            file: {
                ...prevState.file,
                name: file.name,
            },
        }));
    };

    dismissDialog = () => {
        this.setState({
            showDialog: false,
        });
    };

    render() {
        const state = this.state;
        const { file } = state;

        if (state.isLoading) {
            return (
                <div className="file-details edit">
                    <Loader />
                </div>
            );
        }

        var displayTagSelector = this.state.tags.length > 0;

        return (
            <div className="file-details edit">
                <Helmet>
                    <title>{localize("Filer")}</title>
                </Helmet>
                <Label weight="medium">{file.name}</Label>
                <form>
                    <FormSection>
                        <FormControl>
                            <TextField
                                label={localize("Namn")}
                                name="name"
                                value={file.name}
                                onChange={this.onTextFieldChange}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Beskrivning")}
                                name="description"
                                value={file.description}
                                onChange={this.onTextFieldChange}
                                multiline
                            />
                        </FormControl>
                    </FormSection>
                    <FormSection title={localize("Publicering")}>
                        <SpintrDatePicker
                            label={localize("Fran")}
                            value={file.dateStart}
                            onChangeHandler={(date, event) => {
                                this.setState((prevState) => ({
                                    file: {
                                        ...prevState.file,
                                        dateStart: date,
                                    },
                                }));
                            }}
                        />

                        <SpintrDatePicker
                            isClearable={true}
                            label={localize("Till")}
                            value={file.dateEnd}
                            onChangeHandler={(date) => {
                                this.setState((prevState) => ({
                                    file: {
                                        ...prevState.file,
                                        dateEnd: date,
                                    },
                                }));
                            }}
                        />
                    </FormSection>
                    <FormSection>
                        <FormControl>
                            {!displayTagSelector ? (
                                <TextField
                                    label={localize("Taggar")}
                                    name="tagString"
                                    value={file.tagString}
                                    onChange={this.onTextFieldChange}
                                    multiline
                                />
                            ) : (
                                <ActionMenu
                                    label={`${localize("Valj")} ${localize("Taggar")}`}
                                    categories={[
                                        {
                                            items: state.tags.map((tag) => ({
                                                text: tag.text,
                                                isChecked: tag.isChecked,
                                                canCheck: true,
                                                onClick: this.onToggleSelect,
                                            })),
                                        },
                                    ]}
                                />
                            )}
                        </FormControl>
                    </FormSection>
                    {this.props.isVersion && (
                        <FormSection title={localize("ValjFil")}>
                            <FormControl>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        this.onFileSelected(e.target.files[0]);
                                    }}
                                />
                            </FormControl>
                        </FormSection>
                    )}
                </form>
                {
                    !!state.showDialog && (
                        <InfoDialog message={state.dialogMessage} show={state.showDialog} onDismiss={this.dismissDialog} />
                    )
                }
                <FormFooterBar
                    onCancelClick={this.onCancelClick}
                    onSaveClick={this.props.isVersion ? this.addVersion : this.save}
                    saveText={localize(this.props.isVersion ? "LaggTillNyVersion" : "Spara")}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isFileUser: state.profile.active.isFileUser,
        disableFileLocking: state.instance.get("disableFileLocking"),
        enableTextpageExpires: state.instance.get("enableTextpageExpires"),
        restrictedExtensions: state.instance.get("restrictFileUploads"),
    };
};

export default withRouter(connect(mapStateToProps)(FileDetailsEditView));
