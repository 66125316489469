import * as actionTypes from "./action-types";

const initialState = {
    error: false,
    isLoading: false,
    items: [],
    totalCount: 0,
    allUsersHasDepartment: true,
};

const peopleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PEOPLE_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.FETCH_PEOPLE_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.FETCH_PEOPLE_FULFILLED:
            const items = action.payload.data;
            const totalCount = parseInt(action.payload.headers["x-total-count"]);

            if (action.meta.fetchMore) {
                return {
                    ...state,
                    error: initialState.error,
                    isLoading: false,
                    items: state.items.concat(items),
                    totalCount,
                };
            }
            else {
                return {
                    ...state,
                    error: initialState.error,
                    isLoading: false,
                    items,
                    totalCount,
                };
            }

        case actionTypes.FOLLOW_USER_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.FOLLOW_USER_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.FOLLOW_USER_FULFILLED:
            return {
                ...state,
                error: initialState.error,
                isLoading: false,
            };

        case actionTypes.UNFOLLOW_USER_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.UNFOLLOW_USER_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.UNFOLLOW_USER_FULFILLED:
            return {
                ...state,
                error: initialState.error,
                isLoading: false,
            };

        case actionTypes.LOCK_USER_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.LOCK_USER_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.LOCK_USER_FULFILLED:
            return {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.meta.userId) {
                        return {
                            ...user,
                            rights: {
                                ...user.rights,
                                locked: true,
                            },
                        };
                    } else {
                        return user;
                    }
                }),
                error: initialState.error,
                isLoading: false,
            };

        case actionTypes.UNLOCK_USER_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.UNLOCK_USER_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.UNLOCK_USER_FULFILLED:
            return {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.meta.userId) {
                        return {
                            ...user,
                            rights: {
                                ...user.rights,
                                locked: false,
                            },
                        };
                    } else {
                        return user;
                    }
                }),
                error: initialState.error,
                isLoading: false,
            };

        case actionTypes.DISABLE_USER_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.DISABLE_USER_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.DISABLE_USER_FULFILLED:
            return {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.meta.userId) {
                        return {
                            ...user,
                            rights: {
                                ...user.rights,
                                deleted: true,
                            },
                        };
                    } else {
                        return user;
                    }
                }),
                error: initialState.error,
                isLoading: false,
            };

        case actionTypes.ENABLE_USER_PENDING:
            return {
                ...state,
                error: initialState.error,
                isLoading: true,
            };

        case actionTypes.ENABLE_USER_REJECTED:
            return {
                ...state,
                error: true,
                isLoading: false,
            };

        case actionTypes.ENABLE_USER_FULFILLED:
            return {
                ...state,
                items: state.items.map((user) => {
                    if (user.id === action.meta.userId) {
                        return {
                            ...user,
                            rights: { ...user.rights, deleted: false },
                        };
                    } else {
                        return user;
                    }
                }),
                error: initialState.error,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default peopleReducer;
