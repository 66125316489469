import React, { Component, ReactElement, ReactNode, useState } from "react";
import SocialBlock from "src/interactions/components/SocialBlock";
import { SocialFeedType } from "src/social-feed";
import { SocialPost } from "../SocialPost";
import "./SocialPostContainer.scss";
import { SpintrTypes } from "src/typings";
import classNames from "classnames";
import { InteractiveTextFormatOptions } from "src/utils";
import { Label, UnstyledButton } from "src/ui";
import SystemStatusSocialPostHeader from "src/system-status/SystemStatusSocialPostHeader";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { useSelector } from "react-redux";

interface ISocialPostContainerProps {
    identity?: any;
    isShare?: boolean;
    post: Spintr.ISocialPostBase;
    feedType?: SocialFeedType;
    onDoneEditing?: any;
    interactiveTextOptions?:    InteractiveTextFormatOptions | undefined;
    index?: number;
}

interface IState {
    expanded: boolean;
}

function SocialPostContainer(props: ISocialPostContainerProps): ReactElement {
    const [state, setState] = useState<IState>({
        expanded: props.feedType !== SocialFeedType.SystemStatus || props.index === 0
    });

    const userResources = useSelector<Spintr.AppState, Spintr.ISystemStatusResource[]>(
        (appState) => appState.profile.active.systemStatusResources,
    );

    const systemStatusPost = props.post.content.find((c) => c.Type === SpintrTypes.UberType.SystemStatus) as Spintr.ISocialSystemStatus;
    const isSystemStatus = !!systemStatusPost;
    const isSystemStatusFeed = props.feedType === SocialFeedType.SystemStatus;

    if (isSystemStatus &&
        isSystemStatusFeed &&
        !state.expanded &&
        systemStatusPost?.systemStatusType !== SpintrTypes.SystemStatusType.Ongoing &&
        systemStatusPost?.systemStatusType !== SpintrTypes.SystemStatusType.Planned) {
        return (
            <UnstyledButton
                className={classNames("SocialPostContainer expandable-feed-post visage-box-shadow start-page-flexible-padding",
                    {
                        "border": props.feedType === SocialFeedType.SystemStatus,
                        "is-system-status": isSystemStatus,
                    })}
                onClick={() => {
                    setState({
                        expanded: true
                    });
                }}>
                <SystemStatusSocialPostHeader post={props.post} />
                <div className="spacer" />
                <Label size="body-2" color="grey" className="expand-label">{localize("SHOW_UPDATE")}</Label>
                <Visage2Icon icon="arrow-down-1" color="grey" size="small" />
            </UnstyledButton>
        )
    }

    let commentPlaceholder: string | undefined = isSystemStatus && (localize("COMPOSER_QUESTION"));

    return (
        <div className={classNames("SocialPostContainer visage-box-shadow start-page-flexible-padding",
        {
            "border": props.feedType === SocialFeedType.SystemStatus,
            "is-system-status": isSystemStatus,
        })}>
            <div className="post-wrapper">
                <SocialPost
                    post={props.post}
                    onDoneEditing={props.onDoneEditing}
                    feedType={props.feedType}
                    interactiveTextOptions={props.interactiveTextOptions}
                />
            </div>
            {!props.isShare &&
                !props.post.hidden &&
                (props.post.StatusType !== SpintrTypes.StatusType.SystemStatus ||
                    props.feedType === SocialFeedType.SystemStatus ||
                    props.feedType === undefined) && (
                        <SocialBlock
                            onCommentIconClick={() => { }}
                            commentPlaceholder={commentPlaceholder}
                            uberId={props.post.Id}
                            comments={props.post.comments}
                            likers={props.post.likers}
                            identity={props.identity}
                            sendToMixpanel={props.feedType === SocialFeedType.Combined}
                            enableReactions={true}
                            displayBigButtons={true}
                            isFeedPost={true}
                            targetCreatorId={props.post.UserId}
                            targetIsQuestion={props.post.StatusType === SpintrTypes.StatusType.Question}
                        />
            )}
        </div>
    );
}


export default SocialPostContainer;