import { IPivotItemProps, Pivot, PivotItem } from "@fluentui/react";
import { Modal } from "@fluentui/react/lib/components/Modal/Modal";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminRssEditView from "src/admin/views/AdminRssEditView";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { SocialFeedType } from "src/social-feed";
import { SpintrSocialFeed } from "src/spintr/index";
import { SpintrTypes } from "src/typings";
import { ActionMenu } from "src/ui";
import { isAnythingDirty } from "src/utils";
import InformationFeedLoader from "./InformationFeedLoader";
import "./InformationFeedModule.scss";
import api from "src/spintr/SpintrApi";
import PopupHeader from "src/ui/components/PopupHeader";

interface IOwnProps {
}

interface IStateProps {
    socialFeedEnabled: boolean;
    blogsEnabled: boolean;
    newsEnabled: boolean;
    rssEnabled: boolean;
    userFeedId: number;
    userGalleryId: number;
    history: any;
    primaryColor: string;
    dispatch?: any;
}

interface IState {
    displayNewsActionMenu: boolean;
    displayRSSActionMenu: boolean;
    rssMenu: any[];
    showRSSModal: boolean;
    isLoadingRSS: boolean;
    feedLoaderKey: number;
}

type Props = IOwnProps & IStateProps;

class InformationFeedModule extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            displayNewsActionMenu: false,
            displayRSSActionMenu: false,
            rssMenu: [],
            showRSSModal: false,
            isLoadingRSS: false,
            feedLoaderKey: 0
        };
    }

    renderNewsActionMenu() {
        return (
            <ActionMenu
                categories={[
                    {
                        items: [
                            {
                                text: localize("GaTillNyhetsarkivet"),
                                onClick: () => this.props.history.push(`/news`),
                            },
                        ],
                    },
                ]}
            />
        );
    }

    toggleRSSSubscription = (m) => {
        let rssMenu = [...this.state.rssMenu];
        let idx = rssMenu.findIndex((i) => {
            return i.id === m.id;
        });
        rssMenu[idx].isUserSubscribed = !m.isUserSubscribed;
        this.setState({ rssMenu }, () => {
            this.updateSubscriptions();
        });
    };

    updateSubscriptions = () => {
        let subscriptions = this.state.rssMenu
            .map((r) => {
                if (r.isUserSubscribed) return r.id;
            })
            .filter(Boolean);

        this.setState({ isLoadingRSS: true }, () => {
            api.put(`/api/v1/users/rsssubscriptions`, { subscriptions }).then((_response) => {
                this.fetchRSSFeeds();

                this.setState({
                    feedLoaderKey: this.state.feedLoaderKey + 1
                });
            });
        });
    };

    renderRSSActionMenu() {
        return (
            <ActionMenu
                categories={[
                    {
                        items: [
                            ...this.state.rssMenu.map((m) => {
                                return {
                                    text: m.name,
                                    id: m.id,
                                    isChecked: m.forceSubscription || m.isUserSubscribed,
                                    canCheck: true,
                                    onClick: () => {
                                        this.toggleRSSSubscription(m);
                                    },
                                };
                            }),
                            {
                                text: localize("LaggTillRssFlode"),
                                onClick: () => {
                                    this.setState({ showRSSModal: true });
                                },
                            },
                        ],
                    },
                ]}
            />
        );
    }

    fetchRSSFeeds = () => {
        this.setState({ isLoadingRSS: true }, () => {
            api.get(`/api/rss`).then((response) => {
                this.setState({
                    rssMenu: response.data,
                    isLoadingRSS: false,
                });
            });
        });
    };

    closeRSSModal = () => {
        const close = () => {
            this.setState({
                showRSSModal: false
            });
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    renderRSSViewModal = () => {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showRSSModal}
                onDismiss={this.closeRSSModal.bind(this)}
            >
                <PopupHeader
                    text={localize("ValjFil")}
                    onClose={this.closeRSSModal.bind(this)} />
                <AdminRssEditView
                    isModal={true}
                    onCancel={this.closeRSSModal.bind(this)}
                    postSave={() => {
                        this.setState({
                            showRSSModal: false,
                            feedLoaderKey: this.state.feedLoaderKey + 1
                        });

                        this.fetchRSSFeeds();
                    }}
                />
            </Modal>
        );
    };

    public render(): ReactNode {
        const socialText = localize("Flode");
        const everythingText = localize("Allt");
        const newsText = localize("Nyheter");
        const blogsText = localize("Bloggar");
        const rssText = localize("appRSS");

        return (
            <div className="InformationFeedModule visage-box-shadow">
                {this.renderRSSViewModal()}
                <div className={"tabs printignore"}>
                    <Pivot
                        className="pivot-filter-style"
                        defaultSelectedKey={this.props.newsEnabled && this.props.blogsEnabled ? "all" : null}
                        // overflowBehavior={"menu"}
                        onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {

                            // check if click on action menu, required for IE11
                            // @ts-ignore
                            if (ev.target.clientWidth - ev.nativeEvent.offsetX < 30) {
                                //@ts-ignore
                                if (ev.target.querySelector("a")) {
                                    // @ts-ignore
                                    ev.target.querySelector("a").click();
                                }
                            }

                            this.setState({
                                displayNewsActionMenu: item.props.headerText === newsText,
                                displayRSSActionMenu: item.props.headerText === rssText,
                            });
                            if (item.props.headerText === rssText) {
                                this.fetchRSSFeeds();
                            }
                        }}
                        styles={{
                            linkIsSelected: {
                                color: this.props.primaryColor,
                            },
                        }}
                    >
                        {this.props.socialFeedEnabled && (
                            <PivotItem headerText={socialText} itemKey={"feed"}>
                                <SpintrSocialFeed
                                    feedId={this.props.userFeedId}
                                    feedType={SocialFeedType.Combined}
                                    galleryId={this.props.userGalleryId}
                                    isStartPage
                                />
                            </PivotItem>
                        )}
                        {
                            (this.props.newsEnabled && this.props.blogsEnabled) && (
                                <PivotItem
                                    headerText={everythingText}
                                    itemKey={"all"}
                                    onRenderItemLink={(props: IPivotItemProps) => {
                                        return (
                                            <span>
                                                {
                                                    this.props.socialFeedEnabled && this.props.blogsEnabled &&
                                                    (
                                                        <div className="PivotSeparator"></div>
                                                    )
                                                }
                                                {props.headerText}
                                            </span>
                                        );
                                    }}>
                                    <InformationFeedLoader />
                                </PivotItem>
                            )}
                        {this.props.newsEnabled && (
                            <PivotItem
                                headerText={newsText}
                                itemKey={"news"}
                                onRenderItemLink={(props: IPivotItemProps) => {
                                    return (
                                        <span className="PivotItemWithActionMenu">
                                            {
                                                this.props.socialFeedEnabled && !this.props.blogsEnabled &&
                                                (
                                                    <div className="PivotSeparator"></div>
                                                )
                                            }
                                            {props.headerText}
                                            {this.state.displayNewsActionMenu && this.renderNewsActionMenu()}
                                        </span>
                                    );
                                }}
                            >
                                <InformationFeedLoader includeBlogPosts={false} />
                            </PivotItem>
                        )}
                        {this.props.blogsEnabled && (
                            <PivotItem headerText={blogsText} itemKey={"blogs"}>
                                <InformationFeedLoader includeNews={false} />
                            </PivotItem>
                        )}
                        {this.props.rssEnabled && (
                            <PivotItem
                                headerText={rssText}
                                itemKey={"rss"}
                                onRenderItemLink={(props: IPivotItemProps) => {
                                    return (
                                        <span className="PivotItemWithActionMenu">
                                            {props.headerText}
                                            {this.state.displayRSSActionMenu && this.renderRSSActionMenu()}
                                        </span>
                                    );
                                }}
                            >
                                <InformationFeedLoader key={this.state.feedLoaderKey} isRss={true} />
                            </PivotItem>
                        )}
                    </Pivot>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,

        primaryColor: state.instance.get("color") as string,
        blogsEnabled: state.app.items.some((app) => app.enabled && app.id === 2),
        newsEnabled: state.app.items.some((app) => app.enabled && app.id === 5),
        rssEnabled: state.app.items.some((app) => app.enabled && app.id === 11),
        socialFeedEnabled: state.ui.viewMode < SpintrTypes.ViewMode.SmallestLaptop,
        userFeedId: state.profile.active.feedId,
        userGalleryId: state.profile.active.galleryId,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(InformationFeedModule));
