import { Reducer } from "redux";
import * as Actions from "./actions";
import { produce } from "immer";
import { ActionTypes } from "./action-types";

export interface ISpotlightState {
    isLoading: boolean;
    items: Spintr.SpotlightNewsArticle[];
}

const initialState: ISpotlightState = {
    isLoading: true,
    items: [],
};

const spotlightReducer: Reducer<ISpotlightState, Actions.SpotlightAction> =
    (state = initialState, action) => produce(state, draft => {

        switch (action.type) {
            case ActionTypes.FetchSpotlightPending:
                break;

            case ActionTypes.FetchSpotlightFulfilled:
                draft.items = action.payload.data;
                draft.isLoading = false;
                break;

            case ActionTypes.RemoveSpotlightItem:
                draft.items = state.items.filter(item => item.id !== action.payload);
                break;

            case ActionTypes.ClearSpotlight:
                for (const [key, value] of Object.entries(initialState)) {
                    draft[key] = value;
                }

                break;
        }
    });

export default spotlightReducer;