import React, { useEffect, useMemo, useRef, useState } from 'react';
import "./AudioPlayer.scss";
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import WaveSurfer from "wavesurfer.js";
import Microphone from "wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js";
import { localize } from 'src/l10n';


interface IProps {
    editMode?: boolean;
    onUpdate?: (data: any) => void;
    url?: string;
}

interface IState {
    hasAudio: boolean;
    isRecording: boolean;
    isPlaying: boolean;
    //stream: any;
    audioUrl: string;
    audioBlob?: any;
    currentTime: string;
    currentTimeSeconds: number;
    duration: string;
    waveWidth: number;
    statusMessage: string;
    recordingAllowed: boolean;
}

const AudioPlayer = (props: IProps) => {
	const mediaRecorder = useRef(null);
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const audioChunks = useRef([]);

    const [state, setState] = useState<IState>({
        hasAudio: !!props.url,
        isRecording: false,
        isPlaying: false,
        //stream: null,
        audioUrl: props.url,
        currentTime: "",
        currentTimeSeconds: 0,
        duration: "",
        waveWidth: 0,
        statusMessage: "",
        recordingAllowed: false
    });

    const mimeType = useMemo(() => {
        const mimeTypes = ["audio/webm", "audio/ogg", "audio/aac"];

        for (const mt of mimeTypes) {
            //@ts-ignore
            if (MediaRecorder.isTypeSupported(mt)) {
                return mt;
            }
        }

        return undefined;
    }, []);

    useEffect(() => {
        if (props.editMode) {
            setState((s: IState) => ({
                ...s,
                statusMessage: localize("AUDIO_PERMISSION_INFO"),
                recordingAllowed: false
            }));

            navigator.mediaDevices.getUserMedia({audio: true}).then(() => {
                setState((s: IState) => ({
                    ...s,
                    statusMessage: "",
                    recordingAllowed: true
                }));
            }).catch(() => {});
        }
    }, []);

    useEffect(() => {
        const options = {
            container: waveformRef.current,
            waveColor: "#C9CBF1",
            progressColor: "#787CDD",
            cursorColor: "#787CDD",
            cursorWidth: 0,
            barWidth: 2,
            barGap: 2,
            barRadius: 2,
            responsive: true,
            height: 15,
            normalize: true,
            partialRender: !!state.audioUrl,
            minPxPerSec: 5,
            plugins: [
                Microphone.create({
                    bufferSize: 4096,
                    numberOfInputChannels: 1,
                    numberOfOutputChannels: 1,
                    constraints: {
                        video: false,
                        audio: true
                    }
                })
            ]
        };

        wavesurfer.current = WaveSurfer.create(options);

        if (state.audioUrl) {
            wavesurfer.current.load(state.audioUrl);

            wavesurfer.current.on("ready", function() {
                if (!wavesurfer.current) {
                    return;
                }
    
                setState((s: IState) => {
                    return {
                        ...s,
                        duration: formatNumber(wavesurfer.current.getDuration())
                    }
                });
            });
    
            wavesurfer.current.on("finish", () => {
                setState((s: IState) => {
                    return {
                        ...s,
                        isPlaying: false
                    }
                });
            });
        } else {
            wavesurfer.current.microphone.on('deviceReady', function (stream) {
                setState((s: IState) => {
                    return {
                        ...s,
                        permission: true,
                        //stream: streamData,
                        isRecording: true,
                    }
                });

                //@ts-ignore
                const media = new MediaRecorder(stream, { mimeType });

                mediaRecorder.current = media;
                mediaRecorder.current.start();

                let localAudioChunks = [];

                mediaRecorder.current.ondataavailable = (event) => {
                    if (typeof event.data === "undefined") return;
                    if (event.data.size === 0) return;
                    localAudioChunks.push(event.data);
                };

                audioChunks.current = localAudioChunks;
            });
        }

        return () => wavesurfer.current.destroy();
    }, [state.audioUrl]);

    useEffect(() => {
        if (state.isPlaying) {
            if (wavesurfer.current) {
                wavesurfer.current.play();
    
                const intervalId = setInterval(() => {
                    if (!wavesurfer.current) {
                        return;
                    }
    
                    const currentTime = formatNumber(wavesurfer.current.getCurrentTime());
    
                    if (currentTime !== state.currentTime) {
                        setState((s: IState) => {
                            return {
                                ...s,
                                currentTime: formatNumber(wavesurfer.current.getCurrentTime())
                            }
                        });
                    }
                }, 100);

                return () => clearInterval(intervalId);
            }
        } else {
            if (wavesurfer.current) {
                wavesurfer.current.pause();
            }
        }
    }, [state.isPlaying, state.currentTime]);

    const start = async () => {
        wavesurfer.current.microphone.start();
    }

    const stop = () => {
        wavesurfer.current.microphone.stop();
		mediaRecorder.current.stop();

		mediaRecorder.current.onstop = async () => {
			const audioBlob = new Blob(audioChunks.current, { type: mimeType });
			const audioUrl = URL.createObjectURL(audioBlob);

            setState((s: IState) => {
                return {
                    ...s,
                    audioUrl,
                    audioBlob,
                    hasAudio: true
                }
            });

            props.onUpdate(new File([audioBlob], Date.now() + ("." + mimeType.split("/")[1])));
		};
    }

    const pause = () => {
        setState((s: IState) => {
            return {
                ...s,
                isPlaying: false
            }
        });
    }

    const play = () => {
        setState((s: IState) => {
            return {
                ...s,
                isPlaying: true
            }
        });
    }

    const formatNumber = (input: number) => {
        const minutes: number = Math.floor(input / 60);
        const seconds: number = Math.floor(input - minutes * 60);
        const formattedSeconds = seconds < 10 ? ("0" + seconds) : seconds;

        return minutes + ":" + formattedSeconds;
    }

    return (
        <div className="AudioPlayer">
            {!state.hasAudio && !state.isRecording && (
                <Label
                    size="body-2"
                    className="info-text"
                    title={state.statusMessage || localize("RECORD_INFO")}>
                    {state.statusMessage || localize("RECORD_INFO")}
                </Label>
            )}
            <div className="time">
                <Label size="body-2" color="dark-grey">
                    {state.isPlaying ? state.currentTime : state.duration}
                </Label>
            </div>
            <div className="wave">
                <div className="Waveform">
                    <div id="waveform" ref={waveformRef} />
                </div>
            </div>
            <UnstyledButton
                className="round-button"
                disabled={props.editMode && !state.recordingAllowed}
                title={!state.hasAudio && !state.isRecording ?
                    localize("RECORD_VOICE") :
                    !state.hasAudio && state.isRecording ?
                        localize("STOP") :
                        state.isPlaying ? 
                            localize("Pausa") :
                            localize("SpelaUppFil")}
                onClick={() => {
                    if (!state.hasAudio && !state.isRecording) {
                        start();
                    } else if (!state.hasAudio && state.isRecording) {
                        stop();
                    } else if (state.isPlaying) {
                        pause();
                    } else {
                        play();
                    }
                }}>
                {!state.hasAudio && !state.isRecording && (
                    <div className="recording"></div>
                )}
                {!(!state.hasAudio && !state.isRecording) && (
                    <Visage2Icon
                        color={!state.hasAudio && !state.isRecording ? "red" : "visageMidBlue"}
                        size="big"
                        type="bold"
                        icon={
                            !state.hasAudio && !state.isRecording ?
                                "record-circle" :
                                !state.hasAudio && state.isRecording ?
                                    "stop-circle" :
                                    state.isPlaying ?
                                        "pause-circle" :
                                        "play-circle"
                        } />
                )}
            </UnstyledButton>
        </div>
    )
}

export default AudioPlayer;
