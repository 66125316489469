import { Text } from "@fluentui/react";
import React, { CSSProperties, MouseEvent, ReactNode, RefObject, createRef } from "react";

import { hitEnterOrSpace } from "src/files/utils";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import SocialComposerTextInput, {
    ISocialComposerTextInputProps
} from "./SocialComposerTextInput";
import SocialComposerType, {
    ISocialComposerTypeProps as BaseProps,
    IComposerSaveOptions
} from "./SocialComposerType";

import { UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ComposerContext } from "./ComposerContext";
import "./SocialComposerFrame.scss";
import SocialComposerHeader from "./SocialComposerHeader";

interface IColor {
    from: string;
    to: string;
}

const defaultColors: IColor[] = [
    { from: '#239DCC', to: '#582F8C' },
    { from: '#45C8CE', to: '#BE1AA7' },
    { from: '#D29A7B', to: '#C60047' },
    { from: '#1D8C07', to: '#E0BC31' },
    { from: '#AEE46A', to: '#2523B4' },
    { from: '#C2E38C', to: '#328E08' },
    { from: '#3B26B0', to: '#B755D2' },

    { from: '#F5A623', to: '#F5A623' },
    { from: '#D0021B', to: '#D0021B' },
    { from: '#4A4A4A', to: '#4A4A4A' },
    { from: '#4A90E2', to: '#4090E2' },
    { from: '#417505', to: '#417505' },
    { from: '#008B95', to: '#008B95' },
    { from: '#50E3C2', to: '#50E3C2' }
];

const defaultFonts: string[] = [
    "\"Roboto\", sans-serif",
    "\"Merriweather\", serif",
    "\"Indie Flower\", cursive",
    "\"Pacifico\", cursive",
]

enum Popdown {
    None = 0,
    Emoji = 1,
    Color = 2,
    Font = 3
}

type Props = BaseProps & ISocialComposerTextInputProps;

interface IState {
    color: IColor;
    font: number;
    popdown: Popdown;
}

class SocialComposerFrame extends SocialComposerType<Props, IState> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;
    
    protected inputRef: RefObject<SocialComposerTextInput>;

    constructor(props: Props) {
        super(props);

        this.state = {
            color: defaultColors[0],
            font: 1,
            popdown: Popdown.None,
        };

        this.inputRef = createRef();

        this.onColorClick = this.onColorClick.bind(this);
        this.onFontClick = this.onFontClick.bind(this);
        this.onOptionClick = this.onOptionClick.bind(this);
        this.onStageClick = this.onStageClick.bind(this);
        this.closePopdown = this.closePopdown.bind(this);
        this.onDismissBackgroundClick = this.onDismissBackgroundClick.bind(this);
    }

    public componentDidMount(): void {
        if (!this.inputRef.current) {
            return;
        }

        setTimeout(() => {
            // setTimeout to prevent bug when pressing :
            this.inputRef.current.focus();
        }, 0)
    }

    public async saveContent(options: IComposerSaveOptions): Promise<Spintr.ISocialPostBase> {
        const { color, font } = this.state;

        const model = {
            background: `GRADTB${color.from}${color.to}`,
            feedId: options.feedId,
            flag: options.flag,
            font,
            text: options.text,
        };

        const formData = new FormData();
        formData.append("jsonString", JSON.stringify(model));

        try {
            const response = await api.post<Spintr.ISocialStatus>(
                "/api/v1/status", 
                formData, {
                    headers: {
                        ...(options.identity?.id ? {"x-impersonate-user": options.identity?.id} : {})
                    }
                }
            );
            
            return response.data;
        } catch (err) {
            console.log(err);

            return null;
        }
    }

    public render(): ReactNode {
        return (
            <div className="SocialComposerFrame selector">
                {this.renderTypeContent()}
                {this.renderPopdown()}
            </div>
        );
    }

    protected closePopdown(): void {
        this.setState(
            { popdown: Popdown.None }, 
            () => this.context.setSize("default"),
        );
    }

    protected renderTextInput(): React.ReactNode {
        return null;
    }

    protected renderTypeContent(): ReactNode {
        const inputProps: ISocialComposerTextInputProps = this.props;
        const { color, font } = this.state;

        const style: CSSProperties = {
            background: `linear-gradient(${color.from}, ${color.to})`,
            fontFamily: defaultFonts[font - 1],
            fontWeight: 600
        };
        
        const iconSize: string = "25px";

        return (
            <div className={
                "SocialComposerFrame" + (this.state.popdown !== Popdown.None
                    ? " popdown-open"
                    : "")
            }>
                <div className="stage-wrapper">
                    <div 
                        className="stage ignore-custom-font"
                        onClick={this.onStageClick}
                        style={style}
                    >
                        <SocialComposerTextInput
                            {...inputProps}
                            ref={this.inputRef}
                            isFrame={true}
                        />
                    </div>
                    <div className="options">
                        <ul className="list">
                            <li className="item">
                                <a
                                    tabIndex={0}
                                    onKeyUp={(e) => {hitEnterOrSpace(e, this.onOptionClick)}}
                                    className="option color"
                                    data-option={Popdown.Color}
                                    onClick={() => {
                                        this.setState({
                                            popdown: this.state.popdown === Popdown.Color
                                                ? Popdown.None
                                                : Popdown.Color,
                                        }, () => this.context.setSize("small"));
                                    }}
                                >
                                    <Visage2Icon icon="colorfilter" />
                                </a>
                            </li>
                            <li className="item">
                                <a
                                    tabIndex={0}
                                    onKeyUp={(e) => {hitEnterOrSpace(e, this.onOptionClick)}}
                                    className="option font"
                                    data-option={Popdown.Font}
                                    onClick={() => {
                                        this.setState({
                                            popdown: this.state.popdown === Popdown.Font
                                                ? Popdown.None
                                                : Popdown.Font,
                                        }, () => this.context.setSize("small"));
                                    }}
                                >
                                    <Visage2Icon icon="text" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <UnstyledButton
                        className="remove-button"
                        onClick={this.onDismissBackgroundClick}
                    >
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                    {this.renderPopdown()}
                </div>
            </div>
        )
    }

    protected onColorClick(event: MouseEvent<HTMLAnchorElement>): void {
        const { dataset } = event.target as HTMLAnchorElement;

        const index = parseInt(dataset.colorIndex, 10);
        if (isNaN(index)) {
            return;
        }

        this.setState({
            color: defaultColors[index],
            popdown: Popdown.None,
        }, () => this.context.setSize("default"));
    }

    protected onFontClick(event: MouseEvent<HTMLAnchorElement>): void {
        const { dataset } = event.target as HTMLAnchorElement;

        console.log(dataset.font);
        const font = parseInt(dataset.font, 10);
        if (isNaN(font)) {
            return;
        }

        this.setState({
            font,
            popdown: Popdown.None,
        }, () => this.context.setSize("default"));
    }

    protected onOptionClick(event: MouseEvent<HTMLAnchorElement>): void {
        console.log("JA");
        const { dataset } = event.target as HTMLAnchorElement;

        const optVal = parseInt(dataset.option, 10);
        console.log(dataset.option);
        console.log(optVal);
        if (isNaN(optVal)) {
            return;
        }
        console.log("JA 2");

        const popdown: Popdown = optVal;

        this.setState({
            popdown: this.state.popdown === popdown
                ? Popdown.None
                : popdown,
        }, () => this.context.setSize("small"));
    }

    protected onStageClick(): void {
        if (!this.inputRef.current) {
            return;
        }

        this.inputRef.current.focus();
    }

    protected onDismissBackgroundClick(): void {
        this.context.setSize("default");
        this.context.setType("text");
    }

    protected renderPopdown(): ReactNode {
        const { color, popdown } = this.state;
        const popdownName = Popdown[popdown].toLowerCase();

        const text = (
            this.props.editorState.getCurrentContent().getPlainText() ||
            localize("COMPOSER_PLACEHOLDER")
        );

        return (
            <div className={"popdown " + popdownName}>
                {popdown === Popdown.Color && (
                    <div className="colors">
                        <ul className="list">
                            {defaultColors.map((c, i) => {
                                const css: CSSProperties = {
                                    background: `linear-gradient(${c.from}, ${c.to})`
                                };

                                return (
                                    <li
                                        className={[
                                            "item",
                                            color === c ? "active" : "",
                                        ].join(" ")}
                                        key={`${c.from}${c.to}`}
                                    >
                                        <a
                                            className="color-wrapper"
                                            data-color-index={i}
                                            onClick={this.onColorClick}
                                            tabIndex={0}
                                            onKeyUp={(e) => {hitEnterOrSpace(e, this.onColorClick)}}
                                        >
                                            <div
                                                className="color"
                                                style={css}
                                            />
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {popdown === Popdown.Font && (
                    <div className="fonts">
                        <ul className="list">
                            {defaultFonts.map((font, i) => {
                                const css: CSSProperties = {
                                    fontFamily: font
                                };

                                return (
                                    <li
                                        className="item"
                                        key={font}
                                    >
                                        <Text
                                            tabIndex={0}
                                            onKeyUp={(e) => {hitEnterOrSpace(e, this.onFontClick)}}
                                            as="a"
                                            className="sample ignore-custom-font"
                                            style={css}
                                            variant="xLarge"
                                            data-font={i+1}
                                            onClick={this.onFontClick}
                                        >
                                            {text}
                                        </Text>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        )
    }
}

export default SocialComposerFrame;