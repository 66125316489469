/**
 * Determines how search results should be sorted.
 */
export enum SearchSorting {
    Relevance = 0,
    Earliest = 1,
    Latest = 2
}

/**
 * Used by the front end to determine which time span to search through
 */
export enum SearchTimeSpan {
    AllTime = "all",
    Today = "day",
    LastWeek = "week",
    LastMonth = "month",
    LastYear = "year"
}

export enum SearchFileType {
    Everything = 0,
    Audio = 2,
    Images = 3,
    Sheets = 4,
    Documents = 5,
    Presentations = 6,
    Archives = 7
}

/**
 * Contains information about a search term
 */
export interface ISearchFeedback {
    /**
     * The ID of the content object, if any, that the user clicked
     */
    objectId?: number;

    /**
     * The phrase or text the user searched for
     */
    query: string;

    /**
     * Total number of hits as reported by the search engine
     */
    totalHits: number;
}