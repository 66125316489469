import React, { Component } from "react";
import "./AnonTextInput.scss";

interface IProps {
    size?: spintrLabelSizes;
    value: string;
    placeholder?: string;
    onClick?: any;
    onChange?: any;
    required?: boolean;
    ariaLabel?: string;
}

class AnonTextInput extends Component<IProps> {
    render() {
        return (
            <input
                aria-label={this.props.ariaLabel}
                required={this.props.required}
                aria-required={true}
                value={this.props.value ? this.props.value : ""}
                onChange={(e) => {
                    this.props.onChange(e.target.value);
                }}
                placeholder={this.props.placeholder}
                className={`textInput textInput-anon fs-${this.props.size}`}
                type="text"
            />
        );
    }
}

export default AnonTextInput;
