import React, { useState } from "react";
import { IAcademyCourse } from "src/academy/types";
import "./AcademyCourseSidebarParticipants.scss";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import { UsersListPopup } from "src/users/views";

interface IProps {
    course: IAcademyCourse;
}

interface IState {
    showPopup: boolean;
}

const AcademyCourseSidebarParticipants = (props: IProps) => {
    const [state, setState] = useState<IState>({
        showPopup: false
    });

    return (
        <div className="AcademyCourseSidebarParticipants">
            {props.course.users.items.map((user: Spintr.IUser) => {
                return (
                    <div>
                        <SpintrUser
                            key={user.id}
                            id={user.id}
                            imageUrl={user.image}
                            name={user.name}
                            subText={user.departmentName}
                            personalName />
                    </div>
                )
            })}
            {props.course.users.totalCount > 5 && (
                <UnstyledButton onClick={() => {
                    setState((s: IState) => {
                        return {
                            ...s,
                            showPopup: true
                        }
                    });
                }}>
                    <Label size="body-2" color="light-blue" weight="medium">
                        {localize("VisaAlla") + " (" + props.course.users.totalCount + ")"}
                    </Label>
                </UnstyledButton>
            )}
            {state.showPopup && (
                <UsersListPopup
                    users={props.course.users.items}
                    title={localize("COWORKERS_WHO_HAVE_COMPLETED_THIS_COURSE")}
                    popupIsHidden={false}
                    closePopup={() => {
                        setState((s: IState) => {
                            return {
                                ...s,
                                showPopup: false
                            }
                        });
                    }} />
            )}
        </div>
    )
}

export default AcademyCourseSidebarParticipants;
