import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { WikiListState, WikiListStateProps } from "./WikiList.types";
import { ColumnActionsMode, DirectionalHint, IColumn, TooltipHost } from "@fluentui/react";
import { localize } from "src/l10n";
import { CancelToken } from "axios";
import api, { queryWikis } from "src/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DataList, DataListProps } from "../DataList";
import { ListContentCard } from "../ListContentCard";
import { Link } from "react-router-dom";
import moment from "moment";
import { listActionMenuWidth } from "src/ui/helpers/style";
import { setConfirmPopup } from "src/popups/actions";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import { useRealtime } from "src/hooks";
import { SpintrTypes } from "src/typings";
import SpintrList from "src/ui/components/SpintrList/SpintrList";

function WikiList(): ReactElement {
    const [state, setState] = useState<WikiListState>({
        data: [],
        sortedAscending: true,
        sortedBy: "name",
        totalCount: 0,
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const { subscribe, unsubscribe } = useRealtime();
    const listRef = useRef<SpintrList>(null);

    const {
        canCreateWikis,
        hasElevatedPermissions,
        currentUserId,
    } = useSelector<Spintr.AppState, WikiListStateProps>(
        (appState) => ({
            hasElevatedPermissions:
                appState.profile.active.isAdmin
             || appState.profile.active.isEditor,
            canCreateWikis:
                !(appState.instance.get("restrictWikis") as boolean)
                || appState.profile.active.isAdmin
                || appState.profile.active.isEditor,
            currentUserId: appState.profile.active.id,
        })
    );

    useEffect(() => {
        subscribe("Object:Favorite", onFavoriteToggled);
        subscribe("Object:Follow", onFollowToggled);

        return () => {
            unsubscribe("Object:Favorite", onFavoriteToggled);
            unsubscribe("Object:Follow", onFollowToggled);
        };
        
    }, [subscribe, unsubscribe]);

    const onFavoriteToggled = (msg) => {
        if (msg.objectType !== SpintrTypes.UberType.Wiki &&
            msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            data: prevState.data.map(
                (wiki) => wiki.id !== msg.id
                    ? wiki
                    : {
                        ...wiki,
                        favourited: msg.value,
                    },
            )
        }));
    }

    const onFollowToggled = (msg) => {
        if (msg.objectType !== SpintrTypes.UberType.Wiki &&
            msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            data: prevState.data.map(
                (wiki) => wiki.id !== msg.id
                    ? wiki
                    : {
                        ...wiki,
                        followed: msg.value,
                    },
            )
        }));
    }

    const getStandardActionMenuProps = (item: Spintr.IWiki) => {
        return {
            canAddToFavourites: item.uberType !== SpintrTypes.UberType.WikiSection,
            canFollow: item.uberType !== SpintrTypes.UberType.WikiSection,
            canEdit: hasElevatedPermissions || item.editor?.id === currentUserId,
            canDelete: hasElevatedPermissions || item.editor?.id === currentUserId,
            onEditClick: () =>
                history.push(
                    item.uberType === SpintrTypes.UberType.Wiki
                        ? `/wikis/edit-wiki/${item.id}`
                        : item.uberType === SpintrTypes.UberType.WikiArticle
                        ? `/wikis/edit-article/${item.id}`
                        : `/wikis/edit-section/${item.id}`
                ),
            onDeleteClick: () => {
                dispatch(setConfirmPopup({
                    isOpen: true,
                    title: localize("RaderaInnehall"),
                    message: item.uberType === SpintrTypes.UberType.Wiki ?
                        localize("ArDuSakerPaAttDuVillTaBortDennaWiki") + "?" :
                        localize("VillDuVerkligenTaBortX").replace("{{X}}", item.name),
                    onConfirm: () => {
                        if (item.uberType === SpintrTypes.UberType.Wiki) {
                            api.put(`/api/v1/wikis/${item.id}/toggledelete`).then(() => {
                                listRef.current.reFetch();
                            });
                        } else if (item.uberType === SpintrTypes.UberType.WikiArticle) {
                            api.put(`/api/v1/wikis/article/toggledelete/${item.id}`).then(() => {
                                listRef.current.reFetch();
                            });
                        } else {
                            api.delete(`/api/v1/wikis/delete/section?id=${item.id}`).then(() => {
                                listRef.current.reFetch();
                            })
                        }
                    }
                }));
            },
            isFollowing: item.followed,
            isFavourite: item.favourited,
            pinInsteadOfFavourite: true,
            objectId: item.id,
        }
    }

    const onRenderCard = useCallback((item: Spintr.IWiki) => {
        return (
            <ListContentCard
                description={item.description}
                imageUrl={item.imageUrl}
                key={item.id}
                standardActionMenuProps={getStandardActionMenuProps(item)}
                tags={item.tags}
                title={item.name}
                url={item.url} />
        );
    }, [currentUserId, hasElevatedPermissions, history]);
    
    const columns = useMemo<IColumn[]>(() => [{
        key: "name",
        name: localize("Namn"),
        fieldName: "name",
        minWidth: 0,
        onRender: (item: Spintr.IWiki) => <Link to={item.url}>{item.name}</Link>,
    }, {
        key: "tags",
        name: localize("Taggar"),
        fieldName: "tags",
        onRender: (item: Spintr.IWiki) => {
            const allTags = item.tags.map((tag) => tag.text).join(", ");

            return (
                 <TooltipHost content={allTags} directionalHint={DirectionalHint.topAutoEdge}>
                        {allTags}
                 </TooltipHost>
            );
        },
        minWidth: 150,
        columnActionsMode: ColumnActionsMode.disabled,
    }, {
        key: "latestChange",
        name: localize("SenastAndrad"),
        fieldName: "latestChange",
        onRender: (item: Spintr.IWiki) => <>{moment(item.latestchange).format("LLL")}</>,
        minWidth: 150,
    }, {
        key: "actionMenu",
        name: "",
        minWidth: listActionMenuWidth,
        maxWidth: listActionMenuWidth,
        onRender: (item: Spintr.IWiki) => (
            <StandardActionMenu
                {...getStandardActionMenuProps(item)}
            />
        ),
    }], [onRenderCard]);

    const createButton = useMemo<DataListProps["createButton"]>(
        () => !canCreateWikis ? undefined : ({
            onClick: () => history.push("/wikis/create")
        }),
        [canCreateWikis, history],
    );

    const fetch = useCallback(async (
        offset:         number,
        limit:          number,
        orderBy:        string,
        ascending:      boolean,
        searchText:     string,
        cancelToken:    CancelToken,
        fetchMore:      boolean
    ) => {
        const orderByColumn = orderBy !== "name" && orderBy !== "latestChange"
            ? "name"
            : orderBy;

        if (fetchMore && state.data.length >= state.totalCount) {
            return { total: state.totalCount };
        }

        try {
            const result = await queryWikis(
                offset,
                limit,
                orderByColumn,
                ascending,
                searchText,
                false,
                cancelToken
            );

            const totalCount = fetchMore && result.items.length === 0
                ? state.data.length
                : result.totalCount;

            setState((prevState) => ({
                ...prevState,
                data: fetchMore
                    ? [
                        ...prevState.data,
                        ...result.items.filter((item) => prevState.data.every((prevItem) => prevItem.id !== item.id)),
                    ]
                    : result.items,
                totalCount,
            }));
            
            return { total: totalCount };
        } catch (err) {
            console.error(err);

            return {
                data: [],
                total: 0,
            };
        }
    }, [setState, state.totalCount, state.data.length]);

    const onSortChanged = useCallback(
        (orderBy: string, ascending: boolean) => setState((prevState) => {
            if (orderBy !== "name" && orderBy !== "latestChange") {
                return prevState;
            }

            return {
                ...prevState,
                sortedAscending: ascending,
                sortedBy: orderBy,
            };
        }),
        [setState],
    );

    const onShimmerCardRender = useCallback((index: number) => (
        <ListContentCard
            key={`shimmer-${index}`}
            shimmer={true}
            title="" />
    ), []);

    return (
        <div className="WikiList">
            <DataList
                canChangeListType={true}
                createButton={createButton}
                columns={columns}
                data={state}
                fetch={fetch}
                infiniteScroll={true}
                initialListType="cards"
                isDescending={!state.sortedAscending}
                onSortChanged={onSortChanged}
                orderByColumn={state.sortedBy}
                renderCard={onRenderCard}
                renderShimmerCard={onShimmerCardRender}
                take={12}
                ref={listRef}
            />
        </div>
    );
}

export default WikiList;    
