import React from 'react';
import './VisageSidebarLines.scss';
import { ReactSVG } from 'react-svg';

interface IProps {
    items: any[];
}

const itemHeight = 34;

const getChildrenHeight = (item: any) => {
    if (!item.isExpanded) {
        return 0;
    }

    let height = 0;

    for (let child of (item.children || [])) {
        height += itemHeight;

        if (child.isExpanded) {
            height += getChildrenHeight(child);
        }
    }

    return height;
}

const getTotalHeight = (item: any) => {
    let height = itemHeight;

    if (item.isExpanded) {
        height += getChildrenHeight(item);
    }

    return height;
}

const VisageSidebarLines = (props: IProps) => {
    return null;
    if (!props.items || props.items.length === 0) {
        return null;
    }

    return (
        <div className="VisageSidebarLines">
            {
                props.items.map((item: any, index: number) => {
                    const classNames = ["VisageSidebarLine"];

                    const isFirst = index === 0;
                    const isLast = index === (props.items.length - 1);

                    if (isFirst) {
                        classNames.push("isFirst")
                    }

                    if (isLast) {
                        classNames.push("isLast")
                    }

                    const height = getTotalHeight(item);

                    return (
                        <div key={index} className={classNames.join(" ")} style={{
                            height
                        }}>
                            <div className="VisageSidebarLine-left" />
                            <div className="VisageSidebarLine-circle" />
                            {/* <div className="VisageSidebarLine-bottom" /> */}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default VisageSidebarLines;