import React, { Component, createRef, RefObject } from 'react';
import { localize } from 'src/l10n';
import { Icon, Label, SpintrCoachmark } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import "./FooterCreateButton.scss";

interface IProps { isDisplayingWidthTypeSelector?: boolean }

interface IState { }

class FooterCreateButton extends Component<IProps, IState> {
    protected buttonRef: RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.state = {
            isCoachmarkVisible: false
        }

        this.buttonRef = createRef();
    }


    render() {
        return (
            <div className="FooterCreateButton" style={{
                textAlign: "center",
                position: "relative",
                height: "50px"
            }}>
                <div style={{
                    borderBottomWidth: 1,
                    borderBottomColor: "#787CDD",
                    borderBottomStyle: "solid",
                    top: "calc(50% - 1px)",
                    position: "absolute",
                    width: "100%",
                    boxSizing: "border-box"
                }}></div>
                <div style={{
                    display: "inline-block",
                    position: "relative",
                    transform: "translateY(-50%)",
                    msTransform: "translateY(-50%)",
                    top: "50%"
                }}>
                    <div ref={this.buttonRef} className="round-button" style={{
                        padding: "8px 14px 8px 18px",
                        backgroundColor: this.props.isDisplayingWidthTypeSelector ? "#787CDD" : "#FFF",
                        borderWidth: 1,
                        borderColor: "#787CDD",
                        borderStyle: "solid",
                        display: "flex",
                        borderRadius: "30px",
                        alignItems: "center",
                    }}>
                        <div style={{ marginRight: "4px", color: !this.props.isDisplayingWidthTypeSelector ? "#787CDD" : "#FFF" }}>
                            <Label weight='medium' size="h6">
                                {
                                    localize("NySektion")
                                }
                            </Label>
                        </div>
                        <Visage2Icon icon="add" hexColor={!this.props.isDisplayingWidthTypeSelector ? "#787CDD" : "#FFF"} />
                    </div>
                </div>
                <SpintrCoachmark
                    refDiv={this.buttonRef}
                    text={localize("PAGE_BUILDER_PLUS_HELP")}
                    coachmarkId={"PAGE_BUILDER_PLUS_HELP"} />
            </div>
        )
    }
}

export default FooterCreateButton
