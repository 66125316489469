import classnames from "classnames";
import * as React from "react";
import "./UnstyledButton.scss";

export interface IProps<T> {
    onClick?: (data?: T) => void;
    onClickData?: T;
    className?: string;
    title?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    inline?: boolean;
    ariaLabel?: string;
    'data-cy'?: string;
    id?: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
}

class UnstyledButton<T = any> extends React.Component<IProps<T>> {
    public render() {
        const { className, title, children, style } = this.props;

        var classNames = classnames(
            classnames(
                "unstyled-button",
                {
                    disabled: this.props.disabled,
                    inline: this.props.inline,
                },
                className
            )
        );

        return this.props.disabled ? (
            <div
                id={this.props.id}
                aria-label={this.props.ariaLabel}
                className={classNames}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                role="button"
                tabIndex={0}
                title={title}
                style={style}
                data-cy={this.props["data-cy"]}
            >
                {children}
            </div>
        ) : (
            <div
                id={this.props.id}
                data-cy={this.props["data-cy"]}
                aria-label={this.props.ariaLabel || title}
                className={classNames}
                onClick={(e) => this.onClick(e)}
                onKeyDown={this.onKeyDown}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                role="button"
                tabIndex={0}
                title={title}
                style={style}
            >
                {children}
            </div>
        );
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if ([13, 32].some((k) => e.keyCode === k)) {
            this.onClick(e);
        }
    };

    private onClick = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        const { props } = this;

        if (props.disabled) {
            return;
        }

        if (props.onClick) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            
            this.props.onClick(props.onClickData);
        }
    };
}

export default UnstyledButton;
