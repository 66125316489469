import * as React from "react";
import "./Paginator.scss";

interface IProps {
    // activePage: number;
    totalCount: number;
    activePage: number;
    pageSize: number;
    onPageChanged: any;
}

interface IState {
    currentPage: number;
}

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

class Paginator extends React.Component<IProps, IState> {
    private readonly rootClassname = "paginator-component";
    private pageSize: number;
    private totalCount: number;
    private pageNeighbours: number;
    private totalPages: number;

    constructor(props) {
        super(props);
        const { totalCount = null, pageSize, pageNeighbours = 0 } = props;

        this.pageSize = pageSize
        this.totalCount = totalCount
    
        // pageNeighbours can be: 0, 1 or 2
        this.pageNeighbours = typeof pageNeighbours === 'number'
          ? Math.max(0, Math.min(pageNeighbours, 2))
          : 0;
    
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    
        this.state = { currentPage: 1 };
    }

    private range = (from, to, step = 1) => {
        let i = from;
        const range = [];
      
        while (i <= to) {
          range.push(i);
          i += step;
        }
      
        return range;
    }

    private fetchPageNumbers = () => {
        const { activePage } = this.props;
        const totalPages = this.totalPages;
        const pageNeighbours = 1;
    
        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;
    
        if (totalPages > totalBlocks) {
          const startPage = Math.max(2, activePage - pageNeighbours);
          const endPage = Math.min(totalPages - 1, activePage + pageNeighbours);
    
          let pages = this.range(startPage, endPage);
    
          return [1, ...pages, totalPages];
    
        }
    
        return this.range(1, totalPages);
    
      }

    private gotoPage = page => {
        const { onPageChanged = f => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.totalPages));
   
        const paginationData = {
          currentPage,
          totalPages: this.totalPages,
          pageSize: this.pageSize,
          totalCount: this.totalCount
        };
    
        this.setState({ currentPage }, () => onPageChanged(paginationData));
    }
    
    private handleClick = page => evt => {
        evt.preventDefault();
        this.gotoPage(page);
    }
    
    private handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
    }
    
    private handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + (this.pageNeighbours * 2) + 1);
    }
      
    public render() {
        const { rootClassname } = this;
        const { activePage } = this.props;
        const pages = this.fetchPageNumbers();

        return (
            <div className={rootClassname}>
                <>
                    <nav aria-label="Pagination">
                        <ul className="pagination">
                            { pages.map((page, index) => {

                            if (page === LEFT_PAGE) return (
                                <li key={index} className="page-item">
                                <a className="page-link" href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                </li>
                            );

                            if (page === RIGHT_PAGE) return (
                                <li key={index} className="page-item">
                                <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </a>
                                </li>
                            );

                            return (
                                <li key={index} className={`page-item${ activePage === page ? ' active' : ''}`}>
                                <a className="page-link" href="#" onClick={ this.handleClick(page) }>{ page }</a>
                                </li>
                            );

                            }) }
                        </ul>
                    </nav>
                </>
                
            </div>
            
        );
    }
}

export default Paginator;