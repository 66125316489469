import { Modal } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import PopupHeader from "src/ui/components/PopupHeader";
import { MarketplaceWidget } from "../MarketplaceWidget";

interface IProps {
    configurationId: string;
    configurationName: string;

    isShowing: boolean;

    onDismiss?: () => void;
}

const PlacementHelpPopup: FunctionComponent<IProps> = (props) => {
    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            isOpen={props.isShowing}
            onDismiss={props.onDismiss}
        >
            <PopupHeader
                text={localize("MARKETPLACE_PLACE_CONFIGURATION")}
                onClose={props.onDismiss} />
            {props.configurationId && (
                <div className="PlacementHelpPopup popup-inner">
                    <div className="box-preview">
                        <Label
                            as="div"
                            className="heading"
                            size="h2"
                        >
                            {props.configurationName}
                        </Label>
                        <MarketplaceWidget
                            id={props.configurationId}
                            displayBorder
                        />
                    </div>
                    <div className="teaser-box-help help-section">
                        <Label
                            as="div"
                            className="heading"
                            size="h3"
                        >
                            {localize("Startsidan")}
                        </Label>
                        <Label
                            as="div"
                            className="normal-text"
                            size="body-1"
                        >
                            {localize("MARKETPLACE_PLACE_TEASERBOX")}
                        </Label>
                        <Link
                            className="button primaryBGColor"
                            role="button"
                            to={"/admin/teaserbox/create?dataWidget=" + props.configurationId}
                        >
                            <Label
                                as="span"
                                size="h6"
                            >
                                {localize("MARKETPLACE_NEW_TEASERBOX")}
                            </Label>
                        </Link>
                    </div>
                    <div className="text-page-help help-section">
                        <Label
                            as="div"
                            className="heading"
                            size="h3"
                        >
                            {localize("Textsida")}
                        </Label>
                        <Label
                            as="div"
                            className="normal-text"
                            size="body-1"
                        >
                            {localize("MARKETPLACE_PLACE_TEXTPAGE")}
                        </Label>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default PlacementHelpPopup;
