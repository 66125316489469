import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { IMessage, MessageRole } from "src/assistant/types";
import { Label } from "src/ui";

interface IProps {
    message: IMessage;
}

const citationPattern = /(?:\s?\[doc([0-9]+)\])/gmi;

const AssistantMessageText: FunctionComponent<IProps> = ({ message }) => {
    const messageText = useMemo(
        () => message.role === MessageRole.User
            ? message.text
            : message.text
                .replace("[doc0]", "")
                .replace(citationPattern, '<span class="reference"><span>$1</span></span>'),
        [message.role, message.text],
    );

    const [text, setText] = useState("");

    useEffect(() => {
        if (message.role === MessageRole.User) {
            return setText(messageText);
        }

        //@ts-ignore
        const isRecent = (new Date() - message.date) < 5000;

        if (!isRecent) {
            return setText(messageText);
        }

        const words = messageText.split(" ");

        for (let i = 0; i < words.length; i++) {
            setTimeout(() => {
                setText(t => (i === (words.length - 1) ? messageText : (t + " " + words[i])));
            }, i * 100);
        }
    }, []);

    return (
        <div>
            <Label
                className="message-text message-text-fake"
                size="body-3"
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: messageText,
                    }}
                />
            </Label>
            <Label
                className="message-text"
                size="body-3"
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            </Label>
        </div>
    );
}

export default AssistantMessageText;
