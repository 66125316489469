import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import "./Chat.scss";
import { MapDispatchToProps, MapStateToProps, connect, useDispatch } from 'react-redux';
import Composer from '../Composer/Composer';
import Messages from '../Messages/Messages';
import { GetConversationHandler, getConversation, queryPinnedMessages } from 'src/chat/redux';
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import PinnedMessage from '../PinnedMessage/PinnedMessage';

interface IDispatchProps {
    getConversation: GetConversationHandler;
}

interface IOwnProps {
    conversationId: number;
    scrollToMessageId?: number;
    compact?: boolean;
    blocked?: boolean;
    blockedBy?: boolean;
    onMessageSent?: (message: Spintr.IChatMessage) => void;
}

interface IStateProps {
    conversation: Spintr.IConversation;
    pinnedMessages: Spintr.IChatMessage[];
}

type Props = IDispatchProps & IOwnProps & IStateProps;

const Chat = (props: Props) => {
    const messagesRef = useRef<any>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        props.getConversation(props.conversationId);

        if ((props.pinnedMessages || []).length === 0) {
            dispatch(queryPinnedMessages({
                conversationId: props.conversationId
            }));
        }
    }, []);

    const classes : string = useMemo(() => {
        const result = ["Chat"];

        if (props.compact) {
            result.push("compact");
        }

        return result.join(" ");
    }, []);

    return (
        <div className={classes}>
            {(props.pinnedMessages || []).map((m: Spintr.IChatMessage) => {
                return (
                    <PinnedMessage
                        key={m.id}
                        message={m}
                        onClick={() => {
                            messagesRef?.current?.scrollTo(m.id);
                        }}
                    />
                )
            })}
            <Messages
                innerRef={messagesRef}
                conversationId={props.conversationId}
                scrollToMessageId={props.scrollToMessageId}
                compact={props.compact} />
            <Composer
                conversationId={props.conversationId}
                conversation={props.conversation}
                compact={props.compact}
                blocked={props.blocked}
                blockedBy={props.blockedBy}
                onMessageSent={props.onMessageSent} />
        </div>
    )
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, props): IStateProps => ({
        conversation: state.chat.conversations.items.find(c => c.id === props.conversationId),
        pinnedMessages: state.chat.conversations.pinnedMessages[props.conversationId]
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    getConversation
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);