import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { IStackProps, Stack } from '@fluentui/react/lib/Stack';
import React, { Component } from 'react';
import './Loader.scss';

interface IProps {
    loaderSize?: loaderSize
}

class SpintrLoader extends Component<IProps> {
    public static defaultProps : IProps = {
        loaderSize: "normal"
    };

    render() {
        const rowProps: IStackProps = { horizontal: true, verticalAlign: 'center' };

        const tokens = {
            spinnerStack: {
                childrenGap: 20
            }
        };

        let size = this.props.loaderSize === "small" ?
            SpinnerSize.xSmall :
            SpinnerSize.large;

        return (
            <div className="SpintrLoader">
                <Stack {...rowProps} tokens={tokens.spinnerStack}>
                    <Spinner size={size} />
                </Stack>
            </div>
        )
    }
}

export default SpintrLoader;
