import { Text, TextField } from "@fluentui/react";
import React, { ChangeEvent, ReactNode } from "react";
import { localize } from "src/l10n";
import { AuthenticationDataComponent } from "../AuthenticationDataComponent";

class CustomHeaderInput extends AuthenticationDataComponent<any, any> {
    private instructions: string = localize("CUSTOM_HEADER_INSTRUCTIONS");

    constructor(props: any) {
        super(props);

        this.state = {
            data: {
                customHeaderValue: ""
            },
            headerName: ""
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    public componentDidMount(): void {
        const data: any = JSON.parse(this.props.authenticationData);
        
        this.setState({
            headerName: data.customHeaderName,
            instructions: data.instructions,
        });
    }

    public getAuthenticationData(): string {
        return JSON.stringify(this.state.data);
    }    
    
    public isValid(): boolean {
        return this.state.data.customHeaderValue.length > 0;
    }

    public onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({
            data: {
                customHeaderValue: event.target.value.trim()
            }
        }, () => {
            this.isValid();

            if (typeof this.props.onChange === "function") {
                this.props.onChange();
            }
        });
    }
    
    public render(): ReactNode {
        const instructions = this.state.instructions || this.instructions;

        return (
            <div className="CustomHeaderInput">
                <p
                    className="instructions"
                    dangerouslySetInnerHTML={{
                        __html: instructions
                    }}
                />
                <TextField
                    label={this.state.headerName}
                    name="customHeaderValue"
                    onChange={this.onValueChanged}
                    value={this.state.data.customHeaderValue}
                />
            </div>
        );
    }
}

export default CustomHeaderInput;