import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { clearInteractions } from 'src/interactions/actions';
import { localize } from 'src/l10n';
import { clearSpotlight } from 'src/news/components/Spotlight/actions';
import { SocialFeedType, clearFeed } from 'src/social-feed';
import { clearInformationFeed } from 'src/spintr/components/InformationFeed/actions';
import { IApplicationState } from 'src/spintr/reducer';
import { clearTeaserBoxes } from 'src/teaser-box/actions';
import { UnstyledButton, incrementStartViewKey } from 'src/ui';
import SpintrLoader from 'src/ui/components/Loader';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    isLoading?: boolean;
}

const StartViewRefetchButton = (props: IProps) => {
    const dispatch = useDispatch();
    const [isDone, setIsDone] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading && !props.isLoading) {
            setIsLoading(false);
            setIsDone(true);

            setTimeout(() => {
                setIsDone(false);
            }, 5000);
        }
    }, [props.isLoading]);

    const onReloadClick = useCallback(() => {
        setIsLoading(true);
        dispatch(clearFeed(SocialFeedType.Combined));
        dispatch(clearSpotlight());
        dispatch(clearTeaserBoxes());
        dispatch(clearInformationFeed());
        dispatch(clearInteractions());
        dispatch(incrementStartViewKey());
    }, []);

    if (isLoading) {
        return (
            <SpintrLoader loaderSize="small" />
        )
    }

    if (isDone) {
        return (
            <span title={localize("UP_TO_DATE")}>
                <Visage2Icon
                    className="icon-animation animation-pulse primaryIconColor"
                    icon="tick-circle"
                    size="small" />
            </span>
        )
    }

    return (
        <UnstyledButton title={localize("RELOAD")} onClick={onReloadClick}>
            <Visage2Icon icon="rotate-right" size="small" />
        </UnstyledButton>
    )
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        isLoading: state.socialFeed.combined.isLoading ||
            state.teaserboxes.loading ||
            state.spotlight.isLoading ||
            state.informationFeed.isLoading
    };
};

export default connect(mapStateToProps)(StartViewRefetchButton);
