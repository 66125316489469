import React, { ChangeEvent, MouseEvent, ReactElement, useCallback, useEffect, useState } from "react";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, SmallBody } from "src/components";
import { GroupTemplateDrawerProps, GroupTemplateDrawerState } from "./GroupTemplateDrawer.types";
import { UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { FormControl } from "src/ui/components/Forms";
import { PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { createGroupTemplateAsync, getCancelTokenSource, getGroupByIdAsync, GroupTemplateModel } from "src/api";
import { Conditional } from "../Conditional";
import SpintrLoader from "src/ui/components/Loader";

const initialState: GroupTemplateDrawerState = {
    isLoading: false,
    isSaving: false,
};

const initialModel: GroupTemplateModel = {
    groupId: 0,
    name: "",
    saveGroupChat: false,
    saveFiles: false,
    saveNotes: false,
    savePages: false,
    savePlanning: false,
} as const;

function GroupTemplateDrawer({
    groupId,
    onDismiss,
}: GroupTemplateDrawerProps): ReactElement {
    const [state, setState] = useState<GroupTemplateDrawerState>(initialState);
    const [model, setModel] = useState<GroupTemplateModel>(initialModel)

    const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setModel((prevState) => ({ ...prevState, name: value }));
    }, [setModel]);

    const onSavePlanningChanged = useCallback(
        (_: MouseEvent<HTMLElement>, checked: boolean) => setModel(
            (prevState) => ({ ...prevState, savePlanning: checked })
    ), [setModel]);

    const onSaveFilesChanged = useCallback(
        (_: MouseEvent<HTMLElement>, checked: boolean) => setModel(
            (prevState) => ({ ...prevState, saveFiles: checked })
    ), [setModel]);

    const onSaveNotesChanged = useCallback(
        (_: MouseEvent<HTMLElement>, checked: boolean) => setModel(
            (prevState) => ({ ...prevState, saveNotes: checked })
    ), [setModel]);

    const onSavePagesChanged = useCallback(
        (_: MouseEvent<HTMLElement>, checked: boolean) => setModel(
            (prevState) => ({ ...prevState, savePages: checked })
    ), [setModel]);

    const onSubmitting = useCallback(async () => {
        if (model.name.trim().length === 0) {
            return;
        }

        const isSavingAnything =
            model.saveFiles ||
            model.saveGroupChat ||
            model.saveNotes ||
            model.savePages ||
            model.savePlanning;

        if (!isSavingAnything) {
            return;
        }

        setState((prevState) => ({ ...prevState, isSaving: true }));

        try {
            await createGroupTemplateAsync(model);

            setState((prevState) => ({ ...prevState, isSaving: false }));
            onDismiss?.(true);
        } catch (error) {
            console.error(error);
            setState((prevState) => ({ ...prevState, isSaving: false }));
        }
    }, [model, setState])

    useEffect(() => {
        if (!groupId) {
            return;
        }

        setState((prevState) => ({ ...prevState, isLoading: true }));

        const cts = getCancelTokenSource();

        getGroupByIdAsync(groupId, { useElevatedRights: true }, cts.token)
            .then((group) => {
                setModel({
                    ...initialModel,
                    groupId: group.id,
                    name: `${group.name} (${localize("Mall")})`,
                });
                setState((prevState) => ({ ...prevState, group, isLoading: false }));
            })
            .catch((_) => onDismiss?.())

        return () => cts.cancel();
    }, [groupId]);

    return (
        <Drawer
            className="GroupTemplateDrawer"
            open={groupId !== undefined && groupId > 0}
            position="end"
            size="medium"
        >
            <DrawerHeader>
                <DrawerHeaderTitle action={
                    <UnstyledButton
                        className="close-button"
                        onClick={onDismiss}
                        title={localize("Stang")}
                    >
                        <Visage2Icon icon="add" size="big" />
                    </UnstyledButton>
                }>
                    {state.group?.name}
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody className="GroupTemplateDrawer-body">

                <Conditional condition={state.isSaving || state.isLoading}>
                    <SpintrLoader />
                </Conditional>

                <Conditional condition={!state.isSaving && !state.isLoading}>
                    <FormControl>
                        <TextField
                            aria-required={true}
                            className="textField"
                            label={localize("MallensNamn")}
                            name="name"
                            onChange={onNameChanged}
                            required={true}
                            validateOnFocusIn={true}
                            validateOnFocusOut={true}
                            value={model.name}
                            // errorMessage={errors.name}
                        />
                    </FormControl>

                    <FormControl label={localize("SparaFoljandeInnehallSomMallForGruppen")}>
                        <Toggle
                            checked={model.savePlanning}
                            inlineLabel={true}
                            label={localize("Planering")}
                            onChange={onSavePlanningChanged}
                        />

                        <Toggle
                            checked={model.saveNotes}
                            inlineLabel={true}
                            label={localize("Noteringar")}
                            onChange={onSaveNotesChanged}
                        />

                        <Toggle
                            checked={model.saveFiles}
                            inlineLabel={true}
                            label={localize("Mappar")}
                            onChange={onSaveFilesChanged}
                        />
                        <Toggle
                            checked={model.savePages}
                            inlineLabel={true}
                            label={localize("Textsidor")}
                            onChange={onSavePagesChanged}
                        />
                    </FormControl>
                </Conditional>

            </DrawerBody>
            <DrawerFooter className="GroupTemplateDrawer-footer">
                <div className="GroupTemplateDrawer-cancelAction">
                    <UnstyledButton
                        onClick={onDismiss}
                        disabled={state.isSaving || state.isLoading}
                    >
                        <SmallBody color="contentLight" weight="medium">
                            {localize("Avbryt")}
                        </SmallBody>
                    </UnstyledButton>
                </div>
                <div className="GroupTemplateDrawer-saveAction">
                    <PrimaryButton
                        className="GroupTemplateDrawer-button GroupTemplateDrawer-saveButton"
                        disabled={state.isSaving || state.isLoading}
                        onClick={onSubmitting}
                    >
                        {localize("Spara")}
                    </PrimaryButton>
                </div>
            </DrawerFooter>
        </Drawer>
    );
}

export default GroupTemplateDrawer;
