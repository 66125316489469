import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { localize } from "src/l10n";
import { Label, UnstyledButton } from "src/ui";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import { StartPageBuilderContext } from "../StartPageBuilderContext";
import { StartPageBuilderRow } from "../StartPageBuilderRow";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    rows: Spintr.IStartPageRow[];
}

const StartPageBuilderGrid: FunctionComponent<IProps> = ({ rows }) => {
    const [storedWidth, storeWidth] = useState<number>(0);

    const context = useContext(StartPageBuilderContext);
    const { addCell } = context;
    const wrapperRef = useRef<HTMLDivElement>();

    const currentWrapperRef = wrapperRef?.current;
    useEffect(
        () => {
            if (!currentWrapperRef) {
                return;
            }

            if (storedWidth === currentWrapperRef.clientWidth) {
                return;
            }

            storeWidth(currentWrapperRef.clientWidth);
            context.updateWidth(currentWrapperRef.clientWidth);
        },
        [context, currentWrapperRef, storedWidth, storeWidth],
    );

    const onAddClick = useCallback(
        ([componentId, before]) => {
            if (typeof componentId !== "string") {
                return;
            }

            addCell(componentId, before);
            mixpanelTrack(MixPanelEvents.Dynamo_AddRow);
        },
        [addCell],
    );

    return (
        <div className="StartPageBuilderGrid" ref={wrapperRef}>
            {rows.map((row) => (
                <StartPageBuilderRow
                    key={row.id}
                    row={row}
                />
            ))}
            <div className="button-container">
                <UnstyledButton
                    className="add-before-row"
                    title={localize("ADD_ROW")}
                    onClick={onAddClick}
                    onClickData={[
                        rows.length === 0
                            ? ""
                            : rows[rows.length - 1].id,
                        false,
                    ]}
                >
                    <Visage2Icon
                        color="dark-grey"
                        icon="add"
                        size="medium"
                    />
                    <Label
                        color="dark-grey"
                        size="body-3"
                        weight="medium"
                    >
                        {localize("ADD_NEW_ROW")}
                    </Label>
                </UnstyledButton>
            </div>
        </div>
    );
};

export default StartPageBuilderGrid;
