import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label, LocalizedString } from "src/ui";
import { capitalizeFirstLetter } from "src/utils";
import "./SocialWikiArticle.scss";

interface IProps {
    post: Spintr.ISocialWikiArticle;
}

const SocialWikiArticle: FunctionComponent<IProps> = (props) => (
    <div className="SocialWikiArticle">
        <Label
            as="p"
            color="mid-grey"
            size="body-2"
        >
            {props.post.wikiType === 0 && (
                <>
                    <span>
                        {capitalizeFirstLetter(localize("ladeTillArtikeln_small"))}
                    </span> <Link to={props.post.articleUrl}>
                        {props.post.articleName}
                    </Link> <LocalizedString text="iBloggen_small" 
                    /> <Link to={props.post.wikiUrl}>
                        {props.post.wikiName}
                    </Link>.
                </>
            )}
            {props.post.wikiType === 1 && (
                <>
                    <span>
                        {capitalizeFirstLetter(localize("SkapadeNoteringen"))}
                    </span> <Link to={props.post.articleUrl}>
                        {props.post.articleName}
                    </Link>.
                </>
            )}
        </Label>
    </div>
);

export default SocialWikiArticle;
