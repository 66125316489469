import React from "react";
import { localize } from "src/l10n";
import Label from "./Label/Label";

interface IProps {
    authorName: string;
    authorUrl: string;
}

const UnsplashAttribution = ({ authorName, authorUrl }: IProps) => {
    return (
        <Label className="UnsplashAttribution" color="visageGray" size="body-2">
            {localize("PHOTO_BY")}{" "}
            <a target="_blank" href={authorUrl}>
                {authorName}
            </a>{" "}
            {localize("Pa")}{" "}
            <a target="_blank" href="https://www.unsplash.com?utm_source=Spintr&utm_medium=referral">
                Unsplash
            </a>
        </Label>
    );
};

export default UnsplashAttribution;
