import React, { Fragment, FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const QaAnswerUpdatedNotification: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.IQaAnswerUpdatedNotification;
    if (!notification) {
        return;
    }

    return (
        <Fragment>
            <Link to={`/profile/${notification.userId}`}>
                {notification.userName}
            </Link>
            {" " + localize("HarUppdatedatSvaretPaDinFraga") + "."}
        </Fragment>
    );
};

export default QaAnswerUpdatedNotification;
