import { Dropdown, IDropdownOption, Stack, TextField } from "@fluentui/react";
import React, { ChangeEvent, FunctionComponent, useCallback } from "react";
import { localize } from "src/l10n";
import { HttpMethod, IExtraResource } from "src/marketplace/types";
import { FormControl } from "src/ui/components/Forms";

interface IProps {
    resourceError?: string;
    resource: IExtraResource;

    onChange: (resource: IExtraResource) => void;
}

const MarketplaceWidgetResource: FunctionComponent<IProps> = (props) => {
    const {
        resourceError,
        resource,
        onChange,
    } = props;

    const onUriChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            onChange({
                ...resource,
                resourceUri: value
            });
        },
        [resource, onChange],
    );

    const onNameChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            onChange({
                ...resource,
                name: value
            });
        },
        [resource, onChange],
    );

    const onMethodChange = useCallback(
        (_: any, option: IDropdownOption) => {
            const key = option.key.toString();

            if (["GET", "POST"].indexOf(key) === -1) {
                return;
            }

            onChange({
                ...resource,
                requestMethod: key as HttpMethod,
            });
        },
        [resource, onChange],
    );

    return (
        <Stack
            className="MarketplaceWidgetResource"
            horizontal={true}
            verticalAlign="start"
        >
            <FormControl className="request-type">
                <Dropdown
                    label={localize("MARKETPLACE_WIDGET_RESOURCE_METHOD")}
                    onChange={onMethodChange}
                    options={[
                        { key: "GET", text: "GET" },
                        { key: "POST", text: "POST" }
                    ]}
                    selectedKey={resource.requestMethod}
                />
            </FormControl>
            <FormControl className="resource-input">
                <TextField
                    errorMessage={resourceError}
                    label={localize("MARKETPLACE_WIDGET_RESOURCE")}
                    name="resourceUri"
                    onChange={onUriChange}
                    value={resource.resourceUri}
                />
            </FormControl>
            <FormControl className="resource-input">
                <TextField
                    label={localize("MARKETPLACE_WIDGET_RESOURCE_NAME")}
                    name="name"
                    onChange={onNameChange}
                    value={resource.name}
                />
            </FormControl>
        </Stack>
    )
}

export default MarketplaceWidgetResource;
