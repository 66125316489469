import React, { useMemo, useState } from 'react';
import "./ComposerMemoTodoItem.scss";
import { Label, SpintrUser, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { localize } from 'src/l10n';
import { Callout, TextField } from '@fluentui/react';
import SmallCTAButton from 'src/ui/components/Buttons/SmallCTAButton/SmallCTAButton';
import SpintrDatePicker from 'src/spintr/components/SpintrDatePicker';
import moment from 'moment';
import { FormControl, FormSection, FormTokenizedObjectInput } from 'src/ui/components/Forms';
import { SpintrTypes } from 'src/typings';

interface IProps {
    item: any;
    isActive: boolean;
    onActive: () => void;
    onChange: (item: any) => void;
    onDelete: () => void;
}

interface IState {
    activeIndex: number;
    displayUserCallout: boolean;
}

const ComposerMemoTodoItem = (props: IProps) => {
    const [state, setState] = useState<IState>({
        activeIndex: 0,
        displayUserCallout: false
    });

    const showOptions = useMemo(() => {
        return props.isActive;
    }, [props.isActive]);

    return (
        <div className="ComposerMemoTodoItem">
            <div className="top-row">
                <Visage2Icon icon="add-circle" hexColor="#A7A7B5" size="small" />
                <TextField
                    borderless
                    value={props.item.title}
                    autoFocus
                    onFocus={props.onActive}
                    placeholder={localize("WRITE_A_TASK")}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        props.onChange({ ...props.item, title: newValue });
                    }} />
            </div>
            {showOptions && (
                <div className="actions">
                    <div id={"users-selection-" + props.item.id}>
                        {props.item.assignees.length === 0 && (
                            <SmallCTAButton
                                onClick={() => {
                                    setState((s: IState) => ({
                                        ...s,
                                        displayUserCallout: true
                                    }));
                                }}
                                icon="user-add"
                                text={localize("Delegera")}
                            />
                        )}
                        {props.item.assignees.length > 0 && (
                            <div className="users">
                                {props.item.assignees.map((user: any) => {
                                    return (
                                        <div className="user">
                                            <SpintrUser name={user.name} imageUrl={user.imageUrl} size={24} hideText personalName />
                                            <UnstyledButton onClick={() => {
                                                props.onChange({ ...props.item, assignees: [...props.item.assignees].filter(x => x.id !== user.id) });
                                            }}>
                                                <Visage2Icon icon="close-circle" size="small" />
                                            </UnstyledButton>
                                        </div>
                                    )
                                })}
                                <UnstyledButton className="user-add" onClick={() => {
                                    setState((s: IState) => ({
                                        ...s,
                                        displayUserCallout: true
                                    }));
                                }}>
                                    <Visage2Icon icon="user-add" size="small" />
                                </UnstyledButton>
                            </div>
                        )}
                    </div>
                    <SpintrDatePicker
                        value={props.item.dueDate}
                        customInput={
                            <SmallCTAButton
                                onClick={() => {}}
                                icon="calendar-add"
                                text={props.item.dueDate ? moment(props.item.dueDate).format("LLL") : localize("Forfallodatum")}
                                onClear={props.item.dueDate ? () => props.onChange({ ...props.item, dueDate: undefined, dueBy: undefined }) : undefined}
                            />
                        }
                        onChangeHandler={(date) => {
                            props.onChange({ ...props.item, dueDate: date, dueBy: date });
                        }} />
                    <SmallCTAButton
                        onClick={props.onDelete}
                        icon="close-circle"
                        text={localize("TaBort")}
                    />
                </div>
            )}
            {state.displayUserCallout && (
                <Callout
                    preventDismissOnResize
                    role="alertdialog"
                    gapSpace={0}
                    setInitialFocus
                    onDismiss={() => {
                        setState((s: IState) => ({
                            ...s,
                            displayUserCallout: false
                        }));
                    }}
                    className="users-selection"
                    target={"#users-selection-" + props.item.id}
                >
                    <FormSection>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={props.item.assignees}
                                label={localize("SokAnvandare")}
                                types={[
                                    SpintrTypes.UberType.User
                                ]}
                                onChange={(items) => {
                                    props.onChange({ ...props.item, assignees: items.map(x => {
                                        return {
                                            ...x,
                                            id: x.id ? x.id : x.key
                                        }
                                    }) });
                                }}
                            />
                        </FormControl>
                    </FormSection>
                </Callout>
            )}
        </div>
    )
}

export default ComposerMemoTodoItem;
