import {
    Modal,
    Separator
} from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import PopupHeader from "src/ui/components/PopupHeader";

interface IProps {
    dismiss: () => void;
    roles: string[];
    name: string;
}

interface IState { }

class RoleListModal extends Component<IProps, IState> {
    public render() {
        const { name, roles, dismiss } = this.props;
        return (
            <Modal isOpen={true} className="spintr-modal modalWithPopupHeader" onDismiss={dismiss}>
                <PopupHeader
                    text={localize("Roller") + ": " + name}
                    onClose={this.props.dismiss} />
                {roles.map((role, idx) => (
                    <Label role="text" as="div" key={idx}>
                        {role}
                    </Label>
                ))}
            </Modal>
        );
    }
}

export default RoleListModal;
