import { DayOfWeek, Dropdown, IDropdownOption, IStackProps, Stack, TextField } from "@fluentui/react";
import { DatePicker } from "@fluentui/react/lib/components/DatePicker/DatePicker";
import React, { Component } from "react";
import { localize } from "src/l10n";
import { Loader } from "src/ui";
import { FormFooterBar } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { validateRequiredTextField } from "src/utils";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import api from "src/spintr/SpintrApi";

interface IProps {
    item: any;
    categories: any;
    onSaved: any;
    onCancel: any;
    showFormFooterBar?: boolean;
    pageId?: any;
}

interface IState {
    item: any;
    isLoading: boolean;
    enableFieldValidation?: boolean;
    saveError?: any;
}

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 500 } },
};

class ImprovementsForm extends Component<IProps, IState> {
    state = {
        item: {} as any,
        isLoading: true,
        enableFieldValidation: false,
        saveError: null
    };

    componentDidMount = () => {
        this.setState({
            item: {
                ...this.props.item,
                date: this.props.item.date ?
                    this.props.item.date :
                    new Date(),
                category: this.props.item.category ?
                    this.props.item.category :
                    (this.props.categories && this.props.categories.length > 0) ?
                        this.props.categories[0] :
                        null
            }
        }, () => {
            this.setState({
                isLoading: false
            });
        });
    };

    render() {
        const { item, isLoading } = this.state;
        const { showFormFooterBar } = this.props;

        if (isLoading) return <Loader />;

        return (
            <div className="ImprovementsForm">
                <form>
                    <Stack horizontal tokens={{ childrenGap: 50 }} styles={{ root: { width: 650 } }}>
                        <Stack {...columnProps}>
                            {this.state.saveError && this.state.saveError.length > 0 && (
                                <ErrorMessagebar
                                    errorList={this.state.saveError}
                                    onDismiss={() => {
                                        this.setState({
                                            saveError: [],
                                        });
                                    }}
                                />
                            )}
                            <DatePicker
                                label={localize("Fran")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={item.date}
                                isRequired={true}
                                aria-required={true}
                                onSelectDate={(date: Date) => {
                                    this.setState((state) => ({
                                        item: {
                                            ...state.item,
                                            date,
                                        },
                                    }));
                                }}
                            />

                            <Dropdown
                                label={localize("Kategori")}
                                ariaLabel={localize("Kategori")}
                                title={localize("Kategori")}
                                selectedKey={item.category && item.category.id && item.category.id}
                                onChange={(event: React.FormEvent<HTMLDivElement>, i: IDropdownOption) => {
                                    this.setState((state) => ({
                                        item: { ...state.item, category: { id: i.key } },
                                    }));
                                }}
                                options={this.props.categories}
                            />

                            <TextField
                                label={localize("Titel")}
                                name="title"
                                value={item.title}
                                onChange={(_e, title) => {
                                    this.setState((state) => ({ item: { ...state.item, title } }));
                                }}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />

                            <TextField
                                label={localize("Forbattring") + ` (${localize("EjPaborjad")})`}
                                value={item.step1}
                                onChange={(_e, step1) => {
                                    this.setState((state) => ({ item: { ...state.item, step1 } }));
                                }}
                                placeholder={localize("ImprovementStep1")}
                                multiline={true}
                            />
                            <TextField
                                label={localize("Forbattringsforslag") + ` (${localize("Paborjad")})`}
                                value={item.step2}
                                onChange={(_e, step2) => {
                                    this.setState((state) => ({ item: { ...state.item, step2 } }));
                                }}
                                placeholder={localize("ImprovementStep2")}
                                multiline={true}
                            />
                            <TextField
                                label={localize("Beslut") + ` (${localize("Beslutad")})`}
                                value={item.step3}
                                onChange={(_e, step3) => {
                                    this.setState((state) => ({ item: { ...state.item, step3 } }));
                                }}
                                placeholder={localize("ImprovementStep3")}
                                multiline={true}
                            />
                            <TextField
                                label={localize("Uppfoljning") + ` (${localize("Avslutad")})`}
                                value={item.step4}
                                onChange={(_e, step4) => {
                                    this.setState((state) => ({ item: { ...state.item, step4 } }));
                                }}
                                placeholder={localize("ImprovementStep4")}
                                multiline={true}
                            />
                        </Stack>
                    </Stack>
                    {showFormFooterBar && (
                        <FormFooterBar
                            onCancelClick={() => {
                                this.props.onCancel();
                            }}
                            onSaveClick={() => {
                                let payload = {
                                    ...item,
                                    pageId: this.props.pageId
                                }

                                this.setState({
                                    enableFieldValidation: true,
                                    isLoading: true
                                }, () => {
                                    api.post("/api/v1/improvements/improvements", payload).then(this.props.onSaved).catch(this.handleCatch.bind(this));
                                });
                            }}
                        />
                    )}
                </form>
            </div>
        );
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };
}

export default ImprovementsForm;
