import { AxiosResponse } from "axios";
import { Checkbox } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { ActionMenu, Breadcrumbs, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import api from "src/spintr/SpintrApi";

interface IRouteParams {
    typeId: string;
}

interface Props extends RouteComponentProps<IRouteParams> {
    enableDepartmentLists: boolean;
}

interface State {
    showDeleted: boolean;
    showCompleted: boolean;
    listTodos: any[];
    lists: any;
    isLoading: boolean;
}

const types = () => {
    return [
        {
            id: 1,
            name:
                localize("AttGoraPunkter") +
                " " +
                localize("Fran").toLowerCase() +
                " " +
                localize("MinaListor").toLowerCase(),
        },
        {
            id: 2,
            name:
                localize("AttGoraPunkter") +
                " " +
                localize("Fran").toLowerCase() +
                " " +
                localize("GemensammaListor").toLowerCase(),
        },
        {
            id: 4,
            name:
                localize("AttGoraPunkter") +
                " " +
                localize("Fran").toLowerCase() +
                " " +
                localize("Avdelningslistor").toLowerCase(),
        },
        {
            id: 5,
            name:
                localize("AttGoraPunkter") +
                " " +
                localize("Fran").toLowerCase() +
                " " +
                localize("Grupplistor").toLowerCase(),
        },
    ];
};

class TodoListByTypeView extends Component<Props, State> {
    state = {
        showDeleted: false,
        showCompleted: false,
        listTodos: [],
        lists: {} as any,
        isLoading: true,
    };

    typeId = this.props.match.params.typeId;
    title = types().find((t) => {
        return t.id === parseInt(this.typeId);
    })?.name;
    private listRef = React.createRef<SpintrList>();

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    fetchLists = () => {
        this.setState({ isLoading: true }, () => {
            api
                .get("/api/v1/todos/lists")
                .then((response) => {
                    this.setState({ lists: response.data });
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        });
    };

    componentDidMount = () => {
        this.fetchLists();
    };

    render() {
        const { listTodos, showCompleted, showDeleted, isLoading } = this.state;
        if (isLoading) return <SpintrLoader />;

        return (
            <div className="TodoListByTypeView">
                <Helmet>
                    <title>{localize("AttGora")}</title>
                </Helmet>
                <div>
                    <>
                        <Breadcrumbs
                            displayInstance
                            items={[
                                {
                                    text: localize("AttGora"),
                                    key: 10,
                                    link: "/todo",
                                },
                                {
                                    text: this.title,
                                    key: 11,
                                    link: "/todo/type/" + this.props.match.params.typeId,
                                },
                            ]}
                        />
                        <PageHeader
                            title={this.title}
                            actionMenuCategories={[
                                {
                                    items: [
                                        {
                                            text: showCompleted ? localize("DoljAvklarade") : localize("VisaAvklarade"),
                                            onClick: () => {
                                                this.setState({ showCompleted: !showCompleted }, () => {
                                                    this.listRef.current.fetch();
                                                });
                                            },
                                        },
                                        {
                                            text: showDeleted ? localize("DoljBorttagna") : localize("VisaBorttagna"),
                                            onClick: () => {
                                                this.setState({ showDeleted: !showDeleted }, () => {
                                                    this.listRef.current.fetch();
                                                });
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />

                        <SpintrList
                            ref={this.listRef}
                            fetch={(skip, take, columnId, isAscending, searchQuery) => {
                                return new Promise((resolve, reject) => {
                                    api
                                        .get(`/api/v1/todos/todosbytype/${this.typeId}`, {
                                            params: {
                                                bypassDirect: true,
                                                showDeleted: this.state.showDeleted,
                                                showCompleted: this.state.showCompleted,
                                                isAscending: isAscending,
                                                column: columnId,
                                                search: searchQuery,
                                                take: take,
                                                skip: skip,
                                            },
                                        })
                                        .then((response: AxiosResponse) => {
                                            this.setState({ listTodos: response.data.items });
                                            resolve({
                                                data: response.data.items,
                                                totalCount: response.data.totalCount,
                                            });
                                        });
                                });
                            }}
                            orderByColumn={"dueDate"}
                            columns={[
                                {
                                    key: "checkbox",
                                    name: "",
                                    minWidth: 20,
                                    maxWidth: 20,
                                    onRender: (item) => {
                                        let listItem = listTodos.findIndex((f) => f.id === item.id);
                                        return (
                                            <Checkbox
                                                checked={listTodos[listItem] ? listTodos[listItem].isComplete : false}
                                                onChange={() => {
                                                    api.post(`/api/v1/todos/togglecompleted/${item.id}`, {
                                                        id: item.id,
                                                        isComplete: !item.isComplete,
                                                    });
                                                    listTodos[listItem].isComplete = !listTodos[listItem].isComplete;
                                                    this.setState({ listTodos });
                                                }}
                                            />
                                        );
                                    },
                                },
                                {
                                    key: "c0",
                                    name: localize("Namn"),
                                    fieldName: "name",
                                    minWidth: 250,
                                    onRender: (item) => {
                                        if (item.isDeleted)
                                            return (
                                                <span>
                                                    <s>
                                                        <Link className="" to={`/todo/list/${item.list.id}/${item.id}`}>
                                                            {item.name}
                                                        </Link>
                                                    </s>
                                                </span>
                                            );
                                        else
                                            return (
                                                <span>
                                                    <Link className="" to={`/todo/list/${item.list.id}/${item.id}`}>
                                                        {item.name}
                                                    </Link>
                                                </span>
                                            );
                                    },
                                },
                                {
                                    key: "list",
                                    name: localize("Lista"),
                                    fieldName: "listName",
                                    minWidth: 150,
                                    onRender: (item) => {
                                        return (
                                            <span>
                                                <Link to={`/todo/list/${item.list.id}`}>{item.listName}</Link>
                                            </span>
                                        );
                                    },
                                },
                                {
                                    key: "c1",
                                    name: localize("Datum"),
                                    fieldName: "dueDate",
                                    minWidth: 100,
                                    onRender: (item) => {
                                        return <span>{item.dueDate && this.formatDate(item.dueDate)}</span>;
                                    },
                                },
                                {
                                    name: "",
                                    key: "actionmenu",
                                    minWidth: 40,
                                    onRender: (item: any) => {
                                        return (
                                            <ActionMenu
                                                categories={[
                                                    {
                                                        items: [
                                                            {
                                                                text: localize("Redigera"),
                                                                onClick: () => {
                                                                    this.props.history.push({
                                                                        pathname: `/todo/list/${item.list.id}/${item.id}/edit`,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                text: item.isDeleted
                                                                    ? localize("Aterstall")
                                                                    : localize("TaBort"),
                                                                onClick: () => {
                                                                    api
                                                                        .post(`/api/v1/todos/toggledeleted/${item.id}`)
                                                                        .then((_response) => {
                                                                            this.listRef.current.fetch();
                                                                        });
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                    </>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: Props): Props => ({
    ...props,

    enableDepartmentLists: state.instance.get("enableTodoDepartmentLists"),
});

const ConnectedTodoListByTypeView = connect(mapStateToProps)(TodoListByTypeView);

// @ts-ignore
export default withRouter(ConnectedTodoListByTypeView);
