import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMessage, MessageRole } from "src/assistant/types";
import { localize } from "src/l10n";
import { Label, SpintrUser } from "src/ui";
import { capitalizeFirstLetter } from "src/utils";
import AssistantMessageText from "./AssistantMessageText";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    message: IMessage;
}

const AssistantMessageCitationLink = (props: any) => {
    const url = new URL(props.citation.url);
    const isExternal = url.origin !== window.location.origin;

    if (isExternal) {
        return (
            <a href={props.citation.url} target="_blank" className="content" onClick={(e) => {
                e.preventDefault();

                window.open(props.citation.url, "_blank");
            }}>
                {props.children}
            </a>
        )
    }

    return (
        <Link to={url.pathname} className="content">
            {props.children}
        </Link>
    )
}

const CitationIcon = (props) => {
    const url = new URL(props.citation.url);
    const isExternal = url.origin !== window.location.origin;

    if (isExternal) {
        return (
            <Visage2Icon icon="export-2" />
        )
    }

    const icons = {
        "3": "message",
        "7": "folder",
        "9": "document",
        "10": "firstline",
        "16": "book",
        "21": "card-edit"
    }

    let icon = icons[props.citation.type];

    if (!icon) {
        icon = "document";
    }

    return (
        <Visage2Icon icon={icon} />
    )
}

const AssistantMessageCitations: FunctionComponent<IProps> = ({ message}) => {
    const [citations, setCitations] = useState([]);

    useEffect(() => {
        if (!message.citations) {
            return;
        }

        //@ts-ignore
        const isRecent = (new Date() - message.date) < 5000;

        if (!isRecent) {
            return setCitations(message.citations);
        }

        for (let i = 0; i < message.citations.length; i++) {
            setTimeout(() => {
                setCitations(c => ([...c, message.citations[i]]));
            }, (message.text.split(" ").length * 100) + (i * 300));
        }
    }, []);
    
    if (!message.citations || message.citations.length === 0) {
        return null;
    }

    return (
        <div className="citations">
            <ol>
                {citations.slice(0, 3).map((citation, index) => (
                    <li className="citation" key={index}>
                        <AssistantMessageCitationLink citation={citation}>
                            <Label size="body-3" weight="medium" color="visageGray" className="name" title={citation.title}>
                                {citation.title}
                            </Label>
                            <div className="citation-footer">
                                <CitationIcon citation={citation} />
                                <Label className="index" size="small-2" weight="regular"><span>{index + 1}</span></Label>
                            </div>
                        </AssistantMessageCitationLink>
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default AssistantMessageCitations;
