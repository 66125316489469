import Immutable from "immutable";

const initialState = Immutable.Map({
    error: Immutable.Map({ exists: false }),
    isLoading: false,
    items: Immutable.List(),
});

// @ts-ignore
const blogPostReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default blogPostReducer;
