import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";

import { ActionTypes } from './action-types'
import { queryUserShortcuts } from "./api";

interface IFetchUserShortcutsAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IShortcut[]>>;
    type: ActionTypes.FetchUserShortcuts;
}

interface IFetchUserShortcutsPendingAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IShortcut[]>>;
    type: ActionTypes.FetchUserShortcutsPending;
}

interface IFetchUserShortcutsRejectedAction extends Action {
    payload: AxiosError<any>;
    type: ActionTypes.FetchUserShortcutsRejected;
}

interface IFetchUserShortcutsFulfilledAction extends Action {
    payload: AxiosResponse<Spintr.IShortcut[]>;
    type: ActionTypes.FetchUserShortcutsFulfilled;
}

export type FetchUserShortcutsAction = 
      IFetchUserShortcutsAction
    | IFetchUserShortcutsPendingAction
    | IFetchUserShortcutsRejectedAction
    | IFetchUserShortcutsFulfilledAction;

export type FetchUserShortcutsActionHandler = () => IFetchUserShortcutsAction;

export const fetchShortcuts: FetchUserShortcutsActionHandler = () => ({
    payload: queryUserShortcuts(),
    type: ActionTypes.FetchUserShortcuts
});

export type ShortcutAction = FetchUserShortcutsAction;
