import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { localize } from "src/l10n";
import { FillHeight, Label, UnstyledButton } from "src/ui";
import { mixpanelTrack, MixPanelEvents } from "src/utils";
import { StartPageBuilderDrawerItem } from "../StartPageBuilderDrawerItem";

interface IProps {
    hidden?: boolean;
}

const StartPageBuilderDrawer: FunctionComponent<IProps> = ({ hidden }) => {
    const [activeCategory, setActiveCategory] = useState<string | undefined>();

    const onCategoryClick = useCallback((data: any) => {
        setActiveCategory(data?.key === "all"
            ? undefined
            : data?.key as string
        );

        mixpanelTrack(MixPanelEvents.Dynamo_ClickDrawerCategory, {key: data?.key});
    }, [setActiveCategory]);

    const enabledApps = useSelector<Spintr.AppState, Spintr.ISystemApp[]>(
        (state) => (state.app.items || [])
            .filter((app) => app.enabled),
    );

    const enabledAppIds = enabledApps.map((app) => app.id);

    const items = useMemo(() => [{
        categories: ["social"],
        dependentOn: [],
        key: "SocialFeed",
        title: localize("SocialtFlode"),
        text: localize("DYNAMO_SOCIAL_FEED_DESCRIPTION"),
        icon: "Socialfeed.svg",
    },{
        categories: ["social"],
        dependentOn: [],
        key: "Birthdays",
        title: localize("appBirthdays"),
        text: localize("DYNAMO_BIRTHDAYS_DESCRIPTION"),
        icon: "Tags.svg",
    },{
        categories: ["data"],
        dependentOn: [],
        key: "DataBoxes",
        title: localize("DATA_WIDGETS"),
        text: localize("DYNAMO_DATA_BOXES_DESCRIPTION"),
        icon: "Datawidget.svg",
    },{
        categories: ["other"],
        dependentOn: [],
        key: "TeaserBoxes",
        title: localize("Textpuffar"),
        text: localize("DYNAMO_TEASER_BOXES_DESCRIPTION"),
        icon: "Teaserbox.svg",
    },{
        categories: ["other"],
        dependentOn: [],
        key: "Todo",
        title: localize("AttGora"),
        text: localize("DYNAMO_TODO_DESCRIPTION"),
        icon: "Todo.svg",
    },{
        categories: ["other"],
        dependentOn: [],
        key: "OperatingInfo",
        title: localize("Driftinformation"),
        text: localize("DYNAMO_OPERATING_INFO_DESCRIPTION"),
        icon: "Operatinginfo.svg",
    },{
        categories: ["social", "news"],
        dependentOn: [2],
        key: "BlogBoxes",
        title: localize("DYNAMO_BLOG_BOXES"),
        text: localize("DYNAMO_BLOG_BOXES_DESCRIPTION"),
        icon: "Bloggpuff.svg",
    }/*,{
        categories: ["calendar"],
        dependentOn: [6],
        key: "calendar",
        title: localize("Kalender"),
        text: localize("DYNAMO_CALENDAR_DESCRIPTION"),
        icon: "Calendar.svg",
    }*/,{
        categories: ["news"],
        dependentOn: [5],
        key: "SpotlightNews",
        title: localize("ATTACHED_NEWS_ARTICLES"),
        text: "",
        icon: "Attachednews.svg",
    },{
        categories: ["news"],
        dependentOn: [5],
        key: "NewsFeed",
        title: localize("NEWS_FEED"),
        text: localize("DYNAMO_NEWS_DESCRIPTION"),
        icon: "Bignewsarticles.svg",
    }, {
        categories: ["other"],
        dependentOn: [5],
        key: "Tags",
        title: localize("Taggar"),
        text: localize("DYNAMO_TAGS_DESCRIPTION"),
        icon: "Tags.svg",
    }], []);

    const categories = useMemo(
        () => [{key: "all", text: localize("Allt")}].concat(
            [{
                key: "social",
                text: localize("CATEGORY_SOCIAL"),
            }, {
                key: "news",
                text: localize("Nyheter"),
            }, {
                key: "data",
                text: localize("Data"),
            }, {
                key: "other",
                text: localize("Ovrigt"),
            }].sort((a, b) => a.text > b.text ? 1 : -1)
        ),
        [],
    );

    const availableItems = items
        .filter(item => 
            item.dependentOn.length === 0 || 
            item.dependentOn.every(id => enabledAppIds.indexOf(id) !== -1)
        )
        .filter(item =>
            !activeCategory ||
            item.categories.indexOf(activeCategory) !== -1,
        );

    return (
        <div id="StartPageBuilderDrawer" style={!hidden ? undefined : {
            flex: "0px 0 0",
            overflow: "hidden",
            width: 0,
        }}>
            <FillHeight>
                <div className="drawer-inner">
                    <div className="header">
                        <Label
                            color="visageGray"
                            size="body-1"
                            weight="medium"
                        >
                            {localize("DYNAMO_DRAWER_TITLE")}
                        </Label>
                    </div>
                    <div className="wrapper">
                        <div className="categories">
                            <ul className="category-list">
                                {categories.map((category) => (
                                    <li
                                        key={category.key}
                                    >
                                        <UnstyledButton
                                            onClick={onCategoryClick}
                                            onClickData={category}
                                            className={
                                                "category" +
                                                (activeCategory === category.key
                                                    ? " selected"
                                                    : "")
                                            }
                                        >
                                            <Label
                                                as="span"
                                                color="visageGray"
                                                size="body-2"
                                            >
                                                {category.text}
                                            </Label>
                                        </UnstyledButton>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="component-list">
                            <Droppable
                                droppableId="drawer-droppable"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        key="start-page-builder-drawer-droppable"
                                        className="drawer-droppable"
                                        ref={provided.innerRef}
                                        style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'transparent' }}
                                        {...provided.droppableProps}
                                    >
                                        {provided.placeholder}
                                        {availableItems.map((item, index) => (
                                            <div className="item-wrapper" key={item.key}>
                                                <StartPageBuilderDrawerItem
                                                    componentKey={item.key}
                                                    title={item.title}
                                                    text={item.text}
                                                    icon={item.icon}
                                                    key={item.key}
                                                    index={index}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
            </FillHeight>
        </div>
    );
};

export default StartPageBuilderDrawer;
