import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Heading, SmallBody, Text } from "..";
import {
    DeserializedProductColumnContent as Content,
    ProductListColumnProps,
    ProductListColumnState as State,
} from "./ProductListColumn.types";
import productsApi from "src/products/productsApi";
import { Product } from "src/products/types";
import { localize } from "src/l10n";
import SpintrLoader from "src/ui/components/Loader";
import { ProductPreviewCard } from "../ProductPreviewCard";

function deserializeContent(columnContent: string): Content {
    try {
        return JSON.parse(columnContent);
    } catch (e) {
        console.error("Error parsing column content", e);
        return { title: "", items: [] };
    }
}

function renderProduct(product: Product): JSX.Element {
    return (
        <ProductPreviewCard product={product} />
    )
}

function renderProducts(products: Product[], isLoading: boolean): JSX.Element {
    if (isLoading) {
        return <SpintrLoader />;
    }

    if (products.length === 0) {
        return (
            <SmallBody className="ProductsListColumn-empty">
                {localize("NO_PRODUCTS_FOUND")}
            </SmallBody>
        );
    }

    return (
        <ul className="ProductsListColumn-products">
            {products.map((product) => (
                <li className="product" key={product.id}>
                    {renderProduct(product)}
                </li>
            ))}
        </ul>
    );
}

function renderTitle(title?: string | undefined): JSX.Element {
    if (!title) {
        return null;
    }

    return (
        <Heading
            as="h2"
            className="ProductsListColumn-title"
            color="contentDark"
            letterSpacing="dense"
            weight="regular"
        >
            {title}
        </Heading>
    );
}

/**
 * Displays a list of products for use by Uber Content Columns
 * 
 * @see EditableContentSectionsRow
 */
function ProductListColumn(props: ProductListColumnProps): ReactElement {
    const content = useMemo<Content>(
        () => deserializeContent(props.columnContent),
        [props.columnContent],
    );

    const [state, setState] = useState<State>({
        loading: false,
        products: [],
    });

    useEffect(() => {
        if (!content.items || content.items.length === 0) {
            setState({ loading: false, products: [] });
            return;
        }

        setState({ loading: true, products: [] });

        const cancelTokenSource = productsApi.cancelTokenSource();
        productsApi
            .queryByIdsAsync(
                content.items.map((item) => item.key),
                cancelTokenSource.token,
            )
            .then((envelope) => {
                setState({ loading: false, products: envelope.items });
            })
            .catch((err) => {
                console.error("Error fetching products", err);
                setState({ loading: false, products: [] });
            });
            
        return () => cancelTokenSource.cancel();
    }, [content.items, setState]);

    return (
        <div className="ProductListColumn">
            {renderTitle(content.title)}
            {renderProducts(state.products, state.loading)}
        </div>
    );
}

export default ProductListColumn;