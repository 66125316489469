import { SpintrTypes } from "src/typings";

export function canUserCreatePages(privileges, currentUser, instance, isGroupAdmin?) : boolean {
    if (isGroupAdmin !== undefined) {
        return isGroupAdmin;
    }

    const isAdminOrEditor = currentUser.isAdmin ||
        currentUser.isEditor;

    if (!instance.get("enablePrivilegesMatrix")) {
        return isAdminOrEditor;
    }

    const editorsSingleDepartmentTextPageEdit = currentUser.isEditor &&
        instance.get("enableDepartmentTargetText") &&
        instance.get("editorsSingleDepartmentTextPageEdit");

    if (editorsSingleDepartmentTextPageEdit) {
        return false;
    }

    const privilege = privileges.find(p =>
        p.privilegeId === SpintrTypes.Privilege.CreatePages);

    if (privilege) {
        return privilege.value;
    } else {
        return isAdminOrEditor;
    }
}

export function canUserCreateSubPages(privileges, currentUser, instance, pageId) : boolean {
    const isAdminOrEditor = currentUser.isAdmin ||
        currentUser.isEditor;

    if (!instance.get("enablePrivilegesMatrix")) {
        return isAdminOrEditor;
    }

    const editorsSingleDepartmentTextPageEdit = currentUser.isEditor &&
        instance.get("enableDepartmentTargetText") &&
        instance.get("editorsSingleDepartmentTextPageEdit");

    if (editorsSingleDepartmentTextPageEdit) {
        return false;
    }

    const privilege = privileges.find(p =>
        p.uberId === pageId &&
        p.privilegeId === SpintrTypes.Privilege.CreateSubPages);

    if (privilege) {
        return privilege.value;
    } else {
        return isAdminOrEditor;
    }
}

export function canUserEditPage(privileges, currentUser, instance, pageId, isGroupAdmin?) : boolean {
    if (isGroupAdmin !== undefined) {
        return isGroupAdmin;
    }

    const isAdminOrEditor = currentUser.isAdmin ||
        currentUser.isEditor;

    if (!instance.get("enablePrivilegesMatrix")) {
        return isAdminOrEditor;
    }

    const privilege = privileges.find(p =>
        p.uberId === pageId &&
        p.privilegeId === SpintrTypes.Privilege.EditPage);

    if (privilege) {
        return privilege.value;
    } else {
        return isAdminOrEditor;
    }
}

export function canUserDeletePage(privileges, currentUser, instance, pageId, isGroupAdmin?) : boolean {
    if (isGroupAdmin !== undefined) {
        return isGroupAdmin;
    }

    const isAdminOrEditor = currentUser.isAdmin ||
        currentUser.isEditor;

    if (!instance.get("enablePrivilegesMatrix")) {
        return isAdminOrEditor;
    }

    const editorsSingleDepartmentTextPageEdit = currentUser.isEditor &&
        instance.get("enableDepartmentTargetText") &&
        instance.get("editorsSingleDepartmentTextPageEdit");

    if (editorsSingleDepartmentTextPageEdit) {
        return false;
    }

    const privilege = privileges.find(p =>
        p.uberId === pageId &&
        p.privilegeId === SpintrTypes.Privilege.DeletePage);

    if (privilege) {
        return privilege.value;
    } else {
        return isAdminOrEditor;
    }
}

export function canUserCreateAndEditNewsArticles(privileges, currentUser, instance) : boolean {
    const isAdminOrEditor = currentUser.isAdmin ||
        currentUser.isEditor;

    if (!instance.get("enablePrivilegesMatrix")) {
        return isAdminOrEditor;
    }

    const privilege = privileges.find(p =>
        p.privilegeId === SpintrTypes.Privilege.CreateAndEditNewsArticles);

    if (privilege) {
        return privilege.value;
    } else {
        return isAdminOrEditor;
    }
}