import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { Label, LocalizedString } from "src/ui";
import { capitalizeFirstLetter } from "src/utils";

interface ISocialBlogPostProps {
    post: Spintr.ISocialBlogPost;
}

class SocialBlogPost extends Component<ISocialBlogPostProps> {
    public render() : ReactNode {
        return (
            <div className="SocialBlogPost">
                <Label
                    as="p"
                    color="mid-grey"
                    size="body-2"
                >
                    <span>
                        {capitalizeFirstLetter(localize("postadeBlogginlagget_small"))}
                    </span> <Link to={"/" + this.props.post.Url}>
                        {this.props.post.Title}
                    </Link> <LocalizedString text="iBloggen_small" 
                    /> <Link to={"/" + this.props.post.CategoryUrl}>
                        {this.props.post.Category}
                    </Link>
                </Label>
            </div>
        );
    }
}

export default SocialBlogPost;