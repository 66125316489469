import { Pivot, PivotItem } from "@fluentui/react";
import { Dropdown, IDropdownOption, IStackProps, Stack, TextField, Toggle } from "@fluentui/react";
import React, { PureComponent, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { localize } from "src/l10n";
import { getLanguageOptions } from "src/l10n/localize";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, ContentImageViewerAndSelector, Label, Loader, PageHeader } from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import api from "src/spintr/SpintrApi";

interface Props {
    currentUser: any;
    match: any;
    history: any;
    enableCompany?: boolean;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
    targetToCompany?: boolean | undefined;
}

interface State {
    isLoading: boolean;
    blog: Spintr.IBlog;
    saveError: string[];
    saveSuccess: boolean;
    blogName: string;
    showImageSelector: boolean;
    enableFieldValidation?: boolean;
}

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 500 } },
};

class BlogsEditView extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        let targets: any[] | undefined = undefined;
        if (props.targetToCompany && props.currentUser.department?.office?.company) {
            const company = props.currentUser.department.office.company;

            targets = [{
                id: company.id,
                key: company.id,
                name: company.name,
                subText: localize("Foretag"),
            }];
        }

        this.state = {
            isLoading: false,
            saveError: [],
            saveSuccess: false,
            blog: {
                id: 0,
                isPublished: true,
                image: "",
                author: {
                    id: props.currentUser.id,
                    type: 1,
                    name: props.currentUser.name,
                    typeName: "Person",
                },
                coauthors: [],
                color: "#FF33FF",
                type: 1,
                name: "",
                description: "",
                tags: [],
                targets: targets || [],
                language: "",
                showOnStartPage: true,
            } as Spintr.IBlog,
            blogName: "",
            showImageSelector: false,
        };
    }

    public componentDidMount = () => {
        const { blogSlug } = this.props.match.params;

        if (blogSlug) {
            this.setState({ isLoading: true }, () => {
                api.get("/api/v1/routes", { params: { route: `bloggar/${blogSlug}` } }).then((response) => {
                    const blog = response.data as Spintr.IBlog;
                    this.setState({
                        blog,
                        isLoading: false,
                    });
                });
            });
        }
    };

    public onCancel = () => {
        this.props.history.goBack();
    };

    public onSubmit = () => {
        this.setState({
            enableFieldValidation: true
        });

        const payload = { ...this.state.blog };

        payload.author = {
            id: payload.author?.id,
        } as any;

        this.setState({ isLoading: true }, () => {
            api
                .post("/api/v1/blogs", payload)
                .then((response) => {
                    scrollToTop();
                    this.setState({ saveSuccess: true, saveError: [], isLoading: false });
                    this.props.history.push({
                        pathname: `/goto/${response.data.id}`,
                    });
                })
                .catch((saveError) => {
                    scrollToTop();
                    this.setState({ saveError: saveError.response.data.errorlist, isLoading: false });
                });
        });
    };

    render(): ReactNode {
        const { blog, saveError, saveSuccess, showImageSelector, isLoading } = this.state;

        if (isLoading) {
            return <Loader />;
        }
        let adminNewsEditView = false;
        if (window.location.href.indexOf("admin") > -1) {
            adminNewsEditView = true;
        }
        let isNew;
        if (window.location.href.indexOf("blogs/create") > -1) {
            isNew = true;
        } else {
            isNew = false;
        }

        return (
            <div>
                <Helmet>
                    <title>{isNew ? localize("NyBlogg") : localize("RedigeraBlogg")}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        ...(adminNewsEditView ? [{
                            text: localize("Administration"),
                            key: 2,
                            link: "/admin",
                        }] : []),
                        {
                            text: localize("Bloggar"),
                            key: 3,
                            link: adminNewsEditView ? "/admin/blogs" : "/blogs",
                        },
                        {
                            text: isNew ? localize("NyBlogg") : localize("RedigeraBlogg"),
                            key: 4,
                            link: window.location.pathname,
                        },
                    ]}
                />
                {saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({ saveSuccess: false });
                        }}
                    />
                )}
                {saveError && saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({ saveError: [] });
                        }}
                    />
                )}
                <PageHeader
                    title={isNew ? localize("NyBlogg") : localize("RedigeraBlogg")}
                    displayMandatoryText />
                <form>
                    <Pivot overflowBehavior={"menu"}>
                        <PivotItem headerText={localize("Allmant")} style={{ marginBottom: "6em" }}>
                            <Stack>
                                <Stack>
                                    <FormControl>
                                        <ContentImageViewerAndSelector
                                            cropImage
                                            cropAspect={1000 / 170}
                                            height={170}
                                            enableMediaflow={this.props.enableMediaflow}
                                            enableUnsplash={this.props.enableUnsplash}
                                            imageUrl={blog.image}
                                            editMode={true}
                                            onChange={(result) => {
                                                this.setState((state) => ({
                                                    blog: {
                                                        ...state.blog,
                                                        image: result.thumbnailUrl,
                                                        imageTicket: {
                                                            ticket: result.ticket,
                                                            remove: !result.ticket && !result.isUnsplash,
                                                            mediaflowId: result.externalMediaflowId,
                                                            hotlink: result.isUnsplash,
                                                            image: result.thumbnailUrl,
                                                        },
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            data-cy="blog-name"
                                            label={localize("Namn")}
                                            name="name"
                                            value={this.state.blog.name}
                                            onChange={(_e, name) => {
                                                this.setState((state) => ({ blog: { ...state.blog, name } }));
                                            }}
                                            required
                                            aria-required
                                            validateOnFocusIn
                                            validateOnFocusOut
                                            validateOnLoad={!!this.state.enableFieldValidation}
                                            onGetErrorMessage={validateRequiredTextField}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            data-cy="blog-description"
                                            label={localize("Beskrivning")}
                                            name="description"
                                            value={blog.description}
                                            multiline={true}
                                            onChange={(_e, description) => {
                                                this.setState((state) => ({ blog: { ...state.blog, description } }));
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </PivotItem>
                        <PivotItem headerText={localize("Avancerat")} style={{ marginBottom: "6em" }}>
                            <Stack>
                                <FormSection title={localize("Synlig")}>
                                    <FormControl>
                                        <Toggle
                                            label={localize("Publicerad")}
                                            checked={blog.isPublished}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    blog: { ...state.blog, isPublished: !state.blog.isPublished },
                                                }));
                                            }}
                                            onText={localize("Ja")}
                                            offText={localize("Nej")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Toggle
                                            label={localize("Prioriterad")}
                                            checked={blog.isPrioritized}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    blog: { ...state.blog, isPrioritized: !state.blog.isPrioritized },
                                                }));
                                            }}
                                            onText={localize("Ja")}
                                            offText={localize("Nej")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Toggle
                                            label={localize("VisaPaStartsidan")}
                                            checked={blog.showOnStartPage}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    blog: { ...state.blog, showOnStartPage: !state.blog.showOnStartPage },
                                                }));
                                            }}
                                            onText={localize("Ja")}
                                            offText={localize("Nej")}
                                        />
                                    </FormControl>
                                </FormSection>
                                <FormSection title={localize("Taggar")}>
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            hideImage={true}
                                            enableNewItems={true}
                                            items={blog.tags.map((tag) => {
                                                return {
                                                    name: tag,
                                                };
                                            })}
                                            label={localize("SokBlandTaggar")}
                                            onChange={(tags) => {
                                                this.setState((state) => ({
                                                    blog: {
                                                        ...state.blog,
                                                        tags: tags.map((t) => {
                                                            return t.name;
                                                        }),
                                                    },
                                                }));
                                            }}
                                            noAutocomplete
                                        />
                                    </FormControl>
                                </FormSection>
                                <FormSection title={localize("Utgivare")}>
                                    <Label as="p" size="body-3">
                                        {localize("BeskrivningBloggUtgivare")}
                                    </Label>
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            items={
                                                blog.author
                                                    ? [
                                                        {
                                                            ...blog.author,
                                                            // @ts-ignore
                                                            text: blog.author.text || blog.author.name,
                                                            // @ts-ignore
                                                            imageUrl: blog.author.imageUrl || blog.author.image,
                                                            subText: blog.author.info,
                                                        },
                                                    ]
                                                    : []
                                            }
                                            itemLimit={1}
                                            label={localize("Forfattare")}
                                            onChange={(author) => {
                                                // @ts-ignore
                                                this.setState((prevState) => ({
                                                    blog: {
                                                        ...prevState.blog,
                                                        author: author.map((a) => ({
                                                            ...a,
                                                            name: a.name,
                                                            info: a.subText,
                                                            id: a.key,
                                                            image: a.imageUrl,
                                                        }))[0],
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            items={blog.coauthors.map((ca) => ({
                                                ...ca,
                                                // @ts-ignore
                                                text: ca.text || ca.name,
                                                // @ts-ignore
                                                imageUrl: ca.imageUrl || ca.image,
                                                subText: ca.info,
                                            }))}
                                            label={localize("Medforfattare")}
                                            onChange={(coauthors) => {
                                                // @ts-ignore
                                                this.setState((prevState) => ({
                                                    blog: {
                                                        ...prevState.blog,
                                                        coauthors: coauthors.map((ca) => ({
                                                            // @ts-ignore
                                                            id: ca.key || ca.id,
                                                            text: ca.name,
                                                            name: ca.name,
                                                            image: ca.imageUrl,
                                                            info: ca.subText,
                                                        })),
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Toggle
                                            label={localize("DoljForfattare")}
                                            checked={blog.hidePublishers}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    blog: {
                                                        ...state.blog,
                                                        hidePublishers: !state.blog.hidePublishers,
                                                    },
                                                }));
                                            }}
                                            onText={localize("Ja")}
                                            offText={localize("Nej")}
                                        />
                                    </FormControl>
                                </FormSection>
                                <FormSection title={localize("appRSS")}>
                                    <div>{localize("BeskrivningBloggRSS")}</div>
                                    <FormControl>
                                        <Toggle
                                            label={localize("FaRSSLangAvDennaBlogg")}
                                            checked={blog.isExternal}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    blog: { ...state.blog, isExternal: !state.blog.isExternal },
                                                }));
                                            }}
                                            onText={localize("Ja")}
                                            offText={localize("Nej")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Toggle
                                            label={localize("ImporteraRSSFlode")}
                                            checked={blog.isImportRSS}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    blog: { ...state.blog, isImportRSS: !state.blog.isImportRSS },
                                                }));
                                            }}
                                            onText={localize("Ja")}
                                            offText={localize("Nej")}
                                        />
                                    </FormControl>
                                    {blog.isImportRSS && (
                                        <FormControl>
                                            <TextField
                                                label={localize("URL")}
                                                name="URL"
                                                value={blog.importUrl}
                                                onChange={(_e, importUrl) => {
                                                    this.setState((state) => ({ blog: { ...state.blog, importUrl } }));
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                </FormSection>
                                <FormSection title={localize("SynligFor")}>
                                    <FormControl>
                                        <FormTokenizedObjectInput
                                            showAllWhenEmpty
                                            aria-label={localize("SokOchLaggTill")}
                                            items={blog.targets.map((tag) => {
                                                return {
                                                    ...tag,
                                                    text: tag.name || tag.text,
                                                    key: tag.id || tag.key,
                                                };
                                            })}
                                            label={localize("SokOchLaggTill")}
                                            types={[
                                                SpintrTypes.UberType.TargetGroup,
                                                SpintrTypes.UberType.Role,
                                                SpintrTypes.UberType.Department,
                                                SpintrTypes.UberType.Office,
                                                ...(this.props.enableCompany ? [SpintrTypes.UberType.Company] : []),
                                            ]}
                                            onChange={(targets) => {
                                                this.setState((state) => ({
                                                    blog: {
                                                        ...state.blog,
                                                        targets: targets.map((t) => {
                                                            return {
                                                                ...t,
                                                                id: t.key,
                                                                text: t.name,
                                                                image: t.imageUrl,
                                                                info: t.subText,
                                                            };
                                                        }),
                                                    },
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                </FormSection>
                                <FormSection title={localize("Sprak")}>
                                    <FormControl>
                                        <Dropdown
                                            label={localize("ValjSprak")}
                                            selectedKey={blog.language ? blog.language : ""}
                                            onChange={(
                                                _event: React.FormEvent<HTMLDivElement>,
                                                item: IDropdownOption
                                            ) => {
                                                this.setState((state) => ({
                                                    blog: {
                                                        ...state.blog,
                                                        language: item.key.toString(),
                                                    },
                                                }));
                                            }}
                                            options={getLanguageOptions()}
                                            styles={{ dropdown: { width: 300 } }}
                                        />
                                    </FormControl>
                                </FormSection>
                            </Stack>
                        </PivotItem>
                    </Pivot>
                </form>
                <FormFooterBar onCancelClick={this.onCancel} onSaveClick={this.onSubmit}
                    confirmSave={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("PubliceraDetta").toLowerCase())} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
    enableCompany: state.instance.get("enableCompanyLevel"),
    enableMediaflow: state.instance.get("enableMediaflow"),
    enableUnsplash: state.instance.get("enableUnsplash"),
    targetToCompany: state.instance.get("forceCompanyFeed") as boolean,
});

export default withRouter(connect(mapStateToProps)(BlogsEditView));
