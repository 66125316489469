export const teaserboxTemplates = [
    {
        id: 1,
        text: "ValfriRubrik",
        subText: "Undertext",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 2,
        text: "TextMedIkon",
        icon: "car",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 3,
        text: "EndastText",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 4,
        text: "Blogg",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 5,
        image: "/images/sj.jpg",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 6,
        text: "Iframe",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 7,
        text: "TEASER_BOX_DATA",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    },
    {
        id: 8,
        text: "EndastBild",
        backgroundColor: "#FFF",
        textColor: "black",
        iconColor: "black",
        iconBackgroundColor: "#EAEAEAEA",
        showBorder: true,
    }
];

export const icons = [
    {
        key: "mdiArrowRight",
        text: "fa-arrow-right",
        visage2Icon: "arrow-circle-right"
    },
    {
        key: "mdiComment",
        text: "fa-comment",
        visage2Icon: "message"
    },
    {
        key: "mdiAirplane",
        text: "fa-paper-plane",
        visage2Icon: "airplane"
    },
    {
        key: "mdiThumbUpOutline",
        text: "fa-thumbs-o-up",
        visage2Icon: "like"
    },
    {
        key: "mdiCar",
        text: "fa-car",
        visage2Icon: "car"
    },
    {
        key: "mdiPoll",
        text: "fa-signal",
        visage2Icon: "chart"
    },
    {
        key: "mdiShare",
        text: "fa-share",
        visage2Icon: "arrow-right"
    },
    {
        key: "mdiHeartOutline",
        text: "fa-heart",
        visage2Icon: "heart"
    },
    {
        key: "mdiPinetree",
        text: "fa-tree",
        visage2Icon: "tree"
    },
    {
        key: "mdiPlus",
        text: "fa-plus",
        visage2Icon: "add"
    },
    {
        key: "mdiBalanceSunny",
        text: "fa-sun",
        visage2Icon: "sun"
    },
    {
        key: "mdiHome",
        text: "fa-home",
        visage2Icon: "home"
    },
    {
        key: "mdiBookOpen",
        text: "fa-books",
        visage2Icon: "book-1"
    },
    {
        key: "mdiWrench",
        text: "fa-wrench",
        visage2Icon: "setting-2"
    },
    {
        key: "mdiEye",
        text: "fa-eye",
        visage2Icon: "eye"
    },
    {
        key: "mdiPin",
        text: "fa-pin",
        visage2Icon: "paperclip"
    },
];

export const findByFaName = (name: string) => {
    let icon = icons.find(
        (obj) => obj.key === name || obj.text === name || obj.key === "fa-" + name || obj.text === "fa-" + name
    );
    return icon;
};

icons.sort(function (a, b) {
    if (a.text < b.text) {
        return -1;
    }
    if (a.text > b.text) {
        return 1;
    }
    return 0;
});

export const iconsName = [
    {
        key: "mdiArrowRight",
        text: "fa-arrow-right",
    },
    {
        key: "mdiComment",
        text: "fa-comment",
    },
    {
        key: "mdiSilverwareForkKnife",
        text: "fa-cutlery",
    },
    {
        key: "mdiHandPeace",
        text: "fa-hand-peace-o",
    },
    {
        key: "mdiRocket",
        text: "fa-rocket",
    },
    {
        key: "mdiAirplane",
        text: "fa-paper-plane",
    },
    {
        key: "mdiPaw",
        text: "fa-paw",
    },
    {
        key: "mdiThumbUpOutline",
        text: "fa-thumbs-o-up",
    },
    {
        key: "mdiCar",
        text: "fa-car",
    },
    {
        key: "mdiStethoscope",
        text: "fa-stethoscope",
    },
    {
        key: "mdiPoll",
        text: "fa-signal",
    },
    {
        key: "mdiShare",
        text: "fa-share",
    },

    {
        key: "mdiHeartOutline",
        text: "fa-heart",
    },
    {
        key: "mdiPinetree",
        text: "fa-tree",
    },
    {
        key: "mdiPlus",
        text: "fa-plus",
    },
    {
        key: "mdiBalanceSunny",
        text: "fa-sun",
    },
    {
        key: "mdiHome",
        text: "fa-home",
    },
    {
        key: "mdiLinkedin",
        text: "fa-linkedin-in",
    },
    {
        key: "mdiTwitter",
        text: "fa-twitter",
    },
    {
        key: "mdiFacebook",
        text: "fa-facebook",
    },
    {
        key: "mdiInstagram",
        text: "fa-instagram",
    },
    {
        key: "mdiRun",
        text: "fa-running",
    },
    {
        key: "mdiSki",
        text: "fa-skiing-nordic",
    },
    {
        key: "mdiGlassMugVariant",
        text: "fa-beer",
    },
    {
        key: "mdiGlassWine",
        text: "fa-wine-glass-alt",
    },
    {
        key: "mdiWindTurbine",
        text: "fa-wind-turbine",
    },
    {
        key: "mdiGoogleClassroom",
        text: "fa-users-class",
    },
    {
        key: "mdiAccountGroup",
        text: "fa-users",
    },
    {
        key: "mdiBookOpen",
        text: "fa-books",
    },
    {
        key: "mdiWrench",
        text: "fa-wrench",
    },
    {
        key: "mdiFlash",
        text: "fa-bolt",
    },
    {
        key: "mdiEye",
        text: "fa-eye",
    },
    {
        key: "mdiCandyCane",
        text: "fa-candycane",
    },
    {
        key: "mdiFirework",
        text: "fa-firework",
    },
    {
        key: "mdiPartyPopper",
        text: "fa-partypopper",
    },
    {
        key: "mdiPin",
        text: "fa-pin",
    },
];
