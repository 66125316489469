import { INavLink } from "@fluentui/react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Action } from "redux";
import { localize } from "src/l10n";
import { Breadcrumbs, ContentWithSubmenu, Submenu } from "src/ui";
import { MediaFolderView, MediaImageView } from ".";
import { fetchMediaFolder, resetMediaFolderPath, resetMediaSearchTag, setMediaTag } from "../media-actions";
import api from "src/spintr/SpintrApi";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from "src/sidebar";

interface IProps {
    match: any;
    location: any;
    history: any;
    tagId: number;
    folderPath: Spintr.IBreadcrumbItem[];
    isSmallViewMode: boolean;
    dispatch: (action: Action) => void;
}

interface IState { }

class MediaView extends Component<IProps, IState> {
    private _isMounted: boolean = false;

    constructor(props: IProps) {
        super(props);

        this.props.dispatch(resetMediaSearchTag());
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (!this.props.match.params.folderId && !this.props.match.params.imageId) {
            this.props.dispatch(resetMediaFolderPath());
        }

        if (this.props.match.params.folderId > 0) {
            this.props.dispatch(fetchMediaFolder(this.props.match.params.folderId));
        }

        this.updateSideBar();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            let tagId = 0;

            if (!!this.props.location.search &&
                this.props.location.search.length > 0 &&
                this.props.location.search.indexOf("=") > -1) {
                tagId = this.props.location.search.split("=")[1];
            }

            this.props.dispatch(setMediaTag(tagId))
        }

        if (
            !this.props.match.params.imageId &&
            this.props.match.params.folderId > 0 &&
            prevProps.match.params.folderId !== this.props.match.params.folderId
        ) {
            this.props.dispatch(fetchMediaFolder(this.props.match.params.folderId));
        }
    }

    renderContent(): JSX.Element {
        return (
            <>
                {this.props.match.params.imageId ? (
                    <MediaImageView />
                ) : (
                    <MediaFolderView />
                )}
            </>
        );
    }

    updateSideBar() {
        let folderId = this.props.match.params.folderId || 0;

        const doFetch = () => {
            api.get(`/api/media/tags?folderId=${folderId}`).then(response => {
                if (!this._isMounted) {
                    return;
                }

                let items: IVisageSidebarMenuItem[] = [{
                    key: "media",
                    name: localize("Media"),
                    icon: "Image",
                    activeIcon: "Image",
                    route: "/media",
                    routes: ["/media"],
                    search: "",
                    children: response.data && response.data.length > 0 ? [{
                        key: "media-tags",
                        name: localize("Taggar"),
                        children: [
                            {
                                key: "media-tag-" + 0,
                                name: localize("Alla"),
                                id: 0,
                                route: "/media",
                                routes: ["/media"],
                            },
                            ...response.data.map(tag => {
                                return {
                                    key: "media-tag-" + tag.id,
                                    name: tag.text,
                                    id: tag.id,
                                    route: "/media",
                                    routes: ["/media"],
                                    search: "?tagId=" + tag.id
                                }
                            })
                        ]
                    }] : undefined,
                    isExpanded: response.data && response.data.length > 0,
                    isLoading: false,
                    moduleKey: "gallerytop",
                    activeMode: VisageSidebarMenuItemActiveMode.relative
                }];

                this.props.dispatch(setSidebarItems(items, "gallerytop"));
                this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
            }).catch(() => { });
        }

        if (this.props.match.params.imageId) {
            api.get(
                `/api/images/${this.props.match.params.imageId}`
            ).then(response => {
                folderId = response.data.gallery.id;

                doFetch();
            }).catch(() => { });
        } else {
            doFetch();
        }
    }

    render() {
        if (this.props.isSmallViewMode) {
            return this.renderContent();
        }

        return (
            <div className="MediaView">
                <Helmet>
                    <title>{localize("Media")}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            key: "0",
                            text: localize("Mediabank"),
                            link: "/media",
                        },
                        ...this.props.folderPath,
                    ]}
                />
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        tagId: state.media.media.get("tagId"),
        folderPath: state.media.media.get("folderPath"),
        isSmallViewMode: state.ui.isSmallViewMode
    };
};

export default withRouter(connect(mapStateToProps)(MediaView));
