import {
    DefaultButton,
    PrimaryButton, TextField
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { saveCategoryFulfilled } from 'src/groups/planning-actions';
import "src/groups/views/planning/GroupPlanningView.scss";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { Loader } from "src/ui";
import { FormControl } from "src/ui/components/Forms";
import { validateRequiredTextField } from "src/utils";
import api from "src/spintr/SpintrApi";

interface IProps {
    dispatch: any;
    toggleshowAddCategory: any;
    groupId: number;
}

class PlanningAddCategory extends Component<IProps> {
    state = {
        title: "",
        isCreatingCategory: false,
        enableFieldValidation: false
    };

    handleChangeTitle = e => this.setState({ title: e.target.value });

    createCategory = async () => {
        this.setState({
            isCreatingCategory: true,
            enableFieldValidation: true
        }, () => {
            if (!this.state.title ||
                this.state.title.length === 0) {
                this.setState({
                    isCreatingCategory: false
                });

                return;
            }

            const c = {
                id: 0,
                projectId: this.props.groupId,
                title: this.state.title
            };
    
            api.post(`/api/groupplanning/${this.props.groupId}/category/0`, c).then((response) => {
                this.props.dispatch(saveCategoryFulfilled(this.props.groupId, response.data));

                this.setState({
                    isCreatingCategory: false
                }, () => {
                    this.props.toggleshowAddCategory();
                });
            });
        });
    };

    onEnter = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.createCategory();
        }
    };

    cancel() {
        let fn = () => {
            this.props.toggleshowAddCategory();
        }

        if (this.state.title &&
            this.state.title.length > 0) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: fn
            }));
        } else {
            fn();
        }
    }

    render() {
        return (
            <div className="Planning-AddCategory">
                <form>
                    {
                        this.state.isCreatingCategory ?
                            <Loader /> :
                            <div>
                                <FormControl>
                                    <TextField
                                        className="textField"
                                        value={this.state.title}
                                        onChange={this.handleChangeTitle}
                                        placeholder={localize("Titel")}
                                        onKeyDown={this.onEnter}
                                        maxLength={1024}
                                        autoFocus={true}
                                        required
                                        aria-required
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <div className="Planning-AddCategoryFooter">
                                    <DefaultButton onClick={this.cancel.bind(this)}>
                                        {
                                            localize("Avbryt")
                                        }
                                    </DefaultButton>
                                    <PrimaryButton className="Planning-AddCategorySaveButton" onClick={this.createCategory}>
                                        {
                                            localize("SkapaBrada")
                                        }
                                    </PrimaryButton>
                                </div>
                            </div>
                    }
                </form>
            </div>
        );
    }
}

export default connect()(PlanningAddCategory);
