import { MessageBar, MessageBarType } from "@fluentui/react";
import React, { memo } from "react";

interface Props {
    message: string;
    onDismiss: () => void;
}

export default memo(function SuccessMessagebar(props: Props) {
    const { message, onDismiss } = props;
    return (
        <MessageBar
            dismissButtonAriaLabel="Close"
            onDismiss={onDismiss}
            messageBarType={MessageBarType.success}
            isMultiline={false}
        >
            {message}
        </MessageBar>
    );
});
