import { AxiosResponse } from "axios";
import {
    DatePicker,
    DayOfWeek,
    DefaultButton,
    Dropdown,
    Link, PrimaryButton,
    Separator,
    Stack,
    StackItem,
    TextField
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label } from "src/ui";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { scrollToTop } from "src/utils";
import formatDatePickerDate from "src/utils/formatDatePickerDate";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import { UnstyledButton } from "../ui/components";
import { MandatoryText } from "../ui/components/Forms";
import "./AbsenceView.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";


interface IProps {
    id: number;
    onCancel: any;
    api?: any;
    history?: any;
    match?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    currentUserId?: number;
    currentUserName?: string;
    currentUserDepartmentName?: string;
    currentUserImageUrl?: string;
    requiredTagCount?: number;
    isModal?: boolean;
}

interface IState {
    isLoading: boolean;
    absence: any;
    saveError: string[];
    absenceTypes: any;
    targets: any;
    canCreateNewAbsenceType: boolean;
    createNewAbsence: boolean;
    newAbsenceType: string;
    enableFieldValidation?: boolean;
}

class AbsenceEditView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            saveError: [],
            absenceTypes: [],
            absence: {},
            targets: [],
            canCreateNewAbsenceType: false,
            createNewAbsence: false,
            newAbsenceType: "",
        };
    }

    initPage(absence) {
        let dateFrom = new Date();
        let dateTo = new Date();

        dateTo.setDate(dateTo.getDate() + 1);

        if (absence.dateFrom) {
            dateFrom = new Date(absence.dateFrom);
        }

        if (absence.dateTo) {
            dateTo = new Date(absence.dateTo);
        }

        if (this.props.id !== 0) {
            absence.targets = [{ id: absence.userId, name: absence.userName }];
        }

        this.setState({
            isLoading: absence.isLoading,
            targets: absence.targets,
            absence: {
                id: absence.id,
                dateFrom: dateFrom,
                dateTo: dateTo,
                title: absence.title,
                userId: this.props.currentUserId,
                typeId: absence.typeId,
                type: { id: absence.type.id, name: absence.type.name },
                userIds: [],
            },
            saveError: [],
        });
    }
    componentDidMount() {
        api
            .get("/api/absence/settings/enableuseraddabsencetype")
            .then((response) => this.setState({ isLoading: false, canCreateNewAbsenceType: response.data }));

        api
            .get("/api/absence/types", {
                params: {
                    take: 100,
                },
            })
            .then((pagesResponse: AxiosResponse) => {
                if (this.props.id === 0) {
                    this.setState((state) => ({
                        absenceTypes: pagesResponse.data.types,
                        absence: {
                            ...state.absence,
                            typeId: pagesResponse.data.types[0].id,
                            type: pagesResponse.data.types[0],
                        },
                    }));
                } else {
                    this.setState({
                        absenceTypes: pagesResponse.data.types,
                    });
                }
            });

        if (this.props.id === 0) {
            this.initPage({
                isNew: true,
                targets: [],
                type: {
                    id: "",
                    name: "",
                },
            });
        } else {
            let fetchPage = () => {
                api.get("/api/absence/" + this.props.id).then((response) => {
                    this.initPage(response.data);
                });
            };

            fetchPage();
        }
    }

    onCancelClick() {
        this.props.onCancel();
    }

    async onSaveClick(event) {
        event.preventDefault();

        this.setState({
            isLoading: true,
            enableFieldValidation: true
        }, () => {
            let absence = { ...this.state.absence };
            let userIds = this.state.targets.map((obj) => obj.id);

            if (!userIds || userIds.length === 0) {
                this.setState({
                    saveError: ["ABSENCE_SELECT_USER_VALIDATION_MESSAGE"],
                    isLoading: false,
                    enableFieldValidation: true
                }, () => {
                    scrollToTop();
                });

                return;
            }

            absence.userIds = userIds;

            absence.dateFrom = new Date(
                Date.UTC(absence.dateFrom.getFullYear(), absence.dateFrom.getMonth(), absence.dateFrom.getDate())
            );

            absence.dateTo = new Date(
                Date.UTC(absence.dateTo.getFullYear(), absence.dateTo.getMonth(), absence.dateTo.getDate())
            );

            api.post("/api/absence", absence).then(() => {
                this.props.onCancel();
            }).catch((error) => {
                let saveError = ["TeknisktFel"];

                if (error && error.response && error.response.data && error.response.data.errorlist) {
                    saveError = error.response.data.errorlist;
                }

                this.setState({
                    saveError,
                    isLoading: false,
                }, () => {
                    scrollToTop();
                });
            });
        });
    }

    saveNewAbsenceType() {
        let newType = {
            id: 0,
            name: this.state.newAbsenceType,
            color: "38d14d",
            isNew: true,
        };

        api.post("/api/absence/types", newType).then(() => this.getAbsencesTypes());
    }

    getAbsencesTypes() {
        api
            .get("/api/absence/types", {
                params: {
                    take: 100,
                },
            })
            .then((pagesResponse: AxiosResponse) => {
                this.setState({
                    absenceTypes: pagesResponse.data.types,
                });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const absence = this.state.absence;
        let types = [];

        this.state.absenceTypes.map((obj) => {
            types.push({ key: obj.id, text: localize(obj.name) });
        });

        let showIcon = false;
        if (this.state.canCreateNewAbsenceType || this.props.isAdmin || this.props.isEditor) {
            showIcon = true;
        }

        return (
            <div className="AbsenceEditView">
                {!this.props.isModal && (
                    <div>
                        <Stack horizontal verticalAlign="center">
                            <StackItem grow={1}>
                                <div className="mandatoryText">
                                    <Label as="h2" size="h2">
                                        {this.props.id === 0
                                            ? localize("LaggTillFranvaroperiod")
                                            : this.props.id > 0
                                                ? localize("Redigera") + " " + localize("Franvaro").toLowerCase()
                                                : ""}
                                    </Label>
                                </div>
                            </StackItem>
                            <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.onCancelClick.bind(this)}>
                                <Visage2Icon icon="close-circle" />
                            </UnstyledButton>
                        </Stack>
                        <Separator />
                    </div>
                )}
                <MandatoryText />
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <form onSubmit={this.onSaveClick.bind(this)}>
                    <FormSection title={localize("Anvandare")}>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={this.state.targets}
                                types={[SpintrTypes.UberType.User]}
                                label={localize("Franvaro") + " " + localize("For").toLowerCase()}
                                required
                                validateOnLoad={this.state.enableFieldValidation}
                                onChange={(targets) => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        targets: targets.map((e) => {
                                            return {
                                                id: e.key,
                                                name: e.name,
                                                type: SpintrTypes.UberType.User,
                                                key: e.key,
                                                info: e.subText,
                                                imageUrl: e.imageUrl,
                                            };
                                        }),
                                    }));
                                }}
                            />
                        </FormControl>
                    </FormSection>
                    <FormSection title={localize("Franvaro")}>
                        <FormControl>
                            <Dropdown
                                label={localize("TypAv") + " " + localize("Franvaro").toLowerCase()}
                                selectedKey={absence.typeId}
                                onChange={(_e, v) => {
                                    this.setState((state) => ({
                                        absence: {
                                            ...state.absence,
                                            typeId: Number(v.key),
                                            type: {
                                                id: Number(v.key),
                                                name: v.text,
                                            },
                                        },
                                    }));
                                }}
                                options={types}
                                styles={{ dropdown: { width: 250 } }}
                            />
                        </FormControl>
                    </FormSection>
                    <FormControl>


                        {showIcon && (
                            <div
                                className="createNewAbsenceIcon"
                                style={{ height: 30, cursor: "pointer" }}
                                onClick={() => this.setState({ createNewAbsence: !this.state.createNewAbsence })}
                            >
                                <Visage2Icon icon={"add"} />
                                <Label size="small-1">{localize("NyFranvarotyp")}</Label>
                            </div>
                        )}

                        {this.state.createNewAbsence && (
                            <div className="createNewAbsenceTextField">
                                <TextField
                                    value={this.state.newAbsenceType}
                                    className="textField"
                                    label={localize("Namn")}
                                    resizable={false}
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                        this.setState({
                                            ...this.state,
                                            newAbsenceType: newValue,
                                        });
                                    }}
                                />
                                {this.state.newAbsenceType && (
                                    <Link
                                        style={{ marginTop: 36, marginLeft: 10 }}
                                        onClick={() => this.saveNewAbsenceType()}
                                    >
                                        {localize("LaggTill")}
                                    </Link>
                                )}
                            </div>
                        )}
                    </FormControl>
                    <FormSection title={localize("Datum")}>
                        <FormControl>
                            <DatePicker
                                //style={{ maxWidth: 400 }}
                                label={localize("Fran")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={absence.dateFrom}
                                isRequired={true}
                                onSelectDate={(date: Date) => {
                                    this.setState((state) => ({
                                        absence: {
                                            ...state.absence,
                                            dateFrom: date,
                                        },
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <DatePicker
                                //style={{ maxWidth: 400 }}
                                label={localize("Till")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={absence.dateTo ? absence.dateTo : null}
                                isRequired={true}
                                onSelectDate={(date: Date) => {
                                    this.setState((state) => ({
                                        absence: {
                                            ...state.absence,
                                            dateTo: date,
                                        },
                                    }));
                                }}
                            />
                        </FormControl>
                    </FormSection>

                    <FormControl>
                        <TextField
                            value={absence.title}
                            className="textField"
                            label={localize("BeskrivningAvFranvaro")}
                            multiline
                            resizable={false}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                this.setState({
                                    absence: {
                                        ...this.state.absence,
                                        title: newValue,
                                    },
                                });
                            }}
                        />
                    </FormControl>
                    <Label className="absenceBtn">
                        <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                            <DefaultButton onClick={this.onCancelClick.bind(this)} text={localize("Avbryt")} />
                            <PrimaryButton type={"submit"} text={localize("Spara")} />
                        </Stack>
                    </Label>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(AbsenceEditView));
