import { Modal } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminRssEditView from "src/admin/views/AdminRssEditView";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { SocialFeedType } from "src/social-feed";
import { SpintrSocialFeed } from "src/spintr/index";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import PopupHeader from "src/ui/components/PopupHeader";
import { isAnythingDirty } from "src/utils";
import VisageFilterButton from "../VisageFilterButton/VisageFilterButton";
import { fetchContentCategories } from "./actions";
import InformationFeedLoader from "./InformationFeedLoader";
import "./VisageInformationFeed.scss";
import { TeaserBoxLoader } from "src/teaser-box";
import TeaserBoxBlogSlider from "src/teaser-box/components/TeaserBoxBlogSlider";
import { Label, UnstyledButton } from "src/ui";
import { ReactSVG } from "react-svg";

interface IOwnProps { }

interface IStateProps {
    socialFeedEnabled: boolean;
    blogsEnabled: boolean;
    newsEnabled: boolean;
    rssEnabled: boolean;
    userFeedId: number;
    userGalleryId: number;
    history: any;
    primaryColor: string;
    dispatch?: any;
    hasFetchedCategories?: boolean;
    categories?: any[];
    primaryAppFeedTab?: string;
    displayBlogTeaserBoxes?: boolean;
    template?: SpintrTypes.NewsFeedType;
    importantArticles: Spintr.IInformationFeedItem[];
}

export enum InformationFeedType {
    Feed = 0,
    All = 1,
    News = 2,
    Blogs = 3,
    RSS = 4,
    ContentCategory = 5
}

interface IState {
    type: InformationFeedType;
    contentCategoryId?: number;
    rssMenu: any[];
    showRSSModal: boolean;
    isLoadingRSS: boolean;
    displayImportantArticlesOnly: boolean;
}

type Props = IOwnProps & IStateProps;

class VisageInformationFeed extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            type: (this.props.newsEnabled && this.props.blogsEnabled ?
                InformationFeedType.All :
                this.props.newsEnabled ?
                    InformationFeedType.News :
                    this.props.blogsEnabled ?
                        InformationFeedType.Blogs :
                        InformationFeedType.RSS),
            rssMenu: [],
            showRSSModal: false,
            isLoadingRSS: false,
            displayImportantArticlesOnly: false
        };
    }

    componentDidMount() {
        if (!this.props.hasFetchedCategories) {
            this.props.dispatch(fetchContentCategories());
        }

        if (this.props.rssEnabled) {
            this.fetchRSSFeeds();
        } 

        if (this.props.primaryAppFeedTab) {
            switch (this.props.primaryAppFeedTab) {
                case "all":
                    this.setFilter(InformationFeedType.All);
                    break;
                case "news":
                    this.setFilter(InformationFeedType.News);
                    break;
                case "blogs":
                    this.setFilter(InformationFeedType.Blogs);
                    break;
                case "rss":
                    this.setFilter(InformationFeedType.RSS);
                    break;
                default:
                    this.setFilter(InformationFeedType.All);
                    break;
            }            
        }
    }

    renderInformationFeed() {
        const props = {
            includeNews: this.state.type === InformationFeedType.All ||
                this.state.type === InformationFeedType.News ||
                this.state.type === InformationFeedType.ContentCategory,
            includeBlogPosts: this.state.type === InformationFeedType.All ||
                this.state.type === InformationFeedType.Blogs ||
                this.state.type === InformationFeedType.ContentCategory,
            isRss: this.state.type === InformationFeedType.RSS,
            categoryId: this.state.contentCategoryId,
            template: this.props.template,
            displayImportantArticlesOnly: this.state.displayImportantArticlesOnly
        }

        return (
            <InformationFeedLoader {...props} />
        )
    }

    setFilter(type: InformationFeedType, contentCategoryId?: number) {
        this.setState({
            type,
            contentCategoryId: contentCategoryId
        })
    }

    fetchRSSFeeds = () => {
        this.setState({ isLoadingRSS: true }, () => {
            api.get(`/api/rss`).then((response) => {
                this.setState({
                    rssMenu: response.data,
                    isLoadingRSS: false,
                });
            });
        });
    };

    closeRSSModal = () => {
        const close = () => {
            this.setState({
                showRSSModal: false
            });
        }

        if (isAnythingDirty()) {
            this.props.dispatch(setConfirmPopup({
                isOpen: true,
                message: localize("UnsavedChangesWarning"),
                onConfirm: close
            }));
        } else {
            close();
        }
    }

    renderRSSViewModal = () => {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader"
                isOpen={this.state.showRSSModal}
                onDismiss={this.closeRSSModal.bind(this)}
            >
                <PopupHeader
                    text={localize("LaggTillNyFeed")}
                    subText={localize("RSSFeedInfo")}
                    onClose={this.closeRSSModal.bind(this)} />
                <AdminRssEditView
                    isModal={true}
                    onCancel={this.closeRSSModal.bind(this)}
                    postSave={() => {
                        this.setState({
                            showRSSModal: false
                        });

                        this.fetchRSSFeeds();
                    }}
                />
            </Modal>
        );
    };

    toggleRSSSubscription = (m) => {
        let rssMenu = [...this.state.rssMenu];
        let idx = rssMenu.findIndex((i) => {
            return i.id === m.id;
        });
        rssMenu[idx].isUserSubscribed = !m.isUserSubscribed;
        this.setState({ rssMenu }, () => {
            this.updateSubscriptions();
        });
    };

    updateSubscriptions = () => {
        let subscriptions = this.state.rssMenu
            .map((r) => {
                if (r.isUserSubscribed) return r.id;
            })
            .filter(Boolean);

        this.setState({ isLoadingRSS: true }, () => {
            api.put(`/api/v1/users/rsssubscriptions`, { subscriptions }).then((_response) => {
                this.fetchRSSFeeds();
            });
        });
    };

    getCategories() {
        let categories: Spintr.IActionMenuCategory[] = [
            {
                items: [],
            },
        ];

        // if (this.props.socialFeedEnabled) {
        //     categories[0].items.push({
        //         key: "feed",
        //         onClick: () => {
        //             this.setFilter(InformationFeedType.Feed)
        //         },
        //         text: localize("Flode")
        //     });
        // }

        if (this.props.newsEnabled && this.props.blogsEnabled) {
            categories[0].items.push({
                key: "all",
                onClick: () => {
                    this.setFilter(InformationFeedType.All)
                },
                text: localize("Nyheter") + " " + localize("Och_small") + " " + localize("Bloggar").toLowerCase()
            });
        }

        if (this.props.newsEnabled) {
            categories[0].items.push({
                key: "news",
                onClick: () => {
                    this.setFilter(InformationFeedType.News)
                },
                text: localize("Nyheter")
            });
        }

        if (this.props.blogsEnabled) {
            categories[0].items.push({
                key: "blogs",
                onClick: () => {
                    this.setFilter(InformationFeedType.Blogs)
                },
                text: localize("Bloggar")
            });
        }

        if (this.props.rssEnabled) {
            categories[0].items.push({
                key: "rss",
                onClick: () => {
                    this.setFilter(InformationFeedType.RSS)
                },
                text: localize("appRSS")
            });
        }

        if (!!this.props.categories &&
            this.props.categories.length > 0) {
            categories.push({
                title: localize("Kategorier"),
                items: this.props.categories.map((c: any) => {
                    return {
                        key: c.id.toString(),
                        text: c.name,
                        onClick: () => {
                            this.setFilter(InformationFeedType.ContentCategory, c.id)
                        },
                    }
                })
            })
        }

        if (this.props.newsEnabled ||
            this.props.rssEnabled) {
            let category = {
                title: localize("MARKETPLACE_CATEGORY_MISC"),
                items: []
            };

            if (this.props.newsEnabled) {
                category.items.push({
                    key: "news-archive",
                    onClick: () => {
                        this.props.history.push(`/news`)
                    },
                    text: localize("GaTillNyhetsarkivet")
                });
            }

            if (this.props.rssEnabled) {
                category.items.push({
                    key: "rss-settings",
                    onClick: () => {
                        this.props.history.push(`/news`)
                    },
                    text: localize("appRSS") + " " + localize("Installningar").toLowerCase(),
                    iconProps: { iconName: "More" },
                    subMenuProps: {
                        className: "displayOptions",
                        items: [
                            ...this.state.rssMenu.map((m) => {
                                return {
                                    key: m.id.toString(),
                                    text: m.name,
                                    id: m.id,
                                    isChecked: m.forceSubscription || m.isUserSubscribed,
                                    canCheck: true,
                                    onClick: () => {
                                        this.toggleRSSSubscription(m);
                                    },
                                };
                            }),
                            {
                                key: "add-rss",
                                text: localize("LaggTillRssFlode"),
                                onClick: () => {
                                    this.setState({ showRSSModal: true });
                                },
                            },
                        ]
                    },
                });
            }

            categories.push(category);
        }

        return categories;
    }

    getFilterButtonText(): string {
        let result = "";

        if (this.state.type === InformationFeedType.Feed) {
            result = localize("Flode");
        } else if (this.state.type === InformationFeedType.All) {
            result = localize("Nyheter") + " " + localize("Och_small") + " " + localize("Bloggar").toLowerCase();
        } else if (this.state.type === InformationFeedType.News) {
            result = localize("Nyheter");
        } else if (this.state.type === InformationFeedType.Blogs) {
            result = localize("Bloggar");
        } else if (this.state.type === InformationFeedType.RSS) {
            result = localize("appRSS");
        } else if (!!this.props.categories) {
            for (let c of this.props.categories) {
                if (c.id === this.state.contentCategoryId) {
                    result = c.name;
                }
            }
        }

        return result;
    }

    public render(): ReactNode {
        return (
            <div className="VisageInformationFeed">
                <div className="header">
                    <VisageFilterButton
                        inSocialFeed={false}
                        labelText={this.getFilterButtonText()}
                        renderButton={() => {
                            if (!this.props.importantArticles ||
                                this.props.importantArticles.length === 0) {
                                return null;
                            }

                            return (
                                <UnstyledButton
                                    className={[
                                        "important-articles-filter-button",
                                        this.state.displayImportantArticlesOnly ? "primaryBGColor" : "",
                                        this.state.displayImportantArticlesOnly ? "active" : ""
                                    ].join(" ")}
                                    onClick={() => {
                                        this.setState({
                                            displayImportantArticlesOnly: !this.state.displayImportantArticlesOnly
                                        })
                                    }}>
                                    <ReactSVG src={"/images/light_bulb.svg"} />
                                    <Label size="body-2" weight="normal" color={this.state.displayImportantArticlesOnly ? "white" : "dark-grey"}>
                                        {localize(this.props.importantArticles.length === 1 ?
                                            "NICE_TO_KNOW_ARTICLES_FILTER_BUTTON_SINGLE" :
                                            "NICE_TO_KNOW_ARTICLES_FILTER_BUTTON_MULTIPLE").replace("{0}", this.props.importantArticles.length.toString())}
                                    </Label>
                                </UnstyledButton>
                            )
                        }}
                        categories={this.getCategories()} />
                </div>
                <div className="content">
                    {
                        this.state.type === InformationFeedType.Feed && (
                            <SpintrSocialFeed
                                feedId={this.props.userFeedId}
                                feedType={SocialFeedType.Combined}
                                galleryId={this.props.userGalleryId}
                                isStartPage
                            />
                        )
                    }
                    {
                        this.state.type !== InformationFeedType.Feed && this.renderInformationFeed()
                    }
                </div>
                {this.renderRSSViewModal()}
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        primaryColor: state.instance.get("color") as string,
        blogsEnabled: state.app.items.some((app) => app.enabled && app.id === 2),
        newsEnabled: state.app.items.some((app) => app.enabled && app.id === 5),
        rssEnabled: state.app.items.some((app) => app.enabled && app.id === 11),
        socialFeedEnabled: state.ui.viewMode < SpintrTypes.ViewMode.SmallestLaptop,
        userFeedId: state.profile.active.feedId,
        userGalleryId: state.profile.active.galleryId,
        hasFetchedCategories: state.informationFeed.hasFetchedCategories,
        categories: state.informationFeed.categories,
        primaryAppFeedTab: state.instance.get("primaryAppFeedTab"),
        importantArticles: state.informationFeed.importantArticles.filter(x => x.priorityLevel === 4),
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(VisageInformationFeed));
