import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";

import { IRemotePostDeletedAction } from "src/social-feed/actions";
import { DeleteObjectAction } from "src/spintr/actions";
import api from "src/spintr/SpintrApi";
import { InteractionActionTypes } from './action-types';

export interface IFetchCommentsMeta {
    uberId: number;
}

export interface IFetchCommentsAction extends Action {
    meta: IFetchCommentsMeta;
    payload: Promise<AxiosResponse<any>>;
    type: InteractionActionTypes.FetchComments;
}

export interface IFetchCommentsFulfilledAction extends Action {
    meta: IFetchCommentsMeta;
    payload: AxiosResponse<any>;
    type: InteractionActionTypes.FetchCommentsFulfilled;
}

export interface IFetchCommentsPendingAction extends Action {
    meta: IFetchCommentsMeta;
    type: InteractionActionTypes.FetchCommentsPending;
}

export interface IFetchCommentsRejectedAction extends Action {
    meta: IFetchCommentsMeta;
    payload: AxiosError<any>;
    type: InteractionActionTypes.FetchCommentsRejected;
}

export type FetchCommentsHandler = (uberId: number) => IFetchCommentsAction;
export const fetchComments: FetchCommentsHandler =
    (uberId) => ({
        meta: {
            uberId
        },
        payload: api.get('/api/comments?objectId=' + uberId),
        type: InteractionActionTypes.FetchComments,
    }
);

export type FetchCommentsAction =
      IFetchCommentsAction
    | IFetchCommentsPendingAction
    | IFetchCommentsRejectedAction
    | IFetchCommentsFulfilledAction;

export interface ISetCommentsMeta {
    comments: any;
    uberId: number;
}

export interface ISetCommentsAction {
    meta: ISetCommentsMeta;
    type: InteractionActionTypes.SetComments;
}

export type SetCommentsHandler = (uberId: number, comments: any) => ISetCommentsAction;
export const setComments: SetCommentsHandler =
    (uberId, comments) => ({
        meta: {
            comments, uberId,
        },
        type: InteractionActionTypes.SetComments,
    });

export interface IFetchLikersMeta {
    uberId: number;
}

export interface IFetchLikersAction {
    meta: IFetchLikersMeta;
    payload: Promise<AxiosResponse<any>>;
    type: InteractionActionTypes.FetchLikers;
}

export interface IFetchLikersFulfilledAction {
    meta: IFetchLikersMeta;
    payload: AxiosResponse<any>;
    type: InteractionActionTypes.FetchLikersFulfilled;
}

export interface IFetchLikersPendingAction {
    meta: IFetchLikersMeta;
    type: InteractionActionTypes.FetchLikersPending;
}

export interface IFetchLikersRejectedAction {
    meta: IFetchLikersMeta;
    type: InteractionActionTypes.FetchLikersRejected;
}

export type FetchLikersAction =
      IFetchLikersAction
    | IFetchLikersFulfilledAction
    | IFetchLikersPendingAction
    | IFetchLikersRejectedAction;

export type FetchLikersHandler = (uberId: number) => IFetchLikersAction;
export const fetchLikers: FetchLikersHandler =
    (uberId) => ({
        meta: { uberId },
        payload: api.get<any>('/api/like?objectId=' + uberId),
        type: InteractionActionTypes.FetchLikers,
    }
);

export interface ISetLikersMeta {
    likers: any;
    uberId: number;
}

export interface ISetLikersAction {
    meta: ISetLikersMeta;
    type: InteractionActionTypes.SetLikers;
}

export type SetLikersHandler = (uberId: number, likers: any) => ISetLikersAction;
export const setLikers: SetLikersHandler =
    (uberId, likers) => ({
        meta: { likers, uberId },
        type: InteractionActionTypes.SetLikers,
    }
);

export interface IAddLikeMeta {
    uberId: number;
    user: any;
    identity?: any;
}

export interface IAddLikeAction {
    meta: IAddLikeMeta;
    payload?: Promise<AxiosResponse<any>>;
    type: InteractionActionTypes.AddLike;
}

export interface IAddLikeFulfilledAction {
    meta: IAddLikeMeta;
    payload?: AxiosResponse<any>;
    type: InteractionActionTypes.AddLikeFulfilled;
}

export interface IAddLikePendingAction {
    meta: IAddLikeMeta;
    type: InteractionActionTypes.AddLikePending;
}

export type AddLikeAction =
      IAddLikeAction
    | IAddLikeFulfilledAction
    | IAddLikePendingAction;

export type AddLikeHandler = (uberId: number, user: any, identity?: any) => IAddLikeAction;

export const addLikeRealtime: AddLikeHandler =
    (uberId, user) => ({
        meta: {
            uberId,
            user
        },
        type: InteractionActionTypes.AddLike,
    }
);

export const addLike: AddLikeHandler =
    (uberId, user, identity) => ({
        meta: {
            uberId,
            user,
            identity,
        },
        payload: api.post('/api/like', { id: uberId, likeType: !!user.likeType ? user.likeType : 0 }, !!identity && { headers: {
            "x-impersonate-user": identity?.id || 0
        }}),
        type: InteractionActionTypes.AddLike,
    });

export interface IRemoveLikeMeta {
    uberId: number;
    user: any;
    identity?: any;
}

export interface IRemoveLikeAction {
    meta: IRemoveLikeMeta;
    payload?: Promise<AxiosResponse<any>>;
    type: InteractionActionTypes.RemoveLike;
}

export interface IRemoveLikeFulfilledAction {
    meta: IRemoveLikeMeta;
    payload?: AxiosResponse<any>;
    type: InteractionActionTypes.RemoveLikeFulfilled;
}

export interface IRemoveLikePendingAction {
    meta: IRemoveLikeMeta;
    type: InteractionActionTypes.RemoveLikePending;
}

export type RemoveLikeAction =
    IRemoveLikeAction
    | IRemoveLikeFulfilledAction
    | IRemoveLikePendingAction;

export type RemoveLikeHandler = (uberId: number, user: any, identity?: any) => IRemoveLikeAction;
export const removeLikeRealtime: RemoveLikeHandler =
    (uberId, user) => ({
        meta: {
            uberId,
            user
        },
        type: InteractionActionTypes.RemoveLike,
    });

export const removeLike: RemoveLikeHandler =
    (uberId, user, identity) => ({
        meta: {
            uberId,
            user,
            identity
        },
        payload: api.delete('/api/like/' + uberId, !!identity && { headers: {
            "x-impersonate-user": identity?.id || 0
        }}) ,
        type: InteractionActionTypes.RemoveLike,
    });

export interface IAddCommentMeta {
    comment: Spintr.IComment;
    fromRealtime?: boolean;
}

export interface IAddCommentAction {
    meta: IAddCommentMeta;
    type: InteractionActionTypes.AddComment;
}

export type AddCommentHandler = (comment: Spintr.IComment) => IAddCommentAction;
export const addCommentRealtime: AddCommentHandler =
    (comment) => ({
        meta: { comment, fromRealtime: true },
        type: InteractionActionTypes.AddComment,
    });

export const addComment: AddCommentHandler =
    (comment) => ({
        meta: { comment },
        type: InteractionActionTypes.AddComment,
    })

export interface IUpdateCommentMeta {
    comment: Spintr.IComment;
}

export interface IUpdateCommentAction {
    meta: IUpdateCommentMeta;
    type: InteractionActionTypes.UpdateComment;
}

export type UpdateCommentHandler = (comment: Spintr.IComment) => IUpdateCommentAction;
export const updateCommentRealtime: UpdateCommentHandler =
    (comment) => ({
        meta: { comment },
        type: InteractionActionTypes.UpdateComment,
    });

export interface IRemoveCommentMeta {
    id: number;
}

export interface IRemoveCommentAction {
    meta: IRemoveCommentMeta;
    type: InteractionActionTypes.RemoveComment;
}

export type RemoveCommentHandler = (id: number) => IRemoveCommentAction;
export const removeComment: RemoveCommentHandler =
    (id) => ({
        meta: { id },
        payload: api.delete('/api/comments/' + id),
        type: InteractionActionTypes.RemoveComment,
    });

export interface IMarkCommentAsAnswerMeta {
    id: number;
    value: boolean;
}

export interface IMarkCommentAsAnswerAction {
    meta: IMarkCommentAsAnswerMeta;
    type: InteractionActionTypes.MarkCommentAsAnswer;
}

export type MarkCommentAsAnswerHandler = (id: number, value: boolean) => IMarkCommentAsAnswerAction;
export const markCommentAsAnswer: MarkCommentAsAnswerHandler =
    (id, value) => ({
        meta: { id, value },
        type: InteractionActionTypes.MarkCommentAsAnswer,
    });

export type MarkCommentAsAnswer =
    IMarkCommentAsAnswerAction;

export const removeCommentRealtime: RemoveCommentHandler =
    (id) => ({
        meta: { id },
        type: InteractionActionTypes.RemoveComment,
    });

export interface IRemoveCommentFulfilledAction {
    meta: IRemoveCommentMeta;
    payload?: AxiosResponse<any>;
    type: InteractionActionTypes.RemoveCommentFulfilled;
}

export interface IRemoveCommentPendingAction {
    meta: IRemoveCommentMeta;
    type: InteractionActionTypes.RemoveCommentPending;
}

interface IClearInteractionsAction extends Action {
    type: InteractionActionTypes.Clear;
}

export type ClearInteractionsFeedHandler = () => IClearInteractionsAction;

export const clearInteractions: ClearInteractionsFeedHandler = () => ({
    type: InteractionActionTypes.Clear,
});

export type InteractionsAction =
      FetchCommentsAction
    | ISetCommentsAction
    | FetchLikersAction
    | ISetLikersAction
    | AddLikeAction
    | RemoveLikeAction
    | IAddCommentAction
    | DeleteObjectAction
    | IRemotePostDeletedAction
    | IRemoveCommentAction
    | IRemoveCommentFulfilledAction
    | IRemoveCommentPendingAction
    | IUpdateCommentAction
    | MarkCommentAsAnswer
    | IClearInteractionsAction;