import { Reducer } from "redux";

import { MenuAction, MenuActionTypes } from './action-types'

export interface IMainMenuState {
    failed: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    items: Spintr.IMainMenuItem[];
}

export interface IMenuState {
    fetchHasBeenInitialized: boolean;
    main: IMainMenuState;
}

const initialState: IMenuState = {
    main: {
        failed: false,
        isLoaded: false,
        isLoading: false,
        items: [],
    },
    fetchHasBeenInitialized: false
};

export type MenuReducer = Reducer<IMenuState, MenuAction>;

const correctUrlFromApi = (url: string): string => {
    switch (url) {
        case '/bloggar':
            return '/blogs';

        case '/filer':
            return '/files';

        case '/nyheter':
            return '/news';

        case '/grupper':
            return '/groups';

        default:
            return url;
    }
};

const transformMenuItem = (menuItem: Spintr.IMainMenuItem): Spintr.IMainMenuItem => {
    return {
        ...menuItem,
        url: correctUrlFromApi(menuItem.url),
    };
};

const menuReducer: MenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case MenuActionTypes.FetchMainMenuPending:
            if (action.meta && action.meta.isSilentFetch) {
                return state;
            }

            return {
                ...state,
                fetchHasBeenInitialized: true,
                main: {
                    ...state.main,
                    failed: false,
                    isLoaded: false,
                    isLoading: true,
                },
            };

        case MenuActionTypes.FetchMainMenuRejected:
            return {
                ...state,
                main: {
                    ...state.main,
                    failed: true,
                    isLoaded: true,
                    isLoading: false,
                },
            };

        case MenuActionTypes.FetchMainMenuFulfilled:
            const menuItems = action.payload.data.map(transformMenuItem);

            return {
                ...state,
                main: {
                    ...state.main,
                    failed: false,
                    isLoaded: true,
                    isLoading: false,
                    items: menuItems,
                },
            };

        case MenuActionTypes.RemoveMainMenuItem:
            return {
                ...state,
                main: {
                    ...state.main,
                    failed: false,
                    isLoaded: true,
                    isLoading: false,
                    items: state.main.items.filter((item) => item[action.byProperty] !== action.propertyValue),
                },
            };

        default:
            return state
    }
}

export default menuReducer
