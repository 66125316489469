import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, PageHeader } from "src/ui";
import "./TagsView.scss";

interface IProps {
    list: any;
}

interface IState {
    categories: any;
}

class TagsView extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { categories: [] };
    }

    public componentDidMount() {
        this.getLetterItems();
    }

    public render(): ReactNode {
        const homeText = localize("Hem");
        const tagsText = localize("Taggar");

        return (
            <div id="TagsView">
                <Helmet>
                    <title>{localize("Taggar")}</title>
                </Helmet>
                <Breadcrumbs
                    items={[
                        {
                            key: 0,
                            link: "/",
                            text: homeText,
                        },
                        {
                            key: 1,
                            text: tagsText,
                        },
                    ]}
                />
                <PageHeader title={tagsText} />
                <div id="TagsViewInner">
                    <div id="TagsViewBody">
                        <div className="index-container columns-enabled col4">
                            {this.state.categories.map(({ letter, items }, i) => (
                                <div id="indexContent" key={i} className="indexList">
                                    <div className="index-list">
                                        <div className="indexHeader">{letter}</div>
                                        <ul>
                                            {items.map((item, j) => (
                                                <li key={j}>
                                                    <Link to={`/search?q=%23${item}`}>
                                                        <span className="itemName">{item} </span>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private async getLetterItems() {
        const categories = [];

        try {
            const response = await api.get<string[]>("/api/tags/all");

            response.data.forEach((tag) => {
                let letter = tag.substring(0, 1).toUpperCase();
                const charCode = letter.charCodeAt(0);
                if (48 <= charCode && charCode <= 57) {
                    letter = "#";
                }


                let category = categories.find((c) => {
                    return c.letter === letter;
                });

                if (!category) {
                    category = {
                        items: [],
                        letter,
                    };

                    categories.push(category);
                }

                category.items.push(tag);
            });

            this.setState({ 
                categories: categories.sort(
                    (a, b) => {
                        let x = a.letter.charCodeAt(0);
                        let y = b.letter.charCodeAt(0);

                        if ((x === 197 && y === 196) || (x === 196 && y === 197)) {
                            const tmp = x;
                            x = y;
                            y= tmp;
                        }

                        return x - y;
                    },
                ),
            });
        } catch (err) {
            // TODO: Implement error handling
        }
    }
}

export default TagsView;
