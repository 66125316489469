import React from "react";
import "./PayrollView.scss";
import { Line } from "react-chartjs-2";
import { Label } from "src/ui";

interface IProps {
    data: any;
    title: string;
    isPercentage?: boolean;
}

const PayrollHomeSection = (props: IProps) => {
    return (
        <div>
            <Label>{props.title}</Label>
            <Line
                data={props.data}
                options={{
                    // layout: {
                    //     padding: {
                    //         top: 40,
                    //         right: 25
                    //     }
                    // },
                    //responsive: true,
                    //maintainAspectRatio: false,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            display: false,
                            position: "bottom"
                        },
                        tooltip: {
                            mode: "index",
                            intersect: false,
                            backgroundColor: '#3F4692',
                            cornerRadius: 16,
                            padding: 8,
                            caretSize: 6,
                            caretPadding: 6,
                            //yAlign: "bottom",
                            displayColors: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    let title = tooltipItem.label;

                                    // for (let item of this.props.data) {
                                    //     if (item.name == tooltipItem.label &&
                                    //         !!item.title) {
                                    //         title = item.title;
                                    //     }
                                    // }

                                    return title + ": " + tooltipItem.formattedValue + (props.isPercentage ? "%" : "");
                                },
                                title: () => "",
                            },
                        },
                    },
                    scales: {
                        y: {
                            grid: {
                                tickLength: 0,
                                borderDash: [5, 5],
                                color: "#EAECF1",
                            },
                            beginAtZero: true,
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                padding: 6,
                                font: {
                                    size: 10,
                                }
                            }
                        },
                        x: {
                            grid: {
                                tickLength: 0,
                                color: "#EAECF1",
                            },
                            beginAtZero: true,
                            ticks: {
                                padding: 6,
                                font: {
                                    size: 10,
                                }
                            },
                        }
                    }
                }}
            />
        </div>
    )
}

export default PayrollHomeSection;
