import axios, { AxiosResponse } from "axios";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, ContentWithSubmenu, Loader, PageHeader, SpintrUser, Submenu } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import CustomWeeklyTimeline from "src/ui/components/Timeline/CustomWeeklyTimeline";
import ShowMoreText from "react-show-more-text";
import "./ResourceView.scss";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
}

interface IState {
    isNew: boolean;
    isLoading: boolean;
    resource: any;
    saveError: string[];
    categories: any;
    targets: any;
    canCreateNewAbsenceType: boolean;
    createNewAbsence: boolean;
    newCategory: string;
    intervals: any;
    hours: any;
    events: any;
    showDeleteDialog: number;
}
class ResourceWeeklyView extends Component<IProps, IState> {
    private isNew = false;

    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            isLoading: true,
            saveError: [],
            categories: [],
            resource: {},
            targets: [],
            canCreateNewAbsenceType: false,
            createNewAbsence: false,
            newCategory: "",
            intervals: [{ start: "", end: "" }],
            hours: [],
            events: [],
            showDeleteDialog: undefined,
        };
    }

    initPage(resource) {
        this.setState({
            isNew: resource.isNew,

            resource: {
                id: resource.id,
                description: resource.description,
                name: resource.name,
                imageUrl: resource.imageUrl,
                categoryId: resource.categoryId,
                intervals: resource.intervals.sort((a, b) => (a.start > b.start ? 1 : -1)),
            },
            saveError: [],
        });
    }

    fetch = () => {
        this.setState({ isLoading: true }, () => {
            const pageId = this.props.match.params.id;

            axios
                .all([
                    api.get(`/api/v1/bookingresources/${pageId}`).then((response) => {
                        this.initPage(response.data);
                    }),
                ])
                .then(() => {
                    this.setState({ isLoading: false });
                });
        });
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetch();
        }
    };

    saveNewCategory() {
        let newType = {
            id: 0,
            name: this.state.newCategory,

            resources: [],
        };

        api
            .post("/api/bookingresourcecategories", newType)
            .then(() => this.getCategories());
    }

    getCategories() {
        api
            .get("/api/bookingresourcecategories")

            .then((pagesResponse: AxiosResponse) => {
                this.setState({
                    categories: pagesResponse.data,
                });
            });
    }

    deleteResource = (id) => {
        this.setState({ isLoading: true }, () => {
            api.delete(`/api/bookingresources/${id}`).then((response) => {
                this.props.history.push("/booking");
            });
        });
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const resource = this.state.resource;
        let categories = [];

        this.state.categories.map((obj) => {
            categories.push({ key: obj.id, text: obj.name });
        });

        return (
            <div>
                <Helmet>
                    <title>{localize("Resursbokning")}</title>
                </Helmet>
                <CustomDialog
                    message={localize("ArDuSakerAttDuVillUtforaDennaAtgard")}
                    show={this.state.showDeleteDialog !== undefined}
                    onDismiss={() => {
                        this.setState({ showDeleteDialog: undefined });
                    }}
                    onConfirm={() => {
                        this.deleteResource(this.state.showDeleteDialog);
                        this.setState({ showDeleteDialog: undefined });
                    }}
                />

                <div className="ResourceWeeklyView page-margin-bottom">
                    <Breadcrumbs
                        displayInstance
                        items={[
                            {
                                text: localize("Resursbokning"),
                                key: 0,
                                link: "/booking",
                            },
                            {
                                text: resource.name,
                                key: 1,
                                link: `/booking/${resource.categoryId}/${resource.id}`,
                            },
                        ]}
                    />
                    <PageHeader
                        title={resource.name}
                        renderTitle={() => {
                            return (
                                <SpintrUser
                                    big
                                    name={resource.name}
                                    imageUrl={resource.imageUrl}
                                    personalName={false}
                                    onRenderSecondaryText={() => {
                                        return (
                                            <ShowMoreText
                                                lines={1}
                                                more={localize("VisaMer")}
                                                less={localize("VisaMindre")}
                                                expanded={false}
                                            >
                                                {resource.description}
                                            </ShowMoreText>
                                        )
                                    }}
                                />
                            );
                        }}
                        standardActionMenuProps={{
                            canDelete: this.props.isAdmin || this.props.isEditor,
                            canEdit: this.props.isAdmin || this.props.isEditor,
                            objectId: resource.id,
                            onEditClick: () => {
                                this.props.history.push(`/booking/${resource.categoryId}/${resource.id}/edit`);
                            },
                            onDeleteClick: () => {
                                this.setState({ showDeleteDialog: resource.id });
                            },
                        }}
                    />

                    {this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    {this.state.isLoading ? (
                        <Loader />
                    ) : (
                        <CustomWeeklyTimeline
                            resource={this.state.resource}
                            year={this.props.match.params.year}
                            week={this.props.match.params.week}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        currentUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
    };
};

export default withRouter(connect(mapStateToProps)(ResourceWeeklyView));
