import { Stack } from "@fluentui/react";
import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import { FunctionComponent } from "react";
import { IAppPageComponent, IAppPageFormContext } from "src/marketplace/types";
import { buildAppPageComponent } from "src/marketplace/utils";
import api from "src/spintr/SpintrApi";
import { Label, Loader } from "src/ui";
import { AppPageContext } from "../AppPageContext";
import { AppPageFormContext } from "../AppPageFormContext";

interface IProps extends IAppPageComponent {
    heading?: string,
    description?: string;
}

interface IState {
    data?: any;
    isLoading: boolean;
}

interface IAppPageFormModel {
    [key: string]: any;
}

const AppPageForm: FunctionComponent<IProps> = (props) => {
    const { appId, localize } = useContext(AppPageContext);
    
    const { children, requestName } = props;

    const [state, setState] = useState<IState>({ isLoading: false });

    const [model, setModel] = useState<IAppPageFormModel>({});

    const propertyChange = useCallback(
        (key: string, value: any | undefined) => setModel((m) => ({
            ...m,
            [key]: value,
        })),
        [setModel],
    );

    const submitForm = useCallback(
        () => {
            setState({ isLoading: true });

            const cancelTokenSource = axios.CancelToken.source();

            const promise = api.post(
                `/api/v1/MarketplaceApplicationPage/${appId}/request`, {
                    requestName,
                    variables: model,
                }, {
                    cancelToken: cancelTokenSource.token,
                }
            );

            promise.then(
                (response) => setState({
                    data: response.data,
                    isLoading: false,
                }),
                (reason) => setState((s) => ({
                    ...s,
                    isLoading: false,
                }))
            );

            return () => cancelTokenSource.cancel();
        },
        [appId, model, requestName, setState],
    );

    const contextValue: IAppPageFormContext = {
        model,
        submitForm,
        updateModelProperty: propertyChange,
    };

    const onSubmit = useCallback(
        (ev: React.FormEvent) => {
            if (ev.preventDefault) {
                ev.preventDefault();
            }

            submitForm();
        },
        [submitForm],
    );

    const items = (children || []).map((child, index) => buildAppPageComponent({
        ...child,
        variables: {
            ...(child.variables || {}),
            ...(state.data || {})
        },
    }, index));

    if (state.isLoading) {
        return <Loader />;
    }

    return (
        <AppPageFormContext.Provider value={contextValue}>
            <div className="AppPageForm">
                <form action="#" method="POST" onSubmit={onSubmit}>
                    {(props.heading || props.description) && (
                        <Stack className="form-header">
                            {props.heading && (
                                <Label
                                    as="div"
                                    className="header"
                                    size="h4"
                                    weight="medium"
                                >
                                    {localize(props.heading)}
                                </Label>
                            )}
                            {props.description && (
                                <Label
                                    as="div"
                                    className="description"
                                    color="mid-grey"
                                    size="body-2"
                                >
                                    {localize(props.description)}
                                </Label>
                            )}
                        </Stack>
                    )}
                    {items}
                </form>
            </div>
        </AppPageFormContext.Provider>
    );
};

export default AppPageForm;
