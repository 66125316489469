import React from "react";
import { Helmet } from "react-helmet";
import { localize } from "src/l10n";

export const renderAdminTitle = (title: string, translate: boolean = true) => {
    const completeTitle = localize("AdministrationAvX").replace("{{x}}", translate ? localize(title) : title);

    return (
        <Helmet>
            <title>{completeTitle}</title>
        </Helmet>
    );
};
