import { Image } from "@fluentui/react";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { SmallBody, CaptionBody } from "..";
import { ProductPreviewCardProps } from "./ProductPreviewCard.types";

/**
 * Displays a preview of a product
 * 
 * @param props The properties for the component
 * @returns A react element to be rendered
 */
function ProductPreviewCard(props: ProductPreviewCardProps): ReactElement {
    const { product } = props;

    return (
        <Link className="ProductPreviewCard" to={`/products/${product.id}`}>
            <div className="product-image">
                <Image
                    alt={product.name}
                    src={product.imageUrl} />
            </div>
            <div className="product-information">
                <CaptionBody
                    className="product-name"
                    color="contentDark"
                    letterSpacing="dense"
                    lineHeight="higher"
                >
                    {product.name}
                </CaptionBody>
                <CaptionBody
                    className="product-sku"
                    color="gray"
                    letterSpacing="dense"
                    lineHeight="higher"
                >
                    {localize("ARTICLE_ID")}: {product.articleId}
                </CaptionBody>
            </div>
        </Link>
    );
}

export default ProductPreviewCard;
