import { TextField } from "@fluentui/react";
import React, { Component } from "react";
import "src/groups/views/planning/GroupPlanningView.scss";
import { validateRequiredTextField } from "src/utils";

interface IProps {
    dispatch?: any;
    category?: any;
    onClickOutside?: any;
    saveCategory?: any;
    handleChangeTitle?: any;
    title?: any;
    deleteCategory?: any;
    groupId: number;
}

class PlanningCategoryEditor extends Component<IProps> {
    ref = React.createRef();

    onEnter = e => {
        if (e.keyCode === 27) {
            e.preventDefault();
            this.props.onClickOutside();
        }

        if (e.keyCode === 13) {
            e.preventDefault();
            this.props.saveCategory();
        }
    };

    handleClick = e => {
        const node = this.ref.current;

        //@ts-ignore
        if (node.contains(e.target)) {
            return;
        }

        this.props.onClickOutside();
    };

    componentDidMount() {
        document.addEventListener("click", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick, false);
    }

    render() {
        return (
            //@ts-ignore
            <div className="Planning-CategoryTitleEdit" ref={this.ref}>
                <form>
                    <div>
                        <TextField
                            className="textField"
                            value={this.props.title}
                            onChange={this.props.handleChangeTitle}
                            onKeyDown={this.onEnter}
                            maxLength={1024}
                            autoFocus={true}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={true}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default PlanningCategoryEditor;
