import React, { FunctionComponent } from "react";
import Todos from "src/ui/components/Todos/Todos";

const TasksWrapper: FunctionComponent = () => (
    <div className="TasksWrapper">
        <Todos hideTitle={false} />
    </div>
);

export default TasksWrapper;
