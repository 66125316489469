import Immutable, { List } from "immutable";
import { produce } from "immer";

import {
    CLEAR_ATTACHED_FILES_AND_FOLDERS,
    FETCH_ATTACHED_FILE_FULFILLED,
    FETCH_ATTACHED_FILE_PENDING,
    FETCH_ATTACHED_FILE_REJECTED,
    FETCH_ATTACHED_FOLDER_FULFILLED,
    FETCH_ATTACHED_FOLDER_PENDING,
    FETCH_ATTACHED_FOLDER_REJECTED,
    REMOVE_ATTACHED_FILE,
    REMOVE_ATTACHED_FOLDER
} from "./page-action-types";

export interface IPageState {
    attachedFiles: any[];
    attachedFolders: any[]; // List of folders containing lists of files.
}

const initialState: IPageState = {
    attachedFiles: [],
    attachedFolders: []
}

export const pageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_ATTACHED_FILE_PENDING:
                draft.attachedFiles.push({ id: action.meta.id, isLoading: true})
                break;
            case FETCH_ATTACHED_FILE_REJECTED:
                var fileIndex = draft.attachedFiles.findIndex((f) => f.id == action.meta.id)
                draft.attachedFiles[fileIndex] = {...draft.attachedFiles[fileIndex], isLoading: false, isError: true}
                break;
            case FETCH_ATTACHED_FILE_FULFILLED:
                var fileIndex = draft.attachedFiles.findIndex((f) => f.id == action.meta.id)
                draft.attachedFiles[fileIndex] = {...draft.attachedFiles[fileIndex], ...action.payload.data, isLoading: false,}
                break;
            case FETCH_ATTACHED_FOLDER_PENDING:
                draft.attachedFolders.push({ ...action.meta.folder, isLoading: true })
                break;
            case FETCH_ATTACHED_FOLDER_REJECTED:
                var folderIndex = draft.attachedFolders.findIndex((f) => f.id == action.meta.folder.id)
                draft.attachedFolders[folderIndex] = {...draft.attachedFolders[folderIndex], isLoading: false, isError: true}
                break;
            case FETCH_ATTACHED_FOLDER_FULFILLED:
                var folderIndex = draft.attachedFolders.findIndex((f) => f.id == action.meta.folder.id)
                draft.attachedFolders[folderIndex] = {...draft.attachedFolders[folderIndex], isDirectory: true, isLoading: false, ...action.payload.data, }
                break;
            case CLEAR_ATTACHED_FILES_AND_FOLDERS:
                draft.attachedFolders = []
                draft.attachedFiles = []
                break;
            case REMOVE_ATTACHED_FILE:
                draft.attachedFiles = draft.attachedFiles.filter(f => f.id != action.meta.id);
                break;
            case REMOVE_ATTACHED_FOLDER:
                draft.attachedFolders = draft.attachedFolders.filter(f => f.id != action.meta.id);
                break;
            default:
                return state
        }
    })