import React, { ChangeEvent, Component, ReactNode } from "react";
import "./Select.scss";

export type SelectOnSelected<TType> = (item : TType) => void;

export interface ISelectProps<TType> {
    id?: string;
    items: TType[];
    selected: TType | null;
    onSelected: SelectOnSelected<TType>
    textKey?: string;
    valueKey?: string;
}

class Select<TType> extends Component<ISelectProps<TType>> {
    constructor(props : ISelectProps<TType>) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.renderOption = this.renderOption.bind(this);
    }

    public onChange(event : ChangeEvent<HTMLSelectElement>) : void {
        if (!this.props.items) {
            return;
        }

        const { value } = event.target;

        const selected = this.props.items.find(
            item => item[this.props.valueKey||"value"].toString() === value
        ); 

        this.props.onSelected(selected);
    }

    public render() : ReactNode {
        if (!this.props.items) {
            return null;
        }

        const selectedValue = this.props.selected
            ? this.props.selected[this.props.valueKey||"value"]
            : undefined;

        return (
            <div className="Select">
                <select
                    id={this.props.id}
                    onChange={this.onChange}
                    value={selectedValue}
                >
                    {this.props.items.map(this.renderOption)}
                </select>
            </div>
        )
    }

    public renderOption(item : TType, index : number) : ReactNode {
        const key : string = `Select${this.props.id||""}.Option${index}`;

        return (
            <option
                key={key}
                value={item[this.props.valueKey||"value"]}
            >
                {item[this.props.textKey||"text"]}
            </option>
        )
    }
}

export default Select;