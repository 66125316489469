import { Icon, IconButton } from "@fluentui/react";
import React, { Component } from "react";
import Label from "./Label/Label";
import "./ExpandableField.scss";
import classNames from "classnames";
import { IApplicationState } from "src/spintr/reducer";
import { connect } from 'react-redux';
import { Style } from "../helpers";
import TinyFormattedContent from "./Tiny/displayment/TinyFormattedContent";

interface IProps {
    title: string;
    text: string;
    instanceColor: string;
}
interface IState {
    showContent: boolean;
}

class ExpandableField extends Component<IProps, IState> {
    state = {
        showContent: false,
    };

    public render() {
        return (
            <div
                className={classNames("ExpandableFieldWrapper", {
                    ["isExpanded"]: this.state.showContent,
                })}
            >
                <div className="ExpandableField">
                    <div
                        className={classNames("header", {
                            ["isExpanded"]: this.state.showContent,
                        })}
                        onClick={() => {
                            this.setState({ showContent: !this.state.showContent });
                        }}
                    >
                        <Label size="body-2" title={this.props.title}>{this.props.title}</Label>
                        <IconButton
                            styles={{
                                rootHovered: { background: "none" },
                                rootChecked: { background: "none" },
                            }}
                            ariaLabel={this.state.showContent ? "Collapse" : "Expand"}
                            className="expandButton"
                            iconProps={{
                                iconName: this.state.showContent ? "ChevronUp" : "ChevronDown",
                                color: "#fff",
                                styles: {
                                    root: { color: Style.getHexFromSpintrColor("dark-grey") },
                                },
                            }}
                        />
                    </div>
                    {this.state.showContent && (
                        <TinyFormattedContent content={this.props.text} className={"isExpanded"} />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        instanceColor: state.instance.get("color"),
    };
};

export default connect(mapStateToProps)(ExpandableField);