import { AxiosError, AxiosResponse } from "axios";
import { ActionButton, Checkbox, Separator, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import {
    Breadcrumbs,
    ContentImageViewerAndSelector,
    ContentWithSubmenu,
    DivWithHoverActions,
    PageHeader,
    UnstyledButton,
} from "src/ui";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { scrollToTop, validateRequiredTextField } from "src/utils";
import generateUniqueId from "src/utils/uniqueId";
import "./WikiArticleEditView.scss";
import WikiStructure from "./WikiStructure";
import api from "src/spintr/SpintrApi";
import CenteredAddButton from "src/ui/components/CenteredAddButton/CenteredAddButton";
import SplitForm from "src/ui/components/SplitForm/SplitForm";
import { FieldCompletion } from "src/assistant";
import { Helmet } from "react-helmet";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
    isSmallViewMode?: boolean;
    enableMediaflow?: boolean;
    restrictWikis?: boolean;
    enableUnsplash?: boolean;
    dispatch: (action: Action) => void;
}

interface IState {
    isLoading: boolean;
    wiki: any;
    article: any;
    hasExtendedRights: boolean;
    saveError: string[];
    originalPositions: any[];
    positions: any[];
    enableFieldValidation?: boolean;
    displayPreamble?: boolean;
}

class WikiArticleEditView extends Component<IProps, IState> {
    private isNew = !!!this.props.match.params.articleId;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasExtendedRights: this.isNew || this.props.isAdmin || this.props.isEditor,
            saveError: [],
            wiki: {
                allownewarticles: true,
                allowediting: true,
                published: true,
                isprioritized: false,
                editor: {
                    id: this.props.currentUserId,
                    name: this.props.currentUserName,
                    info: this.props.currentUserDepartmentName,
                    imageUrl: this.props.currentUserImageUrl,
                    type: SpintrTypes.UberType.User,
                },
                editorid: 0,
                name: "",
                description: "",
                targets: [],
                tags: "",
                deleted: false,
                preamble: "",
            },
            article: {
                id: 0,
                editor: {
                    id: this.props.currentUserId,
                    name: this.props.currentUserName,
                    info: this.props.currentUserDepartmentName,
                    imageUrl: this.props.currentUserImageUrl,
                    type: SpintrTypes.UberType.User,
                },
                editorid: 0,
                imageTicket: "",
                image: "",
                name: "",
                published: true,
                wikiid: "0",
                sections: [{ id: 0, name: "", content: "" }],
                deleted: false,
                removeImage: false,
                url: { id: 0, aliasurl: "" },
                links: [],
                preamble: "",
            },
            originalPositions: [],
            positions: [],
            displayPreamble: false,
        };
    }

    public componentDidMount() {
        if (this.props.restrictWikis) {
            if (!this.props.isAdmin && !this.props.isEditor) {
                this.props.history.goBack();
                return;
            }
        }

        if (!this.isNew) {
            api.get(`/api/v1/wikis/article/${this.props.match.params.articleId}`).then((response: AxiosResponse) => {
                // response.data.wiki is actually the article
                this.setState((prevState) => ({
                    isLoading: false,
                    hasExtendedRights:
                        this.props.isAdmin ||
                        this.props.isEditor ||
                        response.data.wiki.editor.id === this.props.currentUserId,
                    article: {
                        ...prevState.article,
                        id: response.data.wiki.id,
                        deleted: response.data.wiki.deleted,
                        name: response.data.wiki.name,
                        published: response.data.wiki.published,
                        image: response.data.wiki.imgUrl,
                        editor: {
                            id: response.data.wiki.editor.id,
                            name: response.data.wiki.editor.name,
                            info: "",
                            imageUrl: "",
                            type: SpintrTypes.UberType.User,
                        },
                        url: response.data.wiki.url,
                        links: response.data.wiki.links,
                        preamble: response.data.wiki.preamble,
                    },
                    wiki: response.data.wiki.wiki,
                    originalPositions: response.data.wiki.sections,
                    positions: response.data.wiki.sections,
                }));
            });
        }

        if (this.isNew) {
            api.get(`/api/v1/wikis/${this.props.match.params.wikiId}`).then((response: AxiosResponse) => {
                this.setState({
                    isLoading: false,
                    wiki: response.data.wiki,
                });
            });
        }
    }

    private onConfirmDelete = () => {
        this.setState({ isLoading: true });
        api.put(`/api/v1/wikis/article/toggledelete/${this.props.match.params.articleId}`)
            .then((response) => {
                this.props.history.push({ pathname: `/goto/${this.state.wiki.id}` });
            })
            .catch(() => {
                // TODO: Proper error
                this.setState({ isLoading: false });
            });
    };

    private formFooterButtons() {
        return [
            ...(!this.isNew && this.state.hasExtendedRights
                ? [
                      {
                          text: localize(this.state.article.deleted ? "Aterstall" : "TaBort"),
                          onClick: () => {
                              if (!this.state.article.deleted) {
                                  this.props.dispatch(
                                      setConfirmPopup({
                                          isOpen: true,
                                          title:
                                              localize("ArDuPaSakerAttDuVillTaBortDennaWiki") +
                                              localize("Artikel").toLowerCase() +
                                              "?",
                                          onConfirm: this.onConfirmDelete,
                                      })
                                  );
                                  return;
                              }

                              this.setState({ isLoading: true });

                              api.put(`/api/v1/wikis/article/toggledelete/${this.props.match.params.articleId}`).then(
                                  (response) => {
                                      this.props.history.push({
                                          pathname: `/goto/${this.props.match.params.articleId}`,
                                      });
                                  }
                              );
                          },
                      },
                  ]
                : []),
        ];
    }

    private onCancelClick = () => {
        if (this.isNew) {
            this.props.history.push({
                pathname: this.state.wiki.url,
            });
        } else {
            this.props.history.push({
                pathname: `/${this.state.article.url.aliasurl}`,
            });
        }
    };

    private onSaveClick = () => {
        this.setState({ isLoading: true, enableFieldValidation: true });

        let saveData = {
            name: this.state.article.name,
            published: this.state.article.published,
            editorid: this.state.article?.editor?.id,
            removeImage: this.state.article.removeImage,
            imageTicket: this.state.article.imageTicket,
            links: this.state.article.links,
            mediaflowId: this.state.article.mediaflowId,
            hotlink: this.state.article.hotlink,
            image: this.state.article.image,
            preamble: this.state.article?.preamble,
        } as any;

        if (!this.isNew) {
            api.put(`/api/v1/wikis/article/${this.state.article.id}`, saveData)
                .then((response) => {
                    if (this.state.positions !== this.state.originalPositions) {
                        const sections = this.state.positions.map((section, idx) => ({
                            parentId: 0,
                            id: section.id,
                            position: idx,
                        }));

                        api.put(`/api/wikis/section/positions`, { sections }).then(() => {
                            this.props.history.push({
                                pathname: response.data.url,
                            });
                        });
                    } else {
                        this.props.history.push({
                            pathname: response.data.url,
                        });
                    }
                })
                .catch((error: AxiosError) => {
                    this.setState(
                        { saveError: error.response.data.errorlist || ["TeknisktFel"], isLoading: false },
                        () => {
                            scrollToTop();
                        }
                    );
                });
            return;
        }

        const saveSections = this.state.article.sections.map((section) => {
            section.content = (this.refs[`tiny${section.id}`] as any).getContent();
            return section;
        });

        saveData = {
            ...saveData,
            sections: saveSections,
            wikiid: this.props.match.params.wikiId,
        };

        api.post("/api/v1/wikiarticles", saveData)
            .then((response: AxiosResponse) => {
                this.props.history.push({
                    pathname: `/${response.data.url}`,
                });
            })
            .catch((error: AxiosError) => {
                this.setState({ saveError: error.response.data.errorlist || ["TeknisktFel"], isLoading: false }, () => {
                    scrollToTop();
                });
            });
    };

    private addSection = () => {
        this.setState((prevState) => ({
            article: {
                ...prevState.article,
                sections: [...prevState.article.sections, { id: generateUniqueId(), name: "", content: "" }],
            },
        }));
    };

    private removeSection = (id: number) => {
        this.setState((prevState) => ({
            article: {
                ...prevState.article,
                sections: prevState.article.sections.filter((section) => section.id != id),
            },
        }));
    };

    private onChangePositions = (result) => {
        this.setState({
            positions: result,
        });
    };

    private onChangeText = (ev, val) => {
        const name = ev.target.name;
        this.setState((prevState) => ({
            article: {
                ...prevState.article,
                [name]: val,
            },
        }));
    };

    private onChangeHeaderImage = (result) => {
        this.setState((prevState) => ({
            article: {
                ...prevState.article,
                imageTicket: result.ticket,
                removeImage: !!!result.ticket && !result.isUnsplash,
                mediaflowId: result.externalMediaflowId,
                hotlink: result.isUnsplash,
                image: result.thumbnailUrl,
            },
        }));
    };

    private onChangeLink = (event, val) => {
        const { dataset } = event.target as HTMLAnchorElement;
        if (!dataset) {
            return;
        }

        const links = [...this.state.article.links];
        const link = { ...links[dataset.idx] };

        link[dataset.prop] = val;
        links[dataset.idx] = link;

        this.setState((prevState) => ({
            article: {
                ...prevState.article,
                links,
            },
        }));
    };

    renderNameInputField() {
        return (
            <TextField
                label={localize("RubrikPaArtikel")}
                value={this.state.article.name}
                name="name"
                onChange={this.onChangeText}
                maxLength={150}
                required
                aria-required
                validateOnFocusIn
                validateOnFocusOut
                validateOnLoad={!!this.state.enableFieldValidation}
                onGetErrorMessage={validateRequiredTextField}
            />
        )
    }

    public render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        let adminView = false;
        if (window.location.href.indexOf("admin") > -1) {
            adminView = true;
        }
        const { article } = this.state;

        return (
            <div className={"wiki-article-edit-view " + (adminView ? "apply-padding" : "")}>
                <Helmet>
                    <title>{localize(this.isNew ? "SkapaWikiartikel" : "RedigeraWikiartikel")}</title>
                </Helmet>
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                        aboveSplitForm
                    />
                )}
                <SplitForm
                    headerTitle={this.isNew ? localize("SkapaEnWikiartikel") : localize("RedigeraEnWikiartikel")}
                    displayMandatoryText
                    additionalSettings={[
                        {
                            title: localize("Publicering"),
                            content: (
                                <>
                                    <FormTokenizedObjectInput
                                        label={localize("AnsvarigUtgivare")}
                                        itemLimit={1}
                                        types={[SpintrTypes.UberType.User]}
                                        items={article.editor ? [article.editor] : []}
                                        required
                                        aria-required
                                        validateOnLoad={!!this.state.enableFieldValidation}
                                        onChange={(editor) => {
                                            this.setState((prevState) => ({
                                                article: {
                                                    ...prevState.article,
                                                    editor: editor.map((e) => {
                                                        return {
                                                            name: e.name,
                                                            info: e.subText,
                                                            type: SpintrTypes.UberType.User,
                                                            id: e.key,
                                                            imageUrl: e.imageUrl,
                                                        };
                                                    })[0],
                                                },
                                            }));
                                        }}
                                    />
                                    <FormControl>
                                        <Checkbox
                                            label={localize("Publicerad")}
                                            checked={article.published}
                                            name="published"
                                            onChange={this.onChangeText}
                                        />
                                    </FormControl>
                                </>
                            ),
                        },
                        {
                            title: localize("Struktur"),
                            enabled: !this.isNew,
                            content: (
                                <WikiStructure
                                    onChangePositions={this.onChangePositions}
                                    positions={this.state.originalPositions}
                                />
                            ),
                        },
                        {
                            title: localize("Lankar"),
                            content: (
                                <>
                                    {article.links.map((link, idx) => (
                                        <div key={idx} className="link-container">
                                            <FormControl>
                                                <TextField
                                                    label={localize("Url")}
                                                    value={link.url}
                                                    data-prop="url"
                                                    data-idx={idx}
                                                    onChange={this.onChangeLink}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TextField
                                                    maxLength={64}
                                                    label={localize("Titel")}
                                                    value={link.title}
                                                    data-prop="title"
                                                    data-idx={idx}
                                                    onChange={this.onChangeLink}
                                                />
                                            </FormControl>
                                            <UnstyledButton
                                                onClick={() => {
                                                    this.setState((prevState) => ({
                                                        article: {
                                                            ...prevState.article,
                                                            links: prevState.article.links.filter((l) => l !== link),
                                                        },
                                                    }));
                                                }}
                                            >
                                                {localize("TaBort")}
                                            </UnstyledButton>
                                        </div>
                                    ))}
                                    <ActionButton
                                        iconProps={{ iconName: "Add" }}
                                        styles={{ root: { padding: 0 }, icon: { marginLeft: 0 } }}
                                        onClick={() => {
                                            this.setState((prevState) => ({
                                                article: {
                                                    ...prevState.article,
                                                    links: [...prevState.article.links, { url: "", title: "" }],
                                                },
                                            }));
                                        }}
                                    >
                                        {localize("LaggTill")}
                                    </ActionButton>
                                </>
                            ),
                        },
                    ]}
                >
                    <div className="header-image">
                        <ContentImageViewerAndSelector
                            renderSizeString={() => {
                                return `${localize("OptimalBildStorlek")}: 1000x170px <br />`;
                            }}
                            height={170}
                            cropAspect={1000 / 170}
                            enableMediaflow={this.props.enableMediaflow}
                            enableUnsplash={this.props.enableUnsplash}
                            imageUrl={this.state.article.image}
                            onChange={this.onChangeHeaderImage}
                            cropImage
                            editMode
                        />
                    </div>
                    <FormControl>
                        {this.isNew && (
                            <FieldCompletion
                                anchorPosition="TopRight"
                                completionType={SpintrTypes.CompletionType.HeadingSuggestion}
                                onTextGenerated={(text) => {
                                    this.setState((prevState) => ({
                                        article: {
                                            ...prevState.article,
                                            name: text
                                        },
                                    }));
                                }}
                                offsetX={7}
                                offsetY={28}
                                referenceText={this.state.article.sections.map(x => x.content).join("<br/>")}
                                buttonText={localize("GENERATE_TITLE")}
                            >
                            {this.renderNameInputField()}
                        </FieldCompletion>
                        )}
                        {!this.isNew && this.renderNameInputField()}
                    </FormControl>
                    {this.state.displayPreamble || article.preamble?.length > 0 ? (
                        <DivWithHoverActions
                            actions={[
                                {
                                    icon: "trash",
                                    title: localize("TaBort"),
                                    onClick: () => {
                                        this.setState({
                                            article: { ...article, preamble: "" },
                                            displayPreamble: false,
                                        });
                                    },
                                },
                            ]}
                            displayActionsAtBottom={true}
                        >
                            <FormControl>
                                <TextField
                                    data-cy="wikiarticle-ingress"
                                    label={localize("Ingress")}
                                    defaultValue={article.preamble}
                                    onChange={(event: React.FormEvent, preamble: string) => {
                                        this.setState((state) => ({
                                            article: { ...state.article, preamble },
                                        }));
                                    }}
                                    multiline={true}
                                    rows={5}
                                />
                            </FormControl>
                        </DivWithHoverActions>
                    ) : (
                        <CenteredAddButton
                            text={localize("LaggTillEnIngress")}
                            onClick={() => {
                                this.setState({ displayPreamble: true });
                            }}
                        />
                    )}
                    {this.isNew && (
                        <>
                            <Separator></Separator>
                            <FormSection title={localize("Sektion")}>
                                {this.state.article.sections.map((section) => {
                                    return (
                                        <div key={section.id}>
                                            {section.id > 0 && (
                                                <ActionButton
                                                    iconProps={{ iconName: "Remove" }}
                                                    styles={{ root: { padding: 0 }, icon: { marginLeft: 0 } }}
                                                    onClick={() => this.removeSection(section.id)}
                                                >
                                                    {localize("TaBortSektionen")}
                                                </ActionButton>
                                            )}

                                            <FormControl>
                                                <TextField
                                                    label={localize("RubrikPaSektion")}
                                                    value={section.name}
                                                    onChange={(ev, val) => {
                                                        var sections = this.state.article.sections;
                                                        var updatedSection = sections.find((s) => s.id == section.id);
                                                        updatedSection.name = val;
                                                        this.setState((prevState) => ({
                                                            article: {
                                                                ...prevState.article,
                                                                sections: sections,
                                                            },
                                                        }));
                                                    }}
                                                    required
                                                    aria-required
                                                    validateOnFocusIn
                                                    validateOnFocusOut
                                                    validateOnLoad={!!this.state.enableFieldValidation}
                                                    onGetErrorMessage={validateRequiredTextField}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <TinyEditorComponent
                                                    ref={`tiny${section.id}`}
                                                    enableMediaflow={this.props.enableMediaflow}
                                                    enableUnsplash={this.props.enableUnsplash}
                                                    id={section.id.toString()}
                                                    label={localize("Innehall")}
                                                    content={section.content}
                                                    onChange={(value: string) => {
                                                        this.setState({
                                                            article: {
                                                                ...this.state.article,
                                                                sections: this.state.article.sections.map((s: any) => {
                                                                    if (s.id === section.id) {
                                                                        return {
                                                                            ...s,
                                                                            content: value
                                                                        }
                                                                    }

                                                                    return s;
                                                                })
                                                            }
                                                        });
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                })}

                                <ActionButton
                                    iconProps={{ iconName: "Add" }}
                                    styles={{ root: { padding: 0 }, icon: { marginLeft: 0 } }}
                                    onClick={this.addSection}
                                >
                                    {localize("LaggTillSektion")}
                                </ActionButton>
                            </FormSection>
                        </>
                    )}
                </SplitForm>
                <FormFooterBar
                    top={!this.props.isSmallViewMode}
                    buttons={this.formFooterButtons()}
                    onCancelClick={this.onCancelClick}
                    onSaveClick={this.onSaveClick}
                    saveText={localize(this.isNew ? "Publicera" : "Spara")}
                    confirmSave={localize("ArDuSakerPaAttDuVillX").replace(
                        "{{X}}",
                        localize("PubliceraDetta").toLowerCase()
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        currentUserName: state.profile.active.name,
        currentUserDepartmentName: state.profile.active.department.name,
        currentUserImageUrl: state.profile.active.images["250x999"],
        requiredTagCount: state.instance.get("requiredTagCount"),
        isSmallViewMode: state.ui.isSmallViewMode,
        enableMediaflow: state.instance.get("enableMediaflow"),
        enableUnsplash: state.instance.get("enableUnsplash"),
        restrictWikis: state.instance.get("restrictWikis"),
    };
};

export default connect(mapStateToProps)(WikiArticleEditView);
