import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PayrollView.scss";
import { DefaultButton, Dropdown, Modal, PrimaryButton, SpinButton, Stack, TextField } from "@fluentui/react";
import { localize } from "src/l10n";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { connect, useDispatch } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import { ActionMenu, Loader } from "src/ui";
import { AxiosError, AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import PopupHeader from "src/ui/components/PopupHeader";
import { validateRequiredTextField } from "src/utils";
import { SpintrTypes } from "src/typings";
import moment from "moment";
import { setConfirmPopup } from "src/popups/actions";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";

interface IProps {
}

interface IState {
    includeDeleted: boolean;
    showModal: boolean;
    item: any;
    isLoading: boolean;
    saveError: string[];
}

const emptyItem = {
    id: 0,
    externalId: 1
};

const PayrollCategories = (props: IProps) => {
    const listRef = useRef<SpintrList>();
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        includeDeleted: false,
        showModal: false,
        item: emptyItem,
        isLoading: false,
        saveError: []
    });
    
    const closeModal = useCallback(() => {
        setState(s => ({
            ...s,
            showModal: false
        }));
    }, []);
    
    const save = useCallback(() => {
        setState(s => ({
            ...s,
            isLoading: true,
            saveError: []
        }));

        api.post("/api/v1/payroll/categories", state.item).then(() => {
            listRef.current.reFetch();

            setState(s => ({
                ...s,
                isLoading: false,
                showModal: false,
                item: emptyItem
            }));
        }).catch((saveError: AxiosError) => {
            const hasValidationErrorList = !!saveError &&
                !!saveError.response &&
                !!saveError.response.data &&
                !!saveError.response.data.errorlist;

            setState(s => ({
                ...s,
                isLoading: false,
                saveError: hasValidationErrorList ?
                    saveError.response.data.errorlist :
                    ["TeknisktFel"]
            }));
        });
    }, [state.item]);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        listRef.current.reFetch();
    }, [state.includeDeleted]);

    return (
        <div>
            <SpintrList
                ref={listRef}
                fetch={(skip, take, columnId, isAscending, searchQuery) => {
                    return new Promise((resolve, reject) => {
                        api.get("/api/v1/payroll/categories", {
                            params: {
                                includeDeleted: state.includeDeleted,
                                isAscending,
                                orderByColumn: columnId,
                                searchQuery: searchQuery,
                                skip,
                                take,
                            },
                        })
                        .then((response: AxiosResponse) => {
                            resolve({
                                data: response.data.items,
                                totalCount: response.data.totalCount
                            });
                        }).catch(() => {});
                    });
                }}
                take={1000}
                orderByColumn={"externalId"}
                columns={[{
                    name: localize("EXTERNAL_ID"),
                    fieldName: "externalId",
                    minWidth: 50,
                    maxWidth: 50
                }, {
                    name: localize("Namn"),
                    fieldName: "name",
                    onRender: (item) => {
                        return (
                            <span
                                style={{
                                    ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                }}
                            >
                                {item.name}
                            </span>
                        );
                    },
                }, {
                    name: localize("NAME_SHORT"),
                    fieldName: "nameShort",
                    minWidth: 200
                }, {
                    name: "",
                    key: "actionmenu",
                    minWidth: 40,
                    onRender: (item: any) => {
                        return (
                            <ActionMenu
                                categories={[
                                    {
                                        items: [
                                            {
                                                text: localize("Redigera"),
                                                onClick: () => {
                                                    setState(s => ({
                                                        ...s,
                                                        showModal: true,
                                                        item,
                                                        saveError: []
                                                    }));
                                                },
                                            },
                                            {
                                                text: item.isDeleted ? localize("Aterstall") : localize("TaBort"),
                                                onClick: () => {
                                                    dispatch(setConfirmPopup({
                                                        isOpen: true,
                                                        title: localize("ArDuSakerAttDuVillRaderaDennaPost"),
                                                        onConfirm: () => {
                                                            api.post("/api/v1/payroll/categories", {
                                                                ...item,
                                                                isDeleted: !item.isDeleted
                                                            }).then(() => {
                                                                listRef.current.reFetch();
                                                            }).catch(() => {});
                                                        }
                                                    }));
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        );
                    },
                }]}
                optionItems={{
                    items: [{
                        key: "showDeleted",
                        text: state.includeDeleted ?
                            localize("DoljBorttagna") :
                            localize("VisaBorttagna"),
                        onClick: () => {
                            setState(s => ({
                                ...s,
                                includeDeleted: !state.includeDeleted
                            }));
                        },
                    }],
                }}
                buttons={[
                    {
                        key: "add",
                        text: localize("SkapaNy"),
                        onClick: () => {
                            setState(s => ({
                                ...s,
                                showModal: true,
                                item: emptyItem,
                                saveError: []
                            }));
                        },
                        iconProps: { iconName: "Add" },
                        className: "commandBarAddButton",
                    },
                ]}
            />
            <Modal
                allowTouchBodyScroll
                containerClassName="calendar-event-popup"
                className="spintr-modal modalWithPopupHeader"
                isOpen={state.showModal}
                onDismiss={closeModal}
            >
                <PopupHeader
                    text={state.item.id === 0 ? localize("SkapaKategori") : localize("RedigeraKategori")}
                    onClose={closeModal} />
                <div>
                    {state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={state.saveError}
                            onDismiss={() => {
                                setState(s => ({
                                    ...s,
                                    saveError: []
                                }));
                            }}
                        />
                    )}
                    {state.isLoading && <Loader />}
                    {!state.isLoading && (
                        <div className="popup-inner">
                            <FormSection>
                                <FormControl label={localize("EXTERNAL_ID")}>
                                    <SpinButton
                                        className="spinButton"
                                        defaultValue={state.item.externalId}
                                        min={1}
                                        step={1}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    externalId: newValue
                                                }
                                            }))
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        className="textField"
                                        label={localize("Namn")}
                                        value={state.item.name}
                                        onChange={(ev, val) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    name: val
                                                }
                                            }))
                                        }}
                                        maxLength={64}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        className="textField"
                                        label={localize("NAME_SHORT")}
                                        value={state.item.nameShort}
                                        onChange={(ev, val) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    nameShort: val
                                                }
                                            }))
                                        }}
                                        maxLength={64}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                    <DefaultButton text={localize("Avbryt")} onClick={closeModal} />
                                    <PrimaryButton
                                        text={localize(state.item.id === 0 ? "Skapa" : "Spara")}
                                        onClick={save}
                                    />
                                </Stack>
                            </FormSection>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default PayrollCategories;

