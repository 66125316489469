import React, { Component } from 'react';
import { FillHeight, Scrollable } from "src/ui/components";

class GroupClassicStartView extends Component {
    render() {
        return (
            <div className="GroupClassicStartView">
                <FillHeight>
                    <Scrollable>
                        <div className="site-max-width-with-padding group-content-view-spacing">
                            GroupClassicStartView
                        </div>
                    </Scrollable>
                </FillHeight>
            </div>
        )
    }
}

export default GroupClassicStartView;
