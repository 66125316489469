import { Icon } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import {
    Label,
    setResponsiveProfileVisible,
    SpintrUser,
    UnstyledButton
} from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import "./ResponsiveProfile.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { SpintrTypes } from "src/typings";

interface IMenuItem {
    onClick?: () => void;
    text: string;
    url?: string;
    disableChevron?: boolean;
}

interface IMenuCategory {
    items: IMenuItem[];
    text?: string;
}

interface IDispatchProps {
    setResponsiveProfileVisible: (visible: boolean) => void;
}

interface IStateProps {
    currentUser: IActiveUserProfile;
    isVisible: boolean;
    currentUserDepartments?: any[];
    coworkersEnabled: boolean;
}

type Props = IStateProps & IDispatchProps & RouteComponentProps;

const ResponsiveProfile: FunctionComponent<Props> = (props) => {
    const [displayReloadDialog, setDisplayReloadDialog] = useState(false);

    useEffect(
        () => { props.setResponsiveProfileVisible(false); },
        [props.location.pathname],
    );

    const onCloseClick = useCallback(
        () => props.setResponsiveProfileVisible(false),
        [props.setResponsiveProfileVisible],
    );

    const isSupplierContact = props.currentUser.roles.indexOf("supplier") > -1;

    const logOutItem = {
        onClick: useCallback(() => {
            if (navigator.userAgent.indexOf('MSIE') !== -1 ||
                navigator.userAgent.indexOf('Trident') !== -1) {
                document.execCommand("ClearAuthenticationCache");
            }

            localStorage.clear();

            window.location.assign("/api/authentication/signout");
        }, []),
        text: localize("LoggaUt"),
        url: null,
    };

    const categories: IMenuCategory[] = isSupplierContact ?
    [{
        items: [logOutItem]
    }] :
    [{
        items: [{
            text: localize("Profil"),
            url: "/profile/" + props.currentUser.id,
        }, {
            text: localize("Profilinstallningar"),
            url: "/profile-settings"
        }, ...(props.coworkersEnabled ? [{
            text: localize("Medarbetare"),
            url: "/people"
        }] : []), {
            text: localize("Franvaro"),
            url: "/absence"
        }, logOutItem],
        text: null,
    }, {
        items: [{
            text: localize("Hjalpcenter"),
            url: "https://support.spintr.me"
        }],
        text: localize("Hjalp"),
    }, {
        items: [{
            text: localize("Administration"),
            url: "/admin"
        }],
        text: localize("Administrator"),
    },
    ...(props.currentUserDepartments.length > 0 ? [{
        items: props.currentUserDepartments.map(department => {
            return {
                text: department.name,
                disableChevron: true,
                onClick: () => {
                    api.post("/api/users/changedepartment/" + department.id).then(() => {
                        setDisplayReloadDialog(true)
                    });
                },
            }
            }),
        text: localize("VaxlaAvdelning")
        }] : [])
    ]

    return (
        <div id="ResponsiveProfile" className={props.isVisible ? "" : "hidden"}>
            <CustomDialog
                message={localize("LaddarOmForAttSeAndringar")}
                show={displayReloadDialog}
                confirmMessage={localize("RELOAD")}
                onDismiss={() => {
                    setDisplayReloadDialog(false);
                }}
                onConfirm={() => {
                    window.location.reload();
                }}
            />
            <div className="overlay" onClick={onCloseClick} />
            <div className="profile">
                <div className="header">
                    <div className="user">
                        <SpintrUser
                            imageUrl={props.currentUser.images.topBar}
                            name={props.currentUser.name}
                            subText={props.currentUser.department?.name || ""}
                            personalName={true}
                            size={32}
                            state={1}
                        />
                    </div>
                    <UnstyledButton className="close-button" onClick={onCloseClick}>
                        <Visage2Icon icon="close-circle" />
                    </UnstyledButton>
                </div>
                <div className="body">
                    <ul className="categories">
                        {categories.map((category, i) => (
                            <li
                                className="category"
                                key={i}
                            >
                                {category.text && (
                                    <Label
                                        className="heading"
                                        size="small-2"
                                    >
                                        {category.text}
                                    </Label>
                                )}
                                <ul
                                    className="items"
                                >
                                    {category.items.map((item, j) => (
                                        <li
                                            className="item"
                                            key={i + "." + j}
                                        >
                                            {!item.url && (
                                                <a onClick={item.onClick}>
                                                    <Label
                                                        as="span"
                                                        size="h6"
                                                    >
                                                        {item.text}
                                                    </Label>
                                                    {!item.disableChevron &&
                                                        <Visage2Icon icon="arrow-right-3" />
                                                    }
                                                </a>
                                            )}
                                            {item.url && item.url.substr(0, 1) !== '/' && (
                                                <a
                                                    href={item.url}
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                >
                                                    <Label
                                                        as="span"
                                                        size="h6"
                                                    >
                                                        {item.text}
                                                    </Label>
                                                    <Visage2Icon icon="arrow-right-3" />
                                                </a>
                                            )}
                                            {item.url && item.url.substr(0, 1) === '/' && (
                                                <Link
                                                    to={item.url}
                                                >
                                                    <Label
                                                        as="span"
                                                        size="h6"
                                                    >
                                                        {item.text}
                                                    </Label>
                                                    <Visage2Icon icon="arrow-right-3" />
                                                </Link>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = {
    setResponsiveProfileVisible,
};

const mapStateToProps: MapStateToProps<IStateProps, {}, Spintr.AppState> =
    (state) => ({
        currentUser: state.profile.active,
        isVisible: (state.ui.responsive || {}).profileVisible,
        currentUserDepartments: state.profile.active.departments,
        coworkersEnabled: state.app.items.some(
            (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Coworkers,
        ),
    });

const ResponsiveProfileWithRouter = withRouter(ResponsiveProfile);

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveProfileWithRouter);