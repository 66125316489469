import { AxiosResponse } from "axios";
import moment from "moment";
import { Icon } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { RouteChildrenProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, PageHeader } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { fromNow } from "src/utils";
import api from "src/spintr/SpintrApi";
import { listActionMenuWidth } from "src/ui/helpers/style";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface Props extends RouteChildrenProps { }
interface State {
    list: any[];
    totalCount: number;
}

class PublishingView extends Component<Props, State> {
    private listRef = React.createRef<SpintrList>();

    state = {
        totalCount: 0,
        list: [],
    };

    private getStatusType = (status?: boolean) => {
        switch (status) {
            case undefined:
                return localize("EjBehandlad");
            case true:
                return localize("Godkand");
            case false:
                return localize("Avslagen");
        }
        return status;
    };

    render() {
        return (
            <div className="PublishingView">
                <Helmet>
                    <title>{localize("Publiceringsko")}</title>
                </Helmet>
                <PageHeader
                    title={localize("Publiceringsko")} />
                <SpintrList
                    ref={this.listRef}
                    history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/contentreview/userlist", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: true,
                                        isAscending: isAscending,
                                        searchText: searchQuery,
                                        take: take,
                                        skip: skip,
                                    },
                                })
                                .then((pagesResponse: AxiosResponse) => {
                                    let articles = pagesResponse.data.map((obj) => {
                                        if (obj.publishEndDate && new Date() > new Date(obj.publishEndDate)) {
                                            obj.status = 6;
                                        }

                                        return obj;
                                    });

                                    resolve({ data: articles, totalCount: pagesResponse.headers["x-total-count"] });
                                    this.setState({
                                        list: articles,
                                        totalCount: pagesResponse.headers["x-total-count"],
                                    });
                                });
                        });
                    }}
                    data={{ data: this.state.list, totalCount: this.state.totalCount }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (item) => {
                                let url = `/pages/${item.uberId}/review/${item.versionId}`;
                                if (item.type === SpintrTypes.UberType.NewsArticle) {
                                    url = `/news/edit/${item.uberId}/review/${item.versionId}`;
                                }
                                return (
                                    <Link
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                        to={url}
                                    >
                                        {item.name}
                                    </Link>
                                );
                            },
                        },

                        {
                            name: localize("SenastAndrad"),
                            fieldName: "changedate",
                            minWidth: 160,
                            onRender: (item) => {
                                return (
                                    <span
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {fromNow(item.changedate)}
                                    </span>
                                );
                            },
                        },

                        {
                            name: localize("Status"),
                            fieldName: "status",
                            onRender: (item) => {
                                return (
                                    <span
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {item.isDeleted ? localize("Borttagen") : this.getStatusType(item.status)}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("Fran"),
                            fieldName: "from",
                            minWidth: 120,
                            onRender: (item) => {
                                return (
                                    <Label
                                        title={moment(item.startdate).format("lll")}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {moment(item.startdate).format("lll")}
                                    </Label>
                                );
                            },
                        },
                        {
                            name: localize("Till"),
                            fieldName: "to",
                            minWidth: 160,
                            onRender: (item) => {
                                return (
                                    <Label
                                        title={moment(item.enddate).format("lll")}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                    >
                                        {moment(item.enddate).format("lll")}
                                    </Label>
                                );
                            },
                        },

                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                let url = `/pages/${item.uberId}/review/${item.versionId}`;
                                if (item.type === SpintrTypes.UberType.NewsArticle) {
                                    url = `/news/edit/${item.uberId}/review/${item.versionId}`;
                                }

                                return (
                                    <Link to={url} title={localize("Granska")}>
                                        <Visage2Icon icon="search-normal-1" />
                                    </Link>
                                );
                            },
                        },
                    ]}
                    isDescending={true}
                    orderByColumn="changedate"
                />
            </div>
        );
    }
}

export default withRouter(PublishingView);
