import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode } from "src/sidebar";

function isSidebarItemActive(item: IVisageSidebarMenuItem): boolean {
    let active = false;

    if (item.routes && item.routes.length > 0) {
        for (let route of item.routes) {
            if (!!item.activeMode && item.activeMode === VisageSidebarMenuItemActiveMode.relative && window.location.pathname.indexOf(route) === 0) {
                const excess = window.location.pathname.substring(route.length);

                if (!excess ||
                    excess.indexOf("?") === 0 ||
                    excess.indexOf("/") === 0) {
                    active = true;
                }
            } else if (window.location.pathname === route) {
                if (item.search !== undefined) {
                    if (item.search === "" && window.location.search === "") {
                        active = true;
                    } else if (!!item.search && window.location.search.indexOf(item.search) > -1) {
                        active = true;
                    }
                } else {
                    active = true;
                }
            }
        }
    }

    return active;
}

export default isSidebarItemActive;