import { DefaultButton, IColumn } from "@fluentui/react";
import { CancelToken } from "axios";
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";

import { IAppPageComponent } from "src/marketplace/types";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { AppPageContext } from "../AppPageContext";

interface IDataGridColumn {
    key: string;
    text: string;
    sortable: boolean;
}

interface ICursorPagination {
    style: "cursor";
    cursorKey: string;
}

interface IOffsetPagination {
    style: "offset";
}

interface IPagedPagination {
    style: "pages";
    zeroIndexed: boolean;
}

type PaginationSettings
    = ICursorPagination
    | IOffsetPagination
    | IPagedPagination
    ;

interface IProps extends IAppPageComponent {
    columns?: IDataGridColumn[];
    key?: string;
    rowLink?: string;
    dataVariable?: string;
    pagination?: PaginationSettings;
}

const AppPageDataGrid: FunctionComponent<IProps> = (props) => {
    const context = useContext(AppPageContext);
    const location = useLocation();
    const history = useHistory();

    const [dataState, setDataState] = useState<object[]>([]);

    const onRowClick = useCallback(
        (row: any) => {
            const rowLink = (props.rowLink || "").replace(
                /(?:{{([^}]+)}})/gm,
                (_, variable) => {
                    if (variable === "currentPath") {
                        return location.pathname;
                    }

                    return row[variable] || variable;
                },
            );

            history.push(rowLink);
        },
        [props.rowLink, history, location],
    );

    const columns = useMemo<IColumn[]>(
        () => (props.columns || []).map((column, index) => ({
            fieldName: column.key,
            key: column.key,
            minWidth: 0,
            name: context.text?.sv ? context.text.sv[column.text] || column.text : column.text,
            onRender: index !== 0 || !props.rowLink
                ? undefined
                : (item: any) => (
                    <Link
                        to={(props.rowLink || "").replace(
                            /(?:{{([^}]+)}})/gm,
                            (_, variable) => {
                                if (variable === "currentPath") {
                                    return location.pathname;
                                }
            
                                return item[variable] || variable;
                            },
                        )}
                    >
                        {item[column.key]}
                    </Link>
                )
        })),
        [props.columns, props.rowLink, context, location.pathname],
    );

    const { pagination } = props;
    const fetch = useCallback(
        (skip: number, take: number, column: string, ascending: boolean, query: string, cancellationToken: CancelToken, fetchMore: boolean): Promise<any> => {
            return new Promise((resolve, reject) => {
                if (props.dataVariable && props.variables) {
                    const data = props.variables[props.dataVariable];

                    resolve({ data });
                    return;
                }

                if (!props.requestName) {
                    reject();
                    return;
                }

                const requestParams: { [key: string]: any } = {
                    requestName: props.requestName,
                    variables: {},
                };
                
                if (props.requestVariables) {
                    requestParams.variables = props.requestVariables.reduce(
                        (acc, key) => { acc[key] = (props.variables || {})[key]; return acc; },
                        {},
                    );
                }

                if (pagination) {
                    switch (pagination.style) {
                        case "cursor":
                            if (dataState.length > 0) {
                                requestParams.variables["cursorKey"] = dataState[dataState.length - 1][pagination.cursorKey];
                            }
                            break;

                        case "offset":
                            requestParams.variables["offset"] = skip;
                            requestParams.variables["limit"] = take;
                            break;

                        case "pages":
                            requestParams.variables["pageNumber"] = (skip / take) + (pagination.zeroIndexed ? 0 : 1);
                            requestParams.variables["pageSize"] = take;
                            break;
                    }
                } else {
                    console.warn("Pagination was falsy!");
                }

                const promise = api.post(
                    `/api/v1/MarketplaceApplicationPage/${context.appId}/request`,
                    requestParams,
                    { cancelToken: cancellationToken }
                );

                promise
                    .then((response) => {
                        setDataState((data) => data.concat(response.data.items));

                        resolve({
                            data: dataState.concat(response.data.items),
                            totalCount: response.data.totalCount,
                        });
                    })
                    .catch((reason) => reject(reason));
            });
        },
        [context.appId, props.requestName, props.dataVariable, props.variables, props.requestVariables, dataState, pagination],
    );

    const dataVariables = props.variables[props.dataVariable];
    const exportResults = useCallback(
        () => {
            const data = !dataVariables ? dataState : dataVariables;
            const keys = columns.map((col) => col.key);

            let rows = [columns.map((col) => col.name)];

            for (let item of data) {
                let values = [];

                for (let key of keys) {
                    const value = item[key];

                    if (!value) {
                        values.push("");
                    } else {
                        values.push(value);
                    }
                }

                rows.push(values);
            }

            api.post("/api/files/generate-excel-file", rows).then((response) => {
                const link = document.createElement("a");

                link.setAttribute("href", response.data);
                link.setAttribute("target", "_blank");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(() => {});
        },
        [columns, dataState, dataVariables],
    );

    return (
        <div className={"AppPageDataGrid" + (!props.text ? " no-header" : "")}>
            {props.text && (
                <Label
                    as="div"
                    className="header"
                    size="h4"
                    weight="medium"
                >
                    {context.text?.sv ? context.text.sv[props.text] || props.text : props.text}
                </Label>
            )}
            <div className="actions">
                <DefaultButton
                    iconProps={{ iconName: "ExcelLogo" }}
                    onClick={exportResults}
                    text={localize("ExporteraLista")}
                />
            </div>
            <SpintrList
                columns={columns}
                fetch={fetch}
                orderByColumn={"title"}
                onRowClick={props.rowLink ? onRowClick : undefined}
                disableCommandBar={true}
                disablePagination={!props.pagination}
                infiniteScroll={!!props.pagination}
                disableSearch={true}
                disableSort={true}
            />
        </div>
    );
};

export default AppPageDataGrid;
