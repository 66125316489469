import React, { Component } from "react";
import { from, Subscription } from "rxjs";

import { localize } from "src/l10n";
import { deleteConfiguration, fetchLibrary } from "src/marketplace/api";
import { Loader } from "src/ui";
import {
    CreateConfigurationClickHandler,
    EditConfigurationClickHandler,
    LibraryConfigurationClickHandler
} from "./WidgetLibrary.types";
import MarketplaceDataLoader from "src/marketplace/utils/data-loader";

import WidgetLibraryAppCategory from "./WidgetLibraryAppCategory";

import "./WidgetLibrary.scss";
import { Link } from "react-router-dom";

interface IProps {
    isPopup?: boolean;
    showOptions?: boolean;
    onConfigurationClicked: LibraryConfigurationClickHandler;
    onCreateConfigurationClicked?: CreateConfigurationClickHandler;
    onEditConfigurationClicked?: EditConfigurationClickHandler
}

interface IState {
    data: Spintr.IMarketplaceLibraryWrapper[];
    isLoading: boolean;
    totalCount: number;
}

const initialState: IState = {
    data: [],
    isLoading: true,
    totalCount: 0,
};

class WidgetLibrary extends Component<IProps, IState> {
    private subscription: Subscription;

    constructor(props: IProps) {
        super(props);

        this.state = initialState;

        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    public addNewEntry(appId: string, entry: Spintr.IMarketplaceLibraryWidget) {
        const addEntry = () => {
            this.setState({
                data: this.state.data.map(item => {
                    if (item.app.id !== appId) {
                        return item;
                    }

                    return {
                        ...item,
                        widgets: item.widgets.concat([entry]),
                    };
                }),
            });
        }

        this.setState({
            data: this.state.data.map(item => {
                if (item.app.id !== appId) {
                    return item;
                }

                return {
                    ...item,
                    widgets: item.widgets.filter(w => w.id !== entry.id)
                };
            }),
        }, () => {
            MarketplaceDataLoader.remove(entry.id);

            addEntry();
        });
    }

    public componentDidMount() {
        this.subscription = from(fetchLibrary()).subscribe(
            (result) => {
                if (result == null) {
                    return; // TODO: maybe redirect
                }

                this.setState({
                    // TODO: Add unique by id or summat
                    data: this.state.data.concat(result.data),
                    isLoading: false,
                    totalCount: result.totalCount,
                })
            });
    }

    public componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    public render() {
        return (
            <div className="WidgetLibrary">
                {this.state.isLoading && (
                    <Loader />
                )}
                {!this.state.isLoading && this.state.data.length === 0 && (
                    <div className="empty-library">
                        {localize("MARKETPLACE_LIBRARY_EMPTY")
                            .split(/(\{\{.+\}\})/gmi)
                            .map(str => str.indexOf("{{") !== 0
                                ? str
                                : (
                                    <Link to="/marketplace">
                                        {str.replace("{{", "").replace("}}", "")}
                                    </Link>)
                                )
                        }
                    </div>
                )}
                {this.state.data && this.state.data.length > 0 && (
                    <div className="app-list">
                        {this.state.data
                            .filter((entry) => 
                                this.props.onCreateConfigurationClicked
                                || (
                                    entry.widgets.length > 0
                                )
                            )
                            .sort((a, b) => a.app.name.toLowerCase().localeCompare(b.app.name.toLowerCase()))
                            .map(entry => (
                                <WidgetLibraryAppCategory
                                    entry={entry}
                                    key={entry.app.id}
                                    onConfigurationClicked={this.props.onConfigurationClicked}
                                    onCreateConfigurationClicked={this.props.onCreateConfigurationClicked}
                                    onDeleteClick={this.onDeleteClick}
                                    onEditConfigurationClicked={this.props.onEditConfigurationClicked}
                                    showWidgetOptions={this.props.showOptions}
                                />
                            ))
                        }
                    </div>
                )}
            </div>
        );
    }

    private async onDeleteClick(configuration: Spintr.IMarketplaceLibraryWidget): Promise<void> {
        try {
            const success = await deleteConfiguration(configuration.id);
            if (!success) {
                return;
            }
        } catch { return; }

        this.setState((state) => ({
            ...state,
            data: state.data.map((app) => ({
                ...app,
                widgets: app.widgets.filter(
                    (config) => config.id !== configuration.id,
                ),
            })),
        }));
    }
}

export default WidgetLibrary;
