import classNames from "classnames";
import React, { FunctionComponent, useCallback } from "react";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import { mixpanelTrack, MixPanelEvents } from "src/utils";
import { StartPageBuilderDrawerItem } from "../StartPageBuilderDrawerItem";

interface IProps {
    props: { [key: string]: any };
    onPropsChange(newProps: { [key: string]: any }): void;
}

const templates = [{
    value: SpintrTypes.NewsFeedType.BigNewsArticles,
    nameKey: "DYNAMO_BIG_NEWS",
    descriptionKey: "",
    icon: "Bignewsarticles.svg",
},{
    value: SpintrTypes.NewsFeedType.SmallNewsArticles,
    nameKey: "DYNAMO_SMALL_NEWS",
    descriptionKey: "",
    icon: "Smallnewsarticles.svg",
},{
    value: SpintrTypes.NewsFeedType.ListView,
    nameKey: "DYNAMO_NEWS_LIST",
    descriptionKey: "",
    icon: "Llistnewsarticles.svg",
}];

const NewsLayoutSettings: FunctionComponent<IProps> = ({ props, onPropsChange }) => {
    const onTemplateClick = useCallback(
        (value: SpintrTypes.NewsFeedType.SmallNewsArticles) => {
            onPropsChange({ template: value });
            mixpanelTrack(MixPanelEvents.Dynamo_ClickNewsTemplate);
        },
        [onPropsChange],
    );

    return (
        <div className="NewsLayoutSettings">
            <div className="information">
                <Label
                    color="visageGray3"
                    size="body-1"
                >
                    {localize("CHOOSE_NEWS_LAYOUT")}
                </Label>
            </div>
            <div className="template-grid">
                <div className="heading">
                    <Label
                        className="primaryFGColor"
                        size="body-1"
                        weight="medium"
                    >
                        {localize("Mallar")}
                    </Label>
                </div>
                <div className="templates">
                    {templates.map((template) => (
                        <UnstyledButton
                            className={classNames({
                                "template": true,
                                "selected": props.template === template.value,
                                "primaryBorderColor": props.template === template.value
                            })}
                            key={template.value}
                            onClick={onTemplateClick}
                            onClickData={template.value}
                        >
                            <StartPageBuilderDrawerItem
                                disableDrag
                                componentKey={template.value.toString()}
                                title={localize(template.nameKey)}
                                text={template.descriptionKey && localize(template.descriptionKey)}
                                icon={template.icon}
                                index={0}
                                renderCheckbox={true}
                                selected={props.template === template.value}
                            />
                        </UnstyledButton>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewsLayoutSettings;
