import React from "react";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Label } from "src/ui";
import MiniTitlePriorityLevel from "./MiniTitlePriorityLevel";
import "./MiniTitle.scss";

interface IProps {
    text: string;
    icon?: string;
    readTimeMinutes?: number;
    priorityLevel?: number;
    isInNewsFeed?: boolean;
}

const MiniTitle = (props: IProps) => {
    return (
        <div className="mini-title-wrapper">
            <div className="mini-title">
                <div className="mini-title-item">
                    {
                        props.icon && <Visage2Icon icon={props.icon} size="small" />
                    }
                    <Label size="small-2" weight="normal" color="dark-grey">{props.text}</Label>
                </div>
                {(props.priorityLevel === 4 || props.priorityLevel === 5) && (
                    <MiniTitlePriorityLevel
                        priorityLevel={props.priorityLevel}
                        isInNewsFeed={props.isInNewsFeed} />
                )}
                {!!props.readTimeMinutes && (
                    <Label size="small-2" weight="normal" color="dark-grey">
                        {props.readTimeMinutes + " min " + localize("READ_TIME").toLowerCase()}
                    </Label>
                )}
            </div>
        </div>
    )
}

export default MiniTitle;
