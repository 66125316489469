import React, { Component, ReactNode } from 'react';
import { localize } from 'src/l10n';
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

export const VisageSidebarBackButton = (props) => {
    return (
        <div className="VisageSidebarBackButton VisageSidebar-menuItem-wrapper goBack">
            <UnstyledButton
                className="VisageSidebar-menuItem"
                onClick={props.onClick}>
                <div className="VisageSidebar-menuItem-titleRow">
                    <Visage2Icon
                        icon={"arrow-left"}
                        size={"medium"}
                        color="dark-grey" />
                    <Label
                        as="span"
                        size={"body-2"}
                        color={"dark-grey"}>
                        {localize("GaTillbaka")}
                    </Label>
                </div>
            </UnstyledButton>
        </div>
    )
}