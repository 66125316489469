import React, {
    ChangeEvent,
    ChangeEventHandler,
    Component,
    ReactNode
} from "react";
import { Label } from "src/ui/components/Label/Label";
import FormElement from "./FormElement";
import "./Textbox.scss";

export interface ITextboxProps {
    className?: string;
    errorText?: string;
    id?: string;
    multiline?: boolean;
    name?: string;
    onChange: ChangeEventHandler<FormElement>;
    placeholder?: string;
    type?: string;
    value?: string
}

interface ITextboxState {
    isDirty: boolean;
    isTouched: boolean;
}

/**
 * A textbox with build in multiline and error handling support.
 */
class Textbox extends Component<ITextboxProps, ITextboxState> {
    constructor(props : ITextboxProps) {
        super(props);
        
        this.state = {
            isDirty: false,
            isTouched: false
        };

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    public onChange(event : ChangeEvent<FormElement>) : void {
        this.props.onChange(event)

        if (this.state.isDirty) {
            return;
        }

        this.setState({ isDirty: true});
    }

    public onFocus() : void {
        if (this.state.isTouched) {
            return;
        }

        this.setState({ isTouched: true });
    }

    public render() : ReactNode {
        const attrs = {
            id: this.props.id,
            name: this.props.name,
            placeholder: this.props.placeholder,
            type: this.props.type || "text",
            value: this.props.value
        };

        const classNames = ["Textbox fs-body-2"];
        if (this.props.className) {
            classNames.push(this.props.className);
        }

        if (this.showError()) {
            classNames.push("faulty");
        }

        return (
            <div className={classNames.join(" ")}>
                {this.props.multiline 
                    ? <textarea
                        onFocus={this.onFocus}
                        onChange={this.onChange}
                        {...attrs} />
                    : <input
                        onFocus={this.onFocus}
                        onChange={this.onChange}
                        {...attrs} />
                }
                {this.renderError()}
            </div>
        )
    }

    public showError() {
        return this.props.errorText && this.state.isDirty && this.state.isTouched;
    }

    public renderError() : ReactNode {
        if (!this.showError()) {
            return null;
        }

        return (
            <div className="validationErrors">
                <Label
                    as="span"
                    className="text"
                    color="salmon"
                    size="small-1"
                >
                    {this.props.errorText}
                </Label>
            </div>
        );
    }
}

export default Textbox;