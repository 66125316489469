import React, { Component } from "react";
import UnreadIndicator from "./UnreadIndicator";
import "./UnreadIndicator.scss";

interface IProps {
    count?: number;
    margin?: number;
    ariaLabel?: string;
    secondaryPlacement?: boolean;
    active?: boolean;
}

class ContentWithUnreadIndicator extends Component<IProps> {

    render() {
        let margin = this.props.margin ? this.props.margin * -1 : 2 * -1;

        return (
            <div aria-label={this.props.ariaLabel} className="ContentWithUnreadIndicator">
                {this.props.children}
                <div
                    aria-label={this.props.ariaLabel}
                    className="UnreadIndicatorWrapper"
                    style={
                        this.props.secondaryPlacement
                            ? {
                                top: margin,
                                right: margin,
                            }
                            : {
                                bottom: margin,
                                right: margin,
                            }
                    }
                >
                    <UnreadIndicator count={this.props.count} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
    };
};

export default ContentWithUnreadIndicator;
