function stripHtml(input: string): string {
    if (!input) {
        return "";
    }

    const tmp = document.createElement("DIV");
    tmp.innerHTML = input.replace(/<br\s*[\/]?>/gi, " ");
    return tmp.textContent || tmp.innerText || "";
}

export default stripHtml;
