function getDomainFromUrl(text : string) : string {
    const a = document.createElement('a');

    a.href = text;

    let result = a.host;

    if (result.indexOf('www.') === 0) {
        result = result.substr(4);
    }

    if (!result) {
        return text;
    }

    return result;
}

export default getDomainFromUrl;