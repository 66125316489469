import React from "react";
import { Label } from "src/ui";
import "./AcademyCourseSidebarSection.scss";

interface IProps {
    title?: string;
    onRender: () => any;
}

const AcademyCourseSidebarSection = (props: IProps) => {
    return (
        <div className="AcademyCourseSidebarSection">
            {props.title && (
                <Label size="body-2" color="grey">{props.title}</Label>
            )}
            <div className="section-content">
                {props.onRender()}
            </div>
        </div>
    )
}

export default AcademyCourseSidebarSection;
