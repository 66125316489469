const elementIsHtmlOrBody = (el: Element, doc: Document): boolean => {
    return !!doc && (el === doc.documentElement || el === doc.body)
};

const Scroll = {
    getTop: (element: Element): number => {
        const doc = element.ownerDocument

        return elementIsHtmlOrBody(element, doc)
            ? doc.body.scrollTop || doc.documentElement.scrollTop
            : element.scrollTop;
    },

    setTop: (element: Element, position: number): void => {
        const doc = element.ownerDocument

        if (elementIsHtmlOrBody(element, doc)) {
            doc.body.scrollTop = doc.documentElement.scrollTop = position
        } else {
            element.scrollTop = position
        }
    },

    getLeft: (element: Element): number => {
        const doc = element.ownerDocument

        return elementIsHtmlOrBody(element, doc)
            ? doc.body.scrollLeft || doc.documentElement.scrollLeft
            : element.scrollLeft
    },

    setLeft: (element: Element, position: number): void => {
        const doc = element.ownerDocument

        if (elementIsHtmlOrBody(element, doc)) {
            doc.body.scrollLeft = doc.documentElement.scrollLeft = position
        } else {
            element.scrollLeft = position
        }
    }
}

export default Scroll;
