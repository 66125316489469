import React, { ReactElement } from "react";
import { SmallBodyBolder } from "../Text";
import { DividingHeaderProps } from "./DividingHeader.types";

/**
 * A header that divides content with a line.
 * 
 * @param props The props for the DividingHeader component.
 * @returns A ReactElement representing the DividingHeader component.
 */
function DividingHeader({ text }: DividingHeaderProps): ReactElement {
    return (
        <div className="DividingHeader">
            <div className="DividingHeader-line" />
            <SmallBodyBolder
                className="DividingHeader-text"
                color="contentLight"
            >
                {text}
            </SmallBodyBolder>
        </div>
    );
}

export default DividingHeader;
