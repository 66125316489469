import { Link } from "@fluentui/react";
import React, { memo } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import "./OperatingInfoSupportPost.scss";

interface IProps {
    items: Spintr.IOperatingInfoSupport[];
}

export default memo(function OperatingInfoSupportPost(props: IProps) {
    const { items } = props;

    return (
        <div className="operatingInfoSupportPost">
            {items.map((obj, index) => {
                return (
                    <div key={index} className="supportPost">
                        <div className="allinfo">
                            <Label as="h5" size="body-2" weight="medium">
                                {obj.title}
                            </Label>

                            {obj.phone && (
                                <Label as="p" size="body-3">
                                    {obj.phone}
                                </Label>
                            )}

                            {obj.email && (
                                <Label as="p" size="body-3">
                                    <Link href={`mailto:${obj.email}`}>{obj.email}</Link>
                                </Label>
                            )}

                            {obj.url && (
                                <Label as="p" size="body-3">
                                    <Link href={obj.url} target="_blank">
                                        {localize("Felanmalan")}
                                    </Link>
                                </Label>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});
