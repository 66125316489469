import classNames from "classnames";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui/components";
import "./InformationFeedContainer.scss";
import InformationFeedEntry from "./InformationFeedEntry";
import { SpintrTypes} from "src/typings";
import NewsSpotlightItem from "src/news/components/Spotlight/NewsSpotlightItem";

interface IInformationFeedContainerProps {
    items: Spintr.IInformationFeedItem[];
    onDelete?: any;
    identity?: any;
    template?: SpintrTypes.NewsFeedType;
}

class InformationFeedContainer extends Component<IInformationFeedContainerProps> {
    public render(): ReactNode {
        return (
            <div
                className={
                    "InformationFeedContainer" + 
                    (!this.props.template ? "" : (" template" + this.props.template))
                }
            >
                {this.props.items.length === 0 ? 
                    this.renderEmpty() : 
                    this.props.items.map((item, index) => this.renderItem(item, index === 0))}
            </div>
        );
    }

    public renderEmpty = () => {
        return (
            <div className="information-feed-empty">
                <Label size="body-2" color="mid-grey">
                    {localize("DetFinnsIngetInnehall")}
                </Label>
            </div>
        );
    };

    public renderItem = (item: Spintr.IInformationFeedItem, isLatest: boolean) => {
        const key: string = `InformationFeed.Item${item.id}`;

        if (this.props.template === SpintrTypes.NewsFeedType.BigNewsArticles && isLatest) {
            return (
                <div style={{width: "100%"}} key={key}>
                    {/*
                    //@ts-ignore */}
                    <NewsSpotlightItem article={{...item, attachedBackground: 2}} />
                </div>
            )
        }

        return (
            <div className={classNames("information-feed-entry")} key={key}>
                <InformationFeedEntry
                    identity={this.props.identity}
                    entry={item}
                    onDelete={this.props.onDelete}
                    spotlight={this.props.template === SpintrTypes.NewsFeedType.BigNewsArticles && isLatest}
                    template={this.props.template}
                />
            </div>
        );
    };
}

export default InformationFeedContainer;
