import React, { FunctionComponent, MouseEvent, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { MixPanelEvents, mixpanelTrack } from "src/utils";
import { StartPageBuilderContext } from "../StartPageBuilderContext";

type SizeChangeHandler = (sizeDelta: number, prevId: string, nextId: string, snapToGrid?: boolean) => void;

interface IProps {
    nextId: string;
    previousId: string;
    vertical?: boolean;
    snapOffset?: number;

    onSizeChange: SizeChangeHandler;
}

interface IState {
    handleOffset?: number;
    isDragging: boolean;
}

const StartPageBuilderDivider: FunctionComponent<IProps> = (props) => {
    const { nextId, onSizeChange, previousId, vertical } = props;

    const [state, setState] = useState<IState>({ isDragging: false });
    const selfRef = useRef<HTMLDivElement>();
    const { isDragging, handleOffset } = state;

    const onMouseDown = useCallback(
        (ev: MouseEvent) => {
            if (ev.preventDefault) {
                ev.preventDefault();
            }

            if (ev.stopPropagation) {
                ev.stopPropagation();
            }

            setState((s) => ({
                ...s,
                isDragging: true,
            }));
        },
        [setState],
    );

    const onMouseUp = useCallback(
        (ev: globalThis.MouseEvent) => {  
            if (!state.isDragging) {
                return;
            }

            if (ev.preventDefault) {
                ev.preventDefault();
            }

            if (ev.stopPropagation) {
                ev.stopPropagation();
            }

            if (ev.stopImmediatePropagation) {
                ev.stopImmediatePropagation();
            }

            const deltaX = ev.movementX;

            onSizeChange(
                deltaX > 0
                    ? Math.floor(deltaX)
                    : Math.ceil(deltaX),
                previousId,
                nextId,
                true
            );

            setState((s) => !s.isDragging ? s : ({
                ...s,
                isDragging: false
            }));

            mixpanelTrack(MixPanelEvents.Dynamo_ResizeColumn);
        },
        [nextId, onSizeChange, previousId, state, setState],
    );

    const onMouseLeave = useCallback(
        () => setState((s) => !s.isDragging ? s : ({
            ...s,
            currentDeltaX: undefined,
            currentDeltaY: undefined,
            isDragging: false,
            mouseStartX: undefined,
            mouseStartY: undefined,
        })),
        [setState],
    );

    useEffect(
        () => {
            const onMouseMove =  (ev: globalThis.MouseEvent) => {
                if (!isDragging) {
                    return;
                }
                
                if (!onSizeChange) {
                    return;
                }

                if (!vertical) {
                    onSizeChange(
                        ev.movementX,
                        previousId,
                        nextId,
                    );
                } // else if (vertical )
            };

            document.addEventListener("mousemove", onMouseMove);

            return () => {
                document.removeEventListener("mousemove", onMouseMove);
            }
        },
        [nextId, onSizeChange, previousId, isDragging, vertical],
    );

    useEffect(
        () => {
            document.addEventListener("mouseup", onMouseUp);

            return () => document.removeEventListener("mouseup", onMouseUp);
        },
        [onMouseUp],
    );

    useEffect(
        () => {
            document.addEventListener("mouseleave", onMouseLeave);

            return () => document.removeEventListener("mouseleave", onMouseLeave);
        },
        [onMouseLeave],
    );

    const selfElement = selfRef?.current;
    useEffect(
        () => {
            const onScroll = () => {
                if (!selfElement || vertical) {
                    return 0;
                }

                // TODO: Read this from appState
                const headerOffset = 94;

                const footerOffset = 80;
                const handleSize = 40;

                const screenHeight = window.document.firstElementChild.clientHeight;
                const ownRect = selfElement.getBoundingClientRect();

                const offset =  ownRect.top < 0
                    ? (((screenHeight - (headerOffset + footerOffset)) / 2) - ownRect.top) + headerOffset - (handleSize / 2)
                    : ((screenHeight - ownRect.top - footerOffset - handleSize) / 2);
        
                setState((s) => ({
                    ...s,
                    handleOffset: offset,
                }));
            };
        
            window.addEventListener("scroll", onScroll);

            return () => window.removeEventListener("scroll", onScroll);
        },
        [selfElement, vertical, setState],
    );

    return (
        <div
            className={
                "StartPageBuilderDivider" +
                (state.isDragging ? " dragging" : "") +
                (vertical ? " vertical" : " horizontal")
            }
            ref={selfRef}
            onMouseDown={onMouseDown}
        >
            {/*
            <div
                style={{
                    top: !vertical && handleOffset ? handleOffset + "px" : undefined,
                }}
                className="drag-handle"
            >
                <CustomArrowSplitVertical
                    scale={0.7}
                />
            </div>
            */}
            <div
                className="drop-indicator current"
                style={{
                    display: (state.isDragging)
                        ? "block"
                        : "none",

                    left: !props.snapOffset
                        ? 0
                        : `${props.snapOffset}px`,
                }}
            />
        </div>
    );
};

export default StartPageBuilderDivider;
