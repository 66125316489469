import { ActionButton, TextField } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    post: Spintr.ISocialPostBase;
    onAttachedContentUpdate: any;
    onRemove: any;
    disableEditing?: boolean;
}

interface IState {}

class Visage2ComposerPoll extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render(): ReactNode {
        return (
            <div className="Visage2ComposerImages">
                <div className="options">
                    <div className="centered-flex-row">
                        <Label size="body-2" weight="medium">
                            {localize("Omrostning")}
                        </Label>
                        {!this.props.disableEditing && (
                            <UnstyledButton
                                className="close-button close-button-circle"
                                onClick={() => {
                                    this.props.onRemove();
                                }}
                            >
                                <Visage2Icon icon="close-circle" />
                            </UnstyledButton>
                        )}
                    </div>
                    {this.props.post.options.map((option, i) => (
                        <div className="option" key={i}>
                            <TextField 
                                borderless={true}
                                className="option-input" 
                                data-index={i}
                                placeholder={localize("Svarsalternativ")}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.post,
                                        options: this.props.post.options.map((o: any, index: number) => {
                                            if (i === index) {
                                                return {
                                                    ...o,
                                                    option: newValue
                                                }
                                            } else {
                                                return o;
                                            }
                                        })
                                    });
                                }}
                                value={option.option}
                                maxLength={128}
                                disabled={this.props.disableEditing}
                            />
                            {this.props.post.options.length > 2 && !this.props.disableEditing &&
                                <UnstyledButton
                                    className="remove-icon"
                                    onClick={() => {
                                        const allItems = [...this.props.post.options];
                                        
                                        allItems.splice(i, 1);

                                        this.props.onAttachedContentUpdate({
                                            ...this.props.post,
                                            options: allItems
                                        });
                                    }
                                }>
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            }
                        </div>
                    ))}
                    {!this.props.disableEditing && (
                      <div className="add">
                          <UnstyledButton onClick={() => {
                              this.props.onAttachedContentUpdate({
                                  ...this.props.post,
                                  options: [
                                      ...this.props.post.options,
                                      {
                                          id: 0,
                                          option: "",
                                          answers: []
                                      }
                                  ]
                              });
                          }}>
                              <Visage2Icon icon="add-circle" color="light-blue" />
                              <Label color="light-blue" size="body-2">{localize("LaggTillEttSvarsalternativ")}</Label>
                          </UnstyledButton>
                      </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Visage2ComposerPoll;