import React, { FunctionComponent, useMemo } from "react";
import { IAppPageComponent } from "src/marketplace/types";
import { buildAppPageComponent } from "src/marketplace/utils";

interface IProps extends IAppPageComponent {
    color?: string;
}

const AppPageBackground: FunctionComponent<IProps> = ({ children, color }) => {
    const items = useMemo(
        () => (children || []).map(buildAppPageComponent),
        [children],
    );

    return (
        <div
            className="AppPageBackground"
            style={{
                backgroundColor: color
                    ? "#" + color
                    : "#FFF",
            }}
        >
            {items}
        </div>
    );
};

export default AppPageBackground;
