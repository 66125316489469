import classnames from "classnames";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { BackgroundImage, Label } from "../../../ui";
import { Icon } from "@fluentui/react";
import MiniTitle from "./MiniTitle";
import TranslateButton from "src/ui/components/TranslateButton";
import { SpintrTypes } from "src/typings";
import { fromNow } from "src/utils";

interface INewsSpotlightItemProps extends RouteComponentProps {
    article: Spintr.SpotlightNewsArticle,
    primaryFGColor: string;
    isBlogpost?: boolean;
    enableTranslator: boolean;
    cropHeaderImagesAfterUpload: boolean;
}

interface INewsSpotlightItemState {
    translatedTexts: object;
}

function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}
class NewsSpotlightItem extends Component<INewsSpotlightItemProps, INewsSpotlightItemState> {
    constructor(props) {
        super(props);

        this.state = {
            translatedTexts: {
                [SpintrTypes.TranslateType.Title]: this.props.article.introTitle || this.props.article.title,
                [SpintrTypes.TranslateType.Preamble]: this.props.article.introPreamble || this.props.article.preamble,
                [SpintrTypes.TranslateType.CommentPreview]: this.props.article.commentPreview?.text,
            }
        };
    }

    componentDidUpdate(prevProps: Readonly<INewsSpotlightItemProps>, prevState: Readonly<INewsSpotlightItemState>, snapshot?: any): void {
        if (!!prevProps.article && this.props.article && prevProps.article.title !== this.props.article.title) {
            this.setState({
                translatedTexts: {
                    ...prevState.translatedTexts,
                    [SpintrTypes.TranslateType.Title]: this.props.article.title,
                },
            })
        }

        if (prevState.translatedTexts?.[SpintrTypes.TranslateType.Preamble] !== (this.props.article.introPreamble || this.props.article.preamble)) {
            this.setState({
                translatedTexts: {
                    ...prevState.translatedTexts,
                    [SpintrTypes.TranslateType.Preamble]: this.props.article.introPreamble || this.props.article.preamble,
                },
            })
        }
    }
    
    renderMiniTitle() {
        return (
            <MiniTitle
                icon="firstline"
                text={(this.props.article.uberCategories || []).length > 0 ?
                    this.props.article.uberCategories[0].name :
                        this.props.isBlogpost ?
                            (this.props.article?.author ?? localize("Bloggpost")) :
                            localize("Nyhet")}
                readTimeMinutes={this.props.article.readTimeMinutes}
                priorityLevel={this.props.article.priorityLevel}
            />
        )
    }

    protected translate = (text: string, key: string) => {
        //@ts-ignore
        this.setState((prevState) => ({
            translatedTexts: {
                ...prevState.translatedTexts,
                [key]: text,
            },
        }));
    }

    public render(): ReactNode {
        const article: Spintr.SpotlightNewsArticle = this.props.article;

        const backgroundImageStyle = this.props.cropHeaderImagesAfterUpload ? {
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain"
        } : {};

        const useWhiteText = !!article.imageUrl || article.attachedBackground === 4;

        return (
            <Link
                className={[
                    "spotlight-item",
                    (!article.imageUrl ? "" : "no-image"),
                    (useWhiteText ? "use-white-text" : ""),
                ].join(" ")}
                to={article.url}
            >
                {this.props.cropHeaderImagesAfterUpload && (
                    <div className="image-blur" style={{
                        backgroundImage: `url(${article.imageUrl})`
                    }} />
                )}
                <BackgroundImage
                    imageUrl={article.imageUrl}
                    center
                    style={backgroundImageStyle}
                >
                    <div
                        style={article.attachedBackground === 3 ? {
                            background: `linear-gradient(0deg, ${hexToRGB(this.props.primaryFGColor, 0.7)} 0%, ${hexToRGB(this.props.primaryFGColor, 0)} 100%)`
                        } : {}}
                        className={classnames("gradient", {
                            ["spotlight-gradient"]: article.attachedBackground === 2,
                            ["spotlight-gradient instanceColor"]: article.attachedBackground === 3
                        })}>
                        <div className="spotlight-item-content">
                            {this.renderMiniTitle()}
                            <Label
                                size="body-2"
                                className="spotlight-item-date">
                                {fromNow(article.date, true)}
                            </Label>
                            <Label
                                as="h3"
                                size="h4"
                                weight="medium"
                                className="spotlight-item-title">
                                {this.state.translatedTexts[SpintrTypes.TranslateType.Title]}
                            </Label>
                            <Label
                                as="p"
                                size="body-2"
                                className={classnames("spotlight-item-text", { translationEnabled: this.props.enableTranslator })}>
                                {this.state.translatedTexts[SpintrTypes.TranslateType.Preamble]}
                            </Label>
                            <TranslateButton
                                authorLanguage={article.language === "" ? article.authorLanguage : article.language}
                                uberId={article.id} 
                                texts={{
                                    [SpintrTypes.TranslateType.Title]: this.state.translatedTexts[SpintrTypes.TranslateType.Title],
                                    [SpintrTypes.TranslateType.Preamble]: this.state.translatedTexts[SpintrTypes.TranslateType.Preamble],
                                    [SpintrTypes.TranslateType.CommentPreview]: this.state.translatedTexts[SpintrTypes.TranslateType.CommentPreview],
                                }}
                                onTranslateFn={this.translate}
                                textColor={useWhiteText ? "white" : "black"} />
                            <SocialBlock
                                uberIsComment={!article.allowComments}
                                uberId={article.id}
                                comments={[]}
                                staticCommentCount={article.commentCount}
                                likers={article.likers}
                                onlyDisplayInteractionsBar={true}
                                interactionsBarColor={useWhiteText ? "white" : "mid-grey"}
                                interactionsBarCommentPreview={
                                    !!this.state.translatedTexts[SpintrTypes.TranslateType.CommentPreview]
                                        ? { ...article.commentPreview, text: this.state.translatedTexts[SpintrTypes.TranslateType.CommentPreview] }
                                        : undefined
                                }
                                displayShareButton
                                isUnread={this.props.article.isUnread} />
                        </div>
                    </div>
                </BackgroundImage>
            </Link>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    primaryFGColor: state.instance.get("color"),
    enableTranslator: state.instance.get("enableTranslator"),
    cropHeaderImagesAfterUpload: state.instance.get("cropHeaderImagesAfterUpload")
});

export default withRouter(connect(mapStateToProps)(NewsSpotlightItem));