import { AxiosError, AxiosResponse } from "axios";
import React, { Component, ReactNode } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { from, Subscription } from "rxjs";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Loader } from "src/ui";

interface IMatchParams {
    typeId?: string;
    uberId: string;
}

type Props = RouteComponentProps<IMatchParams>;

class GotoView extends Component<Props> {
    private routeSubscription: Subscription = null;

    constructor(props: Props) {
        super(props);

        console.log(props);
    }

    public componentDidMount(): void {
        const { typeId, uberId } = this.props.match.params;
        
        let type: number;
        if (typeId && !isNaN((type = parseInt(typeId, 10)))) {
            const types = [
                SpintrTypes.UberType.User,
                SpintrTypes.UberType.File,
            ];

            if (types.indexOf(type) > -1) {
                // No need to send a request if any of these types
                return;
            }
        }

        const apiUrl = "/api/v1/routes/" + uberId;
        this.routeSubscription = from(
                api.get<Spintr.IObjectRouteResponse>(apiUrl)
            )
            .subscribe({
                error: this.onError.bind(this),
                next: this.onSuccess.bind(this),
            });
    }

    public componentWillUnmount(): void {
        if (!this.routeSubscription) {
            return;
        }

        this.routeSubscription.unsubscribe();
    }

    public render(): ReactNode {
        const { typeId, uberId } = this.props.match.params;

        let type: number;
        if (typeId && !isNaN((type = parseInt(typeId, 10)))) {
            switch (type) {
                case SpintrTypes.UberType.File:
                    window.open("/api/v1/servefile/" + uberId, "_blank");
                    this.props.history.go(-1);
                    break;
                case SpintrTypes.UberType.User:
                    return <Redirect to={"/profile/" + uberId} />;
            }
        }

        return <Loader />;
    }

    protected onError(_: AxiosError): void {
        this.props.history.replace({
            pathname: "/404",
        });
    }

    protected onSuccess(response: AxiosResponse<Spintr.IObjectRouteResponse>): void {
        const { id, type, url } = response.data;

        if (type === SpintrTypes.UberType.File) {
            window.open("/api/v1/servefile/" + id, "_blank");
            this.props.history.go(-1);
            return;
        }

        this.props.history.replace({
            pathname: (url.charAt(0) === "/")
                ? url
                : "/" + url,
        });
    }
}

export default GotoView;
