import React, { Component, CSSProperties, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { decodeHtmlEntities } from "src/utils";
import { UnstyledButton } from "./Buttons";
import Label from "./Label/Label";
import "./TranslateButton.scss";
import { SpintrTypes } from "src/typings";
import { Stack, TooltipHost } from "@fluentui/react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

const languageCodes = ["sv", "nb", "en", "de", "fi", "da", "it", "es", "fr"];

export const getLanguageCodeFromSpintrLanguage = (spintrLanguage: number) => {
    return languageCodes[spintrLanguage - 1];
};

export const getSpintrLanguageFromLanguageCode = (languageCode: string) => {
    return languageCodes.indexOf(languageCode) + 1;
};

interface IProps {
    userLanguage: number;
    authorLanguage?: number | string;
    text?: string;
    texts?: object;
    onTranslateFn: (translatedText: string, key?: string) => any;
    enableTranslator: boolean;
    uberId: number;
    textColor?: spintrColors;
    infoText?: string;
}

interface IState {
    isTranslated: boolean;
    originalTexts?: object;
}

class TranslateButton extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isTranslated: false,
        };
    }

    componentDidMount = () => {
        const autoTranslate = false;
        if (autoTranslate) {
            this.translatePostText();
        }
    };

    translatePostText = async () => {
        const userLanguageCode = getLanguageCodeFromSpintrLanguage(this.props.userLanguage);

        if (this.props.text) {
            try {
                const translation = (await api.post("/api/translate", {
                    uberId: this.props.uberId,
                    text: decodeHtmlEntities(this.props.text),
                    from: "",
                    to: userLanguageCode,
                })) as any;

                const translatedText = translation.data;
                this.setState({ isTranslated: true });
                this.props.onTranslateFn(translatedText);
            } catch (_) {
                /* ignored */
            }
        } else if (this.props.texts) {
            for (const [key, value] of Object.entries(this.props.texts)) {
                // fulhack for text pages
                const isExtraId = typeof value === "object" && value != null;
                let text = isExtraId ? value.text : value;
                const type = isExtraId ? SpintrTypes.TranslateType.ExtraId : parseInt(key, 10);
                let columnObj;

                if (value.type === SpintrTypes.UberContentSectionType.UserList) {
                    columnObj = JSON.parse(text);

                    text = columnObj.title;
                }

                this.setState({ originalTexts: { ...this.state.originalTexts, [key]: value } });
                try {
                    const translation = (await api.post("/api/translate", {
                        uberId: this.props.uberId,
                        text: text,
                        from: "",
                        to: userLanguageCode,
                        type: type,
                        extraId: isExtraId ? value.id : undefined,
                    })) as any;


                    let translatedText;
                    if (value.type === SpintrTypes.UberContentSectionType.UserList) {
                        columnObj.title = translation.data;
                        translatedText = JSON.stringify(columnObj);
                    } else {
                        translatedText = translation.data;
                    }

                    this.setState({ isTranslated: true });
                    this.props.onTranslateFn(translatedText, key);
                } catch (_) {
                    /* ignored */
                }
            }
        }
    };

    resetTranslation = () => {
        this.setState({ isTranslated: false });
        if (this.props.text) {
            this.props.onTranslateFn(this.props.text);
        } else {
            for (const [key, value] of Object.entries(this.state.originalTexts)) {
                this.props.onTranslateFn(value, key);
            }
            this.setState({ isTranslated: false });
        }
    };

    render = () => {
        let authorLanguage = this.props.authorLanguage;

        if (typeof authorLanguage === "string") {
            authorLanguage = getSpintrLanguageFromLanguageCode(authorLanguage);
        }

        if (!this.props.enableTranslator) {
            // || authorLanguage === this.props.userLanguage
            return null;
        }

        return (
            <Stack horizontal tokens={{ childrenGap: 4 }} className="translate-button">
                <UnstyledButton onClick={this.state.isTranslated ? this.resetTranslation : this.translatePostText}>
                    <Label size="body-3" color={this.props.textColor ?? "mid-grey"}>
                        {localize(this.state.isTranslated ? "SeOriginal" : "SeOversattning")}
                    </Label>
                </UnstyledButton>
                {this.props.infoText && (
                    <TooltipHost content={this.props.infoText} className="marketplace-tooltip">
                        <Visage2Icon icon="info-circle" size="small" />
                    </TooltipHost>
                )}
            </Stack>
        );
    };
}
const mapStateToProps = (state, props) => ({
    ...props,

    userLanguage: Number(state.profile.active.settings.language),
    enableTranslator: state.instance.get("enableTranslator"),
});

export default connect(mapStateToProps)(TranslateButton);
