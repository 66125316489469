import { TextField } from "@fluentui/react";
import React, { ChangeEvent, FunctionComponent, useCallback } from "react";

import { FormControl, FormSection } from "src/ui/components/Forms";
import { DateVariableInput } from "../DateVariableInput";

interface IProps {
    variable: Spintr.IMarketplaceVariableInput;
    onChange(variable: Spintr.IMarketplaceVariableInput): void;
}

const VariableInput: FunctionComponent<IProps> = ({ onChange, variable }) => {
    const onTextChanged = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => onChange({
            ...variable,
            value: event.target.value,
        }),
        [onChange, variable],
    );

    const renderInput = () => {
        switch (variable.dataType) {
            case "date":
                return (
                    <DateVariableInput
                        onChange={onChange}
                        variable={variable} 
                    />
                );

            case "number":
            case "string":
            default:
                return (
                    <FormControl>
                        <TextField
                            label={variable.name}
                            onChange={onTextChanged}
                            name={variable.variableName}
                            type={
                                variable.dataType === "number"
                                    ? "number"
                                    : "text"
                            }
                            value={variable.value}
                        />
                    </FormControl>
                );
        }
    };

    return (
        <div className="VariableInput">
            <FormSection title={variable.dataType !== "date" ? undefined : variable.name}>
                {renderInput()}
            </FormSection>
        </div>
    );
};

export default VariableInput;
