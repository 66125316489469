import { Stack } from "@fluentui/react";
import React, { Component, ReactNode, useState } from "react";
import { Label } from "src/ui";
import { UnstyledButton } from "../Buttons";
import "./ExpandableFormField.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    title: string;
    children?: any;
    startExpanded?: boolean;
}

const ExpandableFormField = (props: IProps) => {
    const [isExpanded, setIsExpanded] = useState(props.startExpanded);

    return (
        <div className="ExpandableFormField">
            <UnstyledButton onClick={() => {
                setIsExpanded(!isExpanded);
            }}>
                <Stack horizontal horizontalAlign="space-between" grow>
                    <Label size="body-2" weight="medium">{props.title}</Label>
                    <Visage2Icon icon={isExpanded ? "arrow-up-2" : "arrow-down-1"} color="light-blue" />
                </Stack>
            </UnstyledButton>
            {isExpanded && (
                <div className="ExpandableFormField-content">
                    {props.children}
                </div>
            )}
        </div>
    )
}

export default ExpandableFormField;