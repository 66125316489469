import { DefaultButton, IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import { ActionButton } from "@fluentui/react/lib/components/Button/ActionButton/ActionButton";
import { Modal } from "@fluentui/react/lib/components/Modal/Modal";
import { Slider } from "@fluentui/react/lib/components/Slider";
import React, { Component } from "react";
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { isAnythingDirty } from "src/utils";
import "./ImageCropper.scss";
import ModalHeader from "./Modal/ModalHeader";
import PopupHeader from "./PopupHeader";

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

function getCanvasBlob(canvas) {
    return new Promise((resolve, reject) => canvas.toBlob((blob) => resolve(blob)));
}

export const getCroppedImage = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = (await createImage(imageSrc)) as any;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation)
    const bBoxWidth =
        Math.abs(Math.cos(rotRad) * image.width) +
        Math.abs(Math.sin(rotRad) * image.height);
    const bBoxHeight =
        Math.abs(Math.sin(rotRad) * image.width) +
        Math.abs(Math.cos(rotRad) * image.height);

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);

    ctx.translate(-image.width / 2, -image.height / 2);
    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
    );

    // set the canvas size to the final image size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste the extracted image at the top left corner
    ctx.putImageData(data, 0, 0)

    let extension = "jpg";
    let extension2 = "jpeg";

    // This is to keep .png format for transparency
    if (!!imageSrc &&
        imageSrc.length > 0 &&
        imageSrc.indexOf(',') > -1) {
        let splittedImageSrc = imageSrc.split(',');

        if (splittedImageSrc.length > 1 &&
            splittedImageSrc[1].length > 0) {
            let base64Extension = splittedImageSrc[1][0];

            if (base64Extension === "i") {
                extension = "png";
                extension2 = "png";
            }
        }
    }

    const blob = await getCanvasBlob(canvas);

    // @ts-ignore
    blob.name = "image." + extension;

    return [canvas.toDataURL("image/" + extension2), blob];
};

{
    /* See https://codesandbox.io/s/q8q1mnr01w?file=/src/index.js:1609-1621 */
}
//             const blobImg = (await getCroppedImage(post.image, this.state.croppedAreaPixels, this.state.imageRotation)) as any;
interface Props {
    image: any;
    show: boolean;
    onConfirm: any;
    onDismiss: any;
    cropAspect: number;
    cropShape: "rect" | "round";
    dispatch?: any;
}

interface State {
    crop: any;
    zoom: any;
    rotation: any;
    croppedAreaPixels: any;
}

const stackTokens: IStackTokens = { childrenGap: 20 };
// const stackStyles: IStackStyles = {
//     root: {
//         marginLeft: "2em",
//         marginRight: "2em",
//     },
// };
class ImageCropper extends Component<Props, State> {
    state = {
        crop: { x: 0, y: 0 },
        zoom: 1,
        rotation: 0,
        croppedAreaPixels: null,
    };

    onZoomChange = (zoom) => {
        this.setState({ zoom });
    };

    rotateLeft = () => {
        this.setState({ rotation: this.state.rotation - 90 });
    };

    rotateRight = () => {
        this.setState({ rotation: this.state.rotation + 90 });
    };

    onConfirm = async () => {
        let [blobImg, imageFilePromise] = await getCroppedImage(
            this.props.image,
            this.state.croppedAreaPixels,
            this.state.rotation
        );
        this.props.onConfirm(blobImg, imageFilePromise, {
            crop: this.state.crop,
            zoom: this.state.zoom,
            rotation: this.state.rotation,
            croppedAreaPixels: this.state.croppedAreaPixels,
        });
    };

    onDismiss = () => {
        const close = () => {
            this.props.onDismiss();
        };

        if (isAnythingDirty()) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    message: localize("UnsavedChangesWarning"),
                    onConfirm: close,
                })
            );
        } else {
            close();
        }
    };

    render() {
        const zoomNumber = parseFloat(this.state.zoom.toFixed(1));
        return (
            <Modal className="ImageCropperModal modalWithPopupHeader" isOpen={this.props.show} onDismiss={this.onDismiss}>
                <PopupHeader
                    text={localize("BeskarBild")}
                    onClose={this.onDismiss} />
                <div className="Cropper-Inner">
                    <Cropper
                        aspect={this.props.cropAspect ? this.props.cropAspect : 45 / 17}
                        cropShape={this.props.cropShape ? this.props.cropShape : "rect"}
                        crop={this.state.crop}
                        onCropChange={(crop) => {
                            this.setState({ crop });
                        }}
                        onCropComplete={(_croppedArea, croppedAreaPixels) => {
                            console.log(_croppedArea, croppedAreaPixels);
                            this.setState({ croppedAreaPixels });
                        }}
                        zoom={this.state.zoom}
                        onZoomChange={this.onZoomChange}
                        image={this.props.image}
                        rotation={this.state.rotation}
                    />
                </div>

                <Stack className="control-wrapper">
                    <Stack
                        className="control-stack"
                        // styles={stackStyles}
                        tokens={stackTokens}
                        horizontalAlign="space-between"
                        horizontal
                    >
                        <Slider
                            value={zoomNumber}
                            min={1}
                            step={0.1}
                            label={localize("Zoom")}
                            onChange={this.onZoomChange}
                        />

                        <div className="action-buttons">
                            <Stack horizontal className="rotation-buttons" verticalAlign="center">
                                <ActionButton
                                    onClick={this.rotateLeft}
                                    iconProps={{ iconName: "Rotate90CounterClockwise" }}
                                >
                                    {localize("RoteraTillVanster")}
                                </ActionButton>
                                <ActionButton onClick={this.rotateRight} iconProps={{ iconName: "Rotate90Clockwise" }}>
                                    {localize("RoteraTillHoger")}
                                </ActionButton>
                            </Stack>
                        </div>
                    </Stack>

                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="end"
                        className="ok-cancel-buttons"
                        tokens={{ childrenGap: 6 }}
                    >
                        <DefaultButton onClick={this.onDismiss}>{localize("Avbryt")}</DefaultButton>
                        <PrimaryButton onClick={this.onConfirm}>{localize("LaddaUpp")}</PrimaryButton>
                    </Stack>
                </Stack>
            </Modal>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
    };
};

export default connect(mapStateToProps)(ImageCropper);
