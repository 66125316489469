export default function toWebSafe(text: string): string {
    text = text.toLowerCase();
    text = text.replace(/&/g, "och");
    text = text.replace(/ /g, "-");
    text = text.replace(/å/g, "a");
    text = text.replace(/ä/g, "a");
    text = text.replace(/ö/g, "o");
    text = text.replace(/\//g, "-");

    text = text.replace(/[^a-zA-Z0-9\-/]/g, "");

    return text;
}