import { Checkbox, PrimaryButton } from "@fluentui/react";
import { EditorState } from "draft-js";
import createEmojiPlugin, {
    EmojiPlugin
} from "draft-js-emoji-plugin";
import createMentionPlugin, {
    DraftMentionObject,
    MentionSearchChangedEvent,
    MentionsPlugin
} from "draft-js-mention-plugin";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { connect } from "react-redux";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { searchUsers } from "src/search";
import { addLocallyCreatedPost, LocallyCreatedPostAdder } from "src/social-feed";
import { Loader } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { mixpanelTrack, parentBy } from "src/utils";
import { getContentStateFromString } from "src/utils/getContentStateFromString";
import { ComposerContext } from "./ComposerContext";
import { ComposerTypeSelectorBar, ExpandedComposerTypeSelector } from "./ComposerTypeSelector";
import SocialComposerExternalFile from "./SocialComposerExternalFile";
import SocialComposerFooter from "./SocialComposerFooter";
import SocialComposerFrame from "./SocialComposerFrame";
import SocialComposerImages from "./SocialComposerImages";
import SocialComposerPoll from "./SocialComposerPoll";
import SocialComposerText from "./SocialComposerText";
import SocialComposerTextInput, { ISocialComposerTextInputProps } from "./SocialComposerTextInput";
import { ISocialComposerType } from "./SocialComposerType";
import SocialComposerTypes from "./SocialComposerTypes";
import SocialComposerUserHeader from "./SocialComposerUserHeader";
import SocialComposerVideo from "./SocialComposerVideo";
import { draftToHtml } from "./utils";
import { SpintrTypes } from "src/typings/index";
import SocialComposerHeader from "./SocialComposerHeader";

interface IOwnProps {
    feedId?: number;
    flag?: number;
    galleryId?: number;
    identity?: any;
    isUnit?: boolean;
    isStartPage?: boolean;
    types: SocialComposerTypes[];
    onClosing?: () => void;
}

enum ComposerStage {
    EditingPost = 1,
    EditingKudos = 2,
    EditingDeal = 3,
}

interface IStateProps {
    enableSharepoint: boolean;
    user: IActiveUserProfile;
    publishToGeneralFeedChecked: boolean;
    smallScreen: boolean;
}

interface IDispatchProps {
    addLocallyCreatedPost: LocallyCreatedPostAdder;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

interface ISocialComposerState {
    expanded: boolean;
    isSaving: boolean;
    suggestions: DraftMentionObject[];
    publishOnStartPage: boolean;
    displayEffectTextTooLongMessage: boolean;
    saveError?: any;
    feedId?: number;
    stage: ComposerStage;
}

class SocialComposer extends Component<Props, ISocialComposerState> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;

    protected mentionPlugin: MentionsPlugin;
    protected emojiPlugin: EmojiPlugin;
    protected mentionSubject: Subject<string>;
    protected mentionSubscription: Subscription;
    protected typeRef: RefObject<ISocialComposerType>;

    constructor(props: Props) {
        super(props);

        this.typeRef = createRef();

        this.state = {
            displayEffectTextTooLongMessage: false,
            expanded: true,
            feedId: this.props.feedId,
            isSaving: false,
            publishOnStartPage: this.props.isUnit
                ? this.props.publishToGeneralFeedChecked
                : true,
            suggestions: [],
            stage: ComposerStage.EditingPost
        };

        // this.handleTabbing = this.handleTabbing.bind(this);
        this.onAddMention = this.onAddMention.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onMentionValue = this.onMentionValue.bind(this);
        this.onOverlayClicked = this.onOverlayClicked.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onPublishOnStartPageChanged = this.onPublishOnStartPageChanged.bind(this);
        this.onTypeChanged = this.onTypeChanged.bind(this);

        this.mentionPlugin = createMentionPlugin();
        this.emojiPlugin = createEmojiPlugin();

        this.mentionSubject = new Subject();
        this.mentionSubscription = this.mentionSubject
            .pipe(
                debounceTime(100)
            )
            .subscribe(this.onMentionValue);
    }

    public componentWillUnmount(): void {
        this.mentionSubscription.unsubscribe();
    }

    public onFocus() {
        this.setState({
            expanded: true
        });
    }

    public onOverlayClicked(): void {
        this.setState(
            { expanded: false },
            () => this.props.onClosing && this.props.onClosing(),
        );
    }

    public render(): ReactNode {
        const wrapperClasses: string[] = ["composer-wrapper", this.context?.type];
        if (this.state.expanded) {
            wrapperClasses.push("expanded");
        }

        if (this.context.size !== "default") {
            wrapperClasses.push(this.context.size);
        }

        const textEditorProps: ISocialComposerTextInputProps = {
            editorState: this.context.editorState,
            onChange: this.onEditorStateChange,
            onFocus: this.onFocus,
            plugins: [
                this.mentionPlugin,
                this.emojiPlugin
            ]
        };

        const { MentionSuggestions } = this.mentionPlugin;
        const { EmojiSuggestions } = this.emojiPlugin;

        return (
            <div id="SocialComposer" 
                onKeyUp={this.handleEscapeKey}
                // onKeyDown={this.handleTabbing}
            >
                <div className={wrapperClasses.join(" ")}>
                    <div
                        className="composer-overlay"
                        onClick={this.onOverlayClicked}
                    // role="presentation" 
                    />
                    <div className="composer-container pop-box-shadow">
                        {this.state.saveError && this.state.saveError.length > 0 && (
                            <ErrorMessagebar
                                errorList={this.state.saveError}
                                onDismiss={() => {
                                    this.setState({ saveError: [] });
                                }}
                            />
                        )}
                        <div className="composer-content">
                            {this.props.isUnit && this.state.expanded && (
                                <Checkbox
                                    className="publish-on-startpage-checkbox"
                                    label={localize("PubliceraAvenPaStartsidan")}
                                    checked={this.state.publishOnStartPage}
                                    onChange={this.onPublishOnStartPageChanged}
                                />
                            )}
                            <SocialComposerHeader
                                onCloseClick={() => {
                                    if (this.props.onClosing) {
                                        this.props.onClosing();
                                    }
                                }}
                            />
                            {
                                this.state.stage === ComposerStage.EditingPost && (
                                    <div className="composer-content-inner">
                                        {this.renderUserHeader()}
                                        {this.renderSocialComposerText()}
                                        <div className="composer-footer">
                                            <PrimaryButton text={"Spara"} onClick={this.onSaveClicked} />
                                        </div>
                                    </div>
                                )
                            }
                            <MentionSuggestions
                                onAddMention={this.onAddMention}
                                suggestions={this.state.suggestions}
                                onSearchChange={this.onSearchChange}
                            />
                            <EmojiSuggestions />
                        </div>
                        <CustomDialog
                            message={localize("TextenITavlarArForLang130")}
                            show={this.state.displayEffectTextTooLongMessage}
                            confirmMessage={localize("Ok")}
                            onDismiss={() => {
                                this.setState({
                                    displayEffectTextTooLongMessage: false
                                }, () => {
                                    const textContent = draftToHtml(
                                        this.context.editorState.getCurrentContent()
                                    );

                                    this.context.setEditorState(
                                        EditorState.createWithContent(
                                            getContentStateFromString(
                                                textContent.substring(0, 130)
                                            )
                                        )
                                    );
                                });
                            }}
                            onConfirm={() => {
                                this.setState({
                                    displayEffectTextTooLongMessage: false
                                }, () => {
                                    this.onTypeChanged("text");
                                });
                            }} />
                    </div>
                </div>
            </div>
        );
    }

    // protected handleTabbing(e) { // this function makes sure overlay disappears on tab out of composer
    //     if (!this.state.expanded) {
    //         return;
    //     }

    //     if (e.key === "Tab") {
    //         setTimeout(() => {
    //             const parent = parentBy(
    //                 window.document.activeElement as HTMLElement,
    //                 (p) => p.getAttribute("id") === "SocialComposer"
    //             );

    //             if (parent) {
    //                 return;
    //             }

    //             console.log("Tab -> onOverlayClicked")
    //             this.onOverlayClicked()
    //         }, 100);
    //     }
    // }

    protected handleEscapeKey = (e) => {
        if (!this.state.expanded) {
            return;
        }

        if (e.key === "Escape") {
            this.onOverlayClicked();
        }
    }

    protected setFeedId = (feedId) => {
        this.setState({ feedId });
    }

    protected validate(): boolean {
        let saveError = [];
        let validateText = true;

        if (this.context?.type === "image" ||
            this.context?.type === "video" ||
            this.context?.type === "external-file" ||
            this.context?.type === "file") {
            validateText = false;
        }

        if (validateText) {
            const textContent = draftToHtml(
                this.context.editorState.getCurrentContent()
            );

            if (!textContent ||
                textContent.length === 0) {
                saveError.push(this.context?.type === "poll" ?
                    "DetMasteFinnasEnTextTillDinOmrostning" :
                    "DuMasteFyllaIEttInnehåll");
            }
        }

        if (this.typeRef &&
            this.typeRef.current &&
            this.typeRef.current.validate) {
            const validateResult = this.typeRef.current.validate();

            if (validateResult &&
                validateResult.length > 0) {
                saveError = [
                    ...saveError,
                    ...validateResult
                ]
            }
        }

        if (saveError.length === 0) {
            return true;
        } else {
            this.setState({
                saveError
            });

            return false;
        }
    }

    protected onSaveClicked(): void {
        if (!this.validate() || this.state.isSaving) {
            return;
        }

        this.setState({
            isSaving: true,
            saveError: null
        }, async () => {
            const textContent = draftToHtml(
                this.context.editorState.getCurrentContent()
            );

            try {
                const post = await this.typeRef.current.saveContent({
                    feedId: this.state.feedId,
                    flag: this.state.publishOnStartPage ? 0 : 1,
                    galleryId: this.props.galleryId,
                    identity: this.props.identity,
                    text: textContent
                });

                if (!!post) {
                    this.props.addLocallyCreatedPost(post);
                }

                if (this.props.isStartPage) {
                    mixpanelTrack("Status post created");
                }

                this.context?.reset();
                this.setState({
                    expanded: false,
                    isSaving: false,
                    suggestions: [],
                }, () => {
                    if (this.props.onClosing) {
                        this.props.onClosing();
                    }
                });
            } catch (err) {
                this.setState({
                    isSaving: false
                });

                console.log(err);
            }
        });
    }

    protected onPublishOnStartPageChanged(ev, val: boolean) {
        this.setState({
            publishOnStartPage: val,
        });
    }

    protected onTypeChanged(type: SocialComposerTypes) {
        this.setState({ expanded: true }, () => {
            this.context?.setType(type);
        });
    }

    protected renderTextInput(): JSX.Element {
        let placeholderKey: string = "COMPOSER_PLACEHOLDER";
        if (this.context.type === "question") {
            placeholderKey = "StallEnFraga";
        }

        const textEditorProps: ISocialComposerTextInputProps = {
            editorState: this.context.editorState,
            onChange: this.onEditorStateChange,
            onFocus: this.onFocus,
            plugins: [
                this.mentionPlugin,
                this.emojiPlugin
            ],
            placeholderKey,
        };

        return (
            <SocialComposerTextInput
                ariaLabel={localize(placeholderKey)}
                {...textEditorProps}
            />
        );
    }

    protected renderUserHeader(): JSX.Element {
        const textEditorProps: ISocialComposerTextInputProps = {
            editorState: this.context.editorState,
            onChange: this.onEditorStateChange,
            onFocus: this.onFocus,
            plugins: [
                this.mentionPlugin,
                this.emojiPlugin
            ]
        };

        const userImage = this.props.identity
            ? this.props.identity.images.feedComposer
            : this.props.user.images.feedComposer;

        return (
            <SocialComposerUserHeader
                {...textEditorProps}
                expanded={this.state.expanded}
                imageUrl={userImage}
                userName={this.props.identity?.name || this.props.user.name}
                // onInputBlur={this.onOverlayClicked}
                hideTextInput={
                    this.context?.type === "frame" &&
                    this.state.expanded
                }
                isStartPage={this.props.isStartPage}
                setFeedId={this.setFeedId}
            />
        );
    }

    protected renderTypeSelector(): JSX.Element {
        return (
            <div className="type-drawer">
                <ComposerTypeSelectorBar
                    onChange={this.onTypeChanged}
                    selected={this.context?.type}
                    types={this.props.types}
                />
            </div>
        )
    }

    protected renderFooter(): JSX.Element {
        if (!this.state.expanded) {
            return null;
        }

        if (this.state.isSaving) {
            return(
                <div>
                    <Loader />
                </div>
            );
        }

        return (
            <div className="composer-footer">
                <PrimaryButton text={"Spara"} onClick={this.onSaveClicked} />
            </div>
        )
        
        return (
            <SocialComposerFooter
                onSaveClicked={this.onSaveClicked}
            />
        );
    }

    renderSocialComposerText() {
        const baseProps = {
            smallScreen: this.props.smallScreen,

            postFooter: this.renderFooter(),
            textInput: this.renderTextInput(),
            typeSelector: this.renderTypeSelector(),
            userHeader: this.renderUserHeader(),

            onCloseClicked: this.onOverlayClicked,
            onTypeChange: this.onTypeChanged,
        };

        const textEditorProps: ISocialComposerTextInputProps = {
            editorState: this.context.editorState,
            onChange: this.onEditorStateChange,
            onFocus: this.onFocus,
            plugins: [
                this.mentionPlugin,
                this.emojiPlugin
            ]
        };

        return (
            <div className="composer-form">
                {this.context.type === "frame" && (
                    <SocialComposerFrame
                        {...textEditorProps}
                        {...baseProps}
                        // @ts-ignore
                        ref={this.typeRef}
                    />
                )}
                {this.context.type === "text" && (
                    <SocialComposerText
                        {...baseProps}
                        // @ts-ignore
                        ref={this.typeRef}
                    />
                )}
            </div>
        )
    }

    protected renderType(textEditorProps: ISocialComposerTextInputProps): ReactNode {
        const baseProps = {
            smallScreen: this.props.smallScreen,

            postFooter: this.renderFooter(),
            textInput: this.renderTextInput(),
            typeSelector: this.renderTypeSelector(),
            userHeader: this.renderUserHeader(),

            onCloseClicked: this.onOverlayClicked,
            onTypeChange: this.onTypeChanged,
        };

        switch (this.context?.type) {
            default:
                return (
                    <SocialComposerText
                        {...baseProps}
                        // @ts-ignore
                        ref={this.typeRef}
                    />
                );
            case "image":
                return (
                    <SocialComposerImages
                        {...baseProps}

                        // @ts-ignore
                        ref={this.typeRef}
                    />
                );
            case "poll":
                // @ts-ignore
                return (
                    <SocialComposerPoll
                        {...baseProps}

                        // @ts-ignore
                        ref={this.typeRef}
                    />
                );
            case "frame":
                return (
                    <SocialComposerFrame
                        {...textEditorProps}
                        {...baseProps}

                        // @ts-ignore
                        ref={this.typeRef}
                    />
                );
            case "video":
                // @ts-ignore
                return (
                    <SocialComposerVideo
                        {...baseProps}

                        // @ts-ignore
                        ref={this.typeRef}
                    />
                );

            case "external-file":
                return (
                    <SocialComposerExternalFile
                        {...baseProps}

                        // @ts-ignore
                        ref={this.typeRef}
                    />
                );

            case "type-selector":
                return (
                    <ExpandedComposerTypeSelector />
                );

            // TODO: Add Office365 files once file browser is done
        }
    }

    protected checkTextLength(editorState: EditorState): void {
        if (!!editorState && this.context?.type === "frame") {
            const textContent = draftToHtml(
                editorState.getCurrentContent()
            );

            if (textContent && textContent.length > 130) {
                this.setState({
                    displayEffectTextTooLongMessage: true
                });
            }
        }
    }

    protected onEditorStateChange(editorState: EditorState): void {
        this.context.setEditorState(editorState);

        if (!this.typeRef.current) {
            return;
        }

        // Need to use the local as the context won't update instantly
        this.typeRef.current.onTextChanged(editorState);
        this.checkTextLength(editorState);
    }

    protected onAddMention(_: DraftMentionObject) {
        /* ignore */
    }

    protected async onMentionValue(value: string): Promise<void> {
        try {
            const results = await searchUsers({ query: value });

            this.setState({
                suggestions: results.data.map(hit => ({
                    avatar: hit.imageUrl,
                    key: hit.id,
                    link: `/goto/1/${hit.id}`,
                    name: hit.name
                }))
            });
        } catch (err) {
            console.log(err);
        }
    }

    protected onSearchChange(event: MentionSearchChangedEvent): void {
        const { value } = event;

        if (value) {
            this.mentionSubject.next(value);
            return;
        }

        this.setState(
            { suggestions: [] },
            () => this.mentionSubject.next(value)
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, Spintr.AppState>(
    (state) => ({
        enableSharepoint: state.instance.get("enableSharepoint"),
        publishToGeneralFeedChecked: state.instance.get("publishToGeneralFeedChecked"),
        smallScreen: state.ui.viewMode < SpintrTypes.ViewMode.TabletLandscape,
        user: state.profile.active,
    }),
    { addLocallyCreatedPost }
)(SocialComposer);