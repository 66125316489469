function validZohoFormat(name: string): boolean {
    var allowed = [
        ".doc",
        ".docx",
        ".rtf",
        ".odt",
        ".sxw",
        ".html",
        ".txt",
        ".xls",
        ".xlsx",
        ".xltx",
        ".xlsm",
        ".ods",
        ".sxc",
        ".csv",
        ".ppt",
        ".pptx",
        ".pps",
        ".odp",
        ".sxi",
    ];

    return allowed.some(extension => name.endsWith(extension));
}

export default validZohoFormat;
