import React, { Component, ReactNode } from "react";
import FloatingLayer, { IFloatingLayerProps } from "./FloatingLayer";
import "./StandardFloatingLayer.scss";

interface IStandardFloatingLayerProps extends IFloatingLayerProps { }

class StandardFloatingLayer extends Component<IStandardFloatingLayerProps> {
    public render() : ReactNode {
        const { children, ...props } = this.props;

        return (
            <FloatingLayer {...props}>
                <div className="StandardFloatingLayer">
                    {children}
                </div>
            </FloatingLayer>
        )
    }
}

export default StandardFloatingLayer;