import { produce } from "immer";

import CalendarAction, {
    FETCH_INPAGE_CALENDAR_FULFILLED,
    FETCH_INPAGE_CALENDAR_PENDING,
    FETCH_INPAGE_CALENDAR_REJECTED,
    FETCH_CALENDAR_SIDEBAR_FULFILLED,
    FETCH_CALENDAR_SIDEBAR_PENDING,
    FETCH_CALENDAR_SIDEBAR_REJECTED,
    SET_CALENDAR_POPUP,
    FETCH_CALENDAR,
    FETCH_CALENDAR_PENDING,
    FETCH_CALENDAR_REJECTED,
    FETCH_CALENDAR_FULFILLED,
    CREATE_CALENDAR_EVENT,
    CREATE_CALENDAR_EVENT_FULFILLED,
    SET_ACTIVE_CALENDAR,
    CREATE_CALENDAR_EVENT_REJECTED,
    CREATE_CALENDAR_EVENT_PENDING,
    CLEAR_ERROR_LIST,
    UPDATE_CALENDAR_EVENT_REJECTED,
    UPDATE_CALENDAR_EVENT_FULFILLED,
    UPDATE_CALENDAR_EVENT,
    UPDATE_CALENDAR_EVENT_PENDING,
    SET_CALENDAR_COLOR,
    SET_CALENDAR_COLOR_PENDING,
    TOGGLE_CALENDAR_EVENT_DELETED_FULFILLED,
    FETCH_CALENDAR_EVENT_FULFILLED,
    FETCH_CALENDAR_EVENT,
    FETCH_CALENDAR_EVENT_REJECTED,
    FETCH_CALENDAR_EVENT_PENDING,
    UPDATE_CALENDAR_EVENT_REALTIME,
    JOIN_CALENDAR_EVENT,
    LEAVE_CALENDAR_EVENT,
} from "./action-types";
import moment from "moment";
import { SpintrActionTypes } from "src/spintr/action-types";
import { joinEvent, leaveEvent } from "./calendar-api";
import { AxiosResponse } from "axios";

export interface ICalendarPopupSettings {
    eventId?: number;
    isOpen: boolean;
    startDate?: Date;
    endDate?: Date;
    editMode?: boolean;
    event?: any;
    preSelectedCalendarId?: number;
    hideCalendarSelector?: boolean;
    errorList?: string[];
    isLoading?: boolean;
    onSave?: (event: any) => void;
    externalId?: string;
}

export interface ICalendarState {
    calendarPopup: ICalendarPopupSettings;
    hasMoreSidebarEvents: boolean;
    hasMoreInPageEvents: any[];
    isLoadingSidebar: boolean;
    isLoadingMoreSidebar: boolean;
    isLoadingInPage: any[];
    isLoadingMoreInPage: any[];
    sidebarEvents: Spintr.ICalendarEvent[];
    inPageCalendarEvents: Spintr.ICalendarEvent[];
    events: Spintr.ICalendarEvent[];
    event: any;
    activeCalendar: number;
    relativeExternalSkip: number;
    relativeExternalSkipInPage: any[];
    relativeSpintrSkip: number;
    relativeSpintrSkipInPage: any;
    lastFetchCalendarParams?: any;
}

const initialState: ICalendarState = {
    calendarPopup: {
        isOpen: false,
        errorList: [],
        isLoading: false,
    },
    hasMoreSidebarEvents: false,
    hasMoreInPageEvents: [],
    isLoadingSidebar: false,
    isLoadingMoreSidebar: false,
    isLoadingInPage: [],
    isLoadingMoreInPage: [],
    sidebarEvents: [],
    inPageCalendarEvents: [],
    events: [],
    event: { isLoading: true, event: {} },
    activeCalendar: 0,
    relativeExternalSkip: 0,
    relativeExternalSkipInPage: [],
    relativeSpintrSkip: 0,
    relativeSpintrSkipInPage: []
};

const setAttending = (id: number, userId, response: AxiosResponse, isAttending: boolean, state: ICalendarState) => {
    let events = [...state.events];

    let eventIndex = events.findIndex((e) => {
        return e.id === id;
    });

    if (eventIndex > -1) {
        if (isAttending) {
            // set status: 1
            let attendee = response.data.attendees.find((a) => {
                return a.id === userId;
            });

            const isInList = events[eventIndex].attendees.some((a) => {
                return a.id === userId;
            });

            if (isInList) {
                events[eventIndex].attendees = events[eventIndex].attendees.map((a) =>
                    a.id === userId ? { ...a, status: 1 } : a
                );
            } else {
                events[eventIndex].attendees.push(attendee);
            }

        } else {
            // set status: 2

            events[eventIndex].attendees = events[eventIndex].attendees.map((a) =>
                a.id === userId ? { ...a, status: 2 } : a
            );
        }
    }

    return events;
};

const setFavourite = (id: number, isFavourite: boolean, state: ICalendarState) => {
    if (state.event.event.id === id) {
        return {
            ...state.event.event,
            isFavourite,
        };
    }

    return state.event.event;
};

const setFollowing = (id: number, isFollowing: boolean, state: ICalendarState) => {
    if (state.event.event.id === id) {
        return {
            ...state.event.event,
            isFollowing,
        };
    }

    return state.event.event;
};

const calendarReducer = (state: ICalendarState = initialState, action: CalendarAction) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_INPAGE_CALENDAR_PENDING:
                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.isLoadingMoreInPage[action.meta.params.calendarId] = true;
                } else {
                    draft.isLoadingInPage[action.meta.params.calendarId] = true;
                }
                break;

            case FETCH_INPAGE_CALENDAR_REJECTED:
                if (action.meta.params.relativeExternalSkip  > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.isLoadingMoreInPage[action.meta.params.calendarId] = false;
                } else {
                    draft.isLoadingInPage[action.meta.params.calendarId] = false;
                }
                break;

            case FETCH_INPAGE_CALENDAR_FULFILLED:
                draft.hasMoreInPageEvents[action.meta.params.calendarId] = action.meta.params.take === action.payload.data.length;

                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.isLoadingMoreInPage[action.meta.params.calendarId] = false;
                } else {
                    draft.isLoadingInPage[action.meta.params.calendarId] = false;
                }

                const inPageEvents = action.payload.data.map((event) => {
                    return {
                        ...event,
                        
                        presentingCalendarId: action.meta.params.calendarId,
                        end: new Date(event.end),
                        start: new Date(event.start),
                    };
                });

                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.events = [...draft.events.filter(i => !inPageEvents.map(ie => ie.id).includes(i.id)), ...inPageEvents]
                    draft.events = draft.events.sort((a, b) => (a.start as Date).getTime() - (b.start as Date).getTime())

                } else {
                    draft.events = [...draft.events.filter(i => !inPageEvents.map(ie => ie.id).includes(i.id)), ...inPageEvents]
                    draft.events = draft.events.sort((a, b) => (a.start as Date).getTime() - (b.start as Date).getTime())

                    // draft.events = [...draft.events, ...inPageEvents]
                }

                draft.relativeExternalSkipInPage[action.meta.params.calendarId] = Number.isInteger(draft.relativeExternalSkipInPage[action.meta.params.calendarId]) 
                ? draft.relativeExternalSkipInPage[action.meta.params.calendarId] + Number(action.payload.headers["x-relative-external-skip"])
                : Number(action.payload.headers["x-relative-external-skip"]);

                draft.relativeSpintrSkipInPage[action.meta.params.calendarId] = Number.isInteger(draft.relativeSpintrSkipInPage[action.meta.params.calendarId])
                ? draft.relativeSpintrSkipInPage[action.meta.params.calendarId] + Number(action.payload.headers["x-relative-spintr-skip"])
                : Number(action.payload.headers["x-relative-spintr-skip"]);
                break;
                
            case FETCH_CALENDAR_SIDEBAR_PENDING:
                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.isLoadingMoreSidebar = true;
                } else {
                    draft.isLoadingSidebar = true;
                }
                break;

            case FETCH_CALENDAR_SIDEBAR_REJECTED:
                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.isLoadingMoreSidebar = false;
                } else {
                    draft.isLoadingSidebar = false;
                }
                break;

            case FETCH_CALENDAR_SIDEBAR_FULFILLED:
                draft.hasMoreSidebarEvents = action.meta.params.take === action.payload.data.length;
                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.isLoadingMoreSidebar = false;
                } else {
                    draft.isLoadingSidebar = false;
                }

                const events = action.payload.data.map((event) => {
                    return {
                        ...event,

                        end: new Date(event.end),
                        start: new Date(event.start),
                    };
                });

                if (action.meta.params.relativeExternalSkip > 0 || action.meta.params.relativeSpintrSkip > 0) {
                    draft.sidebarEvents = [...draft.sidebarEvents, ...events];
                } else {
                    draft.sidebarEvents = events;
                }

                draft.relativeExternalSkip = action.payload.headers["x-relative-external-skip"];
                draft.relativeSpintrSkip = action.payload.headers["x-relative-spintr-skip"];
                break;

            case FETCH_CALENDAR_PENDING:
                break;

            case FETCH_CALENDAR_REJECTED:
                break;

            case FETCH_CALENDAR_FULFILLED:
                draft.events = action.payload.data.map((event) => {
                    let end = new Date(event.end);

                    // Needed because of bug in react-big-calendar
                    if (end.getHours() === 0 &&
                        end.getMinutes() === 0 &&
                        end.getSeconds() === 0 &&
                        end.getMilliseconds() === 0) {
                        end = new Date(end.getTime() + 1);
                    }

                    return {
                        ...event,
                        start: new Date(event.start),
                        end
                    };
                });

                if (!!action.payload.config &&
                    !!action.payload.config.params) {
                    draft.lastFetchCalendarParams = action.payload.config.params;
                }

                break;

            case FETCH_CALENDAR_EVENT_PENDING:
                draft.event.isLoading = true;
                break;

            case FETCH_CALENDAR_EVENT_REJECTED:
                draft.event.isLoading = false;
                break;

            case FETCH_CALENDAR_EVENT_FULFILLED:
                draft.event.isLoading = false;
                const event = action.payload.data as any;
                event.start = new Date(event.start);
                event.end = new Date(event.end);

                if (event.registrationClose) {
                    event.registrationClose = new Date(event.registrationClose);
                }

                event.eventHasExpired = event.end && new Date() > event.end;
                event.participants = event.attendees.concat(event.invited, event.rejected);
                event.isMultipleDays = !moment(event.start).isSame(event.end, "day");

                draft.event.event = event;
                break;

            case SET_CALENDAR_POPUP:
                draft.calendarPopup = {
                    eventId: action.eventId,
                    isOpen: action.isOpen,
                    startDate: action.startDate,
                    endDate: action.endDate,
                    editMode: action.editMode,
                    event: action.event,
                    preSelectedCalendarId: action.preSelectedCalendarId,
                    hideCalendarSelector: action.hideCalendarSelection,
                    onSave: action.onSave,
                    externalId: action.externalId
                };
                break;

            case SET_ACTIVE_CALENDAR:
                draft.activeCalendar = action.id;
                break;

            case SET_CALENDAR_COLOR_PENDING:
                draft.events.map((event) => {
                    if (event.calendarId !== action.meta.id) {
                        return event;
                    }

                    event.color = "#" + action.meta.color;
                });
                break;

            case CLEAR_ERROR_LIST:
                draft.calendarPopup = {
                    ...state.calendarPopup,
                    errorList: initialState.calendarPopup.errorList,
                };
                break;

            case CREATE_CALENDAR_EVENT_PENDING:
                draft.calendarPopup = {
                    ...state.calendarPopup,
                    isLoading: true,
                };
                break;

            case CREATE_CALENDAR_EVENT_REJECTED:
                draft.calendarPopup = {
                    ...state.calendarPopup,
                    // @ts-ignore
                    errorList: action.payload.response.data.errorlist || ["TeknisktFel"],
                    isLoading: false,
                };
                break;

            case CREATE_CALENDAR_EVENT_FULFILLED:
                draft.calendarPopup = {
                    isOpen: false,
                };

                // This might be a bad idea?
                if (state.activeCalendar == 0 || state.activeCalendar == action.payload.data.calendarId) {
                    const event = action.payload.data;
                    event.end = new Date(event.end);
                    event.start = new Date(event.start);
                    draft.events.push(event);
                }

                break;

            case TOGGLE_CALENDAR_EVENT_DELETED_FULFILLED:
                // TODO: Toggle?
                draft.events = draft.events.filter((event) =>
                    event.id !== action.meta.event.id &&
                    event.recurringParentId !== action.meta.event.id);

                break;

            case UPDATE_CALENDAR_EVENT_PENDING:
                draft.calendarPopup = {
                    ...state.calendarPopup,
                    isLoading: true,
                };
                break;

            case UPDATE_CALENDAR_EVENT_REJECTED:
                draft.calendarPopup = {
                    ...state.calendarPopup,
                    // @ts-ignore
                    errorList: action.payload.response.data.errorlist || ["TeknisktFel"],
                    isLoading: false,
                };

                break;

            case UPDATE_CALENDAR_EVENT_FULFILLED:
                draft.calendarPopup = {
                    isOpen: false,
                };

                var eventIdx = draft.events.findIndex(
                    (event) =>
                        (!!event.id && event.id == action.payload.data.id) ||
                        (!!event.exchangeId && event.exchangeId == action.payload.data.exchangeId)
                );

                if (eventIdx > -1) {
                    draft.events[eventIdx] = {
                        ...draft.events[eventIdx],
                        ...action.payload.data
                    };
                }

                if (draft.event.event.id === action.payload.data.id) {
                    draft.event.event = {
                        ...draft.event.event,
                        ...action.payload.data
                    };
                }

                if (action.payload.data.isRecurring) {
                    draft.events = draft.events.map((ev: any) => {
                        if (ev.recurringParentId !== action.payload.data.id) {
                            return ev;
                        }

                        return {
                            ...ev,
                            title: action.payload.data.title,
                            start: action.payload.data.start,
                            end: action.payload.data.end,
                        }
                    });
                }

                break;

            case UPDATE_CALENDAR_EVENT_REALTIME:
                const newEvent = action.meta.event;

                if (
                    action.meta.event.id === state.event.event.id ||
                    action.meta.event.exchangeId === state.event.event.exchangeId
                ) {
                    draft.event.event = {
                        ...state.event.event,
                        title: newEvent.title,
                        text: newEvent.description,
                        allDay: newEvent.allDay,
                        start: new Date(newEvent.start),
                        end: new Date(newEvent.end),
                        eventHasExpired: newEvent.end && new Date() > new Date(newEvent.start),
                    };
                }

                const eventSidebarIdx = state.sidebarEvents.findIndex(
                    (sidebarEvent) => sidebarEvent.id === newEvent.id || sidebarEvent.exchangeId === newEvent.exchangeId
                );

                if (eventSidebarIdx > -1) {
                    draft.sidebarEvents[eventSidebarIdx] = {
                        ...draft.sidebarEvents[eventSidebarIdx],
                        title: newEvent.title,
                        description: newEvent.description,
                        allDay: newEvent.allDay,
                        start: new Date(newEvent.start),
                        end: new Date(newEvent.end),
                    };
                }

                break;

            case LEAVE_CALENDAR_EVENT:
                draft.events = setAttending(action.meta.id, action.meta.userId, action.meta.response, false, state);
                break;

            case JOIN_CALENDAR_EVENT:
                draft.events = setAttending(action.meta.id, action.meta.userId, action.meta.response, true, state);
                break;

            case SpintrActionTypes.AddToFavoritePending:
                draft.event.event = setFavourite(action.meta, true, state);
                break;

            case SpintrActionTypes.AddToFavoriteRejected:
                draft.event.event = setFavourite(action.meta, false, state);
                break;

            case SpintrActionTypes.RemoveFromFavoritesPending:
                draft.event.event = setFavourite(action.meta, false, state);
                break;

            case SpintrActionTypes.RemoveFromFavoritesRejected:
                draft.event.event = setFavourite(action.meta, true, state);
                break;

            case SpintrActionTypes.StartFollowingObjectPending:
                draft.event.event = setFollowing(action.meta, true, state);
                break;

            case SpintrActionTypes.StartFollowingObjectRejected:
                draft.event.event = setFollowing(action.meta, false, state);
                break;

            case SpintrActionTypes.StopFollowingObjectPending:
                draft.event.event = setFollowing(action.meta, false, state);
                break;

            case SpintrActionTypes.StopFollowingObjectRejected:
                draft.event.event = setFollowing(action.meta, true, state);
                break;
        }
    });

export default calendarReducer;
