import { Image } from "@fluentui/react";
import classNames from "classnames";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";

import { Label } from "src/ui";
import { getDomainFromUrl, truncate } from "src/utils";

import "./SocialBookmark.scss";

interface IProps {
    text?: string;
    bookmarkImage?: string;
    bookmarkUrl?: string;
    bookmarkTitle?: string;
    bookmarkDescription?: string;
    bookmarkType?: any;
}

class Bookmark extends Component<IProps> {
    public render(): ReactNode {
        const classes = ["bookmark"];

        if (this.props.bookmarkType === 2) { // YouTube
            classes.push("youtube");

            return (
                <div className={classNames(classes)}>
                    <iframe
                        allowFullScreen={true}
                        frameBorder="0"
                        src={this.props.bookmarkUrl}
                        scrolling="no"
                        title={this.props.bookmarkTitle}
                        width={"100%"}
                    />
                </div>
            );
        } else if (this.props.bookmarkType === 7) {
            classes.push("frame-wrapper");
            classes.push("spotify-height");

            return (
                <div className={classNames(classes)}>
                    <iframe
                        allowFullScreen={true}
                        // allowTransparency={true} <-- generates an error
                        frameBorder="0"
                        src={this.props.bookmarkUrl}
                        scrolling="no"
                        title={this.props.bookmarkTitle}
                    />
                </div>
            )
        }

        classes.push("standard");

        if (this.props.bookmarkImage) {
            classes.push("with-image");
        }

        return (
            <div className={classNames(classes)}>
                {this.props.bookmarkImage && (
                    <div className="bookmark-image">
                        <Image
                            alt={this.props.text}
                            maximizeFrame={true}
                            src={this.props.bookmarkImage}
                        />
                    </div>
                )}
                <div className="bookmark-title">
                    <a
                        href={this.props.bookmarkUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={localize("BesokLank")}
                    >
                        <Label
                            className="bookmark-domain"
                            color="mid-grey"
                            size="body-2"
                            weight="regular"
                        >
                            {getDomainFromUrl(this.props.bookmarkUrl)}
                        </Label>
                    </a>
                    <a
                        href={this.props.bookmarkUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={localize("BesokLank")}
                    >
                        <Label
                            className="bookmark-title-text"
                            color="visageGray"
                            as="h3"
                            size="body-4"
                            weight="medium"
                        >
                            {this.props.bookmarkTitle}
                        </Label>
                    </a>
                    {
                        !!this.props.bookmarkDescription && (
                            <div className="url-wrapper">
                                <div className="url general-row-break">
                                    <a
                                        href={this.props.bookmarkUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        title={localize("BesokLank")}
                                    >
                                        <Label
                                            className="bookmark-description"
                                            color="mid-grey"
                                            size="body-3"
                                        >
                                            {truncate(
                                                this.props.bookmarkDescription,
                                                100,
                                            )}
                                        </Label>
                                    </a>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default Bookmark;