import { Icon, Image } from "@fluentui/react";
import React, { ChangeEvent, ReactNode } from "react";

import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { UnstyledButton } from "src/ui";
import postMediaPlayCountUpdate from "src/utils/postMediaPlayCountUpdate";
import { ComposerContext } from "./ComposerContext";
import { IComposerVideoContent } from "./IComposerViewState";
import SocialComposerType, { IComposerSaveOptions } from "./SocialComposerType";

import "./SocialComposerVideo.scss";

const supportedMimeTypes: string[] = [
    // The commented types below seem to break the file filter in windows
    /*
    "video/ogg",
    "video/annodex",
    "video/webm",
    "video/mpeg",
    "video/x-m4v",
    "video/mp4",
    "video/x-mpeg2",
    "video/mpeg2",
    "video/m2ts",
    "video/x-m2ts",
    */
    "video/*",
];

class SocialComposerVideo extends SocialComposerType<any, never> {
    public static contextType = ComposerContext;

    declare public context: React.ContextType<typeof ComposerContext>;

    protected canvasRef: React.RefObject<HTMLCanvasElement>;
    
    constructor(props: any) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
        this.onVideoMetadataLoaded = this.onVideoMetadataLoaded.bind(this);
        this.onVideoSeeked = this.onVideoSeeked.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    public componentDidMount(): void {
        const contentState = this.getContentState();
        if (!contentState?.file) {
            return;
        }

        this.context.setContentState({
            ...this.getContentState(),
            blobUrl: URL.createObjectURL(contentState.file),
        });
        
    }

    public async saveContent(options: IComposerSaveOptions): Promise<Spintr.ISocialPostBase> {
        const contentState = this.getContentState();

        if (!contentState?.file) {
            return null;
        }

        const statusModel = {
            type: 2,
            feedId: options.feedId,
            flag: options.flag,
            text: options.text,
            content: [{
                description: options.text,
                feedId: options.feedId,
                typeDiscriminator: 17,
            }]
        };

        const formData = new FormData();
        formData.append("jsonString", JSON.stringify(statusModel));
        formData.append("files", contentState.file, contentState.file.name);

        try {
            const response = await api.post(
                "/api/v1/status",
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        ...(options.identity?.id ? {"x-impersonate-user": options.identity?.id} : {})
                    }
                }
            );

            if (!response.data?.id) {
                throw localize("TeknisktFel");
            }

            const postResponse = await api.get<Spintr.ISocialPostBase>(
                "/api/v1/status/" + response.data.id
            );

            if (!postResponse) {
                throw localize("TeknisktFel");
            }

            return postResponse.data;
            
        } catch (err) {
            console.log(err);
            
            throw err;
        }
    }

    public validate(): string[] {
        const result = [];

        if (!this.getContentState()?.file) {
            result.push("DuMasteValjaEnFil");
        }

        return result;
    }

    protected getContentState(): IComposerVideoContent {
        return this.context?.contentState as IComposerVideoContent;
    }

    protected onVideoMetadataLoaded(event: React.SyntheticEvent<HTMLVideoElement>): void {
        const videoPlayer = event.target as HTMLVideoElement;
        console.log(videoPlayer);
        const duration = videoPlayer.duration;

        setTimeout(() => {
            videoPlayer.currentTime = duration / 2;
            console.log(duration);
        }, 200);
    }

    protected onVideoSeeked(event: React.SyntheticEvent<HTMLVideoElement>): void {
        const videoPlayer = event.target as HTMLVideoElement;
        console.log(videoPlayer);

        const canvas = this.canvasRef?.current
            ? this.canvasRef.current
            : videoPlayer.parentElement.querySelector("canvas");
        if (!canvas) {
            console.log(this, this.canvasRef);
            return;
        }
        console.log(canvas);

        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        
        const dataUrl = context.canvas.toDataURL("image/jpeg", 0.75);

        this.context.setContentState({
            ...this.getContentState(),
            thumbnailUrl: dataUrl,
        });
    }

    protected removeFile(): void {
        this.context.setContentState({});
        this.context.setType("image");
    }

    protected onFileChange(event: ChangeEvent<HTMLInputElement>): void {
        const { files } = event.target;
        if (files.length < 1) {
            this.context.setContentState({
                file: undefined,
            });
            this.context.setType("image");
            return;
        }

        const file = files[0];
        this.context.setContentState({
            ...this.getContentState(),
            blobUrl: URL.createObjectURL(file),
            file,
        });
    }

    protected renderTypeContent(): ReactNode {
        const contentState = this.getContentState();
        const inputStyle: React.CSSProperties = { };
        if (contentState?.file) {
            inputStyle.display = "none";
        }

        return (
            <div className="SocialComposerVideo">
                <video
                    autoPlay={false}
                    controls={false}
                    style={{
                        display: "none",
                    }}
                    onLoadedMetadata={this.onVideoMetadataLoaded}
                    onSeeked={this.onVideoSeeked}
                    src={contentState?.blobUrl}
                />
                <canvas
                    style={{
                        display: "none",
                    }}
                    ref={this.canvasRef}
                />
                <input
                    style={inputStyle}
                    accept={supportedMimeTypes.join(",")}
                    multiple={false}
                    name="movie"
                    onChange={this.onFileChange}
                    size={20}
                    type="file"
                />
                {contentState?.thumbnailUrl && (
                    <div className="video-stage">
                        <Image
                            alt={contentState.file.name}
                            className="thumbnail"
                            src={contentState.thumbnailUrl}
                        />
                        <UnstyledButton
                            className="remove-button"
                            onClick={this.removeFile}
                        >
                            <Icon iconName="VisageDismissCircle24Filled" />
                        </UnstyledButton>
                    </div>
                )}
            </div>
        )
    }
}

export default SocialComposerVideo;
