export const FETCH_ROOT_FOLDERS = "FETCH_ROOT_FOLDERS";
export const FETCH_ROOT_FOLDERS_PENDING = "FETCH_ROOT_FOLDERS_PENDING";
export const FETCH_ROOT_FOLDERS_REJECTED = "FETCH_ROOT_FOLDERS_REJECTED";
export const FETCH_ROOT_FOLDERS_FULFILLED = "FETCH_ROOT_FOLDERS_FULFILLED";

export const FIND_FOLDER = "FIND_FOLDER";
export const FIND_FOLDER_PENDING = "FIND_FOLDER_PENDING";
export const FIND_FOLDER_REJECTED = "FIND_FOLDER_REJECTED";
export const FIND_FOLDER_FULFILLED = "FIND_FOLDER_FULFILLED";

export const SET_CURRENT_FOLDER_ID = "SET_CURRENT_FOLDER_ID";

export const SET_HIDE_SHARE = "SET_HIDE_SHARE";

export const SET_ENABLE_ONLINE_EDIT = "SET_ENABLE_ONLINE_EDIT";

export const SET_COPIED_FOLDER = "SET_COPIED_FOLDER";