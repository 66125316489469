import React, { ReactElement, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { PortalProps, VirtualElement } from "./Portal.types";

function toMountNodeProp(mountNode: PortalProps["mountNode"]): { element?: HTMLElement | undefined, className?: string | undefined } {
    if (typeof mountNode !== "object" || mountNode === null) {
        return {};
    }

    if (mountNode instanceof HTMLElement) {
        return { element: mountNode };
    }

    return mountNode;
}

function resolveMountNode(mountNode: { element?: HTMLElement | undefined, className?: string | undefined }): HTMLElement | null | undefined {
    if (mountNode.element) {
        return mountNode.element;
    }

    const fallbackElement = document.body;

    if (mountNode.className) {
        const element = fallbackElement.querySelector(`.${mountNode.className}`);

        if (element && element instanceof HTMLElement) {
            return element;
        }
    }

    return fallbackElement;
}

function Portal(props: PortalProps): ReactElement {
    const mountNode = resolveMountNode(toMountNodeProp(props.mountNode));
    const virtualParentRootRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (!mountNode) {
            return;
        }

        const virtualParent = virtualParentRootRef.current;

        const virtualParentInChild = mountNode.contains(virtualParent);
        if (!virtualParent || virtualParentInChild) {
            return;
        }

        const virtualChild = (mountNode as Node) as VirtualElement;
        if (!virtualChild._virtual) {
            virtualChild._virtual = {};
        }

        virtualChild._virtual.parent = virtualParent;

        return () => {
            virtualChild._virtual.parent = undefined;
        };
    }, [virtualParentRootRef, mountNode]);

    return (       
        <span hidden={true} ref={virtualParentRootRef}>
            {mountNode && createPortal(props.children, mountNode)}
        </span>
    );
}

export default Portal;
