import api from "src/spintr/SpintrApi";
import {
    MenuActionTypes,
    IFetchMainMenuAction,
    IRemoveMainMenuItemAction,
} from "./action-types"

export type FetchMainMenuActionCreator = (silent?: boolean) => IFetchMainMenuAction;

export const fetchMainMenu: FetchMainMenuActionCreator = (silent) => ({
    payload: api.get("/api/menu/top"),
    type: MenuActionTypes.FetchMainMenu,
    meta: { isSilentFetch: !!silent },
});

export type RemoveMainMenuItemActionCreator = (byProperty: keyof Spintr.IMainMenuItem, value: any) => IRemoveMainMenuItemAction;

export const removeMainMenuItem: RemoveMainMenuItemActionCreator = (byProperty, value) => ({
    byProperty,
    propertyValue: value,
    type: MenuActionTypes.RemoveMainMenuItem,
});
