import React, { Component, ReactNode } from "react";

import { localize } from "src/l10n";
import { parentBy } from "src/utils";

import "./SocialComposerFooter.scss";

interface IProps {
    isDisabled?: boolean;

    onSaveClicked: () => void;
    onBlur?: () => void;
}

class SocialComposerFooter extends Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    public render(): ReactNode {
        return (
            <div className="SocialComposerFooter">
                <div
                    tabIndex={0}
                    onKeyDown={(e) => {
                        this.handleKeyDown(e);
                    }}
                    className={
                        "post-button" 
                        + (this.props.isDisabled ? " disabled" : "")
                    }
                    onClick={this.onSaveClicked}
                    role="button"
                    // onBlur={this.onBlur}
                >
                    {localize("START_COMPOSER_SUBMIT_TEXT")}
                </div>
            </div>
        );
    }

    protected handleKeyDown = (e) => {
        const  code = e.which;
        if (code === 13 || code === 32) {
            // 13 = Return, 32 = Space
            this.onSaveClicked();
        }
    };

    // protected onBlur = (e) => {
    //     setTimeout(() => {
    //         const parent = parentBy(
    //             window.document.activeElement as HTMLElement,
    //             (p) => p.getAttribute("id") === "SocialComposer"
    //         );
            
    //         if (parent) {
    //             return;
    //         }

    //         if (this.props.onBlur) this.props.onBlur();
    //     }, 100);

    //     // if (e.relatedTarget?.getAttribute("class") === "actionmenu-component") {
    //     //     this.props.onBlur();
    //     // }
    // };

    protected onSaveClicked(): void {
        this.props.onSaveClicked();
    }
}

export default SocialComposerFooter;
