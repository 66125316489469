import React from "react";
import { localize } from "src/l10n";
import { MarketplaceListItem } from "../MarketplaceListItem";
import "./MarketplaceList.scss";
import { SpintrTypes } from "src/typings";

interface IProps {
    apps: Spintr.IMarketplaceAppResponse[];
    displayMissingAppEntry?: boolean;
}

const MarketplaceList: React.FC<IProps> = ({ apps, displayMissingAppEntry }) => (
    <div className="MarketplaceList">
        {apps.map((item, index) => {
            const key = "MarketplaceList.GridItem" + index;

            return (
                <div className="list-item" key={key}>
                    <MarketplaceListItem
                        app={item}
                        key={item.id}
                    />
                </div>
            );
        })}
        {displayMissingAppEntry && (
            <div className="list-item">
                <MarketplaceListItem
                    app={{
                        authenticationMethod: SpintrTypes.DataAppAuthenticationMethod.AnonymousAccess,
                        byRequest: false,
                        categoryId: "",
                        iconUrl: "/spintr-monogram-192x192.png",
                        description: localize("MARKETPLACE_MISSING_APP_DESC"),
                        installed: false,
                        instructions: "",
                        name: localize("MARKETPLACE_MISSING_APP"),
                        tags: [],
                    }}
                />
            </div>
        )}
    </div>
);

export default MarketplaceList;
