import { ActionButton, TextField } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import FilePicker from "../FilePicker";

interface IProps {
    post: any;
    onAttachedContentUpdate: any;
    onRemove: any;
    enableSharepoint?: boolean;
    enableGoogle?: boolean | undefined;
    enableSharePointListSearch?: boolean;
}

interface IState {
    isFilePickerOpen: boolean;
}

class Visage2ComposerExternalFile extends Component<IProps, IState> {
    private sources: Spintr.FolderRootSource[] = [];

    constructor(props: IProps) {
        super(props);

        this.state = {
            isFilePickerOpen: false
        };

        this.setFileSources();
    }

    protected setFileSources(): void {
        if (this.props.enableGoogle) {
            this.sources.push(SpintrTypes.FolderRootSource.GoogleDrive);
        }

        if (this.props.enableSharepoint) {
            this.sources.push(SpintrTypes.FolderRootSource.Office365Public);
        }
    };

    protected onFilesSelected(files: Spintr.IFileBrowserFile[], source: SpintrTypes.FolderRootSource) {
        this.setState({
            isFilePickerOpen: false
        }, () => {
            this.props.onAttachedContentUpdate({
                ...this.props.post,
                file: {
                    ...files[0],
                    source,
                },
            });
        });
    }

    protected toggleFilePicker(): void {
        this.setState({
            isFilePickerOpen: !this.state.isFilePickerOpen
        });
    };

    render(): ReactNode {
        return (
            <div className="Visage2ComposerExternalFile">
                {this.props.post.file && (
                    <div
                        key={this.props.post.file.id}
                        className="composer-file-row">
                        <Label size="body-1">{this.props.post.file.name}</Label>
                        <UnstyledButton title={localize("TaBort")} onClick={() => {
                            this.props.onAttachedContentUpdate({
                                ...this.props.post,
                                file: undefined
                            });
                        }}>
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>
                )}
                {!this.props.post.file && (
                    <div className="composer-file-row">
                        <UnstyledButton
                            className="composer-add-file"
                            onClick={this.toggleFilePicker.bind(this)}
                        >
                            <Visage2Icon icon="add" color="light-blue" />
                            <Label
                                className="heading"
                                size="body-1"
                                weight="medium"
                                color="light-blue"
                            >
                                {localize("ValjFil")}
                            </Label>
                        </UnstyledButton>
                        <UnstyledButton title={localize("TaBort")} onClick={() => {
                            this.props.onRemove();
                        }}>
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>
                )}
                {this.state.isFilePickerOpen && (
                    <FilePicker
                        onClose={this.toggleFilePicker.bind(this)}
                        onSelect={this.onFilesSelected.bind(this)}
                        sourcesToDisplay={this.sources}
                        source={this.sources[0]}
                        showFiles={true}
                        selectFolders={false}
                        allowMultipleFiles={false}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        enableGoogle: state.profile.active.googleConnected,
        enableSharePointListSearch: false,
        enableSharepoint: state.instance.get("enableSharepoint") as boolean,
    };
};

export default connect(mapStateToProps)(Visage2ComposerExternalFile);