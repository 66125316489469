import React from "react";
import "./GaugeWidget.scss";
import D3GaugeChart from "./D3GaugeChart";

interface GaugeWidgetData {
    value: number;
    thresholds: number[];
    timestamp?: string;
    isPercentage?: boolean;
    hasThresholdsBelowMinValue?: boolean;
    greyInsteadOfRed?: boolean;
}

interface ArcedProps {
    data: GaugeWidgetData;
    variables: { [key: string]: string };
}

const GaugeWidget = (props: ArcedProps) => {
    let data = { ...props.data };


    if (!data) {
        return null;
    }

    if (!data.thresholds || data.thresholds.filter(x => x === 0).length === data.thresholds.length) {
        data.thresholds = [0, (data.value || 1), (data.value || 1) * 2];
    }

    return (
        <div className={data.timestamp ? "GaugeWidgetData with-timestamp" : "GaugeWidgetData"}>
            <D3GaugeChart data={data} variables={props.variables} />
        </div>
    );
}

export default GaugeWidget;