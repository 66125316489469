import React from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Breadcrumbs } from "src/ui";

interface IProps {
    globalBreadcrumbs?: Spintr.IBreadcrumbItem[];
}

const GlobalBreadcrumbs = (props: IProps) => {
    if (!props.globalBreadcrumbs || props.globalBreadcrumbs.length === 0) {
        return null;
    }

    return (
        <div>
            <Breadcrumbs
                hideInstance
                items={props.globalBreadcrumbs}
            />
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    globalBreadcrumbs: state.ui.globalBreadcrumbs
});

export default connect(mapStateToProps)(GlobalBreadcrumbs);
