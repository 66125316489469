import React, { Component } from 'react';
import { FillHeight, Scrollable } from "src/ui/components";

class GroupClassicTodoView extends Component {
    render() {
        return (
            <div className="GroupClassicTodoView">
                <FillHeight>
                    <Scrollable>
                        <div className="site-max-width-with-padding group-content-view-spacing">
                            GroupClassicTodoView
                        </div>
                    </Scrollable>
                </FillHeight>
            </div>
        )
    }
}

export default GroupClassicTodoView;
