import {
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType
} from "@fluentui/react";
import React, { FunctionComponent, ReactElement } from "react";

const getCustomElements = (): JSX.Element => {
    return (
      <div style={{
          display: "flex"
      }}>
        <ShimmerElementsGroup
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 36 },
            { type: ShimmerElementType.gap, width: 10, height: 36 },
          ]}
        />
        <ShimmerElementsGroup
          flexWrap={true}
          width="100%"
          shimmerElements={[
            { type: ShimmerElementType.line, width: '100%', height: 10, verticalAlign: 'bottom' },
            { type: ShimmerElementType.line, width: '90%', height: 8 },
            { type: ShimmerElementType.gap, width: '10%', height: 18 },
          ]}
        />
      </div>
    );
  };

const SocialPostShimmer: FunctionComponent = (): ReactElement => (
    <div className="SocialPostShimmer" style={{
        padding: "20px",
        backgroundColor: "#FFF",
        marginBottom: "20px"
    }}>
        <Shimmer customElementsGroup={getCustomElements()} />
    </div>
);

export default SocialPostShimmer;
