import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ResponsiveBlock from './ResponsiveBlock'

const getInitialState = () => ({
    height: 0
})

class FlexArea extends Component<{ className?: string, onResize: any }> {
    constructor() {
        // @ts-ignore
        super()

        this.state = getInitialState()
        // @ts-ignore
        this.recalculateInContext = () => this.recalculateHeight()
    }

    componentDidMount() {
        // @ts-ignore
        window.addEventListener('resize', this.recalculateInContext)

        this.recalculateHeight()
    }

    componentWillUnmount() {
        // @ts-ignore
        window.removeEventListener('resize', this.recalculateInContext)
    }

    handleResponsiveBlockResize(width, height) {
        // @ts-ignore
        this.props.onResize && this.props.onResize(width, height)
    }

    recalculateHeight() {
        const selfNode = ReactDOM.findDOMNode(this)
        const parentNode = selfNode.parentNode
        // @ts-ignore
        const height = this.state.height
        let viewportHeight = window.innerHeight

        let wasHidden = false
        // @ts-ignore
        if (parentNode && parentNode.style.display === 'none') {
            // @ts-ignore
            parentNode.style.display = 'initial'
            wasHidden = true
        }

        // @ts-ignore
        viewportHeight -= parentNode.getBoundingClientRect().top

        // TODO: viewpoerHeight += scrollPosition.yAxis

        if (parentNode) {
            Array.from(parentNode.childNodes).forEach((s) => {
                // @ts-ignore
                if (s.nodeType === Node.COMMENT_NODE || s.style.position === 'absolute') {
                    return
                }

                // @ts-ignore
                viewportHeight -= s.getBoundingClientRect().height
            })

            // @ts-ignore
            viewportHeight += selfNode.getBoundingClientRect().height
        }

        if (wasHidden) {
            // @ts-ignore
            parentNode.style.display = 'none'
        }

        if (isNaN(viewportHeight) || viewportHeight < 0) {
            viewportHeight = 0
        }

        if (viewportHeight === height) {
            return
        }

        this.setState({
            height: viewportHeight
        })
    }

    render() {
        const style = {
            // @ts-ignore
            height: this.state.height + 'px'
        }

        let className: string = "FlexArea"
        // @ts-ignore
        if (this.props.className) {
            // @ts-ignore
            className += ` ${this.props.className}`
        }

        return (
            <ResponsiveBlock
                className={className}
                onResize={this.handleResponsiveBlockResize.bind(this)}
                style={style}>
                {this.props.children}
            </ResponsiveBlock>
        )
    }
}

export default FlexArea

