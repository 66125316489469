import { PrimaryButton } from "@fluentui/react";
import React, { ReactElement, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { localize } from "src/l10n";
import ConfirmDialog from "src/ui/components/Forms/ConfirmDialog";
import { ProductActionsProps } from "./ProductActions.types";


function ProductActions(props: ProductActionsProps): ReactElement {
    const { productId, isAdministrator } = props;
    const history = useHistory();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const onDeleteClicked = useCallback(
        () => setShowConfirmDialog(true),
        [setShowConfirmDialog],
    );

    const onDeleteConfirmed = useCallback(() => {
        props.onDeleteClick();
    }, []);

    const onConfirmDialogDismissed = useCallback(
        () => setShowConfirmDialog(false),
        [setShowConfirmDialog],
    );

    const onEditClicked = useCallback(() => {
        props.onEditClick();
    }, [productId, history]);

    if (!isAdministrator) {
        return null;
    }

    return (
        <div className="ProductActions">

            <PrimaryButton
                className="button edit-button"
                onClick={onEditClicked}
            >
                {localize("EDIT_PRODUCT")}
            </PrimaryButton>

            <PrimaryButton
                className="button delete-button"
                onClick={onDeleteClicked}
            >
                {localize("DELETE_PRODUCT")}
            </PrimaryButton>

            <ConfirmDialog
                title={localize("RaderaInnehall")}
                message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase())}
                show={showConfirmDialog}
                onDismiss={onConfirmDialogDismissed}
                onConfirm={onDeleteConfirmed}
            />

        </div>
    );
}

export default ProductActions;
