import { produce } from "immer";
import { Reducer } from "redux";

import { ActionTypes } from './action-types';
import * as Actions from "./actions";

export interface IShortcutsState {
    error: boolean;
    isLoading: boolean;
    items: Spintr.IShortcut[];
    hasFetched: boolean;
}

const initialState: IShortcutsState = {
    error: false,
    isLoading: false,
    items: [],
    hasFetched: false
};

const shortcutsReducer: Reducer<IShortcutsState, Actions.ShortcutAction> =
    (state = initialState, action) => produce(state, draft => {
        switch (action.type) {
            case ActionTypes.FetchUserShortcutsPending:
                draft.error = false;
                draft.isLoading = true;
                break;

            case ActionTypes.FetchUserShortcutsRejected:
                draft.error = true;
                draft.isLoading = false;
                break;

            case ActionTypes.FetchUserShortcutsFulfilled:
                draft.error = false;
                draft.isLoading = false;
                draft.hasFetched = true;
                draft.items = action.payload.data;
                break;
        }
    });

export default shortcutsReducer;
