import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "src/spintr/components/SpintrStaticLinksPopout.scss";
import { Style } from "../helpers";
import { ActionMenu } from "./ActionMenu";
import Label from "./Label/Label";

interface IProps {
    title: string;
    children: React.ReactNode[];
    menuItems: any;
    headerChildren: React.ReactNode[];
}

class PopoutFrame extends Component<IProps> {
    constructor(props) {
        super(props);
    }

  
    public render() {
        return (
            <div className="PopoutFrame">
                <div className="PopoutFrame-header">
                    <Label size="h6" className="PopoutFrame-title">{this.props.title}</Label>
                    
                    {!!this.props.headerChildren &&
                        this.props.headerChildren }

                    {!!this.props.menuItems &&
                        <ActionMenu 
                            categories={[
                                {
                                    ...this.props.menuItems
                                }
                            ]} 
                        />
                    }
                </div>
                <div className="PopoutFrame-content">
                    { this.props.children }
               </div>

            </div>
        )
    }
}

export default withRouter(PopoutFrame);
