export default [
    {
        key: "1",
        text: "Svenska",
        code: "sv"
    },
    {
        key: "2",
        text: "Norsk",
        code: "nb"
    },
    {
        key: "3",
        text: "English",
        code: "en"
    },
    {
        key: "4",
        text: "Deutsch",
        code: "de"
    },
    {
        key: "5",
        text: "Suomi",
        code: "fi"
    },
    {
        key: "6",
        text: "Dansk",
        code: "da"
    },
    {
        key: "7",
        text: "Italiano",
        code: "it"
    },
    {
        key: "8",
        text: "Español",
        code: "es"
    },
    {
        key: "9",
        text: "Française",
        code: "fr"
    }
];
