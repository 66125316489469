import {
    FunctionComponent,
    useEffect,
    useMemo,
    useState
} from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface IProps {
    model: object;
    onAutosave: () => void;
}

const Autosaver: FunctionComponent<IProps> = ({ model, onAutosave }) => {
    const [initialized, setInitialized] = useState(false);

    // Memoize a subject
    const subject = useMemo(() => new Subject<boolean>(), []);

    // Check if model changes
    useEffect(() => initialized // don't trigger for first run
        ? subject.next(true)
        : setInitialized(true), 
        [model]
    );

    useEffect(() => {
        const subscription = subject
            .pipe(debounceTime(5000))
            .subscribe(onAutosave);

        return () => subscription.unsubscribe();
    }, [onAutosave]);

    return null;
};

export default Autosaver;
