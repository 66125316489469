import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { CreatePlannerItemHandler, PlannerTimelineItem, PlannerViewMode, TimelineMode } from "../types";
import { PlannerProps, PlannerState, PlannerStateProps } from "./Planner.types";
import { Timeline } from "../Timeline";
import { PlannerCommandBar } from "../PlannerCommandBar";
import { PlannerItemFormDrawer } from "../PlannerItemFormDrawer";
import { PlannerList } from "../PlannerList";
import { PlannerItemDetailDrawer } from "../PlannerItemDetailDrawer";
import { ConditionalRender } from "src/components/ConditionalRender";
import AppDisabledBanner from "src/ui/components/AppDisabledBanner/AppDisabledBanner";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarMode, VisageSidebarMode } from "src/sidebar";
import { localize } from "src/l10n";

function Planner(props: PlannerProps): ReactElement {
    const { hasWriteAccess, appMode, isSmallViewMode } = useSelector<Spintr.AppState, PlannerStateProps>(
        (state) => ({
            isSmallViewMode: state.ui.isSmallViewMode,
            appMode: state.ui.appMode,
            hasWriteAccess: (
                state.profile.active.roles.includes("administrators") ||
                state.profile.active.roles.includes("editor")
            ),
        })
    );

    const [state, setState] = useState<PlannerState>({
        drawerOpen: false,
        searchText: "",
        timelineMode: props.initialTimelineMode || "WEEKS",
        viewMode: props.initialViewMode || "TIMELINE",
    });

    const { timelineMode, viewMode } = state;
    const refetchRef = useRef<() => void>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSidebarMode(VisageSidebarMode.noMenu));

        return () => {
            dispatch(setSidebarMode(VisageSidebarMode.menu));
        };
    }, []);

    const onSearchChanged = useCallback((searchText: string) => setState((prevState) => ({
        ...prevState,
        searchText,
    })), [setState]);

    const onTimelineModeChanged = useCallback(
        (mode: TimelineMode) => setState((prevState) => ({
            ...prevState,
            timelineMode: mode,
        })),
        [setState],
    );

    const onViewModeChange = useCallback(
        (mode: PlannerViewMode) => setState((prevState) => ({
            ...prevState,
            viewMode: mode,
        })),
        [setState],
    );

    const onOpenDrawer = useCallback(
        (id?: number | undefined) => setState((prevState) => ({
            ...prevState,
            drawerOpen: true,
            selectedId: id,
        })),
        [setState],
    );

    const onDrawerDismissed = useCallback(
        (_?: boolean | undefined) => setState((prevState) => ({
            ...prevState,
            drawerOpen: false,
            initialModel: undefined,
        })),
        [setState]
    );

    const onDetailsDismissed = useCallback(() => setState((prevState) => ({
        ...prevState,
        selectedId: undefined
    })), [setState]);

    const onEditClicked = useCallback(
        (id: number) => {
            onDetailsDismissed();
            onOpenDrawer(id);
        },
        [onDetailsDismissed, onOpenDrawer],
    );

    const onBarClicked = useCallback(
        (item: PlannerTimelineItem<number>) => setState((prevState) => ({
            ...prevState,
            selectedId: item.key
        })),
        [setState],
    );

    const onItemClicked = useCallback(
        (item: Spintr.PlannerItem) => setState((prevState) => ({
            ...prevState,
            selectedId: item.id,
        })),
        [setState],
    );

    const onCreateClick = useCallback<CreatePlannerItemHandler>((start, end, categoryId) => {
        setState((prevState) => ({
            ...prevState,
            drawerOpen: true,
            initialModel: {
                startsAt: start,
                endsAt: end,
                categoryId,
            },
        }));
    }, []);

    return (
        <section className="Planner">
            <AppDisabledBanner type={localize("appPlanner")} />
            <PlannerCommandBar
                hasWriteAccess={hasWriteAccess}
                isSmallViewMode={isSmallViewMode}
                appMode={appMode}
                onCreateClick={onOpenDrawer}
                onSearchChange={onSearchChanged}
                onTimelineModeChange={onTimelineModeChanged}
                onViewModeChange={onViewModeChange}
                searchText={state.searchText}
                timelineMode={timelineMode}
                viewMode={viewMode} />

            <ConditionalRender condition={viewMode === "LIST"}>
                {() => (
                    <PlannerList
                        onItemClick={onItemClicked}
                        refetchRef={refetchRef} />
                )}
            </ConditionalRender>

            <ConditionalRender condition={viewMode === "TIMELINE"}>
                {() => (
                    <Timeline
                        hasWriteAccess={hasWriteAccess}
                        onBarClick={onBarClicked}
                        onCreateClick={onCreateClick}
                        searchText={state.searchText}
                        timelineMode={timelineMode} />
                )}
            </ConditionalRender>

            <PlannerItemFormDrawer
                initialModel={state.initialModel}
                itemId={state.selectedId}
                onDismiss={onDrawerDismissed}
                open={state.drawerOpen}
                size={isSmallViewMode ? "full" : "medium"} />

            <PlannerItemDetailDrawer
                onDismiss={onDetailsDismissed}
                onEditClick={onEditClicked}
                open={!!state.selectedId} 
                selectedId={state.selectedId}
                size={isSmallViewMode ? "full" : "medium"}  />

        </section>
    );
}

export default Planner;
