import React, { Component } from "react";
import ErrorMessagebar from "../Messagebars/ErrorMessagebar";
import "./OfflineBanner.scss";

interface IProps {}

interface IState {
    isClosed: boolean,
    isOffline: boolean
}

class OfflineBanner extends Component<IProps, IState> {
    private _isMounted : boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            isClosed: false,
            isOffline: !navigator.onLine
        }
    }

    statusChange() {
        if (!this._isMounted) {
            return;
        }

        const isOffline = !navigator.onLine;

        if (this.state.isOffline === isOffline) {
            return;
        }

        this.setState({
            isOffline: !navigator.onLine
        })
    }

    componentDidMount() {
        this._isMounted = true;

        window.addEventListener("online", this.statusChange.bind(this));
        window.addEventListener("offline", this.statusChange.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;

        window.removeEventListener("online", this.statusChange.bind(this));
        window.removeEventListener("offline", this.statusChange.bind(this));
    }

    render() {
        if (this.state.isClosed) {
            return null;
        }

        if (!this.state.isOffline) {
            return null;
        }

        return (
            <div>
                <div className="OfflineBanner-Wrapper site-max-width">
                    <ErrorMessagebar
                        errorList={[
                            "APP_NETWORK_ERROR"
                        ]}
                        onDismiss={() => {
                            this.setState({
                                isClosed: true
                            });
                        }}
                    />
                </div>
                {/* <div className="OfflineBanner">
                    <Label size="body-2">
                        {localize("APP_NETWORK_ERROR")}
                    </Label>
                    <div className="removeButton">
                        <UnstyledButton
                            onClick={() => {
                                this.setState({
                                    isClosed: true
                                })
                            }}
                        >
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>
                </div> */}
            </div>
        )
    };
}

export default OfflineBanner;
