import React, { ButtonHTMLAttributes, Component, ReactNode } from "react";
import "./StandardButton.scss";


export type StandardButtonState = "Primary" | "Secondary" | "Disabled";

export interface IStandardButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    state: StandardButtonState;
    text: string;
}

class StandardButton extends Component<IStandardButtonProps> {
    public render() : ReactNode {
        const { children, className, state, text, ...attrs } = this.props;
        
        const classNames = [
            "StandardButton", 
            this.props.state.toLowerCase(),
            className
        ];

        return (
            <button
                className={classNames.join(" ")}
                {...attrs}
            >
                {text}
            </button>
        );
    }
}

export default StandardButton;