import React, { Fragment, FunctionComponent } from 'react';
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const PageExpiredNotification: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.IPageExpiredNotification;
    if (!notification) {
        return;
    }

    return (
        <Fragment>
            {localize("Textsidan") + " " + notification.name + " " + localize("HarLoptUtOchBehoverGranskas").toLowerCase() + "."}
        </Fragment>
    );
};

export default PageExpiredNotification;
