export const FETCH_PLANNING = 'FETCH_PLANNING';
export const FETCH_PLANNING_PENDING = 'FETCH_PLANNING_PENDING';
export const FETCH_PLANNING_REJECTED = 'FETCH_PLANNING_REJECTED';
export const FETCH_PLANNING_FULFILLED = 'FETCH_PLANNING_FULFILLED';
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const SAVE_CATEGORY_PENDING = 'SAVE_CATEGORY_PENDING';
export const SAVE_CATEGORY_REJECTED = 'SAVE_CATEGORY_REJECTED';
export const SAVE_CATEGORY_FULFILLED = 'SAVE_CATEGORY_FULFILLED';
export const SAVE_ITEM = 'SAVE_ITEM';
export const SAVE_ITEM_PENDING = 'SAVE_ITEM_PENDING';
export const SAVE_ITEM_REJECTED = 'SAVE_ITEM_REJECTED';
export const SAVE_ITEM_FULFILLED = 'SAVE_ITEM_FULFILLED';
export const SAVE_BOARD_POSITIONS = 'SAVE_BOARD_POSITIONS';
export const SAVE_BOARD_POSITIONS_PENDING = 'SAVE_BOARD_POSITIONS_PENDING';
export const SAVE_BOARD_POSITIONS_REJECTED = 'SAVE_BOARD_POSITIONS_REJECTED';
export const SAVE_BOARD_POSITIONS_FULFILLED = 'SAVE_BOARD_POSITIONS_FULFILLED';
export const DELETE_ITEM_FULFILLED = "DELETE_ITEM_FULFILLED";