import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";

export * from "./build-app-page-component";

export interface IVariableMap {[name: string]: string }

export function extractVariableNames(input: string): string[] {
    const variablePattern: RegExp = /(?:\{\{([a-z]+)\}\})/mig;
    const variables: string[] = [];

    input.replace(variablePattern, (_, name) => {
        if (variables.indexOf(name) === -1) {
            variables.push(name);
        }

        return "";
    });

    return variables;
}

export function insertVariables(variables: IVariableMap, target: string): string {
    return Object.keys(variables)
        .filter(v => Object.prototype.hasOwnProperty.call(variables, v))
        .reduce(
            (str, key) => str.replace(`{{${key}}}`, variables[key]),
            target
        );
}

export function getSampleWidgetData(displayType: Spintr.WidgetType): any {
    switch (displayType) {
        case SpintrTypes.WidgetType.KeyPointIndicator:
            return {
                text: "",
                value: 123,
            };

        case SpintrTypes.WidgetType.BarChart:
            return [{
                name: localize("LABEL") + " #1",
                value: 20,
            },{
                name: localize("LABEL") + " #2",
                value: 30,
            },{
                name: localize("LABEL") + " #3",
                value: 10,
            },];

        case SpintrTypes.WidgetType.PieChart:
            return [{
                value: 25,
            },{
                value: 75,
            }];

        case SpintrTypes.WidgetType.List:
            return [{
                text: localize("LABEL") + " #1",
                value: 30,
            }, {
                text: localize("LABEL") + " #2",
                value: 20,
            },{
                text: localize("LABEL") + " #3",
                value: 10,
            },];

        case SpintrTypes.WidgetType.ListOneColumn:
            return [{
                text: localize("LABEL") + " #1",
            }, {
                text: localize("LABEL") + " #2",
            },{
                text: localize("LABEL") + " #3",
            },];

        default:
            return null;
    }
}