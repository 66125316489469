import { localize } from "src/l10n";
import { IDatePickerStrings } from '@fluentui/react';

export default function getDatePickerLanguageStrings() {
    const months = [
        localize("Januari_stor"),
        localize("Februari_stor"),
        localize("Mars_stor"),
        localize("April_stor"),
        localize("Maj_stor"),
        localize("Juni_stor"),
        localize("Juli_stor"),
        localize("Augusti_stor"),
        localize("September_stor"),
        localize("Oktober_stor"),
        localize("November_stor"),
        localize("December_stor"),
    ]
    
    const days = [
        localize("Sondag_stor"),
        localize("Mandag_stor"),
        localize("Tisdag_stor"),
        localize("Onsdag_stor"),
        localize("Torsdag_stor"),
        localize("Fredag_stor"),
        localize("Lordag_stor"),
    ]
    
    const DayPickerStrings: IDatePickerStrings = {
        months: [
            months[0],
            months[1],
            months[2],
            months[3],
            months[4],
            months[5],
            months[6],
            months[7],
            months[8],
            months[9],
            months[10],
            months[11]
        ],
        shortMonths: [
            months[0].substring(0, 3),
            months[1].substring(0, 3),
            months[2].substring(0, 3),
            months[3].substring(0, 3),
            months[4].substring(0, 3),
            months[5].substring(0, 3),
            months[6].substring(0, 3),
            months[7].substring(0, 3),
            months[8].substring(0, 3),
            months[9].substring(0, 3),
            months[10].substring(0, 3),
            months[11].substring(0, 3)
        ],
        days: [
            days[0],
            days[1],
            days[2],
            days[3],
            days[4],
            days[5],
            days[6]
        ],
        shortDays: [
            days[0].substring(0, 1),
            days[1].substring(0, 1),
            days[2].substring(0, 1),
            days[3].substring(0, 1),
            days[4].substring(0, 1),
            days[5].substring(0, 1),
            days[6].substring(0, 1)
        ],
        goToToday: localize("DATEPICKER_GO_TO_TODAY"),
        prevMonthAriaLabel: localize("DATEPICKER_GO_TO_PREVIOUS_MONTH"),
        nextMonthAriaLabel: localize("DATEPICKER_GO_TO_NEXT_MONTH"),
        prevYearAriaLabel: localize("DATEPICKER_GO_TO_PREVIOUS_YEAR"),
        nextYearAriaLabel: localize("DATEPICKER_GO_TO_NEXT_YEAR"),
        closeButtonAriaLabel: localize("Stang")
    };

    return DayPickerStrings;
}