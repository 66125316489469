import moment from "moment";
import { TooltipHost } from "@fluentui/react";
import React, { Component } from "react";
import Timeline, { CustomHeader, DateHeader, TimelineHeaders, TodayMarker } from "react-calendar-timeline/lib";
import containerResizeDetector from "react-calendar-timeline/lib/resize-detector/container";
import "react-calendar-timeline/lib/Timeline.css";
import { hitEnterOrSpace } from "src/files/utils";
import { getLocalizationTag, localize } from "src/l10n";
import Color from "src/style/colors/Color";
import { Label } from "src/ui";
import { Style } from "src/ui/helpers";
import SpintrLoader from "../Loader";
import "./CustomTimeline.scss";
import { dateToYMD } from "./Utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    users: any;
    items: any;
    showInfo?: any;
    onClickGroup?: any;
    whichMonth?: any;
    instance: any;
}

interface IState {
    isLoading: boolean;
    visibleTimeStart: any;
    visibleTimeEnd: any;
}
export default class CustomTimeline extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        let amountOfDays = moment().daysInMonth();

        const visibleTimeStart = moment().startOf("month").valueOf();
        const visibleTimeEnd = moment().startOf("month").add(amountOfDays, "days").valueOf();

        this.state = {
            isLoading: true,
            visibleTimeStart,
            visibleTimeEnd,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });

        let tag = getLocalizationTag();

        var moment = require("moment");

        switch (tag) {
            case "sv":
                require("moment/locale/sv");
                break;
            case "no":
                require("moment/locale/nb");
                break;
            case "en":
                require("moment/locale/en-gb");
                break;
            case "de":
                require("moment/locale/de");
                break;
            case "fi":
                require("moment/locale/fi");
                break;
        }
    }

    getBookingsByDate = (date: Date, intervalId: number) => {
        return this.props.users
            .map((a) => {
                return a.intervals;
            })
            .flat()
            .find((i) => i.id === intervalId)
            ?.bookings.find((b) => {
                return b === dateToYMD(date);
            });
    };

    // get all bookings for a resource for a given date
    getBookingsByGroup = (date: Date, groupId: number) => {
        return this.props.users
            .find((r) => {
                return r.id === groupId;
            })
            ?.intervals.map((i) => {
                return i.bookings;
            })
            .flat()
            .filter((b) => {
                return b.date === dateToYMD(date);
            });
    };

    getAvailableBookings = (date: Date, groupId: number) => {
        // find all non-booked intervalIds for a given resource (groupId) and date.

        const bookedIntervals = this.getBookingsByGroup(date, groupId).map((b) => {
            return b.intervalId;
        });

        const availableIntervals = this.props.users
            .find((r) => {
                return r.id === groupId;
            })
            .intervals.map((i) => {
                return i.id;
            });

        return availableIntervals.filter((i) => {
            if (!bookedIntervals.includes(i)) return i;
        });
    };

    itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const bookingsOnDate = this.getBookingsByGroup(moment(item.start_time).toDate(), item.group);
        const hasBookings = bookingsOnDate?.length > 0;
        const fullyBooked = !(this.getAvailableBookings(moment(item.start_time).toDate(), item.group)?.length > 0); // FIXME: implement bookingsAvailable(date, groupId)

        const activeColor = this.props.instance.get('color');
        const defaultColor = Color.fromHex(activeColor).customLighten().toString("hex");

        const backgroundColor = fullyBooked ?
            activeColor :
            hasBookings ?
                activeColor :
                defaultColor;

        return (
            <div
                {...getItemProps({
                    onTouchEnd: (e) => {
                        this.showInfoItem(item);
                    },
                    onMouseUp: (e) => {
                        this.showInfoItem(item);
                    },
                    onKeyUp: (e) => {
                        hitEnterOrSpace(e, this.showInfoItem(item))
                    },
                    style: {
                        border: 0,
                    },
                })}
            >
                <div
                    tabIndex={0}
                    style={{
                        backgroundColor: backgroundColor,
                        height: itemContext.dimensions.height,
                        width: itemContext.dimensions.width,
                        overflow: "hidden",
                        paddingLeft: Style.getSpacingStr(2),
                        paddingRight: 2,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "block",
                        lineHeight: "28px",
                    }}
                >
                    <Label size="small-1" as="span">
                        {itemContext.title}
                    </Label>
                </div>
            </div>
        );
    };

    groupRenderer = ({ group }) => {
        return (
            <div className="custom-group">
                <span style={{ cursor: "pointer" }} onClick={() => this.onClickGroup(group)} className="title" title={group.title}>
                    <Label as="span" size="small-1">
                        {group.title}
                    </Label>
                </span>
            </div>
        );
    };

    onPrevClick = () => {
        let currentDate = moment.unix(this.state.visibleTimeStart / 1000).format();
        let date = moment(currentDate).subtract(1, "months");
        let endDate = moment(date).endOf("month").format("YYYY-MM-DD");
        let startDate = moment(date).startOf("month").format("YYYY-MM-DD");
        let endd = moment(endDate).add(1, "days");
        let end = moment(endd).valueOf();
        let start = moment(startDate).valueOf();

        this.setState(
            {
                visibleTimeStart: start,
                visibleTimeEnd: end,
            },
            () => {
                this.changeMonth(startDate, endDate);
            }
        );
    };

    onNextClick = () => {
        let currentDate = moment.unix(this.state.visibleTimeStart / 1000).format();
        let date = moment(currentDate).add(1, "months");
        let endDate = moment(date).endOf("month").format("YYYY-MM-DD");
        let startDate = moment(date).startOf("month").format("YYYY-MM-DD");
        let endd = moment(endDate).add(1, "days");
        let end = moment(endd).valueOf();
        let start = moment(startDate).valueOf();

        this.setState(
            {
                visibleTimeStart: start,
                visibleTimeEnd: end,
            },
            () => {
                this.changeMonth(startDate, endDate);
            }
        );
    };

    showInfoItem = (item) => {
        this.props.showInfo?.(item);
    };

    onClickGroup = (group) => {
        this.props.onClickGroup(group);
    };

    changeMonth = (startdate, enddate) => {
        this.props.whichMonth(startdate, enddate);
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const { visibleTimeStart, visibleTimeEnd } = this.state;
        const { users, items } = this.props;

        let names = users.map((obj) => {
            return {
                id: obj.id,
                title: obj.name,
                categoryId: obj.categoryId,
            };
        });

        return (
            <Timeline
                groups={names}
                items={items}
                itemTouchSendsClick={true}
                // itemHeightRatio={1}
                itemHeightRatio={0.75}
                CursorLine
                canMove={false}
                canResize={false}
                visibleTimeStart={visibleTimeStart}
                visibleTimeEnd={visibleTimeEnd}
                itemRenderer={this.itemRenderer}
                groupRenderer={this.groupRenderer}
                resizeDetector={containerResizeDetector}
            >
                <TodayMarker>
                    {({ styles }) => <div style={{ ...styles, backgroundColor: "#000", zIndex: 100 }} />}
                </TodayMarker>
                <TimelineHeaders className="sticky">
                    <CustomHeader height={31} headerData={{ someData: "data" }} unit="month">
                        {({ headerContext: { intervals }, getRootProps, getIntervalProps, showPeriod, data }) => {
                            return (
                                <div {...getRootProps()}>
                                    {intervals.map((interval) => {
                                        const intervalStyle = {
                                            lineHeight: "30px",
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            borderLeft: "1px solid #cecece",
                                            borderBottom: "1px solid #cecece",
                                        };
                                        return (
                                            <div
                                                onClick={() => {
                                                    showPeriod(interval.startTime, interval.endTime);
                                                }}
                                                {...getIntervalProps({
                                                    interval,
                                                    style: intervalStyle,
                                                })}
                                            >
                                                <div
                                                    className="sticky"
                                                    style={{
                                                        display: "flex",
                                                        textAlign: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        style={{ height: 20, cursor: "pointer" }}
                                                        onClick={() => this.onPrevClick()}
                                                    >
                                                        <TooltipHost
                                                            content={localize("DATEPICKER_GO_TO_PREVIOUS_MONTH")}
                                                        >
                                                            <Visage2Icon icon="arrow-left-2" color={"grey"} />
                                                        </TooltipHost>
                                                    </div>
                                                    {interval.startTime.format("MMMM YYYY")}
                                                    <div
                                                        style={{ height: 20, cursor: "pointer" }}
                                                        onClick={() => this.onNextClick()}
                                                    >
                                                        <TooltipHost content={localize("DATEPICKER_GO_TO_NEXT_MONTH")}>
                                                            <Visage2Icon icon="arrow-right-3" color={"grey"} />
                                                        </TooltipHost>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    </CustomHeader>
                    <DateHeader />
                </TimelineHeaders>
            </Timeline>
        );
    }
}
