import React, { Component, ReactNode } from "react";
import { uniqueId } from "src/utils";
import FormControl from "./FormControl";
import TokenizedInputFabric, {
    ITokenizedInputProps
} from "./TokenizedInput";

export interface IFormTokenizedInputProps extends ITokenizedInputProps {
    label: string;
}

interface IFormTokenizedInputState {
    id?: string;
}

class FormTokenizedInput extends 
    Component<IFormTokenizedInputProps, IFormTokenizedInputState> {
    constructor(props : IFormTokenizedInputProps) {
        super(props);

        this.state = {
            id: uniqueId("FormTokenizedObjectInput")
        };
    }

    public render() : ReactNode {
        const id : string = this.props.id || this.state.id;

        return (
            <div className="FormSelect">
                <FormControl 
                    htmlFor={id}
                    label={this.props.label}
                >
                    <TokenizedInputFabric
                        items={this.props.items}
                        onChange={this.props.onChange}
                    />
                </FormControl>
            </div>
        )
    }
}

export default FormTokenizedInput;