import { Component } from "react";
import { connect } from "react-redux";
import { getUnreadEmails } from "../actions";

interface IStateProps {
    preventEmailFetch: boolean;
    emailEnabled: boolean;
    dispatch?: any;
}

class UnreadEmailsLoader extends Component<IStateProps> {
    private readonly delay = 10 * 60 * 1000; // 10 minutes
    private intervalRef: any;

    public componentDidMount() {
        this.intervalRef = setInterval(() => {
            this.getUnreadEmails();
        }, this.delay);

        this.getUnreadEmails();
    }

    getUnreadEmails() {
        if (!this.props.emailEnabled) {
            return;
        }

        this.props.dispatch(getUnreadEmails());
    }

    public componentDidUpdate() {
        if (this.props.preventEmailFetch) {
            try {
                clearInterval(this.intervalRef);
            } catch (_) { /* ignored */ }
        }
    }

    public render() {
        return null;
    }
}

export default connect<IStateProps, {}, {}, Spintr.AppState>(
    (state) => ({
        preventEmailFetch: state.profile.preventEmailFetch,
        emailEnabled:
            state.instance.get("enableExternalMailLink") != "false" &&
            (state.profile.active.googleConnected ||
                (state.profile.active.roles.includes("aduser") &&
                    (state.instance.get("enableExchange") ||
                        (state.profile.active.office365Connected && state.instance.get("office365Enabled"))))),
    })
)(UnreadEmailsLoader);
