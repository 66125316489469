import { IPivotItemProps, Pivot, PivotItem } from '@fluentui/react';
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog';
import React, { Component, ReactNode } from "react";
import { likeTypes } from 'src/interactions/types';
import { localize } from "src/l10n";
import { Label, SpintrUser } from "src/ui";
import PopupHeader from 'src/ui/components/PopupHeader';
import { Style } from 'src/ui/helpers';

interface IProps {
    users: Spintr.IUser[];
    popupIsHidden: boolean;
    closePopup: any;
    title: string;
    categorizeByLikeType?: boolean;
}

class UsersListPopup extends Component<IProps> {
    renderUsers(users?: Spintr.IUser[], lt?: any) {
        const displayLikeType = this.props.categorizeByLikeType && !!lt && lt.id === undefined;

        if (!users) {
            users = this.props.users;
        }

        return (
            <div>
                {
                    users.map((liker, index) => {
                        let lt = displayLikeType ?
                            likeTypes.find(lt => lt.id === liker.likeType) :
                            undefined;

                        let imageUrl = liker.imageUrl;

                        //@ts-ignore
                        if (!imageUrl && liker.images && liker.images.feedComposer) {
                            //@ts-ignore
                            imageUrl = liker.images.feedComposer;
                        }

                        return (
                            <div key={index} style={{
                                paddingTop: index === 0 ? 0 : Style.getSpacing(4),
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <div style={{
                                    flexGrow: 1
                                }}>
                                    <SpintrUser
                                        name={liker.name}
                                        subText={!!liker.department ? liker.department.name : ""}
                                        imageUrl={imageUrl}
                                        state={liker.state}
                                        personalName={true} />
                                </div>
                                {displayLikeType && lt && lt.icon && (
                                    <img src={lt.icon} style={{
                                        height: 20,
                                        width: 20
                                    }} />
                                )}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderTabs() {
        let tabs = [];

        if (this.props.users.length > 0) {
            tabs.push({
                name: localize("Alla") + " " + this.props.users.length,
                users: this.props.users
            })
        }

        for (let likeType of likeTypes) {
            tabs.push({
                ...likeType,
                users: this.props.users.filter((u: any) => u.likeType === likeType.id || (!u.likeType && likeType.id === 0))
            });
        }

        tabs = tabs.filter(lt => lt.users.length > 0).sort((a, b) => {
            if (a.users.length < b.users.length) { return 1; }
            if (a.users.length > b.users.length) { return -1; }
            return 0;
        });

        return (
            <div>
                <Pivot overflowBehavior={"menu"}>
                    {
                        tabs.map((lt: any, index: number) => {
                            return (
                                <PivotItem
                                    key={index}
                                    title={lt.name}
                                    headerText={lt.icon ? undefined : lt.name}
                                    onRenderItemLink={(ink?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
                                        if (!lt.icon) {
                                            return defaultRenderer(ink);
                                        }
                                        return (
                                            <div className="reactionPivotItem">
                                                <div className="iconWrapper">
                                                    {
                                                        lt.icon && (
                                                            <img key={lt.id} src={lt.icon} />
                                                        )
                                                    }
                                                </div>
                                                <Label size="body-2" color="mid-grey">
                                                    {lt.users.length}
                                                </Label>
                                            </div>
                                        )
                                    }}>
                                    {
                                        this.renderUsers(lt.users, lt)
                                    }
                                </PivotItem>
                            )
                        })
                    }
                </Pivot>
            </div>
        )
    }

    public render(): ReactNode {
        return (
            <Dialog
                hidden={this.props.popupIsHidden}
                styles={{
                    main: {
                        width: "500px !important",
                        maxWidth: "100% !important"
                    }
                }}
                onDismiss={this.props.closePopup}
                modalProps={{
                    allowTouchBodyScroll: true,
                    containerClassName: "UsersListPopup dialogWithPopupHeader"
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.props.title,
                    showCloseButton: true,
                    closeButtonAriaLabel: localize("Stang"),
                }}>
                <PopupHeader
                    text={this.props.title}
                    onClose={this.props.closePopup} />
                {!this.props.categorizeByLikeType && this.renderUsers()}
                {!!this.props.categorizeByLikeType && this.renderTabs()}
            </Dialog>
        );
    }
}

export default UsersListPopup;