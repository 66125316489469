import React, { FunctionComponent, useCallback, useRef } from "react";

import { localize } from "src/l10n";
import { Label } from "src/ui";
import SocialComposerTypes from "../SocialComposerTypes";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface ITypeButtonInfo {
    iconName: string;
    textKey: string;
}

type ConfigMap = {
    [type in SocialComposerTypes]: ITypeButtonInfo;
};

interface IProps {
    onClick: (type: SocialComposerTypes) => void;
    type: SocialComposerTypes;
}

const buttonConfig: ConfigMap = {
    "external-file": { iconName: "link-1", textKey: "Fil" },
    "file": { iconName: "link-1", textKey: "Fil" },
    "frame": { iconName: "edit", textKey: "Effekt" },
    "image": { iconName: "picture-frame", textKey: "Media" },
    "poll": { iconName: "Chart", textKey: "Omrostning" },
    "question": { iconName: "QuestionLight", textKey: "Fraga" },
    "text": { iconName: "Edit", textKey: "Text" },
    "type-selector": { iconName: "", textKey: "" },
    "video": { iconName: "Video", textKey: "Video" },
}

const ComposerTypeButton: FunctionComponent<IProps> = ({ onClick, type }) => {
    const iconAnimationRef = useRef(null);

    const onButtonClick = useCallback(
        () => onClick(type),
        [onClick, type]
    );

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            const code = e.which;
            if ((code === 13) || (code === 32)) { // 13 = Return, 32 = Space
                onClick(type);
            }
        },
        [onClick, type]
    );

    const config = buttonConfig[type];
    if (!config) {
        return null;
    }

    // TODO: Make this prettier
    if (type === "question" && window.innerWidth > 1699) {
        config.textKey = "StallEnFraga";
    }

    return (
        <div
            className={"ComposerTypeButton " + type}
            onClick={onButtonClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            title={localize(config.textKey)}
        >
            <div className="animatedBg" />
            <Visage2Icon icon={config.iconName} />
            <Label size="body-2" color="visageGray" weight="medium">
                {localize(config.textKey)}
            </Label>
        </div>
    );
};

export default ComposerTypeButton;
