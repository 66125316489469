import {
    FETCH_PLANNING,
    FETCH_PLANNING_PENDING,
    FETCH_PLANNING_REJECTED,
    FETCH_PLANNING_FULFILLED,
    SAVE_CATEGORY,
    SAVE_CATEGORY_PENDING,
    SAVE_CATEGORY_REJECTED,
    SAVE_CATEGORY_FULFILLED,
    SAVE_ITEM,
    SAVE_ITEM_PENDING,
    SAVE_ITEM_REJECTED,
    SAVE_ITEM_FULFILLED,
    SAVE_BOARD_POSITIONS,
    SAVE_BOARD_POSITIONS_FULFILLED,
    SAVE_BOARD_POSITIONS_PENDING,
    SAVE_BOARD_POSITIONS_REJECTED,
    DELETE_ITEM_FULFILLED
} from './planning-action-types';

const initialState = {
    failed: false,
    isLoaded: false,
    isLoading: false,
    categories: [],
    groupId: 0
}

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PLANNING_PENDING:
            return {
                ...state,
                failed: false,
                isLoaded: false,
                isLoading: true,
                groupId: action.meta.groupId
            };
            
        case FETCH_PLANNING_REJECTED:
            return {
                ...state,
                failed: true,
                isLoaded: false,
                isLoading: false,
                groupId: action.meta.groupId
            };

        case FETCH_PLANNING_FULFILLED:
            return {
                ...state,
                failed: false,
                isLoaded: true,
                isLoading: false,
                categories: action.payload.data,
                groupId: action.meta.groupId
            };

        case SAVE_ITEM_FULFILLED:
            let isNewItem = true;

            let o = !!action.payload ?
                action.payload.data :
                action.meta.item;

            for (let c of state.categories) {
                if (!!c.items.find(i => i.id === o.id)) {
                    isNewItem = false;
                }
            }

            if (isNewItem) {
                return {
                    ...state,
                    categories: state.categories.map(c => {
                        if (c.id === o.categoryId) {
                            return {
                                ...c,
                                items: [
                                    ...c.items,
                                    o
                                ]
                            }
                        } else {
                            return c;
                        }
                    })
                }
            } else {
                return {
                    ...state,
                    categories: state.categories.map(c => {
                        if (c.id === o.categoryId) {
                            return {
                                ...c,
                                items: c.items.map(i => {
                                    if (i.id === o.id) {
                                        return o;
                                    } else {
                                        return i;
                                    }
                                })
                            }
                        } else {
                            return c;
                        }
                    })
                }
            }

        case SAVE_CATEGORY_FULFILLED:
            const savedCategory = !!action.payload ?
                action.payload.data :
                action.meta.category;

            const isNewCategory = !state.categories.find(c => c.id === savedCategory.id);

            if (isNewCategory) {
                return {
                    ...state,
                    categories: [
                        ...state.categories,
                        savedCategory
                    ]
                }
            } else {
                return {
                    ...state,
                    categories: state.categories.map(c => {
                        if (c.id === savedCategory.id) {
                            return savedCategory;
                        } else {
                            return c;
                        }
                    })
                }
            }

        case SAVE_BOARD_POSITIONS_PENDING:
        case SAVE_BOARD_POSITIONS_FULFILLED:
            if (action.meta.groupId !== state.groupId) {
                return state;
            }

            return {
                ...state,
                categories: action.meta.categories
            };

        case DELETE_ITEM_FULFILLED:
            return {
                ...state,
                categories: state.categories.map(c => {
                    return {
                        ...c,
                        items: c.items.filter(i => i.id !== action.meta.itemId)
                    }
                })
            };

        default:
            return state;
    }
}

export default groupReducer;
