import React, {  } from "react";
import "./SystemStatusSocialPostHeader.scss";
import { Label, SpintrUser } from "src/ui";
import { connect } from "react-redux";
import { Style } from "src/ui/helpers";
import { localize } from "src/l10n";
import moment from "moment";
import SystemStatusTypePill from "./SystemStatusTypePill";
import { fromNow } from "src/utils";

interface IProps {
    compact?: boolean;
    post: any;
}

const SystemStatusSocialPostHeader = (props: IProps) => {
    return (
        <div className={[
            "SystemStatusSocialPostHeader",
            ...(props.compact ? ["compact"] : [])
        ].join(" ")}>
            {props.post.content.length > 0 && (
                <SystemStatusTypePill
                    isInitialStatus={props.post.content[0].isInitialStatus}
                    statusType={props.post.content[0].systemStatusType}
                    big={!props.compact} />
            )}
            <Label title={moment(props.post.Date).format("lll")} color="grey">
                {fromNow(props.post.Date)}
            </Label>
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    currentUser: state.profile.active,
});

export default connect(mapStateToProps)(SystemStatusSocialPostHeader);

