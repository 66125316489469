import { IColumn, PrimaryButton, Stack } from "@fluentui/react";
import moment from "moment";
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { TicketFormModal } from "./TicketFormModal";

const GroupSupportView: FunctionComponent = () => {
    const [displayTicketModal, setDisplayTicketModal] = useState(false);
    const listRef = useRef<SpintrList>();

    const closeModal = useCallback(
        (saved: boolean) => {
            setDisplayTicketModal(false);

            if (saved && !listRef?.current?.reFetch) {
                return;
            }

            listRef.current.reFetch();
        },
        [setDisplayTicketModal],
    );

    const openModal = useCallback(
        () => setDisplayTicketModal(true),
        [setDisplayTicketModal],
    );

    const renderDateColumn = useCallback(
        (item: Spintr.IZendeskTicket, field: "created_at" | "updated_at") => {
            const date = item[field] as Date;

            return moment(date).fromNow();
        },
        []
    );

    const renderSubject = useCallback(
        (item: Spintr.IZendeskTicket) => (
            <a
                href={`https://support.spintr.me/hc/sv/requests/${item.id}`}
                rel="noopener noreferrer"
                target="_blank"
            >
                {item.subject}
            </a>
        ),
        []
    );

    const columns = useMemo<IColumn[]>(() => 
        [
            {
                fieldName: "status",
                key: "status",
                minWidth: 75,
                maxWidth: 75,
                name: localize("Status"),
                onRender: (item) => 
                    item.status.substr(0, 1).toUpperCase() 
                    + item.status.substr(1),
            },
            {
                fieldName: "subject",
                flexGrow: 100,
                key: "subject",
                minWidth: 200,
                name: localize("Amne"),
                onRender: renderSubject
            },
            {
                fieldName: "created_at",
                key: "created_at",
                minWidth: 150,
                name: localize("Skapad"),
                onRender: (item) => renderDateColumn(item, "created_at"),
            },
            {
                fieldName: "updated_at",
                key: "updated_at",
                minWidth: 150,
                name: localize("Uppdaterad"),
                onRender: (item) => renderDateColumn(item, "updated_at"),
            }
        ]
    , [renderDateColumn, renderSubject]);

    const onFetch = useCallback(() => new Promise((resolve, reject) => {
        api
            .get<Spintr.IZendeskTicket[]>("/api/v1/zendesk/tickets")
            .then(response => resolve({
                    data: response.data
                        .map(ticket => ({
                            ...ticket,
                            created_at: new Date(ticket.created_at),
                            updated_at: new Date(ticket.updated_at),
                        }))
                        .filter(ticket => ticket.status !== "closed")
                        .sort((a, b) => 
                            a.status === "solved" && b.status !== "solved"
                                ? -1
                                : a.status !== "solved" &&  b.status === "solved"
                                    ? 1
                                    : a.updated_at > b.updated_at ? -1 : 1,
                        ),
                    totalCount: response.data.length,
            }))
            .catch(reason => reject(reason));
    }), []);

    return (
        <div className="GroupSupportView">
            <Stack
                className="header"
                horizontal={true}
                grow={true}
                verticalAlign={"center"}
            >
                <Label
                    as="h2"
                    size="h2"
                >
                    {localize("OUR_TICKETS")}
                </Label>
                <div className="cta">
                    <PrimaryButton onClick={openModal}>
                        {localize("CREATE_SUPPORT_TICKET")}
                    </PrimaryButton>
                </div>
            </Stack>
            <SpintrList
                columns={columns}
                disableCommandBar={true}
                disablePagination={true}
                disableSearch={true}
                disableSort={true}
                fetch={onFetch}
                orderByColumn=""
                ref={listRef}
            />
            <TicketFormModal
                isOpen={displayTicketModal}
                onClosing={closeModal}
            />
        </div>
    )
};

export default GroupSupportView;