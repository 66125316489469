import { DefaultButton, IconButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { Breadcrumbs, ContentWithSubmenu, PageHeader, Submenu, UnstyledButton } from "src/ui";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { scrollToTop } from "src/utils";
import "./Operatinginfo.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
}
interface IPropsCustomInput {
    history: any;
    onClick?: () => void;
    value: any;
}

interface IState {
    isNew: boolean;
    isLoading: boolean;
    support: any;
    deleted: any;
    hasExtendedRights: boolean;
    saveError: string[];
}

class OperatingSupportView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            isLoading: true,
            hasExtendedRights: this.props.isAdmin || this.props.isEditor,
            saveError: [],
            support: [{ title: "", phone: "", email: "", url: "" }],
            deleted: [],
        };
    }

    initPage(support) {
        this.setState({
            isNew: support.isNew,
            isLoading: support.isLoading,
            support: support.support,
            saveError: [],
        });
    }
    componentDidMount() {
        this.setState({ isLoading: true });

        api.get("/api/operatinginfo/support").then((response) => {
            this.initPage(response.data);
        });
    }
    addNew() {
        let items = [...this.state.support];

        items.push({ title: "", phone: "", email: "", url: "" });

        this.setState({ support: items });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onSaveClick() {
        var saveErrors = [];

        let itemslist = [...this.state.support];

        let checkTitle = itemslist.filter((x) => x.title == "");

        if (checkTitle.length > 0) {
            saveErrors.push("DuMasteFyllaIEttNamn");
        }
        if (saveErrors.length > 0) {
            this.setState(
                {
                    saveError: saveErrors,
                },
                () => {
                    scrollToTop();
                }
            );
            return;
        }

        let items;
        let support = [...this.state.support];
        let deleted = [...this.state.deleted];

        items = { support, deleted };

        api
            .post("/api/operatinginfo/support", items)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });

        this.props.history.goBack();
    }

    handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.support];
        list[index][name] = value;
        this.setState({ support: list });
    };

    handleRemoveClick = (index) => {
        const list = [...this.state.support];
        const deleteList = [...this.state.deleted];

        let item = { ...list[index] };

        deleteList.push(item);

        list.splice(index, 1);
        this.setState({ support: list, deleted: deleteList }, () => console.log(this.state));
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }
        let adminNewsEditView = false;
        if (window.location.href.indexOf("admin") > -1) {
            adminNewsEditView = true;
        }

        const icon = { iconName: "Delete" };

        return (
            <div>
                <Breadcrumbs
                    displayInstance
                    items={[
                        ...(adminNewsEditView ? [{
                            text: localize("Administration"),
                            key: 2,
                            link: "/admin",
                        }] : []),
                        {
                            text: localize("Driftinformation"),
                            key: 3,
                            link: adminNewsEditView ? "/admin/operatinginfo" : "/operatinginfo",
                        },
                        {
                            text: localize("Supportkontakt"),
                            key: 4,
                            link: window.location.pathname,
                        },
                    ]}
                />
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <div className="page-margin-bottom">
                    <PageHeader title={localize("Supportkontakt")} />
                    {this.state.support.map((x, i) => {
                        return (
                            <FormSection key={i}>
                                <div className="button">
                                    <UnstyledButton onClick={() => this.handleRemoveClick(i)}>
                                        <Visage2Icon icon="trash" />
                                    </UnstyledButton>
                                </div>

                                <FormControl>
                                    <TextField
                                        className="textField"
                                        name="title"
                                        placeholder={localize("Namn")}
                                        value={x.title}
                                        maxLength={64}
                                        onChange={(e) => this.handleInputChange(e, i)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        name="phone"
                                        className="textField"
                                        placeholder={localize("Telefonnummer")}
                                        value={x.phone}
                                        maxLength={64}
                                        onChange={(e) => this.handleInputChange(e, i)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <TextField
                                        name="email"
                                        className="textField"
                                        placeholder={localize("Email")}
                                        value={x.email}
                                        onChange={(e) => this.handleInputChange(e, i)}
                                        maxLength={64}
                                    />
                                </FormControl>

                                <FormControl>
                                    <TextField
                                        name="url"
                                        className="textField"
                                        placeholder={localize("UrlFelanmalan")}
                                        value={x.url}
                                        onChange={(e) => this.handleInputChange(e, i)}
                                        maxLength={64}
                                    />
                                </FormControl>
                            </FormSection>
                        );
                    })}

                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize("Spara")}
                    />
                    <DefaultButton text={localize("LaggTillNy")} onClick={() => this.addNew()} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(OperatingSupportView));
