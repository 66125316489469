import { DefaultButton, Modal, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { Component } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { FormControl, MandatoryText } from "src/ui/components/Forms";
import PopupHeader from "src/ui/components/PopupHeader";
import { validateRequiredTextField } from "src/utils";

interface IProps {
    event?: any;
    dismiss: () => void;
}

interface IState {
    isEmailModalLoading?: boolean;
    emailModalSubject?: string;
    emailModalBody?: string;
}

class CalendarEmailAttendeesPopup extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    private onChangeText = (ev, val) => {
        const name = ev.target.name;
        this.setState((prevState) => ({
            [name]: val,
        }));
    };

    private sendEmail = () => {
        this.setState({
            isEmailModalLoading: true,
        });

        api.post("/api/v1/calendarevent/emailattendees", {
            subject: this.state.emailModalSubject,
            body: this.state.emailModalBody,
            ...(this.props.event.exchangeId
                ? {
                      exchangeEventId: this.props.event.exchangeId,
                  }
                : {
                      spintrEventId: this.props.event.id,
                  }),
        })
            .then(() => {
                this.props.dismiss();
            })
            .catch(() => {
                this.props.dismiss();
            });
    };

    public render() {
        return (
            <Modal className="spintr-modal modalWithPopupHeader" isOpen onDismiss={this.props.dismiss}>
                <PopupHeader text={localize("SkickaEpostTillDeltagare")} onClose={this.props.dismiss} />
                <div>
                    {this.state.isEmailModalLoading ? (
                        <Loader />
                    ) : (
                        <form>
                            <MandatoryText />
                            <FormControl>
                                <TextField
                                    label={localize("Amne")}
                                    name="emailModalSubject"
                                    value={this.state.emailModalSubject}
                                    onChange={this.onChangeText}
                                    required
                                    aria-required
                                    validateOnFocusOut
                                    validateOnLoad={false}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    label={localize("BrodText")}
                                    value={this.state.emailModalBody}
                                    name="emailModalBody"
                                    onChange={this.onChangeText}
                                    multiline
                                    rows={5}
                                    autoAdjustHeight
                                    required
                                    validateOnFocusOut
                                    validateOnLoad={false}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>
                            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                <DefaultButton text={localize("Avbryt")} onClick={this.props.dismiss} />

                                <PrimaryButton
                                    text={localize("Skicka")}
                                    disabled={!this.state.emailModalSubject || !this.state.emailModalBody}
                                    onClick={this.sendEmail}
                                />
                            </Stack>
                        </form>
                    )}
                </div>
            </Modal>
        );
    }
}

export default CalendarEmailAttendeesPopup;
