import { Modal } from "@fluentui/react";
import React, { FunctionComponent } from "react";
import { localize } from "src/l10n";
import PopupHeader from "src/ui/components/PopupHeader";
import { WidgetConfiguration } from "../WidgetConfiguration";

import "./WidgetConfigurationPopup.scss";

interface IProps {
    appId: string;
    widgetConfigurationId?: string;
    isShowing: boolean;
    onDismiss?: () => void;
    onSaved?: (data: Spintr.IMarketplaceLibraryWidgetResponse, isNew?: boolean) => void;
}

const WidgetConfigurationPopup: FunctionComponent<IProps> = (props) => {
    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            containerClassName="WidgetConfigurationPopup-container calendar-event-popup"
            isOpen={props.isShowing}
            onDismiss={props.onDismiss}
        >
            <PopupHeader
                text={localize(!!props.widgetConfigurationId ? "MARKETPLACE_EDIT_CONFIGURATION" : "MARKETPLACE_CREATE_CONFIGURATION")}
                onClose={props.onDismiss} />
            {props.appId && (
                <div className="WidgetConfigurationPopup popup-inner">
                    <WidgetConfiguration
                        appId={props.appId}
                        widgetConfigurationId={props.widgetConfigurationId}
                        onCancelClick={props.onDismiss}
                        onSaved={props.onSaved}
                    />
                </div>
            )}
        </Modal>
    )
}

export default WidgetConfigurationPopup;
