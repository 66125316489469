import Axios from "axios";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { localize } from "src/l10n";
import { Label, PageHeader, setDisableWrapperBackground, setVisage2FullscreenMode } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import {
    ProductDetailsViewRouteParams as Params,
    ProductDetailsViewState as State,
} from "./ProductDetailsView.types";
import { deleteProductAsync, findProductAsync } from "src/products/productsApi";
import { Stack, StackItem } from "@fluentui/react";
import { ProductActions, ProductCampaigns, ProductFormDrawer, ProductImages, ProductPosts } from "src/products/components";
import { IProps as LabelProps } from "src/ui/components/Label/Label";
import { contentStatusToText } from "src/utils";
import { Product } from "src/products/types";

const captionRegular: LabelProps = {
    size: "body-2",
};

function ProductDetailsView(): ReactElement {
    const isAdministrator = useSelector<Spintr.AppState, boolean>(
        (state) => state.profile.active.roles?.includes("administrators"),
    );
    
    const [state, setState] = useState<State>({ isLoading: true });
    const { productId } = useParams<Params>();
    const history = useHistory();
    const { pathname } = useLocation();
    const isAdministrativeView = pathname.startsWith("/admin/");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDisableWrapperBackground(true));

        return () => {
            dispatch(setDisableWrapperBackground(false));
        };
    }, []);

    useEffect(() => {
        if (!isAdministrator && isAdministrativeView) {
            history.push(`/products/${productId}`);
            return;
        }

        const productIdNumber = parseInt(productId, 10);
        if (isNaN(productIdNumber)) {
            history.push("/products");
            return;
        }

        const cancelTokenSource = Axios.CancelToken.source();

        findProductAsync(productIdNumber, cancelTokenSource.token)
            .then((product) => setState((prevState) => ({
                ...prevState,
                product,
                highlightedImageId: product.images?.map((image) => image.id).find((id) => !!id),
                isLoading: false,
            })))
            .catch((error) => {
                console.error(error);
                history.push("/products");
                return;
            });

        return () => cancelTokenSource.cancel();
    }, [history, productId, isAdministrativeView, isAdministrator, setState])

    const onDeleteClicked = useCallback(async () => {
        const productIdNumber = parseInt(productId, 10);
        if (isNaN(productIdNumber)) {
            return;
        }

        try
        {
            await deleteProductAsync(productIdNumber);

            history.push("/products");
        } catch (err) {
            console.error(err);
        }
    }, [productId]);

    const onEditClicked = useCallback(() => setState((prevState) => ({
        ...prevState,
        showFormDrawer: true,
    })), [setState]);

    const onFormDismissed = useCallback((product?: Product | undefined) => setState((prevState) => ({
        ...prevState,
        showFormDrawer: false,
        product: product ?? prevState.product,
    })), [setState]);

    if (state.isLoading) {
        return <SpintrLoader />;
    }

    const { product } = state;
    const formDrawer = isAdministrator ? (
        <ProductFormDrawer
            onDismiss={onFormDismissed}
            open={state.showFormDrawer}
            product={product} />
     ) : null;

     const productImages = (product?.images || []).length === 0 ? null : (
        <StackItem className="product-images">
            <ProductImages
                images={product?.images || []}
                initialSelectedImageUrl={product?.imageUrl} />
        </StackItem>
    );

    return (
        <div id="ProductDetailsView">
            <Stack className="view-stack" horizontal={true}>
                <StackItem className="product-details">
                    <Stack className="product-stack">
                        <StackItem>
                            <Stack className="product-information" horizontal={true}>
                                {productImages}
                                <StackItem className="product-text">
                                    <PageHeader
                                        title={product?.name}
                                    />
                                    {/* Number of Posts */}
                                    <div className="information-list">
                                        <div className="information-item">
                                            <Label {...captionRegular}
                                                className="information-term"
                                                color="grey"
                                            >
                                                {localize("ARTICLE_ID")}
                                            </Label>
                                            <Label {...captionRegular}
                                                className="information-data"
                                                color="primaryContent"
                                            >
                                                {product.articleId}
                                            </Label>
                                        </div>
                                        {isAdministrator && (
                                            <div className="information-item">
                                                <Label {...captionRegular}
                                                    className="information-term"
                                                    color="grey"
                                                >
                                                    {localize("Status")}
                                                </Label>
                                                <Label {...captionRegular}
                                                    className="information-data"
                                                    color="visageMidBlue"
                                                >
                                                    <div className="status-label">
                                                        {contentStatusToText(product.status)}
                                                    </div>
                                                </Label>
                                            </div>
                                        )}
                                        {product?.metadata?.map((metadata) => (
                                            <div className="information-item" key={metadata.id}>
                                                <Label {...captionRegular}
                                                    className="information-term"
                                                    color="grey"
                                                >
                                                    {metadata.name}
                                                </Label>
                                                <Label {...captionRegular}
                                                    className="information-data"
                                                    color="primaryContent"
                                                >
                                                    {metadata.value}
                                                </Label>
                                            </div>
                                        ))}
                                        <div className="information-item description">
                                            <Label {...captionRegular}
                                                className="information-term"
                                                color="grey"
                                            >
                                                {localize("Beskrivning")}
                                            </Label>
                                            <Label {...captionRegular}
                                                className="information-data"
                                                color="primaryContent"
                                            >
                                                {product.description}
                                            </Label>
                                        </div>
                                    </div>
                                    <div className="product-actions">
                                        <ProductActions
                                            isAdministrator={isAdministrator}
                                            onDeleteClick={onDeleteClicked}
                                            onEditClick={onEditClicked}
                                            productId={product.id} />
                                    </div>
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem>
                            <ProductCampaigns />
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem className="product-posts">
                    <ProductPosts productId={product.id} />
                </StackItem>
            </Stack>
            {formDrawer}
        </div>
    );
}

export default ProductDetailsView;
