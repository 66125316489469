import {
    ContentBlock,
    ContentState,
    convertFromRaw,
    convertToRaw,
    EditorState,
} from "draft-js";
import { generateUuid } from "src/utils";

type IndexList = Array<{ start: number; end: number }>;

export const draftToHtml = (content: ContentState): string => {
    return content.getBlocksAsArray().map((block: ContentBlock): string => {
        let text = block.getText();

        if (!text) {
            return "";
        }

        const entityRanges: IndexList = [];
        block.findEntityRanges(
            meta => !!meta.getEntity(),
            (start, end) => entityRanges.push({ start, end })
        );

        text = entityRanges
            .sort((a, b) => a.start > b.start ? -1 : 1)
            .reduce(
                (acc: string, range) => {
                    const entityKey = block.getEntityAt(range.start);
                    const entity = content.getEntity(entityKey);
                    const { mention } = entity.getData();

                    if (!mention || !mention.link) {
                        return acc;
                    }

                    return [
                        acc.substr(0, range.start),
                        `[link@${mention.link}@link]`,
                        acc.substr(range.start, (range.end - range.start)),
                        "[/link]",
                        acc.substr(range.end)
                    ].join("");
                },
                text
            );

        return text;
    }).join("\n");
};

const bookmarkPattern: RegExp = /((www\.|(http|https)+\:\/\/)[&#95;.a-z0-9-]+\.[a-z0-9\/&#95;:@=.+!?_,##%&()~-]*[^.|\' |\(|?|,| |>|<|;|\)\n])/i;
const mediaCollection = [
    {
        name: "YouTube",
        pattern: /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/i,
        type: 2,
    },
    {
        name: "vimeo",
        pattern: /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/i,
        type: 3,
    },
    {
        name: "svt",
        pattern: /([A-Za-z0-9.-]*\.)?svtplay\.se\/video|klipp\/([^?]*)/i, // TODO: This regex needs some work
        type: 6,
    },
    {
        name: "spotify",
        pattern: /(https:\/\/)(open|play)(\.spotify\.com\/)(album|track|user\/[^/]+\/playlist)\/([a-zA-Z0-9]+)/i,
        type: 7,
    }
]

interface IBookmarkMatch {
    type: number;
    url: string;
}

export function extractBookmarkUrl(content: ContentState): false | IBookmarkMatch {
    const text = content
        .getBlocksAsArray()
        .map((block) => block.getText())
        .join("");

    const match = text.match(bookmarkPattern);
    if (!match) {
        return false;
    }

    const result: IBookmarkMatch = {
        type: 1,
        url: match[0]
    }

    for (const media of mediaCollection) {
        const mediaMatch = text.match(media.pattern);
        if (!mediaMatch) {
            continue;
        }

        result.type = media.type;
        break;
    }

    return result;
}

export interface IMentionTag {
    id: number;
    name: string;
}

const randomIntFromInterval = (min: number, max: number): number =>
    Math.floor(Math.random() * (max - min + 1) + min);

const generateBlockKey = (): string =>
    [...Array(5)].map(_ => {
        let num;
        do {
            num = randomIntFromInterval(48, 90);
            if (58 <= num && num <= 64) {
                continue;
            }
            break;
        } while (true);
        return String.fromCharCode(num);
    }).join("").toLowerCase();

export const addMentionEntity = (editorState: EditorState, mention: IMentionTag): EditorState => {
    const currentContent = editorState.getCurrentContent();

    const rawContent = convertToRaw(currentContent);
    const entityId = generateUuid();
    rawContent.entityMap[entityId] = {
        data: {
            mention: {
                ...mention,
                link: `/goto/1/${mention.id}`,
            },
        },
        mutability: "SEGMENTED",
        type: "mention",
    };

    rawContent.blocks = [
        ...rawContent.blocks,
        {
            data: mention,
            depth: 0,
            entityRanges: [{
                // key: Object.keys(rawContent.entityMap).indexOf(entityId),
                // @ts-ignore
                key: entityId as number,
                length: mention.name.length,
                offset: 0,
            }],
            inlineStyleRanges: [],
            key: generateBlockKey(),
            text: mention.name,
            type: "unstyled",
        }
    ];

    const nextContentState = convertFromRaw(rawContent);
    const nextEditorState = EditorState.createWithContent(nextContentState);

    return nextEditorState;
}
