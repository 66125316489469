import React, { useEffect, useMemo, useRef, useState } from 'react';
import "./FilterButtons.scss";
import { Label, UnstyledButton } from 'src/ui';
import UnreadIndicator from 'src/ui/components/UnreadIndicator/UnreadIndicator';

interface IFilterButton {
    id: number;
    text: string;
    unreadCount: number;
}

interface IProps {
    tabs: IFilterButton[];
    onChange: (index: number) => void;
    initialActiveId: number;
}

interface IState {
    activeId: number;
    isMeasuring: boolean;
    itemWidth: number;
}

const FilterButtons = (props: IProps) => {
    const wrapperRef = useRef<HTMLDivElement>(undefined);

    const [state, setState] = useState<IState>({
        activeId: props.initialActiveId,
        isMeasuring: true,
        itemWidth: 0
    });

    const indicatorStyle = useMemo(() => {
        const index = props.tabs.map(x => x.id).indexOf(state.activeId);

        return {
            left: state.itemWidth * index,
            width: state.itemWidth
        }
    }, [state.itemWidth, state.activeId, props.tabs]);

    useEffect(() => {
        if (!wrapperRef.current) {
            return;
        }
        
        setState((s) => ({
            ...s,
            isMeasuring: false,
            itemWidth: wrapperRef.current.clientWidth / props.tabs.length
        }));
    }, [wrapperRef.current, props.tabs]);

    return (
        <div className="FilterButtons" ref={wrapperRef}>
            <div className="tabs">
                {props.tabs.map((button: IFilterButton) => (
                    <UnstyledButton
                        key={button.id}
                        className="tab"
                        onClick={() => {
                            setState((s: IState) => ({
                                ...s,
                                activeId: button.id
                            }));

                            props.onChange(button.id);
                        }}>
                        <Label
                            size="body-2"
                            color={state.activeId === button.id ?  "dark-grey" : "primaryContent"}
                            weight="medium">
                            {button.text}
                        </Label>
                        {button.unreadCount > 0 && <UnreadIndicator count={button.unreadCount} />}
                    </UnstyledButton>
                ))}
            </div>
            <div className="indicator" style={indicatorStyle}>
                <div className="indicator-inner" />
            </div>
        </div>
    )
}

export default FilterButtons;
