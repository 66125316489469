function fixImageMaps() {
    let maps = document.getElementsByTagName('map');

    for (let i = 0; i < maps.length; i++) {
        // @ts-ignore
        maps[i].img = document.querySelectorAll(`[usemap="#${maps[i].name}"]`)[0];

        // @ts-ignore
        for (const area of maps[i].areas) {
            area.coordArr = area.coords.split(',');
        }
    }

    function resizeMaps() {
        for (let i = 0; i < maps.length; i++) {
            // @ts-ignore
            const scale = maps[i].img.offsetWidth / (maps[i].img.naturalWidth || maps[i].img.width);

            // @ts-ignore
            for (const area of maps[i].areas) {
                area.coords = area.coordArr.map(coord => Math.round(coord * scale)).join(',');
            }
        }
    }

    resizeMaps();
}

export default fixImageMaps;