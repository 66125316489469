import React, { Component, ReactNode } from "react";
import { uniqueId } from "src/utils";
import FormControl from "./FormControl";
import Select, { ISelectProps } from "./Select";

export interface IFormSelectProps<TType> extends ISelectProps<TType> {
    label: string;
}

interface IFormSelectState {
    id: string;
}

class FormSelect<TType> extends Component<IFormSelectProps<TType>, IFormSelectState> {
    constructor(props : IFormSelectProps<TType>) {
        super(props);

        this.state = {
            id: uniqueId("FormSelect")
        };
    }

    public render() : ReactNode {
        const id : string = this.props.id || this.state.id;

        return (
            <div className="FormSelect">
                <FormControl 
                    htmlFor={id}
                    label={this.props.label}
                >
                    <Select 
                        {...this.props}
                        id={id}
                    />
                </FormControl>
            </div>
        )
    }
}

export default FormSelect;