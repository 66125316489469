import { EditorState } from "draft-js";
import { createContext } from "react";

import ComposerViewState, {
    ComposerSize,
    IComposerContentBase,
} from "../IComposerViewState";
import ComposerTypes from "../SocialComposerTypes";

export interface IComposerContext extends ComposerViewState {
    reset(): void;
    resetEditorState(): void;
    setContentState(state: IComposerContentBase, callback?: () => void): void;
    setEditorState(state: EditorState): void;
    setSize(size: ComposerSize): void;
    setType(type: ComposerTypes): void;
}

const defaultContext: IComposerContext = {
    contentState: null,
    editorState: EditorState.createEmpty(),
    size: "default",
    type: "text",

    reset: () => {/* noop */},
    resetEditorState: () => {/* noop */},
    setContentState: () => {/* noop */},
    setEditorState: () => {/* noop */},
    setSize: () => {/* noop */},
    setType: () => {/* noop */},

};

export const ComposerContext = createContext(defaultContext);

export const ComposerContextConsumer = ComposerContext.Consumer;
export const ComposerContextProvider = ComposerContext.Provider;

export default ComposerContext;