import { EditorState } from "draft-js";
import React, { Component, ReactNode } from "react";
import { FillHeight } from "src/ui";

import SocialComposerHeader from "./SocialComposerHeader";
import SocialComposerTypes from "./SocialComposerTypes";

export interface IComposerSaveOptions {
    feedId: number;
    flag: number;
    galleryId: number;
    text: string;
    identity: any;
}

export interface ISocialComposerTypeProps {
    smallScreen?: boolean;
    postHeader?: JSX.Element;
    postFooter?: JSX.Element;
    textInput?: JSX.Element;
    typeSelector?: JSX.Element;
    userHeader?: JSX.Element;

    onTypeChange?: (type: SocialComposerTypes) => void;
    onCloseClicked?: () => void;
}

export interface ISocialComposerType {
    saveContent: (options: IComposerSaveOptions) 
        => Promise<Spintr.ISocialPostBase | null>;

    onTextChanged(editorState: EditorState): void ;
    
    validate?: any;
}

abstract class SocialComposerType<TProps extends ISocialComposerTypeProps, TState> 
    extends Component<TProps, TState> 
    implements ISocialComposerType
{
    public abstract saveContent(options: IComposerSaveOptions): 
        Promise<Spintr.ISocialPostBase | null>;

    public onTextChanged(editorState: EditorState): void {
        /* noop */
    }

    public render(): ReactNode {
        return (
            <div className="type-wrapper">
                {this.renderTextInput()}
            </div>
        );
    }

    protected renderTextInput(): ReactNode {
        return (
            <div className="text-input">
                {this.props.textInput}
            </div>
        )
    }

    protected abstract renderTypeContent(): ReactNode;

    private wrapTypeContent(): ReactNode {
        const typeContent = this.renderTypeContent();

        if (!this.props.smallScreen) {
            return typeContent;
        }

        return (
            <FillHeight offset={-170}>
                {typeContent}
            </FillHeight>
        );
    }
}

export default SocialComposerType;