import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import { PageHeader } from "src/ui";
import { Pivot, PivotItem } from "@fluentui/react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { AxiosResponse } from "axios";
import PayrollDownloadReport from "./PayrollDownloadReport";
import PayrollHome from "./PayrollHome";
import PayrollCategories from "./PayrollCategories";
import PayrollEntries from "./PayrollEntries";
import "./PayrollView.scss";
import { withRouter } from "react-router";
import PayrollEditors from "./PayrollEditors";

interface IProps {
    currentUser?: IActiveUserProfile;
    appEnabled?: boolean;
    history?: any;
}

const PayrollView = (props: IProps) => {
    const [categories, setCategories] = useState([]);
    const [offices, setOffices] = useState([]);

    const fetchCategories = useCallback(() => {
        api.get("/api/v1/payroll/categories?take=1000").then((response: AxiosResponse) => {
            setCategories(response.data.items);
        }).catch(() => {});
    }, []);

    const fetchOffices = useCallback(() => {
        api.get(`/api/v1/units/simple`, {
            params: {
                includeDeleted: false,
                includeCompanies: false
            },
        }).then((response: AxiosResponse) => {
            setOffices(response.data);
        }).catch(() => {});
    }, []);

    useEffect(() => {
        const hasRight = props.currentUser.settings.isPayrollAdministrator ||
            props.currentUser.settings.isPayrollEditor;

        if (!props.appEnabled || !hasRight) {
            props.history.push("/404");
        }

        fetchCategories();
        fetchOffices();
    }, []);

    return (
        <div className="PayrollView">
            <PageHeader title={localize("PAYROLL_STATISTICS")} />
            <Pivot overflowBehavior={"menu"}>
                <PivotItem headerText={localize("Hem")}>
                    <PayrollHome categories={categories} offices={offices} />
                </PivotItem>
                <PivotItem headerText={localize("SALARIES")}>
                    <PayrollEntries categories={categories} offices={offices} />
                </PivotItem>
                {
                    props.currentUser.settings.isPayrollAdministrator && (
                        <PivotItem headerText={localize("Kategorier")}>
                            <PayrollCategories />
                        </PivotItem>
                    )
                }
                {
                    props.currentUser.settings.isPayrollAdministrator && (
                        <PivotItem headerText={localize("REPORTERS")}>
                            <PayrollEditors />
                        </PivotItem>
                    )
                }
                {
                    props.currentUser.settings.isPayrollAdministrator && (
                        <PivotItem headerText={localize("DOWNLOAD_REPORT")}>
                            <PayrollDownloadReport categories={categories} />
                        </PivotItem>
                    )
                }
            </Pivot>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    ...props,
    currentUser: state.profile.active,
    appEnabled: state.instance.get("enablePayroll")
});

export default withRouter(connect(mapStateToProps)(PayrollView));
