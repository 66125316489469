import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { Action } from "redux";
import { setAppMode } from "src/ui/actions";

interface IMatchParams {
    url: string;
}

interface IProps {
    dispatch: (action: Action) => void;
}

type Props = IProps & RouteComponentProps<IMatchParams>;

class WebViewView extends Component<Props> {
    constructor(props) {
        super(props);

        this.props.dispatch(setAppMode(true));
    }

    render() {
        const url =
            this.props.match.params.url.charAt(0) != "/"
                ? "/" + this.props.match.params.url
                : this.props.match.params.url;
        return <Redirect to={url} />;
    }
}

export default connect()(WebViewView);
