import React, { Component, ReactNode } from 'react';
import { Route, Switch } from 'react-router';
import BlogsCatalogueView from './BlogsCatalogueView';

interface IBlogsRootView {
}

class BlogsRootView extends Component<IBlogsRootView> {
    
    public render() : ReactNode {
        return (
            <div className="BlogsRootView">
                <Switch>
                    <Route
                        exact={true}
                        path=""
                        render={this.renderComponent.bind(this, BlogsCatalogueView)} />
                </Switch>
            </div>
        );
    }

    public renderComponent(RoutedComponent : any, props : any = {}) : ReactNode {
        return (
            <RoutedComponent
                {...props} />
        )
    }
}

export default BlogsRootView
