import React, { FunctionComponent } from "react";
import { localize, localizeFormat } from "src/l10n";
import { Label } from "src/ui";

interface IProps {
    assistantName?: string;
}

const AssistantTypingLoader: FunctionComponent<IProps> = ({ assistantName }) => (
    <div className="AssistantTypingLoader">
        <div className="loader-image">
            {/* <img
                alt={localize("Laddar")}
                height={23}
                src="/Style/Images/spintr-assistant-loader.gif"
                width={45}
            /> */}
            <div className="css-chat-loader">
                <div className="large-bubble">
                    <svg className="chat-dots" xmlns="http://www.w3.org/2000/svg" width="26" height="10" viewBox="0 0 26 10" fill="none">
                        <rect x="0"  y="4" width="6" height="6" rx="3" fill="#A7A7B5"/>
                        <rect x="10" y="4" width="6" height="6" rx="3" fill="#A7A7B5"/>
                        <rect x="20" y="4" width="6" height="6" rx="3" fill="#A7A7B5"/>
                    </svg>
                </div>
                <div className="medium-bubble"></div>
                <div className="small-bubble"></div>
            </div>
        </div>
        {assistantName && (
            <Label
                className="loader.text"
                size="body-3"
                color="mid-grey"
            >
                {localizeFormat("PERSON_IS_TYPING_F", assistantName)}
            </Label>
        )}
    </div>
);

export default AssistantTypingLoader;
