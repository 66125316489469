import { DefaultButton, IColor, Modal, PrimaryButton, Separator, Stack } from "@fluentui/react";
import React, { Component } from "react";
import { localize } from "src/l10n";
import ColorPicker from "src/ui/components/ColorPicker/ColorPicker";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import PopupHeader from "src/ui/components/PopupHeader";

interface IProps {
    calendar: Spintr.ICurrentUserCalendar;
    save: (color: string) => void;
    dismiss: () => void;
}

interface IState {
    activeColor: string;
}

class CalendarColorModal extends Component<IProps, IState> {
    protected onChangeColor = (ev, val: IColor) => {
        this.setState({
            activeColor: val.str,
        });
    };

    protected onSave = () => {
        this.props.save(this.state.activeColor);
        this.props.dismiss();
    };

    public render() {
        const { calendar } = this.props;
        const color = calendar.color ? "#" + calendar.color : "";

        return (
            <Modal isOpen={true} className="spintr-modal modalWithPopupHeader">
                <PopupHeader
                    text={localize("ValjFargFor") + " " + calendar.name}
                    onClose={this.props.dismiss} />
                <ColorPicker color={color} onChangeColor={this.onChangeColor} small />
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                    <DefaultButton onClick={this.props.dismiss} text={localize("Avbryt")} />
                    <PrimaryButton onClick={this.onSave} text={localize("Spara")} />
                </Stack>
            </Modal>
        );
    }
}

export default CalendarColorModal;
