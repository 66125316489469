import React, { Component, ReactNode } from "react";
import { uniqueId } from "src/utils";
import FormControl from "./FormControl";
import Textbox, { ITextboxProps } from "./Textbox";

interface IFormTextboxProps extends ITextboxProps {
    label?: string;
}

interface IFormTextboxState {
    id: string;
}

class FormTextbox extends Component<IFormTextboxProps, IFormTextboxState> {
    constructor(props : IFormTextboxProps) {
        super(props);

        this.state = {
            id: uniqueId("FormTextbox")
        };
    }

    public render() : ReactNode {
        const id : string = this.props.id || this.state.id;

        return (
            <div className="FormTextbox">
                <FormControl
                    label={this.props.label}
                    htmlFor={id}
                >
                    <Textbox 
                        {...this.props} 
                        id={id}
                    />
                </FormControl>
            </div>
        );
    }
}

export default FormTextbox;