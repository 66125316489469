import { createContext } from "react";
import { ILocalizationDictionary } from "src/marketplace/types";

type LocalizationProvider = (text: string) => string;

interface IAppPageContext {
    appId: string;
    appName: string;
    appLogoUrl: string;
    appTitle?: string;
    appHeaderUrl?: string;
    appUrl?: string;
    text?: ILocalizationDictionary;

    localize: LocalizationProvider;
}

const defaultContext: IAppPageContext = {
    appId: "",
    appLogoUrl: "",
    appName: "Sample App",

    localize: str => str,
};

const AppPageContext = createContext(defaultContext);

export const AppPageContextConsumer = AppPageContext.Consumer;
export const AppPageContextProvider = AppPageContext.Provider;

export default AppPageContext;
