import React, { Component, RefObject } from 'react';
import throttle from '../helpers/throttle';
import ScrollableArea from './ScrollableArea';

const thresholdValue = 20

interface IProps {
    height?: number;
    needsFastScrollHandler?: boolean;
    onScroll?: () => void;
    tabIndex?: number;
    width?: number;
}

class ScrollableAreaContainer extends Component<IProps> {
    protected hasMounted: boolean = false;
    protected scrollarea: RefObject<ScrollableArea>;

    constructor(props: IProps) {
        super(props);

        this.scrollarea = React.createRef();

        // @ts-ignore
        this.handleScroll = () => {
            let props = this.props
            // @ts-ignore
            props.onScroll && props.onScroll.apply(props, arguments)
        }
    }

    public componentDidMount() {
        this.hasMounted = true
        // @ts-ignore
        this.throttledScrollHandler = throttle(this.handleScroll, 50)
    }

    public componentWillUnmount() {
        // @ts-ignore
        this.hasMounted = false
    }

    public getArea() {
        return this.scrollarea.current.getArea();
    }

    public isScrolledToBottom() {
        return this.isScrolledToBottomWithHeight(0)
    }

    public isScrolledToBottomWithHeight(height: number) {
        const area = this.getArea()
        if (!area) {
            return false
        }

        return (
            area.getScrollTop() 
            + area.getClientHeight() 
            + height >= area.getScrollHeight() 
            - thresholdValue
        );
    }

    public render() {
        const classNames: string[] = ["ScrollableAreaContainer"];
        
        // @ts-ignore
        if (this.props.className) {
            // @ts-ignore
            classNames.push(this.props.className);
        }

        const attrs: any = {
            className: classNames.join(" "),
            onScroll: this.props.needsFastScrollHandler
                // @ts-ignore
                ? this.handleScroll
                // @ts-ignore
                : this.throttledScrollHandler,
            ref: this.scrollarea,
            shadow: false,
        };

        if (this.props.height) {
            attrs.height = this.props.height;
        }

        if (this.props.width) {
            attrs.width = this.props.width;
        }

        if (this.props.tabIndex) {
            attrs.tabIndex = this.props.tabIndex;
        }

        return (
            <ScrollableArea {...attrs}>
                {this.props.children}
            </ScrollableArea>
        )
    }

    public scrollToPosition(position, useAnimation = false, options = {}) {
        const area = this.getArea()
        if (!area) {
            return;
        }

        area.setScrollTop(position, useAnimation, options)
    }

    public scrollToBottom() {
        const area = this.getArea()
        if (!area) {
            return
        }
        return this.scrollToPosition(area.getScrollHeight())
    }
}

export default ScrollableAreaContainer
