import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProps extends RouteComponentProps {}

interface IState {
    isNavigating: boolean;
}

class MobileAppBridge extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isNavigating: false
        };
    }

    messagePrefix = "AppNav:";

    handleMessage = (e: any) => {
        if (!e ||
            !e.data ||
            !e.data.indexOf ||
            e.data.indexOf(this.messagePrefix) !== 0) {
            return;
        }

        const path = e.data.substring(this.messagePrefix.length);

        if (path === "ping" &&
            //@ts-ignore
            window.ReactNativeWebView) {
            //@ts-ignore
            window.ReactNativeWebView.postMessage("pong");
            return;
        }

        if (path === this.props.location.pathname) {
            return;
        }

        this.props.history.push({
            pathname: path
        });
    };

    componentDidMount(): void {
        window.addEventListener("message", this.handleMessage.bind(this));
    }

    componentWillUnmount(): void {
        window.removeEventListener("message", this.handleMessage.bind(this));
    }

    render () {
        return null;
    }
}

export default withRouter(MobileAppBridge);
