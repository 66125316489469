import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import Birthdays from "src/ui/components/Birthdays/Birthdays";

const BirthdaysWrapper: FunctionComponent = () => {
    const userId = useSelector<Spintr.AppState, number>(
        (state) => state.profile.active.id,
    )
    return (
        <div className="BirthdaysWrapper">
            <Birthdays
                disableButtons={false}
                hideTitle={false}
                userId={userId}
            />
        </div>
    );
};

export default BirthdaysWrapper;
