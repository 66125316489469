import { TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { Loader, PageHeader } from 'src/ui';
import CustomDialog from 'src/ui/components/Dialogs/CustomDialog';
import { FormFooterBar } from 'src/ui/components/Forms';
import ErrorMessagebar from 'src/ui/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/ui/components/Messagebars/SuccessMessagebar';
import { Style } from 'src/ui/helpers';
import api from 'src/spintr/SpintrApi';

interface IPageRouteParams {
    path: string;
    id: any;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    instance?: any;
    currentUser?: any;
}

interface IState {
    isLoading: boolean;
    name?: string;
    enableFieldValidation?: boolean;
    saveError?: any;
    saveSuccess?: boolean;
    displayReloadDialog?: boolean;
}

class SuppliersSettingsView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            api.get(`/api/suppliers/settings`).then((response) => {
                this.setState({
                    name: response.data ? response.data : "Foretagskatalogen",
                    isLoading: false
                });
            });
        });
    }

    goBack() {
        this.props.history.push({
            pathname: `/suppliers`,
        });
    }

    onCancelClick() {
        this.goBack();
    }

    onSaveClick() {
        this.setState({
            isLoading: true
        }, () => {
            api.post("/api/suppliers/settings", {
                name: this.state.name ?
                    this.state.name :
                    "Foretagskatalogen"
            }).then(() => {
                this.setState({
                    saveSuccess: true,
                    saveError: null,
                    isLoading: false,
                    displayReloadDialog: true
                });
            }).catch(this.handleCatch.bind(this));
        });
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    render() {
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        return (
            <div className="SuppliersView">
                {this.state.saveSuccess && (
                    <div style={{
                        marginBottom: Style.getSpacing(3)
                    }}>
                        <SuccessMessagebar
                            message={localize("SparningenLyckades")}
                            onDismiss={() => {
                                this.setState({
                                    saveSuccess: false
                                });
                            }}
                        />
                    </div>
                )}
                {!!this.state.saveError && this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PageHeader title={localize("Installningar")} displayMandatoryText />
                <form>
                    <TextField
                        value={this.state.name}
                        className="textField"
                        label={localize("Namn")}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                            this.setState({
                                name: newValue
                            });
                        }}
                    />
                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize("Spara")}
                    />
                    <CustomDialog
                        message={localize("LaddarOmForAttSeAndringar")}
                        show={this.state.displayReloadDialog}
                        confirmMessage={localize("RELOAD")}
                        onDismiss={() => {
                            this.setState({
                                displayReloadDialog: false
                            });
                        }}
                        onConfirm={() => {
                            window.location.reload();
                        }} />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersSettingsView));
