import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { Breadcrumbs } from 'src/ui';

interface IProps {
    appName?: string;
    item?: any;
}

class SuppliersBreadcrumbs extends Component<IProps> {
    getBreadcrumbs() {
        let items = [
            {
                text: !!this.props.appName ? localize(this.props.appName) : localize("Foretagskatalogen"),
                key: 5,
                link: "/suppliers",
            },
        ];

        if (window.location.pathname === "/suppliers/categories") {
            items.push({
                text: localize("Kategorier"),
                key: 55,
                link: "/suppliers/categories"
            });
        } else if (window.location.pathname === "/suppliers/filecategories") {
            items.push({
                text: localize("Filkategorier"),
                key: 555,
                link: "/suppliers/filecategories"
            });
        } else if (window.location.pathname === "/suppliers/product-codes") {
            items.push({
                text: localize("Produktkoder"),
                key: 5555,
                link: "/suppliers/product-codes"
            });
        } else if (window.location.pathname === "/suppliers/contactpersons") {
            items.push({
                text: localize("Kontaktpersoner"),
                key: 55555,
                link: "/suppliers/contactpersons"
            });
        } else if (window.location.pathname === "/suppliers/roles") {
            items.push({
                text: localize("Roller"),
                key: 55555,
                link: "/suppliers/roles"
            });
        } else if (window.location.pathname === "/suppliers/groups") {
            items.push({
                text: localize("SUPPLIER_GROUPS"),
                key: 555555,
                link: "/suppliers/roles"
            });
        } else if (window.location.pathname === "/suppliers/messages") {
            items.push({
                text: localize("Meddelanden"),
                key: 5555555,
                link: "/suppliers/roles"
            });
        } else if (window.location.pathname === "/suppliers/files") {
            items.push({
                text: localize("SUPPLIER_FILES"),
                key: 55555555,
                link: "/suppliers/roles"
            });
        }

        if (!!this.props.item &&
            !!this.props.item.name &&
            !!this.props.item.id) {
            items.push({
                text: this.props.item.name,
                key: 55555,
                link: "/suppliers/" + this.props.item.id,
            });
        }

        return items;
    }

    render(): ReactNode {
        const breadcrumbs = this.getBreadcrumbs();

        return (
            <Breadcrumbs
                displayInstance
                items={breadcrumbs}
            />
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    appName: state.instance.get("suppliersAppName")
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersBreadcrumbs));
