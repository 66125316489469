import {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import MarketplaceDataLoader from "src/marketplace/utils/data-loader";
import { MarketplaceWidgetContext } from "../MarketplaceWidgetContext";

interface IProps {
    id: string;
    // TODO: Add fetch interval
}

interface IStateProps {
    url: string;
    token: string;
}

const MarketplaceWidgetLoader: FunctionComponent<IProps> = ({ id }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setIntervalId] = useState(0);
    const context = useContext(MarketplaceWidgetContext);

    const stateProps = useSelector<Spintr.AppState, IStateProps>(
        (state) => ({
            token: state.profile.active.crocusKey,
            url: state.instance.get("crocusAddress") as string,
        }),
    );

    const load = useCallback(async () => {
        try {
            const result = await MarketplaceDataLoader.load(id, stateProps.url, stateProps.token);

            context.onDataLoaded(result);
        } catch (e) {
            console.log(e);
        }
    }, [id, context, stateProps.url, stateProps.token]);

    useEffect(() => {
        let interval: number | undefined;

        setIntervalId((currentInterval) => {
            if (currentInterval) {
                window.clearInterval(currentInterval);
            }

            interval = window.setInterval(load, 2 * 60 * 1000);

            return interval;
        });

        load();

        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    }, [load]);

    return null;
}

export default MarketplaceWidgetLoader;

