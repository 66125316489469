function shortenString(text : string, maxLength : number, ending : string = "") : string {
    if (!text || maxLength < 1) {
        return "";
    }

    if (text.length <= maxLength) {
        return text;
    }

    const trimmedString : string = text.substr(0, maxLength);
    if (trimmedString.indexOf(" ") === -1) {
        return trimmedString + ending;
    }

    return trimmedString.substr(
        0,
        Math.min(
            trimmedString.length,
            trimmedString.lastIndexOf(" ")
        )
    ) + ending;
}

export default shortenString;