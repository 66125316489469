import { Stack, StackItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { hideObject } from "src/spintr/actions";
import { ActionMenu, Label } from "src/ui";
import "./SocialHidden.scss";

interface ISocialImageProps {
    post: Spintr.ISocialPostBase;
    dispatch: (action: Action) => void;
}

interface ISocialImageState {}

class SocialHidden extends Component<ISocialImageProps, ISocialImageState> {
    constructor(props: ISocialImageProps) {
        super(props);
    }

    public render(): ReactNode {
        return (
            <Stack horizontal verticalAlign="center" className="social-hidden">
                <StackItem grow={1}>
                    <Label size="body-2" color="mid-grey">
                        {localize("DoldPostAv")} {this.props.post.UserName}
                    </Label>
                </StackItem>
                <ActionMenu
                    categories={[
                        {
                            title: localize("Redaktor"),
                            items: [
                                {
                                    text: localize("Visa"),
                                    onClick: () => {
                                        this.props.dispatch(hideObject(this.props.post.Id));
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </Stack>
        );
    }
}

export default connect()(SocialHidden);
