import { PrimaryButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { Label, Loader, PasswordStrengthMeter } from "src/ui";
import SplitAuth from "../splitauth/SplitAuth";
import api from "src/spintr/SpintrApi";
import { localize } from "src/l10n";
import { getQueryStringMap } from "src/utils";
import "./PasswordRecovery.scss";
import { IApplicationState } from "src/spintr/reducer";

interface IProps extends RouteComponentProps {
    dispatch?: (Action) => void;
}

interface IState {
    newPassword: string;
    newPassword2: string;
    isLoading: boolean;
    error?: string;
    passwordChanged?: boolean;
    code: string;
    codeError?: string;
}

class PasswordRecovery extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const qs = getQueryStringMap(this.props.location);

        this.state = {
            newPassword: "",
            newPassword2: "",
            isLoading: true,
            code: qs.code,
        };

        this.verifyCode(qs.code);
    }

    private verifyCode = (code) => {
        api.get("/api/v1/authentication/verifycode", { params: { code } })
            .then((response) => {
                this.setState({
                    isLoading: false,
                });
            })
            .catch((error) => {
                const data = error.response.data;

                const errorText =
                    data === "UserNotFound"
                        ? "Invalid code"
                        : data === "PasswordRecoveryNotEnabled"
                        ? "Not enabled"
                        : data === "CodeExpired"
                        ? "Code Expired"
                        : localize("TeknisktFel");

                this.setState({
                    isLoading: false,
                    codeError: errorText,
                });
            });
    };

    private onChange = (ev, val) => {
        const name = ev.target.name;

        // @ts-ignore
        this.setState({
            [name]: val,
        });
    };

    private onSubmit = async (ev) => {
        this.setState({
            isLoading: true,
        });

        api.post("/api/v1/authentication/password", {
            password: this.state.newPassword,
            token: this.state.code,
        })
            .then((response) => {
                this.setState({
                    isLoading: false,
                    passwordChanged: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: localize("TeknisktFel"),
                    passwordChanged: true,
                });
            });
    };

    public render() {
        const { codeError, error, isLoading, newPassword, newPassword2, passwordChanged } = this.state;

        const shortPassword = newPassword.length < 8;
        const passwordsMatch = newPassword === newPassword2;

        return (
            <div>
                <div className="password-recovery-view">
                    <SplitAuth error={error} backgroundUrl="/images/forgotpassword.jpg">
                        <>
                            <Label as="h2" size="h2" weight="medium" className="forgot-password-label">
                                {localize("GlomtLosenord")}
                            </Label>

                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {!error && (
                                        <Label size="body-1" className="forgot-password-subtext-label">
                                            {localize(
                                                codeError ??
                                                    (passwordChanged
                                                        ? "PASSWORD_RECOVERY_SUCCESS"
                                                        : "PASSWORD_RESET_CHOOSE_PASSWORD")
                                            )}
                                        </Label>
                                    )}

                                    {passwordChanged || codeError ? (
                                        <>
                                            <PrimaryButton
                                                text={localize("GO_TO_LOGIN")}
                                                onClick={() => this.props.history.push("/sign-in")}
                                                className="next-button"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <TextField
                                                type={"password"}
                                                label={localize("Password")}
                                                value={newPassword}
                                                name="newPassword"
                                                onChange={this.onChange}
                                            />
                                            <div className="strength-meter">
                                                <PasswordStrengthMeter password={newPassword} />
                                            </div>

                                            <TextField
                                                type={"password"}
                                                label={localize("ConfirmPassword")}
                                                value={newPassword2}
                                                name="newPassword2"
                                                onChange={this.onChange}
                                            />

                                            <PrimaryButton
                                                text={localize("Skicka") + "!"}
                                                disabled={shortPassword || !passwordsMatch}
                                                onClick={this.onSubmit}
                                                className="next-button"
                                                type="submit"
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </SplitAuth>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: any) => {
    return {
        ...props,
    };
};

const ConnectedPasswordRecovery = connect(mapStateToProps)(PasswordRecovery);
const ConnectedPasswordRecoveryWithRouter = withRouter(ConnectedPasswordRecovery);

export default ConnectedPasswordRecoveryWithRouter;
