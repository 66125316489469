import React from "react";
import { ReactSVG } from "react-svg";
import { Label, UnstyledButton } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import "./RainbowButton.scss";

interface IProps {
    text: string;
    onClick: (data: any) => any;
    onClickData: any;
    isLoading: boolean;
    isEnabled: boolean;
}

const RainbowButton = (props: IProps) => {
    return (
        <UnstyledButton
            className={["RainbowButton", ...(props.isEnabled ? ["enabled"] : [])].join(" ")}
            disabled={!props.isEnabled || props.isLoading}
            onClick={props.onClick}
            onClickData={props.onClickData}>
            <div className="RainbowButton-inner">
                <Label size="body-2" weight="medium" color="grey">{props.text}</Label>
                {!props.isLoading && <ReactSVG className="icon" src={`/images/${props.isEnabled ? "stars-fill.svg" : "stars-outline.svg"}`} />}
                {props.isLoading && <SpintrLoader loaderSize="small" />}
            </div>
        </UnstyledButton>
    )
}

export default RainbowButton;
