import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import React, { ChangeEvent, FunctionComponent, useCallback } from "react";
import { localize } from "src/l10n";
import { FormControl, FormSection } from "src/ui/components/Forms";

interface IProps {
    variable: Spintr.IMarketplaceVariableSetup;
    onChange(variable: Spintr.IMarketplaceVariableSetup): void;
}

const dataTypes: IDropdownOption[] = [
    { id: "string", text: "Text", key: "string" },
    { id: "number", text: "NUMBER", key: "number" },
    { id: "date", text: "Datum", key: "date" },
];

const VariableForm: FunctionComponent<IProps> = ({ onChange, variable }) => {
    const textChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        onChange({
            ...variable,
            [name]: value
        });
    }, [variable, onChange]);

    const typeChanged = useCallback((_: any, option: IDropdownOption) => {
        onChange({
            ...variable,
            dataType: option.key as Spintr.MarketplaceVariableDataType,
        })
    }, [variable, onChange]);

    const getDataTypes = () => {
        return dataTypes.map((dt: any) => {
            return {
                ...dt,
                text: localize(dt.text)
            }
        })
    }

    return (
        <FormSection key={variable.variableName} title={variable.variableName}>
            <FormControl>
                <TextField
                    label={localize("Namn")}
                    name="name"
                    onChange={textChanged}
                    value={variable.name}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label={localize("Beskrivning")}
                    name="description"
                    onChange={textChanged}
                    value={variable.description}
                />
            </FormControl>
            <FormControl>
                <Dropdown
                    label={localize("DATA_TYPE")}
                    options={getDataTypes()}
                    onChange={typeChanged}
                    selectedKey={variable.dataType}
                />
            </FormControl>
        </FormSection>
    );
};

export default VariableForm;
