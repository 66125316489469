import React, { CSSProperties, MouseEvent, ReactElement, useCallback, useMemo } from "react";
import { DrawerSize, DrawerProps as Props } from "./Drawer.types";
import classNames from "classnames";
import { Portal } from "src/components/Portal";

const translateMap: { [key in DrawerSize]: string } = {
    full: "100%",
    large: "940px",
    medium: "592px",
    small: "320px",
};

function getMountingStyle(position: Props["position"]): CSSProperties {
    switch (position) {
        default:
        case "start":
            return { left: 0, right: "auto" };

        case "end":
            return { left: "auto", right: 0 };
    };
}

function getSizeStyle(size: Props["size"]): CSSProperties {
    return { width: translateMap[size || "small"] };
}

function getOpenStyle(props: Props): CSSProperties {
    if (props.open) {
        return {};
    }

    const size = props.size || "small"
    const translate = translateMap[size];

    return {
        transform: `translate3d(${translate}, 0, 0)`,
    };
}

function getContentStyle(props: Props): CSSProperties {
    return {
        ...getMountingStyle(props.position),
        ...getSizeStyle(props.size),
        ...getOpenStyle(props),
    }
}

function Drawer(props: Props): ReactElement {
    const { children, onOpenChange } = props;

    const contentStyle = useMemo(
        () => getContentStyle(props),
        [props],
    );

    const overlayStyle = useMemo(
        () => props.open ? undefined : { opacity: 0, display: "none" },
        [props.open],
    );

    const onOverlayClicked = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (event.preventDefault) {
                event.preventDefault();
            }

            onOpenChange?.(false);
        },
        [onOpenChange],
    );
    
    if (!props.open) {
        return null;
    }

    return (
        <Portal>
            <div className={classNames("Drawer", props.className, { open: props.open })}>
                <div role="dialog">
                    <div role="document">
                        <div
                            aria-hidden="true"
                            className="Drawer-overlay"
                            onClick={onOverlayClicked}
                            role="presentation"
                            style={overlayStyle} />
                        <div
                            className="Drawer-content"
                            role="dialog"
                            style={contentStyle}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
}

export default Drawer;
