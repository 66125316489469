import { PlannerAction, PlannerActionTypes } from "../types";

export type PlannerState = {
    itemsById:          { [id: number]: Spintr.PlannerItem };
    allItemIds:         number[];

    categoriesById:     { [id: string]: Spintr.PlannerItemCategory };
    allCategoryIds:     string[];
    categoriesLoading:  boolean;
    
    queryItemIds:       number[];
    queryPending:       boolean;
    queryResultCount:   number;
};

const initialState: PlannerState = {
    itemsById:          {},
    allItemIds:         [],

    categoriesById:     {},
    allCategoryIds:     [],
    categoriesLoading:  false,

    queryItemIds:       [],
    queryPending:       false,
    queryResultCount:   0,
};

export function plannerReducer(state: PlannerState = initialState, action: PlannerAction): PlannerState {
    switch (action.type) {
        case PlannerActionTypes.PlannerItemCreated:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.item.id]: action.item,
                },
                allItemIds: state.allItemIds.every(id => id !== action.item.id)
                    ? [...state.allItemIds, action.item.id]
                    : state.allItemIds,

                allCategoryIds: state.allCategoryIds.every(id => id !== action.item.category.id)
                    ? [...state.allCategoryIds, action.item.category.id]
                    : state.allCategoryIds,

                categoriesById: {
                    ...state.categoriesById,
                    [action.item.category.id]: action.item.category,
                },
            };

        case PlannerActionTypes.PlannerItemDeleted:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.id]: undefined,
                },
                allItemIds: state.allItemIds.filter(id => id !== action.id),
                queryItemIds: state.queryItemIds.filter(id => id !== action.id),
            };

        case PlannerActionTypes.PlannerItemUpdated:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.item.id]: action.item,
                },
                allItemIds: state.allItemIds.every(id => id !== action.item.id)
                    ? [...state.allItemIds, action.item.id]
                    : state.allItemIds,

                allCategoryIds: state.allCategoryIds.every(id => id !== action.item.category.id)
                    ? [...state.allCategoryIds, action.item.category.id]
                    : state.allCategoryIds,
                categoriesById: {
                    ...state.categoriesById,
                    [action.item.category.id]: action.item.category,
                },
            };

        case PlannerActionTypes.PlannerFileAdded:
            return !state.itemsById[action.file.itemId] ? state : {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.file.itemId]: {
                        ...state.itemsById[action.file.itemId],
                        files: [...state.itemsById[action.file.itemId].files, action.file],
                    },
                },
            };

        case PlannerActionTypes.FindPlannerItemFulfilled:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.payload.id]: action.payload,
                },
                allItemIds: state.allItemIds.every(id => id !== action.payload.id)
                    ? [...state.allItemIds, action.payload.id]
                    : state.allItemIds,

                allCategoryIds: state.allCategoryIds.every(id => id !== action.payload.category.id)
                    ? [...state.allCategoryIds, action.payload.category.id]
                    : state.allCategoryIds,
                categoriesById: {
                    ...state.categoriesById,
                    [action.payload.category.id]: action.payload.category,
                },
            };

        case PlannerActionTypes.PlannerCategoryQueryPending:
            return {
                ...state,
                categoriesLoading: true,
            };

        case PlannerActionTypes.PlannerCategoryQueryRejected:
            return {
                ...state,
                categoriesLoading: false,
            };

        case PlannerActionTypes.PlannerCategoryQueryFulfilled:
            return {
                ...state,
                categoriesLoading: false,
                allCategoryIds: action.payload.items.map((item) => item.id),
                categoriesById: action.payload.items.reduce((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, { ...state.categoriesById }),
            };

        case PlannerActionTypes.PlannerItemQueryPending:
            return {
                ...state,
                queryPending: true,
            };

        case PlannerActionTypes.PlannerItemQueryRejected:
            return {
                ...state,
                queryPending: false,
            };

        case PlannerActionTypes.PlannerItemQueryFulfilled:
            const updatedItems = action.payload.items.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, { ...state.itemsById});

            return {
                ...state,
                itemsById: updatedItems,
                allItemIds: Object.keys(updatedItems).map((id) => parseInt(id, 10)),
                queryItemIds: action.payload.items.map((item) => item.id),
                queryPending: false,
                queryResultCount: action.payload.totalCount,
                allCategoryIds: action.payload.items.reduce((acc, item) => {
                    if (acc.every(id => id !== item.category.id)) {
                        acc.push(item.category.id);
                    }
                    return acc;
                }, [...state.allCategoryIds]),
                categoriesById: action.payload.items.reduce((acc, item) => {
                    acc[item.category.id] = item.category;
                    return acc;
                }, { ...state.categoriesById }),
            }

        default:
            return state;
    }
}
