import {
    Dropdown,
    Icon,
    IDropdownOption,
    Image,
    Pivot,
    PivotItem,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import React, { ChangeEvent, Component, Fragment, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Subject, Subscription, timer } from "rxjs";
import { debounce } from "rxjs/operators";
import { localize } from "src/l10n";
import {
    fetchRawWidgetData,
    findMarketplaceApp,
    saveWidget,
    validateDsl
} from "src/marketplace/api";
import {
    MarketplaceWidget, MarketplaceWidgetResource, VariableForm
} from "src/marketplace/components";
import { HttpMethod, IExtraResource } from "src/marketplace/types";
import { extractVariableNames, getSampleWidgetData } from "src/marketplace/utils";
import { SpintrTypes } from "src/typings";
import { BackgroundImage, Label, Loader, UnstyledButton } from "src/ui";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import { uniqueId } from "src/utils";
import { IValidationResult } from "src/utils/validation";
import "./MarketplaceWidgetFormView.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IParams {
    appId: string;
}

interface IProps extends RouteComponentProps<IParams> { }

interface IWidgetModel {
    name: string;
    description: string;
    resourceUrl: string;
    dsl: string;
    type: any;
    variables: Spintr.IMarketplaceVariableSetup[];
    method: HttpMethod;
    content: string;
}

interface IState {
    app: Spintr.IMarketplaceAppResponse | null;
    dslIsValid: boolean;
    isLoadingApp: boolean;
    isSaving: boolean;
    isValidatingDsl: boolean;
    model: IWidgetModel;
    redirectTo: string | null;
    sampleData: object;
    parsedData: any;
    validationErrors: IValidationResult;
    variableNames: string[];
    extraResources: IExtraResource[];
}

class MarketplaceWidgetFormView extends Component<IProps, IState> {
    private inputStream: Subject<string>;
    private inputSubscriber: Subscription;
    private readonly typeOptions: IDropdownOption[];

    constructor(props: IProps) {
        super(props);

        this.state = {
            app: null,
            dslIsValid: false,
            extraResources: [],
            isLoadingApp: true,
            isSaving: false,
            isValidatingDsl: false,
            model: {
                content: "",
                description: "",
                dsl: "",
                method: "GET",
                name: "",
                resourceUrl: "",
                type: 1,
                variables: [],
            },
            parsedData: null,
            redirectTo: null,
            sampleData: null,
            validationErrors: {},
            variableNames: [],
        };

        this.typeOptions = [
            { id: "1", isSelected: false, text: localize("WIDGET_LINE_KPI"), key: "WIDGET_TYPE_1" },
            { id: "2", isSelected: true, text: localize("WIDGET_LINE_GRAPH"), key: "WIDGET_TYPE_2" },
            { id: "3", text: localize("WIDGET_BAR_GRAPH"), key: "WIDGET_TYPE_3" },
            { id: "4", text: localize("WIDGET_PIE_GRAPH"), key: "WIDGET_TYPE_4" },
            // { id: "5", text: localize("WIDGET_CIRCLE_GRAPH"), key: "WIDGET_TYPE_5" },
            { id: "8", text: localize("WIDGET_GAUGE"), key: "WIDGET_TYPE_6" }
        ];

        this.onDslUpdated = this.onDslUpdated.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onGetDataClicked = this.onGetDataClicked.bind(this);
        this.onVariableChanged = this.onVariableChanged.bind(this);
        this.onMethodChange = this.onMethodChange.bind(this);
        this.onResourceChanged = this.onResourceChanged.bind(this);
        this.onAddResourceClick = this.onAddResourceClick.bind(this);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);

        this.inputStream = new Subject<string>();
        this.inputSubscriber = this.inputStream
            .pipe(debounce(() => timer(500)))
            .subscribe(this.onDslUpdated);
    }

    public componentDidMount(): void {
        if (this.state.app !== null) {
            this.setState({ isLoadingApp: false });
            return;
        }

        findMarketplaceApp(this.props.match.params.appId).then(
            app => {
                this.setState({
                    app,
                    isLoadingApp: false
                });
            },
            reason => {
                console.log(reason);

                this.setState({ redirectTo: "/marketplace" });
            }
        );
    }

    public componentWillUnmount() {
        this.inputSubscriber.unsubscribe();
        this.inputStream.unsubscribe();
    }

    public onDslUpdated(): void {
        const dsl = this.state.model.dsl.trim();
        if (dsl.length < 1) {
            return;
        }

        this.setState(
            { isValidatingDsl: true },
            async () => {
                try {
                    const response = await validateDsl(
                        this.state.model.dsl,
                        this.state.sampleData
                            ? JSON.stringify(this.state.sampleData)
                            : undefined
                    );

                    let jsonData: object | null;
                    try {
                        jsonData = JSON.parse(response.sampleData);
                    } catch (err) {
                        jsonData = null;
                    }

                    this.setState({
                        dslIsValid: response.success,
                        isValidatingDsl: false,
                        parsedData: jsonData
                    });
                } catch (_) {
                    this.setState({
                        dslIsValid: false,
                        isValidatingDsl: false,
                    });
                }
            }
        );
    }

    public onGetDataClicked(): void {
        if (!this.state.app) {
            return;
        }

        if (!this.state.model.resourceUrl) {
            return;
        }

        const promise = fetchRawWidgetData(
            this.state.app.id,
            this.state.model.resourceUrl,
            this.state.model.method,
            this.state.model.content,
            JSON.stringify({
                resources: this.state.extraResources,
            }),
        );

        promise.then(
            sampleData => this.setState({ sampleData }),
            reason => console.log(reason),
        );
    }

    public onChange(event: ChangeEvent<HTMLInputElement>): void {
        const { name, value } = event.target;

        const model = {
            ...this.state.model,
            [name]: value
        };

        const shouldValidate = model.dsl.trim() !== this.state.model.dsl.trim();

        const variableNames = extractVariableNames(model.resourceUrl || "");
        extractVariableNames(model.dsl || "").forEach(
            (variable) => {
                if (variableNames.every((v) => v !== variable)) {
                    variableNames.push(variable);
                }
            }
        );
        extractVariableNames(model.content || "").forEach(
            (variable) => {
                if (variableNames.every((v) => v !== variable)) {
                    variableNames.push(variable);
                }
            }
        );

        this.setState(
            { model, variableNames },
            () => {
                if (!shouldValidate) {
                    return;
                }

                this.inputStream.next(this.state.model.dsl);
            }
        );
    }

    public onTypeChange(_: any, option: IDropdownOption): void {
        this.setState({
            model: {
                ...this.state.model,
                type: parseInt(option.id, 10)
            }
        });
    }

    public onMethodChange(_: any, option: IDropdownOption): void {
        const method = option.key as HttpMethod;

        this.setState({
            model: {
                ...this.state.model,
                method,
            },
        });
    }

    public onCancelClick(): void {
        this.props.history.push(
            `/marketplace/app/${this.props.match.params.appId}`
        );
    }

    public onSaveClicked(): void {
        if (this.state.isSaving) {
            return;
        }

        this.setState({ isSaving: true }, async () => {
            const { model } = this.state;
            try {
                await saveWidget({
                    applicationId: this.state.app.id,
                    content: model.content,
                    description: model.description,
                    displayType: model.type as number,
                    dslExpression: model.dsl,
                    method: model.method,
                    name: model.name,
                    resourceUrl: model.resourceUrl,
                    variables: model.variables,
                });
            } catch (err) {
                console.log(err);

                this.setState({ isSaving: false });
                return;
            }

            this.onCancelClick();
        });
    }

    public render(): ReactNode {
        if (this.state.isLoadingApp) {
            return <Loader />;
        }

        const { app } = this.state;

        return (
            <div id="MarketplaceWidgetFormView">
                <div className="view-header">
                    <BackgroundImage imageUrl={app.bannerUrl} center={true}>
                        <div className="wrapper">
                            <div className="header-text">
                                <div className="image">
                                    <Image
                                        alt={app.name}
                                        src={app.iconUrl}
                                    />
                                </div>
                                <div className="text">
                                    <Label
                                        as="span"
                                        className="app-title"
                                        color="white"
                                        size="h1"
                                        weight="medium"
                                    >
                                        {app.name}
                                    </Label>
                                    <Label
                                        as="span"
                                        className="category-name"
                                        color="white"
                                        size="h4"
                                        weight="regular"
                                    >
                                        CategoryName
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                </div>
                <div className="view-body">
                    <div className="main-content">
                        <Stack horizontal={true}>
                            <div className="tabs">
                                <Pivot>
                                    <PivotItem
                                        headerText={localize("Information")}
                                    >
                                        {this.renderInformationTab()}
                                    </PivotItem>
                                    <PivotItem headerText={localize("Data")}>
                                        {this.renderDataTab()}
                                    </PivotItem>
                                    {this.state.variableNames.length > 0 && (
                                        <PivotItem headerText={localize("MARKETPLACE_VARIABLES")}>
                                            {this.renderVariableTab()}
                                        </PivotItem>
                                    )}
                                </Pivot>
                            </div>
                            <div className="appearance">
                                <FormControl label={localize("MARKETPLACE_WIDGET_TYPE")}>
                                    <Dropdown
                                        options={this.typeOptions}
                                        onChange={this.onTypeChange}
                                    />
                                </FormControl>
                                {this.renderWidget()}
                            </div>
                        </Stack>
                    </div>
                    <FormFooterBar
                        onCancelClick={this.onCancelClick}
                        onSaveClick={this.onSaveClicked}
                    />
                </div>
            </div>
        );
    }

    public renderDslStatus(): ReactNode {
        if (this.state.model.dsl.length < 1) {
            return null;
        }

        if (this.state.isValidatingDsl) {
            return (
                <Fragment>
                    <Spinner size={SpinnerSize.xSmall} />
                    <Text nowrap={true} variant="xSmall">
                        {localize("Validating")}...
                    </Text>
                </Fragment>
            );
        }

        const valid = this.state.dslIsValid;

        return (
            <Fragment>
                <Visage2Icon icon={valid ? "tick-circle" : "close-circle"} hexColor={valid ? "#107c10" : "#e81123"} />
                <Text nowrap={true} variant="xSmall">
                    {valid
                        ? localize("DSL_EXPRESSION_VALID")
                        : localize("DSL_EXPRESSION_INVALID")}
                </Text>
            </Fragment>
        )
    }

    public renderWidget(): ReactNode {
        const type = this.state.model.type as SpintrTypes.WidgetType;
        const data: any = this.state.parsedData;

        return <MarketplaceWidget
            data={{
                data: data || getSampleWidgetData(type),
                displayType: type,
                iconUrl: this.state.app?.iconUrl,
                name: this.state.model.name,
                variables: {}
            }}
            displayBorder
        />;
    }

    protected renderInformationTab() {
        const widgetNameLabel = localize("Namn");
        const widgetDescLabel = localize("Beskrivning");

        return (
            <FormSection>
                <FormControl>
                    <TextField
                        ariaLabel={widgetNameLabel}
                        aria-required={true}
                        errorMessage={this.state.validationErrors["name"]}
                        label={widgetNameLabel}
                        name="name"
                        onChange={this.onChange}
                        value={this.state.model.name}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        ariaLabel={widgetDescLabel}
                        aria-required={true}
                        errorMessage={this.state.validationErrors["description"]}
                        label={widgetDescLabel}
                        multiline={true}
                        name="description"
                        onChange={this.onChange}
                        value={this.state.model.description}
                    />
                </FormControl>
            </FormSection>
        );
    }

    protected onResourceChanged(resource: IExtraResource): void {
        this.setState((state) => ({
            extraResources: state.extraResources.map(
                (er) => er.id === resource.id
                    ? resource
                    : er,
            ),
        }));
    }

    protected onAddResourceClick(): void {
        this.setState((state) => ({
            extraResources: state.extraResources.concat([{
                id: uniqueId("widget-resource"),
                name: "",
                requestMethod: "GET",
                resourceUri: "",
            }])
        }));
    }

    protected renderDataTab() {
        return (
            <>
                <FormSection>
                    <Stack
                        className="resource root-resource"
                        horizontal={true}
                        verticalAlign={"end"}
                    >
                        <FormControl className="request-type">
                            <Dropdown
                                label={localize("MARKETPLACE_WIDGET_RESOURCE_METHOD")}
                                onChange={this.onMethodChange}
                                options={[
                                    { key: "GET", text: "GET" },
                                    { key: "POST", text: "POST" }
                                ]}
                                selectedKey={this.state.model.method}
                            />
                        </FormControl>
                        <FormControl className="resource-input">
                            <TextField
                                errorMessage={this.state.validationErrors["resourceUrl"]}
                                label={localize("MARKETPLACE_WIDGET_RESOURCE")}
                                name="resourceUrl"
                                onChange={this.onChange}
                                value={this.state.model.resourceUrl}
                            />
                        </FormControl>
                        <div className="button-wrap">
                            <PrimaryButton
                                className="fetch-data-button"
                                disabled={!(this.state.app || {}).installed || !this.state.model.resourceUrl}
                                onClick={this.onGetDataClicked}
                            >
                                {localize("HamtaData")}
                            </PrimaryButton>
                        </div>
                    </Stack>
                    {this.state.extraResources.map((er) => (
                        <MarketplaceWidgetResource
                            key={er.id}
                            onChange={this.onResourceChanged}
                            resource={er}
                        />
                    ))}
                    <UnstyledButton onClick={this.onAddResourceClick}>
                        {localize("WIDGET_ADD_RESOURCE")}
                    </UnstyledButton>
                </FormSection>
                {this.state.model.method === "POST" && (
                    <FormSection>
                        <TextField
                            className="post-content"
                            label={localize("MARKETPLACE_WIDGET_RESOURCE_CONTENT")}
                            multiline={true}
                            name="content"
                            onChange={this.onChange}
                            value={this.state.model.content}
                        />
                    </FormSection>
                )}
                <FormSection>
                    <Stack horizontal={true} className="dsl-area">
                        <div className="dslExpression">
                            <FormControl>
                                <TextField
                                    className="dslInput"
                                    errorMessage={this.state.validationErrors["dsl"]}
                                    label={localize("MARKETPLACE_WIDGET_DSL")}
                                    multiline={true}
                                    name="dsl"
                                    onChange={this.onChange}
                                    value={this.state.model.dsl}
                                />
                            </FormControl>
                            <p className="dsl-status">
                                {this.renderDslStatus()}
                            </p>
                        </div>
                        <div className="result">
                            <FormControl>
                                <TextField
                                    label={localize("INPUT")}
                                    disabled={true}
                                    multiline={true}
                                    value={JSON.stringify(this.state.sampleData, null, 2)}
                                />
                            </FormControl>
                        </div>
                    </Stack>
                    <Stack>
                        <pre>
                            {JSON.stringify(this.state.parsedData, null, 2)}
                        </pre>
                    </Stack>
                </FormSection>
            </>
        )
    }

    protected onVariableChanged(variable: Spintr.IMarketplaceVariableSetup) {
        this.setState({
            model: {
                ...this.state.model,
                variables: this.state.model.variables
                    .filter((v) => v.variableName !== variable.variableName)
                    .concat([variable]),
            }
        })
    }

    protected renderVariableTab() {
        return (
            <div className="variable-tab">
                {this.state.variableNames.map((variableName) => {
                    const variable = this.state.model.variables.find(
                        (v) => v.variableName === variableName
                    );

                    return (
                        <VariableForm
                            key={variableName}
                            onChange={this.onVariableChanged}
                            variable={variable || {
                                dataType: "string",
                                description: "",
                                name: "",
                                variableName,
                            }}
                        />
                    );
                })}
            </div>
        )
    }
}

export default withRouter(MarketplaceWidgetFormView);