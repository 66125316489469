import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Loader } from 'src/ui';
import './Scrollable.scss';

interface IProps {
    onEndReached?: any;
    isInvertedChatView?: boolean;
    displayLoader?: boolean;
    renderHeader?: any;
    renderFooter?: any;
    checkResize?: boolean;
}

interface IState {
    scrollToClass?: string; // this is not the cleanest soluion, but works
}

class Scrollable extends Component<IProps, IState> {
    private wrapper: HTMLElement;
    protected resizeSubscription: Subscription;

    componentDidMount() {
        if (this.props.isInvertedChatView) {
            this.scrollToBottom();
        }

        if (this.props.checkResize) {
            this.resizeSubscription = fromEvent(window, "resize")
                .pipe(debounceTime(100))
                .subscribe(this.onResize);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isInvertedChatView) {
            this.handleScrollLocking(prevProps);
        }
    }

    public componentWillUnmount() {
        if (!this.resizeSubscription) {
            return;
        }

        this.resizeSubscription.unsubscribe();
    }

    handleScrollLocking(prevProps) {
        // TODO: Make this smoother

        if (!prevProps.displayLoader &&
            this.props.displayLoader) {
            const wrapper = ReactDOM.findDOMNode(this.wrapper);

            if (!wrapper) {
                return;
            }

            // @ts-ignore
            const topMessage = wrapper.getElementsByClassName("ChatMessage")[0];

            let topMessageUniqueClass = "";

            for (const c of topMessage.classList) {
                if (c.indexOf("ChatMessage-") === 0) {
                    topMessageUniqueClass = c;
                }
            }

            this.setState({
                scrollToClass: topMessageUniqueClass
            });
        }

        if (prevProps.displayLoader &&
            !this.props.displayLoader) {
            const wrapper = ReactDOM.findDOMNode(this.wrapper);

            if (!wrapper) {
                return;
            }

            if (!this.state ||
                !this.state.scrollToClass) {
                return;
            }

            // @ts-ignore
            const message = wrapper.getElementsByClassName(this.state.scrollToClass)[0];

            message.scrollIntoView();

            const sizeOfDateGrouplabel = 60;
            const sizeOfLoader = 78;

            // @ts-ignore
            wrapper.scrollTop -= (sizeOfDateGrouplabel + sizeOfLoader);

            this.setState({
                scrollToClass: null
            });
        }
    }

    public scrollToBottom(withAnimation? : boolean) {
        let fn = () => {
            if (!this.wrapper) {
                return;
            }

            // @ts-ignore
            const scrollHeight = this.wrapper.scrollHeight;

            if (withAnimation) {
                this.wrapper.scrollTo({
                    top: scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                //@ts-ignore
                this.wrapper.scrollTop = scrollHeight;
            }
        }

        setTimeout(fn.bind(this));
    }

    public scrollToBottomIfAtBottom(diffSpan?: number) {
        // TODO: Add logic for checking if currently at the near bottom

        if (!this.isAtBottom(diffSpan || 200)) {
            return;
        }

        this.scrollToBottom(true);
    }

    isAtBottom(diffSpan? : number) {
        const wrapper = ReactDOM.findDOMNode(this.wrapper);

        if (!wrapper) {
            return false;
        }

        if (!diffSpan) {
            diffSpan = 5;
        }

        // @ts-ignore
        const offsetHeight = wrapper.offsetHeight;
        // @ts-ignore
        const scrollHeight = wrapper.scrollHeight;
        // @ts-ignore
        const scrollTop = wrapper.scrollTop;

        const diff = Math.round(offsetHeight + scrollTop) - Math.round(scrollHeight);

        const isAtBottom = diff > (diffSpan * -1) && diff < diffSpan;

        return isAtBottom;
    }

    isAtTop() {
        const wrapper = ReactDOM.findDOMNode(this.wrapper);

        if (!wrapper) {
            return false;
        }

        // @ts-ignore
        const scrollTop = wrapper.scrollTop;

        return scrollTop === 0;
    }

    onScroll(e) {
        e.preventDefault();

        if (!this.wrapper || !this.props.onEndReached) {
            return;
        }

        if (this.props.isInvertedChatView) {
            const isAtTop = this.isAtTop();

            if (isAtTop) {
                this.props.onEndReached();
            }
        } else {
            const isAtBottom = this.isAtBottom();

            if (isAtBottom) {
                this.props.onEndReached();
            }
        }
    }

    //debouncedOnScroll = debounce(() => this.onScroll(), 10);

    private onResize = () => {
        setTimeout(() => {
            if (this.isAtBottom()) {
                this.props.onEndReached();
            }
        }, 0);
    };

    renderLoader() {
        return (
            <Loader />
        );
    }

    render() {
        return (
            <div
                ref={(el) => { this.wrapper = el; }}
                className="Scrollable-wrapper"
                data-is-scrollable="true"
                onScroll={this.onScroll.bind(this)}>
                {
                    this.props.displayLoader &&
                    this.props.isInvertedChatView ?
                        this.renderLoader() :
                        null
                }
                <div className="Scrollable-content">
                    {
                        this.props.children
                    }
                </div>
                {
                    this.props.displayLoader &&
                    !this.props.isInvertedChatView ?
                        this.renderLoader() :
                        null
                }
            </div>
        )
    }
}

export default Scrollable;
