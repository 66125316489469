import React, { useEffect, useRef, useState } from "react";
import "./ScaleTextFontSize.scss";
import { debounce } from "src/utils";

interface IProps {
    text: string;
}

const maxValue = 60;
const minValue = 22;

declare class ResizeObserver {
    observe(target: Element): any;
    unobserve(target: Element): any;
    disconnect(): any;
}

const ScaleTextFontSize = (props: IProps) => {
    const ref = useRef(null);
    const textRef = useRef(null);
    const [fontSize, setFontSize] = useState(0);

    const getFontSize = () : number  => {
        if (!ref.current ||
            !textRef.current) {
            return minValue;
        }

        for (let i = maxValue; i >= minValue; i = i - 2) {
            textRef.current.style.fontSize = (i + "px");

            const wrapperWidth = ref.current.offsetWidth;
            const textWidth = textRef.current.offsetWidth;

            if (textWidth < wrapperWidth) {
                return i;
            }
        }

        return minValue;
    }

    const resize = () => {
        setFontSize(getFontSize());
    }

    const debouncedResize = debounce(() => resize(), 500);

    useEffect(() => {
        resize();
    }, [props.text]);

    useEffect(() => {
        if (!ref.current) return;

        //@ts-ignore
        const ro = new ResizeObserver(() => { debouncedResize(); });

        ro.observe(ref.current);

        return () => ro.disconnect();
    }, []);

    return (
        <div ref={ref} className="ScaleTextFontSize">
            <span ref={textRef} className="ScaleTextFontSize-fake">{props.text}</span>
            {fontSize !== 0 && (
                <span style={{
                    fontSize: fontSize,
                    lineHeight: fontSize + "px"
                }}>{props.text}</span>
            )}
        </div>
    )
}

export default ScaleTextFontSize;
