import { AnyAction, Middleware } from "redux";

/**
 * a utility to check if a value is a Promise or not
 * @param value
 */
const isPromise = (value: any): boolean => value !== null && typeof value === 'object' && typeof value.then === 'function';

interface IConfig {
    printErrorsToConsole?: boolean;
}

const createErrorHandlingMiddleware = (config: IConfig): Middleware => () => (next) => (action: AnyAction) => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    if (action.meta && action.meta.localError) {
        return next(action);
    }

    return next(action).catch((error: Error) => {
        if (config.printErrorsToConsole) {
            console.log(`${action.type} unhandled rejection caught at middleware with reason: ${JSON.stringify(error.message)}.`);
        }

        return error;
    });
};

export default createErrorHandlingMiddleware;
