import React, { Component } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";
import { Loader } from "src/ui";
import TinyFolder from "./TinyFolder";

interface IProps {
    search?: any;
    isLoading: boolean;
    files?: any;
    enableExternalFiles: boolean;
}

class TinySharePointListSearch extends Component<IProps> {
    public render() {
        const { isLoading, files, enableExternalFiles } = this.props;

        if (!enableExternalFiles) {
            return null;
        }

        if (isLoading) {
            return <Loader />;
        }

        return (
            <TinyFolder
                item={{
                    name: this.props.search.name,
                    files: files.files,
                    external: true,
                }}
            />
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        isLoading: state.files.files.sharePointAttachedFiles.isLoading,
        files: state.files.files.sharePointAttachedFiles.searches.find((f) => f.id === props.search.id) || [],
        enableExternalFiles: state.profile.active.office365Connected && state.instance.get("office365Enabled"),
    };
};

export default connect(mapStateToProps)(TinySharePointListSearch);
