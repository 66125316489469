import { SET_ZOHO_POPUP } from "./zoho-action-types";

const setZohoPopup = (isOpen: boolean, size?: any, id?: any, version?: any) => ({
    isOpen,
    size,
    id,
    version,
    type: SET_ZOHO_POPUP,
});

export { setZohoPopup };
