export enum SocialFeedActionTypes {
    FetchSocialFeed = "SOCIAL_FEED_FETCH",
    FetchSocialFeedFulfilled = "SOCIAL_FEED_FETCH_FULFILLED",
    FetchSocialFeedPending = "SOCIAL_FEED_FETCH_PENDING",
    FetchSocialFeedRejected = "SOCIAL_FEED_FETCH_REJECTED",

    LocalPostCreated = "SOCIAL_FEED_LOCAL_CREATED",

    RemotePostCreated = "SOCIAL_FEED_REMOTE_CREATED",
    RemotePostDeleted = "SOCIAL_FEED_REMOTE_DELETED",
    RemotePostPinned = "SOCIAL_FEED_REMOTE_PIN",
    RemotePostUpdated = "SOCIAL_FEED_REMOTE_UPDATED",
    RemoteVerifyQuestion = "SOCIAL_FEED_REMOTE_VERYFIY_QUESTION",

    VerifyQuestion = "SPINTR_VERIFY_QUESTION",
    VerifyQuestionFulfilled = "SPINTR_VERIFY_QUESTION_FULFILLED",
    VerifyQuestionPending = "SPINTR_VERIFY_QUESTION_PENDING",
    VerifyQuestionRejected = "SPINTR_VERIFY_QUESTION_REJECTED",

    ClearFeed = "SOCIAL_FEED_CLEAR",

    SetFilters = "SET_FILTERS",

    RemotePollVote = "REMOTE_POLL_VOTE"
};
