import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from "../Drawer";
import CompactTable from "src/ui/components/CompactTable/CompactTable";
import { localize } from "src/l10n";
import { Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react";
import { UnitGroupChangeItem, UnitGroupsDrawerProps, UnitGroupsDrawerState } from "./UnitGroupsDrawer.types";
import { createGroupAsync, getCancelTokenSource, getGroupTemplatesAsync } from "src/api";
import { Conditional } from "../Conditional";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ITokenizedInputItem from "src/ui/components/Forms/ITokenizedInputItem";
import { SpintrTypes } from "src/typings";
import { set } from "date-fns";

function getUnitGroupStatus(unit: UnitGroupChangeItem): string {
    switch (unit.status) {
        case "waiting":
            return localize("Väntar");
        case "inProgress":
            return localize("Pågår");
        case "success":
            return localize("Klar");
        case "error":
            return localize("Fel");
    };
}

const administratorTypes: Spintr.UberType[] = [
    SpintrTypes.UberType.Role,
];

function UnitGroupsDrawer({
    changes,
    onDismiss,
    open,
}: UnitGroupsDrawerProps): ReactElement {
    const [state, setState] = useState<UnitGroupsDrawerState>({
        administrators: [],
        isWorking: false,
        units: [],
        templateId: 0,
        templates: [],
    });

    useEffect(() => {
        const cts = getCancelTokenSource();

        getGroupTemplatesAsync(false, cts.token)
            .then((templates) => setState((prevState) => ({
                ...prevState,
                templates,
            })))
            .catch((error) => console.error(error));

        return () => cts.cancel();
    }, [setState]);

    useEffect(() => {
        if (!open) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isWorking: false,
            units: changes.map((change) => ({
                ...change,
                status: "waiting",
            })),
        }));
    }, [open, changes]);

    const templateOptions = useMemo<IDropdownOption<number>[]>(
        () => [
            { key: 0, text: localize("Ingen") },
            ...state.templates.map((template) => ({
                key: template.id,
                text: template.name,
            }))
        ],
        [state.templates],
    );

    const rows = useMemo(() => state.units.map((unit) => [
        unit.name,
        getUnitGroupStatus(unit),
    ]), [state.units]);

    const onDismissed = useCallback((isOpen?: boolean) => {
        if (state.isWorking) {
            return;
        }

        onDismiss([]); // TODO calc changes
    }, [state.isWorking]);

    const onTemplateChanged = useCallback(
        (_, option) => setState((prevState) => ({
            ...prevState,
            templateId: option.key,
        })),
        [setState],
    );

    const onAdministratorsChanged = useCallback(
        (items : ITokenizedInputItem[]) => setState((prevState) => ({
            ...prevState,
            administrators: items,
        })),
        [setState],
    );

    const onButtonClicked = useCallback(() => {
        if (state.isWorking) {
            return;
        }

        const isDone = state.units.every(
            (unit) => unit.status === "success" || unit.status === "error"
        );

        if (isDone) {
            onDismiss(state.units);
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isWorking: true,
        }));
    }, [state.isWorking, state.units]);

    const buttonText = useMemo(() => {
        if (state.isWorking) {
            return localize("Vantar") + "...";
        }

        const isDone = state.units.length > 0
            && state.units.every((unit) => unit.status === "success" || unit.status === "error");

        return localize(isDone ? "Stang" : "Starta");
    }, [state.isWorking, state.units]);

    const createGroup = useCallback(async () => {
        const unit = state.units.find((unit) => unit.status === "waiting");
        if (!unit) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            units: prevState.units.map((u) => u.id === unit.id
                ? { ...u, status: "inProgress" }
                : u,
            ),
        }));

        let groupId: number | undefined;
        try {
            const group = await createGroupAsync({
                name: unit.name,
                availability: SpintrTypes.GroupAvailability.Closed,
                description: "",
                membersCanInvite: false,
                targets: [{ id: unit.id }],
                adminTargets: state.administrators.map((item) => ({ id: item.key as number })),
                version: 2,
                referenceId: unit.id,
                template: state.templateId,
                disableManualMembership: true,
            });

            groupId = group.id;
        } catch (_) {
            setState((prevState) => ({
                ...prevState,
                units: prevState.units.map((u) => u.id === unit.id
                    ? { ...u, status: "error" }
                    : u,
                ),
            }));
            return;
        }
        
        setState((prevState) => ({
            ...prevState,
            units: prevState.units.map((u) => u.id === unit.id
                ? { ...u, status: "success", groupId: groupId }
                : u,
            ),
        }));
    }, [state.units, state.templateId, state.administrators, setState]);

    useEffect(() => {
        if (!state.isWorking) {
            return;
        }

        const hasInProgress = state.units.some((unit) => unit.status === "inProgress");
        const hasWaiting = state.units.some((unit) => unit.status === "waiting");

        const isReadyForNext = 
            state.units.length > 0 &&
            !hasInProgress &&
            hasWaiting;

        if (isReadyForNext) {
            createGroup();
        }

        const isDone = state.units.length > 0 && !hasInProgress && !hasWaiting;
        if (!isDone) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isWorking: false,
        }));
    }, [state.isWorking, state.units, createGroup]);

    return (
        <Drawer
            className="UnitGroupsDrawer"
            open={open}
            position="end"
            size="medium"
            onOpenChange={onDismissed}
        >
            <DrawerHeader>
                <DrawerHeaderTitle>
                    {localize("Grupper")}
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>

                <Conditional condition={state.templates.length > 0}>
                    <FormControl label={localize("Mall")}>
                        <Dropdown
                            onChange={onTemplateChanged}
                            options={templateOptions}
                            selectedKey={state.templateId} />
                    </FormControl>

                    <FormControl>
                        <FormTokenizedObjectInput
                            items={state.administrators}
                            label={localize("Gruppadministrator")}
                            onChange={onAdministratorsChanged}
                            types={administratorTypes} />
                    </FormControl>
                </Conditional>
                
                <CompactTable
                    columNames={[localize("Namn"), localize("Status")]}
                    rows={rows} />
                
            </DrawerBody>

            <DrawerFooter className="UnitGroupsDrawer-footer">
                <PrimaryButton
                    disabled={state.isWorking}
                    onClick={onButtonClicked}
                    text={buttonText} />
            </DrawerFooter>
        </Drawer>
    );
}

export default UnitGroupsDrawer;
