import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import { IApplicationState } from "src/spintr/reducer";
import "./CommentCreator.scss";
import CommentForm from "./CommentForm";

interface ICommentCreatorProps {
    user?: IActiveUserProfile;
    uberId: number;
    identity?: any;
    comment?: any;
    displayButtons?: boolean;
    placeholder?: string;
    sendToMixpanel: boolean;
    autofocus?: boolean;
}

class CommentCreator extends Component<ICommentCreatorProps> {
    public render(): ReactNode {
        return (
            <CommentForm
                autofocus={this.props.autofocus}
                identity={this.props.identity}
                uberId={this.props.uberId}
                currentUser={this.props.user}
                comment={this.props.comment}
                displayButtons={this.props.displayButtons}
                placeholder={this.props.placeholder}
                sendToMixpanel={this.props.sendToMixpanel}
            />
        );
    }
}

export default connect(
    (state: IApplicationState, props: ICommentCreatorProps): ICommentCreatorProps => ({
        ...props,

        user: state.profile.active
    })
)(CommentCreator);
