import Axios, { CancelTokenSource } from "axios";
import Api from "src/spintr/SpintrApi";

export function getCancelTokenSource(): CancelTokenSource {
    return Axios.CancelToken.source();
}

export * from "./groupsApi";
export * from "./objectsApi";
export * from "./routesApi";
export * from "./wikiApi";

export default Api;
