import classNames from "classnames";
import React from 'react';
import "./Label.scss";

export interface IProps {
    as?:
    | "div"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "h7"
    | "label"
    | "span"
    | "p"
    | "em"
    | "pre";
    role?: string;
    ariaLevel?: number;
    attrs?: any;
    color?: spintrColors;
    className?: string;
    size?: spintrLabelSizes;
    style?: React.CSSProperties;
    weight?: spintrLabelFontweight;
    title?: string;
    children?: React.ReactNode;
    uppercase?: boolean;
    dangerouslySetInnerHTML?: any;
    lang?: string;
    centerText?: boolean;
    italic?: boolean;
    underline?: boolean;
    lineThrough?: boolean;
}

export const Label: React.FunctionComponent<IProps> = props => {
    const Tag = props.as! as any;

    const color = !!props.color ? props.color : "visageGray";

    return (
        <Tag
            className={classNames(
                props.className,
                "label-component",
                `fs-${props.size}`,
                {
                    [`fc-${props.color}`]: !!props.color,
                    [`fw-${props.weight}`]: !!props.weight,
                    "uppercase": !!props.uppercase,
                    "subline-letter-spacing": !!props.uppercase,
                    "centerText": !!props.centerText,
                    "italic": !!props.italic,
                    "underline": !!props.underline,
                    "line-through": !!props.lineThrough
                }
            )}
            lang={props.lang}
            role={props.role}
            style={props.style}
            title={props.title}
            aria-level={props.ariaLevel}
            dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
            {...props.attrs}
        >
            {props.children}
        </Tag>
    );
};

Label.defaultProps = {
    as: "div",
    size: "body-1",
};

export default React.memo(Label);
