import { AxiosResponse } from "axios";
import classnames from "classnames";
import { List } from "immutable";
import moment from "moment";
import { ProgressIndicator, TextField } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { Swipeable } from "react-swipeable";
import { Action } from "redux";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, Breadcrumbs, Label, Loader, SpintrUser, UnstyledButton } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, FormFooterBar, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { isAnythingDirty } from "src/utils";
import { fetchMediaFolder } from "../media-actions";
import "./MediaImageView.scss";
import api from "src/spintr/SpintrApi";
import postMediaPlayCountUpdate from "src/utils/postMediaPlayCountUpdate";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    isLoading: string;
    isAdmin: boolean;
    isEditor: boolean;
    folders: List<Spintr.IMediaFolder>;
    images: List<Spintr.IMediaImage>;
    match: any;
    isSmallViewMode: boolean;
    folderPath: Spintr.IBreadcrumbItem[];
    history: any;
    dispatch: (action: Action) => void;
}

interface IState {
    isLoading: boolean;
    isEditing: boolean;
    showDeleteDialog: boolean;
    item?: Spintr.IMediaImageImage;
    editModel?: Spintr.IMediaImageImage;
    displayUnsavedChangesPopup: boolean;
}

class MediaImageView extends Component<IProps, IState> {
    private readonly rootClassName = "media-image-view";

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isEditing: false,
            showDeleteDialog: false,
            displayUnsavedChangesPopup: false
        };
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.match.params.imageId !== this.props.match.params.imageId) {
            this.fetch();
        }
    }

    fetch() {
        api.get(`/api/images/${this.props.match.params.imageId}`).then((response) => {
            let item = response.data as Spintr.IMediaImageImage;
            item.date = new Date(item.date);

            this.props.dispatch(fetchMediaFolder(item.gallery.id, item));

            this.setState({
                isLoading: false,
                item: item,
            });

            // Re-fetch this periodically until the video is done
            if (item.fileType === SpintrTypes.MediaFileType.Video &&
                item.fileStatus !== SpintrTypes.FileStatus.Done && item.fileStatus !== SpintrTypes.FileStatus.Error) {
                setTimeout(() => {
                    this.fetch();
                }, 10 * 1000);
            }

        }).catch(() => {
            this.props.history.push({
                pathname: "/404"
            });
        });
    }

    renderActionMenu(state: IState): JSX.Element {
        let categories = [{
            items: [
                {
                    text: localize("LaddaHem"),
                    onClick: () => {
                        if (state.item.fileType === 1 && state.item.videoUrl) {
                            window.open(state.item.videoUrl);
                            return;
                        }

                        window.open(`/api/servefile/${state.item.id}`);
                    },
                },
            ],
        }];

        if (this.props.isAdmin || this.props.isEditor) {
            categories.push({
                //@ts-ignore
                title: localize("Redigera"),
                items: [
                    {
                        text: localize("Redigera"),
                        onClick: () => {
                            let editModel = state.item;
                            this.setState({ isEditing: true, editModel });
                        },
                    },
                    {
                        text: localize("RoteraMedsols"),
                        onClick: () => this.rotateImage(true),
                    },
                    {
                        text: localize("RoteraMotsols"),
                        onClick: () => this.rotateImage(false),
                    },
                    {
                        text: localize("TaBort"),
                        onClick: () =>
                            this.setState({
                                showDeleteDialog: true,
                            }),
                    },
                ],
            });
        }

        return (
            <ActionMenu
                categories={categories}
            />
        );
    }

    deleteItem() {
        this.setState({
            isLoading: true,
            showDeleteDialog: false,
        });

        api.delete(`/api/images/${this.state.item.id}`).then(() => {
            this.props.history.push({
                pathname: `/media/folder/${this.state.item.gallery.id}`,
            });
        });
    }

    rotateImage(clockwise: boolean) {
        this.setState({
            isLoading: true,
        });
        api
            .put(`/api/images/rotate/${this.state.item.id}?clockwise=${clockwise}`)
            .then((response: AxiosResponse) => {
                this.setState((prevState) => ({
                    isLoading: false,
                    item: {
                        ...prevState.item,
                        imageUrl: response.data.imageUrl,
                    },
                }));
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
                // TODO: Tekniskt Fel
            });
    }

    renderVideoItem(item: Spintr.IMediaImageImage) {
        return (
            <>
                {item.fileStatus !== SpintrTypes.FileStatus.Done ? (
                    item.fileStatus === SpintrTypes.FileStatus.Error ? (
                        <div className="status">
                            <Label>{localize("FilmenKundeIntePubliceras")}</Label>
                        </div>
                    ) : (
                        <div className="ProgressIndicator">
                            <ProgressIndicator
                                label={localize("BearbetningPagar")}
                                description={item.mediaUploadProgress > 0
                                    ? `${item.mediaUploadProgress}% ${localize("Bearbetad").toLowerCase()}`
                                    : `${localize("BearbetningForbereds")}`}
                                percentComplete={item.mediaUploadProgress > 0 && item.mediaUploadProgress / 100}
                            />
                        </div>
                    )
                ) : null}
                {item.videoUrl ? (
                    <video
                        onPlay={(_event) => postMediaPlayCountUpdate(item.id, item.videoUrl)}
                        className="player azuremediaplayer amp-default-skin amp-big-play-centered" controls>
                        <source src={item.videoUrl} />
                        <p className="amp-no-js">
                            To view this video please enable JavaScript, and consider upgrading to a web browser that
                            supports HTML5 video
                        </p>
                    </video>
                ) : null}
            </>
        );
    }

    renderForm() {
        return (
            <div>
                <form>
                    <FormSection>
                        <FormControl>
                            <TextField
                                value={this.state.editModel.displayName}
                                label={localize("Namn")}
                                styles={{ field: { font: "Eloquia", fontSize: 16 } }}
                                className="displayName"
                                onChange={(event: React.FormEvent, val: string) => {
                                    this.setState((prevState) => ({
                                        editModel: {
                                            ...prevState.editModel,
                                            displayName: val,
                                        },
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={this.state.editModel.photographer ? [this.state.editModel.photographer] : []}
                                label={localize("Fotograf")}
                                types={[SpintrTypes.UberType.User]}
                                itemLimit={1}
                                onChange={(photographer) => {
                                    this.setState((prevState) => ({
                                        editModel: {
                                            ...this.state.editModel,
                                            photographer: photographer.map((p) => {
                                                return {
                                                    name: p.name,
                                                    info: p.subText,
                                                    type: SpintrTypes.UberType.User,
                                                    id: p.key,
                                                    imageUrl: p.imageUrl,
                                                    typeName: "user",
                                                };
                                            })[0],
                                        },
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.editModel.tags.toString()}
                                label={localize("TaggarSepareraMedKommatecken")}
                                onChange={(event: React.FormEvent, val: string) => {
                                    this.setState((prevState) => ({
                                        editModel: {
                                            ...prevState.editModel,
                                            tags: val.split(","),
                                        },
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label={localize("Beskrivning")}
                                value={this.state.editModel.text}
                                onChange={(event: React.FormEvent, val: string) => {
                                    this.setState((prevState) => ({
                                        editModel: {
                                            ...prevState.editModel,
                                            text: val,
                                        },
                                    }));
                                }}
                            />
                        </FormControl>
                    </FormSection>
                    <FormFooterBar
                        onCancelClick={() => {
                            let f = () => {
                                this.setState({
                                    isEditing: false
                                });
                            }

                            if (isAnythingDirty()) {
                                this.props.dispatch(setConfirmPopup({
                                    isOpen: true,
                                    message: localize("UnsavedChangesWarning"),
                                    onConfirm: f,
                                }));
                            } else {
                                f();
                            }
                        }}
                        onSaveClick={() => {
                            const editModel = this.state.editModel;

                            var promises = [];
                            this.setState({ isLoading: true, isEditing: false });

                            if (editModel.text !== this.state.item.text) {
                                promises.push(
                                    api.put(`/api/images/${editModel.id}/changetext`, {
                                        description: editModel.text,
                                        mentions: [], // TODO: Add @-tags
                                    })
                                );
                            }

                            if (editModel.displayName !== this.state.item.displayName) {
                                promises.push(
                                    api.put(`/api/images/${editModel.id}/changedisplayname`, {
                                        displayName: editModel.displayName,
                                    })
                                );
                            }

                            if (editModel.tags !== this.state.item.tags) {
                                promises.push(
                                    api.put(`/api/images/${editModel.id}/changetags`, {
                                        tags: editModel.tags,
                                    })
                                );
                            }

                            if (editModel.photographer !== this.state.item.photographer) {
                                let payload = {
                                    ...(editModel.photographer && { photographerId: editModel.photographer.id }),
                                };
                                promises.push(
                                    api.put(`/api/images/${editModel.id}/changephotographer`, payload)
                                );
                            }

                            Promise.all(promises).then(() => {
                                this.fetch();
                            });
                        }}
                    />
                </form>
            </div>
        )
    }

    renderUnsavedChangesDialog() {
        return (
            <CustomDialog
                message={localize("UnsavedChangesWarning")}
                show={!!this.state.displayUnsavedChangesPopup}
                confirmMessage={localize("Ok")}
                onDismiss={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    });
                }}
                onConfirm={() => {
                    this.setState({
                        displayUnsavedChangesPopup: false
                    }, () => {
                        this.setState({
                            isEditing: false
                        });
                    });
                }} />
        )
    }

    renderDowloadLink(size) {
        let sizeNumber = size === "small" ?
            0.25 :
            size === "medium" ?
                0.5 :
                1;

        let sizeLabelText = size === "small" ?
            localize("Liten") :
            size === "medium" ?
                localize("Mellan") :
                localize("Stor");

        return (
            <div>
                <UnstyledButton
                    ariaLabel={localize("LaddaNed") + " (" + sizeLabelText + ")"}
                    className={"linkFGColor"}
                    onClick={() => {
                        const url = `/api/servefile/${this.state.item.id}?imageSize=${size}`;

                        window.open(url, "_blank");
                    }}>
                    <Label role="heading" size="body-3">
                        {sizeLabelText} ({Math.floor(this.state.item.width * sizeNumber)}*
                        {Math.floor(this.state.item.height * sizeNumber)})
                    </Label>
                </UnstyledButton>
            </div>
        )
    }

    render() {
        const state = this.state;
        if (state.isLoading) {
            return <Loader />;
        }

        var style: any = {};
        if (state.item.imageUrl || state.item.videoThumbnailUrl) {
            style.backgroundImage = `url(${state.item.imageUrl || state.item.videoThumbnailUrl})`;
        }

        return (
            <div className={`${this.rootClassName}`}>
                {this.state.showDeleteDialog && (
                    <CustomDialog
                        message={localize("VillDuTaBortBilden")}
                        show={true}
                        onDismiss={() => {
                            this.setState({
                                showDeleteDialog: false,
                            });
                        }}
                        onConfirm={() => this.deleteItem()}
                    />
                )}
                {this.props.isSmallViewMode && (
                    <div className="breadcrumbs-container">
                        <Breadcrumbs
                            items={[
                                {
                                    key: "0",
                                    text: localize("Mediabank"),
                                    link: "/media",
                                },
                                ...this.props.folderPath,
                            ]}
                        />
                    </div>
                )}
                {
                    // @ts-ignore
                    <Swipeable
                        className="item"
                        onSwipedLeft={() =>
                            this.props.history.push({
                                pathname: `/media/image/${state.item.previousImageIdInGallery}`,
                            })
                        }
                        onSwipedRight={() =>
                            this.props.history.push({
                                pathname: `/media/image/${state.item.nextImageIdInGallery}`,
                            })
                        }
                    >
                        {state.item.fileType === SpintrTypes.MediaFileType.Video ? (
                            this.renderVideoItem(state.item)
                        ) : (
                            <img src={state.item.imageUrl} alt="" />
                        )}
                        {state.item.gallery.imageCount > 1 && (
                            <>
                                <div className="arrow left">
                                    <NavLink to={`/media/image/${state.item.nextImageIdInGallery}`}>
                                        <Visage2Icon icon="arrow-left-2" color="white" />
                                    </NavLink>
                                </div>
                                <div className="arrow right">
                                    <NavLink to={`/media/image/${state.item.previousImageIdInGallery}`}>
                                        <Visage2Icon icon="arrow-right-3" color="white" />
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </Swipeable>
                }
                {
                    !state.isEditing && (
                        <div>
                            <div className="media-image-info">
                                <div className="info-displayName-wrapper">
                                    <Label role="heading" as="div" size="h4" className="displayName" title={state.item.displayName}>
                                        {state.item.displayName}
                                    </Label>
                                    {this.renderActionMenu(state)}
                                </div>
                                <div
                                    className={classnames("items", {
                                        fullWidth:
                                            !state.item.width ||
                                            state.item.fileType === SpintrTypes.MediaFileType.Video ||
                                            this.props.isSmallViewMode,
                                    })}
                                >
                                    <div className="item">
                                        <Label role="heading" as="h2" color="dark-grey" size="small-2" weight="normal">
                                            {localize("Agare").toUpperCase()}
                                        </Label>
                                        <Label role="heading" as="div" color="dark-grey" size="body-3">
                                            <SpintrUser
                                                name={state.item.author.name}
                                                imageUrl={state.item.author.imageUrl}
                                                subText={state.item.author.info}
                                                nameLink={`/profile/${state.item.author.id}`}
                                                subLink={`/profile/${state.item.author.id}`}
                                                personalName
                                            />
                                        </Label>
                                    </div>
                                    {state.item.width ? (
                                        <div className="item">
                                            <Label role="heading" as="h2" color="dark-grey" size="small-2" weight="normal">
                                                {localize("Storlek").toUpperCase()}
                                            </Label>
                                            <Label role="heading" as="div" color="dark-grey" size="body-3">
                                                {state.item.width}*{state.item.height}
                                            </Label>
                                        </div>
                                    ) : null}
                                    <div className="item">
                                        <Label as="h2" color="dark-grey" size="small-2" weight="normal">
                                            {localize("Tillagd").toUpperCase()}
                                        </Label>
                                        <Label role="heading" as="div" color="dark-grey" size="body-3">
                                            {moment(state.item.date).format("YYYY-MM-DD")}
                                        </Label>
                                    </div>
                                    {
                                        state.item.photographer && (
                                            <div className="item">
                                                <Label as="h2" color="dark-grey" size="small-2" weight="normal">
                                                    {localize("Fotograf").toUpperCase()}
                                                </Label>
                                                <SpintrUser
                                                    name={state.item.photographer.name}
                                                    imageUrl={state.item.photographer.imageUrl}
                                                    subText={state.item.photographer.info}
                                                    nameLink={`/profile/${state.item.photographer.id}`}
                                                    subLink={`/profile/${state.item.photographer.id}`}
                                                    personalName
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        state.item.tags && state.item.tags.toString().length > 0 && (
                                            <div className="item">
                                                <Label role="heading" as="h2" color="dark-grey" size="small-2" weight="normal">
                                                    {localize("Taggar").toUpperCase()}
                                                </Label>
                                                <Label role="heading" as="div" color="dark-grey" size="body-3">
                                                    {state.item.tags.map((tag) => {
                                                        return (
                                                            <span>
                                                                <NavLink key={tag} to={`/sok?q=${tag}`}>
                                                                    {tag}
                                                                </NavLink>
                                                                {
                                                                    state.item.tags.indexOf(tag) !== (state.item.tags.length - 1) && (
                                                                        <span>, </span>
                                                                    )
                                                                }
                                                            </span>
                                                        );
                                                    })}
                                                </Label>
                                            </div>
                                        )
                                    }
                                    {
                                        state.item.text && (
                                            <div className="item">
                                                <Label role="heading" as="h2" color="dark-grey" size="small-2" weight="normal">
                                                    {localize("Beskrivning").toUpperCase()}
                                                </Label>
                                                <Label role="heading" as="div" color="dark-grey" size="body-3">
                                                    {state.item.text}
                                                </Label>
                                            </div>
                                        )
                                    }
                                </div>
                                {state.item.width && state.item.fileType === SpintrTypes.MediaFileType.Image ? (
                                    <div className="download-box">
                                        <Label role="heading" as="h2" color="dark-grey" size="small-2" weight="normal">
                                            {localize("LaddaNed").toUpperCase()}
                                        </Label>
                                        {
                                            this.renderDowloadLink("small")
                                        }
                                        {
                                            this.renderDowloadLink("medium")
                                        }
                                        {
                                            this.renderDowloadLink("large")
                                        }
                                    </div>
                                ) : null}
                            </div>
                            <SocialBlock uberId={state.item.id} />
                        </div>
                    )
                }
                {
                    state.isEditing && this.renderForm()
                }
                {
                    this.renderUnsavedChangesDialog()
                }
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isSmallViewMode: state.ui.isSmallViewMode,
        folderPath: state.media.media.get("folderPath"),
    };
};

export default withRouter(connect(mapStateToProps)(MediaImageView));
