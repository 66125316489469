import React, { FunctionComponent, useMemo } from "react";
import { useParams } from "react-router";
import { IAppPageView } from "src/marketplace/types";
import { buildAppPageComponent } from "src/marketplace/utils";

interface IProps {
    view: IAppPageView;
    pageVariables?: { [propertyKey: string]: any };
}

const AppPageView: FunctionComponent<IProps> = ({ view, pageVariables }) => {
    const params = useParams();

    const { layout } = view;

    const children = useMemo(
        () => layout.map((item, index) => buildAppPageComponent({
            variables: {
                ...params,
                ...(pageVariables || {}),
            },
            ...item
        }, index)),
        [layout, pageVariables, params],
    );

    return <div id="AppPageView">{children}</div>;
};

export default AppPageView;
