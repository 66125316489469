import React, { FunctionComponent } from "react";
import { localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const UserJoinedEventNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IUserJoinedEventNotification;
    if (!notification) {
        return null;
    }

    const text = localize("KommerDeltaI").toLowerCase();

    return (
        <span>{notification.userName} {text} {notification.eventName}</span>
    );
};

export default UserJoinedEventNotification;