import React, { Component } from 'react';
import { Loader } from "src/ui/components";

class LoaderPage extends Component {
    render() {
        return (
            <Loader />
        )
    }
}

export default LoaderPage;
